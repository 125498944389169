import React from "react";
import CustomLoader from "../../../components/library/CustomLoader";
import ExamLabelCard from "../../../components/PPC/ExamLabelCard";
import { useWindowDimensions } from "../../../components/utils";

const ExamList = ({
  examList = [],
  selectExamCb,
  saveExamCb,
  saveExamLoading,
}) => {
  const windowSize = useWindowDimensions();

  if (windowSize?.width > 700) {
    return (
      <>
        {examList?.length > 0 ? (
          <div className="exam-options-list">
            <ul>
              {examList?.map((exam, i) => (
                <ExamLabelCard
                  key={exam.id}
                  src={exam?.thumbnail || "/images/exam-selection-img.svg"}
                  examName={exam.name}
                  active={exam?.active}
                  onClick={() => selectExamCb(exam.id)}
                />
              ))}
            </ul>

            <div className="exam-section-btn" onClick={saveExamCb}>
              <span>
                {saveExamLoading ? (
                  <CustomLoader buttonSpinner={true} />
                ) : (
                  "DONE"
                )}
              </span>
            </div>
          </div>
        ) : (
          <div className="noresult-found">
            <div className="no-result-icon">
              <img src="/images/no-result-icon.svg" />
            </div>
            <div className="no-rusult-heading">No Results Found!</div>
            <div className="no-rusult-subheading">
              Please check if you have the right spelling,
              <br /> or try different keywords.
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {examList?.length > 0 ? (
        <div className="mobile-cutum-checkobx">
          <div className="chckbox-wrap">
            {examList?.map((exam) => (
              <label className="checkbox-list" key={exam.id}>
                {exam.name}
                <input
                  type="checkbox"
                  checked={exam?.active}
                  onChange={() => selectExamCb(exam.id)}
                />
                <span className="checkmark"></span>
              </label>
            ))}
          </div>

          <div className="checkbox-bottom-btn" onClick={saveExamCb}>
            <span>
              {saveExamLoading ? <CustomLoader buttonSpinner={true} /> : "DONE"}
            </span>
          </div>
        </div>
      ) : (
        <div className="noresult-found">
          <div className="no-result-icon">
            <img src="/images/no-result-icon.svg" />
          </div>
          <div className="no-rusult-heading">No Results Found!</div>
          <div className="no-rusult-subheading">
            Please check if you have the right spelling,
            <br /> or try different keywords.
          </div>
        </div>
      )}
    </>
  );
};

export default ExamList;

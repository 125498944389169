
import React, { useState } from "react";
//import { Cookies } from "react-cookie";
import { msToTime, getMoengageMetadata } from '../../../data/utils/helpers';
import { FallBackImage, pageName } from "../../../../constants/appConfig";
const ToggleSection = ({ moengageData,isOpen, toggle, header, body }) => {

  const moengageEvetTriggred = () => {
    var payload = moengageData && moengageData
    payload.contentID="";
    payload.contentTitle="";
    payload.videoPlayDuration=""
    payload.endTime="";
    payload.startTime=""
    payload.action = "chapter_clicked";
    payload.state=""
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'video',
      getMoengageMetadata(
        payload
      ),
    );
  }

  return (
    <div className="cc">
      <div className={`cc-header ${isOpen ? "active" : ""}`} onClick={()=>{
        toggle() 
        moengageEvetTriggred()
        }}>
        <div className="cc-header-text">{header}</div>
        <div>
          <div className={isOpen ? "chevronUp" : "chevronDown"}></div>
        </div>
      </div>
      {isOpen && <div className="cc-body">{body} </div>}
    </div>
  );
};

const VideoCard = ({ selectedVideoDetails, moengageData, imgSrc, header, subHeader, onClick, expired, demo, token, demourl, isPurchased }) => {
  //let token = Cookies.get('cp_token');
  // const bothfunction=()=>{
  //   onClick();
  // }

  const moengageEvetTriggred = () => {
    var payload = moengageData && moengageData
    payload.contentTitle = selectedVideoDetails && selectedVideoDetails.name
    payload.contentID = selectedVideoDetails && selectedVideoDetails.url ? selectedVideoDetails.url : selectedVideoDetails.demoUrl
    payload.action = "video_opened";
    payload.state=""
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'video',
      getMoengageMetadata(
        payload
      ),
    );
  }


  return (
    <div className="video-info" onClick={() => {
      onClick()
      moengageEvetTriggred()
    }}>
      <div className="video-info-img">
        <img src={imgSrc || FallBackImage} width="94px" height="56px" />


        {/* image locked for expired videos */}

        {
          expired && !demo || !token && !demo || !isPurchased && token && !demo ? <img src={`https://storecdn.${pageName[0]}.com/ic_lock_white_small.svg`} className='lock-image'></img>
            :
          <img className="img-res align-lock-play1" src={`https://storecdn.${pageName[0]}.com/ic_play_white_small.svg`}></img>
        }

      </div>
      <div className="video-info-details">
        <div className="video-info-header">{header}</div>
        <div className="video-info-subheader">{subHeader}</div>
      </div>
    </div>
  );
};
const CourseList = ({ selectedVideoDetails, moengageData, courseListData, setSelectedVideoDetails, expired, modalValueHandler, token, isPurchased }) => {

  return (<>
    {courseListData.map((courseItem) => {
      return (

        <VideoCard
          moengageData={moengageData}
          selectedVideoDetails={selectedVideoDetails}
          token={token}
          isPurchased={isPurchased}
          imgSrc={courseItem.tImg}
          width="94px"
          height="56px"
          onClick={() => {
            modalValueHandler(courseItem);
            setSelectedVideoDetails(courseItem);
          }}
          //   variant='overlayBg'
          header={courseItem.name}
          expired={expired}
          subHeader={courseItem.duration ? msToTime(courseItem.duration) : ''}
          demo={courseItem.demoUrl}
          demourl={courseItem}

        ></VideoCard>
      )
    })}
  </>

  )
}
const CourseContent = ({ subject,moengageData, selectedVideoDetails, courseContentData, token, isPurchased, openSection, toggleSection, setSelectedVideoDetails, setOpenSectionName, setistoggleClicked, courseDetails, modalValueHandler }) => {
  let data = courseContentData;
  let chapters = data.subject[0].ch
  const [value, setValue] = useState(subject?true:false);
  const [currentchapters, setCurrentchapter] = useState(subject?subject:'')
  const [chaptersvalue, setCurrentChaptersValue] = useState("");
  // const [selectedChapter, setSelectedChapter] = useState();


  // const toggleSection = id => {
  //   return () => {
  //     setSelectedChapter(selectedChapter == id ? undefined : id);
  //   };
  // };

  const selectChapetr = (currentchapter) => {
    var element = document.getElementById('title');
    setValue(true);
    setCurrentchapter(currentchapter);

  }
  const allSUbjectsHandler = () => {
    setValue(false);
    setCurrentchapter("");

  }

  return (
    <div classname='course-content' style={{ backgroundColor: '#efeff4' }}>
      <div style={value ? { display: 'none' } : { display: 'block' }} >
        <div classname='cc-title' style={{ padding: '10px', fontSize: '16px', fontWeight: '600' }}>Course Content</div>
      </div>


      {
        courseDetails.type != 2 && courseDetails.type != 4 ?

          (
            <div>
              <div style={{ padding: '10px' }}>
                <div className={value ? 'all-subject' : 'display-none'}
                  onClick={allSUbjectsHandler}
                >
                  ALL SUBJECTS
    </div>
                <div className='current-chapter'>
                  {currentchapters}
                </div>
              </div>
              {
                data && data.subject.map(result => {
                  return (
                    <div>

                      <div className={!value ? "small-12 columns subject-listing" : 'display-none'} onClick={() => selectChapetr(result.name)}>
                        <div className="circle-icon-videos-1" style={{ fontSize: '28px', marginRight: '8px' }}>
                        <img src={`https://storecdn.${pageName[0]}.com/ic_video_subject.svg`} style={{ marginLeft: "10px" }} />                        </div>
                        <div className="subject-title-listing-parent" >
                          <span className="subject-title"  >{result.name}</span>
                          <div>
                            <span small-6 style={{ color: 'rgba(0, 0, 0, 0.54)', paddingBottom: '20px', fontSize: '12px' }} classname="sections-videos">{result.cCount}Sections | </span>
                            <span small-6 style={{ color: 'rgba(0, 0, 0, 0.54)', paddingBottom: '20px', fontSize: '12px' }} classname="sections-videos">{result.vCount} Videos</span>
                          </div>
                        </div>
                        <div className="small-1 columns my-test-series-test-group-title-icon-parent"  >
                          <span className="icon-chevron-right my-test-series-test-group-title-icon"  ></span>
                        </div>
                      </div>


                      {
                        result.name.localeCompare(currentchapters) == 0 && value && result.ch.map((item, index) => {
                          return (
                            <div>
                              <ToggleSection
                               moengageData={moengageData}
                               selectedVideoDetails={selectedVideoDetails}
                                isOpen={index == openSection}
                                toggle={toggleSection(index, item)}
                                header={item.name}
                                body={<CourseList courseListData={item.video}
                                  moengageData={moengageData}
                                  selectedVideoDetails={selectedVideoDetails}
                                  modalValueHandler={modalValueHandler}
                                  token={token}
                                  isPurchased={isPurchased}
                                  demo={item.demo}
                                  setSelectedVideoDetails={(videoDetail) => {
                                    setSelectedVideoDetails(videoDetail);
                                    setOpenSectionName(item.name);
                                    setistoggleClicked(true)
                                  }}
                                  expired={courseDetails.expired}

                                />}
                              ></ToggleSection>
                            </div>
                          );
                        })}

                    </div>)

                })
              }
            </div>)
          :
          (
            chapters.map((item, index) => {
              return (
                <div>
                  <ToggleSection
                   moengageData={moengageData}
                   selectedVideoDetails={selectedVideoDetails}
                    isOpen={index == openSection}
                    toggle={toggleSection(index, item)}
                    header={item.name}
                    body={<CourseList courseListData={item.video} setSelectedVideoDetails={(videoDetail) => {
                      setSelectedVideoDetails(videoDetail);
                      setOpenSectionName(item.name);
                      setistoggleClicked(true)
                    }}

                      moengageData={moengageData}
                      selectedVideoDetails={selectedVideoDetails}
                      token={token}
                      isPurchased={isPurchased}
                      expired={courseDetails.expired}
                      modalValueHandler={modalValueHandler}
                      demo={item.demo}
                    />}
                  ></ToggleSection>
                </div>
              );

            })
          )

      }




    </div>
  )
}
export default CourseContent;

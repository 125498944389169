import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { pdpUrls } from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import { setCartCountFromCookie } from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import { Redirect } from 'react-router-dom';
import { headerSearchOptions } from "../../constants/appConstants";
// import { fireMetaPixelEvent } from "../data/utils/helpers";

const BuyNowComponent = ({
  viewOffers,
  isPurchased,
  productData,
  isLoggedIn,
  setCartCountFromCookie,
  published,
  isPaytmMini,
  onClick,
  history,
  setFromBuyNowBtn,
}) => {
  if (isPurchased == -1) {
    return null;
  }
  const getId = (id) => {
    console.log( headerSearchOptions)
    let fileredId = headerSearchOptions.find((y) => y.value == id);
    if(fileredId) {
      return fileredId.key;
    } else {
      return "";
    }
  }

  let reorderSubcategories = [
  {category: 'FREE_CONTENT'},
  {category:'MODELS_3D'},
  {category:'ONLINE_LIVE_CLASSES'},
  {category:'TEST_SERIES'},
  {category:'VIDEOS'},
  {category:'EBOOKS'},
  {category:'BOOKS'},
  ]
reorderSubcategories = reorderSubcategories?.map(category => productData?.subCategories?.find( item => item.category == category.category))
  reorderSubcategories = reorderSubcategories?.filter(item => item)
  const categoryPathKey = {
    ONLINE_LIVE_CLASSES: "liveClasses",
    TEST_SERIES: "testPkgs",
    VIDEOS: "videoPkgs",
    EBOOKS: "ebooksPkgs",
    BOOKS: "booksPkgs",
    MODELS_3D: "models3DPkgs",
  };
  const addToCartInLocalStorage = (productDetails) => {
    let cartItems =
      (localStorage.getItem("CART_ITEMS") &&
        JSON.parse(localStorage.getItem("CART_ITEMS"))) ||
      [];
    if (!cartItems.find((cartItem) => cartItem.id === productDetails.id)) {
      // product is not added already.
      cartItems.push({
        id: productDetails.id,
      });
    }
    localStorage.setItem("CART_ITEMS", JSON.stringify(cartItems));
  };
  const addToCart = (productDetails, isLoggedIn) => {
    if (!isLoggedIn) {
      addToCartInLocalStorage(productDetails);
    }
  };

  const onBuyNow = () => {
    setFromBuyNowBtn(true);
    viewOffers(true)
    // fire meta pixel Add to cart event
    // fireMetaPixelEvent('AddToCart')
    addToCart(productData, isLoggedIn);
    sessionStorage.setItem("buyNow_Offer", "buy");
    // window.location.replace("#checkout/viewOffers");
    window.location.hash = "#checkout/viewOffers";

  };

  const onViewCourse = () => {
    let courseUrl = `/my-purchase/${productData.id}/${productData.slugURL}`
    history.push(courseUrl)
  }


  let Button = (
    <button
      onClick={onBuyNow}
      className="purchaseActionItem"
      data-testid="purchaseActionItem"
    >
      Buy Now
    </button>
  );
  if (isPurchased) {
    Button = (
      <>
        <button className="purchaseActionItem alreadyPurchasedlabel" data-testid="purchaseActionItem">
          &#x2714; Already Purchased
        </button>
        <span className="viewOffers viewCourse" onClick={() =>onClick(categoryPathKey[reorderSubcategories[0]?.category], productData?.subCategories[0]?.category, "viewCourse")}>View Course</span>
      </>
    );
  } else if (published == false) {
    Button = (
      <button className="purchaseActionItem alreadyPurchasedlabel logocolor" data-testid="purchaseActionItem">
        Currently Not Available
      </button>
    );
  }
  return Button;
};
const mapStateToProps = (state) => {
  return {
    userDetails: state.header.userInfo,
    isPaytmMini: state.isPaytmMini,
  };
};
const mapDispatchToProps = {
  setCartCountFromCookie,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyNowComponent);

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserFirstWish } from "../../data/ducks/wishlist/actions";

const FirstWishListGuideToolTip = () => {
  const { isUserFirstWhish } = useSelector((state) => state?.wishlist);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserFirstWhish) {
      const timeOut = setTimeout(() => dispatch(setUserFirstWish(false)), 4000);
      // clean up setTimeout
      return () => clearTimeout(timeOut);
    }
  }, [isUserFirstWhish]);
  return (
    <>
      {isUserFirstWhish && (
        <span className="acess-wl-tooltip">Access 'My Wishlist' here</span>
      )}
    </>
  );
};

export default FirstWishListGuideToolTip;

const GET_PRODUCT_DETAIL_LISTING = 'GET_PRODUCT_DETAIL_LISTING';
const GET_PRODUCT_DETAIL_LISTING_ON_SCROLL = 'GET_PRODUCT_DETAIL_LISTING_ON_SCROLL';
const CLEAR_PRODUCT_LISTING_DATA = 'CLEAR_PRODUCT_LISTING_DATA';
const GET_SECONDARY_FACETS='GET_SECONDARY_FACETS'

export default {
    GET_PRODUCT_DETAIL_LISTING,
    GET_PRODUCT_DETAIL_LISTING_ON_SCROLL,
    CLEAR_PRODUCT_LISTING_DATA,
    GET_SECONDARY_FACETS
};

import React from "react";
import { courseIncludeSubcategories } from "../../../constants/consts";
import { formatSubCatlabel } from "../../data/utils/helpers";

const CourseIncludes = ({ subCategoriesData, onClick }) => {
  return (
    <div className="pdpIncludeListWrap" id="include">
      <h3 className="pdpCommanHeading">
        This Course <span>Includes</span>
      </h3>
      <div className="pdpIncludeList">
        <ul>
          {courseIncludeSubcategories?.map((subCatData) => {
            const { category, image, id } = subCatData;
            const item = subCategoriesData?.find(
              (item) => item?.category === subCatData?.category
            );
            if (!item) {
              return null;
            }
            const duration =
              subCatData?.category === "ONLINE_LIVE_CLASSES" &&
              item?.vduration > 0
                ? parseInt(item?.vduration) + " Hrs"
                : item?.count;

            const count = item?.count ? item?.count : 0;
            return (
              <li key={category} id={`ci-${category}`} onClick={category !== 'BOOKS' ? () => onClick(id, category) : null}>
                <span>
                  <img src={image} alt={category} />
                  {duration || count}{" "}
                  {category === "EBOOKS"
                    ? " E-Books"
                    : formatSubCatlabel(category)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default CourseIncludes;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import CustomButton from "../components/library/CustomButton";

import { pageName, pdpUrls } from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../constants/appConfig";

const cookie = new Cookies();
const MyaccountForm = (props) => {
  // Local states
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [providerToken, setProviderToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showSetPasswordInput, setShowSetPasswordInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [defaultPassword, setDefaultPassword] = useState("");
  const [confirmSetPassword, setConfirmSetPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyAcc, setVerifyAcc] = useState(false);
  const [verifyFail, setVerifyFail] = useState(false);
  const [defaultPasswordError, setDefaultPasswordError] = useState(false);
  const [confirmSetPasswordError, setConfirmSetPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [error, setError] = useState(null);
  const providerName = cookie.get("provider_name")
    ? cookie.get("provider_name")
    : "";

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const updateField = (fieldName) => {
    return (event) => {
      let value = event.target.value;
      if (fieldName == "confirmPassword") {
        setConfirmPassword(event.target.value);
        setMessage("");
      } else if (fieldName == "defaultPassword") {
        setDefaultPassword(event.target.value);
        if (
          !props.data &&
          defaultPassword.length >= 6 &&
          defaultPassword.length <= 20 &&
          !showPasswordInput
        ) {
          setDefaultPasswordError(false);
        }
        if (defaultPassword.localeCompare(confirmSetPassword) === 0) {
          setConfirmSetPasswordError(false);
        }
        setMessage("");
      } else if (fieldName == "confirmSetPassword") {
        setConfirmSetPassword(event.target.value);
        if (defaultPassword.localeCompare(confirmSetPassword) === 0) {
          setConfirmSetPasswordError(false);
        }
        setMessage("");
      } else if (
        fieldName == "defaultPassword" ||
        fieldName == "confirmSetPassword"
      ) {
        if (
          defaultPassword === defaultPassword.trim() &&
          confirmSetPassword === confirmSetPassword.trim()
        ) {
          setError("");
        }
      } else {
        setPassword(event.target.value);
        if (password.localeCompare(confirmPassword) === 0) {
          setConfirmPasswordError(false);
        }
        if (value === value.trim()) {
          setError("");
        }
        setMessage("");
      }
    };
  };

  if (
    (password &&
      password.length >= 6 &&
      password.length <= 20 &&
      password.localeCompare(confirmPassword) == 0) ||
    (!props.data &&
      defaultPassword &&
      defaultPassword.length >= 6 &&
      defaultPassword.length <= 20 &&
      defaultPassword.localeCompare(confirmSetPassword) == 0 &&
      typeof document != "undefined" &&
      typeof window != "undefined")
  ) {
    let element = window.document.getElementById("custombutton");
    if (element.classList.contains("custom-button-disabled"))
      element.classList.remove("custom-button-disabled");
  } else if (
    (password && password.localeCompare(confirmPassword) != 0) ||
    (!props.data &&
      defaultPassword &&
      defaultPassword.localeCompare(confirmSetPassword) != 0 &&
      typeof document != "undefined" &&
      typeof window != "undefined")
  ) {
    let element = window.document.getElementById("custombutton");
    if (!element.classList.contains("custom-button-disabled"))
      element.classList.add("custom-button-disabled");
  }

  let email = cookie.get("cp_user_email");
  let name = cookie.get("cp_user_name");
  let fbUserId = cookie.get("fb_user_id") ? cookie.get("fb_user_id") : null;

  const responseGoogle = (response) => {
    if (response && response.profileObj) {
      if (response.profileObj?.email == email) {
        setProviderToken(response.tokenId);
        setVerifyAcc(true);
        setShowSetPasswordInput(true);
      } else {
        setVerifyFail(true);
        setShowSetPasswordInput(false);
      }
    }

    setTimeout(() => setVerifyAcc(false), 2000);
    closeModal();
  };


  const responseFacebook = (response) => {
    if (response?.accessToken && response?.email == email) {
      if (response.email == email) {
        setProviderToken(response.accessToken);
        setVerifyAcc(true);
        setShowSetPasswordInput(true);
      } else {
        setVerifyFail(true);
        setShowSetPasswordInput(false);
      }
    } else if (response.accessToken && response.userID) {
      if (response.userID == fbUserId) {
        setProviderToken(response.accessToken);
        setVerifyAcc(true);
        setShowSetPasswordInput(true);
      } else {
        setVerifyFail(true);
        setShowSetPasswordInput(false);
      }
    } else {
      setVerifyFail(true);
      setShowSetPasswordInput(false);
    }

    setTimeout(() => setVerifyAcc(false), 2000);
    closeModal();
  };

  const SubmitData = () => {
    setLoading(true);
    let headers = {};
    headers["Content-Type"] = "application/json";
    headers["X-Auth-Token"] = "fpoa43edty5";
    headers["X-JWT-Token"] = cookie.get("cp_token");

    let payload = {};
    payload.setPassword = defaultPassword;
    payload.providerToken = providerToken ? providerToken : null;
    payload.providerName = providerName;

    let url = `${pdpUrls.userApi}/setPassword`;

    fetch(url, "POST", payload, null, null, headers)
      .then((response) => {
        if (response && response.success == true) {
          setMessage(response.response);
          setProviderToken(null);
          setConfirmSetPassword("");
          setPassword("");
          setLoading(false);
          setShowModal(true);
          setShowPasswordInput(false);
          setTimeout(() => handleLogout(), 2000);
        } else {
          setLoading(false);
          alert(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePasswordSet = (e) => {
    e.preventDefault();
    openModal();
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setShowPasswordInput(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setDefaultPassword("");
    setPassword("");
    setConfirmPassword("");
    setShowPasswordInput(false);
    setError("");
    let element = window.document.getElementById("custombutton");
    if (element.classList.contains("custom-button-disabled"))
      element.classList.remove("custom-button-disabled");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setDefaultPasswordError(false);
    setConfirmSetPasswordError(false);
    if (defaultPassword.match(/[^\x00-\x7F]/)) {
      setError("Password should not contain emojis");
      return;
    }
    if (
      (!props.data && defaultPassword.length < 6) ||
      defaultPassword.length > 20
    ) {
      setDefaultPasswordError(true);
      return;
    }
    if (defaultPassword.localeCompare(confirmSetPassword) != 0) {
      setConfirmSetPasswordError(true);
      return;
    }
    if (showSetPasswordInput && defaultPassword !== defaultPassword.trim()) {
      setError("Your password can’t start or end with a blank space");
      return;
    }

    SubmitData();
  };

  const submit = (event) => {
    event.preventDefault();
    setError("");
    setDefaultPasswordError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    if (password.match(/[^\x00-\x7F]/)) {
      setError("Password should not contain emojis");
      return;
    }
    if (password.length < 6 || password.length > 20) {
      setPasswordError(true);
      return;
    }
    if (password.localeCompare(confirmPassword) != 0) {
      setConfirmPasswordError(true);
      return;
    }
    if (password !== password.trim()) {
      setError("Your password can’t start or end with a blank space");
      return;
    }

    setLoading(true);
    let payload = {};
    fetch(
      `${pdpUrls.userApi}/api/v2/changePassword?src=and&oldPassword=${defaultPassword}&newPassword=${confirmPassword}&src=aweb`,
      "POST",
      payload
    ).then(
      (res) => {
        if (res && res.success) {
          setMessage("Password successfully Changed");
          setConfirmPassword("");
          setLoading(false);

          setShowModal(true);
          setTimeout(() => handleLogout(), 2000);
        } else if (!res.success) {
          setError(res.response);
          setLoading(false);
        }
      },
      (err) => {
        setError(err.message || "Some Error Occurred");
        setLoading(false);
      }
    );
  };

  const handleLogout = () => {
    var eventFired = new CustomEvent("userLogoutCE");
    document.dispatchEvent(eventFired);
  };

  const togglePassType = (e) => {
    let elem = e.target.parentNode.getElementsByTagName("input")[0];
    let imgElm = e.target.parentNode.getElementsByTagName("img")[0];
    elem.focus();
    if (elem.type == "password") {
      elem.type = "text";
      imgElm.src = "/images/crossed_eye.svg";
    } else {
      elem.type = "password";
      imgElm.src = "/images/eyeicon.svg";
    }
  };

  return (
    <>
      {verifyAcc && (
        <div className="mock-comman-pop">
          <div className="mock-comman-pop-content">
            <div className="mock-header-popup">
              <div className="mockpopheader-title">Authentication Success</div>
              <div
                className="close-mock-pop"
                onClick={() => setVerifyAcc(false)}
              >
                <a href="#">
                  <img src="../images/checkout-close.svg" />
                </a>
              </div>
            </div>
            <div className="comman-pop-content">
              Continue to set to your account password
            </div>
          </div>
        </div>
      )}
      {verifyFail && (
        <div className="mock-comman-pop">
          <div className="mock-comman-pop-content">
            <div className="mock-header-popup">
              <div className="mockpopheader-title">Authentication Failed</div>
              <div
                className="close-mock-pop"
                onClick={() => setVerifyFail(false)}
              >
                <a href="#">
                  <img src="../images/checkout-close.svg" />
                </a>
              </div>
            </div>
            <div className="comman-pop-content">
              Account not matching, please retry with email mentioned in your
              profile.
            </div>
          </div>
        </div>
      )}

      {open && (
        <div className="mock-comman-pop">
          <div className="mock-comman-pop-content">
            <div className="mock-header-popup">
              <div className="mockpopheader-title">Authentication</div>
              <div className="close-mock-pop" onClick={closeModal}>
                <a href="#">
                  <img src="../images/checkout-close.svg" />
                </a>
              </div>
            </div>
            <div className="short-heading-popup">
              Please verify your account to proceed.
            </div>
            <div className="comman-pop-content">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Continue with Google"
                onSuccess={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
              <FacebookLogin
                appId="627005884507677"
                autoLoad={false}
                disableMobileRedirect
                fields="name,email,picture"
                callback={responseFacebook}
                icon="fa-facebook"
                textButton="Continue with Facebook"
              />
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="mock-comman-pop">
          <div className="mock-comman-pop-content">
            <div className="mock-header-popup">
              <div className="mockpopheader-title"></div>
              <div
                className="close-mock-pop"
                onClick={() => setShowModal(false)}
              >
                <a href="#">
                  <img src="../images/checkout-close.svg" />
                </a>
              </div>
            </div>
            <div className="comman-pop-content text-center">
              <img src="/images/success.svg" width="160" />
              <h3>
                <b>Congratulations.</b>
              </h3>
              <p>
                {props?.data
                  ? "Your password is changed successfully."
                  : "Your password is set successfully."}
              </p>
            </div>
          </div>
        </div>
      )}
      <form>
        <div className="userconatiner">
          <div className="myaccountheader">Account Information</div>
          <div className="row">
            <div className="input-group">
              <label htmlFor="email">Name</label>
              <input
                type="text"
                size="default"
                value={name || "Vishal123 sharma"}
                disabled={true}
              ></input>
            </div>
            <div className="input-group">
              <label htmlFor="psw">Email</label>
              <input
                type="email"
                size="default"
                value={email || `vishal123.sharma@${pageName[0]}.com`}
                disabled={true}
              ></input>
            </div>
          </div>
          <div className="row min-ht-125">
            <div className="input-group">
              <label htmlFor="pswd">
                {props.data ? "Current Password" : "Password"}
              </label>
              <input
                type="password"
                size="default"
                value={defaultPassword}
                placeholder={props.data && !showPasswordInput ? "●●●●●●" : ""}
                onChange={updateField("defaultPassword")}
                className={defaultPasswordError ? "input-error" : "margin2"}
                disabled={
                  (props.data && !showPasswordInput) ||
                  (!props.data && !showSetPasswordInput)
                }
              ></input>
              <img
                src="/images/eyeicon.svg"
                className="pass-hide-icon"
                onClick={togglePassType}
                width="22"
                alt="show password"
              />
              {props.data && showPasswordInput && (
                <div
                  class="forgetText"
                  onClick={() => props.setForgetPassShow(true)}
                >
                  Forgot Password?
                </div>
              )}
              {!showPasswordInput &&
              !defaultPasswordError &&
              defaultPassword &&
              (defaultPassword.length < 6 || defaultPassword.length > 20) ? (
                <div className="errormessage">
                  Password should be between 6 and 20 characters
                </div>
              ) : null}
              {!props.data && defaultPasswordError ? (
                <div className="errormessage">
                  Password should be between 6 and 20 characters
                </div>
              ) : null}
            </div>
            {showSetPasswordInput && (
              <div className="input-group">
                <label htmlFor="pswd">Confirm Password</label>
                <input
                  type="password"
                  size="default"
                  value={confirmSetPassword}
                  onChange={updateField("confirmSetPassword")}
                  className={
                    confirmSetPasswordError ? "input-error" : "margin2"
                  }
                ></input>
                <img
                  src="/images/eyeicon.svg"
                  className="pass-hide-icon"
                  onClick={togglePassType}
                  width="22"
                  alt="show password"
                />
                {!confirmSetPasswordError &&
                confirmSetPassword &&
                defaultPassword.localeCompare(confirmSetPassword) != 0 ? (
                  <div className="errormessage">Password does not match</div>
                ) : null}
                {confirmSetPasswordError ? (
                  <div className="errormessage">Password does not match</div>
                ) : message ? (
                  <div className="successmessage hideMe">{message}</div>
                ) : null}
              </div>
            )}
            {showPasswordInput && (
              <>
                <div className="input-group">
                  <label htmlFor="psw-repeat">New Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={updateField("password")}
                    className={passwordError ? "input-error" : "margin2"}
                  />
                  <img
                    src="/images/eyeicon.svg"
                    className="pass-hide-icon"
                    onClick={togglePassType}
                    width="22"
                    alt="show password"
                  />
                  {!passwordError &&
                  password &&
                  (password.length < 6 || password.length > 20) ? (
                    <div className="errormessage">
                      Password should be between 6 and 20 characters
                    </div>
                  ) : null}
                  {passwordError ? (
                    <div className="errormessage">
                      Password should be between 6 and 20 characters
                    </div>
                  ) : null}
                </div>
                <div className="input-group">
                  <label htmlFor="psw-repeat">Re-Enter New Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={updateField("confirmPassword")}
                    className={confirmPasswordError ? "input-error" : "margin2"}
                  />
                  <img
                    src="/images/eyeicon.svg"
                    className="pass-hide-icon"
                    onClick={togglePassType}
                    width="22"
                    alt="show password"
                  />
                  {!confirmPasswordError &&
                  confirmPassword &&
                  password.localeCompare(confirmPassword) != 0 ? (
                    <div className="errormessage">Password does not match</div>
                  ) : null}
                  {confirmPasswordError ? (
                    <div className="errormessage">Password does not match</div>
                  ) : message ? (
                    <div className="successmessage hideMe">{message}</div>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <div className="row">
            {error && <div className="errormessage">{error}</div>}
          </div>
          <div className="buttondiv1">
            {props.data ? (
              <>
                {showPasswordInput && (
                  <CustomButton
                    type="secondary"
                    classes="custom-button cancel-btn"
                    buttontext="CANCEL"
                    onClick={handleCancel}
                  />
                )}
                <CustomButton
                  type="primary"
                  id="custombutton"
                  buttontext="CHANGE PASSWORD"
                  loading={loading}
                  onClick={(e) => {
                    showPasswordInput == true
                      ? submit(e)
                      : handlePasswordChange(e);
                  }}
                />
              </>
            ) : (
              <CustomButton
                type="primary"
                id="custombutton"
                buttontext="SET PASSWORD"
                loading={loading}
                onClick={(e) => {
                  showSetPasswordInput == true
                    ? handleSubmit(e)
                    : handlePasswordSet(e);
                }}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  return {
    userDetails: state.header.userInfo,

    isLoggedIn,
    userInfo,
  };
};

export default connect(mapStateToProps)(MyaccountForm);

import React, { useState, useEffect, useCallback } from "react";
import {
  getMoengageMetadata,
  getPdpUrl,
  add3Dots
} from "../../data/utils/helpers";

const MahaPackComparison = ({ mahapackComparisonData, title, GetPayload,isPurchased }) => {
  const MahaPackBtnRowText = "Learn More, Save More, get a Mahapack";

  const [isMobile, setIsMobile] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const comparisonTitles = mahapackComparisonData[0]?.listOfComparisons?.map(
    (comparison) => comparison?.packageTitle
  );

  const handleBuyNow = () => {
    var eventFired = new CustomEvent("buyNowModalOpen");
    document.dispatchEvent(eventFired);
  };

  const handleViewCourse = () => {
    var eventFired = new CustomEvent("viewCourseModalOpen");
    document.dispatchEvent(eventFired);
  };

  const handleMPLink = (d) => {
    let payload = GetPayload || {};
    payload.package_url = window?.location?.href || "";
    payload.mahapack_id = d?.packageId;
    payload.mahapack_name = d?.packageTitle;
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "Explore_mahapack_button_clicked",
        getMoengageMetadata(payload)
      );

    window?.open(
      getPdpUrl(d?.category, d?.packageId, d?.packageTitle),
      "_blank"
    );
  };
  

  const getBoolType = (value) => {
    switch (value) {
      case "Yes":
        return true;
      case "No":
        return true;
      default:
        return false;
    }
  };

 
  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
  }, []);
  
  // Call handleResize initially and add event listener for resize
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const titleVal = isMobile ? add3Dots(title, 20) : title;

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };
  const toggleTitleTooltip = (e) => {
    e.persist()
    let tooltip = e.target.childNodes[1];
    if (tooltip.style.display === "block") {
      tooltip.style.display = "none";
    } else {
      tooltip.style.display = "block";
    }

    // Attach click event listener to document to close the tooltip when clicked outside
    const closeTooltip = (event) => {
      if (!event.target.contains(e.target)) {
        tooltip.style.display = 'none';
        document.removeEventListener('click', closeTooltip);
      }
    };

  // Add click event listener to document to track clicks outside the tooltip
  document.addEventListener('click', closeTooltip);
  };

  const displayedData = showMore
    ? mahapackComparisonData
    : mahapackComparisonData.slice(0, 3);

  return (
    <div className="pdpComparisonData">
      <table className={`aligncenter ${isMobile && !showMore && mahapackComparisonData.length > 3 && 'blur-table'}`}>
        <thead>
          {isMobile ? (
            <tr>
              <th></th>
              <th
                className="title-wrap"
                onClick={(e) => titleVal !== title ? toggleTitleTooltip(e): null}
              >
                {titleVal}
                <span className="title-tooltip">{title}</span>
              </th>
              {comparisonTitles?.map((title, index) => (
                <th className="title-wrap" key={index} onClick={(e) => add3Dots(title,20) !== title ? toggleTitleTooltip(e): null}>{add3Dots(title,20)}<span className="title-tooltip">{title}</span></th>
              ))}
            </tr>
          ) : (
            <tr>
              <th></th>
              <th>{title}</th>
              {comparisonTitles?.map((title, index) => (
                <th key={index}>{title}</th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {isMobile
            ? displayedData && displayedData?.map((data, index) => (
                <tr key={index}>
                  <td className="title-wrap" onClick={(e) => add3Dots(data?.param,20) !== data?.param ? toggleTitleTooltip(e): null}>{add3Dots(data.param,20)}<span className="title-tooltip">{data?.param}</span></td>
                  <td className="title-wrap" onClick={(e) => add3Dots(data?.value,20) !== data?.value ? toggleTitleTooltip(e): null}>
                    {getBoolType(data?.value) ? (
                      <img
                        src={
                          data?.value === "Yes"
                            ? "/images/pdpchecklist.svg"
                            : "/images/pdpcomparecross.svg"
                        }
                        alt="checkIcon"
                      />
                    ) : (
                      add3Dots(data?.value,20)
                    )}
                    <span className="title-tooltip">{data?.value}</span>
                  </td>
                  {data?.listOfComparisons?.map((comparison, index) => (
                    <td key={index} className="title-wrap" onClick={(e) => add3Dots(comparison?.value,20) !== comparison?.value ? toggleTitleTooltip(e): null}>
                      {getBoolType(comparison?.value) ? (
                        <img
                          src={
                            comparison?.value === "Yes"
                              ? "/images/pdpchecklist.svg"
                              : "/images/pdpcomparecross.svg"
                          }
                          alt="checkIcon"
                        />
                      ) : (
                        add3Dots(comparison?.value,20)
                      )}
                      <span className="title-tooltip">{comparison?.value}</span>
                    </td>
                  ))}
                </tr>
              ))
            : mahapackComparisonData?.map((data, index) => (
                <tr key={index}>
                  <td>{data.param}</td>
                  <td>
                    {getBoolType(data?.value) ? (
                      <img
                        src={
                          data?.value === "Yes"
                            ? "/images/pdpchecklist.svg"
                            : "/images/pdpcomparecross.svg"
                        }
                        alt="checkIcon"
                      />
                    ) : (
                      data?.value
                    )}
                  </td>
                  {data?.listOfComparisons?.map((comparison, index) => (
                    <td key={index}>
                      {getBoolType(comparison?.value) ? (
                        <img
                          src={
                            comparison?.value === "Yes"
                              ? "/images/pdpchecklist.svg"
                              : "/images/pdpcomparecross.svg"
                          }
                          alt="checkIcon"
                        />
                      ) : (
                        comparison?.value
                      )}
                    </td>
                  ))}
                </tr>
              ))}

          {!isMobile && mahapackComparisonData?.length > 0 || isMobile && mahapackComparisonData.length < 4 ? (
            <tr>
              <td>
                {MahaPackBtnRowText}
              </td>
              <td>
                <div className="tablebtn" onClick={!isPurchased ? handleBuyNow : handleViewCourse}>
                {!isPurchased ? "Buy Now" : "View Course"}
                </div>
              </td>
              {mahapackComparisonData[0]?.listOfComparisons?.map((d, index) => (
                <td key={index}>
                  <div className="tablebtn" onClick={() => handleMPLink(d)}>
                    Explore Now
                  </div>
                </td>
              ))}
            </tr>
          ) : (
            showMore &&
            mahapackComparisonData?.length > 0 && (
              <tr>
                <td className="title-wrap" onClick={(e) => add3Dots(MahaPackBtnRowText,20) !== MahaPackBtnRowText ? toggleTitleTooltip(e): null}>
                {add3Dots(MahaPackBtnRowText, 20)}
                <span className="title-tooltip">{MahaPackBtnRowText}</span>
              </td>
                <td>
                  <div className="tablebtn" onClick={!isPurchased ? handleBuyNow : handleViewCourse}>
                    {!isPurchased ? "Buy Now" : "View Course"}
                  </div>
                </td>
                {mahapackComparisonData[0]?.listOfComparisons?.map(
                  (d, index) => (
                    <td key={index}>
                      <div className="tablebtn" onClick={() => handleMPLink(d)}>
                        Explore Now
                      </div>
                    </td>
                  )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
      {isMobile && !showMore && mahapackComparisonData?.length > 3 && (
        <div className="tablebtnbottom" onClick={handleShowMore}>
          Show More
        </div>
      )}
      {isMobile && showMore && (
        <div className="tablebtnbottom upshowbtn" onClick={handleShowLess}>
          Show Less
        </div>
      )}
    </div>
  );
};

export default MahaPackComparison;
import React, { useEffect, useState, useRef } from "react";
import DecipherString from "./DecipherString";
import Timer from "./Timer";
import fetch from "../../../../data/utils/fetch";
import CustomModal from "../../../library/CustomModal";
import TestResponseDetails from "./TestResponseDetails";
import { STOREFRONT_URI, pageName } from "../../../../../constants/appConfig";
import SkipSection from "./SkipSection";
import { connect } from "react-redux";
import NetworkDisconnected from "./NetworkDisconnected";
import ATooltip from "../../../library/ATooltip";
import SectionToolTipContent from "./SectionToolTipContent";
import {
  decipherStrings,
  optionScrollHandler,
  questionTypesLabel,
  textSizeClassKeyNames,
  textSizeOptions,
} from "./util";
import {
  getMoengageMetadata,
  getPdpUrl,
  getTestAnalysisResultForReattempt,
  isADDA,
} from "../../../../data/utils/helpers";
import { getSpecialTests } from "../../../../data/ducks/specialTests/actions";
import "../../../../data/ducks/specialTests/reducers";
import SciCalci from "./SciCalci";
import { useExternalScript } from "../../../useExternalScript";
import { TEST_SERIES_URL } from "../../../../../constants/appUrls";
import Axios from "axios";
import FITB from "./TestInputComponents/FITB";
import DF from "./TestInputComponents/DF";
import DTB from "./TestInputComponents/DTB";
import { formatMilliseconds } from "../../../utils";
import { useParams } from "react-router";
import {
  BLANK_IDENTIFIER,
  ENGLISH_IDENTIFIER,
  WEB_IDENTIFIER,
} from "../../../MoengageConstants";
import { TEST } from "../../../../../constants/textConstants";
import { addToast } from "../../../../data/ducks/toast/actions";
import "../../../../data/ducks/toast/reducers";
import ExamTestHeader from "./ExamTest/ExamTestHeader";
import { ChangeTextSize } from "./ExamTest/ExamInstructionTextContent";
import {
  LIVE_TEST_CONSTANTS,
  getStateUpdateTime,
  hitMoengageForLiveTest,
  indicatorMessages,
  liveTestModalData,
} from "../TestResultAnalysis/Components/liveTestUtils";
import { Indicator } from "../TestResultAnalysis/Components/Indicator";
import LiveTestModal from "../TestResultAnalysis/Components/LiveTestModal";
const Test = ({
  data,
  userDetails,
  language,
  changeLanguage,
  prevData,
  title,
  packageId,
  mappingId,
  isPPc,
  goTo,
  isFixedMock,
  fixedMockData,
  isReattempt,
  totalTestTime,
  sfJson,
  addToast,
  mode = "",
  nLTAdditionalData = {},
  ...props
}) => {
  //:::Test Type to be sent by either localstorage or as props:::

  //For Network

  // fixed mock related states

  const [showFixedMockPopup, setShowFixedMockPopup] = useState(null);
  const [fixedMockPopUpText, setFixedMockPopUpText] = useState({
    title: "No Internet",
    desc: "Please turn on the internet to submit the test",
    secCTA: "",
    primaryCTA: "RETRY",
  });
  const [userStartTimeFixedMock, setUserStartTimeFixedMock] = useState();
  //Result Awaited Popup

  const [showResultAwaitedPopUp, setShowResultAwaitedPopUp] = useState(
    fixedMockData.testState == "COMPLETED" ||
      fixedMockData.fpmUserRemainingTime <= 0 ||
      (!isReattempt && localStorage.getItem(`${mappingId}Submitted`) == "true")
      ? true
      : false
  );
  const [currentTime, setCurrentTime] = useState(Date.now());

  const [questionsArray, setQuestionsArray] = useState(
    data[language] && data[language].ques.list
  );
  const [calculatorFlag, setCalculatorFlag] = useState(
    data["meta"].isCalculator
  );
  const [responseArray, setResponseArray] = useState(
    prevData || Array(data.meta.totalq).fill(null)
  );
  const [responseStatusArray, setResponseStatusArray] = useState(
    Array(data.meta.totalq).fill(0)
  );
  const [timeTakenArray, setTimeTakenArray] = useState(
    Array(data.meta.totalq).fill(null)
  );
  const [ismWebHidePanel] = useState(window.innerWidth <= "768");
  const [showResponsePanel, setShowResponsePanel] = useState(
    ismWebHidePanel ? false : true
  );
  const toogleResponsePanel = () => {
    setShowResponsePanel(!showResponsePanel);
  };
  const [sectionwiseTimeLimit, setSectionWiseTimeLimit] = useState(false);
  const [showCalci, setShowCalci] = useState(false);
  const [sectionStartIndexObj, setSectionStartIndexObj] = useState({});
  const [currentQues, setCurrentQues] = useState(0);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [modal, setModal] = useState();
  const [totalTimeArray, setTimeArray] = useState();
  const [selectedSection, setSelectedSection] = useState(0);
  const [validValueStatus, setValidValueStatus] = useState({
    dirty: false,
    message: "",
  });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [clearResponseFlag, setClearResponseFlag] = useState(false);
  const [saveStateUrlForFixedMock, setSaveStateUrlForFixedMock] = useState({});
  const [isTestTimeOver, setIsTestTimeOver] = useState(false);
  let { status } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const examName = urlParams.get("exam"); // taged exam name with test.
  const [freeContentFlag, setFreeContentFlag] = useState(
    urlParams.get("freeContent")
  );
  const [totalSectionCnt, setTotalSectionCnt] = useState(0);
  const [onLastQuesOfLastSection, setOnLastQuesOfLastSection] = useState(false);
  const [currentSectionRemainingTime, setCurrentSectionRemainingTime] =
    useState(0);
  const [selectedTextSize, setSelectedTextSize] = useState(textSizeOptions[2]);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const [indicatorMessage, setIndicatorMessage] = useState("");
  const [showLiveTestModal, setShowLiveTestModal] = useState(false);
  const [liveTestModalBody, setLiveTestModalBody] = useState();
  const [removeEvent, setRemoveEvent] = useState(false);
  const [skipSectionFlag, setSkipSectionFlag] = useState(false);
  const [testSubmitMoengageHit, setTestSubmitMoengageHit] = useState(false);

  const currentSectionRemainingTimeRef = useRef(currentSectionRemainingTime);
  const defaultQuestionType = 1;
  const jQueryScript =
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js";
  useExternalScript(jQueryScript);
  const timeOutId = useRef();
  const prevQuesId = useRef();
  const responseArrrayRef = useRef();
  const tcAnswerRef = useRef();
  responseArrrayRef.current = responseArray;

  const SUBMISSION_CONFIRMATION_MESSAGE =
    "Are you sure you want to submit the test";

  const onBeforeReloadOrCloseTab = (event) => {
    try {
      if (!isFixedMock && !freeContentFlag) {
        // call the saveStateOnUnMount,so that resume timing is also sync when tab is close.
        if (mode !== LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
          saveStateOnUnMount(responseArrrayRef.current, prevQuesId.current);
        }
      }
      event.preventDefault();
      return (event.returnValue = "");
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    responseArrrayRef.current = responseArray;
  }, [responseArray]);
  useEffect(() => {
    if (navigator.onLine && showFixedMockPopup) {
      setShowFixedMockPopup(false);
      if (isFixedMock && !isTestTimeOver && !isReattempt) {
        let timeArray = timeTakenArray;
        let testStateData = responseArray.map((data, index) => {
          if (!data) return data;
          let dataObj = { ...data };
          dataObj.timeTaken =
            dataObj.timeTaken + getTimeTaken(timeArray[index]);
          return dataObj;
        });
        postFixedMockData(testStateData);
      } else if (isFixedMock && !isReattempt && isTestTimeOver) {
        setShowResultAwaitedPopUp(true);
      }
    }
    if (!navigator.onLine) {
      setShowFixedMockPopup(true);
    }
    if (isTestTimeOver && !isReattempt && !isFixedMock) {
      setShowResultAwaitedPopUp(true);
    }
  }, [navigator.onLine, isTestTimeOver]);

  useEffect(() => {
    if (!navigator.onLine) {
      setShowIndicator(true);
      setIndicatorMessage(indicatorMessages?.test_window_offline);
      setTimeout(() => {
        setShowIndicator(false);
      }, [4000]);
    } else {
      if (indicatorMessage) {
        setShowIndicator(true);

        let timeArray = timeTakenArray;
        let testStateData = responseArray.map((data, index) => {
          if (!data) return data;
          let dataObj = { ...data };
          dataObj.timeTaken =
            dataObj.timeTaken + getTimeTaken(timeArray[index]);
          return dataObj;
        });
        postFixedMockData(testStateData);

        setIndicatorMessage(indicatorMessages?.test_window_online);
        setTimeout(() => {
          setShowIndicator(false);
        }, [4000]);
      }
    }
  }, [navigator?.onLine]);

  useEffect(() => {
    if (!removeEvent)
      window.addEventListener("beforeunload", onBeforeReloadOrCloseTab);
    return () => {
      window.removeEventListener("beforeunload", onBeforeReloadOrCloseTab);
    };
  }, [removeEvent, saveStateUrlForFixedMock]);
  const saveStateOnUnMount = (responseArray, currentQues) => {
    if (currentQues != undefined) {
      // update the index of current question;
      let possibleAnsKey = {
        1: "selectedAns",
        2: "fitbAns",
        3: "fitbAns",
        4: "fitbAns",
        5: "fitbAns",
        6: "dtbAns",
      };
      let index = currentQuestionIndex();
      let questionType =
        questionsArray[index]?.questionType || defaultQuestionType;
      // time taken Array
      if (prevQuesId.current != undefined) {
        let prevQuesTimeTakenArray = timeTakenArray[prevQuesId.current];
        prevQuesTimeTakenArray[prevQuesTimeTakenArray.length - 1][1] =
          Date.now();
        timeTakenArray[prevQuesId.current] = prevQuesTimeTakenArray;
      }
      let currQuesTimeArray = timeTakenArray[index];
      if (!currQuesTimeArray) currQuesTimeArray = [];
      currQuesTimeArray.push([Date.now()]);
      timeTakenArray[index] = currQuesTimeArray;
      let updatedResponseArray = [...responseArray];
      if(updatedResponseArray.length !== questionsArray.length){
        let keyMapResponseObj = responseArray.reduce((data, response) => {
          if (response) data[response.questionMapping] = response;
          return data;
        }, {});
        updatedResponseArray = Array(questionsArray.length).fill(null);
        questionsArray?.forEach((question, index) => {
          let questionId = question.qMapId;
          let savedQuesData = keyMapResponseObj[questionId];
          if (savedQuesData) {
            updatedResponseArray[index] = savedQuesData;
          }
        });
      }
     
      if (updatedResponseArray[index]) {
        updatedResponseArray[index].visited = questionStartTime;
      } else {
        updatedResponseArray[index] = {
          questionMapping: questionsArray[index].qMapId,
          [possibleAnsKey[questionType]]: questionType == 1 ? -1 : null,
          timeTaken: 0,
          visited: questionStartTime,
          questionType:
            questionsArray[index]?.questionType || defaultQuestionType,
        };
      }
      setResponseArray(updatedResponseArray);
      setQuestionStartTime(Date.now());
      syncTestData(updatedResponseArray);
      prevQuesId.current = currentQuestionIndex();
    }
  };

  useEffect(() => {
    if (isFixedMock || mode) {
      const interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, [1000]);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (!mode && !isFixedMock && navigator.onLine == false) {
      // false check to make sure its not undefined
      setModal({ type: "offline" });
    } else {
      setModal();
    }
    if (!mode && navigator.onLine && isTestTimeOver && !isFixedMock) {
      onTimerEnd();
    }
  }, [navigator.onLine]);

  useEffect(() => {
    // sync start time in case of fixed mock and initialise saveStateUrlForFixedMock
    if (isFixedMock && !freeContentFlag) {
      if (currentTime > fixedMockData.submitTime) {
        //attempt window over
        fixedMockSubmit();
        return;
      }
      if (
        fixedMockData.testState == "NOT_STARTED" &&
        localStorage.getItem(`${mappingId}Resumed`) != "true" &&
        localStorage.getItem(`${mappingId}Submitted`) != "true"
      ) {
        saveFixedMockData();
      } else {
        makeSaveStateUrlForFixedMock(fixedMockData.postSignedUrl);
      }
    }

    if (mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
      if (currentTime > fixedMockData.submitTime) {
        handleOpenModal("testSubmitted");
        setShowLiveTestModal(true);
        return;
      }
      if (
        fixedMockData.testState == "NOT_STARTED" &&
        localStorage.getItem(`${mappingId}Resumed`) != "true" &&
        localStorage.getItem(`${mappingId}Submitted`) != "true"
      ) {
        saveFixedMockData();
      } else {
        makeSaveStateUrlForFixedMock(fixedMockData.postSignedUrl);
      }
      if (
        fixedMockData.testState !== "NOT_STARTED" &&
        localStorage.getItem(`${mappingId}Submitted`) == "true"
      ) {
        handleOpenModal("testSubmitted");
        setShowLiveTestModal(true);
      }
    }
  }, []);

  // To close the test window after Buffer time is over
  useEffect(() => {
    if (mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
      let liveTestBufferTime = 15 * 60 * 1000; //  15 min buffer time
      if (
        currentTime - fixedMockData.submitTime >=
        liveTestBufferTime + currentSectionRemainingTime
      ) {
        setRemoveEvent(true);
        handleOpenModal("testSubmitted");
      }
    } else if (
      mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST &&
      nLTAdditionalData?.userStartTime
    ) {
      let liveTestBufferTime = 15 * 60 * 1000; //  15 min buffer time
      if (
        currentTime -
          (nLTAdditionalData?.userStartTime + totalTestTime * 1000) >=
        liveTestBufferTime
      ) {
        setRemoveEvent(true);
        handleOpenModal("testSubmitted");
      }
    }
  }, [currentTime]);

  useEffect(() => {
    setQuestionsArray(data[language].ques.list);
  }, [language]);
  useEffect(() => {
    let sectionsInitIndexObj = {};
    let initIndex = 0;
    let sectionTimeArray = [];
    data?.meta?.sections?.map((section, index) => {
      sectionsInitIndexObj[index] = {
        initIndex: initIndex,
        sectionInfo: section,
      };
      if (section.secTime) {
        sectionTimeArray.push(section.secTime);
      }
      initIndex += section.secTotalq;
    });
    setTimeLimitType();
    setSectionStartIndexObj(sectionsInitIndexObj);
    setTotalSectionCnt(data?.meta?.sections?.length);
    if (prevData && data) {
      setTestInitialState();
    } else {
      // when new Test
      if (
        (isFixedMock || mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) &&
        !freeContentFlag
      ) {
        setRemainingTimeFixedMock(); // can be possible to reach here in a resumed fixed mock
      } else if (mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST) {
        setRemainingTimeNonLiveTest();
      } else {
        if (isTimelimitSectionWise()) {
          setTimeArray(sectionTimeArray);
        } else {
          setTimeArray([data.meta.time]);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (userStartTimeFixedMock) {
      setRemainingTimeFixedMock();
    }
  }, [userStartTimeFixedMock]);

  useEffect(() => {
    if (isFixedMock) return;
    props.getSpecialTests();
    return function () {
      if (timeOutId.current) window.clearTimeout(timeOutId.current);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isTestPortalOpened", "yes");
  }, []);

  useEffect(() => {
    const { current } = tcAnswerRef;
    if (current) {
      setHasVerticalScrollbar(current.scrollHeight > current.clientHeight);
    }
  }, [tcAnswerRef.current, currentQues]);

  const checkIsTestAlreadySubmitted = (e) => {
    try {
      let submittedKeys =
        JSON.parse(localStorage.getItem("submittedTest")) || {};
      return Promise.resolve(submittedKeys[`${packageId}_${mappingId}`]);
    } catch (error) {
      console.log(
        "ERRO while getting submmited test from localStorage:-",
        error
      );
    }
  };

  useEffect(() => {
    if (!isFixedMock || !isTimelimitSectionWise()) {
      saveStateOnUnMount(responseArray, currentQues);
    }
  }, [currentQues, selectedSection]);

  useEffect(() => {
    setOnLastQuesOfLastSection(
      selectedSection === totalSectionCnt - 1 &&
        currentQues ===
          sectionStartIndexObj[selectedSection]?.sectionInfo?.secTotalq - 1
    );
  }, [selectedSection, totalSectionCnt, currentQues, sectionStartIndexObj]);

  useEffect(() => {
    setTotalSectionCnt(Object.keys(sectionStartIndexObj).length);
  }, [sectionStartIndexObj]);

  useEffect(() => {
    if (
      (isFixedMock || mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) &&
      isTimelimitSectionWise() &&
      currentSectionRemainingTimeRef.current === 0 &&
      currentSectionRemainingTime > 0
    ) {
      // when currentSectionRemainingTime switches from zero to non zero
      saveStateOnUnMount(responseArray, currentQues);
    }
    currentSectionRemainingTimeRef.current = currentSectionRemainingTime;
  }, [currentSectionRemainingTime]);

  useEffect(() => {
    if (
      isFixedMock &&
      isTimelimitSectionWise() &&
      currentSectionRemainingTimeRef.current !== 0
    ) {
      saveStateOnUnMount(responseArray, currentQues);
    }
  }, [currentQues, selectedSection]);

  useEffect(() => {
    if (
      isFixedMock &&
      isTimelimitSectionWise() &&
      selectedSection !== 0 &&
      currentSectionRemainingTime ===
        data?.meta?.sections?.[selectedSection]?.secTime
    ) {
      saveStateOnUnMount(responseArray, currentQues);
    }
  }, [currentQues, selectedSection, currentSectionRemainingTime]);

  useEffect(() => {
    if (
      mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST &&
      saveStateUrlForFixedMock &&
      saveStateUrlForFixedMock?.url
    ) {
      let timeArray = timeTakenArray;
      let testStateData = responseArray.map((data, index) => {
        if (!data) return data;
        let dataObj = { ...data };
        dataObj.timeTaken = dataObj.timeTaken + getTimeTaken(timeArray[index]);
        return dataObj;
      });
      postFixedMockData(testStateData);
    }
  }, [saveStateUrlForFixedMock]);

  const closeModal = () => {
    if (modal?.type == "submitted" || modal?.type == "alreadySubmit") return;
    // once submitted should not allow to close modal
    setModal();
  };
  //For Pausing the Test

  const closeWindow = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  const saveFixedMockData = () => {
    let data = { packageId: packageId, mappingId: mappingId };
    fetch(`${TEST_SERIES_URL}/testseries/saveFixedMockData`, "POST", data)
      .then((res) => {
        if (res.success) {
          makeSaveStateUrlForFixedMock(res.data.postSignedUrl);
          setUserStartTimeFixedMock(res.data.userStartTime);
          localStorage.setItem(`${mappingId}Resumed`, "true");
        } else {
          alert("something went wrong");
        }
      })
      .catch((err) => {
        alert("something went wrong: ", err);
      });
  };

  const getModalButtonAction = (modalType) => {
    let moepayload = getMoengagePayload() || {};
    if (navigator?.onLine) {
      if (modalType === "submitTest") return submit;
      else if (modalType === "noInternetConnection")
        return () => {
          hitMoengageForLiveTest(
            "test_window",
            "retry_submittest_clicked",
            moepayload
          );
          submit();
        };
      else if (navigator.onLine && modalType === "testSubmitted") {
        return () => {
          setRemoveEvent(true);
          window.close();
        };
      }
    } else {
      if (modalType === "submitTest")
        return () => handleOpenModal("noInternetConnection");
      else if (modalType === "noInternetConnection")
        return () => {
          hitMoengageForLiveTest(
            "test_window",
            "retry_submittest_clicked",
            moepayload
          );
          submit();
        };
    }
  };

  const getMoengagePayload = () => {
    let moepayload = {};
    moepayload.action = "finished";
    moepayload.index = BLANK_IDENTIFIER;
    moepayload.content_subject = BLANK_IDENTIFIER;
    moepayload.rating = BLANK_IDENTIFIER;
    moepayload.content_url = getPdpUrl("TEST_SERIES", mappingId, "");
    moepayload.exam_category = BLANK_IDENTIFIER;
    moepayload.user_exam_category_selected = WEB_IDENTIFIER;
    moepayload.exam = BLANK_IDENTIFIER;
    moepayload.language = ENGLISH_IDENTIFIER;
    moepayload.content_language = language;
    moepayload.content_id = mappingId;
    moepayload.content_title = title;
    moepayload.package_status = "Paid";
    moepayload.package_id = packageId;
    moepayload.package_title = BLANK_IDENTIFIER;
    moepayload.package_type = BLANK_IDENTIFIER;
    moepayload.package_purchased = BLANK_IDENTIFIER;
    moepayload.deep_link = BLANK_IDENTIFIER;
    moepayload.test_id = mappingId;
    moepayload.test_type = "live";
    return moepayload;
  };

  const handleOpenModal = (modalType) => {
    let modalBody = { ...liveTestModalData?.[modalType] };
    if (modalType === "testSubmitted") {
      modalBody.description = `${modalBody.description} ${
        userDetails?.name || ""
      }!`;
      modalBody.secondaryDescription = `Your test is submitted successfully. ${
        mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST
          ? `You can check your result in ${
              getStateUpdateTime(fixedMockData?.fixedMockResultTime) || ""
            }`
          : ""
      }`;
    }
    setLiveTestModalBody(modalBody);
  };

  const setTestInitialState = () => {
    // sets previous state when test is resumed;
    let previousStateObj = prevData.reduce((data, response) => {
      if (response) data[response.questionMapping] = response;
      return data;
    }, {});
    // ((a, b) => a - b)
    let resposeSortedByVisited = prevData
      .filter((data) => data)
      .sort((q1, q2) => q2.visited - q1.visited);
    let lastVisitedQuestion = 0;
    let lastVisitedSection = 0;
    if (resposeSortedByVisited[0]) {
      lastVisitedQuestion = questionsArray.findIndex(
        (question) =>
          question.qMapId == resposeSortedByVisited[0].questionMapping
      );
      // let lastVisitedQuestionInSection = lastVisitedQuestion

      let sectionLastQuestionIndex = -1;
      let lastvisitedQuestionIndex = lastVisitedQuestion;
      lastVisitedSection = data.meta.sections.findIndex((section) => {
        sectionLastQuestionIndex += section.secTotalq;
        if (lastVisitedQuestion <= sectionLastQuestionIndex) return true;
        else {
          lastvisitedQuestionIndex -= section.secTotalq;
          return false;
        }
        // return lastVisitedQuestion <= sectionLastQuestionIndex;
      });
      lastVisitedQuestion = lastvisitedQuestionIndex;
    }
    //
    // let respo

    let sectionwiseTimeSpent = data.meta.sections.reduce((data, section) => {
      data[section.secSubject] = 0;
      return data;
    }, {});
    let questionList = data[language].ques.list;
    let previousResponseStatusArray = Array(questionList.length).fill(0);
    let previousResponseArray = Array(questionList.length).fill(null);
    questionList.forEach((question, index) => {
      let questionId = question.qMapId;
      let savedQuesData = previousStateObj[questionId];
      if (savedQuesData) {
        // update Time spent
        previousResponseArray[index] = savedQuesData;
        sectionwiseTimeSpent[question.subject] += Math.floor(
          +savedQuesData.timeTaken / 1000
        );
        // update response status;
        previousResponseStatusArray[index] =
          savedQuesData?.selectedAns == -1 && !savedQuesData?.fitbAns && !savedQuesData?.dtbAns ? 1 : 2;
        if (savedQuesData.status) {
          previousResponseStatusArray[index] = savedQuesData.status;
        }
      }
    });
    setResponseStatusArray(previousResponseStatusArray);
    setResponseArray(previousResponseArray);
    if (
      (isFixedMock || mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) &&
      !freeContentFlag
    ) {
      setRemainingTimeFixedMock();
    } else if (mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST) {
      let remainingTimeArray = data.meta.sections.map((section) => {
        let timeSpent = sectionwiseTimeSpent[section.secSubject];
        return section.secTime - timeSpent;
      });
      setRemainingTimeNonLiveTest(remainingTimeArray);
    } else {
      let remainingTimeArray = data.meta.sections.map((section) => {
        // let timeSpent = Math.round(
        //   sectionwiseTimeSpent[section.secSubject] / 1000
        // );
        // we are updating it in seconds
        let timeSpent = sectionwiseTimeSpent[section.secSubject];
        return section.secTime - timeSpent;
      });
      if (isTimelimitSectionWise()) {
        setTimeArray(remainingTimeArray);
      } else {
        let remainingTime =
          data.meta.time -
          Object.values(sectionwiseTimeSpent).reduce(
            (tot, val) => val + tot,
            0
          );
        setTimeArray([remainingTime]);
      }
    }

    setSelectedSection(lastVisitedSection);
    setQuestionStartTime(Date.now());
    setCurrentQues(lastVisitedQuestion);
  };

  const getRemainingTime = () => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - userStartTimeFixedMock;

    const remainingTime = Math.min(
      data.meta.time * 1000 - timeElapsed,
      fixedMockData.submitTime - currentTime
    );
    if (remainingTime < 0) {
      return 0;
    }
    return remainingTime;
  };

  // let packageId = data.fixedMockPkg;

  const setRemainingTimeFixedMock = () => {
    let remainingTime = fixedMockData.fpmUserRemainingTime
      ? Math.floor(fixedMockData.fpmUserRemainingTime)
      : Math.floor(getRemainingTime() / 1000);
    if (isTimelimitSectionWise()) {
      let remainingTimeArray = [];

      let timeUpToThisSection = 0;
      const timeSpent = data.meta.time - remainingTime;

      if (data.meta.sections.length == 1) {
        setTimeArray([remainingTime]);
      } else {
        for (let i = 0; i < data.meta.sections.length; i++) {
          let section = data.meta.sections[i];
          timeUpToThisSection += section.secTime;
          if (timeSpent > timeUpToThisSection) {
            // section has been passed
            remainingTimeArray[i] = 0;
          } else if (timeUpToThisSection - timeSpent < section.secTime) {
            // on current section
            remainingTimeArray[i] = timeUpToThisSection - timeSpent;
          } else {
            remainingTimeArray[i] = section.secTime;
          }
        }
        setTimeArray(remainingTimeArray);
      }
    } else {
      setTimeArray([remainingTime]);
    }
  };

  const setRemainingTimeNonLiveTest = (secwiseTimeRemArr = []) => {
    // let passedTime = Date.now() - nLTAdditionalData?.userStartTime;
    let remainingTime = nLTAdditionalData?.userStartTime
      ? (nLTAdditionalData?.userStartTime + totalTestTime * 1000 - Date.now()) /
        1000
      : 0;
    if (isTimelimitSectionWise()) {
      let remainingTimeArray = [];

      let timeUpToThisSection = 0;
      const timeSpent = data.meta.time - remainingTime;

      if (data.meta.sections.length == 1) {
        setTimeArray([remainingTime]);
      } else {

        if(secwiseTimeRemArr?.length === data.meta.sections.length) {

          remainingTimeArray = new Array(data.meta.sections.length).fill(data.meta.sections[0].secTime);

          timeUpToThisSection = data.meta.time;
          let maximumTimeUptoCurrentSection = timeUpToThisSection;

          for (let i = data.meta.sections.length - 1; i >= 0; i--) {

            let section = data.meta.sections[i];
            let currentSectionStartTime = maximumTimeUptoCurrentSection - section.secTime;
        
            if (secwiseTimeRemArr[i] !== section.secTime) {
                // Set all previous elements to 0

                if(timeSpent > maximumTimeUptoCurrentSection) {
                  for (let j = i; j >= 0; j--) {
                    remainingTimeArray[j] = 0;
                  }
                  break;
                }
                else if (timeSpent >= currentSectionStartTime) {
                  remainingTimeArray[i] = Math.min( maximumTimeUptoCurrentSection - timeSpent, secwiseTimeRemArr[i], section.secTime );
                  for (let j = i - 1; j >= 0; j--) {
                    remainingTimeArray[j] = 0;
                  }
                  break;
                }
                else {
                  remainingTimeArray[i] = secwiseTimeRemArr[i];
                  for (let j = i - 1; j >= 0; j--) {
                      remainingTimeArray[j] = 0;
                  }
                  break;
                }
            } else {
                // Set the value equal to secwiseTimeRemArr[i]
                if(timeSpent >= maximumTimeUptoCurrentSection) {
                  for (let j = i; j >= 0; j--) {
                    remainingTimeArray[j] = 0;
                  }
                  break;
                } else if (timeSpent >= currentSectionStartTime) {
                  remainingTimeArray[i] = Math.min( maximumTimeUptoCurrentSection - timeSpent, secwiseTimeRemArr[i], section.secTime );
                  for (let j = i - 1; j >= 0; j--) {
                    remainingTimeArray[j] = 0;
                  }
                  break;
                }
                else {
                  remainingTimeArray[i] = section.secTime;
                }
            }
            maximumTimeUptoCurrentSection -= section.secTime;
          }
          setTimeArray(remainingTimeArray);
        }
        else {
          for (let i = 0; i < data.meta.sections.length; i++) {
            let section = data.meta.sections[i];
            timeUpToThisSection += section.secTime;
            if (timeSpent > timeUpToThisSection) {
              // section has been passed
              remainingTimeArray[i] = 0;
            } else if (timeUpToThisSection - timeSpent < section.secTime) {
              // on current section
              remainingTimeArray[i] = timeUpToThisSection - timeSpent;
            } else {
              remainingTimeArray[i] = section.secTime;
            }
          }
          setTimeArray(remainingTimeArray);
        }
      }
    } else {
      setTimeArray([remainingTime]);
    }
  };

  const setTimeLimitType = () => {
    if (data.meta.sections[0].secTime) {
      setSectionWiseTimeLimit(true);
    }
  };
  const isTimelimitSectionWise = () => {
    return !!data.meta.sections[0].secTime;
  };

  const getTimeTaken = (quesTimeTakenArray) => {
    if (!quesTimeTakenArray) return 0;
    return quesTimeTakenArray.reduce((totTime, data) => {
      if (data[0] && data[1]) {
        totTime += data[1] - data[0];
      } else {
        totTime += Date.now() - data[0];
      }
      return totTime;
    }, 0);
  };
  const syncTestData = (responseData, timeTakenForQuestionArray) => {
    if (modal?.type === "alreadySubmit") {
      return;
    }
    let timeArray = timeTakenForQuestionArray || timeTakenArray;
    let testStateData = responseData.map((data, index) => {
      if (!data) return data;
      let dataObj = { ...data };
      dataObj.timeTaken = dataObj.timeTaken + getTimeTaken(timeArray[index]);
      return dataObj;
    });
    let payload = {
      packageId: packageId,
      mappingId: mappingId,
      testState: JSON.stringify(testStateData),
    };
    if (freeContentFlag) return;
    if (isFixedMock || mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
      postFixedMockData(testStateData);
    } else {
      checkIsTestAlreadySubmitted().then((res) => {
        if (res) {
          if (!mode) setModal({ type: "alreadySubmit" });
          return;
        }
        fetch(
          `${STOREFRONT_URI}/api/v1/test-series/save-state`,
          "POST",
          payload
        ).then(
          (res) => {
            // setIsCouponLoading(false);
          },
          (err) => {}
        );
      });
    }
  };
  const postFixedMockData = (testStateData) => {
    if (Object.keys(saveStateUrlForFixedMock).length == 0) {
      return;
    }
    if (localStorage.getItem(`${mappingId}Submitted`) == "true") {
      if (!mode) {
        fixedMockSubmit();
        return;
      }
    }
    const url = saveStateUrlForFixedMock.url;
    const formData = new FormData();
    for (const pair of saveStateUrlForFixedMock.formData.entries()) {
      formData.append(pair[0], pair[1]);
    }

    if (isTimelimitSectionWise()) {
      let totalUserRemainingTime = currentSectionRemainingTime;
      if (skipSectionFlag) {
        totalUserRemainingTime += data?.meta?.sections?.[selectedSection]?.secTime;
        totalUserRemainingTime -= currentSectionRemainingTime;
        setSkipSectionFlag(false);
      }
      for (var i = selectedSection + 1; i < data?.meta?.sections?.length; i++) {
        totalUserRemainingTime += data?.meta?.sections?.[i]?.secTime;
      }
      localStorage.setItem(`${mappingId}RemainingTime`, totalUserRemainingTime);
      localStorage.setItem(`${mappingId}whichPointOfTime`, Date.now());
      const formatedTestStateData = [...testStateData];
      for (let i = 0; i < formatedTestStateData.length; i++) {
        const answer = formatedTestStateData[i];
        if (answer !== null) {
          answer.userRemainingTime = totalUserRemainingTime;
          answer.currentTime = Date.now();
          break;
        }
      }
      formData.append("file", JSON.stringify(formatedTestStateData));
    } else {
      formData.append("file", JSON.stringify(testStateData));
    }
    const config = {
      method: "post",
      url: url,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    };
    Axios(config)
      .then((res) => {})
      .catch((err) => {});
  };

  const makeSaveStateUrlForFixedMock = (postSignedUrl) => {
    if (!postSignedUrl) return;
    const url = postSignedUrl.url;
    const fields = postSignedUrl.fields;
    const formData = new FormData();
    formData.append("key", fields["key"]);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-security-token", fields["x-amz-security-token"]);
    formData.append("policy", fields["policy"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    setSaveStateUrlForFixedMock({ url: url, formData: formData });
  };

  const submitTest = (confirmed) => {
    if (validValueStatus?.dirty) {
      alert(validValueStatus?.message);
      return;
    }
    if (mode) {
      let moepayload = getMoengagePayload() || {};
      if (navigator.onLine) {
        let confirmSubmit = false;
        if (
          (fixedMockData?.fpmUserRemainingTime &&
            fixedMockData?.fpmUserRemainingTime <= 0) ||
          currentSectionRemainingTime <= 1
        )
          confirmSubmit = true;
        else confirmSubmit = confirm(SUBMISSION_CONFIRMATION_MESSAGE);
        if (confirmSubmit) {
          if (mode && !testSubmitMoengageHit) {
            hitMoengageForLiveTest("test_window", "submittest_clicked", moepayload);
            setTestSubmitMoengageHit(true);
          }
          setSubmitButtonDisabled(true);
          submit();
        }
      } else {
        let confirmSubmit = false;
        if (
          (fixedMockData?.fpmUserRemainingTime &&
            fixedMockData?.fpmUserRemainingTime <= 0) ||
          currentSectionRemainingTime <= 1
        )
          confirmSubmit = true;
        else confirmSubmit = confirm(SUBMISSION_CONFIRMATION_MESSAGE);
        if (confirmSubmit) {
          setRemoveEvent(true);
          handleOpenModal("noInternetConnection");
          localStorage.setItem(`${mappingId}Submitted`, "true");
          setShowLiveTestModal(true);
        }
      }
    } else if (isFixedMock && navigator.onLine === false) {
      setFixedMockPopUpText({
        title: "No Internet",
        desc: "Please turn on the internet to submit the test",
        secCTA: "",
        primaryCTA: "RETRY",
      });
      setShowFixedMockPopup(true);
      return;
    } else if (isFixedMock && navigator.onLine) {
      if (confirmed == true) {
        submit(true);
      } else {
        let confirmSubmit = confirm(SUBMISSION_CONFIRMATION_MESSAGE);
        if (confirmSubmit) {
          setSubmitButtonDisabled(true);
          submit();
        }
      }
    } else {
      if (confirmed == true) {
        submit(true);
      } else {
        let confirmSubmit = confirm(SUBMISSION_CONFIRMATION_MESSAGE);
        if (confirmSubmit) {
          setSubmitButtonDisabled(true);
          submit();
        }
      }
    }
  };
  const submit = (timeEnd) => {
    setRemoveEvent(true);
    if (modal?.type === "alreadySubmit") {
      return;
    }
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    let currentStatus = 2;
    if (!isCurrentQuesAnswered()) currentStatus = 1;
    saveCurrentQuesData(currentStatus);
    updateResponseStatus(currentIndex, currentStatus);

    let moepayload = getMoengagePayload() || {};
    if (typeof Moengage !== "undefined" && !testSubmitMoengageHit)
      Moengage.track_event("test", getMoengageMetadata(moepayload));
      setTestSubmitMoengageHit(true);
    //
    let timeArray = timeTakenArray;
    let testStateData = responseArray.map((data, index) => {
      if (!data) return data;
      let dataObj = { ...data };
      dataObj.timeTaken = dataObj.timeTaken + getTimeTaken(timeArray[index]);
      if (data.tempS3Url) {
        dataObj.dtbAns = data.tempS3Url;
      }
      return dataObj;
    });
    // some code to trimm off extra spill of seconds
    if (sectionwiseTimeLimit) {
      totalTimeArray.forEach((totalTime, sectionIndex) => {
        let startIndex = sectionStartIndexObj[sectionIndex].initIndex;
        let endIndex =
          startIndex +
          sectionStartIndexObj[selectedSection].sectionInfo.secTotalq;
        let responseList = testStateData.slice(startIndex, endIndex);

        let totalTimeTaken = responseList.reduce((totTime, value) => {
          if (!value) return totTime;
          return value.timeTaken + totTime;
        }, 0);
        let totalTestTime = totalTime * 1000;

        let extraTime = totalTimeTaken - totalTestTime;
        if (extraTime > 0) {
          responseList[0].timeTaken -= extraTime;
        }
      });
    } else {
      let totalTimeTaken = testStateData.reduce((totTime, value) => {
        if (!value) return totTime;
        return value.timeTaken + totTime;
      }, 0);
      let totalTestTime = totalTimeArray[0] * 1000;
      let extraTime = totalTimeTaken - totalTestTime;
      if (extraTime > 0) {
        testStateData[0].timeTaken -= extraTime;
      }
    }
    if (timeOutId.current) window.clearTimeout(timeOutId.current);

    // ******test analysis calculations on reattempt*****
    if (freeContentFlag) {
      const createResponseObjectFromArray = (resArr) => {
        return resArr.reduce((data, res) => {
          data[res.questionMapping] = res;
          return data;
        }, {});
      };
      const testCalcData = getTestAnalysisResultForReattempt(
        questionsArray,
        createResponseObjectFromArray(
          testStateData.filter((val) => val != null)
        ),
        data
      );
      localStorage.setItem("testCalcData", JSON.stringify(testCalcData));
      setModal({ type: "submitted" });
      return;
    }
    if (isFixedMock || mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
      if (
        fixedMockData?.testState === "COMPLETED" ||
        fixedMockData.fpmUserRemainingTime <= 0 ||
        localStorage.getItem(`${mappingId}Submitted`) == "true"
      ) {
        // can not submit test if already completed
        if (!mode) fixedMockSubmit();
        else {
          postFixedMockData(testStateData);
          if (navigator?.onLine) {
            handleOpenModal("testSubmitted");
            setShowLiveTestModal(true);
          } else return;
        }
      }
      let fixedMockSubmitTime =
        fixedMockData?.submitTime || fixedMockData?.fixedMockSubmitTime;
      if (fixedMockSubmitTime - Date.now() < 300000) {
        postFixedMockData(testStateData);
        localStorage.setItem(`${mappingId}Submitted`, "true");
        if (!mode) fixedMockSubmit();
        else {
          handleOpenModal("testSubmitted");
          setShowLiveTestModal(true);
        }
      } else {
        const url = saveStateUrlForFixedMock.url;
        const formData = new FormData();
        for (const pair of saveStateUrlForFixedMock.formData.entries()) {
          formData.append(pair[0], pair[1]);
        }
        formData.append("file", JSON.stringify(testStateData));
        const config = {
          method: "post",
          url: url,
          headers: {
            "content-type": "multipart/form-data",
          },
          data: formData,
        };
        Axios(config)
          .then((res) => {
            const data = {
              mappingId: mappingId,
              packageId: packageId,
              testState: testStateData.filter((val) => val != null),
            };
            fetch(`${TEST_SERIES_URL}/testseries/submitFixedMock`, "POST", data)
              .then((res) => {
                if (res.success) {
                  if (!mode) fixedMockSubmit();
                  else {
                    handleOpenModal("testSubmitted");
                    setShowLiveTestModal(true);
                  }
                  localStorage.setItem(`${mappingId}Submitted`, "true");
                }
              })
              .catch((err) => {
                alert("Some error occured! Please try again.");
                setSubmitButtonDisabled(false);
              });
          })
          .catch((err) => {});
      }
    } else {
      checkIsTestAlreadySubmitted().then((res) => {
        if (res) {
          if (!mode) setModal({ type: "alreadySubmit" });
          return;
        }
        fetch(`${STOREFRONT_URI}/api/v1/test-series/save-state`, "POST", {
          packageId: packageId,
          mappingId: mappingId,
          testState: JSON.stringify(testStateData),
        }).then(
          (res) => {
            fetch(`${STOREFRONT_URI}/api/v1/test-series/submit`, "POST", {
              packageId: packageId,
              mappingId: mappingId,
              testState: testStateData.filter((val) => val != null),
            }).then(
              (res) => {
                localStorage.setItem(
                  "submittedTest",
                  JSON.stringify({
                    [`${packageId}_${mappingId}`]: true,
                  })
                );
                if (mode) {
                  handleOpenModal("testSubmitted");
                  setShowLiveTestModal(true);
                } else {
                  setModal({ type: "submitted" });
                }
              },
              (err) => {
                setSubmitButtonDisabled(false);
              }
            );
          },
          (err) => {}
        );
      });
    }
  };

  const fixedMockSubmit = () => {
    // setModal({ type: "submitted" });
    setShowResultAwaitedPopUp(true);
    setRemoveEvent(true);
  };

  const sectionChange = (event) => {
    setSelectedSection(+event.target.id);
    setQuestionStartTime(Date.now());
    setCurrentQues(0);
  };

  const currentQuestionIndex = () => {
    let currentSectionStartIndex = sectionStartIndexObj[selectedSection]
      ? sectionStartIndexObj[selectedSection].initIndex
      : 0;
    let currentIndex = currentSectionStartIndex + currentQues;
    return currentIndex;
  };
  if (!sectionStartIndexObj[selectedSection]) return " ";
  let currentSectionStartIndex =
    sectionStartIndexObj[selectedSection].initIndex;
  let currentSectionEndIndex =
    currentSectionStartIndex +
    sectionStartIndexObj[selectedSection].sectionInfo.secTotalq;
  let currentQuestionList = questionsArray.slice(
    currentSectionStartIndex,
    currentSectionEndIndex
  );
  let currentResponsesList = responseArray.slice(
    currentSectionStartIndex,
    currentSectionEndIndex
  );
  let currentResponseStatusList = responseStatusArray.slice(
    currentSectionStartIndex,
    currentSectionEndIndex
  );

  const updateResponse = (arg) => {
    /** possible qType is 1,2,3,4,5,6
     * questionType representation
     * 1: MCQ
     * 2: FITB
     * 3: MCMC
     * 4: DF
     * 5: DFR
     * 6: DTB
     */
    let {
      value = "",
      qType,
      isSaveState = true,
      tempS3UrlForDtb = "",
      status = 0,
      clearDtbS3Link = false,
    } = arg;
    let questionType = qType;
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    let responseObj = {};
    if (responseArray[currentIndex] === null) {
      responseObj = {
        questionMapping: questionsArray[currentIndex].qMapId,
        questionType: questionType,
        // timeTaken: timeSpent,
        visited: questionStartTime,
        status: status,
      };
    } else {
      responseObj = { ...responseArray[currentIndex] };
      responseObj.questionType = questionType;
      responseObj.visited = questionStartTime;
      responseObj.status = status;
    }
    if (questionType == 1) {
      // MCQ
      responseObj.selectedAns = value != "" ? value : -1;
    }
    // FITB
    else if (questionType == 2) {
      responseObj.fitbAns = value ? value : null;
      delete responseObj.selectedAns;
    }
    // MCMC
    else if (questionType == 3) {
      responseObj.fitbAns = value ? value : null;
    }
    // DF, and DFR
    else if (questionType == 4 || questionType == 5) {
      responseObj.fitbAns = value ? value : null;
      delete responseObj.selectedAns;
    }
    // DTB
    else if (questionType == 6) {
      responseObj.dtbAns = value ? value : null;
      if (tempS3UrlForDtb) {
        responseObj.tempS3Url = tempS3UrlForDtb;
        responseObj.s3Link = true;
      }
      // to reset to default response object when DTB clearResponse.
      if (clearDtbS3Link) {
        delete responseObj?.tempS3Url;
        delete responseObj?.s3Link;
      }
      delete responseObj?.selectedAns;
      delete responseObj?.fitbAns;
    }
    let updatedResponseArray = [...responseArray];
    updatedResponseArray[currentSectionStartIndex + currentQues] = responseObj;
    if (
      (questionType == 1 ||
        questionType == 3 ||
        questionType == 4 ||
        questionType == 5) &&
      isSaveState
    ) {
      // syncTestData only for MCQ,MCMC,DF,DFR questionType
      syncTestData(updatedResponseArray);
    }
    setResponseArray(updatedResponseArray);
    setQuestionStartTime(Date.now());
  };
  //true if answered otherwise false
  const isCurrentQuesAnswered = () => {
    let isAnswered;
    // 0(notAttempt),1(unanswered),2(anwered),3(markforReview),4(answerdandMarkForReview)
    let possibleAnsKey = {
      1: "selectedAns",
      2: "fitbAns",
      3: "fitbAns",
      4: "fitbAns",
      5: "fitbAns",
      6: "dtbAns",
    };
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    const responseObj = responseArray[currentIndex] || {};
    let resAns =
      responseObj[
        possibleAnsKey[
          currentQuestionList[currentQues]?.questionType || defaultQuestionType
        ]
      ];
    // when user answer
    let qType =
      currentQuestionList[currentQues]?.questionType || defaultQuestionType;

    // FITB,DF, DFR and DTB
    if (qType == 2 || qType == 4 || qType == 5 || qType == 6) {
      if (resAns) {
        isAnswered = true;
      } else {
        // when user not answer
        isAnswered = false;
      }
    }
    // MCQ
    if (qType == 1) {
      // when user answer
      if (resAns && resAns != -1) {
        isAnswered = true;
      }
      // when user not answer
      else if (resAns == -1) {
        isAnswered = false;
      }
    }
    // MCMC
    if (qType == 3) {
      // when user answer
      if (resAns != -1 && resAns !== null) {
        isAnswered = true;
      }
      // when user not answer
      else {
        isAnswered = false;
      }
    }
    return isAnswered;
  };
  const updateResponseStatus = (quesIndex, status) => {
    /**
     * status value info ->
     * 0(notAttempt),1(unanswered),2(anwered),3(markforReview),4(answerdandMarkForReview)
     */
    let updatedResponseArray = [...responseStatusArray];
    if (updatedResponseArray[quesIndex] == 3 && status == 2) {
      updatedResponseArray[quesIndex] = 2;
    } else if (
      [updatedResponseArray[quesIndex], status].includes(2) &&
      [updatedResponseArray[quesIndex], status].includes(3)
    ) {
      updatedResponseArray[quesIndex] = 4;
    } else {
      updatedResponseArray[quesIndex] = status;
    }
    setResponseStatusArray(updatedResponseArray);
  };

  const selectQuestion = (event) => {
    setShowCalci(false);
    if (validValueStatus?.dirty) {
      alert(validValueStatus?.message);
      return;
    }
    let nextQuesId = +event.target.id;

    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    let currentStatus = responseStatusArray[currentIndex];
    const responseObj = responseArray[currentIndex];
    if (currentStatus == 0) {
      currentStatus = 1;
    }
    let possibleAnsKey = {
      1: "selectedAns",
      2: "fitbAns",
      3: "fitbAns",
      4: "fitbAns",
      5: "fitbAns",
      6: "dtbAns",
    };
    let qType =
      currentQuestionList[currentQues]?.questionType || defaultQuestionType;
    const resAns = responseObj[possibleAnsKey[qType]];
    if (qType == 1 && responseObj && resAns != -1) {
      if (responseStatusArray[currentIndex] == 3) {
        currentStatus = 4;
      } else if (resAns != -1) {
        currentStatus = 2;
      }
    } else if (qType == 3 && responseObj && resAns != -1 && resAns !== null) {
      if (responseStatusArray[currentIndex] == 3) {
        currentStatus = 4;
      } else if (resAns != -1 && resAns !== null) {
        currentStatus = 2;
      }
    } else if (
      (qType == 2 || qType == 4 || qType == 5 || qType == 6) &&
      responseObj &&
      resAns
    ) {
      if (responseStatusArray[currentIndex] == 3) {
        currentStatus = 4;
      } else if (resAns) {
        currentStatus = 2;
      }
    } else {
      currentStatus = 1;
    }
    saveCurrentQuesData(currentStatus);
    updateResponseStatus(currentIndex, currentStatus);
    setQuestionStartTime(Date.now());
    setCurrentQues(nextQuesId);
  };
  const uploadDtbAnswer = async (dtbAns) => {
    try {
      const res = await fetch(
        `${TEST_SERIES_URL}/generate/signed/url`,
        "POST",
        {
          bucket: "DTB_TS_DOC",
          fileSize: "120000",
        }
      );
      let { signedUrl, tempS3Url } = res.data;
      // update dtbAnswer response with tempS3Url
      updateResponse({
        value: dtbAns,
        qType: 6, //dtb
        isSaveState: true,
        tempS3UrlForDtb: tempS3Url,
      });
      //var str = 'https://testseries-answers.s3.amazonaws.com/qa/1668166014121.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221111T112654Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Credential=AKIAXES3NEURVVS7AF7Z%2F20221111%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=d468318add4066488c278c5e0781cb95bd3db7e89f60911922601af921e2d5f8';
      // var value = str.match(/X-Amz-Expires=(\d+)/i);
      //TODO : implement expire signedUrl
      if (signedUrl) {
        await fetch(signedUrl, "PUT", dtbAns, null, null, null, true);
        return Promise.resolve(true);
      }
    } catch (error) {
      return Promise.reject(false);
    }
  };
  const saveAndNext = () => {
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    let currentStatus = 2;
    if (!isCurrentQuesAnswered()) currentStatus = 1;
    saveCurrentQuesData(currentStatus);
    // updateGlobalStatus(currentStatus);
    updateResponseStatus(currentIndex, currentStatus);

    /**
     * DTB(questionType = 6)
     * if dtbAnwer is less than the 2000 characters,then no need to call s3 url link.
     * if dtbAnwer is more than the 2000 characters
     * ********* dtbAnswer more than 2000 characters  ->
     * Step1 : call https://testseriesapi-qa.adda247.com/generate/signed/url to get signed url
     * Step2 : - PUT request to signedUrl and send dtbAnswer in body.
     *         - set testResponse dtbAns with tempS3Url and s3Link:true corresponding to questionMapping.
     *  ********************************
     */
    if (
      currentResponsesList[currentQues] &&
      currentQuestionList[currentQues]?.questionType == 6 &&
      currentResponsesList[currentQues]?.dtbAns?.length > 2000 &&
      !freeContentFlag
    ) {
      uploadDtbAnswer(currentResponsesList[currentQues]?.dtbAns).then((res) =>
        goToQuestion(currentQues + 1)
      );
    } else {
      goToQuestion(currentQues + 1);
    }
  };
  const saveCurrentQuesData = (currentStatus) => {
    // this function updates the timestamp and visited of cuurent QUes;
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    if (responseArray[currentIndex]) {
      setQuestionStartTime(Date.now());

      let responseObj = responseArray[currentIndex];
      let possibleAnsKey = {
        1: "selectedAns",
        2: "fitbAns",
        3: "fitbAns",
        4: "fitbAns",
        5: "fitbAns",
        6: "dtbAns",
      };
      let value =
        responseObj[
          possibleAnsKey[
            currentQuestionList[currentQues]?.questionType ||
              defaultQuestionType
          ]
        ];
      updateResponse({
        value: value,
        qType:
          currentQuestionList[currentQues]?.questionType || defaultQuestionType,
        isSaveState: false,
        status: currentStatus,
      });
    }
  };
  const goToQuestion = (questionIndex) => {
    setShowCalci(false);
    if (
      questionIndex ==
      sectionStartIndexObj[selectedSection]?.sectionInfo?.secTotalq // on last question
    ) {
      if (!sectionwiseTimeLimit) {
        if (selectedSection + 1 != totalSectionCnt) {
          setQuestionStartTime(Date.now());
          setCurrentQues(0);
          setSelectedSection(selectedSection + 1);
        }
      } else {
        if (selectedSection != totalSectionCnt - 1) {
          showSkipSection();
        }
      }
    } else {
      setQuestionStartTime(Date.now());

      setCurrentQues(questionIndex);
    }
  };

  const markForReviewAndNext = () => {
    let currentStatus = 3;
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    if (isCurrentQuesAnswered()) {
      // answerd + markForReview
      currentStatus = 4;
    }
    saveCurrentQuesData(currentStatus);
    updateResponseStatus(currentIndex, currentStatus);
    goToQuestion(currentQues + 1);
  };

  const clearResponse = () => {
    if (!isCurrentQuesAnswered()) {
      return;
    }
    let currentSectionStartIndex =
      sectionStartIndexObj[selectedSection].initIndex;
    let currentIndex = currentSectionStartIndex + currentQues;
    setValidValueStatus({
      dirty: false,
      message: "",
    });
    // -1 represent user not type answer in case of MCQ and MCMC.
    // null represent user not type answer in case of FITB,DF,DFR and DTB.
    let qType =
      currentQuestionList[currentQues]?.questionType || defaultQuestionType;
    let value = -1;
    if (qType == 2 || qType == 4 || qType == 5 || qType == 6) {
      value = null;
    }
    // clearDtbS3Link -> flag that reset DTBtempUrl from response on clear response when qType is DTB.
    let clearDtbS3Link = false;
    if (qType == 6) {
      clearDtbS3Link = true;
    }
    updateResponse({
      value: value,
      qType: qType,
      status: 1,
      clearDtbS3Link: clearDtbS3Link,
    });
    setClearResponseFlag(!clearResponseFlag);
    updateResponseStatus(currentIndex, 1);
  };

  const selectOption = (event, alreadySelectedAns) => {
    if (
      alreadySelectedAns == -1 ||
      alreadySelectedAns == "null" ||
      alreadySelectedAns == "undefined"
    ) {
      // when fitbAns is -1 or null then remove it first.
      alreadySelectedAns = "";
    }
    let selectedAnswer = event.target.value;
    // when the option is MCMC
    if (currentQuestionList[currentQues]?.questionType == 3) {
      // split the answer
      const updatedSelectedAnsw = alreadySelectedAns
        ? alreadySelectedAns.split(",")
        : [];
      if (event.target.checked) {
        // push the new answer with the existed selected answer
        updatedSelectedAnsw.push(event.target.value);
      } else {
        // remove the existed selected answer
        updatedSelectedAnsw.splice(
          updatedSelectedAnsw.indexOf(event.target.value),
          1
        );
      }
      // join the updated answer with the new selected
      selectedAnswer = updatedSelectedAnsw.join();
    }
    updateResponse({
      value: selectedAnswer,
      qType:
        currentQuestionList[currentQues]?.questionType || defaultQuestionType,
    });
  };
  const selectFillInBlank = (event, qType) => {
    updateResponse({
      value: event,
      qType: qType,
    });
  };
  const questionStatusStyles = [
    "grey",
    "red",
    "green",
    "purple",
    "purple-green",
  ];
  const skipSection = () => {
    let totalSection = data.meta.sections.length;
    try {
      // Because modal is stupid;
      document.body.classList.remove("noscroll");
    } catch (ex) {}
    if (selectedSection !== totalSection - 1) {
      // not last section
      let currentSectionStartIndex =
        sectionStartIndexObj[selectedSection].initIndex;
      let currentIndex = currentSectionStartIndex + currentQues;
      let currentStatus = 2;
      if (isTimelimitSectionWise()) setSkipSectionFlag(true);
      if (!isCurrentQuesAnswered()) currentStatus = 1;
      saveCurrentQuesData(currentStatus);
      updateResponseStatus(currentIndex, currentStatus);
      setSelectedSection(selectedSection + 1);
      setQuestionStartTime(Date.now());
      setCurrentQues(0);
    }
  };
  const handleSectionClick = (idx) => {
    if (idx > selectedSection + 1) {
      addToast({ message: "Can't jump sections in this test" });
    } else if (idx < selectedSection) {
      addToast({ message: "Can't go back to previous section" });
    } else if (idx === selectedSection + 1) {
      showSkipSection();
    }
  };
  const showSkipSection = () => {
    setModal({ type: "skipSection" });
  };
  const countQuestionsByStatus = (statusList, status) => {
    return statusList.filter((val) => val == status).length;
  };

  const onTimerEnd = () => {
    if (sectionwiseTimeLimit) {
      let totalSection = data.meta.sections.length;
      if (selectedSection == totalSection - 1) {
        // submit Test;
        submitTest(true);
      } else {
        skipSection();
      }
    } else {
      submitTest(true);
    }
  };

  let isSplitView = false;
  if (
    currentQuestionList[currentQues]?.pre?.t ||
    currentQuestionList[currentQues]?.q?.t
  ) {
    let instruction = decipherStrings(
      currentQuestionList[currentQues]?.pre?.t ||
        currentQuestionList[currentQues]?.q?.t
    );
    if (
      instruction.length > 500 ||
      (instruction.length > 100 && instruction.indexOf("img") != -1)
    ) {
      isSplitView = true;
    } else {
      isSplitView = false;
    }
  }

  const fillInBlankChange = (value, qType) => {
    selectFillInBlank(value, qType);
    const isEmojiFlag = isEmoji(value);
    const isSpecialCHarFlag = specialCharDetect(value);

    if (isEmojiFlag || isSpecialCHarFlag) {
      setValidValueStatus({
        dirty: true,
        message: "No special character or emojis allowed",
      });
    } else {
      setValidValueStatus({
        dirty: false,
        message: "",
      });
    }
  };
  // DF handler
  const dfFillerChange = (value, qType) => {
    updateResponse({
      value: value,
      qType: qType,
    });
  };
  // DTB handler
  const dtbChange = (value, qType) => {
    updateResponse({ value, qType });
    const isEmojiFlag = isEmoji(value);

    if (isEmojiFlag) {
      setValidValueStatus({
        dirty: true,
        message: "No emojis allowed",
      });
    } else {
      setValidValueStatus({
        dirty: false,
        message: "",
      });
    }
  };
  function isEmoji(str) {
    str = str == "-1" ? "" : str;
    var ranges = [
      "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])", // U+1F680 to U+1F6FF
    ];
    if (str?.match(ranges?.join("|"))) {
      if (str?.includes("[")) {
        return false;
      } else {
        return true;
      }
      //return true;
    } else {
      return false;
    }
  }
  const specialCharDetect = (string) => {
    var format = /[`#\\[\]{};:\\|<>\~]/;
    if (format.test(string)) {
      return true;
    } else {
      return false;
    }
  };

  const calciOnClick = () => {
    setShowCalci(true);
  };
  const getDTBFormattingOptions = (optionkeys = []) => {
    let possibleFontKeys = {
      1: "bold",
      2: "underline",
      3: "bulletedList",
      4: "numberedList",
    };
    let dtbFontStyles = optionkeys.map((key) => possibleFontKeys[key]);
    return dtbFontStyles;
  };
  // change fontSize handler
  const handleChangeTextSize = (textSize = "") => {
    setSelectedTextSize(textSize);
  };
  const testInputComponents = {
    2: (
      <FITB
        value={
          currentResponsesList[currentQues] &&
          currentResponsesList[currentQues]?.fitbAns?.length > 0
            ? currentResponsesList[currentQues].fitbAns
            : ""
        }
        onChange={(e) =>
          fillInBlankChange(
            e?.target?.value?.trim(),
            currentQuestionList[currentQues]?.questionType ||
              defaultQuestionType
          )
        }
        warningmessage={validValueStatus?.message}
      />
    ),
    4: (
      <DF
        value={
          currentResponsesList[currentQues] &&
          currentResponsesList[currentQues]?.fitbAns?.length > 0
            ? currentResponsesList[currentQues].fitbAns
            : ""
        }
        currentQues={currentQues}
        btnDisabled={({ dirty, message }) => {
          setValidValueStatus({
            dirty,
            message,
          });
        }}
        onChange={(val) =>
          dfFillerChange(
            val,
            currentQuestionList[currentQues]?.questionType ||
              defaultQuestionType
          )
        }
      />
    ),
    5: (
      <DF
        value={
          currentResponsesList[currentQues] &&
          currentResponsesList[currentQues]?.fitbAns?.length > 0
            ? currentResponsesList[currentQues].fitbAns
            : ""
        }
        currentQues={currentQues}
        onChange={(val) =>
          dfFillerChange(
            val,
            currentQuestionList[currentQues]?.questionType ||
              defaultQuestionType
          )
        }
        btnDisabled={({ dirty, message }) => {
          setValidValueStatus({
            dirty,
            message,
          });
        }}
      />
    ),
    6: (
      <DTB
        wordLimit={decipherStrings(
          currentQuestionList[currentQues]?.wordLimit
        )?.replace(/"/g, "")} // remove quotes if present.
        editorData={
          currentResponsesList[currentQues]?.dtbAns
            ? currentResponsesList[currentQues]?.dtbAns + ""
            : ""
        }
        quesIndex={currentQues}
        onChangeCb={(val) => {
          dtbChange(
            val,
            currentQuestionList[currentQues]?.questionType ||
              defaultQuestionType
          );
        }}
        dtbFontStyles={getDTBFormattingOptions(
          data?.meta?.dtbFontStyles &&
            data?.meta?.dtbFontStyles[language]?.split(",")
        )}
        warningmessage={validValueStatus?.message}
      />
    ),
  };
  return (
    <>
      <div className={`test-container ${examName ? "examTest-container" : ""}`}>
        {/* CSS library for katex formulas */}
        <link
          rel="stylesheet"
          href="https://storecdn.adda247.com/katex/katex.min.css"
        />

        <CustomModal
          visible={!!modal}
          onCancel={closeModal}
          closeCallback={closeModal}
          modalClassName={
            modal && modal.type == "skipSection"
              ? "skip-section"
              : "test-response-modal"
          }
          hideHeader={modal && modal.type == "skipSection" ? false : true}
        >
          {(modal?.type == "submitted" || modal?.type == "alreadySubmit") && (
            <TestResponseDetails
              specialTestObject={props.specialTestsObj}
              testData={data}
              isPPc={isPPc}
              responseStatusList={responseStatusArray}
              sectionStartIndexObj={sectionStartIndexObj}
              title={title}
              packageId={packageId}
              mappingId={mappingId}
              goTo={goTo}
              freeContentFlag={freeContentFlag}
              isFixedMock={isFixedMock}
              isAlreadySubmited={modal?.type === "alreadySubmit"}
              sfJson={sfJson}
            />
          )}
          {modal && modal.type == "skipSection" && (
            <SkipSection
              responseStatusArray={responseStatusArray}
              sectionInfoObj={sectionStartIndexObj[selectedSection]}
              countQuestionsByStatus={countQuestionsByStatus}
              close={closeModal}
              skipSection={skipSection}
            />
          )}
          {modal && modal.type == "offline" && <NetworkDisconnected />}
        </CustomModal>
        {/* Fixed Mock PopUp Start*/}

        {isFixedMock && showFixedMockPopup && (
          <div className="mock-comman-pop">
            <div className="mock-comman-pop-content">
              <div className="short-heading-popup">
                {fixedMockPopUpText.title}
              </div>
              <div className="comman-pop-content">
                {fixedMockPopUpText.desc}
              </div>
              <div className="mock-pop-btn">
                <a
                  href="#"
                  className="graycolorbtn"
                  onClick={() => {
                    fixedMockPopUpText.secCTA === "NO"
                      ? setShowFixedMockPopup(false)
                      : null;
                  }}
                >
                  {fixedMockPopUpText.secCTA}
                </a>
                <a
                  href="#"
                  onClick={() => {
                    fixedMockPopUpText.secCTA === "NO"
                      ? closeWindow()
                      : setShowFixedMockPopup(false);
                  }}
                  className="redcolorbtn"
                >
                  {fixedMockPopUpText.primaryCTA}
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Fixed Mock PopUp End */}
        {/* Fixed Mock Result Awaited Popup Start */}

        {isFixedMock
          ? showResultAwaitedPopUp && (
              <div className="mock-comman-pop result-pop">
                <div className="mock-comman-pop-content">
                  <div className="mock-header-popup">
                    <div className="mockpopheader-title">
                      {decodeURIComponent(title)}
                    </div>
                  </div>
                  <div className="result-await-pop-img">
                    <img src="/images/mck-empty.svg" />
                  </div>
                  <div className="mock-pop-allready-attemped">
                    Test Completed Successfully
                  </div>
                  {fixedMockData.fixedMockResultTime - currentTime > 0 ? (
                    <>
                      <div className="result-await-pop-title">
                        RESULT AWAITED
                      </div>
                      <div className="result-await-timer-title">
                        Please check back after
                      </div>
                      <div className="result-await-titmer-pop">
                        <img src="/images/ic_timer.svg" />
                        {formatMilliseconds(
                          fixedMockData.fixedMockResultTime - currentTime
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="result-await-pop-title c-g">
                      RESULT Published
                    </div>
                  )}
                </div>
              </div>
            )
          : ""}

        {/* Fixed Mock Result Awaited Popup End */}

        {/*  normal tests header*/}
        {!examName && (
          <div className="test-header">
            <div className="test-result-logo-test">
              {isADDA ? (
                <img
                  src="/images/header-logo.svg"
                  className="logo-img"
                  alt={`${pageName[0]}-logo`}
                  title={pageName[0]}
                  width="172px"
                  height="40px"
                />
              ) : (
                <img
                  src={"/images/sankalpB-logo.svg"}
                  className="logo-img"
                  alt={`${pageName[0]}-logo`}
                  title={pageName[0]}
                />
              )}
            </div>
            <div className="testScreenTitleTag">
              {decodeURIComponent(title)}
            </div>
            <div className="right-dpns">
              <ChangeTextSize
                selectedTextSize={selectedTextSize}
                handleChangeTextSize={handleChangeTextSize}
              />
              <div className="th-select-language">
                View In:
                <select
                  id="language"
                  className="language-select"
                  value={language}
                  onChange={changeLanguage}
                >
                  {Object.keys(data).map((key, index) => {
                    if (["meta", "orgName"].includes(key) || !data[key]) {
                      /// bcoz stupid API :(
                      return "";
                    }
                    return (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        )}

        {/*exam based test header*/}
        {examName && (
          <>
            <ExamTestHeader
              title={decodeURIComponent(title)}
              examName={examName}
              data={data}
              language={language}
              changeLanguage={changeLanguage}
            />
          </>
        )}
        <div className="test-info">
          <div className="testincleft">
            {/*showcase timer for exam based test */}
            {examName && (
              <div className="exam-timer">
                <span>Sections</span>
                <Timer
                  startTime={
                    sectionwiseTimeLimit
                      ? totalTimeArray[selectedSection]
                      : totalTimeArray[0]
                  }
                  sectionId={sectionwiseTimeLimit ? selectedSection : 0}
                  submitTest={onTimerEnd}
                  setIsTestTimeOver={setIsTestTimeOver}
                  setCurrentSectionRemainingTime={
                    setCurrentSectionRemainingTime
                  }
                />
              </div>
            )}

            <div className="test-sections">
              {data.meta.sections.map((section, index) => (
                <ATooltip
                  content={
                    <SectionToolTipContent
                      responseStatusArray={responseStatusArray}
                      sectionInfoObj={sectionStartIndexObj[index]}
                      countQuestionsByStatus={countQuestionsByStatus}
                    />
                  }
                  key={index}
                >
                  <div className="test-sections-item">
                    <button
                      id={index}
                      className={index == selectedSection ? "blue" : "black"}
                      onClick={(e) => {
                        if (!sectionwiseTimeLimit) {
                          sectionChange(e);
                        } else {
                          handleSectionClick(index);
                        }
                      }}
                    >
                      {section.secDN}
                    </button>
                  </div>
                </ATooltip>
              ))}
              {/* cutOff marks */}
              {data["meta"].cutOffMarks > 0 && (
                <div className="cutfmark testinnercut">
                  <div className="markwrap">
                    Cut Off Marks:
                    <span>{`${data["meta"].cutOffMarks} / ${data["meta"].totalm}`}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="test-user-info">
            <div className="user-image">
              <img
                src={`https://storecdn.${pageName[0]}.com/tsp-candidate.jpg`}
                width={"60px"}
              ></img>
            </div>
            <div className="time-status">
              <span>{userDetails.name}</span>
              {/* hide Timer here when exam based test opened */}
              {!examName && (
                <Timer
                  startTime={
                    sectionwiseTimeLimit
                      ? totalTimeArray[selectedSection]
                      : totalTimeArray[0]
                  }
                  sectionId={sectionwiseTimeLimit ? selectedSection : 0}
                  submitTest={onTimerEnd}
                  setIsTestTimeOver={setIsTestTimeOver}
                  setCurrentSectionRemainingTime={
                    setCurrentSectionRemainingTime
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="test-content">
          <div
            className={
              showResponsePanel
                ? "testQuesLeftContent"
                : "testQuesLeftContent fullWidth"
            }
          >
            <div className="testQuesLand">
              {examName && (
                <>
                  {/* show questionType for exam based test */}
                  <div className="testMultiQues">
                    <span className="questiontype-details">
                      <span className="questionType">Question Type: </span>
                      {
                        questionTypesLabel[
                          currentQuestionList[currentQues]?.questionType ||
                            defaultQuestionType
                        ]
                      }
                    </span>
                  </div>

                  <div className="testQesInnerViewIn">
                    <ChangeTextSize
                      selectedTextSize={selectedTextSize}
                      handleChangeTextSize={handleChangeTextSize}
                    />
                    <div className="th-select-language">
                      View In:
                      <select
                        id="language"
                        className="language-select"
                        value={language}
                        onChange={changeLanguage}
                      >
                        {Object.keys(data).map((key, index) => {
                          if (["meta", "orgName"].includes(key) || !data[key]) {
                            return "";
                          }
                          return (
                            <option value={key} key={key}>
                              {key}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              )}
              <div className="wrap-top-marks">
                {/* question No. index showcase only when exam based text */}
                {mode ? (
                  <Indicator
                    showIndicator={showIndicator}
                    indicatorBody={`${indicatorMessage}`}
                    additionIndicatorWrapper={`${
                      navigator?.onLine
                        ? "indicator-online"
                        : "indicator-offline"
                    }`}
                  />
                ) : ""}
                {examName && (
                  <span className="exam-question-no">
                    Question No. {currentSectionStartIndex + currentQues + 1}
                  </span>
                )}
                {/* Marking for specific question */}
                {sectionStartIndexObj?.[selectedSection]?.sectionInfo
                  ?.secOpt ? (
                  <div className="optional-section-info">
                    <img
                      src="/images/icon-attempAllowed.svg"
                      width="24px"
                      height="24px"
                      alt="allowedAttempt"
                    />
                    First{" "}
                    {
                      sectionStartIndexObj?.[selectedSection]?.sectionInfo
                        ?.attemptAllowed
                    }{" "}
                    attempts will be evaluated
                  </div>
                ) : (
                  <></>
                )}

                {/* pos & neg marking */}
                <div className="tc-marking">
                  <div className="postv">
                    +{currentQuestionList[currentQues]?.pos || 0}
                  </div>
                  <div className="marking-divider">|</div>
                  <div className="negtv">
                    {currentQuestionList[currentQues]?.neg > 0
                      ? `-${currentQuestionList[currentQues]?.neg}`
                      : currentQuestionList[currentQues]?.neg?.toFixed(1) || 0}
                  </div>
                </div>
              </div>
              {/* End marking */}
            </div>
            <div
              className={`tc-question-section ${
                isSplitView ? "split-view" : ""
              } ${textSizeClassKeyNames[selectedTextSize]}`}
            >
              <div className={`tc-question`}>
                {/* hide question No. for non-exam based test */}
                {!examName && (
                  <span className="tc-question-no">
                    Q.{currentSectionStartIndex + currentQues + 1}{' '}
                  </span>
                )}
                {currentQuestionList[currentQues].pre ? (
                  <DecipherString
                    text={currentQuestionList[currentQues].pre.t}
                  />
                ) : (
                  <DecipherString text={currentQuestionList[currentQues].q.t} />
                )}
              </div>
              {examName && hasVerticalScrollbar && (
                <div
                  onClick={() => optionScrollHandler(tcAnswerRef, "up")}
                  className="uparrow"
                >
                  up
                </div>
              )}
              <div className="tc-answer" ref={tcAnswerRef}>
                <div style={{ marginTop: "5px" }}>
                  {currentQuestionList[currentQues].pre && (
                    <DecipherString
                      text={currentQuestionList[currentQues].q.t}
                    />
                  )}

                  {/* FITB,DF,DFR,DTB anwers components*/}
                  {
                    testInputComponents[
                      currentQuestionList[currentQues]?.questionType
                    ]
                  }
                  {/* MCQ,MCMC anwers components*/}
                </div>
                {currentQuestionList[currentQues]?.questionType == 3 && (
                  <p className="option-heading-msg">
                    You can select multiple options
                  </p>
                )}
                {(currentQuestionList[currentQues]?.questionType == 1 ||
                  !currentQuestionList[currentQues]?.questionType ||
                  currentQuestionList[currentQues]?.questionType == 3) &&
                  currentQuestionList[currentQues]?.opt?.map(
                    (option, index) => {
                      let response = currentResponsesList[currentQues]
                        ? currentResponsesList[currentQues][
                            currentQuestionList[currentQues]?.questionType ==
                              1 ||
                            !currentQuestionList[currentQues]?.questionType
                              ? "selectedAns"
                              : "fitbAns"
                          ] + "" || ""
                        : "";
                      let inputType = {
                        1: "radio",
                        3: "checkbox",
                      };
                      return (
                        <div className="dtb-option-wrap" key={index}>
                          <span className="input-type-wrap">
                            <input
                              type={
                                inputType[
                                  currentQuestionList[currentQues]
                                    ?.questionType || defaultQuestionType
                                ]
                              }
                              id={`option-${index}`}
                              name="options"
                              onClick={(e) => selectOption(e, response)}
                              value={index}
                              checked={
                                currentQuestionList[currentQues]
                                  ?.questionType == 1 ||
                                !currentQuestionList[currentQues]?.questionType
                                  ? response == index
                                  : response != -1 && response !== null
                                  ? response?.includes(index)
                                  : false
                              }
                            />
                            {<span className="checkmark"></span>}
                          </span>
                          <label htmlFor={`option-${index}`}>
                            <DecipherString text={option.t} />
                          </label>
                        </div>
                      );
                    }
                  )}
              </div>
              {examName && hasVerticalScrollbar && (
                <div
                  onClick={() => optionScrollHandler(tcAnswerRef, "down")}
                  className="downarrow"
                >
                  down
                </div>
              )}
              {calculatorFlag && (
                <>
                  {showCalci ? (
                    <SciCalci showHide={(flag) => setShowCalci(flag)} />
                  ) : (
                    <div
                      className={`calc-wrapper ${
                        !showResponsePanel ? "calc-full" : ""
                      }`}
                    >
                      <img
                        src={"/images/Calci.png"}
                        onClick={() => calciOnClick()}
                        className="calci-show-image"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {!showResponsePanel && (
            <div className="tcq-hide-panel-L">
              <div className={"chevronLeftM"} onClick={toogleResponsePanel}>
                {" "}
              </div>
            </div>
          )}
          <div
            className={`tc-questions-status ${
              showResponsePanel ? "" : "hide-panel"
            }`}
          >
            <div className={`tcq-status-index`}>
              <div className="tcq-answered">
                <div className="circle green">
                  {countQuestionsByStatus(currentResponseStatusList, 2)}
                </div>
                Answered
              </div>
              <div className="tcq-answered">
                <div className="circle red">
                  {countQuestionsByStatus(currentResponseStatusList, 1)}
                </div>
                Not Answered
              </div>
              <div className="tcq-answered">
                <div className="circle grey">
                  {countQuestionsByStatus(currentResponseStatusList, 0)}
                </div>
                Not Visited
              </div>
              <div className="tcq-answered">
                <div className="circle purple">
                  {countQuestionsByStatus(currentResponseStatusList, 3)}
                </div>
                Marked For Review
              </div>
              <div className="tcq-answered">
                <div className="circle purple-green">
                  {countQuestionsByStatus(currentResponseStatusList, 4)}
                </div>
                Answered and Marked for Review
              </div>
            </div>
            <div className="tcq-hide-panel-R">
              <div className={" chevronRightM"} onClick={toogleResponsePanel}>
                {" "}
              </div>{" "}
            </div>
            {examName && (
              <div className="exam-sec-name">
                {sectionStartIndexObj[selectedSection]?.sectionInfo?.secDN}
              </div>
            )}
            <div className={`tcq-status-list`}>
              <span className="tcqs-list-header">Choose a Question</span>
              <div className="tcqs-list-wrap">
                {Array(currentQuestionList.length)
                  .fill(0)
                  .map((value, index) => {
                    return (
                      <div
                        className={`circle ${
                          questionStatusStyles[
                            responseStatusArray[
                              sectionStartIndexObj[selectedSection].initIndex +
                                index
                            ]
                          ]
                        }`}
                        id={index}
                        onClick={selectQuestion}
                        key={index}
                      >
                        {index +
                          sectionStartIndexObj[selectedSection].initIndex +
                          1}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="test-actions">
          <div className="leftbtns">
            <div className="test-action-item-box">
              <div className="test-action-item">
                <button
                  className={!validValueStatus?.dirty ? "red" : "disabled-red"}
                  onClick={() => {
                    if (!validValueStatus?.dirty) {
                      markForReviewAndNext();
                    } else {
                      alert(validValueStatus?.message);
                    }
                  }}
                  // disabled={validValueStatus?.dirty}
                >
                  {onLastQuesOfLastSection
                    ? TEST.markForReviewTxt
                    : TEST.markForReviewAndNextTxt}
                </button>{" "}
              </div>
              <div className="test-action-item">
                <button
                  onClick={clearResponse}
                  className={isCurrentQuesAnswered() ? "red" : "disabled-red"}
                  disabled={!isCurrentQuesAnswered()}
                >
                  Clear Response
                </button>{" "}
              </div>
            </div>
            <div className="test-action-item-box">
              {examName && currentQues > 0 && (
                <div className="test-action-item">
                  <button
                    className={
                      !validValueStatus?.dirty ? "red" : "disabled-red"
                    }
                    onClick={() => {
                      goToQuestion(currentQues - 1);
                    }}
                  >
                    Previous
                  </button>{" "}
                </div>
              )}

              <div className="test-action-item savenextbtn">
                <button
                  className={
                    !validValueStatus?.dirty && !onLastQuesOfLastSection
                      ? "red"
                      : "disabled-red"
                  }
                  onClick={() => {
                    if (!validValueStatus?.dirty) {
                      saveAndNext();
                    } else {
                      alert(validValueStatus?.message);
                    }
                  }}
                  // disabled={validValueStatus?.dirty}
                  disabled={onLastQuesOfLastSection}
                >
                  Save and Next
                </button>{" "}
              </div>
            </div>
          </div>
          <div
            className={
              showResponsePanel ? "rightNonClose" : "test-actions-right"
            }
          >
            {selectedSection != data.meta.sections.length - 1 && (
              <div
                className="test-action-item"
                style={{
                  display: `${sectionwiseTimeLimit ? "" : "none"}`,
                }}
                onClick={showSkipSection}
              >
                <button className="red">Skip Section</button>{" "}
              </div>
            )}
            <div className="test-action-item" onClick={submitTest}>
              <button
                className={!validValueStatus?.dirty ? "red" : "disabled-red"}
                disabled={validValueStatus?.dirty || submitButtonDisabled}
              >
                Submit
              </button>{" "}
            </div>
          </div>
        </div>
        {mode && (
          <LiveTestModal
            showModal={showLiveTestModal}
            closeModal={() => {}}
            title={liveTestModalBody?.title || ""}
            description={liveTestModalBody?.description || ""}
            secondaryDescription={liveTestModalBody?.secondaryDescription || ""}
            secondaryButtonText={liveTestModalBody?.secondaryButtonText || ""}
            primaryButtonText={liveTestModalBody?.primaryButtonText || ""}
            primaryButtonAction={
              liveTestModalBody?.primaryButtonText
                ? getModalButtonAction(liveTestModalBody?.type)
                : null
            }
            secondaryButtonAction={
              liveTestModalBody?.secondaryButtonText
                ? () => setShowLiveTestModal(true)
                : null
            }
            modalType={liveTestModalBody?.type || ""}
            hideCloseIcon={true}
            modalDescClass={''}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { specialTests } = state;
  return {
    specialTestsObj: specialTests.data,
    userDetails: state.header.userInfo || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialTests: () => dispatch(getSpecialTests()),
    addToast: (obj) => dispatch(addToast(obj)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Test);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';


import MaiListing from '../components/myebook/MainListing';
import ViewEbookListing from '../components/myebook/ViewEbookListing';
import Myaccountnavbar from './Myaccountnavbar';
import { withCookies } from 'react-cookie';
import { Cookies } from "react-cookie";
const cookie = new Cookies();



import {
  fetchUserEbooks,
  fetchViewAllEbooks
} from "../data/ducks/myebooks/actions";

import "../data/ducks/myebooks/reducers";
import { getSubstringAfterChar, scrollToTop, getMoengageMetadata, getPdpUrl } from "../data/utils/helpers";
import CustomLoader from '../components/library/CustomLoader';
import { STOREFRONT_URI, pageName } from '../../constants/appConfig';
import CustomHelmet from '../components/library/CustomHelmet';

class MyEbooks extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.isLoggedIn = cookies.get('cp_token');
    this.state = {
      isLoading: true,
      Loading: true,
      clicked: false,
      ebookList: "",
      searchTerm: "",
      searchTermInput: "",
      noSearchTerm: true,
    }
  }

  // fetchUserBooks=()=>{
  //   fetch(`${STOREFRONT_URI}/api/v1/ebooks`, "GET")
  //   .then(res => {
  //     if (res === true) {

  //     } else {
  //       setIsPurchased(false);
  //     }
  //   })
  //   .catch(() => {
  //     CartActionModal;
  //     setIsPurchased(false);
  //   });
  // }
  componentDidMount() {
    scrollToTop(0);
    this.props.fetchUserEbooks().then(() => {
      this.setState({
        Loading: false
      })
    }).catch(() => {
      this.setState({
        Loading: false
      })
    });

    if (this.props.match.params.id && this.state.clicked == false) {
      this.getEbooksList(this.props.match.params.id);
    }
  }

  defaultMetaTitle = `My Ebooks`;
  defaultMetaDesc = `Total number of Ebooks purchased by me from ${pageName[0]}`;

  getEbooksList = (bookId, data) => {

    let MoePayload = {};
    if (data) {
      MoePayload = {
        package_status: 'paid',
        url: location.href,
        name: data.title,
        id: data.id,
        action: 'my_ebook_btnClicked',
        category: 'EBOOKS',
        'packageUrl': getPdpUrl("EBOOKS", data.id, data.title)
      }
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'ebook',
        getMoengageMetadata(
          MoePayload
        ),
      );
    }




    this.state.clicked = true;
    this.setState({ isLoading: true });
    if (!this.props.match.params.id) {
      this.props && this.props.history.push(`/my-ebooks/${bookId}`);
    }


    this.props.fetchViewAllEbooks(bookId, 0, 5000).then(() => {
      this.setState({ isLoading: false });
    });
  }

  handleSearchInput = (event) => {
    event.preventDefault();
    this.setState({ searchTermInput: event.target.value });
    if(!event.target.value.trim()) this.setState({ noSearchTerm: true});
    else this.setState({ noSearchTerm: false});
  }

  handleSearchAction = () => {
    const searchTerm = this.state.searchTermInput.trim().toLowerCase();
    if(searchTerm.length <= 1){
      return;
    }
    this.setState({ searchTerm: searchTerm });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        this.handleSearchAction();
    }
}

  goToEbooks = () => {
    this.props && this.props.history.push(`/my-ebooks`);
  }

  render() {
    const { ebookList, viewEbooksList, location, userInfo } = this.props;


    const { pathname } = location;
    let id = getSubstringAfterChar(pathname, '/');
    if (!this.isLoggedIn) {
      return <Redirect to="/#show-login" />;
    }

    return (
      <div className="myprofile-container myebooks ebookslidepanel">
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .web-footer{
                            margin-top:0px !important;
                        }
                        `}}
        />
        <div className={'ebook-navbar'}>
          <Myaccountnavbar />
        </div>

        <CustomHelmet
          title={this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].metaTitle
              ? this.props.seoData[pathname].metaTitle
              : this.defaultMetaTitle}
          description={this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].metaDescription
              ? this.props.seoData[pathname].metaDescription
              : this.defaultMetaDesc}
          url={
            (this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].canonical) ||
            this.props.req_url
          }
          keywords={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].keywords
          }
          noFollow={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].noFollow
          }
          noIndex={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].noIndex
          }
        />

        <div className="slide-left-ebook">
          <div style={{ zIndex: '-999' }} className="slide-left-ebook-inner">
            <div className="main-container-account" style={{ border: 'none', minHeight: 'calc(100vh - 200px)' }}>
              {id === 'my-ebooks' ?
                <div>
                  <header className='header-title custumSearchWrap'>
                    <div className='text-container'>
                      E-BOOKS
                                      </div>
                    <span className='custum-search-wrapper'>
                      <input className='ebooks-search' type={'text'} placeholder="Search" onChange={(e) => {this.handleSearchInput(e)}} onKeyDown = {this.handleKeyDown}></input>
                      <button className='ebooks-search-btn' onClick={() => {this.handleSearchAction()}}>Search</button>
                    </span>
                  </header>
                  {
                    this.state.Loading ?
                      <CustomLoader />

                      :
                      <MaiListing ebookList={ebookList} getEbooksList={this.getEbooksList} searchTerm={this.state.searchTerm} noSearchTerm={this.state.noSearchTerm}/>
                  }

                </div> :
                <>
                  {
                    this.state.isLoading == false && viewEbooksList && viewEbooksList.ebooksData ? <ViewEbookListing viewEbooksList={viewEbooksList} goToEbooks={this.goToEbooks} />
                      :
                      <CustomLoader />
                  }

                </>
              }

            </div>
          </div>
        </div>

      </div>

    )

  }
}

const mapDispatchToProps = {
  fetchUserEbooks,
  fetchViewAllEbooks
};

const mapStateToProps = state => ({
  ebookList: state.ebooks.ebooksList,
  viewEbooksList: state.ebooks.viewEbooksList,
  isLoggedIn: state.header.jwtToken,
  userInfo: state.header.userInfo
});

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(MyEbooks))

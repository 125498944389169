import React from "react";
import PropTypes from "prop-types";
const ComparisonScoreCard = ({
  title = "",
  currentUserScore = 0,
  topperScore = 0,
  avarageScore = 0,
  totalValue = 0,
  hideTotalValue = false,
  showPercentage = false,
}) => {
  return (
    <div className="abilityBox halfpart">
      <div className="heading-ability">{title}</div>
      <div className="content-ability">
        <div className="your-progressBar">
          <div className="yourprogress-fill"></div>
        </div>
        <div className="topper-progressBar">
          <div className="topperprogress-fill"></div>
        </div>

        <div className="your-ability-score">
          <div className="abilitybarbg yourbar">
            <div
              className="abiitybar"
              style={{ width: `${(currentUserScore / totalValue) * 100}%` }}
            ></div>
          </div>

          <div className="abilitybarbg topperbar">
            <div
              className="abiitybar"
              style={{ width: `${(topperScore / totalValue) * 100}%` }}
            ></div>
          </div>
          <div className="compare-socre">
            <div className="your-total-score">
              <div className="your-totalbox">
                {currentUserScore}
                {showPercentage && "%"}
              </div>
              <div className="your">You</div>
            </div>

            <div className="topper-total-score">
              <div className="topper-totalbox">
                {topperScore}
                {showPercentage && "%"}
              </div>
              <div className="topper">Topper</div>
            </div>

            {!hideTotalValue && (
              <div className="totalmark-total-score">
                <div className="totalmark-totalbox">{totalValue}</div>
                <div className="totalmark">Total</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="ability-footer">
        <div className="ability-footer-text">Average</div>
        <div className="ability-footer-number">
          {avarageScore}
          {showPercentage && "%"}
        </div>
      </div>
    </div>
  );
};
ComparisonScoreCard.propTypes = {
  title: PropTypes.string,
  currentUserScore: PropTypes.number,
  topperScores: PropTypes.number,
  avarageScore: PropTypes.number,
  hideTotalValue: PropTypes.bool,
};
export default ComparisonScoreCard;

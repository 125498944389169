import React from 'react';
//import { urlencoded } from 'body-parser';

const InfoButton = (props) => {
    return (
        <div className='infoButton' style={{ display: props.bookFlag ? "none" : "visible" }}>
            <div className={props.icon}>
            </div>
            <div>
                {props.label}<span style={{ color: "#3dc635" }} >{props.labelMonth}</span>
            </div>
        </div >
    )
}
export default InfoButton;
import React, { useState } from "react";
import { optimizedImgUrl } from "../../data/utils/helpers";
import TestimonialCarouselNew from "../library/TestimonialCarouselNew";
import { FallBackImage } from "../../../constants/appConfig";

const PDPTestimonial = ({ testimonialsData }) => {
  const [centerIndex, setCenterIndex] = useState(1);

  return (
    <section className="pdpTestimonials"  id="Testimonials">
      <div className="pdp-container">
        <h3 className="pdpSimpleHeading">Testimonials</h3>
        <div className="pdpTestimonialsBoxWrap">
          <TestimonialCarouselNew
            show={2}
            infiniteLoop
            isPdpTestimonial = {true}
            setCenterIndex={setCenterIndex}
            centerIndex={centerIndex}
          >
            {testimonialsData?.map((testimonial, index) => (
              <div key={index} className="pdpTestimonialsBox">
                <div className="pdpTestimonialsLeft">
                <div className="pdpTestimonialsImg">
                  {testimonial?.s3URL === "" ? (
                    testimonial?.name[0]
                  ) : (
                    <img src={optimizedImgUrl(testimonial?.s3URL)} alt={`pdp testimonial ${index}`}/>
                  )}
                </div>

                <div className="pdpTestimonialsUser">
                    <div className="pdpTestimonialsInfo">
                      <div className="pdpTestimonialsName">
                        {testimonial?.name}
                      </div>
                      <div className="PdppdpTestimonialsRating">
                        {[...Array(testimonial.rating)]?.map((_, index) => (
                          <img key={index} src="/images/pdpStar.svg" alt="rating"/>
                        ))}
                      </div>
                    </div>
                    {/* <div className="pdpTestimonailDate">Posted on {testimonial.date}</div> */}
                  </div>
                  
                </div>
                <div className="pdpTestimonialsContent">
                 
                  <div className="pdpTestimonialMsg">
                    {testimonial?.description}
                  </div>
                </div>
              </div>
            ))}
          </TestimonialCarouselNew>
        </div>
      </div>
    </section>
  );
};

export default PDPTestimonial;

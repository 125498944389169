import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import { productKeys, quickLinkMapping } from "../../constants/appConstants";
import FilterTags from "../components/filter/FilterTags";
import MainListing from "../components/filter/listings/MainListing";
import NewMobileFilter from "../components/filter/NewMobileFilter";
import SearchBlock from "../components/filter/searchfilters";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import NoResultsFound from "../components/library/NoResultsFound";
import {
  clearExamCategoryData,
  getDataForExamCategoryListing,
  getExamCategoryListingWithFilter,
} from "../data/ducks/examCategoryListing/actions";
import "../data/ducks/examCategoryListing/reducers";
import {
  capitalizedString,
  getMoengageMetadata,
  isEmpty,
  isSet,
  removeElementsFromLastInArray,
  removeHyphensAndInsertSpaces,
  getDeviceType,
  getPdpUrl,
  getUrlPathObject,
  getExamCategoryId,
  removeSpacesAndInsertHyphens,
  getSubstringBeforeChar,
  trackMoengageEvent,
  getSubstringAfterChar,
  replaceHyphenWithUnderscore,
  replaceUnderscoreWithHyphen,
  isADDA,
  getMoengagePayloadFromFacets,
  fireMoengageEvent,
  getTagIdParams,
} from "../data/utils/helpers";
import { pageName } from "../../constants/appConfig";
import CustomSkeleton from "../components/library/CustomSkeleton";
import BreadCrumb from "../components/library/BreadCrumb";
import Faq from "./SEO/faq";
import { checkProperties } from "../components/utils";
import DfpAdPdp from "../components/library/DfpAdPdp";
import { SeoSectionMobile } from "../components/library/SeoBlock/SeoBlock";
import { SeoSubCategory } from "../components/library/SeoBlock/SeoSubCategory";
import fetch from "../data/utils/fetch";
import { SEARCH_URL } from "../../constants/appUrls";
import CarouselSchema from "../components/library/CarouselSchema";

let filterList;
let count = 0;
const sponsoredCategory = 3;
const cookie = new Cookies();
const validTags = ['categoryFacets', 'searchTerm', 'examTypesFacets', 'coursesFacets', 'langFacets', 'tagsFacets', 'subjectFacets'];
class ExamCategoryListing extends PureComponent {
  constructor(props) {
    super(props);
    const tags = this.createTagsFromUrl();
    this.state = {
      loader: true,
      similarProduct: [],
      loading: true,
      showNoresult: false,
      dataLoader: false,
      tagArr: tags.tagArr,
      facetObj: tags.facetObj,
      queryParam: props.location.search.substring(1) || "",
      examName: props.match.params.examName,
      coursesFacets: "",
      isDataEmptyAfterFilterApply: false
    };
  }

  static propTypes = {
    getDataForExamCategoryListing: PropTypes.func,
    examCategoryListingData: PropTypes.object,
    clearExamCategoryData: PropTypes.func,
    facets: PropTypes.object,
    utmParams: PropTypes.object,
  };

  getInitialData() {
    const { location } = this.props;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-");
    // const category=pathname.substring(1).split('-')[0];
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");
    let coursesFacets = sessionStorage.getItem("ExamCategoryMappingId");
    if( coursesFacets) {
      coursesFacets = replaceUnderscoreWithHyphen(coursesFacets).toLowerCase();
      this.setState({coursesFacets: coursesFacets});
    }
    

    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get("cp_user_name");
    let email = cookie.get("cp_user_email");
    const token = cookie.get("cp_token");

    if (user_id_cookie && token) {
      window.dataLayer.push({
        name: name || "guest",
        email: email || "",
        isMobile: getDeviceType() == "m" ? true : false,
        loginType: "email",
        isLoggedIn: true,
        examName: examName,
        pageName: "Category",
        examCategoryName: "",
      });
    } else if (!token && user_id_cookie) {
      window.dataLayer.push({
        name: "guest",
        email: "",
        isMobile: getDeviceType() == "m" ? true : false,
        loginType: "",
        isLoggedIn: false,
        examName: examName,
        pageName: "Category",
        examCategoryName: " ",
      });
    }
    const queryParams = location.search.substring(1);
    typeof window !== "undefined" && window.scrollTo(0, 0);
    this.createTagsFromUrl();
    if (!queryParams.length) {
      this.getAllData(examName);
    } else {
      let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
      this.props.getDataForExamCategoryListing(
        examName + "&" + replaceHyphenWithUnderscore(queryParams) + (coursesFacets? `&coursesFacets=${replaceHyphenWithUnderscore(coursesFacets.toUpperCase())}${tagParams}`: ""),sponsoredCategory);
    }
  }
  componentDidMount() {
    setTimeout(() => document.getElementsByClassName('breadcrumb-container')[0].scrollIntoView({block:'center'}),200);
    setTimeout(() => {
      if (!this.props.examCategoryListingData?.data?.length) {
        this.setState({
          showNoresult: true,
        });
      }
    }, 10000);
    this.getInitialData();
    typeof window !== "undefined" && window.scrollTo(0, 0);
    const { location } = this.props;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");
    if (this.props.examList) {
      let examListCategory =
        JSON.parse(localStorage.getItem("examMenuData")) || this.props.examList;
      let selectedExamCategory = getExamCategoryId(examListCategory, examName);
      let similarProduct = selectedExamCategory
      ? [...examListCategory[selectedExamCategory]]
      : [];
        similarProduct?.map((elem, index) => {
          if (
            removeSpacesAndInsertHyphens(elem.name.toLowerCase()) ==
            examName.toLowerCase()
          ) {
            similarProduct.splice(index, 1);
          }
        });
      this.setState({ similarProduct: similarProduct });
    }
    if (!JSON.parse(localStorage.getItem("examMenuData"))) {
      localStorage.setItem("examMenuData", JSON.stringify(this.props.examList));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.examName !== prevState.examName) {
      return { examName: nextProps.match.params.examName };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.examName !== this.props.match.params.examName) {
      this.getInitialData();
      const { location } = this.props;
      const { pathname } = location;
      const courseCategory = pathname.substring(1).split("-");
      const examName = removeElementsFromLastInArray(courseCategory, 2).join(
        "-"
      );
      if (this.props.examList) {
        let selectedExamCategory = getExamCategoryId(
          this.props.examList,
          examName
        );
        let similarProduct = [...this.props.examList[selectedExamCategory]];
        similarProduct &&
          similarProduct.map((elem, index) => {
            if (
              removeSpacesAndInsertHyphens(elem.name.toLowerCase()) ==
              examName.toLowerCase()
            ) {
              similarProduct.splice(index, 1);
            }
          });
        this.setState({ similarProduct: similarProduct });
      }
    }
    if(this.state.tagArr.length > 0){
      const filteredData = this.getDataBasedOnSelectedFilter(
        this.props?.examCategoryListingData.data,
        this.state.tagArr
      )
      if(!filteredData || filteredData.length == 0){
        this.setState({isDataEmptyAfterFilterApply: true})
      }else if(filteredData?.filter(obj => obj.count != 0)?.length == 0){
        this.setState({isDataEmptyAfterFilterApply: true})
      }else{
        this.setState({isDataEmptyAfterFilterApply: false})
      }
    }
    if(this.state.tagArr != prevState.tagArr){
      const { location } = this.props;
      const { pathname } = location;
      const courseCategory = pathname?.substring(1)?.split("-");
      // const category=pathname.substring(1).split('-')[0];
      const examName = removeElementsFromLastInArray(courseCategory, 2)?.join("-");
    }
    if(this.props?.examCategoryListingData && this.props?.examCategoryListingData?.length > 0){
      this.setState({
        showNoresult: false
      })
    }

    if(prevProps?.facets != this.props.facets){
      if(this.props?.facets?.categories && !this.state?.categories){
        this.setState({
          categories: this.props?.facets?.categories
        })
      }
    }
    if(prevState?.categories != this.state?.categories){
      this.getSecondaryFacetsOnFilterChange({});
    }
  }

  componentWillUnmount() {
    if (
      this.props.examCategoryListingData &&
      !isEmpty(this.props.examCategoryListingData.data)
    ) {
      this.props.clearExamCategoryData();
      this.clearBeforeUnload();
    }
    count = 0;
    const googletag = window.googletag;
    if (googletag) googletag.destroySlots();
  }

  clearBeforeUnload() {
    this.setState({
      tagArr: [],
      facetObj: {},
    });
  }

  createTagsFromUrl = () => {
    const qParams = this.props.location.search.substring(1);
    let tempTagArr = [];
    let newFacetObj = {};
    if (qParams !== undefined && qParams.length) {
      let splitArr = qParams.split("&");
      splitArr = splitArr.filter(
        (e) => !(e.indexOf("utm") > -1 || e.indexOf("gclid") > -1)
      );
      splitArr.map((item) => {
        let newArr = item.split("=");
        if (validTags.includes(newArr[0])) {
          let finalArr = (newArr[1] || "").split(",");
          finalArr.map((it) => {
            if (!tempTagArr.includes(decodeURI(it))) {
              tempTagArr.push(decodeURI(it));
            }
          });
          newFacetObj[newArr[0]] = finalArr;
        }
      });
    }
    return {
      tagArr: tempTagArr.filter((item) => item),
      facetObj: newFacetObj,
    };
  };

  getAllData = (exam) => {
    this.createTagsFromUrl();
    let coursesFacets = sessionStorage.getItem("ExamCategoryMappingId");
    if (coursesFacets) {
      coursesFacets = replaceUnderscoreWithHyphen(coursesFacets).toLowerCase();
      this.setState({ coursesFacets: coursesFacets });
    }
    let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
    if (
      isEmpty(this.props.examCategoryListingData) &&
      this.props.examCategoryListingData !== undefined
    ) {
      this.props.getDataForExamCategoryListing(exam + (coursesFacets? `&coursesFacets=${replaceHyphenWithUnderscore(coursesFacets.toUpperCase())}${tagParams}`: ""),sponsoredCategory).then(() => {
        this.setState({
          loader: false,
          loading: false,
        });

        if (!isEmpty(this.props.examCategoryListingData.data)) {
          const facetsList = this.props.facets;
          this.setState({
            facetsList,
          });
        }
      });
    }
  };

  handleDetailCardClick = (obj) => {
    const category = productKeys[obj.categoryname] || "";
    const id = obj.id || "";
    const title = obj.cardtitle || "";
    const slugurl = obj.slugURL || "";
    const getUtmFromStore = this.props.utmParams;
    // this.fireCretio(id);
    // let categoryName = obj.categoriesincluded?obj.categoriesincluded[0]:''
    const storeUrl = getPdpUrl(category, id, title, slugurl);

    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "product_click",
        getMoengageMetadata({
          source: "web",
          id: id,
          name: title,
          category: category,
          list: category,
          brand: pageName[1],
          variant: "-",
          price: obj.sellingprice,
          quantity: "1",
          packageUrl: storeUrl,
          packageActualPrice: obj.maximumretailprice,
          packageDiscountedPrice: obj.sellingprice,
          getUtmFromStore: getUtmFromStore,
          source_screen: "ExamCategory listing",
          productType: category,
        })
      );
    //createStoreFrontPdpUrl
    // window.open(storeUrl);
    // this.props.history.push(getUrlPathObject(storeUrl));
  };

  handleFilterButton = () => {
    this.setState({
      showFilterModal: true,
    });
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    fireMoengageEvent("filter_button_clicked", moePayload, "filter");
  };

  hideFilterOnMobile = () => {
    this.setState({
      showFilterModal: false,
    });
  };

  resetFilter = () => {
    this.clearAllFilters();
    this.setState({
      showFilterModal: false,
    });
  };

  getSecondaryFacetsOnFilterChange = (facetObj) => {
    const { location } = this.props;
    const { pathname } = location;

    const courseCategory = pathname?.substring(1)?.split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2)?.join(
      "-"
    );
    const coursesFacets = sessionStorage?.getItem("ExamCategoryMappingId");
    let categoryFacets;
    if(Object.keys(facetObj || {})?.length > 0){
      categoryFacets = facetObj?.categoryFacets;
    }
    let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
    const url = `${SEARCH_URL}/packages/secondaryFacets?examTypesFacets=${examName}&${coursesFacets? `coursesFacets=${coursesFacets}${tagParams}&`: ""}${categoryFacets && Object.keys(categoryFacets)?.length > 0? "categoryFacets="+categoryFacets: ""}`;
    try{
      fetch(url).then(res => {
        res = res?.data;
        res.categories = this.state?.categories;
        delete res?.examTypes;
        delete res?.courses;
        this.setState({
          filterList: res
        })
      }).catch(err => {
        console.log("err: ", err);
      })
    }catch(err){
      console.log("err: ", err);
    }
  }

  handleFilterApply = (facetObj) => {
    const { location } = this.props;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");
    this.setState({
      dataLoader: true,
    });
    let urlString = "&";
    const facetObjectKeys = Object.keys(facetObj);
    const facetKeysArrLen = facetObjectKeys.length;
    for (let i = 0; i < facetKeysArrLen; i++) {
      let facetValue = facetObj[facetObjectKeys[i]].join(",");
      urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
    }
    const encodedQueryString = encodeURI(urlString.slice(0, -1));
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    moePayload = {...moePayload, ...getMoengagePayloadFromFacets(facetObj)}
    fireMoengageEvent("filter_applied", moePayload, "filter");
    this.props.history.push(
      getUrlPathObject(
        `/${examName}-exam-kit`,
        `?${encodedQueryString.substring(1)}`
      )
    );
    let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
    this.props
      .getDataForExamCategoryListing(
        `${examName}${replaceHyphenWithUnderscore(encodedQueryString)}${this.state.coursesFacets? (`&coursesFacets=${replaceHyphenWithUnderscore(this.state.coursesFacets.toUpperCase())}${tagParams}`): ""}`,sponsoredCategory)
      .then(() => {
        this.setState({
          dataLoader: false,
          showFilterModal: false,
          tagArr: this.createTagsFromUrl().tagArr,
        });
      });
    typeof window !== "undefined" && window.scrollTo(0, 0);
    this.getSecondaryFacetsOnFilterChange(facetObj);
  };

  getNewParamsForAllCategory = (facetKey, value) => {
    const { facetObj, tagArr } = this.state;
    this.setState({
      dataLoader: true,
    });
    if (facetKey === "allExamtype") {
      const selection = value.toLowerCase();
      this.setState({
        tagArr: [],
        facetObj: {},
      });
      this.props.history.push(
        getUrlPathObject(`/${selection}-study-materials`)
      );
      let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
      this.props.getDataForExamCategoryListing(selection + (this.state.coursesFacets? `&coursesFacets=${this.state.coursesFacets.toUpperCase()}${tagParams}`: ""),sponsoredCategory).then(() => {
        this.setState({
          dataLoader: false,
        });
      });
    } else {
      let newFacetObj = Object.assign({}, facetObj);
      let newTagArr = tagArr;
      const { location } = this.props;
      const { pathname } = location;

      const courseCategory = pathname.substring(1).split("-");
      const examName = removeElementsFromLastInArray(courseCategory, 2).join(
        "-"
      );
      const course = sessionStorage.getItem("ExamCategoryMappingId");
      if (!isSet(newFacetObj[facetKey])) {
        newFacetObj[facetKey] = [];
      }
      if (newFacetObj[facetKey].includes(value)) {
        newFacetObj[facetKey] = newFacetObj[facetKey].filter(
          (e) => e.toLowerCase() !== value.toLowerCase()
        );
        newTagArr = newTagArr.filter(
          (e) => e.toLowerCase() !== value.toLowerCase()
        );
      } else {
        newFacetObj[facetKey].push(value);
        newTagArr.push(value);
      }
      let strNew = "";
      let p;
      for (p in newFacetObj) {
        if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
          strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
        }
      }
      strNew = strNew.slice(0, -1);
      this.setState(
        {
          queryParam: strNew,
          tagArr: newTagArr,
          facetObj: newFacetObj,
          dataLoader: false
        }
      );

      // for retaining old parameters
      const queryParams = new URLSearchParams(this.props?.history?.location?.search);
      
      for (var entry of queryParams.entries()) {
        const key = entry[0];
        const value = entry[1];
        if(!strNew.includes(key) && !key.includes("utm") && !key.includes("Facets") && !key.includes("searchTerm") && !(key.length == 0)){
          strNew += `&${key}=${value}`;
        }
      }
      this.props.history.push(
        getUrlPathObject(`/${examName}-exam-kit`, `?${strNew}`)
      );
    }
    typeof window !== "undefined" && window.scrollTo(0, 0);
  };

  removeTag = (item) => {
    this.setState({
      dataLoader: true,
      showNoresult: false,
    });
    const { location } = this.props;
    const { pathname } = location;
    const { tagArr, facetObj } = this.state;
    let newFacetObj = {};
    const tempTagArr = [...tagArr];

    const courseCategory = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");

    this.setState({
      dataLoader: true,
    });

    if (tempTagArr.indexOf(item) > -1) {
      tempTagArr.splice(tempTagArr.indexOf(item), 1);
      const facetKeys = Object.keys(facetObj);
      facetKeys.map((e) => {
        newFacetObj[e] = facetObj[e].filter((f) => {
          return f.toLowerCase() !== item.toLowerCase();
        });
      });
    }
    let arr3 = [];
    let finalString = "";

    let splitArr = location.search.substring(1).split("&");
    splitArr.map((str) => {
      let newAr = str.split("=");
      const cat = newAr.shift();
      let finalAr = (newAr[0] || "").split(",");

      finalAr = finalAr.filter((val) => {
        let decodedUri = decodeURI(val).toLowerCase();
        return decodedUri !== item.toLowerCase();
      });

      const string2 = finalAr.join(",");

      if (string2.length) {
        arr3.push(cat + "=" + string2);
      }
    });
    if (arr3.length > 1) {
      finalString = arr3.join("&");
    } else {
      if (isSet(arr3[0])) {
        finalString = arr3[0];
      }
    }
    this.props.history.push(getUrlPathObject(`${pathname}`, `?${finalString}`));

    this.setState(
      {
        queryParam: finalString,
        tagArr: tempTagArr,
        facetObj: newFacetObj,
      },
      () => {
        this.props
          .getDataForExamCategoryListing(
            `${examName}&${replaceHyphenWithUnderscore(finalString)}` + (this.state.coursesFacets? "&coursesFacets=" + this.state.coursesFacets.toUpperCase(): ""),sponsoredCategory
          )
          .then(() => {
            this.setState({
              dataLoader: false,
            });
          });
      }
    );
    this.getSecondaryFacetsOnFilterChange(newFacetObj);
  };

  clearAllFilters = () => {
    const { history, location } = this.props;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");

    history.push(getUrlPathObject(`${pathname}`));
    this.setState(
      {
        dataLoader: true,
        queryParam: "",
        tagArr: [],
        facetObj: {},
        showNoresult: false,
      },
      () => {
        let tagParams = getTagIdParams( coursesFacets, this.props.globalExamList );
        this.props.getDataForExamCategoryListing(examName + (this.state.coursesFacets? `&coursesFacets=${replaceHyphenWithUnderscore(this.state.coursesFacets.toUpperCase())}${tagParams}`: ""),sponsoredCategory).then(() => {
          this.setState({
            dataLoader: false,
          });
        });
      }
    );
    this.getSecondaryFacetsOnFilterChange({});
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    fireMoengageEvent("filter_reset", moePayload, "filter");
  };

  datalayerHanler = (searchValue) => {
    const { location } = this.props;
    const { tagArr } = this.state;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-")[0];
    const category = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(category, 2).join("-");
    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get("cp_user_name");
    let email = cookie.get("cp_user_email");
    const token = cookie.get("cp_token");
    const CategoryValue =
      searchValue.data &&
      searchValue.data[0] &&
      searchValue.data[0].suggestionCategory;
    if (searchValue && typeof window != "undefined") {
      let excategory = searchValue.facets.courses;
      let exammCategory =
        searchValue.data &&
        searchValue.data[0] &&
        searchValue.data[0].packageEsEntity &&
        searchValue.data[0].packageEsEntity[0] &&
        searchValue.data[0].packageEsEntity[0].courses;
      if (token) {
        window.dataLayer.push({
          name: name || "guest",
          email: email || "",
          isMobile: getDeviceType() == "m" ? true : false,
          loginType: "email",
          isLoggedIn: true,
          examName: examName,
          pageName: "Listing",
          examCategoryName: exammCategory || "",
          category: CategoryValue || "",
        });
      } else if (!token && !user_id_cookie) {
        window.dataLayer.push({
          name: "guest",
          email: "",
          isMobile: getDeviceType() == "m" ? true : false,
          loginType: "",
          isLoggedIn: false,
          examName: examName,
          pageName: "Category",
          examCategoryName: exammCategory || "",
          category: CategoryValue || "",
        });
      }

      if (count == 1) {
        let payload = {};
        payload.user_email = cookie.get("cp_user_email") || "";
        payload.user_name = cookie.get("cp_user_name") || "";
        payload.source_screen = "";
        payload.examCategory = exammCategory;
        payload.getUtmFromStore = this.props.utmParams;
        trackMoengageEvent("list_page_view", payload);
      }
    }
  };
  
  quickLinks = (category) => {
    let hashvalue = quickLinkMapping[category.toLowerCase()];
    let element =
      typeof window != "undefined" && window.document.getElementById(hashvalue);
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: element.offsetTop - 80,
    });
  };
  getFilteredLinkKey(quickLink, array) {
    let quickLinkKey = quickLink && Object.keys(quickLink);
    array &&
      array.map((swap) => {
        let temp1 = quickLinkKey[swap[0]];
        quickLinkKey[swap[0]] = quickLinkKey[swap[1]];
        quickLinkKey[swap[1]] = temp1;
      });
    return quickLinkKey;
  }
  getFilteredDataExamCategory(data, linkArray) {
    let filteredData = [];

    linkArray.map((linkValue) => {
      let searchVal = getSubstringAfterChar(linkValue, "#");

      let obj = data.find((o) => o.category === searchVal);
      if (obj) {
        filteredData.push(obj);
      }
    });
    return filteredData;
  }

  getDataBasedOnSelectedFilter = (data, filterArray) => {
    let filteredArray = [];
    data?.map((categoryDetails, index) => {
      if(getDeviceType() !== "d"){
        if ( categoryDetails?.count ) {
          filteredArray.push(categoryDetails);
        }
      } else {
        if (
          filterArray.includes(
            replaceUnderscoreWithHyphen(categoryDetails.category)
          ) || filterArray.includes(
            replaceHyphenWithUnderscore(categoryDetails.category)
          )
        ) {
          filteredArray.push(categoryDetails);
        }
      }
    });
    return filteredArray;
  };

  render() {
    const { location, examCategoryListingData, facets, examList } = this.props;
    const {
      dataLoader,
      facetsList,
      showFilterModal,
      tagArr,
      facetObj,
      queryParam,
      similarProduct,
      showNoresult,
    } = this.state;
    const { pathname } = location;

    const courseCategory = pathname.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");
    const categories = facets
      ? facets.categories
      : facetsList && facetsList.categories;
    let quickLink = {};
    for (var key in categories) {
      if (parseInt(categories[key]) > 0) {
        quickLink[key] = categories[key];
      }
    }

    let quickLinkKey = this.getFilteredLinkKey(quickLink, [[2, 4]]);
    let quickLinkArr =
      !isEmpty(quickLinkKey) &&
      quickLinkKey?.filter((e) => e !== "Magazines#MAGAZINES")?.map(e => e === "Models 3d#MODELS_3D" ? "3D Learning#MODELS_3D" : e);

    if (examCategoryListingData?.data?.length) {
      let result = quickLinkArr?.filter((o1) =>
        examCategoryListingData?.data?.some((o2) => o1?.includes(o2?.category))
      );
      quickLinkArr = result;
    }
    if (examCategoryListingData && examCategoryListingData.facets) {
      count++;
      this.datalayerHanler(examCategoryListingData);
    }
    filterList = Object.assign({}, facets);
    delete filterList.examTypes;
    delete filterList.courses;
    if (examCategoryListingData && examCategoryListingData.data) {
      examCategoryListingData.data = this.getFilteredDataExamCategory(
        examCategoryListingData.data,
        quickLinkArr
      );
    }
    const keyDfpAdPdp = "sa_url";
    const pageHeading = removeHyphensAndInsertSpaces(examName.toUpperCase());
    return (
      <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
        {!this.props.isPaytmMini && (
          <div className={"dfd-container"}>
            <DfpAdPdp keyDfpAdPdp={keyDfpAdPdp} category={examName} />
          </div>
        )}

        <CustomHelmet
          title={this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].metaTitle
              ? this.props.seoData[pathname].metaTitle
              : `Prepare with ${pageHeading} Study Material ${new Date().getFullYear()}.${pageHeading} exam preparation ${new Date().getFullYear()} study notes.`
          }
          description={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].metaDescription
              ? this.props.seoData[pathname].metaDescription
              : `${pageHeading} Preparation ${new Date().getFullYear()} & ${pageHeading} Study Material ${new Date().getFullYear()}.Know How to Prepare for ${pageHeading} Exam ${new Date().getFullYear()} with study plan, study notes, tips and strategy.`
          }
          url={
            (this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].canonical) ||
            this.props.req_url
          }
          keywords={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].keywords
          }
          noFollow={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].noFollow
          }
          noIndex={
            this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].noIndex
          }
        />
        <BreadcrumbSchema
          items={[
            {
              title: "HOME",
              url: "/",
            },
            {
              title: pageHeading,
            },
          ]}
        />
        <CarouselSchema
          data = {this.props?.examCategoryListingData?.data}
          origin = {this.props?.origin}
          schemaType = "Carousel"
        />
        <div className={"breadcrumb-container"}>
          <BreadCrumb
            routeData={[
              { name: "Home", target: "/" },
              { name: pageHeading, target: "" },
            ]}
          />
        </div>
        <div className={"header-container"}>
          <h1 className={"listing-header"}>
            {(
              this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].pageHeading
            )
              ?.split(":")
              .join(" : ")
              ?.split(",")
              .join(", ") || `${pageHeading} Study Material ${new Date().getFullYear()}, Study Plan, Notes`}
          </h1>
          {this.props.seoData &&
            this.props.seoData[pathname] &&
            this.props.seoData[pathname].pageHeadingDescription ? (
              <div className="headingdescription seoHeadingDescription">
                {this.props.seoData[pathname].pageHeadingDescription}
              </div>
            ) : (
              <div className="headingdescription seoHeadingDescription">
                {`Buy ${pageHeading} Study Material ${new Date().getFullYear()}.Prepare from ${pageHeading} study notes,study plan shared for ${pageHeading} Exam ${new Date().getFullYear()}`}
              </div>
            )}
        </div>

        <div className={"listing-data-container"}>
          {showNoresult ? (
            <>
              {" "}
              {this.state.tagArr.length > 0 && (
                <div className={"tag-container"}>
                  <FilterTags
                    tagArr={tagArr}
                    removeTag={this.removeTag}
                  />
                  <div
                    className={"clear-all"}
                    onClick={this.clearAllFilters}
                  >
                    Clear All
                  </div>
                </div>
              )}
              <NoResultsFound handleOnClick={this.clearAllFilters} />
            </>
          ) : (
            <>
              {" "}
              {!this.props?.examCategoryListingData ? (
                <CustomLoader />
              ) : (
                <>
                  <div className={"filter-parent-container col m3 fcwidth"}>
                    {(!isEmpty(facetsList) || !isEmpty(facets)) && (
                      <SearchBlock
                        facetsList={facetsList || facets}
                        showSearch={false}
                        similarProduct={similarProduct}
                        getFilterPagesData={examCategoryListingData.data}
                        filterHeading={capitalizedString(examName)}
                        currentItem={examName}
                        getNewParamsforAllCategory={
                          this.getNewParamsForAllCategory
                        }
                        type={"examCategory"}
                        selectedTags={tagArr}
                        getUtmFromStore={this.props.utmParams}
                        // examList={examList}
                        SuffixValue={"Online Coaching"}
                      />
                    )}
                  </div>
                  <div className={"col m9 fccontwidth"}>
                    {isEmpty(examCategoryListingData) || dataLoader ? (
                      [...Array(5)].map((e, i) => <CustomSkeleton />)
                    ) : (
                      <>
                        {!isADDA && quickLinkArr?.length >= 1 && (
                          <div className="seo-tags-container">
                            <div className="seo-quick-link">Quick Links: </div>
                            {quickLinkArr.map((value, index) => {
                              return (
                                // <div className="seo-tags-value" onClick={() => this.quickLinks(getSubstringBeforeChar(value, "#"))} >{index==quickLinkArr.length-1?{getSubstringBeforeChar(value, "#")}:`    ${getSubstringBeforeChar(value, "#")}
                                <div
                                  className="seo-tags-value"
                                  onClick={() =>
                                    this.quickLinks(
                                      getSubstringBeforeChar(value, "#")
                                    )
                                  }
                                >
                                  {" "}
                                  {getSubstringBeforeChar(value, "#")}
                                  {index != quickLinkArr.length - 1 ? (
                                    <span className="ma-le-20">|</span>
                                  ) : null}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {tagArr.length > 0 && (
                          <div className={"tag-container"}>
                            <FilterTags
                              tagArr={tagArr}
                              removeTag={this.removeTag}
                            />
                            <div
                              className={"clear-all"}
                              onClick={this.clearAllFilters}
                            >
                              Clear All
                            </div>
                          </div>
                        )}
                        {!examCategoryListingData?.data.length ||
                        !isSet(
                          examCategoryListingData?.data[0].packageEsEntity
                        )|| (tagArr.length > 0 && this.state.isDataEmptyAfterFilterApply)
                         ? (
                          <NoResultsFound
                            handleOnClick={this.clearAllFilters}
                          />
                        ) : (
                          <MainListing
                            examName={capitalizedString(examName)}
                            params={this.props.match.params}
                            allCategoryData={() => {}}
                            getFilterPagesData={
                              tagArr.length > 0
                                ? this.getDataBasedOnSelectedFilter(
                                    examCategoryListingData.data,
                                    tagArr
                                  )
                                : examCategoryListingData.data
                            }
                            gotoStoreProduct={() => {}}
                            course={pageHeading}
                            referrer={"exam-page"}
                            handleDetailCardClick={this.handleDetailCardClick}
                          />
                        )}
                        {this.state?.filterList || this.props.facets ? <div
                          className={"only-mobile"}
                          onClick={this.handleFilterButton}
                        >
                          <div className={"filter-button"}>
                            <span>
                              <img
                                src={"/images/filter-bottom-icon-new.svg"}
                                alt="filter"
                                title="filter"
                              />{" "}
                              <span className={"filter-bottom-text"}>
                                Filters
                              </span>
                            </span>
                            <span className="filter-sort-btn-label">
                              <span className="filter-selected-category-wrapper">
                              {tagArr.length ? (
                                <>
                                  <span className="filter-applied-dot"></span>
                                  <span className="selected-filter-category">Filter Applied</span>
                                </>
                              ) : (
                                <span className="selected-filter-category">No Filter</span>
                              )}
                              </span>
                            </span>
                          </div>
                        </div> : ""}
                        {showFilterModal && (
                          <NewMobileFilter
                            visible={showFilterModal}
                            onCancel={this.hideFilterOnMobile}
                            facetsList={this.state.filterList || filterList}
                            type={1}
                            handleApply={this.handleFilterApply}
                            resetFilter={this.resetFilter}
                            selectedTags={tagArr}
                            facetObj={facetObj}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {this.props?.seoData[pathname]?.bottomContent && (
          <div className="bottomContentContainer">
            <div
              className="bottomContent"
              dangerouslySetInnerHTML={{
                __html: this.props.seoData[pathname].bottomContent,
              }}
            ></div>
          </div>
        )}

        {this.props.seoData &&
          this.props.seoData[pathname] &&
          this.props.seoData[pathname].faqs &&
          this.props.seoData[pathname].faqs.length >= 1 &&
          checkProperties(this.props.seoData[pathname].faqs[0]) && (
            <div className="faqcontainersearch">
              {" "}
              <Faq faqJson={this.props.seoData[pathname].faqs} />
            </div>
          )}
        {similarProduct && similarProduct.length >= 1 && (
          <div className="related-category-mobile">
            <div className="title-seo">Important Exams Study Kits</div>
            <SeoSectionMobile
              data={similarProduct.slice(0, 5)}
              examCat={""}
              isExamtypes={true}
              isRelatedCategory={false}
              SuffixValue={"Study Materials"}
            />
          </div>
        )}

        {!isEmpty(examCategoryListingData.data) && (
          <div className="related-category-mobile">
            <div className="title-seo">Sub Category</div>
            <SeoSubCategory
              data={examCategoryListingData?.data?.slice(0, 5)}
              examCat={""}
              subCategory={true}
              currentItem={examName}
              prefixValue={examName}
              isExamtypes={true}
              isRelatedCategory={false}
              SuffixValue={""}
            />
          </div>
        )}
      </div>
    );
  }
}
// server side API fetching for SSR
ExamCategoryListing.fetching = ({ dispatch, match, location }) => {
  if (location) {
    const courseCategory = location.substring(1).split("-");
    const examName = removeElementsFromLastInArray(courseCategory, 2).join("-");
    let queryParams = location.indexOf("?") > -1 ? location.split("?")[1] : "";
    return [
      dispatch(
        getDataForExamCategoryListing(
          examName + "&" + replaceHyphenWithUnderscore(queryParams),sponsoredCategory
        )
      ),
    ];
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getDataForExamCategoryListing: (data,sponsoredCategory) =>
      dispatch(getDataForExamCategoryListing(data,sponsoredCategory)),
    getExamCategoryListingWithFilter: (data,sponsoredCategory) =>
      dispatch(getExamCategoryListingWithFilter(data,sponsoredCategory)),
    clearExamCategoryData: () => dispatch(clearExamCategoryData()),
  };
}

function mapStateToProps(state, ownProps) {
  const { examName } = ownProps.match.params;
  const examCategoryListing =
    state.examCategoryListing[examName.replace(/-/g, "")] || {};
  let facets = examCategoryListing.facets;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  let req_url = state.req_url;
  let seoData = state.seoData;
  let isPaytmMini = state.isPaytmMini;
  const examList = state.header.examList;
  const origin = state?.header?.origin;
  const globalExamList = state?.header?.globalExamList;
  return {
    examCategoryListingData: examCategoryListing,
    facets,
    utmParams,
    isLoggedIn,
    userInfo,
    req_url,
    seoData,
    isPaytmMini,
    examList,
    origin,
    globalExamList,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamCategoryListing);

import React, { useEffect, useRef, useState } from 'react';

const PDFPreview = ({pdfUrl}) => {
  const [loader, setLoader] = useState("")
  const containerRef = useRef(null);
  const canvasStyle = {
    width: '100%',
    height: "100%"
  }
  const outerStyle = {
    display: "flex",
    flexDirection:"column",
    maxHeight: "80vh",
    overflow: "auto",
    padding: "15px",
    width: "100%",
    alignItems: "center"
  }
  // < script src = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js" defer></ >

  async function getNumberOfPages(pdfUrl) {
    const loadingTask = window?.pdfjsLib?.getDocument(pdfUrl);
    const pdfDocument = await loadingTask.promise;
    return pdfDocument.numPages;
  }

  useEffect(() => {
    setLoader("")
    const renderPDF = async () => {
      try {
        // Load the PDF document
        setLoader("Wait PDF is Loading...")
        const pdf = await window?.pdfjsLib?.getDocument(pdfUrl).promise;
        // Get the first page of the PDF
        
        const totalPages = await getNumberOfPages(pdfUrl)
        for(let i = 1;i<totalPages;i++) {
          const page = await pdf.getPage(i);
          // Get the container element and create a canvas element
          const container = containerRef.current;
          const _div = document.createElement('div')
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          // Set canvas dimensions based on the PDF page dimensions
          const viewport = page.getViewport({ scale: 1.0 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          canvas.id = "pdf-preview-canvas"
          canvas.style.position = canvasStyle.position;
          canvas.style.width = canvasStyle.width;
          canvas.style.height = canvasStyle.hei;

          // Render the PDF page onto the canvas
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext);
          setLoader("")
          _div.appendChild(canvas)
          // Append the canvas to the container element
          container.appendChild(_div);
        }
      } catch (error) {
        setLoader("Failed to load PDF")

        console.error('Failed to load PDF:', error);
      }
    };

    renderPDF();
  }, [pdfUrl]);

  return <div style={outerStyle} ref={containerRef} >{loader}</div>;
};

export default PDFPreview;

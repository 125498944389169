import React, { useEffect, useState } from "react";
import DecipherString from "./DecipherString";
import fetch from "../../../../data/utils/fetch";
import {
  STOREFRONT_URI,
  pageName,
  stagingMainUrl,
  testSeriesURLProd,
} from "../../../../../constants/appConfig";
import CustomLoader from "../../../library/CustomLoader";
import VideoSolution from "./VideoSolution";
import { useExternalScript } from "../../../useExternalScript";
import { FPM_PkgId, TEST_SERIES_URL } from "../../../../../constants/appUrls";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  decipherStrings,
  questionTypesLabel,
  textSizeClassKeyNames,
  textSizeOptions,
} from "./util";
import {
  checkCorrectFitbAns,
  checkNumberIsinRange,
  getTestAnalysisResultForReattempt,
  isADDA,
  testWithOnlyDtb,
} from "../../../../data/utils/helpers";
import QuestionsAnalysis from "./TestSolComponents/QuestionsAnalysis";
import ChooseQuestions from "./TestSolComponents/ChooseQuetions";
import ExamTestHeader from "./ExamTest/ExamTestHeader";
import { ChangeTextSize } from "./ExamTest/ExamInstructionTextContent";

const TestSolution = ({
  data,
  language,
  changeLanguage,
  title,
  packageId,
  mappingId,
  sfJson = { sfJson },
}) => {
  const [questionsArray, setQuestionsArray] = useState(
    data[language]?.ques?.list
  );
  const [showResponsePanel, setShowResponsePanel] = useState(true);
  const toogleResponsePanel = () => {
    setShowResponsePanel(!showResponsePanel);
  };

  const [averageResponseObj, setAverageResponseObj] = useState();
  const [userResponseObj, setUserResponseObj] = useState({});
  const [topperResponseObj, setTopperResponseObj] = useState();
  const [section, setSection] = useState(0);
  const [currentQues, setCurrentQues] = useState(0);
  const [fixedMockTestState, setFixedMockTestState] = useState({});
  const userDetails = useSelector((store) =>
    store.header.userInfo ? store.header.userInfo : {}
  );
  const userId = userDetails ? userDetails.id : "";
  const jwtTokenNew = useSelector((state) => state.header.jwtTokenNew);
  const [dtbAnswerStr, setDtbAnswerStr] = useState("");
  const [dtbModelStr, setDtbModelStr] = useState("");
  const [dtbInfo, setDtbInfo] = useState({});

  const jQueryScript =
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js";
  useExternalScript(jQueryScript);
  const [selectedTextSize, setSelectedTextSize] = useState(textSizeOptions[2]);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  let freeContentFlag = urlParams.get("freeContent");
  let isFixedMock = urlParams.get("fixedMockResult");
  const examName = urlParams.get("exam");
  const history = useHistory();
  const optionAlphabetMapping = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
  };

  useEffect(() => {
    if (!Object.keys(dtbInfo)?.length > 0) {
      testWithOnlyDtb(packageId, mappingId).then((res) => {
        setDtbInfo(res);
      });
    }
  }, []);
  useEffect(() => {
    setQuestionsArray(data[language].ques.list);
  }, [language]);
  const formattedTime = (t) => {
    // LabelsList []
    let d = t / 1000;
    var m = Math.floor(d / 60);
    var s = Math.floor((d % 3600) % 60);
    var mDisplay = m > 0 ? m.toString().padStart(2, "0") + ": " : "00:";
    var sDisplay = s > 0 ? s.toString().padStart(2, "0") + "" : ``;
    return mDisplay + sDisplay;
  };

  useEffect(() => {
    if (isFixedMock) {
      getFixedMockUserData();
    } else {
      getAverageAndUserTestData();
      getTopperTestData();
    }
  }, [isFixedMock]);

  const getFixedMockUserData = () => {
    let baseUrl;
    if (TEST_SERIES_URL === testSeriesURLProd) {
      baseUrl = TEST_SERIES_URL;
    } else {
      baseUrl = stagingMainUrl;
    }
    fetch(
      `${baseUrl}/${packageId}/${mappingId}/results/${userId}.json`,
      "GET",
      undefined,
      undefined,
      undefined,
      {
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
        Origin: stagingMainUrl?.slice(8),
        "x-jwt-token-new": jwtTokenNew,
      }
    )
      .then((res) => {
        if (res.data) {
          setFixedMockTestState(res.data["submitted-state"].testState);
          if (isADDA) {
            setUserResponseObj(
              createResponseObjectFromArray(
                res.data["submitted-state"].testState
              )
            );
          } else {
            const testCalcData = getTestAnalysisResultForReattempt(
              questionsArray,
              createResponseObjectFromArray(
                res.data["submitted-state"].testState.filter(
                  (val) => val != null
                )
              ),
              data
            );

            setUserResponseObj(testCalcData?.testStateData);
          }
        } else {
          setFixedMockTestState({});
          setUserResponseObj({});
        }
      })
      .catch((err) => {
        console.log(err);
        setFixedMockTestState({});
        setUserResponseObj({});
      });
  };

  const getTopperTestData = () => {
    // toogleLoadingState(0);
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/view-result?packageId=${packageId}&mappingId=${mappingId}`,
      "GET"
    ).then(
      (res) => {
        if (
          res.data &&
          res.data.toppersList &&
          res.data.toppersList[0] &&
          res.data.toppersList[0].email
        ) {
          fetch(
            `${STOREFRONT_URI}/api/v1/test-series/view-submitted-state?mappingId=${mappingId}&packageId=${res.data.toppersList[0].packageId}&email=${res.data.toppersList[0].email}`,
            "GET"
          ).then(
            (res) => {
              if (res.data) {
                setTopperResponseObj(
                  createResponseObjectFromArray(res.data.testState)
                );
              } else {
                setTopperResponseObj({});
              }
            },
            (err) => {
              setTopperResponseObj({});
            }
          );
        } else {
          setTopperResponseObj({});
        }
      },
      (err) => {
        setTopperResponseObj({});
      }
    );
  };

  // const getTopperInfo = () => {};
  const getAverageAndUserTestData = () => {
    if (freeContentFlag) {
      let data = localStorage.getItem("testCalcData");
      let data2 = JSON.parse(data);
      setUserResponseObj(data2?.testStateData);
      return;
    }
    // toogleLoadingState(1);
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/view-submitted-state?mappingId=${mappingId}&packageId=${packageId}`,
      "GET"
    ).then(
      (res) => {
        // toogleLoadingState(1);
        if (res.data) {
          setAverageResponseObj(res.data.questionData || []);
          setUserResponseObj(createResponseObjectFromArray(res.data.testState));
        } else {
          setAverageResponseObj({});
          setUserResponseObj({});
        }
      },
      (err) => {
        // toogleLoadingState(1);
        setAverageResponseObj({});
        setUserResponseObj({});
      }
    );
  };
  const createResponseObjectFromArray = (resArr) => {
    return resArr.reduce((data, res) => {
      data[res.questionMapping] = res;
      return data;
    }, {});
  };

  const sectionChange = (e) => {
    setSection(+event.target.id);
  };
  const next = () => {
    if (currentQues == data.meta.sections[section].secTotalq - 1) {
      // set Question to 0;
      // change section;
      if (section == data.meta.sections.length - 1) {
        setSection(0);
      } else {
        setSection(section + 1);
      }
      setCurrentQues(0);
    } else {
      setCurrentQues(currentQues + 1);
    }
  };
  const exit = () => {
    const fixedMockResult =
      urlParams.get("fixedMockResult") || parseInt(packageId) === FPM_PkgId;
    const freeContent = urlParams.get("freeContent");
    const ppc = urlParams.get("ppc");
    const dtb = urlParams.get("dtb");
    const url = `/my-test-analysis/${title}/${packageId}/${mappingId}`;
    history.push({
      pathname: url,
      search: `?${fixedMockResult ? "fixedMockResult=true" : ""}${
        freeContent ? "&freeContent=true" : ""
      }${sfJson ? `&sfJsonPreview=${sfJson}` : ""}${
        examName ? `&exam=${examName}` : ""
      }${ppc ? `&ppc=${ppc}` : ""}${dtb ? `&dtb=${dtb}` : ""}`,
      state: { section: "solution" },
    });
  };
  const selectQues = (index) => {
    setCurrentQues(index);
  };

  //    if(loading.includes(true))return '';
  const getQuestionDifficulty = (question) => {
    // if(freeContentFlag) return;
    let qId = question.qMapId;
    let correctAns = question.opt.findIndex((item) => item.co == 1);
    let difficulty = "MODERATE";
    let questionData = averageResponseObj && averageResponseObj[qId];
    if (!questionData) return difficulty;
    let corretAnsData = questionData.answerData.find(
      (item) => item.selectedAnswer == correctAns
    );
    if (!corretAnsData) return "";
    var difficultyValue =
      ((100 - questionData.unAttempted) * corretAnsData.percent) / 100;
    if (difficultyValue > 50) {
      difficulty = "EASY";
    } else if (corretAnsData.percent <= 30) {
      difficulty = "DIFFICULT";
    }
    return difficulty;
  };
  const timeSpent = (question) => {
    let timeSpentArray = [0, 0, 0]; // [user Time,Topper Time,Average time]
    let userArray = ["You", "Topper", "Avg"];
    if (freeContentFlag || isFixedMock) {
      userArray = ["You"];
      timeSpentArray = [0];
    }
    let qId = question.qMapId;
    if (userResponseObj[qId] && userResponseObj[qId].timeTaken)
      timeSpentArray[0] = userResponseObj[qId].timeTaken;
    // timeSpentArray[0] = microsecondsToHms(userResponseObj[qId].timeTaken, [
    //   ":",
    //   ":",
    //   "",
    // ]);
    if (!freeContentFlag) {
      if (!isFixedMock) {
        timeSpentArray[2] =
          averageResponseObj && averageResponseObj[qId]
            ? averageResponseObj[qId].averageTime
            : 0;
        timeSpentArray[1] =
          topperResponseObj && topperResponseObj[qId]
            ? topperResponseObj[qId].timeTaken
            : 0;
      }
    }

    return (
      <>
        {timeSpentArray.map((time, index) => (
          <span key={index}>
            {" "}
            {formattedTime(time)} ({userArray[index]})
          </span>
        ))}
      </>
    );
  };
  let prevSectionsLen = data.meta.sections
    .slice(0, section)
    .reduce((index, val) => {
      return index + val.secTotalq;
    }, 0);
  let currentQuesIndex = prevSectionsLen + currentQues;
  let questionList = questionsArray.slice(
    prevSectionsLen + 0,
    prevSectionsLen + data.meta.sections[section].secTotalq
  );

  const getDtbAnswFromTemp = (templateUrl) => {
    return fetch(templateUrl, "GET")
      .then((res) => res.text())
      .then((resp) => {
        return resp;
      });
  };
  const dtbAnswerHandler = async (params = {}, flag = "") => {
    let text = "";
    if (params) {
      const { dtbAnswerUrl, dtbAnswer, dtbAns } = params;
      if (dtbAns || dtbAnswerUrl || dtbAnswer) {
        text =
          dtbAns == "-1"
            ? ""
            : dtbAns ||
              decipherStrings(dtbAnswerUrl) ||
              decipherStrings(dtbAnswer);
        if (text.includes("http://") || text.includes("https://")) {
          // if text is type of url,then get DTB html asynchronously.
          const result = await getDtbAnswFromTemp(text);
          if (flag == "userAns") {
            setDtbAnswerStr(result);
          } else {
            setDtbModelStr(result);
          }
        } else {
          if (flag == "userAns") {
            setDtbAnswerStr(text);
          } else {
            setDtbModelStr(text);
          }
        }
      }
    }
    return "";
  };
  // change fontSize handler
  const handleChangeTextSize = (textSize = "") => {
    setSelectedTextSize(textSize);
  };
  useEffect(() => {
    // user answer calling
    if (
      Object.keys(userResponseObj).length > 0 &&
      userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
    ) {
      setDtbAnswerStr("");
      dtbAnswerHandler(
        userResponseObj[questionsArray[currentQuesIndex]?.qMapId],
        "userAns"
      );
    }
    // model answer calling
    if (questionsArray.length > 0 && questionsArray[currentQuesIndex]) {
      setDtbModelStr("");
      dtbAnswerHandler(questionsArray[currentQuesIndex], "modelAns");
    }
  }, [currentQuesIndex, userResponseObj, questionsArray]);

  // console.log("questionsResponseObj", questionsArray[currentQuesIndex]);
  // console.log(
  //   "userResponseObj",
  //   userResponseObj[questionsArray[currentQuesIndex].qMapId]
  // );
  if (!userResponseObj || (isFixedMock ? false : !topperResponseObj))
    return <CustomLoader />;
  return (
    <div
      className={`test-container test-solution ${
        examName ? "examTest-container" : ""
      }`}
    >
      {/* CSS library for katex formulas */}
      <link
        rel="stylesheet"
        href="https://storecdn.adda247.com/katex/katex.min.css"
      />
      {/*  normal tests header*/}
      {!examName && (
        <div className="test-header">
          <div className="adda-logo-solution">
            {isADDA ? (
              <img
                src="/images/header-logo.svg"
                className="logo-img"
                alt={`${pageName[0]}-logo`}
                title={pageName[0]}
                width="172px"
                height="40px"
              />
            ) : (
              <img
                src={"/images/sankalpB-logo.svg"}
                className="logo-img"
                alt={`${pageName[0]}-logo`}
                title={pageName[0]}
              />
            )}
          </div>
          <div className="resultScreenTitle">{decodeURIComponent(title)}</div>
          <div className="right-dpns">
            <ChangeTextSize
              selectedTextSize={selectedTextSize}
              handleChangeTextSize={handleChangeTextSize}
            />
            <div className="th-select-language">
              View In:
              <select
                id="language"
                className="language-select"
                value={language}
                onChange={changeLanguage}
              >
                {Object.keys(data).map((key, index) => {
                  if (["meta", "orgName"].includes(key) || !data[key]) {
                    /// bcoz stupid API :(
                    return "";
                  }
                  return (
                    <option value={key} key={index}>
                      {key}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      )}
      {/*exam based test header*/}
      {examName && (
        <>
          <ExamTestHeader
            title={decodeURIComponent(title)}
            examName={examName}
            data={data}
            hideViewInstruction={true}
          />
        </>
      )}
      <div className="test-info">
        <div className="testincleft">
          <div className="test-sections">
            {data.meta.sections.map((sectionInfo, index) => (
              <div className="test-sections-item" key={index}>
                <button
                  id={index}
                  className={index == section ? "blue" : "black"}
                  onClick={sectionChange}
                >
                  {sectionInfo.secDN}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="test-user-info">
          <div className="user-image">
            <img
              src={`https://storecdn.${pageName[0]}.com/tsp-candidate.jpg`}
              width={"60px"}
            ></img>
          </div>
          <div className="time-status">
            {/* <span>{userDetails.name}</span> */}
          </div>
        </div>
      </div>
      <div className="test-content">
        <div
          className={
            showResponsePanel
              ? "testQuesLeftContent"
              : "testQuesLeftContent fullWidth"
          }
        >
          <div className="testQuesLand">
            {examName && (
              <>
                {/* show questionType for exam based test */}
                <div className="testMultiQues">
                  <span className="questiontype-details">
                    <span className="questionType">Question Type: </span>
                    {
                      questionTypesLabel[
                        questionsArray[currentQuesIndex]?.questionType || 1
                      ]
                    }
                  </span>
                </div>

                <div className="testQesInnerViewIn">
                  <ChangeTextSize
                    selectedTextSize={selectedTextSize}
                    handleChangeTextSize={handleChangeTextSize}
                  />
                  <div className="th-select-language">
                    View In:
                    <select
                      id="language"
                      className="language-select"
                      value={language}
                      onChange={changeLanguage}
                    >
                      {Object.keys(data).map((key, index) => {
                        if (["meta", "orgName"].includes(key) || !data[key]) {
                          return "";
                        }
                        return (
                          <option value={key} key={key}>
                            {key}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            )}
            <div className="wrap-top-marks">
              {/* question No. index showcase only when exam based text */}
              {examName && (
                <span className="exam-question-no">
                  Question No. {prevSectionsLen + currentQues + 1}
                </span>
              )}
              {data?.["meta"]?.sections?.[section]?.secOpt &&
              userResponseObj?.[questionsArray?.[currentQuesIndex]?.qMapId] &&
              userResponseObj?.[questionsArray?.[currentQuesIndex]?.qMapId]
                ?.evaluated != undefined ? (
                userResponseObj?.[questionsArray?.[currentQuesIndex]?.qMapId]
                  ?.evaluated ? (
                  ""
                ) : (
                  <span className="evaluated-info">
                    <img
                      src={"/images/icon-not-evaluated.svg"}
                      width="24px"
                      height="24px"
                      alt="not-evaulated"
                    />{" "}
                    Not Evaluated
                  </span>
                )
              ) : (
                ""
              )}
            </div>
            {/* End marking */}
          </div>
          <div
            className={`tc-question-section ${textSizeClassKeyNames[selectedTextSize]}`}
          >
            {userResponseObj && topperResponseObj && (
              <div className="tc-question-analytics">
                {questionsArray[currentQuesIndex].questionType !== 6 && (
                  <div
                    className={`difficulty-level  ${
                      freeContentFlag ? "displayNone " : ""
                    }${getQuestionDifficulty(
                      questionsArray[currentQuesIndex]
                    )?.toLowerCase()}`}
                  >
                    {getQuestionDifficulty(questionsArray[currentQuesIndex])}
                  </div>
                )}
                <div style={{ fontSize: "13px" }}>
                  Time Spent
                  {timeSpent(questionsArray[currentQuesIndex])}
                </div>
              </div>
            )}
            <div className="tc-question">
              {/* hide question No. for non-exam based test */}
              {!examName && (
                <span className="tc-question-no">
                  Q.{prevSectionsLen + currentQues + 1}
                  {"    "}
                </span>
              )}
              {questionsArray[currentQuesIndex] &&
                questionsArray[currentQuesIndex].pre && (
                  <DecipherString
                    text={questionsArray[currentQuesIndex].pre.t}
                  />
                )}
              <div style={{ marginTop: "5px" }}>
                <DecipherString
                  text={
                    questionsArray[currentQuesIndex].q &&
                    questionsArray[currentQuesIndex].q.t
                  }
                />
              </div>
            </div>
            <div className="tc-answer">
              {/* mcq */}
              <ol type="A">
                {questionsArray[currentQuesIndex].questionType == 1 &&
                  questionsArray[currentQuesIndex].opt.map((option, index) => {
                    let questionId = questionsArray[currentQuesIndex].qMapId;
                    let userAnswer =
                      userResponseObj[questionId] &&
                      userResponseObj[questionId].selectedAns;
                    return (
                      <li
                        className={`${option.co ? "correct-ans" : ""} ${
                          userAnswer == index ? "user-ans" : ""
                        }`}
                        key={index}
                      >
                        <span>
                          <DecipherString text={option.t} />
                        </span>
                      </li>
                    );
                  })}
              </ol>
              {/* FITB */}
              {questionsArray[currentQuesIndex].questionType == 2 && (
                <div>
                  {userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                    ?.fitbAns != null ? (
                    <div className="fitbitanswerwrap">
                      <div className="answerSolHeading">Your Answer</div>
                      <div className="fitbitanswerstatus">
                        {checkCorrectFitbAns(
                          questionsArray[currentQuesIndex]?.fitbAnswer?.map(
                            (str) => str.trim().toLowerCase()
                          ),
                          userResponseObj[
                            questionsArray[currentQuesIndex]?.qMapId
                          ]?.fitbAns?.toLowerCase()
                        ) ? (
                          <img
                            src="/images/test-solution/green-tick-round.svg"
                            alt=""
                          />
                        ) : (
                          <img
                            src="/images/test-solution/red-cross-round.svg"
                            alt=""
                          />
                        )}
                        {
                          userResponseObj[
                            questionsArray[currentQuesIndex]?.qMapId
                          ]?.fitbAns
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="notattempt-answer">
                      *You have not attempted this question
                    </div>
                  )}
                  <div className="fitbitanswerwrap">
                    <div className="answerSolHeading">Correct Answer</div>
                    <div className="fitbitanswerstatus">
                      <img
                        src="/images/test-solution/green-tick-round.svg"
                        alt=""
                      />
                      {questionsArray[currentQuesIndex]?.fitbAnswer?.join(",")}
                    </div>
                  </div>
                </div>
              )}
              {/* mcmc */}
              <div className="user-answer-panel">
                {(userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                  ?.fitbAns == -1 ||
                  userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                    ?.fitbAns == null) &&
                  questionsArray[currentQuesIndex].questionType == 3 && (
                    <div
                      className="notattempt-answer"
                      style={{ marginBottom: "20px" }}
                    >
                      *You have not attempted this question
                    </div>
                  )}
                {questionsArray[currentQuesIndex].questionType == 3 &&
                  questionsArray[currentQuesIndex].opt.map((option, index) => {
                    let questionId = questionsArray[currentQuesIndex]?.qMapId;
                    let userAnswer = userResponseObj[questionId]?.fitbAns,
                      arrInd =
                        userAnswer != -1 || userAnswer !== null
                          ? userAnswer?.split(",")
                          : [];
                    return (
                      <div
                        className="user-answer-panel"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          margin: "0,0,10px,0",
                        }}
                        key={index}
                      >
                        {arrInd?.length &&
                        (userAnswer != -1 || userAnswer !== null) ? (
                          <div className="leftsidesolution">
                            {" "}
                            {index == 0 && (
                              <div className="answerSolHeading">
                                Your Answer :
                              </div>
                            )}
                            <div className="yourAnswerSide">
                              <div className="answerByYou">
                                {arrInd?.some((e) => e == index) && (
                                  <img
                                    src={"/images/test-solution/tick-blue.svg"}
                                    alt="tick-blue.svg"
                                  />
                                )}
                              </div>
                              <div className="ansbyyoutext">
                                {optionAlphabetMapping[index + 1]
                                  ? optionAlphabetMapping[index + 1]
                                  : index + 1}
                                . <DecipherString text={option.t} />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="rightsidesolution">
                          {index == 0 && (
                            <div className="answerSolHeading">Solution :</div>
                          )}
                          <div className="yourAnswerSide">
                            <div className="ansbyyoutext">
                              {optionAlphabetMapping[index + 1]
                                ? optionAlphabetMapping[index + 1]
                                : index + 1}
                              . <DecipherString text={option.t} />
                            </div>
                            <div className="answerByYou">
                              {option.co ? (
                                <img
                                  src={"/images/test-solution/tick-green.svg"}
                                  alt="tick-green.svg"
                                />
                              ) : (
                                <img
                                  src={"/images/test-solution/cross-red.svg"}
                                  alt="cross-red.svg"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* DF */}
              <div>
                {/* $ separated*/}
                {questionsArray[currentQuesIndex].questionType == 4 && (
                  <div>
                    <div>
                      {userResponseObj[questionsArray[currentQuesIndex].qMapId]
                        ?.fitbAns != null ? (
                        <div className="fitbitanswerwrap">
                          <div className="answerSolHeading">Your Answer</div>
                          <div className="fitbitanswerstatus">
                            {questionsArray[
                              currentQuesIndex
                            ].fitbAnswer.includes(
                              userResponseObj[
                                questionsArray[currentQuesIndex].qMapId
                              ]?.fitbAns
                            ) ? (
                              <img
                                src="/images/test-solution/green-tick-round.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="/images/test-solution/red-cross-round.svg"
                                alt=""
                              />
                            )}
                            {
                              userResponseObj[
                                questionsArray[currentQuesIndex]?.qMapId
                              ]?.fitbAns
                            }
                          </div>
                        </div>
                      ) : (
                        <div className="notattempt-answer">
                          *You have not attempted this question
                        </div>
                      )}
                    </div>
                    {!questionsArray[currentQuesIndex]?.fitbAnswer?.includes(
                      userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                        ?.fitbAns
                    ) && (
                      <div className="fitbitanswerwrap">
                        <div className="answerSolHeading">Correct Answer</div>
                        <div className="fitbitanswerstatus">
                          <img
                            src="/images/test-solution/green-tick-round.svg"
                            alt=""
                          />{" "}
                          {questionsArray[currentQuesIndex]?.fitbAnswer[0]}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* DFR */}
              <div>
                {questionsArray[currentQuesIndex]?.questionType == 5 && (
                  <div>
                    {userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                      ?.fitbAns != null ? (
                      <div className="fitbitanswerwrap">
                        <div className="answerSolHeading">Your Answer</div>
                        <div className="fitbitanswerstatus">
                          {checkNumberIsinRange(
                            questionsArray[
                              currentQuesIndex
                            ]?.fitbAnswer[0].split("<>"),
                            userResponseObj[
                              questionsArray[currentQuesIndex]?.qMapId
                            ]?.fitbAns
                          ) ? (
                            <img
                              src="/images/test-solution/green-tick-round.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/images/test-solution/red-cross-round.svg"
                              alt=""
                            />
                          )}
                          {
                            userResponseObj[
                              questionsArray[currentQuesIndex]?.qMapId
                            ]?.fitbAns
                          }
                        </div>
                      </div>
                    ) : (
                      <div className="notattempt-answer">
                        *You have not attempted this question
                      </div>
                    )}
                    {!checkNumberIsinRange(
                      questionsArray[currentQuesIndex]?.fitbAnswer[0].split(
                        "<>"
                      ),
                      userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                        ?.fitbAns
                    ) && (
                      <div className="fitbitanswerwrap">
                        <div className="answerSolHeading">Correct Answer</div>
                        {questionsArray[currentQuesIndex]?.fitbAnswer?.map(
                          (val, ind) => {
                            let const_data = val.split("<>").join(" to ");
                            return (
                              <div
                                key={`i_${ind}`}
                                className="fitbitanswerstatus"
                              >
                                <img
                                  src="/images/test-solution/green-tick-round.svg"
                                  alt=""
                                />{" "}
                                {const_data}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* DTB */}
              <div>
                {questionsArray[currentQuesIndex]?.questionType == 6 && (
                  <div className="adda-accordion-tabs">
                    {userResponseObj[questionsArray[currentQuesIndex]?.qMapId]
                      ?.dtbAns != null && (
                      <div className="adda-accordion-tab">
                        <input
                          className="adda-accordion"
                          type="checkbox"
                          id="chck1"
                        />
                        <label
                          className="adda-accordion-tab-label"
                          htmlFor="chck1"
                        >
                          Your Answer
                        </label>
                        <div className="adda-accordion-tab-content">
                          <div
                            style={{
                              overflowY: "auto",
                              maxHeight: "400px",
                              padding: "20px",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: dtbAnswerStr }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* open model answer by default when user not attempt dtbAnswer */}
                    <div className="adda-accordion-tab">
                      {dtbAnswerStr ? (
                        <input
                          className="adda-accordion"
                          type="checkbox"
                          id="chck2"
                        />
                      ) : (
                        <input
                          className="adda-accordion"
                          type="checkbox"
                          id="chck2"
                          // checked={true}
                        />
                      )}
                      <label
                        className="adda-accordion-tab-label"
                        htmlFor="chck2"
                      >
                        Model Answer
                      </label>
                      <div className="adda-accordion-tab-content">
                        <div
                          style={{
                            overflowY: "auto",
                            maxHeight: "400px",
                            padding: "20px",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: dtbModelStr }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  // <></>
                )}
              </div>
              {/* accordion */}
              {questionsArray[currentQuesIndex]?.questionType == 0 &&
                questionsArray[currentQuesIndex]?.opt?.map((option, index) => {
                  let questionId = questionsArray[currentQuesIndex]?.qMapId;
                  let userAnswer =
                    userResponseObj[questionId] &&
                    userResponseObj[questionId]?.selectedAns;
                  return (
                    <div
                      className={`${option.co ? "correct-ans" : ""} ${
                        userAnswer == index ? "user-ans" : ""
                      }`}
                      key={index}
                    >
                      <span>
                        {index + 1}. <DecipherString text={option?.t} />
                      </span>
                    </div>
                  );
                })}
              {!questionsArray[currentQuesIndex]?.questionType &&
                questionsArray[currentQuesIndex]?.opt?.map((option, index) => {
                  let questionId = questionsArray[currentQuesIndex]?.qMapId;
                  let userAnswer =
                    userResponseObj[questionId] &&
                    userResponseObj[questionId]?.selectedAns;
                  return (
                    <div
                      className={`${option.co ? "correct-ans" : ""} ${
                        userAnswer == index ? "user-ans" : ""
                      }`}
                      key={index}
                    >
                      <span>
                        {index + 1}. <DecipherString text={option?.t} />
                      </span>
                    </div>
                  );
                })}
            </div>
            {questionsArray[currentQuesIndex].so && (
              <div className="tc-solution">
                <span>
                  <b>Explanation:</b>

                  <DecipherString
                    text={questionsArray[currentQuesIndex].so.t}
                  />
                </span>
              </div>
            )}
          </div>
        </div>

        {!showResponsePanel && (
          <div className="tcq-hide-panel-L">
            <div className={"chevronLeftM"} onClick={toogleResponsePanel}>
              {" "}
            </div>
          </div>
        )}
        <div
          className={`tc-questions-status ${
            showResponsePanel ? "" : "hide-panel"
          }`}
        >
          <QuestionsAnalysis
            dtbInfo={dtbInfo}
            questionList={questionList}
            userResponseObj={userResponseObj}
            optionalSectionFlag={data?.["meta"]?.sections[section]?.secOpt}
          />
          <div className="tcq-hide-panel-R">
            <div className={" chevronRightM"} onClick={toogleResponsePanel} />
          </div>
          <ChooseQuestions
            questionList={questionList}
            userResponseObj={userResponseObj}
            onClick={selectQues}
            prevSectionsLen={prevSectionsLen}
          />
        </div>
      </div>
      <div className="test-actions">
        <div className={showResponsePanel ? "" : "test-actions-right"}>
          {!freeContentFlag && (
            <div className="test-action-item">
              <VideoSolution
                packageId={packageId}
                mappingId={mappingId}
                questionId={currentQuesIndex}
              />
            </div>
          )}
          <div className="test-action-item ">
            <button
              className="red"
              style={{ minWidth: "100px" }}
              onClick={next}
            >
              Next
            </button>{" "}
            <button className="exit-button" onClick={exit}>
              Exit
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSolution;

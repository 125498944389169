import React from "react";
import CustomLoader from "./CustomLoader";

const AButton = ({text,contained,onClick,color,disabled,olc,loading})=>{
    var classValue= text === "COMING SOON" ? "a-button-no-cursor" : "a-button"
    return (
        <div  onClick={disabled?undefined:()=>onClick(olc)}className={`${classValue} ${contained?'contained':''}  ${disabled?'disabled':''} ${color?color:''}`}>
            
      {      loading ? (<CustomLoader buttonSpinner={true} />) :  <span>{text}</span>}
        </div>
    )
}
export default AButton;

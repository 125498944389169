import reducerRegistry from '../../reducerRegistry';
import types from './types';
import {isEmpty} from '../../utils/helpers';

function courseCategoryListing(
    state = {

    },
    action
) {
    switch (action.type) {
    case types.GET_COURSE_CATEGORY_LISTING: {

        const newObj = {};
        if(action.payload && action.payload.data && action.payload.data[0]) {
            newObj[action.meta.courseType] = {
                data: action.payload.data,
                facets: action.payload.data[0].facetsList,
                title:action.payload.title
            };
        }
        // TODO: remove the following block after fix from sanjay
        else {
            newObj[action.meta.courseType] = {
                data: [],
                facets: {}
            };
        }
        return {
            ...newObj
        };
    }

    case types.CLEAR_ALL: {
        state = {};
        return {
            ...state
        };
    }

    default:
        return state;
    }
}
reducerRegistry.register('courseCategoryListing', courseCategoryListing);
export default courseCategoryListing;

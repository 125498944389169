import React, { Component } from "react";
import {
  productKeys,
  productNames,
} from "../../../../constants/appConstants";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isEmpty,
  getMoengageMetadata,
  removeSpacesAndInsertHyphens,
  getExamCategoryId,
  removeHyphensAndInsertSpaces,
  isADDA,
} from "../../../data/utils/helpers";
import { pageName } from "../../../../constants/appConfig";
import { Link } from "react-router-dom";
import {
  SeoSectionMobile,
  SeoSectionWeb,
} from "../../library/SeoBlock/SeoBlock";
import { SeoSubCategoryWeb } from "../../library/SeoBlock/SeoSubCategory";

class SearchBlock extends Component {
  constructor(props) {
    super();
    this.state = {
      mainCategoryItem: "",
      showSearchInput: false,
      selectedId: "",
      checkedItem: "",
      isExamCategoryChecked: true,
      showFilterItemsExamCategory: true,
      showFilterItemsExams: true,
      showFilterItemsSubjects: true,
      showFilterItemsProductCategory: true,
      showFilterItemsFaculty: true,
      showFilterItemsTime: true,
      examObj: {},
      subjectsObj: {},
      productCategoryObj: {},
      facultyObj: {},
      timeObj: {},
      filterParamsObj: {},

      examCatObj: {},
      selectedTags: props.selectedTags,
    };
  }

  examCategoryArray = isADDA ? [
    { name: "Banking" }, { name: "SSC" }, { name: 'Railways' },
    { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }
  ] : [{name: "Class12-JEE"}, {name: "Dropper-JEE"},{name: "Class12-NEET"}, {name: "Dropper-NEET"}];

  uncheckAllBoxes = () => {
    this.setState({
      examObj: {},
      subjectsObj: {},
      productCategoryObj: {},
      facultyObj: {},
      timeObj: {},
      filterParamsObj: {},
      examCatObj: {},
    });
  };

  toggleFilterItemsExamCategory = () =>
    this.setState((prevState) => ({
      showFilterItemsExamCategory: !prevState.showFilterItemsExamCategory,
    }));
  toggleFilterItemsExams = () =>
    this.setState((prevState) => ({
      showFilterItemsExams: !prevState.showFilterItemsExams,
    }));
  toggleFilterItemsSubjects = () =>
    this.setState((prevState) => ({
      showFilterItemsSubjects: !prevState.showFilterItemsSubjects,
    }));
  toggleFilterItemsProductCategory = () =>
    this.setState((prevState) => ({
      showFilterItemsProductCategory: !prevState.showFilterItemsProductCategory,
    }));
  toggleFilterItemsFaculty = () =>
    this.setState((prevState) => ({
      showFilterItemsFaculty: !prevState.showFilterItemsFaculty,
    }));
  toggleFilterItemsTime = () =>
    this.setState((prevState) => ({
      showFilterItemsTime: !prevState.showFilterItemsTime,
    }));

  handleFilterChange = (categoryFacet, e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.getUtmFromStore = this.props.getUtmFromStore || "";
    payload.productCategory_selected = this.props.productCat || "";
    payload.productType = this.props.productCat;
    payload.sort_selected = this.props.sortBy;
    if (categoryFacet == "allExamtype" || categoryFacet == "coursesFacets") {
      if (e.target.checked) {
        payload.action = "filter_applied";
        this.state.examCatObj[e.target.value] = e.target.value;
      } else {
        payload.action = "filter_reset";
        delete this.state.examCatObj[e.target.value];
      }
    }

    if (categoryFacet == "examTypesFacets") {
      if (e.target.checked) {
        payload.action = "filter_applied";
        this.state.examObj[e.target.value] = e.target.value;
      } else {
        payload.action = "filter_reset";
        delete this.state.examObj[e.target.value];
      }
    }

    if (categoryFacet == "categoryFacets") {
      if (e.target.checked) {
        payload.action = "filter_applied";
        this.state.productCategoryObj[e.target.value] = e.target.value;
      } else {
        payload.action = "filter_reset";
        delete this.state.productCategoryObj[e.target.value];
      }
    }
    payload.examCategory_selected = Object.values(this.state.examCatObj);

    payload.exam_selected = Object.values(this.state.examObj);
    // payload.productCategory_selected = Object.values(this.state.productCategoryObj);
    payload.productCategory_selected = this.props.productCat || "";
    payload.sort_selected = this.props.sortBy || "";
    if (this.props.isSort) {
      if (typeof Moengage !== "undefined")
        Moengage.track_event(
          "filter_internal_tab_selection",
          getMoengageMetadata(payload)
        );
    } else {
      if (typeof Moengage !== "undefined")
        Moengage.track_event("filter", getMoengageMetadata(payload));
    }
    this.props.getNewParamsforAllCategory(
      categoryFacet,
      e.target.value?.replace(/_/g, "_")
    );
  };

  handleRadioProductCategory = (e) => {
    // this.props.history.push(`/${productKeys[e]}`)
    let payload = { source: "web", brand: pageName[1] };
    payload.getUtmFromStore = this.props.getUtmFromStore || "";
    payload.productCategory_selected = e.target.value;
    payload.action = "filter_applied";
    if (typeof Moengage !== "undefined")
      Moengage.track_event("filter", getMoengageMetadata(payload));
    this.setState({
      productChecked: e.target.value,
    });
    this.props.changeRoute(productKeys[e.target.value.toLowerCase()]);
  };

  handleFilterSearch = (e) => {
    const searchText = e.target.value.trim();
    let payload = { source: "web", brand: pageName[1] };
    payload.filter_search_tickedSomething = e.target.value;
    payload.getUtmFromStore = this.props.getUtmFromStore || "";
    if (typeof Moengage !== "undefined")
      Moengage.track_event("filter_search ", getMoengageMetadata(payload));
    this.setState({ searchText });
  };



  getFilteredLinkKey(quickLink,array){
       
    let quickLinkKey= quickLink && Object.keys(quickLink);
    array && array.map((swap)=>{
     let temp1=quickLinkKey[swap[0]]
     quickLinkKey[swap[0]]=quickLinkKey[swap[1]]
    quickLinkKey[swap[1]]=temp1
    })
     return quickLinkKey;
   }
  


  render() {
    const { showSearch, facetsList, selectedTags } = this.props;
    const {
      searchText,
      showFilterItemsExamCategory,
      showFilterItemsExams,
      showFilterItemsFaculty,
      showFilterItemsProductCategory,
      showFilterItemsSubjects,
      showFilterItemsTime,
    } = this.state;

    // this.updateProductCategoryObj(selectedTags);

    const courses = facetsList && facetsList.courses;
    const examTypes = facetsList && facetsList.examTypes;
    const subjects = facetsList && facetsList.subjects;
    const categories = facetsList && facetsList.categories;
    const faculties = facetsList && facetsList.faculties;
    const time = facetsList && facetsList.time;

    let courseArr = courses && Object.keys(courses);
    courseArr = (courseArr || []).filter((e) => e !== "Insurance#INSURANCE");
    let examTypesArr = examTypes && Object.keys(examTypes);
    const subjectsArr = subjects && Object.keys(subjects);
    let categoriesArrOld = categories && Object.keys(categories);
    let quickLink = {};
    for (var key in categories) {
      if (parseInt(categories[key]) > 0) {
        quickLink[key] = categories[key];
      }
    }
    let quickLinkKey = this.getFilteredLinkKey(quickLink,[[2,4],]);
    let categoriesArr =
      !isEmpty(quickLinkKey) &&
      quickLinkKey.filter((e) => e !== "Magazines#MAGAZINES");

    // let categoriesArr =
    //   !isEmpty(categoriesArrOld) &&
    //   categoriesArrOld.filter((e) => e !== "Magazines#MAGAZINES");
      
    const facultiesArr = faculties && Object.keys(faculties);
    const timeArr = time && Object.keys(time);

    let examArrFilterItems = examTypesArr;

    function getItem(arrayItem, getItem) {
      return arrayItem.filter(
        (element) => element.toLowerCase().indexOf(getItem.toLowerCase()) > -1
      );
    }

    if (searchText) {
      examArrFilterItems = getItem(examTypesArr, searchText);
    }

    const examCategoryExclusion = [
      "courseDetail",
      "examCategory",
      "examDetail",
    ];
    const productCategoryExclusion = ["courseDetail", "examDetail"];
    const examsExclusion = ["examCategory", "examDetail"];

    if (!this.props?.facetsList || Object.keys(this.props?.facetsList)?.length === 0 && this.props?.type === 'courseCategory') {
      examCategoryExclusion.push('courseCategory');
      productCategoryExclusion.push('courseCategory');
      examsExclusion.push('courseCategory');
    }
    return (
      <>
        {courseArr.length || examArrFilterItems || (categoriesArr && categoriesArr?.length && categoriesArr[0]) ?<div className={"search-block"}>
          <div className={"filter-result"}>Filters</div>
          {/* Exam Category */}
          {!examCategoryExclusion.includes(this.props.type) && (
            <div className="bb">
              <div
                className={"exam-category"}
                onClick={this.toggleFilterItemsExamCategory}
              >
                <span>Exam Category</span>
                <img
                  src={
                    showFilterItemsExamCategory
                      ? "/images/icon-md-top.svg"
                      : "/images/icon-md-bottom.svg"
                  }
                  className={"filter-arrow"}
                  alt={pageName[0]}
                  title={pageName[0]}
                />
              </div>
              {showSearch && (
                <input
                  type="text"
                  placeholder="Search"
                  className={"filter-input"}
                />
              )}

              {showFilterItemsExamCategory && (
                <div className={"exam-category-list"}>
                  {courseArr &&
                    courseArr.map((item, i) => {
                      if (
                        (item || "").toLowerCase() == "engineering#engineering"
                      ) {
                        //item = "Engineering Exams#ENGINEERING EXAMS";
                      }
                      let radioValue = item.substring(item.indexOf("#") + 1);

                      let labelValue = getSubstringBeforeChar(item, "#");
                      if ((labelValue || "").toLowerCase() == "gate iitjam") {
                        labelValue = "GATE & IITJAM";
                      }
                      if ((labelValue || "").toLowerCase() == "iitneet") {
                        labelValue = "IIT/Medical";
                      }

                      if (
                        (labelValue || "").toLowerCase() ==
                        "mechanical_engineering"
                      ) {
                        labelValue = "MECHANICAL-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() ==
                        "instrumentation_engineering"
                      ) {
                        labelValue = "INSTRUMENTATION-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() == "civil_engineering"
                      ) {
                        labelValue = "CIVIL-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() ==
                        "electrical_engineering"
                      ) {
                        labelValue = "ELECTRICAL-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() ==
                        "electronics_engineering"
                      ) {
                        labelValue = "ELECTRONICS-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() ==
                        "computer_science_engineering"
                      ) {
                        labelValue = "COMPUTER-SCIENCE-ENGINEERING";
                      }
                      if (
                        (labelValue || "").toLowerCase() ==
                        "chemical_engineering"
                      ) {
                        labelValue = "CHEMICAL-ENGINEERING";
                      }
                      if (this.props.examCat?.includes("-")) {
                        let str = radioValue?.replace(/_/g, "-");
                        radioValue = str?.toLowerCase();
                      }

                      return (
                        <div className={"filter-list"} key={i + 1}>
                          {this.props.type !== 1 ? (
                            <div className={"md-radio"}>
                              <input
                                type={"radio"}
                                onChange={(e) =>
                                  this.handleFilterChange("allExamtype", e)
                                }
                                value={radioValue}
                                name={item}
                                id={item}
                                checked={
                                  this.props.examCat &&
                                  this.props.examCat.toLowerCase() ==
                                    (radioValue || "").toLowerCase()
                                }
                              />
                              <label for={item}>
                                {labelValue
                                  .replace(/_/g, " ")
                                  .replace(/-/g, " ")}
                              </label>
                            </div>
                          ) : (
                            <div className={"md-checkbox"}>
                              <input
                                type={"checkbox"}
                                onChange={(e) =>
                                  this.handleFilterChange("coursesFacets", e)
                                }
                                value={radioValue}
                                name={item}
                                id={item}
                                checked={
                                  (!isEmpty(this.state.examCatObj) &&
                                    this.state.examCatObj.hasOwnProperty(i) &&
                                    this.state.examCatObj[i].length) ||
                                  (selectedTags &&
                                    selectedTags.includes(radioValue))
                                }
                              ></input>
                              <label for={item}>
                                {labelValue.replace(/_/g, " ")}
                              </label>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              )}
              <div className={"filter-divider"} />
            </div>
          )}
          {/* Exams */}
          {!examsExclusion.includes(this.props.type) && (
            <div className="bb">
              <div
                className={"exam-category"}
                onClick={this.toggleFilterItemsExams}
              >
                <span>Exams</span>
                <img
                  src={
                    showFilterItemsExams
                      ? "/images/icon-md-top.svg"
                      : "/images/icon-md-bottom.svg"
                  }
                  className={"filter-arrow"}
                  alt={pageName[0]}
                  title={pageName[0]}
                />
              </div>
              {showFilterItemsExams && !isEmpty(examTypesArr) && (
                <input
                  type="text"
                  placeholder="Search"
                  className={"filter-input"}
                  onChange={this.handleFilterSearch}
                />
              )}

              {showFilterItemsExams && (
                <div className={"exam-category-list"}>
                  {examArrFilterItems &&
                    examArrFilterItems.map((item, i) => {
                      return (
                        <div className={"md-checkbox"} key={i + 1}>
                          <input
                            type={"checkbox"}
                            onChange={(e) =>
                              this.handleFilterChange("examTypesFacets", e)
                            }
                            value={getSubstringAfterChar(item, "#")}
                            checked={
                              selectedTags &&
                              selectedTags.includes(
                                getSubstringAfterChar(item, "#")
                              )
                            }
                            id={item}
                          ></input>
                          <label for={item}>
                            {getSubstringAfterChar(item, "#")}
                          </label>
                        </div>
                      );
                    })}
                  {(!examArrFilterItems || examArrFilterItems.length == 0) && (
                    <div>No Items Found</div>
                  )}
                </div>
              )}
              <div className={"filter-divider"} />
            </div>
          )}
          {/*/!* Subjects *!/*/}

          {/* Product Category */}
          {!productCategoryExclusion.includes(this.props.type) && (
            <div className="bb">
              <div
                className={"exam-category"}
                onClick={this.toggleFilterItemsProductCategory}
              >
                <span>Product Category</span>
                <img
                  src={
                    showFilterItemsProductCategory
                      ? "/images/icon-md-top.svg"
                      : "/images/icon-md-bottom.svg"
                  }
                  className={"filter-arrow"}
                  alt={pageName[0]}
                  title={pageName[0]}
                />
              </div>

              {showFilterItemsProductCategory && (
                <div className={"exam-category-list"}>
                  {categoriesArr &&
                    categoriesArr?.map(e => e === "Models 3d#MODELS_3D" ? "3D Learning#MODELS_3D" : e)?.map((item, i) => {
                      return (
                        <>
                          {(productNames[this.props.currentItem] ===
                          undefined) && (getSubstringBeforeChar(item, "#") !== "") ? (
                            <div className={"md-checkbox"}>
                              <input
                                type={"checkbox"}
                                onChange={(e) =>
                                  this.handleFilterChange("categoryFacets", e)
                                }
                                value={getSubstringAfterChar(item, "#")}
                                name={item}
                                id={item}
                                checked={
                                  (!isEmpty(this.state.productCategoryObj) &&
                                    this.state.productCategoryObj.hasOwnProperty(
                                      i
                                    ) &&
                                    this.state.productCategoryObj[i].length) ||
                                  (selectedTags &&
                                    selectedTags.includes(
                                      getSubstringAfterChar(item, "#").replace(/_/g, "_")
                                    ))
                                }
                              ></input>
                              <label htmlFor={item}>
                                {getSubstringBeforeChar(item, "#")}
                              </label>
                            </div>
                          ) : (
                            (getSubstringBeforeChar(item, "#") !== "") && <div className={"md-radio"}>
                              <input
                                type={"radio"}
                                onChange={this.handleRadioProductCategory}
                                value={getSubstringAfterChar(item, "#")}
                                name={item}
                                id={item}
                                checked={
                                  this.props.productCat &&
                                  this.props.productCat.toLowerCase() ==
                                    getSubstringBeforeChar(
                                      item,
                                      "#"
                                    ).toLowerCase()
                                }
                              ></input>
                              <label for={item}>
                                {getSubstringBeforeChar(item, "#")}
                              </label>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              )}
              <div className={"filter-divider"} />
            </div>
          )}

          {/* Faculty */}
        </div> : null}
        <div>
          {/* Course Types */}
          {this.props.courseTag && this.props.courseTag.length >= 1 && (
            <SeoSectionWeb
              data={this.props.courseTag}
              SuffixValue={this.props.SuffixValue}
              heading={"Exam Types"}
              examCat={this.props.examCat}
            />
          )}
          {/* Related category */}

          {this.props.type != "examCategory" &&
            this.props.type != "productDetail" && (
              <SeoSectionWeb
                data={this.props?.examCategoryArray || this.examCategoryArray}
                examCat={this.props.examCat}
                isProductListing={
                  this.props.type != "examDetail"
                    ? this.props.isProductListing
                    : null
                }
                heading={this.props.heading}
                SuffixValue={
                  this.props.type != "examDetail"
                    ? this.props.SuffixValue
                    : null
                }
                isRelatedCategory={
                  this.props.type != "examDetail"
                    ? this.props.currentexamName
                      ? false
                      : true
                    : null
                }
                courseType={
                  this.props.type != "examDetail" ? this.props.courseType : null
                }
                productType={
                  this.props.type != "examDetail"
                    ? this.props.productType
                    : null
                }
                currentexamName={this.props.currentexamName}
                productName={
                  this.props.type != "examDetail"
                    ? this.props.productName
                    : null
                }
              />
            )}
          {this.props.type == "productDetail" && (
            <SeoSectionWeb
              data={this.examCategoryArray?.slice(0, 7)}
              examCat={""}
              subCategory={true}
              currentItem={this.props.productType}
              isExamtypes={true}
              currentexamName={this.props.productType}
              isProductCategory={true}
              courseType={true}
              heading={"Exam Category"}
              productType={this.props.productType}
              SuffixValue={this.props.productType}
            />
          )}

          {/* Subcategory */}
          {/* ExamPages */}
          {this.props.examPages && this.props.examPages.length >= 1 && (
            <SeoSectionWeb
              data={this.props.examPages}
              examCat={""}
              subCategory={true}
              // currentItem={examName}
              courseDetailPage={true}
              isExamtypes={true}
              isRelatedCategory={false}
              heading="Exam Pages"
              SuffixValue={"Online Coaching"}
            />
          )}

          {/* {
               !isEmpty(this.props.getFilterPagesData) &&     <div className="related-category-container bb">
                        <div className="seo-exam-category ">Sub Category</div>

                        {!isEmpty(this.props.getFilterPagesData) && this.props.getFilterPagesData.map(value => {
                            let categoryName = value.suggestionCategory;
                            return (
                                <Link className="related-link"
                                    to={`/${(this.props.currentItem || "").toLowerCase()}/${productKeys[categoryName]}-${this.props.courseDetail ? 'study-kit' : 'kit'}`}
                                >
                                    {`View All ${value.suggestionCategory}`}
                                </Link>
                            )

                        })}


                        <div className={'filter-divider'} />
                    </div>
                } */}
          {this.props.productDataSeo ? (
            <SeoSubCategoryWeb
              getFilterPagesData={this.props.productDataSeo}
              isProductListing={this.props.isProductListing}
              isProductCategory={true}
              heading={this.props.heading}
            />
          ) : (
            this.props.getFilterPagesData &&
            this.props.getFilterPagesData.length >= 1 && (
              <SeoSubCategoryWeb
                getFilterPagesData={this.props.getFilterPagesData}
                isProductListing={this.props.isProductListing}
                currentItem={this.props.currentItem}
                prefixValue={this.props.currentItem}
              />
            )
          )}

          {/* similar product */}
          {this.props.similarProduct && this.props.similarProduct.length >= 1 && (
            <div className="related-category-container">
              <div className="seo-exam-category ">
                Important Exams Study Kits
              </div>

              {this.props.similarProduct.slice(0, 5).map((value) => {
                // let categoryName = value.suggestionCategory;
                return (
                  <Link
                    className="related-link"
                    to={
                      this.props.currentexamName
                        ? `/${removeSpacesAndInsertHyphens(
                            value.name.toLowerCase()
                          )}/${this.props.productName}`
                        : `/${removeSpacesAndInsertHyphens(
                            value.name.toLowerCase()
                          )}-exam-kit`
                    }
                  >
                    {`${value.name.toUpperCase()} ${this.props.SuffixValue}`}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SearchBlock;

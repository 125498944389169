import React from 'react';
import CustomModal from '../../../../library/CustomModal';

export default function LiveTestModal(props) {

    return (
        <div>
            <CustomModal
                modalClassName={"ppcBorderPadding"}
                visible={props?.showModal}
                onCancel={props?.closeModal}
                closeCallback={props?.closeModal}
                hideHeader={true}
            >
            <div className={`livetest-popup-content-wrapper ${props?.modalType ? props?.modalType : ""}`}>
                <div className='livetest-header-wrapper'>
                    {props?.title ? <span className='livetest-popup-heading'>{props?.title}</span> : ""}
                    {!props?.hideCloseIcon ? <span className='livetest-popup-close' onClick={props.closeModal}>x</span> : ""}
                </div>
                {props?.description ? <div className='livetest-body-wrapper'>
                    <span className={`livetest-popup-body ${props?.modalDescClass || "" }`} dangerouslySetInnerHTML={{ __html: props?.description }} />
                </div> : ""}
                {props?.secondaryDescription ? <div className='liveTestSecondSub'>
                    <span className='livetest-popup-body'>{props?.secondaryDescription}</span>
                </div> : ""}
                <div className='livetest-modal-button-wrapper'>
                    {props?.secondaryButtonText ? <span className='live-test-modal-secondary-button' onClick={props?.secondaryButtonAction || null}>{props?.secondaryButtonText}</span> : ""}
                    {props?.primaryButtonText ? <span className='live-test-modal-primary-button' onClick={props?.primaryButtonAction || null}>{props?.primaryButtonText}</span> : ""}
                </div>
            </div>
            </CustomModal>
        </div>
    )
}

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { setCartCountFromCookie } from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import { Link} from "react-router-dom";
// import { fireMetaPixelEvent } from "../data/utils/helpers";

const BuyNowComponent = ({
  viewOffers,
  isPurchased,
  productData,
  isLoggedIn,
  published,
  onClick,
  setFromBuyNowBtn,
  setBuyNowPosition,
  setViewCourseClicked,
  isPDPOverlayCard = false
}) => {

  
  

  if (isPurchased == -1) {
    return null;
  }

  let reorderSubcategories = [
    { category: "FREE_CONTENT" },
    { category: "ONLINE_LIVE_CLASSES" },
    { category: "TEST_SERIES" },
    { category: "VIDEOS" },
    { category: "EBOOKS" },
    { category: "BOOKS" },
  ];
  reorderSubcategories = reorderSubcategories?.map((category) =>
    productData?.subCategories?.find(
      (item) => item.category == category.category
    )
  );
  reorderSubcategories = reorderSubcategories?.filter((item) => item);
  const categoryPathKey = {
    ONLINE_LIVE_CLASSES: "liveClasses",
    TEST_SERIES: "testPkgs",
    VIDEOS: "videoPkgs",
    EBOOKS: "ebooksPkgs",
    BOOKS: "booksPkgs",
  };
  const addToCartInLocalStorage = (productDetails) => {
    let cartItems =
      (localStorage.getItem("CART_ITEMS") &&
        JSON.parse(localStorage.getItem("CART_ITEMS"))) ||
      [];
    if (!cartItems.find((cartItem) => cartItem.id === productDetails.id)) {
      // product is not added already.
      cartItems.push({
        id: productDetails.id,
      });
    }
    localStorage.setItem("CART_ITEMS", JSON.stringify(cartItems));
  };
  const addToCart = (productDetails, isLoggedIn) => {
    if (!isLoggedIn) {
      addToCartInLocalStorage(productDetails);
    }
  };

  const onBuyNow = (e,Position= "Buy_Now_Card") => {
    e.preventDefault();
    setFromBuyNowBtn(true);
    viewOffers(true);
    // fire meta pixel Add to cart event
    setBuyNowPosition(Position);
    // fireMetaPixelEvent("AddToCart");
    addToCart(productData, isLoggedIn);
    sessionStorage.setItem("buyNow_Offer", "buy");
    window.location.hash = "#checkout/viewOffers";
  };

  const handleViewCourseUrl = () => {
    const categoryKey = reorderSubcategories?.[0]?.category == "FREE_CONTENT" ? categoryPathKey[reorderSubcategories[1]?.category] : categoryPathKey[reorderSubcategories[0]?.category];
    const productCategory = productData?.subCategories[0]?.category == "FREE_CONTENT" ? productData?.subCategories[1]?.category : productData?.subCategories[0]?.category;
      onClick(
        categoryKey,
        productCategory,
        "viewCourse"
      );
      setViewCourseClicked(true);
  }

  useEffect(() => {
    document.addEventListener('buyNowModalOpen', (e)=> onBuyNow(e,"Comparison Table"))
    document.addEventListener('viewCourseModalOpen', handleViewCourseUrl)
    return () => {
      document.removeEventListener('buyNowModalOpen', (e)=> onBuyNow(e,"Comparision Table"))
      document.removeEventListener('viewCourseModalOpen', handleViewCourseUrl)
    }
  }, []);
  let Button = (
    <Link to="#" onClick={(e)=>onBuyNow(e)}>
      {" "}
      <img src="/images/pdpbunowIcon.svg" alt="Buy now button"/> Buy now
    </Link>
  );
  if (isPurchased) {
    Button = (
      <>
        {!isPDPOverlayCard &&  <div className="purchaseActionItem alreadyPurchasedCourse">
        Already Purchased
        </div>}
        <div className="pdpbtn pdpViewCourseBtn alreadyPurchasedCoursebtn"
          onClick={() =>{
            handleViewCourseUrl()
          }
          }
        >
          <Link to="#">View Course</Link>
        </div>
      </>
    );
  } else if (published == false) {
    Button = (
      <button className="purchaseActionItem alreadyPurchasedlabel logocolor">
        Currently Not Available
      </button>
    );
  }
  return Button;
};
const mapStateToProps = (state) => {
  return {
    userDetails: state.header.userInfo,
    isPaytmMini: state.isPaytmMini,
  };
};
const mapDispatchToProps = {
  setCartCountFromCookie,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyNowComponent);

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { myPurchase, STOREFRONT_URI } from '../../../../constants/appConfig';
import CustomLoader from '../../../components/library/CustomLoader';
import ChildPackageCard from '../../../components/PPC/ChildPackageCard'
import fetch from '../../../data/utils/fetch';
import { capitalizeWords, isADDA, makeUrl, removeHyphensAndInsertSpaces } from '../../../data/utils/helpers';
import PPCWrapper from '../PPCWrapper';
import { getPPCAllContinueListings, setInitialAllContinueListingData } from '../../../data/ducks/header/actions';
import PPCContinueListing from './PPCContinueListing';
import PPCComingSoonListings from './PPCComingSoonListings';


const ExamAllBatches = () => {

    const { packageId, examId, packageName, examName } = useParams();
    const { pathname } = useLocation();
    const [comingSoonData, setComingSoonData]= useState({});
    const [allBatchesList, setAllBatchesList] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pageNo, setPageNo] = useState(0);
    const [packagesCount, setPackagesCount] = useState();
    const [isUniqueExam, setIsUniqueExam] = useState(false);
    const history = useHistory();
    const pageSize = 10;

    const allcontinueStudyingData = useSelector(state => state?.header?.packageData?.allcontinueStudyingData);
    const dispatch = useDispatch();


    const getPPCAllContinueListing = async () => {
        const res = await getPPCAllContinueListings(packageId, 0, 10);
        dispatch(setInitialAllContinueListingData(res));
    };

    const getComingSoonListing = () => {
        const comingSoonUrl = `${STOREFRONT_URI}/api/v2/ppc/package/child?packageId=${packageId}&category=${"ONLINE_LIVE_CLASSES"}&isComingSoon=true`;
        fetch(comingSoonUrl).then(res => {
            if(res?.success){
                setComingSoonData(res?.data);
            }
        }).catch(err => {
            console.log("err: ", err);
        })
    }
    const observer = useRef();
    const lastElementRef = useCallback(
        (node) => {
            if (loadingFlag) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((enteries) => {
                if (
                    enteries[0].isIntersecting &&
                    hasMore
                ) {
                    setPageNo((prev) => prev + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loadingFlag, hasMore]
    );

    const getNextData = (pageNumber) => {
        setLoadingFlag(true);
        try{
            fetch(`${myPurchase.subParents}?packageId=${packageId}&exams=${examId}&pageNumber=${pageNumber}&pageSize=${pageSize}`).then(res => {
                if(res?.success){
                    const packages = res?.data?.packages;
                    const packagesCount = res?.data?.packagesCount;
                    setAllBatchesList([...allBatchesList, ...packages]);
                    if(pageNumber == 0){
                        setPackagesCount(packagesCount);
                    }
                }
                setLoadingFlag(false);
            }).catch(err => {console.log("error: ", err); setLoadingFlag(false);})
        }catch(err){
            console.log("error: ", err)
        }
    }

 

    const handleBatchClick = (batch) => {
        const url = pathname.substring(0, pathname.length-11);
        history.push(`${url}/${batch.packageId}/${makeUrl(batch.title, true)}/batch`)
    }

    useEffect(() => {
        getNextData(pageNo);
    },[pageNo])

    useEffect(() => {
        setIsUniqueExam(localStorage.getItem(`isUniqueExamIn${packageId}`) || false);
    },[])

    useEffect(() => {
        if(!isADDA && isUniqueExam){
            getComingSoonListing();
            getPPCAllContinueListing();
        }
    },[isUniqueExam])


    useEffect(() => {
        setHasMore(allBatchesList?.length < packagesCount);
    },[allBatchesList, packagesCount])

    let breadCrumbsList = [
        {
          route: "/my-purchase",
          name: "My Purchase",
        },
        {
          route: `/my-purchase/${packageId}/${packageName}/exams`,
          name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
        },
        {
          route: `/my-purchase/${packageId}/${packageName}/exams/${examId}/${examName}/options`,
          name: capitalizeWords(removeHyphensAndInsertSpaces(examName)),
        },
        {
            route: pathname,
            name: capitalizeWords(removeHyphensAndInsertSpaces(examName + " Batches")),
        },
      ];

    if(!isADDA && isUniqueExam){
        breadCrumbsList = [
            {
            route: "/my-purchase",
            name: "My Purchase",
            },
            {
            route: pathname,
            name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
            },
        ];
    }

  return (
    <>
    <PPCWrapper
      breadCrumbRoutes={breadCrumbsList}
      pageTitle={(!isADDA && isUniqueExam) ?  removeHyphensAndInsertSpaces(packageName): removeHyphensAndInsertSpaces(examName) + " Batches"}
      rightSection={<></>}
    >
          {
                  <div className="ppc-container">                    
                    {(!isADDA && isUniqueExam) && <PPCComingSoonListings data={comingSoonData} category={"ONLINE_LIVE_CLASSES"} />}
                    {(!isADDA && isUniqueExam) && <PPCContinueListing
                        data={allcontinueStudyingData}
                        isCategoryBased={false}
                    />}

                      <div className='ppc-single-line-card-wrap'>
                          {
                              allBatchesList?.map((batch, idx) => {
                                  return <ChildPackageCard
                                  src={batch.thumbnail}
                                  title={batch.title}
                                  onClick={() => {
                                    handleBatchClick(batch);
                                  }}
                                  ref = {packagesCount > 10 && (allBatchesList.length == idx+1)? lastElementRef: null}
                              />
                              })
                          }
                      </div>
                      {loadingFlag && <CustomLoader/>}
                  </div>
          }          
    </PPCWrapper>
    </>
  )
}

export default ExamAllBatches

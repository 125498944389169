import React from 'react';

const ToggleSection= ({toggle,isOpen,...props}) => {
    // const [isOpen, setIsOpen] = useState(!!props.open);
    return (
        <div className='toggleSectionComponent' onClick={() => { props.click(props.header) }}>
            <div className='sectionHeader' onClick={() => {toggle(!isOpen)}}>
                <div>{props.header}</div><div className={isOpen?'chevronUp':'chevronDown'}></div> </div>
            {isOpen && <div className='sectionBody'>
                {props.body}
            </div>}

        </div>
    )
}
export default ToggleSection;
import React from "react";
// import FilterByExamForMobile from './filter/FilterByExamForMobile';

const ErrorPage = ({ errorMsg = "" }) => {
  return (
    <div className="errorpage-container">
      <div className="icon">
        <img
          src="/images/ic_no content.png"
          alt="no_content"
          title="no_content"
        />
      </div>
      <div className="error-header">Unable to load content.</div>
      <div className="error-text">
        {errorMsg ||
          "Sorry we were unable to load the page you were looking for. Please tryagain."}
      </div>
    </div>
  );
};

export default ErrorPage;

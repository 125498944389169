import React, { useEffect, useState } from "react";

import { enrolledCoursesUrl, routeToAppUrl, routeToAppLinkUrl, pageName } from '../../../../constants/appConfig';
import CustomModal from "../CustomModal";
import { isADDA } from "../../../data/utils/helpers";
import { COURSE_CATEGORY_IDENTIFIERS } from "../../../../constants/textConstants";

const ContineInApp = (
    { show,
        type,
        parentpackageId,
        packageId,
        close,
        isContinuesection,
        fpmId
    }

    
) => {
    const openInApp = () => {
        if(!isADDA) {
            window.open(routeToAppUrl, "_blank")
        }
        let PpkgString = parentpackageId ? `/PPkg_${parentpackageId}` : '';
        let CpkgString = packageId ? `/CPkg_${packageId}` : '';
        if(isContinuesection)
        {
            PpkgString="";
            CpkgString=`/CPkg_${parentpackageId}`
        }
        let PathString = '';
        if (type === COURSE_CATEGORY_IDENTIFIERS.VIDEOS_IDENTIFIER) {
            if(isADDA) {
                PathString = `/my-videos/CPkg_${parentpackageId || packageId}`;
            }else{
                PathString = isContinuesection ? `/video-package${PpkgString}${CpkgString}/VID_${packageId}`:`/video-package${PpkgString}${CpkgString}`;
            }
        }
        else if (type === COURSE_CATEGORY_IDENTIFIERS.TEST_SERIES_IDENTIFIER) {
            if(isADDA) {
                PathString = `/my-mock-test/CPkg_${parentpackageId || packageId}`;
            }else{
                PathString = isContinuesection ? `/my-mock-test${PpkgString}${CpkgString}/MID_${packageId}`:`/my-mock-test${PpkgString}${CpkgString}`;
            }
        }
        else if (type === COURSE_CATEGORY_IDENTIFIERS.EBOOKS_IDENTIFIER) {
            if(isADDA) {
                PathString = `/my-ebooks/CPkg_${parentpackageId || packageId}`;
            }else{
                PathString = isContinuesection ? `/my-ebooks${PpkgString}${CpkgString}/EID_${packageId}`:`/my-ebooks${PpkgString}${CpkgString}`;
            }
        }
        else if (type === COURSE_CATEGORY_IDENTIFIERS.ONLINE_LIVE_CLASSES_IDENTIFIER) {
            if(isADDA) {
                PathString = `/my-live-class/CPkg_${parentpackageId || packageId}`;
            }else{
                PathString = `/my-live-class${PpkgString}${CpkgString}`;
            }
        }
        else if (type === COURSE_CATEGORY_IDENTIFIERS.FPM_IDENTIFIER) {
            PathString = `/fpm/${fpmId}`
        }

        window.open(`${routeToAppUrl}&link=${routeToAppLinkUrl}${PathString}`)
    }
    return (<CustomModal
        visible={show}
        modalClassName='continue-in-app'
        footer={null}
        //title={"Open In App"}
        onCancel={close}
        closeCallback={close}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ overflowY: "auto" }}
    >
        <div className='go-to-app'>
            {isADDA && <div className='gt-app-image'>
                <img
                    src="/images/clBanner.png"
                    alt="Download App"
                    onClick={openInApp}
                />
            </div>}
            {!isADDA && <div className='gt-app-text header'>
                Continue Learning
        </div>}
            <div className='gt-app-text info'>
                <span>
                    For best experience use {pageName[1]} App with exciting features
        </span>
            </div>
            <div className='gt-app-text details'>
                <span>
                    Get Live Interactive Doubt Section | Latest Quiz & Articles | Current Affairs & Latest Exam Information
        </span>
            </div>
            <div>
                <button className='right-button' onClick={openInApp}> OPEN IN APP</button>
            </div>

        </div>
    </CustomModal> 
    )
}

export default ContineInApp;
import React from "react";
import PropTypes from "prop-types";
const SectionTabs = ({
  tabs = [],
  activeTab,
  onClick,
  children,
  inActiveTabClassName = "",
}) => {
  return (
    <section className="contentTabNav">
      <ul>
        {tabs?.map((tab) => (
          <li
            key={tab}
            className={`${
              tab === activeTab ? "activeTabMenu" : inActiveTabClassName
            }`}
            onClick={() => onClick(tab)}
          >
            {tab}
          </li>
        ))}
      </ul>
      {children}
    </section>
  );
};

SectionTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
};
export default SectionTabs;

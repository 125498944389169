import React, { useCallback, useEffect, useRef, useState } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { myPurchase } from "../../../../constants/appConfig";
import ContineInApp from "../../../components/library/ContinueInApp/ContinueInApp";
import CustomLoader from "../../../components/library/CustomLoader";
import ChildPackageCard from "../../../components/PPC/ChildPackageCard";
import { getNextPagePackageChild } from "../../../data/ducks/header/actions";
import {
  getDeviceType,
  isADDA,
  makeUrl,
  saveUserSearchAnalyticsData,
} from "../../../data/utils/helpers";
import LiveTestModal from "../../../components/enrolledCourses/TestSeries/TestResultAnalysis/Components/LiveTestModal";
import { liveTestModalData } from "../../../components/enrolledCourses/TestSeries/TestResultAnalysis/Components/liveTestUtils";

const ChildPackageListings = ({
  data = {},
  category,
  examId = null,
  stateKeyName = "childData",
  subParentId,
  subParentTitle,
  isPaidSearchRedirect,
  comingSoonDataLength,
  isUniqueExam
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [clickedItemData, setClickedItemData] = useState();
  const [showLiveTestModal, setShowLiveTestModal] = useState(false);
  const { packageId } = useParams();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  let { packages = [], packagesCount, selectedCategory } = data;
  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (nextLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((enteries) => {
        if (
          enteries[0].isIntersecting &&
          hasMore &&
          selectedCategory === category
        ) {
          setPageNo((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextLoading, hasMore, selectedCategory]
  );
  const getNextPageData = async () => {
    if (pageNo !== 0) {
      setNextLoading(true);
      await dispatch(
        getNextPagePackageChild(
          subParentId || packageId,
          category,
          examId,
          pageNo,
          10,
          stateKeyName
        )
      );
      setNextLoading(false);
    }
  };
  const modifiedBatchUrl = (str = "", splicedValues = [0, 0]) => {
    let arr = str.split("/");
    arr.splice(splicedValues[0], splicedValues[1]);
    return arr.join("/");
  };
  const makeRedirectUrl = (packageId, title) => {
    let pathnameUrl = pathname;
    if (subParentId && subParentTitle && !isPaidSearchRedirect) {
      // here we remove the batch url's from the string before going to consumption when subparentId
      pathnameUrl = modifiedBatchUrl(pathnameUrl, [7, 3]);
    } else if (isPaidSearchRedirect) {
      // when user comes by searching paid content.
      pathnameUrl = `${modifiedBatchUrl(pathnameUrl, [2, 3])}/0/${makeUrl(
        subParentTitle
      )}`;
    }

    let url = `${pathnameUrl}/${packageId}/${makeUrl(title, true)}?category=${category}`;

    if (subParentId && subParentTitle) {
      url = `${url}&subParentId=${subParentId}&subParentTitle=${subParentTitle}${isPaidSearchRedirect ? "&redirectFrom=paidSearch" : ""}${(!isADDA && isUniqueExam)? "&isUniqueExam=true": ""}`;
    }
    return url;
  };

  const closeLiveTestModal = (e) => {
    setShowLiveTestModal(false);
  };

  useEffect(() => {
    getNextPageData();
  }, [pageNo]);
  useEffect(() => {
    setHasMore(packages?.length < packagesCount);
  }, [data]);
  const openConsumptionScreen = (packageId, title) => {
    /**
     * mWeb android & Ios-> restrict the user to open consumptionScreen for every category and show openInApp popup instead.
     */
    if (getDeviceType() == "m" || getDeviceType() == "t") {
      setClickedItemData({
        packageId: packageId,
        type: category,
        parentPackageId: packageId,
      });
      return false;
    }
    let redirectUrl = makeRedirectUrl(packageId, title);
    history.push(redirectUrl);
  };
  return (
    <>
      <ContineInApp
        show={!!clickedItemData}
        packageId={clickedItemData && clickedItemData.packageId}
        parentpackageId={clickedItemData && clickedItemData.parentPackageId}
        type={clickedItemData && clickedItemData.type}
        close={() => {
          setClickedItemData();
        }}
        isContinuesection={false}
      />
      {packages?.length > 0 ? (
        <div className="ppc-single-line-card-wrap">
          {packages?.map((data, idx) => (
            <ChildPackageCard
              key={`${idx}_${new Date().getTime() * Math.random(-7)}`}
              ref={
                packages?.length > 5 && packages?.length === idx + 1
                  ? lastElementRef
                  : null
              }
              src={data?.thumbnail}
              title={data?.title}
              liveTestCount={data?.liveTestCount}
              showLiveTestData={data?.liveTest || false}
              setShowLiveTestModal={setShowLiveTestModal}
              showLiveTestModal={showLiveTestModal}
              batchStartDate={data?.batchStartDate}
              onClick={() => {
                saveUserSearchAnalyticsData({
                  url: myPurchase.userAnalyticsPaidContent,
                  data,
                });
                openConsumptionScreen(data?.packageId, data?.title);
              }}
            />
          ))}
          {nextLoading && (
            <div className="ppc-next-load">
              <CustomLoader />
            </div>
          )}
        </div>
      ) : comingSoonDataLength === 0 ? (
        <div className="noresult-found">
          <div className="no-result-icon">
            <img src="/images/coming_soon.svg" />
          </div>
          <div className="no-rusult-heading">Sorry to keep you waiting...</div>
          <div className="no-rusult-subheading">
            We are in the process of setting up the content for you. Please
            check again after some time
          </div>
        </div>
      ) : (
        <></>
      )}
      <LiveTestModal 
        showModal={showLiveTestModal} 
        closeModal={closeLiveTestModal}
        title={liveTestModalData?.howItWorks?.title}
        description={liveTestModalData?.howItWorks?.description}
        modalType={liveTestModalData?.type || ""}
        modalDescClass={'lt-how-it-works-desc'}
      />
    </>
  );
};

export default memo(ChildPackageListings);

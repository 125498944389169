import React  from "react";

import { Link } from "react-router-dom";
import { getMoengageMetadata } from "../../data/utils/helpers";

const ViewOffers = ({
    viewOffers,
    setViewOffer,
    isLoggedIn,
    setViewOfferModalOnly,
    setFromBuyNowBtn,
    isPurchased,
    published,
    Getpayload,
    utmParams,
    courses,
  }) => {
    const firstCourse = courses?.split(',')[0].trim();
    const setValue = (e, value) => {
      e.preventDefault();
      setFromBuyNowBtn(false);
      setViewOfferModalOnly(true);
  
      if (viewOffers) viewOffers(value);
      sessionStorage.setItem("buyNow_Offer", "offer");
      if (setViewOffer) setViewOffer(value);
      // if(isLoggedIn){
      // window.location.replace("#checkout/viewOffers");
      window.location.hash = "#checkout/viewOffers";
      // }
    };
    const handleExploreMoreMoe = () => {
      let payload = Getpayload || {};
      payload.package_url = window?.location?.href || "";
      payload.Position = "Buy_Now_Card";
      payload.getUtmFromStore = utmParams || "";
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("Explore_More_Courses_Clicked", getMoengageMetadata(payload));  
    };
    if(!published) {
      return null;
    }
    return (
      <>
        {!isPurchased ? (
          <Link to="#" onClick={(e) => setValue(e, true)}>
            <img src="/images/pdpIconoffer.svg" alt="View offers" /> View Offers
          </Link>
        ) : (
          <Link
          to={
            typeof firstCourse === 'string' && firstCourse.toLowerCase() === "banking"
              ? `/banking-study-materials`
              : typeof firstCourse === 'string' && firstCourse.toLowerCase() === "engineering"
                ? `/engineering-study-materials`
                : typeof firstCourse === 'string'
                ? `/${firstCourse.toLowerCase().split("_").join("-")}-study-materials`
                : '/'
          }
          onClick={() => handleExploreMoreMoe()}
        >
          Explore More Courses
        </Link>
        )}
      </>
    );
  };
export default ViewOffers;
import types from './types';
import { pageSizeForDetailListing, productListingUrl, searchUrlEndPoint } from '../../../../constants/appConfig';
import { isADDA } from '../../utils/helpers';

const getPaginatedDataForProductDetail = ({ pageNo, type, categoryFacet, queryParam, sortBy,sponsoredCategory = 2 }) => {
    let qP;
    if (queryParam && queryParam.length > 2) {
        qP = '&' + queryParam;
    } else {
        qP = '';
    }
    return {
        CALL_API: [
            {
                type: type === 'init' ? types.GET_PRODUCT_DETAIL_LISTING : types.GET_PRODUCT_DETAIL_LISTING_ON_SCROLL,
                meta: {
                    path: `${productListingUrl}?categoryFacets=${categoryFacet}&pageNo=${pageNo}&pageSize=${pageSizeForDetailListing}${qP}${sortBy.toUpperCase() != "RELEVANCE"? "&sortOrder="+sortBy: ""}&sponsoredCategory=${sponsoredCategory}&primaryFacetsRequired=true${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                }
            }
        ]
    };
};


const getSecondaryFacets = (facetObj) => {
    return {
        CALL_API: [
            {
                type:  types.GET_SECONDARY_FACETS ,
                meta: {
                    path: `${searchUrlEndPoint}/packages/secondaryFacets?categoryFacets=${facetObj?.categoryFacet}`,
                    method: 'GET',
                }
            }
        ]
    };
};const clearProductDetailData = () => {
    return {
        type: types.CLEAR_PRODUCT_LISTING_DATA,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
};

export {
    getPaginatedDataForProductDetail,
    clearProductDetailData,
    getSecondaryFacets
};


import React from 'react'

export const BackButton = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Line Lightmode/Back Arrow">
        <path
          id="Vector"
          d="M9.71707 5L3 12L9.71707 19M4.26688 12.0317H19.9999"
          stroke="#202126"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

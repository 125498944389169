import React from "react";

const GeneralExamInstructions = ({ language = "ENGLISH", data = {}}) => {
  switch (language) {
    case "ENGLISH":
      return (
        <div
          className="examInstText1"
          style={{
            height: "93%",
            width: "97%",
            overflow: "auto",
            paddingLeft: "10px",
          }}
        >
          <div>
            <h5> </h5>
            <center>
              <font size="4">Please read the instructions carefully </font>
            </center>
          </div>
          <p>
            <strong>
              <u>General Instructions:</u>
            </strong>
          </p>
          <ol>
            <li>
              Total duration of examination is {data?.meta.time / 60} minutes.
            </li>
            <li>
              The clock will be set at the server. The countdown timer in the
              top right corner of screen will display the remaining time
              available for you to complete the examination. When the timer
              reaches zero, the examination will end by itself. You will not be
              required to end or submit your examination.
            </li>
            <li>
              The Question Palette displayed on the right side of screen will
              show the status of each question using one of the following
              symbols:
            </li>
          </ol>
          <table className="instruction_area" style={{ fontSize: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <span className="not_visited" title="Not Visited">
                    1
                  </span>
                </td>
                <td>You have not visited the question yet.</td>
              </tr>
              <tr>
                <td>
                  <span className="not_answered" title="Not Answered">
                    2
                  </span>
                </td>
                <td>You have not answered the question.</td>
              </tr>
              <tr>
                <td>
                  <span className="answered" title="Answered">
                    3
                  </span>
                </td>
                <td>You have answered the question.</td>
              </tr>
              <tr>
                <td>
                  <span className="review" title="Marked for Review">
                    4
                  </span>
                </td>
                <td>
                  You have NOT answered the question, but have marked the
                  question for review.
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className="review_marked_considered"
                    title="Answered &amp; Marked for Review"
                  >
                    5
                  </span>
                </td>
                <td>
                  The question(s) "Answered and Marked for Review" will be
                  considered for evaluation.
                </td>
              </tr>
            </tbody>
          </table>
          <ol
            style={{
              textAlign: "left",
              listStyleType: "decimal",
              paddingLeft: "4%",
              paddingTop: "3px",
            }}
          >
            <li value="3">
              The Marked for Review status for a question simply indicates that
              you would like to look at that question again. 
              <span className="instruction-red-highlight"> If a question is answered and Marked for Review, your answer for that question will be considered in the evaluation.</span>
            </li>
            <li>
              You can click on the "&gt;" arrow which appears to the left of
              question palette to collapse the question palette thereby
              maximizing the question window. To view the question palette
              again, you can click on "&lt; " which appears on the right side of
              question window.
            </li>
            <li>
              You can click on your "Profile" image on top right corner of your
              screen to change the language during the exam for entire question
              paper. On clicking of Profile image you will get a drop-down to
              change the question content to the desired language.
            </li>
            <li>
              You can click on
              <span
                className="down-arrow-instruction"
              />
              to navigate to the bottom and
              <span
                className="up-arrow-instruction"
              />
              to navigate to the top of the question area, without scrolling.
            </li>
          </ol>
          <p>
            <strong>
              <br/>
              <br/>
              <br/>
              <u>Navigating to a Question:</u>
              <br/>
              <br/>
              <br/>
            </strong>
          </p>
          <ol
            start="14"
            style={{
              textAlign: "left",
              listStyleType: "decimal",
              paddingLeft: "4%",
              paddingTop: "3px",
            }}
          >
            <li value="7">
              To answer a question, do the following:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Click on the question number in the Question Palette at the
                  right of your screen to go to that numbered question directly.
                  By selecting answer to this question and thereafter going to
                  the next question directly from question palette does NOT save
                  your answer to the current question.
                </li>
                <li>
                  Click on <strong>Save &amp; Next</strong> to save your answer
                  for the current question and then go to the next question.
                </li>
                <li>
                  Click on <strong>Mark for Review &amp; Next</strong> to save
                  your answer for the current question, mark it for review, and
                  then go to the next question.
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <strong>
              <br/>
              <br/>
              <br/>
              <u>Answering a Question: </u>
              <br/>
              <br/>
            </strong>
          </p>
          <br />
          <ol>
            <li value="8">
              Procedure for answering a multiple choice type question:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  To select your answer, click on the button of one of the
                  options
                </li>
                <li>
                  To deselect your chosen answer, click on the button of the
                  chosen option again or click on the
                  <strong>Clear Response</strong> button
                </li>
                <li>
                  To change your chosen answer, click on the button of another
                  option
                </li>
                <li>
                  To save your answer, you MUST click on the
                  <strong> Save &amp; Next</strong> button
                </li>
                <li>
                  To mark the question for review, click on the
                  <strong> Mark for Review &amp; Next</strong> button.
                </li>
              </ol>
            </li>
            <li value="9">
              To change your answer to a question that has already been
              answered, first select that question for answering and then follow
              the procedure for answering the question.
            </li>
          </ol>
          <p>
            <strong>
              <br/>
              <br/>
              <br/>
              <u>Navigating through sections:</u>
              <br/>
              <br/>
            </strong>
          </p>
          <br />
          <ol>
            <li value="10">
              Sections in this question paper are displayed on the top bar of
              the screen. Questions in a section can be viewed by clicking on
              the section name. The section you are currently viewing is
              highlighted.
            </li>
            <li>
              After clicking the <strong>Save &amp; Next</strong> button on the
              last question for a section, you will automatically be taken to
              the first question of the next section.
            </li>
            <li>
              You can shuffle between sections and questions anytime during the
              examination as per your convenience only during the time
              stipulated.
            </li>
            <li>
              Candidate can view the corresponding section summary as part of
              the legend that appears in every section above the question
              palette.
            </li>
          </ol>
          <br />
          <p>
            <strong>
              <u>Instruction for images:</u>
            </strong>
          </p>
          <br />
          <ol start="14">
            <li>
              To zoom the image provided in the question roll the cursor over
              it.
            </li>
          </ol>
        </div>
      );
    case "HINDI":
      return (
        <div
          className="examInstText2"
          style={{
            height: "93%",
            width: "97%",
            overflow: "auto",
            paddingLeft: "10px",
          }}
        >
          <pre style={{ textAlign: "center" }}>
            <strong>कृप्या निम्नलिखित निर्देशों को ध्यान से पढ़ें</strong>
          </pre>
          <br />
          <p>
            <strong>
            <u>सामान्य अनुदेश</u>
            </strong>
          </p>
          <br />
          
          <br />
          <ol>
            <li>
              सभी प्रश्नों को हल करने के लिए आपको {data?.meta.time / 60} मिनट का समय दिया जाएगा।
            </li>
            <li>
              सर्वर पर घड़ी सेट की गई है तथा आपकी स्क्रीन के दाहिने कोने में
              शीर्ष पर काउंटडाउन टाइमर में आपके लिए परीक्षा समाप्त करने के लिए
              शेष समय प्रदर्शित होगा। परीक्षा समय समाप्त होने पर, आपको अपनी
              परीक्षा बंद या जमा <strong> ( Submit ) </strong> करने की जरूरत नहीं
              है । यह स्वतः बंद या जमा <strong> ( Submit ) </strong> हो जाएगी।
            </li>
            <li>
              स्क्रीन के दाहिने कोने पर प्रश्न पैलेट, प्रत्येक प्रश्न के लिए
              निम्न में से कोई एक स्थिति प्रकट करता है:
            </li>
          </ol>
          <table className="instruction_area" style={{ fontSize: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <span className="not_visited" title="Not Visited">
                    1
                  </span>
                </td>
                <td>आप अभी तक प्रश्न पर नहीं गए हैं।</td>
              </tr>
              <tr>
                <td>
                  <span className="not_answered" title="Not Answered">
                    2
                  </span>
                </td>
                <td>आपने प्रश्न का उत्तर नहीं दिया है।</td>
              </tr>
              <tr>
                <td>
                  <span className="answered" title="Answered">
                    3
                  </span>
                </td>
                <td>आप प्रश्न का उत्तर दे चुके हैं।</td>
              </tr>
              <tr>
                <td>
                  <span className="review" title="Marked for Review">
                    4
                  </span>
                </td>
                <td>
                आपने प्रश्न का उत्तर नहीं दिया है पर प्रश्न को पुनर्विचार के लिए चिन्हित किया है।
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className="review_marked_considered"
                    title="Answered &amp; Marked for Review"
                  >
                    5
                  </span>
                </td>
                <td>
                प्रश्न जिसका उत्तर दिया गया है और समीक्षा के लिए भी चिन्हित है , उसका मूल्यांकन किया जायेगा ।
                </td>
              </tr>
            </tbody>
          </table>
          <ol start="3">
            <li>
              पुनर्विचार के लिए चिह्नित <strong> ( Marked for Review ) </strong>
              स्थिति सामान्यतः अनुस्मारक के रूप में कार्य करती है जिसे आपने
              प्रश्न को दुबारा देखने के लिए सेट किया है ।
              <span className="instruction-red-highlight"> यदि आपने किसी प्रश्न के लिए उत्तर चुना है जिसे पुनर्विचार के लिए चिह्नित किया है, तब मूल्यांकन में उस उत्तर पर विचार किया जाएगा।</span>
            </li>
            <li>
              आप प्रश्न पैलेट को छुपाने के लिए, "<strong>&gt;</strong>" चिन्ह पर
              क्लिक कर सकते है, जो प्रश्न पैलेट के बाईं ओर दिखाई देता है, जिससे
              प्रश्न विंडो सामने आ जाएगा. प्रश्न पैलेट को फिर से देखने के लिए, "
              <strong> &lt;</strong> " चिन्ह पर क्लिक कीजिए जो प्रश्न विंडो के
              दाईं ओर दिखाई देता है।
            </li>
            <li>
              सम्पूर्ण प्रश्नपत्र की भाषा को परिवर्तित करने के लिए आप को अपने
              स्क्रीन के ऊपरी दाहिने सिरे पर स्थित प्रोफाइल इमेज पर क्लिक करना
              होगा। प्रोफाइल इमेज को क्लिक करने पर आपको प्रश्न के अंतर्वस्तु को
              इच्छित भाषा में परिवर्तित करने के लिए ड्राप-डाउन मिलेगा ।
            </li>
            <li>
              आपको अपने स्क्रीन के निचले हिस्से को स्क्रॉलिंग के बिना नेविगेट
              करने के लिए
              <span
                className="down-arrow-instruction"
              />
              और ऊपरी हिस्से को नेविगेट करने के लिए
              <span
                className="up-arrow-instruction"
              />
              पर क्लिक करना होगा ।
            </li>
          </ol>
          <p>
            <br/>
            <br/>
            <br/>
            <strong>
              किसी प्रश्न पर जाना
            </strong>
            :<br />
            <br/>
            <br/>
            <br/>
          </p>
          <ol>
            <li value="7">
              उत्तर देने हेतु कोई प्रश्न चुनने के लिए, आप निम्न में से कोई एक
              कार्य कर सकते हैं:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  स्क्रीन के दायीं ओर प्रश्न पैलेट में प्रश्न पर सीधे जाने के
                  लिए प्रश्न संख्या पर क्लिक करें। ध्यान दें कि इस विकल्प का
                  प्रयोग करने से मौजूदा प्रश्न के लिए आपका उत्तर सुरक्षित नहीं
                  होता है।
                </li>
                <li>
                  वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए और क्रम में अगले
                  प्रश्न पर जाने के लिए <strong> Save and Next </strong> पर क्लिक
                  करें।
                </li>
                <li>
                  वर्तमान प्रश्न का उत्तर सुरक्षित करने के लिए, पुनर्विचार के लिए चिह्नित करने और क्रम में अगले प्रश्न पर जाने के लिए <strong>  Mark for Review and Next </strong> पर क्लिक करें।
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br/>
            <br/>
            <br/>
            <strong>
              प्रश्नों का उत्तर देना
            </strong>
            :<br />
            <br/>
            <br/>
            <br/>
          </p>
          <ol>
            <li value="8">
              बहुविकल्प प्रकार प्रश्न के लिए
              <br/>
            <br/>
            <br/>
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  अपना उत्तर चुनने के लिए, विकल्प के बटनों में से किसी एक पर
                  क्लिक करें।
                </li>
                <li>
                  चयनित उत्तर को अचयनित करने के लिए, चयनित विकल्प पर दुबारा
                  क्लिक करें या <strong>  Clear Response  </strong> बटन पर क्लिक
                  करें।
                </li>
                <li>
                  अपना उत्तर बदलने के लिए, अन्य वांछित विकल्प बटन पर क्लिक करें।
                </li>
                <li>
                  अपना उत्तर सुरक्षित करने के लिए, आपको
                  <strong> Save &amp; Next  </strong> पर क्लिक करना जरूरी है।
                </li>
                <li>
                  किसी प्रश्न को पुनर्विचार के लिए चिह्नित करने हेतु
                  <strong>  Mark for Review &amp; Next </strong> बटन पर क्लिक करें। <i>यदि किसी प्रश्न के लिए उत्तर चुना हो जो कि पुनर्विचार के लिए चिह्नित किया गया है, तब अंतिम मूल्यांकन में उस उत्तर पर विचार किया जाएगा।</i>
                </li>
              </ol>
            </li>
            <br/>
            <br/>
            <br/>
            <li>
              किसी प्रश्न का उत्तर बदलने के लिए, पहले प्रश्न का चयन करें, फिर नए
              उत्तर विकल्प पर क्लिक करने के बाद <strong> Save &amp; Next </strong> बटन पर क्लिक करें।
            </li>
          </ol>
          <p>
            <br/>
            <br/>
            <br/>
            <br/>
            <strong>
              अनुभागों द्वारा प्रश्न पर जाना
            </strong>
            :<br/>
            <br/>
            <br/>
          </p>
          <ol>
            <li value="10">
              इस प्रश्नपत्र में स्क्रीन के शीर्ष बार पर अनुभाग
              <strong> ( Sections</strong> ) प्रदर्शित हैं। किसी अनुभाग के प्रश्न,
              उस अनुभाग के नाम पर क्लिक करके देखे जा सकते हैं। आप वर्तमान में
              जिस अनुभाग का उत्तर दे रहे हैं, वह अनुभाग हाइलाइट होगा।
            </li>
            <li>
              किसी अनुभाग के लिए अंतिम प्रश्न के
              <strong> Save &amp; Next </strong> बटन पर क्लिक करने के बाद, आप
              स्वचालित रूप से अगले अनुभाग के प्रथम प्रश्न पर पहुंच जाएंगे।
            </li>
            <li>
              आप परीक्षा में निर्धारित समय के दौरान किसी भी समय प्रश्नावलियों और
              प्रश्नों के बीच अपनी सुविधा के अनुसार आ-जा (शफल कर) सकते हैं।
            </li>
            <li>
              परीक्षार्थी संबंधित सेक्शन की समीक्षा को लीजेन्ड के भाग के रूप में देख सकते हैं जो हर एक सेक्शन में प्रश्न पॅलेट के ऊपर दिखाई देता है।
            </li>
          </ol>
          <p>
            <br/>
            <br/>
            <br/>
            <strong>
              <u>छवियों के लिए निर्देश</u>
            </strong>
            <br/>
            <br/>
          </p>
          <p>
            <pre>
              14.प्रश्न में दी गई छवि को ज़ूम ( <strong> ZOOM </strong> ) करने के लिए
              उस पर माउस कर्सर से रोल करें ।
            </pre>
          </p>
          <br/>
        </div>
      );
    default:
      return <div>No Instruction Available for selected language.</div>;
  }
};

export default GeneralExamInstructions;

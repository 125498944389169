import React, { useEffect, useState } from "react";
import { secondToHMS, microsecondsToHms, pick } from "../../../utils";
const TestResultsComparison = ({ testResult }) => {
  const [selectedSection, setSelectedSection] = useState("OVERALL");
  const [comparisonData, setComparisonData] = useState();
  useEffect(() => {
    if (testResult) {
      let ComparisonDataObj = {};
      const USER_TOPPER_MAP = {
        totalMarks: "totalMarks",
        marks: "marks",
        totalQuestions: "totalQuestions",
        correctAnswerCount: "correctAnswerCount",
        incorrectAnswerCount: "incorrectAnswerCount",
        accuracy: "accuracy",
        totalTime: "totalTime",
        timeSpent: "timeSpent",
        dtbQuesSecMarks:"dtbQuesSecMarks",
        dtbQuesSecCount:"dtbQuesSecCount"
      };
      const AVERAGE_MAP = {
        averageScore: "marks",
        averageCorrect: "correctAnswerCount",
        averageIncorrect: "incorrectAnswerCount",
        averageAccuracy: "accuracy",
        averageTime: "timeSpent",
        totalMarks: "totalMarks",
        totalTime: "totalTime",
        totalQuestions: "totalQuestions",
        dtbQuesSecMarks:"dtbQuesSecMarks",
        dtbQuesSecCount:"dtbQuesSecCount"
      };
      // overall data
      let overallTotalQuestions = 0;
      let overallTotalTime = 0;
      let overallTotalMarks = 0;
      let overallCorrectQuestions = 0;
      let overallInCorrectQuestions = 0;
      let topperCorrectQuestions = 0;
      let topperInCorrectQuestions = 0;
      let overAllDtbQuesCount = 0;
      let overAllDtbQuesMarks = 0;


      testResult.sections.forEach((section) => {
        ComparisonDataObj[section.sectionName] = {};
        let userData = pick(USER_TOPPER_MAP, section||{});
        if(section?.info){
          section.info.dtbQuesSecCount = section?.dtbQuesSecCount || 0;
          section.info.dtbQuesSecMarks = section?.dtbQuesSecMarks || 0;
        }
        overallCorrectQuestions += userData.correctAnswerCount;
        overallInCorrectQuestions += userData.incorrectAnswerCount;
        let averageData = pick(AVERAGE_MAP, section?.info||{});
        averageData.totalMarks = userData.totalMarks;
        averageData.totalQuestions = userData.totalQuestions;
        averageData.totalTime = userData.totalTime;

        //
        overallTotalMarks += userData.totalMarks;
        overallTotalQuestions += userData.totalQuestions;
        overallTotalTime += userData.totalTime;
        overAllDtbQuesMarks += userData.dtbQuesSecMarks;
        overAllDtbQuesCount += userData.dtbQuesSecCount;
        //

        ComparisonDataObj[section.sectionName].userInfo = userData;
        ComparisonDataObj[section.sectionName].averageInfo = averageData;
      });

      if (overallTotalTime == 0) {
        overallTotalTime = testResult.overall.totalTime;
      }

      testResult?.topperInfo?.sections.forEach((section) => {
        let topperData = pick(USER_TOPPER_MAP, section);
        topperCorrectQuestions += topperData.correctAnswerCount;
        topperInCorrectQuestions += topperData.incorrectAnswerCount;
        if (ComparisonDataObj[section?.sectionName])
          ComparisonDataObj[section?.sectionName].topperInfo = topperData;
      });
      let userOverallData = pick(USER_TOPPER_MAP, testResult?.overall || {});
      userOverallData.totalMarks = overallTotalMarks;
      userOverallData.totalTime = overallTotalTime;
      userOverallData.totalQuestions = overallTotalQuestions;
      userOverallData.correctAnswerCount = overallCorrectQuestions;
      userOverallData.incorrectAnswerCount = overallInCorrectQuestions;
      userOverallData.dtbQuestionMarks = overAllDtbQuesMarks;
      userOverallData.dtbQuestionCount = overAllDtbQuesCount;


      let topperOverallData = pick(
        USER_TOPPER_MAP,
        testResult?.topperInfo?.overall || {}
      );
      topperOverallData.totalMarks = overallTotalMarks;
      topperOverallData.totalTime = overallTotalTime;
      topperOverallData.totalQuestions = overallTotalQuestions;
      topperOverallData.correctAnswerCount = topperCorrectQuestions;
      topperOverallData.incorrectAnswerCount = topperInCorrectQuestions;
      topperOverallData.dtbQuestionMarks = overAllDtbQuesMarks;
      topperOverallData.dtbQuestionCount = overAllDtbQuesCount;
      let averageOverallData = pick(AVERAGE_MAP, testInfo || {});
      averageOverallData.totalMarks = overallTotalMarks;
      averageOverallData.totalTime = overallTotalTime;
      averageOverallData.totalQuestions = overallTotalQuestions;
      averageOverallData.dtbQuestionMarks = overAllDtbQuesMarks;
      averageOverallData.dtbQuestionCount = overAllDtbQuesCount;
      ComparisonDataObj["OVERALL"] = {
        userInfo: userOverallData,
        averageInfo: averageOverallData,
        topperInfo: topperOverallData,
      };
      setComparisonData(ComparisonDataObj);
    }
  }, [testResult]);

  if (!testResult) return <></>;
  console.log("testResult", testResult);
  const { sections, topperInfo, testInfo, toppersList, overall } = {
    ...testResult,
  };
  const formattedSecondToHMS = (d) => {
    return secondToHMS(d, ["h", "m", "s"]);
  };
  const formattedMicrosecondsToHms = (d) => {
    return microsecondsToHms(d, ["h ", "m ", "s "]);
  };

  const formattedMicrosecondsToMs = (t) => {
    let d = t / 1000;
    var m = Math.floor(d / 60);
    var s = Math.floor((d % 3600) % 60);
    var mDisplay = m > 0 ? m.toString().padStart(2, "0") + "m " : "";
    var sDisplay = s > 0 ? s.toString().padStart(2, "0") + "s" : `00s`;
    return mDisplay + sDisplay;
  };
  const getWidthPercentage = (value, total) => {
    let percentage = (value / total) * 100;
    return `${percentage}%`;
  };

  return (
    <>
      <div className="tr-overview">
        {testResult?.overall?.dtbQuestotalCount > 0 && (
          <p className="tr-sub-info">
            <img
              src={"/images/test-solution/info-icon.svg"}
              alt="tick-blue.svg"
            />
            Subjective questions({testResult?.overall?.dtbQuestotalCount}) are
            not included for analysis
          </p>
        )}
        <div className="tr-overview-row">
          <div className="tr-overview-card" style={{ flexDirection: "column" }}>
            <div className="trc-header">
              <span>Compare</span>
              <span>You • Topper • Average</span>
            </div>
            <div
              className="trc-selector"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {/* Header */}
              <button
                className={`right-button ${
                  selectedSection == "OVERALL" ? "" : "inactive-rb"
                }`}
                onClick={() => {
                  setSelectedSection("OVERALL");
                }}
              >
                Overall
              </button>
              {sections.length > 1 &&
                sections.map((section) => {
                 if(section?.dtbQuesSecCount !== section?.totalQuestions){
                  return (
                    <button
                      className={`right-button ${
                        selectedSection == section.sectionName
                          ? ""
                          : "inactive-rb"
                      }`}
                      onClick={() => {
                        setSelectedSection(section.sectionName);
                      }}
                    >
                      {section.secDN}
                    </button>
                  );
                }
                })}
            </div>
            <div className="trc-body">
              {/* Body */}

              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Score</th>
                    <th>Accuracy</th>
                    <th>Correct</th>
                    <th> Wrong</th>
                    <th> Time</th>
                  </tr>
                </thead>
                <tbody>
                  {comparisonData && comparisonData[selectedSection] ? (
                    <>
                      {["userInfo", "topperInfo", "averageInfo"].map(
                        (dataType, index) => {
                          let data = comparisonData[selectedSection][dataType];
                          let userInfo;
                          switch (index) {
                            case 0:
                              userInfo = (
                                <>
                                  <span>You</span>
                                  <span>User</span>
                                </>
                              );
                              break;
                            case 1:
                              userInfo = (
                                <>
                                  <span>Topper</span>
                                  <span>{toppersList[0].name}</span>
                                </>
                              );
                              break;
                            case 2:
                              userInfo = userInfo = (
                                <>
                                  <span>Average</span>
                                </>
                              );
                              break;
                          }

                          return (
                            <tr>
                              <td>
                                <div className="trc-user-info">{userInfo}</div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: getWidthPercentage(
                                      data.marks,
                                      data.totalMarks - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionMarks' : 'dtbQuesSecMarks'] || 0)
                                    ),
                                  }}
                                >
                                  <div>
                                    <span>{data.marks.toFixed(2)}</span>
                                    <span>
                                      {" "}
                                      /{" "}
                                      {data.totalMarks - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionMarks' : 'dtbQuesSecMarks'] || 0) }
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: getWidthPercentage(
                                      data.accuracy,
                                      100
                                    ),
                                  }}
                                >
                                  <div>
                                    <span>{data.accuracy} %</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: getWidthPercentage(
                                      data.correctAnswerCount,
                                      data.totalQuestions - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionCount' : 'dtbQuesSecCount'] || 0)
                                    ),
                                  }}
                                >
                                  <div>
                                    <span>{data.correctAnswerCount}</span>
                                    <span>
                                      {" "}
                                      /{" "}
                                      {data.totalQuestions - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionCount' : 'dtbQuesSecCount'] || 0)}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: getWidthPercentage(
                                      data.incorrectAnswerCount,
                                      data.totalQuestions - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionCount' : 'dtbQuesSecCount'] || 0)
                                    ),
                                  }}
                                >
                                  <div>
                                    <span>{data.incorrectAnswerCount}</span>
                                    <span>
                                      {" "}
                                      /{" "}
                                      {data.totalQuestions - (data && data[selectedSection == "OVERALL" ? 'dtbQuestionCount' : 'dtbQuesSecCount'] || 0)}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: getWidthPercentage(
                                      data.timeSpent / 1000,
                                      data.totalTime
                                    ),
                                  }}
                                >
                                  <div>
                                    <span>
                                      {formattedMicrosecondsToMs(
                                        data.timeSpent
                                      )}
                                    </span>
                                    <span> / {data.totalTime / 60} m</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* <tr>
                    <td>
                      <div>
                        <span>You</span>
                        <span>User</span>
                      </div>
                    </td>
                    <td>
                      <div
                      >
                        <span>{userSectionData.marks.toFixed(2)}</span>
                        <span>/{userSectionData.totalMarks.toFixed(2)}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: `${userSectionData.accuracy}%`,
                        }}
                      >
                        <span>{userSectionData.accuracy}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: userSectionData.correctAnswerCount
                            ? `${
                                (userSectionData.correctAnswerCount /
                                  userSectionData.totalQuestions) *
                                100
                              }%`
                            : `${(userData[0] / userData[2]) * 100}%`,
                        }}
                      >
                        <span>
                          {userSectionData.correctAnswerCount || userData[0]}
                        </span>
                        <span>
                          /{userSectionData.totalQuestions || userData[2]}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: userSectionData.incorrectAnswerCount
                            ? `${
                                (userSectionData.incorrectAnswerCount /
                                  userSectionData.totalQuestions) *
                                100
                              }%`
                            : `${(userData[1] / userData[2]) * 100}%`,
                        }}
                      >
                        <span>
                          {userSectionData.incorrectAnswerCount || userData[1]}
                        </span>
                        <span>
                          /{userSectionData.totalQuestions || userData[2]}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: `${
                            (userSectionData.timeSpent /
                              1000 /
                              userSectionData.totalTime) *
                            100
                          }`,
                        }}
                      >
                        <span>
                          {formattedMicrosecondsToHms(
                            userSectionData.timeSpent
                          )}
                        </span>
                        <span>/{userSectionData.totalTime / 60}m</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <span>Topper</span>
                        <span>{toppersList[0].name}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{topperSectionData.marks.toFixed(2)}</span>
                        <span>/{topperSectionData.totalMarks.toFixed(2)}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{topperSectionData.accuracy.toFixed(2)}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {topperSectionData.correctAnswerCount ||
                            topperData[0]}
                        </span>
                        <span>
                          /{topperSectionData.totalQuestions || topperData[2]}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {topperSectionData.incorrectAnswerCount ||
                            topperData[1]}
                        </span>
                        <span>
                          /{topperSectionData.totalQuestions || topperData[2]}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{topperSectionData.timeSpent}</span>
                        <span>/{topperSectionData.totalTime}</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <span>Average</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {averageSectionData.averageScore.toFixed(2)}
                        </span>
                        <span>/{topperSectionData.totalMarks.toFixed(2)}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {averageSectionData.averageAccuracy.toFixed(2)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {averageSectionData.averageCorrect.toFixed(2)}
                        </span>
                        <span>/{topperData[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {averageSectionData.averageIncorrect.toFixed(2)}
                        </span>
                        <span>/{topperData[2]}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{averageSectionData.averageTime}</span>
                        <span>/{topperSectionData.totalTime}</span>
                      </div>
                    </td>
                  </tr>
                */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestResultsComparison;

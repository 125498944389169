import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import CountDownTimer from "../CountDownTimer";
import ErrorBoundary from "../ErrorBoundary";

/**
 * BusinessTag component displays business-related information.
 * @component
 *
 * @param {Object} props - The component's props.
 * @param {string} props.iconSrc - The source URL for the icon image.
 * @param {number} props.tagType - The type of the business tag (0, 1,2 or 3).
 * @param {string} props.name - The name of the business.
 * @param {number} props.endTime - The end time for the countdown timer.
 * @param {Function} props.setIsBusinessTagVisible - Callback function to set the visibility of the business tag.
 *
 * @returns {JSX.Element} React component.
 */
const BusinessTag = ({
  iconSrc = "",
  tagType = 0,
  name = "",
  endTime = 0,
  setIsBusinessTagVisible,
}) => {
  return (
    <div className="listingFewHourLeft">
      <img src={iconSrc} alt="bsnTag" loading="lazy" height={14} width={14} />{" "}
      {tagType === 1 && "Price dropped By "}
      {name}
      {tagType === 2 && (
        <ErrorBoundary>
          <CountDownTimer
            targetTime={endTime || 0}
            onTimerEnd={setIsBusinessTagVisible}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};
// Define PropTypes for the component
BusinessTag.propTypes = {
  iconSrc: PropTypes.string,
  tagType: PropTypes.number,
  name: PropTypes.string,
  endTime: PropTypes.number,
  setIsBusinessTagVisible: PropTypes.func.isRequired, // It's required
};

export default BusinessTag;

import types from './types';
import {timeLineUrl_alt} from '../../../../constants/appConfig';
import { func } from 'prop-types';

export function fetchTimelineDataById(id, type) {
    return {
        CALL_API: [
            {
                type: types.GET_TIMELINE_BY_ID,
                meta: {
                    path: `${timeLineUrl_alt}/getTimeLineById?contentId=${id}&type=${type}`,
                    method: 'GET',
                }
            }
        ]
    };
}
export function getHTMLContent(url) {
    return {
        CALL_API: [
            {
                type: types.GET_TIMELINE_STATIC_DATA,
                meta: {
                    path: url,
                    method: 'GET',
                }
            }
        ]
    };
}

export function clearPreviousHtmlData() {
    return {
        type: types.CLEAR_POST_DATA,
        method: 'LOCAL',
    };
}

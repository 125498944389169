import React, { useMemo, useState } from "react";
import CustomModal from "../../../../library/CustomModal";
import { Indicator } from "../../TestResultAnalysis/Components/Indicator";
import { LIVE_TEST_CONSTANTS, hitMoengageForLiveTest } from "../../TestResultAnalysis/Components/liveTestUtils";
const ExamInsStickyFooter = ({
  language = "",
  instructionPageNo = 1,
  onInsChange,
  acceptTnC = false,
  setAcceptTnC,
  data,
  changeLanguage,
  handleStartTestCb,
  isViewInstructionPopup = false,
  mode = "",
  showIndicator=false,
  indicatorMessage="",
  disableTnC=false,
  getMoengagePayload=()=>{},
}) => {
  const [popupErrorMsg, setPopupErrorMsg] = useState("");
  const [languageSelectBox, setLanguageSelectBox] = useState("");

  const onClosePopup = () => {
    setPopupErrorMsg("");
  };
  const handleStartTest = () => {
    // validate language T&C before start test.
    let inValidFlagMsg = "";
    if (languageSelectBox === "") {
      inValidFlagMsg = "Please select the default language to proceed further.";
    } else if (!acceptTnC) {
      inValidFlagMsg = "Please accept terms and conditions before proceeding.";
    }
    if (inValidFlagMsg) {
      setPopupErrorMsg(inValidFlagMsg);
      return;
    }
    // start test callback called when validation passed.
    if (!inValidFlagMsg) {
      handleStartTestCb();
    }
  };
  const disableStartTest = useMemo(() => {
    return !language || !acceptTnC;
  }, [language, acceptTnC]);
  const pickComp = {
    1: <button onClick={() => onInsChange(2)}>Next</button>,
    2: !isViewInstructionPopup ? (
      <div className="ins-footer2">
        <div className="ins-select-lg">
          <label htmlFor="language">Choose Your default Language:</label>
          <select
            id="language"
            className="language-select"
            onChange={(e) => {
              changeLanguage(e);
              setLanguageSelectBox(e.target.value)
            }}
            value={languageSelectBox}
          >
            <option value={""}>-Select-</option>
            {Object.keys(data).map((key, index) => {
              if (["meta", "orgName"].includes(key) || !data[key]) {
                return "";
              }
              return (
                <option value={key} key={key}>
                  {key}
                </option>
              );
            })}
          </select>
        </div>
        <p className="highlightText">
          Please note all questions will appear in your default language. This
          language can be changed for a particular question later on.
        </p>
        <div className="ins-tcs">
          <input
            type="checkbox"
            id="tcs"
            value="start-test"
            checked={acceptTnC}
            disabled={disableTnC}
            onChange={() => {
              if(mode) hitMoengageForLiveTest("instruction_window", "starttest_instructions_clicked", getMoengagePayload());
              setAcceptTnC(!acceptTnC);
            }}
          />
          <label htmlFor="tcs">
            I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in position of / not wearing any / not carrying any prohibited
            gadget like mobile phone, bluetooth devices, etc/any prohibited
            material with me into the examination hall, I agree that in case of
            not adhering to the instructions, I shall be liable to be barred
            from this test and/or to disciplinary action, which may include
            banned from the future tests / examinations.
          </label>
        </div>
        <div className="ins-cta">
          <button className="ins-prev-btn" onClick={() => onInsChange(1)}>
            Previous
          </button>
          <button
            className={
              disableStartTest ? "disable-exm-test-btn" : "exm-test-btn"
            }
            onClick={handleStartTest}
          >
            I am ready to begin
          </button>
        </div>
      </div>
    ) : (
      <div className="ins-footer2">
        <div className="ins-cta">
          <button className="ins-prev-btn" onClick={() => onInsChange(1)}>
            Previous
          </button>
        </div>
      </div>
    ),
  };

  return (
    <>
      <CustomModal
        visible={!!popupErrorMsg}
        title="Info"
        onCancel={onClosePopup}
        closeCallback={onClosePopup}
        closable={true}
        modalClassName={"exam-start-tcs"}
      >
        <p className="popmsg">{popupErrorMsg}</p>
        <div className="tcsOkBtn">
          <button onClick={onClosePopup}>Ok</button>
        </div>
      </CustomModal>
      <div className="ins-sticky-footer">
        <div className="OnOffInd"> {mode ? <Indicator 
                showIndicator={showIndicator}
                indicatorBody={`${indicatorMessage}`}
                additionIndicatorWrapper={`${navigator?.onLine ? "indicator-online" : "indicator-offline"}`}
              /> : ""}
        </div>
        {pickComp[instructionPageNo]}
      </div>
    </>
  );
};
export default ExamInsStickyFooter;

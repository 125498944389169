import React from "react";
import { pageName } from "../../../constants/appConfig";
import { capitalizedString, makeUrl } from "../../data/utils/helpers";

const CarouselSchema = ({ data, origin, schemaType, isOnlyLiveClasses}) => {

  const createInnerHtml = (schemaObj) => {
    if(Object.keys(schemaObj)?.length > 0){
      const newInnerHtmlObj = 
      {
        "@context": "https://schema.org",
        "@type": schemaType === "Course" ? "Course" :"ItemList",
      }
      const schemaData = {...newInnerHtmlObj, ...schemaObj};
      return JSON.stringify(schemaData);
    }
  }

  const createSchemaArr = (carousalData) => {
    const newSchemaArr = [];
    if (carousalData?.length > 0) {
      for (let i = 0; i < carousalData.length; i++) {
        const item = carousalData[i];
        newSchemaArr.push(
          {
            "@type": "ListItem",
            "position": i + 1,
            "item": {
              "@type": "Course",
              "url": item?.url,
              ...getSchemaCommonObj(item)
            }
          }
        );
      }
    }
    return newSchemaArr;
  }

  const getSchemaCommonObj = (item) => {
    let commonObj = {
      "name": item?.title,
      "description": schemaType === "Carousel" ? item?.description || getDescription(item?.title) : getDescription(item?.title),
      "provider": {
        "@type": "Organization",
        "name": pageName[0],
        "sameAs": "https://www.adda247.com",
      },
      "offers": [{
        "@type": "Offer",
        "category": "Paid",
        "priceCurrency": "INR",
        "price": item?.price
      }],
      "hasCourseInstance": [{
        "@type": "CourseInstance",
        "courseMode": "Online",
        "location": "Online",
        "courseWorkload": `PT${item?.olchoursVideos}H`,
        "instructor": item?.faculties ? item?.faculties?.map(faculty => {
          return {
            "@type": "Person",
            "name": faculty?.name,
            "description": faculty?.highlights,
            "image": faculty?.image
          }
        }) : []
      }]
    }
    return commonObj;
  }

  const getDescription = (title) => {
    let description = `Avail ${capitalizedString(
      title
    )} online at ${pageName[1]} at most affordable prizes. Prepare with the latest & updated study material to ace your dream.`;
    return description;
  } 

  const getCarouselSchemaData = (data) => {
    let packagesEntity;
    if(isOnlyLiveClasses){
      packagesEntity = data?.slice(0, 8);
    }else{
      packagesEntity = data?.[0]?.packageEsEntity;
    }
    const formatedData = [];
    packagesEntity?.forEach(item => {
      const usedTitle = makeUrl(item?.slugURL || item?.title);
      const url = origin? (origin + "/product-onlineliveclasses/" + item?.id + "/" + usedTitle): "";

      const formatedItem = {};
      formatedItem.title = item?.title;
      formatedItem.url = url;
      formatedItem.description = getDescription(item?.title);
      formatedItem.price = item?.price;
      formatedItem.faculties = item?.faculties;
      formatedItem.olchoursVideos = item?.olchoursVideos;
      formatedData.push(formatedItem);
    })
    return formatedData;
  }

  const createCarouselData = (data) => {
    const firstCategory = data?.[0]?.category;
    if(firstCategory === "ONLINE_LIVE_CLASSES" || data?.categoryList?.includes("ONLINE_LIVE_CLASSES") || data?.categoryList?.includes("VIDEOS") || isOnlyLiveClasses){
      return getCarouselSchemaData(data);
    }else{
      return [];
    }
  }

  let schemaData = {};

  if(schemaType === "Carousel") {
    const carousalSchemaData = createCarouselData(data);
    schemaData = { "itemListElement" : createSchemaArr(carousalSchemaData) };
  }
  else if(schemaType === "Course") {
    schemaData = getSchemaCommonObj(data);
  }

  const innerHtml = createInnerHtml(schemaData);

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
      />
    );
};

export default CarouselSchema;
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  clearSearchSuggestions,
  getSearchSuggestions,
} from "../../data/ducks/header/actions";
import { pageName } from "../../../constants/appConfig";
import {
  getMoengageMetadata,
  getUrlPath,
} from "../../data/utils/helpers";
import { debounce, useOutsideAlerter } from "../utils";
import Loadable from 'react-loadable';


const OverLaySearchHOC = Loadable({
  loader: () => import("../library/OverLaySearchHOC"),
  modules: ["../library/OverLaySearchHOC"],
  webpack: [require.resolve("../library/OverLaySearchHOC")],
  loading: () => <div></div>,
});
const PopularCourseList = Loadable({
  loader: () => import("./PopularCourseList"),
  modules: ["./PopularCourseList"],
  webpack: [require.resolve("./PopularCourseList")],
  loading: () => <div></div>,
});
const SearchBoxContainer = Loadable({
  loader: () => import("./SearchBoxContainer"),
  modules: ["./SearchBoxContainer"],
  webpack: [require.resolve("./SearchBoxContainer")],
  loading: () => <div></div>,
});
const TrendingSearch = Loadable({
  loader: () => import("./TrendingSearch"),
  modules: ["./TrendingSearch"],
  webpack: [require.resolve("./TrendingSearch")],
  loading: () => <div></div>,
});




const SearchUIOverLay = ({
  setSearchUiVisiblity,
  searchResults,
  getSearchSuggestions,
  clearSearchSuggestions,
  courseList,
}) => {
  const [keyWord, setKeyWord] = useState({
    prevValue: "",
    currentValue: "",
  });
  const [activeTab, setActiveTab] = useState("ALL");
  const [showSearchingLoader, setShowSearchingLoader] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [isKeywordChanged, setIsKeywordChanged] = useState(true);
  const [categoryTabs, setCategoryTabs] = useState([]);
  const inputRef = useRef();
  const history = useHistory();
  const wrapperRef = useRef(null);
  const handleKeyWordChange = (value, cbFrom) => {
    if (value !== keyWord) {
      setKeyWord((prev) => ({
        prevValue: prev.currentValue,
        currentValue: value,
      }));
    }
    /**
     * Focus the input when user clicks on the Left Side Bar Trending Searches.
     */
    if (cbFrom === "trendingSearch") inputRef.current.focus();
  };
  const closeSearchPopup = () => {
    setShowSearchPopup(false);
  };
  useOutsideAlerter(wrapperRef, closeSearchPopup);

  const routeChange = () => {
    setKeyWord({
      prevValue: "",
      currentValue: "",
    });
    clearSearchResults();
    /**
     * reset search UI when user navigates to other page.
     */
    setSearchUiVisiblity(false);
  };
  useEffect(() => {
    //clear listener when route changes
    const historyListener = history.listen(routeChange);
    return () => {
      removeEventListener("listen", historyListener);
    };
  }, []);

  const primaryFacetsRequired = (e) => {
    if (e.target.id === "searchBar") {
      setIsKeywordChanged(true);
    } else {
      setIsKeywordChanged(false);
    }
  };

  const handleOnChangeKeyword = (keyword, activeTab) => {
    /**
     * encode the special characters from the string to be used in the url.
     */
    let encodedKeyword = encodeURIComponent(keyword);
    getSearchSuggestions(encodedKeyword, activeTab, isKeywordChanged).then(
      (res) => setShowSearchingLoader(false)
    );
  };

  // set showSearchPopup to true after 2 seconds of typing in the search box.
  const showPopupSearchAfterDelay = (delay = 2000) => {
    const handlePopupSearchTimer = () => {
      setShowSearchingLoader(true);
      handleOnChangeKeyword(keyWord.currentValue, activeTab);
      setShowSearchPopup(true);
    };
    debounce(handlePopupSearchTimer, delay);
  };

  useEffect(() => {
    searchPopupVisibilityHandler();
  }, [keyWord, activeTab]);

  const specialCharDetect = (string) => {
    var format = /[!@#$%^&*_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(string)) {
      return true;
    } else {
      return false;
    }
  };
  const searchPopupVisibilityHandler = (isFocusOverInput = false) => {
    let keywordLast = keyWord.currentValue[keyWord.currentValue.length - 1];
    let isSpecialCharsFlag = specialCharDetect(keywordLast);

    // run below code only when KeyWord lenght is greater than 1 and less than or equal to 2 characters.
    if (keyWord.currentValue.length > 1 && keyWord.currentValue.length <= 2) {
      if (keywordLast != " " && !isSpecialCharsFlag)
        showPopupSearchAfterDelay(2000);
    }
    // run below code only when KeyWord lenght is greater than 2 characters.
    else if (keyWord.currentValue?.length >= 3) {
      if (keywordLast != " " && !isSpecialCharsFlag) {
        setShowSearchPopup(true);
        if (!isFocusOverInput) {
          setShowSearchingLoader(true);
          debounce(
            () => handleOnChangeKeyword(keyWord.currentValue, activeTab),
            1000
          );
        }
      }
    } else {
      setShowSearchPopup(false);
    }
  };

  useEffect(() => {
    searchPopupVisibilityHandler();
  }, [keyWord]);
  useEffect(() => {
    if (keyWord?.length >= 3) {
      setShowSearchingLoader(true);
      debounce(() => handleOnChangeKeyword(keyWord), 100);
    }
  }, [activeTab]);

  const handleEnterPress = (e, keyWord) => {
    if (e.keyCode === 13) {
      let payload = {
        source: "web",
        brand: pageName[1],
        term: keyWord,
        action: "search_hard_hit_made",
      };
      if (typeof Moengage !== "undefined")
        Moengage.track_event("search", getMoengageMetadata(payload));
      if (keyWord.length > 1) {
        window.open(
          getUrlPath(
            `/search`,
            `categoryFacets=${"TEST_SERIES,BOOKS,EBOOKS,VIDEOS,ONLINE_LIVE_CLASSES"}&searchTerm=${keyWord}&primaryFilter=true`
          ),
          "_self"
        );
      }
    }
  };

  const clearSearchResults = () => {
    clearSearchSuggestions();
  };

  const makeTabs = (primaryFacets) => {
    const possibleCategories = [
      { category: "ALL", label: "ALL" },
      { category: "ONLINE_LIVE_CLASSES", label: "Live classes" },
      { category: "VIDEOS", label: "Videos" },
      { category: "TEST_SERIES", label: "Test series" },
      { category: "BOOKS", label: "Books" },
      { category: "EBOOKS", label: "Ebooks" },
      { category: "MAGAZINES", label: "Magazines" },
      {category: "MODELS_3D", label:"3D Learning"}
    ];
    const categoryTabsArr = [];
    if (Object.keys(primaryFacets || {}).length > 1) {
      categoryTabsArr.push({
        category: "ALL",
        label: "ALL",
      });
    }

    // here we are made tabsList from searchResults primaryFacets
    Object.keys(primaryFacets || {}).forEach((el) => {
      let splitedFacets = el.split("#");
      categoryTabsArr.push({
        category: splitedFacets[1],
        label: splitedFacets[0],
      });
    });

    /**
     * here we are making reorderCategoryTabs from categoryTabsArr
     */
    let reorderCategoryTabs = [];
    reorderCategoryTabs = possibleCategories.filter((tab) =>
      categoryTabsArr.find((category) => category.category === tab.category)
    );
    setCategoryTabs(reorderCategoryTabs);

    // setActiveTab state with first tab for single category.
    if (reorderCategoryTabs.length === 1) {
      setActiveTab(reorderCategoryTabs[0]?.category);
    }
  };
  useEffect(() => {
    if (searchResults?.primaryFacets?.categories && showSearchPopup) {
      makeTabs(searchResults?.primaryFacets?.categories);
    }
  }, [searchResults?.primaryFacets?.categories, showSearchPopup]);
  return (
    <OverLaySearchHOC
      setSearchUiVisiblity={setSearchUiVisiblity}
      SearchComponent={
        <div className="searchbar-input" ref={wrapperRef}>
          <input
            ref={inputRef}
            id={"searchBar"}
            type="text"
            value={keyWord.currentValue}
            onChange={(e) => handleKeyWordChange(e.target.value)}
            onKeyDown={(e) => handleEnterPress(e, keyWord.currentValue)}
            onFocus={(e) => {
              searchPopupVisibilityHandler(true);
              primaryFacetsRequired(e);
            }}
            autoComplete="off"
            placeholder="Enter Course, Category or keyword"
            autoFocus
          />
          <div
            className="searchicon"
            onClick={() => {
              if (keyWord.currentValue.length) {
                // set state's -> (keyWord & activeTab) to default when user click on cross icon.
                setKeyWord({
                  prevValue: "",
                  currentValue: "",
                });
                clearSearchResults();
                setActiveTab("ALL");
              }
            }}
          >
            <img
              src={`/images/${keyWord.currentValue?.length
                ? "close-icon.svg"
                : "icon-search.svg"
                }`}
            />
          </div>
          {/* SearchBoxContainer should only be visible on focus over input and keyword length is greater >= 3*/}
          {showSearchPopup && (
            <SearchBoxContainer
              onClick={handleKeyWordChange}
              keyWord={keyWord.currentValue}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabList={categoryTabs}
              searchResults={searchResults}
              showSearchingLoader={showSearchingLoader}
              primaryFacetsRequiredCb={primaryFacetsRequired}
            />
          )}
        </div>
      }
    >
      <div
        className={`full-width-sarch-content ${showSearchPopup ? "blur" : ""}`}
      >
        <div className="container">
          <TrendingSearch
            onClick={(value) => {
              handleKeyWordChange(value, "trendingSearch");
              setActiveTab("ALL");
            }}
            keyWord={keyWord.currentValue}
          />
          <PopularCourseList coursesList={courseList} />
        </div>
      </div>
    </OverLaySearchHOC>
  );
};
SearchUIOverLay.fetching = ({ dispatch }) => {
  return [dispatch(getSearchSuggestions())];
};
const mapStateToProps = (state) => ({
  showLoader: state.header.showLoader,
  searchResults: state.header.searchResultData,
  courseList: state?.header?.allTrendingData?.data?.hotSellingPackages || [],
});
const mapDispatchToProps = {
  getSearchSuggestions,
  clearSearchSuggestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUIOverLay);

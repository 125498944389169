import React, { useState, useEffect, useRef, useMemo } from "react";
import CourseCard from "./CourseCard";
import { Cookies } from "react-cookie";
import AButton from "../library/AButton";
import { timestampToDateTimedaysecond } from "../utils";
import { getTimeArray } from "../dateUtils";
import CustomLoader from "../library/CustomLoader";
import NoResultsFound from "../library/NoResultsFound";
import NoDataFoundPPC from "../LiveClasses/NoDataFoundPPC";
import { enrolledCoursesUrl, pageName, videoPackageUrl } from "../../../constants/appConfig";
import CustomModal from "../library/CustomModal";
import VideoPlayer from "../library/VideoPlayer/VideoPlayer";
import {
  addScripts,
  removeScripts,
  scrollToTop,
} from "../../data/utils/helpers";
import ShakaPlayer from "../shakaPlayer";
import fetch from "../../data/utils/fetch";
import { getMoengageMetadata } from "../../data/utils/helpers";
import { EBOOKS_COPYRIGHT_MSG, RATING } from "../../../constants/textConstants";

import OlcTimer from "./OlcTimer";
import RatingModalContent from "./RatingModalContent";
import { BLANK_IDENTIFIER } from "../MoengageConstants";
import { moengageEventForLiveClass } from "../LiveClassMoengage";
import HnadoutPanel from "../LiveClasses/HnadoutPanel";
import { indianTimezoneOffset } from "../../../constants/appConstants";
const cookie = new Cookies();

const OlcSection = ({
  data,
  getData,
  searchText,
  setsuggestionData,
  setinternalTab,
  setSearchText,
  setInputText,
  moengageEventSectionTabChange,
  moengageEventforstatus,
  history,
  selectedTab,
  title,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [videoJsOPtion, setvideoJsOptions] = useState();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [videoId, setVideoId] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [recordedUrl, setrecordedUrl] = useState();
  const [dashUrl, setDashUrl] = useState();
  const [hlsUrl, setHlsUrl] = useState();
  const [webDrmEnabled, setWebDrmEnabled] = useState();
  const [videoStatus, setVideoStatus] = useState("");
  const [vStatusData, setVStatusData] = useState([]);
  const [className, setClassName] = useState("");
  const [classId, setClassId] = useState("");
  const [progressApiFlag, setProgressApiFlag] = useState(false);
  const focus = useRef();
  const liveClassOpened = useRef();
  const token = cookie.get("cp_token");
  const [showLicenseExpiredPopUp, setShowLicenseExpiredPopUp] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [isRatingVideosModalVisible, setIsRatingVideosModalVisible] =
    useState(false);
  const [canUserRate, setCanUserRate] = useState(false);
  const [userTotalWatchTime, setUserTotalWatchTime] = useState(0);
  const [currentWt, setCurrentWt] = useState(0);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  const [showRatinModalAllowed, setShowRatingModalAllowed] = useState(true);
  const [sourceScreen] = useState("my-purchase-course");
  const [handoutStatus, setHandoutStatus] = useState(false);

  const [selectedCourseDetail, setSelectedCourseDetail] = useState({});
  const [videoPipStatus, setVideoPipStatus] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [nextVideoId, setNextVideoId] = useState(null);
  const [nextVideoDetail, setNextVideoDetail] = useState(null);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(null);

  useEffect(() => {
    scrollToTop(0);
    addScripts();
    getCurrentTimeStamp();
    return () => {
      // remove JS Scripts;
      removeScripts();
    };
  }, []);

  const getCurrentTimeStamp = () => {
    try {
      fetch(`${enrolledCoursesUrl.liveclassesV1}/current-timestamp`, "GET").then((res) => {
        if (res?.success) {
          setCurrentTimeStamp(res?.data);
        }
      });
    }
    catch (err) {
      console.error(err);
    }
  }

  const openEbook = (data) => {
    if (confirm(EBOOKS_COPYRIGHT_MSG)) {
      typeof window !== "undefined" &&
        window.open(`/live-class-pdf?file=${data}`);
    }
  };

  useEffect(() => {
    if (!data) {
      getData();
    }
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        getData();
      }
    });
  }, []);

  useEffect(() => {
    if (data) getVideoStatusData(data?.[0]?.packageId);
  }, [data]);

  const getUserTotalWatchTime = async () => {
    try {
      let res = await fetch(
        `${videoPackageUrl.videoRatingStatus}?packageId=${id}&src=and&videoId=${recordedUrl}`,
        "GET"
      );
      if (res?.success && res?.data?.length > 0) {
        setUserTotalWatchTime(res?.data[0]?.twt);
        return res?.data[0]?.twt;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const getVId = (url) => {
    return url.split("/").pop()?.split(".")?.[0];
  };

  const getVideoStatusData = async (pkgId) => {
    try {
      let res = await fetch(
        `${videoPackageUrl.videoStatus}?packageId=${pkgId}`,
        "GET"
      );
      if (res.success) {
        let data = res.data;
        setVStatusData(data);
      } else {
        setVStatusData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRatingAction = async () => {
    return getUserTotalWatchTime();
  };

  const showRatingPopup = async () => {
    if (handleRatingAction && canUserRate) {
      const twt = await handleRatingAction();
      if (twt) {
        if (twt / 1000 > totalVideoDuration / 2) {
          setIsRatingVideosModalVisible(true);
        }
      }
    }
  };
  const oncancel = () => {
    setHandoutStatus(false);
    if (showRatinModalAllowed && totalVideoDuration) {
      showRatingPopup();
    }
    if (data) getVideoStatusData(data?.[0]?.packageId);
    setShowModal(false);
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(true);
  };

  const onRatingCancel = () => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
  };

  const onRatingCloseIcon = () => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
  };

  // handles the videoProgress and getVideoProgress for Recorded videos related code.
  const getSelectedVideo = () => {
    return videoStatus === "Recorded" ? recordedUrl : "";
  };
  // end of above code.

  const rejoin = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, [100]);
  };

  const JoinClass = function (classData, status) {
    setSelectedCourseDetail(classData);

    //Moe event
    moengageEventforstatus(status);
    if (status === "Join Live")
      moengageEventForLiveClass(classData, title, sourceScreen);

      const currentTime = (currentTimeStamp || Date.now()) + (5.5*60*60*1000);
      const endTime = classData?.endTime;

    if (classData.status === "Today" && classData.joinUrl  && currentTime < endTime) {
      liveClassOpened.current = true;
      let redirectLiveUrl = window?.location?.pathname || "/my-purchase";
      redirectLiveUrl = redirectLiveUrl.replace(/\//g, ".");

      let d = new Date();
      d.setTime(d.getTime() + 525600 * 60 * 1000);
      cookie.set("cp_liveredirecturl", redirectLiveUrl, {
        path: "/",
        expires: d,
      });

      window.open(classData.joinUrl, "_blank");
    } else if (
      (classData.status === "Recorded" || classData.status === "Today") &&
      classData.url
    ) {
      nextVideoDetails(classData.classId);
      setSelectedVideoId(classData.classId);
      setVideoId(classData.classId);
      setId(classData.packageId);
      setFacultyId(classData.facultyId);
      setrecordedUrl(classData?.url);
      setDashUrl(classData?.dashUrl);
      setHlsUrl(classData?.hlsUrl);
      setWebDrmEnabled(classData?.webDrmEnabled);
      setVideoStatus(classData?.status || "");
      setClassName(classData?.name || "");
      setClassId(classData?.classId);
      var substring = "s3.ap-south-1.amazonaws.com";
      var boolean = classData.url.indexOf(substring) !== -1;
      if (boolean) {
        const videoOptions = {
          controls: true,
          autoplay: true,
          preload: "auto",
          loop: false,
          manualCleanup: true,
          playbackRates: [0.5, 1, 1.5, 2],
          techOrder: ["html5"],
          html5: {
            nativeAudioTracks: false,
            nativeVideoTracks: false,
            hls: {
              overrideNative: true,
              withCredentials: true,
            },
          },
          sources: [
            {
              src: `${videoPackageUrl.liveclassVideoSource}?vp=${classData.url}&pkgId=${classData.packageId}&isOlc=true`,
              type: "application/x-mpegURL",
            },
          ],
        };
        setvideoJsOptions(videoOptions);
      } else {
        var str = classData?.url?.replace(
          "https://youtu.be/",
          "https://www.youtube.com/embed/"
        );
        var urlstr = str + "?rel=0&amp;modestbranding=1&amp;showinfo=0";
        const videoOptions = {
          controls: true,
          autoplay: true,
          preload: "auto",
          loop: false,
          manualCleanup: true,
          techOrder: ["youtube"],
          sources: [
            {
              src: `${urlstr}`,
              type: "video/youtube",
            },
          ],
        };
        setvideoJsOptions(videoOptions);
      }
      setShowModal(true);
    }

    return;
  };

  if (!data) return <CustomLoader />;
  if (data.length == 0)
    return (
      <div className="course-content">
        <NoResultsFound
          image={"/images/coming_soon.svg"}
          message={"Sorry to keep you waiting..."}
          message2={
            "We are in the process of setting up the content for you. Please check again after some time"
          }
        />
      </div>
    );
  const Filters = [
    { id: "", label: "All Classes" },
    { id: "Today", label: "Today" },
    { id: "Recorded", label: "Recorded" },
    { id: "Upcoming", label: "Upcoming" },
  ];
  let displayData =
    data &&
    data.filter((item) => {
      return !selectedFilter || item.status === selectedFilter;
    });
  if (searchText) {
    displayData = displayData.filter((item) => {
      return item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }
  const getInfo = (olcData) => {
    let dateObj = new Date(olcData.startTime);
    let time = timestampToDateTimedaysecond(olcData.startTime, [
      "AM",
      "PM",
    ]).split(" ");
    let timeArray = getTimeArray(olcData.startTime);
    return `${timeArray[4].replace(/^0(?:0:0?)?/, "")} | ${
      timeArray[2]
    } ${timeArray[1].slice(0, 3)} | ${timeArray[0]}`;
  };

  const getButtonStatus = (olcData) => {
    var status = "Watch";
    if (olcData && olcData.url) {
      var vdata = vStatusData?.filter((item) => item.vid == olcData.url)?.[0];
      if (vdata) {
        if (vdata?.wt > 0) status = "Resume";
        if (vdata?.wt === 0 && vdata?.twt > 0) status = "Replay";
      }
      return status;
    } else {
      return status;
    }
  };

  const getButtonComponent = (olcData) => {
    // let obj = {}

    let ButtonComponent = "";
    let ButtonText = "";
    let isLive = false;
    if (olcData.status == "Today") {
      let starttime = new Date(olcData.startTime);
      let endtime = new Date(olcData.endTime);
      let currentTime = currentTimeStamp || Date.now();
      var UTCsecondsstarttime =
        starttime.getTime() + indianTimezoneOffset * 60 * 1000;
      var UTCsecondsendtime =
        endtime.getTime() + indianTimezoneOffset * 60 * 1000;
      let remainingTime = UTCsecondsstarttime - currentTime;
      let remainingEnd = Date.now() - UTCsecondsendtime;

      isLive =
        Date.now() >= UTCsecondsstarttime && Date.now() <= UTCsecondsendtime;

      ButtonComponent = (
        <OlcTimer
          startTime={Math.floor(remainingTime / 1000)}
          TimerCompleteComponent={
            <AButton
              onClick={() => {
                olcData.joinUrl ? JoinClass(olcData, "Join Live") : null;
              }}
              text={"Join Live"}
            ></AButton>
          }
          duration={olcData.duration}
          ComingsoonComponent={<AButton text={"COMING SOON"}></AButton>}
          WatchComponent={
            <AButton
              disabled={!olcData.url}
              onClick={() => {
                olcData.url ? JoinClass(olcData, "Watch") : null;
              }}
              text={"Watch"}
            ></AButton>
          }
        ></OlcTimer>
      );

      if (olcData.status == "Recorded") {
        ButtonText = getButtonStatus(olcData);
      } else if (olcData.status == "Upcoming") {
        ButtonText = "COMING SOON";
      }
      if (ButtonText) {
        ButtonComponent = (
          <AButton disabled={!olcData.url} text={ButtonText}></AButton>
        );
      }
    } else if (olcData.status == "Recorded") {
      ButtonText = getButtonStatus(olcData);
    } else if (olcData.status == "Upcoming") {
      ButtonText = "COMING SOON";
    }
    if (ButtonText) {
      ButtonComponent = (
        <AButton
          onClick={() => {
            ButtonText == "Join Live" || olcData.url
              ? JoinClass(olcData, ButtonText)
              : null;
          }}
          disabled={!olcData.url && ButtonText == "Watch"}
          text={ButtonText}
        ></AButton>
      );
    }

    return {
      actionComponent: ButtonComponent,
      isLive: isLive,
    };
  };

  const getVideoRatingStatus = async () => {
    try {
      let res = await fetch(
        `${`${videoPackageUrl.videoRating}`}?videoId=${classId}&packageId=${id}`,
        "GET"
      );
      if (res?.success) {
        setCanUserRate(false);
      } else {
        setCanUserRate(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  function getWatchTime() {
    // watch time is percentage of the video watched divided by total duration of video
    // NOTE: Re-watching the same portion of video add to watch time
    let watchTime;
    try {
      const userTotalWatchTimeInSecond = userTotalWatchTime / 1000;
      watchTime = parseFloat(
        ((userTotalWatchTimeInSecond / totalVideoDuration) * 100)?.toFixed(2)
      );
    } catch (error) {
      watchTime = 0;
      console.error(error);
    }
    // adding NaN & null check
    if (isNaN(watchTime) || watchTime === null) {
      watchTime = 0;
    }

    return watchTime;
  }

  const handleFinalRatingSubmit = async (rating, type, selectedDetails) => {
    let payload = {};
    if (type === RATING.WITHOUT_DETAILED_FEEDBACK) {
      payload = {
        faculty_id: facultyId,
        faculty_rating: rating.teacherRating,
        content_rating: rating.contentRating,
        videoId: classId,
        packageId: id,
        watch_time: getWatchTime(),
      };

      try {
        let res = await fetch(
          `${videoPackageUrl.videoSaveFeedback}`,
          "POST",
          payload
        );
        if (res?.success) {
          if (
            parseInt(rating.teacherRating) === 5 &&
            parseInt(rating.contentRating) === 5
          ) {
            setIsRatingVideosModalVisible(false);
            setCanUserRate(false);
          }
        } else {
          alert(res.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }

    if (type === RATING.WITH_DETAILED_FEEDBACK) {
      payload = {
        comments: selectedDetails.ratingMsg,
        option: selectedDetails.selectedOptions,
        videoId: classId,
        packageId: id,
        watch_time: getWatchTime(),
      };

      try {
        let res = await fetch(
          `${videoPackageUrl.videoSaveUserCommnets}`,
          "POST",
          payload
        );

        if (res?.success) {
          setCanUserRate(false);
          setIsRatingVideosModalVisible(false);
        } else {
          alert(res.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }

    // moengage events

    moengageEventsForRating(RATING.RATING_SUB);
  };

  const moengageEventsForRating = (actionType) => {
    let payloaddata = {};
    payloaddata.package_id = id;
    payloaddata.package_status = videoStatus || BLANK_IDENTIFIER;
    payloaddata.package_title = className;
    payloaddata.package_url = recordedUrl;
    payloaddata.product_type = selectedTab;
    payloaddata.action = actionType;
    payloaddata.section_type = BLANK_IDENTIFIER;

    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "Live_Class_Recording_Rating",
        getMoengageMetadata(payloaddata)
      );
    }
  };

  const handleRatingSkip = (detailsSectionType) => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
    moengageEventsForRating(
      detailsSectionType ? RATING.RATING_SKIPPED : RATING.REASON_SKIPPED
    );
  };

  const getCurrentVideoWt = (wt) => {
    setCurrentWt(wt);
  };

  const isVideoTypeOlc = (videoStatus) => {
    let isOlc = false;
    switch (videoStatus) {
      case "Recorded":
        isOlc = true;
        break;
      case "Today":
        isOlc = true;
        break;
      case "Upcoming":
        isOlc = true;
        break;
      default:
        isOlc = false;
        break;
    }
    return isOlc;
  };
  const shaka = () => {
    useMemo(() => (
      <ShakaPlayer
        url={dashUrl}
        hlsUrl={hlsUrl}
        contentType={"Conceptual"}
        packageId={id}
        id={recordedUrl}
        classId={classId}
        isBookMarked={false}
        isCompleted={false}
        isOlc={isVideoTypeOlc(videoStatus)}
        getSelectedVideo={() => getSelectedVideo()}
        setProgressApi={(s) => setProgressApiFlag(s)}
        progressFlag={progressApiFlag}
        setShowLicenseExpiredPopUp={setShowLicenseExpiredPopUp}
        // rating props
        isRatingEnable={true}
        handleRatingAction={handleRatingAction}
        getVideoRatingStatus={getVideoRatingStatus}
        userTotalWatchTime={userTotalWatchTime}
        setIsRatingVideosModalVisible={setIsRatingVideosModalVisible}
        canUserRate={canUserRate}
        getCurrentVideoWt={getCurrentVideoWt}
        setTotalVideoDuration={setTotalVideoDuration}
        setShowRatingModalAllowed={setShowRatingModalAllowed}
        openHandoutSection={() => {
          setHandoutStatus(true);
        }}
        handoutsAvailable={selectedCourseDetail?.handoutsAvailable}
        bookmarkData={selectedCourseDetail?.bookmarks}
        pictureInPicture={videoPipStatus}
        closeViewModal={setVideoPipStatus}
      />
    ));
  };
  const nextVideoDetails = (id) => {
    // Get the element with id "custom-modal"
    setSelectedVideoId(id);
    const foundIndex = displayData.findIndex((obj) => obj.classId === id);
    if (foundIndex !== -1) {
      // Check for the next object with the "recordedvideourl" property
      const nextObjectWithRecordedVideoUrl = displayData
        .slice(foundIndex + 1)
        .find((obj) => obj.url);

      if (nextObjectWithRecordedVideoUrl) {
        setNextVideoDetail(nextObjectWithRecordedVideoUrl);
      } else {
        console.log("No next object with recordedvideourl found.");
      }
    } else {
      console.log("Object with classId", id, "not found.");
    }
  };
  const handleNextVideoPlay = () => {
    JoinClass(nextVideoDetail, "Watch");
  };

  return (
    <div className="course-content">
      {webDrmEnabled ? (
        <CustomModal
          modalClassName={"full-screen-modal"}
          contentClass={"full-screen-modal-content"}
          customModalHeaderClass={"custom-modal-header-out"}
          customBodyClass={"custom-modal-body-out"}
          footer={null}
          visible={showModal}
          onCancel={oncancel}
          closeCallback={oncancel}
          closable={true}
          maskClosable={false}
          keyboard={false}
          onBack={oncancel}
          hideCross={true}
          title={`${selectedCourseDetail.name} By ${selectedCourseDetail?.faculty}`}
          goBackIcon={"custom-modal-header-back-btn"}
          titleLabelClass={"custom-modal-header-label"}
        >
          <div className="video-heights">
            {!refresh && (
              <ShakaPlayer
                url={dashUrl}
                hlsUrl={hlsUrl}
                contentType={"Conceptual"}
                packageId={id}
                id={recordedUrl}
                classId={classId}
                isBookMarked={false}
                isCompleted={false}
                isOlc={isVideoTypeOlc(videoStatus)}
                getSelectedVideo={() => getSelectedVideo()}
                setProgressApi={(s) => setProgressApiFlag(s)}
                progressFlag={progressApiFlag}
                setShowLicenseExpiredPopUp={setShowLicenseExpiredPopUp}
                // rating props
                isRatingEnable={true}
                handleRatingAction={handleRatingAction}
                getVideoRatingStatus={getVideoRatingStatus}
                userTotalWatchTime={userTotalWatchTime}
                setIsRatingVideosModalVisible={setIsRatingVideosModalVisible}
                canUserRate={canUserRate}
                getCurrentVideoWt={getCurrentVideoWt}
                setTotalVideoDuration={setTotalVideoDuration}
                setShowRatingModalAllowed={setShowRatingModalAllowed}
                openHandoutSection={() => {
                  setHandoutStatus(true);
                }}
                handoutsAvailable={selectedCourseDetail?.handoutsAvailable}
                bookmarkData={selectedCourseDetail?.bookmarks}
                pictureInPicture={videoPipStatus}
                closeViewModal={setVideoPipStatus}
                nextVideoDetail={nextVideoDetail}
              />
            )}
          </div>
        </CustomModal>
      ) : (
        <CustomModal
          modalClassName={"full-screen-modal"}
          contentClass={"full-screen-modal-content"}
          customModalHeaderClass={"custom-modal-header-out"}
          customBodyClass={"custom-modal-body-out"}
          footer={null}
          visible={showModal}
          onCancel={oncancel}
          closeCallback={oncancel}
          closable={true}
          maskClosable={false}
          keyboard={false}
          onBack={oncancel}
          hideCross={true}
          title={`${selectedCourseDetail.name} By ${selectedCourseDetail?.faculty}`}
          goBackIcon={"custom-modal-header-back-btn"}
          titleLabelClass={"custom-modal-header-label"}
        >
          <VideoPlayer
            videoOptions={videoJsOPtion}
            packageId={id}
            id={recordedUrl}
            demoUrl={false}
            token={
              videoStatus === "Recorded" || videoStatus === "Today" ? token : ""
            } //pass the token in case only for Recorded video.
            isOlc={videoStatus !== "Recorded" && videoStatus !== "Today"} // flag that handles the videoProgress and getVideoProgress for Recorded videos.
            topicName={""}
            // rating props
            isRatingEnable={true}
            handleRatingAction={handleRatingAction}
            getVideoRatingStatus={getVideoRatingStatus}
            userTotalWatchTime={userTotalWatchTime}
            setIsRatingVideosModalVisible={setIsRatingVideosModalVisible}
            canUserRate={canUserRate}
            getCurrentVideoWt={getCurrentVideoWt}
            setTotalVideoDuration={setTotalVideoDuration}
            setShowRatingModalAllowed={setShowRatingModalAllowed}
            totalVideoDuration={totalVideoDuration}
            openHandoutSection={() => {
              setHandoutStatus(true);
            }}
            bookmarkData={selectedCourseDetail?.bookmarks}
            handoutsAvailable={selectedCourseDetail?.handoutsAvailable}
            pictureInPicture={videoPipStatus}
            closeViewModal={setVideoPipStatus}
            nextVideoDetail={nextVideoDetail}
            nextVideoPlayHandler={() => handleNextVideoPlay()}
          />
        </CustomModal>
      )}
      {handoutStatus && (
        <HnadoutPanel
          data={selectedCourseDetail?.joinUrl}
          handlePanelClose={() => {
            setHandoutStatus(false);
          }}
          type="purchased"
          handleVideoPiP={setVideoPipStatus}
        />
      )}
      <CustomModal
        modalClassName={"modalZindex ratingPdpPop"}
        footer={null}
        visible={isRatingVideosModalVisible && showRatinModalAllowed}
        onCancel={onRatingCancel}
        closeCallback={onRatingCloseIcon}
        closable={true}
        maskClosable={false}
        keyboard={false}
        title={RATING.RATE_THIS_VIDEO}
        bodyStyle={{ overflowY: "auto" }}
      >
        <RatingModalContent
          handleFinalRatingSubmit={handleFinalRatingSubmit}
          handleRatingSkip={handleRatingSkip}
          moengageEventsForRating={moengageEventsForRating}
        />
      </CustomModal>

      <div className="course-content-filters">
        <div>
          {Filters.map((filter) => {
            return (
              <AButton
                text={filter.label}
                onClick={() => {
                  moengageEventSectionTabChange(filter.id);
                  setSelectedFilter(filter.id);
                  setinternalTab(filter.id);
                  setSearchText("");
                  setInputText("");
                  setsuggestionData("");
                }}
                contained
                color={filter.id === selectedFilter ? undefined : "grey"}
              />
            );
          })}
        </div>
        <div></div>
      </div>
      {displayData && displayData.length && vStatusData ? (
        <div className="course-items">
          {displayData.map((olc) => {
            let actionInfo = getButtonComponent(olc);
            return (
              <CourseCard
                JoinClass={JoinClass}
                olc={olc}
                live={actionInfo.isLive}
                // showIcon={true}
                title={olc.name}
                openEbook={openEbook}
                // imgSrc={olc.thumbnail}
                actionButton={actionInfo.actionComponent}
                info={getInfo(olc)}
                key={Math.random()}
                classNames={`olc-section-course-card`}
                nextVideoId={nextVideoId}
              />
            );
          })}
        </div>
      ) : (
        <div className="course-items">
          {selectedFilter === "Today" ||
          selectedFilter === "Upcoming" ||
          selectedFilter === "Recorded" ? (
            <NoDataFoundPPC
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          ) : (
            <NoResultsFound />
          )}
        </div>
      )}

      {showLicenseExpiredPopUp && (
        <div className="mock-comman-pop result-pop">
          <div
            className="mock-comman-pop-content"
            style={{ minWidth: "200px", padding: "16px" }}
          >
            <div className="mock-header-popup">
              <div className="mockpopheader-title" style={{ color: "red" }}>
                Sorry for the interruption
              </div>
            </div>
            <div
              className="result-await-timer-title"
              style={{ color: "black", marginBottom: "15px" }}
            >
              We need to reverify the credentials. Please reload to resume
              playing
            </div>
            <span
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => {
                setShowLicenseExpiredPopUp(false);
                rejoin();
              }}
            >
              Reload
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OlcSection;

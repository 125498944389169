import React, { useState, useEffect } from "react";
import CustomLoader from "../library/CustomLoader";
import CustomModal from "../library/CustomModal";
import {
  videoPackageUrl,
  pdpUrls,
  enrolledCoursesUrl,
  pageName,
} from "../../../constants/appConfig";
import {
  addScripts,
  getMoengageMetadata,
  iOSversion,
  isADDA,
  isIosSafari,
  removeScripts,
} from "../../data/utils/helpers";
import NoDataFound from "./NoDataFound";
import ShakaPlayer from "../shakaPlayer";
import VideoPlayer from "../library/VideoPlayer/VideoPlayer";
import { dateMonthTime, getDay, getMonth, getTimeAmPM } from "../utils";
import fetchapi from "../../data/utils/fetch";
import RatingModalContent from "../../components/course/RatingModalContent";
import { RATING } from "../../../constants/textConstants";
import { BLANK_IDENTIFIER } from "../MoengageConstants";
import { moengageEventForLiveClass } from "../LiveClassMoengage";
import HnadoutPanel from "./HnadoutPanel";
import HandOutModal from "./HandOutModal";

const LiveClassesCard = ({
  title,
  todayMatches,
  data,
  activeId,
  parentId,
  setisvideoopen,
  isvideoopen,
  matchDate,
  token,
  showExpiredModal,
  parentInfo,
  published,
  recentParentInfo,
  isExpired,
  todayTime,
  displayEmptyImage,
  globalServerTime,
  desc,
  id,
  webinarurl,
  errorMessage,
  history,
  cookie,
  fetchVideosStatus,
  videoStatusData,
  nextVideoPlayHandler,
  setSelectedVideoId,
  nextVideoId,
  nextThumbnailImage,
}) => {
  const [moengageData, setmoengagedata] = useState();
  const [isViewedOnSingleScreen, setisViewedOnSingleScreen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [videoJsOptions, setvideoJsOptions] = useState("");
  const [isPPC, setIsppc] = useState("");
  const [time, setTime] = useState(Date.now());
  const [serverTime, setGlobarlServerTime] = useState();
  const [newData, setNewData] = useState(data);
  const [status, setStatus] = useState("Watch");
  const [refresh, setRefresh] = useState(false);
  const [showLicenseExpiredPopUp, setShowLicenseExpiredPopUp] = useState(false);
  const [userTotalWatchTime, setUserTotalWatchTime] = useState(0);
  const [isRatingVideosModalVisible, setIsRatingVideosModalVisible] =
    useState(false);
  const [canUserRate, setCanUserRate] = useState(false);
  const [currentWt, setCurrentWt] = useState(0);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  const [showRatinModalAllowed, setShowRatingModalAllowed] = useState(true);
  const [progressApiFlag, setProgressApiFlag] = useState(false);
  const [sourceScreen] = useState("my-live-class");
  const [handoutStatus, setHandoutStatus] = useState(false);
  const [handoutModal, setHandoutModal] = useState(false);
  const [videoPipStatus, setVideoPipStatus] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [globalTimeAvailable, setGlobalTimeAvailable] = useState(false);

  const openEbook = (data) => {
    if (
      confirm(
        `I understand that the E-book is Proprietary & Copyrighted Material of ${pageName[1]}. Any reproduction in any form, physical or electronic mode on public forum etc will lead to infringement of Copyright of ${pageName[1]} and will attract penal actions including FIR and claim of damages under Indian Copyright Act 1957.`
      )
    ) {
      typeof window !== "undefined" &&
        window.open(`/live-class-pdf?file=${data}`);
    }
  };
  useEffect(() => {
    if (data?.classId === nextVideoId) {
      JoinClass(newData);
    }
  }, nextVideoId);

  useEffect(() => {
    removeScripts();
  }, []);

  useEffect(() => {
    if (!globalTimeAvailable)
      setGlobalTimeAvailable(typeof globalServerTime !== "undefined");
  }, [globalServerTime]);

  useEffect(() => {
    let videoIdHash = window.location.hash;
    let ppcStatus;
    if (videoIdHash.includes("current-video")) {
      setIsppc(true);
      ppcStatus = true;
    }
    addScripts();
    return () => {
      // remove JS Scripts;
      removeScripts();
    };
  }, [videoJsOptions, data, todayMatches]);

  useEffect(() => {
    if (
      videoStatusData?.status &&
      newData.classFlag !== videoStatusData.status
    ) {
      setStatus(videoStatusData.status);
    }
  }, [videoStatusData]);

  const getUserTotalWatchTime = async () => {
    try {
      let res = await fetchapi(
        `${`${videoPackageUrl.videoRatingStatus}`}?packageId=${
          data?.packageId
        }&src=and&videoId=${data?.recordedUrl}`,
        "GET"
      );
      if (res?.success && res?.data?.length > 0) {
        setUserTotalWatchTime(res?.data[0]?.twt);
        return res?.data[0]?.twt;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleRatingAction = async () => {
    return getUserTotalWatchTime();
  };

  const showRatingPopup = async () => {
    if (handleRatingAction && canUserRate) {
      const twt = await handleRatingAction();
      if (twt) {
        if (twt / 1000 > totalVideoDuration / 2) {
          setIsRatingVideosModalVisible(true);
        }
      }
    }
  };

  const oncancel = () => {
    setHandoutStatus(false);
    // document.location.reload(true);
    if (showRatinModalAllowed && totalVideoDuration) {
      showRatingPopup();
    }
    fetchVideosStatus();
    setisvideoopen(false);
    setShowModal(false);
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(true);
  };
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getSelectedVideo = () => {
    return data.recordedUrl || "";
  };

  let globalservertimesync = null;
  function synchServerTime(tempArg) {
    let globalServerTime = tempArg;
    globalservertimesync = setInterval(() => {
      globalServerTime += 1000;
      setGlobarlServerTime(globalServerTime);
    }, 1000);
    return globalServerTime;
  }
  useEffect(() => {
    var newMatchArray = JSON.parse(JSON.stringify(newData));
    let timestampData = globalServerTime || +new Date();
    var classStartDiff = timestampData - newMatchArray.startTimeInMilli;
    var classEndDiff = timestampData - newMatchArray.endTimeInMilli;
    if (classStartDiff >= 0) {
      if (newMatchArray.endTimeInMilli - timestampData > 0) {
        newMatchArray.classFlag = "JOIN LIVE";
        newMatchArray.remainingTimeToEnd = classEndDiff;
      } else {
        newMatchArray.classFlag = "Watch";
      }
    } else if (classStartDiff <= -900000) {
      newMatchArray.classFlag = "COMING SOON";
      if (matchDate == newMatchArray.dateMonthMatch) {
        newMatchArray.remainingTime = -classStartDiff;
        newMatchArray.remainingTimeToEnd = classEndDiff;
      }
    } else if (classStartDiff >= -900000 && classStartDiff <= 0) {
      newMatchArray.classFlag = "Start In";
      newMatchArray.remainingTime = -classStartDiff;
      newMatchArray.remainingTimeToEnd = classEndDiff;
    } else if (classStartDiff == 0) {
      newMatchArray.classFlag = "JOIN LIVE";
      newMatchArray.remainingTimeToEnd = classEndDiff;
    }
    setNewData(newMatchArray);
  }, [time, globalTimeAvailable]);

  const JoinLiveClass = (classData) => {
    let redirectLiveUrl = history?.location?.pathname || "/my-live-class";
    redirectLiveUrl = redirectLiveUrl.replace(/\//g, ".");

    let d = new Date();
    d.setTime(d.getTime() + 525600 * 60 * 1000);
    cookie.set("cp_liveredirecturl", redirectLiveUrl, {
      path: "/",
      expires: d,
    });
    window.open(classData.joinurl || webinarurl, "_blank");
  };

  const JoinClass = function (classData) {
    if (
      classData.classFlag === "JOIN LIVE" &&
      (classData.joinurl || webinarurl)
    ) {
      moengageEventForLiveClass(data, title, sourceScreen);
      JoinLiveClass(classData);
    } else if (classData.classFlag === "Watch" && classData.recordedUrl) {
      if (!iOSversion()) {
        let ppcStatus;
        let videoIdHash = window.location.hash;
        if (videoIdHash.includes("current-video")) {
          setIsppc(true);
          ppcStatus = true;
        }
        setSelectedVideoId(data.classId);

        var payload = {};
        payload.id = data.packageId ? data.packageId : null;
        payload.package_status = "Paid";
        payload.name = title ? title : "";
        payload.category = "live-class";
        payload.page_title = data?.topicName ? data.topicName : "";
        payload.subPackageType = "live_videos";
        payload.subPackageId = data.packageId;
        payload.sub_package_title = data?.topicName ? data.topicName : "";
        payload.action = "video_opened";
        payload.isPPc = isPPC;
        setmoengagedata(payload);
        if (typeof Moengage !== "undefined")
          Moengage.track_event("video", getMoengageMetadata(payload));
        var substring = "s3.ap-south-1.amazonaws.com";
        var boolean = classData.recordedUrl.indexOf(substring) !== -1;
        if (boolean) {
          const videoOptions = {
            controls: true,
            autoplay: true,
            preload: "auto",
            loop: false,
            manualCleanup: true,
            playbackRates: [0.5, 1, 1.5, 2],
            techOrder: ["html5"],
            html5: {
              nativeAudioTracks: false,
              nativeVideoTracks: false,
              hls: {
                overrideNative: true,
                withCredentials: true,
              },
            },
            sources: [
              {
                src: `${videoPackageUrl.liveclassVideoSource}?vp=${classData.recordedUrl}&pkgId=${id}&isOlc=true`,
                type: "application/x-mpegURL",
              },
            ],
          };
          setvideoJsOptions(videoOptions);
        } else {
          var str = classData.recordedUrl.replace(
            "https://youtu.be/",
            "https://www.youtube.com/embed/"
          );
          var urlstr = str + "?rel=0&amp;modestbranding=1&amp;showinfo=0";
          const videoOptions = {
            controls: true,
            autoplay: isIosSafari ? false : true,
            preload: "auto",
            loop: false,
            manualCleanup: true,
            techOrder: ["youtube"],
            sources: [
              {
                src: `${urlstr}`,
                type: "video/youtube",
              },
            ],
          };
          setvideoJsOptions(videoOptions);
        }
        setShowModal(true);
        setisvideoopen(true);
      }
    }
    return;
  };

  const moengageEventsForRating = (actionType) => {
    let payloaddata = {};

    payloaddata.package_id = id;
    payloaddata.package_status = data?.status || BLANK_IDENTIFIER;
    payloaddata.package_title = data?.topicName;
    payloaddata.package_url = data?.recordedUrl;
    payloaddata.product_type = activeId;
    payloaddata.action = actionType;
    payloaddata.section_type = BLANK_IDENTIFIER;

    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "Live_Class_Recording_Rating",
        getMoengageMetadata(payloaddata)
      );
    }
  };

  const handleRatingSkip = (detailsSectionType) => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
    moengageEventsForRating(
      detailsSectionType ? RATING.RATING_SKIPPED : RATING.REASON_SKIPPED
    );
  };

  const onRatingCancel = () => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
  };

  const onRatingCloseIcon = () => {
    setIsRatingVideosModalVisible(false);
    setShowRatingModalAllowed(false);
  };

  const getVideoRatingStatus = async () => {
    try {
      let res = await fetchapi(
        `${`${videoPackageUrl.videoRating}`}?videoId=${
          data?.recordedUrl
        }&packageId=${data.packageId}`,
        "GET"
      );
      if (res?.success) {
        setCanUserRate(false);
      } else {
        setCanUserRate(true);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  function getWatchTime() {
    // watch time is percentage of the video watched divided by total duration of video
    // NOTE: Re-watching the same portion of video add to watch time

    let watchTime = 0;
    try {
      const userTotalWatchTimeInSecond = userTotalWatchTime / 1000;
      watchTime = parseFloat(
        ((userTotalWatchTimeInSecond / totalVideoDuration) * 100)?.toFixed(2)
      );
    } catch (error) {
      watchTime = 0;
      console.error(error);
    }
    // adding NaN & null check
    if (isNaN(watchTime) || watchTime === null) {
      watchTime = 0;
    }

    return watchTime;
  }

  const handleFinalRatingSubmit = async (rating, type, selectedDetails) => {
    let payload = {};
    if (type === RATING.WITHOUT_DETAILED_FEEDBACK) {
      payload = {
        faculty_id: data.facultyId,
        faculty_rating: rating.teacherRating,
        content_rating: rating.contentRating,
        videoId: data?.classId,
        packageId: data?.packageId,
        watch_time: getWatchTime(),
      };

      try {
        let res = await fetchapi(
          `${videoPackageUrl.videoSaveFeedback}`,
          "POST",
          payload
        );
        if (res?.success) {
          if (
            parseInt(rating.teacherRating) === 5 &&
            parseInt(rating.contentRating) === 5
          ) {
            setIsRatingVideosModalVisible(false);
            setCanUserRate(false);
          }
        } else {
          alert(res.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }

    if (type === RATING.WITH_DETAILED_FEEDBACK) {
      payload = {
        comments: selectedDetails.ratingMsg,
        option: selectedDetails.selectedOptions,
        videoId: data?.classId,
        packageId: data?.packageId,
        watch_time: getWatchTime(),
      };

      try {
        let res = await fetchapi(
          `${videoPackageUrl.videoSaveUserCommnets}`,
          "POST",
          payload
        );

        if (res?.success) {
          setCanUserRate(false);
          setIsRatingVideosModalVisible(false);
        } else {
          alert(res.message);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
    moengageEventsForRating(RATING.RATING_SUB);
  };

  const getCurrentVideoWt = (wt) => {
    setCurrentWt(wt);
  };

  const rejoin = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, [100]);
  };

  const handleHandoutModal = () => {
    setHandoutModal(true);
  };

  const nextVideoPlayCardHandler = () => {
    // Get the element with id "custom-modal"
    setShowModal(false);
    nextVideoPlayHandler();
  };

  return (
    <div>
      {/* for the course details */}

      {/* for todays live classes */}
      <CustomModal
        fullModalClassName={"full-screen-modal"}
        contentClass={"full-screen-modal-content"}
        footer={null}
        visible={showModal}
        onCancel={oncancel}
        closeCallback={oncancel}
        closable={true}
        maskClosable={false}
        keyboard={false}
        customModalHeaderClass={"custom-modal-header-out"}
        onBack={oncancel}
        hideCross={true}
        customBodyClass={"custom-modal-body-out"}
        title={`${data?.topicName} By ${data?.faculty}`}
        goBackIcon={"custom-modal-header-back-btn"}
        titleLabelClass={"custom-modal-header-label"}
      >
        <div className="video-heights">
          {handoutStatus && (
            <HnadoutPanel
              data={data}
              handlePanelClose={() => {
                setHandoutStatus(false);
              }}
              handleVideoPiP={setVideoPipStatus}
            />
          )}
          {!refresh ? (
            data.webDrmEnabled ? (
              <ShakaPlayer
                url={data.dashUrl}
                showReportHandler={() => setReportModal(true)}
                materialProgressHandler={(value, type) =>
                  materialProgressHandler(value, type)
                }
                contentType={"Conceptual"}
                packageId={data.packageId}
                id={data?.recordedUrl}
                isBookMarked={false}
                isCompleted={false}
                isOlc={true}
                hlsUrl={data.hlsUrl}
                getSelectedVideo={() => getSelectedVideo()}
                setProgressApi={(s) => setProgressApiFlag(s)}
                setShowLicenseExpiredPopUp={setShowLicenseExpiredPopUp}
                progressFlag={progressApiFlag}
                classId={data.classId}
                // rating props
                isRatingEnable={true}
                handleRatingAction={handleRatingAction}
                getVideoRatingStatus={getVideoRatingStatus}
                userTotalWatchTime={userTotalWatchTime}
                setIsRatingVideosModalVisible={setIsRatingVideosModalVisible}
                canUserRate={canUserRate}
                getCurrentVideoWt={getCurrentVideoWt}
                setTotalVideoDuration={setTotalVideoDuration}
                setShowRatingModalAllowed={setShowRatingModalAllowed}
                openHandoutSection={() => {
                  setHandoutStatus(true);
                }}
                bookmarkData={data?.bookmark}
                handoutsAvailable={data?.handoutsAvailable}
                pictureInPicture={videoPipStatus}
                closeViewModal={setVideoPipStatus}
                nextVideoPlayHandler={() => nextVideoPlayCardHandler()}
                nextThumbnailImage={nextThumbnailImage}
              />
            ) : (
              <VideoPlayer
                videoOptions={videoJsOptions}
                isViewedOnSingleScreen={isViewedOnSingleScreen}
                packageId={id}
                id={data.recordedUrl}
                demoUrl={false}
                token={token}
                isOlc={false} // pass isOlc as false so resume GET videoStatus and POST videoStatus code work in player.
                isvideoopen={isvideoopen}
                topicName={data?.topicName ? data.topicName : ""}
                classId={data.classId}
                bookmarkData={data?.bookmark}
                // rating props
                isRatingEnable={true}
                handleRatingAction={handleRatingAction}
                getVideoRatingStatus={getVideoRatingStatus}
                userTotalWatchTime={userTotalWatchTime}
                setIsRatingVideosModalVisible={setIsRatingVideosModalVisible}
                canUserRate={canUserRate}
                getCurrentVideoWt={getCurrentVideoWt}
                setTotalVideoDuration={setTotalVideoDuration}
                setShowRatingModalAllowed={setShowRatingModalAllowed}
                totalVideoDuration={totalVideoDuration}
                openHandoutSection={() => {
                  setHandoutStatus(true);
                }}
                handoutsAvailable={data?.handoutsAvailable}
                pictureInPicture={videoPipStatus}
                closeViewModal={() => {
                  setVideoPipStatus(false);
                }}
                nextVideoPlayHandler={() => nextVideoPlayCardHandler()}
                nextThumbnailImage={nextThumbnailImage}
              />
            )
          ) : (
            <></>
          )}
        </div>
      </CustomModal>
      {handoutModal && (
        <HandOutModal
          data={data}
          close={() => {
            setHandoutModal(false);
          }}
          type="liveclass"
          details={newData}
          openEbook={openEbook}
          isExpired={isExpired}
        />
      )}
      <CustomModal
        modalClassName={"modalZindex ratingPdpPop "}
        footer={null}
        visible={isRatingVideosModalVisible && showRatinModalAllowed}
        onCancel={onRatingCancel}
        closeCallback={onRatingCloseIcon}
        closable={true}
        maskClosable={false}
        keyboard={false}
        title={RATING.RATE_THIS_VIDEO}
        bodyStyle={{ overflowY: "auto" }}
      >
        <RatingModalContent
          handleFinalRatingSubmit={handleFinalRatingSubmit}
          handleRatingSkip={handleRatingSkip}
          moengageEventsForRating={moengageEventsForRating}
        />
      </CustomModal>
      {todayMatches && todayMatches.length > 0 && (
        <div
          className={
            activeId == 1 && matchDate == newData?.dateMonthMatch
              ? "margin-bottom margin-10"
              : "display-none"
          }
        >
          <div
            className={
              !newData?.recordedUrl &&
              globalServerTime > newData?.endTimeInMilli
                ? "md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container class-border-soon  live-class-view-card"
                : !newData?.recordedUrl &&
                  globalServerTime < newData?.startTimeInMilli
                ? "class-border-coming-soon md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
                : newData?.recordedUrl &&
                  newData?.time != todayTime &&
                  newData?.classFlag !== "JOIN LIVE"
                ? "class-border-recorded md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
                : newData?.classFlag == "JOIN LIVE"
                ? "class-border-live md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
                : isExpired
                ? "expired-live-class md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
                : "md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card "
            }
          >
            <div className="large-12 columns detail-card-style ">
              <div className="large-2 columns my-test-series-test-title list-card-detail-style m-web-height1 ">
                <div className="time ">{newData && newData?.time}</div>
                <div className="date ">{newData && newData?.date}</div>
                <div className="day ">{newData && newData?.day}</div>
              </div>
              <div className="large-5 columns list-topic float-left-important m-web-height-2">
                <div className="topic my-test-ellipses ">
                  {newData && newData?.topicName
                    ? newData?.topicName
                    : newData && "Topic will be updated soon "}
                </div>
                {newData && newData?.faculty && (
                  <div className="faculty my-test-ellipses list-faculty-pos ">
                    by&nbsp;
                    {newData && newData?.faculty
                      ? newData?.faculty
                      : "no faculty"}
                    {newData?.keyAdded}
                  </div>
                )}
              </div>
              <div className="large-1 columns list-topic m-web-height-2 mt-3"></div>

              <div className="large-4  rightSideBtnContent columns border-mweb my-test-series-test-title watch-btn-pos right-mobile remove-padding-profile ">
                <div className="btnWrap">
                  {(newData?.handoutsAvailable ||
                    newData?.pdfFileName ||
                    newData?.dppFileNames) && (
                    <i
                      className="handout-button-icon"
                      onClick={handleHandoutModal}
                    ></i>
                  )}
                </div>
                {!isExpired && newData?.classFlag && (
                  <span
                    className={
                      newData?.classFlag === "COMING SOON"
                        ? "right-mobile coming-soon-btn"
                        : newData?.classFlag === "Start In" &&
                          newData?.displayTime
                        ? "join-live-timer-btn"
                        : newData?.classFlag === "JOIN LIVE"
                        ? "join-live-btn"
                        : newData?.recordedUrl && newData?.classFlag === "Watch"
                        ? "watch-btn"
                        : !newData?.recordedUrl &&
                          newData?.classFlag === "Watch"
                        ? "watch-btn-disabled"
                        : null
                    }
                    onClick={() => JoinClass(newData)}
                  >
                    {" "}
                    {newData?.classFlag === "Start In"
                      ? newData?.displayTime
                        ? newData?.displayTime + " mins "
                        : ""
                      : newData?.classFlag}
                  </span>
                )}
              </div>
            </div>

            {isExpired && (
              <div
                onClick={() =>
                  showExpiredModal(
                    parentInfo,
                    published,
                    parentInfo && parentInfo.published
                  )
                }
                className="large-4 columns expired-button "
              >
                <span style={{ float: "right", marginRight: "10px" }}>
                  Expired
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={activeId == 2 ? "margin-bottom margin-10" : "display-none"}
      >
        <div
          className={
            !newData?.recordedUrl && globalServerTime > newData?.endTimeInMilli
              ? "md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container class-border-soon  live-class-view-card"
              : !newData?.recordedUrl &&
                globalServerTime < newData?.startTimeInMilli
              ? "class-border-coming-soon md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
              : newData?.recordedUrl &&
                newData?.time != todayTime &&
                newData?.classFlag !== "JOIN LIVE"
              ? "class-border-recorded md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
              : newData?.classFlag == "JOIN LIVE"
              ? "class-border-live  md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
              : isExpired
              ? "expired-live-class md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card"
              : "md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container   live-class-view-card "
          }
        >
          <div className="large-12 columns detail-card-style ">
            <div className="large-2 columns my-test-series-test-title list-card-detail-style m-web-height1">
              <div className="time ">{newData ? newData?.time : ""}</div>
              <div className="date ">{newData ? newData?.date : ""}</div>
              <div className="day ">{newData ? newData?.day : ""}</div>
            </div>
            <div className="large-5 columns list-topic float-left-important m-web-height-2 ">
              <div className="topic my-test-ellipses ">
                {newData && newData?.topicName
                  ? newData?.topicName
                  : "Topic will be updated soon "}
              </div>
              {newData && newData?.faculty && (
                <div className="faculty my-test-ellipses list-faculty-pos ">
                  by&nbsp;
                  {newData && newData?.faculty
                    ? newData?.faculty
                    : "no faculty"}
                  {newData?.keyAdded}
                </div>
              )}
            </div>
            <div className="large-1 columns list-topic m-web-height-2 mt-3"></div>
            <div className="large-4 rightSideBtnContent columns border-mweb my-test-series-test-title watch-btn-pos right-mobile remove-padding-profile ">
              <div className="btnWrap">
                {(newData?.handoutsAvailable ||
                  newData?.pdfFileName ||
                  newData?.dppFileNames) && (
                  <i
                    className="handout-button-icon"
                    onClick={handleHandoutModal}
                  ></i>
                )}
              </div>

              {!isExpired && (
                <span
                  className={
                    newData?.classFlag === "COMING SOON"
                      ? "right-mobile coming-soon-btn"
                      : newData?.classFlag === "Start In" &&
                        newData?.displayTime
                      ? "join-live-timer-btn"
                      : newData?.classFlag === "JOIN LIVE"
                      ? "join-live-btn"
                      : status !== "Watch"
                      ? "watch-btn"
                      : newData?.recordedUrl && newData?.classFlag === "Watch"
                      ? "watch-btn"
                      : !newData?.recordedUrl && newData?.classFlag === "Watch"
                      ? "watch-btn-disabled"
                      : null
                  }
                  onClick={() => JoinClass(newData)}
                >
                  {" "}
                  {newData?.classFlag === "Start In"
                    ? newData?.displayTime
                      ? newData?.displayTime + " mins "
                      : ""
                    : status !== "Watch"
                    ? status
                    : newData?.classFlag}
                </span>
              )}
            </div>
            {isExpired && (
              <div
                onClick={() =>
                  showExpiredModal(
                    parentInfo,
                    published,
                    parentInfo && parentInfo.published
                  )
                }
                className="large-4 columns expired-button "
              >
                <span style={{ float: "right", marginRight: "10px" }}>
                  Expired
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {showLicenseExpiredPopUp && (
        <div className="mock-comman-pop result-pop">
          <div
            className="mock-comman-pop-content"
            style={{ minWidth: "200px", padding: "16px" }}
          >
            <div className="mock-header-popup">
              <div className="mockpopheader-title" style={{ color: "red" }}>
                Sorry for the interruption
              </div>
            </div>
            <div
              className="result-await-timer-title"
              style={{ color: "black", marginBottom: "15px" }}
            >
              We need to reverify the credentials. Please reload to resume
              playing
            </div>
            <span
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => {
                setShowLicenseExpiredPopUp(false);
                rejoin();
              }}
            >
              Reload
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default LiveClassesCard;

import React, { useState, useEffect, useRef } from "react";
import { makeUrl, getDeviceType, getMoengageMetadata, getPdpUrl, createOptimizedImgUrl, removeSpacesAndInsertHyphens, isADDA } from '../../../data/utils/helpers';
import NewCarousel from "../../library/NewCarousel";
import { Link } from "react-router-dom";
import { pageName } from "../../../../constants/appConfig";
import ContineInApp from "../../library/ContinueInApp/ContinueInApp";
import LazyIntersection from "../../LazyIntersection";
import { BLANK_IDENTIFIER } from "../../MoengageConstants";

let coursepurchaseContainer = React.createRef();
const ExamMenuCarousel = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data)
  }, [])

  useEffect(() => {
    setData(props.data);
    if (data && data.length > 0) {
      let options = {
        root: document.getElementById(`exammenu`),
        rootMargin: "0px",
        threshold: 0.5,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            setData(props.data);
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById(`exammenu-last-item`);
      if (target) observer.observe(target);
    }
  }, [props.data]);

  const [appModaldata, setModalAppdata] = useState();

  const moengageTracker = (e, data) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.exam_category = data?.course || "";
    payload.examNameClicked = data?.name || BLANK_IDENTIFIER;
    payload.source_screen = "Home page";
    payload.getUtmFromStore = props.getUtmFromStore;
    payload.language = "English";
    payload.user_exam_category_selected = "Web";
    payload.deep_link = BLANK_IDENTIFIER;
    payload.package_url = BLANK_IDENTIFIER;
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "browse_by_examName_clicked",
        getMoengageMetadata(payload)
      );

    typeof window != undefined &&
      window.sessionStorage.setItem("ExamCategoryMappingId", props.current);
  };

  return (
    <>
      <section className={`container-padding purchase-container menu-container`}>
        <ContineInApp
          show={!!appModaldata}
          type={appModaldata && appModaldata.type}
          packageId={appModaldata && appModaldata.packageId}
          parentpackageId={""}
          close={() => {
            setModalAppdata("");
          }} />
        {
          <NewCarousel
            current={props.current}
            id={`exammenu`}
            ref={coursepurchaseContainer}
            margin={10}
            classWrapper={'boo-card-container custom-carousel'}
          >
            {
              data?.map((subSubItem, i) => {
                let onCardClick;
                let linkurl;
                if (subSubItem.categories == "BOOKS") {
                  onCardClick = () => {
                    alert("Please check my order section for Book")
                  }
                }
                else {
                  linkurl = subSubItem.linkedPkgs ? `/my-purchase/${subSubItem.id}/${makeUrl(subSubItem.slugURL || subSubItem.title)}`
                    : `/my-purchase/0/package/${subSubItem.id}/${makeUrl(subSubItem.slugURL || subSubItem.title)}`

                  if (getDeviceType() == "m") {
                    linkurl = subSubItem.linkedPkgs ? `/my-purchase/${subSubItem.id}/${makeUrl(subSubItem.slugURL || subSubItem.title)}` : ""
                    onCardClick = () => {
                      setModalAppdata({
                        packageId: subSubItem.id,
                        type: subSubItem.categories,
                      })
                    }
                  }
                }

                return (
                  <div key={subSubItem.id} className='boo-card-wrapper'>
                    <div className="exam-name-box" onClick={(e) => moengageTracker(e, subSubItem)}>
                      <Link
                        to={
                          subSubItem?.urlSlug
                            ? subSubItem?.urlSlug
                            : `/${removeSpacesAndInsertHyphens(
                              subSubItem.name
                            ).toLowerCase()}-exam-kit`
                        }
                        key={subSubItem?.name}
                      >
                        <LazyIntersection
                          modified={true}
                          src={createOptimizedImgUrl(subSubItem?.thumbnail, 120, 120)}
                          className={"exam-img"}
                          width="48px"
                          height="48px"
                          placeholder={isADDA ? "/images/placeholder-adda.svg" : "/images/img-placeholder-sankalp.png"}
                          alt={subSubItem?.name}
                        ></LazyIntersection>
                        <div className="exam-info">
                          <div className="exam-name">{subSubItem?.name}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </NewCarousel>
        }
      </section>
    </>
  );
};

export default ExamMenuCarousel;

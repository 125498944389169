import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import CustomLoader from "../../../components/library/CustomLoader";
import ExamModeToggler from "../../../components/PPC/ExamModeToggler";
import {
  getPPCAllContinueListings,
  getParentPackageExams,
  setInitialAllContinueListingData,
  toggleExamModeKey,
  syncStateWithLocalStorage,
} from "../../../data/ducks/header/actions";
import {
  capitalizeWords,
  removeHyphensAndInsertSpaces,
} from "../../../data/utils/helpers";
import PPCWrapper from "../PPCWrapper";
import PPCContinueListing from "./PPCContinueListing";
import YourExamsListings from "./YourExamsListings";

const PackageYourExamsView = () => {
  const [showContentLoading, setShowContentLoading] = useState(true);
  const { packageId, packageName } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const { packageData, packageExamsData, examModes } = useSelector(
    (state) => state.header
  );
  const dispatch = useDispatch();
  let { allcontinueStudyingData } = packageData;
  let { examsData } = packageExamsData;
  let isExamModeEnabled = false;
  if (packageId && examModes && examModes[packageId]) {
    isExamModeEnabled = examModes[packageId] || false;
  }
  let localStorageExamModes = {};
  if (typeof window !== "undefined" && localStorage.getItem("examModes")) {
    try {
      localStorageExamModes =
        JSON.parse(localStorage.getItem("examModes")) || {};
    } catch (error) {
      console.log("Error while getting ExamModes -->", error);
    }
  }

  const getPPCAllContinueListing = async () => {
    const res = await getPPCAllContinueListings(packageId, 0, 10);
    dispatch(setInitialAllContinueListingData(res));
  };

  const getSelectedExamsData = async () => {
    setShowContentLoading(true);
    await dispatch(getParentPackageExams(packageId));
    setShowContentLoading(false);
  };
  useEffect(() => {
    if (packageId) {
      getSelectedExamsData();
    }
  }, [packageId]);
  useEffect(() => {
    if (packageId) {
      getPPCAllContinueListing();
    }
  }, [packageId]);

  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
    {
      route: pathname,
      name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
    },
  ];
  const storeExamModekey = (packageId, isEnabled) => {
    dispatch(toggleExamModeKey(packageId, isEnabled));
  };

  const onChangingExamMode = (e) => {
    storeExamModekey(packageId, e.target.checked);
    history.replace(`${pathname.replace("/exams", "")}`);
  };
  useEffect(() => {
    // check if key is already available in examModes object.
    // this will run only when selectedExamList is available.
    if (
      packageId &&
      !localStorageExamModes.hasOwnProperty(packageId) &&
      examsData?.selectedExamList?.length
    ) {
      storeExamModekey(packageId, true);
    }
  }, [packageId, examsData]);
  // sync global redux examModes state with localStorageExamModes
  useEffect(() => {
    dispatch(syncStateWithLocalStorage(localStorageExamModes));
  }, []);

  // commented code for now, may use in future
  // redirect to packageviewdetail  page,when selected exam <= 1
  // if (examsData?.selectedExamList?.length <= 1)                 
  //   return <Redirect to={pathname.replace("/exams", "")} />;
  return (
    <PPCWrapper
      breadCrumbRoutes={breadCrumbsList}
      pageTitle={removeHyphensAndInsertSpaces(packageName)}
      LeftSection={
        <ExamModeToggler
          enabled={isExamModeEnabled}
          onChange={onChangingExamMode}
          disabled={showContentLoading} // disable the event till content loading.
        />
      }
      rightSection={
        // show the  manageExam button,when ExamMode is enabled.

        <div
          className="menage-exam-btn"
          style={{
            visibility: isExamModeEnabled ? "visible" : "hidden",
          }}
        >
          {isExamModeEnabled && (
            <Link to={`${pathname.replace("/exams", "")}/selectExams`}>
              Manage Exams
            </Link>
          )}
        </div>
      }
    >
      <section
        className={`ppc-exams-listings ${
          examsData?.selectedExamList?.length ? "examMode-active" : ""
        }`}
      >
        {/* <div className="ppc-listing-search">
          <input type="text" placeholder="Search your exam" />
          <div className="searchicon">
            <img src="/images/ppc-search-icon.svg" />
          </div>
        </div> */}
        <PPCContinueListing
          data={allcontinueStudyingData}
          isCategoryBased={false}
        />
        {showContentLoading ? (
          <CustomLoader />
        ) : (
          <YourExamsListings data={examsData} packageId = {packageId} setLoading = {setShowContentLoading}/>
        )}
      </section>
    </PPCWrapper>
  );
};

export default PackageYourExamsView;

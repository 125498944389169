import React from "react";
import { pageName } from "../../constants/appConfig";
import { isADDA } from "../data/utils/helpers";
class FooterText extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <footer className="new-footer">
          <div className="footer-seo-content">
            {isADDA ? <div className="container homepage-footer">
              <div className="">
                <p>
                  In the era of technology, E-learning has made it possible for students to learn from the comfort of their homes. With the same motive, {pageName[1]} is providing a comfortable learning environment for the aspirants who have been preparing for government exams at an affordable cost price. Started from scratch with an offline coaching center in Delhi in 2010 and now in 2022, {pageName[1]} is one of the top online coaching classes institutes. We have come a long way and are still coming up with new ideas for making {pageName[1]} an all-rounder Ed-tech company in order to help every student in whatever they need to build their career.
                </p>
                <p className="p-label">
                  {pageName[1]} has grasped the title of being the largest Ed-Tech company in India with 1st rank in Learning App in Test prep, 9 Million+ Youtube Subscribers, 15 Million+ active users, Experienced & Helpful Faculty, Best Study Content, and over 1 Lakh +  selection. This is not the end, our journey is still on to overrule the old age education system of India.
                </p>
              </div>
              <div className="content-block">
                <p className="label">What does {pageName[1]} provide?</p>
                <p>{pageName[1]} offers E-learning programs in various sectors, let's have a look at what we master at-</p>
                <p>
                  1. At {pageName[1]}, you can prepare for any government exam be it <a href={`https://www.${pageName[0]}.com/banking-study-materials`} target='_blank'>Banking exam preparation</a>, <a href={`https://www.${pageName[0]}.com/railways-study-materials`} target='_blank'>Railways exam preparation</a>, <a href={`https://www.${pageName[0]}.com/ssc-study-materials`} target='_blank'>SSC study material</a>, Teaching, <a href={`https://www.${pageName[0]}.com/defence-study-materials`} target='_blank'>Defense exam preparation</a>, <a href={`https://www.${pageName[0]}.com/engineering-study-materials`} target='_blank'>Engineering exam preparation</a>, <a href={`https://www.${pageName[0]}.com/upsc-study-materials`} target='_blank'>UPSC exam preparation</a>, State PCS or any other.
                  <br />
                  2. Our Study Material includes Mock Tests, Live Classes, Videos Course, Ebooks, Books and all at an affordable price with the best quality content.
                  <br />
                  3. {pageName[1]} also has experienced & trusted faculty for helping students prepare for various entrance exams like <a href={`https://www.${pageName[0]}.com/neet-exam-kit`} target='_blank'>NEET</a>- NEET UG & NEET PG, <a href={`https://www.${pageName[0]}.com/iit-jee-main-and-advanced-exam-kit`} target='_blank'>JEE Mains and Advanced</a>, GATE, UGC NET,<a href={`https://www.${pageName[0]}.com/cuet-study-materials`} target='_blank'>CUET</a>, <a href={`https://www.${pageName[0]}.com/law-study-materials`} target='_blank'>Law Entrance exams</a>, <a href={`https://www.${pageName[0]}.com/ipm-study-materials`} target='_blank'>IPM Entrance exam</a> and more.
                  <br />
                  4. {pageName[1]} has initiated providing the best education to the school students through online classes.We have also covered study material  for 9th students, class 10th study material, <a href={`https://www.${pageName[0]}.com/class-11-study-materials`} target='_blank'>class 11th study material</a> and class 12th study material.
                  <br />
                  5. {pageName[1]} has launched state exams study material in 8 vernacular languages (Tamil, Telugu, Bengali, Marathi, Odia, Gujarati, Assamese, and Malayalam) apart from English & Hindi language & we are providing online classes for state exams too.
                </p>
              </div>
              <div className="content-block">
                <p className="label">
                  Experienced Faculty & Authentic Study Material for Government Exams.
                </p>
                <p>
                  Undoubtedly, Government exams are tough to crack, especially when everyone runs to grab a Government Job. Every candidate who is working in the private sector looks forward to giving a try for government exams. To let everyone succeed at the same pace, the online learning methodology has been in demand these days.
                </p>
                <p>
                  At {pageName[1]}, we begin with the first step of preparation i.e. guidance about the exam to the last stage of preparation i.e. revision for the exam, our faculty covers each step that is required to excel in the competitive examinations. Our plans include study materials, books, notes, video classes, doubt sessions, test series, mock tests, and much more.
                </p>
              </div>
              <div className="content-block">
                <p className="label">Making Education Interesting For School Students</p>
                <p>
                  The idea of beginning with the school section came up when the government made online learning compulsory for schools. Around 90% of parents are worried about whether their child would learn everything through this online learning”. No worries, our esteemed faculty at {pageName[1]} left no stone unturned in making the concept clear to the students in the most fun and interesting manner. As students prefer mobile for games, cartoons, and movies, and in this scenario, making learning Fun and Engaging For Children is the toughest task for any teacher and our faculty at {pageName[1]} is a PRO in this field.
                </p>
              </div>
              <div className="content-block">
                <p className="label">Why Choose {pageName[1]}?</p>
                <p>
                  The first question that clicks to anyone’s mind while choosing any study material or online class is “Why go with {pageName[1]} only?”.
                </p>
                <p className="choose-adda">
                  1. <span>1 Lakh+ Selection:</span> {pageName[1]} is proud to have helped lakhs of students to grab their dream job. Till today, over 1 lakh candidates have been selected for government exams with the hard work and enthusiasm of our faculty.
                  <br />
                  2. <span>Fruitful Live Sessions:</span> Study becomes more effective when students with different intellectual skills share their ideas in our live classes. Teachers interact with students, clear their doubts, and make them understand the concept in an effective manner.
                  <br />
                  3. <span>Top-notch Faculty:</span> The most crucial factor in the success of {pageName[1]} is our experienced faculty who keep themselves updated with the trend in education. They keep themselves engaged with their students so that they could clear every little doubt of the students in terms of their education.
                  <br />
                  4. <span>Authentic Study Material:</span> Our expert faculty prepares best quality study materials with the latest exam pattern and concepts are cleared in a way which is easy to understand and quick to learn. While preparing the study materials, they do keep themselves updated with the ongoing trends of competitive exams.
                  <br />
                  5. <span>Mock Tests & Test Series:</span> {pageName[1]} not only focuses on clearing the concept but we do prepare our students to ensure that they perform their best in the competitive exams. For this, our teachers prepare mock tests and test series as per the latest pattern so that the students become familiar with the real exam.
                </p>
              </div>
            </div>
             :
             <div className="container homepage-footer">
              <div>
                <p>
              In this era of competitiveness, each student is looking for the best guidance for these exams like JEE/NEET. India is a land that has churned out engineers and doctors for years. Indian education system offers many major educational institutes and colleges providing state-of-the-art infrastructure and placement opportunities.
              </p> 
              <p className="p-label">
              E-learning has made it possible for students to learn from the comfort of their homes. With the same motive, {pageName[1]} is providing a comfortable learning environment for the aspirants who have been preparing for JEE & NEET at an affordable cost price. Started with responsibility and vision in mind to provide the best education to the students and help them achieve their dream college/institution.  The highly experienced faculty members make this Ed-tech company an instant hit among the students. {pageName[1]} believes in learning which is most relevant/dynamic and exam oriented based on the latest patterns.
              </p>
              </div>
              <div className="content-block">
                <p className="label">{pageName[1]} is an initiative which is conceptualized and operationalized by India’s most experienced faculties which have produced numerous AIRs in few past years.</p>
                <p>
                {pageName[1]} faculties focus on comprehensive and rigorous learning which includes live interactive lectures, faculty-made practice papers, live doubt feature, DPPs, mock exams, PYQs, and detailed study material in line with exam patterns. 
                </p>
              </div>
              <div className="content-block">
                <p className="label">What does {pageName[1]} provide?</p>
                <p>{pageName[1]} offers E-learning programs for JEE/NEET courses. Our Study Material includes Mock Tests, Live Classes, Videos Course, Ebooks, Books/study Materials and DPPs and all at an affordable price with the best quality content.</p>
              </div>
              <div className="content-block">
                <p className="label">Experienced Faculty & Authentic Study Material for JEE & NEET Exams.</p>
                <p>Undoubtedly, JEE & NEET entrance exams are tough to crack, especially due to increasing competition due high number of test takers. To let everyone succeed at the same pace, the online learning methodology has been in demand these days. The faculties at {pageName[1]} are highly qualified and carries a vast experience in student mentoring and creating content that has enabled students to reach colleges like IITs/NITs/IITs/AIIMS/CMC etc</p>
                <p>At {pageName[1]}, we begin with the first step of preparation i.e. guidance about the exam to the last stage of preparation i.e. revision for the exam, our faculty covers each step that is required to excel in the competitive examinations. Our plans include study materials, books, notes, video classes, doubt sessions, test series, mock tests, and much more.</p>
              </div>
              <div className="content-block">
                <p className="label">Why Choose {pageName[1]}?</p>
                <p>The first question that clicks to anyone’s mind while choosing any study material or online class is “Why go with {pageName[1]} only?” What makes {pageName[1]} a trusted platform for students? </p>
                <p className="choose-sankalp">
                  1. <span>Experienced Faculty:</span> One doubt and concern that the students and their parents have is whether an online platform can provide all the important learning to them to clear the paper like JEE/NEET. The faculty at {pageName[1]} leaves no stone unturned to provide the best study material, and calculations, and clear the concepts in a fun and interesting way. The most crucial factor in the success of {pageName[1]} is our experienced faculty who keep themselves updated with the trend in education. They keep themselves engaged with their students so that they could clear every little doubt of the students in terms of their education.
                  <br />
                  2. <span>Fruitful Live Sessions:</span> Study becomes more effective when students with different intellectual skills share their ideas in our live classes. Teachers interact with students, clear their doubts, and make them understand the concept in an effective manner. One more problem a student faces is the doubt clearing and the faculty interacts with the students and clears doubts of the students. The live classes are conducted where the material is provided in the best interactive way possible and also relevant examples are discussed to make the concept easier and clear to the students.
                  <br />
                  3. <span>Authentic Study Material:</span> Our expert faculty prepare the best quality study materials with the latest exam pattern and concepts are cleared in a way that is easy to understand and quick to learn. While preparing the study materials, they do keep themselves updated with the ongoing trends of competitive exams. The comprehensive study material is prepared after a lot of research and is based on the latest pattern released by NTA for JEE/NEET
                  <br />
                  4. <span>Mock Tests & Test Series:</span> {pageName[1]} not only focuses on clearing the concept but also provides ample practice of the concepts and questions in the form of daily quizzes, sectional tests, DPPs, and Mock Tests that will prepare our students to perform their best in the competitive exams. Our teachers prepare mock tests and test series as per the latest pattern so that the students become familiar with the real exam. Also, it is not only to provide the mock tests but a proper emphasis is laid on the analysis of these mocks so that the students are able to identify the weak areas and work on to achieve the best score in their respective exam.
                </p>
              </div>
            </div>}
          </div>
        </footer>
      </>
    );
  }
}

export default FooterText;

import React from "react";

const ExamMenuShimmer = ({ loading = true }) => {
  return (
    <div className="select-exam-body ">
      <div className={`exam-menu-wrap loading ${loading ? "loading" : ""}`}>
        {[1, 2, 3, 4, 5, 6, 7, 8]?.map((el, i) => (
          <div className="examm-menu-list" key={i}>
             <div className="examOuter">
            <div className="exam-for-inner">
              <ul>
                <li></li>
                
              </ul>
              <div class="mobile-drop-drown"></div>
            </div>

             </div>
          </div>
        ))}
      </div>
      ​
      <div className={`filtered-exam-list`}>
      <div className={`filtered-exam-wraper   loading ${loading ? "loading" : ""}`}>
        {[1, 2, 3, 4, 5, 6, 7, 8]?.map((el, i) => (
          <div className="exam-name-box" key={i}>
            <a href="#">
              <div className="exam-img" width="48px" height="48px"></div>
              <div className="exam-info">
                <div className="exam-name"></div>
              </div>
            </a>
          </div>
        ))}
        ​
      </div>
      </div>
    </div>
  );
};

export default ExamMenuShimmer;

import React from 'react';
import Helmet from 'react-helmet';
import { prodUrl, pageName } from "../../../constants/appConfig";
import { isADDA } from '../../data/utils/helpers';

const CustomHelmet = ({ title, description, image, url,keywords ,noFollow,noIndex }) => {

    return (
        <Helmet>
            <meta property="viewport" content={`width=device-width, initial-scale=1`} />
            <title>{title}</title>
            <link rel="icon" type="image/x-icon" href={`${image || isADDA ? `https://www.adda247.com/static/images/favicon.ico` : `https://www.sankalpbharat.com/images/ic-header-logo.svg`}`}/>
            <meta name="description" content={`${description}`} />
            <meta name="robots" content={`${noFollow} , ${noIndex}`}/>
            {/* <meta property="keywords" content={`${description}`}/> */}
            <meta property="og:title" content={`${title}`} />
            <meta property="og:description" content={`${description}`} />
            <meta property="og:site_name" content={pageName[1]} />
            <meta content="website" property="og:type" />
            <meta property="og:url" content={`${prodUrl}${url || ""}`} />
            <meta property="og:keywords"   content={keywords}></meta>
            
            <meta property="og:image" content={`${image || isADDA ? `https://www.adda247.com/static/images/favicon.ico` : `https://www.sankalpbharat.com/images/ic-header-logo.svg`}`} />
            <meta property="theme-color" content={`#ff0037`} />

            {url && <link rel="canonical" href={`${prodUrl}${url}`} />}
        </Helmet>
    );
};

export default CustomHelmet;
import React from "react";
import { Link } from "react-router-dom";

const QuestionBox = ({data}) => {
  const renderOptions = (opt) => {
    let x = "";
    opt?.forEach((o) => {
      x += `${String.fromCharCode(65 + o.id)}. ${o.val} `;
    });
    return x;
  };

  return (
    <div className="subjectQnsBox">
      <div className="subjectQns">
        <div className="sujectQnsName">Question</div>
        <div className="subjectQnsText">
          <p dangerouslySetInnerHTML={{ __html: data?.question }} /> <br/>
          {[1, 3].includes(data?.questionType) && (
            <p
              dangerouslySetInnerHTML={{ __html: renderOptions(data?.options) }}
            />
          )}
        </div>
      </div>
      <Link to={{pathname: data?.url}}>
        <div className="subjectQnSolution subSolveActive">Solution</div>
      </Link>
    </div>
  );
};

export default QuestionBox;

import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { secondToHMS, microsecondsToHms, pick } from "../../../utils";
const TestSectionResults = ({ testResult, freeContentFlag }) => {
  const [questionDistribution, setQuestionDistribution] = useState([]);
  const [timeDistribution, setTimeDistribution] = useState([]);
  useEffect(() => {
    if (testResult) {
      let questionDistributionData = [
        ["Section", "Correct", " Incorrect", "Unattempted"],
      ];
      let timeDistributionData = [["Section", "time"]];
      testResult.sections.forEach((section) => {
        // exclude onlyDtbSections,because we don't show analysis for sections with only dtb.
        if(section?.dtbQuesSecCount !== section?.totalQuestions){
        questionDistributionData.push([
          section.secDN,
          section.correctAnswerCount,
          section.incorrectAnswerCount,
          (section.totalQuestions - (section?.dtbQuesSecCount || 0)) -
            (section.correctAnswerCount + section.incorrectAnswerCount),
        ]);
        timeDistributionData.push([
          `${section.secDN} (Time Spent - ${formattedMicrosecondsToHms(
            section.timeSpent
          )})`,
          Math.floor(section.timeSpent / 1000),
        ]);
      }
      });
      setQuestionDistribution(questionDistributionData);
      setTimeDistribution(timeDistributionData);
    }
  }, [testResult]);
  const formattedMicrosecondsToHms = (d) => {
    return microsecondsToHms(d, ["h ", "m ", "s "]);
  };
  if (!testResult) return null;
  return (
    <>
      <div className="tr-overview">
        {testResult?.overall?.dtbQuestotalCount > 0 && (
          <p className="tr-sub-info">
            <img
              src={"/images/test-solution/info-icon.svg"}
              alt="tick-blue.svg"
            />
            Subjective questions({testResult?.overall?.dtbQuestotalCount}) are
            not included for analysis
          </p>
        )}
        <div className="tr-overview-row">
          <div className="tr-overview-card">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Sections</th>
                  <th>You Scored</th>
                  {!freeContentFlag && <th> Section Rank</th>}

                  <th>Accuracy</th>
                  <th>Time Spent</th>
                </tr>
              </thead>
              <tbody>
                {testResult?.sections?.map((sectionData) => {
                  // exclude onlyDtbSections,because we don't show analysis for sections with only dtb.
                  if(sectionData?.dtbQuesSecCount !== sectionData?.totalQuestions){
                  return (
                    <tr className="test-section-result">
                      <td>
                        <div>{sectionData.secDN}</div>
                      </td>
                      <td>
                        <div>
                          <span className="section-marks">
                            {sectionData?.marks?.toFixed(2)}
                          </span>
                          <span style={{ fontWeight: 300 }}>
                            {" "}
                            /{" "}
                            {sectionData?.totalMarks -
                              sectionData?.dtbQuesSecMarks || 0}
                          </span>
                        </div>
                      </td>
                      {!freeContentFlag && (
                        <td>
                          <div>
                            <span className="section-marks">
                              {sectionData?.rank}
                            </span>
                            <span style={{ fontWeight: 300 }}>
                              {" "}
                              / {testResult?.overall?.totalCandidates}
                            </span>
                          </div>
                        </td>
                      )}
                      <td>
                        <div>
                          <span>{sectionData?.accuracy?.toFixed(2)}%</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="section-marks">
                            {formattedMicrosecondsToHms(sectionData?.timeSpent)}
                          </span>
                          {sectionData?.totalTime ? (
                            <span style={{ fontWeight: 300 }}>
                              {" "}
                              /{" "}
                              {formattedMicrosecondsToHms(
                                sectionData?.totalTime * 1000
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* Stacked Bar Chart */}
        <div className="tr-overview-row">
          <div className="tr-overview-card">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>
                    <div className="trc-header">
                      <span>Question Distribution</span>
                      <span>Correct • Incorrect • Unattempted</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={questionDistribution}
                    options={{
                      fontSize: "13",
                      isStacked: "true",
                      fill: "10",
                      displayExactValues: "true",
                      bar: {
                        groupWidth: "80",
                      },

                      vAxis: {
                        title: "Number of Questions",
                        gridlines: {
                          count: "6",
                        },
                        textStyle: {
                          fontSize: "12",
                          color: "#515151",
                        },
                      },
                      selectionMode: "multiple",
                      tooltip: {
                        text: "value",
                        showColorCode: true,
                        shared: true,
                      },
                      legend: {
                        position: "top",
                        maxLines: "4",
                        textStyle: {
                          fontSize: "12",
                          color: "#8f8e94",
                        },
                      },

                      hAxis: {
                        textStyle: {
                          fontSize: "12",
                          color: "#515151",
                          paddingRight: "100",
                          marginRight: "100",
                        },
                      },
                      colors: ["#12b55e", "#e6175c", "#e2e2e2"],
                    }}
                    // For tests
                    // rootProps={{ "data-testid": "2" }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Time Distribution*/}
        {testResult.sections.length > 1 && (
          <div className="tr-overview-row">
            <div className="tr-overview-card">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <div className="trc-header">
                        <span>Time Distribution (in mins)</span>
                        <span>Time Spent across sections</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Chart
                      width={"100%"}
                      height={"300px"}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={timeDistribution}
                      options={{
                        displayExactValues: true,
                        sliceVisibilityThreshold: 0,
                        tooltip: {
                          text: "percentage",
                          showColorCode: true,
                        },

                        format: "$#",
                        fontStyle: "bolder",
                        fontSize: "12",
                        legend: {
                          position: "right",
                          maxLines: "7",
                          alignment: "center",
                          fontStyle: "bold",
                          width: "600",
                          textStyle: {
                            fontSize: "12",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontStyle: "bold",
                          },
                        },
                        pieSliceTextStyle: {
                          color: "#fff",
                          fontStyle: "bold",
                        },
                        pieSliceText: "percentage",

                        chartArea: { left: "10", top: "15%" },
                        slices: {
                          0: { color: "#f6511D" },
                          1: { color: "#ff9a18" },
                          2: { color: "#03a9f4" },
                          3: { color: "#5E239D" },
                          4: { color: "#ff4081" },
                          5: { color: "#00f0b5" },
                        },
                      }}
                      // For tests
                      // rootProps={{ "data-testid": "2" }}
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestSectionResults;

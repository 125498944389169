import React from 'react';
import { createOptimizedImgUrl } from "../../../data/utils/helpers"
const BuyOnline = ({buttonText,linkUrl})=>{
    return (
        
          <div className='video-popup myebookpopup' >

            <div style={{ backgroundColor: 'rgb(255, 229, 207)', textAlign: 'center' }}>
              <img src= {createOptimizedImgUrl("https://s3.ap-south-1.amazonaws.com/storefront-assets/Expiry.png", 200)}
                style={{ width: '200px' }} />
            </div>
            <div >
              <div style={{ margin: "20px 0", fontSize: "20px", fontWeight: "600", lineHeight: "1.2", textAlign: "center", color: "#090023" }}>Package Expired!</div>
              <div style={{ margin: "0 24px 44px", opacity: "0.8", fontSize: "14px", lineHeight: "1.43", textAlign: "center", color: "#444545" }}>

                <span>This product is not available anymore. You can still view similar products in this category.</span>
              </div>
              {
                <div style={{ textAlign: "center" }} ><a style={{ width: "200px", padding: '5px', height: "44px", backgroundColor: "#f57f18", fontSize: "14px", fontWeight: "600", color: "#ffffff" }} className="sf-button-secondary" href={linkUrl}>{buttonText}</a>
                </div>
              }
            </div>
          </div>
    )
}

export default BuyOnline;
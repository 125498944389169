import React, { useEffect } from 'react';

const CustomTab = ({ tabItemList, onSelect, activeId, chevron }) => {
    useEffect(() => {
        let elem = document.querySelector("#tabListcont button.active");
        if (elem && elem.offsetLeft && elem.offsetLeft > 0) {
            document.querySelector("#tabListcont").scrollLeft = elem.offsetLeft;
        }
    }, []);
    return (
        <div className='tab' id="tabListcont">
            {tabItemList.map((item, ind) =>
                <div key={`ind__${ind}`} className={activeId === item.id ? 'active tab-list-parent width-100' : 'tab-list-parent width-100'} onClick={() => onSelect(item.id)}>
                    <div  > {item.label}
                    </div>
                    {chevron && chevron ?
                        <div className='icon-chevron-right tab-icon display-chevron'>
                        </div>
                        : null
                    }
                </div>)}

            {/* <button className='active'> Live Classes</button>
         <button>Mock Test</button>
         <button> Video Courses</button> */}
        </div>
    )
}
export default CustomTab;
import React, { useEffect } from "react";
import { pageName } from "../../../constants/appConfig";

const CustomModal = props => {
  let targetEvent = null;
  useEffect(() => {
    try {
      targetEvent = window.addEventListener("keydown", (event) => {
        if (event.keyCode == 27) {
          closeModal();
        }
      });
      return () => {
        window.removeEventListener("keydown", targetEvent);
        document.body.classList.remove("noscroll");
      }
    } catch (ex) { }
  }, []);

  useEffect(() => {
    if (props.visible) {
      // document.body.classList.add("noscroll");
    }
  }, [props.visible]);

  const closeModal = () => {
    document.body.classList.remove("noscroll");
    if (props.closeCallback) {
      props.closeCallback();
    }
    if (localStorage.getItem("urlAfterLogin")) {
      localStorage.removeItem("urlAfterLogin");
    }
    if (localStorage.getItem("urlForTestPortal")) {
      localStorage.removeItem("urlForTestPortal");
    }
    if (localStorage.getItem("urlForFreeQuizzes")) {
      localStorage.removeItem("urlForFreeQuizzes");
    }
  }
  const onCancel = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
  }
  const onBack = () => {
    document.body.classList.remove("noscroll");
    if (props.onBack) {
      props.onBack();
    }
  }


  return props.visible ? (
    <div
      data-testId={props.dataTestid}
      id={"custom-modal"}
      aria-label="Close"
      className={props.fullModalClassName ? props.fullModalClassName : `custom-modal ${props.modalClassName}`}
      onClick={event => {
        if (event.target.id === "custom-modal") {
          onCancel();
        }
      }}
    >
      <div className={props.contentClass ? props.contentClass : "custom-modal-content"}>
        {!props.hideHeader && <div className={props?.customModalHeaderClass ? props?.customModalHeaderClass : "custom-modal-header"}>
          {props.onBack && !props.hideBackBtn && (
            <div className={props.goBackIcon ? props.goBackIcon : "backIcon back"} onClick={onBack}></div>
          )}
          <div className={props.titleLabelClass ? props.titleLabelClass : ""}>{props.title}</div>
          {!props.hideCross && (
            <div>
              <img
                onClick={closeModal}
                src={props.fromapp ? "/images/back-arrow.svg" : "/images/close-icon.svg"}
                className={props.title && props.fromapp ? "modal-back-arrows" : props.title && !props.fromapp ? "modal-close " : "modal-close-no-title"}
                alt={"close"}
                title={pageName[0]}
              />
            </div>
          )}
        </div>
        }
        <div data-testid="custom-modal-body" className={props?.customBodyClass ? props?.customBodyClass : "custom-modal-body"} style={props.bodyStyle}>
          {props.children}
        </div>
        {props.modalFooter && <div className={"custom-modal-footer"}></div>}
      </div>
    </div >
  ) : (
    <div data-testId={props.dataTestid} style={{ display: "none" }}></div>
  );
};

export default CustomModal;

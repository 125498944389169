import React, { useState } from "react";
import { useParams } from "react-router";
import ExamInsStickyFooter from "./ExamInsStickyFooter";
import ExamInstructionTextContent from "./ExamInstructionTextContent";
import { useSelector } from 'react-redux';

const ExamInstruction = ({
  acceptTnC = "",
  setAcceptTnC = "",
  data = {},
  isViewInstructionPopup = false, // flag that is used to identify this component usage in Instruction page or view Ins popup
  language = "",
  changeLanguage = () => null,
  handleStartTestCb = () => null,
  mode = "",
  instructionRemainingTime = "",
  showIndicator=false,
  indicatorMessage="",
  disableTnC=false,
  getMoengagePayload = () => {},
}) => {
  const [instructionPageNo, setInstructionPageNo] = useState(1);
  const { title } = useParams();
  const onInsChange = (page = 1) => {
    setInstructionPageNo(page);
  };

  const userName = useSelector(state => state?.header?.userInfo?.name)

  return (
    <div className="exam-ins">
      {/* exam header */}
      {!isViewInstructionPopup && (
        <div className="ins-header">
          <img
            src="/images/header-logo.svg"
            className="logo-img"
            alt={`logo`}
            title={"adda247"}
            width="172px"
            height="40px"
          />
          <span>{decodeURIComponent(title)}</span>
        </div>
      )}
      <div className="main-content-wrap">
        {/* instruction page */}
        <div className="ins-wrap">
          <div className="titlepath">
            {instructionPageNo === 1 && "Instructions"}
            {instructionPageNo === 2 && "Other Important Instructions"}
            {mode === "LT" ? <div className="liveTestTimer">
              <span className="instruction-clock-label">Test Starts in: </span> 
              <span className={`instruction-clock-time ${instructionRemainingTime === "00:00:00" ? "time-up" : ""}`}>{instructionRemainingTime}</span>
            </div> : ""}
          </div>
          <ExamInstructionTextContent
            instructionPageNo={instructionPageNo}
            data={data}
          />

          <ExamInsStickyFooter
            instructionPageNo={instructionPageNo}
            onInsChange={onInsChange}
            language={language}
            acceptTnC={acceptTnC}
            setAcceptTnC={setAcceptTnC}
            data={data}
            handleStartTestCb={handleStartTestCb}
            changeLanguage={changeLanguage}
            isViewInstructionPopup={isViewInstructionPopup}
            mode={mode || ""}
            showIndicator={showIndicator || false}
            indicatorMessage={indicatorMessage || ""}
            disableTnC={disableTnC || false}
            getMoengagePayload={getMoengagePayload}
          />
        </div>
        {/* side profile  */}
        {!isViewInstructionPopup && (
          <div className="ins-sideProfile">
            <div className="imageSideProgile">
              <div className="usepic"><img
                src="/images/examtestusericon.png"
                className="logo-img"
                alt={`logo`}
              />
              </div>
              <div className="usernameExam">{userName}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamInstruction;

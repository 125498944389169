import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty, getMoengageMetadata, capitalizedString, parseCourseName, getDeviceType, isADDA } from '../../data/utils/helpers';
import FilterByExamForMobile from './FilterByExamForMobile';
import CustomSelect from '../library/CustomSelect';
// import {Link} from 'react-router-dom';
import { default as Link } from '../../containers/ConnectedLink';
import { pageName } from '../../../constants/appConfig';
import { productKeys } from '../../../constants/appConstants';
import { useSelector } from 'react-redux';

const FilterTopicHeading = (props) => {

    const [hideSortByFilter, setHideSortByFilter] = useState(false);
    const [dpnHeader, setDPNHeader] = useState("");

    const globalExamList = useSelector(state => state.header.globalExamList);
    const memoizedGlobalExamList = useMemo(() => globalExamList, [globalExamList]);

    useEffect(() => {
        if(props?.hideSortByFilter && getDeviceType() !== 'd') setHideSortByFilter(props?.hideSortByFilter);
        else setHideSortByFilter(false);
        getDPN();
    },[]);


    function handleChange(e) {
        props.handleSortBy(e.target.value);
        let payload = { source: "web", brand: pageName[1] };
        payload.action = 'sortOptions_clicked',
            payload.getUtmFromStore = props.getUtmFromStore;

        payload.option = (e.target.value === "ASC") ? 'Price applied' : e.target.value + "Applied";
        if (typeof (Moengage) !== 'undefined') Moengage.track_event("sort", getMoengageMetadata(payload));
    }
    const course = typeof sessionStorage !== "undefined" ? sessionStorage.getItem("ExamCategoryMappingId")?.toUpperCase(): "";

    const getDPN = () => {

        const url = window.location.pathname;
        let endpoint = url.split("/");
        let path =  endpoint?.[endpoint?.length - 1];
        const pageTitle = extractStringsFromUrl(path);
        let dpn = matchIdWithExtractedString(pageTitle, memoizedGlobalExamList);
        if (dpn) setDPNHeader(dpn);
    }

    const extractStringsFromUrl = (url) => {

        let result = "";

        if (url.includes("-study-materials")) {
          const parts = url.split("-study-materials");
          result = parts[0];
        }
      
        if (url.includes("-exam-kit")) {
          const parts = url.split("-exam-kit");
          result = parts[0];
        }
        return result;
      }

      const matchIdWithExtractedString = (extractedString, globalExamList) => {
        const formattedString = extractedString
          .split('-')
          .map(part => part.toUpperCase())
          .join('_');
      
        let matchedDpn = '';
      
        globalExamList.forEach(category => {
          if (category.id === formattedString) {
            matchedDpn = category.dpn;
          } else if (category.examList) {
            const examMatch = category.examList.find(exam => exam.id === formattedString);
            if (examMatch) {
              matchedDpn = examMatch.dpn;
            }
          }
        });
      
        return matchedDpn?.toUpperCase();
      };
      

    return (
        <>
        {
            (props.useTitle || props.title  || props.course)  && <div className={'filter-heading-container'}>
            <h2 className={'filter-heading'} style={props.sortBy ? { 'paddingLeft': '0' } : {}}>
                {
                    props.useTitle || `${(dpnHeader || props.title && props.title.toUpperCase() || props.course && props.course.toUpperCase() || "")} ${props.topicHeading == "Publications" ? "Books" : props.topicHeading == "3D Models" ? '3D Learning' : props.topicHeading} (${props.count ? props.count : 0})`
                }
            </h2>
        </div>
        }
        
            {props.viewAll && (props.count > (isADDA ? 4 : 5)) &&
                <Link className="topHeaderRightLink"
                    to={`/${props.examName.toLowerCase() || ""}/${productKeys[props.categoryName]}-${props.courseDetail ? 'study-kit' : 'kit'}${course  && !props.courseDetail ? "?coursesFacets=" + course: ""}`}>
                    <div className={'view-all'} onClick={props.clicked}>
                        <span style={{ 'paddingRight': '5px', 'verticalAlign': 'middle' }}>View All</span>
                        <img style={{ 'verticalAlign': 'unset' }} src={'/images/arrow-point-to-right-original.svg'}
                            alt={pageName[0]}
                            title={pageName[0]} />
                    </div>
                </Link>
            }
            {props.sortBy &&
                <div className={`sort-by slideLeft20 ${hideSortByFilter ? 'hide-sort-by' : ''}`}>
                    <span className={'sort-by-text'}>Sort by </span>
                    <CustomSelect defaultValue={props.sortByValue} className={'all-selector'}
                        filter={[{ 'key': 'Relevance', 'value': 'Relevance' }, {
                            'key': 'ASC',
                            'value': 'Price'
                        }]}
                        onChange={handleChange}>
                        {/*<opt value="RELEVANCE">Relevance</opt>*/}
                        {/*<Option value="ASC">Price: Low to High</Option>*/}
                    </CustomSelect>
                </div>
            }
        </>
    );
};


export default FilterTopicHeading;
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { pageName } from "../../../constants/appConfig";
import {
  categoryAdditionalMap,
  categoryCountPriority,
  categoryIconMappings,
  categoryPriority,
} from "../../../constants/appConstants";
import {
  createOptimizedImgUrl,
  findMaxTagAssignedAtObject,
  formatCount,
} from "../../data/utils/helpers";
import "../../data/ducks/toast/reducers";
import ToastContainer from "../Toast/ToastContainer";
import BusinessTag from "../library/BusinessTag";
import WishListToggler from "../../containers/WishList/WishListToggler";
import ErrorBoundary from "../ErrorBoundary";
import { BLANK_IDENTIFIER, WEB_IDENTIFIER } from "../MoengageConstants";

/**
 * Represents a product detail card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.packageId - The packageId of the card.
 * @param {string} props.cardTitle - The title of the card.
 * @param {string} [props.src] - The image source URL.
 * @param {Object} [props.contentCount] - The count of content for different categories.
 * @param {boolean} [props.mahaPack=false] - Indicates if it's a mahaPack.
 * @param {string} [props.categories=""] - The categories for the product.
 * @param {Array} [props.languages] - The languages supported.
 * @param {Object} [props.newTags] - New tags associated with the product.
 * @param {boolean} [props.freeContentAvailable] - Indicates if free content is available.
 * @param {Object} [props.couponDetails] - Details about available coupons.
 * @param {Array} [props.timeArray] - An array of time-related data.
 * @param {boolean} [props.expiredCouponFlag] - Indicates if the coupon has expired.
 * @param {number} [props.defaultCouponAbsoluteDiscount=0] - The default coupon discount.
 * @param {boolean} [props.notExpiredDefaultCouponAvailable=false] - Indicates if a non-expired default coupon is available.
 * @param {number} [props.sellingPrice=0] - The selling price of the product.
 * @param {string} [props.tag=null] - A tag associated with the product.
 * @param {boolean} [props.dualValidity=false] - Indicates dual validity.
 * @param {string} [props.pathName=""] - The pathname for the Link.
 * @param {string} [props.source_screen=""] - The source screen information.
 * @param {boolean} [props.published=true] - Indicates if the product is published.
 * @param {boolean} [props.isWishList=false] - Flag that represents whether the product is in the wishlist.
 * @param {boolean} [props.confirmRemoveWishList=false] - Flag that show popup before removing wishlist.
 * @param {boolean} [props.isChecked=false] - Flag that represents whether the product is selected.
 * @param {boolean} [props.isBuyNowShow=false] - Flag that shows the Buy Now CTA.
 * @param {boolean} [props.wishlistOperationAllowed=true] - Flag that allowed wishlist operation perform.
 * @param {function} [props.onClickCb] - The callback function to be called on click.
 * @param {function} [props.onChecked] - The callback function to be called when the checkbox is checked.
 * @returns {JSX.Element} The rendered component.
 */

const AddaProductDetailCard = (props) => {
  const {
    packageInfoData,
    packageId,
    cardTitle,
    src = "/images/produtImg.jpg",
    contentCount = {},
    mahaPack = false,
    categories = "",
    languages,
    newTags,
    freeContentAvailable,
    couponDetails,
    timeArray,
    expiredCouponFlag,
    defaultCouponAbsoluteDiscount = 0,
    notExpiredDefaultCouponAvailable = false,
    sellingPrice = 0,
    tag = null,
    dualValidity = false,
    pathName = "",
    source_screen = "",
    published = true,
    isWishList = false,
    confirmRemoveWishList = false,
    isChecked = false,
    isBuyNowShow = false,
    isSelectableShow = false,
    wishlistOperationAllowed = true,
    onClickCb = () => null,
    onCheckedCb = () => null,
  } = props;
  const [isBusinessTagVisible, setIsBusinessTagVisible] = useState(false);
  const language = useMemo(() => {
    return languages?.find((lg) => lg?.primary)?.name || languages?.[0]?.name;
  }, [languages]);
  const discountedPercentage = useMemo(() => {
    return Math.round(
      100 -
        ((sellingPrice - defaultCouponAbsoluteDiscount) / sellingPrice) * 100
    );
  }, [sellingPrice, defaultCouponAbsoluteDiscount]);

  const isDiscountAvailaible = useMemo(() => {
    return Boolean(
      couponDetails &&
        timeArray &&
        expiredCouponFlag &&
        defaultCouponAbsoluteDiscount &&
        notExpiredDefaultCouponAvailable
    );
  }, [
    couponDetails,
    timeArray,
    expiredCouponFlag,
    defaultCouponAbsoluteDiscount,
    notExpiredDefaultCouponAvailable,
  ]);

  const mainPrice = useMemo(() => {
    return isDiscountAvailaible
      ? Math.round((sellingPrice - defaultCouponAbsoluteDiscount) * 100) / 100
      : sellingPrice;
  }, [sellingPrice, defaultCouponAbsoluteDiscount, isDiscountAvailaible]);

  const recentlyAssignedTag = useMemo(() => {
    return findMaxTagAssignedAtObject(newTags?.businessTags || []);
  }, [newTags]);

  useEffect(() => {
    if (recentlyAssignedTag) {
      setIsBusinessTagVisible(true);
    }
  }, [recentlyAssignedTag]);
  const additionalTag = useMemo(() => {
    if (newTags?.additionalTags) return newTags?.additionalTags; //if additionalTags exist.
    let tagText = "Live + Recorded";
    const categoryList = categories?.split(",");
    if (mahaPack) {
      //if it is mahaPack
      tagText = "Live + Recorded";
    } else {
      // Loop through the priority categories and set tagText if found
      for (const category of categoryPriority) {
        if (categoryList.includes(category)) {
          tagText = categoryAdditionalMap[category];
          break; // Stop searching once a higher-priority category is found
        }
      }
    }
    return tagText;
  }, [newTags, categories, mahaPack]);
  const contentIncludedCount = useMemo(() => {
    const content = [];
    const olcCountandLabel = {
      count: contentCount["olcDuration"] || contentCount["olcCount"],
      label: `${contentCount["olcDuration"] ? "Hrs " : ""}${
        categoryIconMappings["olcCount"]?.label
      }`,
    };
    const categories = Object.keys(contentCount || {});
    categoryCountPriority?.forEach((cat) => {
      if (categories.includes(cat))
        content.push({
          count:
            cat === "olcCount"
              ? formatCount(olcCountandLabel?.count)
              : formatCount(contentCount[cat] || 0), // add "k" as suffix
          icon: categoryIconMappings[cat]?.icon,
          label:
            cat === "olcCount"
              ? olcCountandLabel?.label
              : categoryIconMappings[cat]?.label,
        });
    });
    return content;
  }, [contentCount]);
  const moengagePayload = useMemo(() => {
    return {
      exam_category: packageInfoData?.courses,
      user_exam_category_selected: WEB_IDENTIFIER,
      exam: packageInfoData?.examTypes?.[0]?.name || BLANK_IDENTIFIER,
      language: language,
      source_screen: source_screen,
      package_status: "Paid",
      package_type: packageInfoData?.suggestionCat || BLANK_IDENTIFIER,
      package_title: cardTitle,
      package_id: packageId,
      package_name: cardTitle,
      package_actual_price: packageInfoData?.maxPrice,
      package_discounted_price: packageInfoData?.price,
      package_final_price: mainPrice,
      package_subject: BLANK_IDENTIFIER,
      modified_date: packageInfoData?.updatedAt,
      published_date: packageInfoData?.createdAt,
      package_url:
        typeof window !== "undefined" ? window?.location?.href || "" : "",
      coupon_code: "",
      certification: "",
    };
  }, [packageInfoData, language, mainPrice]);
  return (
    <div
      className={`listingCardBox ${
        isBusinessTagVisible ? "OfferHourLeft" : ""
      } `}
    >
      <Link
        className={`listingCardBoxCard ${!published ? "listcardTagFull" : ""}`}
        to={{
          pathname: pathName,
          state: {
            source_screen: source_screen,
          },
        }}
        onClick={onClickCb}
      >
        <>
          <div className="listCardTopTags">
            {tag ? (
              <div className="ListingTags recomendingTag">{tag}</div>
            ) : (
              dualValidity && (
                <div className="ListingTags dblValdityTag">Double Validity</div>
              )
            )}
            {isSelectableShow && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onCheckedCb(!isChecked, props);
                }}
              >
                {isChecked ? (
                  <img
                    src={"/images/wlcheckative.svg"}
                    alt="check"
                    className="wlcardcheckImg"
                    loading="lazy"
                    height={24}
                    width={24}
                  />
                ) : (
                  <img
                    src={"/images/wlcheckNonative.svg"}
                    alt="uncheck"
                    className="wlcardcheckImg"
                    loading="lazy"
                    height={24}
                    width={24}
                  />
                )}
              </div>
            )}
          </div>
          <div className="listCardBoxImg">
            <img
              src={createOptimizedImgUrl(
                src || "/images/produtImg.jpg",
                250,
                250
              )}
              height="180px"
              width="180px"
              alt={cardTitle || pageName[0]}
            />
          </div>
          <div className="underThumbContent">
            <div className="listingCardsFeatures">
              {language && <div className="listingCardLangTag">{language}</div>}
              <div className="listingCardOptionTag">{additionalTag}</div>
              <ErrorBoundary>
                <WishListToggler
                  packageId={packageId}
                  isWishList={isWishList}
                  wishlistOperationAllowed={wishlistOperationAllowed}
                  confirmRemoveWishList={confirmRemoveWishList}
                  moengagePayload={moengagePayload}
                  source_screen={source_screen}
                />
              </ErrorBoundary>
            </div>
            <div className="titleWithDemo">
              <div className="freedemoavail">
                {freeContentAvailable ? "Free demo available" : ""}
              </div>
              <div className="listingCadTitle">{cardTitle}</div>
            </div>
            <div className="listingListChecks">
              <div className="ListChecksinner">
                {contentIncludedCount?.map((content) => (
                  <span key={content?.label}>
                    <img src={content?.icon} alt={content?.label} />
                    {content?.count} {content?.label}
                  </span>
                ))}
              </div>
            </div>
            <div className="listingCardPrice">
              <div className="listCardMainPrice">₹{mainPrice}</div>
              {isDiscountAvailaible && (
                <>
                  <div className="listCardCutPrice">₹{sellingPrice}</div>
                  <div className="listCardTotalOff">
                    ({discountedPercentage}% off)
                  </div>
                </>
              )}
              {!isDiscountAvailaible && (
                <div className="listCardOfferAvail">
                  <img src="/images/pdpdiscountNew.svg" alt="offers" />
                  Offers Available
                </div>
              )}
            </div>
          </div>
          {isBuyNowShow && <button className="buy-now">Buy Now</button>}
          {isBusinessTagVisible && (
            <BusinessTag
              iconSrc={recentlyAssignedTag?.thumbnail}
              tagType={recentlyAssignedTag?.tagType}
              name={recentlyAssignedTag?.name}
              endTime={recentlyAssignedTag?.endTime}
              setIsBusinessTagVisible={setIsBusinessTagVisible}
            />
          )}
        </>
      </Link>
      <ToastContainer />
    </div>
  );
};

// Define PropTypes for the component
AddaProductDetailCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  src: PropTypes.string,
  contentCount: PropTypes.object,
  mahaPack: PropTypes.bool,
  categories: PropTypes.string,
  languages: PropTypes.array,
  newTags: PropTypes.object,
  freeContentAvailable: PropTypes.bool,
  couponDetails: PropTypes.object,
  timeArray: PropTypes.array,
  expiredCouponFlag: PropTypes.bool,
  defaultCouponAbsoluteDiscount: PropTypes.number,
  notExpiredDefaultCouponAvailable: PropTypes.bool,
  sellingPrice: PropTypes.number,
  tag: PropTypes.string,
  dualValidity: PropTypes.bool,
  pathName: PropTypes.string,
  source_screen: PropTypes.string,
  published: PropTypes.bool,
  isWishList: PropTypes.bool,
  confirmRemoveWishList: PropTypes.bool,
  isChecked: PropTypes.bool,
  isBuyNowShow: PropTypes.bool,
  wishlistOperationAllowed: PropTypes.bool,
  onClickCb: PropTypes.func,
};

export default React.memo(AddaProductDetailCard);

import types from './types';
import {specialTestUrl} from '../../../../constants/appConfig';

const getSpecialTests = () => {
    return {
        CALL_API: [
            {
                type:types.GET_SPECIAL_TESTS,
                meta: {
                    path: specialTestUrl,
                    method: 'GET',
                }
            }
        ]
    };
};


export {
    getSpecialTests
}
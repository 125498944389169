const RECEIVE_BLOG_SITES = 'RECEIVE_BLOG_SITES';
const RECEIVE_ADDA_STATS = 'RECEIVE_ADDA_STATS';
const RECEIVE_MEDIA_COVERAGE = 'RECEIVE_MEDIA_COVERAGE';
const FETCH_HOME_RECOMMENDATION = 'FETCH_HOME_RECOMMENDATION';
const FETCH_USER_APPRECIATION = 'FETCH_USER_APPRECIATION';
const FETCH_HOME_BANNERS = 'FETCH_HOME_BANNERS';
const FETCH_POST_LGGEDIN_USER = 'FETCH_POST_LGGEDIN_USER';
const FEED_DATA_FETCH_SUCCESS_ONSCROLL = 'FEED_DATA_FETCH_SUCCESS_ONSCROLL';
const SET_USER_COURSE = 'SET_USER_COURSE';
const FEED_QUIZ_STATUS_FETCH_SUCCESS = 'FEED_QUIZ_STATUS_FETCH_SUCCESS';
const SET_CURRENT_ACTIVE_QUIZ_SUCCESS = 'SET_CURRENT_ACTIVE_QUIZ_SUCCESS';
const CLEAR_FEED = 'CLEAR_FEED';

export default {
    RECEIVE_BLOG_SITES,
    RECEIVE_ADDA_STATS,
    RECEIVE_MEDIA_COVERAGE,
    FETCH_HOME_RECOMMENDATION,
    FETCH_USER_APPRECIATION,
    FETCH_HOME_BANNERS,
    FETCH_POST_LGGEDIN_USER,
    FEED_DATA_FETCH_SUCCESS_ONSCROLL,
    SET_USER_COURSE,
    FEED_QUIZ_STATUS_FETCH_SUCCESS,
    SET_CURRENT_ACTIVE_QUIZ_SUCCESS,
    CLEAR_FEED
};

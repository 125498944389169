import React, { useEffect, useRef, useState } from "react";
import { pageName } from "../../../constants/appConfig";

function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

function scrollLeft(element, to, duration) {
  var start = element.scrollLeft,
    change = to - start,
    currentTime = 0,
    increment = 20;
  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

const NewCarouselInsta = (props) => {
  const ref = useRef();
  const leftIcon = useRef();
  const rightIcon = useRef();
  const [show, setShow] = useState(0);
  const [leftShow, setLeftShow] = useState(1);
  const [rightShow, setRightShow] = useState(1);
  const [scrollleft, setScrollLeft] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [currentDot, setCurrentDot] = useState(0);
  const [previousDot, setPreviousDot] = useState(0);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [dataLength, setDataLength] = useState(props?.dataLength);
  const [buttonState, setButtonState] = useState(false);
  const [textIndent, setTextIndent] = useState(32);
  const [dotsClassArray, setDotsClassArray] = useState(["active-dot", "", "", "dot-small", "dot-smaller", "dot-offscreen", "dot-offscreen", "dot-offscreen"]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeDotCondition, setActiveDotCondition] = useState(0);

  const getInitialConfig = () => {
    if ( dataLength > props.itemsPerPage && dataLength <= 7) {
      setPaginationNumber(dataLength-props?.lastValue);
      setTextIndent(0);
    }
    else if (dataLength > 7) setPaginationNumber(dataLength - props?.lastValue);
    else setPaginationNumber(0);
  };

  const carouselDots = useRef("");
  const carouselCards = useRef("");



  function slideElements(element, dist, duration) {
    var start = element.scrollLeft;
    var currentTime = 0;
    var increment = 20;
    var duration = duration;
    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, dist, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  const handleNext = () => {
    if (buttonState) return;
    setActiveDotCondition(old => old +1);
    setActiveIndex(old => {if(old != paginationNumber-1) return old+1; else return old})
    setButtonState(true);
    const cardElement = carouselCards.current.firstChild;
    var cardWidth = parseInt(window.getComputedStyle(cardElement).width);
    var cardMargin =
      parseInt(window.getComputedStyle(cardElement).marginRight) +
      parseInt(window.getComputedStyle(cardElement).marginLeft);
    if (paginationNumber < dataLength) {
      if (activeSlide < dataLength - 3 && currentDot < paginationNumber - 1) {
        setActiveSlide(activeSlide + 1);
        slideElements(carouselCards.current, cardWidth + cardMargin, 300);
        if ((activeSlide >= 2 || activeSlide > dataLength - 5) && activeDotCondition == 2 && dataLength > 7) {
          setTextIndent(textIndent - 16);
        }
      }
    } else {
      if (currentDot < paginationNumber - 1) {
        setActiveSlide(activeSlide + 1);
        slideElements(carouselCards.current, cardWidth + cardMargin, 300);
      }
    }

    setTimeout(() => {
      setButtonState(false);
    }, 350);

  };

  const handlePrev = () => {
    if (buttonState) return;
    setActiveDotCondition(old => old-1);
    setActiveIndex(old => {if(old != 0) return old-1; else return old});
    setButtonState(true);
    const cardElement = carouselCards.current.firstChild;
    var cardWidth = parseInt(window.getComputedStyle(cardElement).width);
    var cardMargin =
      parseInt(window.getComputedStyle(cardElement).marginRight) +
      parseInt(window.getComputedStyle(cardElement).marginLeft);
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
      if (activeSlide < dataLength - 5 && dataLength > 7 && activeDotCondition == 0) {
        setTextIndent(textIndent + 16);   
      }
      slideElements(carouselCards.current, (cardWidth + cardMargin) * -1, 300);
    }
    setTimeout(() => {
      setButtonState(false);
    }, 350);
  };

  const changeDotsClassArray = () => {
    switch(activeDotCondition){
      case 0:
        setDotsClassArray(dotsClassArray => {
          const newArray = [...dotsClassArray];
          newArray[activeIndex] = "active-dot";
          newArray[activeIndex+1] = "";
          newArray[activeIndex+2] = "";
          return newArray;
        })
        break;
      case 1:
        setDotsClassArray(dotsClassArray => {
          const newArray = [...dotsClassArray];
          newArray[activeIndex-1] = "";
          newArray[activeIndex] = "active-dot";
          newArray[activeIndex+1] = "";
          return newArray;
        })
        break;
      case 2:
        setDotsClassArray(dotsClassArray => {
          const newArray = [...dotsClassArray];
          newArray[activeIndex-2] = "";
          newArray[activeIndex-1] = "";
          newArray[activeIndex] = "active-dot";
          return newArray;
        });
        break;
      case 3:
        setDotsClassArray(dotsClassArray => {
          const newArray = [...dotsClassArray];

          newArray[activeIndex] = "active-dot";
        if(activeIndex-1 >= 0){
          newArray[activeIndex-1] = "";
        }
        if(activeIndex-2 >= 0){
          newArray[activeIndex-2] = "";
        }
        if(activeIndex-3 >= 0){
          newArray[activeIndex-3] = "dot-small";
        }
        if(activeIndex-4 >= 0){
          newArray[activeIndex-4] = "dot-smaller";
        }
        if(activeIndex-5 >= 0){
          newArray[activeIndex-5] = "dot-offscreen";
        }
        if(activeIndex-6 >= 0){
          newArray[activeIndex-6] = "dot-offscreen";
        }
        if(activeIndex-7 >= 0){
          newArray[activeIndex-7] = "dot-offscreen";
        }

        if(activeIndex + 1 < paginationNumber){
          newArray[activeIndex+1] = "dot-small";
        }
        if(activeIndex + 2 < paginationNumber){
          newArray[activeIndex+2] = "dot-smaller";
        }
        if(activeIndex + 3 < paginationNumber){
          newArray[activeIndex+3] = "dot-offscreen";
        }
        if(activeIndex + 4 < paginationNumber){
          newArray[activeIndex+4] = "dot-offscreen";
        }
        if(activeIndex + 5 < paginationNumber){
          newArray[activeIndex+5] = "dot-offscreen";
        }
        return newArray;
        })
        setActiveDotCondition(2);
        break;
      case -1:
        setDotsClassArray(dotsClassArray => {
          const newArray = [...dotsClassArray];
          newArray[activeIndex] = "active-dot";
        if(activeIndex-1 >= 0){
          newArray[activeIndex-1] = "dot-small";
        }
        if(activeIndex-2 >= 0){
          newArray[activeIndex-2] = "dot-smaller";
        }
        if(activeIndex-3 >= 0){
          newArray[activeIndex-3] = "dot-offscreen";
        }
        if(activeIndex-4 >= 0){
          newArray[activeIndex-4] = "dot-offscreen";
        }
        if(activeIndex-5 >= 0){
          newArray[activeIndex-5] = "dot-offscreen";
        }
        if(activeIndex + 1 < paginationNumber){
          newArray[activeIndex+1] = "";
        }
        if(activeIndex + 2 < paginationNumber){
          newArray[activeIndex+2] = "";
        }
        if(activeIndex + 3 < paginationNumber){
          newArray[activeIndex+3] = "dot-small";
        }
        if(activeIndex + 4 < paginationNumber){
          newArray[activeIndex+4] = "dot-smaller";
        }
        if(activeIndex + 5 < paginationNumber){
          newArray[activeIndex+5] = "dot-offscreen";
        }
        if(activeIndex + 6 < paginationNumber){
          newArray[activeIndex+6] = "dot-offscreen";
        }
        if(activeIndex + 7 < paginationNumber){
          newArray[activeIndex+7] = "dot-offscreen";
        }
        return newArray;
        })
        setActiveDotCondition(0);
        break;
    }
  }

  const dotClassNew = (index) => {
    var cl = "";
        var slide = index - activeSlide;
        if (slide == 0) {
            cl = "active-dot";
        }else {
            cl = "";
        } 
    return cl
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        carouselCards &&
        carouselCards.current &&
        carouselCards.current.firstChild &&
        carouselCards.current.childElementCount *
          carouselCards.current.firstChild.offsetWidth >
          carouselCards.current.offsetWidth &&
        carouselCards.current.offsetParent.offsetWidth
      ) {
        let top = carouselCards.current.offsetHeight / 2 - 20;
        setShow(top);
      }
    }, 400);
  }, [props.children]);

  useEffect(() => {
    setScrollLeft(0);
    scrollLeft(carouselCards.current, 0, 0);
    setLeftShow(1);
    setRightShow(1);
  }, [props.current]);

  useEffect(() => {
    getInitialConfig();
  }, [dataLength]);

  useEffect(() => {
    if (activeSlide == 0) {
      setLeftShow(0);
    } else {
      setLeftShow(1);
    }
    if (activeSlide == paginationNumber - 1) {
      setRightShow(0);
    } else {
      setRightShow(1);
    }
    if(props.dataLength === props.itemsPerPage) {
      setLeftShow(0)
      setRightShow(0)
    }
  }, [activeSlide]);

  useEffect(() => {
    changeDotsClassArray();
  }, [activeIndex, activeDotCondition])

  return (
    <>
      <div id="carousel-insta" style={{ position: "relative" }}>
        {show > 0 && leftShow > 0 && (
          <div
            ref={leftIcon}
            className={"left-arrow-carousel"}
            style={{ top: `${show}px` }}
            onClick={handlePrev}
          >
            <img
              src={"/images/testi-pagi.svg"}
              alt="button-left"
              title={pageName[0]}
              style={{ outlineStyle: "none" }}
            />{" "}
          </div>
        )}
        <div
          ref={carouselCards}
          className={
            props?.classWrapper ? props?.classWrapper : "custom-carousel"
          }
          id={props.id}
        >
          {props.children}
        </div>

        {/* DOTS */}
        {props.id !== "testimonials" && (
          <div className="pagination-wrapper-insta" id={`slider_${props.id}`}>
            <div ref={carouselDots} style={{'textIndent' : `${textIndent}px`}} className="carousel-dots-container-insta">
              {[...Array(paginationNumber)].map((pg, index) => (
                <span
                  className={`dot-${index + 1} small-dot ${dataLength>7 ? (dotsClassArray[index] === undefined ? "dot-offscreen" : dotsClassArray[index]) : dotClassNew(index)}`}
                  key={index}
                ></span>
              ))}
            </div>
          </div>
        )}

        {/* DOTS */}

        {show > 0 && rightShow > 0 && (
          <div
            ref={rightIcon}
            className={"right-arrow-carousel"}
            style={{ top: `${show}px` }}
            onClick={handleNext}
          >
            <img
              src={"/images/testi-pagi.svg"}
              alt="button-right"
              title={pageName[0]}
              style={{ outlineStyle: "none" }}
            />{" "}
          </div>
        )}
      </div>
    </>
  );
};

NewCarouselInsta.defaultProps = {
  itemsPerPage : 4
}

export default NewCarouselInsta;
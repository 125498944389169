import React, { useEffect,useState } from "react";
import BuyOnline from "./BuyOnline";
import RenewPackage from "./RenewPackage";
import CustomModal from "../../library/CustomModal";
const ExpiredCourse = ({ packageInfo, close,productUrlKey,url,buyTitle }) => {
  const [popupType, setPopupType] = useState();

  useEffect(() => {
    let type = "buy-online";
    let isAnyParentPackagePublished = packageInfo.parentInfo? !!packageInfo.parentInfo.find((val)=>val.published) :false

    if(isAnyParentPackagePublished){
      type='renew-package';
    }
    setPopupType(type);
  }, [packageInfo]);
  return (
    <>
      <CustomModal
        modalClassName={"modalZindex"}
        footer={null}
        visible={true}
        title={popupType == "renew-package" ? "Renew Package" : buyTitle}
        onCancel={close}
        closeCallback={close}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ overflowY: "auto" }}
      >
        {popupType == "renew-package" ? (
          <RenewPackage packageTitle={packageInfo.title}  parentInfo={packageInfo.parentInfo} productUrlKey={productUrlKey} />
        ) : (
          <BuyOnline
            buttonText={buyTitle}
            // linkUrl={packageInfo.published && !packageInfo.parentInfo ? `/${productUrlKey}/${packageId}/${urlTitle}` : "/mock-tests"}
            linkUrl={packageInfo.published && !packageInfo.parentInfo ? `/${productUrlKey}/${packageInfo.id}/${packageInfo.title}` : url}
          />
        )}
      </CustomModal>
    </>
  );
};


export default ExpiredCourse;
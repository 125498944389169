import React, { useEffect, useState } from "react";
import { Cookies, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { allCoursesArrLabel, pdpUrls, pageName } from "../../constants/appConfig";
import { productMeta } from "../../constants/meta";
import BuyNowComponent from "../components/BuyNowComponent";
import EmptyCart from "../components/cart/EmptyCart";
import CartActionModal from "../components/CartActionModal";
import BuyNowCheckoutModal from "../components/BuyNowCheckoutModal";
import ErrorBoundary from "../components/ErrorBoundary";
import IncludedPackages from "../components/IncludedPackages";
import BreadCrumb from "../components/library/BreadCrumb";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import CustomModal from "../components/library/CustomModal";
import DfpAdPdp from "../components/library/DfpAdPdp";
import SectionComponent from "../components/library/SectionComponent/SectionComponent";
import {
  AppliedCouponInfo,
  // CallNowWidget,
  CourseDetailsSection,
  ExamIconList,
  InfoButtonList,
  PriceComponent,
  ProductSchema,
  SubCategoriesList,
} from "../components/ProductDetailsSubComponents";
import { CallNowWidget } from "../components/PDPComponent";
import { default as Link } from "../containers/ConnectedLink";
import {
  clearProductDetailsData,
  getPackageMahapack,
  getPackageOffering,
  getProductDetail,
} from "../data/ducks/productDetails/actions";
import "../data/ducks/productDetails/reducers";
import fetch from "../data/utils/fetch";
import {
  capitalizedString,
  createOptimizedImgUrl,
  getDeviceType,
  getMoengageMetadata,
  getPdpUrl,
  getYoutubeIframeUrl,
  isADDA,
  isEmpty,
  scrollToTop,
  trackMoengageEvent,
  updateHash,
} from "../data/utils/helpers";
import FreeContentModal from "../components/FreeContentModal.js";
import ApplyCoupon from "../components/ApplyCoupon";
import { BLANK_IDENTIFIER } from "../components/MoengageConstants";
import { openTestPortal } from "../components/enrolledCourses/TestSeries/TakeTest/util";
import { istUtcTimeDiff } from "../../constants/appConstants.js";

const cookie = new Cookies();

const ProductDetails = (props) => {
  const [checkoutData, setCheckoutData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [showProductInfo, setShowProductInfo] = useState(false);
  const [viewOffer, setViewOffer] = useState(true);
  const [applyOffers, setApplyOffers] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [Moenagagedata, setMoengagedata] = useState("");
  const [isStepSkip, setStepSkip] = useState(false);
  const [fromBuyNowBtn, setFromBuyNowBtn] = useState(false);
  const [priceDetails, setPriceDetails] = useState({
    basePrice: undefined,
    discount: undefined,
    payablePrice: undefined,
    appliedCoupon: "",
    enteredCouponFlag: false,
    discountType: ""
  });
  const [ads, showAds] = useState(false);
  const [activeValidityData, setActiveValidityData] = useState({});
  const [viewOfferModalOnly, setViewOfferModalOnly] = useState(null);
  const [freeContentModalVisible, setFreeContentModalVisible] = useState(false);
  const [removeCouponFlag, setRemoveCouponFlag] = useState(false);
  const [couponRemoving, setCouponRemoving] = useState(false);
  const [applyDefaultCouponFlag, setApplyDefaultCouponFlag] = useState(true);
  const [deviceType, setDeviceType] = useState('d');
  const [moeSourceScreen, setMoeSourceScreen] = useState("_blank");
  // by default map it from API's VP data

  const { location } = props;
  const { pathname } = location;
  var pathnameCms = pathname;
  const [isPurchased, setIsPurchased] = useState(-1);

  let paytmUserInfo;
  if (props.isPaytmMini) {
    paytmUserInfo = JSON.parse(
      typeof window != "undefined" && localStorage.getItem("paytm-user-info")
    );
  }
  const [userEmail, setUserEmail] = useState(
    paytmUserInfo && paytmUserInfo.email ? paytmUserInfo.email : null
  );
  const [isMahapackedCLicked, setMahapackecdCLicked] = useState(0);

  useEffect(() => {
    if (fromBuyNowBtn) {
      if (applyOffers && viewOffer) {
        let payload = Getpayload();
        payload.user_exam_category_selected = "Web";
        payload.modified_date = Moenagagedata?.updatedAt || BLANK_IDENTIFIER;
        payload.published_date = Moenagagedata?.createdAt || BLANK_IDENTIFIER;
        payload.package_url = window?.location?.href || BLANK_IDENTIFIER;
        payload.package_subject = BLANK_IDENTIFIER;
        if (typeof Moengage !== "undefined") {
          Moengage.track_event(
            "buy_now_btn_clicked",
            getMoengageMetadata(payload)
          );
        }

        // sending data to google tag manager on click of buy now / view offer button
        if (props?.userInfo && ProductData && typeof window !== 'undefined') {
          window.dataLayer.push(createGtmDataPayload(props.userInfo, ProductData, props?.isLoggedIn))
        }
      }
    } else {
      if (applyOffers && viewOffer) {
        let payload = Getpayload();
        payload.total_final_amount = "";
        payload.payablePrice = (priceDetails && priceDetails.payablePrice) || "";
        payload.getUtmFromStore = props.utmParams || "";
        payload.total_final_amount = "";
        if (typeof Moengage !== "undefined") {
          trackMoengageEvent(
            "offer_button_clicked",
            getMoengageMetadata(payload)
          );
        }

        // sending data to google tag manager on click of buy now / view offer button
        if (props?.userInfo && ProductData && typeof window !== 'undefined') {
          window.dataLayer.push(createGtmDataPayload(props.userInfo, ProductData, props?.isLoggedIn))
        }
      }
    }

  }, [applyOffers, viewOffer])


  useEffect(() => {
    const payload1 = JSON.parse(localStorage.getItem("DataForMoengage"));
    const payload2 = JSON.parse(localStorage.getItem("TestModalData"));
    let email = props.cookies.get("cp_user_email");

    if(email && payload1 && payload2){
      const {contentId,contentTitle,productId } = payload2;
      setTimeout(() => {
        if(email){
            openTestPortal(contentId,productId,contentTitle, "new");
        }
      }, 1000);
      
      //MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("sample_content_clicked", getMoengageMetadata(payload1.payload));

      localStorage.removeItem("DataForMoengage");
      localStorage.removeItem("TestModalData");

  }  
  }, [props.cookies.get("cp_user_email")]);

  useEffect(() => {
    if (location.hash == '#cart/failure' || location.hash.indexOf('#cart/success') > -1) {
      setApplyOffers(true)
    }
    if (props.loggedUserDetails.email)
      setUserEmail(props.loggedUserDetails.email);
  }, [props.loggedUserDetails.email]);

  useEffect(() => {
    let mahapackid =
      typeof window !== "undefined" && sessionStorage.getItem("mahapackid");
    let original_package_id =
      typeof window !== "undefined" &&
      sessionStorage.getItem("original_package_id");
    let mahapackurlid =
      typeof window !== "undefined" && window.location.href.split("/")[4];
    if (mahapackid != mahapackurlid && original_package_id != mahapackurlid) {
      typeof window !== "undefined" && sessionStorage.removeItem("mahapackid");
      typeof window !== "undefined" &&
        sessionStorage.removeItem("original_package_id");
      typeof window !== "undefined" &&
        sessionStorage.removeItem("mahapack_widget_clicked");
    }
  }, [typeof window !== "undefined" && window.location.href.split("/")[4]]);

  useEffect(() => {
    if(!(priceDetails.appliedCoupon && priceDetails.discount)){
      setCouponRemoving(false);
    }
  },[priceDetails])

  useEffect(() => {
    setMoeSourceScreen(props?.history?.location?.state?.source_screen || '_blank');
  },[]);


  const getProductPurchaseInfo = (id) => {
    fetch(`${pdpUrls.purchasedPackage}?pkgId=${id}`, "GET")
      .then((res) => {
        if (res === true) {
          setIsPurchased(true);
        } else {
          setIsPurchased(false);
        }
        showAds(true);
      })
      .catch(() => {
        CartActionModal;
        setIsPurchased(false);
        showAds(true);
      });
  };

  const mahapackMoengageevent = (mahapackdata) => {
    setMahapackecdCLicked(1);
    let payload = { source: "web", brand: pageName[1] };
    payload.exam_category = Moenagagedata.courses;
    payload.exam =
      Moenagagedata.examTypes && Moenagagedata.examTypes[0]
        ? Moenagagedata.examTypes[0].name || ""
        : "";
    payload.moe_logged_in_status = props.cookies.get("cp_token") || "";
    (payload.user_name = props.cookies.get("cp_user_name") || "guest"),
      (payload.user_email = props.cookies.get("cp_user_email") || "");
    payload.packageActualPrice = mahapackdata.maximumRetailPrice;
    payload.packageDiscountedPrice = mahapackdata.sellingPrice;
    payload.id = mahapackdata.id;
    payload.name = mahapackdata.title;
    payload.productType = mahapackdata.categoryName;
    payload.package_status = "Paid";
    payload.subPackageTitle = mahapackdata.title;
    payload.source_screen = "PDP Page";
    payload.userAgent_1 = navigator.userAgent;
    payload.original_package_id = Moenagagedata.id;
    payload.Repurchase_btn_clicked_flag =
      typeof window !== "undefined" &&
        window.localStorage.getItem("mypuchaseId") == props.match.params.id
        ? 1
        : 0;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "mahapack_widget_click",
        getMoengageMetadata(payload)
      );
    }
  };

  const Getpayload = () => {
    let payload = { source: "web", brand: pageName[1] };
    if (Moenagagedata) {
      payload.subPackageTitle = Moenagagedata?.title;
      payload.Repurchase_btn_clicked_flag =
        typeof window !== "undefined" &&
          window.localStorage.getItem("mypuchaseId") == props?.match?.params?.id
          ? 1
          : 0;
      payload.name = Moenagagedata?.title;
      payload.category = Moenagagedata?.suggestionCat;
      payload.primary_category = Moenagagedata?.primaryCategory;
      // payload.productType = Moenagagedata.suggestionCat;
      payload.id = Moenagagedata?.id;
      payload.package_actual_price = Moenagagedata?.maxPrice;
      payload.package_discounted_price = Moenagagedata?.price;
      payload.package_final_price = price;
      payload.package_subject = BLANK_IDENTIFIER;
      payload.sellingprice = Moenagagedata?.price;
      payload.exam_category = Moenagagedata?.courses;
      payload.modified_date = Moenagagedata?.updatedAt || '';
      payload.published_date = Moenagagedata?.createdAt || '';
      payload.package_url = window?.location?.href || '';
      payload.exam =
        Moenagagedata?.examTypes && Moenagagedata?.examTypes[0]
          ? Moenagagedata?.examTypes[0]?.name || BLANK_IDENTIFIER
          : BLANK_IDENTIFIER;
      payload.package_status = "Paid";
      payload.language =
        Moenagagedata?.languages &&
        Moenagagedata?.languages[0] &&
        Moenagagedata?.languages[0]?.name || "English";
      payload.getUtmFromStore = props?.utmParams || "";
      payload.source_screen = moeSourceScreen;
    }
    if (
      props?.productDetails?.mahapack &&
      props?.productDetails?.mahapack?.id &&
      isMahapackedCLicked == 1
    ) {
      sessionStorage.setItem("original_package_id", Moenagagedata?.id);
      sessionStorage.setItem("mahapack_widget_clicked", isMahapackedCLicked);
      sessionStorage.setItem("mahapackid", props?.productDetails?.mahapack?.id);
      payload.original_package_id = Moenagagedata?.id;
      payload.mahapack_widget_clicked = isMahapackedCLicked;
    } else {
      payload.original_package_id =
        typeof window !== "undefined" &&
        sessionStorage.getItem("original_package_id");
      payload.Repurchase_btn_clicked_flag =
        typeof window !== "undefined" &&
          window.localStorage.getItem("mypuchaseId") == props?.match?.params?.id
          ? 1
          : 0;
      payload.mahapack_widget_clicked =
        typeof window !== "undefined" &&
        sessionStorage.getItem("mahapack_widget_clicked");
    }
    return payload;
  };

  useEffect(() => {
    let device = getDeviceType();
    setDeviceType(device);
  },[props?.productDetails])

  useEffect(() => {
    setCheckoutData(props.userAddress);

  }, [applyOffers])

  useEffect(() => {
    scrollToTop();
    //props.getPackageOffering(props.match.params.id);
    props.getPackageMahapack(props.match.params.id);
    let isStoreEmpty = isEmpty(props.productDetails);
    let previousPackageId =
      !isStoreEmpty &&
      props.productDetails.data &&
      props.productDetails.data.packageEsEntity &&
      props.productDetails.data.packageEsEntity[0] &&
      props.productDetails.data.packageEsEntity[0].id;
    if (
      isStoreEmpty ||
      (previousPackageId != undefined &&
        props.match.params.id != previousPackageId)
    ) {
      setIsLoading(true);
      setPriceDetails({
        basePrice: undefined,
        discount: undefined,
        payablePrice: undefined,
        appliedCoupon: "",
      });
      props.getProductDetail(props.match.params.id).then((data) => {
        data[0].then((d) => {
          setIsLoading(false);
          setMoengagedata(d?.data?.packageEsEntity[0]);
          let respData = d?.data?.packageEsEntity[0];
          setPrimaryValidityColor(respData);

          let d1 = d?.data?.packageEsEntity ? d?.data?.packageEsEntity[0] : "";
          let payload = { source: "web", brand: pageName[1] };
          if (d1) {
            payload.subPackageTitle = d1?.title;
            payload.package_title = d1?.title;
            payload.category = d1?.suggestionCat;
            payload.primary_category = d1?.primaryCategory;
            payload.package_id = d1?.id;
            payload.getUtmFromStore = props?.utmParams || "";
            payload.package_actual_price = d1?.maxPrice;
            payload.package_discounted_price = d1?.price;
            payload.Repurchase_btn_clicked_flag =
              typeof window !== "undefined" &&
                window.localStorage.getItem("mypuchaseId") ==
                props.match.params.id
                ? 1
                : 0;
            payload.exam_category = d1.courses;
            payload.exam =
              d1?.examTypes && d1?.examTypes[0] ? d1?.examTypes[0]?.name || "" : "";
            payload.package_status = "Paid";
            payload.language =
              ( d1?.languages && d1?.languages[0] && d1?.languages[0]?.name ) || "English";
            payload.original_package_id =
              typeof window !== "undefined" &&
              sessionStorage.getItem("original_package_id");

            payload.mahapack_widget_clicked =
              typeof window !== "undefined" &&
              sessionStorage.getItem("mahapack_widget_clicked");

            payload.default_coupon = d1?.defaultCoupons ? 'TRUE' : 'FALSE';// True/False
            payload.default_coupon_name = d1?.defaultCoupons?.code || BLANK_IDENTIFIER; // code name
            payload.sample_content = d1?.freeContentAvailable ? 'TRUE' : 'FALSE'; // True/False
            payload.sample_content_count = d1?.freeContentData?.length ? d1?.freeContentData?.length : 0// freecontent count
            payload.multivalidity = d1?.multiValidity?.length ? 'TRUE' : 'FALSE' // True/False
            payload.source_screen = props?.history?.location?.state?.source_screen || 'url';
            payload.package_final_price = d1.price;
            payload.package_subject = BLANK_IDENTIFIER;
            payload.modified_date = d1?.updatedAt || BLANK_IDENTIFIER;
            payload.published_date = d1?.createdAt || BLANK_IDENTIFIER;
            payload.package_url = window?.location?.href || BLANK_IDENTIFIER;
          }

          //MOENGAGE EVENT
          if (typeof Moengage !== "undefined")
            if (typeof Moengage !== "undefined")
              Moengage.track_event(
                "product_detail",
                getMoengageMetadata(payload)
              );

          //DATALAYER
          const { location } = props;
          const { pathname } = location;

          const examName = pathname.split("/")[3];
          const user_id_cookie = cookie.get("cp_user_id");

          let courseName = d1.courses && d1.courses.split(",")[0].toLowerCase();

          if (
            props.userInfo &&
            props.isLoggedIn &&
            typeof window != undefined
          ) {
            window.dataLayer.push({
              event: "fireRemarketingTag",
              google_tag_params: {
                edu_pid: d1.id,
                ecomm_pagetype: "product",
                ecomm_totalvalue: d1.price,
              },
              event: "productDetail",
              ecommerce: {
                detail: {
                  //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                  products: [
                    {
                      name: d1.title,
                      id: d1.id, // Product id
                      price: d1.price,
                      brand: pageName[1],
                      category: d1 && d1.suggestionCat,
                    },
                  ],
                  userDetails: [
                    {
                      name: (props.userInfo && props.userInfo.name) || "guest",
                      email: (props.userInfo && props.userInfo.email) || "",
                      isMobile: getDeviceType() == "m" ? true : false,
                      loginType: "email",
                      isLoggedIn: true,
                    },
                  ],
                },
              },
            });
          } else if (
            !props.isLoggedIn &&
            !user_id_cookie &&
            typeof window != undefined
          ) {
            window.dataLayer.push({
              event: "fireRemarketingTag",
              google_tag_params: {
                edu_pid: d1.id,
                ecomm_pagetype: "product",
                ecomm_totalvalue: d1.price,
              },
              event: "productDetail",
              ecommerce: {
                detail: {
                  //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                  products: [
                    {
                      name: d1.title,
                      id: d1.id, // Product id
                      price: d1.price,
                      brand: pageName[1],
                      category: d1 && d1.suggestionCat,
                    },
                  ],
                  userDetails: [
                    {
                      name: "guest",
                      email: "",
                      isMobile: getDeviceType() == "m" ? true : false,
                      loginType: "--",
                      isLoggedIn: false,
                    },
                  ],
                },
              },
            });
          }
        });
      });
    } else {
      let payload = { source: "web", brand: pageName[1] };
      if (
        props.productDetails &&
        props.productDetails.data &&
        props.productDetails.data.packageEsEntity &&
        props.productDetails.data.packageEsEntity[0]
      ) {
        let d1 = props?.productDetails?.data?.packageEsEntity[0];
        setMoengagedata(props?.productDetails?.data?.packageEsEntity[0]);
        payload.subPackageTitle = d1?.title;
        payload.package_title = d1?.title;
        payload.category = d1?.suggestionCat;
        payload.primary_category = d1?.primaryCategory;
        payload.package_id = d1?.id;
        payload.package_actual_price = d1?.maxPrice;
        payload.package_discounted_price = d1?.price;
        payload.exam_category = d1?.courses;
        payload.exam =
          d1?.examTypes && d1?.examTypes[0] ? d1?.examTypes[0]?.name || "" : "";
        payload.getUtmFromStore = props?.utmParams || "";
        payload.package_status = "Paid";
        payload.language =
          ( d1?.languages && d1?.languages[0] && d1?.languages[0]?.name ) || "English";
        payload.Repurchase_btn_clicked_flag =
          typeof window !== "undefined" &&
            window.localStorage.getItem("mypuchaseId") == props?.match?.params?.id
            ? 1
            : 0;
        payload.original_package_id =
          typeof window !== "undefined" &&
          sessionStorage.getItem("original_package_id");
        payload.mahapack_widget_clicked =
          typeof window !== "undefined" &&
          sessionStorage.getItem("mahapack_widget_clicked");

        payload.default_coupon = d1?.defaultCoupons ? 'TRUE' : 'FALSE';// True/False
        payload.default_coupon_name = d1?.defaultCoupons?.code || BLANK_IDENTIFIER; // code name
        payload.sample_content = d1?.freeContentAvailable ? 'TRUE' : 'FALSE'; // True/False
        payload.sample_content_count = d1?.freeContentData?.length ? d1?.freeContentData?.length : 0// freecontent count
        payload.multivalidity = d1?.multiValidity?.length ? 'TRUE' : 'FALSE' // True/False
        payload.source_screen = props?.history?.location?.state?.source_screen || 'url';
        payload.package_final_price = price;
        payload.package_subject = BLANK_IDENTIFIER;
        payload.modified_date = d1?.updatedAt || BLANK_IDENTIFIER;
        payload.published_date = d1?.createdAt || BLANK_IDENTIFIER;
        payload.package_url = window?.location?.href || BLANK_IDENTIFIER;
      }

      //MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("product_detail", getMoengageMetadata(payload));

      //DATALAYER
      const { location } = props;
      const { pathname } = location;

      const user_id_cookie = cookie.get("cp_user_id");
      const examName = pathname.split("/")[3];
      let data = props.productDetails.data;
      let ProductData = data && data.packageEsEntity && data.packageEsEntity[0];
      if (!ProductData) return "Some Error";
      let courseName =
        ProductData.courses && ProductData.courses.split(",")[0].toLowerCase();

      if (props.cookies.get("cp_token") && typeof window != "undefined") {
        window.dataLayer.push({
          event: "fireRemarketingTag",
          google_tag_params: {
            edu_pid: payload && payload.id,
            ecomm_pagetype: "product",
            ecomm_totalvalue: payload && payload.packageDiscountedPrice,
          },
          event: "productDetail",
          ecommerce: {
            detail: {
              products: [
                {
                  name: payload && payload.name,
                  id: payload && payload.id, // Product id
                  price: payload && payload.packageDiscountedPrice,
                  brand: pageName[1],
                  category: payload && payload.category,
                },
              ],
              userDetails: [
                {
                  name: props.cookies.get("cp_user_name") || "guest",
                  email: props.cookies.get("cp_user_email") || "",
                  isMobile: getDeviceType() == "m" ? true : false,
                  loginType: "email",
                  isLoggedIn: true,
                },
              ],
            },
          },
        });
      } else if (
        !props.cookies.get("cp_user_email" && typeof window != "undefined")
      ) {
        window.dataLayer.push({
          event: "fireRemarketingTag",
          google_tag_params: {
            edu_pid: payload.id,
            ecomm_pagetype: "product",
            ecomm_totalvalue: payload && payload.packageDiscountedPrice,
          },
          event: "productDetail",
          ecommerce: {
            detail: {
              //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
              products: [
                {
                  name: payload && payload.name,
                  id: payload && payload.id, // Product id
                  price: payload && payload.packageDiscountedPrice,
                  brand: pageName[1],
                  category: payload && payload.category,
                },
              ],
              userDetails: [
                {
                  name: "guest",
                  email: "",
                  isMobile: getDeviceType() == "m" ? true : false,
                  loginType: "--",
                  isLoggedIn: false,
                },
              ],
            },
          },
        });
      }
    }
    let data = props.productDetails.data;
    let ProductData = data?.packageEsEntity[0];

    setPrimaryValidityColor(ProductData);
    const email = props.cookies.get("cp_user_email");
    if (props.cookies.get("cp_token") && (email || props.loggedUserDetails.name)) {
      getProductPurchaseInfo(ProductData.id);
    } else {
      setIsPurchased(false);
      showAds(true);
    }
    let pdpInitialHistoryLength = props.history.length;
    sessionStorage.setItem("pdpInitialHistoryLength", pdpInitialHistoryLength);
  }, [props.match.params.id]);


  const setPrimaryValidityColor = (ProductData) => {
    ProductData?.multiValidity?.map((item, index) => {
      if (item.id == ProductData.id) {
        setActiveValidityData(item);
      }
    });
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: "80px" }}>
        <CustomLoader></CustomLoader>
      </div>
    );
  }

  const getInfoButton = (ProductData) => {
    let infoButtonArray = [];
    if (ProductData.olcMetaJson) {
      infoButtonArray = infoButtonArray.concat(
        Object.keys(ProductData.olcMetaJson).map((keyName) => {
          return;
        })
      );
    }
  };



  const openPackageModal = (id, category, viewCourseFlag = null) => {
    if (id == "FREE_CONTENT") {
      let paramerter = 'test';
      if (ProductData.freeContentData?.filter(item => item?.contentType == 1)?.length) paramerter = 'test';
      else if (ProductData.freeContentData?.filter(item => item?.contentType == 4)?.length) paramerter = 'ebooks';
      updateHash(`freeContent/${props.match.params.id}/${paramerter}`);
      setFreeContentModalVisible(true);
      let payload = { source: "web", brand: pageName[1] };
      let d1 = props?.productDetails?.data?.packageEsEntity[0]
      payload.subPackageTitle = d1.title;
      payload.name = d1.title;
      payload.category = d1.suggestionCat;
      payload.primary_category = d1.primaryCategory;
      payload.id = d1.id;
      payload.packageActualPrice = d1.maxPrice;
      payload.packageDiscountedPrice = d1.price;
      payload.exam_category = d1.courses;
      payload.exam =
        d1.examTypes && d1.examTypes[0] ? d1.examTypes[0].name || "" : "";
      payload.getUtmFromStore = props.utmParams || "";
      payload.package_status = "Paid";
      payload.language =
        d1.languages && d1.languages[0] && d1.languages[0].name;
      payload.Repurchase_btn_clicked_flag =
        typeof window !== "undefined" &&
          window.localStorage.getItem("mypuchaseId") == props.match.params.id
          ? 1
          : 0;
      payload.source_screen = "product_detail";
      payload.original_package_id =
        typeof window !== "undefined" &&
        sessionStorage.getItem("original_package_id");
      payload.mahapack_widget_clicked =
        typeof window !== "undefined" &&
        sessionStorage.getItem("mahapack_widget_clicked");
      //MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("sample_content_clicked", getMoengageMetadata(payload));
      return
    }

    let payload = Getpayload();
    payload.position = category;
    payload.getUtmFromStore = props.utmParams || "";
    if (typeof Moengage !== "undefined")
      if (typeof Moengage !== "undefined")
        Moengage.track_event(
          "content_listing_opened",
          getMoengageMetadata(payload)
        );
    let tempUrl = "";
    let hashParameter = "";
    switch (category) {
      case "ONLINE_LIVE_CLASSES":
        tempUrl = "my-live-class";
        hashParameter = "olcCount";
        break;
      case "VIDEOS":
        tempUrl = "my-videos";
        hashParameter = "videosCount";

        break;
      case "BOOKS":
        tempUrl = "my-ebooks";
        break;
      case "EBOOKS":
        tempUrl = "my-ebooks";
        hashParameter = "ebooksCount";

        break;
      default:
        tempUrl = "my-mock-test";
        hashParameter = "testSeriesCount";

        break;
    }
    if (viewCourseFlag && viewCourseFlag == "viewCourse") {
      updateHash(`packageDetails/${props.match.params.id}/${id}`);
    }
    //  else if (isPurchased && isPurchased != -1) {
    //   props.history.push(`/${tempUrl}`);
    // }
    else {
      if (ProductData.linkedPkgs !== null) {
        // handling of case when a packages is not having child packages. Items Like tests videos ebooks are attached to packages directly.
        updateHash(`packageDetails/${props.match.params.id}/${id}`);
      } else {
        let packageChildData =
          props.productDetails &&
          props.productDetails.packageOfferingData &&
          props.productDetails.packageOfferingData.pkgResponse;
        if (
          packageChildData &&
          !packageChildData[id] &&
          packageChildData[hashParameter]
        ) {
          updateHash(
            `packageDetails/${props.match.params.id}/${id}/${props.match.params.id}/0`
          );
          setStepSkip(true);
        } else {
          updateHash(`packageDetails/${props.match.params.id}/${id}`);
        }
      }
    }
  };

  if (
    isEmpty(props?.productDetails?.data || []) ||
    !props?.productDetails?.data?.packageEsEntity ||
    !props?.productDetails?.data?.packageEsEntity[0]
  ) {
    return (
      <EmptyCart
        title={" "}
        header={"Currently unavailable"}
        subHeader={
          "Be ahead of your competition with our comprehensive study material"
        }
        ProductTypeVisible={true}
      />
    );
  }
  let ProductData = props?.productDetails?.data?.packageEsEntity[0];
  let indexSubCategory = ProductData?.subCategories?.findIndex(item => item.id == "FREE_CONTENT")
  if (ProductData?.freeContentAvailable && ProductData?.freeContentData?.length) {
    if (indexSubCategory < 0) ProductData?.subCategories?.unshift({ id: "FREE_CONTENT", category: "FREE_CONTENT" })
  }
  let courseName =
    ProductData.courses && ProductData.courses.split(",")[0].toLowerCase();

  const categoryObj = {
    ONLINE_LIVE_CLASSES: { label: "Live Class", urlParam: "live-classes" },
    EBOOKS: { label: "Ebooks", urlParam: "ebooks" },
    TEST_SERIES: { label: "Mock Tests", urlParam: "mock-tests" },
    BOOKS: { label: "Books", urlParam: "books" },
    VIDEOS: { label: "Videos", urlParam: "videos" },
    MODELS_3D: {label: "3D Learning", urlParam:"3d-models"}
  };
  let categoryName = "";
  let categoryUrl = "";

  if (ProductData.categories) {
    let categoryId = ProductData.categories
      .split(",")
      .find((categoryId) => categoryObj[categoryId]);
    if (categoryId) {
      categoryName = categoryObj[categoryId].label;
      categoryUrl = categoryObj[categoryId].urlParam;
    }
  }
  // MAGAZINES,ONLINE_LIVE_CLASSES,TEST_SERIES,BOOKS

  if (playVideo) {
    let payload = Getpayload();
    payload.action = "consulting_video_clicked";
    payload.getUtmFromStore = props.utmParams || "";
    if (typeof Moengage !== "undefined")
      if (typeof Moengage !== "undefined")
        Moengage.track_event("consulting_video", getMoengageMetadata(payload));
  }

  let metaTitle = `${capitalizedString(ProductData.title)}`;
  let metaDescription = `Avail ${capitalizedString(
    ProductData.title
  )} online at ${pageName[1]} at most affordable prizes. Prepare with the latest & updated study material to ace your dream.`;
  if (productMeta[ProductData.id]) {
    metaTitle = productMeta[ProductData.id]["title"];
    metaDescription = productMeta[ProductData.id]["description"];
  }

  const handleChangeValidity = (item) => {
    // window.scrollTo(0, 0)
    if (activeValidityData?.id != item.id) {
      setActiveValidityData(item);

      ProductData.title = item?.title;
      ProductData.id = item?.id;
      ProductData.description = item?.desc;
      ProductData.maxPrice = item?.mrp;
      ProductData.price = item?.sp;
      ProductData.dualValidity = item?.dualValidity;

      setPriceDetails((prevState) => ({
        basePrice: undefined,
        discount: undefined,
        payablePrice: undefined,
        appliedCoupon: "",
      }));
    }
  };

  const openViewOffersModal = () => {
    setFromBuyNowBtn(false)
    setViewOfferModalOnly(true)

    if (setApplyOffers) setApplyOffers(true);
    sessionStorage.setItem("buyNow_Offer", "offer");
    if (setViewOffer) setViewOffer(true);
    window.location.hash = "#checkout/viewOffers";
  }
  

  const maxPrice = priceDetails.basePrice == undefined
      ? ProductData.maxPrice
      : priceDetails.basePrice
  const price = priceDetails.payablePrice == undefined
      ? ProductData.price
      : priceDetails.payablePrice
  const sellingPrice = props.productDetails?.data?.packageEsEntity[0]?.price;

  const couponAppliedOrDefaultCouponAvailable = ((priceDetails.appliedCoupon && priceDetails.discount) || (ProductData.defaultCoupons && (Date.now() <= (ProductData.defaultCoupons.validityExpire - (istUtcTimeDiff)))));

  const createGtmDataPayload = (userInfo, productData, isLoggedIn) => {

    const productPayload = {
      name: productData?.title,
      id: productData?.id, // Product id
      price: productData?.price,
      brand: pageName[1],
      category: productData?.suggestionCat,
    }

    const userInfoPayload = {
      name: userInfo?.name || "guest",
      email: userInfo?.email || "",
      isMobile: getDeviceType() == "m" ? true : false,
      loginType: "email",
      isLoggedIn: isLoggedIn ? true : false,
    }

    const google_tag_params = {
      edu_pid: productData?.id,
      ecomm_pagetype: "product",
      ecomm_totalvalue: productData?.price,
    }

    const payload = {
      event: "addToCart",
      google_tag_params: google_tag_params,
      ecommerce: {
        detail: {
          userDetails: [userInfoPayload],
          products: [productPayload]
        }
      }
    }

    return payload;
  }

  return (
    <>
      <div className="productDetailSection">
        {!props.isPaytmMini && (
          <div className={"banner-container"}>
            {ads && <DfpAdPdp packageId={props.match.params.id} />}
          </div>
        )}
        <CustomHelmet
          title={
            props.seoData &&
              props.seoData[pathnameCms] &&
              props.seoData[pathnameCms].metaTitle
              ? props.seoData[pathnameCms].metaTitle
              : `${metaTitle} - ${pageName[1]}`
          }
          description={
            props.seoData &&
              props.seoData[pathnameCms] &&
              props.seoData[pathnameCms].metaDescription
              ? props.seoData[pathnameCms].metaDescription
              : `${metaDescription}`
          }
          url={
            (props.seoData &&
              props.seoData[pathnameCms] &&
              props.seoData[pathnameCms].canonical) ||
            props.req_url
          }
          keywords={
            props.seoData &&
            props.seoData[pathnameCms] &&
            props.seoData[pathnameCms].keywords
          }
          noFollow={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noFollow
          }
          noIndex={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noIndex
          }
        />
        <div className="componentList">
          <ErrorBoundary>
            <IncludedPackages
              isPurchased={isPurchased}
              id={props.match.params.id}
              title={ProductData.title}
              setStepSkip={setStepSkip}
              isStepSkip={isStepSkip}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <FreeContentModal productData={ProductData} setFreeContentModalVisible={setFreeContentModalVisible} freeContentModalVisible={freeContentModalVisible} />
          </ErrorBoundary>
          {ProductData.demoUrl && (
            <ErrorBoundary>
              <CustomModal
                footer={null}
                visible={playVideo}
                title={ProductData.title}
                onCancel={() => {
                  setPlayVideo(false);
                }}
                closeCallback={() => {
                  setPlayVideo(false);
                }}
                closable={true}
                maskClosable={false}
                keyboard={false}
                modalClassName="largemodal"
                bodyStyle={{ overflowY: "auto" }}
              >
                <div className="video-popup">
                  <iframe
                    src={getYoutubeIframeUrl(ProductData.demoUrl)}
                    allow="autoplay"
                  ></iframe>
                </div>
              </CustomModal>
            </ErrorBoundary>
          )}
          {applyOffers &&
            <BuyNowCheckoutModal
              maxPrice={sellingPrice} // because instead of mrp, we have to show selling price
              price={price}
              priceDetails={priceDetails}
              viewOfferModalOnly={viewOfferModalOnly}
              userInfo={props.userInfo}
              isLoggedIn={props.isLoggedIn}
              setViewOfferModalOnly={setViewOfferModalOnly}
              viewOffers={setApplyOffers}
              updatePriceDetails={setPriceDetails}
              loggedUserDetails={props.loggedUserDetails}
              productData={{...ProductData, maxPrice: ProductData.price}}
              cartJson={`{"${ProductData.id}":1}`}
              isShippable={ProductData.shippable}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              Getpayload={Getpayload()}
              setCheckoutData={setCheckoutData}
              checkoutData={checkoutData}
              getUtmFromStore={props.utmParams}
              setFromBuyNowBtn={(flag) => setFromBuyNowBtn(flag)}
              payablePrice={priceDetails && priceDetails.payablePrice}
              activeValidityData={activeValidityData}
            ></BuyNowCheckoutModal>
          }

          <div style = {{display: "none"}}>
            {typeof window !== 'undefined' && <ApplyCoupon
              viewOfferModalOnly={viewOfferModalOnly}
              setViewOfferModalOnly={setViewOfferModalOnly}
              packageId={ProductData.id}
              packageType={ProductData.categories}
              priceDetails={priceDetails}
              maxPrice={maxPrice}
              price={price}
              updatePriceDetails={setPriceDetails}
              productData={ProductData}
              cartPayload={`{\"${ProductData.id}\":1}`}
              loggedUserDetails={props.loggedUserDetails}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              removeCouponFlag = {removeCouponFlag}
              setRemoveCouponFlag = {setRemoveCouponFlag}
              applyDefaultCouponFlag = {applyDefaultCouponFlag}
              setApplyDefaultCouponFlag = {setApplyDefaultCouponFlag}
              notPurchasedAndPublished = {(!isPurchased && isPurchased != -1 && ProductData.published)}
              parent = {"productDetails"}
              Getpayload={Getpayload()}
              activeValidityData={activeValidityData}
          ></ApplyCoupon>}
          </div>

          <div className="breadcrumb-section">
            <BreadcrumbSchema
              items={[
                {
                  title: "Home",
                  url: "/",
                },
                {
                  title: capitalizedString(courseName),
                  url: `/${courseName}-study-materials`,
                },
                {
                  title: categoryName,
                  url: `/${courseName}/${categoryUrl}-study-kit`,
                },
                {
                  title: ProductData.title,
                  url: "/",
                },
              ]}
            />
            <BreadCrumb
              routeData={[
                { name: "Home", target: "/" },
                {
                  name: `${capitalizedString(courseName)?.replace(/_/g, "-")} study material`,
                  target: `/${courseName?.replace(/_/g, "-")}-study-materials`,
                },
                {
                  name: categoryName,
                  target: `/${courseName?.replace(
                    /_/g,
                    "-"
                  )}/${categoryUrl}-study-kit`,
                },
                { name: ProductData.title, target: "" },
              ]}
            />
          </div>
          <div className="productDetailsSection">
            <div className="detailsSection">
              <h1 className="h1">{props.seoData[pathnameCms]?.pageHeading || ProductData.title}</h1>
              {props.productDetails.mahapack &&
                props.productDetails.mahapack.id &&
                isPurchased == false &&
                ProductData.courses.split(",").length == 1 && (
                  <Link
                    to={getPdpUrl(
                      props.productDetails.mahapack.categoryName,
                      props.productDetails.mahapack.id,
                      props.productDetails.mahapack.title
                    )}
                    className="mahapacksection only-mobile"
                  >
                    <span
                      className="span-text"
                      onClick={() =>
                        mahapackMoengageevent(props.productDetails.mahapack)
                      }
                    >
                      Prepare for all{" "}
                      <i>{allCoursesArrLabel[ProductData.courses] || ProductData.courses}{" "}</i>
                      exams with one pack{" "}
                    </span>
                    <div className="mobile-know-more">
                      KNOW MORE
                      {/* <span className="icon-chevron-right chev-right"></span> */}
                    </div>
                  </Link>
                )}
              {props.seoData &&
                props.seoData[pathnameCms] &&
                props.seoData[pathnameCms].pageHeadingDescription && (
                  <div className="headingdescriptionprod">
                    {props.seoData[pathnameCms].pageHeadingDescription}
                  </div>
                )}

              <div className="infoButtonSection">
                <ErrorBoundary>
                  {<InfoButtonList ProductData={ProductData} activeValidityData={activeValidityData} />}
                </ErrorBoundary>
              </div>
                
               {/* coupon applied card mweb */}
              {
                 (!isPurchased && ProductData.published) && <div className="appliedCoupon">
                  <div>
                    <img src={couponAppliedOrDefaultCouponAvailable? "/images/productOffIcon2.svg": "/images/percentage2.svg"} />
                    <div className="couponCode">
                      <div>{priceDetails?.appliedCoupon || ProductData.defaultCoupons?.code || "Offers Available"}</div>
                      <div id="id1">{couponAppliedOrDefaultCouponAvailable? (priceDetails.appliedCoupon? "Coupon Applied": "Coupon Available"): "Add Promocode"}</div>
                    </div>
                  </div>
                  {
                    priceDetails.appliedCoupon ?
                      <div>
                        <div className="discountPrice">- ₹{Math.round((sellingPrice - price) * 100) / 100}</div>
                        {!couponRemoving ? <div class="removeButton" onClick={() => { setRemoveCouponFlag(true); setCouponRemoving(true) }}>Remove</div> :
                          <CustomLoader buttonSpinner={true} />
                        }
                      </div>:
                    ProductData?.defaultCoupons ? <div className = "applyText" onClick = {() => setApplyDefaultCouponFlag(true)}>
                      {applyDefaultCouponFlag? <CustomLoader buttonSpinner={true} />: "APPLY"}
                    </div>:
                    <div className = "applyText" onClick = {openViewOffersModal}>
                      <img src="/images/rightArrow.svg" />
                  </div>
                  }
                </div>
              }
              {ProductData?.supportNumber && deviceType !== 'd' && <CallNowWidget contact={ProductData?.supportNumber} deviceType={deviceType} Getpayload={Getpayload} Position='Top' /> }
               {/* coupon applied card mweb */}

              <div className="courseDetails" style={{ marginTop: "15px !important" }}>
                What you will get
              </div>

              <div className="subCategoryList" style={{ display: "flex" }}>
                {/* {<ExamIconList ProductData={ProductData}/>} */}
                {
                  <ErrorBoundary>
                    <SubCategoriesList
                      ProductData={ProductData}
                      onClick={openPackageModal}
                    />
                  </ErrorBoundary>
                }
              </div>
              {ProductData?.multiValidity?.length > 1 ? (
                <>
                  <div className="validity-outer">
                    <div className="validity-heading">Select Your Validity</div>
                    <div className="validity-slector-wrapper">
                      <ul>
                        {ProductData?.multiValidity?.map((item, index) => {
                          return (
                            <li
                              className={
                                activeValidityData?.id == item?.id
                                  ? "active-validity"
                                  : ""
                              }
                              onClick={() => handleChangeValidity(item)}
                              key={item?.id}
                            >
                              <button>{item.dualValidity ? `${item.validity / 2}+${item.validity / 2}` : item.validity} Months</button>
                              {item?.tags?.length > 0 ?
                                (item?.tags?.map((tagName, index) => {
                                  return (
                                    <div className="valdity-bottom-msg">
                                      {tagName?.name}
                                    </div>
                                  );
                                })) : (item.dualValidity && <div className="valdity-bottom-msg">
                                  Double Validity
                                </div>)
                              }
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>{" "}
                </>
              ) : null}

              <ErrorBoundary>
                <div className="courseDetails">
                  <CourseDetailsSection hlsJson={ProductData.hlsJson} />
                </div>
              </ErrorBoundary>
              <div>{/* <CourseDetailsSection /> */}</div>

              <ErrorBoundary>
                <div className="productInfoSection">
                  <ProductSchema
                    data={ProductData}
                    props={props}
                    activeValidityData={activeValidityData}
                  ></ProductSchema>
                  <SectionComponent
                    header={
                      <div
                        className="productInfoHeaderSection"
                        onClick={() => {
                          setShowProductInfo(!showProductInfo);
                        }}
                      >
                        <div>Product Description</div>
                        <div
                          className={`productInfoHeader ${showProductInfo ? "chevronUp" : "chevronDown"
                            }`}
                        ></div>{" "}
                      </div>
                    }
                    body={
                      <div
                        className={showProductInfo ? "" : "productInfoBody"}
                        dangerouslySetInnerHTML={{
                          __html: ProductData.description,
                        }}
                      ></div>
                    }
                  ></SectionComponent>
                </div>
              </ErrorBoundary>
              <ErrorBoundary>
                {ProductData.examTypes && ProductData.examTypes.length > 0 && (
                  <div className="examCoveredSection">
                    <SectionComponent
                      header="Exams Covered"
                      body={<>{<ExamIconList ProductData={ProductData} />}</>}
                    ></SectionComponent>
                  </div>
                )}
              </ErrorBoundary>
              {/* {props.seoData &&
                props.seoData[pathnameCms] &&
                props.seoData[pathnameCms].bottomContent && (
                  <BottomContent bottomContent={props.seoData[pathnameCms].bottomContent} />
                )}

              <ErrorBoundary>
                {ProductData.categories === "ONLINE_LIVE_CLASSES" && (
                  <div className="prerequisitesSection">
                    <ContentSection
                      header="You Will Need"
                      body={PreRequisites()}
                    ></ContentSection>
                  </div>
                )}
              </ErrorBoundary>
              <ErrorBoundary>
                <FaqSection faqJson={ProductData.faqJson}></FaqSection>
              </ErrorBoundary>

              {/* <ErrorBoundary>
                <LatestBlogs
                  posts_data={
                    props.seoData &&
                    props.seoData[pathnameCms] &&
                    props.seoData[pathnameCms].wp_posts
                  }
                ></LatestBlogs>
              </ErrorBoundary> */}
            </div>
            <div className="purchaseSection">
              <div
                className="purchaseImage"
                onClick={
                  ProductData.demoUrl
                    ? () => {
                      setPlayVideo(true);
                    }
                    : () => { }
                }
              >
                <div
                  className="purchaseImageBg"
                  style={{ backgroundImage: `url(${ProductData.imgUrl})` }}
                ></div>
                <div className="purchaseImageOverlay"></div>
                <img
                  src={createOptimizedImgUrl(ProductData.imgUrl, 200)}
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  alt={pageName[0]}
                  title={`${capitalizedString(ProductData.title)}` || pageName[0]}
                  loading='lazy'
                />
                {ProductData.demoUrl && (
                  <div
                    className="playIcon playIconSection"
                    onClick={() => {
                      setPlayVideo(true);
                    }}
                  ></div>
                )}
              </div>
              <div className="purchaseInfo">
                <CourseDetailsSection hlsJson={ProductData.hlsJson} />
              </div>
              <div className="purchaseAction">
                <div className="priceSection">
                  {PriceComponent(
                    sellingPrice,
                    price,
                    priceDetails,
                    setApplyOffers,
                    setViewOffer,
                    setViewOfferModalOnly,
                    (flag) => setFromBuyNowBtn(flag),
                    (!isPurchased && isPurchased != -1 && ProductData.published),
                    priceDetails.appliedCoupon && priceDetails.discount,
                    ProductData?.defaultCoupons ? true: false
                  )}
                  <AppliedCouponInfo
                    userEmail={userEmail}
                    appliedCoupon={priceDetails.appliedCoupon}
                    couponDiscount={priceDetails.discount}
                    Getpayload={Getpayload()}
                    payablePrice={priceDetails.payablePrice}
                    price={props.productDetails.data.packageEsEntity[0].price}
                    getUtmFromStore={props.utmParams}
                    setRemoveCouponFlag = {setRemoveCouponFlag}
                    isDefaultCouponAvailable = {ProductData?.defaultCoupons ? true: false}
                    applyDefaultCouponFlag = {applyDefaultCouponFlag}
                    setApplyDefaultCouponFlag = {setApplyDefaultCouponFlag}
                    defaultCouponCode = {ProductData?.defaultCoupons?.code}
                    isPurchased = {isPurchased}
                    published = {ProductData.published}
                  />
                </div>
                <BuyNowComponent
                  viewOffers={setApplyOffers}
                  productId={props.match.params.id}
                  isPurchased={isPurchased}
                  Getpayload={Getpayload()}
                  productData={ProductData}
                  isLoggedIn={props.loggedUserDetails.email}
                  published={ProductData.published}
                  history={props.history}
                  onClick={openPackageModal}
                  setFromBuyNowBtn = {setFromBuyNowBtn}
                />

                {props.productDetails.mahapack &&
                  props.productDetails.mahapack.id &&
                  isPurchased == false &&
                  ProductData.courses.split(",").length == 1 && (
                    <div
                      onClick={() =>
                        mahapackMoengageevent(props.productDetails.mahapack)
                      }
                    >
                      <Link
                        to={getPdpUrl(
                          props.productDetails.mahapack.categoryName,
                          props.productDetails.mahapack.id,
                          props.productDetails.mahapack.title
                        )}
                        className="mahapacksection only-desktop"
                      >
                        <div>
                          Prepare for all{" "}
                          <i>{allCoursesArrLabel[ProductData.courses] ||
                            ProductData.courses}{" "}</i>
                          exams with one pack
                        </div>
                        <div className="btntxt">
                          KNOW MORE
                          {/* <span className="icon-chevron-right chev-right"></span> */}
                        </div>
                      </Link>
                    </div>
                  )}
              </div>
              {ProductData?.supportNumber && deviceType === 'd' && <CallNowWidget contact={ProductData?.supportNumber} deviceType={deviceType} Getpayload={Getpayload} Position='Top' /> }
            </div>
         
          </div>
        </div>
      </div>
    </>
  );
};

ProductDetails.fetching = ({ dispatch, match }) => {
  return [
    dispatch(getProductDetail(match.params.id)),
    //dispatch(getPackageOffering(match.params.id))
  ];
}; 

const mapStateToProps = (state) => {
  const { productDetails } = state;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;

  let req_url = state.req_url;
  let seoData = state.seoData;
  return {
    productDetails,
    userAddress: productDetails.userAddress,
    loggedUserDetails: state.header.userInfo || {},
    utmParams,
    isLoggedIn,
    userInfo,
    req_url,
    seoData,
    isPaytmMini: state.isPaytmMini,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (productId) => dispatch(getProductDetail(productId)),
    getPackageOffering: (productId) => dispatch(getPackageOffering(productId)),
    clearProductDetailsData: () => dispatch(clearProductDetailsData()),
    getPackageMahapack: (productId) => dispatch(getPackageMahapack(productId))
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
);

import React from "react";
import CustomButton from "./library/CustomButton";

const AddressModal = ({ userDetails, setAddressModalShow, confirm }) => {

    const handleClose = () => {
        setAddressModalShow(false)
    }

  return (
    <div className="buynow-container">
      <div className="buynow-wrapper">
        <div className="buynow-body-content buynow-address-content">
          <div className="buynow-address-cnf-header">
            Confirm Delivery Address
            <div className="buynow-close-icon" onClick={handleClose}>
              <a>
                <img src="../../images/checkout-close.svg" alt="" />
              </a>
            </div>
          </div>
          <div className="buynow-address-cnf-info">
            <div className="address-cnf-info-icon" />
            <div className="address-cnf-info-text">Incorrect address might result in book not getting delivered</div>
          </div>
          <div className="buynow-address-cnf-content">
            <div className="address-cnf-row">
              <span>Name : </span>
              {userDetails?.name}
            </div>
            <div className="address-cnf-row">
              <span>Mobile Number : </span>
              {userDetails?.phone}
            </div>
            <div className="address-cnf-row">
              <span>Pin Code : </span>
              {userDetails?.pincode}
            </div>
            <div className="address-cnf-row">
              <span>Flat, house no, apartment : </span>
              {userDetails?.firstLine}
            </div>
            <div className="address-cnf-row">
              <span>Area, Colony, Street, Sector, Village : </span>
              {userDetails?.secondLine}
            </div>
            <div className="address-cnf-row">
              <span>Landmark : </span>
              {userDetails?.landmark}
            </div>
            <div className="address-cnf-row">
              <span>City : </span>
              {userDetails?.city}
            </div>
            <div className="address-cnf-row">
              <span>State : </span>
              {userDetails?.state}
            </div>
          </div>
          <div className="buynow-address-cnf-footer footer-paymentbtn-fix">
            <CustomButton
              classes="buynow-address-edit-btn custom-button"
              onClick={handleClose}
              buttontext="Edit Address"
            />
            <CustomButton
              onClick={() => {
                confirm();
                handleClose();
              }}
              buttontext="I Confirm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;

import React, { useState, useEffect } from "react";
import { liveClassHandout } from "../../../constants/appConfig";
import fetchapi from "../../data/utils/fetch";
import PDFViewer from "./PdfViewer";
import ATooltip from "../library/ATooltip"
const pdfLib = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js"

const HnadoutPanel = ({ data, handlePanelClose, type, handleVideoPiP }) => {
  const [handout, setHandout] = useState([]);
  const [pdfModalData, setPdfModalData] = useState({});
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchHandouts()
    loadScript();
    return () => {
      removeScript();
    };
  }, [])

  const getScheduleIdFromJoinUrl = () => {
    const url = data;
    const regex = /[?&]vc=(\d+)/;
    const match = regex.exec(url);
    const vcValue = match && match[1];

    return vcValue;
  };
  const fetchHandouts = async () => {
    let sch_id;
    if (type === "purchased") {
      sch_id = getScheduleIdFromJoinUrl();
    } else {
      sch_id = data?.externalScheduleId;
    }

    try {
      let res = await fetchapi(`${liveClassHandout?.handout}${sch_id}`, "GET");
      if (res?.success) {
        setLoading(false);
        setHandout(res?.data);
      }
    } catch (err) {
      setLoading(false);

      alert(err.message);
    }
  };

  const handleViewHandout = (res) => {
    const { handout_link, comment } = res;
    download(`${handout_link}`, `${comment}`);
  };

  const download = (path, filename) => {
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const viewPdfModal = async (res) => {
    setPdfModalData(res);
  };

  const getExtension = (res) => {
    let extension;
    const url = res;
    extension = url.match(
      /\.(pdf|jpg|jpeg|png|gif|bmp|ppt|pptx|docx|doc|docm|dot)$/i
    )[1];
    if (
      ["jpg", "jpeg", "png", "gif", "bmp"].includes(extension?.toLowerCase())
    ) {
      extension = "img";
    }
    if (
      ["ppt", "pptx", "docx", "doc", "docm", "dot"].includes(
        extension?.toLowerCase()
      )
    ) {
      extension = "ppt";
    }
    return extension;
  }
  const loadScript = () => {
    const script = document.createElement('script');
    script.src = pdfLib;
    script.async = true;
    document.head.appendChild(script);
  };

  const removeScript = () => {
    const script = document?.querySelector(`script[src="${pdfLib}"]`);
    if (script) {
      document.head.removeChild(script);
    }
  };



  useEffect(() => {
    handleVideoPiP(pdfModalVisible);
  }, [pdfModalVisible]);
  return (
    <div className="handout-main-container">
      {pdfModalVisible && (
        <div className="handout-modal-main-container">
          <div className="handout-panel-modal-main-wrapper">
            <div className="handout-panel-modal-PDF-body-container">
              <div className="col-12 handout-panel-top-btn-container">
                <button className="handout-panel-download-btn" onClick={() => {
                  handleViewHandout(pdfModalData)
                }}><i className=""></i>{" "} Download</button>
                <button className="handout-panel-modal-close-btn" onClick={() => { setPdfModalVisible(false) }}>Exit</button>
              </div>
              {getExtension(pdfModalData?.handout_link) === "pdf" && (
                <PDFViewer pdfUrl={pdfModalData?.handout_link} />
              )}
              {getExtension(pdfModalData?.handout_link) === "img" && (
                <img
                  src={pdfModalData?.handout_link}
                  alt={pdfModalData?.comment}
                  className="img-view-handout-modal"
                />
              )}
              {getExtension(pdfModalData?.handout_link) === "ppt" && (
                <h4>Please download to see this file </h4>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="handout-main-wrapper">
        <div className="handout-panel-header">
          <div className="hadnout-panel-header-label">Handouts</div>
          <div className="col-12">
            <i className="handout-modal-close-btn" onClick={() => { handlePanelClose() }}></i>
          </div>
        </div>
        <div className="handout-body-container">
          {handout?.length ? (
            handout?.map((val, idx) => {
              return (
                <div key={`indx__${idx}`} className="handout-panel-modal-list">
                  <div className="handout-panel-list-icon-container">
                    <button className="handout-list-icon-container"></button>
                    <ATooltip
                      customWrapperClass={"custom-tooltip-wrapper"}
                      content={
                        val?.comment === "Untitled"
                          ? `Handout - ${idx + 1}`
                          : val?.comment || `Handout - ${idx + 1}`
                      }
                    >
                      <div className="handout-list-name text-elipsis">
                        {val?.comment === "Untitled"
                          ? `Handout - ${idx + 1}`
                          : val?.comment || `Handout - ${idx + 1}`}
                      </div>
                    </ATooltip>
                  </div>
                  <div>
                    <button
                      className="handout-view-btn"
                      onClick={() => {
                        setPdfModalVisible(true);
                        viewPdfModal(val);
                        setModalHeader(
                          val?.comment === "Untitled"
                            ? `Handout - ${idx + 1}`
                            : val?.comment || `Handout - ${idx + 1}`
                        );
                      }}
                    ></button>
                  </div>
                </div>
              );
            })
          ) : !loading ? (
            <div className="hadnout-panel-no-data">
              <h5>No Handout Available</h5>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HnadoutPanel;

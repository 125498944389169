import React, { useEffect, useState } from 'react'
import OTPInput from '../AOtp';

const OnboardingOtpInput = (props) => {

  const {
    otpDetails = {},
    setOtpDetails = () => {},
    otpRemainingTime = 0,
    resendOTP = () => {},
    receiverType = "phone",
    showOtpDurationMsg = false,
    otpSentFlag = false,
    setOtpSentFlag = () => {}
  } = props;

  const [otpResendTime, setOtpResendTime] = useState(0);
  const [showResendBtn, setShowResendBtn] = useState(true);

  const changeOtpValue = (otp) => {
    setOtpDetails({...otpDetails, otp : otp});
  } 

  const getTimeString = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(seconds).padStart(2, '0');
    return `${minutesString}:${secondsString}`;
  };
  

  useEffect(() => {
    if (otpSentFlag) {
      setOtpResendTime(60);
      setShowResendBtn(false);
    } else {
      setShowResendBtn(true);
    }
  }, [otpSentFlag]);
  
  useEffect(() => {
    let interval;
    if (otpSentFlag) {
      interval = setInterval(() => {
        setOtpResendTime(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            setOtpSentFlag(false);
            setShowResendBtn(true);
            clearInterval(interval);
            return prevTime;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSentFlag]);

  return (
    <div className='otp-send-resend-wrap'>
        <div className='onboarding-otp-input-box-wrap'>
          <OTPInput
            length={6}
            className="otpContainer"
            inputClassName={`otpInput ${false ? 'input-error' : ''}`}
            isNumberInput
            autoFocus
            onChangeOTP={
                (otp) => changeOtpValue(otp)
            }
          />
        </div>
        <div className='otp-resend-wrap'>
            <span className='otp-resend-text'>Didn't receive the OTP?</span>
            {showResendBtn ? 
              <span className='otp-resend-btn' onClick={() => {
                resendOTP()
              }}>Resend</span> :
              <span className='otp-resend-timer'>{getTimeString(otpResendTime)}</span>
            }
            
        </div>
        {showOtpDurationMsg && <div className='otp-validity'>OTP is valid for {otpRemainingTime} min</div>}
    </div>
  )
}

export default OnboardingOtpInput;

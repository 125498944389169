import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
import { debounce, useWishListedPackageIds } from "../../components/utils";
import {
  fetchWishListPackageDetails,
  setSelectedFacets,
  setSelectedWishListPackageIds,
  setShowFilterPopup,
  setWishListPackageDetails,
} from "../../data/ducks/wishlist/actions";
import {
  getDeviceType,
  isADDA,
  scrollToTop,
  toQueryString,
} from "../../data/utils/helpers";
import WishListHeader from "./WishListHeader";
import WishListing from "./WishListing";
import CustomHelmet from "../../components/library/CustomHelmet";

const cookie = new Cookies();

const WishList = () => {
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [wlQueryParams, setWlQueryParams] = useState({
    packageIds: wishListPackageIds?.join(","),
    primaryFacetsRequired: true,
    searchTerm: "",
    pageSize:
      getDeviceType() === DEVICE_IDENTIFIERS?.DESKTOP_IDENTIFIER ? 20 : 8,
  });
  const [pageNo, setPageNo] = useState(1);
  const [wishListPackageIds] = useWishListedPackageIds();
  const { selectedWishListPackageIds = [] } = useSelector(
    (state) => state.wishlist
  );
  const dispatch = useDispatch();
  const isLogined = cookie.get("cp_token");
  useEffect(() => {
    if (wishListPackageIds?.length) {
      const packageIds = wishListPackageIds?.join(",");
      setWlQueryParams((prev) => ({
        ...prev,
        packageIds,
      }));
      fetchWishListDetailHandler(
        {
          ...wlQueryParams,
          packageIds,
        },
        true // show initial load flag
      );
    }
    // reset wlQueryParams state when no any packageIds
    if (!wishListPackageIds?.length) {
      // reset wishlist item details store when no packageIds found
      dispatch(setWishListPackageDetails([]));
      resetWlState();
    }
  }, [wishListPackageIds]);

  useEffect(() => {
    scrollToTop(0);
  }, []);
  /**
   * Asynchronous function to fetch and update wishlist details based on the provided arguments.
   * @async
   * @function
   * @param {object} args - Additional arguments for customizing the wishlist fetch operation.
   * @property {boolean} args.packageIds - A list of package IDs to filter the wishlist.
   * @property {boolean} args.primaryFacetsRequired - Indicates if primary facets are required.
   * @property {string} args.searchTerm - Search term to filter wishlist items.
   * @throws {Error} Throws an error if there is an issue with the API request.
   */
  const fetchWishListDetailHandler = async (
    args = {},
    showInitialLoad = false
  ) => {
    // Merge additional arguments with existing query parameters
    const optionParams = { ...wlQueryParams, ...args };
    const { pg, ...paramsPayload } = optionParams;

    // reset pageNo
    if (!pg) setPageNo(1);
    if (!paramsPayload?.packageIds) {
      return "";
    }
    if (
      paramsPayload?.searchTerm &&
      !paramsPayload?.searchTerm?.trim()?.length >= 2
    ) {
      return "";
    }

    const facets = args?.categoryFacets || args?.coursesFacets || "";
    dispatch(setSelectedFacets(facets));
    if (showInitialLoad) setIsContentLoading(true);
    scrollToTop(0);
    try {
      // Update the query parameters state
      setWlQueryParams((prev) => ({
        ...prev,
        ...paramsPayload,
      }));

      // Generate query strings from the updated parameters
      const queryStrings = toQueryString(paramsPayload);

      // Debounce the API request for 1000 milliseconds
      debounce(async () => {
        const res = await dispatch(
          fetchWishListPackageDetails(queryStrings, pg > 0 ? pg - 1 : 0)
        );

        // Retrieve the first result from the response
        const result = await res[0];

        // Extract package details from the response data or set an empty array if no data is available
        const packageResponse = result?.data?.packages || [];

        // Extract package IDs from the package details
        const packageIds = packageResponse?.map((item) => item?.id);

        // // Set the "hasMore" state based on the number of packages received compared to the total count
        // setHasMore(packageResponse?.length < result?.data?.count);

        // close facet filter popup for mWeb
        if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) {
          dispatch(setShowFilterPopup(false));
        }

        // Update the selected wishlist package IDs based on the received data
        if (selectedWishListPackageIds?.length) {
          const newSelectedWishListPackageIds =
            selectedWishListPackageIds?.filter((id) => packageIds.includes(id));
          dispatch(
            setSelectedWishListPackageIds(newSelectedWishListPackageIds)
          );
        }
        if (showInitialLoad) setIsContentLoading(false);
      }, 1000);
    } catch (error) {
      // Handle errors if there is an issue with the API request
    }
  };

  const handlePageNoChange = (pg = 0) => {
    setPageNo(pg);
    fetchWishListDetailHandler({
      pg,
    });
  };
  const resetWlState = () => {
    setWlQueryParams({
      packageIds: "",
      primaryFacetsRequired: true,
      searchTerm: "",
      pageSize:
        getDeviceType() === DEVICE_IDENTIFIERS?.DESKTOP_IDENTIFIER ? 20 : 8,
    });
  };
  if (!isLogined) return <Redirect to="/#show-login" />;
  // wishlist route available for only ADDA
  if (!isADDA) return <Redirect to="/not-found" />;
  return (
    <>
      <CustomHelmet
        title={"Wishlist"}
        description={
          "Hey Aspirant!Boost your preparation with these courses curated by your friend"
        }
      />
      <div className="wl-wrap">
        <WishListHeader
          searchTerm={wlQueryParams?.searchTerm}
          fetchWishListDetailHandlerCb={fetchWishListDetailHandler}
          resetWlStateCb={resetWlState}
        />
        <WishListing
          isLoading={isContentLoading}
          pageNo={pageNo}
          setPageNo={handlePageNoChange}
          searchTerm={wlQueryParams?.searchTerm}
          pageSize={wlQueryParams?.pageSize}
        />
      </div>
    </>
  );
};
export default WishList;

/* eslint-disable react/display-name */
import React from 'react';
import { Cookies } from "react-cookie";
import { optimizedImgUrl, trackMoengageEvent } from '../../data/utils/helpers';
import { pageName } from '../../../constants/appConfig';
const cookie = new Cookies();
const PlatformBanner = React.memo((props) => {
  const getSubScription = (e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.getUtmFromStore = props.getUtmFromStore;
    trackMoengageEvent("get_subscription_button_clicked", payload);
    window.location.replace(
      `/search?searchTerm=maha%20pack&utm_source=${pageName[1]}&utm_medium=HomePage&utm_campaign=Subscription`
    );
  };
  return (
    <>
      {/* <!-- Get Subscription--> */}
      <section className="get-subscription">
        <div className="container">
          <div className="get-subscription-wrapper">
            <div className="get-subs-left">
              <span>
              <h2>One Stop Solution<br/>For All Exam Preparation </h2>
              <div className="what-you-get">Live classes, Mock Tests, Videos and eBooks in one package </div>
              </span>
              <div className="get-subs-action"><span onClick={(e) => getSubScription(e)} style={{cursor:"pointer"}} className="ripple">Get Mahapack Subscription</span></div>

            </div>
            <div className="get-subs-right">
              <div className="detail-subs">
                <ul>
                  <li>
                    <div className="getSubIcon"><img src="../images/getMahapackIcon1.svg" alt='Live-Classes-Icon'/></div>
                    <div className="getSubText">
                      <div className="getSubtotal">2000+</div>
                      <div className="getSubDetail">Live Classes</div>
                    </div>
                  </li>
                  <li className="listforweb">
                    <div className="getSubIcon"><img src="../images/getMahapackIcon4.svg" alt='Mock-Tests-Icon'/></div>
                    <div className="getSubText">
                      <div className="getSubtotal">4000+</div>
                      <div className="getSubDetail">Full Length Mock Tests</div>
                    </div>
                  </li>
                  <li>
                    <div className="getSubIcon"><img src="../images/getMahapackIcon3.svg" alt='Recorded-Video-Icon'/></div>
                    <div className="getSubText">
                      <div className="getSubtotal">25000+</div>
                      <div className="getSubDetail">Recorded Video</div>
                    </div>
                  </li>
                  <li className="listforweb">
                    <div className="getSubIcon"><img src="../images/getMahapackIcon2.svg" alt='Practice-Tests-Icon'/></div>
                    <div className="getSubText">
                      <div className="getSubtotal">1500+</div>
                      <div className="getSubDetail">Sectional Practice Tests</div>
                    </div>
                  </li>

                  <li className="listformobile">
                    <div className="getSubIcon"><img src="../images/getMahapackIcon2.svg" alt='Quizzes-Icon'/></div>
                    <div className="getSubText">
                      <div className="getSubtotal">6000+</div>
                      <div className="getSubDetail">Mock Tests & Quizzes</div>
                    </div>
                  </li>
                </ul>
               
              </div>
            </div>
          {  /*<div className="bg-icons">
              <img src={optimizedImgUrl("https://${pageName[0]}.com/images/trophy-icon.svg")} className="trophy-icon" alt="trophy-icon" loading='lazy'/>
              <img src={optimizedImgUrl("https://${pageName[0]}.com/images/d-icon.svg")} className="d-icon" alt="d-icon" loading='lazy'/>
              <img src={optimizedImgUrl("https://${pageName[0]}.com/images/pencil-icon.svg")} className="pencil-icon" alt="pencil-icon" loading='lazy' />
            </div>*/}
          </div>
        </div>
      </section>

    </>
  )
});


export default PlatformBanner;
const INITIATE_LOGIN = "INITIATE_LOGIN";
const INITIATE_SIGNUP = "INITIATE_SIGNUP";
const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const SHOW_LOADER = "SHOW_LOADER";
const HIDE_LOADER = "HIDE_LOADER";
const FETCH_AUTO_SUGGESTION = "FETCH_AUTO_SUGGESTION";
const FETCH_SEARCH_SUGGESTIONS = "FETCH_SEARCH_SUGGESTIONS";
const LOGOUT_USER = "LOGOUT_USER";
const VERIFY_EMAIL_REGISTERED = "VERIFY_EMAIL_REGISTERED";
const VERIFY_OTP = "VERIFY_OTP";
const RESET_PASSWORD = "RESET_PASSWORD";
const GET_USER_CART = "GET_USER_CART";
const GET_CART_COUNT_FROM_COOKIE = "GET_CART_COUNT_FROM_COOKIE";
const FETCH_EXAM_LIST = "FETCH_EXAM_LIST";
const FETCH_GLOBAL_EXAM_LIST = "FETCH_GLOBAL_EXAM_LIST";
const FETCH_TRENDING_DATA = "FETCH_TRENDING_DATA";
const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS";
const LOGIN_FLAG = "LOGIN_FLAG";
const GET_MY_PURCHASE_LISTINGS = "GET_MY_PURCHASE_LISTINGS";
const GET_PARENT_PACKAGE_EXAMS = "GET_PARENT_PACKAGE_EXAMS";
const GET_PARENT_PACKAGE_MANAGE_EXAMS = "GET_PARENT_PACKAGE_MANAGE_EXAMS";
const SAVE_PARENT_PACKAGE_EXAMS = "SAVE_PARENT_PACKAGE_EXAMS";
const GET_PACKAGE_CATEGORIES_AND_EXAMS = "GET_PACKAGE_CATEGORIES_AND_EXAMS";
const GET_CHILD_PACKAGE_DATA = "GET_CHILD_PACKAGE_DATA";
const SET_FULL_PACKAGE_DATA = "SET_FULL_PACKAGE_DATA";
const GET_EXAM_SUBPACKAGE = "GET_EXAM_SUBPACKAGE";
const GET_PPC_CONTINUE_LISTING = "GET_PPC_CONTINUE_LISTING";
const GET_PACKAGE_CATEGORIES = "GET_PACKAGE_CATEGORIES";
const GET_PPC_ALL_CONTINUE_LISTING = "GET_PPC_ALL_CONTINUE_LISTING";
const SET_INIT_PPC_ALL_CONTINUELISTINGS = "SET_INIT_PPC_ALL_CONTINUELISTINGS";
const SET_PPC_ALL_CONTINUELISTINGS = "SET_PPC_ALL_CONTINUELISTINGS";
const CLEAN_PPC_PACKAGE_STATE = "CLEAN_PPC_PACKAGE_STATE";
const SAVE_PURCHASED_COURSES = "SAVE_PURCHASED_COURSES";
const GET_NEXT_MY_PURCHASE_LISTINGS = "GET_NEXT_MY_PURCHASE_LISTINGS";
const GET_NEXT_CHILD_PACKAGE_DATA = "GET_NEXT_CHILD_PACKAGE_DATA";
const SET_EXAM_MODE = "SET_EXAM_MODE";
const SAVE_RECENTLY_ADDED_COURSES = "SAVE_RECENTLY_ADDED_COURSES";
const GET_PACKAGE_HOVER_DETAILS = "GET_PACKAGE_HOVER_DETAILS";
const FETCHED_PURCHASED_COURSES_DATA = "FETCHED_PURCHASED_COURSES_DATA";

// paid package search TYPES
const PURCHASED_PACKAGE_FACETS = "PURCHASED_PACKAGE_FACETS";
const PURCHASED_PACKAGE_SEARCH = "PURCHASED_PACKAGE_SEARCH";
const SAVE_RECENT_SEARCH = "SAVE_RECENT_SEARCH";
const PURCHASED_POPULAR_COURSES = "PURCHASED_POPULAR_COURSES";

export default {
  INITIATE_LOGIN,
  INITIATE_SIGNUP,
  UPDATE_USER_DETAILS,
  SHOW_LOADER,
  HIDE_LOADER,
  FETCH_AUTO_SUGGESTION,
  FETCH_SEARCH_SUGGESTIONS,
  CLEAR_SEARCH_SUGGESTIONS,
  LOGOUT_USER,
  VERIFY_EMAIL_REGISTERED,
  VERIFY_OTP,
  RESET_PASSWORD,
  GET_USER_CART,
  GET_CART_COUNT_FROM_COOKIE,
  FETCH_EXAM_LIST,
  FETCH_TRENDING_DATA,
  FETCH_GLOBAL_EXAM_LIST,
  LOGIN_FLAG,
  GET_MY_PURCHASE_LISTINGS,
  GET_NEXT_MY_PURCHASE_LISTINGS,
  GET_NEXT_CHILD_PACKAGE_DATA,
  GET_PARENT_PACKAGE_EXAMS,
  GET_PARENT_PACKAGE_MANAGE_EXAMS,
  SAVE_PARENT_PACKAGE_EXAMS,
  GET_PACKAGE_CATEGORIES_AND_EXAMS,
  GET_CHILD_PACKAGE_DATA,
  SET_FULL_PACKAGE_DATA,
  GET_EXAM_SUBPACKAGE,
  GET_PPC_CONTINUE_LISTING,
  GET_PPC_ALL_CONTINUE_LISTING,
  SET_INIT_PPC_ALL_CONTINUELISTINGS,
  SET_PPC_ALL_CONTINUELISTINGS,
  GET_PACKAGE_CATEGORIES,
  CLEAN_PPC_PACKAGE_STATE,
  SET_EXAM_MODE,
  SAVE_PURCHASED_COURSES,
  SAVE_RECENTLY_ADDED_COURSES,
  GET_PACKAGE_HOVER_DETAILS,
  FETCHED_PURCHASED_COURSES_DATA,
  PURCHASED_PACKAGE_FACETS,
  PURCHASED_PACKAGE_SEARCH,
  SAVE_RECENT_SEARCH,
  PURCHASED_POPULAR_COURSES,
};
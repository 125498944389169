import reducerRegistry from "../../reducerRegistry";
import types from "./types";

function examCategoryListing(state = {}, action) {
  switch (action.type) {
    case types.GET_EXAM_CATEGORY_LISTING: {
      const newObj = {};
      if (action.payload && action.payload.data && action.payload.data[0]) {
        
        let packageData=getExamCategoryPackageData(action.payload.data);
        newObj[action.meta.examType] = {
          data: packageData,
          // data: getExamCategoryPackageData(action.payload.data),

          facets: action.payload.data[0].facetsList,
        };
      }
      else{
        let packageData=getExamCategoryPackageData({});
        newObj[action.meta.examType] = {
          data: packageData,
          facets: action.payload?.data[0]?.facetsList || [],
        };
      }
      return newObj;
    }
    case types.GET_EXAM_CATEGORY_LISTING_WITH_FILTER: {
      const newObj = Object.assign({}, state);

      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.packages
      ) {
        // newObj[`${action.meta.examType}_clone`]=newObj[action.meta.examType]

        let packages = action.payload.data.packages;
        let mappedData = new Map();

        packages.map((details) => {
          if (mappedData.has(details.categories)) {
            mappedData.get(details.categories).push(details);
          } else {
            mappedData.set(details.categories, [details]);
          }
        });
        if (newObj[action.meta.examType].data.length !== mappedData.size) {
          let keys = [...mappedData.keys()];
          keys.map((category) => {
            let obj = newObj[action.meta.examType].data.find(
              (o) => o.name === category
            );
          });
        }
        newObj[action.meta.examType].data.map((categoryDetails) => {
          let newData = mappedData.get(categoryDetails.category);
          if (newData.length > 0) {
            categoryDetails.packageEsEntity = newData;
            categoryDetails.count=newData.length;
          }
        });
    
      }
      return {
        ...newObj,
      };
    }

    case types.CLEAR_ALL: {
      state = {};
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

const getExamCategoryPackageData = (packageData) => {
  
  let defaultPackageData = [
    {
      category: "ONLINE_LIVE_CLASSES",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "Online Live Classes",
    },
    {
      category: "VIDEOS",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "Video Courses",
    },
    {
      category: "TEST_SERIES",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "Test Series",
    },
    {
      category: "EBOOKS",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "E-Books",
    },
    {
      category: "BOOKS",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "Publications",
    },
    {
      category: "MODELS_3D",
      count: 0,
      facetsList: {},
      packageEsEntity: [],
      suggestionCategory: "3D Learning",
    },
  ];
  // packageData.map((packageDetail)=>{
  //   let obj = defaultPackageData.find((pdata, i) => {
  //     if (pdata.category === packageDetail.category) {
        
  //        defaultPackageData[i].packageEsEntity=packageDetail.packageEsEntity
  //        defaultPackageData[i].facetsList=packageData[0].facetsList
  //        defaultPackageData[i].count=packageDetail.packageEsEntity.length;
  //         return true; // stop searching
  //     }
  // });
  // })

  if(Object.keys(packageData)?.length){
    defaultPackageData.map((packageDetail, idx)=> {
      let data =  packageData.find(pdata => pdata?.category == packageDetail?.category)
   
      if(data){
       defaultPackageData[idx].packageEsEntity=data.packageEsEntity
       defaultPackageData[idx].facetsList=data.facetsList
       defaultPackageData[idx].count=data.count||data.packageEsEntity.length 
      }
    })
  }
return defaultPackageData;
};

reducerRegistry.register("examCategoryListing", examCategoryListing);
export default examCategoryListing;

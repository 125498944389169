import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CatalogCard from "../components/CatalogCard";
import CourseContent from "../components/enrolledCourses/Videos/CourseContent";
import SectionComponent from "../components/library/SectionComponent/SectionComponent";
import fetch from "../data/utils/fetch";
import Tab from "../components/library/Tab";
import {
  getDeviceType,
  scrollToTop,
  createOptimizedImgUrl,
  getYoutubeIframeUrl,
  getMoengageMetadata,
  getParameterByName,
  removeScripts,
  addScripts,
  isADDA,
} from "../data/utils/helpers";
import CustomModal from "../components/library/CustomModal";
import MultipleUserNotification from "../components/enrolledCourses/Videos/MultipleUserNotification";
import { videoPackageUrl, pdpUrls, pageName } from "../../constants/appConfig";
import { set } from "react-ga";
import CustomLoader from "../components/library/CustomLoader";
import { default as Link } from "./ConnectedLink";
import { Cookies } from "react-cookie";
;
import ShakaPlayer from "./../components/OldShakaPlayer"
import VideoPlayer from "../components/library/VideoPlayer/OldVideojsPlayer";
import CustomHelmet from "../components/library/CustomHelmet"
//"./../../library/CustomHelmet";


const cookie = new Cookies();
const VideoDetails = ({ videoDetail }) => {
  if (videoDetail) {
    return (
      <SectionComponent
        header={videoDetail.name}
        subheader={"What you’ll learn in this video"}
        body={<div dangerouslySetInnerHTML={{ __html: videoDetail.desc }} />}
      ></SectionComponent>
    );
  }
  return null;
};

const CourseDetails = ({ courseDetailsObj, courseDetails }) => {
  if (
    courseDetails &&
    courseDetailsObj &&
    courseDetailsObj.cD &&
    courseDetailsObj.cD[0]
  ) {
    return (
      <SectionComponent
        header="Course Details"
        body={
          <div
            dangerouslySetInnerHTML={{ __html: courseDetailsObj.cD[0].desc }}
          />
        }
        courseDetails={courseDetails}
        courseDetailsObj={courseDetailsObj}
      ></SectionComponent>
    );
  }
  return null;
};
const ExamCovered = ({ courseDetailsObj }) => {
  if (
    courseDetailsObj &&
    courseDetailsObj.bankUrls &&
    courseDetailsObj.bankUrls.length > 0
  ) {
    let body = (
      <div className="vc-exam-list">
        {courseDetailsObj.bankUrls.map((bankUrl) => {
          return (
            <div className="vc-exam-item">
              <img
                src={createOptimizedImgUrl(bankUrl, 158)}
                width="158px"
              ></img>
            </div>
          );
        })}
      </div>
    );
    return (
      <SectionComponent header="Exams Covered" body={body}></SectionComponent>
    );
  }
  return null;
};
let selectedVideo = null;
let progressApiFlag = false;


const VideoPackage = (props) => {
  const [moengageData, setmoengagedata] = useState();
  const [openSectionId, setOpenSectionId] = useState();
  const [openSectionName, setOpenSectionName] = useState();
  const [relatedDetails, setRelatedDetails] = useState();
  const [courseDetails, setCourseDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [isViewedOnSingleScreen, setIsViewedOnSingleScreen] = useState(true);
  const [buymodal, setbuymodal] = useState(false);
  const [datailsmodal, setdatailsmodal] = useState(false);
  const [childtitle, setchildtitle] = useState("");
  const [parentTitle, setparentTitle] = useState("");
  const [parentinfo, Setparentinfo] = useState([]);
  const [istoggleClicked, setistoggleClicked] = useState(false);
  const [demoUrl, setDemourl] = useState("");
  const [demo, setDemo] = useState(false);
  const [isRetryClicked, setIsRetryClicked] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [subject, setSubject] = useState();
  const [isPPC, setIsppc] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [showLicenseExpiredPopUp, setShowLicenseExpiredPopUp] = useState(false);
  const [handoutStatus, setHandoutStatus] = useState(false)
  // const checkScreenViewStatus = count => {
  const token = cookie.get("cp_token");
  useEffect(() => {
    if (selectedVideoDetails && selectedVideoDetails.url) {
      selectedVideo = selectedVideoDetails.url;
    }
  }, [selectedVideoDetails]);
  const getSelectedVideo = () => {
    return selectedVideo;
  };
  const getProgressApiFlag = (status) => {
    progressApiFlag = status;
  };
  useEffect(() => {
    fetch(`${pdpUrls.purchasedPackageV2}?pkgId=${props.match.params.id}`, "GET")
      .then((res) => {
        if (res === true) {
          setIsPurchased(true);
        } else {
          setIsPurchased(false);
        }
      })
      .catch(() => {
        setIsPurchased(false);
      });
  }, [props.match.params.id]);

  useEffect(() => {
    // loadVideoJsScripts();
    let videoIdHash = window.location.hash;
    if (videoIdHash.includes("current-video")) {
      setIsppc(true);
    }
    if (getDeviceType() == "m") {
      setSelectedSection("videos");
    }
    addScripts();
    scrollToTop(0);
    return () => {
      // remove JS Scripts;
      removeScripts();
    };
  }, []);
  useEffect(() => {
    let videoIdHash = window.location.hash;
    let ppcStatus;
    if (videoIdHash.includes("current-video")) {
      setIsppc(true);
      ppcStatus = true;
    }
    setLoading(true);
    fetch(
      `${videoPackageUrl.packageDetails}/${props.match.params.id}.json`
    ).then(
      (res) => {
        let language = "Hindi";
        setRelatedDetails(res);
        if (res && res.languages[0]) {
          language = res.languages[0];
        }
        fetch(
          `${videoPackageUrl.videoDetails}/${props.match.params.id}?language=${language}`
        ).then(
          (res) => {
            setLoading(false);
            setCourseDetails(res.data);
            var payload = {};
            payload.id = res.data.recentParentInfo
              ? res.data.recentParentInfo.id
              : props.match.params.id;
            payload.package_status = res.data.purchased ? "Paid" : "Free";
            payload.name = res.data.recentParentInfo
              ? res.data.recentParentInfo.title
              : res.data.title;
            payload.category = "Videos";
            payload.page_title = props.match.params.packageName;
            payload.subPackageType = "Videos";
            payload.subPackageId = props.match.params.id;
            payload.sub_package_title = res.data.title;
            payload.action = "video_opened";
            payload.isPPc = ppcStatus;
            setmoengagedata(payload);
            if (typeof Moengage !== "undefined")
              Moengage.track_event("video", getMoengageMetadata(payload));
            // setting initial Values
            let someTestData = null;
            if (
              res.data &&
              res.data.cc &&
              res.data.cc.subject &&
              res.data.cc.subject[0] &&
              res.data.cc.subject[0].ch
            ) {
              someTestData = getVideoDetailsById(
                res.data.cc.subject,
                getPlayedVideoId() && getPlayedVideoId().videoId
              );
            }

            if (someTestData) {
              setSubject(someTestData.subjectName);
              setOpenSectionName(someTestData.chapterName);
              setOpenSectionId(someTestData.chapterId);
              setSelectedVideoDetails(someTestData.videoDetail);
              setDemo(someTestData.demo);
            } else {
              setSelectedVideoDetails(res.data.cc.subject[0].ch[0].video[0]); // setting the first video;
              setOpenSectionName(res.data.cc.subject[0].ch[0].name);
            }
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [props.match.params.id]);


  const getVideoDetailsById = (data, id) => {
    let videoDetail = [];
    if (!id) return "";
    data &&
      data.forEach((sub, subindex) => {
        sub.ch.forEach((ch, chIndex) => {
          ch.video.forEach((video, VideoIndex) => {
            let Videodata = {};
            Videodata.subjectIdx = subindex;
            Videodata.chapterIdx = chIndex;
            Videodata.video = video;
            Videodata.demo = video.demo;
            videoDetail.push(Videodata);
          });
        });
      });

    videoDetail = videoDetail.filter((item) => {
      return item.video.url == id;
    });

    return videoDetail
      ? {
        videoDetail: videoDetail[0] && videoDetail[0].video,
        chapterId: videoDetail.chapterIdx,
        chapterName:
          data[videoDetail[0]?.subjectIdx]?.ch[videoDetail[0]?.chapterIdx]?.name ? data[videoDetail[0]?.subjectIdx]?.ch[videoDetail[0]?.chapterIdx]?.name : "",
        subjectName: data[videoDetail[0]?.subjectIdx]?.name ? data[videoDetail[0]?.subjectIdx]?.name : "",
      }
      : "";
  };
  const getPlayedVideoId = () => {
    let videoIdHash = window.location.hash;
    if (videoIdHash.includes("current-video")) {
      var videoData = {};
      setIsppc(true);
      videoData.videoId = videoIdHash.split("/")[1];
      videoData.subjectId = videoIdHash.split("/")[2];
      return videoData;
    }
    return undefined;
  };

  const setVisibleDetailsmodal = () => {
    setdatailsmodal(false);
    setistoggleClicked(false);
  };
  const setvisiblebuymodal = () => {
    setbuymodal(false);
    setistoggleClicked(false);
  };

  const modalValueHandler = (selectedVideoDetails) => {
    if (typeof window != undefined) {
      var location = window.location.href;
      var title = location.split("/")[5].toLocaleUpperCase();
      setchildtitle(title);
    }

    if (!selectedVideoDetails.demoUrl) {
      !courseDetails.recentParentInfo &&
        courseDetails.expired &&
        courseDetails.published &&
        setbuymodal(true);
      !courseDetails.recentParentInfo &&
        courseDetails.expired &&
        !courseDetails.published &&
        setbuymodal(true);
      courseDetails.recentParentInfo &&
        courseDetails.expired &&
        courseDetails.published &&
        !courseDetails.recentParentInfo.published &&
        setbuymodal(true);
      courseDetails.recentParentInfo &&
        courseDetails.expired &&
        !courseDetails.published &&
        courseDetails.recentParentInfo.published &&
        setdatailsmodal(true);

      courseDetails.recentParentInfo &&
        courseDetails.expired &&
        courseDetails.published &&
        courseDetails.recentParentInfo.published &&
        setdatailsmodal(true);
      let array = [];
      courseDetails.recentParentInfo &&
        array.push(courseDetails.recentParentInfo);
      array.length > 0 && Setparentinfo(array);
      if (
        courseDetails.parentInfo &&
        courseDetails.parentInfo.length > 1 &&
        courseDetails.expired
      ) {
        courseDetails.parentInfo[0].published &&
          courseDetails.parentInfo[1].published &&
          setdatailsmodal(true);
        array = [];
        array.push(courseDetails.parentInfo[0]);
        array.push(courseDetails.parentInfo[1]);
        Setparentinfo(array);
      }
    }
  };

  const toggleSection = (id) => {
    return () => {
      setOpenSectionId(openSectionId == id ? undefined : id);
    };
  };

  const rejoin = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, [100]);
  }

  if (loading)
    return (
      <div className="video-consumption">
        <CustomLoader></CustomLoader>
      </div>
    );
  if (!courseDetails || !selectedVideoDetails) return null;

  const videoJsOptions = {
    controls: true,
    autoplay: true,
    preload: "auto",
    playbackRates: [0.5, 1, 1.5, 2],
    loop: false,
    manualCleanup: true,
    techOrder: ["html5"],
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      hls: {
        overrideNative: true,
        withCredentials: true,
      },
    },
    sources: [
      {
        src: `${selectedVideoDetails?.url?.isFree
          ? videoPackageUrl.videoSource
          : videoPackageUrl.paidVideoSource
          }?id=${selectedVideoDetails.url
            ? selectedVideoDetails.url
            : selectedVideoDetails.demoUrl
          }&pkgId=${props.match.params.id}`,
        type: "application/x-mpegURL",
      },
    ],
  };

  return (
    <>

      <CustomHelmet title={`${pageName[1]} - ${isADDA ? 'India’s Leading Government Job Preparation platform' : 'Live Classes and Doubt Sessions for JEE, NEET'}`} />
      <div className="video-consumption">
        {/* custom modal section */}
        <CustomModal
          modalClassName={"modalZindex"}
          footer={null}
          visible={buymodal}
          title="Buy Videos"
          onCancel={setvisiblebuymodal}
          closeCallback={setvisiblebuymodal}
          closable={true}
          maskClosable={false}
          keyboard={false}
          bodyStyle={{ overflowY: "auto" }}
        >
          <div>
            <div
              style={{
                backgroundColor: "rgb(255, 229, 207)",
                textAlign: "center",
              }}
            >
              <img
                src={createOptimizedImgUrl(
                  "https://s3.ap-south-1.amazonaws.com/storefront-assets/Expiry.png",
                  200
                )}
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <div
                style={{
                  margin: "20px 0",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "1.2",
                  textAlign: "center",
                  color: "#090023",
                }}
              >
                Package Expired!
              </div>
              <div
                style={{
                  margin: "0 24px 44px",
                  opacity: "0.8",
                  fontSize: "14px",
                  lineHeight: "1.43",
                  textAlign: "center",
                  color: "#444545",
                }}
              >
                <span>
                  This product is not available anymore. You can still view
                  similar products in this category.
                </span>
              </div>

              <div style={{ textAlign: "center" }}>
                <a
                  style={{
                    width: "200px",
                    padding: "5px",
                    height: "44px",
                    backgroundColor: "#f57f18",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                  className="sf-button-secondary  "
                  ui-sref="ebooksListing"
                  href="/videos"
                >
                  Buy Videos
                </a>
              </div>
            </div>
          </div>
        </CustomModal>

        <CustomModal
          footer={null}
          visible={datailsmodal}
          title={"Renew Package"}
          onCancel={setVisibleDetailsmodal}
          closeCallback={setVisibleDetailsmodal}
          closable={true}
          maskClosable={false}
          keyboard={false}
          modalClassName="largemodal"
          bodyStyle={{ overflowY: "auto" }}
        >
          <div className="video-popup myebookpopup">
            <p>
              {childtitle || ""}
              <div>is Available with the below courses</div>
            </p>
            {parentinfo &&
              parentinfo.length > 0 &&
              parentinfo.map((value) => {
                return (
                  <Link
                    to={`/product-comprehensive-video/${value.id}/${value.title}`}
                  >
                    <div className="catalog-card ">
                      <div className="catalog-card-media">
                        <div className="catalog-card-overlay"></div>
                        <div>
                          <img
                            src={createOptimizedImgUrl(value.imageUrl, 90)}
                            height="110px"
                          />
                        </div>
                      </div>
                      <div className="catalog-card-content ">
                        <div className="package-title">{value.title || ""}</div>
                        <div className="catalog-card-subheader">
                          <div
                            className="package-subheader"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="paid-itemCount"></div>
                          </div>
                        </div>
                        <div className="catalog-card-text">Details</div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </CustomModal>
        {/* selectedVideoDetails, */}

        {/* modal section closed */}

        <MultipleUserNotification
          isViewedOnSingleScreen={isViewedOnSingleScreen}
          setIsViewedOnSingleScreen={setIsViewedOnSingleScreen}
        />
        <div className="vc-header">
          <span className="vc-title">
            {/* Maths for SSC CGL Mains 2019 Video Course (Hindi) */}
            {courseDetails.title}
          </span>
          <span className="vc-subtitle">
            Language: &nbsp;
            {relatedDetails && relatedDetails.languages
              ? relatedDetails.languages[0]
              : ""}
          </span>
        </div>
        <div className="vc-header-m">
          <span className="vc-title">
            {/* Maths for SSC CGL Mains 2019 Video Course (Hindi) */}
            {`${openSectionName} > ${selectedVideoDetails.name}`}
          </span>
          <span className="vc-subtitle">{`by ${selectedVideoDetails.faculty
            ? selectedVideoDetails.faculty[0].name
            : ""
            }`}</span>
        </div>
        <div className="vc-body">
          {(selectedSection == undefined || selectedSection == "videos") && (
            <div className="vc-course-navigator">
              <div className="vc-cn-container" style={{ maxHeight: "600px" }}>
                <CourseContent
                  moengageData={moengageData && moengageData}
                  selectedVideoDetails={
                    selectedVideoDetails && selectedVideoDetails
                  }
                  courseContentData={courseDetails.cc}
                  toggleSection={toggleSection}
                  setOpenSectionName={setOpenSectionName}
                  openSection={openSectionId}
                  setSelectedVideoDetails={(val) => {
                    rejoin()
                    setSelectedVideoDetails(val)
                  }}
                  buymodal={buymodal}
                  parentinfo={parentinfo}
                  datailsmodal={datailsmodal}
                  setistoggleClicked={setistoggleClicked}
                  courseDetails={courseDetails}
                  modalValueHandler={modalValueHandler}
                  token={token}
                  subject={subject}
                  isPurchased={isPurchased}
                ></CourseContent>
              </div>
            </div>
          )}

          <div className="vc-course-content videoContainer">
            <div className="vc-player">
              <div className="vc-player-video">
                {isViewedOnSingleScreen &&
                  (selectedVideoDetails.webDrmEnabled && !demo ? (
                    !refresh && <ShakaPlayer
                      url={selectedVideoDetails.dashUrl}
                      hlsUrl={selectedVideoDetails.hlsUrl}
                      contentType={"Conceptual"}
                      packageId={props.match.params.id}
                      id={selectedVideoDetails.url}
                      randomString={Math.random()}
                      isBookMarked={false}
                      isCompleted={false}
                      getSelectedVideo={() => getSelectedVideo()}
                      setProgressApi={(s) => getProgressApiFlag(s)}
                      progressFlag={progressApiFlag}
                      setShowLicenseExpiredPopUp={setShowLicenseExpiredPopUp}
                    />
                  ) : (
                    <VideoPlayer
                      videoOptions={videoJsOptions}
                      isViewedOnSingleScreen={isViewedOnSingleScreen}
                      packageId={props.match.params.id}
                      id={selectedVideoDetails.url}
                      demoUrl={false}
                      token={token}
                      isvideoopen={false}

                    />
                  ))}
              </div>
              <div className="vc-player-info">
                <div className="vc-player-courseinfo">
                  <span>{openSectionName}</span>
                  <span>{selectedVideoDetails.name}</span>
                </div>
                <div className="vc-player-facultyinfo">
                  <div>
                    <span style={{ fontSize: "12px", paddingRight: "13px" }}>
                      Video Faculty
                    </span>
                    <span style={{ fontSize: "13px", color: "#30a9f0" }}>
                      {selectedVideoDetails.faculty
                        ? selectedVideoDetails.faculty[0].name
                        : ""}
                    </span>
                  </div>
                  <div>
                    {selectedVideoDetails.faculty ? (
                      <img
                        src={selectedVideoDetails.faculty[0].imgUrl}
                        width="40px"
                      ></img>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="vc-content-tab">
              <Tab
                tabItemList={[
                  { id: "videos", label: "Videos" },
                  { id: "description", label: "Details" },
                ]}
                onSelect={setSelectedSection}
                activeId={selectedSection}
                id="video-info"
              ></Tab>
            </div>
            {(selectedSection == undefined ||
              selectedSection == "description") && (
                <div className="vc-description">
                  <VideoDetails videoDetail={selectedVideoDetails}></VideoDetails>
                  <CourseDetails
                    courseDetailsObj={relatedDetails}
                    courseDetails={courseDetails}
                  ></CourseDetails>
                  <ExamCovered courseDetailsObj={relatedDetails}></ExamCovered>
                </div>
              )}
          </div>
        </div>
        <div></div>
        {showLicenseExpiredPopUp && <div className="mock-comman-pop result-pop">
          <div className="mock-comman-pop-content" style={{ minWidth: "200px", padding: "16px" }}>
            <div className="mock-header-popup">
              <div className="mockpopheader-title" style={{ color: "red" }}>Sorry for the interruption</div>
            </div>
            <div className="result-await-timer-title" style={{ color: "black", marginBottom: "15px" }}>We need to reverify the credentials. Please reload to resume playing</div>
            <span style={{ color: "green", cursor: "pointer" }} onClick={() => { setShowLicenseExpiredPopUp(false); rejoin() }}>Reload</span>
          </div>
        </div>
        }
      </div>
    </>
  );
};

export default VideoPackage;

import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../components/library/BreadCrumb";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import ContinueReading from "../components/home/ContinueReading";
import Tab from "../components/library/Tab";
import AButton from "../components/library/AButton";
// import AInput from "../components/library/AInput";
import fetch from "../data/utils/fetch";
import EBookSection from "../components/course/EBookSection";
import TestSeriesSection from "../components/course/TestSeriesSection";
import VideoSection from "../components/course/VideoSection";
import OlcSection from "../components/course/OlcSection";
import AInput from "../components/library/AInput";
import { childPackageDetailUrl } from "../../constants/appConfig";
import NoResultsFound from "../components/library/NoResultsFound";
import CustomLoader from "../components/library/CustomLoader";
import { Cookies } from "react-cookie";
import { Redirect } from "react-router";
import { func } from "prop-types";
import EmptyCart from "../components/cart/EmptyCart";
import { isEmpty, getMoengageMetadata, getPdpUrl, scrollToTop } from "../data/utils/helpers";
import { IFRAME_URI } from "../../constants/appUrls";
const cookie = new Cookies();

// import EbooksSection from "../components/course/EBookSection";
let joinClassTimer = null;
const SubPackage = (props) => {
  // const InputText = useRef(null);
  const CATEGORIES_MAP = {
    TEST_SERIES: "Mock Tests",
    EBOOKS: "E-Books",
    VIDEOS: "Videos",
    ONLINE_LIVE_CLASSES: "Online Live Classes",
  };

  // const [title, setTitle] = useState("");
  const [loading, setloading] = useState(false)
  const [inputText, setInputText] = useState();
  const [explorenow, setExploreNow] = useState(false)
  const [searchText, setSearchText] = useState();
  const [EBookData, setEBookData] = useState();
  const [TestSeriesData, setTestSeriesData] = useState();
  const [VideoData, setVideoData] = useState();
  const [OlcData, setOlcData] = useState();
  const [selectedTab, setSelectedTab] = useState();
  const [packageData, setPackageData] = useState();
  const [suggestionData, setsuggestionData] = useState();
  const [internalTab, setinternalTab] = useState("")
  const [visibility, setVisibility] = useState(true)
  const [groupFilter, setGroupFilter] = useState("");
  const [SectionType, setSectionType] = useState("")



  const moengageEventCategoryTabChange = (type) => {
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'product_type_clicked',
      getMoengageMetadata({
        product_type: type
      }),
    );
  }

  const moengageEventSectionTabChange = (sectionType) => {
    let tabvalue = "";

    if (selectedTab != "ONLINE_LIVE_CLASSES") {
      tabvalue = (sectionType == "Incomplete" || sectionType == "Watching") ? "In progress" :
        (sectionType == "Not Attempted" || sectionType == "Unread" || sectionType == "Not Watched") ? "Not Started yet" :
          (sectionType == "Attempted" || sectionType == "Watched") ? "Complete" : "All"
    }
    else {
      tabvalue = !sectionType ? 'All' : sectionType
    }

    setSectionType(tabvalue)

    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'section_clicked',
      getMoengageMetadata({
        product_type: selectedTab,
        section_type: tabvalue
      }),
    );
  }


  const moengageEventforstatus = (action, subject, chapter, topic) => {
    let payloaddata = {};
    payloaddata.id = props.match.params.packageId
    payloaddata.name = props.match.params.packageName.replace(/-/g, " ")
    payloaddata.package_url = typeof window != undefined && window.location.origin + getPdpUrl(selectedTab, props.match.params.packageId, props.match.params.packageName.replace(/-/g, " "));
    payloaddata.section_type = !SectionType ? "All" : SectionType;
    payloaddata.product_type = selectedTab;
    payloaddata.package_status = "Paid";
    payloaddata.chapter = chapter;
    payloaddata.topic = topic;
    payloaddata.subject = subject
    payloaddata.action = `${action}_btn_clicked`
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'purchased_content_action_taken',
      getMoengageMetadata(payloaddata),
    );
  }

  const getSuggestionData = (searchValue) => {
    var suggestionList = [];
    setInputText(searchValue);

    if (!searchValue) {
      setSearchText(searchValue)
    }
    if (VideoData && selectedTab == "VIDEOS") {
      VideoData && VideoData.subject && VideoData.subject.forEach((sub, index) => {
        sub.ch.forEach((ch, chIndex) => {
          ch.video.forEach((video, VideoIndex) => {
            suggestionList.push(video)
          })

        })
      });
      if (searchValue) {
        suggestionList = suggestionList.filter((item) => {
          return internalTab ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && item.name && item.status.toLowerCase() == internalTab.toLowerCase() : item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && item.name;
        })
      }
      else {
        suggestionList = ""
      }
      setsuggestionData(suggestionList ? suggestionList.slice(0, 9) : "")
    }

    if (OlcData && searchValue && selectedTab == "ONLINE_LIVE_CLASSES") {
      suggestionList = [];
      suggestionList = OlcData
      suggestionList = suggestionList.filter((item => {
        return internalTab ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && item.status.toLowerCase() == internalTab.toLowerCase() : item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      }))
    }


    if (TestSeriesData && searchValue && selectedTab == "TEST_SERIES") {
      suggestionList = []

      TestSeriesData.map((testdata) => {
        if (groupFilter || internalTab) {
          internalTab && !groupFilter && internalTab == testdata.status && suggestionList.push(testdata.testData && testdata.testData[0])
          !internalTab && groupFilter && groupFilter == testdata.groupName && suggestionList.push(testdata.testData && testdata.testData[0])
          internalTab && groupFilter && groupFilter == testdata.groupName && internalTab == testdata.status && suggestionList.push(testdata.testData && testdata.testData[0])
        }
        else {
          suggestionList.push(testdata.testData && testdata.testData[0])
        }


        // if (internalTab) {
        //   if (internalTab == testdata.status) {
        //     suggestionList.push(testdata.testData && testdata.testData[0])

        //   }
        // }
        // else {
        //   suggestionList.push(testdata.testData && testdata.testData[0])
        // }

      })

      suggestionList = suggestionList.filter((item => {
        return item && item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      }))

    }
    if (EBookData && searchValue && selectedTab == "EBOOKS") {
      suggestionList = []
      EBookData.map((item) => {
        let cmgsoon = 'Coming Soon'
        if (groupFilter) {
          if (groupFilter == item.groupName)
            suggestionList.push(item)
        }
        else {
          suggestionList.push(item)
        }

      })
      suggestionList = suggestionList.filter((item => {
        return internalTab ? internalTab == "Unread" ? item && item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && item.status.toLowerCase().indexOf(internalTab.toLowerCase()) > -1 || item.status.toLowerCase().indexOf(cmgsoon.toLowerCase()) > -1 : item && item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 && item.status.toLowerCase().indexOf(internalTab.toLowerCase()) > -1 : item && item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      }))

    }

    setsuggestionData(suggestionList ? suggestionList.slice(0, 10) : "")
  }

  const handleAutosuggestionlists = (value) => {
    setSearchText(value)
    setInputText(value)
    setsuggestionData("")
  }
  const getEbookData = () => {

    setloading(true);
    fetch(
      `${childPackageDetailUrl.ebooks}/${props.match.params.packageId}`,
      "GET"
    )
      .then(
        (res) => {
          if (res.data && res.data.ebooks) {
            setloading(false)
            setEBookData(res.data.ebooks);
          } else {
            setloading(false)
            setEBookData([]);
          }
        },
        (err) => {
          setloading(false)
          setEBookData([]);
        }
      )
      .catch(() => {
        setloading(false)
        setEBookData([]);
      });
  };


  const handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      setSearchText(inputText)
      setsuggestionData("")
    }
  }


  const getPackageInfo = () => {
    fetch(
      `${childPackageDetailUrl.packageInfo}?packageId=${props.match.params.packageId}`,
      "GET"
    )
      .then((res) => {
        if (res.data) {
          setPackageData(res.data);
          setSelectedTab((res.data.subCategories && res.data.subCategories[0]) ? res.data.subCategories[0].category : '');
        }
        else if (!res.success) {
          setExploreNow(true)
          setPackageData(null);
        }

        else {
          setPackageData(null);
        }
      })
      .catch(() => {
        setPackageData(null);
      });
  };
  const getTestSeriesData = () => {
    setloading(true)
    fetch(
      `${childPackageDetailUrl.testseries}/${props.match.params.packageId}`,
      "GET"
    )
      .then(
        (res) => {
          if (res.data && res.data.testSeries) {
            setloading(false)
            // filter the test with testData
            const filteredTestData = res.data.testSeries?.filter(test => test?.testData)
            setTestSeriesData(filteredTestData);
          } else {
            setloading(false)
            setTestSeriesData([]);
          }
        },
        (err) => {
          setloading(false)
          setTestSeriesData([]);
        }
      )
      .catch(() => {
        setloading(false)
        setTestSeriesData([]);
      });
  };
  const getVideoData = () => {
    setloading(true)
    fetch(
      `${childPackageDetailUrl.videos}/${props.match.params.packageId}`,
      "GET"
    )
      .then(
        (res) => {
          if (res.data && res.data.videos) {
            setloading(false)
            setVideoData(res.data.videos);
          } else {
            setloading(false)
            setVideoData([]);
          }
        },
        (err) => {
          setloading(false)
          setVideoData([]);
        }
      )
      .catch(() => {
        setloading(false)
        setVideoData([]);
      });
  };







  const getOlcData = () => {
    setloading(true)
    fetch(`${childPackageDetailUrl.olc}/${props.match.params.packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.onlineClasses) {
            setloading(false)
            var item = res.data.onlineClasses;
            item.sort(function (a, b) {
              return b.startTime - a.startTime;
            });
            setOlcData(item);
            // startTimer(res.data.onlineClasses)
          } else {
            setloading(false)
            setOlcData([]);
          }
        },
        (err) => {
          setloading(false)
          setOlcData([]);
        }
      )
      .catch(() => {
        setloading(false)
        setOlcData([]);
      });
  };
  const goTo = (url) => {
    props.history.push(url);
  };

  useEffect(() => {
    // getEbookData();
    typeof window !== "undefined" && window.scrollTo(0, 400);
    // scrollToTop(30000)

    if (cookie.get("cp_token")) {
      getPackageInfo();
    }
  }, []);

  let renderedCourse;
  switch (selectedTab) {
    case "VIDEOS":
      renderedCourse = (
        <VideoSection
          moengageEventforstatus={moengageEventforstatus}
          moengageEventSectionTabChange={moengageEventSectionTabChange}
          loading={loading}
          setsuggestionData={setsuggestionData}
          setinternalTab={setinternalTab}
          setSearchText={setSearchText}
          setInputText={setInputText}

          data={VideoData}
          searchText={searchText}
          getData={getVideoData}
          goTo={goTo}
          packageId={props.match.params.packageId}
        />
      );
      break;
    case "EBOOKS":
      renderedCourse = (
        <EBookSection
          moengageEventforstatus={moengageEventforstatus}
          moengageEventSectionTabChange={moengageEventSectionTabChange}
          setGroupFilter={setGroupFilter}
          loading={loading}
          setsuggestionData={setsuggestionData}
          setinternalTab={setinternalTab}
          setSearchText={setSearchText}
          setInputText={setInputText}
          data={EBookData}
          searchText={searchText}
          getData={getEbookData}
          packageId={props.match.params.packageId}
        />
      );
      break;
    case "TEST_SERIES":
      renderedCourse = (
        <TestSeriesSection
          moengageEventforstatus={moengageEventforstatus}
          moengageEventSectionTabChange={moengageEventSectionTabChange}
          setGroupFilter={setGroupFilter}
          loading={loading}
          setsuggestionData={setsuggestionData}
          setinternalTab={setinternalTab}
          setSearchText={setSearchText}
          setInputText={setInputText}
          data={TestSeriesData}
          searchText={searchText}
          getData={getTestSeriesData}
          goTo={goTo}
          packageId={props.match.params.packageId}
        />
      );
      break;
    case "ONLINE_LIVE_CLASSES":
      renderedCourse = (
        <OlcSection
          moengageEventforstatus={moengageEventforstatus}
          moengageEventSectionTabChange={moengageEventSectionTabChange}
          loading={loading}
          setsuggestionData={setsuggestionData}
          setinternalTab={setinternalTab}
          setSearchText={setSearchText}
          setInputText={setInputText}
          title= {packageData?.title}
          data={OlcData}
          searchText={searchText}
          getData={getOlcData}
          goTo={goTo}
          history={props.history}
          selectedTab={selectedTab}
        />
      );
      break;
  }
  let BreadCrumbSchemaArray = [];
  let BreadCrumbArray = [];
  if (props.match.params.parentPackageId && props.match.params.packageId) {
    if (props.match.params.parentPackageName !== "package") {
      BreadCrumbSchemaArray.push({
        title: props.match.params.parentPackageName.replace(/-/g, " "),
        url: `/my-purchase/${props.match.params.parentPackageId}/${props.match.params.parentPackageName}`,
      });
      BreadCrumbArray.push({
        name: props.match.params.parentPackageName.replace(/-/g, " "),
        target: `/my-purchase/${props.match.params.parentPackageId}/${props.match.params.parentPackageName}`,
        // target:"",
      });
    }

    BreadCrumbSchemaArray.push({
      title: packageData && packageData.title ? packageData.title : props.match.params.packageName.replace(/-/g, " "),
      // url: `/my-purchase/${props.match.params.packageId}/${props.match.params.packageName}`,
    });

    BreadCrumbArray.push({
      name: packageData && packageData.title ? packageData.title : props.match.params.packageName.replace(/-/g, " "),
      // target: `/my-purchase/${props.match.params.packageId}/${props.match.params.packageName}`,
      target: "",
    });
  }
  else {
  }
  const token = cookie.get("cp_token");
  if (!token) return <Redirect to="/" />;
  return (
    <div
      style={{
        paddingTop: "80px",
      }}
    >
      <div className="sub-package">
        <div className="main-content-area">
          <div className="breadcrumb-section">
            <BreadcrumbSchema
              items={[
                {
                  title: "Home",
                  url: "/",
                },
                {
                  title: "My Purchases",
                  url: `/my-purchase`,
                },
                ...BreadCrumbSchemaArray,
              ]}
            />
            <BreadCrumb
              routeData={[
                { name: "Home", target: "/" },
                {
                  name: "My Purchases",
                  target: `/my-purchase`,
                },
                ...BreadCrumbArray,
              ]}
            />
          </div>
          <div className="sub-package-title">
            <h1 className="h1" title={packageData && packageData.title}>
              {packageData && packageData.title}
            </h1>
          </div>
          <div>
            <ContinueReading
              goTo={goTo}
            />
          </div>
          {packageData === undefined && (
            <div>
              <CustomLoader />
            </div>
          )}
          {packageData === null || packageData && packageData.subCategories.length == 0 && (
            <div>
              <NoResultsFound image={"/images/coming_soon.svg"}
                width="200px"

                message={'Sorry to keep you waiting...'}
                message2={"We are in the process of setting up the content for you. Please check again after some time"} />
            </div>
          )}

          {explorenow && <EmptyCart header={"You have not purchased any product yet."}
            subHeader={"Browse from our comprehensive range of products - Live Classes, Video Classes, Test Series and E-Books."}
            minHeight="100%"
            top="20px"
            exploreVisible={true} />}
          {
            packageData && packageData.subCategories.length >= 1 && (
              <>
                <div className="sub-package-filter">
                  {(OlcData && OlcData.length >= 1 || TestSeriesData && TestSeriesData.length >= 1 || VideoData && VideoData.subject.length >= 1 || EBookData && EBookData.length >= 1) && <div className="sp-filter-tab">
                    <Tab
                      id="sub-package"
                      tabItemList={packageData.subCategories.map((data) => {
                        return {
                          id: data.category,
                          label: `${CATEGORIES_MAP[data.category]} (${
                            data.count
                            })`,
                        };
                      })}
                      onSelect={(tabId) => {
                        setSelectedTab(tabId);
                        moengageEventCategoryTabChange(tabId)
                        setSearchText("")
                        setInputText("")
                        setsuggestionData("")
                        setGroupFilter("")

                      }}
                      activeId={selectedTab}
                    ></Tab>
                  </div>}
                  {(OlcData && OlcData.length >= 1 || TestSeriesData && TestSeriesData.length >= 1 || VideoData && VideoData.subject && VideoData.subject.length >= 1 || EBookData && EBookData.length >= 1) && <div className="sp-filter-search"
                    onMouseLeave={() => setVisibility(false)}
                    onMouseEnter={() => setVisibility(true)}>
                    <div className="a-search-box" >
                      <AInput
                        placeholder="Search by title"
                        value={inputText}
                        onChange={(e) => {
                          getSuggestionData(e.target.value)
                        }}
                        onKeyDown={e => handleEnterPress(e)}
                      />
                      <button
                        onClick={() => {
                          if (inputText && inputText == searchText) {
                            setSearchText("");
                            setInputText("");
                            getSuggestionData("")
                          } else {
                            setsuggestionData("")
                            setSearchText(inputText);
                          }
                        }}
                      >
                        <img
                          src={
                            inputText && inputText == searchText
                              ? "/images/close-icon.svg"
                              : "/images/icon-search.svg"
                          }
                        ></img>{" "}
                      </button>
                    </div>


                    {visibility && suggestionData && suggestionData.length >= 1 &&
                      <div className={'autosuggestion-container'} >
                        {
                          suggestionData.map((e, idx) => (
                            <div className={'autosuggestion-category'} key={idx}>

                              <div className="autosuggestion-item"
                                onClick={() => handleAutosuggestionlists(e.name || e.title)}>{e.name || e.title}</div>

                            </div>
                          ))
                        }
                        <div
                          onClick={() => handleAutosuggestionlists(inputText)}
                          className={'view-all-suggestion'}>View
                                    all Results for {searchText || inputText}</div>
                      </div>
                    }
                  </div>}
                </div>
                <div className="sub-package-content">{renderedCourse}</div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default SubPackage;

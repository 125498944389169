import React from 'react';
import { useHistory } from 'react-router';

const BreadCrumb = (props) => {
    const history = useHistory();
    const BreadCrumbClick = (e) => {
        if (e.target == "/engineering-study-materials") {
            history.push("/civil-engineering-study-materials");
        } else {
            history.push(e.target);
        }
    }

    return (
    <ul>
        {props.routeData.map((e, index) => (
            <li key={e.name}>
                {e.target.length ? (
                    <span onClick={() => BreadCrumbClick(e)}>
                        <a href={e.target.toLowerCase()}>{e.name == "Engineering" ? "Civil-engineering" : e.name}</a>
                    </span>
                ) : (
                    <span>{e.name == "Engineering" ? "Civil-engineering" : e.name}</span>
                )}
                {/* {index !== props.routeData.length - 1 && <span></span>} */}
            </li>
        ))}
    </ul>
    );
};
export default BreadCrumb;
import { pageName } from "./appConfig"

export const RATING = {
    RATING_SKIPPED: 'Rating_skipped',
    REASON_SKIPPED: 'reason_skipped',
    WITHOUT_DETAILED_FEEDBACK: 'WITHOUT_DETAILED_FEEDBACK',
    WITH_DETAILED_FEEDBACK: 'WITH_DETAILED_FEEDBACK',
    RATING_SUB: 'Rating_submitted',
    RATE_THIS_VIDEO: 'Rate this Video'
}

export const DEVICE_IDENTIFIERS = {
    DESKTOP_IDENTIFIER : "d",
    MOBILE_IDENTIFIER : "m",
    TAB_IDENTIFIER : "t"
}

export const COURSE_CATEGORY_IDENTIFIERS = {
    ONLINE_LIVE_CLASSES_IDENTIFIER : "ONLINE_LIVE_CLASSES",
    TEST_SERIES_IDENTIFIER : "TEST_SERIES",
    VIDEOS_IDENTIFIER : "VIDEOS",
    EBOOKS_IDENTIFIER : "EBOOKS",
    FPM_IDENTIFIER : "FPM"
}

export const EBOOKS_COPYRIGHT_MSG = `I understand that the E-book is Proprietary & Copyrighted Material of ${pageName[1]}. Any reproduction in any form, physical or electronic mode on public forum etc will lead to infringement of Copyright of ${pageName[1]} and will attract penal actions including FIR and claim of damages under Indian Copyright Act 1957.`
export const FixedMockConstants = {
    Register: "Register",
    Registered: "Registered",
    ResultPublished: "Result Published",
    ResultAwaited: "Result Awaited",
    AttemptNow: "Attempt Now",
    Resume: "Resume",
    ComingSoon: "COMING SOON"
}
export const TEST = {
    markForReviewTxt: "Mark for review",
    markForReviewAndNextTxt: "Mark for review and next"
}

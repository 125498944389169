export const generalFaqConstants = {
  ONLINE_LIVE_CLASSES: [
    {
      id: "generalFaq1",
      qN: "How to attend Live Classes after purchase?",
      aN: "For the website, check this link - https://youtu.be/nLLBzDJUQVg For the app, check this link - https://youtu.be/kt2Co5qC29A",
    },
    {
      id: "generalFaq2",
      qN: "How to check the study plan?",
      aN: "To check the study plan, open your purchased live batch. If you are looking to make a purchase on the store/website, you can find the study plan in the package description.",
    },
    {
      id: "generalFaq3",
      qN: "How to get class notifications?",
      aN: "To receive class notifications in the Adda247 app, kindly activate app notifications from your device settings. Then, click on the bell icon available in front of every live class. You can select those classes for which you want to receive class notifications.",
    },
    {
      id: "generalFaq4",
      qN: "How to access PDF/Handouts of live class?",
      aN: "Live class PDFs/Handouts are provided in the student account and can be accessed alongside recorded sessions.",
    },
    {
      id: "generalFaq5",
      qN: "What if I miss my live classes?",
      aN: "If you have missed your live class for the day, the recording of the live class will be provided within 0-6 hours after the live session ends.",
    },
  ],
  TEST_SERIES: [
    {
      id: "generalFaq6",
      qN: "Will I get topic wise test / daily quizzes in the package?",
      aN: "Yes, you will get topic tests/daily quizzes as mentioned on the description page.",
    },
    {
      id: "generalFaq7",
      qN: "Can I re-attempt the test?",
      aN: 'Yes, you will get the "Reattempt Test" option on the test analysis page. However, your marks and rank will be provided based on the first attempt only.',
    },
    {
      id: "generalFaq8",
      qN: "How can test series help me?",
      aN: "Online Mock Tests help you analyze your preparation and optimize the performance of candidates. Online Mock Tests are a convenient way of practicing tests from the comfort zone of your home. Online Mock Tests by Adda247 can be accessed from any gadget like mobile, tablet, or laptop.",
    },
    {
      id: "generalFaq9",
      qN: "When will the mock be live in my account?",
      aN: "Every test series package comes with a certain uploading plan, according to which the tests will be uploaded to students' accounts. Please check the description for the uploading plan.",
    },
    {
      id: "generalFaq10",
      qN: "How to access the test series?",
      aN: 'Login to the Adda247 app with your registered email id. Click on "My Content" > Click on Test Series > Open your purchased Test Series > Now click on the "Get Quiz" button (only available in those mocks which are live) > Select the default language and click on "Start Test."',
    },
  ],
  VIDEOS: [
    {
      id: "generalFaq11",
      qN: "How to access video course?",
      aN: 'To watch video courses, log in to the Adda247 app or website (www.adda247.com) with your registered email id. After that, click on "My Content" for the app and "My Purchased Courses" on the website. Click on Video Courses, open your purchased Video Course, and then click on the Watch button.',
    },
    {
      id: "generalFaq12",
      qN: "How is a video course different from a live class?",
      aN: "In video courses, we provide pre-recorded topic-wise classes, whereas in live classes, students need to join the live session as per the given schedule.",
    },
    {
      id: "generalFaq13",
      qN: "Can I download videos?",
      aN: "We allow you to save videos offline in different available qualities (App only). To change the video quality on the app, click on the video you want to play and then click on video settings to change the quality.",
    },
    {
      id: "generalFaq14",
      qN: "Is the video course updated with the latest content?",
      aN: "Yes, our video courses are up to date with the latest exam content and patterns.",
    },
    {
      id: "generalFaq15",
      qN: "Can I access videos after the subscription expires?",
      aN: "When your subscription expires, you will no longer have access to your courses. However, you can renew your subscription by purchasing it again.",
    },
  ],
  EBOOKS: [
    {
      id: "generalFaq16",
      qN: "Can I download or print Ebook?",
      aN: "E-books cannot be downloaded or available for print as they are in a protected file format that can only be viewed with internet connectivity.",
    },
    {
      id: "generalFaq17",
      qN: "How can I check/know the language of the ebook?",
      aN: "In the description of the ebook, the language is mentioned.",
    },
    {
      id: "generalFaq18",
      qN: "How to access ebooks?",
      aN: 'Login to the Adda247 app with your registered email id. Click on "My Content" > Click on the Ebooks button > Open your purchased Ebook > Now click on the Download button (only available in those ebooks which are live) > Click OKAY on the disclaimer shown to view ebooks.',
    },
    {
      id: "generalFaq19",
      qN: "Will I get ebooks immediately after purchase?",
      aN: "Every Ebooks package comes with a certain uploading plan, according to which the Ebook will be uploaded to the student's account. Please check the description for the uploading plan.",
    },
  ],
  BOOKS: [
    {
      id: "generalFaq20",
      qN: "In how many days my book will be deivered?",
      aN: "Your book will be delivered within 8-10 working days from the date of dispatch.",
    },
    {
      id: "generalFaq21",
      qN: "Is cash on delivery available on books?",
      aN: "As of now we do not have a cash on delivery (COD) option.",
    },
    {
      id: "generalFaq22",
      qN: "How will I track my book order?",
      aN: 'You can track your order via app in "My order" tab.Click on "My Orders" Click on order you want to track And, there you can track your order.',
    },
    {
      id: "generalFaq23",
      qN: "Can I change my purchased book?",
      aN: "Books can only be changed within 24 hours of purchase or until the book is not dispatched from our side. For this process, we suggest you contact us at www.adda247.com/support with the name of your desired book. For the process, you may have to pay the difference amount if any.",
    },
    {
      id: "generalFaq24",
      qN: "Can I return my purchased book?",
      aN: "No, books cannot be returned after successful delivery. However, you can change the book within 24 hours of purchase.",
    },
  ],
  MODELS_3D: [],
};

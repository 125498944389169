import React, { useState, useEffect } from "react";
import Tab from "../../../components/library/Tab";
import Models3DSection from "../../../components/course/Models3DSection";
import EBookSection from "../../../components/course/EBookSection";
import TestSeriesSection from "../../../components/course/TestSeriesSection";
import VideoSection from "../../../components/course/VideoSection";
import OlcSection from "../../../components/course/OlcSection";
import AInput from "../../../components/library/AInput";
import NoResultsFound from "../../../components/library/NoResultsFound";
import CustomLoader from "../../../components/library/CustomLoader";
import { Cookies } from "react-cookie";
import { Redirect, useLocation, useParams } from "react-router";
import EmptyCart from "../../../components/cart/EmptyCart";
import {
  capitalizeWords,
  getDeviceType,
  getMoengageMetadata,
  getPdpUrl,
  isADDA,
  makeUrl,
  removeHyphensAndInsertSpaces,
  scrollToTop,
} from "../../../data/utils/helpers";
import fetch from "../../../data/utils/fetch";
import { childPackageDetailUrl } from "../../../../constants/appConfig";
import PPCWrapper from "../PPCWrapper";
import { removePredefinedString } from "../../../components/utils";
import ErrorBoundary from "../../../components/ErrorBoundary";
import ContineInApp from "../../../components/library/ContinueInApp/ContinueInApp";
import { DEVICE_IDENTIFIERS } from "../../../../constants/textConstants";
const cookie = new Cookies();

const PPCSubPackageView = (props) => {
  const CATEGORIES_MAP = {
    TEST_SERIES: liveTest ? "Live Tests" : "Mock Tests",
    EBOOKS: "E-Books",
    VIDEOS: "Videos",
    ONLINE_LIVE_CLASSES: "Online Live Classes",
    MODELS_3D: "3D Learning"
  };

  const [loading, setloading] = useState(false);
  const [inputText, setInputText] = useState();
  const [explorenow, setExploreNow] = useState(false);
  const [searchText, setSearchText] = useState();
  const [EBookData, setEBookData] = useState();
  const [ModelData, setModelData] = useState();
  const [TestSeriesData, setTestSeriesData] = useState();
  const [VideoData, setVideoData] = useState();
  const [OlcData, setOlcData] = useState();
  const [selectedTab, setSelectedTab] = useState("");
  const [packageData, setPackageData] = useState();
  const [suggestionData, setsuggestionData] = useState();
  const [internalTab, setinternalTab] = useState("");
  const [visibility, setVisibility] = useState(true);
  const [groupFilter, setGroupFilter] = useState("");
  const [SectionType, setSectionType] = useState("");
  const [clickedItemData, setClickedItemData] = useState();
  const [liveTest, setLiveTest] = useState(false);
  const {
    parentPackageId = "",
    packageId = "",
    parentPackageName = "",
    packageName = "",
    examId = "",
    examName = "",
  } = useParams();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const category = query.get("category");
  const subParentId = query.get("subParentId");
  const subParentTitle = query.get("subParentTitle");
  const isUniqueExam = query.get("isUniqueExam");

  const isPaidSearchRedirect = query.get("redirectFrom") === "paidSearch";

  const moengageEventCategoryTabChange = (type) => {
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "product_type_clicked",
        getMoengageMetadata({
          product_type: type,
        })
      );
  };

  const moengageEventSectionTabChange = (sectionType) => {
    let tabvalue = "";

    if (selectedTab != "ONLINE_LIVE_CLASSES") {
      tabvalue =
        sectionType == "Incomplete" || sectionType == "Watching"
          ? "In progress"
          : sectionType == "Not Attempted" ||
            sectionType == "Unread" ||
            sectionType == "Not Watched"
          ? "Not Started yet"
          : sectionType == "Attempted" || sectionType == "Watched"
          ? "Complete"
          : "All";
    } else {
      tabvalue = !sectionType ? "All" : sectionType;
    }

    setSectionType(tabvalue);

    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "section_clicked",
        getMoengageMetadata({
          product_type: selectedTab,
          section_type: tabvalue,
        })
      );
  };

  const moengageEventforstatus = (action, subject, chapter, topic) => {
    let payloaddata = {};
    payloaddata.id = packageId;
    payloaddata.name = packageName.replace(/-/g, " ");
    payloaddata.package_url =
      typeof window != undefined &&
      window.location.origin +
        getPdpUrl(selectedTab, packageId, packageName.replace(/-/g, " "));
    payloaddata.section_type = !SectionType ? "All" : SectionType;
    payloaddata.product_type = selectedTab;
    payloaddata.package_status = "Paid";
    payloaddata.chapter = chapter;
    payloaddata.topic = topic;
    payloaddata.subject = subject;
    payloaddata.action = `${action}_btn_clicked`;
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "purchased_content_action_taken",
        getMoengageMetadata(payloaddata)
      );
  };

  const getSuggestionData = (searchValue) => {
    var suggestionList = [];
    setInputText(searchValue);

    if (!searchValue) {
      setSearchText(searchValue);
    }
    if (selectedTab === "VIDEOS" && Object?.keys(VideoData)?.length) {
      VideoData?.subject?.forEach((sub, index) => {
        sub?.ch?.forEach((ch, chIndex) => {
          ch?.video?.forEach((video, VideoIndex) => {
            suggestionList.push(video);
          });
        });
      });
      if (searchValue) {
        suggestionList = suggestionList?.filter((item) => {
          return internalTab
            ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 &&
                item.name &&
                item.status.toLowerCase() == internalTab.toLowerCase()
            : item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 &&
                item.name;
        });
      } else {
        suggestionList = "";
      }
      setsuggestionData(suggestionList ? suggestionList.slice(0, 9) : "");
    }

    if (selectedTab === "ONLINE_LIVE_CLASSES" && OlcData && searchValue) {
      suggestionList = [];
      suggestionList = OlcData;
      suggestionList = suggestionList.filter((item) => {
        return internalTab
          ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 &&
              item.status.toLowerCase() == internalTab.toLowerCase()
          : item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });
    }

    if (selectedTab === "TEST_SERIES" && TestSeriesData && searchValue) {
      suggestionList = [];

      TestSeriesData.map((testdata) => {
        if (groupFilter || internalTab) {
          internalTab &&
            !groupFilter &&
            internalTab == testdata.status &&
            suggestionList.push(testdata.testData && testdata.testData[0]);
          !internalTab &&
            groupFilter &&
            groupFilter == testdata.groupName &&
            suggestionList.push(testdata.testData && testdata.testData[0]);
          internalTab &&
            groupFilter &&
            groupFilter == testdata.groupName &&
            internalTab == testdata.status &&
            suggestionList.push(testdata.testData && testdata.testData[0]);
        } else {
          suggestionList.push(testdata.testData && testdata.testData[0]);
        }
      });

      suggestionList = suggestionList.filter((item) => {
        return (
          item &&
          item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        );
      });
    }
    if (selectedTab === "EBOOKS" && EBookData && searchValue) {
      suggestionList = [];
      EBookData.map((item) => {
        let cmgsoon = "Coming Soon";
        if (groupFilter) {
          if (groupFilter == item.groupName) suggestionList.push(item);
        } else {
          suggestionList.push(item);
        }
      });
      suggestionList = suggestionList.filter((item) => {
        return internalTab
          ? internalTab == "Unread"
            ? (item &&
                item.title.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1 &&
                item.status.toLowerCase().indexOf(internalTab.toLowerCase()) >
                  -1) ||
              item.status.toLowerCase().indexOf(cmgsoon.toLowerCase()) > -1
            : item &&
              item.title.toLowerCase().indexOf(searchValue.toLowerCase()) >
                -1 &&
              item.status.toLowerCase().indexOf(internalTab.toLowerCase()) > -1
          : item &&
              item.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });
    }

    setsuggestionData(suggestionList ? suggestionList.slice(0, 10) : "");
  };

  const handleAutosuggestionlists = (value) => {
    setSearchText(value);
    setInputText(value);
    setsuggestionData("");
  };
  const getEbookData = () => {
    setloading(true);
    fetch(`${childPackageDetailUrl.ebooks}/${packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.ebooks) {
            setloading(false);
            setEBookData(res.data.ebooks);
          } else {
            setloading(false);
            setEBookData([]);
          }
        },
        (err) => {
          setloading(false);
          setEBookData([]);
        }
      )
      .catch(() => {
        setloading(false);
        setEBookData([]);
      });
  };
  const getModelsData = () => {
    setloading(true);
    fetch(`${childPackageDetailUrl.models3d}/${packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.models3DData) {
            setloading(false);
            setModelData(res?.data?.models3DData);
          } else {
            setloading(false);
            setModelData([]);
          }
        },
        (err) => {
          setloading(false);
          setModelData([]);
        }
      )
      .catch(() => {
        setloading(false);
        setModelData([]);
      });
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      setSearchText(inputText);
      setsuggestionData("");
    }
  };

  const getPackageInfo = () => {
    fetch(`${childPackageDetailUrl.packageInfo}?packageId=${packageId}`, "GET")
      .then((res) => {
        if (res.data) {
          setPackageData(res.data);
          setSelectedTab(category || res?.data?.subCategories?.[0]?.category || "");
        } else if (!res.success) {
          setExploreNow(true);
          setPackageData(null);
        } else {
          setPackageData(null);
        }
      })
      .catch(() => {
        setPackageData(null);
      });
  };
  const getTestSeriesData = () => {
    setloading(true);
    fetch(`${childPackageDetailUrl.testseries}/${packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.testSeries) {
            setloading(false);
            // filter the test with testData
            setLiveTest(res.data?.liveTest || false);
            const filteredTestData = res.data.testSeries?.filter(
              (test) => test?.testData
            );
            setTestSeriesData(filteredTestData);
          } else {
            setloading(false);
            setTestSeriesData([]);
          }
        },
        (err) => {
          setloading(false);
          setTestSeriesData([]);
        }
      )
      .catch(() => {
        setloading(false);
        setTestSeriesData([]);
      });
  };
  const getVideoData = () => {
    setloading(true);
    fetch(`${childPackageDetailUrl.videos}/${packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.videos) {
            setloading(false);
            setVideoData(res.data.videos);
          } else {
            setloading(false);
            setVideoData({});
          }
        },
        (err) => {
          setloading(false);
          setVideoData({});
        }
      )
      .catch(() => {
        setloading(false);
        setVideoData({});
      });
  };

  const getOlcData = () => {
    setloading(true);
    fetch(`${childPackageDetailUrl.olc}/${packageId}`, "GET")
      .then(
        (res) => {
          if (res.data && res.data.onlineClasses) {
            setloading(false);
            var item = res.data.onlineClasses;
            item.sort(function (a, b) {
              return b.startTime - a.startTime;
            });
            setOlcData(item);
          } else {
            setloading(false);
            setOlcData([]);
          }
        },
        (err) => {
          setloading(false);
          setOlcData([]);
        }
      )
      .catch(() => {
        setloading(false);
        setOlcData([]);
      });
  };
  const goTo = (url) => {
    props.history.push(url);
  };
  const routeChange = () => {
    // here reload the page on route change,to load fresh content.
    const query = new URLSearchParams(window?.location?.search);
    const isPaidSearchRedirect = query?.get("redirectFrom") === "paidSearch";
    if(isPaidSearchRedirect){
      window?.location?.reload();
    }
  };
  useEffect(() => {
    scrollToTop(0);
    if (cookie.get("cp_token")) {
      getPackageInfo();
    }
    //clear listener when route changes
    if (isPaidSearchRedirect) {
      const historyListener = props.history.listen(routeChange);
      return () => {
        removeEventListener("listen", historyListener);
      };
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const isPaidSearchRedirect = query?.get("redirectFrom") === "paidSearch";

    if(isPaidSearchRedirect){
      sessionStorage?.setItem("showPaidContentSearch", "true");
    }
  },[])
   useEffect(() => {
    if(packageData && selectedTab && getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER){
      setClickedItemData({
        packageId: packageData?.id,
        type: selectedTab
      });
    }
   }, [packageData, selectedTab]);

  const renderedCourse = {
    VIDEOS: (
      <VideoSection
        moengageEventforstatus={moengageEventforstatus}
        moengageEventSectionTabChange={moengageEventSectionTabChange}
        loading={loading}
        setsuggestionData={setsuggestionData}
        setinternalTab={setinternalTab}
        setSearchText={setSearchText}
        setInputText={setInputText}
        data={VideoData}
        searchText={searchText}
        getData={getVideoData}
        goTo={goTo}
        packageId={packageId}
      />
    ),
    MODELS_3D: (
      <Models3DSection
        moengageEventforstatus={moengageEventforstatus}
        moengageEventSectionTabChange={moengageEventSectionTabChange}
        setGroupFilter={setGroupFilter}
        loading={loading}
        setsuggestionData={setsuggestionData}
        setinternalTab={setinternalTab}
        setSearchText={setSearchText}
        setInputText={setInputText}
        data={ModelData}
        searchText={searchText}
        getData={getModelsData}
        packageId={packageId}
        packageData={packageData}
      />
    ),
    EBOOKS: (
      <EBookSection
        moengageEventforstatus={moengageEventforstatus}
        moengageEventSectionTabChange={moengageEventSectionTabChange}
        setGroupFilter={setGroupFilter}
        loading={loading}
        setsuggestionData={setsuggestionData}
        setinternalTab={setinternalTab}
        setSearchText={setSearchText}
        setInputText={setInputText}
        data={EBookData}
        searchText={searchText}
        getData={getEbookData}
        packageId={packageId}
      />
    ),
    TEST_SERIES: (
      <TestSeriesSection
        moengageEventforstatus={moengageEventforstatus}
        moengageEventSectionTabChange={moengageEventSectionTabChange}
        setGroupFilter={setGroupFilter}
        loading={loading}
        setsuggestionData={setsuggestionData}
        setinternalTab={setinternalTab}
        setSearchText={setSearchText}
        setInputText={setInputText}
        data={TestSeriesData}
        searchText={searchText}
        getData={getTestSeriesData}
        goTo={goTo}
        packageId={packageId}
        liveTest={liveTest}
      />
    ),
    ONLINE_LIVE_CLASSES: (
      <OlcSection
        moengageEventforstatus={moengageEventforstatus}
        moengageEventSectionTabChange={moengageEventSectionTabChange}
        loading={loading}
        setsuggestionData={setsuggestionData}
        setinternalTab={setinternalTab}
        setSearchText={setSearchText}
        setInputText={setInputText}
        data={OlcData}
        title = {packageData?.title}
        searchText={searchText}
        getData={getOlcData}
        goTo={goTo}
        selectedTab={selectedTab}
      />
    ),
  };
  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
  ];
  if (parseInt(parentPackageId) !== 0) {
    breadCrumbsList.push({
      route: `/my-purchase/${parentPackageId}/${makeUrl(parentPackageName)}`,
      name: capitalizeWords(removeHyphensAndInsertSpaces(parentPackageName)),
    });
  }
  // modified the breadCrumbsList in exams sublevel routes.
  if (subParentId) {
    if (examId && examName) {
      breadCrumbsList.push({
        route: `/my-purchase/${parentPackageId}/${makeUrl(
          parentPackageName
        )}/exams/${examId}/${examName}/options`,
        name: capitalizeWords(removeHyphensAndInsertSpaces(examName)),
      });
      breadCrumbsList.push({
        route: `/my-purchase/${parentPackageId}/${makeUrl(
          parentPackageName
        )}/exams/${examId}/${examName}/allBatches`,
        name: capitalizeWords(
          removeHyphensAndInsertSpaces(examName + " Batches")
        ),
      });
      breadCrumbsList.push({
        route: `/my-purchase/${parentPackageId}/${makeUrl(
          parentPackageName
        )}/exams/${examId}/${examName}/${subParentId}/${subParentTitle}/batch`,
        name: capitalizeWords(removeHyphensAndInsertSpaces(subParentTitle)),
      });
    }
  } else {
    if (examId && examName) {
      breadCrumbsList.push({
        route: removePredefinedString(pathname, `/${packageId}`),
        name: capitalizeWords(removeHyphensAndInsertSpaces(examName)),
      });
    }
  }
  if(!isADDA && isUniqueExam){
    const arr = pathname?.split("/");
    arr?.splice(arr?.length-2, 2);
    const allBatchesPathname = arr?.join("/") + `/allBatches`;
    breadCrumbsList = [
      {
        route: "/my-purchase",
        name: "My Purchase",
      },
      {
        route: allBatchesPathname,
        name: capitalizeWords(removeHyphensAndInsertSpaces(parentPackageName))
      },
      {
        route: `/my-purchase/${parentPackageId}/${makeUrl(
          parentPackageName
        )}/exams/${examId}/${examName}/${subParentId}/${subParentTitle}/batch`,
        name: capitalizeWords(removeHyphensAndInsertSpaces(subParentTitle)),
      },
    ]
  }
  breadCrumbsList.push({
    route: pathname,
    name: packageData?.title
      ? packageData?.title
      : capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
  });
  const token = cookie.get("cp_token");
  if (!token) return <Redirect to="/" />;
  let accessState = {
    ONLINE_LIVE_CLASSES: OlcData,
    VIDEOS: VideoData?.subject || [],
    EBOOKS: EBookData,
    TEST_SERIES: TestSeriesData,
  };

  return (
    <>
    <PPCWrapper
      breadCrumbRoutes={
        !isPaidSearchRedirect
          ? accessState[selectedTab]?.length
            ? breadCrumbsList
            : [
                //show two level route when no data availaible
                {
                  route: "/my-purchase",
                  name: "My Purchase",
                },
                {
                  route: pathname,
                  name: packageData?.title
                    ? packageData?.title
                    : capitalizeWords(
                        removeHyphensAndInsertSpaces(packageName)
                      ),
                },
              ]
          : []
      }
      pageTitle={
        packageData?.title
          ? packageData?.title
          : capitalizeWords(removeHyphensAndInsertSpaces(packageName))
      }
    >
      <div className="sub-package">
        <div className="main-content-area">
          {packageData === undefined && (
            <div>
              <CustomLoader />
            </div>
          )}
          {packageData?.subCategories?.length == 0 && (
            <div>
              <NoResultsFound
                image={"/images/coming_soon.svg"}
                width="200px"
                message={"Sorry to keep you waiting..."}
                message2={
                  "We are in the process of setting up the content for you. Please check again after some time"
                }
              />
            </div>
          )}
          {explorenow && (
            <EmptyCart
              header={"You have not purchased any product yet."}
              subHeader={
                "Browse from our comprehensive range of products - Live Classes, Video Classes, Test Series and E-Books."
              }
              minHeight="100%"
              top="20px"
              exploreVisible={true}
            />
          )}
          {packageData?.subCategories?.length >= 1 && (
            <>
              <div className="sub-package-filter">
                {(OlcData?.length >= 1 ||
                  TestSeriesData?.length >= 1 ||
                  VideoData?.subject?.length >= 1 ||
                  EBookData?.length >= 1) && (
                  <div className="sp-filter-tab">
                    <Tab
                      id="sub-package"
                      tabItemList={packageData.subCategories.map((data) => {
                        return {
                          id: data.category,
                          label: `${data?.category === "TEST_SERIES" && liveTest ? "Live Tests" : CATEGORIES_MAP[data.category]} (${
                            data.count
                          })`,
                        };
                      })}
                      onSelect={(tabId) => {
                        setSelectedTab(tabId);
                        moengageEventCategoryTabChange(tabId);
                        setSearchText("");
                        setInputText("");
                        setsuggestionData("");
                        setGroupFilter("");
                      }}
                      activeId={selectedTab}
                    ></Tab>
                  </div>
                )}
                {(OlcData?.length >= 1 ||
                  TestSeriesData?.length >= 1 ||
                  VideoData?.subject?.length >= 1 ||
                  EBookData?.length >= 1) && (
                  <div
                    className="sp-filter-search"
                    onMouseLeave={() => setVisibility(false)}
                    onMouseEnter={() => setVisibility(true)}
                  >
                    <div className="a-search-box">
                      <AInput
                        placeholder="Search by title"
                        value={inputText}
                        onChange={(e) => {
                          getSuggestionData(e.target.value);
                        }}
                        onKeyDown={(e) => handleEnterPress(e)}
                      />
                      <button
                        onClick={() => {
                          if (inputText && inputText == searchText) {
                            setSearchText("");
                            setInputText("");
                            getSuggestionData("");
                          } else {
                            setsuggestionData("");
                            setSearchText(inputText);
                          }
                        }}
                      >
                        <img
                          src={
                            inputText && inputText == searchText
                              ? "/images/close-icon.svg"
                              : "/images/icon-search.svg"
                          }
                        ></img>{" "}
                      </button>
                    </div>

                    {visibility &&
                      suggestionData &&
                      suggestionData.length >= 1 && (
                        <div className={"autosuggestion-container"}>
                          {suggestionData.map((e, idx) => (
                            <div
                              className={"autosuggestion-category"}
                              key={idx}
                            >
                              <div
                                className="autosuggestion-item"
                                onClick={() =>
                                  handleAutosuggestionlists(e.name || e.title)
                                }
                              >
                                {e.name || e.title}
                              </div>
                            </div>
                          ))}
                          <div
                            onClick={() => handleAutosuggestionlists(inputText)}
                            className={"view-all-suggestion"}
                          >
                            View all Results for {searchText || inputText}
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>
              <div className="sub-package-content">
                <ErrorBoundary>
                  {renderedCourse[selectedTab] ? (
                    renderedCourse[selectedTab]
                  ) : (
                    <></>
                  )}
                </ErrorBoundary>
              </div>
            </>
          )}
        </div>
      </div>
    </PPCWrapper>
    <ContineInApp
      show={!!clickedItemData}
      packageId={clickedItemData && clickedItemData?.packageId}
      parentpackageId={clickedItemData && clickedItemData?.parentPackageId}
      type={clickedItemData && clickedItemData?.type}
      close={() => {
        setClickedItemData();
        props?.history?.goBack();
      }}
      isContinuesection={true}
    />
  </>
  );
};

export default PPCSubPackageView;

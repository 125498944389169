import reducerRegistry from "../../reducerRegistry";
import types from "./types";
function productDetails(state = {}, action) {
  switch (action.type) {
    case types.GET_PACKAGE_DETAILS:
      return {
        ...state,
        data: action.payload.data
      };
      case types.GET_PACKAGE_OFFERING:
        return {
          ...state,
          packageOfferingData: action.payload.data
        };
      
    case types.CLEAR_PACKAGE_DETAILS_DATA: {
      state = {};
      return {
        ...state
      };
    }
    case types.UPDATE_USER_ADDRESS:{
      return {
        ...state,
        userAddress:action.payload.data
      }
    }
    case types.PACKAGE_MAHAPACK:{
      return {
        ...state,
        mahapack:action.payload.data
      }
    }
    default:
      return { ...state };
  }
}

reducerRegistry.register("productDetails", productDetails);
export default productDetails;

import React from 'react';
import { connect } from 'react-redux';
import Toast from './Toast';
import { removeToast } from '../../data/ducks/toast/actions';

const ToastContainer = ({ toasts, removeToast }) => {
  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          variant={toast.variant}
          timeout={toast.timeout}
          btnEnabled={toast.btnEnabled}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  toasts: state.toast.toasts,
});

const mapDispatchToProps = {
  removeToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer);

import React from 'react';
import {capitalizedString, replaceUnderscoreWithSpace} from '../../data/utils/helpers';
import { pageName } from '../../../constants/appConfig';

const FilterTags = (props) => {
    function closeTag(e) {
        props.removeTag(e);
    }

    const {tagArr, removeTag} = props;
    return tagArr.length && tagArr.map(item => item.toLowerCase() === 'models_3d' ? '3D Learning' : item ).map(item =>
        <div
            data-testid="filter-tags"
            key={item}
            className={'filter-tag'}
            onClick={() => closeTag(item)}
        >
            <img className={'tag-close-icon'} src={'/images/close-icon.svg'}   alt={pageName[0]}  title={pageName[0]} />
            <span style={{'paddingRight': '8px'}}>{capitalizedString(replaceUnderscoreWithSpace(item))}</span>
        </div>);
};
export default FilterTags;
import React from "react";

const Accordion = ({ label, children }) => {
  return (
    <div>
      <div className="adda-accordion-tab">
        <input
          className="adda-accordion"
          type="checkbox"
          id={`${label}_chck2`}
        />
        <label className="adda-accordion-tab-label" htmlFor={`${label}_chck2`}>
          {label}
        </label>
        <div className="adda-accordion-tab-content">
          <div className="adda-accordion-tab-content-wrapper">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;

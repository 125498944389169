import React, { useEffect, useState } from "react";
import CustomLoader from "../library/CustomLoader";
import { default as Link } from "./../../containers/ConnectedLink";

const NoCourse = ({ info, buttonText, url, cacheFlag, noDataText, noBtn }) => {
  const [noDataFoundText, setNoDataFoundText] = useState(noDataText || `You have not purchased any videos yet`);
  useEffect(() => {
    setNoDataFoundText(noDataText);
  },[noDataText])
  return (


    <div className='empty-cart search-bar-no-result'>
      {cacheFlag ? <CustomLoader /> : <>
        <div className="ec-image">
          <img src="/images/no-result-found.png" width="300" className={'video-not-found'} />
          <div className="ec-header" >{noDataFoundText ? noDataFoundText : info}</div>
        </div>

        <div style={{ padding: "8px" }}
        //   onClick={() => {
        //   this.props.history.push(url);
        // }}
        >
          <Link to={url}>
            {!noBtn && <button className='sf-button-secondary'> {buttonText || 'BUY VIDEO COURSES'}</button>}
          </Link>
        </div>
      </>}

    </div>
  );
};

export default NoCourse;

// this expects that  VideoPlayer is already installed

import React from 'react';
import fetch from '../../../data/utils/fetch';
import { videoPackageUrl } from '../../../../constants/appConfig';
import { getMoengageMetadata } from '../../../data/utils/helpers';
let videoPlayedTime = 0;
var videoPlayedTimeUpdatedOnServer, startTime, endTime;
var action;
import { isIosSafari } from '../../../data/utils/helpers';
import WatermarkElement from '../WatermarkElement';
// import CookieEnableModal from '../CookieEnableModal';
export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.updateVideoStatus = this.updateVideoStatus.bind(this);
    this.moengageEvetTriggred = this.moengageEvetTriggred.bind(this)
    this.state = {
      id: '',
      currentTime: 0,
      videoPercentage: 10,
    }
    this.iconRef = React.createRef();
    // this state is just to call the re-render .......could have used lifecycle
  }
  componentDidMount() {
    // instantiate Video.js
    if (typeof (videojs) != "undefined" && videojs && this.props.token && !this.props.demoUrl) {
      this.playVideo();
    }
    else {
      this.playDemoUrl();
    }

    if (this.props.getVideoRatingStatus) {
      this.props.getVideoRatingStatus()
      this.props?.setShowRatingModalAllowed(true)
    }

    setTimeout(() => {
      let videoContainer = document.querySelector('.video-js');
      videoContainer.setAttribute('id','vid-cont')
    }, 0); // because videojs takes time to initialize
  }

  moengageEvetTriggred(eventName) {
    var value = this.props.selectedVideoDetails
    var payload = this.props.moengageData;
    payload.contentTitle = value?.name || "";
    payload.startTime = startTime;
    payload.endTime = videoPlayedTime;
    payload.action = "video_watched";
    payload.state = action;
    payload.contentID = this.props?.id;
    payload.videoPlayDuration = eventName ? Math.abs((videoPlayedTime - startTime)) : "";
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'video_engaged',
      getMoengageMetadata(
        payload
      ),
    );
  }

  updateVideoStatus = () => {
    this.player.on('ended', () => {
      let payload = [{ "pid": this.props.packageId, "vid": this.props.id, "wt": Math.ceil(this.player.cache_.currentTime) * 1000, "ts": Date.now() }]

      if (!this.props?.isOlc) {
        fetch(videoPackageUrl.videoStatus, 'POST', payload);
      }
    })
    this.player.on('timeupdate', () => {
      if (this.props.setTotalVideoDuration && this.props.totalVideoDuration <= 0) {
        this.props.setTotalVideoDuration(this.player.cache_.duration)
      }

      if (this.player.cache_.currentTime == 0) {
        action = "video_first_start";
        this.moengageEvetTriggred();
      }
      if (this.player.cache_.currentTime - this.state.currentTime >= 10) {
        // here st is screen time
        let payload = [{ "pid": this.props.packageId, "vid": this.props.id, "wt": parseInt(this.player.cache_.currentTime) * 1000, "ts": Date.now(), st: 10000 }]
        this.state.currentTime = this.player.cache_.currentTime;
        if (!this.props?.isOlc) {
          fetch(videoPackageUrl.videoStatus, 'POST', payload)
        }
      }
      //Moengage event update
      var progress = (this.player.cache_.currentTime / this.player.cache_.duration) * 100;
      var progressTick = Math.floor(progress / 10);
      if (progressTick > 0 && this.props.isVideosection && progress >= 10) {
        if ((progressTick * 10) == this.state.videoPercentage) {
          action = 'Reached ' + (10 * progressTick) + '%';
          this.moengageEvetTriggred();
          let videoPercentage = this.state.videoPercentage;
          videoPercentage = videoPercentage + 10;
          this.setState({ videoPercentage: videoPercentage })
        }
      }
      videoPlayedTime = this.player.cache_.currentTime;
    })

    if (this.props.isVideosection) {
      this.player.on("play", () => {
        startTime = this.player.cache_.currentTime;
        startTime = startTime;
        action = 'Start playing';
        this.moengageEvetTriggred();
      });
      this.player.on("seeking", () => {
        action = 'seek';
        this.moengageEvetTriggred();
        startTime = this.player.cache_.currentTime;
        let progress1 = (this.player.cache_.currentTime / this.player.cache_.duration) * 100;
        let progressTick1 = Math.floor(progress1 / 10);
        if (progress1 < 10 || progress1 >= 100) {
          this.setState({ videoPercentage: 10 });
        }
        else if (progress1 < 100) {
          let videovalue = progressTick1 * 10 + 10;
          this.setState({ videoPercentage: videovalue })
        }
      })
      this.player.on("pause", () => {
        action = 'Pause';
        this.moengageEvetTriggred("pause");
      });
      this.player.on("canplaythrough", () => {
        action = "buffering";
        this.moengageEvetTriggred();
      })
      this.player.on("ended", () => {
        action = "video_finished";
        this.moengageEvetTriggred();
      })
      this.player.on("error", () => {
        action = "video_played";
        this.moengageEvetTriggred();
      })
    }

    //this.iconRef.current.style.display = "none";    
    this.player.on("waiting", function () {
      this.addClass("vjs-custom-waiting");
    });
    this.player.on("playing", function () {
      this.removeClass("vjs-custom-waiting");
    });


    // Rating Section 

    if (this.props.isRatingEnable && this.props.canUserRate) {
      this.player.on("pause", () => {
        if (this.props.handleRatingAction) {
          // here handleRatingAction will return totalWatchTime asynchronously and show popup after that.
          this.props.handleRatingAction().then(res => {
            if (res) {
              if ((res / 1000) > (this.player.cache_.duration / 2)) { // time in sec
                this.props.setIsRatingVideosModalVisible(true)
                this.props.getCurrentVideoWt(this.player.cache_.currentTime)
              }
            }
          })
        }
      });

      this.player.on('ended', () => {
        if (this.props.handleRatingAction) {
          // here handleRatingAction will return totalWatchTime asynchronously and show popup after that.
          this.props.handleRatingAction().then(res => {
            if (res) {
              if ((res / 1000) > (this.player.cache_.duration / 2)) { // time in sec
                this.props.setIsRatingVideosModalVisible(true)
                this.props.getCurrentVideoWt(this.player.cache_.currentTime)
              }
            }
          })
        }
      })
    }
  }

  playVideo = () => {
    if (!this.props?.isOlc) {
      fetch(`${videoPackageUrl.videoStatus}?packageId=${this.props.packageId}&videoId=${this.props.id}`).then(
        (res) => {
          let startTime = 0;
          if (res.data && res.data[0]) {
            startTime = res.data[0].wt > 1 ? res.data[0].wt / 1000 : res.data[0].wt || 0;
          }
          this.state.currentTime = startTime;
          this.player = videojs(this.videoNode, this.props.videoOptions, function onPlayerReady() {
            this.player_.on('loadedmetadata', function () {
              this.player_.currentTime(startTime);
            });
            this.player_.play()
            this.bigPlayButton.show();
          });
          this.updateVideoStatus();
        }
      )
    } else {
      let startTime = 0;
      this.state.currentTime = startTime;
      this.player = videojs(this.videoNode, this.props.videoOptions, function onPlayerReady() {
        this.player_.currentTime(startTime)
        this.bigPlayButton.show();
        this.player_.play()
      });
      this.updateVideoStatus();
    }

  }


  playDemoUrl = () => {
    this.player = videojs(this.videoNode, this.props.videoOptions, function onPlayerReady() {
      // this.player_.currentTime(startTime)
      this.bigPlayButton.show();
      this.player_.play()
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.player) {
        this.player.dispose();
      }
      // Create new player
      if (this.props.token && !this.props.demoUrl)
        this.playVideo();
      else
        this.playDemoUrl();
    }
    if (this.props.isViewedOnSingleScreen == false) {
      if (this.player) {
        this.player.pause();
        this.player.dispose()
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <>
        {/* <CookieEnableModal /> */}
        {
          !isIosSafari() ?
            <div key={this.props.id} style={{ width: '100%', height: '100%' }}>
              <div className='normal_player' data-vjs-player style={{ width: '100%', height: '100%' }} ref={node => this.videoContainer = node}>
              <WatermarkElement containerId={'vid-cont'} />
                <video ref={node => this.videoNode = node} className="video-js" style={{ width: '100%', height: '100%' }} controls={false} preload="auto"></video>
              </div>
            </div>
            :
            <div id='vid-cont' className='normal_player' key={this.props.id} style={{ width: '100%', height: '100%' }}>
              <WatermarkElement containerId={'vid-cont'} />
              <video width="100%" height="100%" controls autoPlay={true} style={{ width: '100%', height: '100%' }} >
                <source src={this.props.videoOptions.sources[0].src} type="application/x-mpegURL" withCredentials={true} />
              </video>
            </div>
        }
      </>
    )
  }
}
import types from './types';
import { pageSizeForDetailListing, productListingUrl, searchSuggestionUrl } from '../../../../constants/appConfig';
import { isADDA, replaceHyphenWithUnderscore } from '../../utils/helpers';


const getDataForCourseCategoryListing = (dataUrl,sponsoredCategory = 1) => {
    let data = dataUrl;
    if (data == "civil-engineering") {
        data = "engineering";
    }
    return {
        CALL_API: [
            {
                type: types.GET_COURSE_CATEGORY_LISTING,
                meta: {
                    path: `${searchSuggestionUrl}?coursesFacets=${replaceHyphenWithUnderscore(data)}&primaryFilter=true&sponsoredCategory=${sponsoredCategory}${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                    courseType: data
                }
            }
        ]
    };
};

const clearCourseCategoryData = () => {
    return {
        type: types.CLEAR_ALL,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
}

//@ TODO: Ask abhishek why the below code is here, should be here at all cost!

const getPaginatedDataForCourseDetail = ({ pageNo, courseName, type, categoryFacet, queryParam, sortBy, tagParams="" }) => {
    let updatedCourseName = ""
    if (courseName == "civil-engineering") {
        updatedCourseName = "engineering";
    } else {
        updatedCourseName = courseName;
    }
    return {
        CALL_API: [
            {
                type: type === 'init' ? types.GET_COURSE_DETAIL_LISTING : types.GET_COURSE_DETAIL_LISTING_ON_SCROLL,
                meta: {
                    path: `${productListingUrl}?coursesFacets=${updatedCourseName}${tagParams}&categoryFacets=${categoryFacet}&pageNo=${pageNo}&pageSize=${pageSizeForDetailListing}${queryParam.length ? queryParam : ''}&sortOrder=${sortBy}&primaryFilter=true${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                }
            }
        ]
    };
};

export {
    getDataForCourseCategoryListing,
    clearCourseCategoryData,
    getPaginatedDataForCourseDetail
};
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { fetchTimelineDataById, getHTMLContent, clearPreviousHtmlData} from '../data/ducks/detailPost/actions';
import '../data/ducks/detailPost/reducers';
import '../data/ducks/home/reducers';
import {capitalizedString, feedPostCategories, removeHyphensAndInsertSpaces, timeSince} from '../data/utils/helpers';
import fetch from '../data/utils/fetch';
import CustomSkeleton from '../components/library/CustomSkeleton';
import BreadCrumb from '../components/library/BreadCrumb';
import {productNames} from '../../constants/appConstants';
import CustomLoader from '../components/library/CustomLoader';

class Post extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            htmlContent: '',
            pTitle: ''
        };
    }
    static propTypes = {
        fetchTimelineDataById : PropTypes.func,
        getHTMLContent: PropTypes.func,
        detailedPostData: PropTypes.string,
        postUpdateAt: PropTypes.number,
        htmlData: PropTypes.string,
        contentId: PropTypes.number,
        clearPreviousHtmlData: PropTypes.func,
        source: PropTypes.string,
        postTitle: PropTypes.string
    };


    componentDidMount() {
        typeof window !== 'undefined' && window.scrollTo(0, 0)
        const { source, htmlData } = this.props;

        if (htmlData && htmlData.length) {
           this.extractTitle(htmlData)
        }



        if (source && source.length) {
            this.props.clearPreviousHtmlData();
            this.props.fetchTimelineDataById(this.props.match.params.postId, this.props.match.params.postType).then(() => {
                this.props.getHTMLContent(this.props.detailedPostData.replace('http://', 'https://')).then(() => {
                    let url = this.props.detailedPostData.replace('http://', 'https://');
                    fetch(`${url}`, "GET", {}, "text/html").then((res) => {
                        return res.text();
                    }).then((html) => {
                        this.setState({
                            htmlContent: html
                        }, this.extractTitle(html))
                    });
                });
            })
        }
        typeof window!=='undefined' && window.scrollTo(0, 0)
    }
// PS: extractTitle from html is bad
    extractTitle = (htmlData) => {
        let fR, sR, tR, postTitle = '';
        const re1 = '<span[^>]+?>(.*)<\/span>';
        const re2 = '(<span.*?<\/span>).*?(<span.*?<\/span>)';
        const re3 = '<span.*>(.*)<\/span>';
        fR = htmlData.match(re1);
        let temp1 = fR[1];
        temp1 = temp1.concat('</span>');
        sR = temp1.match(re2);
        tR = sR[2].match(re3);
        postTitle = tR[1];
        this.setState({
            pTitle: postTitle.toString()
        })
    }


    render() { 
        const { htmlContent, pTitle } = this.state;
        const { source, htmlData, postTitle } = this.props;

        const postUpdateAt = this.props.postUpdateAt;
        return(
            <>
                <Helmet>
                    <title>{this.props.match.params.name}</title>
                    <meta property="description" content={this.props.match.params.name} />
                </Helmet>
                <div className="container post-container">
                    <div>
                        <div className={'quarter'}>&nbsp;</div>
                        <div className={'half'}>
                            <BreadCrumb routeData={[{'name': 'Home', 'target': '/'}, {
                                'name': feedPostCategories[this.props.match.params.postType.toLowerCase()],
                                'target': ''
                            }]}/>
                            <h1 className={'post-title'}>
                                {/* {removeHyphensAndInsertSpaces(this.props.match.params.name)} */}
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: pTitle || postTitle
                                    }}
                                />
                            </h1>
                            <h3 className={'post-meta'}>{timeSince(postUpdateAt)}</h3>
                            {
                                (this.state.htmlContent || htmlData) ? (
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.htmlContent || htmlData,
                                        }}
                                    />
                                ) : (
                                    <>
                                       <CustomLoader/>
                                    </>
                                )
                            }

                        </div>
                        <div className={'quarter'}>&nbsp;</div>
                    </div>
                </div>
            </>
        );
    }
}

Post.fetching = ({ dispatch, match }) => {
    return [
        dispatch(fetchTimelineDataById(match.params.postId, match.params.postType))
    ];
};

const mapStateToProps = (state) => ({
    detailedPostData: state.detailPost.contentUrl,
    postUpdateAt: state.detailPost.postUpdateAt,
    htmlData: state.detailPost.htmlData,
    contentId: state.detailPost.id,
    source: state.home.source,
    postTitle: state.detailPost.postTitle || ''
});

const mapDispatchToProps = {
    fetchTimelineDataById,
    getHTMLContent,
    clearPreviousHtmlData
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Post));

import PropTypes from 'prop-types';
import React, { Component } from 'react';
//import BottomScrollListener from 'react-bottom-scroll-listener';
import BottomScrollListener from '../components/library/BottomScrollListener';
import { connect } from 'react-redux';
import { Cookies } from "react-cookie";
import { Redirect } from 'react-router-dom';
import { pageSizeForDetailListing, pageName } from '../../constants/appConfig';
import { productKeys, productNames, radioProductFacetMap, viewAllKeys } from '../../constants/appConstants';
import FilterTags from '../components/filter/FilterTags';
import ProductTypeListing from '../components/filter/listings/ProductTypeListing';
import NewMobileFilter from '../components/filter/NewMobileFilter';
import SearchBlock from '../components/filter/searchfilters';
import BreadcrumbSchema from '../components/library/BreadcrumbSchema';
import CustomHelmet from '../components/library/CustomHelmet';
import CustomLoader from '../components/library/CustomLoader';
import NoResultsFound from '../components/library/NoResultsFound';
import { clearProductDetailData, getPaginatedDataForProductDetail, getSecondaryFacets } from '../data/ducks/productDetailListing/actions';
import '../data/ducks/productDetailListing/reducers';
import {
    capitalizedString,
    capitalizeWords,
    createStorefrontPdpUrl,
    getMoengageMetadata,
    isEmpty,
    isSet,
    validateProductName,
    makeUrl,
    getDeviceType,
    getPdpUrl,
    getUrlPathObject,
    removeHyphensAndInsertSpaces,
    trackMoengageEvent,
    isADDA,
    getMoengagePayloadFromFacets,
    fireMoengageEvent,
    getTagIdParams
} from '../data/utils/helpers';
import CustomSkeleton from '../components/library/CustomSkeleton';
import BreadCrumb from '../components/library/BreadCrumb';

import CustomModal from "../components/library/CustomModal";
import { default as Link } from '../containers/ConnectedLink';
import { FaqSection } from '../components/ProductDetailsSubComponents';
import Faq from './SEO/faq';
import { checkProperties } from '../components/utils';
import DfpAdPdp from '../components/library/DfpAdPdp';
import { SeoSubCategory } from '../components/library/SeoBlock/SeoSubCategory';
import { SeoSectionMobile } from '../components/library/SeoBlock/SeoBlock';
import NewSortByMobileFilter from '../components/filter/NewSortByMobileFilter';
import { getDataForCourseCategoryListing } from '../data/ducks/courseCategoryListing/actions';

let pageN = 1;
let filterObj = {};
let requiredProductName = '';
const cookie = new Cookies();
let count = 0;
const validTags = ['categoryFacets', 'searchTerm', 'examTypesFacets', 'coursesFacets', "langFacets", 'subjectFacets'];

class ProductDetailListing extends Component {
    constructor(props) {
        super(props);
        const tags = this.createTagsFromUrl();
        this.state = {
            loading: true,
            isSort: false,
            listingLoader: false,
            showFilterModal: false,
            queryParam: props.location.search.substring(1) || '',
            tagArr: tags.tagArr,
            sortBy: 'RELEVANCE',
            dataLoader: false,
            facetObj: tags.facetObj,
            showSortModal: false,
            selectedSortCategory: 'Relevance',
            selectedFilterCategory: 'No Filter',
            showFilterAppliedDot: false,
            showSortByAppliedDot: true,
        };

    }

    static propTypes = {
        getPaginatedDataForProductDetail: PropTypes.func,
        getSecondaryFacets: PropTypes.func,
        productDetailListing: PropTypes.object,
        clearProductDetailData: PropTypes.func,
        utmParams: PropTypes.object
    };

    componentWillUnmount() {
        this.props.clearProductDetailData();
        this.clearBeforeUnload();
        count = 0;
        const googletag = window.googletag;
        if (googletag) googletag.destroySlots();
    }
    getInitialData = () => {
        const { location } = this.props;
        const { pathname } = location;
        const examName = pathname.split('/')[1];


        // get the params and call api for required data

        if (location.search.length) {
            const requiredString = location.search.substring(1);
            const paramArr = requiredString.split('&');
            for (let i = 0; i < paramArr.length; i++) {
                const newArr = paramArr[i].split('=');
                if (decodeURI(newArr[1]).length) {
                    filterObj[decodeURI(newArr[0])] = [decodeURI(newArr[1])];
                }
            }
        }

        if (this.checkIfValidCall(requiredProductName) && isEmpty(this.props.productDetailListing)) {
            this.getPaginatedDataForProductPage(0, false, this.state.sortBy);
        }
        typeof window !== 'undefined' && window.scrollTo(0, 0);
    }

    componentDidMount() {
        console.log('productDetailListing.data', this.props);
        this.getInitialData();

        //event list page view
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.productType !== this.props.match.params.productType) {

            this.getPaginatedDataForProductPage(0, false, this.state.sortBy, true);
        }
    }

    examCategoryArray = isADDA ? [
        { name: "Banking" }, { name: "SSC" }, { name: 'Railways' },
        { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }
    ] : [{name: "Class12-JEE"}, {name: "Dropper-JEE"},{name: "Class12-NEET"}, {name: "Dropper-NEET"}];
    productCategoryArray = [
        { name: "Mock Tests", url: "mock-tests" }, { name: "Books", url: "books" }, { name: "E-Books", url: "ebooks" }, { name: "Online Live Classes", url: "live-classes" }, { name: 'Videos', url: "videos" }
    ]

    clearBeforeUnload() {
        this.setState({
            tagArr: [],
            facetObj: {}
        });
    }

    createTagsFromUrl = () => {
        const qParams = this.props.location.search.substring(1);
        let tempTagArr = [];
        let newFacetObj = {};
        if (qParams.length) {
            let splitArr = qParams.split('&');
            splitArr = splitArr.filter(e => !(e.indexOf("utm") > -1 || e.indexOf("gclid") > -1));
            splitArr.map(item => {
                let newArr = item.split('=');
                if(validTags.includes(newArr[0])){
                    let finalArr = (newArr[1] || "").split(',');
                    finalArr.map(it => {
                        if (!tempTagArr.includes(decodeURI(it))) {
                            tempTagArr.push(decodeURI(it));
                        }
                    });
                    newFacetObj[newArr[0]] = finalArr;
                }
            });
        }
        return {
            tagArr: tempTagArr.filter((item) => item),
            facetObj: newFacetObj
        };
    };
    getPaginatedDataForProductPage = (pageNo = 0, forFilters, sortBy, forstateupdate) => {
        if (isEmpty(this.props.productDetailListing) || forFilters || forstateupdate) {
            const detailObj = {
                pageNo,
                type: 'init',
                categoryFacet: viewAllKeys[requiredProductName],
                queryParam: `${this.state.queryParam}` || `${this.props.location.search.substring(1)}`,
                sortBy,
            };

            // dispatch the action to get the detailed data for the listing with pagination.
            this.props.getPaginatedDataForProductDetail(detailObj).then(() => {
                // if(this?.props?.productDetailListing?.data?.facetsList?.examTypes===undefined){

                // }
                this.props.getSecondaryFacets(detailObj);

                this.setState({
                    loading: false,
                    showFilterModal: false,
                    showProductLoader: false,
                    dataLoader: false,
                    tagArr: this.createTagsFromUrl().tagArr
                });
                pageN = 0;
            });
        }
        typeof window !== 'undefined' && window.scrollTo(0, 0);
    };

    getPaginatedDataOnScroll = () => {
        const totalCount = this.props.productDetailListing.data.count || 0;
        if (totalCount < pageN * pageSizeForDetailListing) {
            return;
        }
        this.setState({
            listingLoader: true
        });
        if(pageN > 0) {
            const detailObj = {
                pageNo: pageN,
                type: 'scroll',
                categoryFacet: viewAllKeys[requiredProductName],
                queryParam: `&${this.state.queryParam}` || `&${this.props.location.search.substring(1)}`,
                sortBy: this.state.sortBy,
            };
    
            this.props.getPaginatedDataForProductDetail(detailObj).then(() => {
                // this.props.getSecondaryFacets();
    
                this.setState({
                    listingLoader: false
                });
            });
        }
        this.setState({
            listingLoader: false
        });
        pageN++;
    };

    handleDetailCardClick = (obj) => {
        const category = productKeys[obj.suggestionCat] || '';
        const id = obj.id || '';
        const title = obj.title || '';
        const slugurl = obj.slugURL || '';
        const getUtmFromStore = this.props.utmParams;
        const storeUrl = getPdpUrl(category, id, title, slugurl);

        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
            'product_click',
            getMoengageMetadata({
                source: 'web',
                'id': id,
                'name': title,
                'category': category,
                'list': category,
                'brand': pageName[1],
                'variant': '-',
                'price': obj['price'],
                'quantity': '1',
                // 'packageUrl': storeUrl,
                'packageUrl': storeUrl,
                'packageActualPrice': obj.maxPrice,
                'packageDiscountedPrice': obj['price'],
                getUtmFromStore: getUtmFromStore,
                source_screen: ' ProductDetail listing',
                productType: category
            })
        );
        // window.open(storeUrl);
        // this.props.history.push(getUrlPathObject(storeUrl));

    };

    handleFilterButton = () => {
        this.setState({
            showFilterModal: true,
            showSortModal: false
        });
        fireMoengageEvent("filter_button_clicked", {}, "filter");
    };

    handleSortButton = () => {
        this.setState({
            showSortModal: !this.state.showSortModal
        });
    };

    setSelectedSortCategoryChange = (category) => {
        this.setState({
            selectedSortCategory: category
        })
    }

    hideFilterOnMobile = () => {
        this.setState({
            showFilterModal: false
        });
    };

    handleFilterApply = (facetObj) => {
        this.setState({
            dataLoader: true,
            showFilterAppliedDot: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ),
            selectedFilterCategory: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ) ? 'Filter Applied' : 'No Filter',
        });
        let urlString = '&';
        const facetObjectKeys = Object.keys(facetObj);
        const facetKeysArrLen = facetObjectKeys.length;
        for (let i = 0; i < facetKeysArrLen; i++) {
            let facetValue = facetObj[facetObjectKeys[i]].join(',');
            urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
        }
        const encodedQueryString = encodeURI(urlString.slice(0, -1));
        fireMoengageEvent("filter_applied", getMoengagePayloadFromFacets(facetObj), "filter");
        this.props.history.push(getUrlPathObject(`/${requiredProductName}`, `?${encodedQueryString.substring(1)}`));

        this.setState({
            queryParam: encodedQueryString
        }, () => this.getPaginatedDataForProductPage(0, true, this.state.sortBy));

    };

    resetFilter = () => {
        filterObj = {};
        this.clearAllFilters();
        this.setState({
            showFilterModal: false,
            showFilterAppliedDot: false,
            selectedFilterCategory: 'No Filter',
        });
    };

    changeRoute = (route) => {
        this.setState({
            dataLoader: true,
            facetObj: {},
            tagArr: []
        });
        this.props.history.push(getUrlPathObject(`/${route}`));
        this.props.clearProductDetailData();
        this.props.getPaginatedDataForProductDetail(
            {
                pageNo: 0,
                type: 'init',
                categoryFacet: viewAllKeys[route.toLowerCase()],
                queryParam: '',
                sortBy: 'RELEVANCE'
            }
        ).then(() => {
            this.setState({
                dataLoader: false
            });
        });
    };

    getNewParamsForAllCategory = (facetKey, value) => {

        typeof window !== 'undefined' && window.scrollTo(0, 0);

        const { facetObj, tagArr } = this.state;
        const tempTagArr = tagArr;
        this.setState({
            dataLoader: true
        });
        if (facetKey === 'allExamtype') {
            const selection = value.toLowerCase();
            this.setState({
                tagArr: [],
                facetObj: {}
            });
            this.props.history.push(getUrlPathObject(`/${selection}-study-materials`));
            let tagParams = getTagIdParams( selection, this.props.globalExamList );
            this.props.getDataForCourseCategoryListing(`${selection}${tagParams}`).then(() => {
                this.setState({
                    dataLoader: false
                });
            });
        } else {
            let newFacetObj = Object.assign({}, facetObj);
            let newTagArr = tagArr;

            if (!isSet(newFacetObj[facetKey])) {
                newFacetObj[facetKey] = [];
            }
            if (newFacetObj[facetKey].includes(value)) {
                newFacetObj[facetKey] = newFacetObj[facetKey].filter(e => e.toLowerCase() !== value.toLowerCase());
                newTagArr = newTagArr.filter(e => e.toLowerCase() !== value.toLowerCase());
            } else {
                newFacetObj[facetKey].push(value);
                newTagArr.push(value);
            }
            let strNew = '';
            let p;
            for (p in newFacetObj) {
                if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
                    strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
                }
            }
            strNew = strNew.substring(-1);
            this.setState({
                queryParam: strNew,
                tagArr: newTagArr,
                facetObj: newFacetObj,
                showFilterAppliedDot: tempTagArr.length && true,
                selectedFilterCategory: tempTagArr.length ? 'Filter Applied' : 'No Filter',
            }, () => {
                this.getPaginatedDataForProductPage(0, true, this.state.sortBy);
            });
            this.props.history.push(getUrlPathObject(`/${requiredProductName}`, `?${strNew.slice(0, -1)}`));
        }
    };

    removeTag = (item) => {
        const { tagArr, facetObj } = this.state;
        let newFacetObj = {};
        const { location } = this.props;
        const tempTagArr = [];
        tagArr?.forEach(item => {
            tempTagArr.push(item);
        })
        this.setState({
            dataLoader: true
        });

        if (tempTagArr.indexOf(item) > -1) {
            tempTagArr.splice(tempTagArr.indexOf(item), 1);
            const facetKeys = Object.keys(facetObj);
            facetKeys.map(e => {
                newFacetObj[e] = facetObj[e].filter(f => {
                    return f.toLowerCase() !== item.toLowerCase();
                });
            });
        }
        let arr3 = [];
        let finalString = '';

        let splitArr = location.search.substring(1).split('&');
        splitArr.map(str => {
            let newAr = str.split('=');
            const cat = newAr.shift();
            let finalAr = (newAr[0] || "").split(',');

            finalAr = finalAr.filter(val => {
                let decodedUri = decodeURI(val).toLowerCase();
                return decodedUri !== item.toLowerCase();
            });

            const string2 = finalAr.join(',');

            if (string2.length) {
                arr3.push(cat + '=' + string2);
            }
        });
        if (arr3.length > 1) {
            finalString = arr3.join('&');
        } else {
            if (isSet(arr3[0])) {
                finalString = arr3[0];
            }
        }

        this.setState({
            queryParam: finalString,
            tagArr: tempTagArr,
            facetObj: newFacetObj,
            showFilterAppliedDot: tempTagArr.length && true,
            selectedFilterCategory: tempTagArr.length ? 'Filter Applied' : 'No Filter',

        }, () => {
            this.getPaginatedDataForProductPage(0, true, this.state.sortBy);
        });
        this.props.history.push(getUrlPathObject(`/${requiredProductName}`, `?${finalString}`));
    };

    clearAllFilters = () => {
        const { history } = this.props;
        history.push(getUrlPathObject(`/${requiredProductName}`));
        this.setState({
            dataLoader: true,
            queryParam: '',
            tagArr: [],
            facetObj: {},
            showFilterAppliedDot: false,
            selectedFilterCategory: 'No Filter',
        }, () => {
            this.getPaginatedDataForProductPage(0, true, this.state.sortBy);
        });
        fireMoengageEvent("filter_reset", {}, "filter");
    };

    handleSortBy = (sortBy) => {
        // sortBy = ASC or RELEVANCE
        this.setState({
            sortBy,
            isSort: true,
            loading: true,
            showProductLoader: true
        }, () => {
            this.getPaginatedDataForProductPage(0, true, sortBy);
        });
    };

    handleRadio = (product) => {
        requiredProductName = productKeys[product.toLowerCase()];
        this.props.history.push(getUrlPathObject(`/${requiredProductName}`));

        this.setState({
            dataLoader: true,
            queryParam: ''
        }, () => this.getPaginatedDataForProductPage(0, true, this.state.sortBy));
        this.clearAllFilters();

    };

    checkIfValidCall(requiredProductName) {
        return validateProductName(requiredProductName);
    }




    datalayerHanler = (searchValue) => {

        const { location } = this.props;
        const { pathname } = location;
        const examName = pathname.split('/')[1] || "";



        let ProCategory = productNames[examName] || "";

        let user_id_cookie = cookie.get("cp_user_id");
        let name = cookie.get('cp_user_name');
        let email = cookie.get('cp_user_email');
        const token = cookie.get('cp_token');


        if (searchValue && typeof (window) != "undefined") {

            let excategory = searchValue.courses;
            let exammCategory = searchValue.data && searchValue.data.packageEsEntity && searchValue.data.packageEsEntity[0] && searchValue.data.packageEsEntity[0].courses;
            if (token) {
                window.dataLayer.push({
                    name: name || "guest",
                    email: email || "",
                    isMobile: getDeviceType() == 'm' ? true : false,
                    loginType: 'email',
                    isLoggedIn: true,
                    examName: examName,
                    pageName: 'Listing',
                    examCategoryName: exammCategory,
                    category: ProCategory,


                })
            }
            else if (!token && !user_id_cookie) {
                window.dataLayer.push({
                    name: "guest",
                    email: "",
                    isMobile: getDeviceType() == 'm' ? true : false,
                    loginType: '',
                    isLoggedIn: false,
                    examName: examName,
                    pageName: 'Listing',
                    examCategoryName: exammCategory,
                    category: ProCategory,
                })
            }
            if (count == 1) {
                let payload = {};
                payload.user_email = cookie.get("cp_user_email") || "";
                payload.user_name = cookie.get("cp_user_name") || "";
                payload.source_screen = "";
                payload.examCategory = exammCategory;
                payload.getUtmFromStore = this.props.utmParams;
                trackMoengageEvent("list_page_view",payload);
            }

        }


    }



    render() {
        const { match, productDetailListing, location } = this.props;

        const { pathname } = location;
        const { listingLoader, showFilterModal, queryParam, dataLoader, tagArr, facetObj, showSortModal, selectedFilterCategory,selectedSortCategory, showFilterAppliedDot, showSortByAppliedDot } = this.state;
        const { params } = match;
        requiredProductName = params.productType;

        if (productDetailListing && productDetailListing.data && productDetailListing.data.facetsList) {
            count++;
            this.datalayerHanler(productDetailListing)

        }


        if (this.checkIfValidCall(requiredProductName)) {
            return (
                <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
                    {!this.props.isPaytmMini && <div className={"dfd-container"}>
                        <DfpAdPdp category={radioProductFacetMap[requiredProductName]} />
                    </div>}
                    <CustomHelmet
                        title={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaTitle ? this.props.seoData[pathname].metaTitle : `${pageName[1]} - ${capitalizeWords(removeHyphensAndInsertSpaces(requiredProductName))} for ${isADDA ? 'Bank, SSC, Railway, Teaching & All Govt Job Exam' : 'JEE, NEET'}`}
                        description={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaDescription ? this.props.seoData[pathname].metaDescription : `${pageName[1]} is the best place to get ${productNames[requiredProductName]}. Best  Study material for ${new Date().getFullYear()} Exam is a sure shot way to succeed with our ${productNames[requiredProductName]}. Get a best study material for your exam to analyse performance improve weak areas.`}
                        url={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].canonical || this.props.req_url}
                        keywords={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].keywords}
                        noFollow={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noFollow}
                        noIndex={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noIndex}

                    />

                    <BreadcrumbSchema
                        items={[
                            {
                                title: 'Home',
                                url: '/'
                            },
                            {
                                title: productNames[requiredProductName]
                            }
                        ]}
                    />
                    <div className={'breadcrumb-container'}>
                        <BreadCrumb routeData={[{ 'name': 'Home', 'target': '/' }, {
                            'name': productNames[requiredProductName],
                            'target': ''
                        }]} />
                    </div>
                    <div className={'header-container'}>
                        <h1 className={'listing-header'}>{this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeading?.split(":").join(" : ")?.split(",").join(", ") || productNames[requiredProductName]}</h1>
                        {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeadingDescription && <div className="headingdescription seoHeadingDescription">{this.props.seoData[pathname].pageHeadingDescription}</div>}
                    </div>

                    {
                        productDetailListing && productDetailListing.data && productDetailListing.data.packageEsEntity ? (
                            <div className={'listing-data-container'}>
                                <div className={'filter-parent-container col m3 fcwidth'}>
                                    <SearchBlock
                                        courseType={true}
                                        productType={requiredProductName}
                                        SuffixValue={requiredProductName}
                                        productDataSeo={this.productCategoryArray.slice(0, 5).filter(value => {
                                            return value.url != requiredProductName
                                        })}
                                        examC
                                        isProductListing={true}
                                        facetsList={productDetailListing.data.facetsList}
                                        showSearch={false}
                                        filterHeading={capitalizedString(requiredProductName)}
                                        currentItem={requiredProductName}
                                        type={1}
                                        heading={"Product Category"}
                                        changeRoute={this.changeRoute}
                                        getNewParamsforAllCategory={this.getNewParamsForAllCategory}
                                        selectedTags={tagArr}
                                        productCat={radioProductFacetMap[requiredProductName]}
                                        sortBy={this.state.sortBy}
                                        isSort={this.state.isSort}
                                        examCat={radioProductFacetMap[requiredProductName]}
                                        SuffixValue={"Online Coaching"}
                                        type={"productDetail"}
                                    />
                                </div>
                                <div className={'col m9 fccontwidth'}>
                                    {
                                        dataLoader ? (
                                            <>
                                                <CustomSkeleton />
                                            </>
                                        ) : (
                                                <>
                                                    {

                                                        tagArr.length > 0 &&
                                                        <div className={'tag-container'}>
                                                            <FilterTags tagArr={tagArr} removeTag={this.removeTag} />
                                                            <div className={'clear-all'}
                                                                onClick={this.clearAllFilters}>Clear All
                                                        </div>
                                                        </div>
                                                    }
                                                    {
                                                        !productDetailListing.data.packageEsEntity.length ? (
                                                            <NoResultsFound handleOnClick={this.clearAllFilters}
                                                            allCategoryData={this.props.allTrendingData?.data?.hotSellingPackages}
                                                            handleDetailCardClick={this.handleDetailCardClick}
                                                            />) : (
                                                                <>
                                                                    <BottomScrollListener
                                                                        offset={600}
                                                                        onBottom={() => this.getPaginatedDataOnScroll()}
                                                                    >
                                                                        <ProductTypeListing
                                                                            allCategoryData={productDetailListing.data}
                                                                            packageCount={productDetailListing.data.count}
                                                                            handleDetailCardClick={this.handleDetailCardClick}
                                                                            heading={productNames[requiredProductName]}
                                                                            sortBy={true}
                                                                            handleSortBy={this.handleSortBy}
                                                                            showLoader={this.state.showProductLoader}
                                                                            sortByValue={this.state.sortBy}
                                                                            getUtmFromStore={this.props.utmParams}
                                                                            useTitle={`${productNames[requiredProductName]} for ${isADDA ? `Banking, SSC and others Govt jobs`: `IIT JEE and NEET`}`}
                                                                            hideSortByFilter = {true}
                                                                        />
                                                                    </BottomScrollListener>
                                                                    {
                                                                        listingLoader &&
                                                                        <div style={{ 'margin': '20px' }}>
                                                                            <CustomLoader buttonSpinner={true} />
                                                                        </div>
                                                                    }



                                                                </>

                                                            )
                                                    }
                                                    {productDetailListing?.data?.packageEsEntity?.length ? <div className={'filter-sort-wrapper'}>
                                                            <div className={'only-mobile sort-btn'}
                                                                onClick={this.handleSortButton}>
                                                                <div className={'sort-btn-wrapper'}>
                                                                    <span className='filter-sort-btn-icon'>
                                                                        <img src={'/images/sort-bottom-icon.svg'} alt="sort" title='sort' />                                                                    {
                                                                            // tagArr.length ? (
                                                                            //     <span className={'dot-filter'}></span>) : (
                                                                            //     <span></span>)

                                                                        }
                                                                        <span className={'filter-name'}>Sort By</span>
                                                                    </span>
                                                                    <span className='filter-sort-btn-label'>
                                                                        <span className='filter-selected-category-wrapper'>
                                                                            {showSortByAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                                                            <span className='selected-filter-category'>{selectedSortCategory}</span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={'only-mobile filter-btn'}
                                                                onClick={this.handleFilterButton}>
                                                                <div className={'filter-btn-wrapper'}>
                                                                <span className='filter-sort-btn-icon'>
                                                                        <img src={'/images/filter-bottom-icon-new.svg'} alt="filter" title='filter' />                                                                    
                                                                        <span className={'filter-name'}>Filters</span>
                                                                    </span>
                                                                    <span className='filter-sort-btn-label'>
                                                                        <span className='filter-selected-category-wrapper'>
                                                                            {showFilterAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                                                            <span className='selected-filter-category'>{selectedFilterCategory}</span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                    {showFilterModal && 
                                                        <NewMobileFilter
                                                            visible={showFilterModal}
                                                            onCancel={this.hideFilterOnMobile}
                                                            onOk={this.onOk}
                                                            facetsList={productDetailListing.data.facetsList}
                                                            type={3}
                                                            handleApply={this.handleFilterApply}
                                                            resetFilter={this.resetFilter}
                                                            selectedTags={tagArr}
                                                            facetObj={facetObj}
                                                            handleRadio={this.handleRadio}
                                                            productCategory={radioProductFacetMap[requiredProductName]}
                                                        />
                                                    }
                                                    {showSortModal &&
                                                            <NewSortByMobileFilter
                                                                handleSortBy = {this.handleSortBy}
                                                                handleSortButton = {this.handleSortButton}
                                                                setSelectedSortCategory = {this.setSelectedSortCategoryChange}
                                                                selectedSortCategory = {this.state.selectedSortCategory}
                                                            />
                                                        }
                                                </>
                                            )}


                                </div>


                            </div>


                        ) : (
                                <CustomLoader />
                            )
                    }

                    {/*
                        requiredProductName == "books" &&

                        <CustomModal
                            hideHeader={true}
                            footer={null}
                            visible={true}

                            closable={true}
                            maskClosable={false}
                            keyboard={false}
                            modalClassName="booksbanner"
                            bodyStyle={{ overflowY: "auto" }}
                        >
                            <a href="#" onClick={() => this.changeRoute('ebooks')} >
                                <picture>
                                    <source media="(min-width: 768px)" srcset="/images/desktop-popup.gif" />
                                    <source media="(max-width: 768px)" srcset="/images/mobile-popup.png" />
                                    <img src="/images/desktop-popup.gif" />
                                </picture>
                            </a>
                        </CustomModal>
                        
                        

                    */}
                    {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].bottomContent &&
                              <div className="bottomContentContainer">
                              <div className="bottomContent"
                                dangerouslySetInnerHTML={{
                                  __html: this.props.seoData[pathname].bottomContent
                                }}>
                
                              </div>
                            </div>}

                    {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].faqs && this.props.seoData[pathname].faqs.length >= 1 && checkProperties(this.props.seoData[pathname].faqs[0]) &&
                        <div className="faqcontainer"> <Faq faqJson={this.props.seoData[pathname].faqs} />
                        </div>}

                    {
                        this.examCategoryArray && <div className="related-category-mobile">
                            <div className="title-seo">Exam Category</div>
                            <SeoSectionMobile
                                data={this.examCategoryArray.slice(0, 7)}
                                examCat={""}
                                subCategory={true}
                                currentItem={requiredProductName}
                                isExamtypes={true}
                                currentexamName={requiredProductName}
                                isProductCategory={true}
                                courseType={true}
                                productType={requiredProductName}
                                SuffixValue={requiredProductName}
                            />

                        </div>
                    }
                    {
                        this.productCategoryArray && <div className="related-category-mobile">
                            <div className="title-seo">Product Category</div>
                            <SeoSubCategory
                                data={this.productCategoryArray.slice(0, 5).filter(value => {
                                    return value.url != requiredProductName
                                })}
                                examCat={""}
                                subCategory={true}
                                currentItem={requiredProductName}
                                isExamtypes={true}
                                isRelatedCategory={true}
                                isProductCategory={true}

                            />

                        </div>
                    }
                </div>
            );

        } else {
            // 404 component
            return (
                <Redirect to={'/not-found'} />
            );
        }
    }
}

ProductDetailListing.fetching = ({ dispatch, match, location }) => {
    return [
        dispatch(getPaginatedDataForProductDetail(
            {
                pageNo: 0,
                type: 'init',
                categoryFacet: viewAllKeys[match.params.productType],
                queryParam: location.indexOf('?') > -1 ? `&${location.substring(location.indexOf('?') + 1)}` : '',
                sortBy: 'RELEVANCE'
            }
        )),
        dispatch(getSecondaryFacets())
    ];
};

const mapStateToProps = (state) => {
    const { productDetailListing } = state;
    let utmParams = state.referrer || {};
    let isLoggedIn = state.header.jwtToken;
    let userInfo = state.header.userInfo;
    let req_url = state.req_url;
    let seoData = state.seoData;
    let isPaytmMini = state.isPaytmMini;
    const allTrendingData = state.header.allTrendingData;
    const globalExamList = state.header.globalExamList;
    return {
        productDetailListing,
        utmParams,
        userInfo,
        isLoggedIn,
        req_url,
        seoData,
        isPaytmMini,
        allTrendingData,
        globalExamList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPaginatedDataForProductDetail: (obj) => dispatch(getPaginatedDataForProductDetail(obj)),
        clearProductDetailData: () => dispatch(clearProductDetailData()),
        getSecondaryFacets: (obj) => dispatch(getSecondaryFacets(obj)),
        getDataForCourseCategoryListing: (data,sponsoredCategory) => dispatch(getDataForCourseCategoryListing(data,sponsoredCategory))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailListing);

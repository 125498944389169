import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import QuickViewProductCard from "../../components/home/QuickViewProductCard";
import CustomLoader from "../../components/library/CustomLoader";
import Pagination from "../../components/library/Pagination";
import { setSelectedWishListPackageIds } from "../../data/ducks/wishlist/actions";
import { isEmpty } from "../../data/utils/helpers";
import ProductCardHoc from "../MyPurchases/PPCSearch/ProductCardHoc";

const WishListing = ({
  isLoading = false,
  pageNo = 0,
  setPageNo,
  searchTerm = "",
  pageSize = 0,
}) => {
  const {
    selectedWishListPackageIds = [],
    wishlistPackageDetails: { count = 0, packages: wishListItems = [] } = {},
    selectedfacets = "",
  } = useSelector((state) => state.wishlist);

  const dispatch = useDispatch();
  const { sharedUserId } = useParams();
  const onCheckProduct = (isChecked, item) => {
    const packageId = item?.packageId;
    let newSelectedWishListPackageIds = [...selectedWishListPackageIds];

    // when user check
    if (isChecked && !newSelectedWishListPackageIds.includes(packageId)) {
      newSelectedWishListPackageIds.push(packageId);
    } else {
      // when user uncheck
      newSelectedWishListPackageIds = newSelectedWishListPackageIds?.filter(
        (id) => id !== packageId
      );
    }
    dispatch(setSelectedWishListPackageIds(newSelectedWishListPackageIds));
  };
  const memoWishListItems = useMemo(() => {
    // if sharedUserId then return as it is,from here..
    if (sharedUserId) {
      return wishListItems;
    }
    return wishListItems?.map((item) => ({
      ...item,
      isChecked: selectedWishListPackageIds.includes(item?.id),
    }));
  }, [wishListItems, selectedWishListPackageIds]);
  if (isLoading) return <CustomLoader />;
  return (
    <ErrorBoundary>
      <>
        {selectedfacets && (
          <span className="shorResultFor">
            Showing results for ‘{selectedfacets}’
          </span>
        )}
        <div className="wl-wrapList">
          <div className="wl-items-wrap">
            {memoWishListItems?.length > 0 ? (
              memoWishListItems?.map((item, index) => {
                return (
                  <ProductCardHoc
                    className="wishlistCardWrap"
                    key={`wl_${item?.id}`}
                    id={
                      memoWishListItems?.length === index + 1
                        ? "last-element"
                        : null
                    }
                  >
                    <QuickViewProductCard
                      index={index}
                      key={item.id}
                      id={item.id}
                      cardtitle={item.title}
                      slugURL={item.slugURL}
                      src={item.imgUrl}
                      datasrc={item.imageUrl}
                      categoriesincluded={
                        !isEmpty(item?.subCategories)
                          ? item?.subCategories.map((e) => e.category)
                          : []
                      }
                      sellingprice={item.price}
                      maximumretailprice={item.maxPrice}
                      // categoryname={categoryName}
                      coupons={{
                        coupons: item.coupons,
                        defaultCoupons: item.defaultCoupons,
                      }}
                      item={item}
                      setQuickViewPopup={(flag) => {
                        setQuickViewPopup(flag);
                      }}
                      source_screen="Wish Listing"
                      published={item?.published}
                      isWishList={true} // pass flag true to show prefilled wishlist icon
                      confirmRemoveWishList={true}
                      isChecked={item?.isChecked}
                      isBuyNowShow={true}
                      isSelectableShow={sharedUserId ? false : true} // disable select operation for shared wishlist
                      wishlistOperationAllowed={sharedUserId ? false : true} // disable wishlist operation for shared wishlist
                      onCheckedCb={onCheckProduct}
                    />
                  </ProductCardHoc>
                );
              })
            ) : (
              <div className="noWishlistWrap">
                <div className="noWlImg">
                  {searchTerm ? (
                    <img src="/images/no-result-icon.svg" alt="fallbackImg" />
                  ) : (
                    <img src="/images/no-purchage-img.svg" alt="fallbackImg" />
                  )}
                </div>
                <div className="noWlText">
                  {searchTerm
                    ? "Please check if you have the right spelling, or try different keywords"
                    : "You’ve not wishlisted any item"}
                </div>
                {!searchTerm && (
                  <Link to="/#BANKING" className="noWlbtn">
                    <button>Wishlist Now</button>
                  </Link>
                )}
              </div>
            )}
          </div>
          {count > pageSize && (
            <Pagination
              current={pageNo}
              onChange={setPageNo}
              count={count}
              pageSize={pageSize}
            />
          )}
        </div>
      </>
    </ErrorBoundary>
  );
};

export default React.memo(WishListing);

import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverLaySearchHOC from "../../../components/library/OverLaySearchHOC";
import PPCPopularCourse from "./PPCPopularCourse";

import RecentSearches from "./RecentSearches";
import {
  saveRecentSearches,
  searchPackageFacets,
  searchPurchasedPackages,
} from "../../../data/ducks/header/actions";
import {
  isAnyKeyOfObjectWithValue,
  isEmpty,
  toQueryString,
} from "../../../data/utils/helpers";
import SearchedPackages from "./SearchedPackages";
import { initialFilterKeyConstants } from "./constants";
import WebFacetsFilter from "./FacetFilters/WebFacetsFilter";
import MWebFacetsFilter from "./FacetFilters/MWebFacetsFilter";
import { debounce, useWindowDimensions } from "../../../components/utils";
import ErrorPage from "../../../components/ErrorPage";
import { useHistory } from "react-router";

const SearchComponent = ({
  appliedFilterKeys,
  setAppliedFilterKeys,
  searchPackageHandler,
  inputRef,
  clearSearchErrorCb,
}) => {
  return (
    <div className="searchbar-input">
      <input
        ref={inputRef}
        id={"searchBar"}
        type="text"
        value={appliedFilterKeys?.searchTerm}
        onChange={(e) => {
          const inputValue = e.target.value;
          setAppliedFilterKeys((prev) => ({
            ...prev,
            searchTerm: inputValue,
          }));
          if (inputValue?.trim()?.length >= 3) {
            searchPackageHandler(
              {
                ...appliedFilterKeys,
                searchTerm: inputValue,
              },
              true, // enable isSearchFacet
              true // enable isSaveRecentSearch
            );
          }
        }}
        maxLength="254" // 254 character string allowed
        autoComplete="off"
        placeholder="Find Purchased Courses"
        autoFocus
      />
      <div
        className="searchicon"
        onClick={() => {
          if (appliedFilterKeys?.searchTerm.length) {
            setAppliedFilterKeys((prev) => ({
              ...prev,
              searchTerm: "",
            }));
            inputRef?.current.focus();
            clearSearchErrorCb();
          }
        }}
      >
        <img
          src={`/images/${
            appliedFilterKeys?.searchTerm?.length
              ? "close-icon.svg"
              : "icon-search.svg"
          }`}
        />
      </div>
    </div>
  );
};

const PPCSearch = ({ setSearchUiVisiblity }) => {
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [selectedFiltersFacets, setSelectedFilters] = useState(
    initialFilterKeyConstants
  );
  const [initialFilterState, setInitialFilterState] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [appliedQueryParams, setAppliedQueryParams] = useState("");
  const inputRef = useRef();
  const { myPurchaseSearchResultData, purchasePackageRecentSearches } =
    useSelector((state) => state.header);
  const { packages, facetsList } = myPurchaseSearchResultData;
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowDimensions();
  const isWebView = windowSize?.width >= 768;

  const saveRecentSearchTerm = (searchString = "") => {
    dispatch(saveRecentSearches(searchString));
  };
  useEffect(() => {
    saveRecentSearchTerm();
  }, []);
  const clearSearchError = () => {
    if (searchError) setSearchError("");
  };
  const searchPackageHandler = (
    args = {},
    isSearchFacet = true,
    isSaveRecentSearch = false
  ) => {
    // clear searchError state in initially if present
    clearSearchError();
    const { searchTerm } = args || {}; //here we destructure searchTerm from args.
    const queryStrings = toQueryString(args); // concate all params together

    // setQueryStrings in state
    setAppliedQueryParams(queryStrings);
    //remove spaces and check valid searchTerm character upto 3
    if (!searchTerm?.trim()?.length >= 3) return;
    setIsContentLoading(true);
    // debounce search Package API call
    debounce(async () => {
      try {
        const response = await dispatch(searchPurchasedPackages(queryStrings));

        setIsContentLoading(false);
        const result = await response[0];
        // set showFilterModal flag false after fetching search result when  mobile filter applied
        if (!isSearchFacet) setShowFilterModal(false);

        // store recent search searchTerm in localStorage on success data return when user keyStroke from input.
        if (isSaveRecentSearch && !isEmpty(result?.data))
          saveRecentSearchTerm(searchTerm?.trim());
      } catch (error) {
        setSearchError(
          error?.response || "Something Went Wrong!Please try again"
        );
        setIsContentLoading(false);
      }

      if (isSearchFacet)
        dispatch(searchPackageFacets(encodeURIComponent(searchTerm)));
    }, 1000);
  };
  const onRecentSearchHandler = (clickedValue = "") => {
    // update the state value with the selected clickedValue
    setSelectedFilters((prev) => ({
      ...prev,
      searchTerm: clickedValue,
    }));
    // call searchPackageHandler to search result based on the new searchTerm
    searchPackageHandler({
      ...selectedFiltersFacets,
      searchTerm: clickedValue,
    });
    /**
     * Focus the input when user clicks on the Left Side Bar Recent Searches.
     */
    inputRef.current.focus();
  };

  /**
   * @param {function} onChangeFacetHandlerCb - Handles a change in the selected facets for a given facet type.
   * @param {Object} args - An object containing the following properties:
   * @param {boolean} args.isSelected - Indicates whether the facet was selected (true) or unselected (false).
   * @param {string} args.value - The value of the selected/unselected facet.
   * @param {string} args.facetType - The type of facet that was selected/unselected.
   * @param {boolean} args.isSearchEnabled - Indicates whether the search feature is enabled (true) or not (false).
   * @param {Object} args.selectedFiltersFacets - An object containing the currently selected facets for each facet type.
   * @param {function} setSelectedFilters - A function that updates the currently selected facets in the parent component.
   * @param {function} searchPackageHandler - A function that handles the search feature in the parent component.
   */
  const onChangeFacetHandlerCb = ({
    isSelected,
    value,
    facetType,
    isSearchEnabled,
  }) => {
    const selectedFacets = selectedFiltersFacets[facetType] ?? "";

    // when user selects
    let newValue =
      selectedFacets.length <= 1
        ? value
        : selectedFacets?.split(",")?.concat(value).join(",");

    // when user unselect
    if (!isSelected) {
      const filteredFacets = selectedFacets
        .split(",")
        .filter((str) => str !== value);
      newValue = filteredFacets.join(",");
    }

    const newSelectedFacetsFilters = {
      ...selectedFiltersFacets,
      [facetType]: newValue,
    };

    setSelectedFilters(newSelectedFacetsFilters);
    if (isSearchEnabled) searchPackageHandler(newSelectedFacetsFilters, false);
  };

  /**
   * @param {Object} selectedFiltersFacets - an object that contains the selected filters facets including a searchTerm key that needs to be excluded.
   * @return {boolean} - a boolean value indicating whether any key of the object except the searchTerm key has a value.
   * Note: the function utilizes the useMemo hook to memoize the result based on the changes of the selectedFiltersFacets object.
   */

  const isFilterApplied = useMemo(() => {
    // exclude searchTerm key
    const removeSearchtermKey = {
      ...selectedFiltersFacets,
    };
    delete removeSearchtermKey.searchTerm;
    return isAnyKeyOfObjectWithValue(removeSearchtermKey);
  }, [selectedFiltersFacets]);

  const clearFilters = () => {
    const newAppliedFilters = {
      ...initialFilterKeyConstants,
      searchTerm: selectedFiltersFacets?.searchTerm,
    };
    // set selected filter to its original state by preserving search term.
    setSelectedFilters(newAppliedFilters);
    // call get search result API after clearing applied filters only for web fillters.
    if (isWebView) searchPackageHandler(newAppliedFilters, false);
  };

  // ************** Mweb Filter Handlers ******************

  // mWeb apply filter handler
  const applyFilterHandler = (appliedFilters) => {
    // isSearchFacet false to restrict fetch facet dispatch
    searchPackageHandler(appliedFilters, false);
  };

  // handler to open mweb filter
  function onOpenMwebFilter() {
    setInitialFilterState(selectedFiltersFacets); //used to preserve mweb filter on open and close popup.
    setShowFilterModal(true);
  }
  // handler to close mweb filter
  function onCloseMwebFilter() {
    setSelectedFilters(initialFilterState); //used to preserve mweb filter on open and close popup
    setShowFilterModal(false);
  }

  // *******

  // reset filters state to initial when searchTerm change
  useEffect(() => {
    if (selectedFiltersFacets?.searchTerm)
      setSelectedFilters({
        ...initialFilterKeyConstants,
        searchTerm: selectedFiltersFacets?.searchTerm,
      });
  }, [selectedFiltersFacets?.searchTerm]);
  const isThreeCharacterSearchTerm = useMemo(() => {
    return selectedFiltersFacets?.searchTerm?.trim()?.length >= 3;
  }, [selectedFiltersFacets?.searchTerm]);

  useEffect(() => {
    const oldSearchTerm = sessionStorage?.getItem("searchTerm");
    if(oldSearchTerm){
      sessionStorage?.removeItem("searchTerm");
      const newAppliedFilters = {
        ...initialFilterKeyConstants,
        searchTerm: oldSearchTerm
      };
      setSelectedFilters(newAppliedFilters);
      searchPackageHandler(newAppliedFilters);
    }
  },[])

  const routeChange = () => {
    /**
     * reset paid search UI when user navigates to other page.
     */
    setSearchUiVisiblity(false);
  };
  useEffect(() => {
    //clear listener when route changes
    const historyListener = history.listen(routeChange);
    return () => {
      removeEventListener("listen", historyListener);
    };
  }, []);
  return (
    <OverLaySearchHOC
      setSearchUiVisiblity={setSearchUiVisiblity}
      SearchComponent={
        <SearchComponent
          appliedFilterKeys={selectedFiltersFacets}
          setAppliedFilterKeys={setSelectedFilters}
          searchPackageHandler={searchPackageHandler}
          inputRef={inputRef}
          clearSearchErrorCb={clearSearchError}
        />
      }
      className="paid_web_search"
    >
      {searchError ? (
        <ErrorPage errorMsg={searchError} />
      ) : (
        <div className={`full-width-sarch-content`}>
          <div className="container">
            <div className="left-side-trending">
              {/* web facet filter should show only in desktop view */}
              {isWebView && isThreeCharacterSearchTerm && (
                <WebFacetsFilter
                  facetsList={facetsList}
                  selectedFiltersFacets={selectedFiltersFacets}
                  onChangeFacetHandlerCb={onChangeFacetHandlerCb}
                  clearFiltersCb={clearFilters}
                  isFilterApplied={isFilterApplied}
                />
              )}
              {/* show recent search when searchTerm not present */}
              {!selectedFiltersFacets?.searchTerm?.length && (
                <RecentSearches
                  onClickCb={onRecentSearchHandler}
                  recentSearches={purchasePackageRecentSearches}
                />
              )}
            </div>
            {isThreeCharacterSearchTerm ? (
              <SearchedPackages
                packages={packages}
                loading={isContentLoading}
                categoriesFacet={Object.keys(facetsList?.categories || {})}
                selectedCategoriesFacet={selectedFiltersFacets?.categoryFacets}
                onCategoryChange={onChangeFacetHandlerCb}
                searchTerm={selectedFiltersFacets?.searchTerm}
                appliedQueryParams={appliedQueryParams}
              />
            ) : (
              <PPCPopularCourse />
            )}
          </div>
          {!isWebView && isThreeCharacterSearchTerm && (
            <div className={"only-mobile"} onClick={onOpenMwebFilter}>
              <div className={"filter-button"}>
                <img
                  src={"/images/filter-list.svg"}
                  alt="adda247"
                  title="adda247"
                />
                <span className={"filter-bottom-text"}>FILTERS</span>
                <div>
                  {isFilterApplied && <span className={"dot-filter"}></span>}
                  <span>
                    {isFilterApplied ? "Filter Applied" : "No Filter"}
                  </span>
                </div>
              </div>
            </div>
          )}
          {!isWebView && showFilterModal && (
            <MWebFacetsFilter
              visible={showFilterModal}
              onCancel={onCloseMwebFilter}
              facetsList={facetsList}
              selectedFiltersFacets={selectedFiltersFacets}
              onChangeFacetHandlerCb={onChangeFacetHandlerCb}
              clearFiltersCb={clearFilters}
              isFilterApplied={isFilterApplied}
              applyFilterCb={applyFilterHandler}
              loading={isContentLoading}
            />
          )}
        </div>
      )}
    </OverLaySearchHOC>
  );
};

export default PPCSearch;

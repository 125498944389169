import React, { useState, useMemo, useEffect, useRef } from 'react';
import { getOnboardingScreen } from './getOnboardingScreen';
import { useDispatch } from 'react-redux';
import useOnboardingAPI from './useOnboardingAPI';
import { generateNonce, hitMoengageForOnboarding, validateInput } from '../../../data/utils/helpers';
import { withCookies } from 'react-cookie';
import { pageName, true_caller_config } from '../../../../constants/appConfig';
import OnboardingModal from './OnboardingModal';
import { onBoardingStepStates } from './onboardingConstants';

const RightPanelLoginModal = (props) => {

    const { 
        
        //States
        showSignupForm,
        showPassError,
        showPasswordError,
        loginPanelErrors,
        setLoginPanelErrors,
        showPassword, 
        setShowPassword,

        //functions
        closeModal,
        handleUserLogin,
        handleEmailOnBlur,
        handlePasswordOnBlur,
        handlePasswordOnFocus,
        responseGoogleSuccess,
        responseGoogleFail,
        responseFacebook,
        handleUpdateUserCookie,

        // Navigation States
        currentStep,
        setCurrentStep,

    } = props;

    // API States
    const [mobileSignupDetails, setMobileSignupDetails] = useState({
        phone : "",
        otp : "",
        name : "",
    });

    const [emailSignupDetails, setEmailSignupDetails] = useState({
        email : "",
        password : "",
        forgotPasswordEmail : "",
        otp : "",
        otpSent : false,
        forgotPasswordFlag : false,
        newPassword : "",
        confirmNewPassword : "",
    });

    const [loading, setLoading] = useState(false);
    const [otpSentFlag, setOtpSentFlag] = useState(false);
    const [shouldFocus, setShouldFocus] = useState(false);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [modalErrorDetails, setModalErrorDetails] = useState({
        heading : "Login Error",
        subHeading : "Truecaller is not installed on your device",
        btnLabel : "Close"
    });

    const [requestNonce, setRequestNonce] = useState("");

    const dispatch = useDispatch();

    const consentPopupOpenRef = useRef(false);

    const {verifyMobileOTP, forgotPasswordEmailOTPVerify, forgotPasswordEmailOTPSend, trueCallerSignUp} = useOnboardingAPI();

    const handleMobileOTPVerify = async (payload) => {
        setLoading(true);
        let response = await verifyMobileOTP(payload);
        let moePayload = {
            platform:'web', 
            brand: pageName[1], 
            source:'Mobile', 
            channel: "email", 
            login_status: "success"
        }
        if(response?.success) {
            if(!response?.data?.existingUser || !response?.data?.name) {
                hitMoengageForOnboarding("onboarding_screen", "Signup", moePayload)
                handleUserLogin(response?.data);
                setCurrentStep(onBoardingStepStates.user_details_collection);
                setLoading(false);
            }
            else {
                hitMoengageForOnboarding("onboarding_screen", "Login", moePayload)
                handleUserLogin(response?.data);
                setLoading(false);
                closeModal();
            }
        }
        else {
            setLoginPanelErrors({...loginPanelErrors, otp : [response?.message]});
            setLoading(false);
        }
    }

    const handleForgotPasswordBtnClick = async (emailSignupDetails, otpSentFlag) => {
        if(otpSentFlag) {
            setLoading(true);
            setLoginPanelErrors({...loginPanelErrors, otp : []});
            const response = await forgotPasswordEmailOTPVerify(emailSignupDetails);
            if(response.success) {
                setLoginPanelErrors({...loginPanelErrors, otp : []});
                setEmailSignupDetails({...emailSignupDetails, forgotPasswordFlag : true});
                setCurrentStep(onBoardingStepStates.create_password);
            }
            else {
                setLoginPanelErrors(prevErrors => ({
                    ...prevErrors,
                    otp : [response?.message],
                }));
            }
            setLoading(false);
        }
        else {
            setLoading(true);
            let inputFields = {
                email : [emailSignupDetails?.forgotPasswordEmail],
            }
            const errors = validateInput(inputFields);
            if(errors?.email?.some(error => error)) { 
                setLoginPanelErrors({...loginPanelErrors, email : errors?.email});
                setLoading(false);
                return;
            } else {
                setLoginPanelErrors({...loginPanelErrors, email : []});
                const response = await forgotPasswordEmailOTPSend(emailSignupDetails?.forgotPasswordEmail);
                if(response.success) {
                    setOtpSentFlag(true);
                    setEmailSignupDetails({...emailSignupDetails, otpSent : true});
                }
                else {
                    setLoginPanelErrors({...loginPanelErrors, email : [response?.message]});
                }
                setLoading(false);
            }
        }
    }

    const closeOnboardingModal = () => {
        setShowOnboardingModal(false);
    };

    const invokeTrueCaller = () => {
        let reqNonce = generateNonce();
        setRequestNonce(reqNonce);

        const params = new URLSearchParams({
            type: "btmsheet",
            requestNonce: reqNonce,
            partnerKey: true_caller_config.APP_KEY,
            partnerName: true_caller_config.APP_NAME,
            lang: true_caller_config.LANGUAGE,
            privacyUrl: true_caller_config.LINK_TO_YOUR_PRIVACY_PAGE,
            termsUrl: true_caller_config.LINK_TO_YOUR_TERMS_PAGE,
            loginPrefix: true_caller_config.TITLE_STRING_PREFIX,
            loginSuffix: true_caller_config.TITLE_STRING_SUFFIX,
            ctaPrefix: true_caller_config.BUTTON_TEXT_PREFIX,
            ctaColor: true_caller_config.BUTTON_FILL_COLOR,
            ctaTextColor: true_caller_config.BUTTON_TEXT_COLOR,
            btnShape: true_caller_config.BUTTON_SHAPE,
            skipOption: true_caller_config.FOOTER_CTA_STRING,
            ttl: true_caller_config.TIME_IN_MILLISECONDS,
        });
    
        const trueCallerURL = `truecallersdk://truesdk/web_verify?${params.toString()}`;

        const newTab = window.open(trueCallerURL, '_blank');

        const closeTab = () => {
            if (newTab) {
                newTab.close();
            }
        };

        setTimeout(() => {
            if(newTab && newTab?.closed) {
                setShouldFocus(true);
                consentPopupOpenRef.current = true;
                hitMoengageForOnboarding("onboarding_screen", "Truecaller_activity_initiation", {platform:'web', source:'Truecaller', Exist_Status:'Yes'})
            } else {
                consentPopupOpenRef.current = false;
                setShowOnboardingModal(true);
                hitMoengageForOnboarding("onboarding_screen", "Truecaller_activity_initiation", {platform:'web', source:'Truecaller', Exist_Status:'No'})
                closeTab();
            }
        }, 1000);       // For closing the tab in case true caller is not installed in the device (after 1 sec).

    };

    useEffect(() => {
        if(mobileSignupDetails?.otp?.length === 6) {
            handleMobileOTPVerify(mobileSignupDetails);
        }
        if(emailSignupDetails?.otp?.length === 6) {
            handleForgotPasswordBtnClick(emailSignupDetails, emailSignupDetails?.otpSent);
        }
    }, [mobileSignupDetails?.otp,  emailSignupDetails?.otp]);

    useEffect(() => {
        if (shouldFocus && document.hasFocus() && consentPopupOpenRef.current) {
            setLoading(true);
            setShouldFocus(false);
            consentPopupOpenRef.current = false;
            setTimeout(async () => {
                let response = await trueCallerSignUp(requestNonce);
                if (response && response?.success && response?.data) {

                    setLoginPanelErrors({...loginPanelErrors, social : ""});

                    let moePayload = {
                        platform:'web', 
                        brand: pageName[1], 
                        source:'Truecaller', 
                        channel: "email", 
                        login_status: "success"
                    }

                    hitMoengageForOnboarding("onboarding_screen", "Login", moePayload)
                    handleUserLogin(response?.data);
                    setLoading(false);
                    closeModal();
                } else {
                    setShowOnboardingModal(true);
                    setModalErrorDetails({...modalErrorDetails, subHeading : response?.error || response?.response || response?.message})
                    setLoginPanelErrors({...loginPanelErrors, social : response?.error || response?.response || response?.message});
                    setLoading(false);
                }
            }, 3000);   // Hitting the API after an induced delay of 3 secs to compensate the backend token generation delays
        }
    }, [ shouldFocus, document.hasFocus(), consentPopupOpenRef.current ]);

    const onboardingScreen = useMemo(
        () => getOnboardingScreen({

            //States from this component
            currentStep, 
            setCurrentStep,

            //States from parent components
            showSignupForm,
            showPassError,
            showPasswordError,
            showPassword, 
            setShowPassword,
            otpSentFlag, 
            setOtpSentFlag,
            loginPanelErrors, 
            setLoginPanelErrors,
            loading, 
            setLoading,
    
            //functions
            closeModal,
            handleUserLogin,
            handleEmailOnBlur,
            handlePasswordOnBlur,
            handlePasswordOnFocus,
            responseGoogleSuccess,
            responseGoogleFail,
            responseFacebook,
            dispatch,
            handleMobileOTPVerify,
            handleForgotPasswordBtnClick,
            handleUpdateUserCookie,
            invokeTrueCaller,

            // API States
            mobileSignupDetails, 
            setMobileSignupDetails,
            emailSignupDetails, 
            setEmailSignupDetails,
            loginPanelErrors, 
            setLoginPanelErrors,
            loading, 
            setLoading,
        }), 
    [
        currentStep, 
        mobileSignupDetails, 
        emailSignupDetails, 
        otpSentFlag, 
        loginPanelErrors, 
        loading, 
        showPassword,
    ]);

    return (
        <div>
            {currentStep && onboardingScreen}
            {
                <OnboardingModal 
                    showModal={showOnboardingModal} 
                    closeModal={closeOnboardingModal}
                    currentStep={onBoardingStepStates.modal_error}
                    modalErrorDetails={modalErrorDetails}
                />
            }
        </div>
    );
};

export default withCookies(RightPanelLoginModal);

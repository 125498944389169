import React, { useMemo, useState } from "react";
import CustomModal from "../../../../components/library/CustomModal";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isEmpty,
  isStringValueExist,
} from "../../../../data/utils/helpers";
import { facetsLabelConstants, facetsSearchKeyConstants, disAllowedPPCFacets } from "../constants";

const MWebFacetsFilter = ({
  visible,
  facetsList,
  onCancel,
  selectedFiltersFacets,
  onChangeFacetHandlerCb,
  clearFiltersCb,
  applyFilterCb,
  loading,
}) => {
  const [currentSelectedFacet, setSelectedCurrentFacet] = useState("");
  const [searchPackageTerm, setSearchPackageTerm] = useState("");
  const facetTabs = useMemo(() => {
    let tabs = [];
    // make factesListKeys which has value
    tabs = Object.keys(facetsList).filter(
      (key) => Object.keys(facetsList[key]).length > 0
    ).filter(key => !disAllowedPPCFacets.includes(key));
    setSelectedCurrentFacet(tabs[0]);
    return tabs;
  }, [facetsList]);
  const facetTabMappingToContent = useMemo(() => {
    let mappedContent = [];
    if (!currentSelectedFacet) return [];
    // filter search package
    if (currentSelectedFacet === "packages" && searchPackageTerm) {
      mappedContent =
        Object.keys(facetsList[currentSelectedFacet])?.filter((item) =>
          item.toLowerCase().includes(searchPackageTerm.toLowerCase())
        ) || [];
    } else {
      mappedContent = Object.keys(facetsList[currentSelectedFacet]) || [];
    }
    return mappedContent;
  }, [currentSelectedFacet, searchPackageTerm]);
  const getCountOfSelectedFilter = (facetType) => {
    return selectedFiltersFacets[facetType]
      ? selectedFiltersFacets[facetType]?.split(",")?.length
      : 0;
  };
  const facetsItemRendering = (facetItems = [], facetType = "") => {
    if (!facetItems?.length)
      return (
        <div className="noresult-found">
          <div className="no-result-icon">
            <img src="/images/no-result-icon.svg" />
          </div>
          <div className="no-rusult-heading">No Results Found!</div>
          <div className="no-rusult-subheading">
            Please check if you have the right spelling, or try different
            keywords.
          </div>
        </div>
      );
    return facetItems?.map((item, index) => {
      const facetValue = getSubstringAfterChar(item, "#");
      const facetLabel = getSubstringBeforeChar(item, "#");
      const isChecked = isStringValueExist(
        selectedFiltersFacets[facetType],
        facetValue
      );
      return (
        <div key={item + index} className="paid_checkbox_container">
          <input
            type="checkbox"
            id={`${facetValue}_${index}`}
            value={facetValue}
            checked={isChecked}
            onChange={(e) =>
              onChangeFacetHandlerCb({
                isSelected: e.target.checked,
                value: facetValue,
                facetType,
                isSearchEnabled: false, //pass false flag to restrict dispatch on input change
              })
            }
          />
          <label htmlFor={`${facetValue}_${index}`}>{facetLabel}</label>
        </div>
      );
    });
  };
  const searchPackage = (e) => {
    setSearchPackageTerm(e.target.value);
  };
  return (
    <CustomModal
      visible={visible}
      footer={null}
      centered
      mask={false}
      wrapClassName={"width100vw"}
      title={
        <div className={"modal-header-filter"}>
          <div className={"modal-filtermobile"}>Filters</div>
        </div>
      }
      closeCallback={onCancel}
      bodyStyle={{ padding: "0", margin: "0" }}
      closeIcon={
        <img
          onClick={onCancel}
          alt={"adda247"}
          src={"/images/close-icon.svg"}
          title="adda247"
        />
      }
    >
      <div className={"mobile-filter-main-block"}>
        <div className={"mobile-exam-category"}>
          {facetTabs && !isEmpty(facetTabs) && (
            <div>
              {facetTabs.map((item, i) => {
                return (
                  <div
                    key={`facet_tab_${i}`}
                    onClick={() => setSelectedCurrentFacet(item)}
                    className={
                      currentSelectedFacet == item
                        ? "active-category-listing"
                        : "category-listing"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="facet_label_wrapper"
                    >
                      <div className="facet_label">
                        {facetsLabelConstants[item]}
                      </div>
                      <div className="facet_count">
                        {getCountOfSelectedFilter(
                          facetsSearchKeyConstants[item]
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={"checkbox-container-mobile"}>
          {currentSelectedFacet === "packages" && (
            <input
              placeholder="Search"
              onChange={searchPackage}
              className="facet_search"
            />
          )}
          {facetsItemRendering(
            facetTabMappingToContent,
            facetsSearchKeyConstants[currentSelectedFacet]
          )}
        </div>
        <div className={"filter-footer"}>
          <div className={`reset `} onClick={clearFiltersCb}>
            RESET
          </div>
          <div
            className={`apply`}
            onClick={() => {
              applyFilterCb(selectedFiltersFacets);
            }}
          >
            {loading ? "APPLYING..." : "APPLY"}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default MWebFacetsFilter;

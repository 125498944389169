import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { pageName } from "../../../../../constants/appConfig";
import { getPdpUrl, isADDA } from "../../../../data/utils/helpers";
import ExamInstruction from "./ExamTest/ExamInstruction";
import { Indicator } from "../TestResultAnalysis/Components/Indicator";
import { LIVE_TEST_CONSTANTS, getLiveTestRemainingTime, hitMoengageForLiveTest, indicatorMessages } from "../TestResultAnalysis/Components/liveTestUtils";
import { BLANK_IDENTIFIER, ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "../../../MoengageConstants";

const Instruction = ({
  data,
  language,
  changeLanguage,
  setStartTest,
  isFixedMock,
  exam = "",
  mode = "",
  liveTestAdditionalData = {},
  setFixedMockData,
  startTestFlag = false,
  nLTAdditionalData = {},
  setNLTAdditionalData,
}) => {
  // const [startTest,setStartTest] = useState(false);
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const [indicatorMessage, setIndicatorMessage] = useState("");
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [instructionRemainingTime, setInstructionRemainingTime] = useState("");
  const [disableTnC, setDisableTnC] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime(Date.now());
    }, [1000]);

    return () => { clearInterval(interval) }
  },[]); 

  useEffect(() => {

    if(!navigator.onLine) {
      setShowIndicator(true);
      setIndicatorMessage(indicatorMessages?.instructions_offline);
      setTimeout(() => {
        setShowIndicator(false);
      },[4000]);
    }else{
      if(indicatorMessage) {
        setShowIndicator(true);
        setIndicatorMessage(indicatorMessages?.instructions_online);
        setTimeout(() => {
          setShowIndicator(false);
        },[4000]);
      }
    }
  }, [navigator?.onLine]);

  useEffect(() => {
    if(mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST && liveTestAdditionalData?.startTime && liveTestAdditionalData?.attemptBeforeTime) {
      let startTime = liveTestAdditionalData?.startTime;
      let attemptBeforeTime = liveTestAdditionalData?.attemptBeforeTime;
      let remainingTime = attemptBeforeTime - currentTime;
      let startBufferTime = liveTestAdditionalData?.startBufferTime;
      let consumedBufferTime = currentTime - attemptBeforeTime;

      if ( remainingTime < 1 && consumedBufferTime <= startBufferTime  ) {
        if(remainingTime < 1 && consumedBufferTime < 1000 && navigator.onLine) {
          hitMoengageForLiveTest("instruction_window", "starttest_instructions_automated", getMoengagePayload());
          handleOnClick();
        }
        // In case we are required to Auto start the test in startBufferTime
        // if(consumedBufferTime === 0 && navigator.onLine) {
          // handleOnClick();
        // }
        if(navigator.onLine) setDisableTnC(false);
        else setDisableTnC(true);
        
      }
      // Resume State Flag to be passed below
      else  if ( remainingTime < 0 && consumedBufferTime > startBufferTime && startTestFlag ) {
        window.close();
      }
      else {
        let remainingInstructionTime = getLiveTestRemainingTime(startTime, attemptBeforeTime);
        setInstructionRemainingTime(remainingInstructionTime);
        setDisableTnC(true);
      }

    }
  },[currentTime]); 

  const params = useParams();
  let instruction = "";
  if (data[language]) {
    instruction = data[language].desc;
  }

  const handleCancleButton = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  const handleOnClick = () => {
    const mappingId = params.mappingId;
    if (
      localStorage.getItem(`${mappingId}Resumed`) === "true" &&
      localStorage.getItem(`${mappingId}Submitted`) !== "true"
    ) {
      window.location.reload();
      return;
    }
    if(mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST && !localStorage.getItem(`${mappingId}Resumed`) && liveTestAdditionalData){
      let fixedMockRemTime = (liveTestAdditionalData?.submitTime - liveTestAdditionalData?.attemptBeforeTime)/1000;
      setFixedMockData(prev => {
        const newData = {...prev};
        newData.fpmUserRemainingTime = fixedMockRemTime;
        return newData;
      })
    }
    if(mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST && !nLTAdditionalData?.userAlreadyStarted) {
      setNLTAdditionalData({...nLTAdditionalData, userStartTime : Date.now()})
    }
    setStartTest(true);
  };

  const getMoengagePayload = () => {
    let moepayload = {};
    moepayload.index = BLANK_IDENTIFIER;
    moepayload.content_subject = BLANK_IDENTIFIER;
    moepayload.rating = BLANK_IDENTIFIER;
    moepayload.content_url = getPdpUrl("TEST_SERIES", data?.mappingId, "");
    moepayload.exam_category = BLANK_IDENTIFIER;
    moepayload.user_exam_category_selected = WEB_IDENTIFIER;
    moepayload.exam = BLANK_IDENTIFIER;
    moepayload.language = ENGLISH_IDENTIFIER;
    moepayload.content_language = data?.language;
    moepayload.content_id = data?.mappingId;
    moepayload.content_title = data?.title;
    moepayload.package_status = "Paid";
    moepayload.package_id = data?.packageId || "";
    moepayload.package_title = BLANK_IDENTIFIER;
    moepayload.package_type = BLANK_IDENTIFIER;
    moepayload.package_purchased = BLANK_IDENTIFIER;
    moepayload.deep_link = BLANK_IDENTIFIER;
    moepayload.test_id = data?.mappingId;
    moepayload.test_type = "live";
    if(mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST) {
      moepayload.live_attempt = "yes";
    }
    else if(mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST){
      moepayload.non_live_attempt = "yes";
    }
    return moepayload;
  }

  // Instruction page for exam based tests
  if (exam) {
    return (
      <ExamInstruction
        acceptTnC={acceptTnC}
        setAcceptTnC={setAcceptTnC}
        data={data}
        isViewInstructionPopup={false}
        language={language}
        changeLanguage={changeLanguage}
        handleStartTestCb={handleOnClick}
        mode={mode}
        instructionRemainingTime={instructionRemainingTime}
        showIndicator={showIndicator}
        indicatorMessage={indicatorMessage}
        disableTnC={disableTnC}
        getMoengagePayload={getMoengagePayload}
      />
    );
  }
  // intruction page for normal tests
  return (
    <>
      <div className="instruction-section">
        <div className="adda-logo-instruction">
          {isADDA ? (
            <img
              src="/images/header-logo.svg"
              className="logo-img"
              alt={`${pageName[0]}-logo`}
              title={pageName[0]}
              width="172px"
              height="40px"
            />
          ) : (
            <img
              src={"/images/sankalpB-logo.svg"}
              className="logo-img"
              alt={`${pageName[0]}-logo`}
              title={pageName[0]}
            />
          )}
        </div>
        {data["meta"].cutOffMarks > 0 && (
          <div className="cutfmark">
            <div className="markwrap">
              Cut Off Marks:
              <span>{`${data["meta"].cutOffMarks} / ${data["meta"].totalm}`}</span>
            </div>
          </div>
        )}
        <div className="is-instruction">
          <div className="instructions-wrapper">
            <div className="instructions-heading">
              <h4>Instructions</h4>
            </div>
            {mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST ? <div className="liveTestTimer">
              <span className="instruction-clock-label">Test Starts in: </span> 
              <span className={`instruction-clock-time ${instructionRemainingTime === "00:00:00" ? "time-up" : ""}`}>{instructionRemainingTime || "00:00:00"}</span>
            </div> : ""}
          </div>
          <p>
            {" "}
            {isFixedMock ? (
              <>
                <span>
                  <p>
                    1. Test once started can’t be stopped/restarted.
                    <br />
                    2. The timer will still be running if you close the test
                    window or pause the test.
                    <br />
                    3. Test must be submitted on or before the submission
                    deadline.
                    <br />
                    4. Please keep the internet connection on for smooth
                    functioning of the test.
                  </p>
                </span>
              </>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: instruction }}></span>
            )}
          </p>
        </div>
        <div className="is-language">
          <label htmlFor="language">Choose Your default Language:</label>

          <select
            id="language"
            className="language-select"
            value={language}
            onChange={changeLanguage}
          >
            {Object.keys(data).map((key, index) => {
              if (["meta", "orgName"].includes(key) || !data[key]) {
                /// bcoz stupid API :(
                return "";
              }
              return (
                <option value={key} key={key}>
                  {key}
                </option>
              );
            })}
          </select>
        </div>
        <div className="is-start">
          <input
            type="checkbox"
            id="start-test"
            name=""
            value="start-test"
            checked={acceptTnC}
            disabled={disableTnC}
            onChange={() => {
              if(mode) hitMoengageForLiveTest("instruction_window", "starttest_instructions_clicked", getMoengagePayload());
              setAcceptTnC(!acceptTnC);
            }}
          />
          <label htmlFor="start-test">
            {" "}
            I've read all the instructions carefully and abide by them.
          </label>
        </div>
        <div>
          <button
            className="red "
            onClick={handleOnClick}
            disabled={!acceptTnC}
          >
            Start Test
          </button>
          <button
            className="grayCancel testCancel"
            onClick={handleCancleButton}
          >
            Cancel
          </button>{" "}
        </div>
       <div className="OnOffInd"> {mode && <Indicator 
          showIndicator={showIndicator}
          indicatorBody={`${indicatorMessage}`}
          additionIndicatorWrapper={`${navigator?.onLine ? "indicator-online" : "indicator-offline"}`}
        />}
        </div>
      </div>
    </>
  );
};

export default Instruction;

import React, {Component} from 'react';
import LazyIntersection from '../../components/LazyIntersection';
import {createOptimizedImgUrl} from '../../data/utils/helpers';
import { pageName } from '../../../constants/appConfig';

const quoteImageUrl = '/images/ic-quote.svg';
const imageUrl = `https://storecdn.${pageName[0]}.com`;


class StudentsLoveUs extends Component {
    render() {
        return (
            <div className={'student-review-list'}>
                <div className={'student-review-list-wrap'}>
                    <div className={'student-pic-wrap'}>

                        <LazyIntersection
                            src={quoteImageUrl}
                            alt={pageName[0]}
                            placeholder={'images/placeholder-feed.svg'}
                            style={{width: '30px', height: '24px', position: 'absolute'}}
                        />


                        <div className={'img-circle'}>
                            <LazyIntersection
                                src={createOptimizedImgUrl(imageUrl + this.props.datasrc, 76, 76)}
                                alt={this.props.name}
                                placeholder={'images/placeholder-feed.svg'}
                                style={{borderRadius: '50%', width: '76px', height: '76px'}}
                            />
                        </div>
                    </div>

                    <div className={'testimonial-text-main'}>
                        <p className={'testimonial-text'}>
                            {this.props.desc.substring(0, 255)}
                        </p>
                    </div>

                    <div className={'testimonial-name-container'}>
                        <span style={{'fontSize': '16px'}}><b>{this.props.name}</b></span><br/>
                        <span className="selectd-in">{'Selected in SBI PO 2018'}</span>
                    </div>
                </div>
            </div>
        );
    }

}

export default StudentsLoveUs;
import React, { useState } from "react";
import { createOptimizedImgUrl, getMoengageMetadata, getYoutubeIframeUrl } from "../../data/utils/helpers";
import CustomModal from "../library/CustomModal";
import NewCarouselInsta from "../library/NewCarouselInsta";

const FacultyProfileSlider = ({ facultyData,Getpayload }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [selectedFaculty, setSelectedFaculty] = useState(undefined);


  const handleSelectDemo = (faculty) => {
    setSelectedFaculty(faculty);
    setPlayVideo(true);
    let payload = Getpayload || {};
    payload.package_url = window?.location?.href || "";
    payload.facultyname = faculty?.firstName && faculty?.lastName ?`${faculty?.firstName} ${faculty?.lastName}` : faculty?.name;
    payload.facultyid = faculty?.id;
    if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("Faculty_demo_played", getMoengageMetadata(payload));
  };

  return (
    <>
      {selectedFaculty && (
        <CustomModal
          footer={null}
          visible={playVideo}
          title={`${selectedFaculty?.firstName} ${selectedFaculty?.lastName} ${selectedFaculty?.subject ? `- ${selectedFaculty.subject}` : ''}`}
          onCancel={() => {
            setPlayVideo(false);
            setSelectedFaculty(undefined);
          }}
          closeCallback={() => {
            setPlayVideo(false);
            setSelectedFaculty(undefined);
          }}
          closable={true}
          maskClosable={false}
          keyboard={false}
          modalClassName="largemodal"
          bodyStyle={{ overflowY: "auto" }}
          dataTestid='facultyProfileSlider'
        >
          <div className="video-popup">
            <iframe
              src={getYoutubeIframeUrl(selectedFaculty?.url)}
              allow="autoplay"
            ></iframe>
          </div>
        </CustomModal>
      )}
      <h3 className="pdpSimpleHeading" id="faculty">
        Faculty Profile
      </h3>
      <div className="pdpFacSlider">
        <div className="pdpUl">
          <NewCarouselInsta
            id="facultyProfileContainer"
            dataLength={facultyData?.length}
            lastValue={2}
            itemsPerPage={3}
          >
            {facultyData && facultyData?.map((facultyMember, index) => {
              return (
                <div className="pdpLi" key={index}>
                  <div className="pdpFacInfoWrap">
                    <div className="pdpFacImg">
                    {facultyMember?.image ?
                      <img
                        src={createOptimizedImgUrl(facultyMember?.image,58)}
                        alt="facultyImage"
                      />: facultyMember?.firstName?.[0]
                    }
                    </div>
                    <div className="pdpFacNameExpt">
                      <div className="pdpFacName">{facultyMember?.firstName && facultyMember?.lastName ?`${facultyMember?.firstName} ${facultyMember?.lastName}` : facultyMember?.name}</div>
                      <div className="pdpFacExpt">
                        {facultyMember?.subject ? `${facultyMember?.subject}`: `Faculty`}
                      </div>
                      {facultyMember?.url && 
                        <div
                          className="pdpdFacDemo"
                          onClick={() => handleSelectDemo(facultyMember)}
                        >
                          <img src="/images/facTriangle.svg" alt="playIcon"/> Play Demo
                        </div>
                      }
                    </div>
                  </div>
                  <div className="pdpFacList">
                    <ul>
                      {facultyMember?.exp ? (
                        <li>
                          {facultyMember.exp.match(/(\d+)/)?.[0]}+ years of
                          Experience
                        </li>
                      ) : (
                        <li>Multiple Years of Experience</li>
                      )}
                      {facultyMember?.studentsMentored > 0 && (
                        <li>
                          More than {facultyMember.studentsMentored} Aspirants
                          Mentored
                        </li>
                      )}
                      {facultyMember?.highlights ? (
                        <li>{facultyMember?.highlights}</li>
                      ) : (
                      <li>Subject Matter Expert</li>)}
                    </ul>
                  </div>
                </div>
              );
            })}
          </NewCarouselInsta>
        </div>
      </div>
    </>
  );
};
export default FacultyProfileSlider;

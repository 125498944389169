import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import fetch from "../../../../../data/utils/fetch";
import { TEST_SERIES_URL } from "../../../../../../constants/appUrls";
import TestimonialCarouselNew from "../../../../library/TestimonialCarouselNew";
import { getPdpUrl, isADDA } from "../../../../../data/utils/helpers";
import { getActionButtonActiveStatus, getLiveTestDateTimeObj, getLiveTestState, useLiveTestAction } from "./liveTestUtils";
import { BLANK_IDENTIFIER, ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "../../../../MoengageConstants";
import { formatMilliseconds } from "../../../../utils";

export default function LiveTestBanner({ packageId}) {

  const history = useHistory();

  const [data, setData] = useState([]);
  const [quizState,setQuizState] = useState([]);
  const [centerIndex, setCenterIndex] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [timeRemainingArray, setTimeRemainingArray] = useState([]);
  const [initialQuizStates, setInitialQuizStates] = useState([]);
  
  const userDetails = useSelector(store => store.header.userInfo ? store.header.userInfo : {});

  const focus = useRef();
  const testPortalOpened= useRef();

  const [handleLiveTestAction] = useLiveTestAction(testPortalOpened);

  //For Updating Banner State
  useEffect(() => {
    const fun = () => {
      if (localStorage.getItem("isTestPortalOpened") === "yes"){ 
        // test window opened for fixed mock
        localStorage.setItem("isTestPortalOpened", "no");
        const url = `${TEST_SERIES_URL}/testseries/announcements?packageId=${packageId}`;
        fetchData(url);
      }
    }
    window.addEventListener("focus", fun);

    return () => {
      window.removeEventListener("focus", fun);
    }
  },[]);

  useEffect(() => {
    if(initialQuizStates && quizState && initialQuizStates?.length === quizState?.length) {
      initialQuizStates?.forEach((state, index) => {
        let initialQuizState = JSON.stringify(state);
        let currentQuizState = JSON.stringify(quizState[index]);
        if(initialQuizState !== currentQuizState) {
          setInitialQuizStates([...quizState]);
          const url = `${TEST_SERIES_URL}/testseries/announcements?packageId=${packageId}`;
          fetchData(url);
        }
      })
    } else setInitialQuizStates([...quizState]);
  }, [quizState])

  useEffect(() => {
    if (!data) {
      const url = `${TEST_SERIES_URL}/testseries/announcements?packageId=${packageId}`;
      fetchData(url);
      testPortalOpened.current=false
    }
    window.addEventListener("focus", ()=>{
      focus.current="focus";
      if(testPortalOpened.current)
      {
        const url = `${TEST_SERIES_URL}/testseries/announcements?packageId=${packageId}`;
        fetchData(url);
      }
         
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime(Date.now());
    }, [1000]);

    return () => { clearInterval(interval) }
  },[]); 


  const getRemainingTime = (timeLimit, submitTime, userStartTime) => {
    if(!userStartTime) return 0;
    const timeElapsed = currentTime - userStartTime;
    const remainingTime = Math.min(timeLimit*1000 - timeElapsed, submitTime - currentTime);
    if (remainingTime < 0){
        return 0;
    }
    return remainingTime;
}

  //Fetch Data from APIs:

  const fetchData = (url) => {
    fetch(url)
    .then((result) => {
      if(result && result?.data) setData(result?.data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if(packageId) {
      const url = `${TEST_SERIES_URL}/testseries/announcements?packageId=${packageId}`;
      fetchData(url);
    }
  }, [packageId]);

  useEffect(() => {
    if(data && data.length){
      const arr = new Array(data.length).fill(0);
      setTimeRemainingArray(arr);
    }
  },[data]);

  useEffect(() => {
    var arr = [];
    if(data?.length > 0) {
      var newTimeArray = new Array(data.length).fill(0);
      data?.forEach((quiz,index) => {
        let liveTestStatusObj = getLiveTestState(quiz);
      if(data[index]?.status === "INCOMPLETE") {
        let remainingTime = getRemainingTime(quiz?.timeLimit, quiz?.fixedMockSubmitTime, quiz?.attemptBefore);
        arr.push(liveTestStatusObj);
        newTimeArray[index] = formatMilliseconds(remainingTime);
      }else{
        if(liveTestStatusObj?.status === "Scheduled On") liveTestStatusObj = {...liveTestStatusObj, buttonText : "Scheduled"};
        arr.push(liveTestStatusObj);
      }      
    });}
    setTimeRemainingArray(newTimeArray);
    setQuizState(arr);
    if(!initialQuizStates) setInitialQuizStates(arr);
  }, [data, currentTime]);


  return (
    <>
    {data?.length>0 && quizState.length > 0  ? <span className="announcements-heading">Announcements</span> : ""}
    <section className="schl-stripe-wrap livetestContentWRap">
      <div className="container">

      {data?.length>0 && quizState.length > 0 ? <TestimonialCarouselNew id={"fixed-mock-banner"} show={1} infiniteLoop isFixedMockBanner={true} setCenterIndex={setCenterIndex} sliderLength={data} dataLength={data.length}>
        {data?.map((quiz,index) => {

          let testData = quiz?.testData?.[0];
          let quizStartTime = testData?.fixedMockTestStartTime;
          let quizEndTime = testData?.fixedMockSubmitTime;
          let quizAttemptBeforeTime = testData?.attemptBeforeTime;
          let quizCategory = testData?.testCategory;
          let source_screen = "announcement_banner";
          let event_name =  quizState[index]?.status === "Start" ? "starttest_announcement_clicked" : ( quizState[index]?.status === "Resume" ? "resumetest_announcement_clicked" : "" );

          //Moengage event
          var Parenttitle = testData?.title;

          let moepayload = {
            package_status: packageId == 557 ? "free" : "paid",
            name: Parenttitle,
            TestId: testData?.mappingId,
            test_id: testData?.mappingId,
            content_title: testData?.title,
            contentID: testData?.mappingId,
            id: testData?.mappingId,
            category: "TEST_SERIES",
            action:
              testData?.status == "INCOMPLETE"
                ? "resume_test"
                : testData?.status == "COMPLETED"
                ? "view_results"
                : "start_test",
            content_url: getPdpUrl(
              "TEST_SERIES",
              testData?.mappingId,
              Parenttitle
            ),
            content_subject: BLANK_IDENTIFIER,
            exam_category: WEB_IDENTIFIER,
            user_exam_category_selected: WEB_IDENTIFIER,
            deep_link: BLANK_IDENTIFIER,
            rating: BLANK_IDENTIFIER,
            index: BLANK_IDENTIFIER,
            package_purchased: BLANK_IDENTIFIER,
            exam: BLANK_IDENTIFIER,
            language: ENGLISH_IDENTIFIER,
            content_language: ENGLISH_IDENTIFIER,
            test_type: "live",
          };

          const quizDateTimeObj = getLiveTestDateTimeObj(quizStartTime, quizEndTime, quizAttemptBeforeTime);

          return (
            <div className="schl-stripe-content" key={index}>
              {quizCategory === 2 ? <div className={`fullLengthTag ${isADDA && "adda-tag"}`}>FULL LENGTH</div> : ""}
              <div className="schl-book-img-wrap">
                <img src={isADDA ? "/images/liveClassFPMbannerAdda.svg" : "/images/liveClassFPMbanner.svg"} />
              </div>
              <div className="schl-book-img-wrap lt-banner-mweb">
                <img src={isADDA ? "/images/LTBannerImgMwebAdda.svg" : "/images/LTBannerImgMwebSank.svg"} />
              </div>

              <div className="sch-all-test-content">
                <div className="schl-test-heading-banner">{quiz?.testData?.[0]?.title}</div>
                <div className="schl-bottom-row LiveCLRow">
                  <>
                    {quizState[index]?.buttonText === "Coming Soon" ? (
                      <>
                        <div className="cmgSoonWrap">
                          <span className="cmgSoonbtn">
                            Coming Soon
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="schl-home-date-time-outer liveCLTimer">
                        <span className="schl-start-at">
                           
                            Starts on{" "}
                          </span>
                          <span className="schl-home-time">
                            {`${quizDateTimeObj?.startDateWithoutYear || ""}, ${quizDateTimeObj?.startsAt || ""}`}
                          </span>

                          {testData?.cutOffMarks ? (
                            <>
                          <span className="schl-start-at cutoffWrap">
                            Cut off
                          </span>
                          <span className="schl-home-date">
                          {testData?.cutOffMarks}
                          </span>
                         </>) : ""}
                        </div>
                        <div className="startDateTimer">
                        <span className="schl-start-at">
                            Duration{" : "}
                          </span>
                          <span className="schl-home-duration">
                          {quizDateTimeObj?.duration} 
                          </span>

                         
                        </div>
                      </>
                    )}
                    <div className={ `sch-home-register-btn ${getActionButtonActiveStatus(quizState[index]?.status)}`}>
                      <Link 
                          className={`ripple`}
                          onClick={() => {
                            testPortalOpened.current=true
                            handleLiveTestAction({
                              testData : quiz, 
                              quizStatus : quizState[index]?.buttonText, 
                              reattempt : null,
                              source_screen : source_screen,
                              event_name : event_name,
                              moepayload : moepayload,
                            })
                          }}>
                          { quizState[index]?.buttonText }
                      </Link>
                    </div>
                  </>
                </div>
              </div>
            </div>
          );
        })}
        </TestimonialCarouselNew>:<></>}
      </div>
    </section></>
  );
}
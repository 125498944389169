import { wishListUrl } from "../../../../constants/appConfig";
import fetch from "../../utils/fetch";
import { getLcsServiceHeaders, isADDA } from "../../utils/helpers";
import types from "./types";

const fetchWishlistedpackages = async (userId = 0) => {
  let body = {
    userId: Number(userId),
    lastEvaluatedKey: null,
  };
  try {
    const response = await fetch(
      wishListUrl.wishlistIds,
      "POST",
      body,
      "",
      "",
      getLcsServiceHeaders()
    );
    return Promise.resolve(response?.response || {});
  } catch (error) {
    return Promise.reject(error);
  }
};
const setWishListedPackage = (data = {}) => {
  return {
    type: types.SET_WISHLISTED_PACKAGE,
    meta: {
      response: true,
      method: "LOCAL",
      wishlistPackageData: data,
    },
  };
};
const addRemoveWishList = async (likeType = 1, packageId = 0) => {
  const body = {
    discussionId: packageId,
    active: likeType === 1,
    likeType,
  };
  try {
    const response = await fetch(
      wishListUrl.wishListAddRemove,
      "POST",
      body,
      "",
      "",
      getLcsServiceHeaders()
    );
    if (response?.status >= 200 && response?.status < 300) {
      return Promise.resolve(response?.response || {});
    } else {
      throw new Error("Item not wishlisted");
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : error?.response || "An error occurred";
    return Promise.reject(errorMessage);
  }
};
const fetchWishListPackageDetails = (queryParams = {}, pageNo = 0) => {
  return {
    CALL_API: [
      {
        type: types.FETCH_WISHLISTED_PACKAGE_DETAIL,
        meta: {
          path: `${wishListUrl.wishListPackages}?${queryParams}&pageNo=${pageNo}${isADDA ? "&block3DModels=true" : ""}`,
          method: "GET",
        },
      },
    ],
  };
};
const setWishListPackageDetails = (packages = []) => {
  return {
    type: types.FETCH_WISHLISTED_PACKAGE_DETAIL,
    meta: {
      response: true,
      method: "LOCAL",
    },
    payload: {
      data: packages,
    },
  };
};
const setSelectedWishListPackageIds = (selectedWishListPackageIds = []) => {
  return {
    type: types.SET_SELECTED_WISHLIST_PACKAGE_IDS,
    meta: {
      response: true,
      method: "LOCAL",
      selectedWishListPackageIds,
    },
  };
};
const deleteBulkWishList = async (data = {}) => {
  const body = data;
  try {
    const response = await fetch(
      wishListUrl.deletBulkWishlist,
      "POST",
      body,
      "",
      "",
      getLcsServiceHeaders()
    );
    if (response?.status >= 200 && response?.status < 300) {
      return Promise.resolve(response?.response || {});
    } else {
      throw new Error("WishList not removed");
    }
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : error?.response || "An error occurred";
    return Promise.reject(errorMessage);
  }
};
const setShowFilterPopup = (isVisible = false) => {
  return {
    type: types.SHOW_FILTER_POPUP,
    meta: {
      response: true,
      method: "LOCAL",
      isVisible,
    },
  };
};
const setUserFirstWish = (flag = false) => {
  return {
    type: types.SET_USER_FIRST_TIME_WISH,
    meta: {
      response: true,
      method: "LOCAL",
      flag,
    },
  };
};
const setSelectedFacets = (facets = {}) => {
  return {
    type: types.SET_SELECTED_FACETS,
    meta: {
      response: true,
      method: "LOCAL",
      facets,
    },
  };
};
const setSelectedFiltersFacets = (facets = {}) => {
  return {
    type: types.SET_SELECTED_FACETS_FILTER,
    meta: {
      response: true,
      method: "LOCAL",
      facets,
    },
  };
};
const setConfirmDeletWishList = (data = {}) => {
  return {
    type: types.SHOW_CONFIRM_DELETE_WISHLIST,
    meta: {
      response: true,
      method: "LOCAL",
      data,
    },
  };
};
const setLastClickedWishlistItemInfo = (data = {}) => {
  return {
    type: types.SET_LAST_CLICKED_WISH_ITEM_INFO,
    meta: {
      response: true,
      method: "LOCAL",
      data,
    },
  };
};
export {
  fetchWishlistedpackages,
  setWishListedPackage,
  addRemoveWishList,
  fetchWishListPackageDetails,
  setSelectedWishListPackageIds,
  deleteBulkWishList,
  setShowFilterPopup,
  setUserFirstWish,
  setSelectedFacets,
  setWishListPackageDetails,
  setConfirmDeletWishList,
  setLastClickedWishlistItemInfo,
  setSelectedFiltersFacets,
};

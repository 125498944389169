import React from "react";
import { memo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { myPurchase } from "../../../../constants/appConfig";
import fetch from "../../../data/utils/fetch";
import { FallBackImage } from "../../../../constants/appConfig";
import { isADDA } from "../../../data/utils/helpers";

const YourExamsListings = ({ data = {}, packageId, setLoading }) => {
  const {pathname} = useLocation()
  let { selectedExamList = [] } = data;
  const history = useHistory();
  

  const handleExamClick = (examId, examName) => {
    let url = `${pathname}/${examId}/${examName}`;
    try{
      setLoading(true);
      fetch(`${myPurchase.allPackageCount}?packageId=${packageId}&exams=${examId}`).then(res => {
        setLoading(false);
        if(res.success && (res?.data?.subParentCount > 0)){
          history.push(url+"/options", res.data)
        }else{
          history.push(url);
        }
      }).catch(err => {console.log("err: ", err); setLoading(false)})
    }catch(err){
      console.log(err);
    }
  }
  if(selectedExamList?.length > 0)
  return (
    <div className="ppc-your-exams-wrap">
      <div className="ppc-your-exams-header">
        <div className="ppc-your-exams-heading">Your Exams</div>
      </div>
      <div className="ppc-your-exams-list">
        {selectedExamList?.map((exam) => (
          <Link to="#" onClick = {() => {handleExamClick(exam?.id, exam?.name)}} className="ppc-your-exams-listbox" key={exam?.id}>
            <div className="ppc-your-exams-img">
              <img src={exam?.thumbnail || FallBackImage} />
            </div>
            <div className="ppc-your-exams-name">{exam?.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
  return <></>
};

export default memo(YourExamsListings);

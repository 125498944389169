export const formatDateTime = (timeStamp, dateFormat) => {
  let d = new Date(timeStamp);
  if (dateFormat == "DD MMM YYYY") {
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da} ${mo} ${ye}`;
  }
  return "";
};

export const getUTCArrayfromTimeStamp = (timeStamp)=>{
  //  ["Sat", "Mar", "28", "2020", "4:00", "PM"]
  let dateObj = new Date(timeStamp);
  let date=dateObj.getUTCDate();
  let day = dateObj.getUTCDay();
  let month = dateObj.getUTCMonth();
  let year = dateObj.getUTCFullYear();
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  return [date,day,month,year,hours,minutes];
}

export const getTimeArray = (timeStamp)=>{
  let dateArray = getUTCArrayfromTimeStamp(timeStamp);
  let hours = dateArray[4];
  let amOrPm=hours<12?'AM':'PM';
  let hh = hours%12?hours%12:12; // padstart here
  // let minutes = minutes.pad
  let time = `${hh.toString().padStart(2,'0')}:${dateArray[5].toString().padStart(2,'0')} ${amOrPm}`;
  // ["Sat", "Mar", "28", "2020", "4:00", "PM"]

  return [DAYS[dateArray[1]],MONTHS[dateArray[2]],dateArray[0],dateArray[3],time]
}




export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

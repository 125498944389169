import React, { useState, useEffect } from "react";
import CatalogCard from "../CatalogCard";
import NewCarousel from "../library/NewCarousel";
import fetch from "../../data/utils/fetch";
import { STOREFRONT_URI, pageName } from "../../../constants/appConfig";
import { makeUrl, getDeviceType, getMoengageMetadata, getPdpUrl } from "../../data/utils/helpers";
import ContineInApp from "../library/ContinueInApp/ContinueInApp";
import { useDispatch,useSelector } from "react-redux";
import { saveRecentlyAddedCoursesData } from "../../data/ducks/header/actions";
import NewCarouselInsta from "../library/NewCarouselInsta";
import { overlayVariant } from "../../../constants/appConstants";
let carouselContainer = React.createRef();
const RecentlyAdded = (props) => {
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(0);  // -2 | 'represents End of data';
  const [appModaldata, setModalAppdata] = useState();
  const dispatch = useDispatch();
  const recentlyAddedCoursesList  = useSelector((state) => state.header.recentlyAddedCourses);

  useEffect(() => {
    if (recentlyAddedCoursesList?.length) {
      let options = {
        root: document.getElementById("recently-added"),
        rootMargin: "0px",
        threshold: 1.0,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            getNextPageData(pageNo + 1);
            setPageNo(pageNo + 1)
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById("recently-added-last-item");
      if (target) observer.observe(target);
    }
  }, [recentlyAddedCoursesList]);

  const getNextPageData = (page) => {
    if (pageNo == -1) return; // if reached end of data
    // let nextPageNo =page ? 0: pageNo + 1;
    fetch(
      `${STOREFRONT_URI}/api/v1/my/newpackages?page=${page}&size=40`,
      "GET"
    )
      .then((res) => {
        if (res && res.data) {
          setData([...data, ...res.data]);
          // setPageNo(nextPageNo);
          dispatch(saveRecentlyAddedCoursesData(res.data));
        } else {
          setPageNo(-1);
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    if(!recentlyAddedCoursesList?.length)
    getNextPageData(0);
  }, []);

  const SubCategoryMapping = {
    ONLINE_LIVE_CLASSES: {
      label: "Live Classes",
      icon: "icon-sm-live-class.svg",
    },
    TEST_SERIES: { label: "Mock Tests", icon: "icon-sm-mock-test.svg" },
    BOOKS: { label: "Book", icon: "icon-sm-book.svg" },
    VIDEOS: { label: "Video Lectures", icon: "icon-sm-video.svg" },
    EBOOKS: { label: "EBooks", icon: "icon-sm-book-pdf.svg" },
  };
  if (recentlyAddedCoursesList?.length == 0) return null;
  return (
    <section className="container-padding recentlyadded continue-studyning">
      <div className="common-headding">Recently added  <span>Modules</span></div>
      <NewCarouselInsta ref={carouselContainer} id={"recently-added"} dataLength={recentlyAddedCoursesList?.length} lastValue = {3}>
        {recentlyAddedCoursesList?.map((courseData, index) => {
             let onCardClick;

             let moengageEvent=()=>{
              var payload={};
              payload.id=courseData.id;
              payload.name=courseData.title;
              payload.package_status="Paid";
              payload.category=courseData.categories;
              payload.exam_category=courseData.courses;
              payload.package_added_timestamp=courseData.createdAt;
              payload.package_url=typeof window != undefined && window.location.origin + getPdpUrl(courseData.categories,courseData.id,courseData.title,courseData.slugURL)
               if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                'Recently_Added_click',
                getMoengageMetadata(
                    payload
                ),
            ); 
             }
          if (courseData.categories == "BOOKS") {
            onCardClick=()=>{
              alert("Please check my order section for Book")
            }
          }
          else 
          {
          let linkurl = courseData.linkedPkgs ? `/my-purchase/${courseData.id}/${makeUrl(courseData.slugURL || courseData.title)}`
            : `/my-purchase/${courseData.parentId}/${courseData.parentSlugURL}/${courseData.id}/${makeUrl(courseData.slugURL || courseData.title)}`
            
          if (getDeviceType() == "m") {
            linkurl = courseData.linkedPkgs ? `/my-purchase/${courseData.id}/${makeUrl(courseData.slugURL || courseData.title)}` : ""
            onCardClick = () => {
              setModalAppdata({
                packageId: courseData.id,
                type: courseData.categories,
              })

            }
          }
          else {
            onCardClick = () => {
              props.goTo(linkurl)
            }
          }
        }
          let subCategoriesIcons = courseData.subCategories
            ? courseData.subCategories.map((subCategory) => {
              return (
                <div className="asset-box" key={subCategory?.id}>
                  <img
                    src={`/images/${
                      SubCategoryMapping[subCategory.category].icon
                      }`}
                    className="img-icon"
                    alt={pageName[0]}
                    title={pageName[0]}
                  />
                  <span>
                    {subCategory.count == 0 ? "" : subCategory.count < 100 ? subCategory.count : "100+"}
                  </span>
                </div>
              );
            })
            : "";
          return (
            <div
              className="recentlyaddedcontainer paid-product-box"
              style={{cursor:"pointer"}}
              key={courseData.id}
              id={index == recentlyAddedCoursesList?.length - 3 ? "recently-added-last-item" : ""
              }
            >
              {/* ?  App pop up  */}
              <ContineInApp show={!!appModaldata}
                packageId={appModaldata && appModaldata.packageId}
                parentpackageId={appModaldata && appModaldata.parentPackageId}
                type={appModaldata && appModaldata.type}
                close={() => {
                  setModalAppdata();
                }}
              />
              <CatalogCard
                comp={"recent"}
                variant={overlayVariant.overlayBg}
                src={courseData.imgUrl}
                onSelect={() => {
                  onCardClick();
                   moengageEvent();
                }}
                header={courseData.title}
                heightmain={"100px"}
                widthmain={"200px"}
                text={
                  <div className="iconcss">
                    {subCategoriesIcons}
                  </div>
                }
                subHeader={courseData.languages && courseData.languages[0] && courseData.languages[0].name}
                optimizeWidth={200}
              />
            </div>
          );
        })}
      </NewCarouselInsta>
    </section>
  );
};
export default RecentlyAdded;

import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertToolTipButton from "../../components/library/AlertToolTipButton";
import CustomModal from "../../components/library/CustomModal";
import ToastContainer from "../../components/Toast/ToastContainer";
import { showButtonToolTip } from "../../data/ducks/toast/actions";
import {
  deleteBulkWishList,
  setSelectedFiltersFacets,
  setSelectedWishListPackageIds,
  setWishListedPackage,
  setWishListPackageDetails,
} from "../../data/ducks/wishlist/actions";
import { fireWishListMoengageEvent } from "../../data/utils/helpers";
import { initialFacetsKeys } from "./constants";

const DeleteWishList = ({ resetWlStateCb = () => null }) => {
  const { selectedWishListPackageIds, wishlistPackageData } = useSelector(
    (state) => state.wishlist
  );
  const [isDeletedWLVisible, setIsDeletedWLVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const dispatch = useDispatch();

  const resetWishListStoreState = () => {
    dispatch(setWishListPackageDetails([]));
    dispatch(setSelectedWishListPackageIds([]));
  };
  const updateNewWishListStore = () => {
    const { postData: existingPackageIds } = wishlistPackageData || {
      postData: [],
    };
    const updatedPackageIds = existingPackageIds?.filter(
      (item) =>
        !selectedWishListPackageIds?.includes(+item?.businessReferenceId || "")
    );
    const newWishListState = {
      ...wishlistPackageData,
      likeCount: updatedPackageIds.length,
      postData: updatedPackageIds,
    };
    // reset local state of wlParams
    resetWlStateCb();
    // reset selected facets filter state from store
    dispatch(setSelectedFiltersFacets(initialFacetsKeys));
    // Dispatch the updated wishlist state to the store
    dispatch(setWishListedPackage(newWishListState));
    // reset wishlist state when likeCount is 0
    if (!newWishListState?.likeCount) {
      resetWishListStoreState();
    }
    // fire moengage
    fireWishListMoengageEvent("wishlist_item_removed", {
      source_screen: "Wish listing",
      products_wishlisted_count: newWishListState?.likeCount,
    });
  };

  const deleteWishlistHandler = async () => {
    const payload = {
      discussionId: selectedWishListPackageIds?.map((wl) => wl?.toString()),
      active: false,
      likeType: "1",
    };
    try {
      setIsDeleteLoading(true);
      await deleteBulkWishList(payload);
      // update new wishlist store after deleting..
      updateNewWishListStore();
      dispatch(
        showButtonToolTip({
          isToolTipVisible: true,
          toolTipName: "DELETE_WL",
          tooltipText: `${selectedWishListPackageIds?.length} Item${
            selectedWishListPackageIds?.length > 1 ? "s" : ""
          } removed from Wishlist`,
        })
      );
      setIsDeleteLoading(false);
      toggleDeleteWL();
    } catch (error) {
      setIsDeleteLoading(false);
      dispatch(
        showButtonToolTip({
          isToolTipVisible: true,
          toolTipName: "DELETE_WL",
          tooltipText: error,
        })
      );
      toggleDeleteWL();
    }
  };

  const toggleDeleteWL = () => {
    setIsDeletedWLVisible(!isDeletedWLVisible);
  };
  const isDisabled = useMemo(
    () => !selectedWishListPackageIds?.length,
    [selectedWishListPackageIds]
  );
  return (
    <>
      <AlertToolTipButton
        toolTipName="DELETE_WL"
        iconElement={<img src="/images/wlDlt.svg" alt="swl" />}
        tooltipText={`${selectedWishListPackageIds?.length} Item${
          selectedWishListPackageIds?.length > 1 ? "s" : ""
        } removed from Wishlist`}
        wrapClassName="wldltWrap"
        iconClassName="deleteWLBtn"
        tooltipClassName="tooltiptextDeleteWL"
        onClickCb={toggleDeleteWL}
        disabled={isDisabled}
        isHoverToolTipEnable={false}
      />
      <CustomModal
        visible={isDeletedWLVisible}
        title={
          <div key="packages-header" className={"modal-header"}>
            Confirm Delete
          </div>
        }
        onCancel={toggleDeleteWL}
        closeCallback={toggleDeleteWL}
        closable={true}
        modalClassName={"wishlist-popup"}
      >
        <div>
          <p>Are you sure you want to delete the wishlisted items?</p>
          <div className="wishdltbottom">
            <button onClick={toggleDeleteWL}>Cancel</button>
            <button onClick={deleteWishlistHandler} disabled={isDeleteLoading}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default React.memo(DeleteWishList);

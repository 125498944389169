import { Cookies } from "react-cookie";
import React, { useMemo, useState } from "react";
import CustomModal from "../../components/library/CustomModal";
import { useSelector } from "react-redux";
import {
  copyToClipBoard,
  fireWishListMoengageEvent,
  getDeviceType,
} from "../../data/utils/helpers";
import AlertToolTipButton from "../../components/library/AlertToolTipButton";
import { WEB_HOST_URL } from "../../../constants/appUrls";
import SocialShare from "../../components/library/SocialShare";
import { DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
const cookie = new Cookies();

const ShareWishList = () => {
  const [isShareWLVisible, setIsShareWLVisible] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const {
    wishlistPackageData,
    selectedWishListPackageIds,
    wishlistPackageDetails: { packages = [] } = {},
  } = useSelector((state) => state.wishlist);
  const isWebView = getDeviceType() === DEVICE_IDENTIFIERS?.DESKTOP_IDENTIFIER;

  const userId = cookie.get("cp_user_id");

  const toggleShareWL = () => {
    setIsShareWLVisible(!isShareWLVisible);
  };

  const copyShareUrlHandler = (urlToCopy = "") => {
    copyToClipBoard(urlToCopy)
      .then(() => {
        // fire moengage
        fireWishListMoengageEvent("wishlist_shared", {
          source_screen: "Wish listing",
          products_wishlisted_count: wishlistPackageData?.likeCount,
        });
        setCopiedText("Link copied");
        const toolTipTimeout = setTimeout(() => {
          setCopiedText("");
          clearTimeout(toolTipTimeout);
        }, 5000);
        return;
      })
      .catch(() => setCopiedText("Failed to copy link!."));
  };
  const disableShare = useMemo(
    () => !!selectedWishListPackageIds?.length,
    [selectedWishListPackageIds]
  );
  const shareUrl = `${WEB_HOST_URL}/wl/${userId}`;
  const userName = useMemo(() => {
    const userData = wishlistPackageData?.userData?.[userId];
    if (!userData) return "";
    const fullName =
      (userData?.firstName ? userData.firstName + " " : "") +
      (userData?.lastName ? userData.lastName : "");
    return fullName;
  }, [wishlistPackageData, userId]);
  return (
    <>
      <AlertToolTipButton
        toolTipName="SHARE_WL"
        iconElement={<img src="/images/wlUpload.svg" alt="swl" />}
        tooltipText="Share your Wishlist"
        wrapClassName="shrewLWrap"
        iconClassName="shareWL"
        tooltipClassName="tooltiptextShare"
        onClickCb={toggleShareWL}
        disabled={disableShare || packages?.length < 1} // disable share if any wishlist item is selected or not wishlist present
        isHoverToolTipEnable={true}
      />
      <CustomModal
        visible={isShareWLVisible}
        title={
          <div key="packages-header" className={"modal-header"}>
            Share
          </div>
        }
        onCancel={toggleShareWL}
        closeCallback={toggleShareWL}
        closable={true}
        modalClassName={"wishlist-popup sharewishlist"}
      >
        <div>
          <div className="mobileBox">
            <p>Hey Aspirant!</p>
            <p>
              Boost your preparation with these courses curated by your friend '
              {userName}'
            </p>
          </div>
          <div
            className="wlsharebottom"
            style={{
              display: "flex",
            }}
          >
            {isWebView ? (
              <>
                <input type="text" value={shareUrl} readOnly />
                <button
                  className="copy-btn"
                  onClick={() => copyShareUrlHandler(shareUrl)}
                >
                  Copy
                </button>
                {copiedText && <span class="linkcopied">{copiedText}</span>}
              </>
            ) : (
              <SocialShare
                textToShare={shareUrl}
                onClickCb={(plt) => {
                  fireWishListMoengageEvent("wishlist_shared", {
                    source_screen: "Wish listing",
                    shared_channel: plt,
                    products_wishlisted_count: wishlistPackageData?.likeCount,
                  });
                }}
              />
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default React.memo(ShareWishList);

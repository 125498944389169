import React from 'react';
import { Cookies } from "react-cookie";
import { pageName } from '../../../constants/appConfig';
import FirstWishListGuideToolTip from '../../containers/WishList/FirstWishListGuideToolTip';
import { isADDA } from '../../data/utils/helpers';
const cookie = new Cookies();

const CustomDropdown = (props) => {
    const profilePic=cookie.get("user_profile_pic")||'/images/user-login-icon.svg'
    
    return (
        <div className="dropdown" onMouseEnter={() => {document.querySelector(".signin-up-wrap .dropdown .dropdown-content").style.display ="block";}} onMouseLeave={() => {document.querySelector(".signin-up-wrap .dropdown .dropdown-content").style.display ="none";}} style={{ 'width': '210px', 'right': '10px' }}>
            {props.text &&
                <button className="dropbtn">
                    <div className='login-avatar-wrapper'>
                        <div className={'avatar-logged-in'}><img src={profilePic} alt={pageName[0]} title={pageName[0]} width='30px' height='30px'/></div>
                        <span className={'user-name-loggedin'}>{props.text.length > 15 ? props.text.substring(0, 14) + '...' : props.text}</span>
                        <img style={{ 'paddingLeft': '10px' }} src={'/images/arrow-user-login-icon.svg'} alt={pageName[0]} title={pageName[0]} width='20px' height='5px'/>
                    </div>
                </button>
            }
            <div className="dropdown-content">
                {props.children}
            </div>
         {isADDA && <FirstWishListGuideToolTip/>}
        </div>

    );
};

export default CustomDropdown;

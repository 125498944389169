import React, { Component, useState } from "react";
import Myaccountnavbar from "./Myaccountnavbar";
import MyaccountForm from "./MyaccountForm";
import ForgotPasswordForm from "../components/library/ForgotPasswordForm";
import ResetPasswordForm from "../components/library/ResetPasswordForm";
import { Cookies } from "react-cookie";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CustomLoader from "../components/library/CustomLoader";
import fetch from "../data/utils/fetch";
import { pageName, pdpUrls } from "../../constants/appConfig";
import CustomHelmet from "../components/library/CustomHelmet";
import { isADDA } from "../data/utils/helpers";
import PersonalInformation from "./PersonalInformation";
const cookie = new Cookies();
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      userDetails: [],
      dataloader: true,
      forgetPassShow: false,
      resetPassShow: false,
      otpVal: null,
    };
  }

  requestHeaders = () => {
    return {
      "Content-Type": "application/json",
      "X-Auth-Token": "fpoa43edty5",
      "X-JWT-Token": cookie.get("cp_token"),
    };
  };

  defaultMetaTitle = `My Profile`;
  defaultMetaDesc = `My Profile contains all study materials purchased by me.`;

  fetchData = () => {
    fetch(
      `${pdpUrls.userApi}/api/v2/profile`,
      "GET",
      null,
      null,
      this.requestHeaders()
    )
      .then((data) => this.setState({ data }))
      .catch((error) => this.setState({ error }));
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    let email = cookie.get("cp_user_email");
    let name = cookie.get("cp_user_name");
    const token = cookie.get("cp_token");

    if (token && this.props.userInfo == undefined) {
      return (
        <div style={{ margin: "50px" }}>
          <CustomLoader buttonSpinner={false} />
        </div>
      );
    } else if (token) {
      return (
        <div className="myprofile-container">
          <style
            dangerouslySetInnerHTML={{
              __html: `
                        .web-footer{
                            margin-top:0px !important;
                        }
                        `,
            }}
          />
          <div className="myAcountSlieMenu">
            <Myaccountnavbar />
          </div>

          <CustomHelmet
            title={this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].metaTitle
                ? this.props.seoData[pathname].metaTitle
                : this.defaultMetaTitle}
            description={this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].metaDescription
                ? this.props.seoData[pathname].metaDescription
                : this.defaultMetaDesc}
            url={
              (this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].canonical) ||
              this.props.req_url
            }
            keywords={
              this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].keywords
            }
            noFollow={
              this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].noFollow
            }
            noIndex={
              this.props.seoData &&
              this.props.seoData[pathname] &&
              this.props.seoData[pathname].noIndex
            }
          />

          <div className="myAcountForm">
            {this?.state?.forgetPassShow ? (
              this?.state?.resetPassShow ? (
                <ResetPasswordForm otpVal={this?.state?.otpVal} />
              ) : (
                <ForgotPasswordForm
                  setOTPValue={(e) =>
                    this.setState({ otpVal: e, resetPassShow: true })
                  }
                  setForgetPassShow={(e) =>
                    this.setState({ forgetPassShow: e })
                  }
                  setResetPasswordShow={(e) =>
                    this.setState({ resetPassShow: e })
                  }
                />
              )
            ) : (
              isADDA ? <MyaccountForm
                setForgetPassShow={(e) => this.setState({ forgetPassShow: e })}
                data={this.state?.data?.data?.hasPassword}
              /> : <PersonalInformation profileData={this.state.data}/>
            )}
          </div>
        </div>
      );
    } else {
      // if(typeof this.props.isLoggedIn =='undefined')
      // {
      //   return   (<CustomLoader/>)
      // }
      //   else
      //   {

      //   }
      return <Redirect to="/#show-login" />;
    }
  }
}

const mapStateToProps = (state) => {
  const { productDetailListing } = state;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  return {
    productDetailListing,
    utmParams,
    userInfo,
    isLoggedIn,
  };
};
export default connect(mapStateToProps)(MyAccount);

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import fetch from 'isomorphic-fetch';
import { Redirect } from 'react-router-dom'
import Cookies from 'js-cookie';

import { withRouter } from 'react-router';
import FilterList from '../components/filter/FilterList';

import { isEmpty, isSet } from '../data/utils/helpers';
import { prodUrl, pageName } from '../../constants/appConfig';
import { getCpOriginValue } from '../data/utils/helpers';
import CustomButton from '../components/library/CustomButton';
import CustomLoader from '../components/library/CustomLoader';
import CustomHelmet from '../components/library/CustomHelmet';
import { STORE_URI } from '../../constants/appUrls';


class MyMockTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePanel: 'paid',
      mockData: [],
      loading: true
    };
  }
  static propTypes = {
    // getMockTests: PropTypes.func,
    // mockTestData: PropTypes.object,
    // clearData: PropTypes.func
  };


  componentDidMount() {
    typeof window !== 'undefined' && window.scrollTo(0, 0);
    this.getMockTest()
  }

  requestHeaders = () => {
    return {
      'origin': prodUrl || `http://stagingstore2.${pageName[0]}.com`,
      'cp-origin': getCpOriginValue(!!Cookies.get('cp_user_email')),
      'Content-Type': 'application/json',
      'X-Auth-Token': 'fpoa43edty5',
      'X-JWT-Token': Cookies.get('cp_token')
    };
  }

  getMockTest = (price) => {
    const options = {
      method: 'GET',
      headers: this.requestHeaders()
    };
    fetch(`${STORE_URI}/api/v1/test-series${price === '0' ? '?price=0' : ''}`, options)
      .then(
        res => {
          return res.json().then(response => {
            this.setState({
              mockData: response.data,
              loading: false
            })
            return resolve(response)
          })
        }
      )
      .catch(() => {
        // setInValidaState({ ...invalidState, pincode: true });
      });
  }

  handleMockType = type => {
    this.setState({
      activePanel: type,
      mockData: [],
      loading: true
    })

    if (type === 'free') {
      this.getMockTest('0');
    } else {
      this.getMockTest();
    }
  }

  render() {
    const { activePanel, mockData, loading } = this.state;
    if (isSet(Cookies.get('cp_token'))) {

      return (
        <>
          <CustomHelmet
            title={`${pageName[1]} - Mock Test`}

          />

          <div className="main-container-account">
            <header className='header-title'>
              <div className='text-container'>
                Mock Tests
              </div>
            </header>
            <section>
              <aside className='sub-sidebar'>
                <div className='item' onClick={() => this.handleMockType('paid')}>
                  <div className={`item-text ${activePanel === 'paid' ? ' mock-active-panel' : ''}`}>Mock Test</div>
                </div>
                <div className='item' onClick={() => this.handleMockType('free')}>
                  <div className={`item-text ${activePanel === 'free' ? ' mock-active-panel' : ''}`}>Free Mocks</div>
                </div>
              </aside>
              <article className='list-container'>
                <div className='package-list'>
                  {
                    !isEmpty(mockData) ? (
                      <FilterList
                        filterList={mockData}
                        handleDetailCardClick={() => { }}
                      />
                    ) : (loading ? <div style={{ 'margin': '0 auto' }}><CustomLoader /></div> : <div className='no-result'>
                      <img src={`https://storecdn.${pageName[0]}.com/ic_empty_generic.svg`} alt={pageName[0]} title={pageName[0]} />
                      <section>
                        <div>You have not purchased any test-series yet</div>
                        <div>
                          <div onClick={() => { this.props.history.push(getUrlPathObject('/mock-tests')) }} className={'login-btn zero-state-buy-button'}>BUY NOW</div>
                        </div>
                      </section>
                    </div>)
                  }

                </div>
              </article>
            </section>
          </div>
        </>
      );
    }

    return (
      <Redirect to={'/'} />
    )

  }
}






export default withRouter(connect(null, null)(MyMockTest));

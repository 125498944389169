import React, { useState, useEffect } from "react";
import { getDiscount, getMoengageMetadata,optimizedImgUrl } from "../data/utils/helpers";
import { FallBackImage, pageName } from "../../constants/appConfig";
import InfoButtonLarge from "../components/library/InfoButton/InfoButtonLarge";
import InfoButton from "../components/library/InfoButton/InfoButton";
import ContentSection from "../components/library/ContentSection/ContentSection";
import ToggleSectionComponent from "../components/library/ToggleSectionComponent/ToggleSectionComponent";
import CustomLoader from "./library/CustomLoader";

import { isEmpty, numberWithCommas, getDeviceType, getPostBody, trackMoengageEvent } from "../data/utils/helpers";
import SectionComponent from "../components/library/SectionComponent/SectionComponent";
import { Link } from "react-router-dom";

export const PriceComponent = (maxPrice, price, priceDetails,viewOffers, setViewOffer, setViewOfferModalOnly, setFromBuyNowBtn, notPurchasedAndPublished, isCouponApplied, isDefaultCouponAvailable) => {
  return (
    <div className = {"price " +  (notPurchasedAndPublished && (isCouponApplied || isDefaultCouponAvailable) ? "price2" : "")}>
      <div className={"price-tag ml-0"}>
        <span className="selling-price">&#8377;{numberWithCommas(price)}</span>
        {
          maxPrice !== price ? <>       {getDiscount(maxPrice, price) >= 0 ? (
            <>
              {" "}
              <span className="maximum-retail-price">
                <strike>&#8377; {numberWithCommas(maxPrice)}</strike>
              </span>
              {
                  priceDetails?.discountType == 'flat' ? <span className="off2">Fixed Price</span>  : <span className="off2">({getDiscount(maxPrice, price)}% off)</span>
              }  
            </>
          ) : null}</> : null
        }

      </div>

      {notPurchasedAndPublished && <ViewOffers viewOffers={viewOffers} setViewOffer={setViewOffer} setViewOfferModalOnly={setViewOfferModalOnly} setFromBuyNowBtn={setFromBuyNowBtn} isCouponApplied = {isCouponApplied} isDefaultCouponAvailable = {isDefaultCouponAvailable}/>}
    </div>
  );
};

export const ViewOffers = ({ viewOffers, setViewOffer, moedata, isLoggedIn, productData, setViewOfferModalOnly, setFromBuyNowBtn, isCouponApplied, isDefaultCouponAvailable }) => {

  const setValue = (value) => {
    setFromBuyNowBtn(false)
    setViewOfferModalOnly(true)

    if (viewOffers) viewOffers(value);
    sessionStorage.setItem("buyNow_Offer", "offer");
    if (setViewOffer) setViewOffer(value);
    // if(isLoggedIn){
    // window.location.replace("#checkout/viewOffers");
    window.location.hash = "#checkout/viewOffers";
    // }
  };
  
  return (
    <>
    <div
      className={((isCouponApplied || isDefaultCouponAvailable)? "moreOffers ": "viewOffers ") + (isCouponApplied && window?.innerWidth < 767? "lm-10": "")}
      onClick={() => {
        setValue(true);
      }}
      style = {{width: "86px"}}
    >
      {isCouponApplied ? 
        window?.innerWidth > 767 ? "More Offers >": "View More Offers"
        : 
        (window?.innerWidth > 767 && isDefaultCouponAvailable) ? "View Offers >": "View Offers"
      }
    </div>
    </>
  );
};
export const PreRequisites = () => {
  return (
    <ul className="preRequisiteList">
      <li>Internet connectivity of minimum 5mbps</li>
      <li>Headphone with microphone </li>
      <li>Laptop, Desktop, Tablet or Android Phone </li>
      <li>Notebook, pen to note concepts during class </li>
    </ul>
  );
};
export const ExamIcon = (props) => {
  return (
    <div className="examIcon">
      <div>
        <img
          src={optimizedImgUrl(props.url) || FallBackImage}
          height="48px"
          width="48px"
          alt={pageName[0]}
          title={pageName[0]}
        ></img>
      </div>
      <div className="label">{props.label}</div>
    </div>
  );
};

export const CourseDetailsSection = ({ hlsJson }) => {
  if (
    !hlsJson ||
    hlsJson.length == 0 ||
    !hlsJson[0] ||
    (hlsJson.length == 1 && !hlsJson[0].hlsJson)
  )
    return null;
  return (
    <div className="courseHighlights">
      <span className="courseHighlightHeading">Course Highlights</span>
      <ul>
        {hlsJson.map((hlsItem,index) => {
          if (hlsItem.hlsJson == "") return null;
          return <li key={index}>{hlsItem.hlsJson}</li>;
        })}
      </ul>
    </div>
  );
};

export const AppliedCouponInfo = ({
  appliedCoupon,
  couponDiscount,
  Getpayload,
  payablePrice,
  price,
  userEmail,
  moedata,
  setRemoveCouponFlag,
  isDefaultCouponAvailable,
  applyDefaultCouponFlag,
  setApplyDefaultCouponFlag,
  defaultCouponCode,
  isPurchased,
  published
}) => {
  const [prevCoupon, setprevCoupon] = useState("");
  const [removeFlag, setRemoveFlag] = useState(false);

  useEffect(() => {
    if(appliedCoupon && couponDiscount){
      setRemoveFlag(false);
    }
  },[appliedCoupon && couponDiscount])

  useEffect(() => {
    if (couponDiscount && appliedCoupon !== prevCoupon && Getpayload) {
      var payload = Getpayload;
      payload.couponCode = appliedCoupon;
      payload.couponDiscount = couponDiscount;
      payload.user_exam_category_selected = "Web";
      payload.package_url = window?.location?.href || '_blank';
      if (typeof Moengage !== "undefined")
        Moengage.track_event(
          "coupon_code_successfully_applied",
          getMoengageMetadata(payload)
        );
    }
  }, [couponDiscount]);
  useEffect(() => {
    setTimeout(() => {
      setprevCoupon(appliedCoupon);
    }, 5000);
  }, [appliedCoupon]);

  if(isPurchased || !published){
    return <></>
  }

  return (
    <>
      {/* {couponDiscount && appliedCoupon !== prevCoupon && (
        <div className="couponAppliedInfo">
          Coupon '{appliedCoupon || "SUB20"}' Applied
        </div>
      )} */}
      {
        appliedCoupon && couponDiscount ? <div className="couponAppliedInfo">
          <div><img src = "/images/productOffIcon2.svg" style = {{width: "20px"}}/> "{appliedCoupon || "SUB20"}" coupon applied</div>
          {!removeFlag ? <div onClick = {() => {setRemoveCouponFlag(true); setRemoveFlag(true)}} style = {{cursor: "pointer"}}><img src = "/images/ic_cross2.svg"/></div>:
            <div><CustomLoader buttonSpinner = {true}/></div>
          }
        </div> :
        isDefaultCouponAvailable ?
            <div className="couponAppliedInfo">
              <div><img src="/images/productOffIcon2.svg" style={{ width: "20px" }} /> "{defaultCouponCode}" coupon available</div>
              {!applyDefaultCouponFlag ? <div onClick={() => {setApplyDefaultCouponFlag(true) }} className = "applyButton">APPLY</div> :
                <div><CustomLoader buttonSpinner={true} /></div>
              }
            </div>:
          null
      }

    </>
  );
};

export const FaqSection = ({ faqJson }) => {
  if (!faqJson || faqJson.length == 0 || isEmpty(faqJson[0])) return null;

  function trackFaqClick(question) {
    let payload = {};
    payload.FAQ_question = question;
    payload.action = "FAQ clicked";
    trackMoengageEvent("Faq_Question_Clicked", payload)
  }

  let faqObj = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };
  faqJson.map((faq) => {
    let tempFaq = {};
    if (faq["qN"]) {
      tempFaq["@type"] = "Question";
      tempFaq["name"] = faq["qN"];
      tempFaq["acceptedAnswer"] = {
        "@type": "Answer",
        text: faq["qN"],
      };
      faqObj["mainEntity"].push(tempFaq);
    }
  });

  return (
    <div className="faqSection">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(faqObj),
        }}
      />

      <ContentSection
        header="Frequently Asked Questions"
        body={
          <div>
            {faqJson.map((faq) => {
              if (!faq.qN) {
                return null;
              }
              return (
                <ToggleSectionComponent
                  header={`Q: ${faq.qN}`}
                  body={faq.aN}
                  click={trackFaqClick}
                ></ToggleSectionComponent>
              );
            })}
          </div>
        }
      ></ContentSection>
    </div>
  );
};
export const SubCategoriesList = ({ ProductData, onClick }) => {
 
let reorderSubcategories = [
    {category: 'FREE_CONTENT'},
    {category:'MODELS_3D'},
    {category:'ONLINE_LIVE_CLASSES'},
    {category:'TEST_SERIES'},
    {category:'VIDEOS'},
    {category:'EBOOKS'},
    {category:'BOOKS'},
]
reorderSubcategories = reorderSubcategories?.map(category => ProductData?.subCategories?.find( item => item.category == category.category))
reorderSubcategories = reorderSubcategories?.filter(item => item)
return reorderSubcategories?.map((subCatData) => {
    return subCatData?.category != "ONLINE_LIVE_CLASSES" ? (
      <InfoButtonLarge
        key={subCatData?.category}
        id={subCatData.id}
        count={subCatData.count}
        label={subCatData.category}
        onClick={onClick}
      ></InfoButtonLarge>
    ) : (
      <InfoButtonLarge
        key={subCatData.category}
        id={subCatData.id}
        count={subCatData.vduration ? parseInt(subCatData?.vduration) : 0}
        label={subCatData.category}
        onClick={onClick}
      ></InfoButtonLarge>
    );
  });
};
export const ExamIconList = ({ ProductData }) => {
  let items = [];
  let currentList = [];
  let size = getDeviceType() == "m" ? 3 : 7;
  ProductData.examTypes.forEach((examData, index) => {
    // if (index % size == 0 && index !== 0) {
    //   items.push(currentList);
    //   currentList = [];
    // }
    currentList.push(
      <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>
    );
    // if(index<3) {
    //   items[0].push([
    //     <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>
    //   ]);
    // } else {
    //   items[1].push([
    //     <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>
    //   ]);
    // }
    // items.push(
    //   <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>);
    /* if (index % 2) {
      items[items.length - 1].push(
        <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>
      );
    } else {
      items.push([
        <ExamIcon label={examData.tab} url={examData.thumbnail}></ExamIcon>
      ]);
    } */
  });
  if (currentList.length) items.push(currentList);

  // items = items.concat(items);
  return (
    <>
      {" "}
      {items.map((data, index) => {
        return <div key={index} className="examIconContainer">{data}</div>;
      })}
    </>
  );
};

export const InfoButtonList = ({ ProductData, activeValidityData }) => {
  let bookFlag = false;
  if (ProductData.subCategories.some(el => el.category === "BOOKS") && ProductData.subCategories.length == 1) {
    bookFlag = true;
  }
  let InfoButtonList = [];
  // "start": "05-Feb-2020",
  // "class": "12:00PM- 06:30PM",
  // "seats": 250
  if (activeValidityData?.language) {
    InfoButtonList.push(
      <InfoButton
        icon="languageIcon"
        label={`${activeValidityData?.language}`}
      ></InfoButton>
    );
  }
  if (ProductData.olcMetaJson) {
    let olcMetaJsonData = ProductData.olcMetaJson;
    InfoButtonList = InfoButtonList.concat(
      Object.keys(olcMetaJsonData).map((keyname) => {
        switch (keyname) {
          case "start":
            return (
              <InfoButton
                icon="startDateIcon"
                label={`Starts: ${olcMetaJsonData[keyname]}`}
              ></InfoButton>
            );
          case "class":
            return (
              <InfoButton
                icon="classTimingIcon"
                label={`Timing:${olcMetaJsonData[keyname]}`}
              ></InfoButton>
            );
          case "seats":
            return (
              <InfoButton
                icon="seatsIcon"
                label={`${olcMetaJsonData[keyname]} seats`} // no point of having single seat
              ></InfoButton>
            );
          default:
            return null;
        }
      })
    );
  }
  if (activeValidityData?.validity) {
    InfoButtonList.push(
      <InfoButton
        bookFlag={bookFlag}
        icon="calendarIcon"
        label={`Validity: `}
        labelMonth={`${ProductData.dualValidity ? `${activeValidityData.validity / 2}+${activeValidityData.validity / 2}` : activeValidityData.validity} Months`}
      ></InfoButton>
    );
  }
  return InfoButtonList;
};

export const ProductSchema = ({ data, props, activeValidityData }) => { //
  if (!data || !data.id) return null;
  let months = activeValidityData?.validity || 1
  let dt = new Date();
  let validUntil = new Date(dt.setMonth(dt.getMonth() + months)).toISOString().split("T")[0];
  let schemaObj = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: data.title,
    image: [data.imgUrl],
    description: data.description,
    sku: data.id,
    mpn: data.id,
    brand: {
      "@type": "Brand",
      name: pageName[1],
    },
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5"
      },
      author: {
        "@type": "Organization",
        name: pageName[1]
      }
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.7",
      reviewCount: "315"
    },
    offers: {
      "@type": "Offer",
      url: props.req_url,
      priceCurrency: "INR",
      price: data.price,
      priceValidUntil: validUntil,
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schemaObj),
      }}
    />
  );
};
export const LatestBlogs = ({ posts_data }) => {
  if (!posts_data || posts_data.length == 0 || isEmpty(posts_data[0])) return null;
  return (
    <div className="blogSection">
      <SectionComponent
        header="News & Updates"
        body={
          <ul>
            {
              posts_data.map((post, index) => {
                return <li key={index}>
                  <h3><a href={post.link} target="_blank">{post.title.rendered}</a></h3>
                </li>
              })
            }
          </ul>
        }
      ></SectionComponent>
    </div>
  );
};

export const CallNowWidget = ({ contact, deviceType, Getpayload }) => {

  const [tooltipText, setTooltipText] = useState("Click to Copy");

  const copyContactToClipboard = (contact) => {
    navigator.clipboard.writeText(contact)
      .then(() => {
        setTooltipText("Copied to Clipboard");
        const toolTipTimeout = setTimeout(() => {
          setTooltipText("Click to Copy");
          clearTimeout(toolTipTimeout);
        },5000);
        return;
      })
      .catch(() => console.error('Failed to copy phone number to clipboard.'));
    };

  const handleCallNowInWeb = () => {
    callNowMoengage();
    if(deviceType === "d"){
      copyContactToClipboard(contact);
    }
  }

  const handleCallNowInMWeb = () => {
    callNowMoengage();
    if(deviceType !== "d"){
      window.open(`tel:${contact}`, '_blank');
    }
  }

  const callNowMoengage = () => {
    let payload = Getpayload();
    let moePayload = getMoengageMetadata(payload);
    moePayload.Position = "Top";
    moePayload.exam_category_selected = "Web";
    moePayload.URL = location?.href;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "call_now_button_clicked",
        moePayload
      );
    }
  }

  return (
    <div className={'callUsInnerWrap callnow-tooltip'}onClick={() => { handleCallNowInWeb() }}>
      <div className="callUsInner">
          <div className="imgCallUs"><img src="/images/callUsImg.svg" /></div>
          {
            deviceType === 'd' ? 
            <div className="dataCallUs">For Admission Enquiry: Call at <span>{contact}</span></div> :
            <div className="dataCallUs">For Admission Enquiry <span>{'(' + contact + ')'}</span></div> }
          <div className="callBtn"><Link to="#" onClick={() => { handleCallNowInMWeb() }}>CALL NOW</Link></div>
      </div>
      <span class="callnow-tooltiptext">{tooltipText}</span>
    </div>
  )
}
import React from "react";
import CustomLoader from "../../../components/library/CustomLoader";
import PropTypes from "prop-types";
const SearchResultsContainer = ({
  loading,
  heading,
  headerComponent,
  children,
}) => {
  return (
    <div className="search-courses">
      <div className="full-width-title">{heading}</div>
      {headerComponent}
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="all-category-list">{children}</div>
      )}
    </div>
  );
};
SearchResultsContainer.defaultProps = {
  loading: false,
  heading: "",
  headerComponent: null,
  children: <div />,
};

SearchResultsContainer.propTypes = {
  loading: PropTypes.bool,
  heading: PropTypes.string,
  headerComponent: PropTypes.any,
  children: PropTypes.node,
};
export default SearchResultsContainer;

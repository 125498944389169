import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga";
import {
  courseItems,
  pdpUrls,
  productItems,
  searchUrlEndPoint,
  storeHostUrl,
  storeItemsUrlMapping,
  userPassword,
  pageName,
  FallBackImage,
} from "../../../constants/appConfig";
import {
  LOGGED_IN_CP_ORIGIN_MAP,
  QUIZ_BUTTON_STATES_ENUM,
  viewAllKeys,
} from "../../../constants/appConstants";
import {
  imageOptimiserBaseUrl,
  STOREFRONT_URI,
  TIMELINE_URI,
} from "../../../constants/appUrls";
import fetch from "./fetch";
import { BLANK_IDENTIFIER, ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "../../components/MoengageConstants";
import { useEffect } from "react";

const isADDA = pageName[0] === "adda247";

const isEmpty = (param) => {
  if (param !== null) {
    if (Array.isArray(param) && param.length) {
      return false;
    }
    if (typeof param === "object" && Object.keys(param).length) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};
function hasValue(v) {
  if (typeof v !== "undefined" && v !== "") {
    return true;
  }
  return false;
}

const isSet = (value) => {
  if (value === null || value === "" || value === undefined) {
    return false;
  }
  return true;
};

const getDataFromRedisByProductId = (productId) => {
  // Redis.get(`recommendedProducts_${productId}`, function (err, recommendations) {
  //     return recommendations;
  // });
  return {
    abc: 123,
  };
};

const isEmailValid = (emailId) => {
  const reg =
    /^([A-Za-z0-9_\.\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|]{1,64})\@([A-Za-z0-9_\-\.]){1,253}\.([A-Za-z]{2,})$/;
  const reg2 = /^(?!.*[\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\.\@]{2}).*$/; //no consecutive special character
  const reg3 =
    /^[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\`\~\{\}\[\]\\\|\;\:\'\"\,\<\.\>\/\?]/; //starts with special character

  if (
    reg.test(emailId) === false ||
    reg2.test(emailId) === false ||
    reg3.test(emailId) === true
  ) {
    return false;
  }

  return true;
};

const isUserPasswordValid = (password) => {
  return !!(
    password &&
    password.length > userPassword.minLength &&
    password.length <= userPassword.maxLength
  );
};

const isUserNameValid = (name) => {
  if (name && name.length) {
    const reg = /^[a-zA-Z ]*$/;
    return reg.test(name) !== false;
  }
  return false;
};

const removeSpacesAndInsertHyphens = (text) => {
  text = text.replace(/[\. )/(,:-]+/g, "-");
  if (text.charAt(text.length - 1) === "-") {
    text = text.substr(0, text.length - 1);
  }
  return text;
};

const cookieCreate = (name, value) => {
  const [cookies, setCookie] = useCookies([name]);
  setCookie(name, value, { path: "/" });
};

const getSubstringAfterChar = (str, char) => {
  if (str) {
    return str.substring(str.indexOf(char) + 1);
  } else {
    return "";
  }
};

const getSubstringBeforeChar = (str, char) => {
  if (str) {
    return str.substring(0, str.indexOf(char));
  } else {
    return "";
  }
};

const capitalizedString = (str) => {
  if (typeof str == "string") {
    str = (str || "").toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

const capitalizeWords = (str) => {
  if (typeof str != "string") return str;
  return str
    .split(" ")
    .map((word) => capitalizedString(word))
    .join(" ");
};

const removeHyphensAndInsertSpaces = (str) => str.replace(/-/g, " ");

const timeSince = (date) => {
  let minute = 60;
  let hour = minute * 60;
  let day = hour * 24;
  let month = day * 30;
  let year = day * 365;

  let suffix = " ago";

  let elapsed = Math.floor((Date.now() - date) / 1000);

  if (elapsed < minute) {
    return "just now";
  }

  // get an array in the form of [number, string]
  let a = (elapsed < hour && [Math.floor(elapsed / minute), "minute"]) ||
    (elapsed < day && [Math.floor(elapsed / hour), "hour"]) ||
    (elapsed < month && [Math.floor(elapsed / day), "day"]) ||
    (elapsed < year && [Math.floor(elapsed / month), "month"]) || [
      Math.floor(elapsed / year),
      "year",
    ];

  // pluralise and append suffix
  return a[0] + " " + a[1] + (a[0] === 1 ? "" : "s") + suffix;
};

const getDiscount = (mrp, sp) => Math.round(((mrp - sp) / mrp) * 100);

const feedPostCategories = {
  ca: "Current Affairs",
  ts: "Daily Quiz",
  ja: "Job Alerts",
  ar: "Articles",
  ad: "Advertisement",
};

const isValidCharCheck = (data) => {
  let value = data?.trim();
  var format =
    /^[A-Za-z\d\s0-9.,”&@#()/*:{}[\]]*[.,”&@#()/*:{}[\]]*[A-Za-z\d\s0-9.,”&@#()/*:{}[\]]*$/;
  if (!format.test(value)) {
    return true;
  } else {
    return false;
  }
};
const isValidAddressCheck = (data) => {
  let value = data?.trim();
  var format =
    /^[A-Za-z\d\s0-9.,”&@#()/*:{}[\]-]*[.,”&@#()/*:{}[\]-]*[A-Za-z\d\s0-9.,”&@#()/*:{}[\]-]*$/;
  if (!format.test(value)) {
    return true;
  } else {
    return false;
  }
};

const isEmptyField = (value, minLength = 0) => {
  value = value || "";
  if(minLength) {
    return value?.trim().length < minLength;
  } else {
    return !value || !(value.trim && value.trim());
  }
};
const isPhone = (value) => {
  return (
    !isEmptyField(value) &&
    value.length == 10 &&
    value.match("[6-9]{1}[0-9]{9}")
  );
};
const isPinCode = (value) => {
  if (typeof value === "number") {
    value = value + "";
  }
  return !isEmptyField(value) && value.length == 6;
};

function makeUrl(url, needSameCase) {
  if (!url) return "";
  url = url.split("(").join("");
  url = url.split(")").join(" ");
  url = url.trim();
  url = url.replace(/-\)/g, "");
  url = url.replace(/[^a-zA-Z0-9]/g, "-");

  url = url.replace(/-+/g, "-");

  if (needSameCase) {
    return url;
  }

  return url.toLowerCase();
}

function loginUserDetail() {
  let userDetails = {};

  let user_name = Cookies.get("cp_user_name");
  let user_email = Cookies.get("cp_user_email");
  let user_id = Cookies.get("cp_user_id");
  let examCategory = Cookies.get(`cp_user_${user_id}`);
  let user_paid_status = Cookies.get("cp_paid_user");
  userDetails.user_name = user_name;
  userDetails.user_email = user_email;
  userDetails.user_id = user_id;
  userDetails.examCategory = examCategory;
  userDetails.paid_status = user_paid_status === "true" ? "1" : "0";

  return userDetails;
}
const getExamCategoryId = (examdata, examname) => {
  let examCategory;

  for (const [key, value] of Object.entries(examdata || {})) {
    value.map((elem) => {
      if (
        removeSpacesAndInsertHyphens(elem.name.toLowerCase()) ==
        examname.toLowerCase()
      ) {
        examCategory = key;
      }
    });
  }
  return examCategory;
};

const getTopExamtypes = (examdata, examCategory) => {
  let examTypes = [];
  examCategory = examCategory.replaceAll("-", "_");
  let examCategoryStr = examCategory.toUpperCase();
  if (examCategoryStr == "CIVIL_ENGINEERING") {
    examCategoryStr = "ENGINEERING";
  }
  if (examCategoryStr) {
    examTypes = examdata[examCategoryStr].slice(0, 7);
  } else {
    // examCategory=examCategory.replace(/-/g, '_');
    examTypes = examdata[examCategoryStr].slice(0, 7);
  }
  return examTypes;
};

function createArticleUrl(item) {
  let title = item;
  title = removeSpacesAndInsertHyphens(title);
  title = title.replace(/[^a-zA-Z0-9\-]/g, "");
  return title;
}

// extract quiz ids from the timeline data
const getTestSeriesIds = (feedData) => {
  const quizIdArr = [];
  const quizObjectArr = feedData.filter((o) => o.type === "TS");
  if (!isEmpty(quizObjectArr)) {
    const quizPackageId = quizObjectArr[0].dailyQuizPkg || 0;
    quizObjectArr.forEach((e) => {
      quizIdArr.push(e[e.type].id);
    });
    return [quizPackageId, quizIdArr];
  }
  return [];
};

const massageFeedDataInReducerForQuizStatus = (feedData, testStatusData) => {
  const quizObjectArr = feedData.filter((o) => o.type === "TS");
  quizObjectArr.forEach((e) => {
    const testId = testStatusData.filter((obj) => obj.mappingId === e.TS.id);
    if (!isEmpty(testId)) {
      e.TS.testStatus = testId[0].testState;
    }
  });
  return quizObjectArr;
};

const unionWith = (arr1, arr2) => {
  return [...new Set([...arr1, ...arr2])];
};

const getQuizButtonText = (status) => QUIZ_BUTTON_STATES_ENUM[status];

const createStorefrontPdpUrl = (category, id, title, referrer) => {
  let utmStr = "";
  if (!isEmpty(referrer)) {
    referrer.map((e) => {
      const currentObj = e;
      utmStr =
        utmStr +
        `${Object.keys(currentObj)[0]}=${
          currentObj[Object.keys(currentObj)[0]]
        }&`;
    });
  }
  utmStr = utmStr.slice(0, -1);

  const storeCat = storeItemsUrlMapping[category];
  return `${storeHostUrl}/${
    category.toLowerCase() === "videos" ? "comprehensive" : "product"
  }-${storeCat}/${id}/${makeUrl(title)}?${utmStr}`;
};

const validateProductName = (product) => productItems.includes(product);

const validateCourseName = (courseName) => courseItems.includes(courseName);

const removeElementsFromLastInArray = (arr, n) => {
  arr.splice(arr.length - n, arr.length);
  return arr;
};

function cleanObj(obj) {
  let propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i++) {
    let propName = propNames[i];
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      !obj[propName].length
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function maskString(str) {
  let new_string = "";
  if (isSet(str)) {
    const charactersToMask = str.substring(0, str.indexOf("@")).length;
    // 1. Replace first charactersToMask characters with *
    for (let i = 0; i < charactersToMask; i++) new_string += "*";

    // 3. Add in the last charactersToMask characters from original string.
    new_string += str.substring(charactersToMask);
  }
  return new_string;
}

function getParameterByName(name = "") {
  const query = window.location.search.toString();
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  const results = regex.exec(query);
  if (results == null) return "-";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getAllUTMParams(
  defaultUtmParams = {
    utmSource: "not_set",
    utmMedium: "not_set",
    utmContent: "not_set",
    utmCampaign: "not_set",
    utmTerm: "not_set",
  }
) {
  var utmSource = getParameterByName("utm_source"),
    utmMedium = getParameterByName("utm_medium"),
    utmContent = getParameterByName("utm_content"),
    utmCampaign = getParameterByName("utm_campaign"),
    gclid = getParameterByName("gclid"),
    utmTerm = getParameterByName("utm_term");

  if (utmSource && utmSource !== "-")
    sessionStorage.setItem("utm_source", utmSource);
  else
    utmSource =
      sessionStorage.getItem("utm_source") || defaultUtmParams.utmSource;

  if (utmMedium && utmMedium !== "-")
    sessionStorage.setItem("utm_medium", utmMedium);
  else
    utmMedium =
      sessionStorage.getItem("utm_medium") || defaultUtmParams.utmMedium;

  if (utmContent && utmContent !== "-")
    sessionStorage.setItem("utm_content", utmContent);
  else
    utmContent =
      sessionStorage.getItem("utm_content") || defaultUtmParams.utmContent;

  if (utmCampaign && utmCampaign !== "-")
    sessionStorage.setItem("utm_campaign", utmCampaign);
  else
    utmCampaign =
      sessionStorage.getItem("utm_campaign") || defaultUtmParams.utmCampaign;

  if (utmTerm && utmTerm !== "-") sessionStorage.setItem("utm_term", utmTerm);
  else utmTerm = sessionStorage.getItem("utm_term") || defaultUtmParams.utmTerm;

  if (gclid && gclid !== "-") sessionStorage.setItem("gclid", gclid);
  else gclid = sessionStorage.getItem("gclid");

  return {
    utmSource: utmSource == null ? "" : utmSource,
    utmMedium: utmMedium == null ? "" : utmMedium,
    utmContent: utmContent == null ? "" : utmContent,
    utmCampaign: utmCampaign == null ? "" : utmCampaign,
    utmTerm: utmTerm == null ? "" : utmTerm,
    gclid: gclid == null ? "" : gclid,
  };
}

function getMoengageMetadata(data) {
  let utmParams = getAllUTMParams();
  let userdetail = loginUserDetail();

  if (!utmParams) {
    utmParams = data.getUtmFromStore || "";
  }

  if (typeof window != "undefined" && localStorage.getItem("cp-origin")) {
    utmParams.utmSource = "Paytm";
    utmParams.utmContent = "miniapppaytm";
    utmParams.utmMedium = "paytmmweb";
    utmParams.utmCampaign = "paytmminiapp";
    utmParams.utmTerm = "paytm";
  }
  if (!data) {
    data = {};
  }

  // Create payload with the condition

  let PayloadMoe = {};
  Object.assign(
    PayloadMoe,
    data && data.productType && { productType: data.productType },
    data && data["cp-origin"] && { "cp-origin": data["cp-origin"] },

    // Quiz and Media
    data && data.Button_URL && { Button_URL: data.Button_URL },
    data && data.Type && { Type: data.Type },

    // order failure
    data && data.orderFailureDat && { orderFailureData: data.orderFailureData },
    data &&
      data?.exam_category && {
        exam_category:
          data?.exam_category === BLANK_IDENTIFIER ? "" : data?.exam_category,
      },
    data &&
      data?.exam && { exam: data?.exam === BLANK_IDENTIFIER ? "" : data?.exam },

    data && data.product_type && { product_type: data.product_type },
    data && data.subject && { subject: data.subject },
    data && data.chapter && { chapter: data.chapter },
    data && data.topic && { topic: data.topic },
    data && data.isPPc && { isPPc: data.isPPc },
    data && data.secondConsumed && { secondConsumed: data.secondConsumed },

    data &&
      data.package_added_timestamp && {
        package_added_timestamp: data.package_added_timestamp,
      },
    data && data.login_status && { login_status: data.login_status },

    data &&
      data.Repurchase_btn_clicked_flag && {
        Repurchase_btn_clicked_flag: data.Repurchase_btn_clicked_flag,
      },
    //langauge
    data && data.language && { language: data.language },
    data && data.language_selected && { language_selected: data.language_selected },

    data &&
      data.section_type && {
        section_type: data.section_type === "_blank" ? "" : data.section_type,
      },
    //sort
    data && data.option && { option: data.option },
    //filter Search
    data &&
      data.filter_search_tickedSomething && {
        filter_search_tickedSomething: data.filter_search_tickedSomething,
      },
    data &&
      data.filter_search_started && {
        filter_search_started: data.filter_search_started,
      },
    data &&
      data.filter_search_tickedSomething && {
        filter_search_tickedSomething: data.filter_search_tickedSomething,
      },

    //search
    data && data.state && { state: data.state },
    data && data.term && { term: data.term },

    data &&
      data.no_of_results_found && {
        no_of_results_found: data.no_of_results_found,
      },

    //filter
    data && data.sort_selected && { sort_selected: data.sort_selected },
    data && data.packageUrl && { packageUrl: data.packageUrl },
    data && data.action && { user_action: data.action },
    data &&
      data.sub_package_title && { sub_package_title: data.sub_package_title },
    data && data.time_selected && { time_selected: data.time_selected },
    data && data.schedule_id && { schedule_id: data.schedule_id },
    data && data.userId && { userId: data.userId },
    data && data.teacher_name && { teacher_name: data.teacher_name },
    data && data.package_name && { package_name: data.package_name },
    data &&
      data.Product_Category_Selected && {
        Product_Category_Selected: data.Product_Category_Selected,
      },
    data &&
      data.Subject_selected && { Subject_selected: data.Subject_selected },
    data && data.Tags_Selected && { Tags_Selected: data.Tags_Selected },
    data &&
      data.Faculty_Selected && { Faculty_Selected: data.Faculty_Selected },
    data && data.Exam_Selected && { Exam_Selected: data.Exam_Selected },
    data &&
      data.Exam_Category_Selected && {
        Exam_Category_Selected: data.Exam_Category_Selected,
      },
    data && data.Exam_Category && { Exam_Category: data.Exam_Category },
    data &&
      data.Sort_option_choosed && {
        Sort_option_choosed: data.Sort_option_choosed,
      },
    data && data.Search_Term && { Search_Term: data.Search_Term },
    data && data.start_schedule_time && { start_schedule_time: data.start_schedule_time },
    //Exam and productcategory
    data &&
      data.productCategory_selected && {
        productCategory_selected: data.productCategory_selected,
      },
    data &&
      data.examCategory_selected && {
        examCategory_selected: data.examCategory_selected,
      },
    data && data.examCategory && { examCategory: data.examCategory },
    //mahapack
    data &&
      data.original_package_id && {
        original_package_id: data.original_package_id,
      },
    data &&
      data.mahapack_widget_clicked && {
        mahapack_widget_clicked: data.mahapack_widget_clicked,
      },

    // Package details

    data && data.package_status && { package_status: data.package_status },
    data && data.time_left && { time_left: data.time_left },
    data && data.category && { package_type: data.category },
    data && data.primary_category && { primary_category: data.primary_category },
    data && data.name && { package_title: data.name },
    data && data.id && { package_id: data.id },
    data &&
      data.subPackageId && { sub_package_id: parseInt(data.subPackageId) },
    data && data.subPackageType && { sub_package_type: data.subPackageType },
    data && data.sellingPrice && { price: data.sellingPrice },
    data &&
      data.packageActualPrice && {
        package_actual_price: data.packageActualPrice,
      },
    data &&
      data.packageDiscountedPrice && {
        package_discounted_price: data.packageDiscountedPrice,
      },
    data &&
      data.subPackageId && {
        child_is_parent: data.subPackageId ? data.subPackageId == data.id : "",
      },
    data &&
      data.package_final_price && {
        package_final_price:
          data.package_final_price || data.packageDiscountedPrice || 0,
      },
    data &&
      data.package_url && {
        package_url: data.package_url || (location && Location.href),
      },
    data &&
      data.total_final_amount && {
        total_final_amount: data.total_final_amount,
      },
    data && data.total_final_amount === 0 && { total_final_amount: 0 },
    data?.default_coupon && {
      default_coupon: data.default_coupon,
    },
    data?.default_coupon_name && {
      default_coupon_name:
        data.default_coupon_name === BLANK_IDENTIFIER
          ? ""
          : data.default_coupon_name,
    },
    data?.sample_content && {
      sample_content: data.sample_content,
    },
    data?.sample_content_count && {
      sample_content_count: data.sample_content_count,
    },
    data?.multivalidity && {
      multivalidity: data.multivalidity,
    },
    data?.package_final_price && {
      package_final_price: data?.package_final_price,
    },
    data?.user_exam_category_selected && {
      user_exam_category_selected:
        data?.user_exam_category_selected === BLANK_IDENTIFIER
          ? ""
          : data?.user_exam_category_selected,
    },
    data?.source_screen && {
      source_screen:
        data?.source_screen === BLANK_IDENTIFIER ? "" : data?.source_screen,
    },
    data?.modified_date && {
      modified_date:
        data?.modified_date === BLANK_IDENTIFIER ? "" : data?.modified_date,
    },
    data?.published_date && {
      published_date:
        data?.published_date === BLANK_IDENTIFIER ? "" : data?.published_date,
    },
    data?.package_url && {
      package_url:
        data?.package_url === BLANK_IDENTIFIER ? "" : data?.package_url,
    },
    data?.deep_link && {
      deep_link: data?.deep_link === BLANK_IDENTIFIER ? "" : data?.deep_link,
    },
    data?.examNameClicked && {
      examNameClicked:
        data?.examNameClicked === BLANK_IDENTIFIER ? "" : data?.examNameClicked,
    },
    data?.package_subject && {
      package_subject:
        data?.package_subject === BLANK_IDENTIFIER ? "" : data?.package_subject,
    },
    data?.content_id && {
      content_id: data?.content_id === BLANK_IDENTIFIER ? "" : data?.content_id,
    },
    data?.content_title && {
      content_title:
        data?.content_title === BLANK_IDENTIFIER ? "" : data?.content_title,
    },
    // Transation
    data?.transactionID && { transaction_id: data.transactionID },
    data &&
      data.transaction_date && { transaction_date: data.transaction_date },
    data &&
      data?.package_final_price && {
        transaction_amount:
          data.package_final_price == 0
            ? data.package_final_price
            : data.package_final_price,
      },
    //content detail
    data && data.contentTitle && { content_title: data.contentTitle },
    data && data.contentID && { content_id: parseInt(data.contentID) },
    data && data.contentUrl && { content_url: data.contentUrl },
    data && data.position && { content_position: data.position },
    data && data.position && { position: data.position },
    data && data.endTime && { endTime: data.endTime },
    data && data.startTime && { startTime: data.startTime },
    data && data.Position && { position: data.Position },
    //  Cookies.get("cp_user_") && {exam_category_selected: Cookies.get("cp_user_")},

    //cart

    data && data.is_cart_true && { is_cart_true: data.is_cart_true || false },
    data && data.source_path && { source_path: data.source_path },

    //coupon
    data && data?.appliedCoupon && { appliedCoupon: data.appliedCoupon },
    data && data?.couponDiscount && { coupon_discount: data.couponDiscount },
    data &&
      data?.couponCode && {
        coupon_code:
          data?.couponCode === BLANK_IDENTIFIER ? "" : data?.couponCode,
      },
    data &&
      data?.source_screen && {
        source_screen:
          data?.source_screen === BLANK_IDENTIFIER ? "" : data?.source_screen,
      },
    data && data?.error && { transaction_couponcode_error: data.error },
    data &&
      data?.package_actual_price && {
        package_actual_price: data.package_actual_price,
      },
    data &&
      data?.package_discounted_price && {
        package_discounted_price: data.package_discounted_price,
      },
    data &&
      data?.package_title && {
        package_title:
          data?.package_title === BLANK_IDENTIFIER ? "" : data?.package_title,
      },
    data &&
      data?.package_id && {
        package_id:
          data?.package_id === BLANK_IDENTIFIER ? "" : data?.package_id,
      },
    data &&
      data?.PID && { PID: data?.PID === BLANK_IDENTIFIER ? "" : data?.PID },
    data &&
      data?.package_type && {
        package_type:
          data?.package_type === BLANK_IDENTIFIER ? "" : data?.package_type,
      },
    data &&
      data?.package_status && {
        package_status:
          data?.package_status === BLANK_IDENTIFIER ? "" : data?.package_status,
      },

    //payment
    data && data?.payment_gateway && { payment_gateway: data.payment_gateway },
    data && data.payment_mode && { payment_mode: data.payment_mode },

    // video played
    data && data.reached && { reached: data.reached },
    data && data.rechedTheEnd && { rechedTheEnd: data.rechedTheEnd },
    data?.play && { play: data.play },
    data && data.pause & { pause: data.pause },
    data && data.startTime && { startTime: data.startTime },
    data && data.endTime && { endTime: data.endTime },
    data &&
      data.videoPlayDuration && { videoPlayDuration: data.videoPlayDuration },

    //utm params and gclid

    utmParams && utmParams.utmSource && { utmSource: utmParams.utmSource },
    utmParams && utmParams.utmMedium && { utmMedium: utmParams.utmMedium },
    utmParams && utmParams.utmContent && { utmContent: utmParams.utmContent },
    utmParams &&
      utmParams.utmCampaign && { utmCampaign: utmParams.utmCampaign },
    utmParams && utmParams.utmTerm && { utmTerm: utmParams.utmTerm },
    utmParams && utmParams.gclid && { gclid: utmParams.gclid },
    //user agent
    navigator && navigator.userAgent && { userAgent_1: navigator.userAgent },

    //test series
    data && data?.attemptedDate && { attemptedDate: data.attemptedDate },
    data && data?.title && { title: data.title },
    data && data?.testId && { testId: data.testId },
    data && data?.test_id && { test_id: data.test_id },
    data && data?.test_type && { test_type: data.test_type },
    data && data?.live_attempt && { live_attempt: data.live_attempt },
    data && data?.non_live_attempt && { non_live_attempt: data.non_live_attempt },
    data &&
      data?.rating && {
        rating: data?.rating === BLANK_IDENTIFIER ? "" : data?.rating,
      },
    data && data?.markScored && { markScored: data.markScored },
    data && data?.timeSpent && { timeSpent: data.timeSpent },
    data && data?.totalTime && { totalTime: data.totalTime },
    data &&
      data.attemptedPercentage && {
        attemptedPercentage: data.attemptedPercentage,
      },
    data && data.correct_qnCount && { correct_qnCount: data.correct_qnCount },
    data &&
      data.incorrect_qnCount && { incorrect_qnCount: data.incorrect_qnCount },
    data && data.totalQuestions && { totalQuestions: data.totalQuestions },
    data &&
      data.unattempted_qnCount && {
        unattempted_qnCount: data.unattempted_qnCount,
      },
    data && data.oa_rank && { oa_rank: data.oa_rank },
    data &&
      data.oa_average_score && { oa_average_score: data.oa_average_score },
    data &&
      data.oa_highest_score && { oa_highest_score: data.oa_highest_score },
    data && data.sectionalData && { sectionalData: data.sectionalData },
    data && data.Link_text && { Link_text: data.Link_text },
    data && data.Link_URL && { Link_URL: data.Link_URL },
    data && data.Link_type && { Link_type: data.Link_type },
    data && data.FAQ_question && { FAQ_question: data.FAQ_question },
    data &&
      data?.content_url && {
        content_url:
          data?.content_url === BLANK_IDENTIFIER ? "" : data?.content_url,
      },
    data &&
      data?.index && {
        index: data?.index === BLANK_IDENTIFIER ? "" : data?.index,
      },
    data &&
      data?.package_purchased && {
        package_purchased:
          data?.package_purchased === BLANK_IDENTIFIER
            ? ""
            : data?.package_purchased,
      },
    data &&
      data?.content_language && {
        content_language:
          data?.content_language === BLANK_IDENTIFIER
            ? ""
            : data?.content_language,
      },

    // Get App Link
    data?.moe_logged_in_status && {
      moe_logged_in_status: data.moe_logged_in_status,
    },
    data?.moe_first_visit && { moe_first_visit: data.moe_first_visit },
    data?.mobile_number && { mobile_number: data.mobile_number },

    // Wishlist
    data?.products_wishlisted_count && {
      products_wishlisted_count: data?.products_wishlisted_count
    },
    data?.product_purchased_state && {
      product_purchased_state: data?.product_purchased_state
    },
    data?.shared_channel && {
      shared_channel: data?.shared_channel
    },
    data?.wishlisted_item_purchased && {
      wishlisted_item_purchased: data?.wishlisted_item_purchased
    }
  );

  //user detail
  // data && data.user_mobile_no && { user_mobile_no: data.user_mobile_no},
  // (data && data.user_email) || (userdetail && userdetail.user_email ) && { user_email: data.user_email || userdetail.user_email},
  // (data && data.user_name) || (userdetail && userdetail.user_name ) && { user_name: data.user_name || userdetail.user_name},
  if ((data && data.d_cart_count) || data.cart_count) {
    PayloadMoe.cartproduct_Count = data.d_cart_count || data.cart_count;
  }
  if (data && data.user_mobile_no) {
    PayloadMoe.user_mobile_no = data.user_mobile_no;
  }

  if ((data && data.user_email) || (userdetail && userdetail.user_email)) {
    PayloadMoe.user_email = data.user_email || userdetail.user_email;
  }
  if ((data && data.userId) || (userdetail && userdetail.user_id)) {
    PayloadMoe.userId = data.userId || userdetail.user_id;
    PayloadMoe.user_id = data.userId || userdetail.user_id;
  }
  if (userdetail && userdetail.examCategory) {
    PayloadMoe.exam_category_selected = userdetail.examCategory;
  }
  if ((data && data.user_name) || (userdetail && userdetail.user_name)) {
    PayloadMoe.user_name = data.user_name || userdetail.user_name;
  }
  if ((data && data.paid_status) || (userdetail && userdetail.paid_status)) {
    PayloadMoe.Paid_Status = data.paid_status || userdetail.paid_status;
  }

  if ((data && data.page_title) || (document && document.title))
    PayloadMoe.page_title = data.page_title || document.title;

  if (data && data.signup_source) {
    PayloadMoe.signup_source = data.signup_source;
  }
  if (data && data.mapping_id) {
    PayloadMoe.mapping_id = data.mapping_id;
  }
  if (data && data.productTitle) {
    PayloadMoe.productTitle = data.productTitle;
  }
  if (data && data.content_exam) {
    PayloadMoe.content_exam = data.content_exam;
  }
  if (data && data.content_subject) {
    if (data.content_subject === BLANK_IDENTIFIER)
      PayloadMoe.content_subject = "";
    else PayloadMoe.content_subject = data.content_subject;
  }

  // Onboarding
  data && data?.Exist_Status && { Exist_Status: data.Exist_Status },

  PayloadMoe.platform_1 =
    getDeviceType() == "m"
      ? "MWEB"
      : typeof window != "undefined" && localStorage.getItem("cp-origin")
      ? "WEBVIEW"
      : "WEB";
  return PayloadMoe;
}

const moeKeyFacetObj = {
  langFacets: "Language",
  categoryFacets: "Product_Category_Selected",
  subjectFacets: "Subject_selected",
  tagsFacets: "Tags_Selected",
  facultyFacets: "Faculty_Selected",
  examTypesFacets: "Exam_Selected",
  coursesFacets: "Exam_Category_Selected",
  searchTerm: "Search_Term",
};

const fireMoengageEvent = (
  actionName,
  moePayload = {},
  eventName = "filter"
) => {
  let payload = { source: "MWEB", brand: pageName[1] };
  payload.action = actionName;

  if (Object.keys(moePayload)?.length) {
    payload = { ...payload, ...moePayload };
  }

  if (typeof Moengage !== "undefined") {
    Moengage.track_event(eventName, getMoengageMetadata(payload));
  }
};

const getMoengagePayloadFromFacets = (facetObj) => {
  let moeData = {};
  Object.keys(facetObj)?.forEach((facet) => {
    moeData[moeKeyFacetObj[facet]] = facetObj[facet];
  });
  return moeData;
};

const replaceUnderscoreWithSpace = (str) => {
  if (isSet(str)) {
    return str.replace(/_/g, " ");
  }
  return str;
};
const replaceHyphenWithUnderscore = (str) => {
  if (isSet(str)) {
    return str.replace(/-/g, "_");
  }
  return str;
};
const replaceHyphenWithSpace = (str) => {
  if (isSet(str)) {
    return str.replace(/-/g, " ");
  }
  return str;
};
const replaceUnderscoreWithHyphen = (str) => {
  if (isSet(str)) {
    return str.replace(/_/g, "-");
  }
  return str;
};
const logPageView = (url) => {
  ReactGA.pageview(url);
  let payload = {};
  payload.userAgent_1 = navigator.userAgent;

  trackMoengageEvent("page_view_et", payload);
  // if (typeof (window) != 'undefined') {
  //   window.setTimeout(() => {
  //     if (typeof (Moengage) !== 'undefined') Moengage.track_event("page_view_et", getMoengageMetadata(payload));
  //   }, 3000);
  // } else if (typeof (Moengage) !== 'undefined') {
  //   if (typeof (Moengage) !== 'undefined') Moengage.track_event("page_view_et", getMoengageMetadata(payload));
  // }
};

const trackMoengageEvent = (eventName, payload) => {
  if (typeof window != "undefined") {
    window.setTimeout(() => {
      if (typeof Moengage !== "undefined")
        Moengage.track_event(eventName, getMoengageMetadata(payload));
    }, 3000);
  } else if (typeof Moengage !== "undefined") {
    if (typeof Moengage !== "undefined")
      Moengage.track_event(eventName, getMoengageMetadata(payload));
  }
};

const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left,
    top: rect.top,
  };
};

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const createOptimizedImgUrl = (url, width, height) => {
  if (!url || (url && url.indexOf(".pdf") !== -1)) {
    return FallBackImage;
  }
  url = url.replace("http://img.adda247.com/image?p=", "");
  return `${imageOptimiserBaseUrl}${url}?tr=w-${width}`;
};
const optimizedImgUrl = (url) => {
  if (!url || (url && url.indexOf(".pdf") !== -1)) {
    return FallBackImage;
  }
  url = url.replace(imageOptimiserBaseUrl, "");
  return `${imageOptimiserBaseUrl}${url}`;
};
const sendDataToCretio = (ev) => {
  /* if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
    const email = Cookies.get("cp_user_email") || "";
    window.criteo_q = window.criteo_q || [];
    var deviceType = getDeviceType();
    window.criteo_q.push(
      { event: "setAccount", account: 68128 },
      // You should never update this line
      { event: "setEmail", email: email }, // Can be an empty string
      { event: "setSiteType", type: deviceType },
      ev
    );
  } */
};

// return: type of device, is code is running on serverSide it returns 's';
const getDeviceType = () => {
  if (typeof navigator !== "undefined") {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent
        )
      ? "m"
      : "d";
  }
  return "s";
};

const getUserDeviceType = () => {
  let deviceType = "";
  if (typeof window != "undefined") {
    if (window?.navigator.userAgent.match(/Android/i)) {
      deviceType = "android";
    } else if (window?.navigator.userAgent.match(/iPhone/i)) {
      deviceType = "iphone";
    } else {
      deviceType = "window";
    }
  }
  return deviceType;
};

const removePPKG = (str) => {
  if (str.includes("ppkg_")) {
    return str.replace("ppkg_", "");
  }
  if (str.includes("cpkg_")) {
    return str.replace("cpkg_", "");
  }
  return str;
};
const getCpOriginValue = (isLoggedIn) => {
  let deviceType = getDeviceType();
  // if(!isLoggedIn){
  //   return LOGGED_OUT_CP_ORIGIN_MAP[deviceType];
  // }
  return LOGGED_IN_CP_ORIGIN_MAP[deviceType];
};

const getYoutubeId = (youtubeUrl) => {
  const YOUTUBE_URL_PATTERN =
    /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  let matchedData = youtubeUrl.match(YOUTUBE_URL_PATTERN);
  if (matchedData && matchedData.length && matchedData.length >= 2) {
    return matchedData[2];
  }
  return "";
};
const getYoutubeIframeUrl = (youtubeVideoUrl) => {
  return `https://www.youtube.com/embed/${getYoutubeId(
    youtubeVideoUrl
  )}?autoplay=1`;
};

const scrollToTop = (scrollDuration) => {
  scrollDuration = scrollDuration || 100;
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function () {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
};
const scrollToElement = (element) => {
  if (!element) return "";
  document.getElementById(element)?.scrollIntoView({
    behavior: "smooth",
    block: "end",
  });
};
const scrollToElementWithOffset = (element, offsetVal) => {
  if (!element) return "";

  const offset =
    document.getElementById(element).getBoundingClientRect().top +
    window?.pageYOffset;
  window.scrollTo({
    top: offset - offsetVal,
    behavior: "smooth",
  });
};

const scrollToHorizontalElementWithOffset = (
  containerElement,
  element,
  offsetVal
) => {
  if (!containerElement || !element) return "";

  const targetElement = document.getElementById(element);
  const container = document.querySelector(containerElement);
  if (targetElement && container) {
    const targetRect = targetElement.getBoundingClientRect();
    const scrollLeft =
      targetRect.left + container.scrollLeft - container.offsetLeft;
    const offset =
      document.getElementById(element).getBoundingClientRect().top +
      window?.pageYOffset;
    window.scrollTo({
      top: offset - offsetVal,
      behavior: "smooth",
    });
    container.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  }
};

const numberWithCommas = (x) => {
  x = x || "0";
  if (x - parseInt(x) > 0) {
    x = x.toFixed(2);
  }

  x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return x;
  //return Math.round(parseFloat(x) * 100) / 100;
};

const timestampToDateTime = (ts) => {
  let dateObj = new Date(ts);
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getUTCSeconds();

  let formattedTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0");
  let formattedDate = dateObj.toDateString();
  return formattedDate + " " + formattedTime;
};

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
};

const updateHash = (hashUrl) => {
  //history.replaceState(undefined, undefined, "#"+hashUrl);
  window.location.hash = hashUrl;
};

const getPinCodeInfo = (pincode, callbackFunction) => {
  if (pincode && pincode.length == 6) {
    fetch(`${pdpUrls.pincodeListing}/${pincode}.json`).then(
      (res) => {
        let data = res[0];
        if (data) {
          callbackFunction(data);
        } else {
          callbackFunction(undefined);
        }
      },
      (err) => {
        // setUserDetails({...userDetails,state:data.State,city:data.City})
        callbackFunction(undefined);
      }
    );
  }
};

const getPdpUrl = (category, packageId, title, slugurl) => {
  let categoryName = viewAllKeys[category]
    ? viewAllKeys[category]
    : category?.split(",")[0]; // because we are not sure of types
  let url = "";
  let urlTitleToBeUsed = slugurl && slugurl != "" ? slugurl : title;
  let urlTitle = makeUrl(urlTitleToBeUsed);
  switch (categoryName) {
    case "VIDEOS":
      url = `/product-comprehensive-video/${packageId}/${urlTitle}`;
      break;

    case "ONLINE_LIVE_CLASSES":
      url = `/product-onlineliveclasses/${packageId}/${urlTitle}`;
      break;

    case "TEST_SERIES":
      url = `/product-testseries/${packageId}/${urlTitle}`;
      break;
    case "EBOOKS":
      url = `/product-ebooks/${packageId}/${urlTitle}`;
      break;
    case "BOOKS":
      url = `/product-books/${packageId}/${urlTitle}`;
      break;
    case "MODELS_3D":
      url = `/product-3dmodels/${packageId}/${urlTitle}`;
      break;
    default:
      url = `/product/${packageId}/${urlTitle}`;
  }
  return url;
};

const getUrlPathObject = (url, queryParams) => {
  let trackingQueryParams =
    sessionStorage.getItem("tracing_query_params") || "";
  // if(sessionStorage.getItem('tracing_query_params'))trackingQueryParams+=localStorage.getItem('tracing_query_params')
  let searchParams = queryParams
    ? `${queryParams}&${trackingQueryParams}`
    : `?${trackingQueryParams}`;
  return {
    pathname: url,
    search: searchParams,
  };
};
const getUrlPath = (url, queryParams) => {
  let pathObj = getUrlPathObject(url, queryParams);
  if (pathObj.search) return `${pathObj.pathname}?${pathObj.search}`;
  return pathObj.url;
};

const msToTime = (s) => {
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  if (hrs) return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
  else return pad(mins) + ":" + pad(secs);
};
const msToTimeHMS = (s) => {
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  if (hrs) return pad(hrs) + "h:" + pad(mins) + "m:" + pad(secs) + "s";
  else return "00h:" + pad(mins) + "m:" + pad(secs) + "s";
};

const parseCourseName = (cname) => {
  cname = cname || "";
  if (cname.toLowerCase() == "iitneet") {
    cname = "IIT/MEDICAL";
  }
  if (cname.toLowerCase() == "gate_iitjam") {
    cname = "GATE & IITJAM";
  }

  return cname.split("_").join(" ");
};

const loadJsFile = (filepath, callback) => {
  var script = document.createElement("script");
  script.onload = function () {
    if (callback) {
      callback();
    }
  };
  script.src = filepath;
  document.head.appendChild(script);
};

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const parseUrlForSeo = (url) => {
  url = url || "";
  return url.split("/").join("-");
};

const isIosSafari = () => {
  if (typeof window !== "undefined") {
    let userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i)) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};
const getPostBody = (str) => {
  if (str === null || str === "") return null;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "").substring(0, 50);
};
const removeScripts = () => {
  var youtubeScripts = document.querySelectorAll(
    'script[src="/images/videojs/Youtube.js"]'
  );
  for (var j = youtubeScripts.length - 1; j >= 0; --j) {
    youtubeScripts[j].remove();
  }

  var videojsQualityeScripts = document.querySelectorAll(
    'script[src="/images/videojs/videojs-quality-switcher.js"]'
  );
  for (var k = videojsQualityeScripts.length - 1; k >= 0; --k) {
    videojsQualityeScripts[k].remove();
  }

  var videojshttpScripts = document.querySelectorAll(
    'script[src="/images/videojs/videojs-http-streaming.js"]'
  );
  for (var l = videojshttpScripts.length - 1; l >= 0; --l) {
    videojshttpScripts[l].remove();
  }

  var videojsScripts = document.querySelectorAll(
    'script[src="/images/videojs/video.js?v=1"]'
  );
  for (var m = videojsScripts.length - 1; m >= 0; --m) {
    videojsScripts[m].remove();
  }

  var shakaPlayerScripts = document.querySelectorAll(
    'script[src="/images/shaka-player.ui.min.js"]'
  );
  for (var n = shakaPlayerScripts.length - 1; n >= 0; --n) {
    shakaPlayerScripts[n].remove();
  }

  var muxScripts = document.querySelectorAll('script[src="/images/mux.js"]');
  for (var o = muxScripts.length - 1; o >= 0; --o) {
    muxScripts[o].remove();
  }

  var shakaCSS = document.querySelectorAll(
    'link[href="https://cdnjs.cloudflare.com/ajax/libs/shaka-player/3.1.6/controls.min.css"]'
  );
  for (var p = shakaCSS.length - 1; p >= 0; --p) {
    shakaCSS[p].remove();
  }

  var videoJSCSS = document.querySelectorAll(
    'link[href="/images/videojs/video-js.css"]'
  );
  for (var q = videoJSCSS.length - 1; q >= 0; --q) {
    videoJSCSS[q].remove();
  }
  var jqueryJS = document.querySelectorAll(
    'script[src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"]'
  );
  for (var r = jqueryJS.length - 1; r >= 0; --r) {
    jqueryJS[r].remove();
  }
};

function checkIsTestServer() {
  if(typeof window != "undefined") {
    const TestServerDomains = ["localhost","dev","qa","staging","alpha","sankalpbharat"]
    const isTestServer = TestServerDomains.some(domain => window.location.href.includes(domain));
    return isTestServer;
  }
}

const addScripts = () => {
  var scriptJquery = document.createElement("script");
  scriptJquery.src =
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js";
  // scriptJquery.defer = true;
  scriptJquery.async = true;
  document.head.appendChild(scriptJquery);

  // var script1 = document.createElement("script");
  // script1.src = "/images/videojs/Youtube.js";
  // script1.defer = true;
  // script1.async = true;
  // document.head.appendChild(script1);

  // var script2 = document.createElement("script");
  // script2.src = "/images/videojs/videojs-quality-switcher.js";
  // script2.defer = true;
  // script2.async = true;
  // document.head.appendChild(script2);

  // var script3 = document.createElement("script");
  // script3.src = "/images/videojs/videojs-http-streaming.js";
  // script3.defer = true;
  // script3.async = true;
  // document.head.appendChild(script3);

  let script4Url = "/images/videojs/video.js?v=1";
  const isTestServer = checkIsTestServer();
  if(isTestServer) {
    script4Url = "/images/videojs/videojs-test-server.js?v=1";
  }


  const script = document.querySelector(`script[src="${script4Url}"]`);
  if (script === null) {
    var script4 = document.createElement("script");
    script4.src = script4Url;
    script4.defer = true;
    // script4.async = true;
    document.head.appendChild(script4);
  }
  var videoCSS = document.createElement("link");
  videoCSS.href = "/images/videojs/video-js.css";
  videoCSS.setAttribute("type", "text/css");
  videoCSS.setAttribute("rel", "stylesheet");
  document.head.appendChild(videoCSS);

  var script5 = document.createElement("script");
  script5.src = "/images/shaka-player.ui.min.js";
  script5.defer = true;
  // script5.async = true;
  document.head.appendChild(script5);

  var script6 = document.createElement("script");
  script6.src = "/images/mux.js";
  script6.defer = true;
  // script6.async = true;
  document.head.appendChild(script6);

  var link2 = document.createElement("link");
  link2.href =
    "https://cdnjs.cloudflare.com/ajax/libs/shaka-player/3.1.6/controls.min.css";
  link2.setAttribute("type", "text/css");
  link2.setAttribute("rel", "stylesheet");
  document.head.appendChild(link2);
};
const chatEventTrack = () => {
  let payload = {};
  payload.Link_text = "Chat Bot Testing";
  trackMoengageEvent("chatbot_clicked", payload);
};
const openWidget = () => {
  const element = document.querySelector(
    ".signin-up-wrap .dropdown .dropdown-content"
  );
  if (element) {
    element.style.display = "none";
  }
  document.body.classList
    ? document.body.classList.remove("coursemenu-active")
    : null;
  if (window.fcWidget.isOpen() != true) {
    window.fcWidget.open();
  }
};

const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

const initScriptChat = () => {
  window.fcWidget.init({
    token: "c063a96b-3240-412c-ac5e-4e39c221b39b",
    host: "https://wchat.in.freshchat.com",
    externalId: getCookie("cp_user_email") ? getCookie("cp_user_email") : null,
    restoreId: localStorage.getItem("restoreId")
      ? localStorage.getItem("restoreId")
      : null,
    config: {
      cssNames: {
        widget: "fc_frame",
        open: "fc_open",
        expanded: "fc_expanded",
      },
    },
    showFAQOnOpen: true,
    hideFAQ: true,
    agent: {
      hideName: false,
      hidePic: false,
      hideBio: false,
    },
    headerProperty: {
      //If you have multiple sites you can use the appName and appLogo to overwrite the values.
      appName: "Gadget God",
      appLogo:
        "https://d1qb2nb5cznatu.cloudfront.net/startups/i/2473-2c38490d8e4c91660d86ff54ba5391ea-medium_jpg.jpg?buster=1518574527",
      backgroundColor: "#FFFF00",
      foregroundColor: "#333333",
      backgroundImage:
        "https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png",
    },
    content: {
      placeholders: {
        search_field: "Search",
        reply_field: "Reply",
        csat_reply: "Add your comments here",
      },
      actions: {
        csat_yes: "Yes",
        csat_no: "No",
        push_notify_yes: "Yes",
        push_notify_no: "No",
        tab_faq: "Solutions",
        tab_chat: "Chat",
        csat_submit: "Submit",
      },
      headers: {
        chat: "Chat with Us",
        chat_help: "Reach out to us if you have any questions",
        faq: "Solution Articles",
        faq_help: "Browse our articles",
        faq_not_available: "No Articles Found",
        faq_search_not_available: "No articles were found for {{query}}",
        faq_useful: "Was this article helpful?",
        faq_thankyou: "Thank you for your feedback",
        faq_message_us: "Message Us",
        push_notification:
          "Don't miss out on any replies! Allow push notifications?",
        csat_question: "Did we address your concerns??",
        csat_yes_question: "How would you rate this interaction?",
        csat_no_question: "How could we have helped better?",
        csat_thankyou: "Thanks for the response",
        csat_rate_here: "Submit your rating here",
        channel_response: {
          offline: "We are currently away. Please leave us a message",
          online: {
            minutes: {
              one: "Currently replying in {!time!} minutes ",
              more: "Typically replies in {!time!} minutes",
            },
            hours: {
              one: "Currently replying in under an hour",
              more: "Typically replies in {!time!} hours",
            },
          },
        },
      },
    },
  });
  window.fcWidget.user.get(function (resp) {
    var status = resp && resp.status,
      data = resp && resp.data;
    if (status !== 200) {
      window.fcWidget.user.setProperties({
        firstName: getCookie("cp_user_name"), // user's first name
        email: getCookie("cp_user_email"), // user's email address
        RestoreId: getCookie("cp_user_id"),
      });
      window.fcWidget.on("user:created", function (resp) {
        var status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            localStorage.setItem("restoreId", data.restoreId);
          }
        }
      });
    }
  });
};
const resetMobileMenu = () => {
  let menuActiveItems = document.querySelectorAll(
    `.accordion.new_accordion.active`
  );
  if (menuActiveItems && menuActiveItems.length > 0) {
    [].forEach.call(menuActiveItems, function (el) {
      el.classList.remove("active");
      el.classList.add("close");
    });
  }
  let menuSubItems = document.querySelectorAll(`.mbl-exam-list.active`);
  if (menuSubItems && menuSubItems.length > 0) {
    [].forEach.call(menuSubItems, function (el) {
      el.classList.remove("active");
      el.classList.add("close");
    });
  }
};

const checkLoginStatus = () => {
  return getCookie("cp_token");
};
const iOSversion = () => {
  if (typeof navigator !== "undefined") {
    if (navigator.userAgent.includes("Version/15.0")) {
      alert("Please upgrade your device's iOS version to play this video!");
      return true;
    } else {
      return false;
    }
  }
};

const deleteAllCookies = () => {
  /**
   * NOTE: do not remove examModes key from localStorage.
   * usage:- used in PPC(ExamModes),to persist selected examModes after logout.
   */
  /**
   * NOTE: do not remove recentSearches key from localStorage.
   * usage:- used in PPC(Search Package),to persist searched terms after logout.
   */
  let examModes = localStorage.getItem("examModes"); // here we store examModes before clearing.
  let recentSearches = localStorage.getItem("recentSearches"); // here we store recentSearches before clearing.
  localStorage.clear();
  localStorage.setItem("examModes", examModes); // set examModes again after clearing.
  localStorage.setItem("recentSearches", recentSearches); // set examModes again after clearing.
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    if (cookie.includes("cp_")) {
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
};

const getDeviceUUID = () => {
  let moeData = localStorage.getItem("MOE_DATA");
  let moeDataObj = JSON.parse(moeData);
  let userData = moeDataObj?.USER_DATA;
  let deviceUUID = "";

  if (userData && userData?.deviceUuid) deviceUUID = userData?.deviceUuid;

  return deviceUUID;
};
// Function to save user search analytics data
const saveUserSearchAnalyticsData = ({
  url = "", // The URL where the analytics data will be sent
  data = {}, // Data object containing information about the search
  searchTerm = "", // The search term entered by the user
  contentType = "PACKAGE", // Type of content
  searchKeyName = "searchterm",
  isLanguageList = true,
}) => {
  const payload = {
    userId: Cookies.get("cp_user_id") || null, // User ID obtained from a cookie or null if not available
    deviceId: getDeviceUUID() || null, // Device ID obtained from a function or null if not available
    packageId: data?.id || data?.packageId, // ID of the package related to the search
    categories: Array.isArray(data?.categories)
      ? data?.categories?.join(",")
      : data?.categories, // Categories related to the search,Categories may contain array
    courses: data?.courses, // Courses related to the search
    keyFor: 1, // A key value representing the search type
    contentType: contentType, // Type of content being searched (in this case, a package)
  };

  if (searchTerm) {
    payload[searchKeyName] = decodeURIComponent(searchTerm) || data.title; // Decoded search term or data title as fallback
  }

  if (data?.languages?.length) {
    let lg = data?.languages?.map((lg) => lg?.name);
    if (!isLanguageList) {
      lg = lg.join(",");
    }
    payload.languages = lg;
  }

  try {
    fetch(url, "POST", payload); // Send the analytics data to the specified URL using the fetch function
  } catch (error) {
    console.log("Error on Saving Analytics:", error); // Log an error message if an error occurs during the process
  }
};

/**
 * helper that return boolean value weather test contains only DTB or not.
 * @author //Amit Mathur
 * @param {*} packageId
 * @param {*} mappingId
 * @returns boolean
 */
const testWithOnlyDtb = async (packageId, mappingId) => {
  let dtbCount = 0;
  let questionsLength = 0;
  return fetch(
    `${STOREFRONT_URI}/api/v1/test-series/start-test?packageId=${packageId}&mappingId=${mappingId}&testStatus=COMPLETED`,
    "GET"
  ).then(
    (res) => {
      if (res.data) {
        let languageList = Object.keys(res.data).filter((lang) => {
          if (["meta", "orgName"].includes(lang) || !res.data[lang]) {
            /// bcoz stupid API :(
            return false;
          }
          return lang;
        });
        questionsLength = res.data[languageList[0]].ques?.list?.length;
        res.data[languageList[0]]?.ques?.list?.forEach((quest) => {
          if (quest?.questionType == 6) {
            dtbCount++;
          }
        });
        // set dtbContentFlag true if questionContains only DTB i.e questionType = 6
        let dtbContentFlag = dtbCount == questionsLength;
        return {
          isOnlyDtb: dtbContentFlag,
          dtbCount,
        };
      }
    },
    (err) => {
      console.log("Error whilegetting test:", err);
    }
  );
};

const getMcmcCorrectAns = (options = []) => {
  let correctAnsMcMc = [];
  options?.forEach((opt, idx) => {
    if (opt?.co) {
      correctAnsMcMc.push(idx);
    }
  });
  return correctAnsMcMc;
};
const checkCorrectFitbAns = (correctAnsFitb, userAnsFitb) => {
  return correctAnsFitb.includes(userAnsFitb);
};

function isArrayContainSameElmnt(array1 = [], array2 = []) {
  //sort the both array before doing anything
  let soretedArr1 = array1.sort();
  let soretedArr2 = array2.sort();
  if (soretedArr1.length === soretedArr2.length) {
    return soretedArr1.every((element, index) => {
      if (element === soretedArr2[index]) {
        return true;
      }
      return false;
    });
  }
  return false;
}
/**
 * helper that return status for questions correct,wrong and notAttempted
 * @param {*} questionInfo
 * @returns number // 0,1,2
 */
const getQuestionStatus = (questionInfo, userResponseObj) => {
  let status = 2; //unattempt
  status = getCorrectionStsByQuestionType(questionInfo, userResponseObj);
  return status;
};
const checkNumberIsinRange = (rangeNumberArr = [], testNumber) => {
  // low = rangeNumberArr[0], high=rangeNumberArr[1]
  if (!testNumber) {
    return false;
  }
  const result =
    Number(rangeNumberArr[0]) <= testNumber &&
    testNumber <= Number(rangeNumberArr[1]);
  return result;
};

// *********
const checkMcqStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let correctMcq = questionInfo.opt.findIndex((option) => option.co == 1);
  let status = 2; // unattempt

  if (
    userResponseObj[questionId] &&
    userResponseObj[questionId]?.selectedAns !== -1
  ) {
    if (userResponseObj[questionId].selectedAns == correctMcq) {
      status = 0; // correct
    } else if (
      userResponseObj[questionId].selectedAns != -1 &&
      userResponseObj[questionId].selectedAns != null
    ) {
      status = 1; // incorrect
    }
  }
  return status;
};
const checkFitbStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let correctAnsFitb =
    questionInfo?.fitbAnswer?.map((str) => str.trim().toLowerCase()) || []; // remove space and convert str to lowercase.
  let status = 2; // unattempt
  if (userResponseObj[questionId]) {
    let userAnsFitb = userResponseObj[questionId]?.fitbAns?.toLowerCase();
    let isCorrectFitb = checkCorrectFitbAns(correctAnsFitb, userAnsFitb);
    if (isCorrectFitb) {
      status = 0; // correct
    } else if (userResponseObj[questionId]?.fitbAns != null) {
      status = 1; // incorrect
    }
  }
  return status;
};
const checkCorrectMcmcStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let correctMcMc = getMcmcCorrectAns(questionInfo?.opt);
  let status = 2; // unattempt

  if (
    userResponseObj[questionId] &&
    userResponseObj[questionId]?.fitbAns !== -1 &&
    userResponseObj[questionId]?.fitbAns !== null
  ) {
    let userAnsMcMc = userResponseObj[questionId]?.fitbAns
      ?.split(",")
      ?.map((str) => Number(str));
    let isCorrectMcMc = isArrayContainSameElmnt(userAnsMcMc, correctMcMc);
    if (isCorrectMcMc) {
      status = 0; // correct
    } else if (
      userResponseObj[questionId]?.fitbAns != -1 &&
      userResponseObj[questionId]?.fitbAns != null
    ) {
      status = 1; // incorrect
    }
  }
  return status;
};
const checkCorrectDfStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let correctAnsDF = questionInfo?.fitbAnswer;
  let status = 2; // unattempt

  if (userResponseObj[questionId]) {
    let userAnsDF = userResponseObj[questionId]?.fitbAns;
    let isCorrectDF = correctAnsDF?.includes(userAnsDF);
    if (isCorrectDF) {
      status = 0; // correct
    } else if (userResponseObj[questionId]?.fitbAns != null) {
      status = 1; // incorrect
    }
  }
  return status;
};
const checkCorrectDfrStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let correctAnsDFR = questionInfo?.fitbAnswer[0].split("<>");
  let status = 2; // unattempt

  if (userResponseObj[questionId]) {
    let userAnsDFR = userResponseObj[questionId]?.fitbAns;
    let isCorrectDFR = checkNumberIsinRange(correctAnsDFR, userAnsDFR);
    if (isCorrectDFR) {
      status = 0; // correct
    } else if (userResponseObj[questionId].fitbAns != null) {
      status = 1; // incorrect
    }
  }
  return status;
};
const checkDtbAttemptedStatus = (questionInfo, userResponseObj) => {
  let questionId = questionInfo.qMapId;
  let status = 2; // unattempt
  // no analysis for DTB questions,just check dtbAttempt or not.
  // if attempt -> status = 3 , notAttempt -> status = 2
  const isDtbAttempted = userResponseObj[questionId]?.dtbAns != null;
  status = isDtbAttempted ? 3 : 2;
  return status;
};
// *** META Pixel event helper ***
const fireMetaPixelEvent = (eventType, options = {}) => {
  if (typeof fbq !== "undefined") {
    fbq("track", eventType, { ...options });
  }
};

const getCorrectionStsByQuestionType = (
  questionInfo = {},
  userResponseObj = {}
) => {
  /** possible qType is 1,2,3,4,5,6
   * questionType representation
   * 1: MCQ
   * 2: FITB
   * 3: MCMC
   * 4: DF
   * 5: DFR
   * 6: DTB
   */

  // here we consider 0 as (correct),1 as (incorrect),2 as (unattempted) and 3 as (attempted only for DTB)
  let answerStatus;
  const funcThatCheckCorrection = {
    1: checkMcqStatus,
    2: checkFitbStatus,
    3: checkCorrectMcmcStatus,
    4: checkCorrectDfStatus,
    5: checkCorrectDfrStatus,
    6: checkDtbAttemptedStatus,
  };
  answerStatus = funcThatCheckCorrection[questionInfo?.questionType || 1](
    questionInfo,
    userResponseObj
  );
  return answerStatus;
};

const getTestAnalysisResultForReattempt = (
  questionsArray = [],
  userResponseObj = {},
  data = {}
) => {
  questionsArray?.map((quest) => {
    if (quest?.questionType == 1 || !quest?.questionType) {
      let index = quest.opt.findIndex((opt) => opt.co == 1);
      if (index >= 0) quest.selectedAnswer = index;
    }
  });
  questionsArray?.map((quest) => {
    let questionId = quest.qMapId;
    quest.timeSpent = userResponseObj[questionId]?.timeTaken;
    quest.correctionStatus = getCorrectionStsByQuestionType(
      quest,
      userResponseObj
    );
  });
  let sliceIndex = 0;
  let sectionDatafromStartTest = data["meta"]?.sections;
  sectionDatafromStartTest?.map((sec) => {
    let marksCalculate = 0;
    let timeSpent = 0;
    let correctAnswerCount = 0;
    let incorrectAnswerCount = 0;
    let dtbQuesSecCount = 0;
    let dtbQuesSecMarks = 0;
    let nonEvaluatedAnswerCount = 0; // for optional section
    let requiredQuestions =
      sec?.secOpt && sec?.attemptAllowed ? sec?.attemptAllowed : sec?.secTotalq;

    questionsArray
      .slice(sliceIndex, sliceIndex + sec.secTotalq)
      .forEach((quest) => {
        let questId = quest.qMapId;
        // correct
        if (quest?.correctionStatus == 0) {
          if (requiredQuestions === 0) {
            // if totalQ is more than requiredQ.
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = false;
            nonEvaluatedAnswerCount++;
          } else {
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = true;
            marksCalculate += quest?.pos || 0;
            correctAnswerCount += 1;
            requiredQuestions--;
          }
        }
        // incorrect
        if (quest?.correctionStatus == 1) {
          if (requiredQuestions === 0) {
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = false;
            nonEvaluatedAnswerCount++;
          } else {
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = true;
            marksCalculate -= quest?.neg || 0;
            incorrectAnswerCount += 1;
            requiredQuestions--;
          }
        }
        if (quest?.questionType == 6) {
          if (requiredQuestions === 0) {
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = false;
            nonEvaluatedAnswerCount++;
          } else {
            if (userResponseObj[questId])
              userResponseObj[questId].evaluated = true;
            dtbQuesSecMarks += quest?.pos;
            dtbQuesSecCount += 1;
            requiredQuestions--;
          }
        }
        timeSpent += quest?.timeSpent || 0;
      });
    sec.timeSpent = timeSpent;
    sec.incorrectAnswerCount = incorrectAnswerCount;
    sec.dtbQuesSecCount = dtbQuesSecCount;
    sec.dtbQuesSecMarks = dtbQuesSecMarks;
    sec.marks = marksCalculate;
    sec.correctAnswerCount = correctAnswerCount;
    sec.sectionName = sec.secSubject;
    sec.totalMarks = sec.secMarks;
    sec.totalQuestions = sec.secTotalq;
    sec.totalTime = sec.time;
    sec.positivePerQuestion = sec.secCorrect;
    if (sec.secOpt) {
      // optional section
      sec.nonEvaluatedAnswerCount = nonEvaluatedAnswerCount;
    }
    sliceIndex = sliceIndex + sec.secTotalq;
  });

  let marks = 0;
  let totalMarks = 0;
  let timeSpent = 0;
  let totalTime = 0;
  let dtbQuestotalCount = 0;
  let dtbQuestotalMarks = 0;

  sectionDatafromStartTest.forEach((sec) => {
    marks = marks + sec?.marks;
    totalMarks = totalMarks + sec?.totalMarks;
    dtbQuestotalCount = dtbQuestotalCount + sec?.dtbQuesSecCount;
    dtbQuestotalMarks = dtbQuestotalMarks + sec?.dtbQuesSecMarks;
    timeSpent = timeSpent + sec?.timeSpent;
    totalTime = totalTime + sec?.totalTime;
    sec.accuracy =
      (sec?.correctAnswerCount * 100) /
      (sec?.incorrectAnswerCount + sec?.correctAnswerCount);
  });
  let incorrectAnswerCountResult = 0;
  let correctAnswerCountResult = 0;
  let totalQuestionResult = 0;
  let nonEvaluatedCount = 0;

  sectionDatafromStartTest?.forEach((sec) => {
    incorrectAnswerCountResult += sec.incorrectAnswerCount;
    correctAnswerCountResult += sec.correctAnswerCount;
    if (sec?.secOpt && sec?.attemptAllowed) {
      totalQuestionResult += sec.attemptAllowed;
    } else {
      totalQuestionResult += sec.totalQuestions;
    }
    nonEvaluatedCount += sec?.nonEvaluatedAnswerCount;
  });

  let totalAttempt = correctAnswerCountResult + incorrectAnswerCountResult;
  let totalQuestionExcludeDtb = totalQuestionResult - dtbQuestotalCount;
  const date = new Date();
  let resultData = {
    data: {
      sections: sectionDatafromStartTest,
      overall: {
        dateAttempted: date.getTime(),
        marks: marks,
        totalMarks: totalMarks,
        totalQuestions: totalQuestionResult,
        dtbQuestotalCount: dtbQuestotalCount,
        dtbQuestotalMarks: dtbQuestotalMarks,
        timeSpent: timeSpent,
        totalTime: totalTime, // totalTime ? totalTime : totalTestTime,
        accuracy:
          ((correctAnswerCountResult * 100) / totalAttempt &&
            ((correctAnswerCountResult * 100) / totalAttempt).toFixed(2)) ||
          0,
        attempted:
          (totalAttempt / totalQuestionExcludeDtb &&
            (totalAttempt / totalQuestionExcludeDtb) * 100) ||
          0,
      },
    },
  };
  let testCalcData = {
    resultData: resultData,
    testStateData: userResponseObj,
  };
  return testCalcData;
};

const isExamDetailListingPage = (pathname) => {
  return (
    pathname.includes("live-classes-kit") ||
    pathname.includes("mock-tests-kit") ||
    pathname.includes("books-kit") ||
    pathname.includes("ebooks-kit") ||
    pathname.includes("videos-kit")
  );
};

const camelToFlat = (camel) => {
  const camelCase = camel?.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let flat = "";

  camelCase?.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};
const toCamelCase = (str = "") => {
  if (!str) return "";
  // Split the string by spaces or underscores
  const words = str.split(/[\s_]+/);

  // Convert the first word to lowercase and capitalize the rest
  const camelCaseWords = words.map((word, index) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words and return the camelCase string
  return camelCaseWords.join(" ");
};
const formatSubCatlabel = (label) => {
  return label
    .split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const diff_minutes = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

const getFormattedDate = (newDate) => {
  const date = newDate;
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  var [d, year] = formattedDate?.split(",");
  var newD = d?.split(" ").reverse().join("-");
  return `${newD}-${year?.trim()}`;
};

const getInitials = (fullName = "") => {
  if (!fullName) return "";
  return fullName?.charAt(0).toUpperCase();
};

const createResponseObjectFromArray = (resArr = [], keyName = "") => {
  return resArr.reduce((data, res) => {
    data[res[keyName]] = res;
    return data;
  }, {});
};

const add3Dots = (string, limit) => {
  var dots = "...";
  if (string && string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
};
const toQueryString = (obj) => {
  let queryString = "";
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      /**
       * encode the special characters from the string to be used in the url.
       */
      if (obj[key])
        queryString += key + "=" + encodeURIComponent(obj[key]) + "&";
    }
  }
  return queryString.slice(0, -1); // remove the last '&'
};
const queryStringToObject = (queryString) => {
  const params = new URLSearchParams(queryString);
  const result = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};
const isStringValueExist = (testString = "", matchString = "") => {
  if (!testString || !matchString) return false;
  return testString
    ?.split(",")
    ?.map((str) => str.toUpperCase())
    .includes(matchString.toUpperCase());
};
const isExactStringExistInArray = (testArray = [], matchString = "") => {
  if (!testArray?.length || !matchString) return false;
  return testArray
    ?.map((arr) => arr?.toLowerCase())
    .includes(matchString.toLocaleLowerCase());
};
const isAnyKeyOfObjectWithValue = (obj = {}) => {
  return Object.values(obj)?.filter((value) => value)?.length > 0;
};

const handleShowFilter = (facetData, validFilters) => {
  let showFilterFlag = false;
  if (facetData) {
    for (var key in facetData) {
      if (validFilters?.includes(key) && Object?.keys(facetData[key])?.length) {
        showFilterFlag = true;
        break;
      }
    }
  }
  return showFilterFlag;
};
const findMaxTagAssignedAtObject = (data = []) => {
  if (data.length === 0) {
    return null; // Return null for an empty array
  }

  let maxObject = data[0]; // Initialize with the first object in the array

  for (let i = 1; i < data.length; i++) {
    if (data[i].tagAssignedAt > maxObject.tagAssignedAt) {
      maxObject = data[i];
    }
  }
  // when sale timer is already end then return null
  if(maxObject?.tagType === 2 && !checkIsTimeInFuture(maxObject?.endTime)){
    return null
  }
  return maxObject;
};
const checkIsTimeInFuture = (msTime = 0) => {
  return msTime > new Date().getTime();
};

/**
 * Scrolls the element horizontally with smooth animation.
 * @param {HTMLElement} element - The element to scroll.
 * @param {number} start - The initial scroll position.
 * @param {number} change - The amount of pixels to scroll.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 */
const animateScroll = (element, start, change, duration) => {
  const increment = 20;
  let currentTime = 0;

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scroll = () => {
    currentTime += increment;
    const easedScroll = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = easedScroll;

    if (currentTime < duration) {
      requestAnimationFrame(scroll);
    }
  };

  scroll();
};

/**
 * Handles smooth horizontal scroll of the description slider.
 * @param {boolean} isScrollRight - Determines the scroll direction. Set to true for scrolling to the right, false for scrolling to the left.
 * @param {number} scrollValue - The amount of pixels to scroll.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 */
const smoothSlideScroll = (
  sliderRef = null,
  isScrollRight = true,
  scrollValue = 100,
  duration = 300
) => {
  const element = sliderRef.current;
  const start = element.scrollLeft;
  const change = isScrollRight ? scrollValue : -scrollValue;

  animateScroll(element, start, change, duration);
};

/**
 * get the specific queryParam of the current url.
 * @param {string} paramName - the query param to find value of.
 */
const getQueryParam = (paramName) => {
  if(typeof window != 'undefined') {
    let url = window.location.href;
    const queryString = url?.split('?')[1];
    if (!queryString) {
      return null;
    }
    
    const params = new URLSearchParams(queryString);
    return params?.get(paramName);
  }

  return null
}

/**
* Extracts the last part of a URL after the last occurrence of a hyphen ("-").
* 
* @param {string} url - The URL from which to extract the last part.
* @returns {string} The last part of the URL after the last hyphen ("-").
*/
const getLastQIDFromURL = (url) => {
  const parts = url?.split('-');
  const lastPart = parts?.[parts?.length - 1];
  return lastPart;
}

/**
 * @param {string} url - The URL from which the last occurrence of '-' needs to be removed.
 * @returns {string} - The updated URL with the last occurrence of '-' removed.
 */
const removeLastQIDFromURL = (url) => {
  const lastIndex = url?.lastIndexOf('-');
  if (lastIndex !== -1) {
    return url?.substring(0, lastIndex);
  } else {
    return url;
  }
}

/**
 * get the text/html of the url.
 * @param {string} templateUrl - the templateUrl to get text/html of.
 */
const getDtbAnswerFromTemp = (templateUrl) => {
  return fetch(templateUrl, "GET")
    .then((res) => res.text())
    .then((resp) => {
      return resp;
    });
};

/**
 * Sanitizes the question data and returns it in a specified format.
 * @param {string} d - The question data to sanitize.
 * @returns {object} - The sanitized question data in the specified format.
 */
const sanitizeQuestionData = async(d) => {
  let res = {};
  res.question = d?.question;
  res.questionTitle = removeLastQIDFromURL(d?.questionURL?.split('/')?.[2])?.replace(/-/g," ");
  res.questionType = d?.questionType;
  res.url = `/${d?.questionURL}`;
  res.questionURL = d?.questionURL?.includes('http') ? d?.questionURL : `https://${window.location.hostname}/${d?.questionURL}`;
  res.subject = d?.questionURL?.split('/')[0]?.replace(/-/g," ");
  if(d?.questionType == 1 || d?.questionType == 3) {
    let {correct, optionA, optionB, optionC, optionD, optionE} = d?.mcqData;
    res.correct = correct?.split(',')?.map(item => convertLetterToNumber(item));
    res.correctOption = correct;
    res.options = [{id:0, val: optionA}, {id: 1, val: optionB}, {id: 2, val:optionC},{id: 3, val:optionD}]
    if(optionE)
    res.options.push({id:4, val: optionE})
    res.solution = d?.solution;
  }
  else if(d?.questionType === 2) {
    res.correct = d?.fitbAnswer?.split('|$|')?.[0];
    res.solution = d?.solution || res.correct;
  }
  else if(d?.questionType === 4) {
    res.correct = d?.fitbAnswer?.split('|$|')?.[0];
    res.solution = d?.solution || res?.correct;
  }
  else if(d?.questionType === 5) {
    res.correct = d?.fitbAnswer?.split('<>')?.join(' to ');
    res.solution = d?.solution || res?.correct;
  }
  else if(d?.questionType === 6) {
    let text = d?.dtbAnswerUrl || d?.dtbAnswer;
    let result = '';
    if (d?.dtbAnswerUrl) {
      result = await getDtbAnswerFromTemp(text);
    } else {
      result = text;
    }
    res.solution = result;
  }
  return res;
}

/**
 * Converts a capital letter to its corresponding index value starting from 0.
 * @param {string} letter - The capital letter to find the index value of.
 * @returns {number} The index value of the capital letter starting from 0.
 */
const convertLetterToNumber = (letter) => {
  const asciiValue = letter?.toUpperCase()?.charCodeAt(0);
  const offset = 'A'?.charCodeAt(0);
  return asciiValue - offset;
}

/**
 * convert html tags to entities in a html text.
 * @param {string} text - the html text to convert into entities.
 */
const convertHtmlTagsToEntities = (text) => {
  const convertedText = text?.replace(/</g, '&lt;')?.replace(/>/g, '&gt;');
  return convertedText;
}

/**
 * Extracts the inner text from an HTML string.
 * @param {string} htmlString - The HTML string from which to extract the inner text.
 * @param {number} [maxLength] - The maximum length of the extracted inner text. If provided, the inner text will be truncated to this length.
 * @returns {string} The extracted inner text from the HTML string. If maxLength is provided, the inner text will be truncated to the specified length.
 */
const extractInnerTextFromHtml = (htmlString, maxLength) => {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(htmlString, 'text/html');
  const innerText = parsedDocument.body.innerText;
  
  if(maxLength) {
    return innerText?.slice(0, maxLength)
  } else {
    return innerText;
  }
}

/**
 * Custom React hook to handle hiding the chat widget after a certain delay.
 * The chat widget will be hidden after a delay of 6 seconds unless it's already present on the page.
 *
 * @function useHideChatWithUsCTA
 * @returns {void}
 */
const useHideChatWithUsCTA = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const isChatWidgetElement = document.getElementById("fc_frame");
      const timeoutId = setTimeout(
        () => {
          const chatWidgetElement = document.getElementById("fc_frame");
          chatWidgetElement.classList.add("hideChatWidget");
        },
        isChatWidgetElement ? 0 : 6000
      );
      return () => {
        clearTimeout(timeoutId);
        const chatWidgetElement = document.getElementById("fc_frame");
        chatWidgetElement?.classList?.remove("hideChatWidget");
      };
    }
  }, []);
};
const formatTimestampToHoursMinutesSeconds = (timestamp = 0 ) =>  {
  // Calculate total hours
  const totalHours = Math.floor(timestamp / 3600000);

  // Calculate remaining minutes and seconds
  const remainingMillis = timestamp % 3600000;
  const minutes = Math.floor(remainingMillis / 60000);
  const seconds = Math.floor((remainingMillis % 60000) / 1000);

  // Format each part with leading zeros if necessary
  const hoursStr = totalHours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  // Create the formatted string
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}
const getLcsServiceHeaders  = (businessName = "AddaWishList") => {
  let jwtToken = Cookies.get("cp_token")
  const options = {
    "business-name": businessName,
    "client-name":'admin-panel',
    "jwt-token":jwtToken
  }
  return options
};
const changeUrlPID = (id) => {
  if(window != 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('productId', id);
    const updatedUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState('','',updatedUrl);
  }
}
const copyToClipBoard = (textToCopy = "") => {
  try {
    return navigator.clipboard.writeText(textToCopy);
  } catch (error) {
    console.error("Failed to copy.");
    return error;
  }
};
export const getUpdatedNewWishListStore = (
  isWishList = false,
  packageId = "",
  wishlistPackageData = {
    postData: [],
    likeCount: 0,
  }
) => {
  const businessReferenceId = packageId.toString();

  const { postData: existingPackageIds, likeCount = 0 } = wishlistPackageData;

  const updatedPackageIds = isWishList
    ? existingPackageIds?.filter(
        (item) => item?.businessReferenceId !== businessReferenceId
      )
    : [...existingPackageIds, { businessReferenceId, likeType: 1 }];

  const newWishListState = {
    ...wishlistPackageData,
    likeCount: updatedPackageIds.length,
    postData: updatedPackageIds,
  };
  return newWishListState;
};
/**
 * Formats a number as a count with 'k' suffix for thousands, e.g., 3500 as '3k'.
 * @param {number} count - The count to be formatted.
 * @returns {string} The formatted count as a string.
 */
 function formatCount(count) {
  if (count >= 1000) {
    const countInK = (count / 1000).toFixed(1).replace(/\.0$/, '')?.split('.')[0]; // Remove trailing .0
    return countInK + 'k+';
  } else {
    return count.toString();
  }
}
const fireWishListMoengageEvent = (eventName = "",payload={}) => {
  if (typeof Moengage !== "undefined")
    Moengage.track_event(eventName, getMoengageMetadata(payload));
};

const saveCookies = (cookiesData = {}, setCookie) => {
  let d = new Date();
  d.setTime(d.getTime() + 525600 * 60 * 1000);
  console.log(cookiesData);
  setCookie("cp_token", cookiesData?.jwtToken, {
    path: "/",
    expires: d,
  });
  //this.setCookie('cp_token', this.props.jwtToken, {path: '/', expires: d});
  setCookie("cp_user_name", cookiesData?.name, {
    path: "/",
    expires: d,
  });
  setCookie("cp_user_phone", cookiesData?.phoneNumber, {
    path: "/",
    expires: d,
  });
  setCookie("cp_user_id", cookiesData?.userId, {
    path: "/",
    expires: d,
  });
  setCookie(
    "cp_user_email",
    cookiesData?.email,
    { path: "/", expires: d }
  );
  setCookie(
    "cp_user_" + cookiesData?.userId,
    !isSet(cookiesData?.userCourse) ? "BANKING" : cookiesData?.userCourse,
    {
      path: "/",
      expires: d,
    }
  );
  setCookie("cp_new_token", cookiesData?.jwt256Token, {
    path: "/",
    expires: d,
  });
  setCookie("cp_user_ref_id", cookiesData?.refId, {
    path: "/",
    expires: d,
  });
}

function validateInput(fields) {
  const validationErrors = {
    email: [],
    password: [],
    phone: [],
  };

  if (fields.email && fields?.email?.length) {
    fields?.email?.forEach(email => {
      validationErrors.email.push(isValidEmail(email) ? "" : "Invalid email format");
    });
  }

  if (fields?.password && fields?.password?.length) {
    fields?.password?.forEach(password => {
      validationErrors.password.push(isValidPassword(password) ? "" : "Password should be at least 6 characters long");
    });
  }

  if (fields?.phone && fields?.phone?.length) {
    fields?.phone?.forEach(phone => {
      validationErrors.phone.push(isValidPhoneNumber(phone) ? "" : "Invalid phone number");
    });
  }

  return validationErrors;
}

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function isValidPassword(password) {
    const passwordRegex = /^.{6,}$/;
    return passwordRegex.test(password);
}

function isValidPhoneNumber(phoneNumber) {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phoneNumber);
}

const hitMoengageForOnboarding = (source_screen = "", event_name = "", moepayload = {}) => {

  let moengageData = {};

  let commonAttributes = {

      // Event Attributes
      exam_category : WEB_IDENTIFIER,
      source_screen : source_screen,

      // User Attributes
      user_exam_category_selected : WEB_IDENTIFIER,
      language_selected : ENGLISH_IDENTIFIER,
      user_name : Cookies.get("cp_user_name") || "",
      user_email : Cookies.get("cp_user_email") || "",
      category : isADDA ? "BANKING": "NEET",
      user_id : Cookies.get("cp_user_id"),
      paid_status : Cookies.get("cp_paid_user") ? 1 : 0,
  }

  moengageData = {...moengageData, ...commonAttributes};

  if (moepayload && Object.keys(moepayload)?.length) moengageData = {...moengageData, ...moepayload};

  if (typeof Moengage !== "undefined") Moengage.track_event(event_name, getMoengageMetadata(moengageData));
}

const openEmailClient = (emailAddress) => {
  window.open(`mailto:${emailAddress}`, "_blank");
};

const autoReadSMS = async (cb) => {
  try {
    if (typeof window !== "undefined" && 'OTPCredential' in window) {
      const ac = new AbortController();

      const credential = await navigator.credentials.get({
        otp: { transport: ['sms'] },
      });

      if (credential && credential.code) {
        cb(credential.code);
        ac.abort();
      }
    }
  } catch (error) {
    console.error("Error in fetching OTP:", error);
  }
};

const generateNonce = () => {
  let nonceLength = 32;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let nonce = '';
  for (let i = 0; i < nonceLength; i++) {
    nonce += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return nonce;
}


const getTagIdParams = (category = "", globalList = []) => {
  try {
    let extraParamString = "";
    let categoryIds = [];

    if (category) {
      const categories = category.split(',').map(cat => cat.trim());
      const examMap = new Map();
      
      globalList?.forEach(exam => {
        examMap.set(exam?.id?.toUpperCase(), exam?.exam_category_tag_id);
        exam?.examList?.forEach(currExam => {
          examMap.set(currExam?.id?.toUpperCase(), currExam?.exam_category_tag_id);
        });
      });

      categories?.forEach(cat => {
        const formattedCategory = replaceHyphenWithUnderscore(cat)?.toUpperCase();
        const categoryId = examMap.get(formattedCategory);
        if (categoryId) {
          categoryIds.push(categoryId);
        }
      });
    }

    if (categoryIds.length) extraParamString = `&examCategoryTagId=${categoryIds.join(",")}`;
    
    return extraParamString;
  } catch (error) {
    console.error("Error in getTagIdParams:", error);
    return "";
  }
}

export {
  isADDA,
  isEmpty,
  hasValue,
  isSet,
  getDataFromRedisByProductId,
  isEmailValid,
  isUserPasswordValid,
  isUserNameValid,
  removeSpacesAndInsertHyphens,
  cookieCreate,
  getSubstringAfterChar,
  getSubstringBeforeChar,
  capitalizedString,
  capitalizeWords,
  removeHyphensAndInsertSpaces,
  timeSince,
  isValidCharCheck,
  isValidAddressCheck,
  isPhone,
  isPinCode,
  isEmptyField,
  feedPostCategories,
  makeUrl,
  createArticleUrl,
  getTestSeriesIds,
  massageFeedDataInReducerForQuizStatus,
  unionWith,
  getQuizButtonText,
  createStorefrontPdpUrl,
  validateProductName,
  validateCourseName,
  removeElementsFromLastInArray,
  getDiscount,
  cleanObj,
  maskString,
  getMoengageMetadata,
  replaceUnderscoreWithSpace,
  logPageView,
  getOffset,
  arraysEqual,
  createOptimizedImgUrl,
  getYoutubeIframeUrl,
  scrollToTop,
  scrollToElement,
  numberWithCommas,
  timestampToDateTime,
  sendDataToCretio,
  getDeviceType,
  getUserDeviceType,
  getCpOriginValue,
  updateHash,
  getPinCodeInfo,
  getPdpUrl,
  getUrlPathObject,
  getUrlPath,
  msToTime,
  parseCourseName,
  getParameterByName,
  loadJsFile,
  parseJwt,
  parseUrlForSeo,
  isIosSafari,
  getExamCategoryId,
  getTopExamtypes,
  trackMoengageEvent,
  getPostBody,
  optimizedImgUrl,
  msToTimeHMS,
  removePPKG,
  addScripts,
  removeScripts,
  openWidget,
  chatEventTrack,
  initScriptChat,
  checkLoginStatus,
  resetMobileMenu,
  iOSversion,
  deleteAllCookies,
  replaceHyphenWithUnderscore,
  replaceUnderscoreWithHyphen,
  saveUserSearchAnalyticsData,
  secondsToHms,
  testWithOnlyDtb,
  getQuestionStatus,
  isArrayContainSameElmnt,
  checkNumberIsinRange,
  checkCorrectFitbAns,
  fireMetaPixelEvent,
  replaceHyphenWithSpace,
  isExamDetailListingPage,
  getTestAnalysisResultForReattempt,
  camelToFlat,
  toCamelCase,
  diff_minutes,
  formatSubCatlabel,
  getFormattedDate,
  scrollToElementWithOffset,
  scrollToHorizontalElementWithOffset,
  getInitials,
  createResponseObjectFromArray,
  add3Dots,
  toQueryString,
  queryStringToObject,
  isStringValueExist,
  isExactStringExistInArray,
  isAnyKeyOfObjectWithValue,
  handleShowFilter,
  findMaxTagAssignedAtObject,
  getYoutubeId,
  getCookie,
  getMcmcCorrectAns,
  checkMcqStatus,
  checkFitbStatus,
  checkCorrectMcmcStatus,
  checkCorrectDfStatus,
  checkCorrectDfrStatus,
  checkDtbAttemptedStatus,
  getCorrectionStsByQuestionType,
  smoothSlideScroll,
  getQueryParam,
  getLastQIDFromURL,
  removeLastQIDFromURL,
  sanitizeQuestionData,
  convertLetterToNumber,
  convertHtmlTagsToEntities,
  extractInnerTextFromHtml,
  useHideChatWithUsCTA,
  fireMoengageEvent,
  getMoengagePayloadFromFacets,
  formatTimestampToHoursMinutesSeconds,
  getLcsServiceHeaders,
  changeUrlPID,
  copyToClipBoard,
  checkIsTimeInFuture,
  formatCount,
  fireWishListMoengageEvent,
  getTagIdParams,
  saveCookies,
  validateInput,
  hitMoengageForOnboarding,
  openEmailClient,
  autoReadSMS,
  generateNonce,
  checkIsTestServer,
};

import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";
import { GOOGLE_CLIENT_ID } from "../../../constants/appConfig";
import { maskString } from "../../data/utils/helpers";
import OTPInput from "./AOtp";
import LeftPanelLoginModal from "./LeftPanelLoginModal";

const ForgotPasswordNew = (props) => {
  const [OTP, setOTP] = useState("");
  const maskedEmailId = maskString(props.emailId);

  const resendMethod = () => {
    console.log("resnd");
    props.method;
  };
  const closeModal = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
    if (localStorage.getItem("urlAfterLogin")) {
      localStorage.removeItem("urlAfterLogin");
    }
    if (localStorage.getItem("urlForTestPortal")) {
      localStorage.removeItem("urlForTestPortal");
    }

    if (localStorage.getItem("urlForFreeQuizzes")) {
      localStorage.removeItem("urlForFreeQuizzes");
    }
  };
  const onCancel = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
  };
  const onBack = () => {
    document.body.classList.remove("noscroll");
    if (props.onBack) {
      props.onBack();
    }
  };
  let targetEvent = null;
  useEffect(() => {
    try {
      targetEvent = window.addEventListener("keydown", (event) => {
        if (event.keyCode == 27) {
          closeModal();
        }
      });
      return () => {
        window.removeEventListener("keydown", targetEvent);
        document.body.classList.remove("noscroll");
      };
    } catch (ex) {}
  }, []);

  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleOtp = () => {
    props.method();
    setTimer(30);
  };

  const handleResendClick = () => {
    props.method();
    setTimer(30);
  };

  const handleInputKeyDownEmail = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      props.method();
      setTimer(30);
    }
  };
  const handleInputKeyDownOTP = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      props.validateOTP(OTP);
    }
  };
  return (
    <div
      footer={null}
      closeCallback={() => onCancel()}
      className={"main-login-wrap"}
    >
      <div class="main-login-content-wrap ">
        <LeftPanelLoginModal />
        <div class="right-panel-login">
          <div class="rightpanel-header">
            <div class="back-tn-panel" onClick={() => props.enableLoginModal()}>
              <img src="/images/login-pop-back-btn.svg" />
            </div>
            <div class="right-cross-icon" onClick={closeModal}>
              <img src="/images/login-pop-cross-btn.svg" />
            </div>
          </div>
          <div className="login-data-wrapper">
            <section className="forgotPass-otp">
              <div className="login-heading">Forgot password ?</div>
              <div className="top-subheading">
                {props.showOTPInputSection
                  ? "Please enter the OTP you recieved on your email address"
                  : "Enter the email address associated with your account and we'll send you an OTP to verify it's you"}
              </div>
              <form
                onSubmit={
                  !props.showSignupForm
                    ? props.handleUserLogin
                    : props.handleUserSignup
                }
              >
                <div className="right-panel-form-section">
                  {!props.showOTPInputSection && (
                    <div className="email">
                      <input
                        type={"email"}
                        onChange={(e) => props.handleuseremail(e)}
                        onKeyDown={(e) => handleInputKeyDownEmail(e)}
                        placeholder=' '
                        onBlur={props.handleEmailOnBlur}
                        onFocus={props.handleEmailOnFocus}
                        size="default"
                        className={
                          props.showEmailError ? "wrongpass a-input" : "a-input"
                        }
                        value={props.emailId}
                      />
                      <label class="floating-label">Enter your email</label>
                      <div>
                        {props.showEmailError && (
                          <div className={"otp-error-section"}>
                            Please enter a valid email id
                          </div>
                        )}
                        {props.emailCheckErrorMsg[props.emailId] && (
                          <div className={"otp-error-section"}>
                            {props.emailCheckErrorMsg[props.emailId]}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {props.showOTPInputSection && (
                    <div className="otp-recived">
                      <OTPInput
                        length={6}
                        className="input-otp"
                        inputClassName={`otp-number ${
                          props.otpInvalid ? "wrong-otp" : ""
                        }`}
                        onKeyDown = {(e) => handleInputKeyDownOTP(e)}
                        isNumberInput
                        autoFocus
                        onChangeOTP={(otp) => setOTP(otp)}
                      />

                      <div className="sec-counting">
                        Didn't recieve the OTP?
                        {timer > 0 ? (
                          <span className="sec"> Resend in {timer}s</span>
                        ) : (
                          <span
                            className="resend-otp ripple"
                            onClick={handleResendClick}
                          >
                            <a style={{ cursor: "pointer" }}> Resend</a>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    {props.otpInvalid && (
                      <div className={"wrongpass-msg mt-2 mb-2"}>
                        Please enter a valid OTP
                      </div>
                    )}
                    {props.emptyOTP && (
                      <div className={"wrongpass-msg mt-2 mb-2"}>
                        Please enter OTP
                      </div>
                    )}
                  </div>
                  <div class="otp-submit-btns">
                    <div
                      class="not-user ripple"
                      onClick={() => props.enableLoginModal()}
                    >
                      Not You ?
                    </div>
                    <CustomButton
                      type="primary"
                      className={"primary-red-btn ripple"}
                      buttontext={
                        props.showOTPInputSection
                          ? "RESET PASSWORD"
                          : "SEND OTP"
                      }
                      loading={props.showLoginLoader}
                      onClick={
                        props.showOTPInputSection
                          ? () => props.validateOTP(OTP)
                          : handleOtp
                      }
                    />
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordNew;
import reducerRegistry from '../../reducerRegistry';
import {massageFeedDataInReducerForQuizStatus,unionWith} from '../../../data/utils/helpers';

function home(
    state = {},
    action
) {
    switch (action.type) {
    case 'FETCH_HOME_RECOMMENDATION': {
        const recommendation = action.payload.response;
        return {
            ...state,
            recommendation,
        };
    }

    case 'FETCH_USER_APPRECIATION': {
        const usersAppreciationData = action.payload;
        return {
            ...state,
            usersAppreciationData
        };
    }
    case 'FETCH_HOME_BANNERS': {
        const bannerList = action.payload;
        return {
            ...state,
            bannerList
        };
    }
    case 'FETCH_POST_LGGEDIN_USER': {
        const coursePosts = action.payload.data;
        return {
            ...state,
            coursePosts
        };
    }
    case 'FEED_DATA_FETCH_SUCCESS_ONSCROLL': {
        const newData = action.payload.data;
        // const newState = clone(state);
        const final = state.coursePosts.concat(newData);
        return {
            coursePosts: final,
        };
    }
    case 'SET_USER_COURSE' : {
        const {userCourse} = action.payload;
        return {
            ...state,
            userCourse
        };
    }
    case 'FEED_QUIZ_STATUS_FETCH_SUCCESS': {
        const feedData = action.payload.data;
        const newState = {...state};
        const {coursePosts} = newState;    
        const filterQuizIdsFromReducer = massageFeedDataInReducerForQuizStatus(coursePosts,feedData);
        const newCoursePosts = [...coursePosts];
        const newFeedData = unionWith(newCoursePosts, filterQuizIdsFromReducer); 
        return {
            ...state,
            coursePosts:newFeedData
        };
    }

    case 'CLEAR_FEED': {
        const clonedState = Object.assign({}, state);
        delete clonedState.coursePosts;
        return {
            ...clonedState
        };
    }
    
    case 'SET_REFERRER': {
        const clonedState = Object.assign({}, state);
        clonedState.source = 'home';
        return {
            ...clonedState
        };
    }

    case 'SET_CURRENT_ACTIVE_QUIZ_SUCCESS': {
        const {packageId, mappingId} = action.meta.response;
        const currentActiveQuiz = {
            packageId,
            mappingId,
        };
        return {
            ...state,
            currentActiveQuiz,
        };
    }

    default:
        return state;
    }
}

reducerRegistry.register('home', home);
export default home;

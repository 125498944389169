import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import '../data/ducks/productDetails/reducers';
const UserDetails = lazy(() => import('./UserDetails'));
const PaymentMethod = lazy(() => import('./PaymentMethod'));
const PaymentFailure = lazy(() => import('./PaymentFailure'));
import PaymentFailures from './PaymentFailure';
import PaymentSuccess from './PaymentSuccess';

import ApplyCoupon from "./ApplyCoupon";
import {
  isEmailValid,
  isValidCharCheck,
  isPhone,
  isPinCode,
  isEmptyField
} from "../data/utils/helpers";
import { lockBodyScroll } from "./utils";
import { useHistory } from "react-router";

const BuyNowCheckoutModal = ({
  maxPrice,
  price,
  priceDetails,
  cartJson,
  isShippable,
  userEmail,
  setUserEmail,
  setCheckoutData,
  checkoutData,
  Getpayload,
  getUtmFromStore,
  payablePrice,
  productData,
  updatePriceDetails,
  loggedUserDetails,
  setViewOfferModalOnly,
  viewOffers,
  viewOfferModalOnly,
  userInfo,
  cartData,
  isLoggedIn,
  setFromBuyNowBtn,
  activeValidityData
}) => {
  var stepArray = [{ stepNo: 1, stepName: 'Apply Offer' }, { stepNo: 2, stepName: 'Fill details' }, { stepNo: 3, stepName: 'Payment' }]
  const [hashUrl, setHashUrl] = useState("");
  const doc = useRef(null);
  const [header, setHeader] = useState("What are you preparing for?");
  const [continueButtonClicked, setContinueButtonCLick] = useState(false);
  const [cartError, setCartError] = useState('')
  const [fromapp, setFromapp] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [userInformation, setUserInformation] = useState({});
  const [addressModalShow, setAddressModalShow] = useState(false);
  var buyNowModalUrl = ['#checkout/viewOffers', '#checkout/userDetails', '#checkout/payment']
  const history = useHistory();
  /** function that will called when hash url is changes.
   *  called only when hash url is empty.
   *  handling case when user press back button from browser tab.
   */
  const handleBackFromBrowserTab = () => {
    lockBodyScroll(false);
    viewOffers(false);
    setViewOfferModalOnly(false)
  }
  useEffect(() => {
    if (location.hash) {
      setHashUrl(location.hash);
    }
    window.addEventListener("hashchange", () => {
      setHashUrl(location.hash);
    }, false);
    setCheckoutData({ couponCode: priceDetails.appliedCoupon });
  }, []);
  useEffect(() => {
    let hashUrl = location.hash;
    switch (hashUrl) {
      case '#checkout/viewOffers':
        setActiveStep(1)
        break;
      case '#checkout/userDetails':
        setActiveStep(2)
        break;
      case '#checkout/payment':
        setActiveStep(3)
        break;
      case '#cart/failure':
        setActiveStep(4)
        break;
    }

    if (hashUrl.indexOf('#cart/success') > -1) setActiveStep(3)
  }, [hashUrl])

  useEffect(() => {
    setFromBuyNowBtn(true);
    lockBodyScroll(true)
    let fromapp = getParameterByName('from_app');
    if (fromapp) {
      setFromapp(true);
    }
    else {
      setFromapp(false);
    }
    return () => { lockBodyScroll(false) }
  }, [])
  useEffect(() => {
    if (window.location.hash == '') {
      handleBackFromBrowserTab();
    }
  }, [window.location.hash])
  function getParameterByName(name, url) {
    if (!url) url = typeof window != undefined && window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  if (!hashUrl) return null;
  let cartActionClass;
  let modalHeight = '630px';
  let ModalBodyComponent;
  let ModalHeader;

  if (hashUrl.indexOf('#cart/success') > -1) {
    ModalHeader = "Order Successful";
    ModalBodyComponent = <Suspense fallback={<div></div>}><PaymentSuccess
      orderId={hashUrl.split('/')[2]} email={hashUrl.split('/')[3]}
      Getpayload={Getpayload}
      fromapp={fromapp}
      userInfo={userInfo}
      isLoggedIn={isLoggedIn}
      viewOffers={viewOffers}
      setViewOfferModalOnly={setViewOfferModalOnly}
    /></Suspense>;
    cartActionClass = 'cartaction-success'
  }

  switch (hashUrl) {
    case "#checkout/viewOffers":
      ModalHeader = "Checkout";
      ModalBodyComponent = <Suspense fallback={<div></div>}>  <ApplyCoupon
        viewOfferModalOnly={viewOfferModalOnly}
        setViewOfferModalOnly={setViewOfferModalOnly}
        packageId={productData.id}
        packageType={productData.categories}
        priceDetails={priceDetails}
        maxPrice={maxPrice}
        price={price}
        updatePriceDetails={updatePriceDetails}
        productData={productData}
        cartPayload={`{\"${productData.id}\":1}`}
        loggedUserDetails={loggedUserDetails}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        Getpayload={Getpayload}
        activeValidityData={activeValidityData}
      ></ApplyCoupon></Suspense>;
      break;
    case "#checkout/userDetails":
      ModalHeader = "Checkout";
      ModalBodyComponent = <Suspense fallback={<div></div>}><UserDetails
        productData={productData}
        priceDetails={priceDetails}
        updatePriceDetails={updatePriceDetails}
        setUserInformation={setUserInformation}
        maxPrice={maxPrice}
        price={price}
        checkoutData={checkoutData}
        payablePrice={payablePrice}
        cartJson={cartJson}
        loggedUserDetails={loggedUserDetails}
        isShippable={isShippable}
        updateCheckoutData={setCheckoutData}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        Getpayload={Getpayload}
        continueButtonClicked={continueButtonClicked}
        setContinueButtonCLick={setContinueButtonCLick}
        addressModalShow={addressModalShow}
        setAddressModalShow={setAddressModalShow}
      ></UserDetails></Suspense>;
      break;
    case "#checkout/payment":
      ModalHeader = "Payment";
      ModalBodyComponent = <Suspense fallback={<div></div>}><PaymentMethod
        priceDetails={priceDetails}
        checkoutData={checkoutData}
        setCartError={setCartError}
        Getpayload={Getpayload}
        getUtmFromStore={getUtmFromStore}
      >
      </PaymentMethod></Suspense>;
      break;
    case "#checkout/alreadyPurchased":
      ModalHeader = "Already Purchased";
      ModalBodyComponent = <Suspense fallback={<div></div>}>
        <PaymentFailure
          text={cartError}
          Getpayload={Getpayload}
          checkoutData={checkoutData}
          >
        </PaymentFailure> </Suspense>
      break;


    case "#cart/failure":
      ModalHeader = <span className='error'>Payment Failed</span>;
      ModalBodyComponent = <Suspense fallback={<div></div>}>  <PaymentFailures
        fromapp={fromapp}
        Getpayload={Getpayload}
        cartData={cartData}
      > </PaymentFailures></Suspense>;
      cartActionClass = 'cartaction-failure'
      modalHeight = 'auto';
      break;
  }

  const handleClickStep = (stepNo) => {
    if (hashUrl.indexOf('#cart/success') > -1) return
    if (stepNo == 1) {
      window.location.hash = "#checkout/viewOffers";
    }
    else if (stepNo == 2) {
      window.location.hash = "#checkout/userDetails";
    }
    else {
      setContinueButtonCLick(true)
      let invalidState = {};
      if (isShippable) {
        invalidState = {
          phone: !isPhone(userInformation.phone),
          name: isEmptyField(userInformation.name) || isValidCharCheck(userInformation.name),
          email: !isEmailValid(userInformation.email),
          pincode: !isPinCode(userInformation.pincode),
          firstLine:
            isEmptyField(userInformation.firstLine) ||
            isValidCharCheck(userInformation.firstLine),
          secondLine: isValidCharCheck(userInformation.secondLine),
          landmark: isValidCharCheck(userInformation.landmark),
          city: isValidCharCheck(userInformation.city),
          state: isValidCharCheck(userInformation.state),
        };
      } else {
        invalidState = {
          phone: !isPhone(userInformation.phone),
          name: isEmptyField(userInformation.name) || isValidCharCheck(userInformation.name),
          email: !isEmailValid(userInformation.email),
        };
      }
      if (Object.values(invalidState).find((item) => item)) {
        window.location.hash = "#checkout/userDetails";
        return;
      }

      if(isShippable) {
        setAddressModalShow(true)
      } else {
        window.location.hash = "#checkout/userDetails";
      }
    }
  }

  const backBtnClick = () => {
    if (hashUrl == '#checkout/viewOffers') {
      viewOffers(false);
      setViewOfferModalOnly(false)
      lockBodyScroll(false)
      window.location.hash = "";
    }
    else if (hashUrl == '#checkout/userDetails') {
      window.location.hash = "#checkout/viewOffers";
    }
    else if (hashUrl == '#checkout/payment') {
      window.location.hash = "#checkout/userDetails";
    }
  }
  const handleClose = () => {
    window.location.hash = "";
    lockBodyScroll(false)
    viewOffers(false)
    setViewOfferModalOnly(false)
    if (hashUrl.indexOf('#cart/success') > -1) {
      window.location.reload();
    }
  }


  return (
    <div className={`buynow-container ${addressModalShow ? 'buynow-container-address' : ''}`}>
      <div className="buynow-wrapper">
        <div className="buynow-header">
          {
            buyNowModalUrl.includes(hashUrl) && !viewOfferModalOnly && activeStep != 1 && <div className="buynow-back-icon" onClick={backBtnClick}><a ><img src="../../images/checkout-back-mob.svg" /></a></div>
          }

          <div className="buynow-header-heading" id={"available-offer-checkoutId"}>{viewOfferModalOnly ? 'Available Offers' : (hashUrl.indexOf('#cart/success') > -1) ? 'Confirmation' : 'Checkout'}</div>
          <div className="buynow-close-icon" onClick={handleClose}><a ><img src="../../images/checkout-close.svg" alt="" /></a></div>
        </div>
        {
          !viewOfferModalOnly && (hashUrl.indexOf('#cart/success') < 0) && <div className="buynow-steps">
            <ul className="steps">
              {
                stepArray.map((item, index) => {
                  return (
                    <li className={`step step-active ${activeStep != item.stepNo ? 'step-faded' : ''}`} key={`steps_${index}`}>
                      <div className="step-content" onClick={() => handleClickStep(item.stepNo)}>
                        <span className="step-circle" id={`stepClick_${item.stepNo}`}>{item.stepNo}</span>
                        <span className="step-text">{item.stepName}</span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
        {ModalBodyComponent}

      </div>
    </div>
  );
};
export default BuyNowCheckoutModal;

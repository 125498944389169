export const decipherStrings = (cipherText) => {
  const ALPHABET = "abcABCdefghijkIJKlmnopqrstuvwxyzUVWXYZDEFGHLMNOPQRST";
  if (!cipherText || cipherText.length === 0) {
    return cipherText;
  }

  var newArr = [];

  for (var i = 0; i < cipherText.length; i++) {
    var charPosition = ALPHABET.indexOf(cipherText.charAt(i));
    if (charPosition < 0) {
      newArr.push(cipherText.charAt(i));
    } else {
      var keyVal = (ALPHABET.length + charPosition - 8) % ALPHABET.length;
      var replaceVal = ALPHABET.charAt(keyVal);
      newArr.push(replaceVal);
    }
  }

  var decypheredText = newArr.join("").toString();

  if (decypheredText.indexOf("<p>") !== -1) {
    decypheredText = decypheredText.replace("<p>", "").replace("</p>", "");
  }

  return decypheredText;
};

export const countByStatus = (list) => {
  let countObj = list.reduce(
    (countObj, val) => {
      if (countObj[val] == undefined) countObj[val] = 0;
      countObj[val]++;
      return countObj;
    },
    {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    }
  );
  // as status are <9 sort will work fine
  return countObj;
};

/**
 * Opens a test portal in a new window or tab based on the given parameters.
 *
 * @param {string} mappingId - The mapping ID.
 * @param {string} packageId - The package ID.
 * @param {string} title - The title of the portal.
 * @param {string} status - The status of the portal, e.g., "INCOMPLETE" or "COMPLETED".
 * @param {string} reAtemptFlag - A string flag indicating whether this is a reattempt.
 * @param {Array} exams - An array of exams which is taged to test.
 *
 * @returns {void}
 */
export const openTestPortal = (
  mappingId,
  packageId,
  title,
  status,
  reAtemptFlag = "",
  exams = [],
  sfJson = "",
  dtb = false,
  ppc = false,
  examMode = ""
) => {
  const examParam = getExamListQueryParam(exams);
  let url = `/test-series-portal/${mappingId}/${packageId}/${
    status == "INCOMPLETE" ? "resume" : "new"
  }/${encodeURIComponent(title)}?${ppc ? `&ppc=${ppc}` : ""}${examParam ? `&${examParam}` : ""}${
    sfJson ? `&sfJsonPreview=${sfJson}` : ""
  }${dtb ? `&dtb=${dtb}` : ""}${examMode ? `&mode=${examMode}` : ""}`;

  if (status === "COMPLETED" && reAtemptFlag) {
    url = `/test-series-portal/${mappingId}/${packageId}/${reAtemptFlag}/${encodeURIComponent(
      title
    )}?freeContent=true${ppc ? `&ppc=${ppc}` : ""}${examParam ? `&${examParam}` : ""}${
      sfJson ? `&sfJsonPreview=${sfJson}` : ""
    }${dtb ? `&dtb=${dtb}` : ""}${examMode ? `&mode=${examMode}` : ""}`;
  }

  if (status === "COMPLETED" && !reAtemptFlag) {
    /**
     * Opens the test analysis window for a completed test.
     *
     * @type {string}
     */
    const resultUrl = `/my-test-analysis/${encodeURIComponent(
      title
    )}/${packageId}/${mappingId}?${ppc ? `&ppc=${ppc}` : ""}${examParam ? `&${examParam}` : ""}${
      sfJson ? `&sfJsonPreview=${sfJson}` : ""
    }${dtb ? `&dtb=${dtb}` : ""}${examMode ? `&mode=${examMode}` : ""}`;

    window.open(resultUrl);
  } else {
    window.open(
      url,
      "_blank",
      "height=" +
        screen.height +
        ",width=" +
        screen.width +
        ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
    );
  }
};

const examsPriority = ["BANKING", "SSC"];//add further exams here..

export const getExamListQueryParam = (exams = []) => {
  let examName = "";
  if (exams?.length) {
    // Loop through the examsPriority and set examName if found
    for (const exam of examsPriority) {
      if (exams.includes(exam)) {
        examName = exam;
        break; // Stop searching once a higher-priority exam is found
      }
    }
  }
  return examName ? `exam=${examName}` : "";
};

export const examInstructionPage1Language = ["ENGLISH", "HINDI"];

export const questionTypesLabel = {
  1: "Multiple Choice Question",
  2: "Fill In The Blanks",
  3: "Multiple Choice Multiple Correct",
  4: "Digit Filler",
  5: "Digit Filler Range",
  6: "Descriptive Test",
};
export const textSizeOptions = ["Small", "Medium", "Large", "Extra Large"];
export const textSizeClassKeyNames = {
  Small: "text-sm",
  Medium: "text-md",
  Large: "text-lg",
  "Extra Large": "text-ex-lg",
};
export const optionScrollHandler = (tcAnswerRef = "", key = "up") => {
  if (key === "up") {
    tcAnswerRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    tcAnswerRef.current.scrollTo({
      top: tcAnswerRef.current.scrollHeight,
      behavior: "smooth",
    });
  }
};

import React, { useMemo, useState } from 'react';
import CustomButton from '../CustomButton';
import OnboardingOtpInput from './OnboardingOtpInput';
import CustomModal from '../CustomModal';
import { onBoardingStepStates } from './onboardingConstants';

const getPopupScreen = ({...args}) => {

    const {
        currentStep, 
        closeModal,
        otpDetails,
        setOtpDetails,
        modalButtonAction,
        selectedChange,
        otpSentFlag,
        setOtpSentFlag,
        modalLoading,
        modalErrors,
        resendOTP,
        showEditIcon,
        editButtonAction,
        modalErrorDetails,

    } = {...args};

    function capitalize(str) {
        if (!str || str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    switch (currentStep) {
        case onBoardingStepStates.mobile_otp_send :
            return (
                <div>
                    <div className="signup-header-wrap">
                        <div className="signup-heading">OTP Verification</div>
                        <div className="signup-subheading">Enter the code from the message we sent to updated mobile number &nbsp;
                            <span className="otp-subheader-contact-wrapper">
                                <span className="otp-subheader-contact"> {otpDetails?.phone}</span> &nbsp;
                                {showEditIcon && <span className="email-edit-icon">Edit</span>}
                            </span>
                        </div>
                    </div>

                    <div className='otp-email-input-wrapper'>
                        <OnboardingOtpInput
                            otpDetails={otpDetails}
                            setOtpDetails={setOtpDetails}
                            resendOTP={resendOTP}
                            otpSentFlag={otpSentFlag}
                            setOtpSentFlag={setOtpSentFlag}
                            receiverType={"phone"}
                            showOtpDurationMsg={false}
                        />
                        <div className="nextbtncont">
                        <CustomButton
                            type="primary"
                            className={"bluebtn ripple"}
                            buttontext={"Submit"}
                            loading={modalLoading}
                            onClick={() => {
                                modalButtonAction(otpDetails, true);
                                closeModal();
                            }}
                        />
                        </div>
                    </div>
                </div>
            );
        case onBoardingStepStates.account_recovery_details_collect :
            return (
                <div>
                    <div className="signup-header-wrap">
                        <div className="signup-heading">Account Recovery Details</div>
                        <div className="signup-subheading">Please provide your email id in case you need to recover this account
                        </div>
                    </div>
                    
                    <div className="onbaorPIBox margin18">
                        <label className="floating-label">Email ID</label>
                        <input
                            data-testid="email-input-box"
                            type={"email"}
                            onChange={(e) => {setOtpDetails({...otpDetails, email : e.target.value})}}
                            placeholder="Enter Email ID"
                            className={modalErrors?.email?.[0] ? "wrongpass a-input" : "a-input"}
                            value={otpDetails?.email}
                        />
                        <div className="otpErrorWrap">
                            {modalErrors?.email}
                        </div>
                    </div>

                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Send OTP"}
                        loading={modalLoading}
                        onClick={() => {modalButtonAction(otpDetails)}}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.email_otp_verify :
            return (
                      <div>           
                    <div className='signup-header-wrap'>
                    <div className='signup-heading'>OTP Verification</div>

                        <div className='signup-subheading'>
                        Enter OTP from the message we have sent to Email ID &nbsp;
                        <span className='email-edit-wrap'>
                                <span className='email'>{otpDetails?.email}</span>&nbsp;
                        {showEditIcon && <span className='email-edit-wrap' onClick={editButtonAction}>
                            <span className='email-edit-icon'>edit</span>
                        </span>}
                        </span>
                 </div>
                    </div>
                    <OnboardingOtpInput
                        otpDetails={otpDetails}
                        setOtpDetails={setOtpDetails}
                        resendOTP={resendOTP}
                        otpSentFlag={otpSentFlag}
                        setOtpSentFlag={setOtpSentFlag}
                        receiverType={"email"}
                        showOtpDurationMsg={false}
                    />
                    <div className="otpErrorWrap">{modalErrors?.email}</div>
                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Submit"}
                        loading={modalLoading}
                        onClick={() => {
                            // Send OTP to Mobile API here
                            modalButtonAction(otpDetails);
                        }}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.mobile_otp_verify :
            return (
                <div>
                       <div className='signup-header-wrap'>
                    <div className='signup-heading'>OTP Verification</div>

                        <div className='signup-subheading'>
                        Enter OTP from the message we sent to updated mobile number &nbsp;
                        <span className='email-edit-wrap'>
                                <span className='email'>{`+91 ${otpDetails?.phone || otpDetails?.newPhoneNumber}`}</span>&nbsp;
                        {showEditIcon && <span className='email-edit-wrap'>
                            <span className='email-edit-icon'>edit</span>
                        </span>}
                        </span>
                 </div>
                    </div>

                    <OnboardingOtpInput
                        otpDetails={otpDetails}
                        setOtpDetails={setOtpDetails}
                        resendOTP={resendOTP}
                        otpSentFlag={otpSentFlag}
                        setOtpSentFlag={setOtpSentFlag}
                        receiverType={"email"}
                        showOtpDurationMsg={false}
                    />
                    <div className="otpErrorWrap">{modalErrors?.phone}</div>
                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Submit"}
                        loading={modalLoading}
                        onClick={() => {
                            // Send OTP to Mobile API here
                            modalButtonAction(otpDetails);
                        }}
                    />
                    </div>

                </div>
            );
        case onBoardingStepStates.password_change :
            return (
                <div>
                  
                    <div className="signup-header-wrap">
                        <div className="signup-heading">Change your password</div>
                        <div className="signup-subheading">Enter your current password and your  &nbsp;<span className="otp-subheader-contact-wrapper">
                            <span className="otp-subheader-contact">New Password</span> 
                        </span>
                        </div>
                    </div>


                    <div className='password-change-body-wrap'>

                        {otpDetails?.hasPassword && <div className="onbaorPIBox">
                            <label>Current Password</label>
                            <div className="password-eye">
                                <input 
                                    placeholder='Enter Password' 
                                    type={otpDetails?.showCurrentPassword ? "text" : "password"}
                                    onChange={(e) => {setOtpDetails({...otpDetails, currentPassword : e.target.value})}}
                                    value={otpDetails?.currentPassword}
                                />
                                <div
                                    className="eye-pass"
                                    onClick={() => setOtpDetails({...otpDetails, showCurrentPassword : !otpDetails?.showCurrentPassword})}
                                >
                                    {otpDetails?.showCurrentPassword ? (
                                        <img src="/images/show-pass-eye-icon.svg" alt="" />
                                    ) : (
                                        <img src="/images/crossed_eye.svg" alt="" />
                                    )}
                                </div>
                            </div>
                            <div className="otpErrorWrap">{modalErrors?.password?.[0]}</div>
                        </div>}
                        <div className="onbaorPIBox">
                            <label>New Password</label>
                            <div className="password-eye">
                                <input 
                                    placeholder='Enter Password' 
                                    type={otpDetails?.showNewPassword ? "text" : "password"}
                                    onChange={(e) => {setOtpDetails({...otpDetails, newPassword : e.target.value})}}
                                    value={otpDetails?.newPassword}
                                />
                                <div
                                    className="eye-pass"
                                    onClick={() => setOtpDetails({...otpDetails, showNewPassword : !otpDetails?.showNewPassword})}
                                >
                                    {otpDetails?.showNewPassword ? (
                                        <img src="/images/show-pass-eye-icon.svg" alt="" />
                                    ) : (
                                        <img src="/images/crossed_eye.svg" alt="" />
                                )}
                                </div>
                            </div>
                            <div className="otpErrorWrap">{modalErrors?.password?.[1]}</div>
                        </div>
                        <div className="onbaorPIBox">
                            <label>Confirm Password</label>
                            <div className="password-eye">
                                <input 
                                    placeholder='Enter Password' 
                                    type={otpDetails?.showConfirmPassword ? "text" : "password"}
                                    onChange={(e) => {setOtpDetails({...otpDetails, confirmPassword : e.target.value})}}
                                    value={otpDetails?.confirmPassword}
                                />
                                <div
                                    className="eye-pass"
                                    onClick={() => setOtpDetails({...otpDetails, showConfirmPassword : !otpDetails?.showConfirmPassword})}
                                >
                                    {otpDetails?.showConfirmPassword ? (
                                        <img src="/images/show-pass-eye-icon.svg" alt="" />
                                    ) : (
                                        <img src="/images/crossed_eye.svg" alt="" />
                                    )}
                                </div>
                            </div>
                            <div className="otpErrorWrap">{modalErrors?.password?.[2]}</div>
                        </div>
                    </div>
                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Save"}
                        loading={modalLoading}
                        onClick={() => {modalButtonAction(otpDetails)}}
                    />
                    </div>

                </div>
            );
        case onBoardingStepStates.no_details_found :
            return (
                <div>
                    <div className="signup-header-wrap">
                        <div className="signup-heading">No Details Found</div>
                        <div className="signup-subheading">Please sign in via mobile
                        </div>
                    </div>
                <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Sign-in"}
                        loading={modalLoading}
                        onClick={() => {
                            // Send OTP to Mobile API here
                            closeModal();
                        }}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.account_already_linked_error :
            return (
                <div>
                    <div className="signup-header-wrap">
                        <div className="signup-heading">Error</div>
                        <div className="signup-subheading">The email ID or mobile number is already linked with other account
                        </div>
                    </div>
                    
                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Ok"}
                        loading={modalLoading}
                        onClick={() => {
                            // Send OTP to Mobile API here
                            closeModal();
                        }}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.change_success :
            return (
                <div>
                     <div className="signup-header-wrap">
                        <div className="signup-heading">{`${capitalize(selectedChange || "")} Changed`}</div>
                        <div className="signup-subheading">{`Your ${selectedChange} has been successfully changed`}
                        </div>
                    </div>
                <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Ok"}
                        loading={modalLoading}
                        onClick={() => {
                            
                            closeModal();
                        }}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.account_recovery_success :
            return (
                <div>
                        <div className="signup-header-wrap">
                        <div className="signup-heading">{`Recovery Details Added `}</div>
                        <div className="signup-subheading">{`Your account recovery details has been successfully added`}
                        </div>
                    </div>
                <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={"Ok"}
                        loading={modalLoading}
                        onClick={() => {
                            
                            closeModal();
                        }}
                    />
                    </div>
                </div>
            );
        case onBoardingStepStates.modal_error :
            return (
                <div>
                    <div className="signup-header-wrap">
                        <div className="signup-heading">{modalErrorDetails?.heading}</div>
                        <div className="signup-subheading">{modalErrorDetails?.subHeading}</div>
                    </div>
                <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={modalErrorDetails?.btnLabel}
                        loading={modalLoading}
                        onClick={() => {
                            closeModal();
                        }}
                    />
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const OnboardingModal = (props) => {

    const {
        showModal = false, 
        closeModal = () => {}, 
        currentStep = "", 
        otpDetails = {},
        setOtpDetails = () => {},
        modalButtonAction = () => {},
        selectedChange =  "",
        otpSentFlag = false,
        setOtpSentFlag = () => {},
        modalLoading = false,
        modalErrors = {},
        resendOTP= () => {},
        showEditIcon = true,
        editButtonAction = () => {},
        modalErrorDetails = {}
    } = props;

    const popupScreen = useMemo(
        () => getPopupScreen({
            currentStep, 
            closeModal,
            otpDetails,
            setOtpDetails,
            modalButtonAction,
            selectedChange,
            otpSentFlag,
            setOtpSentFlag,
            modalLoading,
            modalErrors,
            resendOTP,
            showEditIcon,
            editButtonAction,
            modalErrorDetails,
        }), 
    [
        currentStep, 
        otpDetails, 
        modalButtonAction, 
        selectedChange, 
        otpSentFlag, 
        modalLoading,
        modalErrors,
        resendOTP,
        modalErrorDetails,
    ]);

    return (
        <div>
            <CustomModal
                modalClassName={"ppcBorderPadding"}
                visible={showModal}
                onCancel={closeModal}
                closeCallback={closeModal}
                hideHeader={true}
            >
            <div className={`onboarding-popup-content-wrapper`}>
                <div className='onboarding-modal-cross-icon'>
                <div className="right-cross-icon" onClick={closeModal}>
                    <img src="/images/login-pop-cross-btn.svg" />
                </div>
                </div>
                {currentStep && popupScreen}
            </div>
            </CustomModal>
        </div>
    )
}

export default OnboardingModal;
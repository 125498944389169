const GET_PACKAGE_DETAILS = 'GET_PACKAGE_DETAILS';
const GET_PACKAGE_OFFERING = 'GET_PACKAGE_OFFERING';
const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
const CLEAR_PACKAGE_DETAILS_DATA = 'CLEAR_PACKAGE_DETAILS_DATA'
const CHECK_PURCHASED_PACKAGE ='CHECK_PURCHASED_PACKAGE'
const PACKAGE_MAHAPACK ='PACKAGE_MAHAPACK'
export default {
    GET_PACKAGE_DETAILS,
    CLEAR_PACKAGE_DETAILS_DATA,
    GET_PACKAGE_OFFERING,
    UPDATE_USER_ADDRESS,
    CHECK_PURCHASED_PACKAGE,
    PACKAGE_MAHAPACK
}
import React, { useState, useEffect , useMemo, useCallback} from 'react'
import CustomLoader from '../components/library/CustomLoader';
import { pdpUrls } from '../../constants/appConfig';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { getPdpUrl, isADDA, optimizedImgUrl, timestampToDateTime } from '../data/utils/helpers';
import { getManageExamsByPackage, getPackageCategories } from '../data/ducks/header/actions';
import fetch from '../data/utils/fetch';
import CustomModal from '../components/library/CustomModal';
import { timestampToDateTimedaysecond } from '../components/utils';
import { textConstants } from '../../constants/appConstants';

const orderProductCard = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [trackerStatusdata, settrackerStatusdata] = useState('');
  const [data, setData] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  var storeItemsUrlMapping = {
    'mock-tests': 'testseries',
    'videos': 'video',
    'books': 'books',
    'ebooks': 'ebooks',
    'live-classes': 'onlineliveclasses'
  };

  useEffect(()=>{
     let data = localStorage.getItem('orderId')
     if(!data) return
     trackOrder(data)
  },[])

  const trackOrder = (orderId) => {
    setDisableButton(true);
    try {
      fetch(
        `${pdpUrls.trackerAPi}?orderId=${orderId}`,
        "GET"
      ).then(
        res => {
          if (res && res.data) {
            setVisible(!visible)
            setData(res.data);
            settrackerStatusdata(res.data.detailInfos);
          }
          setDisableButton(false);
        },
        err => {
          setVisible(!visible)
          setData("")
          settrackerStatusdata("")
          setDisableButton(false);
        }
      );
    } catch (e) {
      console.log("error: ", e);
    }
    localStorage.removeItem('orderId');
  }

  const makeUrl = (url) => {
    url = url.split(',').join('');
    url = url.split('/').join('-');
    if (typeof (url) != 'undefined' && url != '') {
      return url.split(' ').join('-').toLowerCase();
    }
    else return url;
  }

  const handleClick =(status, pkgData) => {
    if(status === 'PAID') {
      getPaidClickEvent(pkgData)
    } else {
      if(pkgData?.published) {
        history.push(
          getPdpUrl(
            pkgData?.packageCategories[0],
            pkgData?.id,
            pkgData?.title,
            pkgData?.slugurl
          )
        );
      } else {
        history.push({
          pathname: "/",
          hash: "#BANKING", //pass hash exam category value.
        });
      }
    }
  }

  const getPackageCategoriesData = async (packageId) => {
    return dispatch(getPackageCategories(packageId));
  };
  const getManageExam = (packageId) => {
    return dispatch(getManageExamsByPackage(packageId));
  };
  const redirectToCategoryLevel = (
    categorieData,
    childPckgId,
    packageId,
    title
  ) => {
    let redirectUrl = "";

    if (categorieData?.categories?.length > 1) {
      // when categories data is more than 1 than redirect to productDetail overview page.
      redirectUrl = `/my-purchase/${packageId}/${makeUrl(title)}`;
    }
    // else redirect the user to direct consumption screen based on the category.
    else {
      redirectUrl = `/my-purchase/${packageId}/${makeUrl(
        title
      )}/${childPckgId}/${makeUrl(title)}`;
    }
    history.push(redirectUrl);
  };
  const getPaidClickEvent = (pkgData) => {
    let onClick = () => {};
    // when package is expired and publish than redirect direct to PDP page.
    if (pkgData?.expired && pkgData?.published) {
      onClick = () => history.push(
        getPdpUrl(
          pkgData?.packageCategories[0],
          pkgData?.id,
          pkgData?.title,
          pkgData?.slugurl
        )
      );
    }
    // when package is expired but unpublish than redirect to home page examMenu section.
    else if (pkgData?.expired && !pkgData?.published) {
      onClick = () => {
        history.push({
          pathname: "/",
          hash: "#BANKING", //pass hash exam category value.
        });
      };
    }
    // if parent:false and length of childPackages is 1 (redirect to direct consumption screen) || pkgData?.childPackages may be come null.
    else if (
      !pkgData?.parent &&
      (pkgData?.childPackages?.length === 1 || pkgData?.childPackages?.length === 0)
    ) {
      onClick = () => {
        history.push(
          `/my-purchase/0/${makeUrl(pkgData?.title)}/${
            pkgData?.id
          }/${makeUrl(pkgData?.title)}`
        );
      };
    } else if (pkgData?.parent && pkgData?.childPackages?.length === 1) {
      /**
      * if parent:true and length of childPackages is 1    
      --> Step1 - Call getCategories tabs API.
      --> Step2 - now check if categories length is greater than 1,then redirect the user on productDetail Screen with tabs otherwise(when categories length is 1.)
        redirect user direct on consumption screen.
      */
      onClick = async () => {
        if (pkgData?.packageCategories[0] === "BOOKS") {
          if (confirm("Please check my order section for Book!")) {
            history.push("/my-orders");
          }
          return;
        }
        // call getCategories API.
        const res = await getPackageCategoriesData(pkgData?.id);
        const data = await res[0];
        let categorieData = data?.data;
        if (categorieData?.categories?.length && pkgData?.childPackages[0]) {
          redirectToCategoryLevel(
            categorieData,
            pkgData?.childPackages[0],
            pkgData?.id,
            pkgData?.title
          );
        } else {
          alert("category and childPkg not found!.");
        }
      };
    } else if (
      pkgData?.parent &&
      pkgData?.childPackages?.length > 1 &&
      pkgData?.childPackages?.length < 20
    ) {
      /**
      * if parent:true and length of childPackages is greater than 1 and less than 20.    
      --> redirect the user to the productDetails listing page.
      */
      onClick = () => {
        history.push(
          `/my-purchase/${pkgData?.id}/${makeUrl(pkgData?.title)}`
        );
      };
    } else if (pkgData?.parent && pkgData?.childPackages?.length > 20) {
      /**
       * redirect to examlist screen if parent package has more than 20 child packages and user visit first time.
       *
       */
      onClick = async () => {
        //call get exam data (to check user visit first time based on selected exams)
        const res = await getManageExam(pkgData?.id);
        const result = await res[0];
        const data = result?.data;
        // no. of exams is greater than 1 and selected exam array length is null
        if (data?.examList?.length > 1 && !data?.selectedExamList?.length) {
          history.push({
            pathname: `/my-purchase/${pkgData?.id}/${makeUrl(
              pkgData?.title
            )}/selectExams`,
          });
        } else {
          history.push(
            `/my-purchase/${pkgData?.id}/${makeUrl(pkgData?.title)}`
          );
        }
      };
    } else {
      onClick = () => {
        history.push(
          `/my-purchase/${pkgData?.id}/${makeUrl(pkgData?.title)}`
        );
      };
    }
    onClick();
  };

  const onCancelCallback = () => {
    setVisible(false)
    props.orderIdHistoryProps();
  }

  const onCancel = () => {
    setVisible(false)
    props.orderIdHistoryProps();

  }

  return (
    <div>
      <CustomModal
        modalClassName={"modalZindex"}
        footer={null}
        visible={visible}
        title={<div className={"modal-header"}>Order Tracker</div>}
        onCancel={()=>onCancel()}
        closeCallback={()=>onCancelCallback()}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ overflowY: "auto" }}
      >
        <div style={{ padding: '15px' }}>
          <div className='d-flex tracker-header'>
            <div className='d-flex d-f-column d-grow-1'>
              <span className='track-label-header'>Shipment Status</span>
              <span className='d-flex'>
                <span className='track-label'>
                  Last Status Date:
                </span>
                <span className='track-order-time'>
                  {data && data.laststatustime && timestampToDateTimedaysecond(data.laststatustime)}
                </span>
              </span>
              <span className='d-flex  '>
                <span className='track-label' >
                  Last Status:
                </span>
                <span className='track-order-time'>
                  {data.lastStatus}
                </span>
              </span>
              <br/>
              <span className='d-flex  '>
                <span className='track-label' >
                {textConstants.trackingId} 
                </span>
                <span className='track-order-time'>
                {data.trackingId}
                </span>
              </span>
            </div>
            <div className='d-flex d-f-column'>
              <span className='track-label-header'>
                Shipment Details
              </span>
              <span className='d-flex'>
                <span className='track-label'>
                  Destination:
                </span>
                <span className='track-order-time'>
                  {props.address?.city}
                </span>
              </span>
              <span className='d-flex'>
                <span className='track-label'>
                  Destination pincode:
                </span>
                <span className='track-order-time'>
                  {props.address?.pincode}
                </span>
              </span>
            </div>
          </div>
          {
            trackerStatusdata ?
              trackerStatusdata.map((result, idx) => {
                return (
                  <div className="track-p" style={{
                    display: "flex",
                    flexDirection: 'row'
                  }}>
                    {trackerStatusdata.length - 1 != idx ? <div className="tracker-line" data-key={idx}></div> : null}
                    <span className={`tracker-dot ${idx == 0 ? 'first' : ''}`}>
                    </span>
                    <span className='track-order-time'>
                      {timestampToDateTimedaysecond(result.timestamp)}
                    </span>
                    <span className='status-shown'>
                      <span dangerouslySetInnerHTML={{__html: result.activityType}}/>
                        <span className='track-order-city'>
                        {result.origin},IN
                      </span>
                    </span>
                  </div>
                )
              })
              : null
          }
        </div>
      </CustomModal>
      {
        props.orderDetail && props.orderDetail.length > 0 ?
          props.orderDetail.map((result, idx) => {
            return (
              <div style={{ marginTop: "-10px !important" }}   >
                <div className='product-card-container' style={{ marginTop: "2px" }} >
                  <div className='img-width-cart'>
                    <img className='img-w-h'
                      src={optimizedImgUrl(result.imageUrl)} />
                  </div>
                  <div className='d-grow-1 title-mweb'>
                    <p className='line-clamp' onClick={() => handleClick(props.status, result)}>
                      {result.title}
                    </p>
                  </div>
                  <div className='d-grow-1 title  '   >
                    <p className='line-clamp' onClick={() => handleClick(props.status, result)}>
                      {result.title}
                    </p>
                  </div>
                  {/* <div>
                    <span className='d-flex values'>&#x20B9;<b>{result.sellingPrice}</b> </span>
                  </div> */}
                </div>
                {
                  props.status == 'PAID' ?
                    <div className='product-card-container' style={{ flexDirection: 'row-reverse' }}>
                      <div>
                        {props.address && result.shippable ?
                          <span className='view-btn-b font-size-9' >
                            Order Received
                          </span>
                          :
                          <div>
                            <span className='view-btn-mweb font-size-9'
                              onClick={() => handleClick(props.status, result)}>
                              VIEW
                              <span className='right-toggle-red'>
                              </span>
                            </span>
                            <span className='view-btn font-size-9'
                              onClick={() => handleClick(props.status, result)}>
                              VIEW
                              <span className='right-toggle-red'>
                              </span>
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                    :
                    <div className='product-card-container' style={{ flexDirection: 'row-reverse' }}>
                      <span className='view-btn font-size-9' onClick={() => handleClick(props.status, result)}>
                        VIEW
                        <span className='right-toggle-red'>
                        </span>
                      </span>
                      <span className='view-btn-mweb font-size-9' >
                      </span>
                    </div>
                }
                {
                  props.address && props.status == 'PAID' && result.shippable ?
                    <div className='product-card-container' style={{ borderTop: 'solid 1px #a4aab3' }}>
                      <div className='d-flex d-grow-1'>
                        <div className='d-grow-1  d-flex d-f-column' >
                          <span className='address-cart'>
                            Delivery Address
                          </span>
                          <span className='name'>
                            {props.address.name}
                          </span>
                          <span className='address-detail'>
                            {props.address.firstLine}
                          </span>
                          <span className='address-detail'>
                            {props.address.secondLine}
                          </span>
                          <span className='address-detail'>
                            {props.address.city},{props.address.state}-{props.address.pincode}
                          </span>
                          <span className='address-detail'>
                            Phone: {props.address.phone}
                          </span>
                        </div>
                        <div>
                          <button className='track-order-btn'
                            disabled = {disableButton}
                            onClick={() => trackOrder(props.orderId)}>Track Order</button>
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            )
          }
          )
          :
          <div style={{ margin: "auto" }}
            className='loader-container'>
            <CustomLoader />
          </div>
      }
    </div>
  )
};


export default orderProductCard

import { useEffect, useState } from "react";
export const useExternalScript = (url) => {
  let [state, setState] = useState(url ? "loading" : "idle");
  
  useEffect(() => {
  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  document.body.appendChild(script);
   return () => {
    document.body.removeChild(script);
   };
  }, []);
};
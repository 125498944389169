import React, { useState, useEffect } from "react";
import fetch from "../../data/utils/fetch";
import { makeUrl, getDeviceType, getMoengageMetadata, getPdpUrl, createOptimizedImgUrl, saveUserSearchAnalyticsData } from '../../data/utils/helpers';

import NewCarousel from "../library/NewCarousel";
import { STOREFRONT_URI, pageName, myPurchase, FallBackImage } from "../../../constants/appConfig";
import { Link } from "react-router-dom";
import AButton from "../library/AButton";
import { dateMonthYear } from "../utils";
import ContineInApp from "../library/ContinueInApp/ContinueInApp";
import { useDispatch, useSelector } from "react-redux";
import { savePurchasedCoursesData, fetchedPurchasedCoursesData } from "../../data/ducks/header/actions";
import NewCarouselInsta from "../library/NewCarouselInsta";
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
import LazyIntersection from "../LazyIntersection";
let coursepurchaseContainer = React.createRef();

const MyPurchasedCoursesLatest = (props) => {
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

  const dispatch = useDispatch();
  const purchasedCoursesList  = useSelector((state) => state.header.purchasedCourses);
  const purchasedCourseDataPageNos = useSelector((state) => state.header.fetchedPurchasedCoursesData);
  let previousPage;

  useEffect(() => {
    if (purchasedCoursesList && purchasedCoursesList.length ) {
      let options = {
        root: document.getElementById(`my-purchased-home-page`),
        rootMargin: "0px",
        threshold: 0.5,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            // getNextPageData();
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById(`my-purchased-home-page-last-item`);
      if (target) observer.observe(target);
    }
  }, [purchasedCoursesList]);

  useEffect(() => {
    if(!purchasedCoursesList?.length)
     getNextPageData();
  }, []);

  const getNextPageData = () => {
    let nextPageNo = pageNo + 1;
    if (pageNo == -2) return; // if reached end of data
    if(previousPage === nextPageNo) return;
    previousPage = nextPageNo;
      fetch(
        `${STOREFRONT_URI}/api/v1/my/boughtpackages?status=active&sortby=recent&page=${nextPageNo}&size=40`,
        "GET"
      )
        .then((res) => {
          if (res && res.data) {
            dispatch(fetchedPurchasedCoursesData([nextPageNo]));
            setPageNo(nextPageNo);
            // setData([...data, ...res.data]);
            dispatch(savePurchasedCoursesData(res.data));
          } else {
            setPageNo(-2);
          }
        })
        .catch(() => { });
  };
  const [appModaldata, setModalAppdata] = useState();

  const handleCardClick = (id, categories) => {
    let {ONLINE_LIVE_CLASSES_IDENTIFIER, VIDEOS_IDENTIFIER, EBOOKS_IDENTIFIER, TEST_SERIES_IDENTIFIER} = COURSE_CATEGORY_IDENTIFIERS
    let validCategories = [ONLINE_LIVE_CLASSES_IDENTIFIER, VIDEOS_IDENTIFIER, EBOOKS_IDENTIFIER, TEST_SERIES_IDENTIFIER];
    let categoryArr = []
    if(categories){
      categoryArr = categories.split(",");
    }
    let validCategory = '';
    for(let i = 0; i < categoryArr.length; i++){
      if(validCategories.includes(categoryArr[i])){
        validCategory = categoryArr[i];
        break;
      }
    }
    setModalAppdata({
      packageId: id,
      type: validCategory,
    })
  }




  const [pageNo, setPageNo] = useState(purchasedCourseDataPageNos?.length ? purchasedCourseDataPageNos[purchasedCourseDataPageNos[purchasedCourseDataPageNos.length - 1]] : -1);
  if (purchasedCoursesList.length == 0) return null;
  return (
    <>
      <section className="container-padding purchase-container continue-studyning">
        <ContineInApp
          show={!!appModaldata}
          type={appModaldata && appModaldata.type}
          packageId={appModaldata && appModaldata.packageId}
          parentpackageId={""}
          close={() => {
            setModalAppdata("");
          }} />
        <div className="d-flex my-purchase-header">
          <div className="common-headding">
              My Purchased <span>Courses</span></div>
          <div className='my-purchase-viewall'
            onClick={() => {

              if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                'My_Purchased_Courses_Viewall_btn_clicked',
                getMoengageMetadata(
                  {}
                ),
              );
              props.goTo("my-purchase");
            }}

          > <div>View all {' '}</div><div><img src='/images/icon-sm-right.svg' alt='right-icon'/></div></div>

        </div>
        {
          <NewCarouselInsta
            id={`my-purchased-home-page`}
            ref={coursepurchaseContainer}
            margin={10}
            dataLength={purchasedCoursesList.length}
            lastValue = {3}
          >
            {purchasedCoursesList.map((value, index) => {

              let moengageEvent = () => {
                var payload = {};
                payload.id = value.id;
                payload.name = value.title;
                payload.package_status = "Paid";
                payload.category = value.categories;
                payload.exam_category = value.courses;
                payload.package_url = typeof window != undefined && window.location.origin + getPdpUrl(value.categories, value.id, value.title, value.slugURL)
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Purchased_Course_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
              }
              // save user consumption analytics
              const saveAnalytics = () => {
                saveUserSearchAnalyticsData({
                  url: myPurchase.userAnalyticsPaidContent,
                  data:value,
                });
              }

              let onCardClick;
              let linkurl;
              if (value.categories == "BOOKS") {
                onCardClick = () => {
                  alert("Please check my order section for Book")
                }
              }

              else {
                linkurl = value.linkedPkgsExists ? `/my-purchase/${value.id}/${makeUrl(value.title, true)}`
                  : `/my-purchase/0/package/${value.id}/${makeUrl(value.title, true)}`
                  if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) {
                  linkurl = value.linkedPkgsExists ? `/my-purchase/${value.id}/${makeUrl(value.title, true)}` : ""
                  onCardClick = () => {
                    handleCardClick(value?.id, value?.categories)
                  }
                }
              }
              return (
                <div className="mypurchase continue-reading paid-product-box" key={index}>
                  <Link
                    to={linkurl && linkurl}
                    onClick={() => {
                      !linkurl ? onCardClick() : null
                      moengageEvent()
                      saveAnalytics()
                    }}

                    id={
                      index == purchasedCoursesList.length - 3
                        ? `my-purchased-home-page-last-item`
                        : `my-purchased-home-page-${index}`
                    }
                  >
                    <div className="purchaseImage product-img-paid" title={value.title}>
                      <div
                        className="purchaseImageBg"
                        style={{ backgroundImage: `url(${value.imgUrl})` }}
                      ></div>
                      <div className="purchaseImageOverlay overlay-bg"></div>
                      <LazyIntersection
                        src={createOptimizedImgUrl(value.imgUrl, 200)}
                        placeholder={FallBackImage}
                        alt={pageName[0]}
                        style={{
                          maxWidth: "200px",
                          maxHeight: "190px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>

                    <div className="textcontainer paid-content-wrap">
                      <div className="coursename-paid" title={value.title}>{value.title}</div>
                      <span className="timeleft">
                        {`Valid upto : ${dateMonthYear(parseInt(value && value.expirydate + "000"))}`}{" "}
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </NewCarouselInsta>
        }
      </section>
    </>
  );
};

export default MyPurchasedCoursesLatest;

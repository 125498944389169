import React from "react";
import CallNowWidget from "./CallNowWidget";
import {
  getMoengageMetadata,
  openWidget,
  useHideChatWithUsCTA,
} from "../../data/utils/helpers";
import HelpCard from "./HelpCard";

const PDPContactSupport = ({
  contact,
  deviceType,
  Getpayload,
  Position,
  examCategoryName,
  examName,
  subCatData,
  utmParams,
}) => {
  useHideChatWithUsCTA()
  const handleSupportClick = () => {
    const date = new Date();
    const data = {
      examCategory: examCategoryName,
      exam: examName,
      product: "Purchase Related",
      issue: "Request a Sales Call",
      date,
    };
    localStorage.setItem("supportData", JSON.stringify(data));
    window.open("/support", "_blank");

    let payload = Getpayload || {};
    payload.package_url = window?.location?.href || "";
    payload.Position = "Bottom";
    payload.getUtmFromStore = utmParams || "";

    if (typeof Moengage !== "undefined")
      if (typeof Moengage !== "undefined")
        Moengage.track_event(
          "Contact_Us_Button_clicked",
          getMoengageMetadata(payload)
        );
  };

  const openChatWithUsWidget = () => {
    // open the widget
    openWidget();
  };
  return (
    <section className="pdpNeedHelpWrap">
      <div className="pdp-container">
        <div className="pdpneedHelp">
          <div className="pdpNeedHelpContent">
            <div className="pdpNeedHelpHeading webneed">
              Need any <span>help?</span>
            </div>
            <div className="pdpneedContentWrap">
              {/* Contact us */}
              <HelpCard
                title="Get help with our 24x7 Customer Service"
                btnName="Contact Us"
                onClick={() => handleSupportClick()}
              />
              {/* Chat us */}
              <HelpCard
                title="Chat with us for any queries"
                btnName="Chat Now"
                onClick={openChatWithUsWidget}
              />
              {/* Call us */}
              {contact && (
                <CallNowWidget
                  contact={contact}
                  deviceType={deviceType}
                  showDirectCall={true}
                  Getpayload={Getpayload}
                  Position={Position}
                />
              )}
            </div>
          </div>
          <div className="pdpNeedHelpImg">
            <img src="/images/pdpcontactimg.svg" alt="pdpContact" />
          </div>
          <div class="pdpNeedHelpHeading mwebneed">
            Need any <span>help?</span>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PDPContactSupport;

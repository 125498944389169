import React, { useState } from 'react';


const ContentSection = (props) => {


    return (
        <div className='contentSection'>
            <div className='contentSectionHeader'>
                <div>{props.header}</div>
                </div>
            <div className='contentSectionBody'>
               {props.body}
            </div>

        </div>
    )
}
export default ContentSection;
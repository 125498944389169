// Wrapper to handle UI implementations of Hash based Modal

import React, { useEffect, useState, lazy, Suspense } from "react";
import CustomModal from "./library/CustomModal";
// import PackageItems from "./PackageItems";
// import VideoListing from "./VideoListing";
// import TestSeries from "./TestSeries";
// import Ebooks from "./Ebooks";
// import LiveClasses from "./LiveClasses";
import { getProductDetail, clearProductDetailsData, getPackageOffering } from '../data/ducks/productDetails/actions';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router";
const PackageItems = lazy(() => import('./PackageItems'));
const VideoListing = lazy(() => import('./VideoListing'));
const TestSeries = lazy(() => import('./TestSeries'));
const Ebooks = lazy(() => import('./Ebooks'));
const Models3D = lazy(() => import('./Models3D'));
const LiveClasses = lazy(() => import('./LiveClasses'));
const TestSeriesAndEbook = lazy(() => import('./TestSeriesAndEbook'));
const ErrorPage = lazy(() => import('./ErrorPage'));



const IncludedPackages = (props) => {


  const [hashUrl, setHashUrl] = useState("");
  const [header, setHeader] = useState(props.title || "What are you preparing for?");
  const [showAlreadyPurchased, setShowAlreadyPurchased] = useState(true);
  const history = useHistory()
  const location = useLocation()

const setHashUrlHandler = (e) => {
    let hashArray = window.location.hash.split("/");
    if (hashArray.length <= 3 && hashArray[0] === "#packageDetails") {
      setHeader(props.title);
    }
    setHashUrl(window.location.hash);
}

  useEffect(() => {
    if (window.location.hash) {
      setHashUrl(window.location.hash);
    }
    window.addEventListener("hashchange", setHashUrlHandler,  false);
    return () => window.removeEventListener("hashchange",setHashUrlHandler,false)

  }, [props.title]);
  const close = () => {
    window.location.hash = "";
  };
  const back = () => {
      let hashArray = hashUrl.split("/");
      if (hashArray.length == 3) window.location.hash = ''
      else if (hashArray[0] === "#subPackageDetails") {
        window.location.hash = `packageDetails/${props.id}/ebooksPkgs`;
      }
      else if (hashArray.length == 6 && hashArray[2] === 'videoPkgs') {
        /**
         * Very important NOTE for your refrence***
         * for the videoPkgs there has 3 level's of nested routes.
         * Level1 -> ByDefault video tab section when package Modal open for the videoPkgs.
         * Level2 -> By clicking on specific package -> you found available list subjectListing.
         * Level3 -> By clicking on specific subjectListing -> you found available list of further list of videos.
         * Redirect -> after all above step now user redirect to another page if that video is unlocked from logined user.
         */
        //NOTE: for the videoPkgs we need to pop last three elements from an hashArray to go back.
        //handling back press for the second level of videoPkgs route.
        hashArray.pop();
        hashArray.pop();
        hashArray.pop();
        window.location.hash = hashArray.join('/')
      } else if (hashArray.length == 7 && hashArray[2] === 'videoPkgs') {
        //handling back press for last level of videoPkgs route.
        hashArray.splice(5, 1)
        window.location.hash = hashArray.join('/')
      }
      else {
        hashArray.pop();
        hashArray.pop();
        if (props.isStepSkip) {
          hashArray.pop();
          hashArray.pop();
          hashArray.pop();
          props.setStepSkip(true);
        }
        window.location.hash = hashArray.join('/')
      }
  }
  if (!hashUrl) return null;
  // Get Modal Id from hash [0];
  let hashArray = hashUrl.split("/");
  let ModalBodyComponent = "";
  // Handle all the routings Here #packageDetails/3969/testPkgs
  if (hashArray.length <= 3 && hashArray[0] === "#packageDetails") {
    ModalBodyComponent = (
      <Suspense fallback={<div></div>}>
        <PackageItems
          packageId={hashArray[1]}
          packageType={hashArray[2]}
          setStepSkip={props.setStepSkip}
        ></PackageItems></Suspense>
    );
  }
  else if (hashArray.length <= 4 && hashArray[0] === "#subPackageDetails") {
    ModalBodyComponent = (
      <Suspense fallback={<div></div>}>
        <TestSeriesAndEbook
          isPurchased={props.isPurchased}
          setHeader={setHeader}
          id={hashArray[1]}
          packageType={hashArray[2]}
          setShowAlreadyPurchased={setShowAlreadyPurchased}
        ></TestSeriesAndEbook> </Suspense>
    );

  }
  else if (hashArray.length > 2 && hashArray.length <= 8) {
    switch (hashArray[2]) {
      case "videoPkgs":
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <VideoListing
              isPurchased={props.isPurchased}
              setHeader={setHeader}
              videoPackageId={hashArray[3]}
              selectedSubjectIdx={hashArray[5]}
              packageType={hashArray[2]}
              setShowAlreadyPurchased={setShowAlreadyPurchased}
            />
          </Suspense>
        );
        break;
      case "testPkgs":
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <TestSeries
              isPurchased={props.isPurchased}
              setHeader={setHeader}
              testSeriesId={hashArray[3]}
              selectedGroup={hashArray[5]}
              packageId={hashArray[1]}
              setShowAlreadyPurchased={setShowAlreadyPurchased}
            />
          </Suspense>
        );
        break;
      case "ebooksPkgs":
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <Ebooks
              isPurchased={props.isPurchased}
              setHeader={setHeader} 
              eBookId={hashArray[3]} 
              selectedGroup={hashArray[5]} 
            />
          </Suspense>
        );
        break;
      case "models3DPkgs":
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <Models3D
              isPurchased={props.isPurchased}
              setHeader={setHeader} 
              modelId={hashArray[3]} 
              selectedGroup={hashArray[5]} 
            />
          </Suspense>
        );
        break;
      case "liveClasses":
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <LiveClasses
              isPurchased={props.isPurchased}
              setHeader={setHeader}
              liveClassId={hashArray[3]}
              selectedGroup={hashArray[5]}
              packageId={hashArray[1]}
              setShowAlreadyPurchased={setShowAlreadyPurchased}
            />
          </Suspense>
        );
        break;
      default:
        ModalBodyComponent = (
          <Suspense fallback={<div></div>}>
            <ErrorPage/>
          </Suspense>
        );
    }
  }
  else {
    return null
  }
  //
  return (
    <>
      <CustomModal
        footer={null}
        visible={hashUrl ? true : false}
        title={
          <div key='packages-header' className={"modal-header"}>{header}</div>
        }
        onBack={back}
        hideBackBtn={hashArray.length <= 3 && hashArray[0] === "#packageDetails"}
        onCancel={close}
        closeCallback={close}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ height: "630px", overflowY: "scroll", overflowX: "hidden" }}
        modalClassName={"largemodal pdp-content-modal"}
      >
        {ModalBodyComponent}
      </CustomModal>
    </>
  );
};

// const mapStateToProps = (state) => {
//   const {productDetails} = state;
//   return {
//       packageOffering:productDetails.packageOfferingData
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getPackageOffering: (productId) =>dispatch(getPackageOffering(productId)),
//       clearProductDetailData: () => dispatch(clearProductDetailData())
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(IncludedPackages);
export default IncludedPackages;

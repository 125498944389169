import React from "react";
import App from "../app/containers/App";
import {
  Home,
  Post,
  CourseCategoryListing,
  ExamCategoryListing,
  ProductDetailListing,
  CourseDetailListing,
  ExamDetailListing,
  ProductDescription,
  SearchDetailListing,
  MyEbooks,
  MyAccount,
  MyVideosListing,
  LiveVideoPackage,
  VideoPackage,
  MyOrder,
  TestListing,
  TestResult,
  TestPortal,
  Support,
  MyLiveClassesListing,
  AddaGoldLanding,
  SubPackageView,
  JoinImpartus,
  MyPurchase,
  SubPackage,
  MarsPaymentPage,
  ScholarshipMain,
  MyPurchaseList,
  SelectYourExams,
  PackageDetailView,
  PackageYourExamsView,
  ExamSubPackageView,
  PPCSubPackageView,
  TestResultAnalysis,
  ExamOptionsWrapper,
  ExamAllBatches,
  NeetRankPredictor,
  QuestionPage,
  SubjectPage,
  My3DModels,
  VeekshaViewer,
  WishList,
  OnboardingHelpSection,
} from "./splitcomponent";

import NotFound from "../app/containers/NotFound";
import NotFoundStatic from "../app/components/NotFoundStatic";

const routes = [
  {
    component: App,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/adda247-gold-govt-exam-prep-subscription",
        component: AddaGoldLanding,
        exact: true,
      },
      /** Version 1.0 PPC flow routes
       *  comment out the old PPC flow,because of new PPC flow implementation.
       */
      // {
      //   path: "/my-purchase/:parentPackageId/:parentPackageName/:packageId/:packageName",
      //   component: SubPackage,
      //   exact: true,
      // },

      // {
      //   path: "/my-purchase",
      //   component: MyPurchase,
      //   exact: true,
      // },

      // {
      //   path: "/my-purchase/:id/:packageName",
      //   component: SubPackageView,
      //   exact: false,
      // },
      // -------------- End Of Version 1.0 PPC routes -----------------

      /**
       * Version 2.0 PPC flow 27 Sep 2022
       *
       */
      {
        path: "/my-purchase",
        component: MyPurchaseList,
        exact: true,
      },
      // paid search consumption url
      {
        path: "/my-purchase/:subParentId/:subParentTitle/batch",
        component: ExamSubPackageView,
        exact: true,
      },
      // ****
      {
        path: "/my-purchase/:packageId/:packageName",
        component: PackageDetailView,
        exact: true,
      },
      {
        path: "/my-purchase/:parentPackageId/:parentPackageName/:packageId/:packageName",
        component: PPCSubPackageView,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/exams",
        component: PackageYourExamsView,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/exams/:examId/:examName",
        component: ExamSubPackageView,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/exams/:examId/:examName/:subParentId/:subParentTitle/batch",
        component: ExamSubPackageView,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/exams/:examId/:examName/options",
        component: ExamOptionsWrapper,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/exams/:examId/:examName/allBatches",
        component: ExamAllBatches,
        exact: true,
      },
      {
        path: "/my-purchase/:parentPackageId/:parentPackageName/exams/:examId/:examName/:packageId/:packageName",
        component: PPCSubPackageView,
        exact: true,
      },
      {
        path: "/my-purchase/:packageId/:packageName/selectExams",
        component: SelectYourExams,
        exact: true,
      },

      // ----------------- End of Version 2.0 PPC flow routes ----------------
      // my wishlist
      {
        path: "/wishlist", // private route
        component: WishList,
        exact: true,
      },
      // shared wishlist route
      {
        path: "/wl/:sharedUserId", // private route
        component: WishList,
        exact: true,
      },
      {
        path: "/sub-package",
        component: SubPackage,
        exact: true,
      },
      {
        path: "/support",
        component: Support,
        exact: true,
      },
      {
        path: "/gold/paytm/callback",
        component: MarsPaymentPage,
        exact: true,
      },

      {
        path: "/impartus",
        component: JoinImpartus,
        exact: true,
      },
      {
        path: "/video-package/:id/:packageName",
        component: VideoPackage,
        exact: false,
      },
      {
        path: "/my-videos",
        component: MyVideosListing,
        exact: true,
      },
      {
        path: "/my-live-class",
        component: MyLiveClassesListing,
        exact: true,
      },
      {
        path: "/my-live-class/:id/:child",
        component: LiveVideoPackage,
        exact: false,
      },
      {
        path: "/my-live-class/:id",
        component: LiveVideoPackage,
        exact: false,
      },
      {
        path: "/my-ebooks",
        component: MyEbooks,
        exact: true,
      },
      {
        path: "/my-ebooks/:id",
        component: MyEbooks,
        exact: false,
      },
      {
        path: "/my-3d-models",
        component: My3DModels,
        exact: true,
      },
      {
        path: "/my-3d-models/:id",
        component: My3DModels,
        exact: false,
      },
      // **** previous TestResult Analysis v1.0
      // {
      //   path: "/my-test-analysis/:title/:packageId/:mappingId",
      //   component: TestResult,
      //   exact: true,
      // },

      /**
       * @author Amit Mathur
       * @created Feb 15,2023
       * TestResultAnalysis v2.0
       */
      {
        path: "/my-test-analysis/:title/:packageId/:mappingId",
        component: TestResultAnalysis,
        exact: true,
      },
      {
        path: "/test-series-portal/:mappingId/:packageId/:status/:title",
        component: TestPortal,
        exact: true,
      },
      {
        path: "/my-mock-test",
        component: MyVideosListing,
        exact: true,
      },
      {
        path: "/my-mock-test/:id",
        component: TestListing,
        exact: false,
      },
      {
        path: "/my-profile",
        component: MyAccount,
        exact: false,
      },
      {
        path: "/my-orders",
        component: MyOrder,
        exact: true,
      },
      {
        path: "/neet-rank-predictor",
        component: NeetRankPredictor,
        exact: true,
      },
      {
        path: "/3d-learning",
        component: VeekshaViewer,
        exact: true,
      },
      {
        path: "/:subject/question/",
        component: SubjectPage,
        exact: true,
      },
      {
        path: "/:subject/question/:questionTitle",
        component: QuestionPage,
        exact: true,
      },
      // {
      //   path: "/my-cart/checkout",
      //   component: CartCheckout,
      //   exact: false,
      // },
      {
        path: "/onboarding-help",
        component: OnboardingHelpSection,
        exact: true,
      },
      {
        path: "/fpm/:scholarshiptType",
        component: ScholarshipMain,
        exact: true,
      },
      {
        path: "/search",
        component: SearchDetailListing,
        exact: false,
      },
      {
        path: `/:courseType-study-materials`,
        component: CourseCategoryListing,
        exact: false,
      },
      {
        path: "/:examName-exam-kit",
        component: ExamCategoryListing,
        exact: false,
      },
      {
        path: "/:courseType/:productType-study-kit",
        component: CourseDetailListing,
        exact: false,
      },
      {
        path: "/:examName/:productType-kit",
        component: ExamDetailListing,
        exact: false,
      },
      {
        path: "/product/:id/:title",
        component: ProductDescription,
        exact: false,
      },
      {
        path: "/product-:productType/:id/:title",
        component: ProductDescription,
        exact: false,
      },

      {
        path: "/comprehensive-video/:id/:title",
        component: ProductDescription,
        exact: false,
      },
      {
        path: "/post/:postType/:postId/:name?",
        component: Post,
        exact: false,
      },
      {
        path: "/:productType",
        component: ProductDetailListing,
        exact: true,
      },
      {
        path: "/not-found",
        component: NotFoundStatic,
        exact: true,
      },

      {
        component: NotFound,
      },
    ],
  },
];

export default routes;

import React, { useEffect, useState } from "react";
import Tab from "../library/Tab";
import SectionComponent from "../library/SectionComponent/SectionComponent";
import EnrolledCourseListing from "./EnrolledCourseListing";
import fetch from "../../data/utils/fetch";
import { makeUrl, getPdpUrl, getMoengageMetadata, optimizedImgUrl, getDeviceType, isADDA } from "../../data/utils/helpers";
import MyaccountNavbar from "../../containers/Myaccountnavbar";
import CustomLoader from "../library/CustomLoader";
import CustomModal from "../library/CustomModal";
import { pageName, videoPackageUrl } from "../../../constants/appConfig";
import FixedMockListing from "../fixedMock/FixedMockListing";
import { useLocation } from "react-router";
import ContineInApp from "../library/ContinueInApp/ContinueInApp";
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS } from "../../../constants/textConstants";


const EnrolledCourses = ({ courseObj, setCache, cachedData, cachedDataKey, props, setSelectedTabInCache }) => {


  const [showOpenInAppModal, setShowOpenInAppModal] = useState(false);
  const [videoPackageId, setVideoPackageId] = useState("");
  const [parentPckgId, setparentPckgId] = useState("");
  const [selectedTab, setSelectedTab] = useState(props.location?.state?.freeQuizTabId !== undefined ? props.location.state.freeQuizTabId : localStorage.getItem('freeQuizTabId') !== null ? localStorage.getItem('freeQuizTabId') :  courseObj.cachedKey == "myMockTestData" ? cachedData.selectedTab : 0);
  const [selectedTabUrl, setSelectedTabUrl] = useState("initial");
  const [courseItemList, setCourseItemList] = useState([]);
  const [courseCSItemList, setCourseCSItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [routeToAppUrl, setRouteToAppUrl] = useState('');
  const [cacheFlag, setCacheFlag] = useState(true);
  const [onFixedMock, setOnFixedMock] = useState((courseObj.cachedKey == "myMockTestData" && cachedData.selectedTab == 2) || selectedTab == 2 ? true: false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [noSearchTerm, setNoSearchTerm] = useState(false);
  const [appModaldata, setModalAppdata] = useState();
  const [noDataText, setNoDataText] = useState(courseObj?.subSections[selectedTab]?.noItems?.label||"You have not purchased any course yet.")
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  const location = useLocation();

    const filterList = (term) => {
      const newList = courseItemList.filter(course => {
        // regular expression (/\u00A0/g) is to find all occurrences of Unicode character 160 (\u00A0) "No-Break Space", and replace them with a regular space character (' ')
        const sanitisedTitle = course?.title.replace(/\u00A0/g, ' ');
        
        if(course.id.toString() == term || sanitisedTitle?.toLowerCase()?.includes(term?.toLowerCase())){
          return true;
        }
        return false;

      })

        if(newList.length) {
          setFilteredList(newList);
          setNoDataText(courseObj?.subSections[selectedTab]?.noItems?.label);
        }
        else {
          setFilteredList([]);
          setNoDataText(`No results found for ${term}`);
          setCacheFlag(false);
        };
        setLoading(false);
      
    }

  useEffect(() => {
    if(noSearchTerm){
      setSearchTerm('')
      setFilteredList(courseItemList)
    };
  },[noSearchTerm]);
  
  useEffect(() => {
      if(searchTerm.length) filterList(searchTerm);
      else setFilteredList(courseItemList);
      if(courseItemList.length) setLoading(false);
  }, [searchTerm])

  useEffect(() => {
    return () => {
      if(localStorage.getItem('freeQuizTabId')){
        localStorage.removeItem('freeQuizTabId');
      }
    }
  },[]);

  const goToVideoPackage = (item) => {
    if (
      typeof window !== "undefined" &&
      window.innerWidth < 420 &&
      courseObj.headerLabel !== "LIVE CLASSES"
    ) {
      setShowOpenInAppModal(true);
      setVideoPackageId(item && item.id);
      setparentPckgId(item && item.parentPckgId);
    } else {
      courseObj.headerLabel == "LIVE CLASSES"
        ? props.history.push(`/my-live-class/${item.id}`)
        : props.history.push(
          `/video-package/${item.id}/${makeUrl(item.title)}`
        );
    }
  };

  const goToOpenInApp = () => {
    // https://adda247.page.link/?link= tests/CPkg_2976&apn=com.adda247.app&ibi=com.metisEduventures.adda247
    parentPckgId &&
      videoPackageId &&
      window.open(
        `https://adda247.page.link/?link=https://www.adda247.com/video-package/PPkg_${parentPckgId}/CPkg_${videoPackageId}&apn=com.adda247.app&ibi=com.metisEduventures.adda247`
      );
    !parentPckgId &&
      videoPackageId &&
      window.open(
        `https://adda247.page.link/?link=https://www.adda247.com/video-package/CPkg_${videoPackageId}&apn=com.adda247.app&ibi=com.metisEduventures.adda247`
      );
    parentPckgId &&
      !videoPackageId &&
      window.open(
        `https://adda247.page.link/?link=https://www.adda247.com/video-package/PPkg_${parentPckgId}&apn=com.adda247.app&ibi=com.metisEduventures.adda247`
      );
  };

  const openInApp = () => {
    window.open(routeToAppUrl);
  }

  const handleTabClick = (id) => {
    if (selectedTab != id) {
      setLoading(true);
      if (courseObj.cachedKey == "myMockTestData" && id == 2) {
        setOnFixedMock(true);
      } else {
        setOnFixedMock(false);
      }
      setSelectedTab(id)
      setSelectedTabInCache(id);
      setSearchTerm("");
      setNoSearchTerm(true);
    }
  }

  useEffect(() => {
    let currentUrl = courseObj?.subSections?.[selectedTab]?.url;
    setSelectedTabUrl(currentUrl);
    let cachedFlag = false;
    let cachedDataFinal = [];
    if (courseObj?.cachedKey && cachedData && cachedData?.[courseObj?.cachedKey] && cachedData?.[courseObj?.cachedKey]?.length > 0 && courseObj?.subSections?.length == 1) {
      if (selectedTabUrl == "initial" && currentUrl != selectedTabUrl) {
        cachedDataFinal = cachedData?.[courseObj?.cachedKey];
        cachedFlag = true;
      } else {
        cachedFlag = false;
      }
    } else if (courseObj.cachedKey && cachedData && cachedData[courseObj?.subSections?.[selectedTab]?.cachedKey2] && courseObj?.subSections?.length == 3) {
      cachedDataFinal = cachedData?.[courseObj?.subSections?.[selectedTab]?.cachedKey2];
      if(cachedData?.MockTest?.length == 0 || cachedData?.FreeMock?.length == 0 || cachedData?.TestEvent?.length == 0) cachedFlag = false;
      else cachedFlag = true;
      if (onFixedMock){
        cachedFlag = false;
      }
    }
    else {
      cachedFlag = false;
    }
    if (selectedTab == 2){
      setLoading(false);
      return;
    }
    if (!cachedFlag || (courseItemList?.length === 0)) {
      setLoading(true);
      let url = courseObj?.subSections?.[selectedTab]?.url;
      setSelectedTabUrl(url);
      fetch(url, "GET").then(
        (res) => {
          if (res?.data) {
            if(courseObj.headerLabel == 'LIVE CLASSES'){
              setCourseItemList(res?.data['availableList']);
              setFilteredList(res?.data['availableList']);
              setCourseCSItemList(res?.data['comingSoonList']);
            } else {
              setCourseItemList(res?.data);
              setFilteredList(res?.data);
            }
            let obj = res?.data;
            setCache(res?.data, selectedTab);
          } else {
            setCourseItemList([]);
            setFilteredList([]);
            setCourseCSItemList([])
          }
          setLoading(false);
        },
        (res) => {
          setLoading(false);
          setCourseItemList([]);
          setFilteredList([]);
          setCourseCSItemList([]);
        }
      );
      setCacheFlag(false);
    } else {
      setLoading(false);
      setCourseItemList(cachedDataFinal);
      setFilteredList(cachedDataFinal);
      setCacheFlag(true);
    }
  }, [courseObj.subSections[selectedTab]?.url]);

  useEffect(() => {
    if (cachedData && cachedData.scroll) {
      stateChange(cachedData.scroll)
    }
  }, [])
  function stateChange(scroll) {
    setTimeout(function () {
      window.scrollTo(0, scroll);
      props.setSelectedTab(selectedTab, 0);
    }, 1000);
  }
  const onCourseClick = (data, scroll) => {
    props.setSelectedTab(selectedTab, scroll);
    // Moengage Event 
    let MoePayLoad = {};

    if (courseObj.headerLabel === "LIVE CLASSES") {
      MoePayLoad = {
        package_status: 'paid',
        url: location.href,
        name: data?.title,
        id: data?.id,
        action: 'live_class_package_clicked',
        category: 'live-class',
        'packageUrl': getPdpUrl("ONLINE_LIVE_CLASSES", data?.id, data?.title)
      }
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'live-class',
        getMoengageMetadata(
          MoePayLoad
        ),
      );
      setModalAppdata({
        packageId: data?.id,
        type: COURSE_CATEGORY_IDENTIFIERS.ONLINE_LIVE_CLASSES_IDENTIFIER
      })
    }
    if (courseObj.headerLabel === "My Video Courses") {
      MoePayLoad = {
        package_status: 'paid',
        url: location.href,
        name: data?.title,
        id: data?.id,
        action: 'videoPackage_clicked',
        category: 'videos',
        'packageUrl': getPdpUrl("VIDEOS", data?.id, data?.title)
      }
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'video',
        getMoengageMetadata(
          MoePayLoad
        ),
      );
      setModalAppdata({
        packageId: data?.id,
        type: COURSE_CATEGORY_IDENTIFIERS.VIDEOS_IDENTIFIER
      })
    }
    if (courseObj.headerLabel === "MOCK TESTS") {
      MoePayLoad = {
        'package_status': 'paid',
        url: location.href,
        name: data.title,
        id: data.id,
        subPackageId: data.id,
        subPackageTitle: data.title,
        subPackageType: 'TEST_SERIES',
        action: 'testPackage_clicked',
        category: 'TEST_SERIES',
        'packageUrl': getPdpUrl("TEST_SERIES", data.id, data.title)

      }
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'test',
        getMoengageMetadata(
          MoePayLoad
        ),
      );
      setModalAppdata({
        packageId: data?.id,
        type: COURSE_CATEGORY_IDENTIFIERS.TEST_SERIES_IDENTIFIER
      })
    }

    let currentCourseSection = courseObj.subSections[selectedTab];
    // if((getDeviceType() === 'm' || getDeviceType() === 's') && currentCourseSection.routeToAppUrl && (courseObj.headerLabel === "My Video Courses" || courseObj.headerLabel === 'MOCK TESTS' )&& iOS){
    // courseSectionObj.onClick(data);
    // }
    let appRedirectUrl = ''
    if(currentCourseSection?.routeToAppUrl){
      let routeToUrl = currentCourseSection.routeToAppUrl;
      appRedirectUrl = routeToUrl(data);
    }
    if (
     (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) &&
      appRedirectUrl && isADDA
    ) {
      let courseRouteToAppUrl = currentCourseSection.routeToAppUrl(data);
      setRouteToAppUrl(courseRouteToAppUrl)
    } else {
      if(getDeviceType() === DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER || !isADDA) courseSectionObj.onClick(data);
    }
  };
  const close = () => {
    setRouteToAppUrl('');
  }
  let bodyComponent = [];

  let courseSectionObj = courseObj.subSections[selectedTab];

  bodyComponent.push(
    <div className="course-listing-body">
      {courseObj.subSections.length > 1 && (
        <div className="course-listing-nav">
          {courseObj.subSections.map((section, index) => (
            <div
              onClick={() => {handleTabClick(section.id)}}
              className={`cl-nav-item ${selectedTab == section.id ? "active" : " "
                }`}
            >
              {section.label}
            </div>
          ))}
        </div>
      )}
      {
        <div className="course-listing">
          {loading ? (
            <CustomLoader />
          ) : (
            !onFixedMock ?
            filteredList && <EnrolledCourseListing
                enrolledCourseList={filteredList}
                comingSoonCourseList={courseCSItemList}
                onCourseClick={onCourseClick}
                actionButtonText={courseSectionObj?.actionButtonText}
                courseObj={courseObj}
                courseSectionObj={courseSectionObj}
                cacheFlag={cacheFlag}
                noDataText={noDataText}
                paginatedFlag={courseObj?.paginatedFlag}
                liveTest={courseObj?.subSections?.[selectedTab]?.liveTest}
              /> :
              <FixedMockListing 
              searchTerm={searchTerm} 
              noSearchTerm={noSearchTerm}
              courseSectionObj={courseSectionObj}
              cacheFlag={cacheFlag}
              noDataText={noDataText}
              />
          )}
          
        </div>
      }
        {appModaldata && <ContineInApp 
          show={!!appModaldata}
          type={appModaldata && appModaldata?.type}
          packageId={appModaldata && appModaldata?.packageId}
          parentpackageId={""}
          close={() => setModalAppdata("")}
        />}
    </div>
  );

  // bodyComponent=<NoCourse></NoCourse>
  return (
    <div
      style={{
        position: "relative",
        top: "80px",
        display: "flex",
        flexDirection: "row",
      }}
      className="enrolledCourses"
    >
      <div className="myprofile-container leftslideenroll">
        <MyaccountNavbar></MyaccountNavbar>
      </div>
      <div className="course-listing-container">
        <SectionComponent
          header={courseObj.headerLabel}
          body={bodyComponent}
          fromVideo={true}
          setSearchTerm = {setSearchTerm}
          setNoSearchTerm = {setNoSearchTerm}
          selectedTab = {selectedTab}
        ></SectionComponent>
      </div>
    </div>
  );
};
export default EnrolledCourses;

import React from "react";
import {
  createOptimizedImgUrl,
  getDiscount,
  numberWithCommas,
} from "../../data/utils/helpers";
import { FallBackImage } from "../../../constants/appConfig";

const PDPOverlayCard = ({
  dualValidity,
  maxPrice,
  price,
  demoUrl,
  originalPrice,
  pdpUrlTemplate,
  title,
  isPurchased,
  setPlayVideo,
  renderBuyNowComponent,
  renderViewOffers,
  seoDataHeading,
  subCategoriesData,
}) => {
  let mockTestCount = 0;

  subCategoriesData.forEach((subCategory) => {
    if (subCategory.id === 1) {
      mockTestCount = subCategory.count;
    }
  });
  return (
    <>
      <section className="topFixCardWrap" id="PDPOverlayCard">
        <div className="pdp-container">
          <div className="topFixCard">
            <div
              className="pdpCardImg"
              onClick={demoUrl ? () => setPlayVideo(true) : null}
            >
              {dualValidity ? (
                <div className="pdpCardTag">{"Double Validity"}</div>
              ) : null}
              <img
                src={
                  pdpUrlTemplate
                    ? createOptimizedImgUrl(pdpUrlTemplate)
                    : FallBackImage
                }
                alt="pdpCourseImg"
              />
              <img
                className="bckgroundimgforblug"
                src={
                  pdpUrlTemplate
                    ? createOptimizedImgUrl(pdpUrlTemplate)
                    : FallBackImage
                }
                alt="pdpCourseBackgoundImg"
              />
              <div className="bgcolordiv"></div>
              {demoUrl && (
                <div className="videoDemo">
                  <img src="/images/videocall.svg" alt="demoVideoIcon" />
                </div>
              )}
            </div>

            <div className="topFixcardDetail">
              <div className="toptextheading">{seoDataHeading || title}</div>
              <div className="topfixCardpriceWrap">
                <div className="pdpPriceWrap">
                  <div className="pdpPrice">
                    {!isPurchased ? (
                      <div className="pdppriceleft">
                        <div className="pdpOrignalPrice">
                          ₹ {numberWithCommas(price)}{" "}
                        </div>
                        <div className="pdpOffPrice">
                          {maxPrice != price && (
                            <strike>
                              &#8377;
                              {numberWithCommas(maxPrice)}
                            </strike>
                          )}
                        </div>
                        <div className="pdpOffPercent">
                          {maxPrice != price
                            ? `(${getDiscount(maxPrice, price)}% Off)`
                            : null}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="pdpOrignalPriceAlreadySold">
                          ₹ {numberWithCommas(originalPrice)}
                        </div>
                        {isPurchased && (
                          <div className="purchaseActionItem alreadyPurchasedCourse">
                            Already Purchased
                          </div>
                        )}
                      </>
                    )}
                    {/* currently no functionality for share */}
                    {/* <div className="pdpshare">
                    <a href="">
                      <img src="/images/pdpshareicon.svg" />
                    </a>
                  </div> */}
                  </div>
                </div>
                <div className="pdpBottomBtns">
                  <div className="pdpbtn pdpOfferbtn">{renderViewOffers}</div>
                  <div className="pdpbtn pdpBuyNowbtn">
                    {renderBuyNowComponent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PDPOverlayCard;

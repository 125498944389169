import types from './types';
import {pdpUrls, searchUrlEndPoint} from '../../../../constants/appConfig';
import { checkLoginStatus, isADDA } from '../../utils/helpers';

const getProductDetail = (productId) => {
    return {
        CALL_API: [
            {
                type:types.GET_PACKAGE_DETAILS,
                meta: {
                    path: `${pdpUrls.productDetails}?packageId=${productId}&sc=2${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                }
            }
        ]
    };
};

const getPackageMahapack  = (productId) =>{
    return {
        CALL_API: [
            {
                type:types.PACKAGE_MAHAPACK,
                meta: { 
                    path: `${pdpUrls.mahapack}${productId}`,
                    method: 'GET',
                }
            }
        ]
    };

}


const getPackageOffering  = (productId) =>{
    return {
        CALL_API: [
            {
                type:types.GET_PACKAGE_OFFERING,
                meta: { 
                    path: `${pdpUrls.packageOffering}/${productId}?lgi=ENGLISH`,
                    method: 'GET',
                }
            }
        ]
    };

}

const updateUserAddress = (addressObj,isLoggedIn,packageId) => {
    const isLogined = checkLoginStatus();
    const  url = isLogined ? pdpUrls.updateAddress : pdpUrls.updateAddressGuest
    let API_CALLS = [ {
        type: types.UPDATE_USER_ADDRESS,
        meta: {
            path: `${url}?user-email=${addressObj.email || ""}`,
            method: 'POST',
            body: addressObj
        }
    }]
    // if(isLoggedIn){
    //     API_CALLS.push({
    //         type: types.CHECK_PURCHASED_PACKAGE,
    //         meta: {
    //             path: `${pdpUrls.purchasedPackage}?pkgId=${packageId}`,
    //             method: 'GET',
    //             body: addressObj
    //         }
    //     }

    //     )
    // }
    return {
        CALL_API: API_CALLS
    };
};


const clearProductDetailsData = () => {
    return {
        type: types.CLEAR_PACKAGE_DETAILS_DATA,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
};
export {
    getProductDetail,
    clearProductDetailsData,
    getPackageOffering,
    updateUserAddress,
    getPackageMahapack
};
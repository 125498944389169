import React from "react";
const HighLights = ({ hlsJson }) => {
  if (
    !hlsJson ||
    hlsJson?.length == 0 ||
    !hlsJson[0] ||
    (hlsJson?.length == 1 && !hlsJson[0]?.hlsJson)
  )
    return null;
  return (
    <div className="pdpHighlight">
      <div className="pdpCommanHeading" data-testId='productHighlightHeading'>
        Product <span>Highlights</span>
      </div>
      <div className="pdpHighlightFullBox">
        <ul>
          {hlsJson?.map((hlsItem, index) => {
            if (hlsItem?.hlsJson == "") return null;
            return <li key={index}>{hlsItem?.hlsJson}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default HighLights;

import reducerRegistry from '../../reducerRegistry';
import types from '../EnrolledCoursesDataObj/types';

function enrolledCoursesDataObj(
    state = { selectedTab: 0, scroll: 0 },
    action
) {
    switch (action.type) {
        case types.SET_LIVE_CLASS_DATA_NEW: {
            const myLiveClass = action.payload;
            return {
                ...state,
                myLiveClass
            };
        }
        case types.SET_MY_VIDEOS_DATA: {
            const myVideoData = action.payload;
            return {
                ...state,
                myVideoData
            };
        }
        case types.SET_SELECTED_TAB: {
            const selectedTab = action.payload
            return {
                ...state,
                selectedTab: selectedTab.tab,
                scroll: selectedTab.scroll
            };
        }
        case types.SET_MY_MOCK_TEST_DATA: {
            const data = action.payload.data;
            const selectedTab = action.payload.selectedTab;
            let dataKey = "initial";
            if (selectedTab == 0) {
                dataKey = "MockTest";
                return {
                    ...state,
                    "MockTest": data
                };
            } else if (selectedTab == 1) {
                dataKey = "FreeMock";
                return {
                    ...state,
                    "FreeMock": data
                };
            } else if (selectedTab == 2){
                dataKey = "TestEvent";
                return {
                    ...state,
                    "TestEvent": data
                }
            }else {
                dataKey = "other";
                return {
                    ...state,
                    "other": data
                };
            }
        }
        case types.RESET_CACHE_ENROLLED_COURSES: {
            return {
                ...state,
                "selectedTab": 0,
                "scroll": 0,
                "myVideoData": [],
                "myLiveClass": [],
                "MockTest": [],
                "FreeMock": []

            }
        }
        default:
            return state;
    }
}

reducerRegistry.register('enrolledCoursesDataObj', enrolledCoursesDataObj);
export default enrolledCoursesDataObj;

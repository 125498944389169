import React, { useEffect } from "react";
import { getMoengageMetadata, getDeviceType } from "../data/utils/helpers";
import { pageName } from "../../constants/appConfig";
import { Cookies } from "react-cookie";
import CustomButton from "./library/CustomButton";
import { default as Link } from "../containers/ConnectedLink";

const cookie = new Cookies();
const PaymentFailure = ({text,Getpayload,cartData,cartDataInfo,checkoutData}) => {

  useEffect(() => {
    
    let payload={};
    
    // fetch( url, method, body )
   if(Getpayload)
   {
     payload = Getpayload;
     payload.platform= getDeviceType() == 'm' ? 'Mobile' : 'WEB';
     let value=localStorage.getItem('amount');
     let amount= value && value.substring(0,value.length-2)
     payload.package_final_price=amount  || 0;
     payload.source_screen='OrderConfirmActivity';
     payload.failure_reason='FAILED';
   }
   else if (cartDataInfo){
     payload = { source: "web", brand: pageName[1] };
    
    var p=[];
for(var i=0;i<cartData.length;i++)
{
  var c={};
  c.package_quantity=cartData[i].quantity  || "";
  c.package_actual_price=cartData[i].maximumRetailPrice  || "";
  c.package_name=cartData[i].title || "";
  c.package_type=cartData[i].categories || ''
  c.package_id=cartData[i].id || "";
  c.failure_reason='Failed';
  c.package_final_price=cartData[i].sellingPrice || "";
  c.language='';
  c.platform=  getDeviceType() == 'm' ? 'Mobile' : 'WEB';
  c.package_title= cartData[i].title || "";
  c.source_screen='OrderConfirmActivity';
  c.package_status='Paid',
  c.packageDiscountedPrice= cartData[i].maximumRetailPrice-cartData[i].sellingPrice ;
  p.push(c);
  payload.orderFailureData=p;


}
   }
   if( !cookie.get('cp_token'))
    {
    let name  =localStorage.getItem('name');
    let email=localStorage.getItem('email');
    payload.user_name=name;
    payload.user_email=email;
    }
    let phone =localStorage.getItem('phone');
    payload.user_mobile_no=phone;
    if(payload){
payload.page_title="Payment Failure Page"
payload.total_final_amount="";
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'purchase_frontend_failed',
      getMoengageMetadata(
        payload
      ),
    );
  }
  }, []);

  const OrderButtonhandler = () => {
    // if cartJson not present in case of paytm,redirect to viewOffers hash route
    if(!checkoutData?.cartJson || checkoutData?.cartJson?.includes('undefined')){
      window.location.hash = "#checkout/viewOffers"
    }else{
      window.location.hash = "#checkout/payment"
    }
  }

  return (
<div className="buynow-body-content paymentstatuswrap">
    <div className="payment-status-section">
      <div className="payment-failure-text">
      <div className="payment-status-info">
        <div className="payment-status-icon">
          <img src="/images/payment-fail-icon.svg" alt="fail" title='fail'></img>
        </div>
        <div className="payment-status-text">
        {text ||'Your payment could not be completed. Don’t worry! Any deducted amount will be refunded back within 5-7 business days.'}
        <div className="myorders-button" onClick={()=>OrderButtonhandler()}>
              <Link>
                <CustomButton
                  buttontext="Try Again"
                ></CustomButton>
              </Link >
            </div>
        </div>
      </div>
        
      </div>
    </div>
    <div className="bottom-invis-bar"></div>
    </div>
    
  );
};

export default PaymentFailure;

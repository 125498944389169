import types from "./types";
const setLiveClassData_NEW = (data) => {
    return {
        type: types.SET_LIVE_CLASS_DATA_NEW,
        payload: data
    }
}
const setMyVideos = (data) => {
    return {
        type: types.SET_MY_VIDEOS_DATA,
        payload: data
    }
}
const setMyMocktest = (data) => {
    return {
        type: types.SET_MY_MOCK_TEST_DATA,
        payload: data
    }
}
const setSelectedTab = (tab) => {
    return {
        type: types.SET_SELECTED_TAB,
        payload: tab
    }
}
const resetCacheEnrolledCourses = () => {
    return {
        type: types.RESET_CACHE_ENROLLED_COURSES,
    }
}
export {
    setLiveClassData_NEW,
    setMyVideos,
    setMyMocktest,
    setSelectedTab,
    resetCacheEnrolledCourses
};
import React from "react";
import { Link } from "react-router-dom";
import { createOptimizedImgUrl } from "../../../data/utils/helpers";
import CatalogCard from "../../CatalogCard";
import { overlayVariant } from "../../../../constants/appConstants";
const RenewPackage = ({ packageTitle, parentInfo, productUrlKey }) => {
  return (
    <div className="renew-package-container">
      <p>
        {packageTitle || ""}
        <div>is Available with the below courses</div>
      </p>
      {parentInfo &&
        parentInfo.length > 0 &&
        parentInfo.map((value) => {
          return (
            <CatalogCard
            variant={overlayVariant.overlayBg}
              src={value && value.imageUrl}
              header={(value && value.title) || ""}
              subHeader={
                <div className="paid-itemCount">
                  {value.sp && (
                    <span className="renew-package-sp">&#8377; {value.sp}</span>
                  )}
                  {value.mp && (
                    <span className="renew-package-mp">&#8377; {value.mp}</span>
                  )}
                </div>
              }
              text={<div className="rpc-text">DETAILS</div>}
              onSelect={()=>{
                location.pathname=`/${productUrlKey}/${value && value.id}/${ value && value.title}`
              }}
              onImgClick={()=>{
                location.pathname=`/${productUrlKey}/${value && value.id}/${ value && value.title}`
              }}
            ></CatalogCard>
          );
          return (
            <Link
              to={`/${productUrlKey}/${value && value.id}/${
                value && value.title
              }`}
            >
              <div className="catalog-card ">
                <div className="catalog-card-media">
                  <div className="catalog-card-overlay"></div>
                  <div>
                    <img
                      src={createOptimizedImgUrl(value && value.imageUrl, 90)}
                      height="110px"
                    />
                  </div>
                </div>
                <div className="catalog-card-content ">
                  <div className="package-title">
                    {(value && value.title) || ""}
                  </div>
                  <div className="catalog-card-subheader">
                    <div
                      className="package-subheader"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div className="paid-itemCount">
                        {value.sp && (
                          <span className="renew-package-sp">
                            &#8377; {value.sp}
                          </span>
                        )}
                        {value.mp && (
                          <span className="renew-package-mp">
                            &#8377; {value.mp}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="catalog-card-text">Details</div>
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default RenewPackage;

import React, { useState, useEffect, useRef } from "react";
import CourseCard from "./CourseCard";
import AButton from "../library/AButton";
import CustomLoader from "../library/CustomLoader";
import NoResultsFound from '../library/NoResultsFound';
import { openEbook, timeStampdateMonthYearebooks } from "../utils";
const EbookSection = ({ data, getData, searchText, packageId ,setinternalTab,setSearchText,setInputText,setsuggestionData,loading,setGroupFilter,moengageEventSectionTabChange,moengageEventforstatus}) => {
  const focus = useRef();
  const eBookOpened = useRef();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [ddFilter, setDdFilter] = useState("");
  const [ddOptions, setDdOptions] = useState();
  useEffect(() => {
    if (!data) {
      getData();
    }
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === 'visible' && eBookOpened.current) {
        getData();
      }
    });

  }, []);
  useEffect(() => {
    if (data && data.length) {
      let dataObj = {};
      data.forEach((item) => {
        if (!dataObj[item.groupName]) {
          dataObj[item.groupName] = {
            id: item.groupName,
            label: item.groupName,
          };
        }
      });
      setDdOptions([{ id: "", label: "All" }, ...Object.values(dataObj)]);
    }
  }, [data && data.length]);
  if (!data) return <CustomLoader />;

  const Filters = [
    { id: "", label: "All Ebooks" },
    { id: "Incomplete", label: "Incomplete" },
    { id: "Unread", label: "Unread" },
    // { id: "Complete", label: "Read" },
  ];
  let displayData = data.filter((item) => {
    return (
      !selectedFilter ||
      item.status == selectedFilter ||
      (selectedFilter == "Unread" && item.status == "Coming Soon")
    );
  });

  if (ddFilter) {
    displayData = displayData.filter((item) => {
      return ddFilter == item.groupName;
    });
  }
  if (searchText) {
    displayData = displayData.filter((item) => {
      return item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    })
  }
  return (
    <div className="course-content">
      <div className="course-content-filters">
        <div>
          {Filters.map((filter) => {
            return (
              <AButton

                text={filter.label}
                onClick={() => {
                  moengageEventSectionTabChange(filter.id)
                  setSelectedFilter(filter.id);
                  setinternalTab(filter.id);
                  setSearchText("")
                  setInputText("")
                  setsuggestionData("")
                }}
                contained
                color={filter.id === selectedFilter ? undefined : "grey"}
              />
            );
          })}
        </div>
        <div className='cc-filtet-select'>
          <div>
            {ddOptions && (
              <select
                // id="test-status"
                className="test-filter-select"
                value={ddFilter}
                onChange={(e) => { setDdFilter(e.target.value) 
                  setGroupFilter(e.target.value)}}
              >
                {ddOptions.map((values) => {

                  return <option value={values.id}>{values.label}</option>;
                })}
              </select>
            )}
          </div>
        </div>
      </div>
      {displayData.length ? <div className="course-items">
        {displayData.map((ebook) => {
          let actionText = "RESUME";
          if (ebook.status == "Unread") {
            actionText = "START";
          } else if (ebook.status == "Coming Soon") {
            actionText = "COMING SOON";
          } else if (ebook.status == "Complete") {
            actionText = "COMPLETE";
          }

          return (
            <CourseCard
              isBook={true}
              showIcon={true}
              info={ebook.comingSoon && ebook.comingSoonDate ? timeStampdateMonthYearebooks(ebook.comingSoonDate) : ""}
              title={ebook.title}
              imgSrc={ebook.thumbnail}
              actionButton={<AButton text={actionText}    loading={loading}
              onClick={actionText == "COMING SOON" ? null : () => {
                eBookOpened.current = true;
                moengageEventforstatus(actionText)
                openEbook(packageId, ebook.id)
              }} ></AButton>}
              
            />
          );
        })}
      </div> :
        <div className="course-items">
          <NoResultsFound />
        </div>}
    </div>
  );
};

export default EbookSection;

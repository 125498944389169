import React, { useMemo } from "react";
import SectionTabs from "../../../components/enrolledCourses/TestSeries/TestResultAnalysis/Components/SectionTabs";
import { facetTypeKeyName } from "../constants";
import FacetsItemRenderer from "./FacetsItemRenderer";
const WebFilter = ({
  activeTab,
  setActiveTab,
  tabFacetsMap = {},
  facetItems = [],
  selectedFiltersFacets = {},
  onChangeFacetHandlerCb = () => null,
  closeFilter = () => null,
}) => {
  const isTabChangeDisable = useMemo(
    () => !!Object.values(selectedFiltersFacets)?.filter((v) => v)?.length,
    [selectedFiltersFacets]
  );
  return (
    <>
      <SectionTabs
        tabs={Object.keys(tabFacetsMap)}
        activeTab={activeTab}
        onClick={(tab) => {
          if (!isTabChangeDisable) setActiveTab(tab);
        }}
        inActiveTabClassName={isTabChangeDisable ? "inactiveTabMenu" : ""}
      >
        <div className="ativetabContent">
          <ul>
            <FacetsItemRenderer
              facetItems={facetItems}
              facetType={facetTypeKeyName[tabFacetsMap[activeTab]]}
              selectedFiltersFacets={selectedFiltersFacets}
              onChangeFacetHandlerCb={onChangeFacetHandlerCb}
            />
          </ul>
        </div>
      </SectionTabs>
      {/* ghost div behind filter for closing popup */}
      <div
        className="webFilterBgOverlay"
        onClick={(event) => {
          closeFilter();
        }}
      />
    </>
  );
};

export default WebFilter;

import React, { useCallback, useState, useEffect } from "react";
import {
  checkLoginStatus,
  getDeviceType,
  getMoengageMetadata,
} from "../../data/utils/helpers";
import TestimonialCarouselNew from "../library/TestimonialCarouselNew";
import { pageName } from "../../../constants/appConfig";
import { useHistory } from "react-router";
import { DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
import { getExamListQueryParam } from "../enrolledCourses/TestSeries/TakeTest/util";

export const FreeContent = ({
  productData,
  ProductDataId,
  freeContentData,
  utmParams,
  actualPackageId
}) => {

  const contentObject = freeContentData;
  const [centerIndex, setCenterIndex] = useState(1);
  const history = useHistory();
  const [urlAfterLogin, setUrlAfterLogin] = useState('');
  const {MOBILE_IDENTIFIER, TAB_IDENTIFIER} = DEVICE_IDENTIFIERS;

  let loginFlag = typeof document != "undefined" && checkLoginStatus();
  
  useEffect(() => {
    if(loginFlag && urlAfterLogin !== '') {
      if(urlAfterLogin?.includes('test-series-portal'))
      setTimeout(() => {
        window.open(
          urlAfterLogin,
          "_blank",
          "height=" +
            screen.height +
            ",width=" +
            screen.width +
            ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
        );
      }, 1000) //timout added to give time to login modal to close.
      else {
        setTimeout(() => {
          if (
            confirm(
              `I understand that the E-book is Proprietary & Copyrighted Material of ${pageName[1]}. Any reproduction in any form, physical or electronic mode on public forum etc will lead to infringement of Copyright of ${pageName[1]} and will attract penal actions including FIR and claim of damages under Indian Copyright Act 1957.`
            )
          ) {
            typeof window !== "undefined" &&
              window.open(urlAfterLogin);
          }
        }, 1000) //timout added to give time to login modal to close.
      }
      setUrlAfterLogin('')
    }

  }, [loginFlag, urlAfterLogin])

  // for login popup open
  const handleLogin = (postLoginUrl) => {
    setUrlAfterLogin(postLoginUrl)
    var eventFired = new CustomEvent("LoginModalOpen");
    document.dispatchEvent(eventFired);
  };

  const handleClick = useCallback((content) => {
    // e.preventDefault();
    const { contentId, contentTitle, subjectTag, examTag, contentType, examType = []} =
      content;
    const examParam = getExamListQueryParam(examType);
    let d1 = productData;
    let payload = {
      source: "web",
      brand: pageName[1],
      subPackageTitle: d1?.title,
      name: d1?.title,
      category: d1?.suggestionCat,
      primary_category: d1?.primaryCategory,
      id: Number(ProductDataId),
      PID: Number(actualPackageId),
      packageActualPrice: d1?.maxPrice,
      packageDiscountedPrice: d1?.price,
      exam_category: d1?.courses,
      exam:
        d1?.examTypes && d1?.examTypes[0] ? d1?.examTypes[0]?.name || "" : "",
      getUtmFromStore: utmParams || "",
      package_status: "Paid",
      language: d1?.languages && d1?.languages[0] && d1?.languages[0]?.name,
      original_package_id:
        typeof window !== "undefined" &&
        sessionStorage.getItem("original_package_id"),
      mahapack_widget_clicked:
        typeof window !== "undefined" &&
        sessionStorage.getItem("mahapack_widget_clicked"),
      productType: contentType == 1 ? "Test" : "ebooks",
      mapping_id: contentId,
      productTitle: contentTitle,
      content_exam: examTag,
      content_subject: subjectTag,
      source_screen: "PDP",
      package_url: window?.location?.href || "",
      user_exam_category_selected: "Web",
    };
    

    // Checking Login Status
    if (!loginFlag) {
      let postLoginUrl;
      if(contentType == 1) {
        postLoginUrl = `/test-series-portal/${contentId}/${actualPackageId}/new/${encodeURIComponent(contentTitle)}?freeContent=true${examParam ? `&${examParam}` : ""}`;
      } else {
        postLoginUrl = `/e-books?file=${actualPackageId}_${contentId}.doc&freeContent=true${examParam ? `&${examParam}` : ""}`;
      }
      handleLogin(postLoginUrl);
      return;
    } else {
      if (contentType == 1) {
        openTestPortal(contentId, Number(actualPackageId || 0), contentTitle, "new",examType);
      } else {
        openEbook(Number(actualPackageId || 0), contentId);
      }
      // MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event(
            "sample_content_clicked",
            getMoengageMetadata(payload)
          );
    }
  }, [productData, loginFlag]);

  const openEbook = (packageId, ebookId) => {
    if (
      confirm(
        `I understand that the E-book is Proprietary & Copyrighted Material of ${pageName[1]}. Any reproduction in any form, physical or electronic mode on public forum etc will lead to infringement of Copyright of ${pageName[1]} and will attract penal actions including FIR and claim of damages under Indian Copyright Act 1957.`
      )
    ) {
      const url = `/e-books?file=${packageId}_${ebookId}.doc&freeContent=true`;
      const deviceType = getDeviceType();
      if(deviceType === MOBILE_IDENTIFIER || deviceType === TAB_IDENTIFIER){
        window.open(url, "_self");
      }else{
        typeof window !== "undefined" && window.open(url);
      }
    }
  };
  const openTestPortal = (mappingId, packageId, title, status,examType = []) => {
    const examParam = getExamListQueryParam(examType);
    const examStr = examParam ? `&${examParam}` : '';
    let url = `/test-series-portal/${mappingId}/${packageId}/${
      status == "INCOMPLETE" ? "resume" : "new"
    }/${encodeURIComponent(title)}?freeContent=true${examStr}`;
    const deviceType = getDeviceType();
    if (status == "COMPLETED") {
      url = `/my-test-analysis/${encodeURIComponent(
        title
      )}/${packageId}/${mappingId}?ppc=true${examStr}`;
      if(deviceType === MOBILE_IDENTIFIER || deviceType === TAB_IDENTIFIER){
        url = `${url}&freeCourseContent=true${examStr}`;
        history.push(url);
      }else{
        window.open(url);
      }
    } else {
      if(deviceType === MOBILE_IDENTIFIER || deviceType === TAB_IDENTIFIER){
        url = `${url}&freeCourseContent=true${examStr}`;
        history.push(url);
      }else{
        window.open(
          url,
          "_blank",
          "height=" +
            screen.height +
            ",width=" +
            screen.width +
            ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
        );
      }
    }
  };
  return (
    <>
      <div className="pdpCommanHeading" id="freeContent">
        Explore our <span>Free Course Content</span>
      </div>
      {/* <!--Box 1--> */}

      <div className="freeContentBoxWap">
      <TestimonialCarouselNew
        show={2}
        infiniteLoop
        isPdpTestimonial = {true}
        setCenterIndex={setCenterIndex}
        centerIndex={centerIndex}
      >
      {contentObject?.map((content) => (        
          <div className="freeCourseContentBox" key={content?.contentId}>
            <div className="freeContentInfo">
              <div className="leftSideFreeContent">
                <div className="freeContentTags">
                  <div className="freeTag">Free</div>
                  {/* <div className="mustAttemptTag">Must Attempt</div> */}
                  {content.contentType == 1 ? (
                    <div className="mustAttemptTag">Must Attempt</div>
                  ) : (
                    <div className="mustAttemptTag">Must Read</div>
                  )}
                </div>
                <div className="freePdpName">{content?.contentTitle}</div>
                <div className="freeCourseContentBottom mwebonly">
              <img src="/images/pdpTranslate.svg" alt="languageIcon" />{" "}
              {content?.isMultiLingual ? "Multilingual" : "English"}
            </div>
                <div className="freeFeatures">
                  <ul>
                    {content.contentType == 1 && content?.noOfQuestions ? (
                      <li>
                        <img src="/images/pdpQsn.svg" alt="pdpQsnIcon" />
                        {content?.noOfQuestions} Questions
                      </li>
                    ) : null}

                    {content.contentType == 1 && content?.marks ? (
                      <li>
                        <img src="/images/pdpsheetsIcon.svg" alt="pdpsheetsIcon" />
                        {content?.marks} Marks
                      </li>
                    ) : null}
                    {content.contentType == 1 && content?.timeLimit ? (
                      <li>
                        <img
                          src="/images/freecoursetimer.svg"
                          alt="timerIcon"
                        />
                        {content?.timeLimit / 60} Mins
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="rightSideFreeContentBtn">
                <button data-testid = "freeContentBtn" onClick={() => handleClick(content)}>
                  {" "}
                  {content.contentType == 1 ? "Attempt now" : "Read now"}
                </button>
              </div>
            </div>
            <div className="freeCourseContentBottom">
              <img src="/images/pdpTranslate.svg" alt="languageIcon" />{" "}
              {content?.isMultiLingual ? "Multilingual" : "English"}
            </div>
          </div>
       
      ))}
      </TestimonialCarouselNew>
       </div>
    </>
  );
};

export default FreeContent;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import { setSelectedWishListPackageIds } from "../../data/ducks/wishlist/actions";
import { isArrayContainSameElmnt } from "../../data/utils/helpers";
import DeleteWishList from "./DeleteWishList";
import ShareWishList from "./ShareWishList";
import WishListFacetsFilter from "./WishListFacetsFilters/WishListFacetsFilter";

const SelectAllWishList = React.memo(({ isDisabled = true }) => {
  const { wishlistPackageDetails, selectedWishListPackageIds } = useSelector(
    (state) => state.wishlist
  );
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const wishlistedItemIds = wishlistPackageDetails?.packages?.map(
      (wl) => wl?.id
    );
    if (
      selectedWishListPackageIds?.length >= 1 &&
      isArrayContainSameElmnt(selectedWishListPackageIds, wishlistedItemIds)
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedWishListPackageIds, wishlistPackageDetails]);
  const handleCheck = (checkedFlag) => {
    setIsChecked(checkedFlag);
    let selectedPackageIds = [];
    if (checkedFlag) {
      selectedPackageIds = wishlistPackageDetails?.packages?.map(
        (item) => item?.id
      );
    } else {
      selectedPackageIds = [];
    }
    dispatch(setSelectedWishListPackageIds(selectedPackageIds));
  };
  return (
    <div className={`select-all-check ${isDisabled ? "disable" : ""}`}>
      <div
        onClick={(e) => {
          if (isDisabled) return;
          e.preventDefault();
          handleCheck(!isChecked);
        }}
      >
        {isChecked ? (
          <img src={"/images/wlcheckative.svg"} alt="check" />
        ) : (
          <img src={"/images/wlcheckNonative.svg"} alt="uncheck" />
        )}
        <span>Select All</span>
      </div>
    </div>
  );
});

const WishListHeader = ({
  searchTerm = "",
  fetchWishListDetailHandlerCb = () => null,
  resetWlStateCb = () => null,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [showMwebSearch, setShowMwebSearch] = useState(false);
  const {
    wishlistPackageData,
    selectedWishListPackageIds = [],
    wishlistPackageDetails: { count = 0, packages = [] } = {},
  } = useSelector((state) => state.wishlist);
  const { sharedUserId = 0 } = useParams();
  const history = useHistory();

  const pageTitle = useMemo(() => {
    if (!sharedUserId) return "My";
    const userData = wishlistPackageData?.userData?.[sharedUserId];
    if (!userData) return "";
    const fullName =
      (userData?.firstName ? userData.firstName + " " : "") +
      (userData?.lastName ? userData.lastName : "") +
      "'s";
    return fullName;
  }, [wishlistPackageData, sharedUserId]);
  const countLabel = useMemo(
    () => `${count} item${count > 1 ? "s" : ""}`,
    [count]
  );

  const toggleMwebSearch = () => {
    setShowMwebSearch(!showMwebSearch);
  };

  const handleKeywordSearch = (e) => {
    const inputValue = e.target.value;
    fetchWishListDetailHandlerCb({
      searchTerm: inputValue,
    });
  };
  // Add a scroll event listener to check if the user has scrolled 100px
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 140) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const selectedText = useMemo(() => {
    if (!selectedWishListPackageIds?.length) return "0 Selected";
    return selectedWishListPackageIds?.length === packages?.length
      ? `All ${selectedWishListPackageIds?.length || 0} items on this page
are selected`
      : `${selectedWishListPackageIds?.length || 0} item${
          selectedWishListPackageIds?.length > 1 ? "s" : ""
        } Selected`;
  }, [selectedWishListPackageIds, count]);
  return (
    <div className="header-wrap">
      {/* Web header */}
      <div className="wl-headerwrapWeb">
        {/* page title header */}
        <div className="wl-title-wrap">
          <div className="wl-title">
            {pageTitle} Wishlist
            <span className="wl-count"> ({countLabel})</span>
          </div>
          <button className="back-menu" onClick={() => history.replace("/")}>
            Back to Menu
          </button>
        </div>
        {/* filters */}
        <div className={`${isSticky ? "sticky-filter" : ""}`}>
          <div className="filters-wrap">
            {/* hide selectall for sharedUser wl */}
            {!sharedUserId && (
              <div className="left-filters">
                <SelectAllWishList isDisabled={packages?.length < 1} />
                <div className="selected">
                  <span>{selectedText}</span>
                  <ErrorBoundary>
                    <DeleteWishList resetWlStateCb={resetWlStateCb} />
                  </ErrorBoundary>
                </div>
              </div>
            )}
            <div className="right-filters">
              <input
                placeholder="Search in Wishlisted Products"
                type="text"
                value={searchTerm}
                onChange={handleKeywordSearch}
              />
              <ErrorBoundary>
                <WishListFacetsFilter
                  fetchWishListDetailHandlerCb={fetchWishListDetailHandlerCb}
                />
              </ErrorBoundary>
              {/* hide ShareWishList for sharedUser wl */}
              {!sharedUserId && (
                <ErrorBoundary>
                  <ShareWishList />
                </ErrorBoundary>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* mWeb header */}
      <div className="wl-headerwrapMweb">
        <div className="wlMobileHeaderTop">
          {showMwebSearch ? (
            <div className="mweb-search">
              <button className="close-search" onClick={toggleMwebSearch} />
              <input
                placeholder="Search in Wishlisted Products"
                type="text"
                value={searchTerm}
                onChange={handleKeywordSearch}
                autoFocus
              />
            </div>
          ) : (
            <>
              <div className="leftItemswlmobile">
                <button
                  className="back-menu"
                  onClick={() => history.replace("/")}
                />
                <div className="wl-title">
                  {pageTitle} Wishlist
                  <span className="wl-count"> ({countLabel})</span>
                </div>
              </div>

              <div className="rightItemswlmobile">
                {/* hide ShareWishList for sharedUser wl */}
                {!sharedUserId && (
                  <ErrorBoundary>
                    <ShareWishList />
                  </ErrorBoundary>
                )}

                <div className="searchwlmobile" onClick={toggleMwebSearch}>
                  <div className="searchiconmobile"></div>
                </div>
                <ErrorBoundary>
                  <WishListFacetsFilter
                    fetchWishListDetailHandlerCb={fetchWishListDetailHandlerCb}
                  />
                </ErrorBoundary>
              </div>
            </>
          )}
        </div>
        <div className="wlMobileHeaderBottom">
          {/* hide selectall for sharedUser wl */}
          {!sharedUserId && (
            <>
              <SelectAllWishList isDisabled={packages?.length < 1} />
              <div className="selected">
                <span>({selectedText})</span>
              </div>
              <ErrorBoundary>
                <DeleteWishList resetWlStateCb={resetWlStateCb} />
              </ErrorBoundary>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(WishListHeader);

import { initialFacetsKeys } from "../../../containers/WishList/constants";
import reducerRegistry from "../../reducerRegistry";
import types from "./types";

let initialState = {
  wishlistPackageData: {
    lastEvaluatedKey: null,
    likeCount: 8,
    postData: [],
    userData: {},
  },
  wishlistPackageDetails: {
    count: 0,
    packages: [],
    primaryFacets: {
      categories: {},
      courses: {},
    },
  },
  selectedfacets: "",
  selectedFiltersFacets: initialFacetsKeys,
  selectedWishListPackageIds: [],
  isFilterVisible: false,
  isUserFirstWhish: false, //flag represent that user whishlisted the item very first time
  confirmWishListDelete: { visible: false, source_screen: "" },
  lastClickedWishItemInfo: {
    likeType: 0,
    packageId: 0,
  },
};
function wishlist(state = initialState, action) {
  switch (action.type) {
    case types.SET_WISHLISTED_PACKAGE:
      return {
        ...state,
        wishlistPackageData: action.meta.wishlistPackageData,
      };
    case types.FETCH_WISHLISTED_PACKAGE_DETAIL:
      let packages = action.payload.data?.packages || [];
      // spread the previous fetched packages when paginated call
      if (action.meta.isPaginated) {
        packages = [
          ...state?.wishlistPackageDetails?.packages,
          ...(action.payload.data?.packages || []),
        ];
      }
      const data = {
        ...action.payload.data,
        packages,
      };
      return {
        ...state,
        wishlistPackageDetails: data,
      };
    case types.SET_SELECTED_WISHLIST_PACKAGE_IDS:
      return {
        ...state,
        selectedWishListPackageIds: action.meta.selectedWishListPackageIds,
      };
    case types.SHOW_FILTER_POPUP:
      return {
        ...state,
        isFilterVisible: action.meta.isVisible,
      };
    case types.SET_USER_FIRST_TIME_WISH:
      return {
        ...state,
        isUserFirstWhish: action.meta.flag,
      };
    case types.SET_SELECTED_FACETS:
      return {
        ...state,
        selectedfacets: action.meta.facets,
      };
    case types.SHOW_CONFIRM_DELETE_WISHLIST:
      return {
        ...state,
        confirmWishListDelete: action.meta.data,
      };
    case types.SET_LAST_CLICKED_WISH_ITEM_INFO:
      return {
        ...state,
        lastClickedWishItemInfo: action.meta.data,
      };
    case types.SET_SELECTED_FACETS_FILTER:
      return {
        ...state,
        selectedFiltersFacets: action.meta.facets,
      };
    default:
      return {
        ...state,
      };
  }
}

reducerRegistry.register("wishlist", wishlist);
export default wishlist;

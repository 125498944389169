import React, { useEffect, useState,useRef } from "react";
import { olcTimer, testTimer } from "../utils";
 
const OlcTimer = ({ startTime,TimerCompleteComponent,ComingsoonComponent ,WatchComponent,duration }) => {
  const [time, setTime] = useState(startTime);
  const timeOutId = useRef();

  useEffect(() => {
    if (timeOutId.current) window.clearTimeout(timeOutId.current);
    setTime(startTime);
    
  }, [startTime]);
  useEffect(()=>{
      return function(){
        if(timeOutId.current)window.clearTimeout(timeOutId.current);
      }
  },[])
  useEffect(() => {
    if (time <= 0) {
      // console.log(time,'time')
        //
    } else {
      // setTimeoutId;
      timeOutId.current = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    
    }
  }, [time]);

let returncomponent;
if(time>=900)
{
  returncomponent=(ComingsoonComponent);
}
else if(time<=900 && time>0)
{
  returncomponent= (<span>Time Left: {olcTimer(time)}</span>)
}

else if(time<=0)
{
  if(Math.abs(time)>=(duration/1000))
  {
    returncomponent= (WatchComponent)
  }
  else{
    returncomponent=(TimerCompleteComponent)
  }
}
  return  (
    <div>{returncomponent}</div>
  )

};

export default OlcTimer;
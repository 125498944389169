import React from "react";
import { connect } from "react-redux";
import { socialUrls, pageName } from "../../constants/appConfig";
import footerData from "../../server/models/footerData";
import { default as Link } from "../containers/ConnectedLink";
import {
  openWidget,
  trackMoengageEvent,
  optimizedImgUrl,
  isADDA,
} from "../data/utils/helpers";
class AppFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  trackEvent(title, parent, url) {
    let payload = {};
    payload.Link_text = title;
    payload.Link_URL = url;
    payload.Link_type = parent;
    trackMoengageEvent("Footer_Link_Clicked", payload);
  }
  trackAppEvent() {
    let payload = {};
    payload.Link_text = "Download our App";
    trackMoengageEvent("App_Link_on_mobile", payload);
  }
  openSocialUrl(title, url) {
    this.trackEvent(title, "Social", url);
  }
  getUserDeviceType() {
    let deviceType = "";
    if (typeof window != "undefined") {
      if (window?.navigator.userAgent.match(/Android/i)) {
        deviceType = "android";
      } else if (window?.navigator.userAgent.match(/iPhone/i)) {
        deviceType = "iphone";
      } else {
        deviceType = "window";
      }
    }
    return deviceType;
  }

  render() {
    if (this.props.from_app) return null;
    if (this.props.goldapp) return null;
    if (
      typeof window !== "undefined" &&
      (window.location.pathname.includes("my-test-analysis") ||
        window.location.pathname.includes("test-series-portal"))
    ) {
      return null;
    }
    var d = new Date();
    var year = d.getUTCFullYear();
    return (
      <>
        <footer className="new-footer" style={{ marginTop: "0px" }}>
          {/* <!-- main black BG footer--> */}

          <div className="main-footer">
            <div className="container">
              {isADDA ? (
                <>
                  <div className="footer-wrap-updated">
                    <div className="footer-wrapper">
                      {footerData.map((e, eIndex) => {
                        return (
                          <div
                            key={e.header}
                            className={`footerlinks ${eIndex == footerData.length - 1 && "weblast"
                              }`}
                          >
                            <h4 style={{ textTransform: "uppercase" }}>
                              {e.header}
                            </h4>
                            <ul>
                              {e.children.map((f, index) => {
                                if (f.url.indexOf("http") == -1) {
                                  return (
                                    <div
                                      key={`${Math.floor(
                                        Math.random() * Date.now()
                                      )}-${index}`}
                                    >
                                      {f.title == "FAQs" ? (
                                        <li
                                          onClick={() =>
                                            this.trackEvent(
                                              f.title,
                                              e.header,
                                              f.url
                                            )
                                          }
                                        >
                                          <a
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            onClick={() => openWidget()}
                                          >
                                            {eIndex == footerData.length - 1 ||
                                              eIndex == footerData.length - 2
                                              ? f.title
                                              : f.title === "SSC" ||
                                                f.title === "UPSC" ||
                                                f.title === "NEET"
                                                ? f.title
                                                : f.title.toLowerCase()}
                                          </a>
                                        </li>
                                      ) : (
                                        <li
                                          onClick={() =>
                                            this.trackEvent(
                                              f.title,
                                              e.header,
                                              f.url
                                            )
                                          }
                                        >
                                          <Link
                                            to={f.url}
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            target="_blank"
                                          >
                                            {eIndex == footerData.length - 1 ||
                                              eIndex == footerData.length - 2
                                              ? f.title
                                              : f.title === "SSC" ||
                                                f.title === "UPSC" ||
                                                f.title === "NEET"
                                                ? f.title
                                                : f.title.toLowerCase()}
                                          </Link>
                                        </li>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={`indx__${index}`}>
                                      <li>
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          onClick={() =>
                                            this.trackEvent(
                                              f.title,
                                              e.header,
                                              f.url
                                            )
                                          }
                                          target="_blank"
                                          href={f.url}
                                        >
                                          {eIndex == footerData.length - 1 ||
                                            eIndex == footerData.length - 2
                                            ? f.title
                                            : f.title === "SSC" ||
                                              f.title === "UPSC" ||
                                              f.title === "NEET"
                                              ? f.title
                                              : f.title.toLowerCase()}
                                        </a>
                                      </li>
                                    </div>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mid-footer-section-wrap">
                      <div className="mid-footer-left">
                        <div className="adda-address-footer">
                          <div className="adda-footer-logo">
                            <a href="#">
                              <img
                                src="/images/footer-logo.svg"
                                alt={pageName[0]}
                                height="64px"
                                width="120px"
                                loading="lazy"
                              />
                            </a>
                          </div>
                          <div className="footer-mid-content">
                            <div className="company-address">
                              208, Tower A, Unitech Cyber Park
                              <br />
                              Sector 39, Gurugram - 122002
                            </div>
                            <div className="company-mail">
                              <Link
                                to="/support"
                                target="_blank"
                                aria-label="Support page for Adda247"
                              >
                                Click here for 24*7 Support
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mid-footer-right">
                        <div className="social-text-heading">Follow us on</div>
                        <div className="social-links-footer">
                          <a
                            onClick={() =>
                              this.openSocialUrl(
                                "Linkedin",
                                socialUrls["linkedIn"]
                              )
                            }
                            href="https://www.linkedin.com/company/adda-247/about/"
                            target="_blank"
                            className="social-links link-in"
                            aria-label="LinkedIn page for Adda247"
                          ></a>
                          <a
                            onClick={() =>
                              this.openSocialUrl(
                                "Twitter",
                                socialUrls["twitter"]
                              )
                            }
                            href="https://twitter.com/adda247live?lang=en"
                            target="_blank"
                            className="social-links tweet"
                            aria-label="X (twitter) page for Adda247"
                          ></a>
                          <a
                            onClick={() =>
                              this.openSocialUrl(
                                "Telegram",
                                socialUrls["telegram"]
                              )
                            }
                            href={socialUrls["telegram"]}
                            target="_blank"
                            className="social-links telegram"
                            aria-label="Telegram page for Adda247"
                          ></a>
                          <a
                            onClick={() =>
                              this.openSocialUrl("Facebook", socialUrls["fb"])
                            }
                            href="https://www.facebook.com/adda247live/"
                            target="_blank"
                            className="social-links fb"
                            aria-label="Facebook page for Adda247"
                          ></a>
                          <a
                            onClick={() =>
                              this.openSocialUrl("Youtube", socialUrls["yt"])
                            }
                            href="https://www.youtube.com/channel/UC1L2JoMpcY6MRLhFd3gg5Xg"
                            target="_blank"
                            className="social-links youtube"
                            aria-label="Youtube page for Adda247"
                          ></a>
                        </div>

                        <div className="applinks">
                          {/* <a href="https://adda247.app.link/webinstall" target="_blank" onClick={() => this.trackAppEvent()}> */}
                          {(this.getUserDeviceType() === "android" ||
                            this.getUserDeviceType() === "window") && (
                              <a
                                href="https://adda247.app.link/alXqhvGRlvb"
                                target="_blank"
                                className="left-img-app"
                                onClick={() => this.trackAppEvent()}
                              >
                                <img
                                  className="app-store-btn"
                                  src={optimizedImgUrl(
                                    `https://${pageName[0]}.com/images/google-play-store.svg`
                                  )}
                                  alt="donwload-app"
                                  loading="lazy"
                                />
                              </a>
                            )}

                          {(this.getUserDeviceType() === "iphone" ||
                            this.getUserDeviceType() === "window") && (
                              <a
                                href="https://adda247.app.link/vy6vXWsQuwb"
                                style={{ marginLeft: "10px" }}
                                className="right-img-app"
                                target="_blank"
                                onClick={() => trackAppEvent()}
                              >
                                <img
                                  className="app-store-btn"
                                  src={optimizedImgUrl(
                                    `https://${pageName[0]}.com/images/apple-app-store.svg`
                                  )}
                                  alt=""
                                />
                              </a>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-bottom-bar">
                    <div className="company-copyright">
                      © {new Date().getFullYear()} {pageName[1]}. All rights
                      reserved.
                    </div>
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <a
                            href="https://security.adda247.com/"
                            target="_blank"
                          >
                            Responsible Disclosure Program
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              this.trackEvent(
                                "Cancellation & Refunds",
                                "Footer",
                                "/refund.html"
                              )
                            }
                            href="/refund.html"
                            target="_blank"
                          >
                            Cancellation & Refunds
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              this.trackEvent(
                                "Terms & Conditions",
                                "Footer",
                                "/term-conditions.html"
                              )
                            }
                            href="/term-conditions.html"
                            target="_blank"
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() =>
                              this.trackEvent(
                                "Privacy Policy",
                                "Footer",
                                "/privacy-policy.html"
                              )
                            }
                            href="/privacy-policy.html"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="footerinnerwrap">

                    <div className="footer1 footer-contact">
                      <div className="footer-logo">
                        <img
                          src={"/images/sankalp-images/sankalp-footerlogo.svg"}
                          alt="footer-logo"
                          className="nondark"
                        />

                      </div>
                      <p>
                        Contact Us{" "}
                      </p>
                      <p className="emailid">
                        <strong>Email: </strong>{" "}
                        <a
                          href="mailto:support@sankalpbharat.com"
                          target="_blank"
                        >
                          support@sankalpbharat.com
                        </a>

                      </p>
                      <p className="company-mail">
                        For Support:{" "}
                        <Link
                          to='/support'
                          target="_blank"
                        >
                          Click Here
                        </Link>
                      </p>
                      <div className="social-links-footer social-links-footer1">
                        <a
                          onClick={() =>
                            this.openSocialUrl(
                              "Play-Store",
                              socialUrls["play_store"]
                            )
                          }
                          href={socialUrls["play_store"]}
                          target="_blank"
                          className="social-links playstore"
                        ></a>
                        <a
                          onClick={() =>
                            this.openSocialUrl("Instagram", socialUrls["ig"])
                          }
                          href={socialUrls["ig"]}
                          target="_blank"
                          className="social-links ig"
                        ></a>
                        <a
                          onClick={() =>
                            this.openSocialUrl(
                              "Telegram",
                              socialUrls["telegram"]
                            )
                          }
                          href={socialUrls["telegram"]}
                          target="_blank"
                          className="social-links telegram"
                        ></a>
                        <a
                          onClick={() =>
                            this.openSocialUrl("Youtube", socialUrls["yt"])
                          }
                          href={socialUrls["yt"]}
                          target="_blank"
                          className="social-links youtube"
                        ></a>
                      </div>
                    </div>
                    {footerData?.map((data, idx) => (
                      <div className={`footer${idx + 2} footerlinks`} key={idx}>
                        <h4>{data?.header}</h4>
                        <ul>
                          {data?.children?.map((f) => {
                            if (f.url.indexOf("http") == -1) {
                              return (
                                <li key={f?.childKey}>
                                  <Link to={f?.url} target="_blank">
                                    {f?.title}
                                  </Link>
                                </li>
                              );
                            } else {
                              return (
                                <li key={f?.childKey}>
                                  <a href={f?.url} target="_blank">
                                    {f?.title}
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </footer>
      </>
    );
  }
}

// export default AppFooter;
const mapStateToProps = (state) => ({
  from_app: state.from_app,
  goldapp: state.goldapp,
  isPaytmMini: state.isPaytmMini,
});

export default connect(mapStateToProps, null)(AppFooter);

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { myPurchase } from "../../../../constants/appConfig";
import { usePaidSearchConsumptionEvent } from "../../../components/utils";
import { searchPurchasedPackages } from "../../../data/ducks/header/actions";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isStringValueExist,
  saveUserSearchAnalyticsData,
} from "../../../data/utils/helpers";
import ProductCardHoc from "./ProductCardHoc";
import ProductInfoCard from "./ProductInfoCard";
import SearchResultsContainer from "./SearchResultsContainer";

const SearchedPackages = ({
  packages = [],
  loading = false,
  categoriesFacet = [],
  selectedCategoriesFacet = "",
  searchTerm = "",
  onCategoryChange = () => null,
  appliedQueryParams = "",
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const observer = useRef();
  const dispatch = useDispatch();
  const lastElementRef = useCallback(
    (node) => {
      if (nextLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((enteries) => {
        if (enteries[0].isIntersecting && hasMore) {
          setPageNo((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextLoading, hasMore]
  );
  const getNextPageData = async () => {
    if (pageNo !== 0) {
      setNextLoading(true);
      const res = await dispatch(
        searchPurchasedPackages(appliedQueryParams, pageNo)
      );
      setNextLoading(false);
      const result = await res[0];
      setHasMore(result?.data?.packages?.length > 0);
    }
  };

  useEffect(() => {
    getNextPageData();
  }, [pageNo]);
  useEffect(() => {
    // reset paginations state's when appliedQueryParams value change
    setHasMore(true);
    setPageNo(0);
  }, [appliedQueryParams]);
  const [redirectEvent] = usePaidSearchConsumptionEvent();
  const categoryTabsRendering = (categoriesTab = []) => {
    return (
      <div className="cat_tabs">
        {categoriesTab?.map(e => e === "Models 3d#MODELS 3D" ? "3D Learning#MODELS 3D" : e)?.map((item, index) => {
          const facetValue = getSubstringAfterChar(item, "#");
          const facetLabel = getSubstringBeforeChar(item, "#");
          const isCatSelected = isStringValueExist(
            selectedCategoriesFacet,
            facetValue
          );
          return (
            <button
              key={`${facetLabel}_Tab${index}`}
              onClick={() =>
                onCategoryChange({
                  isSelected: !isCatSelected,
                  value: facetValue,
                  facetType: "categoryFacets",
                  isSearchEnabled: true,
                })
              }
              className={isCatSelected ? "active" : ""}
            >
              {facetLabel}
            </button>
          );
        })}
      </div>
    );
  };
  return (
    <SearchResultsContainer
      loading={loading}
      heading="SEARCH RESULTS"
      headerComponent={categoryTabsRendering(categoriesFacet)}
    >
      {packages?.length ? (
        packages?.map((item, idx) => (
          <ProductCardHoc
            className="paid_product_wrapper"
            key={`package_${item?.id}`}
            ref={
              packages?.length > 10 && packages?.length === idx + 1
                ? lastElementRef
                : null
            }
          >
            <ProductInfoCard
              packageInfo={item}
              onClick={() => {
                saveUserSearchAnalyticsData({
                  url: myPurchase.userAnalyticsPaidContent,
                  data: item,
                  searchTerm,
                  searchKeyName: "searchTerm",
                  isLanguageList: false,
                });
                sessionStorage?.setItem("searchTerm", searchTerm);
                redirectEvent(item);
              }}
            />
          </ProductCardHoc>
        ))
      ) : (
        <div className="noresult-found">
          <div className="no-result-icon">
            <img src="/images/no-result-icon.svg" />
          </div>
          <div className="no-rusult-heading">No Results Found!</div>
          <div className="no-rusult-subheading">
            Please check if you have the right spelling, or try different
            keywords.
          </div>
        </div>
      )}
    </SearchResultsContainer>
  );
};

export default SearchedPackages;

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
//import { urlencoded } from 'body-parser';

const SectionComponent = (props) => {
    
    const pageRoute = useRouteMatch();
    const route = pageRoute.path;

    const [title, settitle] = useState('');
    const [hideSearchBar, setHideSearchBar] = useState(true);
    const [searchTermInput, setSearchTermInput] = useState('');
    const setNoSearchTerm = props.setNoSearchTerm;
    const setSearchTerm = props.setSearchTerm;
    
    const handleSearchInput = (event) => {
        event.preventDefault();
        setSearchTermInput(event.target.value);
        if(!event.target.value.trim()) setNoSearchTerm(true);
      }
    
    const  handleSearchAction = () => {
        if (searchTermInput.trim().length <= 1){
            return;
        }
        if(!(props.courseDetails && props.courseDetails.type == 1)) {
            setNoSearchTerm(false);
            setSearchTerm(searchTermInput.trim());
        }
      }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchAction();
        }
    }

    const toggleHandler = (title) => {
        if (typeof window != undefined && typeof document != undefined) {
            let element = window.document.getElementById(title);
            if (element.className == 'bottom-toggle') {
                settitle(title)
            }
            else {
                settitle('')
            }
        }
    }

    useEffect(() => {
        if(route === "/my-mock-test" || route === "/my-live-class" || route === "/my-videos") setHideSearchBar(false);
        else setHideSearchBar(true);
        setSearchTermInput("")
    },[props?.selectedTab]);


    return (
        <div className='sectionComponent'>
            <div className='sectionHeader custumSearchWrap'>
   
                <div className = {props.fromVideo ?  ' mt-head' : ''}>{props.header}</div>
                {
                    props.subheader && <div className='video-pdp-card-subhead'>{props.subheader}</div>
                }
                {!hideSearchBar && <span className='custum-search-wrapper'>
                      <input className='ebooks-search' type={'text'} placeholder="Search" value={searchTermInput} onChange={(e) => {handleSearchInput(e)}} onKeyDown = {handleKeyDown}></input>
                      <button className='ebooks-search-btn' onClick={() => {handleSearchAction()}}>Search</button>
                </span>}
            </div>
            {props.courseDetails && props.courseDetails.type == 1 ?
                props.courseDetailsObj.cD && props.courseDetailsObj.cD.map(field => {

                    return (
                        <div>
                            < div className='d-flex' style={{ padding: '20px' }}>
                                <div className={field.title === 'General Studies' ? 'icon-sub-general-awareness circle-icon-videos' :
                                    field.title === 'Banking & Static Awareness' || field.title === 'Banking Awareness circle-icon-videos' ? 'icon-sub-banking-financial-awareness circle-icon-videos' :
                                        field.title === 'English Language' || field.title === 'English' ? 'icon-sub-eng-lang circle-icon-videos' : field.title === 'Reasoning Ability' || field.title === 'Reasoning' ? 'icon-sub-reasoning-ability circle-icon-videos' :
                                            field.title === 'Computer Awareness' || field.title === 'Computer' || field.title === 'SO-IT' ? 'icon-sub-computer-knowledge circle-icon-videos' :
                                                field.title === 'Quantitative Aptitude' || field.title === 'Maths' || field.title === 'Data Interpretation' ? 'icon-sub-quantitative-aptitude circle-icon-videos' :
                                                    field.title === 'Polity' ? 'icon-sub-polity circle-icon-videos' : field.title === 'Economics' ? 'icon-sub-economics circle-icon-videos' : field.title === 'History' ? 'icon-sub-history circle-icon-videos' :
                                                        field.title === 'Geography' ? 'icon-sub-geographyn circle-icon-videos' : field.title === 'Science' ? 'icon-sub-science' : field.title === 'सामान्य हिंदी' ? 'icon-sub-hindi-lang circle-icon-videos' : 'circle-icon-videos'}
                                >
                                </div>


                                <div className='width-90' style={{ marginLeft: '10px' }}>
                                    <div>
                                        {field.title}
                                    </div>
                                    <div >{field.secN}Sections | {field.vidN}Videos </div>

                                </div>
                                <div id={field.title}
                                    onClick={() => toggleHandler(field.title)}
                                    className={field.title.localeCompare(title) == 0 ? 'top-toggle' : 'bottom-toggle'} style={{ position: 'relative', marginLeft: '0px' }}>
                                </div>
                            </div>
                            <div className={field.title.localeCompare(title) != 0 ? 'display-none' : 'sectionBody'} >
                                <div
                                    dangerouslySetInnerHTML={{ __html: field.desc }}
                                />
                            </div>
                        </div>


                    )
                })

                :
                <div className='sectionBody'>
                    {props.body}
                </div>


            }


        </div >
    )
}
export default SectionComponent;
import reducerRegistry from '../../reducerRegistry';

function detailPost(
    state = {
    },
    action
) {
    switch (action.type) {
    case 'GET_TIMELINE_BY_ID': {
        const resp = action.payload;
        const id = resp[resp.type].id;
        const contentUrl = resp[resp.type].contentLink;
        const postUpdateAt = resp[resp.type].updatedAt;
        return {
            ...state,
            id,
            contentUrl,
            postUpdateAt
        };
    }
    case 'GET_TIMELINE_STATIC_DATA': {
        const resp = action;
        return {
            ...state,
        };
    }
    case 'CLEAR_POST_DATA': {
        return {
            ...state,
            htmlData: ''
        };
    }
    default:
        return state;
    }
}
reducerRegistry.register('detailPost', detailPost);
export default detailPost;

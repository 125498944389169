import { storeHostUrl, pageName } from "../../constants/appConfig";
import { isADDA } from "../../app/data/utils/helpers";

var d = new Date();
var year = d.getUTCFullYear();

const footerData = isADDA
  ? [
      {
        header: "Company",
        children: [
          {
            childKey: 0,
            title: "About Us",
            url: `https://www.${pageName[0]}.com/about-us.html`,
          },
          {
            childKey: 1,
            title: "Contact Us",
            url: `https://www.${pageName[0]}.com/contact-us.html`,
          },
          {
            childKey: 2,
            title: "Media",
            url: `https://www.${pageName[0]}.com/media.html`,
          },
          {
            childKey: 3,
            title: "Careers",
            url: `https://www.${pageName[0]}.com/careers.html`,
          },
          {
            childKey: 4,
            title: "Careers for Faculties",
            url: `https://www.${pageName[0]}.com/lp/career-teachers.html`,
          },
          {
            childKey: 5,
            title: "Franchise",
            url: "http://franchise.careerpower.in",
          },
          {
            childKey: 6,
            title: "Content Partner",
            url: `https://partners.${pageName[0]}.com`,
          },
          {
            childKey: 7,
            title: "Online Support",
            url: `https://www.${pageName[0]}.com/support`,
          },
        ],
      },
      {
        header: "Popular Goals",
        children: [
          {
            childKey: 0,
            title: "BANKING & INSURANCE",
            url: "/banking-study-materials",
          },
          {
            childKey: 1,
            title: "SSC",
            url: "/ssc-study-materials",
          },
          {
            childKey: 2,
            title: "RAILWAYS",
            url: "/railways-study-materials",
          },
          {
            childKey: 3,
            title: "TEACHING",
            url: "/ctet-study-materials",
          },
          {
            childKey: 4,
            title: "DEFENCE",
            url: "/defence-study-materials",
          },
          {
            childKey: 5,
            title: "ENGINEERING",
            url: "/engineering-study-materials",
          },
          {
            childKey: 6,
            title: "UPSC",
            url: "/upsc-study-materials",
          },
          {
            childKey: 7,
            title: "NEET",
            url: "/neet-study-materials",
          },
        ],
      },
      {
        header: "Products",
        children: [
          {
            childKey: 0,
            title: "Mock Tests",
            url: `https://www.${pageName[0]}.com/mock-tests`,
          },
          {
            childKey: 2,
            title: "Live Classes",
            url: `https://www.${pageName[0]}.com/live-classes`,
          },
          {
            childKey: 3,
            title: "Video Courses",
            url: `https://www.${pageName[0]}.com/videos`,
          },
          {
            childKey: 5,
            title: "Ebooks",
            url: `https://www.${pageName[0]}.com/ebooks`,
          },
          {
            childKey: 6,
            title: "Books",
            url: `https://www.${pageName[0]}.com/books`,
          },
        ],
      },
      {
        header: "Latest Online Coaching",
        children: [
          {
            childKey: 0,
            title: "UPSC Online Coaching",
            url: "/upsc/live-classes-study-kit",
          },
          {
            childKey: 1,
            title: "GATE Online Coaching",
            url: "/gate-and-ese/live-classes-kit",
          },
          {
            childKey: 2,
            title: "SSC GD Online Classes",
            url: "/ssc-gd/live-classes-kit",
          },
          {
            childKey: 3,
            title: "IBPS SO Online Classes",
            url: "/ibps-so/live-classes-kit",
          },
          {
            childKey: 4,
            title: "Banking Coaching",
            url: "/banking/live-classes-study-kit",
          },
          {
            childKey: 5,
            title: "SBI PO Online Coaching",
            url: "/sbi-po/live-classes-kit",
          },
          {
            childKey: 6,
            title: "CUET Online Coaching",
            url: "/cuet/live-classes-study-kit",
          },
        ],
      },
      {
        header: "Latest Mock Tests",
        children: [
          {
            childKey: 0,
            title: "JAIIB CAIIB Mock test",
            url: "/jaiib-caiib/mock-tests-study-kit",
          },
          {
            childKey: 1,
            title: "CUET Mock Test",
            url: "/cuet/mock-tests-study-kit",
          },
          {
            childKey: 2,
            title: "SSC CHSL Mock Test",
            url: "/ssc-chsl/mock-tests-kit",
          },
          {
            childKey: 3,
            title: "SSC GD Mock Test",
            url: "/ssc-gd/mock-tests-kit",
          },
          {
            childKey: 4,
            title: "SSC Mock Test",
            url: "/ssc/mock-tests-study-kit",
          },
          {
            childKey: 5,
            title: "CTET Mock Test",
            url: "/ctet/mock-tests-kit",
          },
          {
            childKey: 6,
            title: "RRB NTPC Mock Test",
            url: "/rrb-ntpc/mock-tests-kit",
          },
        ],
      },
    ]
  : [
      {
        header: "COMPANY",
        children: [
          {
            childKey: 0,
            title: "About Us",
            url: `https://www.${pageName[0]}.com/about-us.html`,
          },
          {
            childKey: 1,
            title: "Contact Us",
            url: `https://www.${pageName[0]}.com/contact-us.html`,
          },
          {
            childKey: 2,
            title: "Privacy Policy",
            url: `https://www.${pageName[0]}.com/privacy-policy.html`,
          },
          {
            childKey: 3,
            title: "Terms & Conditions",
            url: `https://www.${pageName[0]}.com/terms-and-conditions.html`,
          },
          {
            childKey: 3,
            title: "Cancellation Refund Policy",
            url: `https://www.${pageName[0]}.com/cancellation-refund-policy.html`,
          },
          {
            childKey: 4,
            title: "Careers",
            url: `https://www.${pageName[0]}.com/careers.html`,
          },
          {
            childKey: 5,
            title: "Careers for Faculties",
            url: `https://www.${pageName[0]}.com/careeer-techer.html`,
          },
        ],
      },
      {
        header: "Products",
        children: [
          {
            childKey: 0,
            title: "3D Learning",
            url: `/3d-models`,
          },
          {
            childKey: 1,
            title: "Mock Tests",
            url: `/mock-tests`,
          },
          {
            childKey: 2,
            title: "Live Classes",
            url: `/live-classes`,
          },
          {
            childKey: 3,
            title: "Books",
            url: `/books`,
          },
          {
            childKey: 4,
            title: "Questions & Answers",
            url: `/zoology/question`,
          },
        ],
      },
      {
        header: "IIT JEE",
        children: [
          {
            childKey: 0,
            title: "Class 12",
            url: `/class12-jee-study-materials`,
          },
          {
            childKey: 1,
            title: "Dropper",
            url: `/dropper-jee-study-materials`,
          },
        ],
      },
      {
        header: "NEET",
        children: [
          {
            childKey: 0,
            title: "Class 12",
            url: `/class12-neet-study-materials`,
          },
          {
            childKey: 1,
            title: "Dropper",
            url: `/dropper-neet-study-materials`,
          },
          {
            childKey: 2,
            title: "NEET Rank & College Predictor",
            url: `/neet-rank-predictor`,
          },
        ],
      },
    ];

export default footerData;

import React, { Component } from 'react'


function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

class BottomScrollListener extends Component {
  static defaultProps = {
    debounce: 200,
    offset: 0,
  }  
  optionalScrollContainerRef = React.createRef();

  constructor(props) {
    super(props)
    

    if (props.debounce) {
      this.handleOnScroll = debounce(this.handleOnScroll.bind(this), props.debounce, true)
    } else {
      this.handleOnScroll = this.handleOnScroll.bind(this)
    }
  }

  componentDidMount() {
    if (this.props.children instanceof Function) {
      if (this.optionalScrollContainerRef.current) {
        this.optionalScrollContainerRef.current.addEventListener('scroll', this.handleOnScroll)
      } else {
        throw Error(
          'Unable to use scroll container: Ref to child not available, did you pass the ref prop to an element?',
        )
      }
    } else {
      document.addEventListener('scroll', this.handleOnScroll)
    }
  }

  componentWillUnmount() {
    if (this.props.children instanceof Function) {
      if (this.optionalScrollContainerRef.current) {
        this.optionalScrollContainerRef.current.removeEventListener('scroll', this.handleOnScroll)
      } else {
        throw Error('Unable to clean up scroll container: Ref has been unmounted prematurely.')
      }
    } else {
      document.removeEventListener('scroll', this.handleOnScroll)
    }
  }

  handleOnScroll() {
    if (this.props.children instanceof Function) {
      const scrollNode = this.optionalScrollContainerRef.current

      if (scrollNode != null) {
        const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + scrollNode.clientHeight)
        const scrollPosition = Math.round(scrollNode.scrollHeight - this.props.offset)

        if (scrollPosition <= scrollContainerBottomPosition) {
          this.props.onBottom()
        }
      }
    } else {
      const scrollNode = document.scrollingElement || document.documentElement
      const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + window.innerHeight)
      //const scrollPosition = Math.round(scrollNode.scrollHeight - this.props.offset-350);
      let scrollPosition = document.body.scrollTop;
      if(scrollPosition==0) {
          scrollPosition = document.documentElement.scrollTop;
      }
      if (scrollPosition <= scrollContainerBottomPosition) {
        this.props.onBottom()
      }
    }
  }

  render() {
    if (!this.props.children) return null

    if (this.props.children instanceof Function) {
      return this.props.children(this.optionalScrollContainerRef)
    } else {
      return this.props.children
    }
  }
}

export default BottomScrollListener
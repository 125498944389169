import React, { useState, useEffect } from "react";

const QuestionOptions = ({ options, correctOptions, qType, setShowSolution }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [wrongOptions, setWrongOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setShowSolution(false);
  }, [])

  useEffect(() => {
    if (submitted) {
      let wrongIds = selectedOptions.filter(
        (ci) => !correctOptions.find((si) => si === ci)
      );
      setSelectedOptions(correctOptions)
      setWrongOptions(wrongIds)
    }
  }, [submitted]);

  const handleOptClick = (id) => {
    if (qType == 1) {
      setSelectedOptions([id]);
    } else if (qType == 3) {
      let idx = selectedOptions.indexOf(id);
      let arr = [...selectedOptions];
      if (idx !== -1) {
        //remove
        arr.splice(idx, 1);
        setSelectedOptions(arr);
      } else {
        //add
        setSelectedOptions([...arr, id]);
      }
    }
  };

  return (
    <>
      <div className="sank-seo-options-wrap">
        {options?.map((item, idx) => (
          <div
            className={`sank-seo-option-box ${
            !submitted ? (selectedOptions?.includes(item?.id) ? "sank-seo-selected-box" : '') : correctOptions?.includes(item?.id) ? 'sank-seo-active-box' : wrongOptions?.includes(item?.id) ? 'sank-seo-wrong-selected-box' : ''    
        }`}
            onClick={!submitted ? () => handleOptClick(item?.id) : null}
            id={`opt__${item?.id}`}
            key={`opt__${idx}`}
          >
            <div className="sank-seo">{String.fromCharCode(65 + item.id)}</div>
            <h2
              className="sank-seo-content"
              dangerouslySetInnerHTML={{ __html: item?.val }}
            />
            {qType == 3 && <div className="sank-seo-checkbox"></div>}
          </div>
        ))}
      </div>
      <div>
        {!submitted && (
          <button className="seo-submit-btn" disabled={!selectedOptions?.length} onClick={() => setSubmitted(true)}>Submit</button>
        )}
      </div>
    </>
  );
};

export default QuestionOptions;

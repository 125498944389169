import React, {useRef} from "react";
import { formatSubCatlabel, scrollToElementWithOffset, scrollToHorizontalElementWithOffset } from "../../data/utils/helpers";
import { packageContentSubcategories } from "../../../constants/consts";
const PackageContent = ({ subCategoryData, onClick }) => {
    const sliderRef = useRef(null);
  
    const handleScrollLeft = () => {
      sliderRef.current.scrollLeft -= 300;
    };
  
    const handleScrollRight = () => {
      sliderRef.current.scrollLeft += 300;
    };
    
    return (
      <div className="toGet">
        <div className="slider-wrapper">
          <ul className="slider" ref={sliderRef}>
            {packageContentSubcategories?.map((subCatData) => {
              const { category, image, id } = subCatData;
              const item = subCategoryData?.find(
                (item) => item?.category === category
              );
  
              if (!item) {
                return null;
              }
  
              const duration =
                subCatData?.category === "ONLINE_LIVE_CLASSES" &&
                item?.vduration > 0
                  ? parseInt(item?.vduration) + " Hrs"
                  : item?.count;
  
              const count = item?.count ? item?.count : 0;
  
              return (
                <li key={category}>
                  <div onClick={category !== 'BOOKS' ? () => onClick(id, category) : null}>
                    <img src={image} alt={category}  />
                    {duration || count}
                    <span>
                      {category === "EBOOKS"
                        ? "E-Books"
                        : formatSubCatlabel(category)}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="arrow left" onClick={handleScrollLeft}>
            <img
              src={"/images/pdpslidebtnarrow.svg"}
              alt="button-left"
              title="adda247"
              style={{ transform: "rotate(180deg)", outlineStyle: "none" }}
            />{" "}
          </div>
          <div className="arrow right" onClick={handleScrollRight}>
            <img
              src={"/images/pdpslidebtnarrow.svg"}
              alt="button-right"
              title="adda247"
              style={{ outlineStyle: "none" }}
            />{" "}
          </div>
        </div>
      </div>
    );
  };
  
  export default PackageContent;
import React from "react";

const NetworkDisconnected = ()=>{

    return (
        <div className='skip-section-body'>
            <div  className='skip-section-header'>
                Network Disconnected
            </div>
            <div className='skip-section-subheader'>
                Your Network got disconnected.
            </div>
            <div className='skip-section-info'>
                Your test data was saved. You will be able to restart test fom where you left.
            </div>
            

        </div>
    )
}
export default NetworkDisconnected;
import reducerRegistry from '../../reducerRegistry';
import types from './types';

function examDetailListing(
    state = {

    },
    action
) {
    switch (action.type) {
    case types.GET_EXAM_DETAIL_LISTING:
        const data = action.payload.data;
        data.packageEsEntity = data.packages;
        delete data.packages;
        return {
            ...state,
            data: data
        };

    case types.GET_EXAM_DETAIL_LISTING_ON_SCROLL: {
        const newList = state?.data?.packageEsEntity?.concat(action?.payload?.data?.packages);
        state.data.packageEsEntity = newList;
        return {
            ...state
        };
    }

    case types.CLEAR_EXAM_DETAIL_LISTING_DATA: {
        state = {};
        return {
            ...state
        };
    }
    default:
        return state;
    }
}
reducerRegistry.register('examDetailListing', examDetailListing);
export default examDetailListing;

import React, { useState, useEffect } from 'react';
import CustomModal from './CustomModal';
import { getParameterByName } from '../../data/utils/helpers';
import CustomLoader from "./CustomLoader";
const MarsPaymentPage = (props) => {

    const [paymentStatus, setPaymentstatus] = useState("");
    useEffect(() => {

        let status = getParameterByName("status");
        setPaymentstatus(status)
    }, [])
    const redirectUrl = () => {
        window.location.pathname = "gold/paytm/callback/redirect";
    }
    return ( 
        <div> 
            {paymentStatus ? <div className='go-to-app'>
            <div className='gt-app-image' style={{ display: "flex", marginTop: "150px" }}>
                <img style={{ margin: "auto" }}
                    src={paymentStatus == "success" ? "/images/marssuccess.svg" : "/images/marsfailed.svg"}
                    // style={{ maxHeight: "100%", maxWidth: "100%" }}
                    alt="Download App"
                // onClick={openInApp}
                />
            </div>

            <div className='gt-app-text info'>
                <span>
                    {paymentStatus == "success" ? "You have added money into your Paytm Wallet successfully" : "Sorry! There was some error while adding money to your wallet"}
                </span>
            </div>

            <div>
                <button className='right-button'  style={{borderRadius:"11px"}}onClick={() => redirectUrl()}> {paymentStatus == "success" ? "OKAY" : "Try again"} </button>
            </div>

            </div> : <div style={{
                    height: '80vh',
                display: "flex",
                    justifyContent: 'center'
            }}><CustomLoader /></div>}
   </div>
    )
}


export default MarsPaymentPage;
import React, { useEffect, useState } from "react";
import CatalogCard from "../CatalogCard";
import { default as Link } from "../../containers/ConnectedLink";
import {
  makeUrl,
  scrollToTop,
  createOptimizedImgUrl,
  isADDA,
} from "../../data/utils/helpers";
import NoCourse from "./NoCourse";
import Pagination from "../library/Pagination";
import { overlayVariant } from "../../../constants/appConstants";
import LazyIntersection from "../LazyIntersection";
import { FallBackImage } from "../../../constants/appConfig";
import LiveTestModal from "./TestSeries/TestResultAnalysis/Components/LiveTestModal";
import { liveTestModalData } from "./TestSeries/TestResultAnalysis/Components/liveTestUtils";
const EnrolledCourseListing = ({
  enrolledCourseList,
  comingSoonCourseList,
  onCourseClick,
  courseObj,
  actionButtonText,
  courseSectionObj,
  cacheFlag,
  paginatedFlag,
  noDataText, liveTest,
}) => {
  const [endIndex, setEndIndex] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLiveTestModal, setShowLiveTestModal] = useState(false);

  useEffect(() => {
    var activePage = sessionStorage.getItem("enrolledCourseLisitingPage");
    if (activePage) {
      setCurrentPage(activePage);
      setTimeout(
        () => sessionStorage.removeItem("enrolledCourseLisitingPage"),
        1000
      );
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [enrolledCourseList]);

  if (enrolledCourseList.length == 0) {
    let noCourseObj = courseSectionObj.noItems;
    return (
      <NoCourse
        url={noCourseObj.url}
        info={noCourseObj.label}
        buttonText={noCourseObj.buttonText}
        cacheFlag={cacheFlag}
        noDataText={noDataText}
        noBtn={noCourseObj?.noBtn}
      ></NoCourse>
    );
  }
  const handleScroll = (item) => {
    const position = window.pageYOffset;
    onCourseClick(item, position);
  };

  const handleEndIndex = () => {
    if (endIndex + 10 > comingSoonCourseList.length)
      setEndIndex(comingSoonCourseList.length);
    else if (endIndex == comingSoonCourseList.length) return;
    else setEndIndex(endIndex + 10);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem("enrolledCourseLisitingPage", page);
    console.log(page * 20 - 20, page * 20);
    scrollToTop(100);
  };

  const closeLiveTestModal = (e) => {
    setShowLiveTestModal(false);
  };


  const comingSoonBody = () => {
    if (comingSoonCourseList && comingSoonCourseList.length > 0) {
      return (
        <div className="comingLiveClassWrap">
          <div className="liveSubHeading">Coming Soon</div>
          <div className="comingLiveClass">
            {comingSoonCourseList.slice(0, endIndex).map((item, idx) => (
              <>
                <div
                  className="mypurchase continue-reading paid-product-box"
                  key={idx}
                  onClick={() => handleScroll(item)}
                >
                  <div
                    className="purchaseImage product-img-paid"
                    title={item.title}
                  >
                    <div
                      className="purchaseImageBg"
                      style={{ backgroundImage: `url(${item.imageUrl || FallBackImage})` }}
                    ></div>
                    <div className="purchaseImageOverlay overlay-bg"></div>
                    <LazyIntersection
                      src={createOptimizedImgUrl(item.imageUrl, 200)}
                      placeholder={FallBackImage}
                      alt={item.name}
                      style={{
                        maxWidth: "200px",
                        maxHeight: "190px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>

                  <div className="textcontainer paid-content-wrap">
                    <div className="coursename-paid" title={item.title}>
                      {item.title}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="commingShowmore">
            {comingSoonCourseList?.length > 5 &&
              endIndex !== comingSoonCourseList.length && (
                <span onClick={() => handleEndIndex()}>
                  Show More <i></i>
                </span>
              )}
          </div>

          <div className="liveSubHeading">All Live Classes</div>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      {comingSoonBody()}
      <div className="enrolled-course-listing">
        {enrolledCourseList
          .slice(
            paginatedFlag ? currentPage * 20 - 20 : 0,
            paginatedFlag ? currentPage * 20 : enrolledCourseList?.length
          )
          .map((item) => {
            let expirydate = item.expiryDate;
            let expiredDateString = "";
            if (expirydate) {
              const d = new Date(expirydate && expirydate);
              const ye = new Intl.DateTimeFormat("en", {
                year: "numeric",
              }).format(d);
              const mo = new Intl.DateTimeFormat("en", {
                month: "short",
              }).format(d);
              const da = new Intl.DateTimeFormat("en", {
                day: "2-digit",
              }).format(d);
              let expireddatevalue = `${da} ${mo} ${ye}`;
              expiredDateString = `on ${expireddatevalue}`;
            }

            return (
              <div
                key={item.id}
                className="catalog-video"
                onClick={() => handleScroll(item)}
              >
                <CatalogCard
                  header={item.title}
                  isExpired={item.expired}
                  fromVideo={true}
                  text={
                    <div className="catalog-card-text">
                      {/* <span>{`${item && item.expired ? 'Expired' : 'Expires'} on ${expireddatevalue}`}</span> */}
                      <span className={`${!item.expired && "expire-text"}`}>
                        {item && item.expired
                          ? `Expired ${expiredDateString}`
                          : `${
                              expiredDateString
                                ? `Expires ${expiredDateString}`
                                : ""
                            }`}
                      </span>
                      <span className="view-course">
                        {actionButtonText || "VIEW COURSE"}
                      </span>
                    </div>
                  }
                  src={item.imageUrl}
                  variant={overlayVariant.overlayBg}
                  liveTest={liveTest}
                  showLiveTestModal={showLiveTestModal}
                  setShowLiveTestModal={setShowLiveTestModal}
                  showFullLengthLabel={false}
              
            ></CatalogCard>
              </div>
            );
          })}
        {paginatedFlag && (
          <div className="pagination liveandcoming">
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              count={enrolledCourseList && enrolledCourseList.length}
              pageSize={20}
            />
          </div>
        )}
        <LiveTestModal 
        showModal={showLiveTestModal} 
        closeModal={closeLiveTestModal}
        title={liveTestModalData?.howItWorks?.title}
        description={liveTestModalData?.howItWorks?.description}
        modalType={liveTestModalData?.type || ""}
        modalDescClass={'lt-how-it-works-desc'}
      />
    </div>
    </>
  );
};

export default EnrolledCourseListing;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { openTestPortal } from "../TakeTest/util";
import TestAnalysisHeader from "../TestResultAnalysis/Components/TestAnalysisHeader";

const DTBResullInfo = ({ mappingId, packageId, title, sfJson, exam }) => {
  const { search } = useLocation();
  let testurlQueryParams = search;
  return (
    <div className="resultAnalysisWrapper">
      <TestAnalysisHeader title={title} />
      <div className="dtb-big-img">
        <img src="/images/dtb-img-big.svg" alt="banner" />
        <p>Thank you for attempting the Test</p>
        <div className="dtb-bottom-btns">
          <Link
            to={`/test-series-portal/${mappingId}/${packageId}/COMPLETED/${encodeURIComponent(
              title
            )}${testurlQueryParams}`}
          >
            <button type="button" className="viewmodal-btn">
              View model answer
            </button>
          </Link>
          <button
            className="reattempt-btn"
            type="button"
            onClick={() => {
              openTestPortal(
                mappingId,
                packageId,
                title,
                "COMPLETED",
                "REATTEMPT",
                [exam],
                sfJson,
                true
              );
            }}
          >
            Reattempt the test
          </button>
        </div>
      </div>
    </div>
  );
};

export default DTBResullInfo;

import React from 'react';
import ContentSection from '../../components/library/ContentSection/ContentSection';
import ToggleSectionComponent from '../../components/library/ToggleSectionComponent/ToggleSectionComponent';
import { trackMoengageEvent } from '../../data/utils/helpers';

const Faq = ({ faqJson }) => {


    const isEmpty = param => {
        if (param !== null) {
          if (Array.isArray(param) && param.length) {
            return false;
          }
          if (typeof param === "object" && Object.keys(param).length) {
            return false;
          }
          return true;
        }
      };

    if (!faqJson || faqJson.length == 0 || isEmpty(faqJson[0])) return null;
    function trackFaqClick(question){
      let payload = {};
          payload.FAQ_question = question;
          payload.action = "FAQ clicked";
          trackMoengageEvent("Faq_Question_Clicked",payload)
    }
    
    let faqObj = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity":[]
    };
 
    faqJson.map(faq => {
      let tempFaq = {};
      if(faq['que']){
        tempFaq['@type'] = 'Question';
        tempFaq['name'] = faq['que'];
        tempFaq['acceptedAnswer'] = {
          "@type": "Answer",
          "text": faq['ans']
        };
        faqObj["mainEntity"].push(tempFaq) ;
      }
    });
  
  
    return (
      <div className="faqSection">
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html:JSON.stringify(faqObj)
          }}
        />
  
        <ContentSection
          header="Frequently Asked Questions"
          body={
            <div>
              {faqJson.map(faq => {
                if(!faq.que){
                  return null;
                }
                return (
                  <ToggleSectionComponent
                    header={`Q: ${faq.que}`}
                    body={faq.ans}
                    click={trackFaqClick}
                  ></ToggleSectionComponent>
                );
  
              })}
            </div>
          }
        ></ContentSection>
      </div>
    );
  };
  export default Faq;
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_IDENTIFIERS } from "../../../../constants/textConstants";
import {
  setSelectedFiltersFacets,
  setShowFilterPopup,
} from "../../../data/ducks/wishlist/actions";
import {
  getDeviceType,
  isAnyKeyOfObjectWithValue,
} from "../../../data/utils/helpers";
import { initialFacetsKeys, tabFacetsMap } from "../constants";
import MwebFilter from "./MwebFilter";
import WebFilter from "./WebFilter";

const WishListFacetsFilter = ({ fetchWishListDetailHandlerCb }) => {
  const {
    wishlistPackageDetails,
    isFilterVisible = false,
    selectedFiltersFacets = initialFacetsKeys,
  } = useSelector((state) => state.wishlist);
  const [activeTab, setActiveTab] = useState("Exam Category");
  const [initialFacetsState, setInitialFacetsState] = useState({});
  const dispatch = useDispatch();
  const isWebView = getDeviceType() === DEVICE_IDENTIFIERS?.DESKTOP_IDENTIFIER;

  // handler to open and close filter overlay
  const togglerFilter = () => {
    dispatch(setShowFilterPopup(!isFilterVisible));
    if (!isWebView) setInitialFacetsState(selectedFiltersFacets); //used to preserve mweb filter on open and close popup.
  };

  // handler to close mweb filter
  function onCloseMwebFilter() {
    dispatch(setSelectedFiltersFacets(initialFacetsState)); //used to preserve mweb filter on open and close popup
    dispatch(setShowFilterPopup(false));
  }
  const onChangeFacetHandlerCb = ({
    isSelected,
    value,
    facetType,
    isSearchEnabled,
  }) => {
    const selectedFacets = selectedFiltersFacets[facetType] ?? "";

    // when user selects
    let newValue =
      selectedFacets.length <= 1
        ? value
        : selectedFacets?.split(",")?.concat(value).join(",");

    // when user unselect
    if (!isSelected) {
      const filteredFacets = selectedFacets
        .split(",")
        .filter((str) => str !== value);
      newValue = filteredFacets.join(",");
    }

    const newSelectedFacetsFilters = {
      ...selectedFiltersFacets,
      [facetType]: newValue,
    };
    dispatch(setSelectedFiltersFacets(newSelectedFacetsFilters));
    if (isSearchEnabled) fetchWishListDetailHandlerCb(newSelectedFacetsFilters);
  };

  const facetTabMappingToContent = useMemo(() => {
    if (!activeTab || !isFilterVisible || !wishlistPackageDetails) return [];

    let mappedContent = [];

    const primaryFacets = wishlistPackageDetails?.primaryFacets;
    const tabFacets = tabFacetsMap[activeTab];

    if (primaryFacets && tabFacets && primaryFacets[tabFacets]) {
      mappedContent = Object.keys(primaryFacets[tabFacets]);
    }

    return mappedContent;
  }, [activeTab, isFilterVisible, wishlistPackageDetails]);

  // mWeb apply filter handler
  const applyFilterHandler = (appliedFilters) => {
    fetchWishListDetailHandlerCb(appliedFilters);
  };
  const clearFilters = () => {
    // set selected filter to its original state.
    dispatch(setSelectedFiltersFacets(initialFacetsKeys));
  };

  const isFilterApplied = useMemo(() => {
    return isAnyKeyOfObjectWithValue(selectedFiltersFacets);
  }, [selectedFiltersFacets]);
  return (
    <div>
      <button onClick={togglerFilter} className="wl-filter-btn">
        <div>
          <span className="label">Filter</span>
          {isFilterApplied && <span className={"dot-filter"}></span>}
        </div>
      </button>
      {isWebView && isFilterVisible && (
        <WebFilter
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabFacetsMap={tabFacetsMap}
          facetItems={facetTabMappingToContent}
          selectedFiltersFacets={selectedFiltersFacets}
          onChangeFacetHandlerCb={onChangeFacetHandlerCb}
          closeFilter={togglerFilter}
        />
      )}
      {!isWebView && isFilterVisible && (
        <MwebFilter
          visible={isFilterVisible}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          loading={false}
          tabFacetsMap={tabFacetsMap}
          facetItems={facetTabMappingToContent}
          selectedFiltersFacets={selectedFiltersFacets}
          onChangeFacetHandlerCb={onChangeFacetHandlerCb}
          onCancel={onCloseMwebFilter}
          applyFilterCb={applyFilterHandler}
          clearFiltersCb={clearFilters}
        />
      )}
    </div>
  );
};

export default React.memo(WishListFacetsFilter);

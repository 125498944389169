import PropTypes from 'prop-types';
import React, { Component } from 'react';
//import BottomScrollListener from 'react-bottom-scroll-listener';
import BottomScrollListener from '../components/library/BottomScrollListener';
import { connect } from 'react-redux';
import { pageSizeForDetailListing, searchUrlEndPoint, pageName } from '../../constants/appConfig';
import { productKeys, productNames, viewAllKeys } from '../../constants/appConstants';
import FilterTags from '../components/filter/FilterTags';
import ProductTypeListing from '../components/filter/listings/ProductTypeListing';
import NewMobileFilter from '../components/filter/NewMobileFilter';
import SearchBlock from '../components/filter/searchfilters';
import BreadcrumbSchema from '../components/library/BreadcrumbSchema';
import CustomHelmet from '../components/library/CustomHelmet';
import CustomLoader from '../components/library/CustomLoader';
import NoResultsFound from '../components/library/NoResultsFound';
import NotFoundStatic from '../components/NotFoundStatic';
import { Cookies } from "react-cookie";

import { clearCourseDetailData, getPaginatedDataForCourseDetail } from '../data/ducks/courseDetailLisitng/actions';
import '../data/ducks/courseDetailLisitng/reducers';
import {
    capitalizeWords,
    capitalizedString,
    createStorefrontPdpUrl,
    getMoengageMetadata,
    isEmpty,
    isSet,
    validateCourseName,
    validateProductName,
    makeUrl,
    getDeviceType,
    getPdpUrl,
    getUrlPathObject, getTopExamtypes, removeHyphensAndInsertSpaces, trackMoengageEvent, isADDA, fireMoengageEvent, getMoengagePayloadFromFacets, replaceHyphenWithUnderscore, getTagIdParams
} from '../data/utils/helpers';
import CustomSkeleton from '../components/library/CustomSkeleton';
import BreadCrumb from '../components/library/BreadCrumb';
import Faq from './SEO/faq';
import { checkProperties } from '../components/utils';
import DfpAdPdp from '../components/library/DfpAdPdp';
import { SeoSubCategory } from '../components/library/SeoBlock/SeoSubCategory';
import { SeoSectionMobile } from '../components/library/SeoBlock/SeoBlock';
import NewSortByMobileFilter from '../components/filter/NewSortByMobileFilter';
import fetch from '../data/utils/fetch';
import CarouselSchema from '../components/library/CarouselSchema';

let pageN = 0;
const antIcon = <div className={'spinner'} style={{ fontSize: 24 }} />;
let requiredProductName = '';
let filterObj = {};
let count = 0;
const sponsoredCategory = 1;
const cookie = new Cookies();
class CourseDetailListing extends Component {
    constructor(props) {
        super(props);
        const tags = this.createTagsFromUrl();
        this.state = {
            loading: true,
            listingLoader: false,
            showFilterModal: false,
            showSortModal: false,
            selectedSortCategory: 'Relevance',
            selectedFilterCategory: 'No Filter',
            showFilterAppliedDot: false,
            showSortByAppliedDot: true,
            queryParam: props.location.search.substring(1) || '',
            tagArr: tags.tagArr,
            sortBy: 'RELEVANCE',
            dataLoader: false,
            facetObj: tags.facetObj,
            examPages: "",
            facetsList: null
        };
    }

    static propTypes = {
        getPaginatedDataForCourseDetail: PropTypes.func,
        courseDetailListing: PropTypes.object,
        clearCourseDetailData: PropTypes.func,
        utmParams: PropTypes.object
    };


    componentWillUnmount() {
        if (this.props.courseDetailListing && !isEmpty(this.props.courseDetailListing)) {
            if (typeof window !== 'undefined') {
                this.props.clearCourseDetailData();
            }
            this.clearBeforeUnload();
        }
        const googletag = window.googletag;

        if (googletag) googletag.destroySlots();
        count = 0;
    }

    examCategoryArray = isADDA ? [
        { name: "Banking" }, { name: "SSC" }, { name: 'Railways' },
        { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }, { name: "Civil-engineering" }, { name: "Civil-Engineering" }, { name: "civil-engineering" }
    ] : [{name: "Class12-JEE"}, {name: "Dropper-JEE"},{name: "Class12-NEET"}, {name: "Dropper-NEET"}];

    componentDidMount() {
        const { location, match } = this.props;
        const { pathname } = location;
        const courseName = pathname.split('/')[1];
        const productName = pathname.split('/')[2];
        let category = productName.split('-').slice(0, -2).join('-');
        this.getFacetsList(replaceHyphenWithUnderscore(courseName).toUpperCase());
        

        //data layer




        if (location.search.length) {
            const requiredString = location.search.substring(1);
            const paramArr = requiredString.split('&');
            for (let i = 0; i < paramArr.length; i++) {
                const newArr = paramArr[i].split('=');
                if (decodeURI(newArr[1]).length) {
                    filterObj[decodeURI(newArr[0])] = [decodeURI(newArr[1])];
                }
            }
        }
        typeof window !== 'undefined' && window.scrollTo(0, 0);
        if (this.validateCondition(requiredProductName, courseName)) {
            this.getPaginatedDataForDetailedPage(0, replaceHyphenWithUnderscore(courseName).toUpperCase(), false, this.state.sortBy);
        }
        //event handler



        let examPages = getTopExamtypes(this.props.examList, this.props.match.params.courseType == "banking" ? "BANKINGANDINSURANCE" : this.props.match.params.courseType)
        this.setState({ examPages: examPages })
        typeof window !== 'undefined' && window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.courseType !== this.props.match.params.courseType) {
            const { location, match } = this.props;
            const { pathname } = location;
            const courseName = pathname.split('/')[1];
            const productName = pathname.split('/')[2];
            let category = productName.split('-').slice(0, -2).join('-');

            this.getPaginatedDataForDetailedPage(0, replaceHyphenWithUnderscore(courseName).toUpperCase(), false, this.state.sortBy, true);
            typeof window !== 'undefined' && window.scrollTo(0, 0);
        }
    }

    getFacetsList = (course) => {
        let tagParams = getTagIdParams( course, this.props.globalExamList );
        fetch(`${searchUrlEndPoint}/packages/secondaryFacets?coursesFacets=${course.toUpperCase()}${tagParams}${requiredProductName? "&categoryFacets=" + viewAllKeys[requiredProductName]: ""}`).then(res => {
            this.setState({facetsList: res.data})
        })
    }

    clearBeforeUnload() {
        this.setState({
            tagArr: [],
            facetObj: {}
        });
    }

    createTagsFromUrl = () => {
        const qParams = this.props.location.search.substring(1);
        let tempTagArr = [];
        let newFacetObj = {};
        if (qParams.length) {
            let splitArr = qParams.split('&');
            splitArr = splitArr.filter(e => !(e.indexOf("utm") > -1 || e.indexOf("gclid") > -1));
            splitArr.map(item => {
                let newArr = item.split('=');
                let finalArr = (newArr[1] || "").split(',');
                finalArr.map(it => {
                    if (!tempTagArr.includes(decodeURI(it))) {
                        tempTagArr.push(decodeURI(it));
                    }
                });
                newFacetObj[newArr[0]] = finalArr;
            });
        }
        return {
            tagArr: tempTagArr.filter((item) => item),
            facetObj: newFacetObj
        };
    };

    handleFilterButton = () => {
        this.setState({
            showFilterModal: true,
            showSortModal: false
        });
        fireMoengageEvent("filter_button_clicked", {}, "filter");
    };

    handleSortButton = () => {
        this.setState({
            showSortModal: !this.state.showSortModal
        });
    };

    hideFilterOnMobile = () => {
        this.setState({
            showFilterModal: false,
            currentActiveFilter: ''
        });
    };

    setExamAsCurrentCategoryAndOpenFilter = () => {
        this.setState({
            showFilterModal: true,
            showSortModal: false,
            currentActiveFilter: 'examTypes'
        });
    };

    handleFilterApply = (facetObj) => {
        this.setState({
            dataLoader: true,
            showFilterModal: false,
            showFilterAppliedDot: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ),
            selectedFilterCategory: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ) ? 'Filter Applied' : 'No Filter',
        });
        let urlString = '&';
        const facetObjectKeys = Object.keys(facetObj);
        const exam = this.props.location.pathname.split('/')[1];
        const facetKeysArrLen = facetObjectKeys.length;
        for (let i = 0; i < facetKeysArrLen; i++) {
            let facetValue = facetObj[facetObjectKeys[i]].join(',');
            urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
        }
        const encodedQueryString = encodeURI(urlString.slice(0, -1));
        fireMoengageEvent("filter_applied", getMoengagePayloadFromFacets(facetObj), "filter");
        this.props.history.push(getUrlPathObject(`${this.props.location.pathname}`, `?${encodedQueryString.substring(1)}`));
        // this.createTagsFromUrl();
        this.setState({
            queryParam: encodedQueryString,
            showFilterModal: false,
            currentActiveFilter: ''
        }, () => this.getPaginatedDataForDetailedPage(0, exam, true, this.state.sortBy));
    };

    resetFilter = () => {
        filterObj = {};
        this.clearAllFilters();
        this.setState({
            showFilterModal: false,
            currentActiveFilter: '',
            showFilterAppliedDot: false,
            selectedFilterCategory: 'No Filter',
        });
    };

    handleRadio = (course) => {
        const { location } = this.props;
        const { pathname } = location;
        const productName = pathname.split('/')[2];
        this.props.history.push(getUrlPathObject(`/${course.toLowerCase()}/${productName}`));
        this.getPaginatedDataForDetailedPage(0, course.toLowerCase(), true, this.state.sortBy);
    };

    getPaginatedDataForDetailedPage = (pageNo = 0, courseName, forFilters, sortBy, stateUpdate) => {
        this.createTagsFromUrl();
        if (isEmpty(this.props.courseDetailListing) || forFilters || stateUpdate) {
            // dispatch the action to get the detailed data for the listing with pagination.
            let tagParams = getTagIdParams( courseName, this.props.globalExamList );
            const courseObj = {
                pageNo,
                courseName,
                type: 'init',
                categoryFacet: viewAllKeys[requiredProductName],
                queryParam: this.state.queryParam || `&${this.props.location.search.substring(1)}`,
                sortBy,
                sponsoredCategory,
                tagParams,
            };
            this.props.getPaginatedDataForCourseDetail(courseObj).then(() => {
                this.setState({
                    loading: false,
                    showFilterModal: false,
                    showProductLoader: false,
                    dataLoader: false,
                    tagArr: this.createTagsFromUrl().tagArr
                });
            });
            pageN = 0;
        }
    };

    getPaginatedDataOnScroll = (courseName) => {
        const totalCount = this.props.courseDetailListing.data.count || 0;
        if (totalCount <= (pageN +1)* pageSizeForDetailListing) {
            return;
        }
        this.setState({
            listingLoader: true
        });
        pageN++;
        let tagParams = getTagIdParams( courseName, this.props.globalExamList );
        const courseObj = {
            pageNo: pageN,
            courseName,
            type: 'scroll',
            categoryFacet: viewAllKeys[requiredProductName],
            queryParam: this.state.queryParam || `&${this.props.location.search.substring(1)}`,
            sortBy: this.state.sortBy,
            sponsoredCategory,
            tagParams,
        };
        this.props.getPaginatedDataForCourseDetail(courseObj).then(() => {
            this.setState({
                listingLoader: false
            });
        });
    };

    handleDetailCardClick = (obj) => {
        const category = productKeys[obj.suggestionCat] || '';
        const id = obj.id || '';
        const title = obj.title || '';
        const slugurl = obj.slugURL || '';
        const getUtmFromStore = this.props.utmParams;
        const storeUrl = getPdpUrl(category, id, title, slugurl);

        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
            'product_click',
            getMoengageMetadata({
                source: 'web',
                'id': id,
                'name': title,
                'category': category,
                'list': category,
                'brand': pageName[1],
                'variant': '-',
                'price': obj['price'],
                'quantity': '1',
                'packageUrl': storeUrl,
                'packageActualPrice': obj.maxPrice,
                'packageDiscountedPrice': obj['price'],
                getUtmFromStore: getUtmFromStore,
                source_screen: ' CourseDetail listing',
                productType: category
            })
        );
        //createStoreFrontPdpUrl
        // window.open(storeUrl);
        // this.props.history.push(getUrlPathObject(storeUrl));
    };

    getNewParamsForAllCategory = (facetKey, value) => {
        const { facetObj, tagArr } = this.state;
        this.setState({
            dataLoader: true
        });
        if (facetKey === 'allExamtype') {
            const selection = value.toLowerCase();
            this.setState({
                tagArr: [],
                facetObj: {}
            });
            this.props.history.push(getUrlPathObject(`/${selection}`));
            this.props.getSearchData(selection).then(() => {
                this.setState({
                    dataLoader: false
                });
            });
        } else {
            let newFacetObj = Object.assign({}, facetObj);
            let newTagArr = tagArr;
            const courseName = this.props.match.params.courseType;
            const productName = this.props.match.params.productType;


            if (!isSet(newFacetObj[facetKey])) {
                newFacetObj[facetKey] = [];
            }
            if (newFacetObj[facetKey].includes(value)) {
                newFacetObj[facetKey] = newFacetObj[facetKey].filter(e => e.toLowerCase() !== value.toLowerCase());
                newTagArr = newTagArr.filter(e => e.toLowerCase() !== value.toLowerCase());
            } else {
                newFacetObj[facetKey].push(value);
                newTagArr.push(value);
            }
            let strNew = '';
            let p;
            for (p in newFacetObj) {
                if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
                    strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
                }
            }
            strNew = strNew.substring(-1);
            this.setState({
                queryParam: strNew,
                tagArr: newTagArr,
                facetObj: newFacetObj
            }, () => {
                this.getPaginatedDataForDetailedPage(0, replaceHyphenWithUnderscore(courseName).toUpperCase(), true, this.state.sortBy);
            });
            this.props.history.push(getUrlPathObject(`/${courseName}/${productName}-study-kit`, `?${strNew.slice(0, -1)}`));
        }
    };



    datalayerHanler = (searchValue) => {

        const { location } = this.props;
        const { pathname } = location;
        const examName = pathname.split('/')[1];
        const productName = pathname.split('/')[2];
        let category = productName.split('-').slice(0, -1).join('-');

        const productCategory = pathname.split('/')[2];
        let ProCategory = productName.split('-').slice(0, -2).join('-');

        let ProductValue = productNames[ProCategory];
        let user_id_cookie = cookie.get("cp_user_id");
        let name = cookie.get('cp_user_name');
        let email = cookie.get('cp_user_email');
        const token = cookie.get('cp_token');


        if (searchValue && typeof (window) != "undefined") {

            let excategory = searchValue.courses;
            let exammCategory = searchValue.data && searchValue.data.packageEsEntity && searchValue.data.packageEsEntity[0] && searchValue.data.packageEsEntity[0].courses;
            if (token) {
                window.dataLayer.push({
                    name: name || "guest",
                    email: email || "",
                    isMobile: getDeviceType() == 'm' ? true : false,
                    loginType: 'email',
                    isLoggedIn: true,
                    examName: examName,
                    pageName: 'Listing',
                    examCategoryName: exammCategory,
                    category: ProductValue,

                })
            }
            else if (!token && !user_id_cookie) {
                window.dataLayer.push({
                    name: "guest",
                    email: "",
                    isMobile: getDeviceType() == 'm' ? true : false,
                    loginType: '',
                    isLoggedIn: false,
                    examName: examName,
                    pageName: 'Listing',
                    examCategoryName: exammCategory,
                    category: ProductValue,

                })
            }
            //event 
            if (count == 1) {
                let payload = {};
                payload.user_email = cookie.get("cp_user_email") || "";
                payload.user_name = cookie.get("cp_user_name") || "";
                payload.source_screen = "";
                payload.examCategory = exammCategory;
                payload.getUtmFromStore = this.props.utmParams;
                trackMoengageEvent("list_page_view", payload);

            }

        }


    }





    handleSortBy = (sortBy) => {
        const courseName = this.props.match.params.courseType;
        // sortBy = ASC or RELEVANCE
        this.setState({
            sortBy,
            showProductLoader: true
        }, () => {
            this.getPaginatedDataForDetailedPage(0, replaceHyphenWithUnderscore(courseName).toUpperCase(), true, sortBy);
        });
    };

    setSelectedSortCategoryChange = (category) => {
        this.setState({
            selectedSortCategory: category
        })
    }

    removeTag = (item) => {
        const { tagArr, facetObj } = this.state;
        const { location } = this.props;
        const { pathname } = location;
        const courseName = pathname.split('/')[1];
        const tempVar = pathname.split('/')[2];
        let tempArr = tempVar.split('-');
        tempArr.pop();
        tempArr.pop();
        const courseCategory = tempArr.join('-');
        let newFacetObj = {};
        const tempTagArr = [];
        tagArr?.forEach(item => {
            tempTagArr.push(item);
        })
        this.setState({
            dataLoader: true
        });

        if (tempTagArr.indexOf(item) > -1) {
            tempTagArr.splice(tempTagArr.indexOf(item), 1);
            const facetKeys = Object.keys(facetObj);
            facetKeys.map(e => {
                newFacetObj[e] = facetObj[e].filter(f => {
                    return f.toLowerCase() !== item.toLowerCase();
                });
            });
        }
        let arr3 = [];
        let finalString = '';

        let splitArr = location.search.substring(1).split('&');
        splitArr.map(str => {
            let newAr = str.split('=');
            const cat = newAr.shift();
            let finalAr = (newAr[0] || "").split(',');

            finalAr = finalAr.filter(val => {
                let decodedUri = decodeURI(val).toLowerCase();
                return decodedUri !== item.toLowerCase();
            });

            const string2 = finalAr.join(',');

            if (string2.length) {
                arr3.push(cat + '=' + string2);
            }
        });
        if (arr3.length > 1) {
            finalString = arr3.join('&');
        } else {
            if (isSet(arr3[0])) {
                finalString = arr3[0];
            }
        }
        this.setState({
            queryParam: finalString,
            tagArr: tempTagArr,
            facetObj: newFacetObj,
            showFilterAppliedDot: tempTagArr.length && true,
            selectedFilterCategory: tempTagArr.length ? 'Filter Applied' : 'No Filter',

        }, () => {
            this.getPaginatedDataForDetailedPage(0, courseName, true, this.state.sortBy);
        });
        this.props.history.push(getUrlPathObject(`/${courseName}/${courseCategory}-study-kit`, `?${finalString}`));
    };

    clearAllFilters = () => {
        const { history, location } = this.props;
        const { pathname } = location;
        const courseCategory = pathname.substring(1).split('/')[1];
        const courseName = pathname.substring(1).split('/')[0];
        history.push(getUrlPathObject(`/${courseName}/${courseCategory}`));
        this.setState({
            dataLoader: true,
            queryParam: '',
            tagArr: [],
            facetObj: {},
            showFilterAppliedDot: false,
            selectedFilterCategory: 'No Filter',
        }, () => {
            this.getPaginatedDataForDetailedPage(0, courseName, true, this.state.sortBy);
        });
        fireMoengageEvent("filter_reset", {}, "filter");
    };



    validateCondition(requiredProductName, courseName) {
        courseName = courseName.split(" ").join("");
        return !!(validateProductName(requiredProductName) && validateCourseName(courseName));
    }

    render() {
        const { location, courseDetailListing, match } = this.props;
        const { listingLoader, showFilterModal, showSortModal, selectedFilterCategory,selectedSortCategory, showFilterAppliedDot, showSortByAppliedDot, dataLoader, tagArr, facetObj } = this.state;
        const { params } = match;
        const { courseType } = params;
        const { productType } = params;
        const { pathname } = location;
        const courseName = pathname.split('/')[1];
        const productName = pathname.split('/')[2];
        requiredProductName = productName.split('-').slice(0, -2).join('-');

        if (courseDetailListing && courseDetailListing.data && this.state.facetsList) {
            count++;
            this.datalayerHanler(courseDetailListing)

        }

        let offerType = productNames[requiredProductName];

        let helmetDescription = "";
        let helmetTitle = `${capitalizeWords(removeHyphensAndInsertSpaces(courseType))} ${capitalizeWords(removeHyphensAndInsertSpaces(productType))} at ${pageName[1]}`
        if (offerType === 'Videos') {
            helmetDescription = `Preparing for ${capitalizedString(courseType)} exam online? Study online for ${capitalizedString(courseType)} exam with video courses from ${pageName[1]} with subject experts. Get live sessions with toppers.`
        }
        else if (offerType === 'Online Live Classes') {
            helmetDescription = `Are you a ${capitalizedString(courseType)} exam aspirant? Get ${capitalizedString(courseType)} exam Online coaching from subject experts. Join live interactive classes from faculties to ace selection.`;
        }
        else if (offerType === 'Mock Tests') {
            helmetDescription = `Practice for ${capitalizedString(courseType)} exam online with online ${capitalizedString(courseType)} test series by ${pageName[1]}. Get daily mocks, assessment, solved question papers to assess your preparation`;
        }
        else if (offerType === 'E-Books') {
            helmetDescription = `Get Best e-books & study material for ${capitalizedString(courseType)} exam online at ${pageName[1]}.  Read & practice with latest editions of most important books to crack ${capitalizedString(courseType)}exam.`;
        }


        return (
            <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
                {!this.props.isPaytmMini && <div className={"dfd-container"}>
                    <DfpAdPdp category={courseName || ""}/>
                </div>}
                <CustomHelmet
                    title={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaTitle ? this.props.seoData[pathname].metaTitle : helmetTitle}
                    description={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaDescription ? this.props.seoData[pathname].metaDescription : helmetDescription}
                    url={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].canonical || this.props.req_url}
                    keywords={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].keywords}
                    noFollow={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noFollow}
                    noIndex={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noIndex} />

                {
                    this.validateCondition(requiredProductName, courseName) ? (
                        <div>
                            <BreadcrumbSchema
                                items={[
                                    {
                                        title: 'Home',
                                        url: '/'
                                    },
                                    {
                                        title: capitalizedString(courseDetailListing.title || courseType),
                                        url: `/${courseType}-study-materials`
                                    },
                                    {
                                        title: productNames[requiredProductName]
                                    }
                                ]}
                            />
                            {(requiredProductName === "live-classes" || requiredProductName === "videos") ? 
                            <CarouselSchema 
                                data = {courseDetailListing?.data?.packageEsEntity} 
                                isOnlyLiveClasses={true} 
                                origin = {this?.props?.origin}
                                schemaType = "Carousel"
                            /> : ""}
                            <div className={'breadcrumb-container'}>
                                <BreadCrumb routeData={[{ 'name': 'Home', 'target': '/' }, {
                                    'name': capitalizedString(courseDetailListing.title || courseType),
                                    'target': `/${courseType}-study-materials`
                                }, { 'name': this.props.seoData[pathname]?.extra1 || productNames[requiredProductName], 'target': '' }]} />
                            </div>
                            <div className={'header-container'}>
                                <h1 className={'listing-header'}>{this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeading?.split(":").join(" : ")?.split(",").join(", ")  || (`${courseDetailListing.title || courseType} ${productNames[requiredProductName]} ${new Date().getFullYear()}`)}</h1>
                                {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeadingDescription && <div className="headingdescription seoHeadingDescription">{this.props.seoData[pathname].pageHeadingDescription}</div>}
                            </div>

                            {
                                courseDetailListing && courseDetailListing.data && courseDetailListing.data.packageEsEntity && this.state.facetsList? (
                                    <div className={'listing-data-container'}>
                                        <div className={'filter-parent-container col m3 fcwidth'}>
                                            <SearchBlock
                                                SuffixValue={`${removeHyphensAndInsertSpaces(this.props.match.params.productType)}`}
                                                courseType={this.props.match.params.courseType}
                                                productType={this.props.match.params.productType}
                                                examPages={this.state.examPages}
                                                facetsList={this.state.facetsList}
                                                showSearch={false}
                                                filterHeading={capitalizedString(courseName)}
                                                currentItem={courseName}
                                                getNewParamsforAllCategory={this.getNewParamsForAllCategory}
                                                type={'courseDetail'}
                                                selectedTags={tagArr}
                                                getUtmFromStore={this.props.utmParams}
                                            />
                                        </div>
                                        <div className={'col m9 fccontwidth'}>
                                            {
                                                dataLoader ? (
                                                    <>
                                                        <CustomSkeleton />
                                                    </>
                                                ) : (
                                                    <>
                                                        {tagArr.length > 0 &&
                                                            <div className={'tag-container'}>
                                                                <FilterTags tagArr={tagArr} removeTag={this.removeTag} />
                                                                <div className={'clear-all'}
                                                                    onClick={this.clearAllFilters}>Clear All
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !courseDetailListing.data.packageEsEntity.length || !this.state.facetsList? (
                                                                <NoResultsFound
                                                                    handleOnClick={this.clearAllFilters} 
                                                                    allCategoryData={this.props.allTrendingData?.data?.hotSellingPackages}
                                                                    handleDetailCardClick={this.handleDetailCardClick}
                                                                    />) : (
                                                                <>
                                                                    <BottomScrollListener
                                                                        offset={600}
                                                                        onBottom={() => this.getPaginatedDataOnScroll(courseName)}
                                                                    >
                                                                        <ProductTypeListing

                                                                            openDetailFilters={this.setExamAsCurrentCategoryAndOpenFilter}
                                                                            facetsList={this.state.facetsList}
                                                                            tagArr={tagArr}
                                                                            handleApply={this.handleFilterApply}
                                                                            showShortFiltersForDetail={true}
                                                                            allCategoryData={courseDetailListing.data}
                                                                            packageCount={courseDetailListing.data.count}
                                                                            handleDetailCardClick={this.handleDetailCardClick}
                                                                            heading={`${capitalizedString(courseType)} ${productNames[requiredProductName]}`}
                                                                            sortBy={true}
                                                                            handleSortBy={this.handleSortBy}
                                                                            sortByValue={this.state.sortBy}
                                                                            showLoader={this.state.showProductLoader}
                                                                            getUtmFromStore={this.props.utmParams}
                                                                            title={courseDetailListing.title}
                                                                            useTitle={`${courseDetailListing.data.count} ${productNames[requiredProductName]} for ${capitalizedString(courseDetailListing.title || courseType)}`}
                                                                            hideSortByFilter = {true}
                                                                        />
                                                                        {
                                                                            listingLoader &&
                                                                            <div style={{ 'margin': '20px' }}>
                                                                                <CustomLoader buttonSpinner={true} />
                                                                            </div>
                                                                        }
                                                                    </BottomScrollListener>
                                                                </>
                                                            )

                                                        }
                                                        {courseDetailListing?.data?.packageEsEntity?.length ? <div className={'filter-sort-wrapper'}>
                                                            <div className={'only-mobile sort-btn'}
                                                                onClick={this.handleSortButton}>
                                                                <div className={'sort-btn-wrapper'}>
                                                                    <span className='filter-sort-btn-icon'>
                                                                        <img src={'/images/sort-bottom-icon.svg'} alt="sort" title='sort' />                                                                    {
                                                                            // tagArr.length ? (
                                                                            //     <span className={'dot-filter'}></span>) : (
                                                                            //     <span></span>)

                                                                        }
                                                                        <span className={'filter-name'}>Sort By</span>
                                                                    </span>
                                                                    <span className='filter-sort-btn-label'>
                                                                        <span className='filter-selected-category-wrapper'>
                                                                            {showSortByAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                                                            <span className='selected-filter-category'>{selectedSortCategory}</span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={'only-mobile filter-btn'}
                                                                onClick={this.handleFilterButton}>
                                                                <div className={'filter-btn-wrapper'}>
                                                                <span className='filter-sort-btn-icon'>
                                                                        <img src={'/images/filter-bottom-icon-new.svg'} alt="filter" title='filter' />                                                                    {
                                                                            // tagArr.length ? (
                                                                            //     <span className={'dot-filter'}></span>) : (
                                                                            //     <span></span>)

                                                                        }
                                                                        <span className={'filter-name'}>Filters</span>
                                                                    </span>
                                                                    <span className='filter-sort-btn-label'>
                                                                        <span className='filter-selected-category-wrapper'>
                                                                            {showFilterAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                                                            <span className='selected-filter-category'>{selectedFilterCategory}</span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                        {showFilterModal && 
                                                            <NewMobileFilter
                                                                visible={showFilterModal}
                                                                onCancel={this.hideFilterOnMobile}
                                                                onOk={this.onOk}
                                                                facetsList={this.state.facetsList}
                                                                handleApply={this.handleFilterApply}
                                                                facetsObject={filterObj}
                                                                resetFilter={this.resetFilter}
                                                                type={2}
                                                                handleRadio={this.handleRadio}
                                                                selectedTags={tagArr}
                                                                facetObj={facetObj}
                                                                courseCategory={courseName}
                                                                variant={'courseDetail'}
                                                                currentActiveFilter={this.state.currentActiveFilter}
                                                            />
                                                        }
                                                        {showSortModal &&
                                                            <NewSortByMobileFilter
                                                                handleSortBy = {this.handleSortBy}
                                                                handleSortButton = {this.handleSortButton}
                                                                setSelectedSortCategory = {this.setSelectedSortCategoryChange}
                                                                selectedSortCategory = {this.state.selectedSortCategory}
                                                            />
                                                        }
                                                    </>
                                                )}

                                        </div>
                                    </div>
                                ) : (
                                    <CustomLoader />
                                )
                            }


                        </div>
                    ) : (
                        <div style={{ 'marginTop': '80px', 'height': '90vh' }}>
                            <NotFoundStatic />
                        </div>
                    )
                }

                {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].bottomContent &&
                           <div className="bottomContentContainer">
                           <div className="bottomContent"
                             dangerouslySetInnerHTML={{
                               __html: this.props.seoData[pathname].bottomContent
                             }}>
             
                           </div>
                         </div>}

                {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].faqs && this.props.seoData[pathname].faqs.length >= 1 && checkProperties(this.props.seoData[pathname].faqs[0]) &&
                    <div className="faqcontainersearch"> <Faq faqJson={this.props.seoData[pathname].faqs} />
                    </div>}

                {
                    this.state.examPages && this.state.examPages.length >= 1 && <div className="related-category-mobile">
                        <div className="title-seo">Exam Pages</div>
                        <SeoSectionMobile
                            data={this.state.examPages}
                            examCat={""}
                            subCategory={true}
                            // currentItem={examName}
                            courseDetailPage={true}
                            isExamtypes={true}
                            isRelatedCategory={false}
                            SuffixValue={"Online Coaching"}
                        />

                    </div>
                }
                {/* Related Category */}
                {
                    this.examCategoryArray && <div className="related-category-mobile">
                        <div className="title-seo">Related Category</div>
                        <SeoSectionMobile
                            data={this.examCategoryArray}
                            courseType={this.props.match.params.courseType}
                            productType={this.props.match.params.productType}
                            examCat={""}
                            courseDetailPage={true}

                            isRelatedCategory={false}
                            SuffixValue={`${removeHyphensAndInsertSpaces(this.props.match.params.productType)}`}
                        />

                    </div>
                }


            </div>
        );
    }
}

CourseDetailListing.fetching = ({ dispatch, match, location }) => {
    return [
        dispatch(getPaginatedDataForCourseDetail({
            pageNo: 0,
            courseName: match.params.courseType || 'banking',
            type: 'init',
            categoryFacet: viewAllKeys[match.params.productType],
            queryParam: `&${location.substring(location.indexOf('?') + 1)}`,
            sortBy: 'RELEVANCE',
            sponsoredCategory
        }
        ))
    ];
};

const mapStateToProps = (state) => {
    const { courseDetailListing } = state;
    let utmParams = state.referrer || {};
    let req_url = state.req_url;
    let seoData = state.seoData;
    let isPaytmMini = state.isPaytmMini;
    const examList = state.header.examList;
    let  allTrendingData = state.header.allTrendingData;
    const origin = state?.header?.origin || "";
    const globalExamList = state?.header?.globalExamList;
    return {
        courseDetailListing,
        examList,
        utmParams,
        req_url,
        seoData,
        isPaytmMini,
        allTrendingData,
        origin,
        globalExamList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPaginatedDataForCourseDetail: (obj) => dispatch(getPaginatedDataForCourseDetail(obj)),
        clearCourseDetailData: () => dispatch(clearCourseDetailData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailListing);

import React, { useState,useEffect } from "react";

const DFNumpad = ({
  inputValue = '',
  currentQues,
  onChange,
  btnDisabled = ()=>{}
}) => {
  const [state, setState] = useState(inputValue);

  const numPadKeys = [
    "1",
    "2",
    "3",
    "backspace",
    "4",
    "5",
    "6",
    "-",
    "7",
    "8",
    "9",
    ".",
    "",
    "0",
    "",
  ];
  const isNonDigit = (key) => {
    const pattern = /[^0-9]/g;
    return pattern.test(key);
  };

  useEffect(() => {
    if (inputValue) {
      setState(inputValue)
    } else {
      setState("")
    }
  }, [currentQues])

  useEffect(() => {
    if (inputValue.includes(".")) {
      const afterDec = inputValue.split(".")
        btnDisabled({dirty:!afterDec[1].length,message:"Not a valid Decimal Number"})
    } else {
      btnDisabled({dirty:false,message:""})
    }
    setState(inputValue)
  }, [inputValue])

  const handleKeyPress = (key) => {
    let newValue = state;
    if (state.length < 20 && key !== "backspace") {
      if (key === ".") {
        if (inputValue === "-") {
          newValue = newValue + "0"
        }
        if (inputValue.length) {
          if (!inputValue.includes(".")) {
            newValue = newValue + key
          }
        } else {
          newValue = "0."
        }
      }
      else if (key === "-") {
        const arr = inputValue.split("");
        const index = arr.indexOf(".");
        if (index > -1) {
          arr.splice(index, 1);
        }
        const val = arr.reduce((accumulator, a) => Number(accumulator) + Number(a), 0)
        if (inputValue.length) {
          if (!inputValue.includes("-")) {
            if (val > 0) {
              newValue = key + inputValue;
            } else {
              newValue = "-0"
            }
          }
        } else {
          newValue = "-"
        }
      }
      else if (key === "0") {
        if (inputValue === "0") {
          newValue = "0"
        }
        else if (inputValue !== "-0" && inputValue !== "0") {
          if (inputValue.includes(".")) {
            const afterDec = inputValue.split(".")
            if (afterDec[1].length < 3) {
              newValue = inputValue + key;
            }
          } else {
            newValue = inputValue + key;
          }
        }
      }
      else if (key !== "0" && inputValue.includes(".")) {
        const afterDec = inputValue.split(".")
        if (afterDec[1].length < 3) {
          newValue = inputValue + key;
        }
      }
      else {
        newValue = newValue + key
      }
    } else if (key === "backspace") {
      newValue = inputValue.substring(0, inputValue.length - 1);
    }

    setState(newValue)
    onChange(newValue);
  };
 
  return (
    <>
      <div className="fitbAnswer" readOnly style={{ width: "300px", pointerEvents: "none" }}>{state}</div>
      <div className="numpad-container">
        <div className="numpad-content">
          {numPadKeys.map((key, id) => (
            <div
              key={id}
              className={`numpad-item ${isNonDigit(key) || !key ? "non-digit" : ""
                }`}
              onClick={() =>  handleKeyPress(key)}
            >
              {key == "backspace" ? <img src="/images/numpad-cross.svg"/> : key}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DFNumpad;

import React from "react";

const AProgressBar = ({progress,color})=>{
    return (
        <div className={`a-progressbar  ${color?color:''}`}>
            <div className={`a-progress`} style={{width: `${progress}%`}}></div>
        </div>

    )
}
export default AProgressBar;
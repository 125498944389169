import React, { useEffect, useState } from 'react';
import { getLiveTestActionText, getLiveTestDateTimeObj, getLiveTestRemainingTime, getLiveTestState, getStateUpdateTime, useLiveTestAction } from './liveTestUtils';
import CatalogCard from '../../../../CatalogCard';
import { formatDateTime } from '../../../../dateUtils';

const LiveTestCard = (props) => {
    const {
        testPackageInfo,
        item,
        test,
        event_name,
        source_screen,
        moe_data,
        testPortalOpened
    } = props

    const [liveTestSchedule, setLiveTestSchedule] = useState({});
    const [liveTestActionText, setLiveTestActionText] = useState("");
    const [liveTestStatusObj, setLiveTestStatusObj] = useState({});
    const [liveTestRemainingTime, setLiveTestRemainingTime] = useState("");
    const [currentTime, setCurrentTime] = useState(Date.now());

    const [handleLiveTestAction] = useLiveTestAction(testPortalOpened);
    
    let handleButtonClick = (reattempt) => {
        if(!reattempt && liveTestStatusObj?.buttonClass === "liveClassDisabled") return;
        else handleLiveTestAction({testData : test, quizStatus : liveTestStatusObj?.buttonText || "Coming Soon", reattempt : reattempt, event_name : reattempt ? "reattempttest_listing_clicked" : event_name, source_screen : source_screen, moepayload : moe_data});
    }

    useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime(Date.now());
    }, [1000]);

    return () => { clearInterval(interval) }
  },[]); 

  useEffect(() => {
    if(test) {
      let testStateObj = getLiveTestState(test);
      let liveTestActionBtn = getLiveTestActionText(test);
      let liveTestSchedule = getLiveTestDateTimeObj(test?.testData[0]?.fixedMockTestStartTime, test?.testData[0]?.fixedMockSubmitTime, test?.testData[0]?.attemptBeforeTime)
      setLiveTestStatusObj(testStateObj);
      setLiveTestActionText(liveTestActionBtn)
      setLiveTestRemainingTime(getLiveTestRemainingTime(Math.min(Date.now(), test?.testData?.[0]?.fixedMockSubmitTime), test?.testData?.[0]?.fixedMockResultTime));
      setLiveTestSchedule(liveTestSchedule);
    }
  },[currentTime]); 

    return (
        <div
        key={item.id}
        className={`catalog-video liveTestWRapInner ${
            testPackageInfo.expired && test.status !== "COMPLETED"
            ? "expired-package"
            : ""
        } ${
            testPackageInfo.expired || test.status == "COMING_SOON"
            ? "package-disabled"
            : ""
        }  `}
        >
            <CatalogCard
                header={
                    <div className="test-cutMarks">
                        {item?.title}
                    </div>
                }
                text={
                <>
                    <div className="test-info liveTestInfo">
                        <div className="test-info-details">
                        <>
                            {item?.noOfQuestions ? <>
                                <span> {item?.noOfQuestions} Questions</span>
                                <span>|</span> 
                            </> : ""}
                            {item?.timeLimit ? <span>{item?.timeLimit / 60} Minutes</span> : ""}
                        </>
                        </div>
                        <div className="test-actions">
                        {test.status === "COMPLETED" && Date.now() > item?.fixedMockResultTime && item?.resultPublished &&
                            <div
                                className={`test-info-action`}
                                onClick={() => handleButtonClick("REATTEMPT")}
                            >
                                <span className={`not_started test-btn`}>
                                Re-Attempt
                                </span>
                            </div>
                        }
                    
                
                        {liveTestStatusObj?.status === "Result Awaited" && liveTestRemainingTime && (
                            <div className="liveTest-timer">{liveTestRemainingTime}</div>
                        )}
                        
                        {liveTestStatusObj?.status ? <div
                            className={`test-info-action ${liveTestStatusObj?.buttonClass}`}
                            onClick={() => handleButtonClick()}
                        >
                            {liveTestStatusObj?.status === "Start Awaited" ? <div className={"liveTestTollTipText"}>{`This test will be enabled after ${getStateUpdateTime(test?.testData?.[0]?.fixedMockResultTime)}.`}</div> : ""}
                            {liveTestActionText?.actionItem}
                        </div> : ""}
                        {liveTestStatusObj?.status === "Scheduled On" && (
                            <div className="liveTest-DateTime">{`${liveTestSchedule?.startDate || ""} | ${liveTestSchedule?.startsAt || ""}`}</div>
                        )}
                        </div>
                    </div>
                    <div>
                        {
                            item?.commingSoonDate ? (
                            <span>
                                Mock Live on{" "}
                                {formatDateTime(
                                item?.commingSoonDate || "",
                                "DD MMM YYYY"
                                )}
                            </span>
                            ) : ""
                        }
                    </div>
                </>
                }
                liveTest={item?.liveTest || false}
                testCategory={item?.testCategory || 2}
                showHowItWorksCTA={false}
                liveTestCutoff={item?.cutOffMarks || 0}
                liveTestPackage={testPackageInfo?.liveTest || false}
            />
        </div>
    )
}

export default LiveTestCard;

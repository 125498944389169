import React, { useEffect } from "react";
import DFNumpad from "./DFNumpad";

const DF = ({ value,currentQues, onChange,btnDisabled }) => {
  return (
    <div>
      <DFNumpad inputValue={value} currentQues={currentQues} btnDisabled={btnDisabled} onChange={onChange} />
    </div>
  );
};

export default DF;

import React from 'react';
import CustomButton from '../CustomButton';
import GoogleLogin from 'react-google-login';
import FacebookLoginRender from 'react-facebook-login/dist/facebook-login-render-props';
import { FACEBOOK_ID, GOOGLE_CLIENT_ID, pageName } from '../../../../constants/appConfig';
import useOnboardingAPI from './useOnboardingAPI';
import OnboardingOtpInput from './OnboardingOtpInput';
import { autoReadSMS, validateInput } from '../../../data/utils/helpers';
import { updateUserDetails } from '../../../data/ducks/header/actions';
import { onBoardingStepStates } from './onboardingConstants';

export const getOnboardingScreen = ({ ...args }) => {

    const {
        currentStep,
        setCurrentStep,

        //States
        showSignupForm,
        showPassError,
        showPasswordError,
        showPassword,
        setShowPassword,
        otpSentFlag, 
        setOtpSentFlag,
        loginPanelErrors, 
        setLoginPanelErrors,
        loading, 
        setLoading,

        //functions
        closeModal,
        handleUserLogin,
        handleEmailOnBlur,
        handlePasswordOnBlur,
        handlePasswordOnFocus,
        responseGoogleSuccess,
        responseGoogleFail,
        responseFacebook,
        dispatch,
        handleMobileOTPVerify,
        handleForgotPasswordBtnClick,
        handleUpdateUserCookie,
        invokeTrueCaller,

        // API States
        mobileSignupDetails, 
        setMobileSignupDetails,
        emailSignupDetails, 
        setEmailSignupDetails,

    } = {...args};

    const {sendMobileOTP, registerNewUser, updateUserProfile, emailUserLogin} = useOnboardingAPI();


    const handleMobileOTPSend = async (payload) => {
        setLoading(true);
        let inputFields = {
            phone : [payload],
        }
        const errors = validateInput(inputFields);
        if(errors?.phone?.some(error => error)) { 
            setLoginPanelErrors({...loginPanelErrors, phone : errors?.phone});
            setLoading(false);
        }
        else {
            setLoginPanelErrors({...loginPanelErrors, phone : []});
            let response = await sendMobileOTP(payload);
            if(response?.success) {
                setOtpSentFlag(true);
                autoReadSMS(smsReceivedCallback);
                setCurrentStep(onBoardingStepStates.mobile_otp_verify);
            }
            else {
                setLoginPanelErrors({...loginPanelErrors, phone : [response?.message]});
            }
            setLoading(false);
        }
    }

    const smsReceivedCallback = (code) => {
        setMobileSignupDetails({...mobileSignupDetails, otp : code});
    }

    const handleNewUserRegister = async (payload) => {
        setLoading(true);    
        try {
            const [response] = await Promise.all([
                updateUserProfile(payload),
                dispatch(updateUserDetails({ name: payload?.name })),
                handleUpdateUserCookie("", "", payload?.name),
            ]);
            if (response?.success) {
                setLoading(false);
                closeModal();
            } else {
                setLoading(false);
                console.error("mobile register fail --> ", response?.message);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error during registration:", error);
        }
    };

    const handleEmailLogin = async (payload) => {

        setLoading(true);
        let inputFields = {
            email : [payload.email],
        }
        const errors = validateInput(inputFields);
        if(errors?.email?.some(error => error)) { 
            setLoginPanelErrors({...loginPanelErrors, email : errors?.email});
            setLoading(false);
            return;
        }
        else {

            setLoginPanelErrors({...loginPanelErrors, email : []});

            let response = await emailUserLogin(payload);
            if(response?.success) {
                
                let loginDetails = {...response?.data};
                
                handleUserLogin(loginDetails);
                setLoading(false);
                closeModal();
            }
            else {
                setLoginPanelErrors(prevErrors => ({
                    ...prevErrors,
                    email : [],
                    password :[response?.message]
                }));
                setLoading(false);
            }
        }
    }


    const handleNewPasswordSet = async (emailSignupDetails) => {
        setLoading(true);
        let inputFields = {
            password : [emailSignupDetails?.newPassword, emailSignupDetails?.confirmNewPassword]
        }
        const errors = validateInput(inputFields);
        if(errors?.password?.some(error => error)) { 
            setLoginPanelErrors({...loginPanelErrors, password : errors?.password});
            setLoading(false);
            return;
        }
        else if(emailSignupDetails?.newPassword !== emailSignupDetails?.confirmNewPassword){
            setLoginPanelErrors({...loginPanelErrors, password : ["", "Passwords should be same"]});
            setLoading(false);
            return;
        }
        else {
            setLoginPanelErrors({...loginPanelErrors, password : []});
            const response = await registerNewUser(emailSignupDetails , emailSignupDetails?.forgotPasswordFlag);
            if (response?.success) {
                setEmailSignupDetails(prevDetails => ({
                    ...prevDetails,
                    otpSent: false,
                    forgotPasswordFlag: false,
                    newPassword: "",
                    confirmNewPassword: "",
                }));
                setShowPassword({
                    currentPassword : false,
                    confirmPassword : false,
                });
                setCurrentStep(onBoardingStepStates.email_signin);
            } else {
                setLoginPanelErrors({...loginPanelErrors, password : ["", response?.message]});
            }
            setLoading(false);
        }
    }

    const getResendBtnAction = (currentStep) => {
        if(currentStep === onBoardingStepStates.mobile_otp_verify) {
            handleMobileOTPSend(mobileSignupDetails?.phone);
        }
        else if (currentStep === onBoardingStepStates.forgot_password_email_collect) {
            handleForgotPasswordBtnClick(emailSignupDetails, false);
        }
    }

    const handleMobileEdit = (step) => {
        setLoginPanelErrors({
            email : [],
            password : [],
            phone : [],
            otp : [],
        });
        if(step === onBoardingStepStates.signup) {
            setCurrentStep(step);
        }
        else if (step === onBoardingStepStates.forgot_password_email_collect) {
            setEmailSignupDetails(prevDetails => ({
                ...prevDetails,
                otpSent: false,
                forgotPasswordFlag: false,
                newPassword: "",
                confirmNewPassword: "",
            }));
            setCurrentStep(step);
        }
    }

    const handleKeyPressLogin = (e, payload = {}) => {
        if (e.keyCode === 13) {
            handleEmailLogin(payload)
        }
      };

    switch (currentStep) {
        case onBoardingStepStates.signup:
            return (
                <div className="right-panel-login">
                    <div className="rightpanel-header">
                    </div>
                    <div className="login-and-form">

                        <div>
                            <div className="right-panel-form-section">

                                <div className='signup-header-wrap'>
                                    <div className='signup-heading'>Sign Up</div>
                                    <div className='signup-subheading'>Please enter your mobile number to continue.</div>
                                </div>
                                <div className="inputWrap-onboard">
                                    <span className="floating-label">Mobile Number</span>
                                    <input
                                        data-testid="email-input-box"
                                        type={"mobile"}
                                        onChange={(e) => setMobileSignupDetails({...mobileSignupDetails, phone : e.target.value?.trim()?.substring(0, 10) })}
                                        placeholder="Enter 10 digit number"
                                        onBlur={handleEmailOnBlur}
                                        size="default"
                                        className={
                                            loginPanelErrors?.phone?.[0] ? "wrongpass a-input" : "a-input"
                                        }
                                        value={mobileSignupDetails?.phone}
                                    />
                                    <div className={"wrongpass-msg mb-2"}>
                                        {loginPanelErrors?.phone?.[0]}
                                    </div>
                                </div>
                                <div className="nextbtncont">
                                    <CustomButton
                                        type="primary"
                                        className={`bluebtn ripple ${(mobileSignupDetails?.phone?.length < 10) ? "onboardBtnDisable" : ""}`}
                                        buttontext={"Continue"}
                                        loading={loading}
                                        onClick={() => handleMobileOTPSend(mobileSignupDetails?.phone)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dashed-stripe"><span>Or Login with
                        </span>
                        </div>
                        <div className="online-login-btns">
                            <div className="onlinebtntop">
                                <GoogleLogin
                                    render={(renderProps) => (
                                        <div
                                            onClick={renderProps.onClick}
                                            className="login-with-google commanbtn"
                                        >
                                            Google
                                        </div>
                                    )}
                                    clientId={GOOGLE_CLIENT_ID}
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleFail}
                                />
                                <FacebookLoginRender
                                    appId={FACEBOOK_ID}
                                    autoLoad={false}
                                    disableMobileRedirect
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    render={(renderProps) => (
                                        <div
                                            onClick={renderProps.onClick}
                                            className="login-with-fb commanbtn"
                                            style={{ boxShadow: "0px 4px 10px rgb(0 0 0 / 15%)" }}
                                        >
                                            Facebook
                                        </div>
                                    )}
                                />
                                <CustomButton
                                    type="primary"
                                    className={"primary-red-btn commanbtn"}
                                    buttontext={
                                        <span className="email-signin-btn-wrap">
                                            <span className="email-signin-btn-label">Email</span>
                                        </span>
                                    }
                                    loading={false}
                                    onClick={() => setCurrentStep(onBoardingStepStates.email_signin)}
                                />
                            </div>
                            <br />
                            <div className={"wrongpass-msg mb-2"}>
                                {loginPanelErrors?.social}
                            </div>
                            {/* Hidding true caller for now */}
                            {/* <div className="onlinebtntop true-caller-btn">
                                <CustomButton
                                    type="primary"
                                    className={"primary-red-btn commanbtn"}
                                    buttontext={
                                        <span className="email-signin-btn-wrap">
                                            <span className="email-signin-btn-label">Continue with True Caller</span>
                                        </span>
                                    }
                                    loading={false}
                                    onClick={invokeTrueCaller}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            );
        case onBoardingStepStates.email_signin:
            return (
                <div className="right-panel-login">
                    <div className="login-and-form">
                        <div>
                            <div className="right-panel-form-section">
                                <div className="inputWrap-onboard emailloginwrap">
                                <div className="onboardMobileImg">
                    <img src="/images/Onboard-Almost-icon.svg" />
                </div>  
                <div className="signup-header-wrap">
                        <div className="signup-heading">Continue with Email</div>
                    
                    </div>


                                <div className="onbaorPIBox">
                                    <label className="floating-label">Email</label>
                                    <input
                                        data-testid="email-input-box"
                                        type={"email"}
                                        onChange={(e) => {setEmailSignupDetails({...emailSignupDetails, email : e.target.value?.trim()})}}
                                        placeholder="Enter email ID"
                                        onBlur={handleEmailOnBlur}
                                        size="default"
                                        className={loginPanelErrors?.email?.[0] ? "wrongpass a-input" : "a-input"}
                                        value={emailSignupDetails?.email}
                                    />
                                    <div className={"wrongpass-msg mb-2"}>
                                        {loginPanelErrors?.email}
                                    </div>
                                </div>
                               
                                <div className="onbaorPIBox">
                                    <span className="floating-label">Password</span>
                                    <div className="password-eye">
                                        <input
                                            data-testid="password-input-box"
                                            type={showPassword?.currentPassword ? "text" : "password"}
                                            style={{ "margin-bottom": "unset" }}
                                            onChange={(e) => {setEmailSignupDetails({...emailSignupDetails, password : e.target.value?.trim()})}}
                                            onBlur={
                                                showSignupForm
                                                    ? handlePasswordOnBlur
                                                    : () => { }
                                            }
                                            placeholder="Atleast 6 characters"
                                            onFocus={handlePasswordOnFocus}
                                            size="default"
                                            className={
                                                showPasswordError || showPassError
                                                    ? "wrongpass a-input"
                                                    : "a-input"
                                            }
                                            value={emailSignupDetails?.password}
                                            onKeyDown={(e) => {handleKeyPressLogin(e, emailSignupDetails)}}
                                        />
                                        <div
                                            className="eye-pass"
                                            onClick={() => setShowPassword({...showPassword, currentPassword : !showPassword?.currentPassword})}
                                        >
                                            {showPassword?.currentPassword ? (
                                                <img src="/images/show-pass-eye-icon.svg" alt="" />
                                            ) : (
                                                <img src="/images/crossed_eye.svg" alt="" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="forgot-pass">
                                        <a data-testid="forgot-password"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setEmailSignupDetails({...emailSignupDetails, otpSent : false});
                                                setCurrentStep(onBoardingStepStates.forgot_password_email_collect);
                                            }}
                                        >
                                            Forgot Password?
                                        </a>
                                    </div>
                                    <div className={"wrongpass-msg mb-2"}>
                                        {loginPanelErrors?.password}
                                    </div>
                                 </div>
                                
                              
                                </div>
                                {/* <div className="primary-red-btn ripple" onClick={!showSignupForm ? handleUserLogin : handleUserSignup}>{!showSignupForm ? 'LOGIN' : 'SIGNUP'}</div> */}
                                <div className="nextbtncont">
                                    <CustomButton
                                        type="primary"
                                        className={`bluebtn ripple ${(emailSignupDetails?.email?.length < 2 || !emailSignupDetails?.password?.length) ? "onboardBtnDisable" : ""}`}
                                        buttontext={"Submit"}
                                        loading={loading}
                                        onClick={() => {handleEmailLogin(emailSignupDetails)}}
                                    />
                                </div>
                                <div className="singup" data-testid="login-signup-button">
                                    {`New to ${pageName[1]}`}
                                    <a
                                        data-testid="login-signup-button-link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>{
                                            setCurrentStep(onBoardingStepStates.signup)
                                        }}
                                    >
                                        Sign-Up Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case onBoardingStepStates.mobile_otp_verify:
            return (
                <div className="right-panel-login">

                    <div className='signup-header-wrap'>
                        <div className='signup-heading'>OTP Verification</div>

                        <div className='signup-subheading'>Enter the code from the sms we sent to &nbsp;<br/>
                            <span className='otp-subheader-contact-wrapper'>
                                <span className='otp-subheader-contact'>{`+91 ${mobileSignupDetails?.phone}`}</span> &nbsp;
                                <span className='email-edit-icon' onClick={() => handleMobileEdit(onBoardingStepStates.signup)}>Edit</span>
                            </span>

                        </div>

                    </div>
                    <div className='otp-input-wrapper'>
                        <OnboardingOtpInput 
                            otpDetails={mobileSignupDetails}
                            setOtpDetails={setMobileSignupDetails}
                            otpSentFlag={otpSentFlag}
                            setOtpSentFlag={setOtpSentFlag}
                            resendOTP={() => {getResendBtnAction(onBoardingStepStates.mobile_otp_verify)}}
                            receiverType={"phone"}
                            showOtpDurationMsg={false}
                        />
                        <div className={"otp-error-section"}>
                            {loginPanelErrors?.otp?.[0]}
                        </div>
                        
                        <div className="nextbtncont">
                            <CustomButton
                                type="primary"
                                className={`bluebtn ripple ${(mobileSignupDetails?.otp?.length < 6) ? "onboardBtnDisable" : ""}`}
                                buttontext={"Submit"}
                                loading={loading}
                                onClick={() => handleMobileOTPVerify(mobileSignupDetails)}
                            />
                        </div>
                    </div>
                </div>
            );
        case onBoardingStepStates.forgot_password_email_collect:
            return (
                <div className="right-panel-login forgotScreen">

                    <div className='signup-header-wrap'>
                        <div className='signup-heading'>Forgot Password?</div>
                        {emailSignupDetails?.otpSent ? 
                        <div className='signup-subheading'>
                            Please enter the OTP sent to
                            <span className='forgot-password-email-edit-wrap'>{`${emailSignupDetails?.forgotPasswordEmail} `}
                            <span className='email-edit-icon' onClick={() => handleMobileEdit(onBoardingStepStates.forgot_password_email_collect)}></span>
                            </span>
                        </div>
                        : <div className='signup-subheading'>Enter the email address associated with your account and we'll send you an OTP to verify it's you </div>}
                    </div>
                    <div className='otp-input-wrapper'>
                        {emailSignupDetails?.otpSent ?

                            <>
                                <div className='otp-input-wrapper'>
                                    <OnboardingOtpInput 
                                        otpDetails={emailSignupDetails}
                                        setOtpDetails={setEmailSignupDetails}
                                        resendOTP={() => {getResendBtnAction(onBoardingStepStates.forgot_password_email_collect)}}
                                        otpSentFlag={otpSentFlag}
                                        setOtpSentFlag={setOtpSentFlag}
                                        receiverType={"email"}
                                        showOtpDurationMsg={false}
                                    />
                                    <div className={"otp-error-section"}>
                                        {loginPanelErrors?.otp?.[0]}
                                    </div>
                                </div>
                            </> : 
                            <>
                                <span className="floating-label">Email</span>
                                <input
                                    data-testid="email-input-box"
                                    type={"email"}
                                    onChange={(e) => setEmailSignupDetails({...emailSignupDetails, forgotPasswordEmail : e.target.value?.trim()})}
                                    placeholder="Enter email ID"
                                    onBlur={handleEmailOnBlur}
                                    size="default"
                                    className={
                                        loginPanelErrors?.email?.[0] ? "wrongpass a-input" : "a-input"
                                    }
                                    value={emailSignupDetails?.forgotPasswordEmail}
                                />
                                <div className={"wrongpass-msg mb-2"}>
                                    {loginPanelErrors?.email}
                                </div>
                            </>
                        }
                     
                </div>
                <div className="nextbtncont">
                            <CustomButton
                                type="primary"
                                className={
                                    `bluebtn ripple ${((emailSignupDetails?.otpSent ? 
                                    (emailSignupDetails?.otp?.length < 6 ? "onboardBtnDisable" : "") : 
                                    (!emailSignupDetails?.forgotPasswordEmail?.length ? "onboardBtnDisable" : "")) )}`
                                }
                                buttontext={"Submit"}
                                loading={loading}
                                onClick={() => {handleForgotPasswordBtnClick(emailSignupDetails, emailSignupDetails?.otpSent)}}
                            />
                        </div>
                </div>
            );
        case onBoardingStepStates.create_password:
            return (
                <div className="right-panel-login">
                    <div className="login-and-form">
                     <div className='signup-header-wrap'>
                        <div className='signup-heading'>Create Password</div>

                        <div className='signup-subheading'>
                            {`Enter new password for ${emailSignupDetails?.email}`}
                        </div>

                    </div>
                    
                    <div className='inputWrap-onboard emailloginwrap'>
                        <div className='onbaorPIBox'>
                        <label className='input-label'>Password</label>
                            <div className="password-eye">
                               
                                <input 
                                    className='input-box' 
                                    placeholder='Enter new password' 
                                    type={showPassword?.currentPassword ? "text" : "password"}
                                    onChange={(e) => {setEmailSignupDetails({...emailSignupDetails, newPassword : e.target.value?.trim()})}}
                                    value={emailSignupDetails?.newPassword}
                                />
                                <div
                                    className="eye-pass"
                                    onClick={() => setShowPassword({...showPassword, currentPassword : !showPassword?.currentPassword})}
                                >
                                    {showPassword?.currentPassword ? (
                                        <img src="/images/show-pass-eye-icon.svg" alt="" />
                                    ) : (
                                        <img src="/images/crossed_eye.svg" alt="" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"wrongpass-msg mb-2"}>
                            {loginPanelErrors?.password?.[0]}
                        </div>
                        <div className='onbaorPIBox noMarginBottom'>
                        <label className='input-label'>Confirm Password</label>
                            <div className="password-eye">
                              
                                <input 
                                    className='input-box' 
                                    placeholder='Enter new password' 
                                    type={showPassword?.confirmPassword ? "text" : "password"}
                                    onChange={(e) => {setEmailSignupDetails({...emailSignupDetails, confirmNewPassword : e.target.value?.trim()})}}
                                    value={emailSignupDetails?.confirmNewPassword}
                                />
                                <div
                                    className="eye-pass"
                                    onClick={() => setShowPassword({...showPassword, confirmPassword : !showPassword?.confirmPassword})}
                                >
                                    {showPassword?.confirmPassword ? (
                                        <img src="/images/show-pass-eye-icon.svg" alt="" />
                                    ) : (
                                        <img src="/images/crossed_eye.svg" alt="" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"wrongpass-msg mb-2"}>
                            {loginPanelErrors?.password?.[1]}
                        </div>
                    </div>
                    <div className="nextbtncont">
                    <CustomButton
                        type="primary"
                        className={`bluebtn ripple ${(emailSignupDetails?.newPassword?.length > 5 && emailSignupDetails?.confirmNewPassword?.length > 5 ) ? "" : "onboardBtnDisable"}`}
                        buttontext={"Login"}
                        loading={loading}
                        onClick={() => {handleNewPasswordSet(emailSignupDetails)}}
                    />
                    </div>

                    </div>
                    </div>
               
            );
        case onBoardingStepStates.user_details_collection:
            return (
                <div className="right-panel-login">
                    <div className="signup-header-wrap">
                        <div className="signup-heading">Almost there</div>
                        <div className="signup-subheading">Please enter below details to complete your profile.</div>
                    </div>
                <div className="onboardMobileImg">
                    <img src="/images/Onboard-Almost-icon.svg" />
                </div>

                    <div className='inputWrap-onboard'>
                        <div className='user-detail-input-box-wrap'>
                            <label className='floating-label'>Full Name</label>
                            <input 
                                className='user-detail-input-box' 
                                placeholder='Enter Full Name' 
                                value={mobileSignupDetails?.name}
                                onChange={(e) => {setMobileSignupDetails({...mobileSignupDetails, name : e.target.value})}}
                            />
                        </div>
                        <div className="nextbtncont">
                            <CustomButton
                                type="primary"
                                className={`bluebtn ripple ${(mobileSignupDetails?.name?.trim()?.length < 2) ? "onboardBtnDisable" : ""}`}
                                buttontext={"Submit"}
                                loading={loading}
                                onClick={() => {handleNewUserRegister(mobileSignupDetails)}}
                            />
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};


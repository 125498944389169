/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { loggedInUserMenu, socialUrls, STOREFRONT_URI, pageName } from "../../../constants/appConfig";
import { default as Link } from "../../containers/ConnectedLink";
import {
  fireWishListMoengageEvent,
  isADDA,
  openWidget
} from "../../data/utils/helpers";
import Chevron from "./Chevron";
import CustomAccordion from "./CustomAccordion";
import CustomAccordionSideBar from "./CustomAccordionSideBar";
const handleUserAccountLink = (linkParam) => {
  typeof window !== "undefined" && window.open(`${STOREFRONT_URI}${linkParam}`);
};
const Sidebar = React.memo((props) => {
  const [showAllCourses, toggleCourses] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [setRotate, setRotateState] = useState("accordion__icon");
  useEffect(() => {
    setRotateState(
      showAllCourses === false ? "accordion__icon" : "accordion__icon rotate"
    );
  }, [showAllCourses]);
  
  let courseList = props.allCoursesList;
  if (courseList && courseList.hasOwnProperty("critical")) {
    delete courseList.critical;
  }
  const resetSideBar = () => {
    setActiveMenu("")
  }
  const {globalExamList} = props;
  return props.open ? (
    <>
      <div className="slidebar-wrap animate-left">
        <div className="sidebar-header">
          {!props.isPaytmMini ? (
            props.userName && props.userName.length ? (
              <div class="sidebar-user-detail">
                <div class="sidebar-userpic"><img src="/images/user-login-icon.svg" width='30px' height='30px'/></div>
                <div class="user-detail-wrap">
                  <div class="sidebar-user-name">{props.userName}</div>
                  {isADDA ? 
                    <div class="sidebar-user-email">{props.userEmail != "null" ? props.userEmail : ""}</div> :
                    <div class="sidebar-user-email">{props.userPhone && props.userName !== props.userPhone ? (props.userPhone) : (props.userEmail != "null" ? props.userEmail : "")}</div>
                  }
                </div>
              </div>
            ) : (
              <div className="login-logout-btns" >
                <div className="login-btn" onClick={props.initiateLogin}>Signup/Login</div>
              </div>
            )
          ) : (
            ""
          )}
          <a className="sidebar-close" onClick={props.close}>×</a>
        </div>
        <div className="mbl-exam-wraps">
          <div className="mb-menu-wrap">
          <div class={`mbl-exam-list`} onClick={() => {props.close(); props.history.push("/")}}>
            <CustomAccordionSideBar
              icon={"/images/ic_home_bottom-bar(selected).svg"}
              close={props.close}
              title={"Home"}
              isLink={true}
              MainExams={[]}
              bodyStyle={{ background: "#fff" }}
              customChildClass={"sidebar-item-bg"}
              history={props.history}
              setSideBarActive={() => resetSideBar()}
              activeItem={{}}
              courseList={[]}
              noChevron = {true}
            />
          </div>
            {
              globalExamList && globalExamList.length>0 && globalExamList.map((globalItem, gloabalIndex) => {
                return (
                  <>
                    <div class={`mbl-exam-list ${globalItem.id} ${activeMenu == globalItem.id ? "active" : "close"}`} onClick={() => {globalItem.id === activeMenu ? setActiveMenu("") : setActiveMenu(globalItem.id) }}>
                      <CustomAccordionSideBar
                        icon={globalItem.s3Url}
                        close={props.close}
                        title={globalItem.dpn.toLowerCase()}
                        isLink={true}
                        Sidebar={true}
                        MainExams={globalExamList}
                        bodyStyle={{ background: "#fff" }}
                        customChildClass={"sidebar-item-bg"}
                        history={props.history}
                        setSideBarActive={() => resetSideBar()}
                        activeItem={globalItem}
                        courseList={courseList}
                      />
                    </div>
                  </>)
              })
            }
          </div>
          <div className="otherlinks">
            <div className="mypurchangelink">
              {props.userName && props.userName.length && (
                <>
                 {isADDA && <Link
                    to="/wishlist"
                    className="bar-item button wishlistbutton"
                    onClick={() => {
                      props.close()
                      fireWishListMoengageEvent('wishlist_viewed', {
                        source_screen:'Wish listing',
                        products_wishlisted_count: props?.wishlistPackageData?.likeCount || 0
                      });
                      }}
                  >
                    <span>My Wishlist</span>
                   <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
                  </Link>}
                  <CustomAccordion
                    close={props.close}
                    title={"My Purchased Courses"}
                    bodyStyle={{ background: "#fff" }}
                    customChildClass={"sidebar-item-bg"}
                    history={props.history}
                    content={loggedInUserMenu.map((e) => {
                      return `${e.name}#${e.url}`;
                    })}
                  />
                  <Link
                    to="/my-orders"
                    className="bar-item button"
                    onClick={() => props.close()}
                  >
                    My Orders
                  </Link>
                  
                  <a
                    className="bar-item button" onClick={() => openWidget()}>Help & Support</a>
                  <div
                    className="bar-item button sidebar-logout"
                    onClick={props.handleLogOut}
                  >
                    Logout
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="slidebar-social">
            <div className="socail-heading">Follow us on</div>
            {isADDA ? 
            <>
            <a><img
              src="/images/sidebar-social1.svg"
              onClick={() => window.open(socialUrls["yt"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social2.svg"
              onClick={() => window.open(socialUrls["fb"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social3.svg"
              onClick={() => window.open(socialUrls["twitter"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social4.svg"
              onClick={() => window.open(socialUrls["linkedIn"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social5.svg"
              onClick={() => window.open(socialUrls["telegram"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            </> : 
            <>
            <a><img
              src="/images/sidebar-social1.svg"
              onClick={() => window.open(socialUrls["yt"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social6.png"
              width='36px'
              onClick={() => window.open(socialUrls["ig"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            <a><img
              src="/images/sidebar-social5.svg"
              onClick={() => window.open(socialUrls["telegram"])}
              alt={pageName[0]}
              title={pageName[0]}
            /></a>
            </>}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div></div>
  );
});

export default Sidebar;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { productKeys } from "../../../constants/appConstants";
import { getPdpUrl, isEmpty } from "../../data/utils/helpers";
import QuickViewProductCard from "../home/QuickViewProductCard";
import CustomLoader from "../library/CustomLoader";
import { checkIsPackageWishList, useWishListedPackageIds } from "../utils";

const PopularCourseList = ({ coursesList = [], loading = false }) => {
  const [quickViewPopup, setQuickViewPopup] = useState(false);
  const { hoveredPackageInfos = {} } = useSelector((state) => state.header);
  const [wishListPackageIds] = useWishListedPackageIds();

  return (
    <div className="search-courses">
      <div className="full-width-title">Popular courses</div>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="all-category-list">
          {coursesList?.map((item, i) => {
            let categories = item["categories"] || "";
            categories = categories.toLowerCase();

            const category = productKeys[categories] || "";
            const id = item.id || "";
            const title = item.cardtitle || item.title || "";
            const slugurl = item.slugURL || "";
            const pdpurl = getPdpUrl(category, id, title, slugurl);
            const isWishList = checkIsPackageWishList(
              wishListPackageIds,
              item?.id
            );
            return (
              <div className="filterListCards" key={item.id}>
                <QuickViewProductCard
                  index={i}
                  id={item.id}
                  cardtitle={item.title}
                  slugURL={item.slugURL}
                  src={item.imgUrl}
                  datasrc={item.imageUrl}
                  categoriesincluded={
                    !isEmpty(item.subCategories)
                      ? item.subCategories.map((e) => e.category)
                      : []
                  }
                  sellingprice={item.price}
                  maximumretailprice={item.maxPrice}
                  categoryname={categories}
                  coupons={{
                    coupons: item.coupons,
                    defaultCoupons: item.defaultCoupons,
                  }}
                  item={item}
                  setQuickViewPopup={(flag) => {
                    setQuickViewPopup(flag);
                  }}
                  pdpUrl={pdpurl}
                  productDetailInfo={
                    hoveredPackageInfos[item.id] &&
                    hoveredPackageInfos[item.id].packageEsEntity[0]
                  }
                  isProductInfoExist={hoveredPackageInfos.hasOwnProperty(
                    item.id
                  )}
                  source_screen="Popular Courses"
                  isWishList={isWishList}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PopularCourseList;

import React, { useEffect, useState } from 'react';
import StarRating from '../starRating';
import fetch from "../../data/utils/fetch";
import { videoPackageUrl } from "../../../constants/appConfig";
import { RATING } from '../../../constants/textConstants'

const RatingModalContent = ({ handleFinalRatingSubmit, handleRatingSkip, moengageEventsForRating }) => {

    const [rating, setRating] = useState({ teacherRating: 0, contentRating: 0 });
    const [hideRateSection, setHideRateSection] = useState(false)
    const [hideDetailedRateSection, setHideDetailedRateSection] = useState(true);
    const [ratingMsg, setRatingMsg] = useState('');
    const [feedbackOptions, setFeedbackOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isSubmitDisable,setIsSubmitDisable] = useState(false)
    const fetchOptionsList = async () => {
        
        try {
           let res = await fetch(`${videoPackageUrl.videoRatingFeedbackOptions}`, "GET")
            if(res?.data){
                setFeedbackOptions([...res.data, 'Others']); 
                setHideRateSection(true);
                setHideDetailedRateSection(false)
            }
        } catch(err){
            console.log('err', err)
        }
    }


    const handleRatingSubmit = async () => {

        if(feedbackOptions.length === 0 && selectedOptions.length === 0){
            handleFinalRatingSubmit(rating, RATING.WITHOUT_DETAILED_FEEDBACK, {})

            if(rating.contentRating <= 4 || rating.teacherRating <= 4){  
                    fetchOptionsList();
            }
        }

        if(feedbackOptions.length > 0 && selectedOptions.length > 0 ){
            handleFinalRatingSubmit(rating, RATING.WITH_DETAILED_FEEDBACK, {ratingMsg: ratingMsg, selectedOptions: selectedOptions.toString()})
        }
    }

    const handleRatingMsg = (e) => {
        setRatingMsg(e.target.value)
    }

    const handleSelectedOptions = (selectedVal) => {
        const index = selectedOptions.indexOf(selectedVal);
        if( index > -1){
            let filtered = selectedOptions.filter(item => item !== selectedVal)
            setSelectedOptions(filtered)
        } else {
            let uniqueValues = [];
            uniqueValues.push(selectedVal)
            setSelectedOptions((prevVal) => Array.from(new Set([...prevVal, selectedVal])))
            moengageEventsForRating('Rating_reason_selected')
        }
        
    }

    useEffect(() => {
        let isDisabled = false
            if(!hideRateSection && hideDetailedRateSection){
                isDisabled =  !rating.contentRating && !rating.teacherRating
            }else if(hideRateSection && !hideDetailedRateSection){
                isDisabled = !selectedOptions?.length
            }
            setIsSubmitDisable(isDisabled)
    }, [hideRateSection,hideDetailedRateSection,rating,selectedOptions])
    return (
        <div className='main-content'>

            <div className="ratebodypop">
                <div className="rateStarSection" style= {{display: hideRateSection ? 'none': 'block'}}>
                    <div className="popBodyHeading"> How would you rate the following? </div>
                    <div className="pdpPopRow">
                        <p>Teacher</p>
                        <StarRating type={'teacherRating'} rating={rating.teacherRating} setRating={setRating} />
                    </div>
                    <div className="pdpPopRow">
                        <p>Content</p>
                        <StarRating type={'contentRating'} rating={rating.contentRating} setRating={setRating} />
                    </div>
                </div>
                <div className="rateFeedbackWrap" style= {{display: hideDetailedRateSection ? 'none': 'block'}} >
                    <div className="popBodyHeading">What did you not like? </div>
                    <div className="rateOptions">
                        <ul>
                            {feedbackOptions.map((val) => {
                                return (
                                    <li key={val} className={selectedOptions.indexOf((val)) >= 0 ? 'rateOptionsActive': 'not-submitted'} onClick={() =>handleSelectedOptions(val)}><button>{val}</button></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="RateMsgText">
                        <input type="text" onChange={handleRatingMsg} placeholder="Leave a feedback" />
                    </div>

                </div>

            </div>
            <div className="pdpPopRowBtn">
                <button onClick={() =>handleRatingSkip(hideDetailedRateSection)} className="popSkipBtn"> Skip</button>
                <button onClick={() =>handleRatingSubmit()} disabled={isSubmitDisable} className={isSubmitDisable ? 'PopSubmitBtnDisabled': 'PopSubmitBtn'}> Submit</button>
            </div>
        </div>
    )
}

export default RatingModalContent;
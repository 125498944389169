import { Cookies } from "react-cookie";
import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";
import { STORE_URI } from "../../constants/appUrls";
import ErrorBoundary from "../components/ErrorBoundary";
import Footer from "../components/Footer";
import NavBar from "../components/Header";
import { resetCacheEnrolledCourses } from "../data/ducks/EnrolledCoursesDataObj/actions";
import { fetchExamList, fetchGlobalExamList, fetchTendingData } from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import { getSeoData, getSeoDetails } from '../data/ducks/seoData/actions';
import "../data/ducks/seoData/reducers";
import "../data/ducks/wishlist/reducers";
import { addRemoveWishList, fetchWishlistedpackages, setWishListedPackage } from "../data/ducks/wishlist/actions";
import fetch from "../data/utils/fetch";
import { getUpdatedNewWishListStore, isADDA, logPageView } from "../data/utils/helpers";
const cookie = new Cookies();

const App = (props) => {
  const { history } = props;
  const handleLocationChange = () => {
    logPageView(window.location.pathname + window.location.search);
    if (!props.seoData[history.location.pathname] && history.location.pathname !== "/") {
      // get seoData if it is not already present
      getSeoDetails();
    }
  };
  useEffect(() => {
    if (!props.examMenuData || !props.examMenuData.SSC) {
      props.fetchExamList();
    }
    history.listen(handleLocationChange);
    if (
      Array.isArray(props.referrer) &&
      !sessionStorage.getItem("tracing_query_params")
    ) {
      let tracing_query_params = props.referrer
        .map((value) => `${Object.keys(value)[0]}=${Object.values(value)[0]}`)
        .join("&");

      sessionStorage.setItem("tracing_query_params", tracing_query_params);
    }

    if (!props.seoData[history.location.pathname] && history.location.pathname !== "/") {
      // get seoData if it is not already present
      getSeoDetails();
    }
    // *** fetch wishlist data only for business ADDA ***
  }, []);

  const userId = useMemo(() => {
    const pathname = history?.location?.pathname || "";
    if (pathname.includes("wl")) {
      const parts = pathname.split("/");
      const sharedUserId = parts[2] || "";
      return +sharedUserId;
    } else {
      const cookieUserId = cookie.get("cp_user_id");
      return cookieUserId;
    }
  }, [history,cookie.get("cp_user_id")]);

  useEffect(() => {
    // *** fetch wishlist data only for business ADDA ***
    if (isADDA && userId) fetchWishlistedHandler(userId);
  }, [isADDA, userId]);

  const fetchWishlistedHandler = async (userId) => {
    const res = await fetchWishlistedpackages(userId);
    let newWishListState = { ...res };

    // auto wishlist the last clicked item after login
    const { likeType, packageId } = props?.lastClickedWishItemInfo;
    if (likeType && packageId) {
      await addRemoveWishList(likeType, packageId);
      if (res?.postData) {
        // add new wishlist item with the response and update store
        newWishListState = getUpdatedNewWishListStore(
          false, // not wishlist yet
          packageId,
          res
        );
      }
    }
    props.setWishListedPackage(newWishListState);
  };
  const getSeoDetails = () => {
    let path = history.location.pathname;
    try {
      path = path?.split('?')[0];
    } catch (error) {
      path = history.location.pathname;
      console.error('error parsing path');
    }
    
    fetch(
      `${STORE_URI}/v1/cms?url=${encodeURI(path)}`,
      "GET"
    ).then(
      (res) => {
        props.getSeoDetails(res.data, history.location.pathname)
      },
      (err) => {

      }
    );
  };

  let isTestSeriesPortal = false;
  let isAddaGold = false;

  if (
    props.test_portal ||
    (typeof window !== "undefined" &&
      (window.location.pathname.includes("my-test-analysis") ||
        window.location.pathname.includes("test-series-portal")))
  ) {
    isTestSeriesPortal = true;
  }
  if (
    props.adda_gold ||
    (typeof window !== "undefined" &&
      window.location.pathname.includes(
        "adda247-gold-govt-exam-prep-subscription"
      ))
  ) {
    isAddaGold = true;
  }
  const clearCacheData = () => {
    props.resetCacheEnrolledCourses();
  };

  return (
    <>
      <Helmet
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
      />
      <ErrorBoundary>
        {/*@TODO: Insert Header here*/}
        {isAddaGold ? <></> : <NavBar history={history} examMenuData={props.examMenuData} globalExamList={props.globalExamList} clearCacheData={() => clearCacheData()} />}

        <div style={{ minHeight: "50vh" }}>
          {renderRoutes(props.route.routes)}
        </div>
        {isTestSeriesPortal ? "" : <Footer history={history} />}
      </ErrorBoundary>
    </>
  );
};

App.fetching = ({ dispatch, origin }) => {
  return [
    dispatch(fetchExamList()),
    dispatch(fetchTendingData()),
    dispatch(fetchGlobalExamList(origin)),
  ];
};

const mapStateToProps = (state) => ({
  examMenuData: state.header.examList,
  referrer: state.referrer,
  test_portal: state.test_portal,
  adda_gold: state.adda_gold,
  seoData: state.seoData,
  allTrendingData: state.header.allTrendingData,
  globalExamList: state.header.globalExamList,
  contacts: state.header.contacts,
  lastClickedWishItemInfo: state.wishlist?.lastClickedWishItemInfo,
});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchExamList,
    fetchGlobalExamList,
    fetchTendingData,
    getSeoData,
    resetCacheEnrolledCourses: () => dispatch(resetCacheEnrolledCourses()),
    getSeoDetails: (data, url) => dispatch(getSeoDetails(data, url)),
    setWishListedPackage: (data) => dispatch(setWishListedPackage(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

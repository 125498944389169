import types from "./types";
import {
  pageSizeForDetailListing,
  productListingUrl,
} from "../../../../constants/appConfig";
import { isADDA } from "../../utils/helpers";

const getPaginatedDataForExamDetail = ({
  pageNo,
  examName,
  type,
  categoryFacet,
  queryParam,
  sortBy,
  sponsoredCategory = 1,
  tagParams="",
}) => {
  let course = "";
  if(queryParam.indexOf("coursesFacets") !== -1){
    course = "";
  }
  if(typeof window !== "undefined"){
    course = sessionStorage.getItem("ExamCategoryMappingId") ? sessionStorage.getItem("ExamCategoryMappingId")?.toUpperCase() : "";
  }
  return {
    CALL_API: [
      {
        type:
          type === "init"
            ? types.GET_EXAM_DETAIL_LISTING
            : types.GET_EXAM_DETAIL_LISTING_ON_SCROLL,
        meta: {
          path: `${productListingUrl}?examTypesFacets=${examName}&categoryFacets=${categoryFacet}&pageNo=${pageNo}&pageSize=${pageSizeForDetailListing}&sponsoredCategory=${sponsoredCategory}&primaryFilter=true&${queryParam.length ? `${queryParam}` : ""
            }${sortBy.toUpperCase() != "RELEVANCE"? "&sortOrder=" + sortBy: ""}${course? `&coursesFacets=${course}${tagParams}`: ""}${isADDA ? "&block3DModels=true" : ""}`,
          method: "GET"
        }
      }
    ]
  };
};

const clearExamDetailData = () => {
  return {
    type: types.CLEAR_EXAM_DETAIL_LISTING_DATA,
    meta: {
      response: true,
      method: "LOCAL"
    }
  };
};

export { getPaginatedDataForExamDetail, clearExamDetailData };

import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Cookies, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { pageName, pdpUrls } from "../../constants/appConfig";
import { productMeta } from "../../constants/meta";
import EmptyCart from "../components/cart/EmptyCart";
import CartActionModal from "../components/CartActionModal";
import BuyNowCheckoutModal from "../components/BuyNowCheckoutModal";
import ErrorBoundary from "../components/ErrorBoundary";
import IncludedPackages from "../components/IncludedPackages";
import BreadCrumb from "../components/library/BreadCrumbNew";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import CustomModal from "../components/library/CustomModal";
import DfpAdPdp from "../components/library/DfpAdPdp";
import {
  ViewOffers,
  AppliedCouponInfo,
  HighLights,
  SalientFeature,
  PackageContent,
  PackageInfo,
  ExamCovered,
  CallNowWidget,
  PDPTestimonial,
  PDPCard,
  PDPOverlayCard,
  ProductDetailDescription,
  CourseIncludes,
  FAQSection,
  FreeContent,
  BuyNowComponentMweb,
  PDPContactSupport,
  FacultyProfileSlider,
} from "../components/PDPComponent";
import {
  clearProductDetailsData,
  getPackageMahapack,
  getPackageOffering,
  getProductDetail,
} from "../data/ducks/productDetails/actions";
import "../data/ducks/productDetails/reducers";
import fetch from "../data/utils/fetch";
import {
  capitalizedString,
  changeUrlPID,
  findMaxTagAssignedAtObject,
  getDeviceType,
  getFormattedDate,
  getMoengageMetadata,
  getYoutubeIframeUrl,
  isEmpty,
  scrollToTop,
  updateHash,
} from "../data/utils/helpers";
import BuyNowComponentNew from "../components/BuyNowComponentNew";
import ApplyCoupon from "../components/ApplyCoupon";
import { salientFeaturesData } from "../components/PDPComponent/PDPSalientConst";
import { generalFaqConstants } from "../../constants/generalFaqConstants";
import BusinessTag from "../components/library/BusinessTag";
import { categoryAdditionalMap, categoryPriority } from "../../constants/appConstants";
import { checkIsPackageWishList, useWishListedPackageIds } from "../components/utils";
import CarouselSchema from "../components/library/CarouselSchema";

const cookie = new Cookies();

const ProductDetails = (props) => {
  const [productData, setProductData] = useState(props?.productDetails ? props?.productDetails?.data?.packageEsEntity[0] : []);
  const [checkoutData, setCheckoutData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [viewOffer, setViewOffer] = useState(true);
  const [applyOffers, setApplyOffers] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [Moenagagedata, setMoengagedata] = useState("");
  const [isStepSkip, setStepSkip] = useState(false);
  const [fromBuyNowBtn, setFromBuyNowBtn] = useState(false);
  const [buyNowPostion,setBuyNowPosition] = useState("");
  const [isViewCourseClicked, setViewCourseClicked] = useState(false);
  const [priceDetails, setPriceDetails] = useState({
    basePrice: undefined,
    discount: undefined,
    payablePrice: undefined,
    appliedCoupon: "",
    enteredCouponFlag: false,
    discountType: "",
  });
  const [ads, showAds] = useState(false);
  const [activeValidityData, setActiveValidityData] = useState({});
  const [viewOfferModalOnly, setViewOfferModalOnly] = useState(null);
  const [freeContentModalVisible, setFreeContentModalVisible] = useState(false);
  const [removeCouponFlag, setRemoveCouponFlag] = useState(false);
  const [couponRemoving, setCouponRemoving] = useState(false);
  const [applyDefaultCouponFlag, setApplyDefaultCouponFlag] = useState(true);
  const [isMahapackedCLicked, setMahapackecdCLicked] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [deviceType, setDeviceType] = useState("d");
  const [isBusinessTagVisible, setIsBusinessTagVisible] = useState(false);
  const { location } = props;
  const { pathname } = location;
  const {id:actual_packageId} = props?.match?.params;
  let pathnameCms = pathname;
  const [isPurchased, setIsPurchased] = useState(-1);
  const [language,setLanguage] = useState(undefined);
  const [showTopArrow, setShowTopArrow] = useState(false);
  const [wishListPackageIds] = useWishListedPackageIds();
  let isMobile = false;
  if(typeof window != "undefined") {
    isMobile = window.innerWidth <= 767;
  }

  let paytmUserInfo;
  if (props.isPaytmMini) {
    paytmUserInfo = JSON.parse(
      typeof window != "undefined" && localStorage.getItem("paytm-user-info")
    );
  }
  const [userEmail, setUserEmail] = useState(
    paytmUserInfo && paytmUserInfo.email ? paytmUserInfo.email : null
  );
  useEffect(() => {
    let device = getDeviceType();
    setDeviceType(device);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScrollToTop);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScrollToTop);
    };
  }, []);

 // auto applied coupon on variant change
 useEffect(() => {
  if (!applyDefaultCouponFlag) {
    const defaultCouponCode = productData?.defaultCoupons?.code;
    if (defaultCouponCode) {
      setApplyDefaultCouponFlag(true);
    }
  }
}, [productData?.id]);

  const handleScrollToTop = () => {
    const pdpDisWrap = document.querySelector(".pdpOverviewWrap");
    const pdpWrapper = document.querySelector(".pdp-wrapper");
    if (pdpDisWrap && pdpWrapper) {
      const pdpDisWrapTop = pdpDisWrap.getBoundingClientRect().top - 100;
      if (pdpDisWrapTop <= 0) {
        setShowTopArrow(true);
      } else {
        setShowTopArrow(false);
      }
    }
  };

  const handleScroll = () => {
    const pdpDisWrap = document.querySelector(".pdpDisWrap");
    const pdpWrapper = document.querySelector(".pdp-wrapper");
    if (pdpDisWrap && pdpWrapper) {
      const pdpDisWrapTop = pdpDisWrap.getBoundingClientRect().top;
      if (pdpDisWrapTop <= 0) {
        setShowOverlay(true);
      } else {
        setShowOverlay(false);
      }
    }
  };
  useEffect(() => {
    if (fromBuyNowBtn) {
      if (applyOffers && viewOffer) {
        let payload = Getpayload();
        payload.getUtmFromStore = props?.utmParams || "";
        payload.source_screen = "PDP";
        payload.modified_date = Moenagagedata?.updatedAt || '';
        payload.published_date = Moenagagedata?.createdAt || '';
        payload.package_url = window?.location?.href || '';
        payload.Position = buyNowPostion;
        payload.package_subject = "_blank";
        if (typeof Moengage !== "undefined") {
          Moengage.track_event(
            "buy_now_btn_clicked",
            getMoengageMetadata(payload)
          );
        }
      }
    }
    else if(isViewCourseClicked) {
      let payload = Getpayload();
      payload.package_url = window?.location?.href || '';
      payload.getUtmFromStore = props?.utmParams || "";

      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("View_Course_Button_Clicked", getMoengageMetadata(payload));
    }
     else {
      if (applyOffers && viewOffer) {
        let payload = Getpayload();
        payload.total_final_amount = "";
        payload.payablePrice =
          (priceDetails && priceDetails.payablePrice) || "";
        payload.getUtmFromStore = props.utmParams || "";
        payload.total_final_amount = "";
        payload.modified_date = Moenagagedata?.updatedAt || '';
        payload.published_date = Moenagagedata?.createdAt || '';
        payload.package_url = window?.location?.href || '';
        if (typeof Moengage !== "undefined") {
          Moengage.track_event(
            "offer_button_clicked",
            getMoengageMetadata(payload)
          );
        }
      }
    }
  }, [applyOffers, viewOffer,isViewCourseClicked]);

  useEffect(() => {
    if (
      location.hash == "#cart/failure" ||
      location.hash.indexOf("#cart/success") > -1
    ) {
      setApplyOffers(true);
    }
    if (props.loggedUserDetails.email)
      setUserEmail(props.loggedUserDetails.email);
  }, [props.loggedUserDetails.email]);

  useEffect(() => {
    let mahapackid =
      typeof window !== "undefined" && sessionStorage.getItem("mahapackid");
    let original_package_id =
      typeof window !== "undefined" &&
      sessionStorage.getItem("original_package_id");
    let mahapackurlid =
      typeof window !== "undefined" && window.location.href.split("/")[4];
    if (mahapackid != mahapackurlid && original_package_id != mahapackurlid) {
      typeof window !== "undefined" && sessionStorage.removeItem("mahapackid");
      typeof window !== "undefined" &&
        sessionStorage.removeItem("original_package_id");
      typeof window !== "undefined" &&
        sessionStorage.removeItem("mahapack_widget_clicked");
    }
  }, [typeof window !== "undefined" && window.location.href.split("/")[4]]);

  useEffect(() => {
    if (!(priceDetails.appliedCoupon && priceDetails.discount)) {
      setCouponRemoving(false);
    }
  }, [priceDetails]);

  const getProductPurchaseInfo = (id) => {
    fetch(`${pdpUrls.purchasedPackage}?pkgId=${id}`, "GET")
      .then((res) => {
        if (res === true) {
          setIsPurchased(true);
        } else {
          setIsPurchased(false);
        }
        showAds(true);
      })
      .catch(() => {
        CartActionModal;
        setIsPurchased(false);
        showAds(true);
      });
  };

  const Getpayload = () => {
    let payload = { source: "web", brand: pageName[1] };
    if (Moenagagedata) {
      payload.subPackageTitle = Moenagagedata.title;
      payload.Repurchase_btn_clicked_flag =
        typeof window !== "undefined" &&
        window.localStorage.getItem("mypuchaseId") == props.match.params.id
          ? 1
          : 0;
      payload.name = Moenagagedata.title;
      payload.category = Moenagagedata.suggestionCat;
      payload.primary_category = Moenagagedata?.primaryCategory;
      payload.id = Number(productData?.id);
      payload.PID = Number(actual_packageId);
      payload.package_actual_price = Moenagagedata.maxPrice;
      payload.package_discounted_price = Moenagagedata.price;
      payload.package_final_price = price;
      payload.sellingprice = Moenagagedata.price;
      payload.exam_category = Moenagagedata.courses;
      payload.exam =
        Moenagagedata.examTypes && Moenagagedata.examTypes[0]
          ? Moenagagedata.examTypes[0].name || ""
          : "";
      payload.package_status = "Paid";
      payload.language =
        Moenagagedata.languages &&
        Moenagagedata.languages[0] &&
        Moenagagedata.languages[0].name || "English";
      payload.getUtmFromStore = props.utmParams || "";
      payload.package_subject = "_blank";
      payload.user_exam_category_selected = "Web";
    }
    if (
      props.productDetails.mahapack &&
      props.productDetails.mahapack.id &&
      isMahapackedCLicked == 1
    ) {
      sessionStorage.setItem("original_package_id", Moenagagedata.id);
      sessionStorage.setItem("mahapack_widget_clicked", isMahapackedCLicked);
      sessionStorage.setItem("mahapackid", props.productDetails.mahapack.id);
      payload.original_package_id = Moenagagedata.id;
      payload.mahapack_widget_clicked = isMahapackedCLicked;
    } else {
      payload.original_package_id =
        typeof window !== "undefined" &&
        sessionStorage.getItem("original_package_id");
      payload.Repurchase_btn_clicked_flag =
        typeof window !== "undefined" &&
        window.localStorage.getItem("mypuchaseId") == props.match.params.id
          ? 1
          : 0;
      payload.mahapack_widget_clicked =
        typeof window !== "undefined" &&
        sessionStorage.getItem("mahapack_widget_clicked");
    }
    return payload;
  };

  const getContactFromExamCategoryArray = (examCategoryArray) => {
    for (let exam of examCategoryArray) {
      if (props?.contacts?.EXAM_CATEGORIES?.[exam]) {
            return props?.contacts?.EXAM_CATEGORIES?.[exam];
        }
    }
    return null;
  };
  
  useEffect(() => {
    setCheckoutData(props.userAddress);
  }, [applyOffers]);

  // useEffect(()=>{
  //   console.log("ProductDetails",productData)
  // },[])

  useEffect(() => {
    scrollToTop();
    props.getPackageMahapack(props.match.params.id);
    let isStoreEmpty = isEmpty(props.productDetails);
    let previousPackageId =
      !isStoreEmpty &&
      props.productDetails.data &&
      props.productDetails.data.packageEsEntity &&
      props.productDetails.data.packageEsEntity[0] &&
      props.productDetails.data.packageEsEntity[0].id;
    if (
      isStoreEmpty ||
      (previousPackageId != undefined &&
        props.match.params.id != previousPackageId)
    ) {
      setIsLoading(true);
      setPriceDetails({
        basePrice: undefined,
        discount: undefined,
        payablePrice: undefined,
        appliedCoupon: "",
      });
      props.getProductDetail(props.match.params.id).then((data) => {
        data[0].then((d) => {
          setIsLoading(false);
          setMoengagedata(d.data.packageEsEntity[0]);
          let respData = d.data.packageEsEntity[0] || {};
          setProductData(respData); // initial data set
          let primaryValProduct = respData?.multiValidity?.filter(item => item.primary === true)[0];
          handleChangeValidity(primaryValProduct, respData);

          let d1 = d.data.packageEsEntity ? d.data.packageEsEntity[0] : "";
          let payload = { source: "web", brand: pageName[1] };
          if (d1) {
            payload.subPackageTitle = d1.title;
            payload.name = d1.title;
            payload.category = d1.suggestionCat;
            payload.primary_category = d1.primaryCategory;
            payload.id = Number(primaryValProduct?.id);
            payload.PID = Number(respData?.id);
            payload.getUtmFromStore = props.utmParams || "";
            payload.packageActualPrice = d1.maxPrice;
            payload.packageDiscountedPrice = d1.price;
            payload.Repurchase_btn_clicked_flag =
              typeof window !== "undefined" &&
              window.localStorage.getItem("mypuchaseId") ==
                props.match.params.id
                ? 1
                : 0;
            payload.exam_category = d1.courses;
            payload.exam =
              d1.examTypes && d1.examTypes[0] ? d1.examTypes[0].name || "" : "";
            payload.package_status = "Paid";
            payload.language =
              d1.languages && d1.languages[0] && d1.languages[0].name;
            payload.original_package_id =
              typeof window !== "undefined" &&
              sessionStorage.getItem("original_package_id");

            payload.mahapack_widget_clicked =
              typeof window !== "undefined" &&
              sessionStorage.getItem("mahapack_widget_clicked");

            payload.default_coupon = d1?.defaultCoupons ? "TRUE" : "FALSE"; // True/False
            payload.default_coupon_name = d1?.defaultCoupons?.code; // code name
            payload.sample_content = d1?.freeContentAvailable
              ? "TRUE"
              : "FALSE"; // True/False
            payload.sample_content_count = d1?.freeContentData?.length
              ? d1?.freeContentData?.length
              : 0; // freecontent count
            payload.multivalidity = d1?.multiValidity?.length
              ? "TRUE"
              : "FALSE"; // True/False
          }

          //MOENGAGE EVENT
          if (typeof Moengage !== "undefined")
            if (typeof Moengage !== "undefined")
              Moengage.track_event(
                "product_detail",
                getMoengageMetadata(payload)
              );

          //DATALAYER
          const { location } = props;
          const { pathname } = location;

          const examName = pathname.split("/")[3];
          const user_id_cookie = cookie.get("cp_user_id");

          let courseName = d1.courses && d1.courses.split(",")[0].toLowerCase();

          if (
            props.userInfo &&
            props.isLoggedIn &&
            typeof window != undefined
          ) {
            window.dataLayer.push({
              event: "fireRemarketingTag",
              google_tag_params: {
                edu_pid: d1.id,
                ecomm_pagetype: "product",
                ecomm_totalvalue: d1.price,
              },
              event: "productDetail",
              ecommerce: {
                detail: {
                  //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                  products: [
                    {
                      name: d1.title,
                      id: d1.id, // Product id
                      price: d1.price,
                      brand: pageName[1],
                      category: d1 && d1.suggestionCat,
                    },
                  ],
                  userDetails: [
                    {
                      name: (props.userInfo && props.userInfo.name) || "guest",
                      email: (props.userInfo && props.userInfo.email) || "",
                      isMobile: getDeviceType() == "m" ? true : false,
                      loginType: "email",
                      isLoggedIn: true,
                    },
                  ],
                },
              },
            });
          } else if (
            !props.isLoggedIn &&
            !user_id_cookie &&
            typeof window != undefined
          ) {
            window.dataLayer.push({
              event: "fireRemarketingTag",
              google_tag_params: {
                edu_pid: d1.id,
                ecomm_pagetype: "product",
                ecomm_totalvalue: d1.price,
              },
              event: "productDetail",
              ecommerce: {
                detail: {
                  //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                  products: [
                    {
                      name: d1.title,
                      id: d1.id, // Product id
                      price: d1.price,
                      brand: pageName[1],
                      category: d1 && d1.suggestionCat,
                    },
                  ],
                  userDetails: [
                    {
                      name: "guest",
                      email: "",
                      isMobile: getDeviceType() == "m" ? true : false,
                      loginType: "--",
                      isLoggedIn: false,
                    },
                  ],
                },
              },
            });
          }
        });
      });
    } else {
      let payload = { source: "web", brand: pageName[1] };
      if (
        props.productDetails &&
        props.productDetails.data &&
        props.productDetails.data.packageEsEntity &&
        props.productDetails.data.packageEsEntity[0]
      ) {
        let d1 = props.productDetails.data.packageEsEntity[0] || {};
        setProductData(d1); // set initial data
        setMoengagedata(props.productDetails.data.packageEsEntity[0]);
        let primaryValProduct = d1?.multiValidity?.filter(item => item.primary === true)[0];
        payload.subPackageTitle = d1.title;
        payload.name = d1.title;
        payload.category = d1.suggestionCat;
        payload.primary_category = d1.primaryCategory;
        payload.id = Number(primaryValProduct?.id);
        payload.PID = Number(d1?.id);
        payload.packageActualPrice = d1.maxPrice;
        payload.packageDiscountedPrice = d1.price;
        payload.exam_category = d1.courses;
        payload.exam =
          d1.examTypes && d1.examTypes[0] ? d1.examTypes[0].name || "" : "";
        payload.getUtmFromStore = props.utmParams || "";
        payload.package_status = "Paid";
        payload.language =
          d1.languages && d1.languages[0] && d1.languages[0].name;
        payload.Repurchase_btn_clicked_flag =
          typeof window !== "undefined" &&
          window.localStorage.getItem("mypuchaseId") == props.match.params.id
            ? 1
            : 0;
        payload.original_package_id =
          typeof window !== "undefined" &&
          sessionStorage.getItem("original_package_id");
        payload.mahapack_widget_clicked =
          typeof window !== "undefined" &&
          sessionStorage.getItem("mahapack_widget_clicked");

        payload.default_coupon = d1?.defaultCoupons ? "TRUE" : "FALSE"; // True/False
        payload.default_coupon_name = d1?.defaultCoupons?.code; // code name
        payload.sample_content = d1?.freeContentAvailable ? "TRUE" : "FALSE"; // True/False
        payload.sample_content_count = d1?.freeContentData?.length
          ? d1?.freeContentData?.length
          : 0; // freecontent count
        payload.multivalidity = d1?.multiValidity?.length ? "TRUE" : "FALSE"; // True/False
      }

      //MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event("product_detail", getMoengageMetadata(payload));

      //DATALAYER
      const { location } = props;
      const { pathname } = location;

      const user_id_cookie = cookie.get("cp_user_id");
      const examName = pathname.split("/")[3];
      // let data = props.productDetails.data;
      // let productData = data && data.packageEsEntity && data.packageEsEntity[0];
      if (!productData) return "Some Error";
      let courseName =
        productData?.courses && productData?.courses?.split(",")[0].toLowerCase();

      if (props.cookies.get("cp_token") && typeof window != "undefined") {
        window.dataLayer.push({
          event: "fireRemarketingTag",
          google_tag_params: {
            edu_pid: payload && payload.id,
            ecomm_pagetype: "product",
            ecomm_totalvalue: payload && payload.packageDiscountedPrice,
          },
          event: "productDetail",
          ecommerce: {
            detail: {
              products: [
                {
                  name: payload && payload.name,
                  id: payload && payload.id, // Product id
                  price: payload && payload.packageDiscountedPrice,
                  brand: pageName[1],
                  category: payload && payload.category,
                },
              ],
              userDetails: [
                {
                  name: props.cookies.get("cp_user_name") || "guest",
                  email: props.cookies.get("cp_user_email") || "",
                  isMobile: getDeviceType() == "m" ? true : false,
                  loginType: "email",
                  isLoggedIn: true,
                },
              ],
            },
          },
        });
      } else if (
        !props.cookies.get("cp_user_email" && typeof window != "undefined")
      ) {
        window.dataLayer.push({
          event: "fireRemarketingTag",
          google_tag_params: {
            edu_pid: payload.id,
            ecomm_pagetype: "product",
            ecomm_totalvalue: payload && payload.packageDiscountedPrice,
          },
          event: "productDetail",
          ecommerce: {
            detail: {
              //   'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
              products: [
                {
                  name: payload && payload.name,
                  id: payload && payload.id, // Product id
                  price: payload && payload.packageDiscountedPrice,
                  brand: pageName[1],
                  category: payload && payload.category,
                },
              ],
              userDetails: [
                {
                  name: "guest",
                  email: "",
                  isMobile: getDeviceType() == "m" ? true : false,
                  loginType: "--",
                  isLoggedIn: false,
                },
              ],
            },
          },
        });
      }
    }
    // let data = props.productDetails.data;
    // let productData = data?.packageEsEntity[0];
    let primaryValProduct = productData?.multiValidity?.filter(item => item.primary === true)[0];
    handleChangeValidity(primaryValProduct, productData);
    const email = props.cookies.get("cp_user_email");
    if (
      props.cookies.get("cp_token") &&
      (email || props.loggedUserDetails.name) &&
      primaryValProduct?.id
    ) {
      getProductPurchaseInfo(primaryValProduct?.id);
    } else {
      setIsPurchased(false);
      showAds(true);
    }
    let pdpInitialHistoryLength = props.history.length;
    sessionStorage.setItem("pdpInitialHistoryLength", pdpInitialHistoryLength);
  }, [props.match.params.id]);

  // const setPrimaryValidityColor = (productData) => {
  //   productData?.multiValidity?.map((item, index) => {
  //     if (index === 0) {
  //       setActiveValidityData(item);
  //     }
  //   });
  // };

  const selectedFeaturesVal = useMemo(() => {
    if (productData?.salientFeatures?.length > 0) {
      return productData?.salientFeatures;
    } else {
      const categoryList = productData?.categoryList || [];
      const category = categoryList[0];
      return salientFeaturesData?.[category];
    }
    
  }, [productData]);
  const faqData = useMemo(() => {
    const { subCategories = [], faqJson, id } = productData ?? {};
    const categoryList = subCategories
        ?.filter(cat => cat?.id !== 'FREE_CONTENT')
        ?.map(cat => cat?.category) ?? [];
    
    const generalFaqs = categoryList
        ?.reduce((acc, cat) => [
            ...acc,
            ...(generalFaqConstants[cat] || [])
        ], []) ?? [];
    
    const customFaqs = faqJson || [];
    
    return [...generalFaqs, ...customFaqs];
}, [productData]);

const recentlyAssignedTag = useMemo(() => {
  return findMaxTagAssignedAtObject(productData?.newTags?.businessTags || []);
}, [productData]);

const additionalTag = useMemo(() => {
  if (productData?.newTags?.additionalTags)
    return productData?.newTags?.additionalTags; //if additionalTags exist.
  let tagText = "Live + Recorded";
  const categoryList = productData?.categories?.split(",") || [];
  if (productData?.mahaPack) {
    //if it is mahaPack
    tagText = "Live + Recorded";
  } else {
    // Loop through the priority categories and set tagText if found
    for (const category of categoryPriority) {
      if (categoryList.includes(category)) {
        tagText = categoryAdditionalMap[category];
        break; // Stop searching once a higher-priority category is found
      }
    }
  }
  return tagText;
}, [productData]);
const isWishList = useMemo(() => {
  if(!wishListPackageIds?.length && !actual_packageId) return false
  return checkIsPackageWishList(wishListPackageIds, +actual_packageId);
}, [productData,actual_packageId,wishListPackageIds]);
useEffect(() => {
  if (recentlyAssignedTag) {
    setIsBusinessTagVisible(true);
  }
}, [recentlyAssignedTag]);
  if (isLoading) {
    return (
      <div style={{ marginTop: "80px" }}>
        <CustomLoader></CustomLoader>
      </div>
    );
  }

  const openPackageModal = (id, category, viewCourseFlag = null) => {
    //currently this if is not used anywhere
    if (id == "FREE_CONTENT") {
      let paramerter = "test";
      if (
        productData?.freeContentData?.filter((item) => item?.contentType == 1)
          ?.length
      )
        paramerter = "test";
      else if (
        productData?.freeContentData?.filter((item) => item?.contentType == 4)
          ?.length
      )
        paramerter = "ebooks";
      updateHash(`freeContent/${props.match.params.id}/${paramerter}`);
      setFreeContentModalVisible(true);
      let d1 = props?.productDetails?.data?.packageEsEntity[0];
      let payload = {
        source: "web",
        brand: pageName[1],
        subPackageTitle: d1.title,
        name: d1.title,
        category: d1.suggestionCat,
        id: Number(productData?.id),
        packageActualPrice: d1.maxPrice,
        packageDiscountedPrice: d1.price,
        exam_category: d1.courses,
        exam: d1.examTypes && d1.examTypes[0] ? d1.examTypes[0].name || "" : "",
        getUtmFromStore: props.utmParams || "",
        package_status: "Paid",
        language: d1.languages && d1.languages[0] && d1.languages[0].name,
        Repurchase_btn_clicked_flag: typeof window !== "undefined" && window.localStorage.getItem("mypuchaseId") == props.match.params.id ? 1 : 0,
        source_screen : "product_detail",
        original_package_id : typeof window !== "undefined" && sessionStorage.getItem("original_package_id"),
        mahapack_widget_clicked: typeof window !== "undefined" && sessionStorage.getItem("mahapack_widget_clicked"),
      };

      //MOENGAGE EVENT
      if (typeof Moengage !== "undefined")
        if (typeof Moengage !== "undefined")
          Moengage.track_event(
            "sample_content_clicked",
            getMoengageMetadata(payload)
          );
      return;
    }

    let payload = Getpayload();
    payload.position = category;
    payload.getUtmFromStore = props?.utmParams || "";
    payload.package_url = window?.location?.href || '';
    if (typeof Moengage !== "undefined")
      if (typeof Moengage !== "undefined")
        Moengage.track_event(
          "content_listing_opened",
          getMoengageMetadata(payload)
        );
    let tempUrl = "";
    let hashParameter = "";
    switch (category) {
      case "ONLINE_LIVE_CLASSES":
        tempUrl = "my-live-class";
        hashParameter = "olcCount";
        break;
      case "VIDEOS":
        tempUrl = "my-videos";
        hashParameter = "videosCount";

        break;
      case "BOOKS":
        tempUrl = "my-ebooks";
        break;
      case "EBOOKS":
        tempUrl = "my-ebooks";
        hashParameter = "ebooksCount";

        break;
      default:
        tempUrl = "my-mock-test";
        hashParameter = "testSeriesCount";

        break;
    }
    if (viewCourseFlag && viewCourseFlag == "viewCourse") {
      updateHash(`packageDetails/${props.match?.params?.id}/${id}`);
    } else {
      if (productData?.linkedPkgs !== null) {
        // handling of case when a packages is not having child packages. Items Like tests videos ebooks are attached to packages directly.
        updateHash(`packageDetails/${props.match.params.id}/${id}`);
      } else {
        let packageChildData =
          props?.productDetails &&
          props?.productDetails?.packageOfferingData &&
          props?.productDetails?.packageOfferingData.pkgResponse;
        if (
          packageChildData &&
          !packageChildData[id] &&
          packageChildData[hashParameter]
        ) {
          updateHash(
            `packageDetails/${props.match?.params?.id}/${id}/${props.match?.params?.id}/0`
          );
          setStepSkip(true);
        } else {
          updateHash(`packageDetails/${props.match?.params?.id}/${id}`);
        }
      }
    }
  };

  if (
    isEmpty(props?.productDetails?.data || []) ||
    !props?.productDetails?.data?.packageEsEntity ||
    !props?.productDetails?.data?.packageEsEntity[0]
  ) {
    return (
      <EmptyCart
        title={" "}
        header={"Currently unavailable"}
        subHeader={
          "Be ahead of your competition with our comprehensive study material"
        }
        ProductTypeVisible={true}
      />
    );
  }
  // let productData = props?.productDetails
  //   ? props?.productDetails?.data?.packageEsEntity[0]
  //   : [];
  let indexSubCategory = productData?.subCategories?.findIndex(
    (item) => item.id == "FREE_CONTENT"
  );
  if (
    productData?.freeContentAvailable &&
    productData?.freeContentData?.length
  ) {
    if (indexSubCategory < 0)
      productData?.subCategories?.unshift({
        id: "FREE_CONTENT",
        category: "FREE_CONTENT",
      });
  }
  let courseName =
    productData?.courses && productData?.courses.split(",")[0].toLowerCase();

  const categoryObj = {
    ONLINE_LIVE_CLASSES: { label: "Live Class", urlParam: "live-classes" },
    EBOOKS: { label: "Ebooks", urlParam: "ebooks" },
    TEST_SERIES: { label: "Mock Tests", urlParam: "mock-tests" },
    BOOKS: { label: "Books", urlParam: "books" },
    VIDEOS: { label: "Videos", urlParam: "videos" },
  };
  let categoryName = "";
  let categoryUrl = "";

  if (productData?.categories) {
    let categoryId = productData?.categories
      .split(",")
      .find((categoryId) => categoryObj[categoryId]);
    if (categoryId) {
      categoryName = categoryObj[categoryId].label;
      categoryUrl = categoryObj[categoryId].urlParam;
    }
  }

  // MAGAZINES,ONLINE_LIVE_CLASSES,TEST_SERIES,BOOKS

  if (playVideo) {
    let payload = Getpayload();
    payload.action = "consulting_video_clicked";
    payload.getUtmFromStore = props.utmParams || "";
    if (typeof Moengage !== "undefined")
      if (typeof Moengage !== "undefined")
        Moengage.track_event("consulting_video", getMoengageMetadata(payload));
  }

  let metaTitle = `${capitalizedString(productData?.title)}`;
  let metaDescription = `Avail ${capitalizedString(
    productData?.title
  )} online at ${pageName[1]} at most affordable prizes. Prepare with the latest & updated study material to ace your dream.`;
  if (productMeta[productData?.id]) {
    metaTitle = productMeta[productData?.id]["title"];
    metaDescription = productMeta[productData?.id]["description"];
  }


  function handleChangeValidity(item, pdObj = {}) {
    // window.scrollTo(0, 0)
    // for deep copy
    let pd = JSON.parse(JSON.stringify(pdObj));
    if (activeValidityData?.id != item?.id) {
      changeUrlPID(item?.id);
      setActiveValidityData(item);

      pd.title = item?.title;
      pd.id = item?.id;
      pd.description = item?.desc;
      pd.maxPrice = item?.mrp;
      pd.price = item?.sp;
      pd.dualValidity = item?.dualValidity;
      setLanguage(item?.language)
      setProductData(pd)

      setPriceDetails((prevState) => ({
        basePrice: undefined,
        discount: undefined,
        payablePrice: undefined,
        appliedCoupon: "",
      }));
    }
  };
  const openViewOffersModal = () => {
    setFromBuyNowBtn(false);
    setViewOfferModalOnly(true);

    if (setApplyOffers) setApplyOffers(true);
    sessionStorage.setItem("buyNow_Offer", "offer");
    if (setViewOffer) setViewOffer(true);
    window.location.hash = "#checkout/viewOffers";
  };

  const maxPrice =
    priceDetails?.basePrice == undefined
      ? productData?.price
      : priceDetails?.basePrice;
  const price =
    priceDetails?.payablePrice == undefined
      ? productData?.price
      : priceDetails?.payablePrice;
  const sellingPrice = props.productDetails?.data?.packageEsEntity[0]?.price;

  // For coupon applied or default coupon available
  const couponAppliedOrDefaultCouponAvailable =
    (priceDetails?.appliedCoupon && priceDetails?.discount) ||
    (productData?.defaultCoupons &&
      Date.now() <=
        productData?.defaultCoupons?.validityExpire - 5.5 * 60 * 60 * 1000);

  // Buy Now button render
  const renderBuyNowComponent = (props,isPDPOverlayCard = false) => {
    return (
      <>
        <BuyNowComponentNew
          viewOffers={setApplyOffers}
          isPurchased={isPurchased}
          productData={productData}
          isLoggedIn={props?.loggedUserDetails?.email}
          published={productData?.published}
          onClick={openPackageModal}
          setFromBuyNowBtn={setFromBuyNowBtn}
          setBuyNowPosition={setBuyNowPosition}
          setViewCourseClicked={setViewCourseClicked}
          isPDPOverlayCard={isPDPOverlayCard}
        />
      </>
    );
  };

  // For view Offer button render
  const renderViewOffers = (props) => {
    return (
      <ViewOffers
        setViewOfferModalOnly={setViewOfferModalOnly}
        viewOffers={setApplyOffers}
        setViewOffer={setViewOffer}
        isLoggedIn={props?.loggedUserDetails?.email}
        productData={productData}
        setFromBuyNowBtn={(flag) => setFromBuyNowBtn(flag)}
        isPurchased={isPurchased}
        published={productData?.published}
        Getpayload={Getpayload()}
        utmParams = {props?.utmParams}
        courses = {productData?.courses}
      />
    );
  };

  const CallNowComponent = () => {
    return (
      <div className='groupmeetwrap'>
        {productData?.supportNumber && deviceType !== "d" && (
          <CallNowWidget
            contact={productData?.supportNumber}
            deviceType={deviceType}
            Getpayload={Getpayload()}
            Position="Top"
          />
        )}
        {productData?.liveDoubtEnabled && (
          <div className="groupmeetlist dbtSolve">
          <div className="icongroupmeet">
                      <img src="/images/groupmeeticon.svg" alt="groupmeeticon"/>
                    </div>{" "}
                    <span className="nameigroup">Dedicated live rooms for doubts solving</span> 
                  </div>
                )}
                {productData?.supportNumber && deviceType === "d" && (
                  <CallNowWidget
                    contact={productData?.supportNumber}
                    deviceType={deviceType}
                    Getpayload={Getpayload()}
                    Position="Top"
                  />
                )}
      </div>
    )
  }
  // code for checking and rendering that the demo url is youtube url or other url
  const isYoutubeUrl = (url) => /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([\w-]{11})(?:\S+)?$/.test(url);
  const getVideoIframeUrl = (url) => isYoutubeUrl(url) ? getYoutubeIframeUrl(url) : url;


  const getVideoTag = (url) => (
    <iframe
      src={getVideoIframeUrl(url)}
      allow="autoplay"
    ></iframe>
  );
  return (
    <>
      <div className="detail-screen-wrap">
        <CustomHelmet
          title={
            props.seoData &&
            props.seoData[pathnameCms] &&
            props.seoData[pathnameCms].metaTitle
              ? props.seoData[pathnameCms].metaTitle
              : `${metaTitle} - ${pageName[1]}`
          }
          description={
            props.seoData &&
            props.seoData[pathnameCms] &&
            props.seoData[pathnameCms].metaDescription
              ? props.seoData[pathnameCms].metaDescription
              : `${metaDescription}`
          }
          url={
            (props.seoData &&
              props.seoData[pathnameCms] &&
              props.seoData[pathnameCms].canonical) ||
            props.req_url
          }
          keywords={
            props.seoData &&
            props.seoData[pathnameCms] &&
            props.seoData[pathnameCms].keywords
          }
          noFollow={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noFollow
          }
          noIndex={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noIndex
          }
        />

        {/* IncludedPackages existing component */}
        <ErrorBoundary>
          <IncludedPackages
            isPurchased={isPurchased}
            id={props.match.params.id}
            title={productData?.title}
            setStepSkip={setStepSkip}
            isStepSkip={isStepSkip}
          />
        </ErrorBoundary>

        {/* Existing used code  */}
        {productData?.demoUrl && (
          <ErrorBoundary>
            <CustomModal
              footer={null}
              visible={playVideo}
              title={productData?.title}
              onCancel={() => {
                setPlayVideo(false); //show hide
              }}
              closeCallback={() => {
                setPlayVideo(false);
              }}
              closable={true}
              maskClosable={false}
              keyboard={false}
              modalClassName="largemodal"
              bodyStyle={{ overflowY: "auto" }}
            >
              <div className="video-popup">
                {getVideoTag(productData?.demoUrl)}
              </div>
            </CustomModal>
          </ErrorBoundary>
        )}
        {applyOffers && (
          <BuyNowCheckoutModal
            maxPrice={
              priceDetails.basePrice == undefined
                ? productData?.price
                : priceDetails.basePrice
            }
            price={
              priceDetails.payablePrice == undefined
                ? productData?.price
                : priceDetails.payablePrice
            }
            priceDetails={priceDetails}
            viewOfferModalOnly={viewOfferModalOnly}
            userInfo={props.userInfo}
            isLoggedIn={props.isLoggedIn}
            setViewOfferModalOnly={setViewOfferModalOnly}
            viewOffers={setApplyOffers}
            updatePriceDetails={setPriceDetails}
            loggedUserDetails={props.loggedUserDetails}
            productData={productData}
            cartJson={`{"${productData?.id}":1}`}
            isShippable={productData?.shippable}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            Getpayload={Getpayload()}
            setCheckoutData={setCheckoutData}
            checkoutData={checkoutData}
            getUtmFromStore={props.utmParams}
            setFromBuyNowBtn={(flag) => setFromBuyNowBtn(flag)}
            payablePrice={priceDetails && priceDetails.payablePrice}
            activeValidityData={activeValidityData}
          ></BuyNowCheckoutModal>
        )}
        <div style={{ display: "none" }}>
          {typeof window !== "undefined" && (
            <ApplyCoupon
              viewOfferModalOnly={viewOfferModalOnly}
              setViewOfferModalOnly={setViewOfferModalOnly}
              packageId={productData?.id} //selected validity id as packageId
              packageType={productData?.categories}
              priceDetails={priceDetails}
              Getpayload={Getpayload()}
              maxPrice={maxPrice}
              price={price}
              activeValidityData={activeValidityData}
              updatePriceDetails={setPriceDetails}
              productData={productData}
              cartPayload={`{\"${productData?.id}\":1}`} //selected validity id as packageId
              loggedUserDetails={props.loggedUserDetails}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              removeCouponFlag={removeCouponFlag}
              setRemoveCouponFlag={setRemoveCouponFlag}
              applyDefaultCouponFlag={applyDefaultCouponFlag}
              setApplyDefaultCouponFlag={setApplyDefaultCouponFlag}
              notPurchasedAndPublished={
                !isPurchased && isPurchased != -1 && productData?.published
              }
              parent={"productDetails"}
              actualPackageId={actual_packageId}
            ></ApplyCoupon>
          )}
        </div>
        {showOverlay && getDeviceType() == "d" && (
          <>
              <PDPOverlayCard 
              seoDataHeading={props?.seoData[pathnameCms]?.pageHeading}
              dualValidity={productData?.dualValidity}
              maxPrice={maxPrice}
              price={price}
              originalPrice={productData?.price}
              demoUrl={productData?.demoUrl}
              pdpUrlTemplate={productData?.imgUrl}
              title={productData?.title}
              isPurchased={isPurchased}
              setPlayVideo={setPlayVideo}
              playVideo={playVideo}
              subCategoriesData={productData?.subCategories} //mock test count
              renderBuyNowComponent={renderBuyNowComponent(props,true)}
              renderViewOffers={renderViewOffers(props)}
            />
          </>
        )}
        <section className="pdp-wrapper">
          {/* {!props.isPaytmMini && (
        <div className={"banner-container1"}>
          {ads && <DfpAdPdp packageId={props.match.params.id} />} 
        </div>
      )} */}

          <div className="pdp-container">
            <div className="pdp-full-detail">
              <div className="pdf-breadcrumb">
                <BreadcrumbSchema
                  items={[
                    {
                      title: "Home",
                      url: "/",
                    },
                    {
                      title: capitalizedString(courseName),
                      url: `/${courseName}-study-materials`,
                    },
                    {
                      title: categoryName,
                      url: `/${courseName}/${categoryUrl}-study-kit`,
                    },
                    {
                      title: productData?.title,
                      url: "/",
                    },
                  ]}
                />
                <BreadCrumb
                  routeData={[
                    { name: "Home", target: "/" },
                    {
                      name: `${capitalizedString(courseName)?.replace(
                        /_/g,
                        "-"
                      )} study material`,
                      target: `/${courseName?.replace(
                        /_/g,
                        "-"
                      )}-study-materials`,
                    },
                    {
                      name: categoryName,
                      target: `/${courseName?.replace(
                        /_/g,
                        "-"
                      )}/${categoryUrl}-study-kit`,
                    },
                    { name: productData?.title, target: "" },
                  ]}
                />
              </div>

              <div className="priceInfoWithCard">
                <div className="pdpInfoSection">
                  <div className="pdpBannerData">
                     {isBusinessTagVisible && (
                       <BusinessTag
                            iconSrc={recentlyAssignedTag?.thumbnail}
                            tagType={recentlyAssignedTag?.tagType}
                            name={recentlyAssignedTag?.name}
                            endTime={recentlyAssignedTag?.endTime || 0}
                            setIsBusinessTagVisible={setIsBusinessTagVisible}
                          />
                          )} 
                  <h1 className="pdp-heading">
                    {props.seoData[pathnameCms]?.pageHeading ||
                      productData?.title}
                  </h1>
                  {props.seoData &&
                    props.seoData[pathnameCms] &&
                    props.seoData[pathnameCms].pageHeadingDescription && (
                      <div className="headingdescriptionprod">
                        {props.seoData[pathnameCms].pageHeadingDescription}
                      </div>
                    )}
                  {/* packageInfo for showing the information of package ex- start date,enrolled */}
                  <PackageInfo
                    studentsEnrolled={productData?.studentsEnrolled || 0}
                    examCovered={productData?.examTypes?.length}
                    olcMetaJson={productData?.olcMetaJson}
                    dualValidity={productData?.dualValidity}
                    tags={productData?.tags}
                    examDateTime={productData?.examDate ? getFormattedDate(productData?.examDate) : ''}
                  />
                  {/* coupon applied card mweb */}
                  {!isPurchased && productData?.published && (
                    <div className="appliedCoupon"
                    onClick={openViewOffersModal}
                    >
                      <div>
                        <img
                          src={
                            couponAppliedOrDefaultCouponAvailable
                              ? "/images/pdpdiscountNew.svg"
                              : "/images/pdpdiscountNew.svg"
                          }
                          alt="discount image"
                        />
                        <div className="couponCode">
                          <div>
                              {priceDetails?.appliedCoupon ? <>"{priceDetails?.appliedCoupon}"</> : productData?.defaultCoupons?.code ? <>"{productData?.defaultCoupons?.code}"</> : "Offers Available"}
                          </div>
                          <div id="id1">
                            {couponAppliedOrDefaultCouponAvailable
                              ? priceDetails.appliedCoupon
                                ? "Coupon Applied"
                                : "Coupon Available"
                              : "Add Promocode"}
                          </div>
                        </div>
                      </div>
                      {priceDetails.appliedCoupon ? (
                        <div>
                          {!couponRemoving ? (
                            <div
                              className="removeButton"
                              onClick={(e) => {
                                e.stopPropagation()
                                setRemoveCouponFlag(true);
                                setCouponRemoving(true);
                              }}
                            >
                              <img src="/images/pdpclosecoupon.svg" alt="closeIcon"/>
                            </div>
                          ) : (
                            <CustomLoader buttonSpinner={true} />
                          )}
                        </div>
                      ) : productData?.defaultCoupons ? (
                        <div
                          className="applyText"
                          onClick={(e) => {e.stopPropagation(); setApplyDefaultCouponFlag(true)}}
                        >
                          {applyDefaultCouponFlag ? (
                            <CustomLoader buttonSpinner={true} />
                          ) : (
                            "APPLY"
                          )}
                        </div>
                      ) : (
                        <div
                          className="applyText"
                        >
                          <img src="/images/rightArrow.svg" alt="arrow"/>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="callnow-m">{isMobile && <CallNowComponent/> }</div>
                  <div className="courseDetails">
                    What will<span> you get</span>
                  </div>
                  {
                    <ErrorBoundary>
                      <PackageContent
                        subCategoryData={productData?.subCategories}
                        onClick={openPackageModal}
                      />
                    </ErrorBoundary>
                  }
                  {productData?.multiValidity?.length > 0 ? (
                    <>
                      <div className="selectValidity">
                        <div className="slelectValheading">
                          {productData?.multiValidity?.length > 1
                            ? <>Select Your <span>Validity</span></>
                            : "Validity"}
                        </div>
                        <div className="validitywrap">
                          {/* sort products variants based in asc based on validity */}
                          {productData?.multiValidity?.sort((a, b) => a?.validity - b?.validity)?.map((item, index) => (
                            <button
                              className={
                                activeValidityData?.id === item?.id
                                  ? "selectedValidty"
                                  : "ripple"
                              }
                              onClick={() => handleChangeValidity(item, productData)}
                              key={item?.id}
                            >
                              {item.dualValidity
                                ? `${item.validity / 2}+${item.validity / 2}`
                                : item.validity}{" "}
                              Months
                            </button>
                          ))}
                        </div>
                      </div>
                    
                    </>
                  ) : null}
                </div>
                  <div className="salientCallWrap">
                  {selectedFeaturesVal && selectedFeaturesVal?.length > 0 &&
                    <ErrorBoundary>
                      <SalientFeature
                        salientFeatures={selectedFeaturesVal}
                      />
                    </ErrorBoundary>
                  }
                  {!isMobile && <CallNowComponent/> }
                  </div>
                </div>
                <ErrorBoundary>
                  <div className="pdpCardWrap">
                    <div className="pdpCard">
                      {/* productCard */}
                      <PDPCard
                        isPurchased={isPurchased}
                        published={productData?.published}
                        setPlayVideo={setPlayVideo}
                        playVideo={playVideo}
                        language={language}
                        additionalTag={additionalTag}
                        pdpTemplateUrl={productData?.imgUrl}
                        dualValidity={productData?.dualValidity}
                        demoUrl={productData?.demoUrl}
                        maxPrice={maxPrice}
                        price={price}
                        originalPrice={productData?.price}
                        subCategoriesData={productData?.subCategories}
                        packageId={actual_packageId}
                        isWishList={isWishList}
                        productData={productData}
                      />

                      <AppliedCouponInfo
                        userEmail={userEmail}
                        appliedCoupon={priceDetails?.appliedCoupon}
                        couponDiscount={priceDetails?.discount}
                        Getpayload={Getpayload()}
                        payablePrice={priceDetails?.payablePrice}
                        price={
                          props?.productDetails?.data?.packageEsEntity[0]?.price
                        }
                        getUtmFromStore={props?.utmParams}
                        setRemoveCouponFlag={setRemoveCouponFlag}
                        isDefaultCouponAvailable={
                          productData?.defaultCoupons ? true : false
                        }
                        applyDefaultCouponFlag={applyDefaultCouponFlag}
                        setApplyDefaultCouponFlag={setApplyDefaultCouponFlag}
                        defaultCouponCode={productData?.defaultCoupons?.code}
                        isPurchased={isPurchased}
                        published={productData?.published}
                        published_date = {productData?.createdAt}
                        modified_date = {productData?.updatedAt}
                      />

                       <div className="pdpMoneyBack">
                      
                      </div> 
                      <div className="pdpBottomBtns">
                        <div className="pdpbtn pdpBuyNowbtn">
                          {renderBuyNowComponent(props)}
                        </div>
                        {productData?.published && (
                          <div className="pdpbtn pdpOfferbtn">
                            {renderViewOffers(props)}
                          </div>
                        )}
                         {isBusinessTagVisible && (
                          <BusinessTag
                            iconSrc={recentlyAssignedTag?.thumbnail}
                            tagType={recentlyAssignedTag?.tagType}
                            name={recentlyAssignedTag?.name}
                            endTime={recentlyAssignedTag?.endTime || 0}
                            setIsBusinessTagVisible={setIsBusinessTagVisible}
                          />
                          )} 
                      </div>
                    </div>
                  </div>
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </section>

        {/* Product Description Area */}
        {productData && productData?.categoryList && (productData?.categoryList?.includes("ONLINE_LIVE_CLASSES") || productData?.categoryList?.includes("VIDEOS")) ? 
          <CarouselSchema 
            data = {productData} 
            origin = {props?.origin}
            schemaType = "Course"
          /> : ""
        }
        <ErrorBoundary>
          <section className="pdpDisWrap">
            <div className="pdp-container">
              {/* <!-- Product Description aream --> */}
              <div className="pdpDescriptionWrap">
                {/* Highlights of online Live Classes */}    
                  <HighLights hlsJson={productData?.hlsJson} />      
                  <ExamCovered coveredExamTypes={productData?.examTypes} />
                {productData?.freeContentAvailable && (
                  <FreeContent
                    productData={productData}
                    ProductDataId={productData?.id}
                    freeContentData={productData?.freeContentData}
                    utmParams = {props?.utmParams}
                    actualPackageId={actual_packageId}
                  />
                )}
                {productData?.subCategories &&
                  productData?.subCategories?.length > 0 && (
                    <CourseIncludes
                      subCategoriesData={productData?.subCategories}
                      onClick={openPackageModal}
                    />
                  )}
                  <div className="pdpFacSliderWrap">
                    {productData?.faculties && <FacultyProfileSlider facultyData={productData?.faculties} Getpayload= {Getpayload} />}
                  </div>
              </div>
            </div>
          </section>
        </ErrorBoundary>
        <ErrorBoundary>
          <section className="pdpOverviewWrap">
          {
          showTopArrow && 
            <div className= "pdp-arrow-top" >
              <button onClick={()=> scrollToTop()}>Top</button>
            </div>
           } 
           {/* Faculty Slider */}
            {/* ProductDescription */}
            <ProductDetailDescription
              Getpayload = {Getpayload()}
              description={productData?.description}
              overview={productData?.overview}
              additionalDesc={productData?.additionalDesc}
              mahapackJson={productData?.mahapackJson}
              title={productData?.title}
              faqJson={faqData}
              testimonialsData={productData?.testimonialJson}
              subCategoriesData={productData?.subCategories}
              isPurchased = {isPurchased}
              isMobile={isMobile}
              showOverlay={showOverlay}
            />
             {faqData?.length > 0 && 
             <ErrorBoundary><FAQSection faqJson={faqData} /></ErrorBoundary>
            }
        {productData?.testimonialJson &&
          productData?.testimonialJson?.length > 0 && (
            <ErrorBoundary>
              <PDPTestimonial testimonialsData={productData?.testimonialJson} />
            </ErrorBoundary>
          )}
          
          </section>
        </ErrorBoundary>
       
        <ErrorBoundary>
          <PDPContactSupport
            contact={productData?.supportNumber}
            deviceType={deviceType}
            Getpayload={Getpayload()}
            Position="Bottom"
            examCategoryName={productData?.title}
            examName= {productData?.courseList[0]}
            subCatData={ productData?.subCategories[0]?.category}
            utmParams ={props?.utmParams}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          {/* <!--mobile Footer Stripe--> */}
          <div className="buyFooterFix">
            <BuyNowComponentMweb
              maxPrice={maxPrice}
              price={price}
              originalPrice={productData?.price}
              isPurchased={isPurchased}
              renderBuyNowComponent={renderBuyNowComponent(props)}
              renderViewOffers={renderViewOffers(props)}
              />
          </div>
        </ErrorBoundary>
      </div>
    </>
  );
};

ProductDetails.fetching = ({ dispatch, match }) => {
  return [
    dispatch(getProductDetail(match.params.id)),
    //dispatch(getPackageOffering(match.params.id))
  ];
};

const mapStateToProps = (state) => {
  const { productDetails } = state;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;

  let req_url = state.req_url;
  let seoData = state.seoData;
  let contacts = state.header.contacts;
  let origin = state.header.origin;
  return {
    productDetails,
    userAddress: productDetails.userAddress,
    loggedUserDetails: state.header.userInfo || {},
    utmParams,
    isLoggedIn,
    userInfo,
    req_url,
    seoData,
    isPaytmMini: state.isPaytmMini,
    contacts,
    origin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (productId) => dispatch(getProductDetail(productId)),
    getPackageOffering: (productId) => dispatch(getPackageOffering(productId)),
    clearProductDetailsData: () => dispatch(clearProductDetailsData()),
    getPackageMahapack: (productId) => dispatch(getPackageMahapack(productId)),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
);

import React, { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import AlertToolTipButton from "../../components/library/AlertToolTipButton";
import { loginFlag } from "../../data/ducks/header/actions";
import { showButtonToolTip } from "../../data/ducks/toast/actions";
import {
  addRemoveWishList,
  setConfirmDeletWishList,
  setLastClickedWishlistItemInfo,
  setUserFirstWish,
  setWishListedPackage,
} from "../../data/ducks/wishlist/actions";
import {
  checkLoginStatus,
  fireWishListMoengageEvent,
  getUpdatedNewWishListStore,
} from "../../data/utils/helpers";
const cookie = new Cookies();

/**
 * A component that allows users to toggle items in their wishlist.
 *
 * @param {object} props - The component's props.
 * @param {number} props.packageId - The ID of the package.
 * @param {boolean} props.isWishList - Indicates whether the package is in the wishlist.
 * @param {boolean} props.wishlistOperationAllowed - Indicates whether wishlist operations are allowed.
 */
const WishListToggler = ({
  packageId = 0,
  isWishList = false,
  wishlistOperationAllowed = true,
  confirmRemoveWishList = false,
  moengagePayload = {},
  source_screen = "",
}) => {
  const [wishListLoad, setWishListLoad] = useState(false);
  const { wishlistPackageData } = useSelector((state) => state?.wishlist);

  const dispatch = useDispatch();

  /**
   * Updates the wishlist store based on the current state of the wishlist.
   *
   * @param {boolean} isWishList - Indicates whether the package is in the wishlist.
   * @param {number} packageId - The ID of the package to add or remove.
   */
  const updateNewWishListStore = (isWishList, packageId) => {
    const newWishListState = getUpdatedNewWishListStore(
      isWishList,
      packageId,
      wishlistPackageData
    );
    // Dispatch the updated wishlist state to the store
    dispatch(setWishListedPackage(newWishListState));
    const previousLikeCount = wishlistPackageData?.likeCount;
    if (previousLikeCount === 0 && newWishListState?.likeCount === 1) {
      dispatch(setUserFirstWish(true));
    }

    const eventName = isWishList ? "item_unwishlisted" : "item_wishlisted";
    const products_wishlisted_count = newWishListState?.likeCount;
    // fire wishlist moengage
    onWishListMoengageEvent(eventName, products_wishlisted_count);
  };
  // wishlist moengage event handler
  const onWishListMoengageEvent = (eventName = "", likeCount = 0) => {
    const payload = {
      ...moengagePayload,
      products_wishlisted_count: likeCount,
    };
    fireWishListMoengageEvent(eventName, payload);
  };

  // add/remove wishlist action handler
  const addRemoveWishListHandler = async (likeType, packageId) => {
    return await addRemoveWishList(likeType, packageId);
  };

  /**
   * Handles the toggle wishlist action.
   *
   * @param {Event} e - The click event.
   * @param {boolean} isWishList - Indicates whether the package is in the wishlist.
   * @param {number} packageId - The ID of the package.
   */
  const toggleWishListHandler = async (
    e,
    isWishList = false,
    packageId = 0
  ) => {
    e.preventDefault(); // Prevent the default link behavior
    // here 1 represent add and 2 represent remove for the likeType
    const likeType = isWishList ? 2 : 1;
    dispatch(
      setLastClickedWishlistItemInfo({
        likeType,
        packageId,
      })
    );
    // show login popup if user not login on perform like
    let loginStatus = checkLoginStatus();
    if (!loginStatus) {
      dispatch(loginFlag(true));
      return;
    }
    const WISHLIST_MAX_LIMIT = 100;
    if (!wishlistOperationAllowed || wishListLoad) return;
    // max limit reach validation on add to wishlist
    if (!isWishList && wishlistPackageData?.likeCount === WISHLIST_MAX_LIMIT) {
      dispatch(
        showButtonToolTip({
          isToolTipVisible: true,
          toolTipName: `ITEM_WL_${packageId}`,
          tooltipText: "You’ve reached the maximum limit for wishlist",
        })
      );
      return;
    }
    // here by pass the direct action of wishList,when confirmRemoveWishList flag is true and item isWishList
    if (confirmRemoveWishList && isWishList) {
      dispatch(
        setConfirmDeletWishList({
          visible: true,
          source_screen,
        })
      );
      return;
    }
    const wishListToastMsg = isWishList
      ? "Item removed from Wishlist"
      : "Item added to Wishlist";
    // Toggle wishlist state
    try {
      setWishListLoad(true);
      await addRemoveWishListHandler(likeType, packageId);
      dispatch(
        showButtonToolTip({
          isToolTipVisible: true,
          toolTipName: `ITEM_WL_${packageId}`,
          tooltipText: wishListToastMsg,
        })
      );
      //   update new wishlist store
      updateNewWishListStore(isWishList, packageId);
      setWishListLoad(false);
    } catch (error) {
      setWishListLoad(false);
      dispatch(
        showButtonToolTip({
          isToolTipVisible: true,
          toolTipName: `ITEM_WL_${packageId}`,
          tooltipText: error,
        })
      );
    }
  };
  return (
    <AlertToolTipButton
      toolTipName={`ITEM_WL_${packageId}`}
      iconElement={
        <div
          className={`listingCardWishlist ${
            isWishList ? "activeWhishlist" : ""
          }`}
        />
      }
      tooltipText="Item added to Wishlist"
      iconClassName="wLBtn"
      tooltipClassName="wl-tooltip"
      onClickCb={(e) => toggleWishListHandler(e, isWishList, packageId)}
      isHoverToolTipEnable={false}
    />
  );
};

export default WishListToggler;

import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import fetch from "../../../data/utils/fetch";
import CustomLoader from "../../library/CustomLoader";
import CustomModal from "../../library/CustomModal";
import ATooltip from '../../library/ATooltip';
import { STOREFRONT_URI, pageName, stagingMainUrl, testResultOrigin, testSeriesURLProd } from "../../../../constants/appConfig";
import TestResultsComparison from "./TestResults/TestResultsComparison";
import TestSectionResults from "./TestResults/TestSectionResults";
import { secondToHMS, overAlldate } from "../../utils";
import CustomHelmet from "../../library/CustomHelmet";
import { connect, useSelector } from "react-redux";
import { getMoengageMetadata, getParameterByName, isADDA, testWithOnlyDtb } from "../../../data/utils/helpers";
import { TEST_SERIES_URL } from "../../../../constants/appUrls";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DTBResullInfo from "./TestResults/DTBResullInfo";



var freeContentFlag;
var fixedMockFlag;
if (typeof window !== "undefined") {
const urlParams = new URLSearchParams(window.location.search);
freeContentFlag =  urlParams.get("freeContent");
fixedMockFlag =  urlParams.get("fixedMockResult");
}
const TestResultOverview = ({
  data,
  topperData,
  averageData,
  toppersList,
  rating,
  setRating,
  userDetails,
  mappingId,
  packageId,
  moengageData,
  sectionData,isPPC,
  isFixedMock,
  title
}) => {
  const [feedback, setFeedback] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [responseText, setResponseText] = useState();
  const [feedbackUpdated, setFeedbackUpdated] = useState(false);
  const { search } = useLocation();
  const sfJson = new URLSearchParams(search).get("sfJsonPreview");
  useEffect(() => {
    if (rating == undefined) {
      setFeedback(true);
      // setRating(-1);

    }
  }, [rating]);
  // {"packageId":"3710","mappingId":"76822","responseText":"some text","starRating":3,"selectedIds":["1"]}
  const closeFeedback = () => {
    if (!feedbackUpdated) {
      setRating(-1);
    }
    setFeedback(false);
  };
  const openFeedback = (value) => {
    setRating(value);
    setFeedback(true);
    var payloadvalue = moengageData;
    var payload = {};
    payload.package_status = "Paid/Free";
    payload.contentTitle = payloadvalue.title;
    payload.contentID = payloadvalue.testId;
    payload.id = payloadvalue.id;
    payload.action = "rate_submitted";
    payload.isPPc=isPPC;
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'test',
      getMoengageMetadata(
        payload
      ),
    );
  };
  const updateFeedback = () => {
    let payload = {
      packageId: packageId,
      mappingId: mappingId,
      responseText: responseText,
      starRating: rating + 1,
      selectedIds: rating > 2 ? [] : selectedIds,
    };
    fetch(
      `${STOREFRONT_URI}/api/v1/feedback`,
      // `https://store.adda247.com/api/v1/test-series/view-result?packageId=${props.match.params.packageId}&mappingId=${props.match.params.mappingId}`,
      "POST",
      payload
    ).then(
      (res) => {
        // setIsCouponLoading(false);
        if (res.success) {
          setFeedbackUpdated(true);
        }
      },
      (err) => {
        setFeedbackUpdated(false);
      }
    );
  };
  const scrollToTopRankers = () => {
    var element = document.getElementById("top_rankers");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    // location.hash='#top_rankers'
  };
  const selectedResponse = (value) => {
    let updatedResponse = [...selectedIds];
    let indexOfValue = updatedResponse.indexOf(value);
    if (indexOfValue == -1) {
      updatedResponse.push(value);
    } else {
      updatedResponse.splice(indexOfValue, 1);
    }
    setSelectedIds(updatedResponse);
  };
  const d = new Date(data?.dateAttempted);

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  let date;
  if(isValidDate(d)){
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
     date = `${da} ${mo} ${ye}`;
  }
  const imagesForRating = {
    1: "one",
    2: "one",
    3: "three",
    4: "four",
    5: "five",
  };

  
  let totalTimeSpent = sectionData?sectionData?.reduce((totTime,section)=>{ 
    if (section){
      totTime+=Math.floor((section?.timeSpent/1000))
    }
    return totTime;
   },0):Math.floor((data?.timeSpent/1000));
  return (
    <>
      {(isFixedMock && data?.percentile === undefined) || (freeContentFlag && fixedMockFlag ) ? "" : <div>
        <CustomModal
          title={
            feedbackUpdated ? (
              ""
            ) : (
                 <div className="feedback-modal-header">
                  <span>Rate this test</span>
                  <span>How was your experience with this test</span>
                </div>
              )
          }
          visible={feedback}
          onCancel={closeFeedback}
          modalClassName={"feedback-modal"}
          closeCallback={closeFeedback}
        >
          {feedbackUpdated ? (
            <div className="feedback-content">
              <div className="feedback-image">
                <img
                  height="120px"
                  src={`https://storecdn.${pageName[0]}.com/ic_rating_success.svg`}
                />
              </div>
              <div className="feedback-updated-header">Thank You!</div>
              <div className="feedback-updated-text">
                We have received your feedback. It will help us improve your
                experience.
              </div>
            </div>
          ) : (
              <div className="feedback-content">
                <div className="feedback-image">
                  <img
                    height="120px"
                    src={`https://storecdn.${pageName[0]}.com/ic_rating_${
                      imagesForRating[rating + 1] || 'zero'
                      }.svg`}
                  />
                </div>
                <div className="feedback-star-rating">
                  {Array(5)
                    .fill(1)
                    .map((val, index) => {
                      return (
                        <span
                          onClick={() => {
                            openFeedback(index);
                          }}
                        >
                          <img
                            height="40px"
                            src={
                              (rating == undefined || index > rating)
                                ? "/images/star-grey.png"
                                : "/images/yellow-star.png"
                            }
                          ></img>
                        </span>
                      );
                    })}
                </div>
                {(rating != undefined && rating > -1 && rating <= 2) && (
                  <div className="feedback-category">
                    <div className="feedback-category-header">
                      What did you not like?
                  </div>
                    <div className="feedback-category-list">
                      {[
                        "Wrong Questions",
                        "Interface",
                        "No Explaination",
                        "Timer Issue",
                        "Mock Level",
                        "Content Quality",
                      ].map((val, index) => {
                        return (
                          <button
                            className={
                              selectedIds?.includes(index + 1)
                                ? "feeback-category-active"
                                : ""
                            }
                            onClick={() => {
                              selectedResponse(index + 1);
                            }}
                          >
                            {val}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}

                {rating == undefined ? <div className="feedback-action rate-later">
                  <button onClick={closeFeedback}>Rate Later</button>
                </div> : <>
                    <div className="feedback-response">
                      <textarea
                        value={responseText}
                        onChange={(e) => {
                          setResponseText(e.target.value);
                        }}
                        rows="4"
                        cols="12"
                        placeholder="We’re listening. Tell us more…"
                      ></textarea>
                    </div>
                    <div className="feedback-action">
                      <button onClick={updateFeedback}>SUBMIT FEEDBACK</button>
                    </div>
                  </>}
              </div>
            )}
        </CustomModal>
      </div>}
      <div className="tr-overview">
      {isFixedMock && data?.percentile === undefined ? "" :
      <>
      { data?.dtbQuestotalCount > 0 && <p className="tr-sub-info">
       <img src={'/images/test-solution/info-icon.svg'} alt="tick-blue.svg" />
        Subjective questions({data?.dtbQuestotalCount}) are not included for analysis</p>}
        <div className="tr-overview-row overview-height">
          {/* overview */}

          <div className="tr-overview-card ">
            <div className="tro-card-header">
              <span>Name</span>
              <span>
              <img src={`https://storecdn.${pageName[0]}.com/ic_name.svg`} />
              </span>
            </div>
            <div className="tro-card-info">
              <span>{userDetails?.name}</span>
              <span></span>
            </div>
            <div className="tro-card-details">
              <div>
                <span>Attempted On</span>
                <span>{date || ''}</span>
              </div>
              <div className="reattempt" onClick={() => {
                  let  url = `/test-series-portal/${mappingId}/${packageId}/REATTEMPT/${encodeURIComponent(title)}?freeContent=true${sfJson ? `&sfJsonPreview=${sfJson}`:''}`
                  window.open(
                    url,
                    "_blank",
                    "height=" +
                    screen.height +
                    ",width=" +
                    screen.width +
                    ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
                  )}}>Re-Attempt</div>
            </div>
          </div>

          <div className="tr-overview-card ">
            <div className="tro-card-header">
              <span>Marks Scored</span>
              <span>
              <img src={`https://storecdn.${pageName[0]}.com/ic_marks.svg`} />
              </span>
            </div>
            <div className="tro-card-info">
              <span>{data?.marks?.toFixed(2)}</span>
              <span>/ {isFixedMock && data?.maximumMarks ? data?.maximumMarks : data?.totalMarks - data?.dtbQuestotalMarks || 0}</span>
            </div>
            <div className={freeContentFlag ? "displayNone" : 'tro-card-details'}>
            {data?.cutOffMarks > 0 &&  <div>
                <span>Cut Off Marks</span>
                <span>{data?.cutOffMarks}</span>
              </div>}
              <div>
                <span>Highest Score</span>
                <span>{isFixedMock ? toppersList[0]?.totalMarksObtained : topperData?.overall?.marks}</span>
              </div>
              {!isFixedMock && <div>
                <span>Average Score</span>
                <span>{averageData?.averageScore}</span>
              </div>}
            </div>
          </div>
          <div className={ freeContentFlag ? "displayNone" : 'tr-overview-card '}>
            <div className="tro-card-header">
              <span>Rank</span>
              <span>
              <img src={`https://storecdn.${pageName[0]}.com/ic_rank.svg`} />
              </span>
            </div>
            <div className="tro-card-info">
              <span>{isFixedMock? data?.rank : data?.overallRank }</span>
              <span>/ {data?.totalCandidates}</span>
            </div>
            <div
              className="tro-card-details view-rankers"
              onClick={scrollToTopRankers}
            >
              <div>
                VIEW TOP RANKERS{" "}
                <img src={`https://storecdn.${pageName[0]}.com/ic_arrow_down_pink.svg`}></img>
              </div>
            </div>
          </div>
          <div className="tr-overview-card ">
            <div className="tro-card-header">
              <span>Time Spent</span>
              <span>
              <img src={`https://storecdn.${pageName[0]}.com/ic_time_spent.svg`} />
              </span>
            </div>
            <div className="tro-card-info">
              <span>{secondToHMS(totalTimeSpent, ["h ", "m ", "s "])}</span>
              <span>/ {data?.totalTime / 60} m</span>
            </div>
            {/* <div className="tro-card-details">
              <div>
                <span>Attempted On</span>
                <span>May 4,2020</span>
              </div>
            </div> */}
          </div>
        </div>
      </>}
        {isFixedMock && data?.percentile === undefined
        ?
        <div className="reattempt-wrap">
          <div className="reattempt-heading">You've not Attempted the test</div>
         <div className="reattempt" onClick={() => {
                  let  url = `/test-series-portal/${mappingId}/${packageId}/REATTEMPT/${encodeURIComponent(title)}?freeContent=true`
                  window.open(
                    url,
                    "_blank",
                    "height=" +
                    screen.height +
                    ",width=" +
                    screen.width +
                    ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
                  )}}>ATTEMPT NOW</div>
        </div> :
        <div className="tr-overview-row">
          <div className="tr-overview-card tr-overview-percentiles">
            <div className={freeContentFlag ? "displayNone" : 'percentile-item'}>
              <div className="percentile-label">Percentile</div>
              <div className="percentile-chart">
                <CircularProgressbar
                  strokeWidth={3}
                  value={data?.percentile}
                  text={`${data?.percentile}%`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "#ff4081",
                    pathColor: "#ff4081",
                  })}
                />
              </div>
            </div>
            <div className={"percentile-item"}>
              <div className="percentile-label">Accuracy</div>
              <div className="percentile-chart">
                <CircularProgressbar
                  strokeWidth={3}
                  value={data?.accuracy}
                  text={`${data?.accuracy}%`}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "#03a9f4",
                    pathColor: "#03a9f4",
                  })}
                />
              </div>
            </div>
            <div className="percentile-item">
              <div className="percentile-label">Attempted</div>
              <div className="percentile-chart">
                <CircularProgressbar
                  value={data?.attempted}
                  text={`${data?.attempted}%`}
                  strokeWidth={3}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    textColor: "#2ace77",
                    pathColor: "#2ace77",
                  })}
                />
              </div>
            </div>
          </div>
        </div>}
        {(isFixedMock && data?.percentile === undefined ) || (freeContentFlag && fixedMockFlag )? "" : <div className="tr-overview-row">
          <div className="tr-overview-card tr-overview-feedback">
            <div className="feedback-text">
              <div className="feedback-text-header">
                {(rating && rating != -1) ? "You rated this test" : "Rate this test"}{" "}
              </div>
              <div className="feedback-text-info">
                {(rating && rating != -1)
                  ? "We have received your feedback. It will help us improve your experience."
                  : "Tell us your experience with this test. It will help us improve."}
              </div>
              <div>
                {Array(5)
                  .fill(1)
                  .map((val, index) => {
                    return (
                      <span
                        onClick={() => {
                          if (rating == -1) {
                            setFeedback(true);
                            setRating(index);
                          }
                        }}
                      >
                        <img
                          height="40px"
                          src={
                            (rating == undefined || index > rating)
                              ? "/images/star-grey.png"
                              : "/images/yellow-star.png"
                          }
                        ></img>
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className="feedback-icon">
              <img
                height="100px"
                src={`https://storecdn.${pageName[0]}.com/ic_rating_zero.svg`}
              ></img>
            </div>
          </div>
        </div>}
        <div className={ freeContentFlag ? "displayNone" : 'top-rankers-text'}>TOP RANKERS</div>
        <div className={freeContentFlag ? "displayNone" : 'tr-overview-row topper-list'}>
          {toppersList && toppersList?.map((topperInfo, index) => {
            return (
              <div
                className="tr-overview-topper"
                id={index == toppersList?.length - 1 ? "top_rankers" : ""}
              >
                <div className="tr-overview-card topper-item">
                  <div className="topper-info">
                    <div className="topper-name"> {topperInfo?.name}</div>
                    <div>
                      Score: {topperInfo?.totalMarksObtained} / {isFixedMock && toppersList[0]?.maximumMarks ? toppersList[0].maximumMarks :  data?.totalMarks - data?.dtbQuestotalMarks|| 0}
                    </div>
                  </div>
                  <div className="topper-avatar">
                    <div className="name-avatar">
                      <span>
                        {topperInfo?.name && topperInfo?.name[0]?.toUpperCase()}
                      </span>
                    </div>
                    <div className="rank-avatar">
                      <span>{index + 1}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const TestResult = (props) => {
  
  const [moengageData, setMoengageData] = useState()
  const [testResult, setTestResult] = useState();
  const [feedbackData, setFeedbackData] = useState();
  const [isPPC,setIsppc]=useState()
  const [loadingFeedback, setLoadingFeedBack] = useState(true);
  const [rating, setRating] = useState(undefined); // not in a mood to change intial value now;
  const [isFixedMock, setIsFixedMock] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  let { mappingId, packageId, title } = useParams();
  const dtbContentFlag = new URLSearchParams(search).get("dtb");
  const sfJson = new URLSearchParams(search).get("sfJsonPreview");

  const initialiseSection = () => {
    if(history?.location?.state?.section === "solution"){
      window.history.replaceState({}, document.title)
      if(fixedMockFlag){
        return 1;
      }else if(freeContentFlag){
        return 2;
      }else{
        return 3;
      }
    }else{
      return 0;
    }
  }
  const [selectedSection, setSelectedSection] = useState(initialiseSection());

  const moengageViewResult = (res) => {
    var data = {};
    var sectionalData = [];
    data.attemptedDate = overAlldate(res?.data?.overall?.dateAttempted);
    data.package_status = "Paid/Free";
    data.title = props?.match?.params?.title;
    data.testId = props?.match?.params?.mappingId;
    data.id = props?.match?.params?.packageId;
    data.action = "analysis viewed";
    data.rating = rating;
    data.markScored = res?.data?.overall?.marks;
    data.timeSpent = res?.data?.overall?.timeSpent;
    data.totalTime = res?.data?.overall?.totalTime;
    data.attemptedPercentage = res?.data?.overall?.attempted;
    data.correct_qnCount = res?.data?.sections?.reduce((total, value) => {
      return total + value?.correctAnswerCount;
    }, 0);
    data.incorrect_qnCount = res?.data?.sections?.reduce((total, value) => {
      return total + value?.incorrectAnswerCount;
    }, 0);
    data.totalQuestions = res?.data?.sections?.reduce((total, value) => {
      return total + value?.totalQuestions;
    }, 0);
    data.unattempted_qnCount = data?.totalQuestions - (data?.incorrect_qnCount + data?.correct_qnCount);
    data.oa_rank = res?.data?.overall?.overallRank;
    data.oa_average_score = res?.data?.testInfo?.averageScore;
    data.oa_highest_score = res?.data?.toppersList && res?.data?.toppersList[0]?.totalMarksObtained;

    res?.data?.sections?.forEach(function (elem) {
      sectionalData.push({
        sec_name: elem?.sectionName,
        sec_rank: elem?.rank,
        sec_accuracy: elem?.accuracy,
        sec_score: elem?.marks,
        sec_timeTaken: elem?.timeSpent
      });
    });
    data.sectionalData = sectionalData
    let ppcstatus=getParameterByName("ppc")
    setMoengageData(data);
   
    setIsppc(ppcstatus)
    data.isPPc=ppcstatus;
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      '"test_submitted_scoreData',
      getMoengageMetadata(
        data
      ),
    );
    setTestResult(res.data);
  }


  const userDetails = useSelector(store => store.header.userInfo ? store.header.userInfo : {});
  const userId = userDetails ? userDetails.id : '';
  const [fixedMockData, setFixedMockData] = useState(null);
  const [topperData, setTopperData] = useState({});
  const [topperList, setTopperList] = useState(null);
  const [userData, setUserData] = useState({});
  const [userRank, setUserRank] = useState({});

  const jwtTokenNew = useSelector(state => state.header.jwtTokenNew);


    const getTopperData = () => {
      let baseUrl;
      if (TEST_SERIES_URL === testSeriesURLProd){
        baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
      }else{
        baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
      }
      fetch(
        `${baseUrl}/${props.match.params.packageId}/${props.match.params.mappingId}/results/topper.json`,
        "GET").then((res) => {
            if (res) {
              setTopperData(res[0]);
              setTopperList(res);
              getUserData();
            }
          }
        ).catch((err) => console.log(err))
      }

    const getUserData = () => {
      let baseUrl;
      if (TEST_SERIES_URL === testSeriesURLProd){
        baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
      }else{
        baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
      }
      fetch(
        `${baseUrl}/${props.match.params.packageId}/${props.match.params.mappingId}/results/${userId}.json`,
        "GET", undefined, undefined, undefined,
        {
          'mode' : 'no-cors',
          'Access-Control-Allow-Origin' : "*",
          'Origin' : stagingMainUrl?.slice(8),
          'x-jwt-token-new' : jwtTokenNew
        }
      ).then((res) => {
          if (res.data) {
            setUserData(res.data);
            getUserRank();
            getFeedbackInfo();
          }
        },
        ).catch((err) => console.log(err));
    }

    const getUserRank = () => {
      let baseUrl;
      if (TEST_SERIES_URL === testSeriesURLProd){
        baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
      }else{
        baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
      }
      fetch(
        `${baseUrl}/${props.match.params.packageId}/${props.match.params.mappingId}/results/${userId}_rank.json`,
        "GET", undefined, undefined, undefined,
        {
          'mode': 'no-cors',
          'Access-Control-Allow-Origin' : "*",
          'Origin' : stagingMainUrl?.slice(8),
          'x-jwt-token-new' : jwtTokenNew
        }
      ).then((res) => {
          if (res) {
            setUserRank(res);
          }
        },
        ).catch((err) => console.log(err));
    }

  useEffect(() => {
    if(isFixedMock){
      getTopperData();
    }
  },[isFixedMock]);

  useEffect(() => {
    if(topperData && userData && userRank){
      let fixedMockData = {...userRank, ...userData.result}
      setFixedMockData(fixedMockData);
    }
  },[topperData, userData, userRank])

  useEffect(() => {
    // dont run below code for DTB result analysis.
    if(dtbContentFlag){
      return ''
    }
    // -------------
    let testData = localStorage.getItem('testCalcData');
    let testDataParsed  = JSON.parse(testData);
    if(!fixedMockFlag) getFeedbackInfo();

    if(freeContentFlag) {
      if (testDataParsed.resultData) {
         moengageViewResult(testDataParsed?.resultData)
      } 
      return
    }
    if(typeof window !== undefined){
      const urlParams = new URLSearchParams(window.location.search);
      if(urlParams.get("fixedMockResult") && !freeContentFlag) {
        setIsFixedMock(true);
      }
      else{
        fetch(
          `${STOREFRONT_URI}/api/v1/test-series/view-result?packageId=${props.match.params.packageId}&mappingId=${props.match.params.mappingId}`,
          "GET"
        ).then(
          (res) => {
            if (res.data) {
              moengageViewResult(res)
            } else {
              setTestResult(null);
            }
          },
          (err) => {
            setTestResult(null);
          }
        );
      }
    }
  }, []);

  const getFeedbackInfo = () => {
    setLoadingFeedBack(true);
    fetch(
      `${STOREFRONT_URI}/api/v1/feedback?mappingId=${props.match.params.mappingId}&packageId=${props.match.params.packageId}`,
      "GET"
    ).then(
      (res) => {
        setLoadingFeedBack(true);
        // setIsCouponLoading(false);
        setRating(res.data ? +res.data.starRating - 1 : undefined);
        setFeedbackData(res.data);
      },
      (err) => {
        setRating(undefined)
        setLoadingFeedBack(true);
        setFeedbackData(null);
      }
    );
  };
  let component = <></>
  if(!dtbContentFlag){
    component = <CustomLoader />;
  }
  if ((testResult || (fixedMockData && topperList)) && loadingFeedback) {
    switch (selectedSection) {
      case 0:

      if(isFixedMock && fixedMockData) {

        component = (
          <TestResultOverview
            isPPC={isPPC}
            userDetails={props.userDetails}
            data={fixedMockData}
            toppersList={topperList}
            rating={rating}
            setRating={setRating}
            mappingId={props.match.params.mappingId}
            packageId={props.match.params.packageId}
            title={props.match.params.title}
            moengageData={moengageData}
            isFixedMock={isFixedMock}
          />
        );

      }else{
        component = (
          <TestResultOverview
            isPPC={isPPC}
            userDetails={props.userDetails}
            data={testResult?.overall}
            sectionData={testResult?.sections}
            topperData={testResult?.topperInfo}
            averageData={testResult?.testInfo}
            toppersList={testResult?.toppersList}
            rating={rating}
            setRating={setRating}
            mappingId={props.match.params.mappingId}
            packageId={props.match.params.packageId}
            title={props.match.params.title}
            moengageData={moengageData}
          />
        );
      }        

        break;
      case 1:
        component = isFixedMock || fixedMockFlag ?
        <div className="view-solution">
            <ATooltip content={'HEHE'}>
            <button
              onClick={() => {
                props.history.push(
                  `/test-series-portal/${props.match.params.mappingId}/${props.match.params.packageId}/COMPLETED/${encodeURIComponent(props.match.params.title)}${freeContentFlag ? "?freeContent=true" : ''}${isFixedMock ? '?fixedMockResult=true' : ''}${sfJson ? `&sfJsonPreview=${sfJson}`:''}`
                );
              }}
            >
              {" "}
              View Solutions
            </button>
            </ATooltip>
          </div>
        : <TestSectionResults testResult={testResult}  isPPC={isPPC} freeContentFlag={freeContentFlag}/>;
        break;
      case 2:
        component = freeContentFlag ?          <div className="view-solution">
        <ATooltip content={'HEHE'}>
        <button
          onClick={() => {
            props.history.push(
              `/test-series-portal/${props.match.params.mappingId}/${props.match.params.packageId}/COMPLETED/${encodeURIComponent(props.match.params.title)}${freeContentFlag ? "?freeContent=true" : ''}${sfJson ? `&sfJsonPreview=${sfJson}`:''}`
            );
          }}
        >
          {" "}
          View Solutions
        </button>
        </ATooltip>
      </div> : <TestResultsComparison testResult={testResult} isPPC={isPPC} />;

        break;
      case 3:
        component = (
          <div className="view-solution">
            {testResult?.overall?.dtbQuestotalCount > 0 && (
                      <p className="tr-sub-info">
                        <img
                          src={"/images/test-solution/info-icon.svg"}
                          alt="tick-blue.svg"
                        />
                        Subjective questions({testResult?.overall?.dtbQuestotalCount}) are
                        not included for analysis
                      </p>
             )}
            <ATooltip content={'HEHE'}>
            <button
              onClick={() => {
                props.history.push(
                  `/test-series-portal/${props.match.params.mappingId}/${props.match.params.packageId}/COMPLETED/${encodeURIComponent(props.match.params.title)}${freeContentFlag ? "?freeContent=true" : ''}${sfJson ? `&sfJsonPreview=${sfJson}`:''}`
                );
              }}
            >
              {" "}
              View Solutions
            </button>
            </ATooltip>
          </div>
        );
        break;
    }
  }
  else if(dtbContentFlag){
  component = <DTBResullInfo mappingId={mappingId} packageId={packageId} title={title} freeContentFlag={freeContentFlag} sfJson={sfJson}/>
  }
  return (
    <div
    style={{
      position: "relative",
      top: "20px",
      display: "flex",
      flexDirection: "row",
    }}
  >
    <CustomHelmet title={`${pageName[1]} Store | ${pageName[1]} Store`} />
    <div className="test-result">
      {!dtbContentFlag ? 
        <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="test-result-logo-result">
            <div onClick={() => window.open("/")}>
            {isADDA ?
                    <img 
                      src="/images/header-logo.svg" 
                      class="logo-img" 
                      alt={`${pageName[0]}-logo`} 
                      title={pageName[0]} 
                      width="172px" 
                      height="40px" 
                    />
                   : <img
                      src={"/images/sankalpB-logo.svg"}
                      className="logo-img"
                      alt={`${pageName[0]}-logo`}
                      title={pageName[0]}
                  />}
            </div>
          </div>
          <div className="test-result-header">
            TEST RESULT ANALYSIS :{" "}
            <span className="test-title">
              {decodeURIComponent(props.match.params.title || "")}
            </span>
          </div>
        </div>
          <div className="tr-nav">
            <div className="tr-nav-items">
            { (freeContentFlag && !fixedMockFlag ? ["OVERVIEW", "SECTION REPORTS", "SOLUTIONS"] : isFixedMock || fixedMockFlag ? ["OVERVIEW", "SOLUTIONS"] : ["OVERVIEW", "SECTION REPORTS", "COMPARE YOURSELF", "SOLUTIONS"]).map(
            (label, index) => {
              return (
                <div
                  className={selectedSection == index ? "tr-active" : ""}
                  onClick={() => setSelectedSection(index)}
                >
                  {label}
                </div>
              );
            }
          )}
            </div>
          </div>
        </>
        : null
      }
      {/* Body     */}
      {component}
    </div>
  </div>
  );
};
const mapStateToProps = (state) => ({
  userDetails: state.header.userInfo || {},
});
export default connect(mapStateToProps, null)(TestResult);
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { requestHeaders } from "../../data/utils/fetch";
import { hiddenFpmIds, TIMELINE_URI } from "../../../constants/appUrls";
import TestimonialCarouselNew from "../library/TestimonialCarouselNew";
import { testEventsUrl } from "../../../constants/appConfig";
import { dateMonthFullTime } from "../utils";
import { getDeviceType } from "../../data/utils/helpers";
import { DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
import { FixedMockConstants } from "../../../constants/textConstants";
import { openTestPortal } from "../enrolledCourses/TestSeries/TakeTest/util";

const FixedMockButton = (
  {
    quiz,
    quizCompletionData,
    quizCompletionStatus,
    registrationData,
    index,
    currentTime,
    quizState,
    handleAppRedirectPopup
  }) => {

  const history = useHistory();
  const userDetails = useSelector(store => store.header.userInfo ? store.header.userInfo : {});

  const openTestWindow = () => {
    let url = `/test-series-portal/${quiz.mappingId}/${quiz.fixedMockPkg}/new/${quiz.title}`
    if (quizState[index] === FixedMockConstants.AttemptNow || quizState[index] === FixedMockConstants.Resume) {
      window.open(
        url,
        "_blank",
        "height=" +
        screen.height +
        ",width=" +
        screen.width +
        ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
      );
    }
  }

  const handleAppRedirect = () => {
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (getDeviceType() !== "d" && !iOS) handleAppRedirectPopup(quiz.mappingId);
    else openTestWindow()
  }

  return (
    <>
      <div className={"sch-home-register-btn " + (quizState[index] === FixedMockConstants.Registered ? "registeredBtnActive" : "") + (quizState[index] === FixedMockConstants.ResultAwaited ? "resultAwaitedButton" : "")}>
        <Link
          className={`ripple`}
          onClick={() => {
            if (userDetails.email) {
              if (quizState[index] === FixedMockConstants.AttemptNow) {
                history.push(`/fpm/${quiz.mappingId}`);
              }
              else if (quizState[index] === FixedMockConstants.Resume) {
                handleAppRedirect();
              }
              else if (quizState[index] === FixedMockConstants.ResultAwaited) {
                // do nothing
              }
              else {
                history.push({
                  pathname: `/fpm/${quiz.mappingId}`,
                  state: { registrationStatus: quizState[index], src: "/" }
                });
              }
            }
            else {
              localStorage.setItem('GoToQuizRegister', true);
              localStorage.setItem('urlAfterLogin', '/fpm/' + quiz.mappingId);
              history.push("/#show-login");
            }
          }}>
          {quizState[index]}
        </Link>
      </div>
    </>
  );
}

export default function FixedMockBanner({ handleAppRedirectPopup }) {

  const history = useHistory();

  const [data, setData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);
  const [quizCompletionData, setQuizCompletionData] = useState([]);
  const [quizState, setQuizState] = useState([]);
  const [centerIndex, setCenterIndex] = useState(null);
  const [auth, setAuth] = useState(false);
  const [allTestIds, setAllTestIds] = useState('');
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [timeRemainingArray, setTimeRemainingArray] = useState([]);


  var mappingIds = '';
  const userDetails = useSelector(store => store.header.userInfo ? store.header.userInfo : {});

  const getDate = (time) => {

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const t = new Date(time);

    const minutes = t.getMinutes();
    const hours = t.getHours();
    const day = t.getDate();
    const month = t.getMonth();

    const minutesFormated = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString();
    const daySuffix = day % 10 == 1 && day != 11 ? 'st' : (day % 10 == 2 && day != 12 ? 'nd' : (day % 10 == 3 && day != 13 ? 'rd' : 'th'));
    const date = hours.toString() + " " + day.toString() + " " + month.toString();

    return {
      hours: hours <= 12 ? hours.toString() + ":" + minutesFormated + "AM" : (hours - 12).toString() + ":" + minutesFormated + "PM",
      day: day.toString() + daySuffix,
      month: months[month]
    };
  }

  const getTimeObjTopCard = (time) => {
    let dateTime = dateMonthFullTime(time);
    let splittedArray = dateTime.dateMonthTime.split(" ");
    return splittedArray;
  };
  const getMappingIds = () => {
    data.forEach(quiz => {
      let id = quiz.mappingId.toString();
      mappingIds += id + ',';
    });
  }

  const getRibbonText = (state) => {
    switch (state) {
      case FixedMockConstants.Register:
        return FixedMockConstants.ComingSoon;
      case FixedMockConstants.Registered:
        return FixedMockConstants.ComingSoon;
      case FixedMockConstants.AttemptNow:
        return FixedMockConstants.AttemptNow;
      case FixedMockConstants.Resume:
        return FixedMockConstants.AttemptNow;
      case FixedMockConstants.ResultPublished:
        return FixedMockConstants.ResultPublished;
      default:
        return FixedMockConstants.ResultAwaited;
    }
  }

  let options = {
    method: "GET",
    headers: requestHeaders(),
  };

  useEffect(() => {
    if (options?.headers["X-JWT-Token"]) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [options]);

  //For Updating Banner State
  useEffect(() => {
    const fun = () => {
      if (localStorage.getItem("isTestPortalOpened") == "yes" && auth) {
        // test window opened for fixed mock
        localStorage.setItem("isTestPortalOpened", "no");
        getQuizCompletionStatus(`${TIMELINE_URI}/fixedMock/status?mappingIds=${allTestIds}`);
      }
    }
    window.addEventListener("focus", fun);

    return () => {
      window.removeEventListener("focus", fun);
    }
  }, [allTestIds]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, [1000]);

    return () => { clearInterval(interval) }
  }, []);

  const formatMilliseconds = (milli) => {
    let seconds = Math.floor(milli / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    const secString = seconds < 10 ? "0" + seconds : seconds;
    const minString = minutes < 10 ? "0" + minutes : minutes;
    const hourString = hours < 10 ? "0" + hours : hours;
    return `${hourString}:${minString}:${secString}`;
  }

  const getRemainingTime = (timeLimit, submitTime, userStartTime) => {
    if (!userStartTime) return 0;
    const timeElapsed = currentTime - userStartTime;
    const remainingTime = Math.min(timeLimit * 1000 - timeElapsed, submitTime - currentTime);
    if (remainingTime < 0) {
      return 0;
    }
    return remainingTime;
  }

  //Fetch Data from APIs:

  const fetchData = (url) => {
    fetch(url)
      .then((res) => res.json())
      .then((result) => {
        const filteredList = result?.data?.filter(fpm => {
          let hide = false;
          hiddenFpmIds?.forEach(hiddenFpmId => {
            if (fpm.mappingId === hiddenFpmId) {
              hide = true;
            }
          })
          return !hide;
        });
        setData(filteredList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRegistrationStatus = (url) => {
    fetch(url, options)
      .then((res) => res.json())
      .then((result) => {
        if (result.success && result.data) {
          setRegistrationData(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuizCompletionStatus = (url) => {
    fetch(url, options)
      .then((res) => res.json())
      .then((result) => {
        if (result.success && result.data) {
          setQuizCompletionData(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectToMockListing = () => {
    if (auth) {
      localStorage.setItem('freeQuizTabId', 2);
      history.push(
        {
          pathname: '/my-mock-test',
          state: { 'freeQuizTabId': 2 }
        }
      );
    } else {
      localStorage.setItem('freeQuizTabId', 2);
      localStorage.setItem('urlForFreeQuizzes', '/my-mock-test');
      history.push('#show-login');
    }
  }

  useEffect(() => {
    const url = `${testEventsUrl.activeEvents}&src=aweb&page=0&size=20`;
    fetchData(url);
  }, []);

  useEffect(() => {
    if (data && data.length) {
      const arr = new Array(data.length).fill(0);
      setTimeRemainingArray(arr);
      getMappingIds();
      setAllTestIds(mappingIds);
      let url = `${TIMELINE_URI}/fixedMock/register/status?mappingIds=${mappingIds}`;
      getRegistrationStatus(url);
      url = `${TIMELINE_URI}/fixedMock/status?mappingIds=${mappingIds}`;
      getQuizCompletionStatus(url);
    }
  }, [data, auth]);


  useEffect(() => {
    var arr = [];
    if (auth) {
      arr = []
      if (data.length > 0 && registrationData.length > 0 && quizCompletionData.length > 0) {
        var newTimeArray = new Array(data.length).fill(0);
        data.forEach((quiz, index) => {
          if (quizCompletionData.length ? quizCompletionData[index]?.testState === "INCOMPLETE" : false) {
            let remainingTime = getRemainingTime(quiz.timeLimit, quiz.fixedMockSubmitTime, quizCompletionData[index]?.userStartTime);
            arr.push(getQuizState(quiz, index, quizCompletionData[index].userStartTime));
            newTimeArray[index] = formatMilliseconds(remainingTime);
          } else {
            arr.push(getQuizState(quiz, index));
          }

        });
      }
      setTimeRemainingArray(newTimeArray);
      setQuizState(arr);
    }
    else {
      arr = []
      data.length > 0 && data.forEach((quiz, index) => {
        arr.push(getQuizState(quiz, index));
      });
      setQuizState(arr);
    }
  }, [auth, data, registrationData, quizCompletionData, currentTime]);

  // ::::::All Quiz Conditions::::::

  const getQuizState = (quiz, index, userStartTime) => {
    let quizState = '';
    let remainingTime = getRemainingTime(quiz.timeLimit, quiz.fixedMockSubmitTime, userStartTime);
    if (auth && quizCompletionData.length > 0 && registrationData.length > 0) {
      if (currentTime >= quiz.fixedMockResultTime) {
        quizState = FixedMockConstants.ResultPublished;
      }
      else if ((currentTime < quiz.fixedMockResultTime && (currentTime >= quiz.fixedMockSubmitTime || quizCompletionData?.at(index).testState === 'COMPLETED' || (quizCompletionData?.at(index).testState === 'INCOMPLETE' && remainingTime === 0))) || localStorage.getItem(`${quiz.mappingId}Submitted`) === "true") {
        quizState = FixedMockConstants.ResultAwaited;
      }
      else if (currentTime < quiz.fixedMockTestStartTime && registrationData?.length > 0 && registrationData.at(index).isRegistered) {
        quizState = FixedMockConstants.Registered;
      }
      else if (currentTime < quiz.fixedMockTestStartTime && registrationData?.length > 0 && registrationData.at(index).isRegistered === false) {
        quizState = FixedMockConstants.Register;
      }
      else if (currentTime >= quiz.fixedMockTestStartTime && currentTime < quiz.fixedMockSubmitTime && quizCompletionData?.length > 0 && quizCompletionData?.at(index).testState === 'NOT_STARTED' && (localStorage.getItem(`${quiz.mappingId}Submitted`) == null || localStorage.getItem(`${quiz.mappingId}Submitted`) != "false")) {
        quizState = FixedMockConstants.AttemptNow;
      }
      else if (currentTime >= quiz.fixedMockTestStartTime && currentTime < quiz.fixedMockSubmitTime && quizCompletionData?.length > 0 && quizCompletionData?.at(index).testState === 'INCOMPLETE' && remainingTime !== 0 && localStorage.getItem(`${quiz.mappingId}Submitted`) != "true") {
        quizState = FixedMockConstants.Resume;
      }
    } else {
      if (currentTime >= quiz.fixedMockResultTime) {
        quizState = FixedMockConstants.ResultPublished;
      }
      else if (currentTime < quiz.fixedMockResultTime && currentTime >= quiz.fixedMockSubmitTime) {
        quizState = FixedMockConstants.ResultAwaited;
      }
      else if (currentTime >= quiz.fixedMockTestStartTime && currentTime < quiz.fixedMockSubmitTime) {
        quizState = FixedMockConstants.AttemptNow;
      }
      else if (currentTime < quiz.fixedMockTestStartTime) {
        quizState = FixedMockConstants.Register;
      }
    }
    return quizState;
  }

  const openResultTab = (quiz) => {
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER && !iOS) handleAppRedirectPopup(quiz.mappingId);
    else {
      let url = `/my-test-analysis/${quiz.title}/${quiz.fixedMockPkg}/${quiz.mappingId}?&fixedMockResult=true`
      if (userDetails.email) {
        localStorage.setItem("fixedMockResult", "yes");
        window.open(url);
      } else {
        localStorage.setItem('GoToQuizResult', true);
        localStorage.setItem('resultUrl', url);
        history.push("/#show-login");
      }
    }
  }

  return (
    <section className="schl-stripe-wrap">
      <div className="container">

        {data?.length > 0 && quizState.length > 0 ? <TestimonialCarouselNew id={"fixed-mock-banner"} show={1} infiniteLoop isFixedMockBanner={true} setCenterIndex={setCenterIndex} sliderLength={data} dataLength={data.length}>
          {data.map((quiz, index) => {
            const quizDate = getDate(quiz.fixedMockTestStartTime);
            const quizDateNew = getTimeObjTopCard(quiz.fixedMockTestStartTime);
            const resultDate = getDate(quiz.fixedMockResultTime);
            if (quizCompletionData && quizCompletionData.length > 0) {
              var quizCompletionStatus = quizCompletionData.at(index).testState;
            }
            const localRemainingTimeAtSomePointTime = localStorage.getItem(`${quiz.mappingId}RemainingTime`);
            const pointOfTime = localStorage.getItem(`${quiz.mappingId}whichPointOfTime`);
            const isTimeLimitSectionWise = quiz?.sections?.[0]?.secTime > 0;
            const localRemainingTime = pointOfTime ? Math.ceil(localRemainingTimeAtSomePointTime * 1000 - (currentTime - pointOfTime)) : null;

            return (
              <div className="schl-stripe-content" key={index}>
                <div className="ribbon ribbon-top-left">
                  <span>
                    {getRibbonText(quizState[index])}
                  </span>
                </div>
                <div className="schl-book-img-wrap">
                  <img src={"/images/fpmbanner-updated.svg"} alt="fpmbanner"/>
                </div>

                <div className="sch-all-test-content">
                  <div className="schl-test-heading-banner">{quiz.title}</div>
                  <div className="schl-small-test-heading">
                    {quiz?.exl?.length === 1 ? "(" + quiz.examid + ")" : ""}
                  </div>
                  <div className="schl-bottom-row">
                    <div className="schl-button-shape">
                      <img src="/images/batchtag.svg" alt="batchtag"/>
                      {quiz.fixedMockPrizeType}
                    </div>

                    <div className="sch-mbl-conent">
                      <div className="schl-button-shape">
                        <img src="/images/batchtag.svg" alt="batchtag-image"/>{" "}
                        {quiz.fixedMockPrizeType}
                      </div>
                    </div>

                    {currentTime >= quiz.fixedMockResultTime ? (
                      <>
                        <div className="sch-home-register-btn">
                          <Link
                            to={`${userDetails.email ? "" : "/#show-login"}`}
                            className="ripple"
                            onClick={() => {
                              openResultTab(quiz);
                            }}
                          >
                            {quizState.length > 0
                              ? quizState[index]
                              : "View Result"}
                          </Link>
                        </div>
                      </>
                    ) : quizState[index] === FixedMockConstants.ResultAwaited ? (
                      <>
                        <div className="schl-home-date-time-outer resultdec">
                          <span className="schl-start-at">
                            {" "}
                            <img src="/images/fpmbanner-cal-icon.svg" alt="fpmbanner-cal-image"/> Result
                            Declaration at
                            {" " +
                              resultDate.hours +
                              " | " +
                              resultDate.day +
                              " " +
                              resultDate.month}
                          </span>
                        </div>
                        <FixedMockButton
                          quiz={quiz}
                          quizCompletionData={quizCompletionData}
                          quizCompletionStatus={quizCompletionStatus}
                          registrationData={registrationData}
                          index={index}
                          currentTime={currentTime}
                          quizState={quizState}
                          handleAppRedirectPopup={handleAppRedirectPopup}
                        />
                      </>
                    ) : (
                      <>
                        {quizState[index] === FixedMockConstants.Resume ? (
                          <>
                            {(isTimeLimitSectionWise && !pointOfTime) ? (
                              ""
                            ) : (
                              <div className="schl-home-date-time-outer">
                                <span className="schl-start-at">
                                  <img src="/images/fpmbanner-cal-icon.svg" alt="fpmbanner-image"/>{" "}
                                  Time Remaining{" "}
                                </span>
                                <span className="banner-time-remaining-counter">
                                  {isTimeLimitSectionWise
                                    ? formatMilliseconds(
                                      localRemainingTime > 0 ? localRemainingTime : 0
                                    )
                                    : (timeRemainingArray[index]
                                      ? timeRemainingArray[index]
                                      : "")}
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="schl-home-date-time-outer">
                              <span className="schl-start-at">
                                <img src="/images/fpmbanner-cal-icon.svg" alt="fpmbanner-image"/>{" "}
                                Starts at{" "}
                              </span>
                              <span className="schl-home-time">
                                {quizDateNew[3]} {quizDateNew[4]} |
                              </span>
                              <span className="schl-home-date">
                                {quizDate.day} {quizDate.month}
                              </span>
                            </div>
                          </>
                        )}
                        <FixedMockButton
                          quiz={quiz}
                          quizCompletionData={quizCompletionData}
                          quizCompletionStatus={quizCompletionStatus}
                          registrationData={registrationData}
                          index={index}
                          currentTime={currentTime}
                          quizState={quizState}
                          handleAppRedirectPopup={handleAppRedirectPopup}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </TestimonialCarouselNew> : <></>}
        {
          data?.length > 0 && quizState.length > 0
            ? <div className="banner-to-mock">
              <div onClick={() => redirectToMockListing()}>View All Tests</div>
            </div>
            : ""
        }
      </div>
    </section>
  );
}
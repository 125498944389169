import fetch from "isomorphic-fetch";
import Cookies from "js-cookie";
import { getCpOriginValue, getDeviceType } from "./helpers";
import { prodUrl } from "../../../constants/appConfig";

function addSource(url) {
  if (url.indexOf("?") !== -1) {
    url = url + `&src=${getDeviceType() == 'm' ? 'mweb' : 'aweb'}`;
  } else {
    url = url + `?src=${getDeviceType() == 'm' ? 'mweb' : 'aweb'}`;
  }
  return url;
}

export default (url, method, body, ctype, useToken, headersObj, noSrc = false) => {
  if(!noSrc){
    url = addSource(url);
  }
  let options = "";
  if (method === "GET") {
    options = {
      method,
      headers: requestHeaders(ctype, useToken, headersObj, url),
    };
  } else {
    options = {
      method,
      headers: requestHeaders(ctype, useToken, headersObj),
      body: JSON.stringify(body),
    };
  }
  
  return fetch(url, options).then((res) => parseStatus(res.status, res, url));
};

function parseStatus(status, res, url) {
  
  return new Promise((resolve, reject) => {
    
    if (status >= 200 && status < 300) {
      if (res.url.indexOf("verifyOTP") > -1) {
        return resolve();
      }
      if (res.url.indexOf(".html") > -1) {
        return resolve(res);
      }
      let tempResponse = JSON.parse(JSON.stringify(res));

      res.json().then(
        (response) => {
          resolve(response);
        },
        (err) => {
          return resolve();
        }
      );
    } else {
      if (typeof window != "undefined" && status == "401") {
        var jwtToken = Cookies.get("cp_token");
        if (
          jwtToken &&
          jwtToken != "" &&
          jwtToken.length &&
          jwtToken.length > 10
        ) {
          var eventFired = new CustomEvent("error401");
          document.dispatchEvent(eventFired);
        }
      }
 
      let contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        res.json().then((response) => {
          reject(response);
        });
      } else {
        res.text().then((response) => {
          reject(response);
        });
      }
    }
  });
}

export function requestHeaders(ctype, useToken, headersObj, callurl=null) {
  let jwtToken =
    Cookies.get("cp_token") ||
    (useToken
      ? "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZXZvcHNAYWRkYTI0Ny5jb20iLCJhdWQiOiI4Mjk4NTM4IiwiaWF0IjoxNTg3NTM3MTY1LCJpc3MiOiJhZGRhMjQ3LmNvbSIsIm5hbWUiOiJEZXZvcHMifQ.zjRfvavg0x-GsIAkYq7vaP63-CU5SYGKACnqwX2bk5whU3eF8m28SihU_hDpc_Fjk_IP-Aze9_gHYNDT1HMGEA"
      : "");
  let csrfToken = jwtToken == "" ? Cookies.get("csrf_token") || "" : "";

  let csrfOptions = {};

  if (jwtToken == "" && csrfToken != "") {
    csrfOptions = { "X-CSRF-Token": csrfToken };
  }
  let cpOrigin = typeof window !== "undefined" && localStorage.getItem("cp-origin") || getCpOriginValue(!!Cookies.get("cp_user_email"));

  let options = {};
  if(callurl && callurl.includes("crm=true")) {
    options = {
      origin: prodUrl,
      "cp-origin": (cpOrigin && typeof(cpOrigin) !== "undefined") ? cpOrigin : '11',
      "Content-Type": ctype || "application/json",
      "X-Auth-Token": "fpoa43edty5"
    };
  } else {
    options = {
      origin: prodUrl,
      "cp-origin": (cpOrigin && typeof(cpOrigin) !== "undefined") ? cpOrigin : '11',
      "Content-Type": ctype || "application/json",
      "X-Auth-Token": "fpoa43edty5",
      "X-JWT-Token": jwtToken,
    };
  }

  // if (headersObj)options ={...options,...headerOptions}

  options = Object.assign(options, csrfOptions, headersObj);

  return options;
}

import React, { useMemo } from "react";
import {
  createOptimizedImgUrl,
  getDiscount,
  numberWithCommas,
  toCamelCase,
} from "../../data/utils/helpers";
import { FallBackImage } from "../../../constants/appConfig";
import ErrorBoundary from "../ErrorBoundary";
import WishListToggler from "../../containers/WishList/WishListToggler";
import { BLANK_IDENTIFIER, WEB_IDENTIFIER } from "../MoengageConstants";

const PDPCard = ({
  isPurchased,
  setPlayVideo,
  language,
  additionalTag,
  demoUrl,
  dualValidity,
  maxPrice,
  price,
  originalPrice,
  pdpTemplateUrl,
  isWishList,
  packageId,
  productData,
}) => {
  const moengagePayload = useMemo(() => {
    return {
      exam_category: productData?.courses,
      user_exam_category_selected: WEB_IDENTIFIER,
      exam: productData?.examTypes?.[0]?.name || BLANK_IDENTIFIER,
      language: language,
      source_screen: "PDP",
      package_status: "Paid",
      package_type: productData?.suggestionCat || BLANK_IDENTIFIER,
      package_title: productData?.title,
      package_id: packageId,
      package_name: productData?.title,
      package_actual_price: maxPrice,
      package_discounted_price: originalPrice,
      package_final_price: price,
      package_subject: BLANK_IDENTIFIER,
      modified_date: productData?.updatedAt,
      published_date: productData?.createdAt,
      package_url:
        typeof window !== "undefined" ? window?.location?.href || "" : "",
      coupon_code: "",
      certification: "",
      product_purchased_state: isPurchased ? "yes" : "no",
    };
  }, [productData, isPurchased]);
  return (
    <>
      {dualValidity ? (
        <div className="pdpCardTag">{"Double Validity"}</div>
      ) : null}

      <div
        data-testid="pdpCardImg"
        className="pdpCardImg"
        onClick={demoUrl ? () => setPlayVideo(true) : null}
      >
        <img
          src={
            pdpTemplateUrl
              ? createOptimizedImgUrl(pdpTemplateUrl)
              : FallBackImage
          }
          alt="pdpCourseImg"
        />

        <img
          className="bckgroundimgforblug"
          src={
            pdpTemplateUrl
              ? createOptimizedImgUrl(pdpTemplateUrl)
              : FallBackImage
          }
          alt="pdpCourseBackgoundImg"
        />
        <div className="bgcolordiv"></div>
        {demoUrl && (
          <div className="videoDemo">
            <img src="/images/videocall.svg" alt="videoIcon" />
          </div>
        )}
      </div>
      <div data-testid="pdpCardInfo" className="pdpCardInfo">
        {language && <div className="pdpLang">{toCamelCase(language)}</div>}
        <div className="listingCardOptionTag">{additionalTag}</div>
        <ErrorBoundary>
          <WishListToggler
            isWishList={isWishList}
            packageId={packageId}
            wishlistOperationAllowed={true}
            moengagePayload={moengagePayload}
            source_screen="PDP"
          />
        </ErrorBoundary>
      </div>

      <div data-testid="pdpPriceWrap" className="pdpPriceWrap">
        <div className="pdpPrice">
          {!isPurchased ? (
            <div className="pdppriceleft">
              <div className="pdpOrignalPrice">
                ₹ {numberWithCommas(price)}{" "}
              </div>
              <div className="pdpOffPrice">
                {maxPrice != price && (
                  <strike>
                    &#8377;
                    {numberWithCommas(maxPrice)}
                  </strike>
                )}
              </div>
              <div className="pdpOffPercent">
                {maxPrice != price
                  ? `(${getDiscount(maxPrice, price)}% Off)`
                  : null}
              </div>
            </div>
          ) : (
            <div className="pdpOrignalPriceAlreadySold">
              ₹ {numberWithCommas(originalPrice)}
            </div>
          )}
          {/* currently no functionality for share */}
          {/* <div className="pdpshare">
            <a href="">
            <img src="/images/pdpshareicon.svg" />
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PDPCard;

import React, { useState } from "react";
import { Cookies } from "react-cookie";
import {
  getDeviceType,
  getMoengageMetadata,
  getUserDeviceType,
  isADDA,
  optimizedImgUrl,
} from "../data/utils/helpers";
import { newsletterUrl, pageName } from "../../constants/appConfig";
import fetch from "../data/utils/fetch";

const cookie = new Cookies();

const GetLink = ({isHome, pos = "footer"}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleMobileChange = (e) => {
    setSuccessMessage(null);
    setMobileNumber(e.target.value.replace(/[^0-9]/g, ""));
    if (!e.target.value || e.target.value.trim() == "") {
      setErrorMessage("Mobile Number is required!");
    } else {
      setErrorMessage(null);
    }
  };

  const getLinkMoengageEvent = (position) => {
    let payload = {
      source: "web",
      brand: pageName[1],
      moe_first_visit: "",
      moe_logged_in_status: cookie.get("cp_token") || "",
      platform_1: getDeviceType() == "m" ? "Mobile" : "WEB",
      userAgent_1: navigator.userAgent,
      user_email: cookie.get("cp_user_email") || "",
      user_name: cookie.get("cp_user_name") || "guest",
      mobile_number: mobileNumber,
    };
    if(["header","footer"]?.includes(position)) {
        payload.position = position === "header" ? "Top" : "Bottom";
    } else {
        payload.position = position;
    }
    if (typeof Moengage !== "undefined") {
      Moengage.track_event("Get_Link", getMoengageMetadata(payload));
    }
  };

  const getLinkTextBoxClick = (position) => {
    let payload = {
      source: "web",
      brand: pageName[1],
      moe_first_visit: "",
      moe_logged_in_status: cookie.get("cp_token") || "",
      platform_1: getDeviceType() == "m" ? "Mobile" : "WEB",
      userAgent_1: navigator.userAgent,
      user_email: cookie.get("cp_user_email") || "",
      user_name: cookie.get("cp_user_name") || "guest",
      mobile_number: mobileNumber,
    };
    if(["header","footer"]?.includes(position)) {
        payload.position = position === "header" ? "Top" : "Bottom";
    } else {
        payload.position = position;
    }
    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "Get_Link_textbox_clicked",
        getMoengageMetadata(payload)
      );
    }
  };

  const sendSMS = (e, position) => {
    e.preventDefault();
    //capture get app link moengage event
    getLinkMoengageEvent(position);
    setSuccessMessage(null);
    if (!mobileNumber || mobileNumber.trim() == "") {
      setErrorMessage("Mobile Number is required!");
    } else {
      if (mobileNumber.length == 10 && mobileNumber.match("[6-9]{1}[0-9]{9}")) {
        fetch(`${newsletterUrl}/sms/send`, "POST", {
          to: mobileNumber,
          messageType: isADDA ? "ADDA" : "SANKALPBHARAT",
        }).then(
          (res) => {
            setMobileNumber("");
            setErrorMessage(null);
            if (res.includes("success")) {
              setSuccessMessage(
                "The link has been sent to your mobile number!"
              );
            }
          },
          (err) => {
            setMobileNumber("");
            setSuccessMessage(null);
            setErrorMessage("Something went wrong!");
          }
        );
      } else {
        setErrorMessage("Mobile number is invalid");
      }
    }
  };

  const trackAppEvent = () => {
    let payload = {};
    payload.Link_text = "Download our App";
    trackMoengageEvent("App_Link_on_mobile", payload);
  };

  if(isHome) {
    return (
      <section className="banner">
          <div className="container">
            <div className="banner-content-body">
              <div className="banner-text-area">
                <h1>
                  {isADDA ? 'Learning Today' : 'Let\'s Learn'}
                  <br />
                  {isADDA ? 'Leading Tomorrow' : 'Beyond The Limits'}
                </h1>
                <div className="banner-punch-line">
                  {isADDA ? 'With India\'s #1 Learning App' : 'Best way to learn from anywhere'}
                </div>
                <p>
                  {isADDA ? 'Download our app & discover ADDA247\'s revolutionary way of learning!' : 'The more you learn, the more things you will know, here at Sankalp Bharat you learn anything anywhere.'}
                </p>
                {/* <!--banner newsletter --> */}
                <form className="banner-newsletter" id="test-form-banner">
                  <div className="inputbox">
                    <span style={{ width: "max-content" }}>+91</span>
                    <input
                      type="tel"
                      className="mobNumber2"
                      placeholder="Enter your 10 digit mobile number"
                      name="form_field_1"
                      pattern="^[6-9]{1}\d{9}$"
                      required
                      maxLength="10"
                      value={mobileNumber}
                      onChange={handleMobileChange}
                      onClick={() => getLinkTextBoxClick(pos)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="submitbtn-dwnapp">
                    <button
                      onClick={(e) => sendSMS(e, pos)}
                      value="Get link"
                      className="ripple"
                    >
                      Get link
                    </button>
                    <a href={`https://${pageName[0]}.app.link/webinstall`}></a>
                    <div className="loader">
                      <div className="loading"></div>
                    </div>
                  </div>
                  {successMessage && (
                    <div className="msg-mobNum success-mobNum">
                      {successMessage}
                    </div>
                  )}
                  {errorMessage && (
                    <div className="msg-mobNum error-mobNum">
                      {errorMessage}
                    </div>
                  )}
                </form>
                <div className="app-store-container">
                  {(getUserDeviceType() === "android" ||
                    getUserDeviceType() === "window") && (
                    <a
                      href={
                        isADDA
                          ? "https://adda247.app.link/BIN8jCOQlvb"
                          : "https://bit.ly/3A6YzHL"
                      }
                      target="_blank"
                      onClick={() => trackAppEvent()}
                    >
                      <img
                        className="app-store-btn"
                        src={"/images/google-play-store.svg"}
                        alt=""
                      />
                    </a>
                  )}
                  {isADDA &&
                    (getUserDeviceType() === "iphone" ||
                      getUserDeviceType() === "window") && (
                      <a
                        href="https://adda247.app.link/gI2OLH2Puwb"
                        style={{ marginLeft: "10px" }}
                        target="_blank"
                        onClick={() => trackAppEvent()}
                      >
                        <img
                          className="app-store-btn"
                          src={"/images/apple-app-store.svg"}
                          alt=""
                        />
                      </a>
                    )}
                </div>
                {/* <!--end banner newsletter--> */}
              </div>
              <div className="banner-right-img">
                {
                  isADDA ? <img src={optimizedImgUrl("https://adda247.com/images/banner-right-img-new.svg")} alt="banner" /> : <img
                  src={"/images/sankalp-images/bannerimg1.svg"}
                  alt="banner"
                />
                }
              </div>
            </div>
          </div>
          <div className="banner-circle-motion">
            <div className="circle circle1"></div>
            <div className="circle circle2"></div>
            <div className="circle circle3"></div>
            <div className="circle circle4"></div>
            <div className="circle circle5"></div>
          </div>
        </section>
    )
  }

  return (
    <section className="dwn-app">
      <div className="container">
        <div className="app-text-area">
          <div class="common-headding">
            Download Our <span>App</span>
          </div>
          <div className="input-number-text">
            <div className="getappheading showonlyweb">
              We will send you a link, open it on your phone to download the app
            </div>
            <div className="dwn-app-img-mbl">
              {isADDA ? (
                <img
                  src={optimizedImgUrl(
                    "https://adda247.com/images/dwn-app-img.svg"
                  )}
                  alt="downloadAddaApp"
                  loading="lazy"
                />
              ) : (
                <img
                  src={"/images/sankalp-images/sankalp-dwn-app-img.svg"}
                  alt="downloadSankalpApp"
                  loading="lazy"
                />
              )}
            </div>
            <form className="showonlyweb" id="test-form">
              <div className="inputbox">
                <span style={{ width: "max-content" }}>+91</span>
                <input
                  type="tel"
                  className="mobNumber2"
                  placeholder="Enter your 10 digit mobile number"
                  name="form_field_1"
                  pattern="^\d{10}$"
                  required
                  maxLength="10"
                  value={mobileNumber}
                  onChange={handleMobileChange}
                  onClick={() => getLinkTextBoxClick(pos)}
                  autocomplete="off"
                />
              </div>
              <div className="submitbtn-dwnapp">
                <button
                  onClick={(e) => sendSMS(e, pos)}
                  value="Get link"
                  className="ripple"
                >
                  Get link
                </button>
                <a href="https://adda247.app.link/webinstall"></a>
                <div className="loader">
                  <div className="loading"></div>
                </div>
              </div>
              {successMessage && (
                <div className="msg-mobNum success-mobNum">
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div className="msg-mobNum error-mobNum">
                  {errorMessage}
                </div>
              )}
            </form>
            <div class="dwnbtnmweb mwebshow">
              <a href="https://adda247.app.link/webinstallb">Download Now</a>
            </div>
            <div className="app-store-container">
              {(getUserDeviceType() === "android" ||
                getUserDeviceType() === "window") && (
                <a
                  href={
                    isADDA
                      ? "https://adda247.app.link/YHm77kZQlvb"
                      : "https://bit.ly/3A6YzHL"
                  }
                  target="_blank"
                  onClick={() => trackAppEvent()}
                >
                  <img
                    className="app-store-btn"
                    src={"/images/google-play-store.svg"}
                    alt="app-store"
                    loading="lazy"
                  />
                </a>
              )}
              {isADDA &&
                (getUserDeviceType() === "iphone" ||
                  getUserDeviceType() === "window") && (
                  <a
                    href="https://adda247.app.link/jL8qIxhQuwb"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                    onClick={() => trackAppEvent()}
                  >
                    <img
                      className="app-store-btn"
                      src={"/images/apple-app-store.svg"}
                      alt="app-store"
                      loading="lazy"
                    />
                  </a>
                )}
            </div>
          </div>
        </div>
        <div class="dwn-app-img">
          {isADDA ? (
            <img
              src={optimizedImgUrl(
                "https://adda247.com/images/dwn-app-img.svg"
              )}
              alt="downloadAddaApp"
              loading="lazy"
            />
          ) : (
            <img
              src="/images/sankalp-images/sankalp-dwn-app-img.svg"
              alt="downloadSankalpApp"
              loading="lazy"
            />
          )}
        </div>
      </div>
    </section>
  );
};

GetLink.defaultProps = {
  pos: "footer",
  isHome: false,
}

export default GetLink;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import EmptyCart from "../../components/cart/EmptyCart";
import CustomLoader from "../../components/library/CustomLoader";
import PackageDetailCard from "../../components/PPC/PackageDetailCard";
import {
  dateMonthYear,
  useRedirectToConsumptionUrl,
} from "../../components/utils";
import {
  getNextMyPurchaseListings,
} from "../../data/ducks/header/actions";
import { getPdpUrl, isADDA, makeUrl } from "../../data/utils/helpers";
import { getCategoryLevelConsumptionUrl } from "./helpers";
import OnboardingPPCModal from "../../components/library/LoginModal/OnboardingPPCModal";
import Cookies from "js-cookie";

const MyPurchaseList = ({ list, loading, activeCategoryParam }) => {
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (nextLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((enteries) => {
        if (enteries[0].isIntersecting && hasMore) {
          setPageNo((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextLoading, hasMore]
  );

  const handleShowOnboardingModal = () => {
    let userEmail = Cookies.get("cp_user_email");
    if (!isADDA && !userEmail || userEmail == "null") {
        setShowOnboardingModal(true);
    }
  }

  const getNextPageData = async () => {
    if (pageNo !== 0) {
      setNextLoading(true);
      const res = await dispatch(getNextMyPurchaseListings(pageNo, 10));
      setNextLoading(false);
      const result = await res[0];
      setHasMore(result?.data?.length > 0);
    }
  };

  const [redirectEvent] = useRedirectToConsumptionUrl();

  useEffect(() => {
    getNextPageData();
  }, [pageNo]);

  useEffect(() => {
    handleShowOnboardingModal();
  },[]);
  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : list?.length > 0 ? (
        <>
          <div class="ppc-mypack-content">
            {list?.map((data, idx) => (
              <PackageDetailCard
                ref={
                  list?.length > 5 && list?.length === idx + 1
                    ? lastElementRef
                    : null
                }
                key={data.packageId}
                src={data.thumbnail}
                title={data.title}
                validityDate={dateMonthYear(parseInt(data?.expiry))}
                isExpired={data.expired}
                onClick={() => redirectEvent(data, activeCategoryParam)}
              />
            ))}
          </div>
          {nextLoading && (
            <div className="ppc-next-load">
              <CustomLoader />
            </div>
          )}
        </>
      ) : (
        list?.length === 0 && (
          <EmptyCart
            header={"You have not purchased any product yet."}
            subHeader={
              "Browse from our comprehensive range of products - Live Classes, Video Classes, Test Series and E-Books."
            }
            minHeight="100%"
            top="20px"
            exploreVisible={true}
          />
        )
      )}
      {showOnboardingModal && <OnboardingPPCModal/>}
    </>
  );
};

export default MyPurchaseList;

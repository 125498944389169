import React, { useState, useEffect } from 'react';
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import BreadCrumb from "../components/library/BreadCrumb";
import CustomMyPurchase from "../components/home/MyPurchase"
import { myPurchase } from '../../constants/appConfig';
import fetch from '../data/utils/fetch';
import { getPdpUrl, getMoengageMetadata } from '../data/utils/helpers';
import { Redirect } from 'react-router';
import { Cookies } from "react-cookie";
import EmptyCart from '../components/cart/EmptyCart';
import { Link } from 'react-router-dom';
const cookie = new Cookies();

const MyPurchase = (props) => {
  const [activePackage, setActivepackage] = useState([]);
  const [expiredPackage, setExpiredPackage] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [Aloading, setALoading] = useState(false);
  const [Eloading, setEloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allData, setAlldata] = useState([]);
  const [expireMessage, setexpireMessage] = useState("");
  const [activeMessage, setActiveMessage] = useState("");
  const [isResponseEmpty, setResponseNull] = useState(false)
  const [IsexpiredResonse, setExpioredResponse] = useState(false)

  const [isExpiredClick, setIsExpiredClick] = useState(false);
  const [expiredPageNumber, setExpiredpageNumber] = useState(0);
  const OpenConsumptionPage = (category, id) => {
    let cat = ""
    if (category == "TEST_SERIES")
      cat = "my-mock-test"
    if (category == "EBOOKS")
      cat = "my-ebooks"
    if (category == "ONLINE_LIVE_CLASSES")
      cat = "my-live-class"
    if (category == "VIDEOS")
      cat = "my-videos"

    return `${cat}/${id}`

  }
  const loadingoverall = () => {
    setLoading(Aloading && Eloading);
  }
  const checkForFeedCarousel = p1 => {
    if (p1) {


      if (!isExpiredClick) {
        var value = Math.round(activePackage.length / 2) - 1
        if (p1 >= value && !isResponseEmpty) {
          setPageNumber(pageNumber + 1)
          updateData(pageNumber + 1)
        }
      }
      else {
        let expired = Math.round(expiredPackage.length / 2) - 1
        if (p1 >= expired && !IsexpiredResonse) {
          setExpiredpageNumber(expiredPageNumber + 1)
          updateExpiredData(expiredPageNumber + 1);
        }

      }

    }
    else if (screen.width < 780) {
      if (!isExpiredClick) {
        setPageNumber(pageNumber + 1);

        if (!pageNumber) return;

        updateData()
      }
      else {
        setExpiredpageNumber(expiredPageNumber + 1)
        if (!expiredPageNumber) return;
        updateExpiredData();
      }

    }
  };
  const updateExpiredData = (pageNo) => {
    setEloading(true);
    fetch(`${myPurchase.boughtpackages}?status=expired&sortby=recent&page=${pageNo}&size=10`, 'GET').then(res => {

      if (res.data && res.data.length >= 1) {
        setEloading(false);

        const expiredPackagevvalue = res.data

        const expiredvalue = [...expiredPackage]
        const allvalue = [...allData];
        allvalue.push(...expiredPackagevvalue);
        setAlldata(allvalue);
        if (expiredvalue.length >= 1) {
          expiredvalue.push(...expiredPackagevvalue);
          setExpiredPackage(expiredvalue);
        }
        else {
          setExpiredPackage(res.data)
        }

      }
      else {
        if (pageNo == 0) {
          setexpireMessage("No-expire-packages-yet")
          setExpioredResponse(true)
        }
        setEloading(false);
        setExpioredResponse(true)
      }
    })
  }
  const updateData = (pageNo) => {
    setALoading(true);
    fetch(`${myPurchase.boughtpackages}?status=active&sortby=recent&page=${pageNo}&size=10`, 'GET').then(res => {
      if (res.data && res.data.length >= 1) {
        setALoading(false);
        const activePackagevvalue = res.data
        const allvalue = [...allData];
        allvalue.push(...activePackagevvalue);
        setAlldata(allvalue);
        const activevalue = [...activePackage]
        if (activevalue.length >= 1) {
          activevalue.push(...activePackagevvalue);
          setActivepackage(activevalue);
        }
        else {
          setActivepackage(res.data)
        }
      }
      else {

        setALoading(false);
        if (pageNo == 0) {
          setActiveMessage("No-active-packages-yet")
          setResponseNull(true)
        }
        setResponseNull(true)
      }

    })

  }

  useEffect(() => {
    updateData(0);

  }, [])

  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo(0, 0);
    updateExpiredData(0);
    // setIsExpiredClick(false);
  }, [])
  let email = cookie.get('cp_user_email');
  let name = cookie.get('cp_user_name');
  const token = cookie.get('cp_token');
  if (token) {
    return (

      <div>
        <div>

          <BreadcrumbSchema
            items={[
              {
                title: "Home",
                url: "/"
              },
              {
                title: "My Purchases"

              }
            ]}
          />
          <div className={"breadcrumb-container"}>
            <BreadCrumb
              routeData={[
                { name: "Home", target: "/" },
                {
                  name: "My Purchases",
                  target: ""
                }
              ]}
            />
          </div>
          <div className={"header-container"}>
            <h1 className={"mypurchase-title"}>
              {"My Purchases"}
            </h1>
          </div>

          {/* active purchases */}

          {Eloading && Aloading ? <CustomLoader /> :
            activeMessage && expireMessage ?

              <div className="empty-cont">
                < EmptyCart header={"You have not purchased any product yet."}
                  subHeader={"Browse from our comprehensive range of products - Live Classes, Video Classes, Test Series and E-Books."}
                  minHeight="100%"
                  top="20px"
                  exploreVisible={true} />
              </div>
              :
              <>
                <CustomMyPurchase
                  id="activepackage"
                  setIsExpiredClick={setIsExpiredClick}
                  updateData={updateData}
                  loading={Aloading}
                  OpenConsumptionPage={OpenConsumptionPage}
                  checkForFeedCarousel={checkForFeedCarousel}
                  title={"Active Packages"}
                  viewall={false}
                  isExpired={false}
                  data={activePackage}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  isResume={false}
                  message="Active" />

                <CustomMyPurchase
                  updateData={updateExpiredData}
                  activeData={activePackage}
                  loading={Eloading}
                  id="expiredpackage"
                  title={"Expired Packages"}
                  viewall={false}
                  OpenConsumptionPage={OpenConsumptionPage}
                  isExpired={true}
                  pageNumber={expiredPageNumber}
                  data={expiredPackage}
                  setPageNumber={setExpiredpageNumber}
                  setIsExpiredClick={setIsExpiredClick}
                  checkForFeedCarousel={checkForFeedCarousel}
                  imgcontcss={"imagecountexpired"}
                  message="Expired"
                />
              </>


          }
        </div>
      </div>
    )
  }
  else {
    return (
      <Redirect to='/#show-login' />
    );
  }

}
export default MyPurchase;
import React from "react";
import { getDiscount, numberWithCommas } from "../../data/utils/helpers";

const BuyNowComponentMweb = ({
  price,
  maxPrice,
  renderBuyNowComponent,
  renderViewOffers,
  isPurchased,
  originalPrice,
}) => {
  return (
    <>
      <div className="leftFooterFix">
        <div className="pdppriceleft">
          {!isPurchased ? (
            <>
              <div className="pdpOrignalPrice">
                ₹ {numberWithCommas(price)}{" "}
              </div>
              <div className="pdpOffPrice">
                {maxPrice != price && (
                  <strike>
                    &#8377;
                    {numberWithCommas(maxPrice)}
                  </strike>
                )}
              </div>
              <div className="pdpOffPercent">
                {maxPrice != price
                  ? `(${getDiscount(maxPrice, price)}% Off)`
                  : null}
              </div>
            </>
          ) : (
            <div className="pdpOrignalPrice">
              ₹ {numberWithCommas(originalPrice)}{" "}
            </div>
          )}
        </div>
        <div className="OfferFooterFix">
          {/* View More Offers */}
          {renderViewOffers}
        </div>
      </div>
      <div className="rightFooterFix">
        <div className="pdpbtn pdpBuyNowbtn">{renderBuyNowComponent}</div>
      </div>
    </>
  );
};
export default BuyNowComponentMweb;

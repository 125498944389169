import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { TEST_SERIES_URL } from "../../../../constants/appUrls";
import {
  formatSpecialTestTime,
  timeStampdateMonthYear,
} from "../../../components/utils";
import MyaccountNavbar from "../../../containers/Myaccountnavbar";
import { getSpecialTests } from "../../../data/ducks/specialTests/actions";
import "../../../data/ducks/specialTests/reducers";
import fetch from "../../../data/utils/fetch";
import {
  getMoengageMetadata,
  getPdpUrl,
  isADDA,
  testWithOnlyDtb,
} from "../../../data/utils/helpers";
import { formatDateTime } from "../../dateUtils";
import CustomLoader from "../../library/CustomLoader";
import CustomModal from "../../library/CustomModal";
import NoResultsFound from "../../library/NoResultsFound";
import SectionComponent from "../../library/SectionComponent/SectionComponent";
import ExpiredCourse from "../ExpiredCourses/ExpiredCourse";
import {
  BLANK_IDENTIFIER,
  ENGLISH_IDENTIFIER,
  WEB_IDENTIFIER,
} from "../../MoengageConstants";
import { getExamListQueryParam, openTestPortal } from "./TakeTest/util";
import MockTestCard from "./TestResultAnalysis/Components/MockTestCard";
import LiveTestCard from "./TestResultAnalysis/Components/LiveTestCard";
import { getLiveTestState } from "./TestResultAnalysis/Components/liveTestUtils";
const TestsListing = (props) => {
  const focus = useRef();
  const testPortalOpened = useRef();
  const [showSchedule, setShowSchedule] = useState(false);
  const [testPackageInfo, setTestPackageInfo] = useState({});
  const [testsList, setTestsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [status, setStatus] = useState(0);
  const [searchText, setSearchText] = useState();
  const [showExpiredCourseInfo, setShowExpiredCourseInfo] = useState(false);
  const [allData, setAlladat] = useState("");
  const [selectedTabFilter, setSelectedTabFilter] = useState("allLiveTests");
  const [groupFilteredTestData, setGroupFilteredTestData] = useState([]);
  const [filteredTestData, setFilteredTestData] = useState([]);


  const TestStatus = {
    0: { label: "", ddLabel: "All Test" },
    COMPLETED: { label: "View Result", ddLabel: "Completed" },

    INCOMPLETE: { label: "Resume Test", ddLabel: "Incomplete" },
    NOT_STARTED: { label: "Start Test", ddLabel: "Not Started" },
    COMING_SOON: { label: "Coming Soon", ddLabel: "Coming Soon" },
  };
  const changeStatus = (e) => {
    setStatus(e.target.value);
  };
  const close = () => {
    setShowSchedule(false);
  };
  useEffect(() => {
    window.addEventListener("focus", () => {
      focus.current = "focus";

      if (testPortalOpened.current) {
        getTestLists();
        testPortalOpened.current = false;
      }
    });
  }, []);

  const getTestLists = () => {
    setLoading(true);
    fetch(
      `${TEST_SERIES_URL}/api/v5/test-series/${props.match.params.id}?pageNumber=0&pageSize=5000`,
      "GET"
    ).then(
      (res) => {
        setLoading(false);
        if (res?.data) {
          setTestsList(res?.data?.testInfo);
          setGroupFilteredTestData(res?.data?.testInfo)
          setTestPackageInfo(res?.data?.packageInfo);

          let groupList = res?.data?.testInfo
            .reduce((groupList, item) => {
              if (groupList.indexOf(item.groupName) == -1)
                groupList.push(item.groupName);
              return groupList;
            }, [])
            .sort();
          groupList.unshift("All Test");
          if (groupList.length > 2) setGroups(groupList);
        } else {
          setCourseItemList([]);
        }
      },
      (res) => {
        setLoading(false);
        setCourseItemList([]);
      }
    );
  };

  const redirectToResultAnalysis = (title, packageId, mappingId, exams) => {
    const examParam = getExamListQueryParam(exams);
    testWithOnlyDtb(packageId, mappingId).then((res) => {
      let url = `/my-test-analysis/${title}/${packageId}/${mappingId}?${
        res?.isOnlyDtb ? "dtb=true" : ""
      }${examParam ? `&${examParam}` : ""}`;
      window.open(url);
    });
  };

  useEffect(() => {
    testPortalOpened.current = false;
    typeof window !== "undefined" && window.scrollTo(0, 0);
    getTestLists();

    if (!props.specialTestsObj) {
      props.getSpecialTests();
    }
  }, []);

  useEffect(() => {
    if(testsList?.length && groupFilteredTestData?.length && selectedTabFilter) {
      getTabFilterData(selectedTabFilter);
    }
  }, [selectedTabFilter, groupFilteredTestData]);

  useEffect(() => {
    if(testsList?.length) filterTests();
  }, [selectedGroup, testsList, status, searchText]);

  let bodyComponent = [];
  const filterTests = () => {
    setLoading(true);
    let filteredData = testsList
      .filter((test) => {
        return selectedGroup == 0 || test.groupName == groups[selectedGroup];
      })
      .filter((test) => {
        if (
          !(test && test.testData && test.testData[0] && test.testData[0].title)
        )
          return false;
        let title = test.testData[0].title.toLowerCase();
        return !searchText || title.includes(searchText.toLowerCase());
      })
      .filter((test) => {
        return status == 0 || status == test.status;
      });
      setFilteredTestData(filteredData);
      setGroupFilteredTestData(filteredData);
      setLoading(false);
  };

  const getTabFilterData = (filterType) => {
    setLoading(true);
    let selectedFilterCategory = 0;
    let filteredData = [];
    if(filterType === "sectionalLengthTests") {
      selectedFilterCategory = 1;
      filteredData = groupFilteredTestData?.filter((test) => test?.testData?.[0]?.testCategory === selectedFilterCategory);
    }
    else if(filterType === "fullLengthTests") {
      selectedFilterCategory = 2;
      filteredData = groupFilteredTestData?.filter((test) => test?.testData?.[0]?.testCategory === selectedFilterCategory);
    }
    else {
      filteredData = [...groupFilteredTestData];
    }

    setFilteredTestData(filteredData);
    setTimeout(() => {
      setLoading(false);
    }, [200]);
  }

  const getActionText = (test) => {
    let packageId = props.match.params.id;
    let specialTestResultOffline = false;
    let item = test?.testData?.[0];
    let mappingId = item?.mappingId;
    let text;
    if (
      test?.status == "COMPLETED" &&
      props.specialTestsObj &&
      props.specialTestsObj[packageId] &&
      props.specialTestsObj[packageId][mappingId]
    ) {
      let resultTimeData = props.specialTestsObj[packageId][mappingId];
      let dateArray = resultTimeData.resultDate.split("/"); //  "11/07/2019"   "resultTime" : "1700 hrs"
      let hrs = resultTimeData.resultTime.slice(0, 2);
      let min = resultTimeData.resultTime.slice(2, 4);
      var testDate = new Date(
        Date.parse(
          `${dateArray[2]}-${dateArray[0]}-${dateArray[1]} ${hrs}:${min}:00`
        )
      ).getTime();
      let currentDate = Date.now();
      if (currentDate < testDate) {
        text = ` Results available on: ${timeStampdateMonthYear(
          testDate
        )} at ${formatSpecialTestTime(resultTimeData.resultTime)}`;
        specialTestResultOffline = true;
      }
    } else {
    }
    return {
      actionItem:
        testPackageInfo?.expired && test?.status !== "COMPLETED" ? (
          <span className="expired">Expired</span>
        ) : (
          <span
            className={`${test.status.toLowerCase()} ${
              text ? "test-disabled" : ""
            } test-btn`}
          >
            {text || (TestStatus[test?.status] && TestStatus[test?.status].label)}
          </span>
        ),
      specialTestResultOffline: specialTestResultOffline,
    };
  };
  if (loading) {
    bodyComponent.push(<CustomLoader />);
  } else {
    bodyComponent.push(
      <>
        {showExpiredCourseInfo && (
          <ExpiredCourse
            packageInfo={testPackageInfo}
            close={() => {
              setShowExpiredCourseInfo(false);
            }}
            url="/mock-tests"
            buyTitle="Buy Mock Tests"
            productUrlKey="product-testseries"
          />
        )}
        <CustomModal
          title={"Test Schedule & Info."}
          visible={showSchedule}
          modalClassName="schedule-modal"
          onCancel={close}
          closeCallback={close}
        >
          <div dangerouslySetInnerHTML={{ __html: testPackageInfo.desc }}></div>
        </CustomModal>
        <div className="course-listing-body">
          {groups?.length > 0 && (
            <div className="course-listing-nav">
              {groups?.map((groupName, index) => (
                <div
                  onClick={() => setSelectedGroup(index)}
                  className={`cl-nav-item ${
                    selectedGroup == index ? "active" : " "
                  }`}
                >
                  {groupName}
                </div>
              ))}
            </div>
          )}
          <div className="course-listing">
         
            <div className="enrolled-course-listing test-listing-container">
            {testPackageInfo && testPackageInfo?.liveTest ? <div className="live-test-sectional-filters-wrap">
              <span className={`live-test-sectional-filters ${selectedTabFilter === "allLiveTests"  && "live-test-filter-active"}`} onClick={() => setSelectedTabFilter("allLiveTests")}>All Live Tests</span>
              <span className={`live-test-sectional-filters ${selectedTabFilter === "fullLengthTests"  && "live-test-filter-active"}`} onClick={() => setSelectedTabFilter("fullLengthTests")}>Full Length Live Tests</span>
              <span className={`live-test-sectional-filters ${selectedTabFilter === "sectionalLengthTests"  && "live-test-filter-active"}`} onClick={() => setSelectedTabFilter("sectionalLengthTests")}>Sectional Live Tests</span>
            </div> : ""}
            
              {filteredTestData?.length === 0 && (
                <NoResultsFound
                  message={"No Results Found for the search criteria!"}
                />
              )}
              {filteredTestData?.length > 0 && testPackageInfo && Object.keys(testPackageInfo)?.length > 0 && filteredTestData?.map((test) => {
                let item = test?.testData?.[0];
                let difficulty =
                  test?.level == "UNRATED" ? "MODERATE" : test?.level;
                let ActionTextData = getActionText(test);
                let mappingId = item?.mappingId;
                let packageId = props.match.params.id;
                let title = item?.title;
                const exams = item?.examList || [];

                //Moengage event
                var Parenttitle =
                testPackageInfo && testPackageInfo.recentParentInfo
                  ? testPackageInfo.recentParentInfo.title
                  : testPackageInfo.title;
                var data = {
                  package_status: packageId == 557 ? "free" : "paid",
                  // url: location.href,
                  name: Parenttitle,
                  TestId: mappingId,
                  test_id: mappingId,
                  content_title: item?.title,
                  contentID: mappingId,
                  id:
                    testPackageInfo && testPackageInfo.recentParentInfo
                      ? testPackageInfo.recentParentInfo.id
                      : props.match.params.id,
                  subPackageId: props.match.params.id,
                  sub_package_title: testPackageInfo?.title,
                  subPackageType: "TEST_SERIES",
                  category: "TEST_SERIES",
                  action:
                    test.status == "INCOMPLETE"
                      ? "resume_test"
                      : test.status == "COMPLETED"
                      ? "view_results"
                      : "start_test",
                  content_url: getPdpUrl(
                    "TEST_SERIES",
                    props.match.params.id,
                    Parenttitle
                  ),
                  content_subject: BLANK_IDENTIFIER,
                  exam_category: WEB_IDENTIFIER,
                  user_exam_category_selected: WEB_IDENTIFIER,
                  deep_link: BLANK_IDENTIFIER,
                  rating: BLANK_IDENTIFIER,
                  index: BLANK_IDENTIFIER,
                  package_purchased: BLANK_IDENTIFIER,
                  exam: BLANK_IDENTIFIER,
                  language: ENGLISH_IDENTIFIER,
                  content_language: ENGLISH_IDENTIFIER,
                };

                let click = (reAtemptFlag = "") => {
                  if (test?.status == "COMPLETED" && !reAtemptFlag) {
                    testPortalOpened.current = true;
                    redirectToResultAnalysis(
                      encodeURIComponent(title),
                      packageId,
                      mappingId,
                      exams
                    );
                  } else {
                    testPortalOpened.current = true;
                    openTestPortal(
                      mappingId,
                      packageId,
                      title,
                      test.status,
                      reAtemptFlag,
                      exams,
                      "",
                      false,
                      true
                    );
                  }

                  if (typeof Moengage !== "undefined")
                    Moengage.track_event("test", getMoengageMetadata(data));
                };

                if (testPackageInfo?.expired && test?.status !== "COMPLETED") {
                  click = () => {
                    setShowExpiredCourseInfo(true);
                  };
                } else if (test?.status == "COMING_SOON") {
                  click = null;
                }
                if (ActionTextData.specialTestResultOffline) {
                  click = null;
                }
                let liveTestStatusObj = {};
                let source_screen = "mock_test_listing";
                let event_name = "";
                if(item?.liveTest) {
                  liveTestStatusObj = getLiveTestState(test);
                  if (data) {
                    data = {...data, test_type : "live"};
                  }
                  switch (liveTestStatusObj?.status) {
                    case "Start":
                      event_name = "starttest_listing_clicked";
                      break;
                    case "Resume":
                      event_name = "resumetest_listing_clicked";
                      break;
                    case "Result Published":
                      event_name = "showresult_listing_clicked";
                      break;
                    default:
                      event_name = "";
                      break;
                  }
                }
                return (
                  <>
                    { !item?.liveTest ? 
                      <MockTestCard 
                        testPackageInfo={testPackageInfo}
                        item={item}
                        difficulty={difficulty}
                        test={test}
                        click={click}
                        ActionTextData={ActionTextData}
                        liveTestPackage={testPackageInfo?.liveTest}
                      /> : 
                      <LiveTestCard
                        testPackageInfo={testPackageInfo}
                        item={item}
                        test={test}
                        event_name={event_name}
                        source_screen={source_screen}
                        moe_data={data}
                        liveTestPackage={testPackageInfo?.liveTest}
                        testPortalOpened={testPortalOpened}
                      />
                    }
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
  let header = (
    <div className="test-listing-header">
      <div className="tl-header-info">
        <div className="tl-back">
          <div
            className="icon"
            onClick={() => {
              props.history.push("/my-mock-test");
            }}
          >
            <div className="back"></div>
          </div>
        </div>
        <div className="tl-header-details">
          <div className="tl-header-name">{testPackageInfo.title}</div>
          <div>
            {testPackageInfo.expired ? (
              <span className="tl-expired">
                <span className="tl-expired-text">
                  Expired on{" "}
                  {formatDateTime(testPackageInfo.expiryDate, "DD MMM YYYY")}{" "}
                </span>
                <span className="tl-header-schedule">
                  <a
                    onClick={() => {
                      setShowExpiredCourseInfo(true);
                    }}
                  >
                    RENEW PACKAGE
                  </a>{" "}
                </span>
              </span>
            ) : (
              <span className="tl-header-schedule">
                <a
                  onClick={() => {
                    setShowSchedule(true);
                  }}
                >
                  View Test Schedule And Info
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="test-filter">
          <div className="test-filter-input">
            <input
              placeholder="Search Tests"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            ></input>
          </div>
          <div>
            <select
              id="test-status"
              className="test-filter-select"
              value={status}
              onChange={changeStatus}
            >
              {Object.keys(TestStatus).map((status) => {
                if (status == "COMING_SOON") return null;
                let label = TestStatus[status].ddLabel;
                return <option value={status}>{label}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div
      style={{
        position: "relative",
        top: "0px",
        display: "flex",
        flexDirection: "row",
      }}
      className="enrolledCourses"
    >
      <div className="myprofile-container">
        <MyaccountNavbar></MyaccountNavbar>
      </div>

      <div className="course-listing-container">
        <SectionComponent
          header={header}
          body={bodyComponent}
          fromVideo={true}
        ></SectionComponent>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { specialTests } = state;
  return {
    specialTestsObj: specialTests.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialTests: () => dispatch(getSpecialTests()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TestsListing);

import React, { useState, useEffect } from "react";
import Ainput from "../components/library/AInput";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import CustomButton from "../components/library/CustomButton";
import {
  changeDateFormat,
  timeStampdateMonthYear,
} from "../../app/components/utils";
import { pdpUrls } from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import CustomLoader from "../components/library/CustomLoader";
import OrderProductCard from "./OrderProductCard";
import { default as Link } from "../containers/ConnectedLink";
import { checkLoginStatus } from "../data/utils/helpers";

const cookie = new Cookies();
const CardContainer = (props) => {
  let email = cookie.get("cp_user_email");
  let name = cookie.get("cp_user_name");
  const isLoggedIn = checkLoginStatus();
  const [orderDetail, setOrderDetails] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [address, setAddress] = useState("");
  const [titleUrl, setTitleUrl] = useState({
    hasTestSeries: false,
    hasBooks: false,
    hasMags: false,
    haseBooks: false,
    hasVideos: false,
    hasOnlineClass: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (orderId) return;
    let data = props.dataOrderIdData;
    if (data) {
      toggleHandler(data,[]);
    }
  }, [props.dataOrderIdData]);

  const toggleHandler = (orderId, categoryIds) => {
    const  url = isLoggedIn ? pdpUrls.orderDetails : pdpUrls.orderDetailsGuest
    if (isLoading) {
      return false;
    }
    setOrderDetails([]);
    setOrderId(orderId);
    setAddress("");

    if (typeof window != undefined && typeof document != undefined) {
      let element = window.document.getElementById(orderId);

      if (element?.className == "bottom-toggle" && isLoading == false) {
        setIsLoading(true);
        fetch(`${url}/${orderId}`, "GET").then(
          (res) => {
            setIsLoading(false);
            if (res && res.success) {
              if (res.data.packages) {
                let data = res.data.packages;
                res.data.address
                  ? setAddress(res.data.address)
                  : setAddress("");
                for (var i = 0; i < data.length; i++) {
                  data[i].packageCategories = [];
                  if (categoryIds.includes(1)) {
                    data[i].packageCategories.push('TEST_SERIES');
                  }
                  if (categoryIds.includes(2)) {
                    data[i].packageCategories.push('BOOKS');
                  }
                  if (categoryIds.includes(3)) {
                    data[i].packageCategories.push('MAGAZINES'); 
                  }
                  if (categoryIds.includes(4)) {
                    data[i].packageCategories.push('EBOOKS'); 
                  }
                  if (categoryIds.includes(5)) {
                    data[i].packageCategories.push('VIDEOS');
                  }
                  if (categoryIds.includes(6)) {
                    data[i].packageCategories.push('ONLINE_LIVE_CLASSES');
                  }
                  setOrderDetails(data);
                }
              }
            }
          },
          (err) => {
            setOrderId("");
            setOrderDetails("");
            setAddress("");
            setIsLoading(false);
          }
        );
      } else {
        setOrderId("");
        setOrderDetails("");
        setAddress("");
      }
    }
  };

  return props.data && props.data.length > 0 ? (
    props.data.map((value, idx) => {
      return (
        <div className="order-c-parent">
          <div
            className="order-container-mweb"
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <span className="values-order-mweb">
              {timeStampdateMonthYear(value.createdAt)}
            </span>
            {value.status == "PAID" ? (
              <span className="success-message">SUCCESS</span>
            ) : (
              <span className="failed-message">FAILED</span>
            )}
          </div>
          <div className="order-container-mweb">
            <span className="item-web  ">Order Id</span>
            <span className="values-order-mweb d-grow-1">
              {" "}
              #{value.orderId}
            </span>
          </div>

          <div className="order-container-mweb">
            <span className="item-web  ">Order Total</span>
            <span className="values-order-mweb  ">
              {" "}
              &#x20B9; {value.amount} ({value.pC} items)
            </span>
            <span
              id={value.orderId}
              className={
                value.orderId == orderId ? "top-toggle" : "bottom-toggle"
              }
              onClick={() => toggleHandler(value.orderId, value.categoryIds)}
            >
              {" "}
            </span>
          </div>
          <div className="order-container">
            <span className="itemorderdate">
              <span>Order date:</span>
              <span className="values-order">
                {timeStampdateMonthYear(value.createdAt)}
              </span>
            </span>
            <span className="item">
              Order Id:
              <span className="values-order"> #{value.orderId}</span>
            </span>
            {value.status == "PAID" ? (
              <span className="item">
                Status:
                <span className="pulse-success"></span>
                <span className="success-message">SUCCESSFUL</span>
              </span>
            ) : (
              <span className="item">
                Status:
                <span className="pulse-failed"></span>
                <span className="failed-message">FAILED</span>
              </span>
            )}

            <span
              id={value.orderId}
              className={
                value.orderId == orderId ? "top-toggle" : "bottom-toggle"
              }
              onClick={() => toggleHandler(value.orderId, value.categoryIds)}
            >
              {" "}
            </span>
          </div>
          <div className="order-container1 flex-start">
            <span className="item">
              Order Total:
              <span className="values">
                {" "}
                &#x20B9; {value.amount} ({value.pC})
              </span>
            </span>
          </div>
          <div
            id={value.orderId + "orderproductcard"}
            className={
              value.orderId != orderId ? "display-none" : "display-block"
            }
          >
            <OrderProductCard
              orderDetail={orderDetail}
              storeurl={props.storeurl}
              status={value.status}
              address={address}
              orderId={value.orderId}
              history={props.history}
            />
          </div>
        </div>
      );
    })
  ) : props.loading ? (
    <CustomLoader />
  ) : (
    <div className="empty-cart">
      <div className="ec-image">
        <img src="/images/no-result-found.png" width="300" />
        <div className="ec-header">You have not placed any orders yet </div>
      </div>
      <Link to="/">
        <div style={{ padding: "8px" }}>
          <button className="sf-button-secondary"> Go to Home</button>
        </div>
      </Link>
    </div>
  );
};

export default CardContainer;

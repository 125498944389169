import React from "react";
import { isADDA } from "../../../../../data/utils/helpers";
import { pageName } from "../../../../../../constants/appConfig";

const TestAnalysisHeader = ({ title = "" }) => {
  return (
    <section className="resultHeader">
      <div className="fuild-container">
        <div className="result-logo" onClick={() => window.open("/")}>
          <img
            src={isADDA ? "/images/header-logo.svg" : "/images/sankalpB-logo.svg"}
            alt={`${pageName[0]}-logo`}
              title={pageName[0]}
          />
          {title && (
            <span className="contentExamName">{decodeURIComponent(title)}</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default TestAnalysisHeader;

const salientFeaturesData = {
    "ONLINE_LIVE_CLASSES": [
      {
        id: 1,
        name: "Expert Faculties",
        thumbnail: "/images/pdp-default-salient/Liveclass1_ExpertFaculties.svg",
        description:
          "Get access to the top expert faculties of India for best preparation",
      },
      {
        id: 2,
        name: "Interactive Classes",
        thumbnail: "/images/pdp-default-salient/Liveclass2_interactiveclasses.svg",
        description:
          "Engaging interactive classes for best understanding from anywhere",
      },
      {
        id: 3,
        name: "Recorded Videos",
        thumbnail: "/images/pdp-default-salient/Liveclass3_RECORDEDVIDEO.svg",
        description:
          "Get recorded sessions of every live class so that learning never stops",
      },
      {
        id: 4,
        name: "Limited Batch Size",
        thumbnail: "/images/pdp-default-salient/Liveclass4_LimitedBatchTime.svg",
        description:
          "Get personalized attention for your doubts with limited batch size",
      },
    ],
    "TEST_SERIES": [
      {
        id: 5,
        name: "All India Rank",
        thumbnail: "/images/pdp-default-salient/Test1_AllIndiaRanks.svg",
        description:
          "Know where you stand among other aspirants across India!",
      },
      {
        id: 6,
        name: "Detailed Analytics & Solutions",
        thumbnail: "/images/pdp-default-salient/Test2_DetailedSolutions.svg",
        description:
          "Topper comparison, Sectional performance, Detailed solution & much more",
      },
      {
        id: 7,
        name: "Latest Pattern",
        thumbnail: "/images/pdp-default-salient/Test3_LatestPattern.svg",
        description:
          "Get Mocks and sectional tests based on the latest pattern of the exams",
      },
      {
        id: 8,
        name: "Unlimited Reattempts",
        thumbnail: "/images/pdp-default-salient/Test4_UnlimitedReattempts.svg",
        description:
          "Gauge your preparation from time to time with unlimited reattempts",
      },
    ],
    "VIDEOS": [
      {
        id: 9,
        name: "Expert Faculties",
        thumbnail: "/images/pdp-default-salient/Video1_ExpertFaculties.svg",
        description:
          "Get access to the top expert faculties of India for best preparation",
      },
      {
        id: 10,
        name: "High-quality video material",
        thumbnail: "/images/pdp-default-salient/Video2_HightqualityVideo.svg",
        description:
          "Get access to carefully curated high-quality content for best clarity",
      },
      {
        id: 11,
        name: "Self Paced Learning",
        thumbnail: "/images/pdp-default-salient/Video3_SelfPacedLearning.svg",
        description:
          "Learn anytime, anywhere, and at your own pace with Adda's videos",
      },
      {
        id: 12,
        name: "Device Compatibility",
        thumbnail: "/images/pdp-default-salient/Video4_DeviceCompatibility.svg",
        description:
          "Learn across devices, compatible with mobile, tabs, and desktops",
      },
    ],
    "EBOOKS": [
      {
        id: 13,
        name: "Best in Class Material",
        thumbnail: "/images/pdp-default-salient/Ebook1_BestinClassMaterial.svg",
        description:
          "Never compromise on quality with Adda's preparation material",
      },
      {
        id: 14,
        name: "Latest Pattern",
        thumbnail: "/images/pdp-default-salient/Ebook2_LatestPattern.svg",
        description:
          "Preparation material based on the latest exam pattern every year",
      },
      {
        id: 15,
        name: "Self Paced Learning",
        thumbnail: "/images/pdp-default-salient/Ebook3_SelfPacedLearning.svg",
        description:
          "Learn anytime, anywhere, and at your own pace with Adda's E-Books",
      },
      {
        id: 16,
        name: "Device Compatibility",
        thumbnail: "/images/pdp-default-salient/Ebook4_DeviceCompatibility.svg",
        description:
          "Learn across devices, compatible with mobile, tabs, and desktops",
      },
    ],
    "BOOKS": [
      {
        id: 17,
        name: "Latest Pattern",
        thumbnail: "/images/pdp-default-salient/Book1_LatestPattern.svg",
        description:
          "Preparation material based on the latest exam pattern every year",
      },
      {
        id: 18,
        name: "Practice Questions",
        thumbnail: "/images/pdp-default-salient/Book2_PracticeQuestions.svg",
        description:
          "Get access to Previous Year ques., Topicwise ques. and much more",
      },
      {
        id: 19,
        name: "Structured Learning",
        thumbnail: "/images/pdp-default-salient/Book3_StructuredCourseContent.svg",
        description: "Get access to structured content for best preparation",
      },
      {
        id: 20,
        name: "Self Paced Learning",
        thumbnail: "/images/pdp-default-salient/Book4_SelfPacedLearning.svg",
        description:
          "Learn anytime, anywhere, and at your own pace with Adda's Books",
      },
    ],
};
  
  export { salientFeaturesData };
  
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import fetch from "../data/utils/fetch";
import {withCookies} from 'react-cookie';
import CustomLoader from "../components/library/CustomLoader";
import {STOREFRONT_URI} from '../../constants/appConfig'
import { getParameterByName } from '../data/utils/helpers';

const JoinImpartus = (props)=>{
            useEffect(()=>{
                getJoinUrl();
            },[])
        const getJoinUrl = ()=>{
            //https://www.adda247.com/impartus?packageid=123&scheduleid=5354
            let token = getParameterByName("jwt");
            if(token){
              props.cookies.set('cp_token', token, {path: '/'});
            }
            if(!props.cookies.get('cp_token')){
                props.history.push('/');
            }
            else{
              let packageid = getParameterByName("packageid");
              let scheduleid = getParameterByName("scheduleid");
            fetch(
                `${STOREFRONT_URI}/api/v2/live-classes/${packageid}/schedule/${scheduleid}`,
                // /api/v2/live-classes/${props.match.params.packageid}/schedule/${props.match.params.scheduleid}`,
                "GET"
              ).then(
                res => {
                    if(res.success && res.data && res.data.joinUrl){
                        window.location.replace(res.data.joinUrl)
                    }
                    else{
                        props.history.push('/');
                    }
                },
                err => {
                    props.history.push('/');
                }
              );
            }
        }
        return(<>
         <div
      style={{
        position: "relative",
        top: "80px",
        display: "flex",
        flexDirection: "row",
        justifyContent:'center'
      }}
    >
        <CustomLoader/></div></>)
}
const mapStateToProps = state => {
    return {
      userDetails: state.header.userInfo,
    };
  };
  
  export default withCookies(connect(mapStateToProps, null)(JoinImpartus));
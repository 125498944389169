import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { BrowseByProductType } from "../../components/home";
import { dfpSlotsSubjectSeoPage, subjectPagesAvailable } from "../../../constants/appConstants";
import GetLink from "../../components/GetLink";
import BreadCrumb from "../../components/library/BreadCrumb";
import BreadcrumbSchema from "../../components/library/BreadcrumbSchema";
import {
  capitalizeWords,
  sanitizeQuestionData,
  scrollToTop,
} from "../../data/utils/helpers";
import Pagination from "../../components/library/Pagination";
import DfpAdPdp from "../../components/library/DfpAdPdp";
import CustomLoader from "../../components/library/CustomLoader";
import ErrorPage from "../../components/ErrorPage";
import { Link } from "react-router-dom";
import fetch from "../../data/utils/fetch";
import { TEST_SERIES_URL } from "../../../constants/appUrls";
import QuestionBox from "../../components/QuestionPage/QuestionBox";
import CustomHelmet from "../../components/library/CustomHelmet";

const SubjectPage = () => {
  const { subject } = useParams();
  const [subjectData, setSubjectData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);
  const [totalCount, setTotalCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAd, setShowAd] = useState(true);

  useEffect(() => {
    setSubjectData([]);
    setErrorMsg("");
    fetchSubjects(1);
  }, [subject]);

  const fetchSubjects = (pg) => {
    setLoading(true);
    setErrorMsg("");
    if (subjectPagesAvailable?.find(sub => sub.title === subject)) {
      fetchSubjectQuestions(pg);
    } else {
      setErrorMsg(`No Data for subject ${subject}!`);
      setLoading(false);
    }
  };

  const fetchSubjectQuestions = (pg) => {
    setLoading(true);
    scrollToTop();
    setCurrentPage(pg);
    fetch(
      `${TEST_SERIES_URL}/questions/view/Questions/${subject
        ?.replace(/-/g, "_")
        ?.toUpperCase()}?page=${pg - 1}&size=${pageSize}`
    )
      .then((res) => {
        if (res?.success && res?.data) {
          let subData = [];
          res?.data?.forEach(async (item) => {
            let d = await sanitizeQuestionData(item);
            subData.push(d);
          });
          setTotalCount(res?.count);
          setSubjectData(subData);
          if (!res?.data?.length)
            setErrorMsg(
              `No Data for subject ${capitalizeWords(
                subject?.replace(/-/g, " ")
              )}!`
            );
        } else {
          setSubjectData([]);
          setErrorMsg(
            `No Data for subject ${capitalizeWords(
              subject?.replace(/-/g, " ")
            )}!`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Something Went Wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="sank-seo-wrap adda-main-wrap">
      <div className="sank-seo-container home-new">
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {showAd && <div className="subject-page-dfp-banner">
              <div className="spDFPcontent">
              <DfpAdPdp dfpSlot={dfpSlotsSubjectSeoPage} />
              <div class="close_ad" onClick={() => setShowAd(prev => !prev)}>X</div>
              </div>
            </div>}
            <div className="breadcrumb-section">
              <BreadcrumbSchema
                items={[
                  {
                    title: "Home",
                    url: "/",
                  },
                  {
                    title: capitalizeWords(subject),
                    url: `/${subject}/question`,
                  },
                ]}
              />
              <BreadCrumb
                routeData={[
                  { name: "Home", target: "/" },
                  {
                    name: `${capitalizeWords(subject?.replace(/-/g, " "))}`,
                    target: "",
                  },
                ]}
              />
            </div>
            <CustomHelmet
              title={`${capitalizeWords(subject)} Questions`}
              description={subjectPagesAvailable?.find(sub => sub.title === subject)?.desc || `In this article, we have covered ${subject} Questions related to class 11th and 12th which will be helpful for IIT JEE/NEET examination`}
              url={`/${subject}/question`}
            />
            <div className="subjectSectionWrap">
              {!errorMsg && subjectData?.length ? (
                <div className="subjectContent">
                  <h1 className="subjectHeading">{capitalizeWords(subject)}</h1>
                  <h2 className="subjectSubHeading">Latest Questions</h2>
                  {subjectData?.map((subData, idx) => (
                    <QuestionBox key={`sub__${idx}`} data={subData} />
                  ))}
                  <Pagination
                    current={currentPage}
                    count={totalCount}
                    pageSize={pageSize}
                    onChange={fetchSubjectQuestions}
                    className="paginationBtnsbottom"
                  />
                </div>
              ) : (
                <ErrorPage errorMsg={errorMsg} />
              )}
              <div className="subjectSidebar">
                <div className="otherSubjectHeading">Other Subjects</div>
                <div className="otherSubjectlist">
                  <ul>
                    {subjectPagesAvailable
                      ?.filter((sub) => sub?.title !== subject)
                      ?.map((sub, idx) => (
                        <Link
                          key={`sub__${idx}`}
                          to={{ pathname: `/${sub?.title?.toLowerCase()}/question` }}
                        >
                          <li>{capitalizeWords(sub?.title?.replace(/-/g, " "))}</li>
                        </Link>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
        <BrowseByProductType />
        <GetLink pos="subject_page" />
      </div>
    </div>
  );
};

export default SubjectPage;

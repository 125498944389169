import reducerRegistry from "../../reducerRegistry";
import types from "./types";

function seoData(state = {}, action) {
  switch (action.type) {
    case types.GET_SEO_DATA: {
      let data = action.payload.data;

      if (data) {
        return {
          ...state,
          [data.url]: data,
        };
      } else {
        return { ...state };
      }
    }
    case types.SEO_DATA: {
      let data = action.payload.data;
      let url = action.payload.url;
      if (action.payload.data) {
        return {
          ...state,
          [url]: data,
        };
      } else {
        return { ...state };
      }
    }

    default:
      return { ...state };
  }
}

reducerRegistry.register("seoData", seoData);
export default seoData;
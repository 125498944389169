import React from 'react';

const AInput = (props)=>{
    let className,error,errorMessage,parentProps;
    ({className,error,errorMessage,...parentProps}=props)
 
    return <><input
    className={`a-input ${className?className:''} ${error?'error':''}`}
    {...parentProps}
    />
        {props.show && <label className="floating-label">{props.name}</label>}
        {props.isCheck && <span className="checkmark-input"></span>}
        {error ? <div className='a-input-errormessage'>{errorMessage}</div> : ""}
    </>

}
export default AInput;
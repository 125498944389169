import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import CustomLoader from "../../../components/library/CustomLoader";
import CategoriesTabs from "../../../components/PPC/CategoriesTabs";
import ExamModeToggler from "../../../components/PPC/ExamModeToggler";
import {
  getAllAvailableDataOfthePackage,
  getPackageCategories,
  getParentPackageExams,
  setFullPackageData,
  syncStateWithLocalStorage,
  toggleExamModeKey,
} from "../../../data/ducks/header/actions";
import {
  capitalizeWords,
  isADDA,
  removeHyphensAndInsertSpaces,
} from "../../../data/utils/helpers";
import PPCWrapper from "../PPCWrapper";
import ChildPackageListings from "./ChildPackageListings";
import PPCComingSoonListings from "./PPCComingSoonListings";
import PPCContinueListing from "./PPCContinueListing";

const PackageDetail = () => {
  const [showContentLoading, setShowContentLoading] = useState(true);
  const [examId, setExamId] = useState(0);
  const {
    packageData,
    packageExamsData,
    examModes = {},
  } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  const { packageId, packageName } = useParams();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState();
  const activeCategory = new URLSearchParams(search).get("category");
  let { examsData } = packageExamsData;

  let { childData, comingSoonData, continueStudyingData, categoryTabs } =
    packageData;
  let isExamModeEnabled = false;
  if (packageId && examModes && examModes[packageId]) {
    isExamModeEnabled = examModes[packageId] || false;
  }
  let localStorageExamModes = {};
  if (typeof window !== "undefined" && localStorage.getItem("examModes")) {
    try {
      localStorageExamModes =
        JSON.parse(localStorage.getItem("examModes")) || {};
    } catch (error) {
      console.log("Error while getting ExamModes -->", error);
    }
  }
  /**
   * Steps for handling packageData API CALLS.
   * Step1 -> first call getExamsData API.
   * Step2 -> selectedExam list length is 1,then take examId and called the next services based on that.
   */

  const getAllAvailableData = async (packageId, activeTab, examId) => {
    setShowContentLoading(true);
    let result = await getAllAvailableDataOfthePackage(
      packageId,
      activeTab,
      examId
    );
    setShowContentLoading(false);
    dispatch(setFullPackageData(result, activeTab));
  };

  // service 1 that will called on first render.
  const getPackageExams = async () => {
    const result = await dispatch(getParentPackageExams(packageId));
    const { data } = await result[0];
    let resExamId =
      data?.selectedExamList?.length === 1 && data?.selectedExamList[0]?.id;
    if (resExamId && isExamModeEnabled) {
      setExamId(resExamId);
    }
    // redirect to yourExam view page,when selected exam >=1
    if (data?.selectedExamList?.length >= 1 && isExamModeEnabled) {
      history.replace(`${pathname}/exams`);
    }
    getPackageCategoriesData(packageId, isExamModeEnabled ? resExamId : null);
  };
  const getPackageCategoriesData = async (packageId, examId) => {
    dispatch(getPackageCategories(packageId, examId));
  };
  // service 2 called after getting data from the service 1.
  useEffect(() => {
    if (packageId) {
      getPackageExams();
    }
  }, [packageId, isExamModeEnabled]);

  //call the services onChanging any of the flags data.
  useEffect(() => {
    if (activeTab && packageId) {
      getAllAvailableData(packageId, activeTab, examId);
    }
  }, [activeTab, packageId, examId, isExamModeEnabled]);

  // sync tab state
  useEffect(() => {
    const validActiveCategory = categoryTabs?.find(
      (cat) => cat?.category === activeCategory
    )?.category;
    const activeTabValue = validActiveCategory || categoryTabs[0]?.category;
    if (activeTabValue) {
      setActiveTab(activeTabValue);
    }
  }, [activeCategory, categoryTabs]);
  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
    {
      route: pathname,
      name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
    },
  ];

  // redirect to yourExam view page,when selected exam >=2
  // if (yourSelectedExams?.selectedExamList?.length >= 2)
  //   return <Redirect to={`${pathname}/exams`} />;

  const storeExamModekey = (packageId, isEnabled) => {
    dispatch(toggleExamModeKey(packageId, isEnabled));
  };

  const onChangingExamMode = (e) => {
    if (activeCategory) {
      history.replace(pathname); // reset to default pathname url
    }
    setExamId(0);
    storeExamModekey(packageId, e.target.checked);
  };
  useEffect(() => {
    // check if key is already available in examModes object.
    // this will run only when selectedExamList is available.
    if (
      packageId &&
      !localStorageExamModes.hasOwnProperty(packageId) &&
      examsData?.selectedExamList?.length
    ) {
      storeExamModekey(packageId, true);
    }
  }, [packageId, examsData]);
  // sync global redux examModes state with localStorageExamModes
  useEffect(() => {
    dispatch(syncStateWithLocalStorage(localStorageExamModes));
  }, []);
  return (
    <PPCWrapper
      breadCrumbRoutes={breadCrumbsList}
      pageTitle={removeHyphensAndInsertSpaces(packageName)}
      LeftSection={
        // show the ExamMode only when selectedExamList is available.
        <ExamModeToggler
          enabled={isExamModeEnabled}
          onChange={onChangingExamMode}
          disabled={showContentLoading} // disable the event till content loading.
          isVisible={examsData?.selectedExamList?.length > 0}
        />
      }
      rightSection={
        // show the  manageExam button,when ExamMode is enabled.

        <div
          className="menage-exam-btn"
          style={{
            visibility:
              isExamModeEnabled && examsData?.selectedExamList?.length
                ? "visible"
                : "hidden",
          }}
        >
          {isExamModeEnabled && examsData?.selectedExamList?.length && (
            <Link to={`${pathname}/selectExams`}>Manage Exams</Link>
          )}
        </div>
      }
    >
      <section
        className={`ppc-exams-listings ${
          examsData?.selectedExamList?.length ? "examMode-active" : ""
        }`}
      >
        {/* <div className="mobile-sarch-icon">
          <img src="/images/ppc-search-icon.svg" />
        </div> */}
        {/* <div className="ppc-listing-search">
          <input type="text" placeholder="Search your exam" />
          <div className="searchicon">
            <img src="/images/ppc-search-icon.svg" />
          </div>
        </div> */}
        <CategoriesTabs
          tabs={categoryTabs}
          activeTab={activeTab}
          disableEvent={showContentLoading}
        />
        {showContentLoading ? (
          <CustomLoader />
        ) : (
          <>
            <PPCContinueListing
              data={continueStudyingData}
              category={activeTab}
            />
            <PPCComingSoonListings data={comingSoonData} category={activeTab} />
            <ChildPackageListings
              data={childData}
              category={activeTab}
              comingSoonDataLength={comingSoonData?.packages?.length}
              //handle to store paginated data on scroll in passed state when single exam is selected.
              examId={examId}
              stateKeyName="childData" // where you store data
            />
          </>
        )}
      </section>
    </PPCWrapper>
  );
};

export default PackageDetail;

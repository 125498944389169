import React, { Component } from "react";
import MyaccountNavbar from "./Myaccountnavbar";
import CardContainer from "./CardContainer";
import OrderHeader from "./OrderHeadre";
import fetch from "../data/utils/fetch";
import CustomLoader from "../components/library/CustomLoader";
import { pageName, pdpUrls } from "../../constants/appConfig";
import { renderStatic } from "react-helmet";
import OrderProductCard from "./OrderProductCard";
import Pagination from "../components/library/Pagination";
import { Cookies } from "react-cookie";
import { Redirect } from "react-router";
import { checkLoginStatus, scrollToTop } from "../data/utils/helpers";
import CustomModal from "../components/library/CustomModal";
import { timestampToDateTimedaysecond } from "../components/utils";
import CustomHelmet from "../components/library/CustomHelmet";
const cookie = new Cookies();
class MyOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderDetails: "",
      loading: true,
      size: 10,
      page: 1,
      filter: "PAID",
      totalCount: 0,
      errorMessage: "There are no product purchased by you",
      dataOrderId: "",
      orderId: null,
      visible: false,
      trackerStatusdata: "",
      data: "",
      address: "",
    };
  }

  defaultMetaTitle = `My Orders`;
  defaultMetaDesc = `My Orders contains all deatils about the product purchased by me.`;

  allOrdersHandler = () => {
    this.setState({ totalCount: 0 });

    this.setState({ errorMessage: "There are no product purchased by you" });

    let status = "PAID" + "," + "FAILED" + "," + "ABANDONED" + "," + "RECEIVED";
    this.setState({ page: 1 });
    let page = 1;
    let size = this.state.size;

    this.setState({ filter: "ALL" });
    this.fetchOrderDetails(status, page, size);
  };

  paidOrdersHandler = () => {
    this.setState({ totalCount: 0 });
    let status = "PAID";
    this.setState({ page: 1 });
    let page = 1;
    let size = this.state.size;
    this.fetchOrderDetails(status, page, size);
    this.setState({ filter: "PAID" });
    this.setState({ errorMessage: "There are no product purchased by you" });
  };
  failedOrdersHandler = () => {
    this.setState({ totalCount: 0 });
    this.setState({ page: 1 });
    let page = 1;
    let status = "FAILED" + "," + "ABANDONED" + "," + "RECEIVED";
    this.setState({ errorMessage: "There are no failed product " });
    let size = this.state.size;
    this.fetchOrderDetails(status, page, size);
    this.setState({ filter: "FAILED" });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page != this.state.page) {
      if (this.state.filter == "ALL") {
        let status =
          "PAID" + "," + "FAILED" + "," + "ABANDONED" + "," + "RECEIVED";
        let page = this.state.page;
        let size = this.state.size;
        this.fetchOrderDetails(status, page, size);
      } else if (this.state.filter == "FAILED") {
        let status = "FAILED" + "," + "ABANDONED" + "," + "RECEIVED";
        this.fetchOrderDetails(status, this.state.page, 10);
      } else {
        this.fetchOrderDetails("PAID", this.state.page, 10);
      }
    }
  }

  handleTrackOrderModal = () => {
    const token = checkLoginStatus();
    const  url = token ? pdpUrls.orderDetails : pdpUrls.orderDetailsGuest
    const search = this.props.location.search;
    let orderId = new URLSearchParams(search).get("orderId");
    this.setState({
      orderId: orderId,
    });
    if (orderId && token) {
      this.setState({ visible: true });
      fetch(`${url}/${orderId}`, "GET")
        .then((res) => {
          if (res.data.packages) {
            if (res.data.address) {
              this.setState({
                address: res.data.address,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      fetch(`${pdpUrls.trackerAPi}?orderId=${orderId}`, "GET")
        .then(
          (res) => {
            if (res && res.data) {
              this.setState({
                data: res.data,
                trackerStatusdata: res.data.detailInfos,
              });
            }
          },
          (err) => {
            this.setState({
              visible: false,
              data: "",
              settrackerStatusdata: "",
            });
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
    else if(!token && orderId){
      window.location.replace('/#show-login');
    }
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
    window.location.replace("/my-orders");
  };

  componentDidMount() {
    this.handleTrackOrderModal();
    this.fetchOrderDetails("PAID", this.state.page, 10);
    this.setState({ filter: "PAID" });
  }

  fetchOrderDetails(status, page, size) {
    this.setState({ orderDetails: "" });
    this.setState({ totalCount: 0 });
    this.setState({ loading: true });
    fetch(
      `${pdpUrls.orderApi}?status=${status}&page=${page - 1}&size=${size}`,
      "GET"
    ).then(
      (res) => {
        if (res && res.success) {
          this.setState({ orderDetails: res.data });
          this.setState({ totalCount: res.message });
          this.setState({ loading: false });
        }
        let data = this.props.location?.state?.orderId;
        if (data) {
          let result = res.data.some((item) => item.orderId == data);
          if (!result) {
            alert("Your Order is too late");
          } else {
            this.setState({
              dataOrderId: this.props.location?.state?.orderId,
            });
          }
        }
      },

      (err) => {
        this.setState({ loading: false });
      }
    );
  }

  setPage = (page) => {
    if (this.state.page != page) this.setState({ results: null });
    this.setState({ page: page });
    scrollToTop(100);
  };

  render() {
    const { data, visible, trackerStatusdata, orderId, address } = this.state;
    let email = cookie.get("cp_user_email");
    let name = cookie.get("cp_user_name");
    const token = cookie.get("cp_token");
    if (orderId) {
      return (
        <div className="account-container">
          <style
            dangerouslySetInnerHTML={{
              __html: `
                    .web-footer{
                        margin-top:0px !important;
                    }
                    `,
            }}
          />
          <CustomHelmet
              title={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaTitle
                  ? this.props.seoData[pathname].metaTitle
                  : this.defaultMetaTitle}
              description={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaDescription
                  ? this.props.seoData[pathname].metaDescription
                  : this.defaultMetaDesc}
              url={
                (this.props.seoData &&
                  this.props.seoData[pathname] &&
                  this.props.seoData[pathname].canonical) ||
                this.props.req_url
              }
              keywords={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].keywords
              }
              noFollow={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noFollow
              }
              noIndex={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noIndex
              }
            />
          <CustomModal
            modalClassName={"modalZindex"}
            footer={null}
            visible={visible}
            title={<div className={"modal-header"}>Order Tracker</div>}
            onCancel={() => this.closeModal()}
            closeCallback={() => this.closeModal()}
            // closeCallback={()=>this.setState({visible :false})}
            closable={true}
            maskClosable={false}
            keyboard={false}
            bodyStyle={{ overflowY: "auto" }}
          >
            <div style={{ padding: "15px" }}>
              <div className="d-flex tracker-header">
                <div className="d-flex d-f-column d-grow-1">
                  <span className="track-label-header">Shipment Status</span>
                  <span className="d-flex">
                    <span className="track-label">Last Status Date</span>
                    <span className="track-order-time">
                      {data &&
                        data.laststatustime &&
                        timestampToDateTimedaysecond(data.laststatustime)}
                    </span>
                  </span>
                  <span className="d-flex  ">
                    <span className="track-label">Last Status</span>
                    <span className="track-order-time">{data.lastStatus}</span>
                  </span>
                </div>
                <div className="d-flex d-f-column">
                  <span className="track-label-header">Shipment Details</span>
                  <span className="d-flex">
                    <span className="track-label">Destination:</span>
                    <span className="track-order-time">{address.city}</span>
                  </span>
                  <span className="d-flex">
                    <span className="track-label">Destination pincode</span>
                    <span className="track-order-time">{address.pincode}</span>
                  </span>
                </div>
              </div>
              {trackerStatusdata
                ? trackerStatusdata.map((result, idx) => {
                    return (
                      <div
                        className="track-p"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {trackerStatusdata.length - 1 != idx ? (
                          <div className="tracker-line" data-key={idx}></div>
                        ) : null}
                        <span
                          className={`tracker-dot ${idx == 0 ? "first" : ""}`}
                        ></span>
                        <span className="track-order-time">
                          {timestampToDateTimedaysecond(result.timestamp)}
                        </span>
                        <span className="status-shown">
                          <span dangerouslySetInnerHTML={{__html: result.activityType}}/>
                          <span className="track-order-city">
                            {result.origin},IN
                          </span>
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
          </CustomModal>
        </div>
      );
    } else {
      if (email && name && token) {
        return (
          <div className="account-container myaOrderSlider">
            <style
              dangerouslySetInnerHTML={{
                __html: `
                          .web-footer{
                              margin-top:0px !important;
                          }
                          `,
              }}
            />
            <CustomHelmet
              title={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaTitle
                  ? this.props.seoData[pathname].metaTitle
                  : this.defaultMetaTitle}
              description={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaDescription
                  ? this.props.seoData[pathname].metaDescription
                  : this.defaultMetaDesc}
              url={
                (this.props.seoData &&
                  this.props.seoData[pathname] &&
                  this.props.seoData[pathname].canonical) ||
                this.props.req_url
              }
              keywords={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].keywords
              }
              noFollow={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noFollow
              }
              noIndex={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noIndex
              }
            />
            <div className='myacountsliderNav'>
              <MyaccountNavbar />
            </div>
            <div style={{ flexGrow: 1 }} className="inside-container div-100">
              <div>
                <OrderHeader
                  failedOrdersHandler={this.failedOrdersHandler}
                  paidOrdersHandler={this.paidOrdersHandler}
                  allOrdersHandler={this.allOrdersHandler}
                  filter={this.state.filter}
                />
              </div>
              <CardContainer
                dataOrderIdData={this.state.dataOrderId}
                data={this.state.orderDetails}
                storeurl={pdpUrls.storeurl}
                loading={this.state.loading}
                errorMessage={this.state.errorMessage}
                history={this.props.history}
              />
              {this.state.orderDetails.length > 0 &&
              this.state.totalCount > 10 ? (
                <div className="pagination">
                  <span className="value-pagination">
                    {(this.state.page - 1) * 10 + 1}-
                    {this.state.page * 10 > this.state.totalCount
                      ? this.state.totalCount
                      : this.state.page * 10}{" "}
                    of {this.state.totalCount}
                  </span>
                  <Pagination
                    current={this.state.page}
                    onChange={this.setPage}
                    count={this.state.totalCount}
                    pageSize={10}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      } else {
        return <Redirect to="/" />;
      }
    }
  }
}
export default MyOrder;

import React, { useState, useEffect } from "react";
import { isEmpty } from "../../../data/utils/helpers";
import NewCarousel from "../../library/NewCarousel";
import FilterTopicHeading from "../FilterTopicHeading";
import QuickViewProductCard from "../../home/QuickViewProductCard";
import { useSelector } from "react-redux";
import { checkIsPackageWishList, useWishListedPackageIds } from "../../utils";
const carouselContainer = React.createRef();

const MainListing = (props) => {
  const [quickViewPopup, setQuickViewPopup] = useState(false);
  const { hoveredPackageInfos = {} } = useSelector((state) => state.header);
  const [wishListPackageIds] = useWishListedPackageIds();
  const quickLinkMapping = {
    "online live classes": "olc",
    "test series": "test-series",
    "e-books": "ebooks",
    publications: "books",
    "video courses": "videos",
    "3d learning": "models",
    "3d models": "models"
  };

  useEffect(() => {
    var coordinates = JSON.parse(sessionStorage.getItem("QVPCscrollVal"));
    if (coordinates != undefined) {
      typeof window !== "undefined" &&
        window.scrollTo({ left: 0, top: coordinates.Y - 80 });
      sessionStorage.removeItem("QVPCscrollVal");
    }
  }, []);
  return (
    <>
      {
        // props.params ?
        !isEmpty(props.getFilterPagesData) &&
          props.getFilterPagesData.map((items, i) => {
            let categoryName = items.suggestionCategory;
            return (
              <>
                <div
                  key={i}
                  className={"category-list-alt"}
                  id={quickLinkMapping[items.suggestionCategory.toLowerCase()]}
                >
                  {items.packageEsEntity && items.packageEsEntity.length ? (
                    props.referrer === "exam-page" ? (
                      <div>
                        <FilterTopicHeading
                          count={items.count}
                          topicHeading={items.suggestionCategory}
                          viewAll={true}
                          clicked={props.vieAllClicked}
                          facetsList={props.facetsList}
                          examName={props.examName}
                          categoryName={categoryName}
                          courseDetail={props.courseDetail}
                          course={props.title || props.course}
                        />
                      </div>
                    ) : (
                      <div>
                        <FilterTopicHeading
                          count={items.count}
                          topicHeading={items.suggestionCategory}
                          viewAll={true}
                          clicked={props.vieAllClicked}
                          facetsList={props.facetsList}
                          examName={props.course}
                          categoryName={categoryName}
                          courseDetail={props.courseDetail}
                          course={props.title || props.course}
                        />
                      </div>
                    )
                  ) : null}

                  <div className={"list-item-container product-wrapper"}>
                    {items?.packageEsEntity?.length > 0 && (
                      <NewCarousel
                        ref={carouselContainer}
                        id={`listingContainer9`}
                      >
                        {items.packageEsEntity.map((item, index) => {
                          const isWishList = checkIsPackageWishList(
                            wishListPackageIds,
                            item?.id
                          );
                          return (
                            <>
                              <QuickViewProductCard
                                index={index}
                                key={item.id}
                                id={item.id}
                                onClick={props.handleDetailCardClick}
                                cardtitle={item.title}
                                slugURL={item.slugURL}
                                src={item.imgUrl}
                                datasrc={item.imageUrl}
                                categoriesincluded={
                                  !isEmpty(item.subCategories)
                                    ? item.subCategories.map((e) => e.category)
                                    : []
                                }
                                sellingprice={item.price}
                                maximumretailprice={item.maxPrice}
                                categoryname={categoryName}
                                coupons={{
                                  coupons: item.coupons,
                                  defaultCoupons: item.defaultCoupons,
                                }}
                                item={item}
                                setQuickViewPopup={(flag) => {
                                  setQuickViewPopup(flag);
                                }}
                                productDetailInfo={
                                  hoveredPackageInfos[item.id] &&
                                  hoveredPackageInfos[item.id]
                                    .packageEsEntity[0]
                                }
                                isProductInfoExist={hoveredPackageInfos.hasOwnProperty(
                                  item.id
                                )}
                                source_screen="Product Listing"
                                isWishList={isWishList}
                              />
                            </>
                          );
                        })}
                      </NewCarousel>
                    )}
                  </div>
                  <div className="view-all-dynamic-page">
                    {props.referrer === "exam-page" ? (
                      <div>
                        <FilterTopicHeading
                          count={items.count}
                          topicHeading={items.suggestionCategory}
                          viewAll={true}
                          clicked={props.vieAllClicked}
                          facetsList={props.facetsList}
                          examName={props.examName}
                          categoryName={categoryName}
                          courseDetail={props.courseDetail}
                          course={props.title || props.course}
                        />
                      </div>
                    ) : (
                      <div>
                        <FilterTopicHeading
                          count={items.count}
                          topicHeading={items.suggestionCategory}
                          viewAll={true}
                          clicked={props.vieAllClicked}
                          facetsList={props.facetsList}
                          examName={props.course}
                          categoryName={categoryName}
                          courseDetail={props.courseDetail}
                          course={props.title || props.course}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })
      }
    </>
  );
};
export default MainListing;

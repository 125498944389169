import React, { useMemo, useState } from "react";
import { createResponseObjectFromArray } from "../../../../data/utils/helpers";
import { testTimer } from "../../../utils";
import { openTestPortal } from "../TakeTest/util";
import ComparisonChart from "./Components/ComparisonChart";
import LeaderBoard from "./Components/LeaderBoard";
import QuestionDistribution from "./Components/QuestionDistribution";
import ScoreAnalysisPieChart from "./Components/ScoreAnalysisPieChart";
import ScoreCard from "./Components/ScoreCard";
import SectionTabs from "./Components/SectionTabs";
import TestRating from "./Components/TestRating";
import TimeDistriButionPieChart from "./Components/TimeDistriButionPieChart";

const SectionalAnalysis = ({
  sectionTabs = [],
  testResult = {},
  mappingId = 0,
  packageId = 0,
  title = "",
  rating,
  setRating = () => {},
  isPPC,
  moengageData,
  freeContentFlag = false,
  ratingAvailable,
  exam,
  mode=""
}) => {
  const [selectedSection, setSelectedSection] = useState(sectionTabs[0]);
  const [sectionalAnalysisList, setSectionalAnalysisList] = useState({});

  const { overall, sections, testInfo, topperInfo, toppersList } = testResult;
 
  const timeDistribution = useMemo(() => {
    if (!Object.keys(testResult).length) return [];
    const sectionsByKeys = createResponseObjectFromArray(
      sections || [],
      "secDN"
    );
    // here we store list of section analysis by key in state..
    setSectionalAnalysisList(sectionsByKeys);

    // timeDistribution analysis..
    let timeDistributionData = [["Section", "time"]];
    testResult?.sections?.forEach((section) => {
      // exclude onlyDtbSections,because we don't show analysis for sections with only dtb.
      if (section?.dtbQuesSecCount !== section?.totalQuestions) {
        timeDistributionData.push([
          `${section.secDN} (Time Spent - ${testTimer(
            section?.timeSpent / 1000
          )})`,
          Math.floor(section.timeSpent / 1000),
        ]);
      }
    });
    return timeDistributionData;
  }, [testResult]);

  // here we return respective sectional data.
  const selectedSectionData = useMemo(() => {
    if (!selectedSection || !Object.keys(sectionalAnalysisList).length)
      return {};

    return sectionalAnalysisList[selectedSection];
  }, [selectedSection, sectionalAnalysisList]);
  const totalQuestionExcludeDtb = selectedSectionData?.secOpt ? selectedSectionData?.attemptAllowed - selectedSectionData?.dtbQuesSecCount :
    selectedSectionData?.totalQuestions - selectedSectionData?.dtbQuesSecCount;
  const attemptedPercentile =
    ((selectedSectionData?.correctAnswerCount +
      selectedSectionData?.incorrectAnswerCount) /
      totalQuestionExcludeDtb) *
    100;
  // here we return respective sectional topperInfo.
  const topperInfoData = useMemo(() => {
    if (!selectedSection) return {};
    return createResponseObjectFromArray(topperInfo?.sections || [], "secDN")[
      selectedSection
    ];
  }, [selectedSection]);

  // here we return respective sectional score comparison.
  const sectionalScoreComparisonData = useMemo(() => {
    if (!selectedSection) return [];
    const currentSectionData = sectionalAnalysisList[selectedSection] || {};
    // current user score analysis..
    let {
      correctAnswerCount,
      incorrectAnswerCount,
      totalQuestions,
      dtbQuesSecCount = 0,
      nonEvaluatedAnswerCount = 0, // optional test
      info,
    } = currentSectionData;
    let unattemptedCount =
      totalQuestions -
      dtbQuesSecCount -
      nonEvaluatedAnswerCount -
      (correctAnswerCount + incorrectAnswerCount);

    let topperUnattemptedCount =
      topperInfoData?.totalQuestions -
        topperInfoData?.dtbQuesSecCount -
        (topperInfoData?.nonEvaluatedAnswerCount || 0) -
        (topperInfoData?.correctAnswerCount +
          topperInfoData?.incorrectAnswerCount) || 0;
    let sectionalScore = [["Section", "Correct", " Incorrect", "Unanswered"]];
    let keys = ["You", "Average", "Highest"];
    keys?.forEach((key) => {
      switch (key) {
        case "You":
          sectionalScore.push([
            key,
            correctAnswerCount,
            incorrectAnswerCount,
            unattemptedCount,
          ]);
          break;
        case "Average":
          sectionalScore.push([
            key,
            info?.averageCorrect,
            info?.averageIncorrect,
            (testInfo?.unattemptedCount - (testInfo?.unevaluatedCount || 0)) || 0,
          ]);
          break;
        case "Highest":
          sectionalScore.push([
            key,
            topperInfoData?.correctAnswerCount,
            topperInfoData?.incorrectAnswerCount,
            topperUnattemptedCount,
          ]);
          break;
        default:
          break;
      }
    });
    return sectionalScore;
  }, [selectedSection, sectionalAnalysisList]);

  // pieChart data
  const pieChartConfg = useMemo(() => {
    if (!Object.keys(selectedSectionData).length) return [];
    let dataConfigs = [
      {
        label: "Accuracy",
        percVal: selectedSectionData?.accuracy || 0,
        progressSelector: "circular-progressTwo",
        progressValue: 0,
        progressEndValue: selectedSectionData?.accuracy || 0,
        speed: 20,
        gradientsColorCode: ["#0B8F87", "#DCD3D3"],
        legendClassName: "accuracytotal",
      },
      {
        label: "Attempted",
        percVal: attemptedPercentile || 0,
        progressSelector: "circular-progressThree",
        progressValue: 0,
        progressEndValue: attemptedPercentile || 0,
        speed: 30,
        gradientsColorCode: ["#FFCF5C", "#DCD3D3"],
        legendClassName: "attemptedTotal",
      },
    ];
    return dataConfigs;
  }, [selectedSectionData]);
  return (
    <>
      <SectionTabs
        tabs={sectionTabs}
        activeTab={selectedSection}
        onClick={setSelectedSection}
      />
      <section className="rowWrap">
        <div className="rowWidth8">
          <div
            className={`sectionalBoxwrap ${
              freeContentFlag || mode ? "sectionalBox50" : ""
            } `}
          >
            <ScoreCard
              ribonLabel={"SECTIONAL"}
              title="Your Score"
              iconSrc="../../../images/resultIcon-check.svg"
              consumedNumber={
                selectedSectionData?.marks % 1
                  ? selectedSectionData?.marks.toFixed(2) // fixed to two decimal after decimal point,if number is float
                  : selectedSectionData?.marks
              }
              totalNumber={
                selectedSectionData?.totalMarks -
                (selectedSectionData?.dtbQuesSecMarks || 0)
              }
            />
            {/* exclude rank in reattempt */}
            {!freeContentFlag && !mode && (
              <ScoreCard
                ribonLabel={"SECTIONAL"}
                title="Rank"
                iconSrc="../../../images/resultIcon-star.svg"
                consumedNumber={selectedSectionData?.rank}
                totalNumber={overall?.totalCandidates || 0}
                isRefresh={false}
              />
            )}
            <ScoreCard
              ribonLabel={"SECTIONAL"}
              title="Time spent"
              iconSrc="../../../images/resultIcon-timer.svg"
              consumedNumber={selectedSectionData?.timeSpent / 1000}
              totalNumber={selectedSectionData?.totalTime}
              isTimeScoreCard={true}
            />
          </div>
        </div>
        <div className="rowWidth4">
          <ScoreAnalysisPieChart
            ribonLabel="SECTIONAL"
            dataConfig={pieChartConfg}
          />
        </div>
      </section>
      <section className="rowWrap" style={{ marginTop: "0px" }}>
        <div
          className={`rowWidth8 ${freeContentFlag ? "freeContentWrap" : ""}`}
        >
          <QuestionDistribution
            correctCount={selectedSectionData?.correctAnswerCount}
            wrongCount={selectedSectionData?.incorrectAnswerCount}
            unattemptedCount={
              selectedSectionData?.totalQuestions -
              (selectedSectionData?.correctAnswerCount +
                selectedSectionData?.incorrectAnswerCount + (selectedSectionData?.nonEvaluatedAnswerCount ? selectedSectionData?.nonEvaluatedAnswerCount : 0))
            }
            unevaluatedCount={
              selectedSectionData?.nonEvaluatedAnswerCount
            }
            totalCount={totalQuestionExcludeDtb}
            isSectional={true}
            reAttemptCb={() => openTestPortal(mappingId,packageId,title,"COMPLETED","REATTEMPT",[exam])}
          />

          <TestRating
            rating={rating}
            setRating={setRating}
            isPPC={isPPC}
            moengageData={moengageData}
            flexBy={freeContentFlag ? "column" : ""}
            ratingAvailable={ratingAvailable}
          />
          <section
            className={`boxWhiteFull  ${
              !freeContentFlag ? "twopartpieNtable" : "fullpart"
            }`}
          >
            <TimeDistriButionPieChart data={timeDistribution} />
            {/* exlude ComparisonChart in reattempt.. */}
            {!freeContentFlag && (
              <ComparisonChart
                heading="Sectional Score Comparison"
                data={sectionalScoreComparisonData}
                legendPosition={"bottom"}
                scores={{
                  you: {
                    marks: selectedSectionData?.marks || 0,
                    total:
                      selectedSectionData?.totalMarks -
                      (selectedSectionData?.dtbQuesSecMarks || 0),
                  },
                  average: {
                    marks: selectedSectionData?.info?.averageScore || 0,
                    total: selectedSectionData?.totalMarks -
                    (selectedSectionData?.dtbQuesSecMarks || 0),
                  },
                  highest: {
                    marks: topperInfoData?.marks || 0,
                    total:
                      topperInfoData?.totalMarks ||
                      0 - topperInfoData?.dtbQuesSecMarks ||
                      0, //exclude dtbQuestions
                  },
                }}
              />
            )}
          </section>
        </div>

        {toppersList?.length && (
          <div className="rowWidth4">
            <LeaderBoard
              toppersList={toppersList}
              totalMarks={overall?.totalMarks}
              dtbQuestotalMarks={overall?.dtbQuestotalMarks || 0}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default SectionalAnalysis;

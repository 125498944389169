import React, { useEffect } from 'react';

const Tab =     ({tabItemList,onSelect,activeId,id})=>{
useEffect(()=>{
    let elem = document.querySelector(`#tabListcont-${id} button.active`);
    if(elem && elem.offsetLeft && elem.offsetLeft>0){
        document.querySelector(`#tabListcont-${id}`).scrollLeft = elem.offsetLeft;
    }
},[]);
 return (
     <div className='tab' id={`tabListcont-${id}`}>
     {tabItemList.map((item)=> {
         let label = "";
         if (activeId === item.id){
             let arr = item.label.split(")");
             label = arr[0] + " " + "modules" + ")" + arr[1];
         }else{
             label = item.label;
         }
         return <button className={activeId===item.id?'active':''} onClick={()=>onSelect(item.id)}>{label}</button>;
     })}
     {/* <button className='active'> Live Classes</button>
     <button>Mock Test</button>
     <button> Video Courses</button> */}
     </div>
 )
}
export default Tab;
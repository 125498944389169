import React from "react";

const FITB = ({ value = "", onChange, warningmessage = '' }) => {
  return (
    <div className="fill-in-blanks-input">
      <input
        type="text"
        placeholder={`Type your answer here`}
        value={value}
        onChange={onChange}
      />
      {warningmessage && (
        <p className="fill-in-blanks-input-warning" style={{ color: "red" }}>
          {warningmessage}
        </p>
      )}
    </div>
  );
};

export default FITB;

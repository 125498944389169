import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { wrapperWithoutExamList } from "../../../../constants/appConstants";
import { default as Link } from "../../../containers/ConnectedLink";
import {
  createOptimizedImgUrl,
  // getDeviceType,
  getMoengageMetadata,
  isADDA,
  removeSpacesAndInsertHyphens,
  scrollToElement,
  trackMoengageEvent,
} from "../../../data/utils/helpers";
import LazyIntersection from "../../LazyIntersection";
import ExamMenuCarousel from "./ExamMenuCarousel";
import ExamMenuShimmer from "./ExamMenuShimmer";
import { FallBackImage, pageName } from "../../../../constants/appConfig";

const getDeviceType = () => {
  if(typeof navigator !== "undefined"){
    const ua = navigator.userAgent;
    if (/(tablet|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "m";
    }
    if ( /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) && screen.width<=767 ) {
      return "s";
    }
    if(/iPad|iPod/.test(ua)){
      return "t";
    }
    if ( /Mobile|iPhone|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) && screen.width>767 ){
      return "m"
    }
    return "d";
  }
};
class ExamMenu extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    examData: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      /**
       * @togglerWrapper state is used only for desktop view.
       */
      togglerWrapper: {
        current: props?.globalExamList?.[0]?.id,
        visible: true,
      },
      current: isADDA ? "BANKING" : props?.globalExamList?.[0]?.examList?.[0]?.id,
      selected: isADDA ? "BANKING" : props?.globalExamList?.[0]?.examList?.[0]?.id,
      list:props.examData,
      globalList: props.globalExamList,
      searchData: {},
      searchText: "",
      innerHeight: "",
      changeExam: true,
      mblActive: false,
      viewAllText: isADDA ? "Banking and Insurance" : props?.globalExamList?.[0]?.examList?.[0]?.dpn,
      initialList: {},
      showLoader: false,
    };
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
    let existingState = this.state;
    this.setState({ ...existingState, changeExam: false });
    let slideElements = document.querySelectorAll(
      ".examm-menu-list.active .exam-for-inner.slideUp"
    );
    if (slideElements && slideElements.length > 0) {
      [].forEach.call(slideElements, function (el) {
        el.classList.remove("slideUp");
      });
    }
    }, 500);
    this.hashChangeListner = this.props.history.listen((location, action) => {
      if (action === "POP") {
        this.updateWrapperStateIfHash();
      }
    });

    setTimeout(() => {
      if (window.location.hash) this.scrollToExamMenuSection();
    }, 2000);

    this.updateWrapperStateIfHash();

    // removing last set value of ExamCategoryMappingId
    try {
      window.sessionStorage.removeItem("ExamCategoryMappingId");
    } catch (error) {
      console.error(error);
    }
    
  }
  componentDidUpdate(prevProps, prevState) {
    this.handleLoader();
    typeof window != undefined && this.state?.current && window.sessionStorage.setItem("ExamCategoryMappingId", this.state?.current);
  }

  componentWillUnmount() {
    this.hashChangeListner();
  }
  handleLoader = () => {
    this.setState({ showLoader: false });
  };

  scrollToExamMenuSection = () => {
    scrollToElement("exam_menu_section");
  };
  /**
   * @updateWrapperStateIfHash is used to update the state of togglerWrapper if the hash is present in the url.
   */
  updateWrapperStateIfHash = () => {
    let hash = window.location.hash;
    if (hash) {
      this.setState({
        current: location.hash.toUpperCase().replace("#", ""),
        togglerWrapper: {
          current: this.getActiveWrapperBasedOnHash(
            location.hash.toUpperCase().replace("#", "")
          ).activeParent,
          visible: true,
        },
        viewAllText: this.getActiveWrapperBasedOnHash(
          location.hash.toUpperCase().replace("#", "")
        ).activeChild,
      });
    }
  };
  /**
   * @getActiveWrapperBasedOnHash is used to get the active wrapper based on the hash.
   */
  getActiveWrapperBasedOnHash = (examTypeHash) => {
    let wrapper = {
      activeParent: "",
      activeChild: "",
    };
    this.props.globalExamList.forEach((exam) => {
      if (exam?.examList?.find((subExam) => subExam.id === examTypeHash)) {
        wrapper.activeParent = exam.id;
        wrapper.activeChild = exam?.examList?.find(
          (subExam) => subExam.id === examTypeHash
        ).dpn;
      } else if(exam.type === wrapperWithoutExamList && !exam.examList && exam.id == examTypeHash){
        wrapper.activeParent = exam.id;
        wrapper.activeChild = exam.dpn;
      }
    });
    return wrapper;
  };
  moengageTracker = (e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.exam_category = e.course || "";
    payload.examNameClicked = e.name || "";
    payload.source_screen = "Home page";
    payload.exam = e.name;
    payload.getUtmFromStore = this.props.getUtmFromStore;
    payload.language = "English";
    payload.user_exam_category_selected = "Web";
    payload.deep_link = "_blank";
    payload.package_url = "_blank";
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "browse_by_examName_clicked",
        getMoengageMetadata(payload)
      );
  };
  /**
   *
   * @param {*} wrapperToggler is function which perform toggling of each wrapper section.this function will only work for desktop view.
   */
  wrapperToggler = (wrapperName) => {
    this.setState({
      togglerWrapper: {
        current: wrapperName,
        visible:
          this.state.togglerWrapper.current === wrapperName
            ? !this.state.togglerWrapper.visible
            : true,
      },
    });
  };
  updateHashLabel = (category) => {
    window.location.hash = category;
  };
  handleClick = (mainCategory, parentWrapper) => {
    let key = mainCategory;
    /**
     * @param {*} ifItsParent is used to check if the clicked category is parent or child.
     */
    let ifItsParent = this.state.globalList?.find(
      (w) => {return w.id.toUpperCase() == key.toUpperCase()}
    );
    /**
     * @param {*} getFirstKey has contain filter data which has exams data in the this.state.list[key].
     */
    let getFirstKey = parentWrapper.examList?.filter(
      (item) => this.state.list[item.id]?.length
      )[0].id;
      /**
     * @param {*} hashLabel should be update based on multiple scenarios (like Mobile and web view).
     */
    let hashLabel = "";
    if (getDeviceType() === "d") {
      hashLabel = mainCategory;
    } else {
      hashLabel = ifItsParent ? getFirstKey : mainCategory;
    }
    /**
     * @param {*} this.updateHashLabel is used to update the hash label url so we can handle and persist the selected filtered on page refresh and going back to current page.
     */
    this.updateHashLabel(hashLabel);

    let slideUpElements = document.querySelectorAll(".exam-for-inner.slideUp");
    if (slideUpElements && slideUpElements.length > 0) {
      [].forEach.call(slideUpElements, function (el) {
        el.classList.remove("slideUp");
      });
    }
    if (ifItsParent) {
      let objWithItems = ifItsParent?.examList?.find(
        (x) => this.state.list[x.id].length > 0
      );
      if (objWithItems) {
        key = objWithItems?.id?.toUpperCase();
        this.setState({ viewAllText: objWithItems.dpn });
      } else if(ifItsParent?.dpn && ifItsParent.type === wrapperWithoutExamList) {
        key = ifItsParent?.id?.toUpperCase();
        this.setState({ viewAllText: ifItsParent.dpn });
      }
    } else {
      this.setState({ viewAllText: parentWrapper.dpn });
    }
    let payload = {};
    payload.exam_category_selected = key.toUpperCase();
    trackMoengageEvent("exam_category_tab_clicked", payload);
    this.setState({
      current:
        key.toUpperCase() || location.hash.toUpperCase().replace("#", ""),
      changeExam: false,
    });
  };


  viewAllProductsClicked = () => {
    let payload = {};
    trackMoengageEvent("view_all_exam_category_products", payload);
  };

  getActiveClass = (globalWrapper, currentKey) => {
    let wrapperItem = globalWrapper?.examList?.find((x) => x.id == currentKey);
    if (wrapperItem || globalWrapper.id == currentKey) {
      return true;
    } else {
      return false;
    }
  };

  getActionHandler = (globalItem) => {
    if (getDeviceType() === "m" || getDeviceType() == "s" || globalItem.type === wrapperWithoutExamList) {
      return this.handleClick(globalItem.id, globalItem);
    } else {
      return this.wrapperToggler(globalItem.id);
    }
  };
  getClassNameBasedonDevice = (globalItem) => {
    const { togglerWrapper, current } = this.state
    let activeClass = "";
    if (getDeviceType() == "m" || getDeviceType() == "s") {
      activeClass = `${this.getActiveClass(globalItem, current) ? "active" : "close"
        }`;
    } else {
      activeClass = `${togglerWrapper.current === globalItem.id && togglerWrapper.visible
          ? "visible"
          : "close"
        } ${this.getActiveClass(globalItem, current) ? "active" : ""}`;
    }
    return activeClass;
  };
  rippleClick = () => {
    document
      .querySelector(".examm-menu-list.active .exam-for-inner")
      .classList.toggle("slideUp");
    this.state.changeExam
      ? this.setState({ changeExam: false })
      : this.setState({ changeExam: true });
  };
  findExam = (e) => {
    this.setState({ searchText: e.target.value });
    if (!e.target.value || e.target.value == "") return this.clearSearch();
    var newExamData = JSON.parse(JSON.stringify(this.props.globalExamList));
    var subExamData = JSON.parse(JSON.stringify(this.props.examData));
    newExamData.map((examItem, examItemIndex) => {
      if (examItem && examItem.examList && examItem.examList.length > 0) {
        examItem.examList &&
          examItem.examList.map((subElement, subIndex) => {
            let filteredArray = subExamData[subElement.id] && subExamData[subElement.id].filter((x) =>
              x.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            if (filteredArray && filteredArray.length > 0) {
              subExamData[subElement.id] = filteredArray;
            } else {
              subExamData[subElement.id] = [];
            }
          });
        let filteredExams = examItem.examList.filter(
          (x) => subExamData[x.id].length > 0
        );
        if (filteredExams && filteredExams.length > 0) {
          examItem.examList = filteredExams;
        } else {
          examItem.examList = [];
        }
      }
    });
    let latestData = newExamData.filter((x) => x?.examList?.length > 0);
    this.setState({
      globalList: latestData,
      list: subExamData,
      current: latestData[0]?.examList[0]?.id,
      selected: latestData[0]?.examList[0]?.id || "",
      viewAllText: latestData[0]?.examList[0]?.dpn,
    });
  };
  clearSearch = () => {
    var latestGlobalExamList = JSON.parse(
      JSON.stringify(this.props.globalExamList)
    );
    var latestExamData = JSON.parse(JSON.stringify(this.props.examData));
    this.setState({
      list: latestExamData,
      globalList: Object.values(latestGlobalExamList),
      current: isADDA ? "BANKING" : this.props?.globalExamList?.[0]?.examList?.[0]?.id,
      searchText: "",
      viewAllText: isADDA ? "Banking and Insurance" : this.props?.globalExamList?.[0]?.examList?.[0]?.dpn,
    });
  };
  getExamLength = (examName) => {
    return this.state?.list[examName]?.length;
  };
  mblActiveClick = () => {
    this.state.mblActive
      ? this.setState({ mblActive: false })
      : this.setState({ mblActive: true });
    this.state.searchText && this.clearSearch();
  };
  render() {
    const { current, viewAllText, list, globalList } = this.state;
    let examDataList = list;

    return (
      <section
        className={
          this.props.isPaytmMini
            ? " margintop container-padding select-exam"
            : "container-padding select-exam"
        }
        id="exam_menu_section"
      >
        <div className="container">
          {/* <!--Exam- heading and sarchbar--> */}
          <div className="select-exam-heading-outer">
            <div className="common-headding">
              {this.props?.title} <span>{this.props?.subtitle}</span>
            </div>
            <div
              className={`mbl-search-icon search ${this.state.mblActive && "searchActive"
                }`}
              onClick={() => this.mblActiveClick()}
            ></div>
            <div
              className={`exam-filter-wraper ${this.state.mblActive && "mblActive"
                }`}
            >
              <input
                type="text"
                placeholder="Find your exam"
                value={this.state.searchText}
                // onKeyUp={(e) => this.findExam(e)}
                onChange={(e) => this.findExam(e)}
              />
              <div className="search-icon">
                <img src="/images/search-icon.svg" alt="search-icon" />
              </div>
              {this.state.searchText &&
                getDeviceType() !== "m" &&
                getDeviceType() !== "s" && (
                  <>
                    <div
                      className="crossicon-search"
                      onClick={() => this.clearSearch()}
                    >
                      <img src="/images/checkout-close.svg" at="" />
                    </div>
                  </>
                )}
            </div>
          </div>
          {/* Show Shimmer till no Data Available */}
          {this.state.showLoader ? (
            <ExamMenuShimmer loading={this.state.showLoader} />
          ) : (
            <>
              {/* <!--filtered Exam with left panel --> */}
              {globalList?.length > 0 && Object.keys(examDataList).length > 0? (
                <>
                  <div className="select-exam-body">
                    <div className="exam-menu-wrap">
                      {
                        globalList?.map((globalItem, globalIndex) => {
                          return (
                            <div
                              className={`examm-menu-list ${this.getClassNameBasedonDevice(
                                globalItem
                              )}`}
                              id={`${this.getActiveClass(globalItem, current)
                                  ? "activeWrapper"
                                  : "notActive"
                                }`}
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                              key={globalItem.id}
                            >
                              <div
                                className={`${globalItem.type === wrapperWithoutExamList && "remove-toggle"} exam-menu-btn`}
                                onClick={(e) => this.getActionHandler(globalItem)}
                              >
                                <div className="exam-menu-logo">
                                  <img
                                    src={globalItem.s3Url}
                                    style={{ width: "21px" }}
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="exam-name-wrap"
                                >
                                  {globalItem.dpn}
                                </div>
                              </div>
                              <div className="examOuter">
                                <div className="exam-for-inner">
                                  <ul>
                                    {globalItem &&
                                      globalItem.examList &&
                                      globalItem.examList.length > 0 &&
                                      globalItem.examList.map(
                                        (globalSubItem, index) => {
                                          let activeclass = "inactive";
                                          if (
                                            current?.toUpperCase() ==
                                            globalSubItem?.id?.toUpperCase()
                                          ) {
                                            activeclass = "active";
                                          }
                                          if (
                                            getDeviceType() == "m" ||
                                            getDeviceType() == "s"
                                          ) {
                                            if (!this.state.changeExam) {
                                              if (activeclass == "active") {
                                                if (
                                                  this.getExamLength(
                                                    globalSubItem.id.toUpperCase()
                                                  ) > 0
                                                ) {
                                                  return (
                                                    <li
                                                      onClick={(e) =>
                                                        this.handleClick(
                                                          globalSubItem.id,
                                                          globalSubItem
                                                        )
                                                      }
                                                      className={`active mobile_active `}
                                                      key={globalSubItem.id.toUpperCase()}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <span>
                                                        {globalSubItem.dpn.replace(
                                                          " STATE EXAMS",
                                                          ""
                                                        )}
                                                      </span>
                                                    </li>
                                                  );
                                                }
                                              }
                                            } else {
                                              if (
                                                this.getExamLength(
                                                  globalSubItem.id.toUpperCase()
                                                ) > 0
                                              ) {
                                                return (
                                                  <li
                                                    onClick={(e) =>
                                                      this.handleClick(
                                                        globalSubItem.id,
                                                        globalSubItem
                                                      )
                                                    }
                                                    className={`${activeclass}`}
                                                    key={globalSubItem.id.toUpperCase()}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <span>
                                                      {globalSubItem.dpn.replace(
                                                        " STATE EXAMS",
                                                        ""
                                                      )}
                                                    </span>
                                                  </li>
                                                );
                                              }
                                            }
                                          } else {
                                            if (
                                              this.getExamLength(
                                                globalSubItem.id.toUpperCase()
                                              ) > 0
                                            ) {
                                              return (
                                                <li
                                                  onClick={(e) =>
                                                    this.handleClick(
                                                      globalSubItem.id,
                                                      globalSubItem
                                                    )
                                                  }
                                                  className={`${activeclass}`}
                                                  key={globalSubItem.id.toUpperCase()}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <span>
                                                    {globalSubItem.dpn.replace(
                                                      " STATE EXAMS",
                                                      ""
                                                    )}
                                                  </span>
                                                </li>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                  </ul>
                                  {!this.state.changeExam && (
                                    <>
                                      <div
                                        className="mobile-drop-drown"
                                        onClick={() => this.rippleClick()}
                                      >
                                        Change your Exam{" "}
                                        <img src="/images/mbl-drop-arrow.svg" />
                                      </div>
                                    </>
                                  )}
                                </div>
                                {this.state.changeExam && (
                                  <>
                                    <div
                                      className="closeup-dropmenu"
                                      onClick={() => this.rippleClick()}
                                    >
                                      <img src="/images/white-up-menu.svg" />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {/* <!-- Right Side All filtered Exam--> */}
                    <div className="filtered-exam-list">
                      {
                        examDataList[current]?.length > 0 ? (
                          <div className="filtered-exam-wraper selectExam">
                            {/* <!--Exam repeat list--> */}
                            {
                              examDataList[current]?.length < 12 ? (
                                examDataList[current]?.map((e) => {
                                  return (
                                    <div
                                      className={"exam-name-box"}
                                      key={e.name}
                                      onClick={(e) => this.moengageTracker(e)}
                                    >
                                      <Link
                                        to={
                                          e.webUrlId ? `${e.webUrlId}-exam-kit` :
                                          (e.urlSlug
                                            ? e.urlSlug
                                            : `/${removeSpacesAndInsertHyphens(
                                              e.name
                                            ).toLowerCase()}-exam-kit`)
                                        }
                                        key={e.name}
                                      >
                                        <LazyIntersection
                                          modified={true}
                                          src={createOptimizedImgUrl(
                                            e.thumbnail,
                                            120,
                                            120
                                          )}
                                          className={"exam-img"}
                                          placeholder={isADDA ?
                                            "/images/placeholder-adda.svg"
                                            : "/images/img-placeholder-sankalp.png"
                                          }
                                          alt={e.name}
                                        ></LazyIntersection>
                                        <div className="exam-info">
                                          <div className="exam-name">{e.name}</div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })
                              ) : (
                                <div>
                                  <ExamMenuCarousel
                                    data={examDataList[current]}
                                    current={current}
                                  />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div className="noresult-found">
                            <div className="no-result-icon">
                              <img src="/images/no-result-icon.svg" />
                            </div>
                            <div className="no-rusult-heading">
                              No Results Found!
                            </div>
                            <div className="no-rusult-subheading">
                              Please check if you have the right spelling,
                              <br /> or try different keywords.
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="noresult-found">
                  <div className="no-result-icon">
                    <img src="/images/no-result-icon.svg" />
                  </div>
                  <div className="no-rusult-heading">No Results Found!</div>
                  <div className="no-rusult-subheading">
                    Please check if you have the right spelling,
                    <br /> or try different keywords.
                  </div>
                </div>
              )}
            </>
          )}

          {viewAllText && viewAllText !== "" && (
            <div className="bottom-filtered-exam">
              <div className="view-all-exams">
                <Link
                  to={
                    current?.toLowerCase() == "banking"
                      ? `/banking-study-materials`
                      : current?.toLowerCase() == "engineering"
                        ? `/engineering-study-materials`
                        : `/${current
                          ?.toLowerCase()
                          ?.split("_")?.join("-")}-study-materials`
                  }
                  data-attr={current.toLowerCase()}
                >
                  <span onClick={this.viewAllProductsClicked}>
                    view all {viewAllText.toLowerCase()} products
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}
export default withRouter(ExamMenu);
import React, { useState, useEffect } from "react";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import BreadCrumb from "../components/library/BreadCrumb";
import ConitnueReading from "../components/home/ContinueReading";
import NewMobileFilter from "../components/filter/NewMobileFilter";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isEmpty,
  getUrlPathObject,
  getDeviceType,
  makeUrl,
  scrollToTop,
  getMoengageMetadata,
  getMoengagePayloadFromFacets,
  fireMoengageEvent,
} from "../data/utils/helpers";
import CatalogCard from "../components/CatalogCard";
import { showIcons, dateMonthYear } from "../components/utils";
import {
  packageView,
  pageSizeForPackageViewListing,
  pageName
} from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import CustomLoader from "../components/library/CustomLoader";
import FilterTags from "../components/filter/FilterTags";
import CustomSkeleton from "../components/library/CustomSkeleton";
import BottomScrollListener from "../components/library/BottomScrollListener";
import { Cookies } from "react-cookie";
import { Redirect } from "react-router";
// import ContineInApp from "../../components/library/ContinueInApp/ContinueInApp";
import ContinueInApp from "../components/library/ContinueInApp/ContinueInApp";
import EmptyCart from "../components/cart/EmptyCart";
import { overlayVariant } from "../../constants/appConstants";
const cookie = new Cookies();
let pageN = 1;
let examObjgloabl = {};
const SubpackageView = (props) => {
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const data = [
    { type: "ebooks", count: 1 },
    { type: "olc", count: 100 },
    { type: "videos", count: 10 },
    { type: "test", count: 20 },
  ];
  const [childPackages, setChildpackages] = useState([]);
  const [examTypesFilterData, setexamTypesFilterData] = useState([]);
  const [showfilter, setshowfilter] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [examTypeData, setExamtypeddata] = useState([]);
  const [examObj, setexamObj] = useState({});
  const [facetList, setFacetList] = useState("");
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [examObjMobile, setexamObjMobile] = useState({});
  const [mainTitle, setMainTitle] = useState("");
  const [validUpto, setValidUpto] = useState("");
  const [toatalpackagecount, settoatalpackagecount] = useState(0);
  const [listingloader, setListingloading] = useState(false);
  const [facetObjValue, setfacetObj] = useState({});
  const [nodatafound, setnodatafound] = useState();
  const [tagObj, setTagObj] = useState({});
  const [tagObjM, setTagObjm] = useState({});

  let value = mainTitle;
  let valueUptodate = validUpto;

  useEffect(() => {
    setFilterObjectfromurl();
    scrollToTop(0);
  }, []);
  //  const getParameterByName=(name, url)=> {
  //   if (!url) url=typeof window !=undefined && window.location.href;
  //     name = name.replace(/[\[\]]/g, '\\$&');
  //     var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //         results = regex.exec(url);
  //     if (!results) return null;
  //     if (!results[2]) return '';
  //     return decodeURIComponent(results[2].replace(/\+/g, ' '));
  //   }
  const goTo = (url) => {
    props.history.push(url);
  };
  const setFilterObjectfromurl = () => {
    const { location } = props;

    // var url_string = "http://www.example.com/t.html?a=1&b=3&c=m2-m3-m4-m5"; //window.location.href
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("examTypesFacets");

    let filterObj = {};
    if (location.search.length) {
      const requiredString = location.search.substring(1);
      const paramArr = requiredString.split("&");
      for (let i = 0; i < paramArr.length; i++) {
        if (paramArr[i]) {
          const newArr = paramArr[i].split("=");
          if (decodeURI(newArr[1]).length) {
            filterObj[decodeURI(newArr[0])] = [decodeURI(newArr[1])];
          }
        }
      }
    }
    var array =
      filterObj &&
      filterObj.examTypesFacets &&
      filterObj.examTypesFacets[0] &&
      filterObj.examTypesFacets[0].split(",");
    var setfacets = {};
    setfacets.examTypesFacets = array;

    setfacetObj(setfacets);
    var examobjfilter = {};
    var filterdata =
      filterObj &&
      filterObj.examTypesFacets &&
      filterObj.examTypesFacets[0] &&
      filterObj.examTypesFacets[0];
    filterdata &&
      filterdata.length >= 1 &&
      filterdata.split(",").map((value) => (examobjfilter[value] = value));
    examObjgloabl = examobjfilter;
    var tagObjArray = [];
    var tagValue = {};

    Object.keys(examobjfilter).map((value) => {
      return tagObjArray.push(value.replace("%26", "&"));
    });
    tagObjArray.map((value) => {
      return (tagValue[value] = value);
    });
    setTagObj(tagValue);
    setTagObjm(tagValue);
    setexamObj(examobjfilter);
    setexamObjMobile(tagValue);
  };

  useEffect(() => {
    const { location } = props;
    if (location.search.length && !isEmpty(examObjgloabl)) {
      intilaData(examObjgloabl);
    } else {
      updateData();
    }
  }, [Object.keys(examObj).length]);

  const handleFilterSearch = (e) => {
    const searchvalue = e.target.value.trim();

    setExamtypeddata(getItem(examTypesFilterData, searchvalue));

    setSearchText(searchText);
  };

  const clearAllFilters = () => {
    const { pathname } = location;

    props.history.push(
      getUrlPathObject(
        `/my-purchase/${props.match.params.id}/${makeUrl(
          props.match.params.packageName
        )}`
      )
    );

    var examfilter = { ...examObj };
    var exammobile = { ...examObjMobile };
    var facetObj = { ...facetObjValue };
    var tagAobj = { ...tagObj };
    var tagm = { ...tagObjM };
    tagm = {};
    tagAobj = {};
    facetObj = {};
    exammobile = {};
    examfilter = {};
    setTagObjm(tagm);
    setTagObj(tagAobj);
    setexamObj(examfilter);
    setfacetObj(facetObj);
    setexamObjMobile(exammobile);
    fireMoengageEvent("filter_reset", {}, "filter");
  };

  const resetFilter = () => {
    clearAllFilters();
    setshowFilterModal(false);
    // this.setState({
    //   showFilterModal: false,
    //   currentActiveFilter: ""
    // });
  };

  const hideFilterOnMobile = () => {
    setshowFilterModal(false);
  };

  const toggleFilterItemsExams = () => {
    setshowfilter(!showfilter);
  };
  const handleFilterChange = (e) => {
    let value = e.target.value;
    let facetKey = "examTypesFacets";
    //   this.setState({
    //     dataLoader: true
    // });
    if (value.search("&") >= 0) value = value.replace("&", "%26");

    const facetObj = facetObjValue;
    const tagArr = Object.keys(tagObj);
    // this.setState({
    //     dataLoader: true
    // });
    const { location } = props;
    const { pathname } = location;

    let newFacetObj = Object.assign({}, facetObj);
    let newTagArr = tagArr;

    if (!isSet(newFacetObj[facetKey])) {
      newFacetObj[facetKey] = [];
    }
    if (newFacetObj[facetKey].includes(value)) {
      newFacetObj[facetKey] = newFacetObj[facetKey].filter(
        (e) => e.toLowerCase() !== value.toLowerCase()
      );
      newTagArr = newTagArr.filter(
        (e) => e.toLowerCase() !== value.toLowerCase()
      );
    } else {
      newFacetObj[facetKey].push(value);
      newTagArr.push(value);
    }
    let strNew = "";
    let p;
    for (p in newFacetObj) {
      if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
        strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
      }
    }
    strNew = strNew.substring(-1);

    // this.setState({
    //     queryParam: strNew,
    //     tagArr: newTagArr,
    //     facetObj: newFacetObj
    // }, () => {
    //     this.getPaginatedDataForDetailedPage(1, examName, true, this.state.sortBy);
    // });

    props.history.push(
      getUrlPathObject(
        `/my-purchase/${props.match.params.id}/${makeUrl(
          props.match.params.packageName
        )}`,
        `?${strNew.slice(0, -1)}`
      )
    );

    setfacetObj(newFacetObj);
    var examfilter = { ...examObj };
    var tagObjfilter = { ...tagObj };
    if (e.target.checked) {
      var moePayload = {};
      moePayload.action = "filter_Applied";
      moePayload.exam_Selected = e.target.value
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'Filter',
        getMoengageMetadata(moePayload),
      );
      examfilter[value] = value;
      tagObjfilter[e.target.value] = e.target.value;
    }
    else {
      delete examfilter[value];
      delete tagObjfilter[e.target.value];
    }
    setTagObj(tagObjfilter);
    setexamObj(examfilter);
  };
  const handleFilterChangeMobile = (e) => {
    var examfilter = { ...examObjMobile };
    var tagArrym = { ...tagObjM };

    if (e.target.checked) {
      examfilter[e.target.value.replace("&", "%26")] = e.target.value.replace(
        "&",
        "%26"
      );
      tagArrym[e.target.value] = e.target.value;
    } else {
      delete examfilter[e.target.value.replace("&", "%26")];
      delete tagArrym[e.target.value];
    }
    setTagObjm(tagArrym);
    setexamObjMobile(examfilter);
  };

  const updateData = () => {
    setnodatafound("");
    setLoading(true);
    fetch(
      `${packageView.packageView}?packageId=${props.match.params.id
      }&examTypes=${examObj && Object.values(examObj).join(",")
      }&pageNo=0&pageSize=${pageSizeForPackageViewListing}`,
      "GET"
    ).then((res) => {
      if (
        res.data &&
        res.data.childPackages &&
        res.data.childPackages.length >= 1
      ) {
        settoatalpackagecount(res.data.count);
        setLoading(false);
        setMainTitle(res.data.title);
        setValidUpto(res.data.expiry);
        setexamTypesFilterData(Object.keys(res.data.facetsList.examTypes));
        setExamtypeddata(Object.keys(res.data.facetsList.examTypes));
        setFacetList(res.data.facetsList);
        const childPackages = res.data.childPackages;
        setChildpackages(res.data.childPackages);
      } else {
        if (isEmpty(examObj)) setnodatafound("Empty Cart");
        if (!isEmpty(examObj)) setnodatafound("No data");
        setLoading(false);
      }
    });

    pageN = 1;
  };

  const intilaData = (examobjgloabl) => {
    setLoading(true);
    setnodatafound("");
    fetch(
      `${packageView.packageView}?packageId=${props.match.params.id
      }&examTypes=${examobjgloabl ? Object.values(examobjgloabl).join(",") : ""
      }&pageNo=0&pageSize=${pageSizeForPackageViewListing}`,
      "GET"
    ).then((res) => {
      if (
        res.data &&
        res.data.childPackages &&
        res.data.childPackages.length >= 1
      ) {
        settoatalpackagecount(res.data.count);
        setLoading(false);
        setMainTitle(res.data.title);
        setValidUpto(res.data.expiry);
        setexamTypesFilterData(Object.keys(res.data.facetsList.examTypes));
        setExamtypeddata(Object.keys(res.data.facetsList.examTypes));
        setFacetList(res.data.facetsList);
        const childPackages = res.data.childPackages;
        setChildpackages(res.data.childPackages);
      } else {
        if (isEmpty(examobjgloabl)) setnodatafound("Empty Cart");
        if (!isEmpty(examobjgloabl)) setnodatafound("No data");

        setLoading(false);
      }
    });
    examObjgloabl = {};
    pageN = 1;
  };

  const getPaginatedDataOnScroll = () => {
    const totalCount = toatalpackagecount;
    if (totalCount < pageN * pageSizeForPackageViewListing) {
      return;
    }
    // this.setState({
    //     listingLoader: true
    // });
    setListingloading(true);

    fetch(
      `${packageView.packageView}?packageId=${props.match.params.id
      }&examTypes=${Object.values(examObj).join(
        ","
      )}&pageNo=${pageN}&pageSize=${pageSizeForPackageViewListing}`,
      "GET"
    ).then((res) => {
      if (
        res.data &&
        res.data.childPackages &&
        res.data.childPackages.length >= 1
      ) {
        const childdata = [...childPackages];
        const packagedata = res.data.childPackages;
        childdata.push(...packagedata);
        setChildpackages(childdata);
      } else {
        setListingloading(false);
      }
    });

    pageN = pageN + 1;
  };

  const handleFilterApply = () => {
    var data = { ...examObjMobile };

    var facetObj = {};
    var facetArray = [];

    data &&
      Object.keys(data).map((val) => {
        return facetArray.push(val);
      });

    facetObj.examTypesFacets = facetArray;

    setfacetObj(facetObj);
    const { location } = props;
    const { pathname } = location;
    const courseCategory = pathname.substring(1).split("-")[0];
    let urlString = "&";
    const facetObjectKeys = Object.keys(facetObj);
    const facetKeysArrLen = facetObjectKeys.length;
    for (let i = 0; i < facetKeysArrLen; i++) {
      let facetValue = facetObj[facetObjectKeys[i]].join(",");
      urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
    }
    const encodedQueryString = encodeURI(urlString.slice(0, -1));
    fireMoengageEvent("filter_applied", getMoengagePayloadFromFacets(facetObj), "filter");
    props.history.push(
      getUrlPathObject(
        `/my-purchase/${props.match.params.id}/${makeUrl(
          props.match.params.packageName
        )}`,
        `?${encodedQueryString.substring(1)}`
      )
    );

    //   this.setState({
    //     dataLoader: false,
    //     showFilterModal: false,
    //     tagArr: this.createTagsFromUrl().tagArr,
    //     currentActiveFilter: ""
    //   });
    // });
    setshowFilterModal(false);
    setexamObj(examObjMobile);
    setTagObj(tagObjM);
    typeof window !== "undefined" && window.scrollTo(0, 0);
  };

  const isSet = (value) => {
    if (value === null || value === "" || value === undefined) {
      return false;
    }
    return true;
  };
  const removeTag = (item) => {
    //   this.setState({
    //     dataLoader: true
    // });
    const { location } = props;
    const { pathname } = location;
    // const examName = pathname.split('/')[1];
    // const productName = pathname.split('/')[2];

    //  const {  facetObj } = props;
    const tagArr = Object.values(tagObj);
    const facetObj = facetObjValue;
    // const courseName = pathname.split('/')[1];
    // const courseCategory = pathname.substring(1).split('-')[0];
    let newFacetObj = {};
    const tempTagArr = tagArr;
    // this.setState({
    //     dataLoader: true
    // });
    let item2 = item.replace("&", "%26");
    if (tempTagArr.indexOf(item) > -1) {
      tempTagArr.splice(tempTagArr.indexOf(item), 1);
      const facetKeys = Object.keys(facetObj);
      facetKeys.map((e) => {
        newFacetObj[e] = facetObj[e].filter((f) => {
          return f.toLowerCase() !== item2.toLowerCase();
        });
      });
    }

    let arr3 = [];
    let finalString = "";

    let splitArr = location.search.substring(1).split("&");
    splitArr.map((str) => {
      let newAr = str.split("=");
      const cat = newAr.shift();
      let finalAr = (newAr[0] || "").split(",");

      finalAr = finalAr.filter((val) => {
        let decodedUri = decodeURI(val).toLowerCase();
        return decodedUri !== item2.toLowerCase();
      });

      const string2 = finalAr.join(",");

      if (string2.length) {
        arr3.push(cat + "=" + string2);
      }
    });
    if (arr3.length > 1) {
      finalString = arr3.join("&");
    } else {
      if (isSet(arr3[0])) {
        finalString = arr3[0];
      }
    }

    // this.setState({
    //     queryParam: finalString,
    //     tagArr: tempTagArr,
    //     facetObj: newFacetObj

    // }, () => {
    //     this.getPaginatedDataForDetailedPage(1, examName, true, this.state.sortBy);
    // });
    // props.history.push(getUrlPathObject(`/my-purchase/${props.match.params.id}/${makeUrl(value)`, `?${finalString}`));
    props.history.push(
      getUrlPathObject(
        `/my-purchase/${props.match.params.id}/${makeUrl(
          props.match.params.packageName
        )}`,
        `?${finalString}`
      )
    );
    setfacetObj(newFacetObj);
    var tagObjfilter = { ...tagObj };
    var examfilter = { ...examObj };
    var examobjm = { ...examObjMobile };
    var tagObjM = { ...tagObjM };
    var item1 = item.replace("&", "%26");

    delete tagObjfilter[item];
    delete tagObjM[item];
    delete examfilter[item1];
    delete examobjm[item1];
    setTagObjm(tagObjM);
    setTagObj(tagObjfilter);
    setexamObj(examfilter);
    setexamObjMobile(examobjm);
  };

  const handleFilterButton = () => {
    setshowFilterModal(true);
    fireMoengageEvent("filter_button_clicked", {}, "filter");
  };

  function getItem(arrayItem, getItem) {
    return arrayItem.filter(
      (element) => element.toLowerCase().indexOf(getItem.toLowerCase()) > -1
    );
  }

  const handleIconData = (data) => {
    let iconData = [];
    for (var i = 0; i < data.length; i++) {
      iconData.push({ type: data[i].category, count: data[i].count });
    }
    return iconData;
  };
  const token = cookie.get("cp_token");
  if (!token) return <Redirect to="/#show-login" />;
  return (
    <div className="packageview">
      <ContinueInApp
        show={!!modalData}
        packageId={modalData && modalData.packageId}
        parentpackageId={modalData && modalData.parentPackageId}
        type={modalData && modalData.type}
        close={() => {
          setModalData();
        }}
      />
      {!facetList && loading ? (
        <div style={{ margin: "50px" }}>
          <CustomLoader />
        </div>
      ) : !loading && !facetList ? (
        <div className="empty-cont">
          <EmptyCart
            header={"You have not purchased any product yet."}
            subHeader={
              "Browse from our comprehensive range of products - Live Classes, Video Classes, Test Series and E-Books."
            }
            minHeight="100%"
            top="20px"
            exploreVisible={true}
          />
        </div>
      ) : (
        <div className="container-padding ">
          <BreadcrumbSchema
            items={[
              {
                title: "Home/My-Purchases",
                url: "/",
              },
              {
                title: value,
              },
            ]}
          />
          <div className={"breadcrumb-container"}>
            <BreadCrumb
              routeData={[
                { name: "Home", target: "/" },
                {
                  name: "My Purchases",
                  target: "/my-purchase",
                },
                {
                  name: value,
                  target: "",
                },
              ]}
            />
          </div>
          <div className={"header-container "}>
            <h1
              className={"header-title"}
              style={{ textTransform: "capitalize", padding: "0px" }}
              title={value}
            >
              {value}
            </h1>
            <span className="expiredDateText">
              {valueUptodate
                ? `Vaild upto : ${dateMonthYear(parseInt(valueUptodate))}`
                : null}
            </span>
          </div>

          <div>
            <ConitnueReading goTo={goTo} />
          </div>
          {
            <div className="d-flex container-padding margin-top">
              {facetList && !isEmpty(facetList.examTypes) && (
                <div
                  className={"search-block"}
                  style={{ maxWidth: "300px", minWidth: "300px" }}
                >
                  <div className={"filter-result"}>Filter Results</div>
                  <div className="bb">
                    <div
                      className={"exam-category"}
                      onClick={toggleFilterItemsExams}
                    >
                      <span>Exams</span>
                      <img
                        src={
                          showfilter
                            ? "/images/icon-md-top.svg"
                            : "/images/icon-md-bottom.svg"
                        }
                        className={"filter-arrow"}
                        alt={pageName[0]}
                        title={pageName[0]}
                      />
                    </div>
                    {showfilter && !isEmpty(examTypesFilterData) && (
                      <input
                        type="text"
                        placeholder="Search"
                        className={"filter-input"}
                        onChange={(e) => handleFilterSearch(e)}
                      />
                    )}

                    {showfilter && (
                      <div className={"exam-category-list"}>
                        {examTypeData &&
                          examTypeData.length >= 1 &&
                          examTypeData.map((item, i) => {
                            return (
                              <div
                                className={"filter-list md-checkbox"}
                                key={i + 1}
                              >
                                <input
                                  type={"checkbox"}
                                  onChange={(e) => handleFilterChange(e)}
                                  value={getSubstringAfterChar(item, "#")}
                                  checked={
                                    Object.keys(tagObj).length > 0 &&
                                    Object.keys(tagObj).includes(
                                      getSubstringAfterChar(item, "#")
                                    )
                                  }
                                  id={item}
                                ></input>
                                <label for={item}>
                                  {getSubstringBeforeChar(item, "#")}
                                </label>
                              </div>
                            );
                          })}
                        {examTypeData.length <= 0 && <div>No Items Found</div>}
                      </div>
                    )}
                    <div className={"filter-divider"} />
                  </div>
                </div>
              )}

              {loading ? (
                <CustomSkeleton />
              ) : (
                <div className="listing-container">
                  {Object.keys(tagObj).length > 0 && (
                    <div className={"tag-container"}>
                      <FilterTags
                        tagArr={Object.values(tagObj)}
                        removeTag={removeTag}
                      />
                      <div className={"clear-all"} onClick={clearAllFilters}>
                        Clear All
                      </div>
                    </div>
                  )}
                  <BottomScrollListener
                    offset={600}
                    onBottom={getPaginatedDataOnScroll}
                  >
                    {childPackages && childPackages.length >= 1
                      ? childPackages.map((value) => {
                        let onCardClick;
                        const COURSE_MAP = {
                          ONLINE_LIVE_CLASSES: "online-live-class",
                          TEST_SERIES: "test-series",
                          VIDEOS: "video",
                          EBOOKS: "e-book",
                        };

                        if (value.categories == "BOOKS") {
                          onCardClick = () => {
                            alert("Please check my order section for Book")
                          }
                        }
                        if (getDeviceType() == "m" && value.categories !== "BOOKS") {
                          onCardClick = () => {
                            setModalData({
                              packageId: value.id,
                              parentPackageId: props.match.params.id,
                              type: value.categories,
                            });
                          };
                        }
                        else if (value.categories !== "BOOKS") {
                          onCardClick = () => {
                            props.history.push(
                              getUrlPathObject(
                                `/my-purchase/${props.match.params.id
                                }/${makeUrl(
                                  props.match.params.packageName
                                )}/${value.id
                                }/${value.slugURL}`
                              )
                            );
                          };
                        }

                        return (
                          <div className="my_purchase_inner">
                            {value.newlyAdded && (
                              <span className="viewed-button">New</span>
                            )}
                            <CatalogCard
                              onSelect={onCardClick}
                              heightmain={"141px"}
                              fromVideo={false}
                              widthmain={"212px"}
                              imgcss={"imgcss"}
                              src={value.imgUrl}
                              header={value.title}
                              subHeader={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  className={"package-subheader"}
                                >
                                  {showIcons(handleIconData(value.subCategories), value.languages
                                  )}
                                </div>
                              }
                              text={""}
                              //   onSelect={()=>{selectPackage(productData.pkgId,productData)}}
                              variant={overlayVariant.overlayBg}
                            ></CatalogCard>
                          </div>
                        );
                      })
                      : null}
                  </BottomScrollListener>
                </div>
              )}
            </div>
          }

          {facetList && !isEmpty(facetList.examTypes) && <div className={"only-mobile"} onClick={handleFilterButton}>

            <div className={"filter-button"}>
              <img
                src={"/images/filter-bottom-icon-new.svg"}
                alt={pageName[0]}
                title={pageName[0]}
              />
              {Object.keys(examObjMobile).length > 0 ? (
                <span className={"dot-filter"}></span>
              ) : (
                <span></span>
              )}
              <span className={"filter-bottom-text"}>FILTERS</span>
            </div>
          </div>}
          {showFilterModal && (
            <NewMobileFilter
              visible={showFilterModal}
              onCancel={hideFilterOnMobile}
              // onOk={onOk}
              facetsList={facetList}
              handleApply={handleFilterApply}
              // facetsObject={examObj}
              resetFilter={resetFilter}
              type={2}
              selectedTags={Object.keys(tagObjM)}
              // facetObj={facetObjValue}
              // variant={'examDetail'}P::
              currentSelectedFilter={"examTypes"}
              handleFilterChange={handleFilterChangeMobile}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default SubpackageView;

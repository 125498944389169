import React, { useState } from "react";
import { getDeviceType, optimizedImgUrl } from "../../data/utils/helpers";
import { FallBackImage } from "../../../constants/appConfig";

const ExamCovered = ({ coveredExamTypes }) => {
  const [showAll, setShowAll] = useState(getDeviceType() !== "d");
  const examsToDisplay = showAll
    ? coveredExamTypes
    : coveredExamTypes?.slice(0, 9);

  const handleShowMore = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  return (
    <div className="examCovered">
      <div className="pdpCommanHeading">
        Exams <span>Covered</span>
      </div>
      <div className="examCoveredList">
          <ul className="examCoveredListRow">
            {examsToDisplay?.map((examData, index) => (
              <li key={index}>
                <div className="examCoverImg">
                  <img
                    src={optimizedImgUrl(examData?.thumbnail) || FallBackImage}
                    alt={examData?.tab}
                    height="48px"
                    width="48px"
                  />
                </div>
                <div className="ExamCoverName">{examData?.tab}</div>
              </li>
              
            ))}
            {(getDeviceType() === "d" || getDeviceType() === "") &&
            !showAll &&
            coveredExamTypes?.length > 10 ? (
              <div className="showMoreExams moreexams">
                <span onClick={(e) => handleShowMore(e)}>Show more</span>
              </div>
            ) : (
              getDeviceType() === "d" &&
              showAll && (
                <div className="showMoreExams lessexams">
                  <span onClick={(e) => handleShowMore(e)}>Show less</span>
                </div>
              )
            )}
          </ul>
      </div>
    </div>
  );
};
export default ExamCovered;

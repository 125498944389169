import React, { useState } from "react";
import { FallBackImage } from "../../constants/appConfig";
import { createOptimizedImgUrl } from "../data/utils/helpers";
import { overlayVariant } from "../../constants/appConstants";
import LiveTestToolTipIcon from "./library/LiveTestToolTipIcon";
import { toolTipMessage } from "./enrolledCourses/TestSeries/TestResultAnalysis/Components/liveTestUtils";
const CatalogCard = ({
  src,
  header,
  subHeader,
  text,
  onSelect,
  visible,
  variant,
  key,
  backFillImage,
  cardBody,
  onImgClick,
  isExpired,
  fromVideo,
  heightmain,
  widthmain,
  comp,
  optimizeWidth = 110,
  liveTest = false,
  setShowLiveTestModal,
  showHowItWorksCTA=true,
  testCategory = 2,
  showFullLengthLabel=true,
  liveTestCutoff=0,
  liveTestPackage=false,
}) => {
  const [imageSrc,setImageSrc] = useState(src)
  let style = {};
  let bgImgStyle = {};
  const showHowItWorks = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowLiveTestModal(true);
  };
  const overlayBgVariant = variant === overlayVariant.overlayBg;
  if (visible === false) style = { visibility: "hidden" };
  if (overlayBgVariant && imageSrc?.indexOf(".pdf") == -1) {
    bgImgStyle = {
      backgroundImage: `url(${createOptimizedImgUrl(imageSrc, optimizeWidth)})`,
      backgroundSize: "cover",
      height: heightmain || "110px",
      width: widthmain || "110px",
      backgroundPosition: "bottom"
    };
  } else if (overlayBgVariant && imageSrc?.indexOf(".pdf") != -1) {
    let source = FallBackImage;
    bgImgStyle = {
      backgroundImage: `url(${source})`,
      backgroundSize: "cover",
      height: heightmain || "110px",
      width: widthmain || "110px",
      backgroundPosition: "bottom"
    };
  }

  return (
    <div
      key={key}
      className={!comp ? "catalog-card" : ""}
      onClick={onSelect}
      style={style}
    >
      {imageSrc && imageSrc != "none" && imageSrc?.indexOf(".pdf") == -1 ? (
        <>
          {overlayBgVariant ? (
            <div className="catalog-card-overlay"></div>
          ) : null}
          <div className="purchaseImage product-img-paid">
          <div className="purchaseImageBg" style={{backgroundImage: `url(${
                imageSrc == "none"
                  ? undefined
                  : createOptimizedImgUrl(imageSrc, optimizeWidth) || backFillImage || FallBackImage
              })`}}></div>
          <div className="purchaseImageOverlay overlay-bg"></div>
          <img
            alt='overlay'
            title='overlay'
              src={
                imageSrc === "none"
                  ? undefined
                  : createOptimizedImgUrl(imageSrc, optimizeWidth) || backFillImage || FallBackImage
              }
            
              onClick={onImgClick}
              height={heightmain || "110px"}
              width={widthmain || "110px"}
              loading='lazy'
              onError={() =>{console.log(FallBackImage,'FallBackImage'); setImageSrc(FallBackImage)}}
            />
          
          </div>
        </>
      ) : imageSrc && imageSrc != "none" ? (
        <div className="catalog-card-media" style={bgImgStyle}>
          {overlayBgVariant ? (
            <div className="catalog-card-overlay"></div>
          ) : null}
          <div>
            <img
              src={FallBackImage}
              height={heightmain || "110px"}
              alt='img'
              title='img'
            ></img>
          </div>
        </div>
      ) : null}

      <div className={`catalog-card-content paid-content-wrap ${bgImgStyle.backgroundImage?'':'noimg'}`}>
      <div className="liveTestPaidWrap">
      {liveTestPackage && <div className="live-test-tag-wrap">
        {liveTest && testCategory === 2 && showFullLengthLabel ? <div className="live-test-category-tagWrap"><span className='live-test-category-tag'>Full Length</span></div> : ""}
        {liveTestCutoff > 0 && (
          <div className="livetest-cutoff-marks">
              <div className="livetest-cutoff-marks-wrap">
              Cut off : {liveTestCutoff}
              </div>
          </div>
          )}
        </div>}
        <div className={`liveTestTitleWrap ${liveTest ? "live-test-title-wrap" : ""}`}>
        {liveTest && !showHowItWorksCTA && <LiveTestToolTipIcon additionalClass={"live-test-icon-left"} tooltipMessage={testCategory === 2 ? toolTipMessage.fullLengthTooltip : toolTipMessage?.sectionalTooltip}/>}
        <div className={!fromVideo ? "coursename-paid" : "package-title-card"} title={header}>{header}</div>
        </div>
        
        {liveTest && showHowItWorksCTA && <LiveTestToolTipIcon liveTestText={showHowItWorksCTA ? "Live Test" : ""} tooltipMessage={testCategory === 2 ? toolTipMessage.fullLengthTooltip : toolTipMessage?.sectionalTooltip}/>}</div>
        <div className="catalog-card-subheader">{subHeader}</div>
        {/* <div className={isExpired ? "catalog-card-text red" : 'catalog-card-text'}>{text}</div> */}
      
        {liveTest && showHowItWorksCTA ? (
          <div className="how-it-works-wrapper" onClick={showHowItWorks}>
            How it works?
          </div>
        ) : ""}

<div className="pccCardBottomMobile">
        {liveTest && showHowItWorksCTA && <LiveTestToolTipIcon liveTestText={showHowItWorksCTA ? "Live Test" : ""} tooltipMessage={testCategory === 2 ? toolTipMessage.fullLengthTooltip : toolTipMessage?.sectionalTooltip}/>}
        {liveTest ? (
          <div className="how-it-works-wrapper" onClick={showHowItWorks}>
            How it works?
          </div>
        ) : ""}

        </div>

        <div className={isExpired ? "total-asset red" : 'total-asset'}>
          {text}
        </div>
      </div>
      {cardBody}
    </div>
  );
};

export default CatalogCard;

import React, { useState, useEffect } from "react";
import BreadcrumbSchema from "../../components/library/BreadcrumbSchema";
import BreadCrumb from "../../components/library/BreadCrumb";
import CustomLoader from "../../components/library/CustomLoader";
import {
  capitalizedString,
  getLastQIDFromURL,
  sanitizeQuestionData,
  extractInnerTextFromHtml,
  scrollToTop,
} from "../../data/utils/helpers";
import QuestionPageSchema from "./QuestionPageSchema";
import ErrorPage from "../../components/ErrorPage";
import fetch from "../../data/utils/fetch";
import CustomHelmet from "../../components/library/CustomHelmet";
import { TEST_SERIES_URL } from "../../../constants/appUrls";
import { useParams } from "react-router";
import QuestionOptions from "../../components/QuestionPage/QuestionOptions";
import { BrowseByProductType } from "../../components/home";
import GetLink from "../../components/GetLink";
import { dfpSlotsSubjectSeoPage } from "../../../constants/appConstants";
import DfpAdPdp from "../../components/library/DfpAdPdp";

const QuestionPage = () => {
  const { questionTitle } = useParams();
  const [questionData, setQuestionData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSolution, setShowSolution] = useState(true);
  const [showAd, setShowAd] = useState(true);

  useEffect(() => {
    scrollToTop()
    const quesId = getLastQIDFromURL(questionTitle);
    if (quesId) {
      getQuestionData(quesId);
    } else {
      setLoading(false);
      setErrorMsg("Some error occurred!");
    }
  }, []);

  const getQuestionData = async (quesId) => {
    setLoading(true);
    let url = `${TEST_SERIES_URL}/questions/view/question/${quesId}`;
    fetch(url, "GET")
      .then((res) => {
        if (res?.success && res?.data) {
          sanitizeQuestionData(res?.data).then((data) => {
            setQuestionData(data);
          });
        } else {
          setErrorMsg(res?.response);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Something Went Wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="sank-seo-wrap adda-main-wrap">
      {loading ? (
        <CustomLoader />
      ) : !errorMsg && questionData ? (
        <>
          {showAd && <div className="subject-page-dfp-banner">
              <div className="spDFPcontent">
              <DfpAdPdp dfpSlot={dfpSlotsSubjectSeoPage} />
              <div class="close_ad" onClick={() => setShowAd(prev => !prev)}>X</div>
              </div>
          </div>}
          <CustomHelmet
            title={`${
              extractInnerTextFromHtml(questionData?.question, 120) ||
              "Question"
            }`}
            description={
              extractInnerTextFromHtml(questionData?.solution, 170) || "Answer"
            }
            url={questionData.url}
          />
          <div className="sank-seo-container">
            <QuestionPageSchema data={questionData} />
            <div className="breadcrumb-section">
              <BreadcrumbSchema
                items={[
                  {
                    title: "Home",
                    url: "/",
                  },
                  {
                    title: capitalizedString(questionData?.subject),
                    url: `/${questionData?.subject?.split(" ")?.join("-")}/question`
                  },
                  {
                    title: questionData?.questionTitle?.split(" ")?.join("-"),
                    url: questionData?.url,
                  },
                ]}
              />
              <BreadCrumb
                routeData={[
                  { name: "Home", target: "/" },
                  {
                    name: `${capitalizedString(questionData?.subject)}`,
                    target: `/${questionData?.subject?.split(" ")?.join("-")}/question`
                  },
                  {
                    name: `${capitalizedString(questionData?.questionTitle)} ?`,
                    target: "",
                  },
                ]}
              />
            </div>
            <div className="sank-seo-qes-wrap">
              <div className="sank-seo-qes-heading">Question</div>
              <div className="sank-seo-phera">
                <h1
                  dangerouslySetInnerHTML={{ __html: questionData?.question }}
                />
              </div>

              {[1, 3].includes(questionData?.questionType) && (
                <QuestionOptions
                  options={questionData?.options}
                  correctOptions={questionData?.correct}
                  qType={questionData.questionType}
                  setShowSolution={setShowSolution}
                />
              )}
            </div>
            <div className="sank-seo-ans-wrap">
              <div className="sank-seo-ans-heading" onClick={() => setShowSolution(true)}>
                Solution
                {!showSolution && (
                  <div
                    className="sank-seo-ans-collapse"
                  />
                )}
              </div>
              {showSolution && (
                <div className="sank-seo-ans-phera">
                  {[1, 3].includes(questionData?.questionType) && (
                    <div>
                      The correct option is {questionData?.correctOption}
                    </div>
                  )}
                  {[2, 4, 5].includes(questionData.questionType) &&
                    questionData?.correct &&
                    questionData?.correct !== questionData?.solution && (
                      <div>The correct answer is {questionData?.correct}.</div>
                    )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionData?.solution,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <ErrorPage errorMsg={errorMsg} />
      )}
        <div className="sank-seo-container home-new">
          <BrowseByProductType />
          <GetLink pos='question_page' />
      </div>
    </section>
  );
};

export default QuestionPage;

import reducerRegistry from "../../reducerRegistry";
import { prodUrl } from "../../../../constants/appConfig";
import types from "./types";
import { Cookies } from "react-cookie";
const cookie = new Cookies();

import { makeTabs, removeDuplicateinObject } from "../../../components/utils";
import { isADDA } from "../../utils/helpers";

function header(
  state = {
    showLoader: false,
    loginFlag: false,
    search: {},
    searchResultData: {},
    myPurchaseSearchResultData: {
      packages:[],
      facetsList:{}
    },
    purchasePackageRecentSearches: [],
    myPurchaseList: [],
    purchasedCourses: [],
    recentlyAddedCourses: [],
    fetchedPurchasedCoursesData: [],
    examModes: {},
    packageExamsData: {
      examsData: {},
      manageExams: {},
    },
    packageData: {
      categoryTabs: [],
      continueStudyingData: [],
      comingSoonData: {},
      childData: [],
      examSubPackages: {},
      allcontinueStudyingData: [],
    },
  },
  action
) {
  switch (action.type) {
    case "INITIATE_LOGIN": {
      const loginData = action.payload;

      const { jwtToken, userInfo, jwtTokenNew } = loginData;

      // cookieCreate('cp-token', jwtToken);
      return {
        ...state,
        jwtToken,
        userInfo,
        jwtTokenNew
      };
    }

    case "INITIATE_SIGNUP": {
      const loginData = action.payload;
      const { jwtToken, userInfo, jwtTokenNew } = loginData;
      // cookieCreate('cp-token', jwtToken);

      let d = new Date();
      d.setTime(d.getTime() + 525600 * 60 * 1000);
      if(action.payload?.userInfo?.profilePicUrl){
        cookie.set('user_profile_pic', action.payload?.userInfo?.profilePicUrl, { path: '/', expires: d });
      }

      return {
        ...state,
        jwtToken,
        userInfo,
        jwtTokenNew
      };
    }

    case "UPDATE_USER_DETAILS": {
      
      const updatedFields = action.payload;

      const newUserInfo = Object.keys(updatedFields).reduce((userInfo, key) => {
        if (userInfo.hasOwnProperty(key)) {
          return {
            ...userInfo,
            [key]: updatedFields[key],
          };
        }
        return userInfo;
      }, { ...state.userInfo });

      return {
        ...state,
        userInfo: newUserInfo,
      };
    }

    case "GET_CART_COUNT_FROM_COOKIE": {
      const { cartCount } = action.payload;
      return {
        ...state,
        cartCount,
      };
    }

    case "GET_USER_CART": {
      const cartData = action.payload;
      const cartCount = cartData && cartData.data && cartData.data.carts.length;
      return {
        ...state,
        cartCount,
      };
    }

    case "RESET_PASSWORD": {
      const loginData = action.payload;
      const { jwtToken, userInfo } = loginData;
      // cookieCreate('cp-token', jwtToken);
      return {
        ...state,
        jwtToken,
        userInfo,
      };
    }

    case "SHOW_LOADER": {
      return {
        ...state,
        showLoader: true,
      };
    }
    case "LOGIN_FLAG": {
      return {
        ...state,
        loginFlag: action.payload,
      };
    }
    case "HIDE_LOADER": {
      return {
        ...state,
        showLoader: false,
      };
    }

    case "LOGOUT_USER": {
      const jwtToken = "";
      const userInfo = {};
      return {
        ...state,
        jwtToken,
        userInfo,
      };
    }

    case "FETCH_AUTO_SUGGESTION": {
      const searchResult = action.payload.data;
      const { searchKey, dropDownValue } = action.meta;

      let newObj = {};
      newObj[`${searchKey}-${dropDownValue}`] = searchResult;
      let copyState = Object.assign({}, state);
      copyState["search"] = {
        ...state.search,
      };

      copyState["search"] = Object.assign(copyState.search, newObj);
      return copyState;
    }
    case "CLEAR_SEARCH_SUGGESTIONS": {
      return {
        ...state,
        searchResultData: {},
      };
    }
    case "FETCH_SEARCH_SUGGESTIONS": {
      let searchResultData = action.payload.data;
      let { isKeywordChanged } = action.meta;
      if (state.searchResultData.primaryFacets && !isKeywordChanged) {
        searchResultData.primaryFacets = state.searchResultData.primaryFacets;
      }
      return {
        ...state,
        searchResultData,
      };
    }

    case "FETCH_EXAM_LIST": {
      const examList = action.payload.data || {};
      if (examList && examList["BANKING"]) {
        examList["BANKINGANDINSURANCE"] = examList["BANKING"];
        examList["BANKING"] = examList["BANKING"];
      }

      if (examList && examList["ENGINEERING EXAMS"]) {
        examList["ENGINEERINGEXAMS"] = examList["ENGINEERING EXAMS"];
        examList["ENGINEERING"] = examList["ENGINEERING EXAMS"];
      }
      examList["UG"] = [
        getOtherStateObject("CUET", "cuet", "/images/exam-icons/cucet.svg"),
        getOtherStateObject(
          "Law entrances",
          "law",
          "/images/exam-icons/law-entrance-exam.svg"
        ),
        getOtherStateObject(
          "IPM Entrance Exams",
          "ipm",
          "/images/exam-icons/ipm-entrance.svg"
        ),
        getOtherStateObject(
          "Other UG Entrance Exams",
          "otherugexams",
          "/images/exam-icons/other-ug-updated.svg"
        ),
        getOtherStateObject(
          "Hotel Management Exams",
          "hotelmanagement",
          "/images/exam-icons/hotel-management-updated.svg"
        ),
        getOtherStateObject(
          "Design Aptitude Test",
          "designaptitude",
          "/images/exam-icons/design-apti.svg"
        ),
        getOtherStateObject(
          "IIT/MEDICAL",
          "iitneet",
          "/images/exam-icons/cucet.svg"
        ),
      ];
      examList["ENGINEERINGCAT"] = [
        getOtherStateObject(
          "CIVIL ENGINEERING",
          "civil-engineering",
          "/images/exam-icons/civil-eng-updated.svg"
        ),
        getOtherStateObject(
          "MECHANICAL ENGINEERING",
          "mechanical-engineering",
          "/images/exam-icons/mech-eng-updated.svg"
        ),
        getOtherStateObject(
          "ELECTRICAL ENGINEERING",
          "electrical-engineering",
          "/images/exam-icons/elect-eng-updated.svg"
        ),
        getOtherStateObject(
          "ELECTRONIC ENGINEERING",
          "electronics-engineering",
          "/images/exam-icons/electronics-eng-updated.svg"
        ),
        getOtherStateObject(
          "COMPUTER SCIENCE ENGINEERING",
          "computer-science-engineering",
          "/images/exam-icons/cs-eng-updated.svg"
        ),
        getOtherStateObject(
          "INSTRUMENTATION ENGINEERING",
          "instrumentation-engineering",
          "/images/exam-icons/instru-eng-updated.svg"
        ),
        getOtherStateObject(
          "CHEMICAL ENGINEERING",
          "chemical-engineering",
          "/images/exam-icons/chem-eng-updated.svg"
        ),
      ];
      examList["OTHER"] = [
        getOtherStateObject(
          "WEST BENGAL STATE EXAMS",
          "west-bengal",
          "/images/exam-icons/wb.png"
        ),
        getOtherStateObject(
          "RAJASTHAN STATE EXAMS",
          "rajasthan",
          "/images/exam-icons/rajasthan.png"
        ),
        getOtherStateObject(
          "TAMILNADU STATE EXAMS",
          "tamil-nadu",
          "/images/exam-icons/tamil.png"
        ),
        getOtherStateObject(
          "MAHARASHTRA STATE EXAMS",
          "maharashtra",
          "/images/exam-icons/mh.png"
        ),
        getOtherStateObject(
          "HARYANA STATE EXAMS",
          "haryana",
          "/images/exam-icons/haryana.png"
        ),
        getOtherStateObject(
          "CHHATTISGARH STATE EXAMS",
          "chhattisgarh",
          "/images/exam-icons/chhatisgarh.png"
        ),
        getOtherStateObject(
          "UP STATE EXAMS",
          "uttar-pradesh",
          "/images/exam-icons/up.png"
        ),
        getOtherStateObject(
          "BIHAR STATE EXAMS",
          "bihar",
          "/images/exam-icons/bihar.png"
        ),
        getOtherStateObject(
          "MP STATE EXAMS",
          "madhya-pradesh",
          "/images/exam-icons/mp.png"
        ),
        getOtherStateObject(
          "AP & TELANGANA STATE EXAMS",
          "andhra-pradesh",
          "/images/exam-icons/ap.png"
        ),
        getOtherStateObject(
          "KERALA STATE EXAMS",
          "kerala",
          "/images/exam-icons/kerala.png"
        ),
        getOtherStateObject(
          "PUNJAB STATE EXAMS",
          "punjab-state-exams",
          "/images/exam-icons/ppsc.png"
        ),
        getOtherStateObject(
          "ODISHA STATE EXAMS",
          "odisha-state-exams",
          "/images/exam-icons/opsc.png"
        ),
        getOtherStateObject(
          "UTTARAKHAND STATE EXAMS",
          "uttarakhand",
          "/images/exam-icons/UKSC.svg"
        ),
        getOtherStateObject(
          "GUJARAT STATE EXAMS",
          "gujarat",
          "/images/exam-icons/guj-exam.svg"
        ),
        getOtherStateObject(
          "JHARKHAND STATE EXAMS",
          "jharkhand",
          "/images/exam-icons/jharkhand.png"
        ),
      ];
      return {
        ...state,
        examList,
      };
    }

    case "FETCH_GLOBAL_EXAM_LIST": {
      const globalExamList =
        action.payload.data.exl.filter((x) => x?.lni?.toUpperCase() == "ENGLISH") || {};
      return {
        ...state,
        globalExamList,
        origin: action.meta.origin
      };
    }
    case "FETCH_TRENDING_DATA": {
      const allTrendingData = action.payload;
      return {
        ...state,
        allTrendingData,
      };
    }

    case types.SAVE_PURCHASED_COURSES: {
      const purchasedCoursesData = [
        ...state.purchasedCourses,
        ...action.payload.coursesData,
      ];

      return {
        ...state,
        purchasedCourses: purchasedCoursesData,
      };
    }
    case types.SAVE_RECENTLY_ADDED_COURSES: {
      const recentlyAddCoursesData = [...action.payload.recentlyAddedCoursesData, ...state.recentlyAddedCourses];
      console.log('ADDA-247 - header - reducers.js - 201');

      return {
          ...state,
          recentlyAddedCourses:recentlyAddCoursesData
      };
  }
  case types.FETCHED_PURCHASED_COURSES_DATA: {
    const fetchedPurchasedCoursesData = [
      ...state.fetchedPurchasedCoursesData,
      ...action.payload.newPurchaseCourseDataPageNo,
    ];

    return {
      ...state,
      fetchedPurchasedCoursesData: fetchedPurchasedCoursesData
    }
  }

    case "FETCH_AUTO_SUGGESTION": {
      const searchResult = action.payload.data;
      const { searchKey, dropDownValue } = action.meta;

      let newObj = {};
      newObj[`${searchKey}-${dropDownValue}`] = searchResult;
      let copyState = Object.assign({}, state);
      copyState["search"] = {
        ...state.search,
      };

      copyState["search"] = Object.assign(copyState.search, newObj);
      return copyState;
    }
    case "CLEAR_SEARCH_SUGGESTIONS": {
      return {
        ...state,
        searchResultData: {},
      };
    }
    case "FETCH_SEARCH_SUGGESTIONS": {
      let searchResultData = action.payload.data;
      let { isKeywordChanged } = action.meta;
      if (state.searchResultData.primaryFacets && !isKeywordChanged) {
        searchResultData.primaryFacets = state.searchResultData.primaryFacets;
      }
      return {
        ...state,
        searchResultData,
      };
    }

    case "FETCH_EXAM_LIST": {
      const examList = action.payload.data || {};
      if (examList && examList["BANKING"]) {
        examList["BANKINGANDINSURANCE"] = examList["BANKING"];
        examList["BANKING"] = examList["BANKING"];
      }

      if (examList && examList["ENGINEERING EXAMS"]) {
        examList["ENGINEERINGEXAMS"] = examList["ENGINEERING EXAMS"];
        examList["ENGINEERING"] = examList["ENGINEERING EXAMS"];
      }
      examList["UG"] = [
        getOtherStateObject("CUET", "cuet", "/images/exam-icons/cucet.svg"),
        getOtherStateObject(
          "Law entrances",
          "law",
          "/images/exam-icons/law-entrance-exam.svg"
        ),
        getOtherStateObject(
          "IPM Entrance Exams",
          "ipm",
          "/images/exam-icons/ipm-entrance.svg"
        ),
        getOtherStateObject(
          "Other UG Entrance Exams",
          "otherugexams",
          "/images/exam-icons/other-ug-updated.svg"
        ),
        getOtherStateObject(
          "Hotel Management Exams",
          "hotelmanagement",
          "/images/exam-icons/hotel-management-updated.svg"
        ),
        getOtherStateObject(
          "Design Aptitude Test",
          "designaptitude",
          "/images/exam-icons/design-apti.svg"
        ),
        getOtherStateObject(
          "IIT/MEDICAL",
          "iitneet",
          "/images/exam-icons/cucet.svg"
        ),
      ];
      examList["ENGINEERINGCAT"] = [
        getOtherStateObject(
          "CIVIL ENGINEERING",
          "civil-engineering",
          "/images/exam-icons/civil-eng-updated.svg"
        ),
        getOtherStateObject(
          "MECHANICAL ENGINEERING",
          "mechanical-engineering",
          "/images/exam-icons/mech-eng-updated.svg"
        ),
        getOtherStateObject(
          "ELECTRICAL ENGINEERING",
          "electrical-engineering",
          "/images/exam-icons/elect-eng-updated.svg"
        ),
        getOtherStateObject(
          "ELECTRONIC ENGINEERING",
          "electronics-engineering",
          "/images/exam-icons/electronics-eng-updated.svg"
        ),
        getOtherStateObject(
          "COMPUTER SCIENCE ENGINEERING",
          "computer-science-engineering",
          "/images/exam-icons/cs-eng-updated.svg"
        ),
        getOtherStateObject(
          "INSTRUMENTATION ENGINEERING",
          "instrumentation-engineering",
          "/images/exam-icons/instru-eng-updated.svg"
        ),
        getOtherStateObject(
          "CHEMICAL ENGINEERING",
          "chemical-engineering",
          "/images/exam-icons/chem-eng-updated.svg"
        ),
      ];
      examList["OTHER"] = [
        getOtherStateObject(
          "WEST BENGAL STATE EXAMS",
          "west-bengal",
          "/images/exam-icons/wb.png"
        ),
        getOtherStateObject(
          "RAJASTHAN STATE EXAMS",
          "rajasthan",
          "/images/exam-icons/rajasthan.png"
        ),
        getOtherStateObject(
          "TAMILNADU STATE EXAMS",
          "tamil-nadu",
          "/images/exam-icons/tamil.png"
        ),
        getOtherStateObject(
          "MAHARASHTRA STATE EXAMS",
          "maharashtra",
          "/images/exam-icons/mh.png"
        ),
        getOtherStateObject(
          "HARYANA STATE EXAMS",
          "haryana",
          "/images/exam-icons/haryana.png"
        ),
        getOtherStateObject(
          "CHHATTISGARH STATE EXAMS",
          "chhattisgarh",
          "/images/exam-icons/chhatisgarh.png"
        ),
        getOtherStateObject(
          "UP STATE EXAMS",
          "uttar-pradesh",
          "/images/exam-icons/up.png"
        ),
        getOtherStateObject(
          "BIHAR STATE EXAMS",
          "bihar",
          "/images/exam-icons/bihar.png"
        ),
        getOtherStateObject(
          "MP STATE EXAMS",
          "madhya-pradesh",
          "/images/exam-icons/mp.png"
        ),
        getOtherStateObject(
          "AP & TELANGANA STATE EXAMS",
          "andhra-pradesh",
          "/images/exam-icons/ap.png"
        ),
        getOtherStateObject(
          "KERALA STATE EXAMS",
          "kerala",
          "/images/exam-icons/kerala.png"
        ),
        getOtherStateObject(
          "PUNJAB STATE EXAMS",
          "punjab-state-exams",
          "/images/exam-icons/ppsc.png"
        ),
        getOtherStateObject(
          "ODISHA STATE EXAMS",
          "odisha-state-exams",
          "/images/exam-icons/opsc.png"
        ),
        getOtherStateObject(
          "UTTARAKHAND STATE EXAMS",
          "uttarakhand",
          "/images/exam-icons/UKSC.svg"
        ),
        getOtherStateObject(
          "GUJARAT STATE EXAMS",
          "gujarat",
          "/images/exam-icons/guj-exam.svg"
        ),
        getOtherStateObject(
          "JHARKHAND STATE EXAMS",
          "jharkhand",
          "/images/exam-icons/jharkhand.png"
        ),
      ];
      return {
        ...state,
        examList,
      };
    }

    case "FETCH_GLOBAL_EXAM_LIST": {
      const globalExamList =
        action.payload.data.exl.filter((x) => x.lni == "ENGLISH") || {};
      return {
        ...state,
        globalExamList,
      };
    }

    case "FETCH_TRENDING_DATA": {
      const allTrendingData = action.payload;
      return {
        ...state,
        allTrendingData,
      };
    }
    case types.GET_MY_PURCHASE_LISTINGS:
      const myPurchaseList = action.payload.data;
      return {
        ...state,
        myPurchaseList,
      };
    case types.GET_NEXT_MY_PURCHASE_LISTINGS:
      const list = [...state.myPurchaseList, ...action.payload.data];
      return {
        ...state,
        myPurchaseList: list,
      };
    case types.GET_NEXT_CHILD_PACKAGE_DATA:
      const childPaginatedData = {
        packages: [
          ...state.packageData[action.meta.state].packages,
          ...action.payload.data.packages,
        ],
        packagesCount: action.payload.data.packagesCount,
        selectedCategory: action.meta.selectedCategory,
      };
      const afterChildUpdatedData = state.packageData;
      afterChildUpdatedData[action.meta.state] = childPaginatedData;
      return {
        ...state,
        packageData: afterChildUpdatedData,
      };
    case types.SET_EXAM_MODE:
      return {
        ...state,
        examModes: action.meta.availableExamModes,
      };
    case "SYNC_EXAM_MODES":
      return {
        ...state,
        examModes: action.meta.examModes || {},
      };

    case types.GET_PARENT_PACKAGE_EXAMS:
      const packageExamsData = {
        ...state.packageExamsData,
        examsData: {
          ...(action.payload.data || {}),
        },
      };
      return {
        ...state,
        packageExamsData,
      };
    case types.GET_PARENT_PACKAGE_MANAGE_EXAMS:
      const manageExams = action.payload.data;
      const afterAddingManageExams = {
        ...state.packageExamsData,
        manageExams,
      };
      return {
        ...state,
        packageExamsData: afterAddingManageExams,
      };

    case types.GET_PACKAGE_CATEGORIES:
      const categoryTabs = makeTabs(action.payload.data.categories);
      const afterTabsAdded = {
        ...state.packageData,
        categoryTabs,
      };
      return {
        ...state,
        packageData: afterTabsAdded,
      };

    case types.SET_FULL_PACKAGE_DATA:
      const packageData = {
        ...state.packageData,
        ...action.meta.packageData,
      };
      if (action.meta.category !== "ONLINE_LIVE_CLASSES") {
        delete packageData.comingSoonData;
      }
      return {
        ...state,
        packageData,
      };

    case types.GET_EXAM_SUBPACKAGE:
      const examSubPackages = action.payload.data || {};
      examSubPackages.selectedCategory = action.meta.selectedCategory;
      const newData = {
        ...state.packageData,
        examSubPackages,
      };
      return {
        ...state,
        packageData: newData,
      };
    case types.GET_PPC_CONTINUE_LISTING:
      let continueStudyingData = [
        ...state.packageData.continueStudyingData,
        ...(action.payload.data || []),
      ];
      const updatedPackageData = state.packageData;
      updatedPackageData["continueStudyingData"] = continueStudyingData;
      return {
        ...state,
        packageData: updatedPackageData,
      };

    case types.SET_INIT_PPC_ALL_CONTINUELISTINGS:
      let initialStudyingData = action.meta.allContinueListing || [];
      // now sort the result based on updated value
      initialStudyingData.sort((a, b) => b.updatedAt - a.updatedAt);
      const updatedPackageState = {
        ...state.packageData,
        allcontinueStudyingData: initialStudyingData,
      };
      return {
        ...state,
        packageData: { ...updatedPackageState },
      };

    case types.SET_PPC_ALL_CONTINUELISTINGS:
      let allcontinueStudyingData = [
        ...state.packageData.allcontinueStudyingData,
        ...action.meta.allContinueListing,
      ];
      const newPackageData = {
        ...state.packageData,
        allcontinueStudyingData,
      };
      return {
        ...state,
        packageData: newPackageData,
      };

    case types.CLEAN_PPC_PACKAGE_STATE:
      return {
        ...state,
        ...action.meta.initialPPCState,
      };
      case types.GET_PACKAGE_HOVER_DETAILS:
        let existingPackagesInfo = {
          ...state.hoveredPackageInfos
        };
        existingPackagesInfo[action.meta.packageId] = action.payload.data;
        return {
          ...state,
          hoveredPackageInfos: existingPackagesInfo
        };
        // paid search reducers
      case types.PURCHASED_PACKAGE_SEARCH: {
        let packages = action.payload.data?.packages || [];
        // spread the previous fetched packages when paginated call
          if(action.meta.isPaginated){
            packages = [
              ...state.myPurchaseSearchResultData.packages,
              ...action.payload.data?.packages || []
            ]
          }
        const myPurchaseSearchResultData = {
          ...state.myPurchaseSearchResultData,
          packages:packages,
        };
        return {
          ...state,
          myPurchaseSearchResultData,
        };
      }
      case types.SAVE_RECENT_SEARCH:
        return {
          ...state,
          purchasePackageRecentSearches: action.meta.recentSearches,
        };
      case types.PURCHASED_PACKAGE_FACETS: {
        const facetsList = action.payload.data?.facetsList || {};
        const myPurchaseSearchResultData = {
          ...state.myPurchaseSearchResultData,
          facetsList,
        };
        return {
          ...state,
          myPurchaseSearchResultData,
        };
      }
      case types.PURCHASED_POPULAR_COURSES: {
        let purchasedPopularCourses = action.payload.data;
        return {
          ...state,
          purchasedPopularCourses,
        };
      }
    default:
      return state;
  }
}

function getOtherStateObject(stateName, slug, logo) {
  return {
    id: 0,
    name: stateName,
    sequenceNo: 0,
    count: 0,
    thumbnail: logo
      ? `${prodUrl}${logo}`
      : `${prodUrl}/images/${isADDA ? 'placeholder-adda.svg': 'img-placeholder-sankalp.png'}`,
    tab: stateName,
    course: "OTHER",
    examType: true,
    other: true,
    urlSlug: "/" + slug + "-study-materials",
  };
}

reducerRegistry.register("header", header);
export default header;

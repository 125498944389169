import React, { useEffect, useState } from 'react';
import {createOptimizedImgUrl} from '../../data/utils/helpers';
import {default as Link}from '../../containers/ConnectedLink';

const MainListing = props => {
    let ebookList = props.ebookList;
    const [ebookListArr, setEbookListArr] = useState([]);
    const [notDataText, setNoDataText] = useState("You have not placed any orders yet");

    const filterList = (term, ebooks) => {
          const newList = ebooks.filter(book => book.id.toString() == term || book.title.toLowerCase().includes(term));
          if(newList.length) {
            setEbookListArr(newList);
            setNoDataText("You have not placed any orders yet");
        }
          else {
            setNoDataText(`No results found for ${term}`);
            setEbookListArr([]);
        }
      }

    useEffect(() => {
        if(props.searchTerm) filterList(props.searchTerm, ebookList);
        else setEbookListArr(ebookList);
    },[props.searchTerm])

    useEffect(() => {
        setEbookListArr(ebookList);
    },[props.noSearchTerm])
 
    if(ebookListArr && ebookListArr.length<=0 || !ebookListArr){
        return (
                <div className='empty-cart search-bar-no-result' >
                    <div className="ec-image">
                        <img src="/images/no-result-found.png" width="300" />
                        <div className="ec-header" >{notDataText} </div>
                    </div>
                    <Link to='/'>
                        <div style={{ padding: "8px" }}>
                            <button className='sf-button-secondary'> Go to Home</button>
                        </div>
                    </Link>
                </div>

        )
    }


    const currentTimeStamp = Date.now();

    return (
        <section>
            <article className={'list-container'}>
                <div className={'package-list'}>
                    { !ebookListArr ? <div>Loading...</div> :
                       ebookListArr && ebookListArr.map((item,i)=>{
                           
                           const d = new Date (item && item.expiryDate);
                           const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
                           const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
                           const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

                           let expiryDate = `${da} ${mo} ${ye}`;

                           return (
                            <div className={'package-list-item'} style={{marginBottom:'12px'}} key={i} onClick={() => props.getEbooksList(item && item.id,item)} data-expiry={item.expiryDate}>
                                <div className={'package-list-img-div'}>
                                    <img className={'package-img'} 
                                        src= {
                                            item && item.imageUrl && createOptimizedImgUrl(item.imageUrl,100)
                                        } 
                                        alt={`package-Thumbnail-${i}`}
                                    />
                                </div>  
                                <div className={'package-title'}>
                                {item && item.title}
                                <div className={`validity-container my-test-ellipses ${item.expiryDate && currentTimeStamp>item.expiryDate?'expired':''}`}>
                                    <>{item.expiryDate && currentTimeStamp>item.expiryDate?'Expired ':'Expires '}</>
                                     on {expiryDate}
                                </div>
                                <div className={'package-list-item-view-btn'}>
                                    <span className={'view-class-btn'} style={{'margin':'0'}}>VIEW EBOOKS</span>
                                </div>
                                </div> 
                                <div style={{'clear':'both'}}></div>       
                          </div>
                           )
                       })
                    }
                  
                </div>

            </article>
        
        </section>
    )
}

export default MainListing
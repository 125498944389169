import React from "react";
import { getFormattedDate } from "../../data/utils/helpers";
const PackageInfo = ({
    examCovered,
    olcMetaJson,
    tags,
    studentsEnrolled,
    examDateTime
  }) => {
    let olcMetaJsonData = olcMetaJson ? olcMetaJson : {};
  
    return (
      <>
        <div className="tagRating">
          {tags?.length > 0 && <div className="popularTag">{tags[tags.length -1]?.name}</div>}
          { studentsEnrolled > 200 && (
            <div className="popularTag entrolltag">
              {studentsEnrolled} enrolled
            </div>
          )}
          {examCovered > 1 ? (
            <div className="examcovered"> Exams Covered: {examCovered}</div>
          ) : null}
            {examDateTime && <div className="examcovered"> Exams Date: {examDateTime}</div>}
          {/* this will use later once rating will be added from backend */}
          {/* <div className="rating-pdp"> */}
          {/* {[...Array(5)].map((_, index) => (
              <img key={index} src="/images/pdpStar.svg" />
            ))} */}
        </div>
        {Object.keys(olcMetaJsonData)?.length ? (
          <div className="pdpKeys mt-4">
            <ul>
              {olcMetaJsonData && olcMetaJsonData["start"]?.length > 0 && (
              <li data-testId="starts">
                <img src="/images/pdpStartEvent.svg" alt="date icon"/> Starts:{" "}
                <span>
                  {olcMetaJsonData && olcMetaJsonData["start"]
                    ? getFormattedDate(olcMetaJsonData["start"])
                    : null}
                </span>
              </li>
              )}
              {olcMetaJsonData && olcMetaJsonData["seats"] && (
              <li data-testId="seats">
                <img src="/images/pdpSeats.svg" alt="seat icon"/> Seats:{" "}
                <span>
                  {olcMetaJsonData && olcMetaJsonData["seats"]
                    ? olcMetaJsonData["seats"]
                    : ""}
                </span>
              </li>
              )}
              {olcMetaJsonData && olcMetaJsonData["class"]?.length > 0 && (
              <li className="timerFull">
                <i><img src="/images/pdpTimer.svg" alt="timing"/> Timings:{" "}</i>
                <span>
                  {" "}
                  {olcMetaJsonData && olcMetaJsonData["class"]
                    ? olcMetaJsonData["class"]
                    : ""}
                </span>
              </li>
              )}
            </ul>
          </div>
        ) : null}
      </>
    );
  };
export default PackageInfo;
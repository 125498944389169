import React from "react";
import { getMoengageMetadata, checkLoginStatus } from "../data/utils/helpers";
import FreeContentCatalogCard from "./FreeContentCatalogCard";
import FreeContentTab from "./FreeContentTab";
import { pageName } from "../../constants/appConfig";
import { loginFlag } from "../data/ducks/header/actions";
import { useDispatch } from "react-redux";
import { openTestPortal } from "./enrolledCourses/TestSeries/TakeTest/util";

const FreeContentTestModal = ({ id, TabList, onSelect, productData,closeCallback}) => {

  let data = productData.freeContentData?.filter(item => item.contentType == 1)
  let cardBtn = "Attempt"
  let dispatch = useDispatch()
  
  const onClick = (data) => {
    let loginFlag1 = checkLoginStatus();
    const {contentId,contentTitle,subjectTag, examTag,contentType } = data;
    let payload = { source: "web", brand: pageName[1] };
    let d1 = productData
    payload.subPackageTitle = d1.title;
    payload.name = d1.title;
    payload.category = d1.suggestionCat;
    payload.primary_category = d1?.primaryCategory;
    payload.id = d1.id;
    payload.packageActualPrice = d1.maxPrice;
    payload.packageDiscountedPrice = d1.price;
    payload.exam_category = d1.courses;
    payload.exam =
      d1.examTypes && d1.examTypes[0] ? d1.examTypes[0].name || "" : "";
    // payload.getUtmFromStore = props.utmParams || "";
    payload.package_status = "Sample";
    payload.language =
      d1.languages && d1.languages[0] && d1.languages[0].name;
    // payload.Repurchase_btn_clicked_flag =
    //   typeof window !== "undefined" &&
    //     window.localStorage.getItem("mypuchaseId") == props.match.params.id
    //     ? 1
    //     : 0;
    payload.original_package_id =
      typeof window !== "undefined" &&
      sessionStorage.getItem("original_package_id");
    payload.mahapack_widget_clicked =
      typeof window !== "undefined" &&
      sessionStorage.getItem("mahapack_widget_clicked");
    payload.productType = contentType == 1 ? 'Test' : 'ebooks';
    payload.mapping_id = contentId;
    payload.productTitle = contentTitle;
    payload.content_exam = examTag;
    payload.content_subject = subjectTag;
    payload.source_screen = "content_listing"

    // Checking Login Status
    if(!loginFlag1){
      localStorage.setItem("DataForMoengage", JSON.stringify(payload));
      localStorage.setItem("TestModalData",JSON.stringify({"contentId":contentId,"contentTitle":contentTitle,"productId":productData?.id}));
      closeCallback();
      dispatch(loginFlag(true));
      return;
    } 
    // return alert('Not Login')
    else{
    
    // const {contentId,contentTitle,subjectTag, examTag,contentType } = data;
    
    openTestPortal(contentId,productData?.id, contentTitle, "new");

  //MOENGAGE EVENT
  if (typeof Moengage !== "undefined")
    if (typeof Moengage !== "undefined")
      Moengage.track_event("sample_content_clicked", getMoengageMetadata(payload));

  }
}

  return (
    <>
      <FreeContentTab tabItemList={TabList} activeId={id} onSelect={onSelect}  />
      {data?.map((item) => {
        return <> <FreeContentCatalogCard item={item} onClick = {onClick} productData={productData} cardBtn={cardBtn}/></>;
      })}
    </>
  );
};
export default FreeContentTestModal;

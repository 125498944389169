import React, { useState, useEffect, useRef } from "react";
import CourseCard from "./CourseCard";
import AButton from "../library/AButton";
import CustomLoader from "../library/CustomLoader";
import NoResultsFound from "../library/NoResultsFound";
import { useOpen3DModel, timeStampdateMonthYearebooks } from "../utils";
import { getMoengageMetadata } from "../../data/utils/helpers";
const Models3DSection = ({
  data,
  getData,
  searchText,
  packageId,
  setinternalTab,
  setSearchText,
  setInputText,
  setsuggestionData,
  loading,
  setGroupFilter,
  moengageEventSectionTabChange,
  moengageEventforstatus,
  packageData
}) => {
  const modelOpened = useRef();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [ddFilter, setDdFilter] = useState("");
  const [ddOptions, setDdOptions] = useState();
  const [open3DModel] = useOpen3DModel();
  useEffect(() => {
    if (!data) {
      getData();
    }
    document.addEventListener("visibilitychange", getDataVisible);

    return () => {
      document.removeEventListener('visibilitychange', getDataVisible);
    }
  }, []);
  useEffect(() => {
    if (data && data.length) {
      let dataObj = {};
      data.forEach((item) => {
        if (!dataObj[item.groupName]) {
          dataObj[item.groupName] = {
            id: item.groupName,
            label: item.groupName,
          };
        }
      });
      setDdOptions([{ id: "", label: "All" }, ...Object.values(dataObj)]);
    }
  }, [data && data.length]);
  if (!data) return <CustomLoader />;

  function getDataVisible() {
    if (document.visibilityState === "visible" && modelOpened.current) {
      getData();
    }
  }

  const openModel = (item) => {
    var payload = {};
    payload.name =
      packageData?.recentParentInfo
        ? packageData?.recentParentInfo?.title
        : packageData.title;
    payload.category =
      packageData?.recentParentInfo
        ? packageData?.recentParentInfo?.category
        : "MODELS_3D";
    payload.id =
      packageData?.recentParentInfo
        ? packageData?.recentParentInfo?.id
        : packageData?.id;
    payload.subPackageId = packageData.id;
    payload.subPackageType = "MODELS_3D";
    payload.contentTitle = item?.title;
    payload.contentID = item?.id;
    payload.action = "3d_models_opened";
    if (typeof Moengage !== "undefined")
      Moengage.track_event("MODELS_3D", getMoengageMetadata(payload));

    open3DModel(packageId, item?.webLink, item?.title, payload);
  };

  const Filters = [
    { id: "", label: "All 3D Learning" },
    // { id: "Incomplete", label: "Incomplete" },
    // { id: "Unread", label: "Unread" },
    // { id: "Complete", label: "Read" },
  ];
  let displayData = data?.filter((item) => {
    return (
      !selectedFilter ||
      item.status == selectedFilter ||
      (selectedFilter == "Unread" && item.status == "Coming Soon")
    );
  });

  if (ddFilter) {
    displayData = displayData?.filter((item) => {
      return ddFilter === item.groupName;
    });
  }
  if (searchText) {
    displayData = displayData?.filter((item) => {
      return item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }
  return (
    <div className="course-content">
      <div className="course-content-filters">
        <div>
          {Filters?.map((filter, idx) => {
            return (
              <AButton
                key={`model_fltr_${idx}`}
                text={filter.label}
                onClick={() => {
                  moengageEventSectionTabChange(filter.id);
                  setSelectedFilter(filter.id);
                  setinternalTab(filter.id);
                  setSearchText("");
                  setInputText("");
                  setsuggestionData("");
                }}
                contained
                color={filter?.id === selectedFilter ? undefined : "grey"}
              />
            );
          })}
        </div>
        <div className="cc-filtet-select">
          <div>
            {ddOptions && (
              <select
                // id="test-status"
                className="test-filter-select"
                value={ddFilter}
                onChange={(e) => {
                  setDdFilter(e.target.value);
                  setGroupFilter(e.target.value);
                }}
              >
                {ddOptions?.map((values, idx) => {
                  return <option key={`opt__${idx}`} value={values?.id}>{values?.label}</option>;
                })}
              </select>
            )}
          </div>
        </div>
      </div>
      {displayData?.length ? (
        <div className="course-items">
          {displayData?.map((model, idx) => {
            let actionText = "START";
            if (model.comingSoon) {
              actionText = "COMING SOON";
            }

            return (
              <CourseCard
                key={`model__${idx}`}
                isBook={true}
                showIcon={true}
                // info={
                //   model?.comingSoon && model?.comingSoonDate
                //     ? timeStampdateMonthYearebooks(model?.comingSoonDate)
                //     : ""
                // }
                title={model?.title}
                imgSrc={model?.thumbnail}
                actionButton={
                  <AButton
                    text={actionText}
                    loading={loading}
                    onClick={
                      actionText === "COMING SOON"
                        ? null
                        : () => {
                          modelOpened.current = true;
                          moengageEventforstatus(actionText)
                          openModel(model)
                        }
                    }
                  ></AButton>
                }
              />
            );
          })}
        </div>
      ) : (
        <div className="course-items">
          <NoResultsFound />
        </div>
      )}
    </div>
  );
};

export default Models3DSection;

import React from "react";
import Chart from "react-google-charts";

const ComparisonChart = ({
  heading = "",
  data = [],
  scores = {},
  flexByRow = false,
}) => {
  return (
    <div className="scrore-comp">
      <div className="qdisheading">
        <label>{heading}</label>
      </div>

      <div className={`${flexByRow ? "scoretableRow" : ""}`}>
        <div className="ruller">
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              fontSize: "13",
              isStacked: "true",
              fill: "10",
              displayExactValues: "true",
              bar: {
                groupWidth: "40",
              },

              xAxis: {
                title: "Scores",
                gridlines: {
                  count: "6",
                },
                textStyle: {
                  fontSize: "12",
                  color: "#515151",
                },
              },
              selectionMode: "multiple",
              tooltip: {
                text: "value",
                showColorCode: true,
                shared: true,
              },
              legend: "none",

              hAxis: {
                textStyle: {
                  fontSize: "12",
                  color: "#515151",
                  paddingRight: "100",
                  marginRight: "100",
                },
              },
              colors: ["#54B1AB", "#FF647C", "#FFCF5C"],
            }}
          />
          <div className="ruller-scores">
            <div className="ruller-name">Scores</div>
            <div className="rullerbox">
              <div className="totalrullerscore">
                <span>{scores?.you?.marks}</span> / {scores?.you?.total}
              </div>
            </div>
            <div className="rullerbox average">
              <div className="totalrullerscore">
                <span>{scores?.average?.marks}</span> /{scores?.average?.total}
              </div>
            </div>
            <div className="rullerbox">
              <div className="totalrullerscore">
                <span>{scores?.highest?.marks}</span> /{scores?.highest?.total}
              </div>
            </div>
          </div>
        </div>
        <div className="rullerscore-wrap">
          <div className="colors-for-ruller">
            <div className="colorbox correct-color">Correct</div>
            <div className="colorbox incorrect-color">Incorrect</div>
            <div className="colorbox unanswered-color">Unanswered</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonChart;

// wishlist TYPES
const SET_WISHLISTED_PACKAGE = "SET_WISHLISTED_PACKAGE";
const ADD_REMOVE_WISHLIST = "ADD_REMOVE_WISHLIST";
const FETCH_WISHLISTED_PACKAGE_DETAIL = "FETCH_WISHLISTED_PACKAGE_DETAIL";
const SET_SELECTED_WISHLIST_PACKAGE_IDS = "SET_SELECTED_WISHLIST_PACKAGE_IDS";
const DELETE_BULK_WISHLIST = "DELETE_BULK_WISHLIST";
const SHOW_FILTER_POPUP = "SHOW_FILTER_POPUP";
const SET_USER_FIRST_TIME_WISH = "SET_USER_FIRST_TIME_WISH";
const SET_SELECTED_FACETS = "SET_SELECTED_FACETS";
const SHOW_CONFIRM_DELETE_WISHLIST = "SHOW_CONFIRM_DELETE_WISHLIST";
const SET_LAST_CLICKED_WISH_ITEM_INFO = "SET_LAST_CLICKED_WISH_ITEM_INFO";
const SET_SELECTED_FACETS_FILTER = "SET_SELECTED_FACETS_FILTER";

export default {
  SET_WISHLISTED_PACKAGE,
  ADD_REMOVE_WISHLIST,
  FETCH_WISHLISTED_PACKAGE_DETAIL,
  SET_SELECTED_WISHLIST_PACKAGE_IDS,
  DELETE_BULK_WISHLIST,
  SHOW_FILTER_POPUP,
  SET_USER_FIRST_TIME_WISH,
  SET_SELECTED_FACETS,
  SHOW_CONFIRM_DELETE_WISHLIST,
  SET_LAST_CLICKED_WISH_ITEM_INFO,
  SET_SELECTED_FACETS_FILTER,
};

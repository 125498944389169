import React, { useState, useEffect } from "react";
import CourseCard from "./CourseCard";
import AButton from "../library/AButton";
import AProgressBar from "../library/AProgressBar";
import AExpansionPanel from "../library/AExpansionPanel";
import CustomLoader from "../library/CustomLoader";
import NoResultsFound from "../library/NoResultsFound";
const AllVideosSection = ({
  data,
  goTo,
  packageId,
  searchText,
  moengageEventforstatus,
}) => {
  if (!data?.subject?.length)
    return (
      <div className="course-content">
        <NoResultsFound
          image={"/images/coming_soon.svg"}
          message={"Sorry to keep you waiting..."}
          message2={
            "We are in the process of setting up the content for you. Please check again after some time"
          }
        />
      </div>
    );
  return (
    <div className="course-items course-items-video">
      {data?.subject?.map((subject, index) => {
        let chapterSectionInfo = ChapterSection(
          subject.ch,
          searchText,
          goTo,
          packageId,
          index,
          subject.name,
          moengageEventforstatus
        );
        if (!chapterSectionInfo) return chapterSectionInfo;
        return (
          <AExpansionPanel
            isSubject={true}
            open={!!searchText}
            title={subject.name}
            cCount={subject.cCount}
            content={chapterSectionInfo}
          />
        );
      })}
    </div>
  );
};

const FilteredVideoSection = ({
  appliedFilter,
  data,
  goTo,
  searchText,
  packageId,
  moengageEventforstatus,
}) => {
  let filteredData = [];

 
    data?.subject?.forEach((sub, index) => {
      sub.ch.forEach((chapter, chapterIndex) => {
        chapter.video.forEach((video) => {
          if (!appliedFilter || video.status == appliedFilter) {
            // let videoCard = getVideoCard(video);
            video.subIndex = index;
            video.chapterIndex = chapterIndex;
            filteredData.push(video);
          }
        });
      });
    });

  if (searchText) {
    filteredData = filteredData?.filter((item) => {
      return item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    });
  }
  if (filteredData.length == 0)
    return (
      <div className="course-content">
        <NoResultsFound />
      </div>
    );
  return (
    <div className="course-items">
      {filteredData.map((video) => {
        return getVideoCard(
          video,
          goTo,
          packageId,
          video.subIndex,
          undefined,
          data.subject[video.subIndex].name,
          data.subject[video.subIndex].ch[video.chapterIndex].name,
          moengageEventforstatus
        );
      })}
    </div>
  );
};
const ChapterSection = (
  chapters,
  searchText,
  goTo,
  packageId,
  subjectIndex,
  subject,
  moengageEventforstatus
) => {
  let chaptersContent = [];
  chapters.forEach((chapter) => {
    // chapter, goTo,searchText, packageId, subjectIndex )
    let chapterVideos = VideosList(
      chapter,
      goTo,
      searchText,
      packageId,
      subjectIndex,
      subject,
      moengageEventforstatus
    );

    if (chapterVideos) {
      chaptersContent.push(
        <AExpansionPanel
          variant="light"
          open={!!searchText}
          vCount={chapter.video && chapter.video.length}
          title={chapter.name}
          content={chapterVideos}
        />
      );
    }
  });
  if (chaptersContent.length == 0) return null;
  return <>{chaptersContent}</>;
};

const VideosList = (
  chapter,
  goTo,
  searchText,
  packageId,
  subjectIndex,
  subject,
  moengageEventforstatus
) => {
  let videos = chapter.video;
  let chapterContent = [];
  videos.forEach((video) => {
    let videoCard = getVideoCard(
      video,
      goTo,
      packageId,
      subjectIndex,
      searchText,
      subject,
      chapter.name,
      moengageEventforstatus
    );
    if (videoCard) chapterContent.push(videoCard);
  });
  if (chapterContent.length == 0) return null;
  return <div className="course-items">{chapterContent}</div>;
};

const getVideoInfo = (video) => {
  let timeLeft = video.duration - video.seekTime; // in ms
  if (video.status == "Watched") timeLeft = video.duration;
  timeLeft = timeLeft / 1000; // in s
  let hrs = Math.floor(timeLeft / 3600)
    ? `${Math.floor(timeLeft / 3600)} hrs `
    : "";
  let min = Math.ceil((timeLeft % 3600) / 60)
    ? `${Math.ceil((timeLeft % 3600) / 60)} min `
    : "";
  timeLeft = `${hrs ? hrs : ""}${min ? min : ""}${
    video.seekTime && video.seekTime < video.duration ? "Left" : ""
  } `;
  return timeLeft;
};

const getVideoCard = (
  video,
  goTo,
  packageId,
  subjectIndex,
  searchText,
  subject,
  chapter,
  moengageEventforstatus
) => {
  if (
    searchText &&
    video.name.toLowerCase().indexOf(searchText.toLowerCase()) == -1
  )
    return null;
  let actionText;
  if (video.status == "Watching") {
    actionText = "RESUME";
  } else if (video.status == "Watched") {
    actionText = "REPEAT";
  } else if (video.status == "Not Watched") {
    actionText = "WATCH NOW";
  }
  let info = getVideoInfo(video);
  let url = `/video-package/${packageId}/${video.name}#current-video/${parseInt(
    video.url
  )}/${subjectIndex}`;
  return (
    <CourseCard
      showIcon={true}
      title={video.name}
      icon={true}
      actionButton={
        <AButton
          onClick={() => {
            moengageEventforstatus(actionText, subject, chapter, video.title);
            goTo(url);
          }}
          text={actionText}
        ></AButton>
      }
      info={info}
      detail={
        video.seekTime ? (
          <AProgressBar
            color="blue"
            progress={Math.ceil((video.seekTime / video.duration) * 100)}
          />
        ) : (
          ""
        )
      }
    />
  );
};

const VideoSection = ({
  data,
  getData,
  searchText,
  goTo,
  packageId,
  setsuggestionData,
  setinternalTab,
  setSearchText,
  setInputText,
  moengageEventSectionTabChange,
  moengageEventforstatus,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  useEffect(() => {
    if (!data) {
      getData();
    }
  }, []);
  if (!data) return <CustomLoader />;

  const Filters = [
    { id: "", label: "All Videos" },
    { id: "Watching", label: "Watching" },
    { id: "Not Watched", label: "Not Watched" },
    { id: "Watched", label: "Watched" },
  ];

  return (
    <div className="course-content">
      <div className="course-content-filters">
        <div>
          {Filters.map((filter) => {
            return (
              <AButton
                text={filter.label}
                onClick={() => {
                  moengageEventSectionTabChange(filter.id);
                  setSelectedFilter(filter.id);
                  setinternalTab(filter.id);
                  setSearchText("");
                  setInputText("");
                  setsuggestionData("");
                }}
                contained
                color={filter.id === selectedFilter ? undefined : "grey"}
              />
            );
          })}
        </div>
        <div></div>
      </div>

      {selectedFilter == "" ? (
        <AllVideosSection
          data={data}
          searchText={searchText}
          goTo={goTo}
          packageId={packageId}
          moengageEventforstatus={moengageEventforstatus}
        />
      ) : (
        <FilteredVideoSection
          appliedFilter={selectedFilter}
          data={data}
          searchText={searchText}
          goTo={goTo}
          packageId={packageId}
          moengageEventforstatus={moengageEventforstatus}
        />
      )}
    </div>
  );
};

export default VideoSection;

import React, { useState, useEffect } from "react";
import CustomButton from "./library/CustomButton";
import AddressModal from "./AddressModal";
import Ainput from "./library/AInput";
import { connect } from "react-redux";
import { updateUserAddress } from "../data/ducks/productDetails/actions";
import CustomLoader from "./library/CustomLoader";
import { pdpUrls, paytmMiniUrls, pageName, STOREFRONT_URI } from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import { Cookies } from "react-cookie";
import {
  isEmailValid,
  getMoengageMetadata,
  getDeviceType,
  getDiscount,
  isValidCharCheck,
  isValidAddressCheck,
  numberWithCommas,
  isPhone,
  isPinCode,
  isEmptyField,
  trackMoengageEvent,
  isADDA,
  
} from "../data/utils/helpers";
import ApplyCouponCRM, {
  AppliedCouponDetails,
  YourOrdersDetails,
  YourOrdersDetailsMobileView,
} from "./ApplyCouponCRM";
const cookie = new Cookies();

const UserDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [showGuestMsg, setShowGuestMsg] = useState(false);

  const [purchased, setPurchased] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  useEffect(() => {
    var payload = props.Getpayload;
    const buyNow_Offer = sessionStorage.getItem("buyNow_Offer");
    payload.source_screen = `${buyNow_Offer == "buy" ? "Buy_now" : "View_offers"}`;
    if (typeof Moengage !== "undefined")
      trackMoengageEvent("continue_btn_clicked", getMoengageMetadata(payload));

    if (props.userDetails && props.userDetails.email && cookie.get('cp_token')) {
      setIsEmailDisabled(true);
    } else if (props.userEmail && cookie.get('cp_token')) {
      setIsEmailDisabled(true);
    }
  }, [props.userDetails]);
  useEffect(() => {
    if (props.continueButtonClicked) {
      props.setContinueButtonCLick(false);
      handleContinue();
    }
  }, [props.continueButtonClicked]);

  let initialState = {
    name:
      (props.priceDetails.address && props.priceDetails.address.name) || (props.priceDetails.name),
    phone:
      (props.priceDetails.address && props.priceDetails.address.phone) || (props.priceDetails.phone),
    email:
      (props.priceDetails.address && props.priceDetails.email) || props.userEmail || (props.priceDetails.email),
  };
  if (props.isShippable) {
    let userDetails = props.userAddress ||
      props.priceDetails.address || {
      city: undefined,
      firstLine: undefined,
      landmark: undefined,
      pincode: undefined,
      secondLine: undefined,
      state: undefined,
    };
    let id;
    let userAddress;
    ({ id, ...userAddress } = { ...userDetails, ...initialState });
    initialState = { ...userAddress };
  }
  let userInfo =
    localStorage.getItem("paytm-user-info") &&
    JSON.parse(localStorage.getItem("paytm-user-info"));
  
  if (props.isPaytmMini && localStorage.getItem("paytm-user-info")) {
    userInfo = JSON.parse(localStorage.getItem("paytm-user-info"));
    initialState = Object.assign(initialState, userInfo);
  }
  const [userDetails, setUserDetails] = useState(initialState);

  useEffect(() => {
    let initialStateData = {
      name: props.priceDetails?.address?.name || props.priceDetails.name || "",
      phone: props.priceDetails?.address?.phone || props.priceDetails.phone || "",
      email: props.priceDetails?.address?.email || props.priceDetails.email || "" ,
      pincode: props.priceDetails?.address?.pincode,
      firstLine: props.priceDetails?.address?.firstLine,
      secondLine: props.priceDetails?.address?.secondLine,
      landmark: props.priceDetails?.address?.landmark,
      city: props.priceDetails?.address?.city,
      state: props.priceDetails?.address?.state,
    }
    for (const property in initialStateData) {
      if (!initialState[property] && document.getElementById(property)) {
        document.getElementById(property)?.focus();
        document.getElementById(property)?.select();
        return;
      }
    }
  }, [])


  useEffect(() => {
    if (userDetails.pincode && userDetails.pincode.length == 6) {
      fetch(`${pdpUrls.pincodeListing}/${userDetails.pincode}.json`)
        .then(
          (res) => {
            let data = res[0];
            setUserDetails({
              ...userDetails,
              state: data.State||data.state,
              city: data.City||data.city,
            });
            setInValidaState({ ...inValidState, pincode: false });
          },
          (err) => {
            setInValidaState({ ...inValidState, pincode: true });
          }
        )
        .catch(() => {
          setInValidaState({ ...inValidState, pincode: true });
        });
    }
  }, [userDetails.pincode]);

  const [inValidState, setInValidaState] = useState({
    phone: false,
    name: false,
    pincode: false,
  });

  const handlePay = () => {

    let invalidState = {};

    if (props.isShippable) {
      invalidState = {
        phone: !isPhone(userDetails.phone),
        name:
          isEmptyField(userDetails.name) || isValidCharCheck(userDetails.name),
        email: !isEmailValid(userDetails.email),
        pincode: !isPinCode(userDetails.pincode) || inValidState.pincode ,
        firstLine:
          isEmptyField(userDetails.firstLine) ||
          isValidAddressCheck(userDetails.firstLine),
        secondLine: isEmptyField(userDetails.secondLine, 5) || isValidAddressCheck(userDetails.secondLine),
        landmark: isEmptyField(userDetails.landmark, 5) || isValidAddressCheck(userDetails.landmark),
        city: isValidCharCheck(userDetails.city),
        state: isValidCharCheck(userDetails.state),
      };
    } 
    else {
      invalidState = {
        phone: !isPhone(userDetails.phone),
        name:
          isEmptyField(userDetails.name) || isValidCharCheck(userDetails.name),
        email: !isEmailValid(userDetails.email),
      };
    }
    setInValidaState(invalidState);
    if (Object.values(invalidState).find((item) => item)) {
      props.setUserInformation(userDetails);
      return;
    }
    else
    {
      if (props.isShippable){
        props.setAddressModalShow(true);
      } else {
        payViaRazorPay(props.priceDetails.razorPayCreateOrderResponse)
      }
    }
  };
  const updateField = (fieldName) => {
    return (event) => {
      let value = event.target.value;
      let updatedUserDetails = { ...userDetails };
      if (fieldName == "phone") {
        value = value.replace(/[^0-9]/g,'')
      } else if (fieldName == "email") {
        props.setUserEmail(value);
      }

      updatedUserDetails[fieldName] = value;
      setUserDetails(updatedUserDetails);
      props.setUserInformation(updatedUserDetails);
      props.updateCheckoutData({ ...updatedUserDetails });
    };
  };
  const updateInfo = () => {
    setLoading(true);
    props.setUserInformation(userDetails);
    props.updateUserAddress(userDetails).then((data) => {
      data[0].then((resData) => {
        setLoading(false);
        let checkoutData = {
          email: userDetails.email,
          cartJson: props.cartJson,
          phone: userDetails.phone,
          name: userDetails.name,
          couponCode: props.checkoutData.appliedCoupon,
        };
        if (resData.success) {
          if (props.isShippable) {
            checkoutData.addressId = resData.data.id;
          }

          props.updateCheckoutData({ ...checkoutData });
          window.location.hash = "checkout/payment";
        } else {
          props.updateCheckoutData({ ...checkoutData });
        }
      });
    });
  };

  const updateAddress = (data) => {
    const query = new URLSearchParams(location.search);
    const guestUserToken = query.get("token");
    const payload = {
      name: userDetails.name || "",
      email: userDetails.email || "",
      firstLine: userDetails.firstLine || "",
      secondLine: userDetails.secondLine || "",
      city: userDetails.city || "",
      state: userDetails.state || "",
      landmark: userDetails.landmark || "",
      pincode: userDetails.pincode || "",
      phone: userDetails.phone || "",
    }
    fetch(
      `${STOREFRONT_URI}/api/v1/addresses/upgrade?orderId=${data.orderId}`,
      "PUT",
      payload,
      null,
      null,
      { token: guestUserToken }
    ).catch((err) => {
      console.error(err);
    });
  }

  const payViaRazorPay = (data) => {
    if(isADDA){
      updateAddress(data);
    }
    var options = {
      key: data.razorPayApiKey,
      // secret:'B3dTjpyO1ZWlSmWMw4b9fkdg',
      amount: data.amount,
      currency: data.currency,
      name: pageName[0],
      description: "Govt job in your pocket",
      image: `https://storecdn.${pageName[0]}.com/ic_header_logo.svg"`,
      order_id: data.id,
      handler: function (response) {
        // updateOrderSuccessInfo(data,response)
        window.location.hash = `cart/success/${data.orderId}/${props.priceDetails?.address?.email || props.priceDetails.email}`;
      },
      prefill: {
        name: props.priceDetails?.address?.name || props.priceDetails.name,
        email: props.priceDetails?.address?.email || props.priceDetails.email,
        method: null,
        contact: props.priceDetails?.address?.phone|| props.priceDetails.phone,
      },
      notes: {
        orderId: data.orderId,
      },
      theme: {
        color: "#fc3a52",
      },
      modal: {
        ondismiss: function (data) {
          window.location.hash = "cart/failure";
        },
      },
    };
    var razorpayObject = new Razorpay(options);
    razorpayObject.open();
  };
  const InputField = (
    <>
      {" "}
      <div className="userDetails" style={{ paddingBottom: "10px" }}>
        <div>
          <Ainput
            id="name"
            type="text"
            size="default"
            show="true"
            value={userDetails.name}
            onChange={updateField("name")}
            error={
              (inValidState.name && isEmptyField(userDetails.name)) ||
              isValidCharCheck(userDetails.name)
            }
            placeholder=" "
            name="Full Name"
            errorMessage={
              inValidState.name && isEmptyField(userDetails.name)
                ? "Full Name is Mandatory"
                : isValidCharCheck(userDetails.name)
                  ? "Only Alphanumeric and .,”@#()*/:{}[] allowed"
                  : null
            }
            disabled={
              props.isPaytmMini && userInfo && userInfo.name ? true : loading
            }
          ></Ainput>
        </div>
        <div>
          <Ainput
            id="phone"
            type="tel"
            size="default"
            show="true"
            value={userDetails.phone}
            onChange={updateField("phone")}
            error={inValidState.phone && !isPhone(userDetails.phone)}
            placeholder=" "
            name="Phone Number"
            errorMessage={
              inValidState.phone && !isPhone(userDetails.phone)
                ? "Enter a Valid Mobile Number"
                : null
            }
            disabled={
              props.isPaytmMini && userInfo && userInfo.email ? true : loading
            }
            maxlength="10"
          ></Ainput>
        </div>
        <div>
          <Ainput
            id="email"
            type="email"
            size="default"
            show="true"
            value={userDetails.email}
            onChange={updateField("email")}
            error={inValidState.email && !isEmailValid(userDetails.email)}
            placeholder=" "
            name="Email"
            errorMessage={
              inValidState.email && !isEmailValid(userDetails.email)
                ? "Email is Mandatory"
                : null
            }
            onFocus={() => setShowGuestMsg(true)}
            onBlur={() => setShowGuestMsg(false)}
            disabled={
              props.isPaytmMini && userInfo && userInfo.email
                ? true
                : loading || isEmailDisabled
            }
          ></Ainput>
        </div>
        {!props.isLoggedIn &&
        showGuestMsg &&
          <div className="inputTolltip">Please enter a valid Email ID. You will need to sign up with the same email to access the  purchased course.</div>
        }
        {props.isShippable && (
          <>
            <div>
              <Ainput
                id="pincode"
                type="number"
                size="default"
                show="true"
                value={userDetails.pincode}
                maxlength={"6"}
                onChange={updateField("pincode")}
                error={(inValidState.pincode && !isPinCode(userDetails.pincode)) || inValidState.pincode}
                placeholder=" "
                name="Pin Code"
                errorMessage={
                  inValidState.pincode ? 'Delivery service unavailable in this area. Kindly try another pincode.'
                    : !isPinCode(userDetails.pincode)
                    ? "Please enter a valid pincode"
                    : null
                }
                disabled={loading}
              ></Ainput>
            </div>
            <div>
              <Ainput
                id="firstLine"
                value={userDetails?.firstLine}
                onChange={updateField("firstLine")}
                disabled={loading}
                type="text"
                size="default"
                show="true"
                maxLength="200"
                error={
                  (inValidState?.firstLine &&
                    isEmptyField(userDetails?.firstLine)) ||
                  isValidAddressCheck(userDetails.firstLine)
                }
                errorMessage={
                  inValidState?.firstLine && isEmptyField(userDetails?.firstLine)
                    ? "Let us know your Flat, House No., Building, Company"
                    : isValidAddressCheck(userDetails?.firstLine)
                      ? "Only Alphanumeric and .,-”@#()*/:{}[] allowed"
                      : null
                }
                placeholder=" "
                name="Flat, House No., Building,Company"
              ></Ainput>
            </div>
            <div>
              <Ainput
                id="secondLine"
                type="text"
                disabled={loading}
                size="default"
                show="true"
                maxLength="200"
                value={userDetails.secondLine}
                onChange={updateField("secondLine")}
                error={(inValidState?.secondLine && isEmptyField(userDetails?.secondLine, 5)) || isValidAddressCheck(userDetails?.secondLine)}
                errorMessage={
                  inValidState?.secondLine && isEmptyField(userDetails?.secondLine, 5)
                  ? "Let us know your address (min 5 characters)"
                  : isValidAddressCheck(userDetails?.secondLine)
                    ? "Only Alphanumeric and .,-”@#()*/:{}[] allowed"
                    : null
                }
                placeholder=" "
                name="Area,Colony,Street,Sector,Village"
              ></Ainput>
            </div>
            <div>
              <Ainput
                id="landmark"
                type="text"
                disabled={loading}
                size="default"
                show="true"
                maxLength="200"
                value={userDetails?.landmark}
                error={(inValidState?.landmark && isEmptyField(userDetails?.landmark, 5)) || isValidAddressCheck(userDetails?.landmark)}
                errorMessage={
                  inValidState?.landmark && isEmptyField(userDetails?.landmark, 5)
                  ? "Let us know your Landmark (min 5 characters)"
                  : isValidAddressCheck(userDetails?.landmark)
                    ? "Only Alphanumeric and .,-”@#()*/:{}[] allowed"
                    : null
                }
                onChange={updateField("landmark")}
                placeholder=" "
                name="Landmark"
              ></Ainput>
            </div>
            <div>
              <Ainput
                id="city"
                value={userDetails.city}
                disabled={true}
                onChange={updateField("city")}
                type="text"
                show="true"
                error={isValidCharCheck(userDetails.city)}
                errorMessage={
                  isValidCharCheck(userDetails.city)
                    ? "Only Alphanumeric and .,”@#()*/:{}[] allowed"
                    : null
                }
                size="default"
                placeholder=" "
                name="City"
              ></Ainput>
            </div>
            <div>

              <Ainput
                id="state"
                type="text"
                disabled={true}
                size="default"
                show="true"
                value={userDetails.state}
                error={isValidCharCheck(userDetails.state)}
                errorMessage={
                  isValidCharCheck(userDetails.state)
                    ? "Only Alphanumeric and .,”@#()*/:{}[] allowed"
                    : null
                }
                onChange={updateField("state")}
                placeholder=" "
                name="State"
              ></Ainput>
            </div>
          </>
        )}
      </div>
    </>
  );

  const handleContinue = () => {
    let invalidState = {};

    if (props?.isShippable) {
      invalidState = {
        phone: !isPhone(userDetails?.phone),
        name:
          isEmptyField(userDetails?.name) || isValidCharCheck(userDetails?.name),
        email: !isEmailValid(userDetails?.email),
        pincode: !isPinCode(userDetails?.pincode) || inValidState.pincode ,
        firstLine: isEmptyField(userDetails?.firstLine) || isValidAddressCheck(userDetails?.firstLine),
        secondLine: isEmptyField(userDetails?.secondLine, 5) || isValidAddressCheck(userDetails?.secondLine),
        landmark: isEmptyField(userDetails?.landmark, 5) || isValidAddressCheck(userDetails?.landmark),
        city: isValidCharCheck(userDetails?.city),
        state: isValidCharCheck(userDetails?.state),
      };
    } else {
      invalidState = {
        phone: !isPhone(userDetails?.phone),
        name:
          isEmptyField(userDetails?.name) || isValidCharCheck(userDetails?.name),
        email: !isEmailValid(userDetails?.email),
      };
    }
    setInValidaState(invalidState);
    if (Object.values(invalidState).find((item) => item)) {
      props.setUserInformation(userDetails);
      return;
    }

    if (props?.isShippable){
      props.setAddressModalShow(true);
    } else {
      submit();
    }
  }

  const submit = () => {
    localStorage.setItem("name", userDetails && userDetails.name);
    localStorage.setItem("email", userDetails && userDetails.email);
    localStorage.setItem("phone", userDetails && userDetails.phone);
    const user_id_cookie = cookie.get("cp_user_id");
    var payload = props.Getpayload;

    if (props.userInfo && props.isLoggedIn && typeof window != "undefined") {
      dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 2, action: "checkout" },
            products: [
              {
                name: payload && payload.name,
                id: payload && payload.id,
                price:
                  props.payablePrice && props.payablePrice == 0
                    ? 0
                    : props.payablePrice || payload.sellingprice,
                brand: pageName[0],
                category: payload && payload.category,
                quantity: 1,
              },
            ],
            userDetails: [
              {
                name:
                  (props.userInfo && props.userInfo.name) ||
                  (userDetails && userDetails.name) ||
                  "",
                email:
                  (props.userInfo && props.userInfo.email) ||
                  (userDetails && userDetails.email) ||
                  "",
                isMobile: getDeviceType() == "m" ? true : false,
                loginType: "email",
                isLoggedIn: true,
              },
            ],
          },
        },
      });
    } else if (
      !props.isLoggedIn &&
      !user_id_cookie &&
      typeof window != "undefined"
    ) {
      dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 2, action: "checkout" },
            products: [
              {
                name: payload && payload.name,
                id: payload && payload.id,
                price:
                  props.payablePrice && props.payablePrice == 0
                    ? 0
                    : props.payablePrice || payload.sellingprice,
                brand: pageName[0],
                category: payload && payload.category,
                quantity: 1,
              },
            ],
            userDetails: [
              {
                name: (userDetails && userDetails.name) || "guest",
                email: (userDetails && userDetails.email) || "",
                isMobile: getDeviceType() == "m" ? true : false,
                loginType: "",
                isLoggedIn: false,
              },
            ],
          },
        },
      });
    }

    var payload = props.Getpayload;
    payload.user_mobile_no = userDetails && userDetails["phone"];
    payload.user_email = userDetails && userDetails.email;
    payload.user_name = userDetails && userDetails.name;
    payload.source_screen = "Fill_details";
    if (typeof Moengage !== "undefined")
      trackMoengageEvent(
        "continue_btn_clicked",
        getMoengageMetadata(payload)
      );
    if (loading) {
      return;
    }

    if (props.isPaytmMini) {
      let paytmUserInfo =
        JSON.parse(localStorage.getItem("paytm-user-info")) || {};

      if (!paytmUserInfo.email) {
        paytmUserInfo.email = userDetails.email;
      }
      if (!paytmUserInfo.name) {
        paytmUserInfo.name = userDetails.name;
      }
      if (!paytmUserInfo.phone) {
        paytmUserInfo.phone = userDetails.phone;
      }
      localStorage.setItem("paytm-user-info", JSON.stringify(paytmUserInfo));
    }

    updateInfo();
  };

  if (loading)
    return (
      <div className="couponLoader">
        {" "}
        <CustomLoader />
      </div>
    );
  return (
    <>
      <div className="buynow-body-content">
        <div className="buynow-left-side">
          <section
            class={
              collapse
                ? "buynow-user-details"
                : "buynow-user-details details-collapsed"
            }
          >
            <div className="buynow-heading-wrap">
              <div className="buynow-inner-heading">Your Details</div>
              {collapse ? (
                <div
                  className="buynow-arrow-collap buynow-arrow-top formobonly"
                  onClick={() => setCollapse(!collapse)}
                ></div>
              ) : (
                <div
                  className="buynow-arrow-collap buynow-arrow-bottom formobonly"
                  onClick={() => setCollapse(!collapse)}
                ></div>
              )}
            </div>
            {collapse ? InputField : null}
          </section>
        </div>

        <div className="buynow-right-side">
          <YourOrdersDetails 
            bookFlag={props?.productData?.subCategories?.some(el => el?.category === "BOOKS") && props?.productData?.subCategories?.length == 1 ? true : false}
            priceDetails={props.priceDetails}
            productData={props.productData}
            price={props.price}
            maxPrice={props.maxPrice}
          />

          {purchased && (
            <div>
              <span>
                User associated with {userDetails.email} has already purchased
                this product
              </span>
            </div>
          )}
          <AppliedCouponDetails
            priceDetails={props.priceDetails}
            productData={props.productData}
          />
          <div className="footer-paymentbtn-fix">
            <YourOrdersDetailsMobileView
              priceDetails={props.priceDetails}
              price={props.price}
              maxPrice={props.maxPrice}
            />
            <CustomButton
              loading={loading}
              buttontext="Continue"
              // onClick={() => payViaRazorPay(props.priceDetails.razorPayCreateOrderResponse)}
              onClick={handlePay}
            ></CustomButton>
          </div>
        </div>
      </div>
      {props.addressModalShow && <AddressModal confirm={() =>  payViaRazorPay(props.priceDetails.razorPayCreateOrderResponse)} userDetails={userDetails} setAddressModalShow={props.setAddressModalShow} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { productDetails } = state;
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  return {
    userAddress: productDetails.userAddress,
    productInfo: productDetails.data,
    isLoggedIn,
    userInfo,
    isPaytmMini: state.isPaytmMini,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserAddress: (addressObj) => dispatch(updateUserAddress(addressObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);

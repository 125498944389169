import React, { useEffect, useState } from 'react';
import NewCarousel from '../library/NewCarousel';
import CustomLoader from '../library/CustomLoader';
import { getPdpUrl, makeUrl, getDeviceType, getMoengageMetadata, createOptimizedImgUrl } from '../../data/utils/helpers';
import { Link } from 'react-router-dom';
import { dateMonthYear } from '../utils';
import ContineInApp from '../library/ContinueInApp/ContinueInApp';
import { getProductDetail } from '../../data/ducks/productDetails/actions';
import { pageName } from '../../../constants/appConfig';

let coursepurchaseContainer = React.createRef();

const MyPurchase = (props) => {

    const [appModaldata, setModalAppdata] = useState();
    useEffect(() => {
        if (getDeviceType() == "m") {
            apiHandlerForMobile()
        }
    }, [props.data && props.data.length])


    const apiHandlerForMobile = () => { 
        let options = {
            root: document.querySelector(`#mypurchasepackages${props.id}`),
            rootMargin: '0px',
            threshold: 1.0
        }
        let callback = (items) => {
            items.forEach(item => {
                if (item.isIntersecting) {
                    props.setPageNumber(props.pageNumber + 1)
                    props.updateData(props.pageNumber + 1)
                }
            });
        }

        let observer = new IntersectionObserver(callback, options);
        let target = document.querySelector(`#mypurchase-lastitem${props.id}`);
        target && observer.observe(target);
    }

    
    const handleCarousel = (e) => {
        if (props.isExpired) {
            props.setIsExpiredClick(true);
            props.checkForFeedCarousel(e);
        }
        else {
            props.checkForFeedCarousel(e);
            props.setIsExpiredClick(false);
        }
    }

    return (

        <div className='container-padding purchase-container'>
            <ContineInApp
                show={!!appModaldata}
                type={appModaldata && appModaldata.type}
                packageId={appModaldata && appModaldata.packageId}
                parentpackageId={""}
                close={() => {
                    setModalAppdata("");
                }} />
            <div className="d-flex" >
                <h2 className={'subtitle-mypurchase'}>{props.title || "My Purchase Courses"}</h2>
                {props.viewall && <span className='viewall'>
                    View all
</span>}
            </div>
            {props.data && props.data.length >= 1 ? <NewCarousel
                id={`mypurchasepackages${props.id}`}
                ref={coursepurchaseContainer}
                handleCarouselCallback={(e) => handleCarousel(e)}
                margin={10}>


                {props.data.map((value, index) => {
                    // const pdpurl = props.isExpired ? getPdpUrl(value.categories && value.categories, value.id, value.title, value.slugurl) : props.OpenConsumptionPage(value.categories, value.id);

                    let moengageEvent = () => {
                        var payload = {};
                        payload.id = value.id;
                        payload.name = value.title;
                        payload.package_status = "Paid";
                        payload.category = value.categories;
                        payload.exam_category = value.courses;
                        payload.package_url = typeof window != undefined && window.location.origin + getPdpUrl(value.categories, value.id, value.title, value.slugURL)
                        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                            'Purchased_Course_clicked',
                            getMoengageMetadata(
                                payload
                            ),
                        );
                    }
                     

                    let  Repurchase_btn_clicked=()=>{
                        localStorage.setItem("mypuchaseId",value.id)
                        var payload = {};
                        payload.id = value.id;
                        payload.name = value.title;
                        payload.package_status = "Paid";
                        payload.category = value.categories;
                        payload.exam_category = value.courses;
                        payload.package_url = typeof window != undefined && window.location.origin + getPdpUrl(value.categories, value.id, value.title, value.slugURL)
                        payload.Repurchase_btn_clicked_flag=1
                        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                            'Repurchased_Course_clicked',
                            getMoengageMetadata(
                                payload
                            ),
                        );

                    }
                  
                    let Url = props.isExpired ? getPdpUrl(value.categories && value.categories, value.id, value.title, value.slugurl) : (value.linkedPkgs ? `/my-purchase/${value.id}/${makeUrl((value.slugURL || value.title), true)}` : `/my-purchase/0/package/${value.id}/${makeUrl((value.slugURL || value.title), true)}`)
                    let onCardClick;


                    if (value.categories == "BOOKS") {
                        Url = undefined
                        onCardClick = () => {
                            alert("Please check my order section for Book")
                        }

                    }

                    if (getDeviceType() == "m" && value.categories != "BOOKS") {
                        Url = props.isExpired ? getPdpUrl(value.categories && value.categories, value.id, value.title, value.slugurl) : (value.linkedPkgs ? `/my-purchase/${value.id}/${makeUrl((value.slugURL || value.title), true)}` : undefined)
                        onCardClick = () => {
                            setModalAppdata({
                                packageId: value.id,
                                type: value.categories,
                            })
                        }
                    }
                    return (
                        <Link className={'mypurchase'} to={Url && Url}
                            onClick={() => {

                                !props.isExpired && moengageEvent()
                                !Url ? onCardClick() : null
                                props.isExpired ? Repurchase_btn_clicked():null
                            }}>
                            <div className="purchaseImage" id={index == props.data.length - 1 ? `mypurchase-lastitem${props.id}` : ""}>
                                <div className="purchaseImageBg"
                                    style={{ backgroundImage: `url(${value.imgUrl})` }}></div>
                                <div className="purchaseImageOverlay"></div>
                                <img src={createOptimizedImgUrl(value.imgUrl, 310, 173)} alt={pageName[0]} title={value.title} />
                                {props.isExpired && <span className="Expired">Expired</span>}
                            </div>



                            <div className='textcontainer'>

                                <span className='cardtext' title={value.title}>
                                    {value.title}

                                </span>
                                {<span className='expiredDateText'>{props.isExpired ? `Expired:${dateMonthYear(parseInt(value.expirydate + "000"))}` : `Vaild upto :${dateMonthYear(parseInt(value.expirydate + "000"))}`} </span>}
                                {props.isResume ? <div className='resume'>
                                    <span className='buy-now'>
                                        Resume
</span>
                                </div> :
                                    value.expired == "false" ? null :
                                        value.expired == "true" && (value.purchasable != "true" || !value.published) ?
                                            null :
                                            props.activeData && props.activeData.length >= 1 && props.activeData.map(result => { return result.id == value.id }).indexOf(true) == -1 && <span className='buy-now' >
                                                REPURCHASE
</span>}
                            </div>
                        </Link>
                    )
                })
                }
            </NewCarousel> : <span className="No-active-packages-yet">No {props.message} packages yet</span>}
        </div>

    )
}
export default MyPurchase;
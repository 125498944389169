import React, { useState } from 'react'

const orderHeader = (props) => {

    return (
        <div className="item-edge ">
            <div style={{ color: "#0277bd", lineHeight: '30px' }}>My Orders</div>
            <div>
                <span style={{ cursor: 'pointer' }} className={props.filter == 'ALL' ? "filter-tag active-header" : 'filter-tag'}
                    onClick={props.allOrdersHandler}>
                    All Orders
</span>
                <span style={{ cursor: 'pointer' }}
                    className={props.filter == "PAID" ? "filter-tag active-header" : "filter-tag "} onClick={props.paidOrdersHandler}>
                    Paid
</span>
                <span style={{ cursor: 'pointer' }}
                    onClick={props.failedOrdersHandler}
                    className={props.filter == "FAILED" ? "filter-tag active-header" : "filter-tag "}>
                    Failed
</span>
            </div>
        </div>
    )
};


export default orderHeader

import React from "react";

const SidePanel = (props) => {
  let sidePanelArr = props?.sidePanelArr;

  const sidePanelArrTopics = Object.keys(sidePanelArr);

  return (
    <div className={"my-test-series-group-menu-container"}>
      <div className={"my-test-series-group-name-md-content"}>
        <div className={"my-test-series-group-menu-ul"}>
          <div
            className={`my-test-series-group-li ${
              props?.groupNameId == -1 ? "active" : ""
            }`}
            onClick={() => props.getModelsbyGroupNameDefault(-1)}
          >
            <div className="column12 d-flex">
              <div
                className={
                  "columns my-test-series-test-group-title-parent column11"
                }
              >
                <span className="my-test-series-test-group-title">
                  All 3D Learning
                </span>
              </div>
              <div
                className={
                  "columns my-test-series-test-group-icon-parent column-1"
                }
              >
                <span className="chevron right" />
              </div>
            </div>
          </div>

          {sidePanelArrTopics &&
            sidePanelArrTopics?.map((item, i) => {
              return (
                <div
                  className={`my-test-series-group-li ${
                    props?.groupNameId == i ? "active" : ""
                  }`}
                  key={i + 11}
                  onClick={() => props?.getModelsbyGroupName(item, i)}
                >
                  <div className="column12 d-flex">
                    <div className="columns my-test-series-test-group-title-parent column11">
                      <span className="my-test-series-test-group-title">
                        {item}
                      </span>
                    </div>
                    <div className="columns my-test-series-test-group-icon-parent column-1">
                      <span className="chevron right" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getMoengageMetadata,
  getPdpUrl,
  isADDA,
  makeUrl,
} from "../data/utils/helpers";
import { Cookies } from "react-cookie";
import { pageName } from "../../constants/appConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  getManageExamsByPackage,
  getPackageCategories,
} from "../data/ducks/header/actions";
import { useHistory } from "react-router";
import { EBOOKS_COPYRIGHT_MSG } from "../../constants/textConstants";

export const createFilterListByGroupName = (dataArray) => {
  let FilterList = [{ id: "all", value: "All" }];
  FilterList = FilterList.concat(
    Object.values(
      dataArray.reduce((acc, item, idx) => {
        if (item.groupName && !acc[item.groupName])
          acc[item.groupName] = {
            key: idx,
            value: item.groupName.toUpperCase(),
            groupName: item.groupName,
          };
        return acc;
      }, {})
    )
  );
  return FilterList;
};
export const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getTimeDurationPercentage = (duration, totalSecondsInMilli) => {
  let percentage = 0;
  let seconds = totalSecondsInMilli / 1000;
  percentage = (duration / seconds) * 100;
  return parseInt(percentage);
};

const getItemCount = (itemCount, ItemLabel) => {
  return itemCount > 0 ? (
    <div className={ItemLabel === "FREE" ? "free-itemCount" : "paid-itemCount"}>
      {itemCount} {ItemLabel}
    </div>
  ) : undefined;
};

export const secondToHMS = (d, labelList) => {
  // LabelsList []
  let defaultLabelList = [" H ", " MIN ", " SEC"];
  if (labelList) {
    defaultLabelList = labelList;
  }
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay =
    h > 0 ? h.toString().padStart(2, "0") + defaultLabelList[0] : " ";
  var mDisplay =
    m > 0 ? m.toString().padStart(2, "0") + defaultLabelList[1] : " ";
  var sDisplay =
    s > 0
      ? s.toString().padStart(2, "0") + defaultLabelList[2]
      : `00${defaultLabelList[2]}`;
  return hDisplay + mDisplay + sDisplay;
};
export const testTimer = (d) => {
  // LabelsList []

  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h.toString().padStart(2, "0") : "00";
  var mDisplay = m > 0 ? m.toString().padStart(2, "0") : "00";
  var sDisplay = s > 0 ? s.toString().padStart(2, "0") : "00";
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const olcTimer = (d) => {
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var mDisplay = m > 0 ? m.toString().padStart(2, "0") : "00";
  var sDisplay = s > 0 ? s.toString().padStart(2, "0") : "00";
  return `${mDisplay}:${sDisplay}`;
};

export const microsecondsToHms = (d, labelList) => {
  d = Number(d);
  d = Math.floor(d / 1000);

  return secondToHMS(d, labelList);
};

export const timeStampToDateMonth = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let date = dateObj.getDate();
  let month = MonthMap[dateObj.getMonth()];

  return `${date} ${month}`;
};
export const timestampToDateTimedaysecond = (timestamp, amPmLabelArray) => {
  let date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  let labelArray = amPmLabelArray || ["am", "pm"];
  var ampm = hours >= 12 ? labelArray[1] : labelArray[0];
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  let formattedDate = date.toDateString();
  return formattedDate + " " + strTime;
};

export const checkProperties = (obj) => {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return true;
  }
  return false;
};
export const dateSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return "st";
  }
  if (j == 2 && k != 12) {
    return "nd";
  }
  if (j == 3 && k != 13) {
    return "rd";
  }
  return "th";
};
export const dateMonthFullTime = (timestamp) => {
  let date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let d = date.getDate();
  let year = date.getFullYear();
  const MonthMap = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = MonthMap[date.getMonth()];
  let result = {};
  let dateMonthTime = d + " " + month + " " + year + " " + strTime;
  let dateMonthMatch = d + " " + month + " " + year;
  result.dateMonthMatch = dateMonthMatch;
  result.dateMonthTime = dateMonthTime;
  return result;
};

export const dateMonthTime = (timestamp) => {
  let date = new Date(timestamp);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  let d = date.getDate();
  let year = date.getFullYear();
  const MonthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month = MonthMap[date.getMonth()];
  let result = {};

  // tempObj.dateMonthTime = tempObj.date + " " + tempObj.month + " " + tempObj.time;
  // tempObj.dateMonthMatch = tempObj.date + " " + tempObj.month;

  let dateMonthTime = d + " " + month + " " + year + " " + strTime;
  let dateMonthMatch = d + " " + month + " " + year;
  result.dateMonthMatch = dateMonthMatch;
  result.dateMonthTime = dateMonthTime;
  return result;
};

export const getTimeAmPM = (timestamp) => {
  let dateObj = new Date(timestamp);
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getMonth = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let month = MonthMap[dateObj.getMonth()];
  return month;
};
export const getDay = (timestamp) => {
  let dateObj = new Date(timestamp);
  const WEEKMAP = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let day = WEEKMAP[dateObj.getDay()];
  return day;
};

export const overAlldate = (timestamp) => {
  var date = new Date(timestamp);
  var result = `${date.getDate()} ${date.getMonth()} ${date.getHours()}: ${date.getMinutes()}: ${date.getSeconds()}`;
  return result;
};

export const timestamptodateyearandtime = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let date = dateObj.getDate();
  let month = MonthMap[dateObj.getMonth()];
  let year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  let seconds = dateObj.getSeconds();
  return `${date} ${month},${year},${hours}:${minutes}:${seconds}`;
};

export const dateMonthYear = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = dateObj?.getDate();
  let month = MonthMap[dateObj?.getMonth()];
  let year = dateObj?.getFullYear();
  let hours = dateObj?.getHours();
  let minutes = dateObj?.getMinutes();
  let seconds = dateObj?.getSeconds();
  return `${date} ${month}, ${year}`;
};
export const timeStampdateMonthYear = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let date = dateObj.getDate();
  let month = MonthMap[dateObj.getMonth()];
  let year = dateObj.getFullYear();
  return `${date} ${month},${year}`;
};
export const timeStampdateMonthYearebooks = (timestamp) => {
  let dateObj = new Date(timestamp);
  const MonthMap = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = dateObj.getDate();
  let month = MonthMap[dateObj.getMonth()];
  let year = dateObj.getFullYear();
  return `${date} ${month} ${year}`;
};

export const changeDateFormat = (date) => {
  if (!date) {
    return;
  }
  var value = new Date(date);
  return value.toLocaleString();
};

export const getItemCountInPackage = (type, data) => {
  let info = [];
  switch (type) {
    case "videoPkgs":
      info.push(<div className="videoIconS"></div>);
      info.push(getItemCount(data.freeVideosCount, "FREE"));
      info.push(
        getItemCount(
          data.videosCount - 1,
          "Video" + (data.videosCount > 1 ? "s" : "")
        )
      );
      break;
    case "ebooksPkgs":
      info.push(<div className="eBookIconS"></div>);
      info.push(getItemCount(data.freeEbooksCount, "FREE"));
      info.push(
        getItemCount(
          data.ebooksCount,
          "EBook" + (data.ebooksCount > 1 ? "s" : "")
        )
      );
      // info =
      //   getItemCount(data.freeEbooksCount, "FREE") +
      //   getItemCount(data.ebooksCount, "EBook");
      break;
    case "models3DPkgs":
      info.push(<div className="models3DS"></div>);
      info.push(getItemCount(data.freeEbooksCount, "FREE"));
      info.push(
        getItemCount(
          data.models3DCount,
          "3D-Model" + (data.ebooksCount > 1 ? "s" : "")
        )
      );
      break;
    case "testPkgs":
      info.push(<div className="testIconS"></div>);
      info.push(getItemCount(data.testSeriesCount, "Mock Tests "));
      info.push(
        getItemCount(
          data.ebooksCount,
          "EBook" + (data.ebooksCount > 1 ? "s" : "")
        )
      );
      // info =
      //   getItemCount(data.testSeriesCount, "Mock Tests +") +
      //   getItemCount(data.ebooksCount, "EBook");
      break;
    case "liveClasses":
      info.push(<div className="liveClassIconS"></div>);
      info.push(
        getItemCount(data.olcCount, "Video" + (data.olcCount > 1 ? "s" : ""))
      );
      // info =
      //   getItemCount(data.olcCount, "Video");
      break;
    // case "books":
    // info.push(<div className="bookIconS"></div>);
    // info.push(
    //   getItemCount(data.booksCount, "Book" + (data.booksCount > 1 ? "s" : ""))
    // );
    //   break;
  }
  info = info.filter((item) => item !== undefined);
  // this filter is unnecessary;
  return info;
};

export const showIcons = (data, languages) => {
  let info = [];

  for (var i = 0; i < data.length; i++) {
    if (data[i].type == "ONLINE_LIVE_CLASSES") {
      info.push(<div className="liveClassIconS"></div>);
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
    if (data[i].type == "EBOOKS") {
      info.push(<div className="eBookIconS"></div>);
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
    if (data[i].type == "MODELS_3D") {
      info.push(<div className="models3DS"></div>);
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
    if (data[i].type == "VIDEOS") {
      info.push(<div className="videoIconS"></div>);
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
    if (data[i].type == "TEST_SERIES") {
      info.push(<div className="testIconS"></div>);
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
    if (data[i].type == "BOOKS") {
      info.push(
        <img
          src="/images/icon-sm-book.svg"
          className="img-icon"
          title="book"
          alt="book"
        />
      );
      info.push(
        <div className={" padding-6p"}>
          {data[i].count >= 1 && data[i].count}
        </div>
      );
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="padding-14">{languages[0] && languages[0].name}</span>
      <div style={{ display: "flex" }}>{info}</div>
    </div>
  );
};
export const addRazorPayCheckoutScript = () => {
  var head = document.getElementsByTagName("head")[0];
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  head.appendChild(script);
};

export const pick = (keyMapper, dataObj) => {
  let pickedObj = {};
  Object.keys(keyMapper).forEach((keyName) => {
    pickedObj[keyMapper[keyName]] = dataObj[keyName];
  });
  return pickedObj;
};
export const moengageEvent = () => {
  if (typeof Moengage !== "undefined")
    Moengage.track_event("Explore_Now_btn_clicked", getMoengageMetadata({}));
};

export const openEbook = (packageId, ebookId) => {
  if (confirm(EBOOKS_COPYRIGHT_MSG)) {
    typeof window !== "undefined" &&
      window.open(`/e-books?file=${packageId}_${ebookId}.doc`);
  }
};
export const useOpen3DModel = () => {
  const history = useHistory();
  const open3DModel = (packageId, modelUrl, topic, payload) => {
    history.push({
      pathname: "/3d-learning",
      state: { pkgId: packageId, file: modelUrl, topic, payload },
    });
  };
  return [open3DModel];
};

export const resultInfoForSpecialTest = (specialTestData) => {
  let dateArray = specialTestData.resultDate.split("/"); //  "11/07/2019"   "resultTime" : "1700 hrs"
  let hrs = specialTestData.resultTime.slice(0, 2);
  let min = specialTestData.resultTime.slice(2, 4);
  var testDate = new Date(
    Date.parse(
      `${dateArray[2]}-${dateArray[0]}-${dateArray[1]} ${hrs}:${min}:00`
    )
  ).getTime();
  let currentDate = Date.now();
  let displaytext = "";

  if (currentDate < testDate) {
    displaytext = ` Results available on: ${timeStampdateMonthYear(
      testDate
    )} at ${formatSpecialTestTime(specialTestData.resultTime)}`;
  }

  return {
    resultOnline: currentDate > testDate,
    text: displaytext,
  };
};

export const formatSpecialTestTime = (timeString) => {
  var hours = +timeString.slice(0, 2);
  var minutes = +timeString.slice(2, 4);
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const loadVideoJsScripts = () => {
  let scripts = ["/images/drm/video.js", "/images/drm/videojs-contrib-eme.js"];
  let script = document.createElement("script");
  script.onload = function () {
    let innerScript = document.createElement("script");
    innerScript.src = "/images/drm/videojs-contrib-eme.js";
    document.head.appendChild(innerScript);
  };
  script.src = "/images/drm/video.js";
  document.head.appendChild(script);
};

let timeout;
export const debounce = function (func, delay) {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const openWidget = () => {
  window.fwSettings = {
    widget_id: 84000001021,
  };
  !(function () {
    if ("function" != typeof window.FreshworksWidget) {
      var n = function () {
        n.q.push(arguments);
      };
      (n.q = []), (window.FreshworksWidget = n);
    }
    FreshworksWidget("open");
    const cookie = new Cookies();
    FreshworksWidget("identify", "ticketForm", {
      name: cookie.get("cp_user_name") ? cookie.get("cp_user_name") : "",
      email: cookie.get("cp_user_email") ? cookie.get("cp_user_email") : "",
    });
  })();
};

export const shortenBreadCrumb = (text) => {
  if (text.length > 6) {
    return text.substring(0, 6) + "...";
  }
  return text;
};
export const formatMilliseconds = (milli) => {
  let seconds = Math.floor(milli / 1000);
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  const secString = seconds < 10 ? "0" + seconds : seconds;
  const minString = minutes < 10 ? "0" + minutes : minutes;
  const hourString = hours < 10 ? "0" + hours : hours;
  return `${hourString}:${minString}:${secString}`;
};
/**
 * remove duplicates from an array of object.
 * @param {*} removeDuplicateinObject is an method.
 * @author Amit Mathur
 * @returns uniques array objects.
 */
export const removeDuplicateinObject = (duplicateArray = []) => {
  const flag = {};
  const uniques = [];
  duplicateArray?.forEach((el) => {
    if (!flag[el.id]) {
      flag[el.id] = true;
      uniques.push(el);
    }
  });
  return uniques;
};

export function msToTime(ms) {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + " Sec";
  else if (minutes < 60) return minutes + " Min";
  else if (hours < 24) return hours + " Hrs";
  else return days + " Days";
}
/**
 * function that return timeLeftStamp.
 * @param {*} duration
 * @param {*} seekTime
 * @returns time left
 */
export const getTimeLeftStamp = (duration, seekTime) => {
  let timeLeft = duration - seekTime; // in ms
  timeLeft = timeLeft / 1000; // in s
  let hrs = Math.floor(timeLeft / 3600)
    ? `${Math.floor(timeLeft / 3600)} hrs `
    : "";
  let min = Math.ceil((timeLeft % 3600) / 60)
    ? Math.ceil((timeLeft % 3600) / 60)
    : "";
  min = min > 1 ? `${min} mins ` : `${min} min `;
  timeLeft = timeLeft ? `${hrs ? hrs : ""}${min ? min : ""}left ` : "";
  return timeLeft;
};

/**
 * method that return new string after removing the passed predefined string from an originalString.
 */
export const removePredefinedString = (
  originalString = "",
  predefinedStr = ""
) => {
  let new_str = originalString.substring(
    0,
    originalString.indexOf(predefinedStr)
  );
  return new_str;
};

/**
 * @param {*} lockBodyScroll method that lock body scroll based on passed boolean value.
 * @author {*} Amit Mathur
 * usage -> you can use,when you want to show any overlay popup.
 */
export const lockBodyScroll = (isBodyScrollLock = false) => {
  if (typeof window !== "undefined") {
    document.body.style.overflow = isBodyScrollLock ? "hidden" : "visible";
    document.body.style.height = isBodyScrollLock ? "100vh" : "";
  }
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

/**
 * Hook that gives window dimensions on every resize event and updates the state accordingly.
 */
export const useWindowDimensions = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", throttle(handleResize, 500));
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", throttle(handleResize));
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
export const makeTabs = (categorieData = []) => {
  const possibleCategories = [
    { category: "ONLINE_LIVE_CLASSES", label: "Live Classes" },
    { category: "TEST_SERIES", label: "Test Series" },
    { category: "VIDEOS", label: "Video Courses" },
    { category: "EBOOKS", label: "Ebooks" },
    { category: "BOOKS", label: "Books" },
    { category: "MAGAZINES", label: "Magazines" },
    { category: "MODELS_3D", label: "3D Learning" },
  ];

  let categoryTabsArr = [];
  if (categorieData?.length > 0) {
    categoryTabsArr = categorieData?.map((category) => ({
      category: category,
      label: possibleCategories?.find((dt) => dt.category === category)?.label,
    }));
  }

  let reorderCategoryTabs = [];
  reorderCategoryTabs = possibleCategories.filter((data) =>
    categoryTabsArr.find((category) => category.category === data.category)
  );
  return reorderCategoryTabs;
};

export const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const useDrag = (ref, deps = [], options) => {
  const {
    onPointerDown = () => {},
    onPointerUp = () => {},
    onPointerMove = () => {},
    onDrag = () => {},
  } = options;

  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e) => {
    setIsDragging(true);

    onPointerDown(e);
  };

  const handlePointerUp = (e) => {
    setIsDragging(false);

    onPointerUp(e);
  };

  const handlePointerMove = (e) => {
    onPointerMove(e);

    if (isDragging) {
      onDrag(e);
    }
  };

  useEffect(() => {
    const element = ref.current;
    document.addEventListener("pointerup", handlePointerUp);
    if (element) {
      element.addEventListener("pointerdown", handlePointerDown);
      element.addEventListener("pointerup", handlePointerUp);
      element.addEventListener("pointermove", handlePointerMove);

      return () => {
        element.removeEventListener("pointerdown", handlePointerDown);
        element.removeEventListener("pointerup", handlePointerUp);
        element.removeEventListener("pointermove", handlePointerMove);
      };
    }

    return () => {
      document.addEventListener("pointerup", handlePointerUp);
    };
  }, [...deps, isDragging]);

  return { isDragging };
};

export const getStrippedHTML = (htmlStr = "") => {
  let str = htmlStr + "";
  let strippedHtml = str.replace(/<[^>]+>/g, "");
  return strippedHtml;
};
export function getWordCounts(str = "") {
  const arr = str.split(" ");
  return arr.filter((word) => word !== "").length;
}

export const useRedirectToConsumptionUrl = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getCategoryLevelConsumptionUrl = (
    categorieData,
    childPckgId,
    packageId,
    title,
    activeCategoryParam
  ) => {
    const categoriesLength = categorieData?.categories?.length || 0;
    const redirectUrl = `/my-purchase/${packageId}/${makeUrl(title, true)}`;

    if (categoriesLength > 1) {
      // when categories data is more than 1 than redirect to productDetail overview page.
      return redirectUrl + activeCategoryParam;
    }

    // else redirect the user to direct consumption screen based on the category.
    return `${redirectUrl}/${childPckgId}/${makeUrl(title, true)}`;
  };
  const getPackageCategoriesData = async (packageId) => {
    return dispatch(getPackageCategories(packageId));
  };
  const getManageExam = (packageId) => {
    return dispatch(getManageExamsByPackage(packageId));
  };
  const onClick = async (pkgData = {}, activeCategoryParam) => {
    const {
      expired,
      published,
      packageCategories,
      packageId,
      title,
      slugurl,
      parent,
      childPackages,
    } = pkgData;

    let redirectUrl;

    const examRes = await getManageExam(packageId);
    const examResult = await examRes[0];
    const examData = examResult?.data;

    if (expired && published) {
      // Redirect to PDP page when package is expired and published
      redirectUrl = getPdpUrl(packageCategories[0], packageId, title, slugurl);
    } else if (expired && !published) {
      // Redirect to home page examMenu section when package is expired but unpublished
      redirectUrl = {
        pathname: "/",
        hash: "#BANKING", // Pass hash exam category value
      };
    } else if (!isADDA && examData?.examList?.length === 1) {
      redirectUrl = {
        pathname: `/my-purchase/${packageId}/${title}/exams/${
          examData?.examList?.[0]?.id
        }/${makeUrl(examData?.examList?.[0]?.name)}/allBatches`,
      };
      localStorage.setItem(`isUniqueExamIn${packageId}`, "true");
    } else if (!parent && (childPackages?.length === 1 || !childPackages)) {
      // Redirect to direct consumption screen when parent is false and length of childPackages is 1 || childPackages may be come null.
      redirectUrl = `/my-purchase/0/${makeUrl(
        title,
        true
      )}/${packageId}/${makeUrl(title, true)}`;
    } else if (parent && childPackages?.length === 1) {
      /**
       * If parent is true and length of childPackages is 1:
       * Step 1 - Call getCategories tabs API.
       * Step 2 - Check if categories length is greater than 1, then redirect the user to the productDetail Screen with tabs. Otherwise, (when categories length is 1.)redirect the user directly to the consumption screen.
       */
      if (packageCategories[0] === "BOOKS") {
        if (confirm("Please check my order section for Book!")) {
          history.push("/my-orders");
        }
        return;
      }

      // Call getCategories API
      const res = await getPackageCategoriesData(packageId);
      const data = await res[0];
      const categorieData = data?.data;

      if (categorieData?.categories?.length && childPackages[0]) {
        redirectUrl = getCategoryLevelConsumptionUrl(
          categorieData,
          childPackages[0],
          packageId,
          title,
          activeCategoryParam
        );
      } else {
        console.log("Category and childPkg not found!");
        return;
      }
    } else if (
      parent &&
      childPackages?.length > 1 &&
      childPackages?.length < 20
    ) {
      // Redirect the user to the productDetails listing page when parent is true and length of childPackages is greater than 1 and less than 20
      redirectUrl = `/my-purchase/${packageId}/${makeUrl(
        title,
        true
      )}${activeCategoryParam}`;
    } else if (parent && childPackages?.length > 20) {
      /**
       * Redirect to examlist screen if parent package has more than 20 child packages and user visit first time.
       */

      // no. of exams is greater than 1 and selected exam array length is null
      if (
        examData?.examList?.length > 1 &&
        !examData?.selectedExamList?.length
      ) {
        redirectUrl = {
          pathname: `/my-purchase/${packageId}/${makeUrl(title)}/selectExams`,
        };
      } else {
        redirectUrl = `/my-purchase/${packageId}/${makeUrl(
          title,
          true
        )}${activeCategoryParam}`;
      }
    } else {
      redirectUrl = `/my-purchase/${packageId}/${makeUrl(
        title,
        true
      )}${activeCategoryParam}`;
    }
    history.push(redirectUrl);
  };
  return [onClick];
};
export const usePaidSearchConsumptionEvent = () => {
  const history = useHistory();
  const onClick = (pkgData = {}) => {
    const { subParent, id: subParentId, title: subParentTitle } = pkgData;
    let redirectUrl = "";
    const queryString = `?redirectFrom=paidSearch`;
    if (subParent) {
      redirectUrl = `/my-purchase/${subParentId}/${makeUrl(
        subParentTitle
      )}/batch${queryString}`;
    } else {
      redirectUrl = `/my-purchase/0/${makeUrl(
        subParentTitle
      )}/${subParentId}/${makeUrl(subParentTitle)}${queryString}`;
    }
    history.push(redirectUrl);
  };
  return [onClick];
};
export const isDateAndTimeSmallerThanCurrent = (dateTimeString) => {
  if (!dateTimeString) {
    throw new Error("Missing date and time string");
  }

  let givenDateTime = new Date(dateTimeString).getTime();
  let currentDateTime = new Date().getTime();

  return givenDateTime > currentDateTime;
};
export const useWishListedPackageIds = () => {
  const { wishlistPackageData = {} } = useSelector((state) => state?.wishlist);

  // Use useMemo to calculate wishListPackageIds only when wishlistPackageData changes
  const wishListPackageIds = useMemo(() => {
    return wishlistPackageData?.postData?.map((item) =>
      Number(item?.businessReferenceId || 0)
    );
  }, [wishlistPackageData]);

  return [wishListPackageIds];
};

export const checkIsPackageWishList = (
  wishListedPackageIds = [],
  packageId = 0
) => {
  return wishListedPackageIds?.includes(packageId);
};

/**
 * Injects a watermark into a specified container element and moves it randomly within the container.
 * 
 * @param {string} containerId - The ID of the container element where the watermark will be injected.
 * @param {boolean} isMoving - Determines whether the watermark should be moving or not.
 * @returns {object} - An object containing a ref to the watermark element.
 */
export const injectWatermark = (containerId, isMoving) => {
  const watermarkRef = useRef(null);

  useEffect(() => {
    let wbInterval;
    if (watermarkRef.current) wbInterval = setInterval(moveElement, 2000);

    return () => {
      if (watermarkRef.current) clearInterval(wbInterval);
    };
  }, [watermarkRef]);

  function moveElement() {
    const element = watermarkRef.current;
    const vidContainer = document.getElementById(containerId);
    if (document.body.contains(element) && vidContainer) {
      const containerRect = vidContainer.getBoundingClientRect();
      const screenWidth = containerRect.width - element.clientWidth;
      const screenHeight = containerRect.height - element.clientHeight;
      if (isMoving) {
        // Choose a random corner (0, 1, 2, or 3)
        const randomCorner = Math.floor(Math.random() * 4);

        let randomX, randomY;

        switch (randomCorner) {
          case 0: // Top-left corner
            randomX = 0;
            randomY = 0;
            break;
          case 1: // Top-right corner
            randomX = screenWidth - 50;
            randomY = 0;
            break;
          case 2: // Bottom-left corner
            randomX = 0;
            randomY = screenHeight - 50;
            break;
          case 3: // Bottom-right corner
            randomX = screenWidth - 50;
            randomY = screenHeight - 50;
            break;
          default:
            break;
        }

        element.style.left = randomX + "px";
        element.style.top = randomY + "px";
      }
    } else {
      if (vidContainer) {
        const watermarkElement = watermarkRef.current;

        if (watermarkElement) {
          vidContainer.appendChild(watermarkElement);
        } else {
          console.error("watermarkRef.current is null or undefined");
        }
      } else {
        console.error("vid-cont element not found");
      }
    }
  }

  return { watermarkRef };
};

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { pageName } from "../../../constants/appConfig";
import Loadable from "react-loadable";

const PPCSearch = Loadable({
  loader: () => import("../../containers/MyPurchases/PPCSearch/PPCSearch"),
  modules: ["../../containers/MyPurchases/PPCSearch/PPCSearch"],
  webpack: [
    require.resolve("../../containers/MyPurchases/PPCSearch/PPCSearch"),
  ],
  loading: () => <div></div>,
});

const SearchUIOverLay = Loadable({
  loader: () => import("./index"),
  modules: ["./index"],
  webpack: [require.resolve("./index")],
  loading: () => <div></div>,
});

const FocusInput = () => {
  const [searchUiVisibility, setSearchUiVisiblity] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (searchUiVisibility) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
      document
        .getElementsByClassName("main-header-wrap")[0]
        .classList.add("active-header-search");
    } else {
      document
        .getElementsByClassName("main-header-wrap")[0]
        .classList.remove("active-header-search");
    }
    return () => {
      document.body.style.overflow = "inherit";
      document.body.style.height = "inherit";
      document
        .getElementsByClassName("main-header-wrap")[0]
        .classList.remove("active-header-search");
    };
  }, [searchUiVisibility]);
  const isMyPurchase = pathname.includes("/my-purchase");
  return (
    <>
      <div className="search-input-container onlyDesktop">
        <input
          className={`search-input-field `}
          id={"searchBar"}
          type="text"
          placeholder={
            isMyPurchase
              ? "Find Purchased Courses"
              : "Find video courses, mock tests, live classes..."
          }
          autoComplete="off"
          onFocus={() => setSearchUiVisiblity(true)}
          readOnly
        />
        <img
          className={"search-submit-field"}
          src={"/images/icon-search.svg"}
          alt={pageName[0]}
          title={pageName[0]}
        ></img>
      </div>
      {searchUiVisibility &&
        (isMyPurchase ? (
          <PPCSearch setSearchUiVisiblity={setSearchUiVisiblity} />
        ) : (
          <SearchUIOverLay setSearchUiVisiblity={setSearchUiVisiblity} />
        ))}
    </>
  );
};

export default FocusInput;

/* eslint-disable react/display-name */
import React from 'react';
import { Cookies } from "react-cookie";
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { productKeys } from '../../../constants/appConstants';
import { pageName } from '../../../constants/appConfig';
import { browseByProductType } from '../../../server/models/home';
import { getMoengageMetadata, isADDA, trackMoengageEvent } from '../../data/utils/helpers';
import PlatformBanner from './PlatformBanner';
const cookie = new Cookies();
const BrowseByProductType = React.memo((props) => {

  const userDetails = useSelector(store => store.header.userInfo ? store.header.userInfo : {});
  const history = useHistory();

  const getSubScription = (e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.getUtmFromStore = props.getUtmFromStore;
    trackMoengageEvent("get_subscription_button_clicked", payload);
    window.location.replace(
      `https://www.${pageName[0]}.com/search?searchTerm=maha%20pack&utm_source=${pageName[1]}&utm_medium=HomePage&utm_campaign=Subscription`
    );
  };
  const moengageEventTracker = (e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.productType = e.title || "";
    payload.getUtmFromStore = props.getUtmFromStore;
    let name = cookie.get('cp_user_name');
    let email = cookie.get('cp_user_email');
    payload.source_screen = 'Home page';
    payload.user_email = email;
    payload.user_name = name;
    payload.language = "English";
    payload.exam_category = "Web";
    payload.user_exam_category_selected = "Web";
    payload.deep_link = "_blank";
    if (typeof (Moengage) !== 'undefined') Moengage.track_event("browse_by_productType_clicked", getMoengageMetadata(payload));
  }
  return (
    <>
      <section className={props.isPaytmMini ? ' margintop container-padding products-type' : "container-padding products-type"}>
        <div className="container">
          <div className="common-headding">Explore by <span>Products</span></div>
          <div className="product-type-wrapper">
            {/* <!--repeat-box--> */}
            {
              browseByProductType().map(e => {
                return (
                  <div className="product-type-box" onClick={() => moengageEventTracker(e)}>
                    <Link 
                    // to={`/${productKeys[e.title]}`} key={e.title} 
                    key={e.title}
                    onClick = {() => {
                      if(e.title === 'Free Study Material'){
                        if(userDetails.email){
                          localStorage.setItem('freeQuizTabId', 2);
                          history.push(
                            {pathname : '/my-mock-test',
                            state : {'freeQuizTabId' : 2}}
                            );
                        }else{
                          localStorage.setItem('freeQuizTabId', 2);
                          localStorage.setItem('urlForFreeQuizzes', '/my-mock-test');
                          history.push('#show-login');
                        }
                      }else{
                        history.push({pathname: `/${productKeys[e.title]}`});
                      }
                    }}
                    >
                      <div className="product-type-text">
                        <div className="product-type-heading">{e.title}</div>
                        <p>{e.desc}</p>
                        <div class="product-type-action">
                          <span style={{textTransform: "uppercase"}}>{e.explore_text}</span> { isADDA ? (<img src="/images/product-type-arrow.svg" alt='arrow-right'/>) : (<img src="/images/sankalp-images/productype-arrow.svg" alt='arrow-right'/>)}
                        </div>
                      </div>
                      <div class="product-type-img"><img src={e.iconUri} alt={e.title} loading='lazy'/></div>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </>
  )
});


export default BrowseByProductType;
import React, { useEffect, useState } from 'react'
import OnboardingModal from './OnboardingModal'
import { hitMoengageForOnboarding, saveCookies, validateInput } from '../../../data/utils/helpers';
import useOnboardingAPI from './useOnboardingAPI';
import { useDispatch } from 'react-redux';
import { loginFromCookie } from '../../../data/ducks/header/actions';
import { withCookies } from 'react-cookie';
import { onBoardingStepStates } from './onboardingConstants';

const OnboardingPPCModal = (props) => {

    const [showOnboardingModal, setShowOnboardingModal] = useState(true);
    const [modalButtonAction, setModalButtonAction] = useState(() => {});
    const [otpSentFlag, setOtpSentFlag] = useState(false);
    const [emailVerifyDetails, setEmailVerifyDetails] = useState({
        email : "",
        otp : "",
        userId : "",
    });
    const [currentModalState, setCurrentModalState] = useState(onBoardingStepStates.account_recovery_details_collect);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const dispatch = useDispatch();

    const setCookie = (name, value, options = {}) => {
        props.cookies.set(name, value, options);
    };

    const {sendEmailOTP, verifyEmailOTP} = useOnboardingAPI();

    const handleSendEmailOTP = async (emailVerifyDetails) => {
        setLoading(true);
        let inputFields = {
            email : [emailVerifyDetails?.email],
        }
        const errors = validateInput(inputFields);
        if (errors?.email?.some(error => error)) { 
            setErrors({...errors, email : errors?.email});
            setLoading(false);
            return;
        }
        else {
            setErrors({...errors, email : []});
            const response = await sendEmailOTP(emailVerifyDetails?.email, emailVerifyDetails?.userId, false);
            if (response?.success) {
                setOtpSentFlag(true);
                setEmailVerifyDetails({...emailVerifyDetails, userId : response?.data?.id});
                setModalButtonAction(getModalAction(onBoardingStepStates.email_otp_verify));
                setCurrentModalState(onBoardingStepStates.email_otp_verify);
            }
            else {
                setErrors({...errors, email : [response?.message]});
            }
            setLoading(false);
        }
    }

    const handleOtpVerify = async (emailVerifyDetails) => {
        {  
            setLoading(true);
            setErrors({...errors, email : []});
            const response = await verifyEmailOTP(emailVerifyDetails, false);
            if (response?.success) {
                setCurrentModalState(onBoardingStepStates.account_recovery_success);
                hitMoengageForOnboarding("Mycontent", "Recovery_Email_Updated", {platform : "web"});

                let loginDetails = {...response?.data};
                
                let cookiesData = {
                    jwtToken : loginDetails?.jwtToken,
                    jwt256Token : loginDetails?.jwtTokenNew || loginDetails?.jwt256Token,
                    userId : loginDetails?.id,
                    name : loginDetails?.name,
                    email : loginDetails?.email,
                    phoneNumber : loginDetails?.phoneNumber,
                    userCourse : loginDetails?.userCourse,
                }
    
                saveCookies(cookiesData, setCookie);
                dispatch(loginFromCookie(
                    loginDetails?.jwtToken,
                    loginDetails?.name,
                    loginDetails?.email,
                    loginDetails?.id,
                    loginDetails?.phoneNumber,
                    loginDetails?.selectedCourse,
                    loginDetails?.jwtTokenNew  || loginDetails?.jwt256Token,
                ));

                if (typeof window !== "undefined") {
                    updateUserDataInCookie(
                        loginDetails?.email,
                        loginDetails?.jwtTokenNew  || loginDetails?.jwt256Token,
                        loginDetails?.name
                    );
                }
            }
            else {
                setErrors({...errors, email : [response?.message]});
            }
            setLoading(false);
        }
    }


    const getModalAction = (modalCurrentState) => {
        if(modalCurrentState === onBoardingStepStates.account_recovery_details_collect) {
            return (payload) => handleSendEmailOTP;
        }
        else if(modalCurrentState === onBoardingStepStates.email_otp_verify) {
            return (payload) => handleOtpVerify;
        }
        else return closeOnboardingModal;
    }

    const closeOnboardingModal = () => {
        setShowOnboardingModal(false);
    }

    const handleEditButtonAction = () => {
        setCurrentModalState(onBoardingStepStates.account_recovery_details_collect);
        setModalButtonAction(getModalAction(onBoardingStepStates.account_recovery_details_collect));
    }

    useEffect(() => {
        setEmailVerifyDetails({...emailVerifyDetails, userId : props.cookies.get("cp_user_id") || ""})?.toString();
        setModalButtonAction(getModalAction(onBoardingStepStates.account_recovery_details_collect));
    },[]);

    return (
        <div>
            <OnboardingModal 
                showModal={showOnboardingModal} 
                closeModal={closeOnboardingModal}
                currentStep={currentModalState}
                setCurrentModalState={setCurrentModalState}
                otpDetails={emailVerifyDetails}
                setOtpDetails={setEmailVerifyDetails}
                modalButtonAction={modalButtonAction}
                otpSentFlag={otpSentFlag} 
                setOtpSentFlag={setOtpSentFlag}
                selectedChange={"email"}
                modalLoading={loading}
                modalErrors={errors}
                resendOTP={() => {handleSendEmailOTP(emailVerifyDetails)}}
                editButtonAction={handleEditButtonAction}
            />
        </div>
    )
}

export default withCookies(OnboardingPPCModal);
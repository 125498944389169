import types from './types';
import {STOREFRONT_URI} from '../../../../constants/appConfig';

const fetchUserEbooks = () => {
    return {
        CALL_API: [
            {
                type: types.FETCH_USER_EBOOK,
                meta: {
                    path: `${STOREFRONT_URI}/api/v1/ebooks`,
                    method: 'GET'
                }
            }
        ]
    };
};
const fetchViewAllEbooks = (bookId,pageNumber,pageSize) => {
    return {
        CALL_API: [
            {
                type: types.FETCH_VIEW_EBOOK,
                meta: {
                    path: `${STOREFRONT_URI}/api/v2/ebooks/${bookId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
                    method: 'GET'
                }
            }
        ]
    };
};


export {
    fetchUserEbooks,
    fetchViewAllEbooks
};
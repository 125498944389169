import { pageName } from "./appConfig";

var d = new Date();
var year = d.getUTCFullYear();
export const productMeta = {
    '3542':{
        'title':'SSC CGL MAHA Pack (Live Online Coaching, Test Series,Video Courses, Ebooks)',
        'description':`Our SSC CGL MAHA Pack are prepared by the expert faculties of ${pageName[1]} based on latest exam pattern .SSC CGL Maha Pack incorporates all the essentials like Live online coaching, Test series, Video courses & E-books.Enroll Now & Improve Your Score.`
    },
    '3461':{
        'title':'SSC-CGL Tier – I eBook kit (Hindi Medium)',
        'description':`SSC-CGL Tier – I : Here's the best ebook kit for SSC CGL Tier- I Exam and it is available in Hindi medium so that the content can be easily understood. You will get a pack of 95 e-books for Exam preparation.`
    },
    '3359':{
        'title':`SBI PO Test Series ${year}: Best Practice SBI PO Online Test Series Sets`,
        'description':`SBI PO Test Series ${year}: Here's the best online test series for SBI PO ${year} Exam. Attempt SBI PO Prelims and Mains Test Series for Exam preparation`
    },
    '5338':{
        'title':`SSC ${year} COMPLETE FOUNDATION BATCH 2.0 |SSC LIVE CLASSES `,
        'description':`"Best coaching for all SSC exams preparation with 285+ hrs Live Classes. Course available in Hindi & English. Videos available 24/7 for quick Revision.. Solve Unlimited doubts with experts.Enroll Now."`
    },
    '3969':{
        'title':`Bank Online Test Series ${year}: Online Mock Test Series & ebooks`,
        'description':`${pageName[1]} provides you to practice a wide range of online mock test series & ebooks to prepare for various Banking exams.Validity of 12 months. 24×7 Doubts Clearing.Detailed Solutions of all Questions.`
    },
    '5159':{
        'title':'Online classes for Banking Awareness Batch for SBI, RRB, RBI and IBPS Exams | Bilingual',
        'description':`Online classes for Banking Awareness is necessary if you plan to attempt any Banking exam. So, here you get the best online classes for Banking Awareness. Based on Latest Exam Pattern.`
    },
};



export const pageMeta = {
    '-sbi-po-live-classes-kit':{
        'title':`SBI PO Online Coaching Classes ${year} : Join SBI PO Online Coaching`
    },
    '-sbi-po-mock-tests-kit':{
        'title':`SBI PO Online Mock Test Series ${year} : Practice the best SBI PO mock test series`
    },
    '-sbi-po-ebooks-kit':{
        'title':`SBI PO Best books ${year} : Get best Ebooks, PDF\'s, and SBI PO exam preparation books.`
    },
    '-sbi-clerk-live-classes-kit':{
        'title':`SBI Clerk Online Classes ${year} : Join the latest SBI Clerk Online Coaching Classes by ${pageName[1]}.`
    },
    '-sbi-clerk-mock-tests-kit':{
        'title':`SBI Clerk Online Mock Test series ${year}: Attempt the best SBI Clerk Mock test series`
    },
    '-sbi-clerk-ebooks-kit':{
        'title':`Best SBI Clerk Books ${year} : Prepare with the latest books, ebooks, pdf, for SBI Clerk exam`
    },
    '-ibps-rrb-live-classes-kit':{
        'title':`IBPS RRB Online coaching classes ${year} : Enroll now to get the best study material for IBPS RRB Exam`
    },
    '-ibps-po-live-classes-kit':{
        'title':`IBPS PO Online coaching Classes ${year} : Join IBPS PO Online classes by ${pageName[1]}.`
    },
    '-ibps-po-mock-tests-kit':{
        'title':`Get the best IBPS PO Test Series : Practice the latest IBPS PO test series by ${pageName[1]}`
    },
    '-ibps-po-books-kit':{
        'title':`Buy IBPS PO Best Books ${year} : Prepare for IBPS PO Exam with the latest books by ${pageName[1]}`
    },
    '-ibps-clerk-live-classes-kit':{
        'title':`IBPS Clerk Online Coaching Classes ${year} : Join the best IBPS Clerk online coaching | ${pageName[1]}`
    },
    '-ibps-clerk-mock-tests-kit':{
        'title':`IBPS Clerk Online Mock Test ${year} : Practice with best mock test for IBPS Clerk Exam`
    }
};

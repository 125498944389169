import React, { useState } from "react";
import CustomModal from "../../../../library/CustomModal";
import ExamInstruction from "./ExamInstruction";

const ViewInstructionHeader = ({
  examName = "",
  data = {},
  language,
  changeLanguage,
}) => {
  const [showExamInstruction, setShowExamInstruction] = useState(false);
  const handlePopup = () => {
    setShowExamInstruction(!showExamInstruction);
  };
  return (
    <>
      <CustomModal
        visible={showExamInstruction}
        title="Info"
        onCancel={handlePopup}
        closeCallback={handlePopup}
        closable={true}
        modalClassName={"exam-ins-popup"}
      >
        <p className="info-text">
          Note the timer is running. Kindly close instruction page to attend the
          questions.
        </p>

        <ExamInstruction
          data={data}
          isViewInstructionPopup={true}
          language={language}
          changeLanguage={changeLanguage}
        />
      </CustomModal>
      <div className="view-ins-header">
        <span>{examName}</span>
        <div className="viewIncouter">
          <div className="viewinsimg"></div>
          <button onClick={handlePopup}>View Instructions</button>
        </div>
      </div>
    </>
  );
};

export default React.memo(ViewInstructionHeader);

import React from "react";
import { getInitials } from "../../../../../data/utils/helpers";

const LeaderBoard = ({
  toppersList = [],
  totalMarks = 0,
  dtbQuestotalMarks = 0,
}) => {
  return (
    <div>
      <div className="leaderboardWrap">
        <div className="leaderboard-heading">Leaderboard</div>
        <div className="leaderboard-content">
          <ul>
            {toppersList?.map((topperInfo, index) => (
              <li key={index}>
                <div className="leaderboard-snap">
                  {getInitials(topperInfo?.name || "")} <span>{index + 1}</span>
                </div>
                <div className="leaderboard-name">{topperInfo?.name}</div>
                <div className="leaderboard-rank">
                  <span>
                    {topperInfo?.totalMarksObtained % 1
                      ? topperInfo?.totalMarksObtained.toFixed(2) // fixed to two decimal after decimal point,if number is float
                      : topperInfo?.totalMarksObtained}
                    /{totalMarks - dtbQuestotalMarks}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;

import React, { lazy, Suspense, useState } from "react";
import { connect } from "react-redux";
import "../../data/ducks/rankPredictor/reducers";
import "../../data/ducks/toast/reducers";
import ErrorBoundary from "../../components/ErrorBoundary";
import {
  setPageNo,
  setRankType,
  setRank,
  updateFormData,
  getCollegeList,
} from "../../data/ducks/rankPredictor/actions";
import { addToast } from "../../data/ducks/toast/actions";
import UserDetailsForm from "../../components/rankPredictor/UserDetailsForm";
import ToastContainer from "../../components/Toast/ToastContainer";
const RankPredict = lazy(() =>
  import("../../components/rankPredictor/RankPredict")
);
const CollegePredict = lazy(() =>
  import("../../components/rankPredictor/CollegePredict")
);

const NeetRankPredictor = ({
  page,
  rankType,
  currentRank,
  formInputData,
  setPageNo,
  setRankType,
  setRank,
  updateFormData,
  addToast,
  collegeList,
  getCollegeList,
}) => {
  const [steps] = useState([
    { id: 1, title: "Fill Details" },
    { id: 2, title: "Predict Your Rank" },
    { id: 3, title: "Predict Your College" },
  ]);

  const getCurrentPageComponent = () => {
    switch (page) {
      case 1:
        return (
          <UserDetailsForm
            rankType={rankType}
            setRankType={setRankType}
            setPageNo={setPageNo}
            updateFormData={updateFormData}
            addToast={addToast}
          />
        );
      case 2:
        return (
          <Suspense fallback={<div></div>}>
            <RankPredict
              rankType={rankType}
              currentRank={currentRank}
              formInputData={formInputData}
              setPageNo={setPageNo}
              setRank={setRank}
            />
          </Suspense>
        );
      case 3:
        return (
          <Suspense fallback={<div></div>}>
            <CollegePredict
              currentRank={currentRank}
              formInputData={formInputData}
              collegeList={collegeList}
              getCollegeList={getCollegeList}
            />
          </Suspense>
        );
      default:
        return (
          <UserDetailsForm
            rankType={rankType}
            setRankType={setRankType}
            setPageNo={setPageNo}
          />
        );
    }
  };
  const getCurrentPageHeader = () => {
    switch (page) {
      case 1:
      case 2:
        return (
          <h1 className="formHeanding">NEET 2023 Rank and College Predictor</h1>
        );
      case 3:
        return (
          <div className="sankHeadingWithBtn MobileShowSank">
            <div className="sankHeadingAllIndia">
              Eligible {rankType ? "State " : "All India "} Level Colleges as
              per your Predicted {rankType ? "State " : "All India "}
              Level Rank
            </div>
            <div className="SankFormHeaderBtn">
              <button data-testid="predictAgain" onClick={() => setPageNo(1)}>
                Predict Again
              </button>
            </div>
          </div>
        );
      default:
        return (
          <h1 className="formHeanding">NEET 2023 Rank and College Predictor</h1>
        );
    }
  };

  return (
    <div className={`sankFormWapMain ${page === 3 ? 'sankLastStep' :''}`}>
      <div className="formdostspn1"></div>
      <div className="formdostspn2"></div>
      <div className="sankFormContent">
        <div className="sankFormHeader">
          <ErrorBoundary>{getCurrentPageHeader()}</ErrorBoundary>
          <div className="sankFormSetps">
            <div className="stepper-wrapper">
              {steps?.map((step) => (
                <div
                  className={`stepper-item ${page === step.id && "completed"}`}
                  key={step.id}
                >
                  <div className="step-counter">{step.id}</div>
                  <div className="step-name">{step.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ToastContainer />
        <ErrorBoundary>{getCurrentPageComponent()}</ErrorBoundary>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.rankPredictor.pageNo,
    rankType: state.rankPredictor.rankType,
    currentRank: state.rankPredictor.currentRank,
    formInputData: state.rankPredictor.formInputData,
    collegeList: state.rankPredictor.collegeList,
  };
};

const mapDispatchToProps = {
  setPageNo,
  setRankType,
  setRank,
  updateFormData,
  addToast,
  getCollegeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(NeetRankPredictor);

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LiveTestBanner from "../enrolledCourses/TestSeries/TestResultAnalysis/Components/LiveTestBanner";
import { useParams } from "react-router";
import { TIMELINE_URI, GLOBAL_EXAMS_API } from "../../../constants/appUrls";
import { isADDA } from "../../data/utils/helpers";
import CustomModal from "../library/CustomModal";
import AdmitCardCampaigns from "../../containers/MyPurchases/SubModules/AdmitCardCampaigns";
import fetch from "../../data/utils/fetch";

const CategoriesTabs = ({
  tabs = [],
  activeTab,
  disableEvent,
  isPaidSearchRedirect,
}) => {
  const location = useLocation();
  let { pathname } = location;
  const history = useHistory();
  const getIconSrc = {
    ONLINE_LIVE_CLASSES: "login-icon5",
    VIDEOS: "login-icon4",
    TEST_SERIES: "login-icon2",
    EBOOKS: "login-icon3",
    MODELS_3D: "ic_models3d",
  };
  const params = useParams();

  const paramsArray = Object.values(params);

  const [campaigns, setCampaigns] = useState([]);
  const [isCampaignsFetched, setIsCampaignsFetched] = useState(false);
  const [freshCampaigns, setFreshCampaigns] = useState([]);
  const [submittedCampaignIds, setSubmittedCampaignIds] = useState([]);
  const [isSubmittedCampaignsFetched, setIsSubmittedCampaignsFetched] =
    useState(false);
  const [isCampaignSubmitted, setIsCampaignSubmitted] = useState(false);
  const [isCampaignVisible, setIsCampaignVisible] = useState(false);

  // get campaigns data
  const idForCampaign =
    params?.subParentId || params?.packageId || params?.parentPackageId;

  const getCampaigns = (packageId) => {
    try {
      fetch(
        `${TIMELINE_URI}/api/v1/admitCardCampaign?packageId=${packageId}`
      ).then((res) => {
        if (res?.success) {
          setCampaigns(res?.data);
          setIsCampaignsFetched(true);
        }
      });
    } catch (error) {}
  };
  const getSubmittedCampaigns = (packageId) => {
    try {
      fetch(
        `${GLOBAL_EXAMS_API}certification-ws/api/v1/admitCardCampaign/submittedCampaigns?packageId=${packageId}`
      ).then((res) => {
        if (res?.success) {
          setSubmittedCampaignIds(res?.data);
          setIsSubmittedCampaignsFetched(true);
        }
      });
    } catch (error) {}
  };

  const getCampaignsAndSubmittedCampaigns = () => {
    const campaignPopCloseDate =
      JSON.parse(localStorage?.getItem(`campaignPopCloseDate`)) || "";
    const today = new Date()?.getDate();
    const campaignPackageIds =
      JSON.parse(localStorage?.getItem(`campaignPackageIds`)) || [];
    // pop-up opened on same day
    if (campaignPackageIds?.includes(idForCampaign)) {
      if (today === campaignPopCloseDate) {
        // campaign pop-up won't show
      } else {
        getCampaigns(idForCampaign);
        getSubmittedCampaigns(idForCampaign);
      }
    } else {
      getCampaigns(idForCampaign);
      getSubmittedCampaigns(idForCampaign);
    }
  };

  useEffect(() => {
    if(!isADDA) getCampaignsAndSubmittedCampaigns();
  }, []);

  useEffect(() => {
    if (!isADDA && isCampaignSubmitted) {
      getCampaignsAndSubmittedCampaigns();
    }
  }, [isCampaignSubmitted]);

  useEffect(() => {
    if (isSubmittedCampaignsFetched && isCampaignsFetched) {
      const freshCampaigns = campaigns?.filter(
        (campaign) => !submittedCampaignIds?.includes(campaign?.id)
      );
      setFreshCampaigns(freshCampaigns);
      if (freshCampaigns?.length > 0) {
        setIsCampaignVisible(true);
      }
    }
  }, [isSubmittedCampaignsFetched, isCampaignsFetched, campaigns]);

  const onClose = () => {
    setIsCampaignVisible(false);
    const date = new Date()?.getDate();
    localStorage.setItem(`campaignPopCloseDate`, date);
    const campaignPackageIds =
      JSON.parse(localStorage?.getItem(`campaignPackageIds`)) || [];
    !campaignPackageIds?.includes(idForCampaign) &&
      campaignPackageIds.push(idForCampaign);
    localStorage.setItem(
      `campaignPackageIds`,
      JSON.stringify(campaignPackageIds)
    );
  };

  return (
    <>
      <LiveTestBanner
        packageId={params?.parentPackageId || params?.packageId}
      />
      <div className="ppc-exam-cat-tab">
        {tabs?.map((tab) => (
          <div
            onClick={() =>
              !disableEvent &&
              history.replace(
                `${pathname}?category=${tab.category}${
                  isPaidSearchRedirect ? `&redirectFrom=paidSearch` : ""
                }`
              )
            }
            className={`ppc-exam-tablist  ${
              tab.category === activeTab && "activetab"
            }`}
            key={tab.category}
          >
            <div className="ppc-tablist-icon">
              <img
                src={`/images/${getIconSrc[tab.category]}.svg`}
                alt="category-tab"
              />
            </div>
            <div className="ppc-tablist-name">{tab?.label}</div>
          </div>
        ))}
      </div>
      {!isADDA && (
        <CustomModal
          visible={isCampaignVisible}
          modalClassName={`admit-card-campaign ${
            isCampaignSubmitted ? `GiftThanksScreen` : null
          }`}
          footer={null}
          hideHeader={true}
          header={null}
          bodyStyle={{ overflowY: "auto" }}
          closable={true}
          onClose={onClose}
        >
          <AdmitCardCampaigns
            campaigns={freshCampaigns}
            submittedCampaignIds={submittedCampaignIds}
            isCampaignSubmitted={isCampaignSubmitted}
            setIsCampaignSubmitted={setIsCampaignSubmitted}
            onClose={onClose}
            setSubmittedCampaignIds={setSubmittedCampaignIds}
          />
        </CustomModal>
      )}
    </>
  );
};

export default CategoriesTabs;

import types from './types';
import { productListingUrl, searchSuggestionUrl } from '../../../../constants/appConfig';
import { isADDA } from '../../utils/helpers';

const getDataForExamCategoryListing = (exam,sponsoredCategory = 1) => {
    return {
        CALL_API: [
            {
                type: types.GET_EXAM_CATEGORY_LISTING,
                meta: {
                    path: `${searchSuggestionUrl}?examTypesFacets=${exam}&primaryFilter=true&sponsoredCategory=${sponsoredCategory}${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                    examType: exam.replace(/-/g, '').split('&')[0]
                }
            }
        ]
    };
};

const getExamCategoryListingWithFilter = (exam,sponsoredCategory = 1) => {
    return {
        CALL_API: [
            {
                type: types.GET_EXAM_CATEGORY_LISTING_WITH_FILTER,
                meta: {
                    path: `${productListingUrl}?examTypesFacets=${exam}&sponsoredCategory=${sponsoredCategory}&primaryFacetsRequired=true${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                    examType: exam.replace(/-/g, '').split('&')[0]
                }
            }
        ]
    };
};

const clearExamCategoryData = () => {
    return {
        type: types.CLEAR_ALL,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
}

export {
    getDataForExamCategoryListing,
    clearExamCategoryData,
    getExamCategoryListingWithFilter
};
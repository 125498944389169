import React, { useEffect, useState, useRef } from "react";
import { testTimer } from "../../../utils";
const Timer = ({ startTime, submitTest, sectionId, setIsTestTimeOver, setCurrentSectionRemainingTime }) => {
  const [time, setTime] = useState(Math.floor(startTime));
  const timeOutId = useRef();

  const getCurrentTime = () => {
    return Math.ceil(Date.now() / 1000);
  };
  
  const [initialTime, setInitialTime] = useState(startTime + getCurrentTime());

  useEffect(() => {
    if (timeOutId.current) clearTimeout(timeOutId.current);
    setTime(startTime);
    setInitialTime(startTime + getCurrentTime());
  }, [startTime, sectionId]);

  useEffect(() => {
    const handleTimerTick = () => {
      setTime(initialTime - getCurrentTime());
    };
    
    timeOutId.current = setInterval(handleTimerTick, 1000);

    return () => {
      if (timeOutId.current) clearInterval(timeOutId.current);
    };
  }, [initialTime]);

  useEffect(() => {
    if (time <= 0) {
      setIsTestTimeOver(true);
      submitTest(true);
    }
  }, [time]);

  useEffect(() => {
    setCurrentSectionRemainingTime(time);
  },[time]);


  return <span>Time Left: {testTimer(time)}</span>;
};

export default Timer;
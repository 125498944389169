import React, { useEffect, useState } from 'react'
import CustomButton from '../components/library/CustomButton'
import OnboardingModal from '../components/library/LoginModal/OnboardingModal'
import useOnboardingAPI from '../components/library/LoginModal/useOnboardingAPI';
import { saveCookies, scrollToTop, validateInput } from '../data/utils/helpers';
import { loginFromCookie, updateUserDetails } from '../data/ducks/header/actions';
import { useDispatch } from 'react-redux';
import { withCookies } from 'react-cookie';
import { onBoardingStepStates } from '../components/library/LoginModal/onboardingConstants';

const PersonalInformation = (props) => {

    const {profileData} = props;

    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [currentModalState, setCurrentModalState] = useState(onBoardingStepStates.mobile_otp_send);
    const [modalButtonAction, setModalButtonAction] = useState(() => {});

    const [personalInformation, setPersonalInformation] = useState({
        email: "",
        userId: "",
        newPhoneNumber: "",
        oldPhoneNumber: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        otp: "",
        name: "",
        updateEmail : true,
        hasPassword : false,
        logOut : true,
        showCurrentPassword : false,
        showNewPassword : false,
        showConfirmPassword : false,
    });

    const [selectedChange, setSelectedChange] = useState("email");
    const [modalLoading, setModalLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalErrors, setModalErrors] = useState({
        email : [],
        password : [],
        phone : [],
        otp : [],
    });

    const [pIErrors, setPIErrors] = useState({
        email : [],
        password : [],
        phone : [],
        otp : [], 
    });

    const [otpSentFlag, setOtpSentFlag] = useState(false);

    const {sendEmailOTP, verifyEmailOTP, updateMobileSendOTP, updateMobileVerifyOTP, changeUserPassword, updateUserProfile} = useOnboardingAPI(); 

    const dispatch = useDispatch();

    const setCookie = (name, value, options = {}) => {
        props.cookies.set(name, value, options);
      };

    const closeOnboardingModal = () => {
        setShowOnboardingModal(false);
    };

    const openModal = (modalStep) => {
        setCurrentModalState(modalStep);
        setShowOnboardingModal(true);
    }

    const getModalAction = (modalAction) => {
        if(modalAction === "verifyMobileOTP") {
            return () => async (payload) => {
                handleMobileOtpVerify(payload)
            }
        }
        else if(modalAction === "verifyEmailOTP") {
            return () => async (payload) => {
                handleEmailOtpVerify(payload);
            }
        }
        else if(modalAction === "passwordChange") {
            return () => async (payload) => {
                setModalLoading(true);
                let passwordErrorArr = [];
                if (personalInformation?.hasPassword) passwordErrorArr = [...passwordErrorArr, payload?.currentPassword, payload?.newPassword, payload?.confirmPassword];
                else passwordErrorArr = [...passwordErrorArr, payload?.newPassword, payload?.confirmPassword];
                let inputFields = {
                    password : passwordErrorArr,
                }
                const errors = validateInput(inputFields);
                if (errors?.password?.some(error => error)) { 
                    setModalErrors({...modalErrors, password : personalInformation?.hasPassword ? errors?.password : ["", ...errors?.password]});
                    setModalLoading(false);
                    return;
                }
                else if (payload?.newPassword !== payload?.confirmPassword) {
                    setModalErrors({...modalErrors, password : ["","", "New password and confirm passwords should be same"]});
                    setModalLoading(false);
                    return;
                }
                else {
                    setModalErrors({...modalErrors, password : []});
                    const response = await changeUserPassword(payload);
                    if (response?.success ){
                        setPersonalInformation({...personalInformation, 
                            currentPassword : "", 
                            newPassword : "",
                            confirmPassword : "",
                            showCurrentPassword : false,
                            showConfirmPassword : false,
                            showNewPassword : false,
                            hasPassword : true,
                        })
                        setCurrentModalState(onBoardingStepStates.change_success);
                        let userDetails = response?.data || {};
                        userDetails.jwt256Token = props.cookies.get("cp_new_token");
                        updateCookies(userDetails);
                    }
                    else {
                        setModalErrors({...modalErrors, password : ["","",response?.message]});
                    }
                    setModalLoading(false);
                }
            }
        }
        else return closeOnboardingModal
    }

    const handleMobileOtpSent = async () => {

        let inputFields = {
            phone : [personalInformation?.newPhoneNumber],
        }
        const errors = validateInput(inputFields);
        if (errors?.phone?.some(error => error)) { 
            setPIErrors({...errors, phone : errors?.phone});
            return;
        }
        else {
            setPIErrors({...errors, phone : []});
            const response = await updateMobileSendOTP(personalInformation);
            if (response?.success) {
                setOtpSentFlag(true);
                setSelectedChange("mobile");
                setModalButtonAction(getModalAction("verifyMobileOTP"));
                openModal(onBoardingStepStates.mobile_otp_verify);
            }
            else {
                setPIErrors({...errors, phone : [response?.message]});
            }
        }
    }

    const handleMobileOtpVerify = async (payload) => {
        setModalLoading(true);
        const response = await updateMobileVerifyOTP(payload);
        if (response?.success) {
            setModalErrors({...modalErrors, phone : []});
            setCurrentModalState(onBoardingStepStates.change_success);
            setPersonalInformation({...personalInformation, oldPhoneNumber : payload?.newPhoneNumber});
            updateCookies(response?.data);
        }
        else {
            setModalErrors({...modalErrors, phone : [response?.message]});
        }
        setModalLoading(false);
    }

    const handleEmailOtpSent = async () => {

        let inputFields = {
            email : [personalInformation?.email],
        }
        const errors = validateInput(inputFields);
        if (errors?.email?.some(error => error)) { 
            setPIErrors({...errors, email : errors?.email});
            return;
        }
        else {
            setPIErrors({...errors, email : []});
            const response = await sendEmailOTP(personalInformation?.email, personalInformation?.userId, false);
            if (response?.success) {
                setPIErrors({
                    email : [],
                    password : [],
                    phone : [],
                    otp : [],
                });
                setOtpSentFlag(true);
                setSelectedChange("email");
                setModalButtonAction(getModalAction("verifyEmailOTP"));
                openModal(onBoardingStepStates.email_otp_verify);
            }
            else {
                setPIErrors({...errors, email : [response?.message]});
            }
        }
    }

    const handleEmailOtpVerify = async (payload) => {
        setModalLoading(true);
        let inputFields = {
            email : [payload?.email],
        }
        const errors = validateInput(inputFields);
        if (errors?.email?.some(error => error)) { 
            setModalErrors({...modalErrors, email : errors?.email});
            setModalLoading(false);
            return;
        }
        else {
            setModalErrors({...modalErrors, email : []});
            const response = await verifyEmailOTP(payload);
            if (response?.success ){
                setCurrentModalState(onBoardingStepStates.change_success);
                updateCookies(response?.data);
            }
            else {
                setModalErrors({...modalErrors, email : [response?.message]});
            }
            setModalLoading(false);
        }
    }

    const handlePasswordChange = () => {
        if (!personalInformation?.email) {
            setPIErrors({...pIErrors, password : "Please add the email first"});
            return;
        }
        setSelectedChange("password");
        openModal(onBoardingStepStates.password_change);
        setModalButtonAction(getModalAction("passwordChange"));
    }

    const updateName = async (payload) => {
        setLoading(true);
        let response = await updateUserProfile(payload);
        if(response?.success) {

            let d = new Date();
            d.setTime(d.getTime() + 525600 * 60 * 1000);

            setCookie("cp_user_name", personalInformation?.name, {
                path: "/",
                expires: d,
            });
            setSelectedChange("name");
            if (typeof window !== "undefined") {
                updateUserDataInCookie(
                    personalInformation?.email || "",
                    props.cookies.get("cp_token"),
                    personalInformation?.name
                );
            }
            dispatch(updateUserDetails({name: personalInformation?.name}));
            openModal(onBoardingStepStates.change_success);
        }
        else {
            console.error("mobile register fail --> ", response?.message);
        }
        setLoading(false);
    }

    const updateCookies = (loginDetails) => {
                
        let cookiesData = {
            jwtToken : loginDetails?.jwtToken,
            jwt256Token : loginDetails?.jwtTokenNew || loginDetails?.jwt256Token,
            userId : loginDetails?.id,
            name : loginDetails?.name,
            email : loginDetails?.email,
            phoneNumber : loginDetails?.phoneNumber,
            userCourse : loginDetails?.userCourse,
            refId : loginDetails?.refId,
        }

        saveCookies(cookiesData, setCookie);
        if (typeof window !== "undefined") {
            updateUserDataInCookie(
                loginDetails?.email,
                loginDetails?.jwtToken,
                loginDetails?.name
            );
        }
        dispatch(loginFromCookie(
            loginDetails?.jwtToken,
            loginDetails?.name,
            loginDetails?.email,
            loginDetails?.id,
            loginDetails?.phoneNumber,
            loginDetails?.selectedCourse,
            loginDetails?.jwtTokenNew,
        ));
    }

    const getResendBtnAction = (currentStep) => {
        if(currentStep === onBoardingStepStates.mobile_otp_verify) {
            handleMobileOtpSent();
        }
        else if (currentStep === onBoardingStepStates.email_otp_verify) {
            handleEmailOtpSent();
        }
    }

    useEffect(() => {

        scrollToTop(0);
        if ( profileData && profileData?.data && Object.keys(profileData?.data).length ) {

            let cookiesData = {
                name : profileData?.data?.name || (props.cookies.get("cp_user_name") == "null" ?  "" : props.cookies.get("cp_user_name")),
                userId : profileData?.data?.id || (props.cookies.get("cp_user_id") || ""),
                email : profileData?.data?.email || (props.cookies.get("cp_user_email") == "null" ?  "" : props.cookies.get("cp_user_email")),
                mobile : profileData?.data?.phone || (props.cookies.get("cp_user_phone") == "undefined" ?  "" : props.cookies.get("cp_user_phone")),
            }
    
            setPersonalInformation(prevState => ({
                ...prevState,
                email: cookiesData.email ||  prevState.email,
                userId: cookiesData.userId || prevState.userId,
                newPhoneNumber: cookiesData.mobile || prevState.newPhoneNumber,
                oldPhoneNumber: cookiesData.mobile || prevState.oldPhoneNumber,
                name: cookiesData.name || prevState.name,
                hasPassword : profileData?.data?.hasPassword,
            }));

        }
    },[profileData]);

    const autoOtpVerify = () => {
        if(currentModalState === onBoardingStepStates.mobile_otp_verify) {
            handleMobileOtpVerify(personalInformation);
        }
        else if(currentModalState === onBoardingStepStates.email_otp_verify) {
            handleEmailOtpVerify(personalInformation);
        }
    }

    useEffect(() => {
        if(personalInformation?.otp?.length === 6) {
            autoOtpVerify();
        }
    }, [personalInformation?.otp]);

    return (
        <div className="OnboardPI-wrap">
            <div className="signup-header-wrap">
                <div className="signup-heading">Personal Information</div>
                <div className="signup-subheading">Please enter below details to complete your profile</div>
            </div>


            <div className='personal-info-body-wrap'>
                <div className="onbaorPIBox">
                    <label className='personal-info-field-label' >Full Name</label>
                    <input 
                        className='personal-info-field-input' 
                        placeholder='Enter Name' 
                        onChange={(e) => setPersonalInformation({...personalInformation, name : e.target.value})}
                        value={personalInformation?.name}
                    />
                </div>
                <div className="onbaorPIBox oBoption">
                    <label className='personal-info-field-label' >Email</label>
                    <div className="onbaordPInputWrap">
                        <input 
                            className='personal-info-field-input' 
                            placeholder='Enter Email' 
                            onChange={(e) => setPersonalInformation({...personalInformation, email : e.target.value})}
                            value={personalInformation?.email}
                        />
                        <span 
                            className='personal-info-action-btn' 
                            onClick={() => { handleEmailOtpSent() }}
                        >
                            Update
                        </span>
                    </div>
                    <div className="otpErrorWrap">{pIErrors?.email?.[0]}</div>
                </div>
                <div className="onbaorPIBox oBoption">
                    <label className='personal-info-field-label' >Mobile Number</label>
                    <div className="onbaordPInputWrap">
                        <input 
                            className='personal-info-field-input' 
                            placeholder='Enter Mobile Number' 
                            onChange={(e) => setPersonalInformation({...personalInformation, newPhoneNumber : e.target.value?.trim()?.substring(0,10)})}
                            value={personalInformation?.newPhoneNumber}
                        />
                        <span 
                            className='personal-info-action-btn' 
                            onClick={() => {handleMobileOtpSent()}}>
                                Update
                            </span>
                    </div>
                    <div className="otpErrorWrap">{pIErrors?.phone?.[0]}</div>
                </div>
                <div className="onbaorPIBox oBoption">
                    <label className='personal-info-field-label' >Password</label>
                    <div className="onbaordPInputWrap">
                        <input 
                            className='personal-info-field-input' 
                            placeholder='Enter Password' 
                            onChange={(e) => {setPersonalInformation({...personalInformation, password : e.target.value})}}
                            value={"●●●●●●"}
                            disabled
                        />
                        <span 
                            className='personal-info-action-btn' 
                            onClick={() => {handlePasswordChange()}}>
                                Change 
                        </span>
                        <div className="otpErrorWrap">{pIErrors?.password}</div>
                    </div>
                </div>
            </div>
            {personalInformation?.name && props.cookies.get("cp_user_name") !== personalInformation?.name ? <div className="nextbtncont">
                <CustomButton
                    type="primary"
                    className={"bluebtn ripple"}
                    buttontext={
                        <span className="help-section primary-btn-wrap">
                            <span className="help-section-primary-btn-label">Submit</span>
                        </span>
                    }
                    loading={loading}
                    onClick={() => {updateName({name : personalInformation?.name})}}
                />
            </div> : ""}
            <OnboardingModal
                showModal={showOnboardingModal}
                closeModal={closeOnboardingModal}
                currentStep={currentModalState}
                setCurrentStep={setCurrentModalState}
                otpDetails={personalInformation}
                setOtpDetails={setPersonalInformation}
                modalButtonAction={modalButtonAction}
                selectedChange={selectedChange}
                modalLoading={modalLoading}
                modalErrors={modalErrors}
                resendOTP={() => {getResendBtnAction(currentModalState)}}
                otpSentFlag={otpSentFlag}
                setOtpSentFlag={setOtpSentFlag}
                showEditIcon={false}
            />
        </div>
    )
}

export default withCookies(PersonalInformation);
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FallBackImage, STOREFRONT_URI, pageName } from "../../../constants/appConfig";
import ContineInApp from "../../components/library/ContinueInApp/ContinueInApp";
import {
  replaceReadingListData, updateReadingListData,
  updateReadingListPage
} from "../../data/ducks/continueReading/actions";
import "../../data/ducks/continueReading/reducers";
import fetch from "../../data/utils/fetch";
import { getDeviceType, getMoengageMetadata, isADDA, optimizedImgUrl } from "../../data/utils/helpers";
import AProgressBar from "../library/AProgressBar";
import NewCarouselInsta from "../library/NewCarouselInsta";
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS, EBOOKS_COPYRIGHT_MSG } from "../../../constants/textConstants";
import { TEST_SERIES_URL } from "../../../constants/appUrls";
import { openTestPortal } from "../enrolledCourses/TestSeries/TakeTest/util";

let carouselContainer = React.createRef();
const ContinueReadingLatest = (props) => {
  const focus = useRef();
  const testPortalOpened = useRef();
  const propData = useRef();
  const [modalData, setModalData] = useState();
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
  useEffect(() => {
    propData.current = props.continueReading;
  }, [props.continueReading])

  useEffect(() => {
    if(!props.continueReading?.data?.length){
      getNextPageData(0);
    }
    let previousData = props.continueReading?.data;
    window.addEventListener("focus", () => {
      focus.current = "focus";
      if (testPortalOpened.current) {
        updateTestData();
      }
    });
  }, []);

  const updateTestData = () => {
    // its bad design  but we need to do it

    // getting pageSize to update all the videos
    let propsData = propData.current
    if (!propsData) return;
    let pageSize = ((propsData.page || 0) + 1) * 10;
    let previousData = propsData.data || []
    fetch(
      ` ${isADDA ? TEST_SERIES_URL : STOREFRONT_URI}/api/v4/my/purchased/test-series/continue-studing?pageNo=0&pageSize=${pageSize}`,
      "GET"
    ).then((res) => {
      if (res && res.data) {
        let index = 0;
        let updatedData = previousData.map((item) => {
          if (item.type == "TEST-SERIES") {
            if (res.data[index]) {
              res.data[index].type = 'TEST-SERIES';
              let updatedData = res.data[index]
              index += 1;
              return updatedData
            }
            return item;
          }

          else {
            return item;
          }
        })
        props.replaceReadingListData(updatedData);

      }
      else {
        let updateValue = previousData.filter((item => {

          return item && item.type != "TEST-SERIES";

        }))

        props.replaceReadingListData(updateValue);
      }


    }, () => { })

  }
  const getNextPageData = (page) => {
    // let nextPageNo = props.continueReading.page
    //   ? props.continueReading.page + 1
    //   : 0;
    let ebooksData = fetch(
      `${STOREFRONT_URI}/api/v1/my/purchased/ebooks/continue-studing?pageNo=${page}&pageSize=15`,
      "GET"
    );
    let testData = fetch(
      ` ${isADDA ? TEST_SERIES_URL : STOREFRONT_URI}/api/v4/my/purchased/test-series/continue-studing?pageNo=${page}&pageSize=15`,
      "GET"
    );
    let videoData = fetch(
      ` ${STOREFRONT_URI}/api/v1/my/purchased/videos/continue-studing?pageNo=${page}&pageSize=15`,
      "GET"
    );
    Promise.all([videoData, testData, ebooksData])
      .then((responses) => {
        let mergedData = [];
        const TYPE_ARRAY = ["VIDEOS", "TEST-SERIES", "EBOOKS"];
        responses.forEach((response, index) => {
          if (response && response.data) {
            let fetchedData = response.data.map((item) => {
              item.type = TYPE_ARRAY[index];
              return item;
            });
            fetchedData.sort((a, b) => b.updatedAt - a.updatedAt);
            mergedData = mergedData.concat(fetchedData);
          }
        });
        props.updateReadingListData(mergedData)


      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let minItemsForCarousel = getDeviceType() == DEVICE_IDENTIFIERS.MOBILE_IDENTIFIER ? 2 : 6;
    if (props.continueReading.data && (props.continueReading.data.length > minItemsForCarousel)) {
      let options = {
        root: document.getElementById("continue-studying"),
        rootMargin: "0px",
        threshold: 0.5,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            // getNextPageData(!props.continueReading.page ? 1 : props.continueReading.page + 1);
            props.updateReadingListPage(
              !props.continueReading.page ? 1 : props.continueReading.page + 1
            );
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById("continue-studying-last-item");
      if (target) observer.observe(target);
    }
  }, [props.continueReading.data && props.continueReading.data.length]);
  if (!props.continueReading.data || props.continueReading.data.length == 0)
    return null;
  return (
    <div className="container-padding continue-studyning pendingcourse">
      <ContineInApp show={!!modalData}
        packageId={modalData && modalData.packageId}
        parentpackageId={modalData && modalData.parentPackageId}
        type={modalData && modalData.type}
        close={() => {
          setModalData();
        }}
        isContinuesection={true}
      />
      <h2 className={"common-headding "} > Continue <span>Studying</span></h2>
      <NewCarouselInsta ref={carouselContainer} id={"continue-studying"} sliderLength={props.continueReading.data} dataLength={props.continueReading.data.length} lastValue = {3}>
        {props.continueReading.data.map((courseInfo, index) => {
          let timeLeft;
          let payload = {};
          payload.id = courseInfo.packageId;
          payload.category = courseInfo.type;
          payload.package_status = "Paid";
          payload.exam_category = courseInfo.category && courseInfo.category;
          if (
            courseInfo.duration != undefined &&
            courseInfo.seekTime != undefined
          ) {
            timeLeft = courseInfo.duration - courseInfo.seekTime; // in ms
            timeLeft = timeLeft / 1000; // in s
            let hrs = Math.floor(timeLeft / 3600)
              ? `${Math.floor(timeLeft / 3600)} hrs `
              : "";
            let min = Math.ceil((timeLeft % 3600) / 60)
              ? Math.ceil((timeLeft % 3600) / 60)
              : "";
            min = min > 1 ? `${min} mins ` : `${min} min `
            payload.time_left = timeLeft ? `${hrs ? hrs : ""}${min ? min : ""}` : "";
            timeLeft = timeLeft ? `${hrs ? hrs : ""}${min ? min : ""}left ` : '';

          }
          let onClickFunction = () => {
            console.log("clicked");
          };
          if (courseInfo.type == "EBOOKS") {

            if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                setModalData({
                  packageId: courseInfo.id,
                  type: COURSE_CATEGORY_IDENTIFIERS.EBOOKS_IDENTIFIER,
                  parentPackageId: courseInfo.packageId
                })
              }
            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                if (
                  confirm(
                    EBOOKS_COPYRIGHT_MSG
                  )
                ) {
                  typeof window !== "undefined" &&
                    window.open(
                      `/e-books?file=${courseInfo.packageId}_${courseInfo.id}.doc`
                    );
                }
              };
            }
          }
          else if (courseInfo.type == "TEST-SERIES") {
            if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) {
              onClickFunction = () => {

                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                setModalData({
                  packageId: courseInfo.id,
                  type: COURSE_CATEGORY_IDENTIFIERS.TEST_SERIES_IDENTIFIER,
                  parentPackageId: courseInfo.packageId
                })
              }
            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                testPortalOpened.current = true;
                openTestPortal(courseInfo.id,courseInfo.packageId,courseInfo.title,"INCOMPLETE",null,courseInfo?.courseList, [], "", false, true)
              };
            }
          }
          else if (courseInfo.type == 'VIDEOS') {

            if (getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                setModalData({
                  packageId: courseInfo.id,
                  parentPackageId: courseInfo.packageId,
                  type: COURSE_CATEGORY_IDENTIFIERS.VIDEOS_IDENTIFIER
                })
              }
            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                    payload
                  ),
                );
                if(getDeviceType() === DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER){
                  let url = `/video-package/${courseInfo.packageId}/${courseInfo.title}#current-video/${courseInfo.id}`
                  props.goTo(url);
                }
                else {
                  setModalData({
                    packageId: courseInfo.id,
                    parentPackageId: courseInfo.packageId,
                    type: COURSE_CATEGORY_IDENTIFIERS.ONLINE_LIVE_CLASSES_IDENTIFIER
                  })
                }
              }
            }
          }
          return (
            <div className="continue-reading-container"
            key={`Math.floor(Math.random() * Date.now())-${index}`}
              id={
                index == props.continueReading.data.length - 3
                  ? "continue-studying-last-item"
                  : ""
              }
              onClick={onClickFunction}
            >
              <div className="continue-reading paid-product-box">
                <div
                  title={courseInfo.title}
                  className="continue-reading-image product-img-paid"
                  // style={{ backgroundImage: `url(${courseInfo.thumbnail})` }}
                  style={{ backgroundImage: `url(${optimizedImgUrl(courseInfo.thumbnail)})` }}

                >
                  <div className="overlay-bg"></div>
                  <img src={optimizedImgUrl(courseInfo.thumbnail )|| FallBackImage } onError={(e)=>{e.target.onerror = null; e.target.src=`${FallBackImage}`}} alt={courseInfo.title|| 'course'}/>
                </div>
                <div className="continue-reading-progress">
                  {courseInfo.seekTime ? (
                    <AProgressBar
                      progress={Math.ceil(
                        (courseInfo.seekTime / courseInfo.duration) * 100
                      )}
                    // progress={2}
                    />
                  ) : ''}
                </div>
                <div className="continue-reading-details paid-content-wrap">
                  <div className="coursename-paid" title={courseInfo.title}>{courseInfo.title}</div>
                  {timeLeft && (
                    <div className="timeleft"> {timeLeft}</div>
                  )}
                  <div class="resume-btn"><a href="#" tabIndex="0">Resume</a></div>
                </div>
              </div>
            </div>
          );
        })}
      </NewCarouselInsta>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { continueReading } = state;
  return {
    continueReading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateReadingListData: (data) => dispatch(updateReadingListData(data)),
    updateReadingListPage: (pageNo) => dispatch(updateReadingListPage(pageNo)),
    replaceReadingListData: (data) => dispatch(replaceReadingListData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueReadingLatest);

import { Cookies } from "react-cookie";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import PPCBreadCrumbs from "../../components/PPC/PPCBreadCrumbs";
import { cleanUpPPCPackageState } from "../../data/ducks/header/actions";
import { scrollToTop } from "../../data/utils/helpers";
import ErrorBoundary from "../../components/ErrorBoundary";
const cookie = new Cookies();

const PPCWrapper = ({
  breadCrumbRoutes = [],
  pageTitle = "",
  LeftSection,
  rightSection = "",
  children,
}) => {
  const dispatch = useDispatch();
  const initialPPCState = {
    packageExamsData: {
      examsData: {},
      manageExams: {},
    },
    packageData: {
      categoryTabs: [],
      continueStudyingData: [],
      comingSoonData: {},
      childData: [],
      examSubPackages: {},
      allcontinueStudyingData: [],
    },
  };

  useEffect(() => {
    scrollToTop(0);
    /**
     * cleanUp the PPC global state on every time when component is ummount.so that every time new fresh state is available for storing data.
     */
    return () => dispatch(cleanUpPPCPackageState(initialPPCState));
  }, []);
  const token = cookie.get("cp_token");

  if (token) {
    return (
      <ErrorBoundary>
        <div className="ppc-wrap">
          <div className="ppc-container">
            {/* <!--PPC breadcrumbs--> */}
            {breadCrumbRoutes?.length > 0 && (
              <PPCBreadCrumbs routes={breadCrumbRoutes} />
            )}
            {/* <!--top bg bar--> */}
            <div
              className={`exam-selection-heading-bg ${
                LeftSection ? "oldExamActive" : ""
              }`}
            >
              {LeftSection}
              <div className="exam-selection-heading">{pageTitle}</div>
              {rightSection}
            </div>
            {children}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
  return <Redirect to="/#show-login" />;
};

export default PPCWrapper;

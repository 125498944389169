import React from "react";
import Cookies from "js-cookie";
import { injectWatermark } from "../utils";

const WatermarkElement = ({ containerId }) => {
  const { watermarkRef } = injectWatermark(containerId, true);
  return (
    <div className="student-watermark" ref={watermarkRef} id="moving-watermark">
      {Cookies.get("cp_user_name") || "name"} <br />
      {Cookies.get("cp_user_phone") || ""}
    </div>
  );
};

export default WatermarkElement;

import React, { useEffect } from 'react';

const Toast = ({ message, onClose, timeout, variant, btnEnabled }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, timeout); // Automatically close the toast after 3 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`toast toast-${variant}`}>
      <div className="toast-message">{message}</div>
      {btnEnabled && <button data-testid="toast-close" className="toast-close" onClick={onClose}>
        X
      </button>}
    </div>
  );
};

Toast.defaultProps = {
  timeout : 3000,
  variant : 'error',
  btnEnabled: false
}

export default Toast;

import React, { useState, useEffect, useMemo } from "react";
import AButton from "../library/AButton";
import LazyIntersection from "../LazyIntersection";
import { createOptimizedImgUrl, isADDA } from "../../data/utils/helpers";
import { timeStampdateMonthYearebooks } from "../utils";
import HandOutModal from "../LiveClasses/HandOutModal";
import LiveTestToolTipIcon from "../library/LiveTestToolTipIcon";
import { getStateUpdateTime, toolTipMessage } from "../enrolledCourses/TestSeries/TestResultAnalysis/Components/liveTestUtils";

const CourseCard = ({
  showIcon,
  imgSrc,
  title,
  info,
  detail,
  actionButton,
  live,
  icon,
  olc,
  isBook,
  openEbook,
  liveTest,
  reattemptBtn,
  liveTestStatusObj,
  liveTestSchedule,
  liveTestRemainingTime = "",
  liveTestResultTime = "",
  testCategory = 2,
  liveTestCutoff = 0,
  liveTestPackage = false,
  classNames
}) => {
  const [handoutModal, setHandoutModal] = useState(false);
  const handleHandoutModal = () => {
    setHandoutModal(true);
  };

  return (
    <div
      className={`course-card ${classNames} ${liveTest ? "live-test-course-card-wrap" : ""}`}
    >
      {handoutModal && (
        <HandOutModal
          data={olc?.joinUrl}
          close={() => {
            setHandoutModal(false);
          }}
          type="purchased"
          details={olc}
          openEbook={openEbook}
        />
      )}
      {showIcon && (
        <div className="course-card-icon">
          {icon ? (
            <div className="playIcon"></div>
          ) : (
            <LazyIntersection
              src={createOptimizedImgUrl(imgSrc, 68, 80)}
              placeholder={
                isADDA
                  ? "/images/placeholder-adda.svg"
                  : "/images/img-placeholder-sankalp.png"
              }
            />
          )}
        </div>
      )}
      <div className="course-card-content">
        <div className="liveTestCCWrap">
          {live && (
            <div className="live-info">
              <div className="dot"></div>
              <div>Live</div>
            </div>
          )}
          {liveTestPackage && (
            <div className="live-test-tag-wrap">
              {liveTest && testCategory === 2 ? (
                <div className="live-test-category-tagWrap">
                  <span className="live-test-category-tag">Full Length</span>
                </div>
              ) : (
                ""
              )}
              {liveTestCutoff > 0 && (
                <div className="livetest-cutoff-marks">
                  <div className="livetest-cutoff-marks-wrap">
                    Cut off : {liveTestCutoff}
                  </div>
                </div>
              )}
            </div>
          )}
          {liveTest ? (
            <LiveTestToolTipIcon
              tooltipMessage={
                testCategory === 2
                  ? toolTipMessage.fullLengthTooltip
                  : toolTipMessage?.sectionalTooltip
              }
            />
          ) : (
            ""
          )}
          <div className="cc-content-title" title={title}>
            {title}
          </div>
        </div>

        <div className="cc-content-info">
          <span className="cc-content-info-inner">
            {isBook && info ? `E-Book Live On ${info}` : info}
          </span>
        </div>
        <div className="cc-content-detail">{detail}</div>
        {olc && olc.faculty && (
          <span className={"faculty-name"}>{`By: ${olc.faculty}`}</span>
        )}
      </div>
      <div className="LiveTestRightSection">
        <div className="course-card-action pdfIconAddWrap">
          {olc && (olc?.handoutsAvailable ||olc?.pdfFileName ||olc?.dppFileNames) && (
            <i
              className="handout-button-icon pdfIconAdd"
              onClick={handleHandoutModal}
            ></i>
          )}
        </div>
        {/* <AButton text={"RESUME"}></AButton> */}
        {liveTest &&
          liveTestStatusObj?.status === "Result Awaited" &&
          liveTestRemainingTime && (
            <div className="liveTest-timer">{liveTestRemainingTime}</div>
          )}
        <div className="liveTestBtnsWrap">
          {liveTest && liveTestStatusObj?.status === "Result Published" && (
            <div className="course-card-resume-cta reAButton">
              {reattemptBtn}
            </div>
          )}
          {actionButton && (
            <div
              className={`course-card-action ${liveTestStatusObj?.buttonClass}`}
            >
              {liveTest && liveTestStatusObj?.status === "Start Awaited" ? (
                <div
                  className={"liveTestTollTipText"}
                >{`This test will be enabled after ${getStateUpdateTime(
                  liveTestResultTime
                )}.`}</div>
              ) : (
                ""
              )}
              {actionButton}
            </div>
          )}
        </div>
        {liveTest && liveTestStatusObj?.status === "Scheduled On" && (
          <div className="liveTest-DateTime">{`${liveTestSchedule?.startDate} | ${liveTestSchedule?.startsAt}`}</div>
        )}
      </div>
    </div>
  );
};

export default CourseCard;

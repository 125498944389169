import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { productKeys } from "../../../constants/appConstants";
import {
  getDeviceType,
  getMoengageMetadata,
  getPdpUrl,
  getUrlPath,
  getUrlPathObject,
  isEmpty,
  saveUserSearchAnalyticsData,
} from "../../data/utils/helpers";
import { pageName, searchUrlEndPoint } from "../../../constants/appConfig";
import QuickViewProductCard from "../home/QuickViewProductCard";
import CustomLoader from "../library/CustomLoader";
import SearchNotFound from "./SearchNotFound";
import { checkIsPackageWishList, useWishListedPackageIds } from "../utils";

const SearchBoxContainer = ({
  onClick,
  keyWord,
  activeTab,
  setActiveTab,
  tabList,
  searchResults,
  showSearchingLoader,
  primaryFacetsRequiredCb,
}) => {
  const history = useHistory();
  const [quickViewPopup, setQuickViewPopup] = useState(false);
  const { hoveredPackageInfos = {} } = useSelector((state) => state.header);
  const [wishListPackageIds] = useWishListedPackageIds();

  const getResultContainer = (item, i) => {
    if (getDeviceType() === "m" || getDeviceType() === "s") {
      let categories = item["categories"] || "";
      categories = categories.toLowerCase();

      const category = productKeys[categories] || "";
      const id = item.id || "";
      const title = item.cardtitle || item.title || "";
      const slugurl = item.slugURL || "";
      const pdpurl = getPdpUrl(category, id, title, slugurl);
      const isWishList = checkIsPackageWishList(wishListPackageIds, item?.id);
      return (
        <div className="filterListCards" key={item.id}>
          <QuickViewProductCard
            index={i}
            id={item.id}
            // onClick={() => props.handleDetailCardClick(item)}
            cardtitle={item.title}
            slugURL={item.slugURL}
            src={item.imgUrl}
            datasrc={item.imageUrl}
            categoriesincluded={
              !isEmpty(item.subCategories)
                ? item.subCategories.map((e) => e.category)
                : []
            }
            sellingprice={item.price}
            maximumretailprice={item.maxPrice}
            categoryname={categories}
            coupons={{
              coupons: item.coupons,
              defaultCoupons: item.defaultCoupons,
            }}
            item={item}
            setQuickViewPopup={(flag) => {
              setQuickViewPopup(flag);
            }}
            pdpUrl={pdpurl}
            productDetailInfo={
              hoveredPackageInfos[item.id] &&
              hoveredPackageInfos[item.id].packageEsEntity[0]
            }
            isProductInfoExist={hoveredPackageInfos.hasOwnProperty(item.id)}
            source_screen="Searchbar"
            isWishList={isWishList}
          />
        </div>
      );
    } else {
      return (
        <li
          key={i}
          onClick={() => {
            history.push(
              getUrlPathObject(
                getPdpUrl(item.categories, item.id, item.title, item.slugURL)
              ),
              { source_screen: "Searchbar" }
            );
            // save user analytics trending search call
            saveUserSearchAnalyticsData({
              url: `${searchUrlEndPoint}/v2/trending/searches`,
              data: item,
              searchTerm: keyWord,
            });
          }}
          title={item.title}
        >
          <span>{item.title}</span>
        </li>
      );
    }
  };
  const redirectViewAll = (keyWord, categoryFacet) => {
    let facets =
      categoryFacet === "ALL"
        ? "TEST_SERIES,BOOKS,EBOOKS,VIDEOS,ONLINE_LIVE_CLASSES,MODELS_3D"
        : categoryFacet;
    let payload = {
      source: "web",
      brand: pageName[1],
      term: keyWord,
      action: "search_hard_hit_made",
    };
    if (typeof Moengage !== "undefined")
      Moengage.track_event("search", getMoengageMetadata(payload));
    window.open(
      getUrlPath(
        `/search`,
        `categoryFacets=${facets}&searchTerm=${keyWord}&primaryFilter=true`
      ),
      "_self"
    );
  };
  const getActionLabel = (activeTab) => {
    return tabList?.find((tab) => tab.category === activeTab)?.label === "ALL"
      ? "Products"
      : tabList?.find((tab) => tab.category === activeTab)?.label;
  };
  return (
    <div className="searchbox-web">
      {/* Suggested list of search result. */}
      <div className="searchbox-tabbox overlap.left-side-trending ul -screen">
        {tabList?.length > 0 && (
          <div
            className="sarchbar-tab"
            // style={{ pointerEvents: showSearchingLoader ? "none" : "" }}
          >
            <ul>
              {tabList.map((item, index) => (
                <li
                  id="tabNode"
                  key={index}
                  className={`${item.category === activeTab ? "active" : ""}`}
                  onClick={(e) => {
                    setActiveTab(item.category);
                    primaryFacetsRequiredCb(e);
                  }}
                  title={item.label}
                >
                  <span>{item.label}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {showSearchingLoader ? (
        <CustomLoader />
      ) : searchResults?.count ? (
        <ul className="searchbar-list">
          {searchResults?.packages?.map((item, index) =>
            getResultContainer(item, index)
          )}

          <div
            onClick={() => {
              redirectViewAll(keyWord, activeTab);
            }}
            title="View All"
            className="view-suggestion-btn"
          >
            <a title={`View All ${getActionLabel(activeTab)}`}>
              View All {getActionLabel(activeTab)}
            </a>
          </div>
        </ul>
      ) : (
        searchResults?.count == 0 && (
          <SearchNotFound onClick={onClick} searchKeywordText={keyWord} />
        )
      )}
    </div>
  );
};

export default SearchBoxContainer;

const Pluralize  = (value,itemText)=>{

    switch(+value){
        case 0:
        case NaN: return '';
        case 1: return `${value} ${itemText}`;
        default: return `${value} ${itemText}s`;

    }
}
export default Pluralize;
import React from "react";

export const SectionToolTipContent = ({
  responseStatusArray,
  sectionInfoObj,
  countQuestionsByStatus
}
) => {
  let currentResponseStatusList = responseStatusArray.slice(
    sectionInfoObj.initIndex,
    sectionInfoObj.initIndex + sectionInfoObj.sectionInfo.secTotalq
  );
  return (
    <div
    className='section-tooltip-container'
     
    >
      <div
      className='section-tooltip-header'
     
      >
        {sectionInfoObj.sectionInfo.secDN}
      </div>
      <div className="tcq-answered">
      <div>
        Answered
        </div>
        <div className="circle green">
          {countQuestionsByStatus(currentResponseStatusList, 2)}
        </div>
        
      </div>
      <div className="tcq-answered">
      <div>
        Not Answered
        </div>
        <div className="circle red">
          {countQuestionsByStatus(currentResponseStatusList, 1)}
        </div>
      
      </div>
    
      <div className="tcq-answered">
      <div>
        Marked For Review
        </div>
        <div className="circle purple">
          {countQuestionsByStatus(currentResponseStatusList, 3)+countQuestionsByStatus(currentResponseStatusList, 4)}
        </div>
     
      </div>
      <div className="tcq-answered">
      <div>  Not Visited</div>
        <div className="circle grey">
          {countQuestionsByStatus(currentResponseStatusList, 0)}
        </div>
      
      
      </div>
      <div className="tcq-answered">
      <div>  Answered and Marked for Review</div>
        <div className="circle purple-green">
          {countQuestionsByStatus(currentResponseStatusList, 4)}
        </div>
      </div>
    </div>
  );
};

export default SectionToolTipContent;

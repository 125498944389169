import React, { useEffect, useState } from "react";
import fetch from "../data/utils/fetch";
// import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import BuyNowComponent from "../components/BuyNowComponent";
import CustomButton from "../components/library/CustomButton";
import CheckoutModal from "../components/CheckoutModal";
import ApplyCouponModal from "../components/ApplyCouponModal";
import { pdpUrls, cartCheckoutUrls, iFrameUrl, pageName } from "../../constants/appConfig";
import BreadCrumb from "../components/library/BreadCrumb";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import SectionComponent from "../components/library/SectionComponent/SectionComponent";
import CatalogCard from "../components/CatalogCard";
import {
  AppliedCouponInfo,
  PriceComponent,
  ViewOffers
} from "../components/ProductDetailsSubComponents";
import Pluralize from "../components/library/Pluralize";
import Ainput from "../components/library/AInput";
import { isEmailValid, getPinCodeInfo, getPdpUrl, getUrlPathObject, getMoengageMetadata, checkLoginStatus } from "../data/utils/helpers";
import { setCartCountFromCookie } from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import { connect } from "react-redux";
import { updateUserAddress } from "../data/ducks/productDetails/actions";
import CartActionModal from "../components/CartActionModal";
// import CheckoutModal from "../components/CheckoutModal";
import CustomLoader from "../components/library/CustomLoader";
// import BrowseByProductType from "../components/home/BrowseByProductType"
import EmptyCart from "../components/cart/EmptyCart";
import { overlayVariant } from "../../constants/appConstants";
let r_count=0;
const CartCheckout = props => {
  const [applyOffers, setApplyOffers] = useState(false);
  const [viewOffer, setViewOffer] = useState(true);
  const [checkoutData, setCheckoutData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [cartData, setCartData] = useState();
  const [fromapp, setFromapp] = useState(false);
  const [cartDataInfo, setCartDataInfo] = useState({
    cartPayload: {},
    basePrice: 0,
    payablePrice: 0,
    isShippable: false,
    couponList: [],
    unPublishedCartPayload: ''
  });
  const [priceDetails, setPriceDetails] = useState({
    basePrice: undefined,
    discount: undefined,
    payablePrice: undefined,
    appliedCoupon: ""
  });
  const [onPageCouponValue, setOnPageCouponValue] = useState();
  // Form Validation
  const [loading, setLoading] = useState(false);
  let initialState = {};
  const [userDetails, setUserDetails] = useState(initialState);
  const [inValidState, setInValidaState] = useState({
    phone: false,
    name: false,
    email: false
  });
  const [applyingWithoutEmail, setApplyingWithoutEmail] = useState(false);
  const [applying, setApplying] = useState(false);
  const isEmpty = value => {
    value = value || "";
    return !value || !(value.trim && value.trim());
  };

  function getParameterByName(name, url) {
    if (!url) url = typeof window != undefined && window.location.href;;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  useEffect(() => {
    let fromapp = getParameterByName("from_app");
    if (fromapp) {


      setFromapp(true);
      sessionStorage.setItem("from_app", "1");
    }
    else {
      setFromapp(false);
    }
  })
  const isPhone = value => {
    return !isEmpty(value) && value.length == 10;
  };
  const isPinCode = value => {
    return !isEmpty(value) && value.length == 6;
  };
  // END

  // const [cartData,setCartData] = useState()

  // Apply coupon Locally
  useEffect(() => {
    getPinCodeInfo(userDetails.pincode, pincodeData => {
      if (pincodeData) {
        setUserDetails({
          ...userDetails,
          state: pincodeData.State,
          city: pincodeData.City
        });
      }
    });
  }, [userDetails.pincode]);
  useEffect(() => {
    if (priceDetails.appliedCoupon !== onPageCouponValue) {
      setOnPageCouponValue(priceDetails.appliedCoupon); // set the value of  coupon on checkout page to the value that was updated on modal;
    }
  }, [priceDetails.appliedCoupon]);


  useEffect(() => {
    r_count=0;
    fetch(`${pdpUrls.csrfToken}`, "GET")
      .then(res => {
        props.cookies.set('csrf_token', res.data);
      })
      .catch(() => { });
  }, []);

  //Moe event value setup
  const createMoengageObjectFromArray = function (dataArr) {
    var dataObj = {};
    var keysArr = Object.keys(dataArr[0]);

    dataArr.forEach(function (parentEl) {
      keysArr.forEach(function (key) {
        if (!dataObj[key])
          dataObj[key] = parentEl[key];
        else
          dataObj[key] += ', ' + parentEl[key];
      });
    });
    return dataObj;
  }


  const moePayload = (data,value) => {
    var cartItem = [];
    var count = 0;
    var transactionAmt = 0;
    var transaction_value = 0;
    data.forEach(elem => {
      cartItem.push({
        packageActualPrice: elem.maximumRetailPrice,
        packageDiscountedPrice: elem.sellingPrice,
        category: elem.categories,
        id: elem.id,
        title: elem.title, //package title and package name
        package_url: typeof window != undefined && window.location.origin + getPdpUrl(elem.categories[0], elem.id, elem.title),
        // getPdpUrl = (category,packageId,title, slugurl)
      });
      count++;
      transaction_value += elem.sellingPrice;
    })
    transactionAmt = priceDetails.payablePrice != undefined ? priceDetails.payablePrice : cartDataInfo.payablePrice;

    let moepayload = createMoengageObjectFromArray(cartItem);
    moepayload.total_final_amount = transactionAmt ? transactionAmt : transactionAmt==0?  "0" : transaction_value;
  
      moepayload.cart_count = count;
   
 
    moepayload.page_title = "ADD247 / My CartPage"
    moepayload.package_status = "Paid";

    moepayload.user_email = userDetails && userDetails.email;
    moepayload.user_name = userDetails.name;
    moepayload.getUtmFromStore = props.utmParams;
    moepayload.is_cart_true = true;
    moepayload.user_mobile_no = userDetails.phone;
    moepayload.source_path = props.from_app || "";

    return moepayload;

  }

  const applyCoupon = couponCode => {
    const isLogined = checkLoginStatus();
    const url = isLogined ? pdpUrls.applyCart : pdpUrls.applyCartGuest
    if (couponCode != "") {
      let payload = moePayload(cartData);
      payload.couponCode = couponCode;
      payload.total_final_amount = "";
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("coupon_code_applyButton_clicked", getMoengageMetadata(payload));

    }
    else {
      setOnPageCouponValue("")
      let payload = moePayload(cartData);
      payload.couponCode = priceDetails.appliedCoupon;
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("coupon_code_removed", getMoengageMetadata(payload));
      r_count=0;
    }
    if (!isEmailValid(userDetails.email)) {
      // email not entered when user is not logged in
      setApplyingWithoutEmail(true);
      return;
    }
    setApplyingWithoutEmail(false);
    if (applying) return;
    // if (couponCode == "") {
    //   setApplying("Removing Coupon");
    // } else {
    //   setApplying("Applying Coupon");
    // }

    let payload = {
      cartJson: cartDataInfo.cartPayload,
      couponCode: couponCode
    };
    setApplying(true);
    fetch(
      `${url}?user-email=${userDetails.email}`,
      "POST",
      payload
    ).then(
      res => {
        let defaultValue = {
          basePrice: undefined,
          discount: undefined,
          payablePrice: undefined
        };
        setApplying(false);
        if (res.data) {
          let data = res.data;



          // setEnteredCouponCode(couponCode);
          if (res.data.discount !== 0) {
            setPriceDetails({
              // basePrice: data.basePrice,
              discount: data.discount,
              payablePrice: data.payablePrice,
              appliedCoupon: couponCode
            });
            // Close Modal
            // close();
          }
          else {
            setPriceDetails({ appliedCoupon: couponCode, ...defaultValue });
          }
        } else {
          setPriceDetails({ appliedCoupon: couponCode, ...defaultValue });
        }
      },
      err => {
        setApplying(false);
      }
    );
  };
  //// END-Local coupon Application
  const updateCartInfoFromResponse = (data, couponList, isPurchasable, isdeleteSection) => {
    // data should be type of array
    // use onlu publishedCart
    setCartDataInfo(
      data.reduce(
        (acc, cartItem, index) => {
          // let cartObjString = ""
          if (cartItem.purchasable || isPurchasable) {
            acc.cartPayload.push(`\"${cartItem.id}\":1`);
            // if (index === res.data.length - 1) {
            //   acc.cartPayload = `{${acc.cartPayload.join(",")}}`;
            // }
            // acc.cartPayload[`\"${cartItem.id}\"`] = cartItem.quantity|| 1;
            acc.basePrice += cartItem.maximumRetailPrice || 0;
            acc.payablePrice += cartItem.sellingPrice || 0;
            acc.isShippable = acc.isShippable || cartItem.shippable;
            if (cartItem.coupons)
              acc.couponList = acc.couponList.concat(cartItem.coupons);
          }
          else {
            acc.unPublishedCartPayload.push(cartItem.id)
          }
          if (index == data.length - 1) {
            acc.cartPayload = acc.cartPayload.length > 0 ? `{${acc.cartPayload.join(",")}}` : '';
            // acc.unPublishedCartPayload = acc.unPublishedCartPayload.length>0?`{${acc.unPublishedCartPayload.join(",")}}`:'';
          }
          return acc;
        },
        {
          cartPayload: [],
          unPublishedCartPayload: [],
          basePrice: 0,
          payablePrice: 0,
          isShippable: false,
          couponList: couponList || []
        }
      )
    )
    if (!isdeleteSection) {
      let payload = moePayload(data);
      payload.total_final_amount = "";
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("myCart/Checkout", getMoengageMetadata(payload));
    }


  }
  const removeAll = () => {

    let unpublishedIds = cartDataInfo.unPublishedCartPayload;
    deleteItemFromCart(unpublishedIds);
  }
  const updateUserInfo = () => {
    // User Logged In
    let userInfo = {};
    if (props.userDetails && props.userDetails.email) {
      userInfo.email = props.userDetails.email;
      userInfo.name = props.userDetails.name;
      setUserDetails({ ...userDetails, ...userInfo });
    }
  };
  useEffect(() => {
    updateUserInfo();

    /*
    Case 1 : Checkout By ID
      Case 1.1 : Logged In
      Case 1.2  : Logged Out
    Case 2 : Cart Checkout 
      Case 2.1 : Logged In
      Case 2.2 : Logged Out 
    */
    const urlParams = new URLSearchParams(window.location.search);
    const packageIds = urlParams.get("packageIDs");
    setPageLoading(true);
    if (packageIds) {
      fetch(`${cartCheckoutUrls.packageById}?ids=${packageIds}`, "GET").then(
        res => {
          setPageLoading(false);
          if (res.data && res.data.length > 0) {
            let result = res.data;

            result[0].purchasable = true;
            setCartData(result);

            updateCartInfoFromResponse(res.data, [], true, false);
            // setCartDataInfo(
            //   res.data.reduce( 
            //     (acc, cartItem, index) => {
            //       // let cartObjString = ""
            //       acc.cartPayload.push(`\"${cartItem.id}\":1`);
            //       if (index === res.data.length - 1) {
            //         acc.cartPayload = `{${acc.cartPayload.join(",")}}`;
            //       }
            //       // acc.cartPayload[`\"${cartItem.id}\"`] = cartItem.quantity|| 1;
            //       acc.basePrice += cartItem.maximumRetailPrice || 0;
            //       acc.payablePrice += cartItem.sellingPrice || 0;
            //       acc.isShippable = acc.isShippable || cartItem.shippable;
            //       if (cartItem.coupons)
            //         acc.couponList = acc.couponList.concat(cartItem.coupons);
            //       return acc;
            //     },
            //     {
            //       cartPayload: [],
            //       basePrice: 0,
            //       payablePrice: 0,
            //       isShippable: false,
            //       couponList: []
            //     }
            //   )
            // );
          }
        },
        err => {
          setPageLoading(false);
        }
      );
    } else {
      if (props.userDetails && props.userDetails.email) {
        fetch(cartCheckoutUrls.cart, "GET").then(
          res => {
            setPageLoading(false);
            let data = res.data.carts;
            setCartData(data);
            updateCartInfoFromResponse(data, res.data.couponDto || [], false);
          },
          err => {
            setPageLoading(false);
          }
        );
      } else {
        setPageLoading(false);
      }
    }
  }, [props.userDetails && props.userDetails.email]);
  const goTo = (pathName) => {
    props.history.push(getUrlPathObject(pathName));
  }
  const proceedToCheckout = () => {
    // Check validation of Form fields


    // Tracking the user activity when the user try to purchase item from the outside adda like ssc and many more
    //buynow_widget_click moe event
    let moedata = moePayload(cartData);
    if(!props.from_app)
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("continue_btn_clicked", getMoengageMetadata(moedata));

    if (props.from_app) {
      let payload = moePayload(cartData);
      payload.source_path = props.from_app;
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("buynow_widget_click", getMoengageMetadata(payload));
    }
    localStorage.setItem('phone', userDetails.phone);
    let invalidState = {};
    if (cartDataInfo.isShippable) {
      invalidState = {
        phone: !isPhone(userDetails.phone),
        name: isEmpty(userDetails.name),
        email: !isEmailValid(userDetails.email),
        pincode: !isPinCode(userDetails.pincode),
        firstLine: isEmpty(userDetails.firstLine)
      };
    } else {
      invalidState = {
        phone: !isPhone(userDetails.phone),
        name: isEmpty(userDetails.name),
        email: !isEmailValid(userDetails.email)
      };
    }
    setInValidaState(invalidState);
    if (Object.values(invalidState).find(item => item)) {
      return;
    }

    // if shipable then save address and send addressId in checkout
    if (cartDataInfo.isShippable) {
      props.updateUserAddress(userDetails).then(data => {
        data[0].then(resData => {
          setLoading(false);
          let updatedcheckoutData = {
            email: userDetails.email,
            cartJson: cartDataInfo.cartPayload, // props.productInfo.packageEsEntity[0].id,
            phone: userDetails.phone,
            name: userDetails.name,
            couponCode: checkoutData.appliedCoupon
          };
          // props.updateCheckoutData({
          //   email: userDetails.email,
          //   cartJson: props.cartJson,
          //   phone: userDetails.phone,
          //   name: userDetails.name,
          //   couponCode: props.checkoutData.appliedCoupon
          // });
          if (resData.success) {
            if (cartDataInfo.isShippable) {
              updatedcheckoutData.addressId = resData.data.id;
            }

            setCheckoutData({ ...updatedcheckoutData });
            window.location.hash = "checkout/payment";
          } else {
            setCheckoutData({ ...updatedcheckoutData });
          }
        });
      });
    } else {
      setCheckoutData({
        email: userDetails.email,
        cartJson: cartDataInfo.cartPayload,
        phone: userDetails.phone,
        name: userDetails.name,
        couponCode: checkoutData.appliedCoupon
      });
      window.location.hash = "checkout/payment";
    }
  };
  const deleteItemFromCart = (ids, cartvalue) => {
   let count= cartData && cartData.length
  };

  //Invalid coupon code moe

  if (onPageCouponValue && onPageCouponValue === priceDetails.appliedCoupon && priceDetails.discount === undefined && !applyOffers && r_count==0) {
    let payload = moePayload(cartData);
    payload.couponCode = priceDetails.appliedCoupon;
    payload.error = "Invalid Coupon";
    payload.total_final_amount = "";
    if (typeof (Moengage) !== 'undefined') Moengage.track_event("coupon_code_incorrect", getMoengageMetadata(payload));
    r_count=1;
  };
  // End invalid coupon code  moe

  const updateField = fieldName => {
    return event => {
      let value = event.target.value;
      let updatedUserDetails = { ...userDetails };
      if (fieldName == "phone") {
        value = value.substring(0, 10);
      }
      updatedUserDetails[fieldName] = value;
      setUserDetails(updatedUserDetails);
    };
  };
  // ProcessCartData
  if (!props.from_app &&  (!cartData || pageLoading)) {
    return (
      <div className="cart-container">
        <div className="cart-checkout">
          <CustomLoader />
        </div>
      </div>
    );
  }
  if(props.from_app &&  (!cartData || pageLoading))
  {
    return null;
  }
  if (cartData && cartData.length === 0) {
    return <EmptyCart title={'Cart'} header={'There are no products in your cart!'} subHeader={'Be ahead of the competition with our comprehensive study material'} 
    ProductTypeVisible={true}/>;
  }
  const routeToPdpPage = PackageInfo => {
    if (props.from_app) return;

    const categoryName = PackageInfo.categories && PackageInfo.categories[0] ? PackageInfo.categories[0] : ''
    const storeUrl = getPdpUrl(categoryName, PackageInfo.id, PackageInfo.title);
    props.history.push(getUrlPathObject(storeUrl));
  };
  const urlParams = new URLSearchParams(typeof window !="undefined" &&  window.location.search);
  const packageIds = urlParams.get("packageIDs");
  let packageId = packageIds ? packageIds.split(",")[0] : undefined;
  return (
    <div className="cart-container">
      <div className={`cart-checkout  ${props.from_app ? 'nopadding' : ''}`}>
        <CartActionModal goTo={goTo}
          cartData={cartData}
          cartDataInfo={cartDataInfo}
          Getpayload={moePayload(cartData)}
        />
        <ApplyCouponModal
          fromapp={fromapp}
          packageId={packageId}
          packageType={cartData && cartData[0].categories[0]}
          visible={applyOffers}
          priceDetails={priceDetails}
          productData={{
            maxPrice: cartDataInfo.basePrice,
            price: cartDataInfo.payablePrice
          }}
          Getpayload={moePayload(cartData)}
          updatePriceDetails={setPriceDetails}
          availableCouponList={cartDataInfo.couponList}
          loggedUserDetails={props.userDetails || {}}
          cartPayload={cartDataInfo.cartPayload}
          onCancel={() => setApplyOffers(false)}
          userEmail={userDetails.email}
          setUserEmail={value => {
            setUserDetails({ ...userDetails, email: value });
          }}
          setViewOffer={setViewOffer}
        ></ApplyCouponModal>
        <CheckoutModal
          Getpayload={moePayload(cartData)}
          priceDetails={priceDetails}
          cartJson={cartDataInfo.cartPayload}
          isShippable={cartDataInfo.isShippable}
          setCheckoutData={setCheckoutData}
          checkoutData={checkoutData}
          // userEmail={userEmail}
          // setUserEmail={setUserEmail}
          // Getpayload={Getpayload()}
          // Getpayload={Getpayload()}
          getUtmFromStore={props.utmParams}
        ></CheckoutModal>
        <div className="breadcrumb-section">
          <BreadcrumbSchema
            items={[
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Cart",
                url: ""
              }
            ]}
          />
          {
            !props.from_app ?
              <BreadCrumb
                routeData={[
                  { name: "Home", target: "/" },
                  { name: "Cart", target: "" }
                ]}
              />
              : null

          }

        </div>
        <div
          className="cart-section-list"
        // style={{
        //   // width: "1300px",
        //   display: "flex",
        //   flexDirection: "row"
        //   // justifyContent: "space-between",
        //   // height: "fit-content"
        // }}
        >
          <div className="cart-section">
            {cartDataInfo.cartPayload &&
              <SectionComponent
                header={!props.from_app ? `Cart (${Pluralize(cartData.length - cartDataInfo.unPublishedCartPayload.length, "Item")})` : ""}
                body={
                  <div className="cart-section-body">
                    {cartData.map(cartItem => {
                      if (!cartItem.purchasable) return null
                      return (
                        <CatalogCard
                          key={`cartItem-${cartItem.id}`}
                          src={cartItem.imageUrl}
                          variant={overlayVariant.overlayBg}
                          onImgClick={() => { routeToPdpPage(cartItem) }}
                          header={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                              }}
                            >
                              <span
                                className='cartitem-title'
                                onClick={() => {
                                  routeToPdpPage(cartItem);
                                }}
                              >
                                {cartItem.title}
                              </span>
                              {packageId ? (
                                ""
                              ) : (
                                  <span
                                    className="cartitem-delete-d"
                                    onClick={() => {
                                      deleteItemFromCart([cartItem.id], [cartItem]);
                                    }}
                                  >
                                    <img
                                      height="28px"
                                      width="24px"
                                      src="/images/delete.png"
                                      alt={pageName[0]}
                                      title={pageName[0]}
                                    ></img>
                                  </span>
                                )}
                            </div>
                          }
                          subHeader={`Validity: ${cartItem.validity}`}
                          text={
                            <div className="cartitem-price">
                              {PriceComponent(
                                cartItem.maximumRetailPrice,
                                cartItem.sellingPrice
                              )}
                              <div
                                className={`cartitem-delete-m ${props.from_app ? 'noicon' : ''}`}
                                onClick={() => {
                                  deleteItemFromCart([cartItem.id], [cartItem]);
                                }}
                              >
                                <img
                                  height="28px"
                                  width="24px"
                                  src="/images/delete.png"
                                  alt={pageName[0]}
                                  title={pageName[0]}
                                ></img>
                              </div>
                            </div>
                          }
                        ></CatalogCard>
                      );
                    })}
                  </div>
                }
              ></SectionComponent>
            }
            {
              cartDataInfo.unPublishedCartPayload.length > 0 && <SectionComponent
                header={<div
                  style={
                    {
                      display: 'flex',
                      justifyContent: 'space-between'
                    }
                  }
                ><div>You can't buy the below products</div><div

                  className='remove-all'
                  onClick={removeAll}

                > Remove All  </div></div>}
                body={
                  <div className="cart-section-body unpublished-items">
                    {cartData.map(cartItem => {
                      if (cartItem.purchasable) return null
                      return (
                        <CatalogCard
                          key={`cartItem-${cartItem.id}`}
                          src={cartItem.imageUrl}
                          variant={overlayVariant.overlayBg}
                          iconClick='1234'
                          // onImgClick={()=>{routeToPdpPage(cartItem)}}
                          header={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                              }}
                            >
                              <span
                                className='cartitem-title'
                              // onClick={() => {
                              //   routeToPdpPage(cartItem);
                              // }}
                              >
                                {cartItem.title}
                              </span>
                              {/* {packageId ? (
                              ""
                            ) : (
                              <span
                                className="cartitem-delete-d"
                                onClick={() => {
                                   deleteItemFromCart([cartItem.id],[cartItem]);
                                }}
                              >
                                <img
                                  height="28px"
                                  width="24px"
                                  src="/images/delete.png"
                                ></img>
                              </span>
                            )} */}
                            </div>
                          }
                          // subHeader={}
                          text={
                            <span className="cartitem-discontinued">
                              Item Discontinued
                          </span>
                          }
                        ></CatalogCard>
                      );
                    })}
                  </div>
                }
              ></SectionComponent>
            }
          </div>
          <div className="userdetails-section">
            {cartDataInfo.cartPayload &&
              <SectionComponent
                header={`User Details`}
                body={
                  <div className="checkout-userinfo">
                    <div>
                      <Ainput
                        type="text"
                        size="default"
                        value={userDetails.name}
                        onChange={updateField("name")}
                        error={inValidState.name && isEmpty(userDetails.name)}
                        placeholder="Full Name"
                        errorMessage="Full Name is Mandatory"
                        disabled={loading}
                      ></Ainput>
                    </div>
                    <div>
                      <Ainput
                        type="email"
                        size="default"
                        value={userDetails.email}
                        onChange={updateField("email")}
                        disabled={
                          loading ||
                          !!(props.userDetails && props.userDetails.email)
                        }
                        error={
                          (inValidState.email &&
                            !isEmailValid(userDetails.email)) ||
                          applyingWithoutEmail
                        }
                        placeholder="Email"
                        errorMessage={`Email is Mandatory ${applyingWithoutEmail &&
                          "for using offers"}`}
                      // disabled={loading}
                      ></Ainput>
                    </div>
                    <div>
                      <Ainput
                        type="number"
                        size="default"
                        value={userDetails.phone}
                        onChange={updateField("phone")}
                        error={inValidState.phone && !isPhone(userDetails.phone)}
                        placeholder="Phone Number"
                        errorMessage="Enter a Valid Mobile Number"
                        disabled={loading}
                        maxlength="10"
                      ></Ainput>
                    </div>

                    {cartDataInfo.isShippable && (
                      <>
                        <div>
                          <Ainput
                            type="number"
                            size="default"
                            value={userDetails.pincode}
                            maxlength={"6"}
                            onChange={updateField("pincode")}
                            error={
                              inValidState.pincode &&
                              !isPinCode(userDetails.pincode)
                            }
                            placeholder="Pin Code"
                            errorMessage="Please enter a valid pincode"
                            disabled={loading}
                          ></Ainput>
                        </div>
                        <div>
                          <Ainput
                            value={userDetails.firstLine}
                            onChange={updateField("firstLine")}
                            disabled={loading}
                            type="text"
                            size="default"
                            error={
                              inValidState.firstLine &&
                              isEmpty(userDetails.firstLine)
                            }
                            errorMessage="Let us know your address"
                            placeholder="Flat, House No., Building,Company"
                          ></Ainput>
                        </div>
                        <div>
                          <Ainput
                            type="text"
                            disabled={loading}
                            size="default"
                            value={userDetails.secondLine}
                            onChange={updateField("secondLine")}
                            placeholder="Area,Colony,Street,Sector,Village"
                          ></Ainput>
                        </div>
                        <div>
                          <Ainput
                            type="text"
                            disabled={loading}
                            size="default"
                            value={userDetails.landmark}
                            onChange={updateField("landmark")}
                            placeholder="Landmark"
                          ></Ainput>
                        </div>
                        <div>
                          <Ainput
                            value={userDetails.city}
                            disabled={loading}
                            onChange={updateField("city")}
                            type="text"
                            size="default"
                            placeholder="City"
                          ></Ainput>
                        </div>
                        <div>
                          {/* state */}
                          <Ainput
                            type="text"
                            disabled={loading}
                            size="default"
                            value={userDetails.state}
                            onChange={updateField("state")}
                            placeholder="State"
                          ></Ainput>
                        </div>
                      </>
                    )}
                    <div className="checkout-coupon-bg">
                      <div className=" coupon-input">
                        <Ainput
                          className="coupon-input-box"
                          placeholder="Enter Coupon Code"
                          onChange={e => {
                            setOnPageCouponValue(e.target.value);
                          }}
                          value={onPageCouponValue || priceDetails.appliedCoupon}
                          onKeyUp={e => {
                            if (e.keyCode == 13) {
                              applyCoupon(onPageCouponValue);
                            }
                          }}
                        ></Ainput>
                        {(onPageCouponValue || priceDetails.appliedCoupon) &&
                          priceDetails.appliedCoupon == onPageCouponValue ? (
                            <button
                              className="coupon-list-apply coupon-input-submit"
                              onClick={() => {
                                applyCoupon("");
                              }}
                            >
                              REMOVE
                            </button>
                          ) : (
                            <button
                              className="coupon-list-apply coupon-input-submit"
                              onClick={() => {
                                if (onPageCouponValue != "") {
                                  applyCoupon(onPageCouponValue);
                                }
                              }}
                            >
                              APPLY
                            </button>
                          )}

                      </div>

                    </div>
                    <div className='coupon-invalid-text'>{
                      onPageCouponValue && onPageCouponValue === priceDetails.appliedCoupon && priceDetails.discount === undefined ? 'Invalid Coupon' : ''}</div>
                    <div className="buynow-section">
                      <div className="priceSection">
                        {PriceComponent(
                          cartDataInfo.basePrice,
                          priceDetails.payablePrice != undefined ? priceDetails.payablePrice : cartDataInfo.payablePrice
                        )}

                        <AppliedCouponInfo
                          appliedCoupon={priceDetails.appliedCoupon}
                          couponDiscount={priceDetails.discount}
                          Getpayload={moePayload(cartData)}
                          payablePrice={priceDetails.payablePrice}
                        />
                        <div className="viewoffer">
                          {<ViewOffers
                            moedata={moePayload(cartData)}
                            viewOffers={setApplyOffers} />}
                        </div>
                      </div>
                      <CustomButton
                        buttontext="BUY NOW"
                        onClick={
                          proceedToCheckout
                          //   ()=>{
                          //   window.location.hash='#checkout/payment'
                          // }
                        }
                      ></CustomButton>
                      {/* <BuyNowComponent
                      productId={props.match.params.id}
                      isPurchased={false}
                      // Getpayload={Getpayload()}
                    /> */}
                    </div>
                    <div className="checkout-userinfo-tnc">
                      By Safe and secure payments. 100% Authentic products.{" "}
                      <a href={`${pdpUrls.store}/term-conditions`} title='term-conditions'>T&C</a>
                    </div>
                  </div>
                }
              ></SectionComponent>}
          </div>
        </div>
        {/* <div className="cart-content">
          <div className="cart-content-header">Cart </div>
          {cartData.map(cartItem => {
            return (
              <div
                className="cart-item-list"
                // style={{ display: "flex", flexDirection: "row" }}
                key={cartItem.id}
              >
                <div>
                  <img src={cartItem.imageUrl} height="132px" width="132px" />
                </div>
                <div
                  className="cart-item-content"
                  //   style={{ display: "flex", flexDirection: "column",justifyContent:'space-between',flexGrow:'1' }}
                >
                  <div
                    className="cart-item-header"
                    // style={{ display: "flex", flexDirection: "row",justifyContent:'space-between' ,flexGrow:'1'  }}
                  >
                    <div>{cartItem.title}</div>
                    <div>
                      <span className="mrp">
                        {" "}
                        &#8377;{cartItem.maximumRetailPrice}
                      </span>{" "}
                      <span className="sp">
                        {" "}
                        &#8377; {cartItem.sellingPrice}
                      </span>
                    </div>
                  </div>
                  <div
                    className="cart-item-subheader"
                    // style={{ display: "flex", flexDirection: "row",justifyContent:'space-between' }}
                  >
                    <div>{cartItem.validity}</div>
                    <div>Remove</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="cart-price"
          //    style={{ display: "flex", flexDirection: "column" ,flexGrow:1 ,justifyContent:'space-between' }}
        >
          <div className="price-details-section">
            <div className="price-details-heading">Price Details</div>
            <div className="price-details-info">
              <div>Sub - Total</div>
              <div>50000</div>
            </div>
            <div className="price-details-info">
              <div>Discount</div>
              <div>50000</div>
            </div>
            <div className="price-details-info">
              <div>Amount Payable</div>
              <div>0</div>
            </div>
          </div>

          <CheckoutModal
            cartJson={`{123:1}`}
            isShippable={cartDataInfo.isShippable}
            priceDetails={{ appliedCoupon: "SUB20" }}
          />
          <ApplyCouponModal
            packageId={props.match.params.packageId}
            packageType={cartData && cartData[0].categories}
            visible={applyOffers}
            priceDetails={priceDetails}
            productData={{}}
            updatePriceDetails={setPriceDetails}
            availableCouponList={availableCouponList}
            cartPayload={cartDataInfo.cartPayload}
            onCancel={() => setApplyOffers(false)}
            getUtmFromStore={props.utmParams}
          ></ApplyCouponModal>
          <div className="cart-checkout">
            <div
              onClick={() => {
                setApplyOffers(true);
              }}
            >
              View Offers
            </div>
            <CustomButton
              buttontext="Checkout"
              onClick={() => {
                window.location.hash = "checkout/userDetails";
              }}
            ></CustomButton>
          </div>
        </div>
       */}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  // const { productDetails } = state;
  let utmParams = state.referrer || {};
  return {
    //   userAddress: productDetails.userAddress,
    userDetails: state.header.userInfo,
    utmParams,
    from_app: state.from_app
    //   productInfo: productDetails.data
  };
};

const mapDispatchToProps = {
  updateUserAddress,
  setCartCountFromCookie
};
// const mapDispatchToProps = dispatch => {
//   return {
//     updateUserAddress: addressObj => dispatch(updateUserAddress(addressObj)),
//     setCartCountFromCookie
//   };
// };

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(CartCheckout)
);


import React, { useState, useEffect } from "react";
import {
  pdpUrls,
  cartCheckoutUrls,
  videoPackageUrl,
  routeToAppUrl,
  routeToAppLinkUrl,
  storeItemsUrlMapping,
  pageName
} from "../../constants/appConfig";
import fetch from "../data/utils/fetch";
import CatalogCard from "../components/CatalogCard";
import CustomButton from "./library/CustomButton";
import CustomLoader from "./library/CustomLoader";
import { Cookies } from "react-cookie";
import { checkLoginStatus, getDeviceType, getMoengageMetadata, openWidget, trackMoengageEvent } from "../data/utils/helpers";
import { setCartCountFromCookie } from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import { connect } from "react-redux";
import { getParameterByName  } from "../../app/components/utils";
import { default as Link } from "../containers/ConnectedLink";
import CustomModal from "./library/CustomModal";
import { useHistory } from "react-router";
import { Redirect } from 'react-router-dom';
const cookie = new Cookies();
const packageCategoryData = [
  {
    title: "START WITH LIVE CLASS",
    name: "ONLINE_LIVE_CLASSES",
    imgPath: "/images/oder-list-icon1.svg",
    url: "/my-live-class",
  },
  {
    title: "START WITH VIDEOS",
    name: "VIDEOS",
    imgPath: "/images/oder-list-icon2.svg",
    url: "/my-videos",
  },
  {
    title: "START WITH QUIZ",
    name: "TEST_SERIES",
    imgPath: "/images/oder-list-icon3.svg",
    url: "/my-mock-test",
  },
  {
    title: "START WITH E-BOOKS",
    name: "EBOOKS",
    imgPath: "/images/oder-list-icon4.svg",
    url: "/my-ebooks",
  },
];

const PaymentSuccess = ({
  orderId,
  email,
  userInfo,
  Getpayload,
  cartDataInfo,
  isLoggedIn,
  setViewOfferModalOnly,
  viewOffers,
  ...props
}) => {
  // #cart/success/CP4349914/user1@dispostable.com

  const [orderedPackages, setOrderedPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [fromapp, setFromApp] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [categoryPackageData, setCategoryPackageData] = useState([]);
  const [orderPackageData, setOrderPackageData] = useState([]);

  const [nameUser, setNameUser] = useState(cookie.get("cp_user_name"));

  // let fromApp = 0;
  // if(typeof(sessionStorage)!=="undefined" && sessionStorage.getItem("from_app")==1){
  //   fromApp = 1;
  // }

  let history = useHistory();

  const handleSeoEvents = () => {
    if (orderedPackages.length > 0 && orderData) {
      let productListForDataLayer = [];
      for (var i = 0; i < orderedPackages.length; i++) {
        var category = [];
        if (orderedPackages[i].categoryIds.includes(1)) {
          orderedPackages.hasTestSeries = true;
          category.push("Test Series");
        }
        if (orderedPackages[i].categoryIds.includes(2)) {
          orderedPackages[i].hasBooks = true;
          category.push("Books");
        }
        if (orderedPackages[i].categoryIds.includes(3)) {
          orderedPackages[i].hasMags = true;
          category.push("Mags");
        }
        if (orderedPackages[i].categoryIds.includes(4)) {
          orderedPackages[i].haseBooks = true;
          category.push("E Books");
        }
        if (orderedPackages[i].categoryIds.includes(5)) {
          orderedPackages[i].hasVideos = true;
          category.push("Videos");
        }
        if (orderedPackages[i].categoryIds.includes(6)) {
          orderedPackages[i].hasLiveClass = true;
          category.push("Live Class");
        }
  
        productListForDataLayer.push({
          name: orderedPackages[i].title,
          id: orderedPackages[i].id,
          price: orderedPackages[i].sellingPrice,
          brand: pageName[1],
          category: category.join(", "),
          variant: "-",
          quantity: 1,
        });
      }
  
      let payload = Getpayload;
      const user_id_cookie = cookie.get("cp_user_id");
  
      let name = cookie.get("cp_user_name");
      let email = cookie.get("cp_user_email");
      const token = cookie.get("cp_token");
  
      if (token && user_id_cookie && typeof window != "undefined") {
        // window.dataLayer.push({
        //   event:"conversion",
        //   ecommerce:
        //  {
        //     name: userInfo && userInfo.name || "guest",
        //   email: userInfo && userInfo.email || "",
        //   isMobile: getDeviceType() == 'm' ? true : false,
        //   loginType: 'email',
        //   isLoggedIn: true,
        //   pageName: 'Order Success',
        //   examName: payload && payload.exam,
        //   packageName: payload && payload.name,
        //   category: payload && payload.category,
        //   price: payload && payload.packageDiscountedPrice,
        //   packageShortId: payload && payload.id,
        //   brand: 'ADDA247',
        //   variant: "",
        //   quantity: orderedPackages && orderedPackages.length || 0,
        //   coupon: orderData && orderData.coupon || '',
        //   finalPackagePrice: orderData.amount || 0,
        //   isPaid: 'TRUE',
        //   status: "PAID",
        //   txnID: orderId || '',
        //   id: orderId || '',
        //   affiliation: 'Online',
        //   revenue: orderData.amount || 0,
        //   action: 'purchase',
        //   shipping: '',}
  
        // })
  
        window.dataLayer.push({
          status: "PAID",
          clickid : sessionStorage.getItem('utm_term'),
          isPaid: true,
          txnID: orderId || "",
          finalPackagePrice: orderData.amount || 0,
          coupon: (orderData && orderData.coupon) || "",
          email: email || "",
          name: name || "guest",
          loginType: "email",
          isLoggedIn: true,
          isMobile: getDeviceType() == "m" ? true : false,
          event: "conversion",
          ecommerce: {
            purchase: {
              actionField: {
                id: orderId || "",
                affiliation: "Online Store",
                revenue: orderData.amount || 0,
                tax: "",
                shipping: "",
                coupon: (orderData && orderData.coupon) || "",
                action: "purchase",
              },
              products: productListForDataLayer,
            },
          },
        });
      } else if (!user_id_cookie && !token && typeof window != "undefined") {
        // window.dataLayer.push({
        //   event:"conversion",
        //   ecommerce:
        //   {
        //   name: "guest",
        //   email: "",
        //   isMobile: getDeviceType() == 'm' ? true : false,
        //   loginType: '',
        //   isLoggedIn: false,
        //   examName: payload && payload.exam,
        //   pageName: 'Order Success',
        //   packageName: payload && payload.name,
        //   category: payload && payload.category,
        //   price: payload && payload.packageDiscountedPrice,
        //   packageShortId: payload && payload.id,
        //   brand: 'ADDA247',
        //   variant: "",
        //   quantity: orderedPackages && orderedPackages.length || 0,
        //   coupon: orderData && orderData.coupon || '',
        //   finalPackagePrice: orderData.amount || 0,
        //   isPaid: 'TRUE',
        //   status: "PAID",
        //   txnID: orderId || '',
        //   id: orderId || '',
        //   affiliation: 'Online',
        //   revenue: orderData.amount || 0,
        //   action: 'purchase',
        //   shipping: '',}
  
        // })
  
        let user_name = localStorage.getItem("name");
        let user_email = localStorage.getItem("email");
  
        window.dataLayer.push({
          status: "PAID",
          clickid : sessionStorage.getItem('utm_term'),
          isPaid: true,
          txnID: orderId || "",
          finalPackagePrice: orderData.amount || 0,
          coupon: (orderData && orderData.coupon) || "",
          email: user_email,
          name: user_name,
          loginType: "",
          isLoggedIn: false,
          isMobile: getDeviceType() == "m" ? true : false,
          event: "conversion",
          ecommerce: {
            purchase: {
              actionField: {
                id: orderId || "",
                affiliation: "Online Store",
                revenue: orderData.amount || 0,
                tax: "",
                shipping: "",
                coupon: (orderData && orderData.coupon) || "",
                action: "purchase",
              },
  
              products: productListForDataLayer,
            },
          },
        });
      }
    }
  }
  useEffect(() => {
    let url = typeof window != undefined && window.location.href;
    let fromappvalue = getParameterByName("from_app", url);
    if (fromappvalue) {
      setFromApp(true);
    } else {
      setFromApp(false);
    }
  }, []);

  useEffect(()=>{
    handleSeoEvents();
  },[orderedPackages.length > 0 && orderData])

  const OrderButtonhandler = () => {
    if(!props.isPaytmMini){
      if(!cookie.get('cp_token')){
        alert('Please login to access your content')
      }
      window.location.replace('/my-orders')
    }
    setShowContent(true);
    //  window.open( `${routeToAppUrl}&link=${routeToAppLinkUrl}/activity/ORDER`)
  };
  const createPdpUrl = (category, id, title) => {
    var storeCat = storeItemsUrlMapping[category];
    var page = "";
    if (category == "videos") {
      page = "product-comprehensive-video";
    } else {
      page = "product-" + storeCat;
    }
    return "/" + page + "/" + id + "/" + makeUrl(title);
  };
  const makeUrl = (url) => {
    url = url.split(",").join("");
    url = url.split("/").join("-");
    if (typeof url != "undefined" && url != "") {
      return url.split(" ").join("-").toLowerCase();
    } else return url;
  };
  const viewPackage = (data, status, viewPackageUrl) => {
    let url =
      viewPackageUrl == "/my-ebooks"
        ? "my-ebooks"
        : viewPackageUrl == "/my-videos"
          ? "my-videos"
          : viewPackageUrl == "/my-live-class"
            ? "my-live-class"
            : `${pdpUrls.store}/${viewPackageUrl}`;
    if (orderData.status == "PAID") {
      return `/my-purchase/${data.id}/${makeUrl(data.title, true)}`;
    } else {
      return createPdpUrl(url, data.id, data.title);
    }
  };

  const setCategoryDataList = (CategoryData) => {
    let results = packageCategoryData.filter(({ name: id1 }) =>
      CategoryData.some(({ name: id2 }) => id2 === id1)
    );
    setCategoryPackageData(results);
  };

  const openLoginModal = (url) => {
    const token = cookie.get("cp_token");
    if (!token) {
    return  window.location.replace('/#show-login');
    }
    else{ 
      history.push(url);
    }
  }
  const rightSectionClick = (item, type) => {
    showScollBar();
    let MoePayLoad = {};
    let switchParam = "";
    if (type == "list") {
      switchParam = item.name;
    } else {
      switchParam = type;
    }
    switch (switchParam) {
      case "ONLINE_LIVE_CLASSES": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'pps_live_class',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        openLoginModal(item.url)
        // history.push(item.url);
        break;
      }
      case "VIDEOS": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'pps_videos',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        openLoginModal(item.url)
        // history.push(item.url);
        break;
      }
      case "TEST_SERIES": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'pps_quiz',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        openLoginModal(item.url)
        // history.push(item.url);
        break;
      }
      case "EBOOKS": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'pps_ebooks',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        openLoginModal(item.url)
        // history.push(item.url);
        break;
      }
      case "TRACK": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'track_order_clicked',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        handleTrackOrder();
        break;
      }
      case "SUPPORT": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'help_and_support_clicked',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        break;
      }
      case "ORDER": {
        MoePayLoad = {
          source_screen: "OrderConfirmActivity"
        }
        if (typeof (Moengage) !== 'undefined') trackMoengageEvent(
          'order_details_clicked',
          getMoengageMetadata(
            MoePayLoad
          ),
        );
        openLoginModal('/my-orders')
        // history.push("/my-orders");
        break;
      }
      default:
        break;
    }
  }
  useEffect(() => {
    setIsLoading(true);
    // const isLogined = checkLoginStatus();
    const  url =  pdpUrls.orderDetailsGuest;
    // if(window?.location?.pathname === '/'){
    //   url = pdpUrls.orderDetailsGuest;
    // }
    fetch(`${url}/${orderId}?user-email=${email || ""}&crm=true`, "GET").then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.packages) {
          setOrderPackageData(res.data)
          setOrderedPackages(res.data.packages);
          setOrderData(res.data);
          setCategoryDataList(res.data.packages[0]?.categoryData);
          if (res.data) {
            moengageEventHandler(res.data);
          }
        } else setOrderedPackages([]);
      }
    );
    sessionStorage.removeItem("from_app");
  }, []);

  const moengageEventHandler = (orderData) => {
    let payload = {};
    if (Getpayload) {
      payload = Getpayload;
    } else if (cartDataInfo) {
      payload.package_final_price = orderData.amount || 0;
    }
    if (!cookie.get("cp_token")) {
      let name = localStorage.getItem("name");
      let email = localStorage.getItem("email");
      payload.user_name = name;
      payload.user_email = email;
    }
    let phone = localStorage.getItem("phone");
    payload.user_mobile_no = phone || 0;
    payload.platform = getDeviceType() == "m" ? "Mobile" : "WEB";
    payload.platform_1 = getDeviceType() == "m" ? "Mobile" : "WEB";
    payload.package_final_price = orderData.amount || 0;
    payload.page_title = "Payment Success Page";
    payload.package_status = "Paid";
    payload.total_final_amount = "";

    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "purchase_frontend_paid",
        getMoengageMetadata(payload)
      );

    typeof window !== "undefined" &&
      window.localStorage.removeItem("mypuchaseId");
  };

 

  if (isLoading) return <CustomLoader />;
  const COURSE_TYPE = {
    1: { url: "/my-mock-test" },
    4: { url: "/my-ebooks" },
    5: { url: "/my-videos" },
    6: { url: "/my-live-class" },
  };

  const redirectToOrderPage = () => {
    if(!props.isPaytmMini){
      if(!cookie.get('cp_token')){
        alert('Please login to access your content')
      }
      window.location.replace('/my-orders')
    }
  }
  const ORDERS_PAGE = "/my-orders";

  const showScollBar = () => {
    document.body.style.overflow = "visible";
    document.body.style.height = "";
  }
  const handleContactSupport = () => {
    window.open(`${window.location.protocol}//${window.location.host}/support`, '_blank');
  };

  const handleTrackOrder = () => {
    showScollBar();
    const token = cookie.get("cp_token");
    if (!token) {
      return  window.location.replace('/#show-login');
    }
    else{
      localStorage.setItem('orderId', orderId)
      history.push({
        pathname: "/my-orders",
        state: { orderId: orderId },
      });
    }
  };

  return (
    <div className="buynow-body-content sucessstatus">
      <div className="payment-sucess-section">
        <CustomModal
          modalClassName="continue-in-app"
          footer={null}
          visible={showContent}
          //title={"Open In App"}
          onCancel={setShowContent}
          closeCallback={setShowContent}
          closable={true}
          maskClosable={false}
          keyboard={false}
          bodyStyle={{ overflowY: "auto" }}
        >
          <div className="go-to-app">
            <div className="gt-app-image">
              <img
                src="/images/Purchased_product_screen.png"
                // style={{ maxHeight: "100%", maxWidth: "100%" }}
                alt="Download App"
              // onClick={openInApp}
              />
            </div>
          </div>
        </CustomModal>
        <div className="payment-success-wrap">
          <div className="success-body-content">
            <div className="success-left-area">
              <div className="success-msg-wrap">
                <div className="payment-status-icon">
                  <img
                    src="/images/successCheck.svg"
                    alt={pageName[0]}
                    title={pageName[0]}
                  ></img>
                </div>
                <div className="success-text">
                  <div className="payment-status-text">
                    Thank you for your purchase!
                  </div>
                  <div className="username-order-confirm">
                    Hi {nameUser}, Your order has been confirmed!
                  </div>
                </div>
              </div>

              <div className="order-success-box">
                <div className="buynow-heading-wrap">
                  <div className="buynow-inner-heading">Your Order</div>
                </div>
                <div className="order-success-box-content">
                  <div className="order-row">
                    <div className="order-row-left">Order Id :</div>
                    <div className="order-row-right">{orderId}</div>
                  </div>
                  {/* { */}
                  {orderedPackages[0]?.categoryData?.some(
                    (item) => item.name == "BOOKS"
                  ) && orderedPackages[0]?.categoryData?.length == 1 ?

                    // orderedPackages[0]?.categoryData?.length > 1 &&
                    // orderedPackages[0]?.categoryData[0]?.name != "BOOKS" ? (
                    null
                    : <>            <div className="order-row">
                      <div className="order-row-left">Course Name : </div>
                      <div className="order-row-right">
                        {" "}
                        {orderedPackages[0]?.title}{" "}
                      </div>
                    </div>

                      <div className="order-row">
                        <div className="order-row-left">Validity :</div>
                        <div className="order-row-right">
                          {orderedPackages[0]?.validity} Months
                        </div>
                      </div></>
                  }

                  <div className="order-row">
                    <div className="order-row-left">Price :</div>
                    <div className="order-row-right">
                      Rs. {orderPackageData?.amount}
                    </div>
                  </div>
                  <div className="view-order-btn" onClick={() => rightSectionClick(null, "ORDER")}>
                    <a>VIEW ORDER DETAILS</a>
                  </div>
                </div>
              </div>
              <div className="order-footer webfooter" onClick={() => rightSectionClick(null, "SUPPORT")}>
                <span>
                  Facing an issue ? <Link onClick={()=> window.open(`${window.location.protocol}//${window.location.host}/support`, '_blank')}>Contact Support</Link>
                </span>{" "}
                <img
                  src="/images/oder-success-support-icon.svg"
                  onClick={() => handleContactSupport()}
                ></img>
              </div>
            </div>
            <div className="success-right-area">
              {/* {
              // (orderedPackages[0]?.categoryData?.length == 1 &&
              // orderedPackages[0]?.categoryData[0]?.name != "BOOKS") || 
              (orderedPackages[0]?.categoryData?.length > 1) ? (
                <>
                  <div className="success-acces-list">
                    <div className="success-acces-list-heading">
                      Access from <span>My Purchased Courses</span> later Or
                    </div>
                    {categoryPackageData.length &&
                      categoryPackageData.map((item, index) => {
                        return (
                          <>
                            <div className="success-list-row">
                              <div className="success-list-icon">
                                <img src={item.imgPath} />
                              </div>
                              <div className="success-list-btn">
                                <a href={item.url}>{item.title}</a>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : null} */}

              {orderedPackages[0]?.categoryData?.some(
                (item) => item.name == "BOOKS"
              ) && orderedPackages[0]?.categoryData?.length == 1 ? (
                // orderedPackages[0]?.categoryData?.length &&
                //                   orderedPackages[0]?.categoryData?.length != 1 &&
                //                   orderedPackages[0]?.categoryData[0]?.name != "BOOKS"
                <>
                  <div className="success-product-box">
                    <div className="buynow-heading-wrap">
                      <div className="buynow-inner-heading">
                        Book you have ordered
                      </div>{" "}
                    </div>
                    <div className="success-product-body">
                      <div className="product-image-left">
                        <img src={orderedPackages[0]?.imageUrl} />
                      </div>
                      <div className="product-dis-right">
                        <div className="product-dis-title">
                          {orderedPackages[0]?.title}
                        </div>
                      </div>
                    </div>
                    <div className="product-delivery-text">
                      *Your delivery should reach within 5-7 days
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {categoryPackageData && categoryPackageData.length > 0 &&
                  <div className="success-acces-list">
                    <div className="success-acces-list-heading">
                      Access from <span>My Purchased Courses</span> later Or
                    </div>
                    {categoryPackageData.length &&
                      categoryPackageData.map((item, index) => {
                        return (
                          <>
                            <div className="success-list-row" onClick={() => rightSectionClick(item, "list")}>
                              <div className="success-list-icon">
                                <img src={item.imgPath} />
                              </div>
                              <div className="success-list-btn">
                                <a>{item.title}</a>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                 }
                </>
              )}

              {/* Product img box sextion*/}
              {

                orderedPackages[0]?.categoryData?.some(
                  (item) => item.name == "BOOKS"
                )
                  ? (
                    <div className="track-roder-btn">
                      <a onClick={() => rightSectionClick(null, "TRACK")}>
                        <img src="/images/order-sucess-van.svg" alt="" />
                        TRACK ORDER
                      </a>
                    </div>
                  ) : null}

              <div className="order-footer mwebfooter">
                <span>
                  Facing an issue ? <a>Contact Support</a>
                </span>{" "}
                <img
                  src="/images/oder-success-support-icon.svg"
                  onClick={() => handleContactSupport()}
                ></img>
              </div>
            </div>
          </div>
        </div>

  
        {/* <div className="payment-success-disclaimer">
        We have sent a record of this order to {email}. You can view your order
        and invoice from My Orders in your profile. <br /><br />For any query regarding this
        order, you can reach our Customer support at
        <a href={"mailto:support@adda247.com"} className="payment-success-learn" title='Contact US'>
          {" "}
          {"support@adda247.com"}
        </a>
      </div> */}
        {/* {orderedPackages.length > 0 && !fromapp && <div className="payment-success-details">
        <div className="cartInfo-success-header">Items Ordered</div>
        {orderedPackages.map(orderedPackage => {
          let viewPackageUrl = COURSE_TYPE[orderedPackage.categoryIds[0]]
            ? COURSE_TYPE[orderedPackage.categoryIds[0]].url
            : ORDERS_PAGE;
          return (
            <CatalogCard
              src={orderedPackage.imageUrl}
              width="120px"
              height="80px"
              header={orderedPackage.title}

              cardBody={
                <div className="payment-success-actions">
                  <div className="payment-success-actioninfo">
                    {" "}
                    <img
                      className="payment-actions-successIcon"
                      src="/images/successCheck.svg"
                      alt={pageName[0]}
                      title={pageName[0]} ></img>
                    Added To your account{" "}
                  </div>
                  <div className="payment-success-learn">
                    <a
                      target={viewPackageUrl == '/my-ebooks' ? "_self" : viewPackageUrl == '/my-videos' ? "_self" : viewPackageUrl == '/my-live-class' ? "_self" : '_blank'}
                      href={viewPackage(orderedPackage, orderData.status, viewPackageUrl)}
                      target='_self'
                      title="view-package"
                    >
                      {props.isPaytmMini ? "" : "Learn Now"}
                    </a>
                  </div>
                </div>
              }
            >

            </CatalogCard>
          );
        })}
      </div>
      } */}
      </div>
    </div>
  );
};

// export default PaymentSuccess;

const mapStateToProps = (state) => {
  // const { productDetails } = state;
  return {
    userDetails: state.header.userInfo,
    isPaytmMini: state.isPaytmMini,
  };
};
const mapDispatchToProps = {
  setCartCountFromCookie,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);

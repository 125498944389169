import React, { useEffect, useRef, useState } from "react";
import { pageName } from "../../../constants/appConfig";

function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

function scrollLeft(element, to, duration) {
  var start = element.scrollLeft,
    change = to - start,
    currentTime = 0,
    increment = 20;
  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollLeft = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

const NewCarousel = (props) => {
  const ref = useRef();
  const leftIcon = useRef();
  const rightIcon = useRef();
  const [show, setShow] = useState(0);
  const [leftShow, setLeftShow] = useState(0);
  const [rightShow, setRightShow] = useState(1);
  const [scrollleft, setScrollLeft] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [newActiveSlide, setNewActiveSlide] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (
        ref &&
        ref.current &&
        ref.current.firstChild &&
        ref.current.childElementCount * ref.current.firstChild.offsetWidth >
          ref.current.offsetWidth &&
        ref.current.offsetParent.offsetWidth
      ) {
        let top = ref.current.offsetHeight / 2 - 20;
        setShow(top);
      }
    },400)
  }, [props.children]);
  useEffect(() => {
    setScrollLeft(0);
    scrollLeft(ref.current, 0, 0);
    setLeftShow(0);
    setRightShow(1);
  }, [props.current]);

  // comment out the autoscroll testimonial code for now.
  // useEffect(() => {
  //   /**
  //   this will run only for the testimonial section.
  //    * 
  //    */
  //   if (props.id === "testimonials") {
  //     const interval = setInterval(() => {
  //       if (props.centerIndex <= 2) {
  //         rightIcon.current.click()
  //       }
  //       if (props.centerIndex === 3) {
  //         leftIcon.current.click()
  //         leftIcon.current.click()
  //       }
  //     }, 3000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [props.centerIndex]);
  const handlePrev = () => {
    setNewActiveSlide(newActiveSlide - 1);
    if (props.id !== "testimonials") {
      var paginationWrapper = document.querySelector(`#slider_${props.id}.pagination-wrapper`);
      paginationWrapper.classList.add('transition-prev');
      setTimeout(() => {
        if (paginationWrapper.classList.contains('transition-next')) {
          paginationWrapper.classList.remove('transition-next')
        } else if (paginationWrapper.classList.contains('transition-prev')) {
          paginationWrapper.classList.remove('transition-prev')
        }
      }, 200);
    }
    if (props.id === 'testimonials') {
      props.setCenterIndex(prev => prev - 1)
    }
    var style =
      ref.current.firstChild.currentStyle ||
      window.getComputedStyle(ref.current.firstChild);
    let value =
      ref.current.firstChild.offsetWidth +
      parseFloat(style.marginLeft) +
      parseFloat(style.marginRight);
    let scrollLeftValue = scrollleft - value;
    const slideNo = Math.ceil(ref.current.scrollLeft / value) + 1;
    setScrollLeft(scrollLeftValue);
    scrollLeft(ref.current, scrollLeftValue, 300);
    window.setTimeout(() => {
      if (ref && ref.current && scrollLeftValue - 100 <= 0) {
        setLeftShow(0);
      } else {
        setLeftShow(1);
      }
      setRightShow(1);
    }, 305);
  };
  const handleNext = () => {
    setNewActiveSlide(newActiveSlide + 1);
    if (props.id !== "testimonials") {
      var paginationWrapper = document.querySelector(`#slider_${props.id}.pagination-wrapper`);
      paginationWrapper.classList.add('transition-next');
      setTimeout(() => {
        if (paginationWrapper.classList.contains('transition-next')) {
          paginationWrapper.classList.remove('transition-next')
        } else if (paginationWrapper.classList.contains('transition-prev')) {
          paginationWrapper.classList.remove('transition-prev')
        }
      }, 200);
    }
    if (props.id === 'testimonials') {
      props.setCenterIndex(prev => prev + 1)
    }
    const { current } = ref;
    const { firstChild } = current;
    var style =
      ref.current.firstChild.currentStyle ||
      window.getComputedStyle(ref.current.firstChild);
    let value =
      ref.current.firstChild.offsetWidth +
      parseFloat(style.marginLeft) +
      parseFloat(style.marginRight);
    const slideNo = Math.ceil(ref.current.scrollLeft / value) + 1;
    setActiveSlide(slideNo);
    if (typeof props.handleCarouselCallback == "function") {
      props.handleCarouselCallback(slideNo);
    }
    let scrollLeftValue = scrollleft + value;
    setScrollLeft(scrollLeftValue);
    scrollLeft(ref.current, scrollLeftValue, 300);
    window.setTimeout(() => {
      if (
        ref &&
        ref.current &&
        scrollLeftValue + ref.current.offsetWidth >=
        ref.current.scrollWidth - 100
      ) {
        setRightShow(0);
      } else {
        setRightShow(1);
      }
      setLeftShow(1);
    }, 305);
    // props.handleNavigationCallback()

  };
  return (
    <>
      <div style={{ position: "relative" }}>
        {show > 0 && leftShow > 0 && (
          <div
            ref={leftIcon}
            className={"left-arrow-carousel"}
            style={{ top: `${show}px` }}
            onClick={handlePrev}
          >
            <img
              src={"/images/testi-pagi.svg"}
              alt="button-left"
              title={pageName[0]}
              style={{ outlineStyle: "none" }}
            />{" "}
          </div>
        )}
        <div ref={ref} className={props?.classWrapper? props?.classWrapper :"custom-carousel"} id={props.id}>
          {props.children}
        </div>

        {/* DOTS */}
        {
          props.id !== "testimonials" && <div class="pagination-wrapper" id={`slider_${props.id}`}>
            <div class="pagination-container">
              {
                props?.dataLength > 4 && props?.dataLength < 5 ? (
                  <>
                    {
                      [...Array(2)].map((elementInArray, index) => (
                        index == newActiveSlide ? <div key={`ind__${index}`} id={`dotId_${index + 1}`} className={`${index == 0 && 'little-dot--first'} ${index == 1 && 'little-dot--last'} little-dot`}>
                          <div class="big-dot-container">
                            <div class="big-dot"></div>
                          </div>
                        </div> : <div className={`${index == 0 && 'little-dot--first'} ${index == 1 && 'little-dot--last'} little-dot`} id={`dotId_${index + 1}`}></div>
                      )
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      props?.dataLength > 5 && (
                        <>
                          {
                            newActiveSlide == 0 ? <div class="little-dot little-dot--first" id={`dotId_1`}>
                              <div class="big-dot-container">
                                <div class="big-dot"></div>
                              </div>
                            </div> : <div class="little-dot little-dot--first" id={`dotId_1`}></div>
                          }
                          {
                            newActiveSlide == 1 ? <div class="little-dot" id={`dotId_2`}>
                              <div class="big-dot-container">
                                <div class="big-dot"></div>
                              </div>
                            </div> : <div class="little-dot" id={`dotId_2`}></div>
                          }
                          {
                            ((newActiveSlide > 1) && (newActiveSlide < (props.dataLength - 5))) ? <div class="little-dot" id={`dotId_3`}>
                              <div class="big-dot-container">
                                <div class="big-dot"></div>
                              </div>
                            </div> : <div class="little-dot" id={`dotId_3`}></div>
                          }
                          {
                            (newActiveSlide == (props.dataLength - 5)) ? <div class="little-dot" id={`dotId_4`}>
                              <div class="big-dot-container">
                                <div class="big-dot"></div>
                              </div>
                            </div> : <div class="little-dot" id={`dotId_4`}></div>
                          }
                          {
                            (newActiveSlide == (props.dataLength - 4)) ? <div class="little-dot little-dot--last" id={`dotId_5`}>
                              <div class="big-dot-container">
                                <div class="big-dot"></div>
                              </div>
                            </div> : <div class="little-dot little-dot--last" id={`dotId_5`}></div>
                          }
                        </>
                      )
                    }
                  </>
                )
              }

            </div>
          </div>
        }

        {/* DOTS */}

        {show > 0 && rightShow > 0 && (
          <div
            ref={rightIcon}
            className={"right-arrow-carousel"}
            style={{ top: `${show}px` }}
            onClick={handleNext}
          >
            <img
              src={"/images/testi-pagi.svg"}
              alt="button-right"
              title={pageName[0]}
              style={{ outlineStyle: "none" }}
            />{" "}
          </div>
        )}
      </div>
    </>

  );
};
export default NewCarousel;

import types from './types';
import { seoDataUrl } from '../../../../constants/appConfig';

const getSeoData = (pageUrl) => {
    return {
        CALL_API: [
            {
                type: types.GET_SEO_DATA,
                meta: {
                    path: `${seoDataUrl}?url=${encodeURI(pageUrl)}`,
                    method: 'GET',
                }
            }
        ]
    };
};

const getSeoDetails =  (data,url) => {
    const payload={data:data,url:url};
 
    return {
        type: "SEO_DATA",
        payload: payload,

    };
};


export {
    getSeoData,
    getSeoDetails
}
import React, { useState, useEffect } from 'react';
import { pageName, pdpUrls } from '../../../constants/appConfig'
import SidePanel from './Sidepanel';
import { createOptimizedImgUrl, getDeviceType, getMoengageMetadata } from '../../data/utils/helpers';
import CustomModal from '../library/CustomModal';
import { default as Link } from '../../containers/ConnectedLink';
import { timeStampdateMonthYearebooks } from '../utils';
import ContineInApp from '../library/ContinueInApp/ContinueInApp';
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS, EBOOKS_COPYRIGHT_MSG } from '../../../constants/textConstants';

const ViewEbookListing = props => {

    const [visiblebuymodal, setVisibleBuymoadl] = useState(false);
    const [visiblerenewmodal, setVisiblerenewmodal] = useState(false);
    const [parentPackageimageUrl, setparentPackageimageUrl] = useState('');
    const [currentEbookthumbnail, setcurrentEbookthumbnail] = useState('');
    const [parentPackagetitle, setparentPackagetitle] = useState('');
    const [currentEbooktitle, setcurrentEbooktitle] = useState('');
    const [filterString, setFilterString] = useState("");
    const [filterList, setFilterList] = useState([]);


    let packageInfo = props.viewEbooksList && props.viewEbooksList.packageInfo;
    let expirydate = packageInfo.expiryDate;
    const d = new Date(expirydate && expirydate);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let expireddatevalue = `${da} ${mo} ${ye}`;
    let ebooksData = props.viewEbooksList && props.viewEbooksList.ebooksData;

    let packageInfoId = packageInfo && packageInfo.id;
    let expired = (packageInfo && packageInfo.expired) || false;
    let parentInfo = packageInfo.parentInfo && packageInfo.parentInfo[0] || '';

    let buymodal = parentInfo ? parentInfo.expired && !parentInfo.published : packageInfo.expired && !packageInfo.published;
    let renewmodal = parentInfo ? parentInfo.expired && parentInfo.published : packageInfo.expired && packageInfo.published;

    let parentPackageimage = parentInfo && parentInfo.imageUrl;
    let parentPackagetit = parentInfo && parentInfo.title;
    


useEffect(()=>{
    var packageData=packageInfo;
    var payload={};
    payload.name=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.title : packageData.title;
    payload.category=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.category : "EBOOKS";
    payload.id=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.id : packageData.id;
    payload.subPackageId=packageData.id;
    payload.subPackageType="EBOOKS"
    payload.action="ebookPackage_clicked"
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        'ebook',
        getMoengageMetadata(
          payload
        ),
      );

},[])

    const groupByCategory = (arr) => {
        let group = (arr || []).reduce((acc, curr) => {
            acc[curr.groupName] = [...acc[curr.groupName] || [], curr];
            return acc;
        }, {});
        return group;
    }

    const [panelData, setPanelData] = useState([]);
    const [groupNameId, setGroupNameId] = useState(-1);
    const [clickedItemData, setClickedItemData] = useState();

    useEffect(() => {
        setFilterList((!panelData.length || groupNameId == -1) ? ebooksData : panelData);
    },[panelData, groupNameId])

    useEffect(() => {
        if(filterString.trim().length == 0){
            setFilterList((!panelData.length || groupNameId == -1) ? ebooksData : panelData);
        }
    },[filterString])


    let sidePanelArr = groupByCategory(ebooksData);

    const getEbooksbyGroupName = (groupName, index) => {
        let ebooksDataWithPanel = sidePanelArr[groupName];
        setPanelData(ebooksDataWithPanel);
        setGroupNameId(index);
    }

    const getEbooksbyGroupNameDefault = (defaultId) => {
        setGroupNameId(defaultId);
    }
    const EbookspageHandler = () => {
        setVisibleBuymoadl(false);
    }

    const modalvisibility = (item) => {
        if (buymodal) setVisibleBuymoadl(true);

        else if (renewmodal) {
            setVisiblerenewmodal(true)
        }

        parentPackageimage ? setparentPackageimageUrl(parentPackageimage) : setparentPackageimageUrl(item.thumbnail && item.thumbnail);
        parentPackagetit ? setparentPackagetitle(parentPackagetit) : setparentPackagetitle(item.title)

    }

    const openmodal = () => {
        if (buymodal) setVisibleBuymoadl(true);

        else if (renewmodal) {
            setVisiblerenewmodal(true)
        }
        setparentPackageimageUrl(parentPackageimage)
        setparentPackagetitle(parentPackagetit)
    }
    const openEbook = (item, event) => {
        var packageData=packageInfo;
        var payload={};
        payload.name=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.title : packageData.title;
        payload.category=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.category : "EBOOKS";
        payload.id=packageData && packageData.recentParentInfo ? packageData.recentParentInfo.id : packageData.id;
        payload.subPackageId=packageData.id;
        payload.subPackageType="EBOOKS";
        payload.contentTitle = item.title;
        payload.contentID = item.id;
        payload.action="ebook_opened"
        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
            'ebook',
            getMoengageMetadata(
              payload
            ),
          );

        if(getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER){
            setClickedItemData({
                packageId: packageData && packageData?.recentParentInfo ? packageData?.recentParentInfo?.id : packageData?.id,
                type: COURSE_CATEGORY_IDENTIFIERS.EBOOKS_IDENTIFIER
            });
            return;
        }

        if (confirm(EBOOKS_COPYRIGHT_MSG)) {
            let element = typeof window !== 'undefined' && window.document.getElementById(item.id + 'continuediv');
            element.innerHTML = '<span>CONTINUE READING</span>';
            typeof window !== 'undefined' && window.open(`/e-books?file=${packageInfoId}_${item.id}.doc`);
        }
    }

    const ebooklisting = (ebooksData) => {
        return ebooksData && ebooksData.map((item, i) => {
            return (
                <div className={`card-background-ebook card-shadow ${expired ? 'expired' : ''}`} key={i + 2} onClick={!expired && !item.comingSoon ? (event) => openEbook(item , event) : (item.comingSoon ? () => { } : () => modalvisibility(item))}>
                    {item.status == "COMING_SOON" && <div className="date-container">
                        <span className="date-text">{item.comingSoonDate && timeStampdateMonthYearebooks(item.comingSoonDate)}</span>
                    </div>}
                    <div className="expcont"></div>
                    <div className={'image-card-container-ebook'}>
                        <div className={'image-align-card-ebook'}>
                            <img className="img-card-dim-ebook" src={item && item.thumbnail && createOptimizedImgUrl(item.thumbnail, 172)} />
                        </div>
                    </div>
                    <span className="line-clamp label-card title-border-top" style={{ textAlign: 'left', margin: '6px 0', fontSize: '13px', height: '52px', maxHeight: 'none', WebkitLineClamp: '3' }}>
                        {item && item.title}
                    </span>
                    <div id={item && item.id + 'continuediv'}
                        className={`add-to-cart listing-add-to-cart margin-force-zero sf-button-secondary ${item.comingSoon ? 'comingSoon' : ''}`}
                    //  onClick={(event)=> openEbook(item && item.id, event)}
                    >

                        {
                            expired ?
                                <span>EXPIRED</span>
                                :
                                (
                                    item.comingSoon ? <span>COMING SOON</span> : <span>{item && item.status === 'NOT_STARTED' ? 'OPEN E-BOOK' : 'CONTINUE READING'}</span>
                                )
                        }

                    </div>
                </div>
            )
        })
    }

    const handleFilterStringChange = (event) => {
        setFilterString(event.target.value);
    }

    const handleSearch = () => {
        if(filterString.trim().length <= 1){
            return;
        }
        const data = (!panelData.length || groupNameId == -1) ? ebooksData : panelData;
        setFilterList(data.filter(item => {
            return item.title.toLowerCase().includes(filterString.trim().toLowerCase());
        }))
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    const notfound = <div className="ec-image" style = {{width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div className='empty-cart'>
            <img src="/images/no-result-found.png" width="300" />
            <div className="ec-header" style = {{textAlign: "center"}}>{'No Ebooks found for your search'}</div>
        </div>
    </div>


    if (ebooksData && ebooksData.length <= 0) {
        return (
            <div className='empty-cart center' style={{ width: '100%', height: '485px' }} >
                <div className="ec-image">
                    <img src="/images/no-result-found.png" width="300" />
                    <div className="ec-header" >No e-books available yet. Coming Soon! </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <CustomModal
                modalClassName={"modalZindex"}
                footer={null}
                visible={visiblebuymodal}
                // hideHeader={true}
                // hideCross={false}
                title='Buy E-Books'
                onCancel={setVisibleBuymoadl}
                closeCallback={setVisibleBuymoadl}
                closable={true}
                maskClosable={false}
                keyboard={false}

                bodyStyle={{ overflowY: "auto" }}>
                <div  >

                    <div style={{ backgroundColor: 'rgb(255, 229, 207)', textAlign: 'center' }}>
                        <img src= {createOptimizedImgUrl("https://s3.ap-south-1.amazonaws.com/storefront-assets/Expiry.png", 200)}
                            style={{ width: '200px' }} />
                    </div>
                    <div  >
                        <div style={{ margin: "20px 0", fontSize: "20px", fontWeight: "600", lineHeight: "1.2", textAlign: "center", color: "#090023" }}>Package Expired!</div>
                        <div style={{ margin: "0 24px 44px", opacity: "0.8", fontSize: "14px", lineHeight: "1.43", textAlign: "center", color: "#444545" }}>

                            <span>This product is not available anymore. You can still view similar products in this category.</span>
                        </div>

                        <div style={{ textAlign: "center" }}  ><a style={{ width: "200px", padding: '5px', height: "44px", backgroundColor: "#f57f18", fontSize: "14px", fontWeight: "600", color: "#ffffff" }} className="sf-button-secondary  " ui-sref="ebooksListing" href='/ebooks'>Buy E-Books</a>
                        </div>
                    </div>
                </div>
            </CustomModal>


            <CustomModal
                footer={null}
                visible={visiblerenewmodal}
                title={"Renew Package"}
                onCancel={setVisiblerenewmodal}
                closeCallback={setVisiblerenewmodal}
                closable={true}
                maskClosable={false}
                keyboard={false}
                modalClassName="largemodal"
                bodyStyle={{ overflowY: "auto" }}
            >
                <div className="video-popup myebookpopup">
                    <p>
                        {packageInfo.title || ""}
                        <div>is Available with the below courses</div>
                    </p>

                    <Link to={`/product-ebooks/${packageInfo.id}/${packageInfo.title}`}>
                        <div className="catalog-card ">
                            <div className="catalog-card-media" >
                                <div className="catalog-card-overlay"></div>
                                <div><img src={createOptimizedImgUrl(parentPackageimageUrl, 90)} height="110px" /></div>
                            </div>
                            <div className="catalog-card-content ">
                                <div className="package-title">{parentPackagetitle || ""}</div>
                                <div className="catalog-card-subheader">
                                    <div className="package-subheader" style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="paid-itemCount"></div>
                                    </div>
                                </div>
                                <div className="catalog-card-text">Details</div>
                            </div>
                        </div>
                    </Link>

                </div>
            </CustomModal>

            <div className={'testseries-container'}>
                <div className={'my-test-series-header ebooksearch'} style={{ background: 'white', borderColor: 'transparent' }}>
                <div className='custum-search-wrapper mwebviewsearch'>
                            <input className='ebooks-search blackishSearch' type={'text'} placeholder="Search" onChange={handleFilterStringChange} onKeyDown = {handleKeyDown}></input>
                            <button className='ebooks-search-btn' onClick={handleSearch}>Search</button>
                        </div>

                <div className="small-11 columns no-outline-mac my-test-ellipses ng-binding" id="cp-no-padding" style={{ lineHeight: '2.3', color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', fontSize: '16px' }}>
                        <span className={'icon'} style={{ cursor: 'pointer', marginRight: '6px' }} onClick={props.goToEbooks}><img src='/images/back-arrow.svg' /></span>
                        {packageInfo && packageInfo.title}
                  
                    </div>
                    <div className='custum-search-wrapper webviewsearch'>
                            <input className='ebooks-search blackishSearch' type={'text'} placeholder="Search" onChange={handleFilterStringChange} onKeyDown = {handleKeyDown}></input>
                            <button className='ebooks-search-btn' onClick={handleSearch}>Search</button>
                        </div>
                    {expired ?
                        <div className="show-only-web" style={{
                            color: "rgb(213, 17, 0)", fontSize: "14px", lineHeight: "1",
                            fontWeight: "normal", marginLeft: "31px"
                        }}>
                            Expired on {expireddatevalue}
                            <span className="renew-package" role="button" tabindex="0"
                                onClick={openmodal}>
                                <u style={{ marginLeft: "10px" }}>RENEW PACKAGE</u></span></div>

                        : null
                    }

                </div>
                <div className="ebookcont" style={{ display: 'flex', height: 'calc(100vh - 170px)' }}>
                    <div style={{ flex: '1', display: sidePanelArr && !sidePanelArr.length && Object.keys(sidePanelArr).length > 1 ? '' : 'none' }} className={'panel-web-only'}>
                        <SidePanel sidePanelArr={sidePanelArr} getEbooksbyGroupName={getEbooksbyGroupName} groupNameId={groupNameId} getEbooksbyGroupNameDefault={getEbooksbyGroupNameDefault} />
                    </div>
                    <div style={{ flex: '3', maxHeight: '-webkit-fill-available', overflowY: 'scroll' }}>
                        <div className={'list-item-container left-space'}>
                            {(!ebooksData || !panelData) ? <div>Loading..</div> : (filterList.length != 0? ebooklisting(filterList): notfound)}
                        </div>
                    </div>
                    <ContineInApp
                        show={!!clickedItemData}
                        packageId={clickedItemData && clickedItemData?.packageId}
                        parentpackageId={clickedItemData && clickedItemData?.parentPackageId}
                        type={clickedItemData && clickedItemData?.type}
                        close={() => {
                            setClickedItemData();
                        }}
                        isContinuesection={true}
                    />
                </div>
            </div>
        </div>
    )
}
export default ViewEbookListing
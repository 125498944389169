import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  capitalizeWords,
  removeHyphensAndInsertSpaces,
} from "../../../data/utils/helpers";
import PPCWrapper from "../PPCWrapper";
import CustomLoader from "../../../components/library/CustomLoader";
import { myPurchase } from "../../../../constants/appConfig";
import fetch from "../../../data/utils/fetch";
import ExamOptions from "./ExamOptions";

const ExamOptionsWrapper = () => {
  const { packageId, examId, packageName, examName } = useParams();
  const { pathname, state } = useLocation();
  const [allPackageCountData, setAllPackageCountData] = useState(state);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const history = useHistory();


  useEffect(() => {
    if(!allPackageCountData){
        setLoadingFlag(true);
        try{
            fetch(`${myPurchase.allPackageCount}?packageId=${packageId}&exams=${examId}`).then(res => {
                if(res.success){
                setAllPackageCountData(res.data);
                }
                const data = res.data;
                titleTextForSubParents = data?.packages?.reduce((result, current) => {
                    if(result){
                        return result + ", " + current?.title;
                    }else{
                        return current?.title;
                    }
                },"")
                setLoadingFlag(false);
            }).catch(err => {console.log("err: ", err); setLoadingFlag(false)});
        }catch(error){
            console.log("error: ", error);
        }
    }
  },[])

  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
    {
      route: `/my-purchase/${packageId}/${packageName}/exams`,
      name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
    },
    {
      route: pathname,
      name: capitalizeWords(removeHyphensAndInsertSpaces(examName)),
    },
  ];

  const handleAllCoursesClick = () => {
    const url = pathname.substring(0, pathname.length - 8);
    history.push(url, {fromOptionsPage: true});
  }

  const handleAllBatchesClick = () => {
    if(allPackageCountData?.packages?.length == 0) return;
    const url = pathname.substring(0, pathname.length - 8) + "/allBatches";
    history.push(url);
  }

  return (
    <PPCWrapper
      breadCrumbRoutes={breadCrumbsList}
      pageTitle={removeHyphensAndInsertSpaces(examName)}
      rightSection={<></>}
    >
      {loadingFlag? <CustomLoader/>:
              <ExamOptions 
                examName={examName} 
                allPackageCountData={allPackageCountData} 
                handleAllBatchesClick = {handleAllBatchesClick} 
                handleAllCoursesClick = {handleAllCoursesClick}
              />
      }
    </PPCWrapper>
  );
};

export default ExamOptionsWrapper;
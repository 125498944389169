import React, { Component } from 'react';
import { prodUrl } from '../../constants/appConfig';
import { wrapperWithoutExamList } from '../../constants/appConstants';
import { default as Link } from '../containers/ConnectedLink';
import { isADDA, removeSpacesAndInsertHyphens } from '../data/utils/helpers';

class MainMenu extends Component {
  constructor() {
    super();
    this.state = {
      mainCategoryItem: '',
      selectedId: '',
      categoryGroup: {},
      activeMenu: isADDA ? "GOVERNMENTEXAMS" : "IITJEE",
      wrapperExamList: {}
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let examId;
    let wrapperId;

    let locallyStoredExam = JSON.parse(localStorage.getItem("selectedExam"));
    if(locallyStoredExam){
      wrapperId = locallyStoredExam.wrapperId;
      examId = locallyStoredExam.examId;
    }
    else if(this.props.selectedExam){
      const arr = this.getExamIdAndWrapperIdByExamName(this.props.selectedExam);
      examId = arr[0];
      wrapperId = arr[1];
    }
    


    this[`textInput${0}`]?.click();
    document.addEventListener('mousedown', this.handleClickOutside);
    
    if(this.props.selectedExam && examId && wrapperId){
      this.changeMenu(examId, wrapperId);
    }
    else{
      this.setState({ activeMenu: this.props?.globalExamList?.[0]?.id })
      let prevState = this.state;
      let wrapperExam = this.props.globalExamList.filter(x => x.id == this.props.globalExamList?.[0]?.id);
      if (wrapperExam?.[0]?.examList?.[0]?.dpn) {
        this.props.handleExamChange(wrapperExam?.[0]?.examList?.[0]?.dpn);
        this.setState({ ...prevState, selectedId: wrapperExam?.[0]?.examList?.[0]?.id, wrapperExamList: wrapperExam?.[0]?.examList })
      }
      else {
        this.changeMenu(wrapperExam?.[0]?.id);
      }
    }
  }

  closeMenu = () => this.props.resetMenu();

  closeMenuOnLink = () => {
    this.closeMenu();
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !event.target.className.includes("all-course-content")) {
      this.closeMenu();
    }
  };


  groupByCategory(arr) {
    let group = (arr || []).reduce((acc, curr) => {
      acc[curr.category] = [...acc[curr.category] || [], curr];
      return acc;
    }, {});
    return group;
  }

  getMainCategory = (param, e) => {
    this.setState({ selectedId: param.toUpperCase() });
  };

  changeMenu = (exam_id, wrapper_id) => {
    let preState = this.state;
    let wrapperExam = this.props.globalExamList.filter(x => x.id == exam_id);
    let getValidItems;
    if(wrapper_id){
      getValidItems = wrapperExam[0]?.examList?.find(x => x.id == wrapper_id && this.props.examMenuData[x.id]?.length > 0);
    }else{
      getValidItems = wrapperExam[0]?.examList?.find(x => this.props.examMenuData[x.id]?.length > 0)
    }
    if (getValidItems) {
      this.setState({ ...preState, activeMenu: exam_id, selectedId: getValidItems.id.toUpperCase(), wrapperExamList: wrapperExam[0]?.examList });
      this.props.handleExamChange(getValidItems.dpn);
    } else {
      if(wrapperExam && wrapperExam[0] && wrapperExam[0].type === wrapperWithoutExamList){
        this.setState({ ...preState, activeMenu: exam_id, selectedId: wrapperExam[0]?.id?.toUpperCase(), wrapperExamList: this.props.examMenuData?.[exam_id] });
        this.props.handleExamChange(wrapperExam[0].dpn);
      }
      else{
        this.setState({ ...preState, activeMenu: exam_id, selectedId: wrapperExam[0]?.id?.toUpperCase(), wrapperExamList: wrapperExam[0]?.examList });
        this.props.handleExamChange(wrapperExam[0].dpn);
      }
    }
  }

  getActiveClass = (wrapper_id) => {
    let wrapperExam = this.props?.globalExamList?.find(x => x.id == wrapper_id);
    if (wrapperExam) {
      let activeItems = wrapperExam?.examList?.find(y => y.id == this.state.selectedId);
      if (activeItems) {
        return true;
      } else {
        return wrapperExam && wrapperExam.type === wrapperWithoutExamList && wrapperExam.id == this.state.selectedId;
      }
    }
    return false;
  }

  getExamIdAndWrapperIdByExamName = (examName) => {
    const globalList = this.props.globalExamList;
    const examMenuData = this.props.examMenuData;

    let examId;
    let wrapperId;

    let found = false;

    globalList?.forEach(globalExam => {
      const examList = globalExam?.examList;
      !found && examList?.forEach(wrapperExam => {
        if(!found){
          const mainExamList = examMenuData[wrapperExam.id];
          const foundObj = mainExamList?.find(x => {
            return x.name.toUpperCase() == examName.toUpperCase();
          })
          if(foundObj){
            wrapperId = wrapperExam.id;
            examId = globalExam.id;
            found = true;
          }
        }
      })
    })

    return [examId, wrapperId];
  }

  render() {
    const { selectedId, wrapperExamList } = this.state;
    const { allCoursesList, globalExamList } = this.props;
    return (
      <>
        <div ref={this.wrapperRef} className="dropdown-wrap">
          <div className='container'>
            <div className={'allcourse-menu-list'}>
              <div className="all-course-cat">
                <ul>
                  {
                    globalExamList && globalExamList.length > 0 && globalExamList.map((wrapper, wrapperIndex) => {
                      return (
                        <>
                          {<li className={this.getActiveClass(wrapper.id) ? "active" : "close"} onClick={() => {this.changeMenu(wrapper.id);}} key={wrapperIndex}><a className='exam-menu-wrapper'><img src={wrapper.s3Url} alt="" />{wrapper.dpn}</a></li>}
                        </>
                      )
                    })
                  }
                </ul>
              </div>
              <div className='subcat-shows'>
                <div className={'left-side-menu-list'}>
                  <ul>
                    {
                      wrapperExamList && wrapperExamList.length > 0 && wrapperExamList.map((item, index) => {
                        if (allCoursesList[item.id] && allCoursesList[item.id].length && allCoursesList[item.id].length > 0) {
                          return (
                            <li style={(selectedId == item.id.toUpperCase()) ? ({ background: '#f3f3f3', color: "#0e9696", cursor: 'pointer', textTransform: "capitalize" }) : { cursor: 'pointer', textTransform: "capitalize" }}
                              key={index} onClick={(evt) => this.getMainCategory(item.id, evt)}
                              onMouseEnter={(evt) => {this.getMainCategory(item.id, evt); this.props.handleExamChange(item.dpn.replace(" STATE EXAMS", ""));}} data-id={index}
                              ref={input => {
                                this[`textInput${index}`] = input;
                              }} className={'main-course'}>
                              {
                                item.dpn.replace(" STATE EXAMS", "")
                              }
                            </li>
                          )
                        } else {
                          return null;
                        }
                      }
                      )
                    }
                  </ul>
                </div>
                <div span={19} className={'inner-course-display'}>
                  <div span={6} className={'course-display-wrap'} onClick={this.closeMenuOnLink}>
                    {/* <div className={'exam-menu-heading'}><strong>{`${allCoursesArrLabel[this.state.selectedId]} Exams`}</strong></div> */}
                    <ul className={'category-list'}>
                      {
                        this.props.examMenuData && this.props.examMenuData[this.state.selectedId] && this.props.examMenuData[this.state.selectedId].map((it, i) =>
                          <li key={i + 1}>
                            <Link key={i + 3}
                              className={'exam-li'}
                              to={
                                it?.webUrlId ? `/${it?.webUrlId}-exam-kit` : (it.urlSlug ? it.urlSlug :
                                  `/${removeSpacesAndInsertHyphens(it.name).toLowerCase()}-exam-kit`)
                              }
                              onClick = {() => {
                                const obj = {wrapperId: this.state.selectedId, examId: this.state.activeMenu}
                                localStorage.setItem("selectedExam", JSON.stringify(obj));
                                sessionStorage.setItem("ExamCategoryMappingId", this.state.selectedId);
                              }}
                            >
                              {it.name}
                            </Link>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MainMenu;
import React, { useMemo, useState, useEffect } from "react";
import {
  examInstructionPage1Language,
  textSizeClassKeyNames,
  textSizeOptions,
} from "../util";
import { isADDA } from "../../../../../data/utils/helpers";
import GeneralExamInstructions from "./GeneralExamInstructions";

export const ViewLanguageDropdown = ({
  label = "",
  viewLanguage = "",
  languageList,
  viewChangeLanguage,
}) => {
  useEffect(() => {
    const isSelectedLanguageInstructionAvailable = languageList?.find((elem) => elem === viewLanguage)
    if(!isSelectedLanguageInstructionAvailable){
      viewChangeLanguage(languageList[0]);
    }
  }, [languageList])
  
  return (
    <div className="language-dpn">
      <label htmlFor="v-language">{label}:</label>
      <select
        id="v-language"
        className="language-select"
        value={viewLanguage}
        onChange={(e) => viewChangeLanguage(e.target.value)}
      >
        {languageList?.map((lg) => (
          <option value={lg} key={lg}>
            {lg}
          </option>
        ))}
      </select>
    </div>
  );
};
export const ChangeTextSize = ({
  selectedTextSize = textSizeOptions[1],
  handleChangeTextSize = () => null,
}) => {
  return (
    <div className="textSize-dpn">
      Text Size: 
      <select
        id="textSize"
        value={selectedTextSize}
        onChange={(e) => handleChangeTextSize(e.target.value)}
      >
        {textSizeOptions.map((textSize) => (
          <option
            value={textSize}
            key={textSize}
            className={`${textSizeClassKeyNames[textSize]} ${
              textSize === selectedTextSize ? "active-opt" : ""
            }`}
          >
            {textSize}
          </option>
        ))}
      </select>
    </div>
  );
};

const ExamInstructionTextContent = ({ instructionPageNo = 1, data = {} }) => {
  const [selectedTextSize, setSelectedTextSize] = useState(textSizeOptions[2]);
  const [viewInsLanguage, setViewInsLanguage] = useState("");
  const viewChangeLanguage = (lg) => {
    setViewInsLanguage(lg);
  };
  const lg = instructionPageNo === 1 ? [...examInstructionPage1Language] : [];

  const languageList = useMemo(() => {
    // make langlist dynamically
    Object.keys(data)?.forEach((key) => {
      if (["meta", "orgName"].includes(key) || !data[key]) {
        return;
      }
      if (instructionPageNo === 2) lg.push(key);
    });

    
    return lg;
  }, [data, instructionPageNo]);

  useEffect(() => {
    // set 0 index as default lang state
    setViewInsLanguage(lg[0]);
  }, [])

  const otherInstruction = useMemo(() => {
    if (data[viewInsLanguage]) return data[viewInsLanguage]?.desc;
  }, [viewInsLanguage]);

  const handleChangeTextSize = (textSize = "") => {
    setSelectedTextSize(textSize);
  };
  const renderHtmlContent = () => {
    return (
      <div
        className={`inssBodyContent ${
          instructionPageNo === 2 ? "inssBodyContent2" : ""
        } ${textSizeClassKeyNames[selectedTextSize]}`}
      >
        <div className="ins-detail">
          {instructionPageNo === 1 && (
            <GeneralExamInstructions language={viewInsLanguage} data={data}/>
          )}
          {instructionPageNo === 2 && (
            <div dangerouslySetInnerHTML={{ __html: otherInstruction }} />
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="ins-dpns">
        {isADDA && <ChangeTextSize
          selectedTextSize={selectedTextSize}
          handleChangeTextSize={handleChangeTextSize}
        />}
        <ViewLanguageDropdown
          label="View in"
          languageList={languageList}
          viewLanguage={viewInsLanguage}
          viewChangeLanguage={viewChangeLanguage}
        />
      </div>
      {renderHtmlContent()}
    </div>
  );
};

export default ExamInstructionTextContent;

const tabFacetsMap = {
  "Exam Category": "courses",
  "Product Category": "categories",
};

const facetTypeKeyName = {
  categories: "categoryFacets",
  courses: "coursesFacets",
};
const initialFacetsKeys = {
  categoryFacets: "",
};

export { tabFacetsMap, facetTypeKeyName, initialFacetsKeys };

import React from 'react'
import CatalogCard from '../../../../CatalogCard';
import { formatDateTime } from '../../../../dateUtils';

const MockTestCard = (props) => {
    const {
        testPackageInfo,
        item,
        difficulty,
        test,
        click,
        ActionTextData,
        liveTestPackage=false,
    } = props
    return (
        <div
        key={item?.id}
        className={`catalog-video ${
            testPackageInfo?.expired && test?.status !== "COMPLETED"
            ? "expired-package"
            : ""
        } ${
            testPackageInfo?.expired || test?.status == "COMING_SOON"
            ? "package-disabled"
            : ""
        }  `}
        >
            <CatalogCard
                header={
                <div className="test-cutMarks">
                    {item?.title}
                    {!liveTestPackage && item?.cutOffMarks > 0 && (
                    <div className="cutfmark">
                        <div className="markwrap">
                        Cut Off Marks:
                        <span>
                            {item?.cutOffMarks} / {item?.marks}
                        </span>
                        </div>
                    </div>
                    )}
                </div>
                }
                text={
                <div className="test-info">
                    <div className="test-info-details">
                    {test?.status == "COMING_SOON" ? (
                        item?.commingSoonDate ? (
                        <span>
                            Mock Live on{" "}
                            {formatDateTime(
                            item?.commingSoonDate,
                            "DD MMM YYYY"
                            )}
                        </span>
                        ) : ""
                    ) : (
                        <>
                        <span>
                            {" "}
                            {difficulty} {"  "}
                        </span>
                        <span> {item?.noOfQuestions} Questions</span>
                        <span className="dot"></span>
                        <span>{item?.timeLimit / 60} Minutes</span>
                        </>
                    )}
                    </div>
                    <div className="test-actions">
                    {/* Ristrict reattempt flow for new questionType for now,implement in next sprint */}
                    {test.status === "COMPLETED" && (
                        <div
                        className="test-info-action"
                        onClick={() => click("REATTEMPT")}
                        >
                        <span className={`not_started test-btn`}>
                            Re-Attempt
                        </span>
                        </div>
                    )}
                    <div
                        className="test-info-action"
                        onClick={() => click()}
                    >
                        {ActionTextData?.actionItem}
                    </div>
                    </div>
                </div>
                }
                liveTestPackage={liveTestPackage || false}
                liveTestCutoff={liveTestPackage ? item?.cutOffMarks || 0 : 0}
            />
        </div>
    )
}

export default MockTestCard;

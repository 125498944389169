import React from "react";

const SkipSection = ({
  responseStatusArray,
  sectionInfoObj,
  countQuestionsByStatus,
  close,
  skipSection,
}) => {
  const skipAndClose = () => {
    skipSection();
    close();
  };
  let currentResponseStatusList = responseStatusArray.slice(
    sectionInfoObj.initIndex,
    sectionInfoObj.initIndex + sectionInfoObj.sectionInfo.secTotalq
  );
  return (
    <div className="skip-section-body">
      <div className="test-response-details">
        <div className="header">
          <span>Test Summary</span>
          <span>Your responses are saved successfully!</span>
        </div>
        <ol className="skip-section-info">
          <li>Time remaining for the current section will be lost.</li>
          <li>you will not be able to comeback to this section.</li>
        </ol>
        <div className="tableoverlay">
          <table>
            <thead>
              <tr>
                <td>Section</td>
                <td>Attempted</td>
                <td>Skipped</td>
                <td>Not Visited</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              <td>{sectionInfoObj.sectionInfo.secDN}</td>
              <td> {countQuestionsByStatus(currentResponseStatusList, 2)}</td>
              <td> {countQuestionsByStatus(currentResponseStatusList, 1)}</td>
              <td> {countQuestionsByStatus(currentResponseStatusList, 0)}</td>
              <td>{currentResponseStatusList?.length}</td>
            </tbody>
          </table>
        </div>
      </div>
      <div className="skip-section-action">
        <button onClick={close}>Cancel</button>
        <button onClick={skipAndClose}>Change Section</button>
      </div>
    </div>
  );
};
export default SkipSection;
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { videoPackageUrl } from "../../../constants/appConfig";
import { addScripts, removeScripts } from "../../data/utils/helpers";
import CustomLoader from "../library/CustomLoader";
import CustomModal from "../library/CustomModal";
import VideoPlayer from "../library/VideoPlayer/VideoPlayer";
import ShakaPlayer from "../shakaPlayer";

const cookie = new Cookies();
const PPCContinueLiveClassPopup = ({
  visible = false,
  onCancel,
  showVideoLoad,
  videoConsumptionData = {},
}) => {
  console.log("videoConsumptionData", videoConsumptionData);
  const [videoJsOPtion, setvideoJsOptions] = useState({});
  const [selectedVideo, setSelectedVideo] = useState("");
  const [progressApiFlag, setProgressApiFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showLicenseExpiredPopUp, setShowLicenseExpiredPopUp] = useState(false);
  let { packageId, dashUrl, hlsUrl, url, classId, name, webDrmEnabled } =
    videoConsumptionData;
  const token = cookie.get("cp_token");

  const setVideoJsOption = (url, srcPackageId) => {
    setSelectedVideo(url);
    let substring = "s3.ap-south-1.amazonaws.com";
    let boolean = url.indexOf(substring) !== -1;
    if (boolean) {
      const videoOptions = {
        controls: true,
        autoplay: true,
        preload: "auto",
        loop: false,
        manualCleanup: true,
        playbackRates: [0.5, 1, 1.5, 2],
        techOrder: ["html5"],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
            withCredentials: true,
          },
        },
        sources: [
          {
            src: `${videoPackageUrl.liveclassVideoSource}?vp=${url}&pkgId=${srcPackageId}&isOlc=true`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setvideoJsOptions(videoOptions);
    } else {
      let str = url.replace(
        "https://youtu.be/",
        "https://www.youtube.com/embed/"
      );
      let urlstr = str + "?rel=0&amp;modestbranding=1&amp;showinfo=0";
      const videoOptions = {
        controls: true,
        autoplay: true,
        preload: "auto",
        loop: false,
        manualCleanup: true,
        techOrder: ["youtube"],
        sources: [
          {
            src: `${urlstr}`,
            type: "video/youtube",
          },
        ],
      };
      setvideoJsOptions(videoOptions);
    }
  };

  useEffect(() => {
    addScripts();
    return () => {
      // remove JS Scripts;
      removeScripts();
    };
  }, []);

  useEffect(() => {
    if (url && packageId) {
      setVideoJsOption(url, packageId);
    }
  }, [url, packageId]);
  const getSelectedVideo = () => {
    return selectedVideo;
  };

  const rejoin = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    },[100]);
  }
  return (
    <div className="ppc-live-class-popup">
      <CustomModal
        modalClassName={"modalZindex largemodal"}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closeCallback={onCancel}
        closable={true}
        maskClosable={false}
        keyboard={false}
        title={name}
        bodyStyle={{ overflowY: "auto" }}
      >
        <div className={`${showVideoLoad ||  !webDrmEnabled ?'video-height':''}`}>
          {showVideoLoad ? (
            <CustomLoader />
          ) : (
            <>
              {webDrmEnabled ? (
                !refresh && <ShakaPlayer
                  url={dashUrl}
                  hlsUrl={hlsUrl}
                  contentType={"Conceptual"}
                  packageId={packageId}
                  id={classId}
                  classId={classId}
                  isOlc={true}
                  isBookMarked={false}
                  isCompleted={false}
                  getSelectedVideo={() => getSelectedVideo()}
                  setProgressApi={(s) => setProgressApiFlag(s)}
                  progressFlag={progressApiFlag}
                  setShowLicenseExpiredPopUp = {setShowLicenseExpiredPopUp}
                />
              ) : (
                <VideoPlayer
                  videoOptions={videoJsOPtion}
                  packageId={packageId}
                  id={classId}
                  demoUrl={false}
                  token={token}
                  isOlc={false} // pass isOlc as false so resume GET videoStatus and POST videoStatus code work in player.
                  topicName={""}
                />
              )}
            </>
          )}
        </div>
      </CustomModal>
      {showLicenseExpiredPopUp && <div className="mock-comman-pop result-pop">
        <div className="mock-comman-pop-content" style={{ minWidth: "200px", padding: "16px" }}>
          <div className="mock-header-popup">
            <div className="mockpopheader-title" style={{ color: "red" }}>Sorry for the interruption</div>
          </div>
          <div className="result-await-timer-title" style={{ color: "black", marginBottom: "15px" }}>We need to reverify the credentials. Please reload to resume playing</div>
          <span style={{ color: "green", cursor: "pointer" }} onClick={() => { setShowLicenseExpiredPopUp(false); rejoin() }}>Reload</span>
        </div>
      </div>
      }
    </div>
  );
};

export default PPCContinueLiveClassPopup;

import React, { useEffect, useState } from "react";
import { countByStatus } from "./util";
import {resultInfoForSpecialTest} from '../../../utils'
import { testWithOnlyDtb, toQueryString } from "../../../../data/utils/helpers";
import { useHistory, useLocation } from "react-router";
const TestResponseDetails = ({
  specialTestObject,
  testData,
  responseStatusList,
  sectionStartIndexObj,
  title,
  packageId,
  mappingId,
  goTo,
  isPPc,
  freeContentFlag,
  isFixedMock,
  isAlreadySubmited = false,
  sfJson=''
}) => {

  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();
  const {search} = useLocation();
  const urlParams = new URLSearchParams(search);
  const examName = urlParams.get("exam"); // taged exam name with test.

  const viewResult = () => {
    if(disableButton){
      return;
    }
    try {
      setDisableButton(true);
      testWithOnlyDtb(packageId, mappingId).then((res) => {
        const freeCourseContent = !!urlParams.get("freeCourseContent");
        const queryObj = {};
        if (freeContentFlag) {
          queryObj["freeContent"] = true;
        }
        if (sfJson) {
          queryObj["sfJsonPreview"] = sfJson;
        }
        if(examName){
          queryObj['exam'] = examName
        }
        const testAnalysisUrl = `/my-test-analysis/${title}/${packageId}/${mappingId}`;
        if (res?.isOnlyDtb) {
          const url = `${testAnalysisUrl}?${toQueryString({...queryObj, dtb: true})}`;
          if(freeCourseContent){
            history.replace(url);
          }else{
            window.open(url);
          }
        } else {
          if(isFixedMock){
            queryObj["fixedMockResult"] = true;
          }
          const url = isPPc ? `${testAnalysisUrl}?${toQueryString({...queryObj, ppc: true})}` : `${testAnalysisUrl}?${toQueryString(queryObj)}`;
          if(freeCourseContent){
            history.replace(url);
          }else{
            goTo(url);
          }
        }
        setDisableButton(false);
      }).catch((err) => {
        console.log("err: ", err);
        setDisableButton(false);
      })
    } catch (err) {
      console.log("err: ", err);
    }
    ;
  };
  let totalCount = countByStatus(responseStatusList);
  let showResultInfo ={resultOnline:true};
  if(specialTestObject && specialTestObject[packageId] && specialTestObject[packageId][mappingId]){
    showResultInfo=resultInfoForSpecialTest(specialTestObject[packageId][mappingId])
  }
  return (
    <div className="test-response-details">
      <div className="header">
        <span>Test Summary</span>
        <span>{isAlreadySubmited ? 'You have already submitted the test!':'Your responses are saved successfully!'}</span>
      </div>
      {!isAlreadySubmited && (
        <div>
          <table>
            <thead>
              <tr>
                <td>Section</td>
                <td>Attempted</td>
                <td>Skipped</td>
                <td>Not Visited</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
              {testData.meta.sections.map((section, index) => {
                let startIndex = sectionStartIndexObj[index];

                let sectionResStatusList = responseStatusList.slice(
                  startIndex.initIndex,
                  startIndex.initIndex + section.secTotalq
                );
                let info = [];
                let statusCount = countByStatus(sectionResStatusList);

                info.push(<td>{section.secDN}</td>);
                info.push(<td>{statusCount[2] + statusCount[4]}</td>);
                info.push(<td>{statusCount[3] + statusCount[1]}</td>);
                info.push(<td>{statusCount[0]}</td>);
                info.push(<td>{section.secTotalq}</td>);

                return <tr>{info}</tr>;
              })}
              <tr>
                <td>Total</td>
                <td>{totalCount[2] + totalCount[4]}</td>
                <td>{totalCount[3] + totalCount[1]}</td>
                <td>{totalCount[0]}</td>
                <td>{testData.meta.totalq}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
     
      <div className={`view-result ${showResultInfo.resultOnline?'':'vr-disabled'}`} onClick={showResultInfo.resultOnline?viewResult:''}>
        {showResultInfo.resultOnline?'VIEW RESULT':showResultInfo.text}
      </div>
    </div>
  );
};
export default TestResponseDetails;

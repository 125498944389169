import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CustomButton from './CustomButton';
import LeftPanelLoginModal from './LeftPanelLoginModal';

const ResetPasswordModalNew = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const history = useHistory();

  const handleLogout = () => {
    props.onCancel();
    var eventFired = new CustomEvent("userLogoutCE");
    document.dispatchEvent(eventFired);
    history.push("/#show-login");
    // window.location.replace('/#show-login')
  };
  return (
    <div closeCallback={props.onCancel} className={"main-login-wrap"}>
      <div class="main-login-content-wrap ">
        <LeftPanelLoginModal />
        <div class="right-panel-login">
          <div class="rightpanel-header">
            <div class="back-tn-panel" onClick={props.onCancel}><img src="/images/login-pop-back-btn.svg" /></div>
            <div class="right-cross-icon" onClick={props.onCancel}><img src="/images/login-pop-cross-btn.svg" /></div>
          </div>
          <div className="login-data-wrapper">
            {
              props.resetPasswordSuccess ? <section class="reset-succcess">
                <div class="success-check-icon">
                  <img src="/images/success.svg" />
                </div>
                <div class="login-heading">Congratulations.</div>
                
                <div class="sucess-msg-rest">Your password has been reset successfuly</div>
                <div class="right-panel-form-section">
                  <div class="primary-red-btn ripple" onClick={handleLogout}>Log in &rarr;</div>
                </div>
              </section> : <section className='reset-screen'>
                <div className="login-heading">Forgot password ?</div>
                <div className="top-subheading">Please enter your new password</div>
                <form className='right-panel-form-section' autoComplete='off'>
                  <div class="email">
                    <input
                      type={showPassword ? "text" : 'password'}
                      onChange={(e) => props.handleNewPassword(e)}
                      size='large'
                      placeholder=' '
                      onKeyDown={props.handleEnterInResetPassword}
                      value={props.newPassword}
                      className={props.showNewPasswordError ? 'wrongpass a-input mb-2' : 'a-input mb-2'}
                    />
                    <label class="floating-label">Enter your New Password</label> 
                    <div class="eye-pass" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <img src="/images/crossed_eye.svg" alt="" /> : <img src="/images/show-pass-eye-icon.svg" alt="" />}</div>
                
                    {
                      props.showNewPasswordError &&
                      <div className={'wrongpass-msg'}>Password should be between 6 and 20 characters</div>
                    }
                  </div>
                  <div class="password">
                    <input
                      type={showPasswordNew ? 'text' : 'password'}
                      placeholder=' '
                      style={{"margin-bottom":"0px"}}
                      onChange={(e) => props.handleConfirmPassword(e)}
                      size='large'
                      onKeyDown={props.handleEnterInResetPassword}
                      value={props.confirmPassword}
                      className={props.showConfirmPasswordError ? 'wrongpass a-input' : 'a-input'}
                    />
                    <label class="floating-label">Confirm your Password</label>
                    <div class="eye-pass" onClick={() => setShowPasswordNew(!showPasswordNew)}>{showPasswordNew ? <img src="/images/crossed_eye.svg" alt="" /> : <img src="/images/show-pass-eye-icon.svg" alt="" />}</div>
                    {
                      props.showConfirmPasswordError &&
                      <div className={'wrongpass-msg'}>Passwords do not match</div>
                    }
                    {
                      !props.showConfirmPasswordError && !props.showNewPasswordError && props.showSpacePasswordError &&
                      <div className={'wrongpass-msg'}>Your password can’t start or end with a blank space</div>

                    }
                  </div>
                  {/* <div onClick={props.resetPasswordMethod} loading={props.showLoader} class="primary-red-btn ripple">Reset Password</div> */}
                  <CustomButton
                    type="primary"
                    className={"primary-red-btn ripple"}
                    buttontext={"RESET PASSWORD"}
                    loading={props.showLoader}
                    onClick={props.resetPasswordMethod}
                  />
                </form>
              </section>
            }


          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModalNew;
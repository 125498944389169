import React, { useEffect, useState } from "react";
import {
  dfpSlotsForMobilePDP,
  dfpSlotsForWebPDP,
} from "../../../constants/appConstants";
import { getDeviceType } from "../../data/utils/helpers";

const DfpAdPdp = React.memo((props) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);
  useEffect(() => {
    const url = "https://ads.google.com";
    fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    }).catch((err) => {
      setAdBlockDetected(true);
    });
  }, []);
  useEffect(() => {
    if (adBlockDetected) {
      // let bannerElement = document.getElementById("ROS_Banner");
      // bannerElement[0].style.minHeight = "0px";
      let breadcrumbElement = document.getElementsByClassName(
        "breadcrumb-container"
      );
      let bannerElement = document.getElementsByClassName("banner-container");
      if (bannerElement[0]) {
        bannerElement[0].style.minHeight = "0px";
        var cDiv = bannerElement[0].children;
        for (var i = 0; i < cDiv.length; i++) {
          if (cDiv[i].tagName == "DIV") {
            //or use toUpperCase()
            cDiv[i].style.height = "0px"; //do styling here
          }
        }
      }
      if (breadcrumbElement[0]) {
        breadcrumbElement[0].style.marginTop = "0px";
      }
      // var cDiv = bannerElement[0].children;
      // for (var i = 0; i < cDiv.length; i++) {
      //   if (cDiv[i].tagName == "DIV") {
      //     //or use toUpperCase()
      //     cDiv[i].style.height = "0px"; //do styling here
      //   }
      // }
    }
  }, [adBlockDetected]);

  let divId = "div-gpt-ad-1577441964124-" + parseInt(Math.random() * 10000);
  if (typeof window == "undefined") {
    return null;
  }

  let dfpSlot = [];
  if (getDeviceType() !== "d") {
    dfpSlot = props.dfpSlot?.mweb || dfpSlotsForMobilePDP[0];
    divId = props.dfpSlot?.mweb?.id || divId
  } else {
    dfpSlot = props.dfpSlot?.web || dfpSlotsForWebPDP[0];
    divId = props.dfpSlot?.web?.id || divId
  }

  useEffect(() => {
    const googletag = window.googletag;
    if (googletag) {
      googletag.destroySlots();
      googletag.cmd = googletag.cmd || [];
      googletag.cmd.push(function () {
        if (typeof googletag != "undefined") {
          googletag
            .defineSlot(dfpSlot.path, dfpSlot.size, divId)
            .addService(googletag.pubads());
            googletag.pubads().collapseEmptyDivs(true);
          // googletag.pubads().enableSingleRequest();
          googletag
            .pubads()
            .setTargeting(
              props.keyDfpAdPdp ? props.keyDfpAdPdp : "CategoryListing",
              props.category ? [props.category] : [""]
            );
          googletag
            .pubads()
            .setTargeting(
              "packageid",
              props.packageId ? [props.packageId] : [""]
            );
          googletag.enableServices();
          googletag.pubads().refresh();
        }
      });
      googletag.cmd.push(function () {
        googletag.display(divId);
      });
      return () => {
        googletag.destroySlots();
      };
    }
  }, []);
  return (
    <div
      id={divId}
      style={{ width: `${dfpSlot.size[0]}px`, height: `${dfpSlot.size[1]}px` }}
    />
  );
});

export default DfpAdPdp;

import React, { useState, useRef, useEffect } from 'react';
import Chevron from './Chevron';
import { getSubstringAfterChar, getSubstringBeforeChar, getUrlPathObject, getUrlPath, openWidget } from '../../data/utils/helpers';
import { STOREFRONT_URI } from '../../../constants/appConfig';


function CustomAccordion(props) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');
  const [activeMenu, setActiveMenu] = useState("");
  const content = useRef(null);
  function toggleAccordion(title = null) {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    );
  }
  const gotoLink = (url, itemTitle) => {
    if (props.footer) {
      props.trackEvent(itemTitle, props.title, url)
    }

    if (url === `${STOREFRONT_URI}/my-ebooks`) {
      props.history.push({
        pathname: "/my-purchase",
        state: "EBOOKS",
      })
      !props.footer && props.close();
    }
    else if (url === `${STOREFRONT_URI}/my-3d-models`) {
      props.history.push({
        pathname: "/my-purchase",
        state: "MODELS_3D",
      })
      !props.footer && props.close();
    }
    else if (url === `${STOREFRONT_URI}/my-videos`) {
      props.history.push({
        pathname: "/my-purchase",
        state: "VIDEOS",
      })
      !props.footer && props.close();
    }
    else if (url == `${STOREFRONT_URI}/my-live-class`) {
      props.history.push({
        pathname: "/my-purchase",
        state: "ONLINE_LIVE_CLASSES",
      })
      !props.footer && props.close();
    }
    else if (url == `${STOREFRONT_URI}/my-mock-test`) {
      props.history.push('/my-mock-test')
      !props.footer && props.close();
    }
    else if (props.isLink) {
      props.history.push(getUrlPathObject(url));
    }
    else {

      props.isPaytmMini ? window.open(`${url}?utm_source=paytm`, "_self") : window.open(getUrlPath(url))
    }
    !props.footer && props.close();

  }

  return (
    <>
      <div className="mb-exam-menu-btn">
        {
          props.icon && (<div className="mb-exam-menu-logo"><img src={props.icon} alt="" /></div>)
        }
        {
          <button className={`accordion new_accordion ${setActive}`} style={props.bodyStyle} onClick={toggleAccordion}>
            <p className="accordion__title">{props.title}</p>
            <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
          </button>
        }
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={'accordion__content'}
      >
        {Array.isArray(props.content) && props.content.map(q => {
          return (
            <>
              {
                (q == "FAQs#faqs") ? (<div className={`accordion__child ${props.customChildClass ? props.customChildClass : ''}`} onClick={() => openWidget()}>FAQs
                </div>) : (<div className={`accordion__child ${props.customChildClass ? props.customChildClass : ''}`}
                  onClick={() => gotoLink(getSubstringAfterChar(q, '#'), getSubstringBeforeChar(q, '#'))}>
                  {getSubstringBeforeChar(q, '#')}
                </div>)

              }
            </>
          );
        })
        }

      </div>
    </>
  );
}

export default CustomAccordion;
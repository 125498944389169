import React, { useState } from 'react';

const ATooltip = ({ content, ...props }) => {
    // const [visible,setVisible]= useState(false);


    return (
        <div className='atooltip'
        >
            {props.children}
            {/* <div className={`atooltip-content ${visible?'':'atooltip-hidden'}`}> */}
            <div className={`atooltip-content`}>
                {content}
            </div>

        </div>

    )

}
export default ATooltip;
import React from 'react';
import CustomLoader from './CustomLoader';
import { pageName } from '../../../constants/appConfig';

const CustomButton = (props) => {
    return (
        
        <div data-testid="custom-button" className={props.classes ? props.classes: props.disabled ? ' custom-button-disabled custom-button': "custom-button"} {...props}  >
            {props.customicon &&
            <img alt={pageName[0]} src={props.customicon} title={pageName[0]}/>
            }
            {
                props.loading ? (<CustomLoader buttonSpinner={true} />) : (<span className={'button-text'}>{props.buttontext}</span>)
            }
        </div>
    );
};

export default CustomButton;
import React, { useState, useEffect } from "react";
import {
  makeUrl,
  // getDeviceType,
  getMoengageMetadata,
  getPdpUrl,
  createOptimizedImgUrl,
} from "../../data/utils/helpers";

import { FallBackImage, pageName } from '../../../constants/appConfig';
import { Link } from "react-router-dom";
import ContineInApp from "../library/ContinueInApp/ContinueInApp";

import TestimonialCarouselNew from "../library/TestimonialCarouselNew";
let coursepurchaseContainer = React.createRef();
const Testimonials = (props) => {
  const [centerIndex, setCenterIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [slickCenter, setSlickCenter] = useState("");
  const [data, setData] = useState([]);
  const testimonials = [
    {
      username: "Sunny Shivram Gedam",
      user_img: `https://${pageName[0]}.com/images/testimonail-img1.png`,
      selected_in: "Selected As IBPS Clerk 2021",
      user_msg:
        "I have recently qualified for IBPS Clerk and got selected in Public Sector Bank i.e. Bank of India. I would especially like to mention the name of Ashish Gautam Sir, his daily teaching of GA and also his motivational words at the start of every class kept me motivated each and every day to do more and more practice and study.",
    },
    {
      username: "Babita Choudhary",
      user_img: `https://${pageName[0]}.com/images/testimonail-img2.png`,
      selected_in: "Selected As IBPS SO 2021",
      user_msg:
        `I recently qualified for IBPS SO. I worked in a private sector bank and during that period I decided to crack Banking exams. I started preparing in 2019. A Big thanks to the ${pageName[1]} team. The mock test that they provide helped me a lot, the questions are of very good quality and the daily quizzes are of great help to boost speed.`,
    },
    {
      username: "Nishad More",
      user_img: `https://${pageName[0]}.com/images/testimonail-img3.png`,
      selected_in: "Selected As IBPS PO 2021",
      user_msg:
        `The initial days of my preparation were quite hard because of the Covid scenario. I came across ${pageName[2]} and saw its faculty and study materials and that too at the most affordable price. So, I subscribed to the Adda mahapack and started studying in a planned manner. I am extremely thankful to all the ${pageName[2]} faculties and team.`,
    },
    {
      username: "Aneel Taxak",
      user_img: `https://${pageName[0]}.com/images/testimonail-img4.png`,
      selected_in: "Selected As IBPS Clerk 2021",
      user_msg:
        `I started my banking preparation in Feb 2020 with ${pageName[2]}. I cleared all my prelim exams (IBPS, RRB, and SBI), but failed in the Mains exam. I feel nervous many times but never give up on preparations. ${pageName[2]} has the best team to crack the bank exam. I specially thanks to my best teacher Shantanu sir, Sourav sir, and Ashish sir for my success.`,
    },
    {
      username: "Sandeep Poharkar",
      user_img: `https://${pageName[0]}.com/images/testimonail-img5.png`,
      selected_in: "Selected As LIC ADO, IBPS Clerk & IBPS SO",
      user_msg:
        `I started my preparation for the banking exam in 2016. After many failures, I am selected in LIC ADO in 2019. After 9 months, I also cracked IBPS CLERK in Punjab national bank. In April 2022, I cracked IBPS SO In Rajbhasa Adhikari scale 1. I practised daily on ${pageName[1]} daily bases approx 8-10 hours daily practice. Never lose hope, keep practising.`,
    },
  ];

  const getDeviceType = () => {
    if (typeof navigator !== "undefined") {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "m";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) && screen.width <= 767) {
        return "s";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) && screen.width > 767) {
        return "m"
      }
      return "d";
    }
  };

  useEffect(() => {
    setData(testimonials);
    if (typeof navigator !== "undefined") {
      setIsMobile(getDeviceType() === "s");
    }
    if (getDeviceType() === "d") {
      setCenterIndex(1);
      setSlickCenter("slick-center");
    } else {
      setSlickCenter("");
    }
  }, [typeof screen !== "undefined" ? screen.width : null]);

  useEffect(() => {
    if (data && data.length) {
      let options = {
        root: document.getElementById(`testimonials`),
        rootMargin: "0px",
        threshold: 0.5,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById(`testimonials-last-item`);
      if (target) observer.observe(target);
    }
  }, [data && data.length]);

  const [appModaldata, setModalAppdata] = useState();
  const [pageNo, setPageNo] = useState(-1);
  const renderTestimonialContent = () => {
    return (
      <>
        {data.map((value, index) => {
          let moengageEvent = () => {
            var payload = {};
            payload.id = value.id;
            payload.name = value.title;
            payload.package_status = "Paid";
            payload.category = value.categories;
            payload.exam_category = value.courses;
            payload.package_url =
              typeof window != undefined &&
              window.location.origin +
              getPdpUrl(
                value.categories,
                value.id,
                value.title,
                value.slugURL
              );
            if (typeof Moengage !== "undefined")
              Moengage.track_event(
                "Purchased_Course_clicked",
                getMoengageMetadata(payload)
              );
          };

          let onCardClick;
          let linkurl;
          if (value.categories == "BOOKS") {
            onCardClick = () => {
              alert("Please check my order section for Book");
            };
          } else {
            linkurl = value.linkedPkgs
              ? `/my-purchase/${value.id}/${makeUrl(
                value.slugURL || value.title
              )}`
              : `/my-purchase/0/package/${value.id}/${makeUrl(
                value.slugURL || value.title
              )}`;

            if (getDeviceType() == "m") {
              linkurl = value.linkedPkgs
                ? `/my-purchase/${value.id}/${makeUrl(
                  value.slugURL || value.title
                )}`
                : "";
              onCardClick = () => {
                setModalAppdata({
                  packageId: value.id,
                  type: value.categories,
                });
              };
            }
          }
          return (
            <div
              className={`mypurchase continue-reading paid-product-box testimonial-box ${!isMobile && index == centerIndex ? "slick-center" : ""
                }`}
              key={value?.username}
            >
              <Link
                to={linkurl && linkurl}
                onClick={() => {
                  !linkurl ? onCardClick() : null;
                  moengageEvent();
                }}
                id={
                  index == data.length - 3
                    ? `testimonials-last-item`
                    : `testimonials-${index}`
                }
              >
                <div
                  className={`textcontainer paid-content-wrap test-custom`}
                  id={`testimonial_${index}`}
                >
                  <div className="user-detail">
                    <div className="user-img">
                      <img
                        src={createOptimizedImgUrl(value.user_img || FallBackImage, 66)}
                        loading="lazy" alt='avatar' width='66px' height='66px' />
                    </div>
                    <div className="user-name-wrap">
                      <div className="username">{value.username}</div>
                      <div className="selectedin">{value.selected_in}</div>
                      <div className="star-rating">
                        <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                        <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                        <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                        <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                        <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div className="user-msg-wrap">
                    <div className="user-msg">{value.user_msg}</div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </>
    )
  }
  if (data.length == 0) return null;
  return (
    <>
      <section className="container-padding testimonial">
        <ContineInApp
          show={!!appModaldata}
          type={appModaldata && appModaldata.type}
          packageId={appModaldata && appModaldata.packageId}
          parentpackageId={""}
          close={() => {
            setModalAppdata("");
          }}
        />
        <div className="d-flex my-purchase-header">
          <div className="common-headding">Testimonials</div>
          <div
            className="my-purchase-viewall"
            onClick={() => {
              if (typeof Moengage !== "undefined")
                Moengage.track_event(
                  "My_Purchased_Courses_Viewall_btn_clicked",
                  getMoengageMetadata({})
                );
              props.goTo("my-purchase");
            }}
          ></div>
        </div>
        {
          // isMobile ? <TestimonialsCarousel
          //   id={`testimonials`}
          //   ref={coursepurchaseContainer}
          //   margin={10}
          //   setCenterIndex={setCenterIndex}
          //   centerIndex={centerIndex}
          // >

          //   {renderTestimonialContent()}
          // </TestimonialsCarousel>
          //   :

          <div style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
            <TestimonialCarouselNew
              show={getDeviceType() === "s" ? 1 : 3}
              infiniteLoop
              setCenterIndex={setCenterIndex}
              centerIndex={centerIndex}
            >
              {data.map((value, index) => {
                let moengageEvent = () => {
                  var payload = {};
                  payload.id = value.id;
                  payload.name = value.title;
                  payload.package_status = "Paid";
                  payload.category = value.categories;
                  payload.exam_category = value.courses;
                  payload.package_url =
                    typeof window != undefined &&
                    window.location.origin +
                    getPdpUrl(
                      value.categories,
                      value.id,
                      value.title,
                      value.slugURL
                    );
                  if (typeof Moengage !== "undefined")
                    Moengage.track_event(
                      "Purchased_Course_clicked",
                      getMoengageMetadata(payload)
                    );
                };

                let onCardClick;
                let linkurl;
                if (value.categories == "BOOKS") {
                  onCardClick = () => {
                    alert("Please check my order section for Book");
                  };
                } else {
                  linkurl = value.linkedPkgs
                    ? `/my-purchase/${value.id}/${makeUrl(
                      value.slugURL || value.title
                    )}`
                    : `/my-purchase/0/package/${value.id}/${makeUrl(
                      value.slugURL || value.title
                    )}`;

                  if (getDeviceType() == "m") {
                    linkurl = value.linkedPkgs
                      ? `/my-purchase/${value.id}/${makeUrl(
                        value.slugURL || value.title
                      )}`
                      : "";
                    onCardClick = () => {
                      setModalAppdata({
                        packageId: value.id,
                        type: value.categories,
                      });
                    };
                  }
                }
                return (
                  <div
                    // className={`mypurchase continue-reading paid-product-box testimonial-box 
                    // ${!isMobile && index == centerIndex ? "slick-center" : ""}
                    //   `}
                    className={` paid-product-box testimonial-box carousel-card
                      ${index == centerIndex && getDeviceType() != "m" && getDeviceType() != "s" ? slickCenter : ""}`}
                    key={value?.username}
                  >
                    <Link
                      to={linkurl && linkurl}
                      onClick={() => {
                        !linkurl ? onCardClick() : null;
                        moengageEvent();
                      }}
                      id={
                        index == data.length - 3
                          ? `testimonials-last-item`
                          : `testimonials-${index}`
                      }
                    >
                      <div
                        className={`textcontainer paid-content-wrap test-custom`}
                        id={`testimonial_${index}`}
                      >
                        <div className="user-detail">
                          <div className="user-img">
                            <img
                              src={createOptimizedImgUrl(value.user_img || FallBackImage, 66)}
                              loading="lazy" alt='avatar' width='66px' height='66px' />
                          </div>
                          <div className="user-name-wrap">
                            <div className="username">{value.username}</div>
                            <div className="selectedin">{value.selected_in}</div>
                            <div className="star-rating">
                              <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                              <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                              <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                              <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                              <img src="/images/full-star.svg" alt='star-icon' loading="lazy" />
                            </div>
                          </div>
                        </div>
                        <div className="user-msg-wrap">
                          <div className="user-msg">{value.user_msg}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

            </TestimonialCarouselNew>
          </div>
        }
        {/* <div className="dot-wrapper">
          {[...Array(Math.ceil(data.length / 2))].map((el, index) => (
            <div className={`dot ${index === 1 && 'active-dot'}`} />
          ))}
        </div> */}
      </section>
    </>
  );
};

export default Testimonials;

import React, { useEffect, useState } from 'react';
import FixedMockCard from './FixedMockCard';
import { useLocation } from 'react-router';
import { requestHeaders } from '../../data/utils/fetch';
import { FPM_PkgId, hiddenFpmIds, TEST_SERIES_URL, TIMELINE_URI } from '../../../constants/appUrls';
import CustomLoader from '../library/CustomLoader';
import { Link } from 'react-router-dom';
import Pagination from '../library/Pagination';
import { testEventsUrl } from '../../../constants/appConfig';
import { scrollToTop } from '../../data/utils/helpers';
import fetch from '../../data/utils/fetch';
import NoCourse from '../enrolledCourses/NoCourse';
import ContineInApp from '../library/ContinueInApp/ContinueInApp';
import { COURSE_CATEGORY_IDENTIFIERS } from '../../../constants/textConstants';

const FixedMockListing = (props) => {

    const [currentTime, setCurrentTime] = useState(Date.now());
    const [showResultAwaitedPopUp, setShowResultAwaitedPopUp] = useState(false);
    const [showCongratePopUp, setShowCongratePopUp] = useState(false);
    const [resultAwaitedPopUpData, setResultAwaitedPopUpData] = useState({});
    const [registerStatus, setRegisterStatus] = useState({});
    const [quizStatusObject, setQuizStatusObject] = useState({});
    const [loading, setLoading] = useState(false);
    const [flagtoRefreshPage, setFlagtoRefreshPage] = useState(false);
    const [showNetworkPopup, setShowNetworkPopup] = useState(false); 
    const [fixedMockList, setFixedMockList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalTestCount, setTotalTestCount] = useState(20);
    const [activeTestCount, setActiveTestCount] = useState(20);
    const [pastTestCount, setPastTestCount] = useState(0);
    const [loadingQuizState, setLoadingQuizState] = useState(false);
    const [loadingRegisterStatus, setLoadingRegisterStatus] = useState(false);
    const [loadingMergeData, setLoadingMergeData] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [clickedItemData, setClickedItemData] = useState();

    const pageSize = 20;

    const headers = requestHeaders("aplication/json", true, {});
    const location = useLocation();

    useEffect(() => {
        if(props?.noSearchTerm) setSearchTerm("");
        else setSearchTerm(encodeURIComponent(props?.searchTerm) || "");
        setCurrentPage(1);
    }, [props?.searchTerm, props?.noSearchTerm]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, [1000]);

        if (location.state && location.state.isRegistered) {
            handlePopUp(true, false, {});
        }

        return () => { clearInterval(interval) }
    },[]); 

    // for getting past test count
    useEffect(() => {
        let url = `${testEventsUrl.pastEvents}&page=0&size=20&searchTerm=${searchTerm}`
        fetch(url, "GET").then(res => {
            if (res.count || res.count == 0){
                let cnt = res.count;

                // because one page of old test will go in active tests
                if (cnt % 20 != 0){
                    cnt = cnt - cnt%20; 
                }else{
                    if (cnt != 0){
                        cnt = cnt - 20
                    }
                }
                // because one page of old test will go in active tests
                setPastTestCount(cnt);
                setActiveTestCount(20);
            }
        }).catch(err => {
            console.log("err: ", err);
        })
    },[searchTerm])

    useEffect(() => {
        setTotalTestCount(activeTestCount + pastTestCount);
    },[activeTestCount, pastTestCount])

    useEffect(() => {
        scrollToTop(100);
        fetchFixedMockList();
    }, [currentPage, flagtoRefreshPage, searchTerm])

    useEffect(() => {
        if(navigator.onLine && showNetworkPopup){
          setShowNetworkPopup(false);
        }
      },[navigator.onLine]);

    useEffect(() => {
        const fun = () => {
          if (localStorage.getItem("isTestPortalOpened") == "yes"){ 
            // test window opened for fixed mock
            localStorage.setItem("isTestPortalOpened", "no");
            setFlagtoRefreshPage(old => !old); // refresh page to get new data
          }
        }
        window.addEventListener("focus", fun);
    
        return () => {
          window.removeEventListener("focus", fun);
        }
      },[]);

    const fetchQuizState = (mappingIds, isOld = false, mergeFlag = false) => { 
        if (mappingIds.length == 0){
            return;
        }
        setLoadingQuizState(true);
        let url;
        if (!isOld){
            //live and upcoming tests
            url = `${TIMELINE_URI}/fixedMock/status?mappingIds=${mappingIds}`;
        }else{
            url = `${TEST_SERIES_URL}/testseries/statusList?packageId=${FPM_PkgId}&mappingIds=${mappingIds}`
        }
        fetch(url, "GET").then((res) => {
            if (res.success) {
                const statusObj = makeObjectFromQuizStatusList(res.data);
                if (mergeFlag){
                    setQuizStatusObject(old => {return {...old, statusObj} })
                }else{
                    setQuizStatusObject(statusObj)
                }
            }
            setLoadingQuizState(false);
        }).catch((err) => {
            console.log("some error occured: ", err);
            setLoadingQuizState(false);
        })
    }

    const fetchRegisterStatus = (mappingIds) => {
        if (mappingIds.length == 0){
            return;
        }
        setLoadingRegisterStatus(true);
        fetch(`${TIMELINE_URI}/fixedMock/register/status?mappingIds=${mappingIds}`, "GET").then((res) => {
            if (res.success) {
                setRegisterStatus(makeObjectFromRegisterStatusList(res.data));
            }
            setLoadingRegisterStatus(false);
        })
            .catch((err) => {
                setLoadingRegisterStatus(false);
            })
    }


    const fetchAndMergeFromOldTests = () => {
        setLoadingMergeData(true);
        let url = `${testEventsUrl.pastEvents}&page=0&size=20&searchTerm=${searchTerm}`;
        fetch(url, "GET").then(res => {
            if (res.data){
                const newFilteredList = res?.data?.filter(fpm => {
                    let hide = false;
                    hiddenFpmIds?.forEach(hiddenFpmId => {
                        if(fpm.mappingId === hiddenFpmId){
                            hide = true;
                        }
                    })
                    return !hide;
                });
                setFixedMockList(old => old.concat(newFilteredList));
                fetchQuizState(getMappingIdStringForStartedTests(res.data), true, true);
            }
            setLoadingMergeData(false);
        }).catch(err => {
            console.log("err: ", err);
            setLoadingMergeData(false);
        })
    }

    const fetchFixedMockList = () => {
        setLoading(true);
        let url;
        let isOldFlag;
        if (currentPage <= Math.ceil(activeTestCount/20)){
            url = `${testEventsUrl.liveAndUpcoming}&page=${currentPage-1}&size=${pageSize}&searchTerm=${searchTerm}`;
            isOldFlag = false;
        }else{
            url = `${testEventsUrl.pastEvents}&page=${currentPage-Math.ceil(activeTestCount/20)}&size=${pageSize}&searchTerm=${searchTerm}`;
            isOldFlag = true;
        }

        fetch(url, "GET").then(res => {
            if (res.data){
                const filteredList = res?.data?.filter(fpm => {
                    let hide = false;
                    hiddenFpmIds?.forEach(hiddenFpmId => {
                        if(fpm.mappingId === hiddenFpmId){
                            hide = true;
                        }
                    })
                    return !hide;
                });
                setFixedMockList(filteredList);
                fetchQuizState(getMappingIdStringForStartedTests(res.data), isOldFlag);
                fetchRegisterStatus(getMappingIdStringForNotStartedTests(res.data));
            }
            if (currentPage <= Math.ceil(activeTestCount/20)){
                let cnt = 0;
                if (res.count){
                    cnt = res.count;
                }
                let temp = 20 - cnt%20
                if (temp == 20){
                    temp = 0;
                }
                cnt += temp;
                if (cnt == 0){
                    cnt = 20;
                }

                if (currentPage == Math.ceil(cnt/20)){ // if we are on last page of active events
                    fetchAndMergeFromOldTests();
                }
                setActiveTestCount(cnt);
            }
            setLoading(false);
        }).catch(err => {
            console.log("err: ", err);
            setLoading(false);
        })
    }

    const makeObjectFromRegisterStatusList = (data) => {
        let obj = data.reduce((obj, item, i) => {
            obj[item.mappingId] = item.isRegistered;
            return obj;
        },{});
        return obj;
    }

    const makeObjectFromQuizStatusList = (data) => {
        let obj = data.reduce((obj, item, i) => {
            obj[item.mappingId] = {testState: item.testState, userStartTime: item.userStartTime};
            return obj;
        },{});
        return obj;
    }

    const getMappingIdStringForNotStartedTests = (data) => {
        let mappingIds = 
        data.reduce((mappingIds, mock, i) => {
            if (currentTime < mock.fixedMockTestStartTime){
                //not started
                return mappingIds + mock.mappingId + ",";
            }else{
                return mappingIds;
            }
        }, "")
        return mappingIds;
    }

    const getMappingIdStringForStartedTests = (data) => {
        let mappingIds = 
        data.reduce((mappingIds, mock, i) => {
            if (currentTime >= mock.fixedMockTestStartTime){
                //started
                return mappingIds + mock.mappingId + ",";
            }else{
                return mappingIds;
            }
        }, "")
        return mappingIds;
    }

    const handlePopUp = (congrate, resultAwaited, data) => {
        if (congrate) {
            setShowCongratePopUp(true);
        } else if (resultAwaited) {
            setResultAwaitedPopUpData(data);
            setShowResultAwaitedPopUp(true);
        }
    }

    const formatMilliseconds = (milli) => {
        let seconds = Math.floor(milli / 1000);
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        const secString = seconds < 10 ? "0" + seconds : seconds;
        const minString = minutes < 10 ? "0" + minutes : minutes;
        const hourString = hours < 10 ? "0" + hours : hours;
        return `${hourString}:${minString}:${secString}`;
    }

    const changePage = (page) => {
        setCurrentPage(page);
    }

    const handleAppRedirectPopup = (fpmId) => {
        setClickedItemData({
        fpmId: fpmId,
        type: COURSE_CATEGORY_IDENTIFIERS.FPM_IDENTIFIER
        });
        return;
    }

    const renderNoCourse = () => {
        return <NoCourse
        url={props.courseSectionObj?.noItems?.url}
        info={props.courseSectionObj?.noItems?.label}
        buttonText={props.courseSectionObj?.noItems?.buttonText}
        cacheFlag = {props.cacheFlag}
        noDataText = {props.noDataText}
      ></NoCourse>
    }

    return (( loading || loadingQuizState || loadingRegisterStatus || loadingMergeData ) ? 
        
        <CustomLoader /> : 
            fixedMockList.length == 0 ? renderNoCourse() :
            <div className="course-listing">
            <div className="mock-list-wrap">
                <div className="mock-list-content">
                    {
                        fixedMockList?.map((data, index) => {
                            return (
                                <FixedMockCard
                                    data={data}
                                    currentTime={currentTime}
                                    handlePopUp={handlePopUp}
                                    formatMilliseconds={formatMilliseconds}
                                    userRegistered={registerStatus[data.mappingId] ? registerStatus[data.mappingId] : false}
                                    testState={quizStatusObject[data.mappingId] ? quizStatusObject[data.mappingId].testState : ""}
                                    userStartTime = {quizStatusObject[data.mappingId] ? quizStatusObject[data.mappingId].userStartTime : ""}
                                    showNetworkPopup = {setShowNetworkPopup}
                                    handleAppRedirectPopup = {handleAppRedirectPopup}
                                />
                            )
                        })
                    }

                </div>

                <div className="fixedMockPagination" style = {{textAlign:"center"}}><Pagination current = {currentPage} onChange = {changePage} count = {totalTestCount} pageSize = {pageSize}/></div>
            </div>


            {showCongratePopUp && <div className="mock-comman-pop">
                <div className="mock-comman-pop-content">
                    <div className="short-heading-popup">Congratulations!</div>
                    <div className="comman-pop-content">You have been successfully registered.</div>
                    <div className="mock-pop-btn"><a href="#" onClick={() => {setShowCongratePopUp(false) }} className="redcolorbtn">CONTINUE</a></div>
                </div>
            </div>}
            {showResultAwaitedPopUp &&
                <div className="mock-comman-pop result-pop">
                    <div className="mock-comman-pop-content">
                        <div className="mock-header-popup">
                            <div className="mockpopheader-title">{resultAwaitedPopUpData.title}</div>
                            <div className="close-mock-pop" onClick={() => { setShowResultAwaitedPopUp(false) }}><a href="#"><img src="../images/checkout-close.svg" /></a></div>
                        </div>
                        <div className="result-await-pop-img"><img src="../images/mck-empty.svg" /></div>
                        {resultAwaitedPopUpData.testState == "COMPLETED" ? <div className="mock-pop-allready-attemped">You’ve already attempted the test.</div>: <></>}
                        <div className="result-await-pop-title">RESULT AWAITED</div>
                        <div className="result-await-timer-title">Please check back after</div>
                        <div className="result-await-titmer-pop"><img src="../images/ic_timer.svg" />{formatMilliseconds(resultAwaitedPopUpData.resultTime - currentTime)}</div>
                    </div>
                </div>
            }

            {/* No Internet Popup Start */}
            {showNetworkPopup && <div className="mock-comman-pop">
                <div className="mock-comman-pop-content">
                    <div className="short-heading-popup">No Internet</div>
                    <div className="comman-pop-content">Please turn on the internet to submit the test.</div>
                    <div className="mock-pop-btn">
                    <Link to="#" className="redcolorbtn" onClick={() => {setShowNetworkPopup(false)}}>RETRY</Link></div>
                </div>
                </div>}
            {/* No Internet Popup End */}
            <ContineInApp
                show={!!clickedItemData}
                fpmId={clickedItemData && clickedItemData?.fpmId}
                type={clickedItemData && clickedItemData?.type}
                close={() => {
                    setClickedItemData();
                }}
            />
        </div>
    )
          
}

export default FixedMockListing;
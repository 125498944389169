import React from 'react';
import {Route} from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const NotFound = ({message}) => {
    return (
        <Route render={({ staticContext }) => {
            if (staticContext) {
                staticContext.status = 404;
            }
            return null;
            // return (
            //     <div>
            //         <h1>{message || '404 : Not Found'}</h1>
            //     </div>
            // );
        }}/>
    );
};

export default NotFound;
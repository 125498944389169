import CustomModal from "../../library/CustomModal";
import React, { useState, useEffect } from "react";
import fetch from '../../../data/utils/fetch';
import {videoPackageUrl} from '../../../../constants/appConfig';
const MultipleUserNotification = ({setIsViewedOnSingleScreen,isViewedOnSingleScreen}) => {
//   const [isViewedOnSingleScreen, setIsViewedOnSingleScreen] = useState(true);
useEffect(()=>{
  if(isViewedOnSingleScreen){
  checkScreenViewStatus();
  }
  },[isViewedOnSingleScreen])
  const checkScreenViewStatus = count => {
    fetch(
      `${videoPackageUrl.singleView}`,
      "GET",
      undefined,
      undefined,
      undefined,
      { count: count ? count : "" }
    ).then(res => {
      if (res.success) {
        setTimeout(() => {
          checkScreenViewStatus(res.data);
        }, 5000);
      } else {
        setIsViewedOnSingleScreen(false);
      }
    });
  };
  return (
    <CustomModal
    visible={!isViewedOnSingleScreen}
      hideHeader={true}
    //   bodyStyle={{ height: "630px", overflowY: "scroll", overflowX: "hidden" }}
      modalClassName={"multi-view"}
    >
      <div className='mv-body'>
          <div className='mv-title'>Video Paused </div>
          <div className='mv-info'>
        Looks like your account is logged in from another device.

          </div>
          <div className='mv-info'>Please logout from other device and retry.</div>
          {/* <div>Please logout from other device and retry.</div> */}
          <div
           onClick={()=>{setIsViewedOnSingleScreen(true);
            document.body.classList.remove("noscroll");}}
            className={"login-btn zero-state-buy-button mv-button"}
          >
            RETRY
          </div>
      </div>
    </CustomModal>
  );
};

export default MultipleUserNotification;

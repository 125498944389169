import React from "react";
import Loadable from "react-loadable";
import { isADDA } from "../app/data/utils/helpers";

export const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "../app/containers/Home"),
  modules: ["../app/containers/Home"],
  webpack: [require.resolve("../app/containers/Home")],
  loading: () => <div>loading</div>,
});
export const SearchDetailListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SearchDetailListing" */ "../app/containers/SearchDetailListing"
    ),
  modules: ["../app/containers/SearchDetailListing"],
  webpack: [require.resolve("../app/containers/SearchDetailListing")],
  loading: () => <div></div>,
});

export const MyMockTest = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MyMockTest" */ "../app/containers/MyMockTest"),
  modules: ["../app/containers/MyMockTest"],
  webpack: [require.resolve("../app/containers/MyMockTest")],
  loading: () => <div></div>,
});

export const Post = Loadable({
  loader: () => import(/* webpackChunkName: "Post" */ "../app/containers/Post"),
  modules: ["../app/containers/Post"],
  webpack: [require.resolve("../app/containers/Post")],
  loading: () => <div></div>,
});

export const CourseCategoryListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CourseCategoryListing" */ "../app/containers/CourseCategoryListing"
    ),
  modules: ["../app/containers/CourseCategoryListing"],
  webpack: [require.resolve("../app/containers/CourseCategoryListing")],
  loading: () => <div></div>,
});

export const ExamCategoryListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExamCategoryListing" */ "../app/containers/ExamCategoryListing"
    ),
  modules: ["../app/containers/ExamCategoryListing"],
  webpack: [require.resolve("../app/containers/ExamCategoryListing")],
  loading: () => <div></div>,
});

export const ProductDetailListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProductDetailListing" */ "../app/containers/ProductDetailListing"
    ),
  modules: ["../app/containers/ProductDetailListing"],
  webpack: [require.resolve("../app/containers/ProductDetailListing")],
  loading: () => <div></div>,
});

export const CourseDetailListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CourseDetailListing" */ "../app/containers/CourseDetailListing"
    ),
  modules: ["../app/containers/CourseDetailListing"],
  webpack: [require.resolve("../app/containers/CourseDetailListing")],
  loading: () => <div></div>,
});

export const ExamDetailListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExamDetailListing" */ "../app/containers/ExamDetailListing"
    ),
  modules: ["../app/containers/ExamDetailListing"],
  webpack: [require.resolve("../app/containers/ExamDetailListing")],
  loading: () => <div></div>,
});

export const ProductDescription = Loadable({
  loader: isADDA
    ? () =>
        import(
          /* webpackChunkName: "ProductDescription" */ "../app/containers/ProductDetailsNew"
        )
    : () =>
        import(
          /* webpackChunkName: "ProductDescription" */ "../app/containers/ProductDetails"
        ),
  modules: isADDA
    ? ["../app/containers/ProductDetailsNew"]
    : ["../app/containers/ProductDetails"],
  webpack: isADDA
    ? [require.resolve("../app/containers/ProductDetailsNew")]
    : [require.resolve("../app/containers/ProductDetails")],
  loading: () => <div></div>,
});

export const MyEbooks = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MyEbooks" */ "../app/containers/MyEbooks"),
  modules: ["../app/containers/MyEbooks"],
  webpack: [require.resolve("../app/containers/MyEbooks")],
  loading: () => <div></div>,
});
export const My3DModels = Loadable({
  loader: () =>
    import(/* webpackChunkName: "My3DModels" */ "../app/containers/My3DModels"),
  modules: ["../app/containers/My3DModels"],
  webpack: [require.resolve("../app/containers/My3DModels")],
  loading: () => <div></div>,
});

export const MyAccount = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountDescription" */ "../app/containers/MyAccount"
    ),
  modules: ["../app/containers/MyAccount"],
  webpack: [require.resolve("../app/containers/MyAccount")],
  loading: () => <div></div>,
});
export const MyPurchase = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ContainersMyPurchase" */ "../app/containers/MyPurchase"
    ),
  modules: ["../app/containers/MyPurchase"],
  webpack: [require.resolve("../app/containers/MyPurchase")],
  loading: () => <div></div>,
});
export const MyPurchaseList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MyPurchasesMyPurchase" */ "../app/containers/MyPurchases/MyPurchase"
    ),
  modules: ["../app/containers/MyPurchases/MyPurchase"],
  webpack: [require.resolve("../app/containers/MyPurchases/MyPurchase")],
  loading: () => <div></div>,
});
export const SelectYourExams = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SelectYourExamsSelectExams" */ "../app/containers/MyPurchases/SelectYourExams/SelectExams"
    ),
  modules: ["../app/containers/MyPurchases/SelectYourExams/SelectExams"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SelectYourExams/SelectExams"
    ),
  ],
  loading: () => <div></div>,
});
export const WishList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "WishList" */ "../app/containers/WishList/WishList"
    ),
  modules: ["../app/containers/WishList/WishList"],
  webpack: [require.resolve("../app/containers/WishList/WishList")],
  loading: () => <div></div>,
});
export const PackageDetailView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PackageDetailView" */ "../app/containers/MyPurchases/SubModules/PackageDetailView"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/PackageDetailView"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SubModules/PackageDetailView"
    ),
  ],
  loading: () => <div></div>,
});
export const PackageYourExamsView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PackageYourExamsView" */ "../app/containers/MyPurchases/SubModules/PackageYourExamsView"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/PackageYourExamsView"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SubModules/PackageYourExamsView"
    ),
  ],
  loading: () => <div></div>,
});
export const ExamSubPackageView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExamSubPackageView" */ "../app/containers/MyPurchases/SubModules/ExamSubPackageView"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/ExamSubPackageView"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SubModules/ExamSubPackageView"
    ),
  ],
  loading: () => <div></div>,
});

export const ExamOptionsWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExamOptionsWrapper" */ "../app/containers/MyPurchases/SubModules/ExamOptionsWrapper"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/ExamOptionsWrapper"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SubModules/ExamOptionsWrapper"
    ),
  ],
  loading: () => <div></div>,
});

export const ExamAllBatches = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ExamAllBatches" */ "../app/containers/MyPurchases/SubModules/ExamAllBatches"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/ExamAllBatches"],
  webpack: [
    require.resolve("../app/containers/MyPurchases/SubModules/ExamAllBatches"),
  ],
  loading: () => <div></div>,
});

export const SubPackageView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SubpackageView" */ "../app/containers/SubPackageView"
    ),
  modules: ["../app/containers/SubPackageView"],
  webpack: [require.resolve("../app/containers/SubPackageView")],
  loading: () => <div></div>,
});

export const MyVideosListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MyVideosListing" */ "../app/containers/EnrolledCourseContainer"
    ),
  modules: ["../app/containers/EnrolledCourseContainer"],
  webpack: [require.resolve("../app/containers/EnrolledCourseContainer")],
  loading: () => <div></div>,
});

export const MyLiveClassesListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountDescription" */ "../app/containers/EnrolledCourseContainer"
    ),
  modules: ["../app/containers/EnrolledCourseContainer"],
  webpack: [require.resolve("../app/containers/EnrolledCourseContainer")],
  loading: () => <div></div>,
});

export const VideoPackage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "VideoPackage" */ "../app/containers/VideoPackage"
    ),
  modules: ["../app/containers/VideoPackage"],
  webpack: [require.resolve("../app/containers/VideoPackage")],
  loading: () => <div></div>,
});

export const LiveVideoPackage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountDescription" */ "../app/containers/MyLiveClassesVideoPackage"
    ),
  modules: ["../app/containers/MyLiveClassesVideoPackage"],
  webpack: [require.resolve("../app/containers/MyLiveClassesVideoPackage")],
  loading: () => <div></div>,
});
export const MyOrder = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MyOrder" */ "../app/containers/MyOrder"),
  modules: ["../app/containers/MyOrder"],
  webpack: [require.resolve("../app/containers/MyOrder")],
  loading: () => <div></div>,
});

export const TestListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TestListing" */ "../app/components/enrolledCourses/TestSeries/TestsListing"
    ),
  modules: ["../app/components/enrolledCourses/TestSeries/TestsListing"],
  webpack: [
    require.resolve(
      "../app/components/enrolledCourses/TestSeries/TestsListing"
    ),
  ],
  loading: () => <div></div>,
});
export const TestResult = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TestResult" */ "../app/components/enrolledCourses/TestSeries/TestResult"
    ),
  modules: ["../app/components/enrolledCourses/TestSeries/TestResult"],
  webpack: [
    require.resolve("../app/components/enrolledCourses/TestSeries/TestResult"),
  ],
  loading: () => <div></div>,
});
export const TestResultAnalysis = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TestResultAnalysisIndex" */ "../app/components/enrolledCourses/TestSeries/TestResultAnalysis/index"
    ),
  modules: [
    "../app/components/enrolledCourses/TestSeries/TestResultAnalysis/index",
  ],
  webpack: [
    require.resolve(
      "../app/components/enrolledCourses/TestSeries/TestResultAnalysis/index"
    ),
  ],
  loading: () => <div></div>,
});
export const TestPortal = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TestPortal" */ "../app/components/enrolledCourses/TestSeries/TakeTest"
    ),
  modules: ["../app/components/enrolledCourses/TestSeries/TakeTest"],
  webpack: [
    require.resolve("../app/components/enrolledCourses/TestSeries/TakeTest"),
  ],
  loading: () => <div></div>,
});
export const MarsPaymentPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarsPaymentsuccess" */ "../app/components/library/MarsPaymentsuccess"
    ),
  modules: ["../app/components/library/MarsPaymentsuccess"],
  webpack: [require.resolve("../app/components/library/MarsPaymentsuccess")],
  loading: () => <div></div>,
});

export const Support = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Support" */ "../app/containers/Support"),
  modules: ["../app/containers/Support"],
  webpack: [require.resolve("../app/containers/Support")],
  loading: () => <div></div>,
});
export const CartCheckout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CartCheckout" */ "../app/containers/CartCheckout"
    ),
  modules: ["../app/containers/CartCheckout"],
  webpack: [require.resolve("../app/containers/CartCheckout")],
  loading: () => <div></div>,
});
export const JoinImpartus = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "JoinImpartus" */ "../app/containers/JoinImpartus"
    ),
  modules: ["../app/containers/JoinImpartus"],
  webpack: [require.resolve("../app/containers/JoinImpartus")],
  loading: () => <div></div>,
});

export const SubPackage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SubPackage" */ "../app/containers/SubPackage"),
  modules: ["../app/containers/SubPackage"],
  webpack: [require.resolve("../app/containers/SubPackage")],
  loading: () => <div></div>,
});
export const PPCSubPackageView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PPCSubPackageView" */ "../app/containers/MyPurchases/SubModules/PPCSubPackageView"
    ),
  modules: ["../app/containers/MyPurchases/SubModules/PPCSubPackageView"],
  webpack: [
    require.resolve(
      "../app/containers/MyPurchases/SubModules/PPCSubPackageView"
    ),
  ],
  loading: () => <div></div>,
});

export const AddaGoldLanding = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddaGoldLanding" */ "../app/containers/AddaGoldLanding"
    ),
  modules: ["../app/containers/AddaGoldLanding"],
  webpack: [require.resolve("../app/containers/AddaGoldLanding")],
  loading: () => <div></div>,
});

export const ScholarshipMain = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ScholarshipMain" */ "../app/containers/scholarship/ScholarshipMain"
    ),
  modules: ["../app/containers/scholarship/ScholarshipMain"],
  webpack: [require.resolve("../app/containers/scholarship/ScholarshipMain")],
  loading: () => <div></div>,
});

export const NeetRankPredictor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "NeetRankPredictor" */ "../app/containers/RankPredictor/NeetRankPredictor"
    ),
  modules: ["../app/containers/RankPredictor/NeetRankPredictor"],
  webpack: [
    require.resolve("../app/containers/RankPredictor/NeetRankPredictor"),
  ],
  loading: () => <div></div>,
});

export const VeekshaViewer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "VeekshaViewer" */ "../app/containers/VeekshaViewer"
    ),
  modules: ["../app/containers/VeekshaViewer"],
  webpack: [require.resolve("../app/containers/VeekshaViewer")],
  loading: () => <div></div>,
});

export const QuestionPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "QuestionPage" */ "../app/containers/SEO/QuestionPage"
    ),
  modules: ["../app/containers/SEO/QuestionPage"],
  webpack: [require.resolve("../app/containers/SEO/QuestionPage")],
  loading: () => <div></div>,
});

export const SubjectPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SubjectPage" */ "../app/containers/SEO/SubjectPage"
    ),
  modules: ["../app/containers/SEO/SubjectPage"],
  webpack: [require.resolve("../app/containers/SEO/SubjectPage")],
  loading: () => <div></div>,
});


export const OnboardingHelpSection = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "OnboardingHelpSection" */ "../app/components/library/LoginModal/OnboardingHelpSection"
    ),
  modules: ["../app/components/library/LoginModal/OnboardingHelpSection"],
  webpack: [require.resolve("../app/components/library/LoginModal/OnboardingHelpSection")],
  loading: () => <div></div>,
});

import reducerRegistry from "../../reducerRegistry";
import types from "./types";

let initialState = {
  pageNo: 1,
  rankType: 0,
  formInputData: {},
};
// rank
// 0 - All India Level Rank
// 1 - State Level Rank
function rankPredictor(state = initialState, action) {
  switch (action.type) {
    case types.SET_PAGE_NO:
      return {
        ...state,
        pageNo: action.payload.data,
      };
    case types.SET_RANK_TYPE:
      return {
        ...state,
        rankType: action.payload.data,
      };
    case types.SET_RANK:
      return {
        ...state,
        currentRank: action.payload.data,
      };
    case types.UPDATE_FORM_DATA:
      return {
        ...state,
        formInputData: { ...action.payload.data },
      };
    case types.GET_COLLEGE_LIST:
      let data = action.payload.data.colleges;
      let totalCount = action.payload.data.totalCount;
      let message = action.payload.data.message;
      if (data) {
        return {
          ...state,
          collegeList: {
            data,
            totalCount,
            message
          },
        };
      } else {
        return {
          ...state,
          collegeList: {
            data: [],
            totalCount: 0,
            message
          }
        };
      }
    default:
      return {
        ...state,
      };
  }
}

reducerRegistry.register("rankPredictor", rankPredictor);
export default rankPredictor;

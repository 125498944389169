import reducerRegistry from "../../reducerRegistry";
import types from "./types";

function specialTests(state = {}, action) {
    switch (action.type) {
      case types.GET_SPECIAL_TESTS:
       
        let dataObj = action.payload?.data?.reduce((obj,item)=>{
                if(!obj[item.packageId])obj[item.packageId]={};
                obj[item.packageId][item.mappingId] = item;
                return obj;
              
        },{})
        
        return {
          ...state,
          data: dataObj 
        };
      default:
        return { ...state };
    }
  }
  
  reducerRegistry.register("specialTests", specialTests);
  export default specialTests;
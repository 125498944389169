import React from "react";
import Chart from "react-google-charts";

const TimeDistriButionPieChart = ({ data = [] }) => {
  const filteredData = data
    ?.filter((item) => item[0]?.toLowerCase() !== "section")
    ?.map((item) => {
      const section = item[0]?.split(" (")[0]; // get section name before "("
      return section;
    });
  const options = {
    format: "$#",
    fontStyle: "bolder",
    fontSize: "14",
    legend: {
      position: "none",
      alignment: "center",
      width: "100%",
      height: "150px",
      textStyle: {
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.87)",
        fontStyle: "bold",
      },
    },

    pieSliceTextStyle: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "9px",
      lineHeight: "13px",
      color: "#ffffff",
    },
    slices: {
      0: { color: "#F6C755" },
      1: { color: "#76B391" },
      2: { color: "#EE8D65" },
      3: { color: "#9B7BBB" },
      4: { color: "#E27972" },
      5: { color: "#7BA5DA" },
    },
    pieSliceText: "percentage",
    chartArea: {
      width: "90%",
      height: "90%",
    },
  };

  return (
    <div className="pie-wrap">
      <div className="qdisheading">
        <label>Time Distribution</label>
      </div>

      <div className="pie-chart">
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"100%"}
          height={"200px"}
        />
      </div>
      <div className="pieChartList">
        <ul>
          {filteredData?.map((item, index) => (
            <li key={index}>
              <span
                style={{
                  backgroundColor: options?.slices[index]?.color,
                }}
              ></span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TimeDistriButionPieChart;

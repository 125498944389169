import React from "react";
import { createOptimizedImgUrl } from "../../../data/utils/helpers";

const ProductInfoCard = ({ packageInfo, onClick }) => {
  const { title = "", imgUrl = "", dualValidity, subParent } = packageInfo;
  const getRibbonLabel = () => {
    if (packageInfo?.dualValidity) {
      return "DualValidity";
    } else if (packageInfo?.subParent) {
      return "Batch";
    }
    return "";
  };
  return (
    <div className={"purchase_course_card"} onClick={onClick}>
      <div className="paid_course_img">
        <img
          src={createOptimizedImgUrl(
            imgUrl || "/images/produtImg.jpg",
            193,
            193
          )}
          height="173px"
          width="173px"
          alt={title}
        />
      </div>
      <div className="product-content">
        {dualValidity && <div className={`dual_ribban`}>DualValidity</div>}
        {subParent && <div className={`batch_ribban`}>Batch</div>}
        <div className="productTitle">{title}</div>
      </div>
    </div>
  );
};

export default ProductInfoCard;

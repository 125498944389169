import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { VIDEO_URI } from "../../constants/appUrls";
import { videoPackageUrl } from "../../constants/appConfig";
import fetch_url from "../data/utils/fetch";
import CookieEnableModal from "./library/CookieEnableModal";
import WatermarkElement from "./library/WatermarkElement";

var vid = null;
const ShakaPlayer = (props) => {
  const videoContainer = useRef(null);
  const videoComponent = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setPlayFlag] = useState(false);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [videoEndedFlag, setVideoEndedFlag] = useState(false);
  const [videoPlayDuration, setVideoPlayDuration] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);

  const playerRef = useRef(null);

  let [rateChange, setRateChange] = useState(1);
  var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const isRecordedUrlAvailaible = useMemo(() => {
    return props.getSelectedVideo ? props.getSelectedVideo() : false;
  }, [props.getSelectedVideo]);

  useEffect(() => {
    const attrLen = document.getElementsByClassName(
      "shaka-controls-container"
    )[0]?.attributes?.length;
    if (attrLen > 1) {
      setShowPlayPause(true);
    } else {
      setShowPlayPause(false);
    }
  });
  useEffect(() => {
    setPlayFlag(false);
    if (isRecordedUrlAvailaible) {
      props.setProgressApi(true);
    }
    var video = document.getElementById("shaka-video");
    var player = new shaka.Player(video);
    if (typeof video !== "undefined" && video !== null) {
      video.pause();
    }
    if (typeof player !== "undefined" && player !== null) {
      player.unload();
      player.destroy();
    }
    setTimeout(() => {
      fetchDRMToken(props.id);
    }, 2000);
  }, [props.id, props.url, props?.classId]);

  useEffect(() => {
    if (isRecordedUrlAvailaible && !props.progressFlag) {
      const timer = setInterval(() => {
        videoOnProgress();
      }, parseInt(10000 / rateChange)); // timer = 10 sec * playbackSpeed
      return () => clearInterval(timer);
    }
  }, [isRecordedUrlAvailaible, rateChange]);

  useEffect(() => {
    if (props?.getVideoRatingStatus) {
      props.getVideoRatingStatus();
      props?.setShowRatingModalAllowed(true);
    }
  }, []);

  const timeSet = (timeInSec) => {
    setCurrentTime(timeInSec);
  };
  const fetchDRMToken = async (video_id = null) => {
    if (isRecordedUrlAvailaible) {
      props.setProgressApi(true);
    }

    let obs = document.getElementsByClassName("shaka-controls-container");
    for (var i = obs.length - 1; i >= 0; --i) {
      obs[i].remove();
    }
    let obs_spinner = document.getElementsByClassName(
      "shaka-spinner-container"
    );
    for (var j = obs_spinner.length - 1; j >= 0; --j) {
      obs_spinner[j].remove();
    }
    let video = document.getElementById("shaka-video");
    fetch_url(
      `${videoPackageUrl.videoStatus}?packageId=${props.packageId}&videoId=${
        video_id || props.id
      }`
    ).then((res) => {
      let startTime = 0;
      if (res.data && res.data[0]) {
        startTime =
          res.data[0].wt > 1
            ? res.data[0].wt / 1000 + (res.data[0].wt % 1000)
            : 0;
      }
      if (startTime > 0) {
        video.currentTime = startTime;
        videoOnProgress(startTime);
        const video5 = document.getElementById("shaka-video");
        video5.addEventListener("timeupdate", (event) => {
          timeSet(startTime);
        });
      }
      if (isRecordedUrlAvailaible) {
        props.setProgressApi(false);
      }
    });

    if (typeof video !== "undefined" && video !== null) {
      video.pause();
    }
    let player = new shaka.Player(video);

    if (isSafari || iOS) {
      async function getFpsCertificate() {
        let req = await fetch(
          "//fp-keyos.licensekeyserver.com/cert/2b8db8748b79535edd52bbc1495d6efe.der"
        );
        let cert = await req.arrayBuffer();
        return new Uint8Array(cert);
      }
      // async function initPlayer() {
      // Enable support for IE
      shaka.polyfill.installAll();
      // Check to see if the browser supports the basic APIs Shaka needs.
      if (shaka.Player.isBrowserSupported()) {
        // Create a Player instance.
        if (isPlaying) {
          setPlayFlag(false);
        }
        // let video = document.getElementById("shaka-video");
        // let player = new shaka.Player(video);
        const video_safari = videoComponent.current;
        const videoCnt_safari = videoContainer.current;
        const ui = new shaka.ui.Overlay(player, videoCnt_safari, video_safari);
        //Setting UI configuration JSON object
        const uiConfig = {};
        //Configuring elements to be displayed on video player control panel
        uiConfig["controlPanelElements"] = [
          "time_and_duration",
          // "quality",
          // "playback_rate",
          // "fullscreen",
          // "picture_in_picture"
        ];
        // uiConfig["playbackRates"] = [0.75, 1, 1.25, 1.5, 1.75, 2];
        ui.configure(uiConfig);
        ui.getControls();

        // Attach player to the window to make it easy to access in the JS console.
        window.player = player;
        // Get certificate
        let cert = await getFpsCertificate();
        // Global content Id to have access to it later.
        let contentId = "";
        player.configure(
          "drm.servers.com\\.apple\\.fps\\.1_0",
          `${VIDEO_URI}/v3/get/license?drm-type=fairplay&pkgId=${
            props.packageId
          }${
            props?.isOlc
              ? "&isOlc=true&id=" + props?.classId
              : "&isOlc=false&id=" + props?.id
          }&isPersistent=false`
        );
        player.configure(
          "drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate",
          cert
        );
        player.configure("drm.initDataTransform", (initData, initDataType) => {
          if (initDataType != "skd") return initData;
          // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
          const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
          contentId = skdUri.split("skd://")[1].substr(0, 32);
          const cert = player.drmInfo().serverCertificate;
          return shaka.util.FairPlayUtils.initDataTransform(
            initData,
            contentId,
            cert
          );
        });
        // Tell player that it needs to send custom data (authentication XML) during its license request.
        player.getNetworkingEngine().registerRequestFilter((type, request) => {
          if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
            let jwtToken = Cookies.get("cp_token");
            // request.headers["X-JWT-Token"] = jwtToken;
            // request.headers["X-Auth-Token"] = "fpoa43edty5";
            request.headers["Content-Type"] = "text/plain";
            const originalPayload = new Uint8Array(request.body);
            const base64Payload =
              shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
            const params = "spc=" + base64Payload + "&assetId=" + contentId;
            request.body = shaka.util.StringUtils.toUTF8(params);
          }
        });
        player
          .getNetworkingEngine()
          .registerResponseFilter((type, response) => {
            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
              let responseText = shaka.util.StringUtils.fromUTF8(response.data);
              // Trim whitespace.
              responseText = responseText.trim();
              // Decode the base64-encoded data into the format the browser expects.
              response.data =
                shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
            }
          });

        // Listen for error events.
        player.addEventListener("error", (event) => {
          console.error(
            "Error code",
            event.detail.code,
            "object",
            event.detail
          );
        });
        // Try to load a manifest.
        // This is an asynchronous process.
        console.log("PLAYER IS CALLING");

        video.play();
        player
          .load(props.hlsUrl)
          .then(function () {
            // This runs if the asynchronous load is successful.
            const videoDet = document.getElementById("shaka-video");
            if (props?.setTotalVideoDuration) {
              props.setTotalVideoDuration(videoDet?.duration);
            }
            console.log("The video has now been loaded!");
          })
          .catch((e) => {
            console.error(
              "Failed loading manifest. Code",
              e.code,
              "object",
              e.detail
            );
          });
      } else {
        // This browser does not have the minimum set of APIs we need.
        console.error("Browser not supported!");
      }
    } else {
      // Install built-in polyfills to patch browser incompatibilities.
      shaka.polyfill.installAll();

      // Check to see if the browser supports the basic APIs Shaka needs.
      if (shaka.Player.isBrowserSupported()) {
        // Everything looks good!

        console.log("Player is good=>>>>>>", props.url);
        const video = videoComponent.current;
        const videoCnt = videoContainer.current;
        // let player = new shaka.Player(video);
        const ui = new shaka.ui.Overlay(player, videoCnt, video);
        //Setting UI configuration JSON object
        const uiConfig = {};
        //Configuring elements to be displayed on video player control panel
        uiConfig["controlPanelElements"] = [
          "time_and_duration",
          // "quality",
          // "playback_rate",
          // "fullscreen",
          // "picture_in_picture"
        ];
        // uiConfig["playbackRates"] = [0.75, 1, 1.25, 1.5, 1.75, 2];
        ui.configure(uiConfig);
        ui.getControls();

        // Tell player from where to get DRM licenses.
        player.configure({
          drm: {
            servers: {
              "com.widevine.alpha": `${VIDEO_URI}/v3/get/license?drm-type=widevine&pkgId=${
                props.packageId
              }${
                props?.isOlc
                  ? "&isOlc=true&id=" + props?.classId
                  : "&isOlc=false&id=" + props?.id
              }&isPersistent=false`,
              "com.microsoft.playready": `${VIDEO_URI}/v3/get/license?drm-type=playready&pkgId=${
                props.packageId
              }${
                props?.isOlc
                  ? "&isOlc=true&id=" + props?.classId
                  : "&isOlc=false&id=" + props?.id
              }&isPersistent=false`,
            },
          },
        });
        // Tell player that it needs to send custom data (authentication XML) during its license request.
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            // Only add headers to license requests:
            console.log(
              "shaka.net.NetworkingEngine.RequestType.LICENSE",
              shaka.net.NetworkingEngine.RequestType.LICENSE
            );

            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
              // This is the specific header name and value the server wants.
              // You need to add actual authentication XML here in base64 encoded format.
              let jwtToken = Cookies.get("cp_token");
              // request.headers["X-JWT-Token"] = jwtToken;
              // request.headers["X-Auth-Token"] = "fpoa43edty5";
            }
          });

        player.addEventListener("error", (error) => {
          console.log("Error", error);
        });
        await player
          .load(props.url)
          .then(function () {
            console.log("The video has now been loaded!=>>>>>>>>>>>>");
            vid = document.getElementById("shaka-video");
            if (props?.setTotalVideoDuration) {
              props.setTotalVideoDuration(vid?.duration);
            }
            vid.addEventListener("ratechange", (event) => {
              playBackRateChange(event);
            });
          })
          .catch((error) => {
            console.log("Error in catch", error);
            window.setTimeout(() => {
              fetchDRMToken();
            }, 2000);
          });
      } else {
        // This browser does not have the minimum set of APIs we need.
        console.error("Browser not supported!");
      }
    }

    removeDefaultComponents();
    addTopRightControlPanel(player);
    loadTime(player);
    createCustomChapterMarker(player);
  };

  const removeDefaultComponents = () => {
    const qualityComponent = document.querySelector(
      ".shaka-playbackrate-button"
    );
    const resolutionComponent = document.querySelector(
      ".shaka-resolution-button"
    );

    if (qualityComponent) {
      qualityComponent.remove();
    }
    if (resolutionComponent) {
      resolutionComponent.remove();
    }
  };
  const addTopRightControlPanel = (player) => {
    // Top Right Control Pannel
    const playerContainer = document.querySelector(
      ".shaka-controls-button-panel"
    );
    if (playerContainer) {
      const customDiv = document.createElement("div");
      customDiv.classList.add("shaka-top-right-container");
      customDiv.classList.add("shaka-overflow-button");
      customDiv.classList.add("shaka-tooltip-status");

      playerContainer.appendChild(customDiv);
    }
    // adding custom components after top right control panel got added in DOM
    addHandoutComponent();
    addQualityButtons(player);
    addCustomPlayback(player);
    customFullscreenToggle();
  };

  const addHandoutComponent = () => {
    const topRightControlPanel = document.querySelector(
      ".shaka-top-right-container"
    );
    // custom handout button
    const customButton = document.createElement("button");
    customButton.classList.add("shaka-handout-button");
    customButton.innerHTML = '<i class="shaka-handout-button-icon"></i>';

    customButton.addEventListener("click", (e) => {
      e.stopPropagation();
      props.openHandoutSection();
    });
    if (props?.handoutsAvailable) {
      topRightControlPanel.style.width = "215px";

      topRightControlPanel?.appendChild(customButton);
    } else {
      topRightControlPanel.style.width = "154px";
    }
  };

  const addQualityButtons = (player) => {
    // Top Right Control Pannel
    const topRightControlPanel = document.querySelector(
      ".shaka-top-right-container"
    );
    const videoElement = document.getElementById("shaka-video");

    // custom playback rate menu button
    const customPlaybackRateButton = document.createElement("button");
    customPlaybackRateButton.classList.add("shaka-video-quality-button");
    customPlaybackRateButton.innerHTML =
      '<i class="shaka-video-quality-icon"></i>';

    // container for the custom menu items
    const menuContainer = document.createElement("div");
    menuContainer.className = "shaka-video-quality-menu-content";

    // playback rate menu wrapper
    const menuContainerWrapper = document.createElement("div");
    menuContainerWrapper.className = "shaka-menu-content-wrapper";
    // play back rate menu heading
    const qualityHeader = document.createElement("div");
    qualityHeader.className = "shaka-menu-header-container";
    qualityHeader.innerText = "Video Quality";

    menuContainerWrapper.appendChild(qualityHeader);

    const qualityContainer = document.createElement("div");
    qualityContainer.classList.add("item-resolution");

    // Create a radio button for "Auto" quality
    const autoRadioButton = document.createElement("input");
    autoRadioButton.type = "radio";
    autoRadioButton.className = "video-quality-radio";
    autoRadioButton.name = "quality";
    autoRadioButton.value = "auto";
    autoRadioButton.id = "quality-auto";
    autoRadioButton.defaultChecked = true;

    const autoLabel = document.createElement("label");
    autoLabel.className = "video-quality-span";
    autoLabel.textContent = "Auto";
    autoLabel.setAttribute("for", "quality-auto");

    autoRadioButton.addEventListener("change", () => {
      // Configure Shaka Player to enable ABR for automatic quality selection
      player.configure({
        abr: {
          enabled: true,
        },
      });
    });

    qualityContainer.appendChild(autoRadioButton);
    qualityContainer.appendChild(autoLabel);
    menuContainerWrapper.appendChild(qualityContainer);

    // Get available video tracks from Shaka Player
    const tracks = player.getVariantTracks();

    // Add a separator element between "Auto" and the individual quality options
    const separator = document.createElement("div");
    separator.className = "quality-separator";
    qualityContainer.appendChild(separator);

    // Create a radio button for each track
    tracks.forEach((track, index) => {
      const menuItem = document.createElement("div");
      menuItem.className = "item-resolution";

      const radioButton = document.createElement("input");
      radioButton.type = "radio";
      radioButton.className = "video-quality-radio";
      radioButton.name = "quality";
      radioButton.value = index;
      radioButton.id = `quality-${index}`;

      const label = document.createElement("label");
      label.className = "video-quality-span";
      label.textContent = `${track.height}p`;
      label.setAttribute("for", `quality-${index}`);

      // HD superscript element
      const superscript_HD = document.createElement("sup");
      superscript_HD.innerText = "HD";
      superscript_HD.className = "video-quality-superscript";

      // DATA SAVER element
      const datasaver_label = document.createElement("div");
      datasaver_label.innerText = "DATA SAVER";
      datasaver_label.className = "video-quality-datasaver";

      menuItem.appendChild(radioButton);
      menuItem.appendChild(label);

      // condition for HD superscript
      if (track?.height > 700) {
        label.appendChild(superscript_HD);
      }
      // condition for DATA SAVER label
      if (index === tracks?.length - 1) {
        menuItem.appendChild(datasaver_label);
      }
      // Change quality when the radio button is selected
      radioButton.addEventListener("change", () => {
        player.selectVariantTrack(track);
      });
      separator.appendChild(menuItem);
    });

    menuContainerWrapper.appendChild(separator);
    menuContainer.appendChild(menuContainerWrapper);
    customPlaybackRateButton.appendChild(menuContainer);
    topRightControlPanel.appendChild(customPlaybackRateButton);
  };

  const addCustomPlayback = (player) => {
    // Top Right Control Pannel
    const topRightControlPanel = document.querySelector(
      ".shaka-top-right-container"
    );
    const videoElement = document.getElementById("shaka-video");

    // custom playback rate menu button
    const customPlaybackRateButton = document.createElement("button");
    customPlaybackRateButton.classList.add("shaka-play-back-rate-button");
    customPlaybackRateButton.innerHTML =
      '<i class="custom-playback-rate-button"></i>';

    // container for the custom menu items
    const menuContainer = document.createElement("div");
    menuContainer.className = "shaka-playback-rate-menu-content";

    // playback rate menu wrapper
    const menuContainerWrapper = document.createElement("div");
    menuContainerWrapper.className = "shaka-menu-content-wrapper";
    // play back rate menu heading
    const playbackHeader = document.createElement("div");
    playbackHeader.className = "shaka-menu-header-container";
    playbackHeader.innerText = "Playback Speed";

    menuContainerWrapper.appendChild(playbackHeader);

    // Add custom playback rate menu items
    var customRates = [
      { rate: 0.5, label: "0.5 x" },
      { rate: 0.7, label: "0.7 x" },
      { rate: 1, label: "1.0 x" },
      { rate: 1.25, label: "1.25 x" },
      { rate: 1.5, label: "1.5 x" },
      { rate: 1.75, label: "1.75 x" },
      { rate: 2, label: "2 x" },
    ];

    customRates.forEach((item) => {
      const menuItem = document.createElement("div");
      menuItem.className = "item-resolution";

      // custom playback rate radio input
      const radioInput = document.createElement("input");
      radioInput.type = "radio";
      radioInput.className = "video-quality-radio";
      radioInput.name = "playbackRate";
      radioInput.value = item.rate;
      radioInput.id = item.rate;

      // custom playback rate radio label
      const label = document.createElement("label");
      label.className = "video-quality-span";
      label.textContent = item.label;
      label.htmlFor = item.rate;

      // custom playback NORMAL super script
      const superscript_HD = document.createElement("sup");
      superscript_HD.innerText = "(NORMAL)";
      superscript_HD.className = "video-quality-superscript";

      // condition for adding NORMAL superscript
      if (item?.rate === 1) {
        label.appendChild(superscript_HD);
      }

      menuItem.appendChild(radioInput);
      menuItem.appendChild(label);

      // Set the playback rate when a menu item is clicked
      menuItem.addEventListener("click", () => {
        const selectedRate = parseFloat(item.rate);
        videoElement.playbackRate = selectedRate;
      });

      menuContainerWrapper.appendChild(menuItem);
      const radio = menuContainerWrapper.querySelector(
        'input[type="radio"][id="1"]'
      );
      if (radio) {
        radio.checked = true; // Set the 'checked' property to true to select the radio button
      } else {
        console.log(`Radio button with ID ${radio} not found.`);
      }
    });
    menuContainer.appendChild(menuContainerWrapper);
    customPlaybackRateButton.appendChild(menuContainer);
    topRightControlPanel.appendChild(customPlaybackRateButton);
  };

  const customFullscreenToggle = () => {
    const topRightControlPanel = document.querySelector(
      ".shaka-top-right-container"
    );
    // const videoElement = document.getElementById("shaka-video");
    const videoElement = document.getElementById("new-shaka-video-wrapper");

    // Create a custom fullscreen toggle button
    const fullscreenButton = document.createElement("button");
    fullscreenButton.className = "custom-fullscreen-button";
    fullscreenButton.innerHTML = '<i class="custom-fullscreen-icon"></i>';

    // Add click event to toggle fullscreen mode
    fullscreenButton.addEventListener("click", () => {
      if (!document.fullscreenElement) {
        // Enter fullscreen mode
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen();
        }
      } else {
        // Exit fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    });
    // Append the custom button to the controls container
    topRightControlPanel.appendChild(fullscreenButton);
  };

  const createCustomChapterMarker = (player) => {
    // Create an array of custom ad marker times in seconds
    const arr = props?.bookmarkData || [];

    const total = player.getMediaElement().duration;

    const markers = arr?.map((ele, ind) => {
      return {
        name: ele.name,
        time: ele.startTime / 1000,
        width: ((ele.endTime - ele.startTime) / 1000 / total) * 100,
      };
    });

    // Get the UI container element
    const uiContainer = document.querySelector(".shaka-ad-markers");
    // Create a custom ad marker element for each time
    markers?.forEach(({ time, width, name }) => {
      const adMarker = document.createElement("div");
      adMarker.className = "shaka-ad-marker";

      const left = (time / total) * 100 + "%";

      adMarker.style.left = left;
      adMarker.style.width = `${width}%`;
      adMarker.setAttribute("data-time", time);

      const markerSpan = document.createElement("span");
      markerSpan.innerHTML = name;
      adMarker.appendChild(markerSpan);
      uiContainer.appendChild(adMarker);
    });
  };

  const loadTime = (player) => {
    const uiContainer = document.querySelector(".shaka-controls-button-panel");
    const currentTimeDisplay = uiContainer.querySelector(".shaka-current-time");
    currentTimeDisplay.style.display = "none";

    // Create custom time display elements
    const customCurrentTimeDisplay = document.createElement("div");
    customCurrentTimeDisplay.className = "custom-current-time-display";
    uiContainer.appendChild(customCurrentTimeDisplay);

    const customTimeDurationDisplay = document.createElement("div");
    customTimeDurationDisplay.className = "custom-time-duration-display";
    uiContainer.appendChild(customTimeDurationDisplay);
    setVideoPlayDuration(player.getMediaElement().duration);
    window.timerId = setInterval(() => {
      const currentTime = player.getMediaElement().currentTime;
      const totalDuration = player.getMediaElement().duration;
      customCurrentTimeDisplay.textContent = formatTime(currentTime);
      customTimeDurationDisplay.textContent = formatTime(totalDuration);
    }, 1000);
  };
  useEffect(() => {
    if (videoPlayDuration > 0 && videoPlayDuration - currentTime < 15) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  }, [currentTime]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const openPipWindow = () => {
    const videoElement = document.getElementById("shaka-video");
    document.exitPictureInPicture().catch((error) => {
      console.error("Error exiting picture-in-picture:", error);
    });
  };
  const closePipWindow = () => {
    const videoElement = document?.getElementById("shaka-video");
    if (document.pictureInPictureEnabled && !document.pictureInPictureElement) {
      videoElement?.requestPictureInPicture().catch((error) => {
        console.error("Error entering picture-in-picture:", error);
      });
    }
  };

  useEffect(() => {
    const videoElement = document.getElementById("shaka-video");
    videoElement?.addEventListener("leavepictureinpicture", () => {
      // Picture-in-picture mode has been exited
      props?.closeViewModal(false);
      console.log("Exited picture-in-picture mode");
    });

    if (document.pictureInPictureElement === videoElement) {
      openPipWindow();
    } else {
      closePipWindow();
    }
  }, [props?.pictureInPicture]);

  useEffect(() => {
    // Function to handle fullscreenchange event
    document.addEventListener("fullscreenchange", handleFullscreenChange);
  }, [document?.fullscreenElement]);

  const handleFullscreenChange = () => {
    const topRightControlPanel = document.querySelector(
      ".shaka-top-right-container"
    );

    if (document.fullscreenElement) {
      // Video is in fullscreen mode
      if (topRightControlPanel) {
        topRightControlPanel.style.top = "0rem";
      }
      console.log("Video is in fullscreen mode");
    } else {
      if (topRightControlPanel) {
        topRightControlPanel.style.top = "-2.5rem";
      }
      console.log("Video exited fullscreen mode");
    }
  };

  // clrearing time interval on unmount
  useEffect(() => {
    return clearInterval(window?.timerId);
  }, []);

  const videoOnProgress = (timeee = null) => {
    if (isRecordedUrlAvailaible) {
      let playerTime = document.getElementById("shaka-video");
      setCurrentTime(parseInt(playerTime.currentTime));
      let playerCurrentTime =
        timeee && timeee > 0
          ? timeee * 1000
          : parseInt(playerTime.currentTime) * 1000;

      if (
        !props.progressFlag &&
        playerCurrentTime > 0 &&
        playerTime.ended &&
        !videoEndedFlag
      ) {
        let payload = [
          {
            pid: props.packageId,
            vid: props.getSelectedVideo(),
            wt: 0,
            ts: Date.now(),
          },
        ];

        fetch_url(videoPackageUrl.videoStatus, "POST", payload);
        setVideoEndedFlag(true);
      }
      if (!props.progressFlag && playerCurrentTime > 0 && !playerTime?.paused) {
        let payload = [
          {
            pid: props.packageId,
            vid: props.getSelectedVideo(),
            wt: playerCurrentTime,
            ts: Date.now(),
            st: 10000,
          },
        ];

        fetch_url(videoPackageUrl.videoStatus, "POST", payload);
      }
    }
  };
  const videoPlayPauseHandler = (flag) => {
    vid = document.getElementById("shaka-video");
    if (flag) {
      vid.pause();
      if (isPlaying) {
        setPlayFlag(false);
      }
    } else {
      vid.play();
      if (!isPlaying) {
        setPlayFlag(true);
      }
    }
  };
  const videoForwardHandler = () => {
    vid.currentTime = vid.currentTime + 15;
  };
  const videoBackwardHandler = () => {
    vid.currentTime = vid.currentTime - 15;
  };

  const playBackRateChange = (e) => {
    const rateSpeed = e.target?.ui?.player_?.playRateController_?.rate_ || 1;
    setRateChange(rateSpeed);
  };
  const changePlayer = (status) => {
    if (status) {
      if (!isPlaying) {
        setPlayFlag(status);
      }
    } else {
      if (isPlaying) {
        setPlayFlag(status);
      }
    }

    // Rating section

    let VideoDetails = document.getElementById("shaka-video");
    // video pause status
    if (
      !status &&
      props.isRatingEnable &&
      props.canUserRate &&
      props?.handleRatingAction
    ) {
      props.handleRatingAction().then((res) => {
        if (res) {
          if (res / 1000 > VideoDetails?.duration / 2) {
            // time in sec
            props.setIsRatingVideosModalVisible(true);
            props.getCurrentVideoWt(VideoDetails?.currentTime);
          }
        }
      });
    }

    // video end status
    if (
      VideoDetails?.ended &&
      props?.handleRatingAction &&
      props.isRatingEnable &&
      props?.canUserRate
    ) {
      props.handleRatingAction().then((res) => {
        if (res) {
          if (res / 1000 > VideoDetails?.duration / 2) {
            props.setIsRatingVideosModalVisible(true);
            props.getCurrentVideoWt(VideoDetails?.currentTime);
          }
        }
      });
    }
  };

  return (
    <div className="new-shaka-video-wrapper" id="new-shaka-video-wrapper">
      <CookieEnableModal />
      <div
        className={`video-container ${props?.isOlc ? "olc_there" : "olc_not"}`}
        ref={videoContainer}
        id="video-container"
      >
        <WatermarkElement containerId={"video-container"} />
        <p data-content="resolution"></p>
        <video
          ref={videoComponent}
          className="shaka-video"
          id="shaka-video"
          onTimeUpdate={(e) => timeSet(e.target.currentTime)}
          onPause={() => changePlayer(false)}
          onPlay={() => changePlayer(true)}
          controls={false}
        />
        {showPlayPause ? (
          <div className="pre-forword-wrap">
            <button
              className="pre-video-icon"
              onClick={() => videoBackwardHandler()}
            >
              <img
                className="shaka-icon-placeholder"
                src="/images/gold_assets/shaka-new-rewind.svg"
              />
            </button>
            <button
              className={`play-pause-btn ${!isPlaying ? "pause-btn" : ""} `}
              onClick={() => videoPlayPauseHandler(isPlaying)}
            >
              <img
                className="shaka-icon-placeholder"
                src={`${
                  !isPlaying
                    ? "/images/gold_assets/shaka-new-play.svg"
                    : "/images/gold_assets/shaka-new-pause.svg"
                }`}
              />
            </button>
            <button
              className="forword-video-icon"
              onClick={() => videoForwardHandler()}
            >
              <img
                className="shaka-icon-placeholder"
                src="/images/gold_assets/shaka-new-forward.svg"
              />
            </button>
            {showNextButton && props?.nextVideoDetails?.tImg && (
              <img
                src={props?.nextVideoDetails?.tImg || ""}
                id="class-next-btn"
                className="forword-video-icon"
                onClick={() => props.nextVideoPlayHandler()}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  header: state.header,
});
export default connect(mapStateToProps, null)(ShakaPlayer);

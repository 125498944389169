import React, { memo, useEffect, useMemo, useState } from "react";
import { pick } from "../../../utils";
import { openTestPortal } from "../TakeTest/util";
import ComparisonChart from "./Components/ComparisonChart";
import LeaderBoard from "./Components/LeaderBoard";
import QuestionDistribution from "./Components/QuestionDistribution";
import ScoreAnalysisPieChart from "./Components/ScoreAnalysisPieChart";
import ScoreCard from "./Components/ScoreCard";
import TestRating from "./Components/TestRating";

const OverAllResultAnalysis = ({
  testResult = {},
  mappingId = 0,
  packageId = 0,
  title = "",
  rating,
  setRating = () => {},
  isPPC,
  moengageData,
  freeContentFlag = false,
  fixedMockFlag = false,
  ratingAvailable,
  exam = "",
}) => {
  const [overAllResultData, setOverAllResultData] = useState({});
  const {
    overall,
    sections = [],
    testInfo,
    topperInfo,
    toppersList,
    mode = ""
  } = testResult;
  // isTestAttempted is a boolean value that is assigned based on the overall data,if test is attempted,overall is present otherwise not.
  const isTestAttempted = overall;
  const totalTimeSpent = useMemo(() => {
    return sections?.length
      ? sections?.reduce((totTime, section) => {
          if (section) {
            totTime += Math.floor(section?.timeSpent / 1000);
          }
          return totTime;
        }, 0)
      : Math.floor(overall?.timeSpent / 1000);
  }, [sections]);
  // here we defined the keys from testResult based on the requirement
  useEffect(() => {
    if (Object.keys(testResult)?.length) {
      let overAllData = {
        ...overall,
      };
      // define testInfo key if present
      if (testInfo) {
        overAllData["testInfo"] = {
          ...testInfo,
        };
      }
      // overall section mapping..
      let correctAnswerCount = 0;
      let incorrectAnswerCount = 0;
      let totalQuestions = 0;
      let totalUnevaluatedAnswerCount = 0;
      const USER_OVER_ALL_MAP = {
        totalQuestions: "totalQuestions",
        correctAnswerCount: "correctAnswerCount",
        incorrectAnswerCount: "incorrectAnswerCount",
        nonEvaluatedAnswerCount: "nonEvaluatedAnswerCount", //optional test
      };

      testResult?.sections?.forEach((section) => {
        let pickedData = pick(USER_OVER_ALL_MAP, section || {});
        correctAnswerCount += pickedData?.correctAnswerCount;
        incorrectAnswerCount += pickedData?.incorrectAnswerCount;
        totalQuestions += pickedData?.totalQuestions;
        if (pickedData.nonEvaluatedAnswerCount)
          //optional test
          totalUnevaluatedAnswerCount += pickedData.nonEvaluatedAnswerCount;
      });
      overAllData.correctAnswerCount = correctAnswerCount;
      overAllData.incorrectAnswerCount = incorrectAnswerCount;
      overAllData.nonEvaluatedAnswerCount = totalUnevaluatedAnswerCount; //optional test
      overAllData.totalQuestions =
        totalQuestions - overall?.dtbQuestotalCount || 0; //exclude dtbQues count.

      // overall topperInfo section mapping..
      if (topperInfo) {
        const TOPPER_OVER_ALL_MAP = {
          totalQuestions: "totalQuestions",
          correctAnswerCount: "correctAnswerCount",
          incorrectAnswerCount: "incorrectAnswerCount",
          nonEvaluatedAnswerCount: "nonEvaluatedAnswerCount", // optional test
        };
        let topperCorrectAnswerCount = 0,
          topperIncorrectAnswerCount = 0,
          topperTotalQuestions = 0,
          topperTotalUnevaluatedAnswerCount = 0;
        topperInfo?.sections?.forEach((section) => {
          let topperPickedData = pick(TOPPER_OVER_ALL_MAP, section || {});
          topperCorrectAnswerCount += topperPickedData?.correctAnswerCount;
          topperIncorrectAnswerCount += topperPickedData?.incorrectAnswerCount;
          topperTotalQuestions += topperPickedData?.totalQuestions;
          if (topperPickedData.nonEvaluatedAnswerCount)
            // optional test
            topperTotalUnevaluatedAnswerCount +=
              topperPickedData.nonEvaluatedAnswerCount;
        });
        overAllData.topperInfo = {
          overall: {
            ...topperInfo?.overall,
            correctAnswerCount: topperCorrectAnswerCount,
            incorrectAnswerCount: topperIncorrectAnswerCount,
            nonEvaluatedAnswerCount: topperTotalUnevaluatedAnswerCount, // optional test
            totalQuestions:
              topperTotalQuestions - topperInfo?.overall?.dtbQuestotalCount ||
              0, //exclude dtbQues count.
          },
        };
      }
      setOverAllResultData(overAllData);
    }
  }, [testResult]);
  const pieChartConfg = useMemo(() => {
    if (!Object.keys(overAllResultData).length) return [];
    let dataConfigs = [
      {
        label: "Percentile",
        percVal: overAllResultData?.percentile || 0,
        progressSelector: "circular-progress",
        progressValue: 0,
        progressEndValue: overAllResultData?.percentile || 0,
        speed: 10,
        gradientsColorCode: ["#FF647C", "#DCD3D3"],
        legendClassName: "perceragetotal",
      },
      {
        label: "Accuracy",
        percVal: overAllResultData?.accuracy || 0,
        progressSelector: "circular-progressTwo",
        progressValue: 0,
        progressEndValue: overAllResultData?.accuracy || 0,
        speed: 20,
        gradientsColorCode: ["#0B8F87", "#DCD3D3"],
        legendClassName: "accuracytotal",
      },
      {
        label: "Attempted",
        percVal: overAllResultData?.attempted || 0,
        progressSelector: "circular-progressThree",
        progressValue: 0,
        progressEndValue: overAllResultData?.attempted || 0,
        speed: 30,
        gradientsColorCode: ["#FFCF5C", "#DCD3D3"],
        legendClassName: "attemptedTotal",
      },
    ];
    // here filter out Percentile object when Reattempt...
    if (freeContentFlag) {
      dataConfigs = dataConfigs.filter(
        (dataConfig) => dataConfig.label !== "Percentile"
      );
    }
    return dataConfigs;
  }, [overAllResultData]);

  const overAllScoreComparisonData = useMemo(() => {
    if (!Object?.keys(overAllResultData)?.length) return;
    let {
      correctAnswerCount,
      incorrectAnswerCount,
      totalQuestions,
      testInfo,
      topperInfo,
      nonEvaluatedAnswerCount = 0, // optional test
    } = overAllResultData;
    let unattemptedCount =
      totalQuestions -
      nonEvaluatedAnswerCount -
      (correctAnswerCount + incorrectAnswerCount);

    // topper info
    const topperInfoData = topperInfo?.overall;
    let topperUnattemptedCount =
      topperInfoData?.totalQuestions -
        (topperInfoData?.nonEvaluatedAnswerCount || 0) - // optional test
        (topperInfoData?.correctAnswerCount +
          topperInfoData?.incorrectAnswerCount) || 0;
    let score = [["Section", "Correct", " Incorrect", "Unanswered"]];
    let keys = ["You", "Average", "Highest"];
    keys?.forEach((key) => {
      switch (key) {
        case "You":
          score.push([
            key,
            correctAnswerCount,
            incorrectAnswerCount,
            unattemptedCount,
          ]);
          break;
        case "Average":
          score.push([
            key,
            testInfo?.averageCorrect,
            testInfo?.averageIncorrect,
            testInfo?.unattemptedCount - (testInfo?.unevaluatedCount || 0) || 0,
          ]);
          break;
        case "Highest":
          score.push([
            key,
            topperInfoData?.correctAnswerCount,
            topperInfoData?.incorrectAnswerCount,
            topperUnattemptedCount,
          ]);
          break;
        default:
          break;
      }
    });
    return score;
  }, [overAllResultData]);
  return (
    <>
      {/* overallSection only to be shown when overall data is availaible */}
      {isTestAttempted ? (
        <section className="rowWrap">
          <div className="rowWidth8">
            <div
              className={`sectionalBoxwrap ${
                freeContentFlag ? "sectionalBox50" : ""
              } `}
            >
              <ScoreCard
                ribonLabel={"OVERALL"}
                ribonLabelRight={
                  overAllResultData?.cutOffMarks
                    ? `Cuttoff marks: ${overAllResultData?.cutOffMarks}`
                    : ""
                }
                title="Your Score"
                iconSrc="../../../images/resultIcon-check.svg"
                consumedNumber={
                  overAllResultData?.marks % 1
                    ? overAllResultData?.marks.toFixed(2) // fixed to two decimal after decimal point,if number is float
                    : overAllResultData?.marks
                }
                totalNumber={
                  overAllResultData?.totalMarks -
                  (overAllResultData?.dtbQuestotalMarks || 0)
                }
              />
              {/* exclude rank in reattempt */}
              {!freeContentFlag && (
                <ScoreCard
                  ribonLabel={"OVERALL"}
                  title={`${overAllResultData?.nonLiveUser ? "Predicted Rank" : "Rank" }`}
                  iconSrc="../../../images/resultIcon-star.svg"
                  consumedNumber={
                    overAllResultData?.overallRank || overAllResultData?.rank
                  }
                  totalNumber={overAllResultData?.totalCandidates}
                  isRefresh={false}
                />
              )}
              <ScoreCard
                ribonLabel={"OVERALL"}
                title="Time spent"
                iconSrc="../../../images/resultIcon-timer.svg"
                consumedNumber={totalTimeSpent}
                totalNumber={overAllResultData?.totalTime}
                isTimeScoreCard={true}
              />
            </div>
          </div>
          <div className="rowWidth4">
            <ScoreAnalysisPieChart
              ribonLabel="OVERALL"
              dataConfig={pieChartConfg}
            />
          </div>
        </section>
      ) : (
        <div />
      )}
      <section className="rowWrap">
        <div
          className={
            isTestAttempted
              ? `rowWidth8 ${freeContentFlag ? "freeContentWrap" : ""}`
              : "rowWidth4 noMargin"
          }
        >
          {/* hide question distribution for fixedMock analysis */}
          {!fixedMockFlag && (
            <QuestionDistribution
              correctCount={overAllResultData?.correctAnswerCount}
              wrongCount={overAllResultData?.incorrectAnswerCount}
              unattemptedCount={
                overAllResultData?.totalQuestions -
                (overAllResultData?.correctAnswerCount +
                  overAllResultData?.incorrectAnswerCount +
                  (overAllResultData?.nonEvaluatedAnswerCount
                    ? overAllResultData?.nonEvaluatedAnswerCount
                    : 0))
              }
              unevaluatedCount={overAllResultData?.nonEvaluatedAnswerCount}
              totalCount={overAllResultData?.totalQuestions}
              reAttemptCb={() =>
                openTestPortal(
                  mappingId,
                  packageId,
                  title,
                  "COMPLETED",
                  "REATTEMPT",
                  [exam],
                  mode,
                )
              }
            />
          )}
          {/* show Attempt now view for fixedMock */}
          {fixedMockFlag && !isTestAttempted && (
            <div className="rowWidth4 boxWhiteFull notAttemptWrapper">
              <div className="nopurchagesecion">
                <img src="../../../images/no-purchage-img.svg" />
                <p>You've not Attempted the test</p>
                <button
                  className="attemptedNowbtn"
                  type="button"
                  onClick={() => {
                    openTestPortal(
                      mappingId,
                      packageId,
                      title,
                      "COMPLETED",
                      "REATTEMPT"
                    );
                  }}
                >
                  Attempt Now
                </button>
              </div>
            </div>
          )}
          {/* hide TestRating for fixedMock */}
          {isTestAttempted && !fixedMockFlag && (
            <TestRating
              rating={rating}
              setRating={setRating}
              isPPC={isPPC}
              moengageData={moengageData}
              flexBy={freeContentFlag ? "column" : ""}
              ratingAvailable={ratingAvailable}
            />
          )}
          {/* exlude ComparisonChart in reattempt and fixedMock.. */}
          {!fixedMockFlag && !freeContentFlag && (
            <section className="boxWhiteFull">
              <ComparisonChart
                heading="Score Comparison"
                data={overAllScoreComparisonData}
                legendPosition={"right"}
                scores={{
                  you: {
                    marks: overAllResultData?.marks || 0,
                    total:
                      overAllResultData?.totalMarks -
                      (overAllResultData?.dtbQuestotalMarks || 0),
                  },
                  average: {
                    marks: overAllResultData?.testInfo?.averageScore || 0,
                    total:
                      overAllResultData?.totalMarks -
                      (overAllResultData?.dtbQuestotalMarks || 0),
                  },
                  highest: {
                    marks: overAllResultData?.topperInfo?.overall?.marks || 0,
                    total:
                      overAllResultData?.topperInfo?.overall?.totalMarks ||
                      0 -
                        overAllResultData?.topperInfo?.overall
                          ?.dtbQuestotalMarks ||
                      0, //exclude dtbQuestions
                  },
                }}
                flexByRow={true}
              />
            </section>
          )}
        </div>
        {toppersList?.length && (
          <div
            className={
              isTestAttempted ? "rowWidth4" : "rowWidth8 fullwidthleaderboard"
            }
          >
            <LeaderBoard
              toppersList={toppersList}
              totalMarks={
                fixedMockFlag
                  ? toppersList[0]?.maximumMarks
                  : overAllResultData?.totalMarks
              }
              dtbQuestotalMarks={overAllResultData?.dtbQuestotalMarks || 0}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default memo(OverAllResultAnalysis);

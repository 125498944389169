import types from './types';
import { pageSizeForDetailListing, productListingUrl, searchUrlEndPoint } from '../../../../constants/appConfig';
import { isADDA } from '../../utils/helpers';

const getPaginatedDataForSearchDetail = ({ pageNo, queryParam, sortBy, type }) => {
    let qP;
    if (queryParam && queryParam.length > 2) {
        if (queryParam.charAt(0) === '&') {
            queryParam = queryParam.substring(1);
        }
        qP = encodeURI(queryParam);
    } else {
        qP = '';
    }
    const urlSearchParams = new URLSearchParams(qP);
    const searchTerm = urlSearchParams.get("searchTerm");
    let url;
    if(searchTerm){
        url = `${searchUrlEndPoint}/packages/v8/search`
    }else{
        url = `${productListingUrl}`
    }
    return {
        CALL_API: [
            {
                type: type === 'init' ? types.GET_SEARCH_DETAIL_LISTING : types.GET_SEARCH_DETAIL_LISTING_ON_SCROLL,
                meta: {
                    path: `${url}?${decodeURI(qP)}${sortBy.toUpperCase() != "RELEVANCE"? "&sortOrder="+sortBy: ""}&pageNo=${pageNo}&pageSize=${pageSizeForDetailListing}&primaryFilter=true&primaryFacetsRequired=true${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                }
            }
        ]
    };
};

const clearSearchDetailData = () => {
    return {
        type: types.CLEAR_SEARCH_LISTING_DATA,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
};

export {
    getPaginatedDataForSearchDetail,
    clearSearchDetailData
};


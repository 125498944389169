import React, { useEffect, useState } from 'react'

const TestimonialCarouselNew = (props) => {
    const {children, show, infiniteLoop, setCenterIndex, centerIndex, isFixedMockBanner,isPdpTestimonial} = props

    const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0)
    const [length, setLength] = useState(children.length)
    
    const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show)
    const [transitionEnabled, setTransitionEnabled] = useState(true)

    const [touchPosition, setTouchPosition] = useState(null)
    const [buttonState, setButtonState] = useState(false);

    //For Autoscroll Functionality

  const [scrollCount, setScrollCount] = useState(0);
  const [fpmNavButtonClicked, setFpmNavButtonClicked] = useState(false);

  useEffect(() => {
    if(isFixedMockBanner && length ==1){
        setCurrentIndex(0);
    }
    if(isPdpTestimonial && length ==2 || length ==1){
        setCurrentIndex(0);
    }
  },[])
  const nextBanner = () => {
    if (currentIndex <= (length)) {
        setCurrentIndex(prevState => prevState + 1)
    }
    if( currentIndex > length){
        setCurrentIndex(0)
    }
}

    useEffect(() => {
        if(isFixedMockBanner){
            if (props.id === "fixed-mock-banner") {
                const interval = setInterval(() => {
                    if(!fpmNavButtonClicked && length > 1){
                        setScrollCount(scrollCount + 1);
                        nextBanner();
                    }
                }, 5000);
                return () => {
                    clearInterval(interval);
                };
            }
        }
    }, [scrollCount, fpmNavButtonClicked]);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
        setIsRepeating(infiniteLoop && children.length > show)
    }, [children, infiniteLoop, show])

    useEffect(() => {
        if (isRepeating) {
            if (currentIndex === show || currentIndex === length) {
                setTransitionEnabled(true)
            }
        }
    }, [currentIndex, isRepeating, show, length])

    const next = () => {
        if (isRepeating || currentIndex < (length - show)) {
            setCenterIndex(currentIndex<=5? currentIndex-1: currentIndex-6);
            setCurrentIndex(prevState => prevState + 1)
        }
        setButtonState(true);
        if(isFixedMockBanner){
            setFpmNavButtonClicked(true)
            setTimeout(() => {
                setFpmNavButtonClicked(false)
            }, 2500);
        }
        setTimeout(() => {
            setButtonState(false);
        }, 350);
    }

    const prev = () => {
        if (isRepeating || currentIndex > 0) {
            setCenterIndex(currentIndex>=3? currentIndex-3: currentIndex+2);
            setCurrentIndex(prevState => prevState - 1)
        }
        setButtonState(true);
        if(isFixedMockBanner){
            setFpmNavButtonClicked(true)
            setTimeout(() => {
                setFpmNavButtonClicked(false)
            }, 2500);
        }
        setTimeout(() => {
            setButtonState(false);
        }, 350);
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    const handleTransitionEnd = () => {
        if (isRepeating) {
            if (currentIndex === 0) {
                setTransitionEnabled(false)
                setCurrentIndex(length)
            } else if (currentIndex === length + show) {
                setTransitionEnabled(false)
                setCurrentIndex(show)
            }
        }
    }

    const renderExtraPrev = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[length - 1 - index])
        }
        output.reverse()
        return output
    }

    const renderExtraNext = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[index])
        }
        return output
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {/* You can alwas change the content of the button to other things */}
                {
                    (isRepeating || currentIndex > 0)  &&
                    <button onClick={prev} className="left-arrow" disabled={buttonState}>
                        &lt;
                    </button>
                }
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{
                            transform: `translateX(-${currentIndex * (100 / show)}%)`,
                            transition: !transitionEnabled ? 'none' : undefined,
                        }}
                        onTransitionEnd={() => handleTransitionEnd()}
                    >
                        {
                            (length > show && isRepeating) &&
                            renderExtraPrev()
                        }
                        {children}
                        {
                            (length > show && isRepeating) &&
                            renderExtraNext()
                        }
                    </div>
                </div>
                {/* You can alwas change the content of the button to other things */}
                {
                    (isRepeating || currentIndex < (length - show)) &&
                    <button onClick={next} className="right-arrow" disabled={buttonState}>
                        &gt;
                    </button>
                }
            </div>
        </div>
    )
}

export default TestimonialCarouselNew

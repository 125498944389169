import React from 'react';
import { useHistory } from 'react-router';
import { default as Link } from '../../containers/ConnectedLink'

const BreadCrumb = (props) => {
    const history = useHistory();
    const BreadCrumbClick = (e) => {
        if (e.target == "/engineering-study-materials") {
            history.push("/civil-engineering-study-materials");
        } else {
            history.push(e.target);
        }
    }

    return (
        <div className={'custom-breadcrumb'}>
            {
                props.routeData.map(e => {
                    return <span className={'breadcrumb-item'}
                        key={e.name}
                        style={e.target.length ? {
                            'color': '#4a90e2',
                            'cursor': 'pointer'
                        } : { 'color': 'rgba(0, 0, 0, 0.65)' }}>
                        {
                            e.target.length ? (
                                <span onClick={() => BreadCrumbClick(e)}>{e.name == "Engineering" ? "Civil-engineering" : e.name}</span>
                                // <Link to={e.target.toLowerCase()}>{e.name == "Engineering" ? "civil-engineering" : e.name}</Link>
                            ) : (
                                <span>{e.name == "Engineering" ? "Civil-engineering" : e.name}</span>
                            )
                        }
                    </span>;
                })
            }
        </div>
    );
};
export default BreadCrumb;
import React, { useEffect, useState } from "react";
import EnrolledCourses from "../components/enrolledCourses/EnrolledCourses";
import { enrolledCoursesUrl, routeToAppUrl, routeToAppLinkUrl, testEventsUrl, pageName } from "../../constants/appConfig";
import { makeUrl, getDeviceType, isADDA } from '../data/utils/helpers'
import { Redirect } from 'react-router-dom';
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { setLiveClassData_NEW, setMyMocktest, setMyVideos, setSelectedTab } from "../data/ducks/EnrolledCoursesDataObj/actions";
import CustomHelmet from "../components/library/CustomHelmet";
import "../data/ducks/EnrolledCoursesDataObj/reducers"

const cookie = new Cookies();
const EnrolledCourseContainer = (props) => {
  const [courseId, setCourseId] = useState("");
  const [cachedData, setCachedData] = useState(null);
  const [cachedDataKey, setCachedDataKey] = useState(null);

  const getTestSubsections = () => {
    let subSections = [
      {
        id: 0,
        label: getDeviceType() == 'm' ? 'Purchased Mocks' : "Mock Test",
        cachedKey2: "MockTest",
        url: enrolledCoursesUrl.myMockTest,
        actionButtonText: "View Tests",
        onClick: (data) => {
          props.history.push(`my-mock-test/${data.id}`);
        },
        routeToAppUrl: (data) => {
          return `${routeToAppUrl}&link=${routeToAppLinkUrl}/my-mock-test/CPkg_${data.id}`
        },
        noItems: {
          url: "/mock-tests",
          label: "You have not purchased any Mock tests yet.",
          buttonText: "BUY MOCK TESTS",
        },
      },
      {
        id: 1,
        label: "Free Mocks",
        url: enrolledCoursesUrl.myFreeMockTest,
        cachedKey2: "FreeMock",
        actionButtonText: "View Tests",
        onClick: (data) => {
          props.history.push(`my-mock-test/${data.id}`);
        },
        routeToAppUrl: (data) => {
          return `${routeToAppUrl}&link=${routeToAppLinkUrl}/my-mock-test/CPkg_${data.id}`
        },
        noItems: {
          url: "/mock-tests",
          label: "There are no free Mock tests available now.",
          buttonText: "BUY MOCK TESTS",
          noBtn: isADDA ? false : true,
        },
      },
      {
        id: 2,
        label: "Test Events",
        url: testEventsUrl.activeEvents,
        cachedKey2: "TestEvent",
        routeToAppUrl: (data) => {
          return `${routeToAppUrl}&link=${routeToAppLinkUrl}/my-mock-test/CPkg_${data.id}`
        },
        noItems: {
          url: "/mock-tests",
          label: "There are no free Mock tests available now.",
          buttonText: "BUY MOCK TESTS",

        },
      },
      {
        id: 3,
        label: "Live Tests",
        url: enrolledCoursesUrl.myLiveTest,
        cachedKey2: "LiveTests",
        actionButtonText: "View Tests",
        onClick: (data) => {
          props.history.push(`my-mock-test/${data.id}`);
        },
        routeToAppUrl: (data) => {
          return `${routeToAppUrl}&link=${routeToAppLinkUrl}/my-mock-test/CPkg_${data.id}`
        },
        noItems: {
          url: "/mock-tests",
          label: "There are no free Mock tests available now.",
          buttonText: "BUY MOCK TESTS",

        },
        liveTest:true,
      },
    ];

    return subSections;
  }

  useEffect(() => {
    setCourseId(props.location.pathname);
    typeof window !== "undefined" && window.scrollTo(0, 0);
  }, [props.location.pathname]);
  const EnrolledCoursesDataObj = {
    "/my-videos": {
      headerLabel: "My Video Courses",
      cachedKey: "myVideoData",
      subSections: [
        {
          id: 0,
          label: "",
          url: enrolledCoursesUrl && enrolledCoursesUrl.myVideos,
          actionButtonText: "VIEW COURSE",
          onClick: (data) => {
            props.history.push(`/video-package/${data.id}/${makeUrl(data.title)}`)
          },
          routeToAppUrl: (data) => {
            // parentPckgId
            return `${routeToAppUrl}&link=${routeToAppLinkUrl}/video-package/${data.parentPckgId ? `/PPkg_${data.parentPckgId}` : ''}${data.parentPckgId ? `/CPkg_${data.id}` : ''}`
          },
          noItems: {
            url: "/videos",
            label: "You have not purchased any videos yet",
            buttonText: "BUY VIDEO COURSES",
          },
        },
      ],
    },

    "/my-live-class": {
      headerLabel: "LIVE CLASSES",
      cachedKey: "myLiveClass",
      paginatedFlag: true,
      // cardClick route path
      // empty route
      subSections: [
        {
          id: 0,
          label: "",
          url: enrolledCoursesUrl && enrolledCoursesUrl.liveclasses,
          actionButtonText: "VIEW COURSE",
          onClick: (data) => {
            props.history.push(`/my-live-class/${data.id}`)
            // props.history.push(`my-mock-test/${data.id}`);
          },

          noItems: {
            url: "/live-classes",
            label: "You have not purchased any Live Classes yet",
            buttonText: "BUY LIVE CLASS",

          },
        },
      ],
    },
    "/my-mock-test": {
      headerLabel: "MOCK TESTS",
      cachedKey: "myMockTestData",
      subSections: getTestSubsections(),
    },
  };
  
  const token = cookie.get("cp_token");
  if (!token) return <Redirect to="/#show-login" />;
  if (!courseId) return null;
  const setCacheFunction = (data, selectedTab) => {
    let key;
    let dataAction = {};
    if (courseId == "/my-live-class") {
      key = "myLiveClass";
      setCachedDataKey("myLiveClass");
      props.setLiveClassData(data);
    } else if (courseId == "/my-videos") {
      setCachedDataKey("myVideoData");
      props.setMyVideos(data);
    } else if (courseId == "/my-mock-test") {
      setCachedDataKey("myMockTestData");
      props.setMyMocktest({ "data": data, "selectedTab": selectedTab });
    }
  }
  return (
    <>
    <CustomHelmet title={`${pageName[1]} - ${isADDA ? 'India’s Leading Government Job Preparation platform' : 'Live Classes and Doubt Sessions for JEE, NEET'}`} />
    <EnrolledCourses
      courseObj={EnrolledCoursesDataObj[courseId]}
      props={props}
      cachedData={props.enrolledCoursesDataObj || []}
      cachedDataKey={cachedDataKey}
      setSelectedTabInCache={(tab) => {
        props.setSelectedTab(tab)
      }}
      setCache={(data, selectedTab) => setCacheFunction(data, selectedTab)}
    ></EnrolledCourses>
    </>
  );
};
const mapStateToProps = state => ({
  enrolledCoursesDataObj: state.enrolledCoursesDataObj,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setLiveClassData: (data) => dispatch(setLiveClassData_NEW(data)),
    setMyVideos: (data) => dispatch(setMyVideos(data)),
    setMyMocktest: (data) => dispatch(setMyMocktest(data)),
    setSelectedTab: (tab, scroll) => dispatch(setSelectedTab({ "tab": tab, "scroll": scroll }))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnrolledCourseContainer);
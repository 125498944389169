import React, { useEffect, useState } from "react";
import CustomTab from "../components/library/CustomTab";
import LiveClassesCard from "../components/LiveClasses/LiveClassesCard";
import fetchapi from "../data/utils/fetch";
import { enrolledCoursesUrl, videoPackageUrl } from "../../constants/appConfig";
import {
  dateMonthTime,
  getTimeAmPM,
  getMonth,
  getDay,
} from "../components/utils";
import CustomLoader from "../components/library/CustomLoader";
import CatalogCard from "../components/CatalogCard";
import MyaccountNavbar from "./Myaccountnavbar";
import {
  getDeviceType,
  createOptimizedImgUrl,
  scrollToTop,
  removePPKG,
} from "../data/utils/helpers";
import NoDataFound from "../components/LiveClasses/NoDataFound";
import CustomModal from "../components/library/CustomModal";
import { default as Link } from "../containers/ConnectedLink";
import { Redirect } from "react-router";
import { Cookies } from "react-cookie";
import { loadVideoJsScripts } from "../components/utils";

const cookie = new Cookies();
let isvideoopen = false;
const MyLiveClassesVideoPackage = (props) => {
  let joinClassTimer = null;
  let timer = 1;
  let count = 1;
  let globalservertimesync = null;
  var arrIndexForTimer = [],
    arrIndexForEndTimer = [];
  const [showOpenInAppModal, setShowOpenInAppModal] = useState(false);
  const [videoPackageId, setVideoPackageId] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [courseItemList, setCourseItemList] = useState([]);
  const [classInfo, setClassInfo] = useState("");
  const [expired, setExpired] = useState("");
  const [parentInfo, setParentInfo] = useState("");
  const [recentParentInfo, setrecentParentInfo] = useState("");
  const [published, setPublished] = useState(false);
  const [expiredText, setexpiredText] = useState("");
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const [tempjoinurl, settempjoinUrl] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [webinarurl, setwebinarUrl] = useState("");
  const [matcharray, setMatchArray] = useState([]);
  const [matchJsonValue, setMatchSecondValue] = useState([]);
  const [DateMatch, setMatchDate] = useState(null);
  const [displayEmptyImage, setDisplayEmptyImage] = useState(false);
  const [serverTime, setGlobarlServerTime] = useState();
  const [showmodal, setShowModal] = useState(false);
  const [videoJsOptions, setvideoJsOptions] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buymodal, setbuymodal] = useState(false);
  const [datailsmodal, setdatailsmodal] = useState(false);
  const [childtitle, setchildtitle] = useState("");
  const [visibilityChange, setvisibilityChange] = useState("");
  const [hidden, sethidden] = useState("");
  const [loading, setLoading] = useState([false, false, false]);
  const [mainLoading, setMainLoading] = useState(true);
  const [tokenvalue, settoken] = useState("");
  const [scheduleinfo, setscheduleinfo] = useState(false);
  const [newResponse, setNewResponse] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [timestampData, setTimestampData] = useState(null);
  const [videoStatusData, setVideoStatusData] = useState([]);
  const [time, setTime] = useState(Date.now());
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [nextVideoId, setNextVideoId] = useState(null);
  const [nextThumbnailImage, setNextThumbnailImage] = useState(null);

  const updateLoadingState = (value, idx) => {
    let updatedLoading = [...loading];
    updatedLoading[idx] = value; // can even toggle;
    setLoading(updatedLoading);
  };

  const isvideoopenhandler = (value) => {
    isvideoopen = value;
  };

  useEffect(() => {
    if (typeof hj !== "undefined") {
      hj("event", "started_video");
    }
    // loadVideoJsScripts();
  }, []);

  useEffect(() => {
    scrollToTop(0);
    let url_timestamp = enrolledCoursesUrl.liveclassesV1;
    fetchapi(`${url_timestamp}/current-timestamp`, "GET").then((res) => {
      if (res.success) {
        setTimestampData(res.data);
        fetchapicall(res.data);
        handlePageVisibility(res.data);
      }
    });
    return () => {
      document.removeEventListener(
        visibilityChange,
        function () {
          if (!document[hidden]) {
            if (window.location.href.indexOf("my-live-class/") !== -1) {
              handlevisibilitychange();
            }
          }
        },
        false
      );
    };
  }, [props.match.params.id]);

  // useEffect(() => {
  //   const interval = setInterval(() => setTime(Date.now()), 20000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  function handlePageVisibility(newTime = null) {
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    setvisibilityChange(visibilityChange);
    sethidden(hidden);
    document.addEventListener(
      visibilityChange,
      function () {
        if (!document[hidden]) {
          if (window.location.href.indexOf("my-live-class/") !== -1) {
            handletabrefresh(newTime);
            count++;
            timer = timer * count;
          }
        }
        clearInterval(joinClassTimer);
        // clearInterval(globalservertimesync);
      },
      false
    );
  }

  const handletabrefresh = (newTimeHandle = null) => {
    if (!document[hidden] && !isvideoopen) {
      fetchapicall(newTimeHandle);
    } else {
      // clearInterval(joinClassTimer);
    }
  };

  const setCurrentTimeStamp = () => {
    let url_timestamp = enrolledCoursesUrl.liveclassesV1;
    fetchapi(`${url_timestamp}/current-timestamp`, "GET").then((res) => {
      // updateLoadingState(false, 1);
      if (res.success) {
        setTimestampData(res.data);
      }
    });
  };

  const fetchVideosStatus = () => {
    let finalId =
      getDeviceType() == "m"
        ? props.match.params.child
          ? removePPKG(props.match.params.child)
          : removePPKG(props.match.params.id)
        : props.match.params.id;

    fetchapi(`${videoPackageUrl?.videoStatus}?packageId=${finalId}`)
      .then((res) => {
        if (res.success) {
          let data = res.data;
          setVideoStatusData(data);
        } else {
          setVideoStatusData([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchapicall = (timeee = null) => {
    if (!timeee) {
      timeee = timestampData;
    }
    //  api call start
    //  clearInterval(joinClassTimer);
    let url = enrolledCoursesUrl.videoClasses;
    let dateArray = [];
    let enddateArray = [];
    let tempMatchArray = [];
    let todMatches = [];
    let endtempMatchArray = [];
    let errorMessage = null;
    let zeroState = [];
    var getWebinarLink = "";
    var matchDate = "";
    var globalServerTime = "";

    function synchServerTime(tempArg) {
      let globalServerTime = tempArg;
      globalservertimesync = setInterval(() => {
        globalServerTime += 1000;
        setGlobarlServerTime(globalServerTime);
      }, 1000);
      return globalServerTime;
    }
    // updateLoadingState
    // updateLoadingState(true, 0);
    let finalId =
      getDeviceType() == "m"
        ? props.match.params.child
          ? removePPKG(props.match.params.child)
          : removePPKG(props.match.params.id)
        : props.match.params.id;

    fetchVideosStatus();

    fetchapi(`${url}/${finalId}`, "GET").then(
      (newresp) => {
        setNewResponse(newresp?.data?.chapter?.video);
        // updateLoadingState(false, 0);
        if (newresp.success && newresp?.data) {
          setClassInfo(newresp?.data);
          let data = newresp?.data;
          // for expiry date
          if (data.expired) {
            setExpired(data.expired);
          }
          //Parent Info
          if (data.parentInfo) {
            setParentInfo(data.parentInfo);
          }
          //Recent Parent INfo
          setrecentParentInfo(data.recentParentInfo);
          //for package published/unpublished
          if (data.published) {
            setPublished(data.published);
          }

          //seeting expired text
          const d = new Date(data && data.expiryDate);
          const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            d
          );
          const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
            d
          );
          const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
            d
          );
          let expireddatevalue = `Expired on ${da} ${mo} ${ye}`;
          setexpiredText(expireddatevalue);
          //About course description
          if (data?.chapter?.desc) setDesc(data?.chapter?.desc);
          if (data?.title) setTitle(data?.title);
          //Error Message
          if (data.message && data.message !== null && data.message !== "") {
            errorMessage = data.message;
            setErrorMessage(errorMessage);
            setDisplayEmptyImage(true);
            return;
          }
          if (data) {
            var classInformation = data;
            if (data?.chapter?.video) {
              let videoArray = data.chapter.video;
              videoArray.forEach((element, elemIndex) => {
                let arrIndexForTimer = [];
                let arrIndexForEndTimer = [];
                var tempdateobj = element.startTime - 19800000;
                var mydate = new Date(tempdateobj);
                var joinurl = element.joinUrl || "";
                var tempObj = {};
                tempObj.startDate = element.startTime - 19800000;
                tempObj.joinurl = joinurl || "";
                let result = dateMonthTime(tempdateobj);
                tempObj.date = mydate.getDate() + " " + getMonth(tempdateobj);
                tempObj.time = getTimeAmPM(tempdateobj);
                tempObj.day = getDay(tempdateobj);
                tempObj.dateMonthTime = result.dateMonthTime;
                tempObj.dateMonthMatch = result.dateMonthMatch;
                tempObj.endDate = element.endTime - 19800000;
                var endTempDate = new Date(tempObj.endDate);
                tempObj.startTimeInMilli = mydate.getTime();
                tempObj.endTimeInMilli = endTempDate.getTime();
                var milliseconds =
                  new Date(tempObj.endDate) - new Date(tempObj.startDate);
                tempObj.minutes = milliseconds / 60000;
                tempObj.topicName = element.name;
                tempObj.faculty = element?.faculty[0]?.name;
                tempObj.facultyId = element?.faculty[0]?.id;
                tempObj.pdfFileName = element?.pdfFileName;
                tempObj.recordedUrl = element.url || element.hlsUrl;
                tempObj.webDrmEnabled = element.webDrmEnabled;
                tempObj.hlsUrl = element.hlsUrl;
                tempObj.dashUrl = element.dashUrl;
                tempObj.id = element.sequence;
                tempObj.classId = element?.classId;
                tempObj.packageId = element.packageId;
                tempObj.status = element?.status;
                tempObj.olcType = element?.olcType;
                tempObj.title = classInformation?.title;
                tempObj.externalScheduleId = element?.externalScheduleId;
                tempObj.bookmark = element?.bookmarks;
                tempObj.externalScheduleId = element?.externalScheduleId;
                tempObj.handoutsAvailable = element?.handoutsAvailable;
                tempObj.tImg = element?.tImg;
                tempObj.dppFileNames = element?.dppFileNames;

                var obj = {};
                if (timeee || timestampData) {
                  //format date
                  const d = new Date(timeee || timestampData);
                  const ye = new Intl.DateTimeFormat("en", {
                    year: "numeric",
                  }).format(d);
                  const mo = new Intl.DateTimeFormat("en", {
                    month: "short",
                  }).format(d);
                  const da = d.getDate();
                  matchDate = da + " " + mo + " " + ye;
                  setMatchDate(matchDate);
                  if (matchDate == tempObj.dateMonthMatch) {
                    todMatches.push(elemIndex);
                  }
                  globalServerTime = synchServerTime(timeee || timestampData);
                }
                var classStartDiff =
                  globalServerTime - tempObj.startTimeInMilli;
                var classEndDiff = globalServerTime - tempObj.endTimeInMilli;
                if (classStartDiff >= 0) {
                  if (tempObj.endTimeInMilli - globalServerTime > 0) {
                    tempObj.classFlag = "JOIN LIVE";
                    tempObj.remainingTimeToEnd = classEndDiff;
                    arrIndexForEndTimer.push(elemIndex);
                  } else {
                    tempObj.classFlag = "Watch";
                  }
                } else if (classStartDiff <= -900000) {
                  tempObj.classFlag = "COMING SOON";
                  if (matchDate == tempObj.dateMonthMatch) {
                    arrIndexForTimer.push(elemIndex);
                    tempObj.remainingTime = -classStartDiff;
                    tempObj.remainingTimeToEnd = classEndDiff;
                  }
                } else if (classStartDiff >= -900000 && classStartDiff <= 0) {
                  tempObj.classFlag = "Start In";
                  arrIndexForTimer.push(elemIndex);
                  tempObj.remainingTime = -classStartDiff;
                  tempObj.remainingTimeToEnd = classEndDiff;
                } else if (classStartDiff == 0) {
                  tempObj.classFlag = "JOIN LIVE";
                  tempObj.remainingTimeToEnd = classEndDiff;
                  arrIndexForEndTimer.push(elemIndex);
                }
                //set current time
                setCurrentTime(timeee || timestampData);
                tempMatchArray.push(tempObj);
              });
              setTodayMatches(todMatches);
              setMainLoading(false);
              if (
                arrIndexForTimer.length > 0 ||
                arrIndexForEndTimer.length > 0
              ) {
                startTimer(tempMatchArray);
              }
              setMatchArray(tempMatchArray);
            }
            getWebinarLink = classInformation?.webinarJsonUrl;
            //JOin url
            settempjoinUrl(classInformation.joinUrl);
            //webinar url
            setwebinarUrl(classInformation.joinUrl);
          }
          //end
        } else {
          setClassInfo(null);
        }
      },
      (err) => {
        setClassInfo(null);
      }
    );
    //api call end
  };
  //back to my-live-class
  const goToEbooks = () => {
    props.history.push(`/my-live-class`, "_self");
  };

  // display timer function

  var expected = Date.now() + 1000;
  function startTimer(marray) {
    let interval = 1000;
    joinClassTimer = setInterval(function () {
      let timelap = Date.now() - expected;
      arrIndexForTimer.forEach(function (elemIndex, i) {
        marray[elemIndex].remainingTime -= 1000;
        var timeInSeconds = marray[elemIndex].remainingTime / 1000;
        var minutes = parseInt(timeInSeconds / 60, 10);
        var seconds = parseInt(timeInSeconds % 60, 10);
        if (minutes === 0 && seconds === 0) {
          marray[elemIndex].classFlag = "JOIN LIVE";
          arrIndexForEndTimer.push(elemIndex);
          arrIndexForTimer.splice(i, 1);
          return;
        } else if (minutes < 15 || (minutes === 15 && seconds === 0)) {
          marray[elemIndex].classFlag = "Start In";
        }
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        marray[elemIndex].displayTime = minutes + ":" + seconds;
        setMatchArray(marray);
      });
      arrIndexForEndTimer.forEach(function (elemIndex, i) {
        marray[elemIndex].remainingTimeToEnd += 1000;
        var timeInSeconds = marray[elemIndex].remainingTimeToEnd / 1000;
        var minutes = parseInt(timeInSeconds / 60, 10);
        var seconds = parseInt(timeInSeconds % 60, 10);
        if (minutes === 0 && seconds === 0) {
          marray[elemIndex].classFlag = "Watch";
          arrIndexForEndTimer.splice(i, 1);
          return;
        }
      });
      if (arrIndexForTimer.length === 0 && arrIndexForEndTimer.length === 0) {
        clearInterval(joinClassTimer);
      }
    }, 1000);
  }

  const setVisibleDetailsmodal = () => {
    setdatailsmodal(false);
  };
  const setvisiblebuymodal = () => {
    setbuymodal(false);
  };

  const showExpiredModal = (
    parentInfo,
    IschildPublished,
    IsParentPublished,
    childtitle
  ) => {
    setchildtitle(childtitle);
    !parentInfo && IschildPublished && setbuymodal(true);
    !parentInfo && !IschildPublished && setbuymodal(true);
    parentInfo &&
      IschildPublished &&
      parentInfo[0] &&
      !parentInfo[0].published &&
      setbuymodal(true);
    parentInfo &&
      !IschildPublished &&
      parentInfo[0] &&
      parentInfo[0].published &&
      setdatailsmodal(true);

    parentInfo &&
      IschildPublished &&
      parentInfo[0] &&
      parentInfo[0].published &&
      setdatailsmodal(true);
    let array = [];
    parentInfo && parentInfo.length > 0 && array.push(parentInfo[0]);
    array.length > 1 && setParentInfo(array);
    if (parentInfo && parentInfo.length > 1) {
      parentInfo[0].published &&
        parentInfo[1].published &&
        setdatailsmodal(true);
      array = [];
      array.push(parentInfo[0]);
      array.push(parentInfo[1]);
      setParentInfo(array);
    }
  };
  const ScheduleInfo = () => {
    setscheduleinfo(true);
  };

  let subsections = [];

  subsections.push({
    id: 0,
    label: "About This Course",
  });
  subsections.push({
    id: 1,
    label: `Today's Classes`,
  });
  subsections.push({
    id: 2,
    label: "All Classes",
  });

  const getVideoData = (data, recordedUrl) => {
    if (recordedUrl) {
      var vdata = null;
      data?.forEach((item) => {
        if (item.vid === recordedUrl) {
          if (item?.wt > 0) item.status = "Resume";
          if (item?.wt === 0 && item?.twt > 0) item.status = "Replay";
          vdata = item;
        }
      });
      return vdata;
    } else {
      return null;
    }
  };
  const nextVideoPlayHandler = () => {
    // Get the element with id "custom-modal"
    isvideoopen = false;
    const foundIndex = matcharray.findIndex(
      (obj) => obj.classId === selectedVideoId
    );

    if (foundIndex !== -1) {
      // Check for the next object with the "recordedvideourl" property
      const nextObjectWithRecordedVideoUrl = matcharray
        .slice(foundIndex + 1)
        .find((obj) => obj.recordedUrl);

      if (nextObjectWithRecordedVideoUrl) {
        const nextVideoId = nextObjectWithRecordedVideoUrl.classId;
        const thumbnailImg = nextObjectWithRecordedVideoUrl.tImg;
        // console.log("Next video with recordedvideourl, classId:", nextVideoId);
        // alert("nextVideoId", nextVideoId);
        setNextThumbnailImage(thumbnailImg);

        // console.log("Next video with recordedvideourl, classId:", nextVideoId);
        // alert("nextVideoId", nextVideoId);
        setNextVideoId(nextVideoId);
      } else {
        setNextThumbnailImage("");

        console.log("No next object with recordedvideourl found.");
      }
    } else {
      setNextThumbnailImage("");

      console.log("Object with classId", selectedVideoId, "not found.");
    }
  };
  const setSelectedVideoIdHandler = (id) => {
    setSelectedVideoId(id);
    const foundIndex = matcharray.findIndex((obj) => obj.classId === id);
    if (foundIndex !== -1) {
      // Check for the next object with the "recordedvideourl" property
      const nextObjectWithRecordedVideoUrl = matcharray
        .slice(foundIndex + 1)
        .find((obj) => obj.recordedUrl);

      if (nextObjectWithRecordedVideoUrl) {
        const thumbnailImg = nextObjectWithRecordedVideoUrl.tImg;

        setNextThumbnailImage(thumbnailImg);
      } else {
        setNextThumbnailImage("");

        console.log("No next object with recordedvideourl found.");
      }
    } else {
      setNextThumbnailImage("");

      console.log("Object with classId", selectedVideoId, "not found.");
    }
  };

  const token = cookie.get("cp_token");
  if (token) {
    return (
      <div className=" small-12 columns live-class-container">
        {/* start modal */}
        <CustomModal
          modalClassName={"modalZindex"}
          footer={null}
          visible={buymodal}
          // hideHeader={true}
          // hideCross={false}
          title="Buy Online classes"
          onCancel={setvisiblebuymodal}
          closeCallback={setvisiblebuymodal}
          closable={true}
          maskClosable={false}
          keyboard={false}
          bodyStyle={{ overflowY: "auto" }}
        >
          <div className="video-popup myebookpopup">
            <div
              style={{
                backgroundColor: "rgb(255, 229, 207)",
                textAlign: "center",
              }}
            >
              <img
                src={createOptimizedImgUrl(
                  "https://s3.ap-south-1.amazonaws.com/storefront-assets/Expiry.png",
                  200
                )}
                style={{ width: "200px" }}
              />
            </div>
            <div>
              <div
                style={{
                  margin: "20px 0",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "1.2",
                  textAlign: "center",
                  color: "#090023",
                }}
              >
                Package Expired!
              </div>
              <div
                style={{
                  margin: "0 24px 44px",
                  opacity: "0.8",
                  fontSize: "14px",
                  lineHeight: "1.43",
                  textAlign: "center",
                  color: "#444545",
                }}
              >
                <span>
                  This product is not available anymore. You can still view
                  similar products in this category.
                </span>
              </div>
              {!parentInfo && published ? (
                <Link
                  to={`/product-onlineLiveClasses/${props.match.params.id}/${title}`}
                >
                  <div style={{ textAlign: "center" }}>
                    <a
                      style={{
                        width: "200px",
                        padding: "5px",
                        height: "44px",
                        backgroundColor: "#f57f18",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#ffffff",
                      }}
                      className="sf-button-secondary "
                    >
                      Buy Online classes
                    </a>
                  </div>
                </Link>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <a
                    style={{
                      width: "200px",
                      padding: "5px",
                      height: "44px",
                      backgroundColor: "#f57f18",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#ffffff",
                    }}
                    className="sf-button-secondary"
                    href="/live-classes"
                  >
                    Buy Online classes
                  </a>
                </div>
              )}
            </div>
          </div>
        </CustomModal>
        <div className="modal-container">
          <CustomModal
            footer={null}
            visible={datailsmodal}
            title={"Renew Package"}
            onCancel={setVisibleDetailsmodal}
            closeCallback={setVisibleDetailsmodal}
            closable={true}
            maskClosable={false}
            keyboard={false}
            modalClassName="largemodal"
            bodyStyle={{ overflowY: "auto" }}
          >
            <div className="myebookpopup">
              <p>
                {title || ""}
                <div>is Available with the below courses</div>
              </p>
              {parentInfo &&
                parentInfo?.length > 0 &&
                parentInfo?.map((value) => {
                  return (
                    <Link
                      to={`/product-onlineLiveClasses/${value && value.id}/${
                        value && value.title
                      }`}
                    >
                      <div className="catalog-card ">
                        <div className="catalog-card-media">
                          <div className="catalog-card-overlay"></div>
                          <div>
                            <img
                              src={createOptimizedImgUrl(
                                value && value.imageUrl,
                                90
                              )}
                              height="110px"
                            />
                          </div>
                        </div>
                        <div className="catalog-card-content ">
                          <div className="package-title">
                            {(value && value.title) || ""}
                          </div>
                          <div className="catalog-card-subheader">
                            <div
                              className="package-subheader"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="paid-itemCount"></div>
                            </div>
                          </div>
                          <div className="catalog-card-text">Details</div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </CustomModal>
        </div>
        <div className="scheduleinfo">
          <CustomModal
            footer={null}
            visible={scheduleinfo}
            title={"Live class and schedule info"}
            onCancel={setscheduleinfo}
            closeCallback={setscheduleinfo}
            closable={true}
            maskClosable={false}
            keyboard={false}
            modalClassName="largemodal"
            bodyStyle={{ overflowY: "auto" }}
          >
            <div>
              <div className="d-flex pad-30-bottom">
                <strong> Packages Includes</strong>
                <span className="pad-10-top">{title}</span>
              </div>
              <div>
                <table>
                  <tr>
                    <th>Title </th>
                    <th>Topic</th>
                    <th>Teacher</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                  {matcharray.length > 0 &&
                    matcharray.map((data) => {
                      return (
                        <tr>
                          <td className="title-td">{title}</td>
                          <td>
                            {data.topicName
                              ? data.topicName
                              : "Topic will be updated soon "}
                          </td>
                          <th>{data.faculty ? data.faculty : "NA"}</th>
                          <td>
                            {data.date && data.day
                              ? data.date + " " + data.day
                              : ""}
                          </td>
                          <td>{data.time ? data.time : "NA"}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </CustomModal>
        </div>
        {/* end modal */}
        <div className="forLeftSliderOnly">
          <div className="myprofile-container leftslideenroll">
            <MyaccountNavbar></MyaccountNavbar>
          </div>
          <div className="margin-left-70">
            <div className=" my-test-series-header container-list-style">
              <div className="title-ellipsis">
                <span className={"show-only-web-class"} onClick={goToEbooks}>
                  <img src="/images/back-arrow.svg" className="img-width" />
                </span>
                <span> {title}</span>
              </div>
              {expired ? (
                <div
                  className="show-only-web-class"
                  style={{
                    color: "rgb(213, 17, 0)",
                    fontSize: "14px",
                    lineHeight: "1",
                    fontWeight: "normal",
                    marginLeft: "38px",
                    marginTop: "-10px",
                  }}
                >
                  {expiredText}
                  <span
                    className="renew-package"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      showExpiredModal(
                        parentInfo,
                        published,
                        parentInfo && parentInfo.published
                      )
                    }
                  >
                    <u style={{ marginLeft: "10px" }}>RENEW PACKAGE</u>
                  </span>
                </div>
              ) : (
                <div className="scheduleinfotext">
                  <span
                    className="renew-package"
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      ScheduleInfo(
                        parentInfo,
                        published,
                        parentInfo && parentInfo.published
                      )
                    }
                  >
                    <u style={{ marginLeft: "10px" }}>
                      View Live classes and schedule Info
                    </u>
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex d-dir">
              <CustomTab
                tabItemList={subsections}
                onSelect={setSelectedTab}
                activeId={selectedTab}
                chevron={true}
              ></CustomTab>
              <div className="d-flex flex-direction overflow-auto overflow-auto1">
                {/* course detail section */}
                <div
                  className={selectedTab == 0 ? "flex-center" : "display-none"}
                  id="courseDesc"
                  style={{ height: "900px" }}
                >
                  <div className="small-12 columns selected-grp-container">
                    <div className="md-whiteframe-1dp row cart-detail-card-align card-move-top card-list-container height-fitcontent">
                      <div>
                        <div className="large-12 columns card-list-container-card">
                          <div className="my-test-series-test-title">
                            <div className="left-underline course-description-text">
                              Course Description
                            </div>
                            <div
                              className="course-class-description"
                              dangerouslySetInnerHTML={{ __html: desc }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {mainLoading ? (
                  <div>
                    <CustomLoader style={{ opacity: ".5" }} />
                  </div>
                ) : (
                  ((todayMatches && todayMatches.length == 0) ||
                    errorMessage) &&
                  selectedTab == 1 && (
                    <div>
                      {/* data not found case for todays class */}
                      <NoDataFound
                        errorMessage={errorMessage}
                        selectedOption={setSelectedTab}
                        isExpired={expired}
                        showExpiredModal={showExpiredModal}
                        parentInfo={parentInfo}
                        published={published}
                        recentParentInfo={recentParentInfo}
                        subsectionsId={2}
                        displayMessage={"VIEW ALL CLASSES"}
                      />
                    </div>
                  )
                )}
                <div
                  className={
                    expired && !errorMessage && matcharray.length > 0
                      ? "expired-live-class"
                      : ""
                  }
                >
                  {/* {
                  mainLoading && <div><CustomLoader style={{ opacity: '.5' }} /></div>
                } */}
                  {loading.includes(true) ? (
                    <div>
                      <CustomLoader style={{ opacity: ".5" }} />
                    </div>
                  ) : matcharray.length > 0 && !errorMessage ? (
                    matcharray.map((element, ind) => {
                      return (
                        <div key={`inde__${ind}`}>
                          <LiveClassesCard
                            token={token}
                            id={props.match.params.id}
                            data={element}
                            activeId={selectedTab}
                            matchDate={DateMatch}
                            isExpired={expired}
                            globalServerTime={serverTime}
                            desc={desc}
                            webinarurl={webinarurl}
                            displayEmptyImage={displayEmptyImage}
                            errorMessage={errorMessage}
                            showExpiredModal={showExpiredModal}
                            parentInfo={parentInfo}
                            published={published}
                            recentParentInfo={recentParentInfo}
                            setisvideoopen={isvideoopenhandler}
                            isvideoopen={isvideoopen}
                            todayMatches={todayMatches}
                            title={title ? title : ""}
                            history={props.history}
                            cookie={cookie}
                            fetchVideosStatus={fetchVideosStatus}
                            videoStatusData={
                              videoStatusData &&
                              getVideoData(
                                videoStatusData,
                                element?.recordedUrl
                              )
                            }
                            nextVideoPlayHandler={() => nextVideoPlayHandler()}
                            setSelectedVideoId={(id) =>
                              setSelectedVideoIdHandler(id)
                            }
                            nextVideoId={nextVideoId}
                            nextThumbnailImage={nextThumbnailImage}
                          />
                        </div>
                      );
                    })
                  ) : (
                    (matcharray.length > 0 ||
                      matcharray.length == 0 ||
                      errorMessage) &&
                    selectedTab == 2 && (
                      <div>
                        {" "}
                        <NoDataFound
                          errorMessage={errorMessage}
                          selectedOption={setSelectedTab}
                          isExpired={expired}
                          showExpiredModal={showExpiredModal}
                          parentInfo={parentInfo}
                          published={published}
                          recentParentInfo={recentParentInfo}
                          subsectionsId={goToEbooks}
                          displayMessage={"GO TO LIVE CLASSES"}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
};
export default MyLiveClassesVideoPackage;

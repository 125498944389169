import React from 'react'
import { pageName } from '../../../constants/appConfig';

const NoDataFound = ({ errorMessage, setSelectedFilter, selectedFilter }) => {


    return (



        <div className="center" style={{ width: "100%" }}>

            {<div>
                <img src={`https://storecdn.${pageName[0]}.com/ic_empty_liveclass.svg`} style={{ width: "300px" }} />
                <div>
                    <span  >{selectedFilter == "Today" ? "You don't have any class scheduled today" : selectedFilter == "Recorded" ? "Videos will be updated soon. Please try after sometime" : "Live session data will be updated soon please try again later"}</span>
                </div>


                {selectedFilter == "Today" && <div className="padding-top-20 "  >
                    <button className="sf-button-secondary "
                        onClick={() => setSelectedFilter("")}>VIEW ALL CLASSES</button>
                </div>}
            </div>}


        </div>

    )

}
export default NoDataFound;
import React, { Component } from 'react';
import { default as Link } from '../containers/ConnectedLink';
import { Cookies } from "react-cookie";
import { STOREFRONT_URI } from '../../constants/appConfig'
import { connect } from "react-redux";
import { fireWishListMoengageEvent, getMoengageMetadata, isADDA } from '../data/utils/helpers';
import { BLANK_IDENTIFIER } from '../components/MoengageConstants';
const cookie = new Cookies();
let email = ""
let name = ""
class MyaccountNavbar extends Component {

    constructor(props) {
        super(props);
        email = cookie.get('cp_user_email');
        name = cookie.get('cp_user_name');
        this.state = {
            userDetails: []
        };
    }

    handleUserAccountLink = (linkParam) => {
        typeof window !== 'undefined' && window.open(`${STOREFRONT_URI}${linkParam}`);
    };
    handleNavigation = () => {
        if (typeof document != "undefined" && typeof window != "undefined") {
            let element = window.document.getElementById("navigationbar");
            element.classList.toggle({ width: "60px" })
        }
    }

    moeHandler = (value) => {
        let payload = { action: `my_${value}btnClicked` };
        if (value === "test") {
            payload.index = BLANK_IDENTIFIER;
            payload.content_subject = BLANK_IDENTIFIER;
            payload.rating = BLANK_IDENTIFIER;
            payload.content_url = BLANK_IDENTIFIER;
            payload.exam_category = BLANK_IDENTIFIER;
            payload.user_exam_category_selected = BLANK_IDENTIFIER;
            payload.exam = BLANK_IDENTIFIER;
            payload.language = "English";
            payload.content_language = BLANK_IDENTIFIER;
            payload.content_id = BLANK_IDENTIFIER;
            payload.content_title = BLANK_IDENTIFIER;
            payload.package_status = BLANK_IDENTIFIER;
            payload.package_id = BLANK_IDENTIFIER;
            payload.package_title = BLANK_IDENTIFIER;
            payload.package_type = BLANK_IDENTIFIER;
            payload.package_purchased = BLANK_IDENTIFIER;
            payload.deep_link = BLANK_IDENTIFIER;
        }
        if (typeof (Moengage) !== 'undefined') Moengage.track_event(
            value,
            getMoengageMetadata(payload),
        );
    }

    render() {
        return (



            <div className='sticky' >
                <div className="area" onClick={this.handleNavigation}>
                </div><nav id="navigationbar" className="main-menu">
                    <ul>

                        <li className='has-subnav'>
                            <Link to='/my-profile'  >
                                <span className="nav-icon" style={{ paddingRight: "10px" }}>
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Profile</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -16.000000)">
                                                <g id="Item-Copy-3" transform="translate(4.000000, 0.000000)">
                                                    <g id="ic-nav-profile" transform="translate(26.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="profile" className="icon-bg" transform="translate(9.000000, 9.000000)"
                                                            stroke="#FFFFFF">
                                                            <path d="M15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 Z"
                                                                id="Oval-12" strokeLinejoin="round" fillRule="nonzero"></path>
                                                            <circle id="Oval-10" cx="14.8436725" cy="10.4218362" r="4.42183623"></circle>
                                                            <path d="M23.6873449,26.8436725 C23.6873449,21.959447 19.7278979,18 14.8436725,18 C9.95944703,18 6,21.959447 6,26.8436725"
                                                                id="Path" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <div className="nav-text">
                                    <p style={{ maxWidth: "190px", marginLeft: "10px !important" }}>  {this.props.userDetails.name || "Myaccount"}

                                    </p>
                                    <p style={{ maxWidth: "100px", marginLeft: "10px !important" }}> {this.props.userDetails?.phone && this.props.userDetails?.phone != "null" ? this.props.userDetails?.phone : (this.props.userDetails?.email && this.props.userDetails?.email != "null" ? this.props.userDetails?.email : "")}
                                    </p>
                                </div>



                            </Link>

                        </li>
                        {!isADDA && <li className="has-subnav" onClick={() => this.moeHandler("3dmodels")}>
                            <Link to={'/my-3d-models'}>
                                <span className="nav-text">
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >

                                        <title>3D Learning</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -416.000000)">
                                                <g id="My-Orders" transform="translate(4.000000, 400.000000)">
                                                    <g id="ic-nav-orders" transform="translate(26.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="Group-58" transform="translate(9.000000, 6.000000)" stroke="#FFFFFF" className="icon-bg"
                                                            strokeLinecap="round" strokeLinejoin="round">
                                                            <polygon id="Path-4" points="14.8536309 35.7142857 14.8536309 17.8571429 29.6358817 9.28571429 29.6374534 27.1428571"></polygon>
                                                            <polygon id="Path-4-Copy" transform="translate(7.464425, 22.500000) scale(-1, 1) translate(-7.464425, -22.500000) "
                                                                points="0.0738783943 35.7142857 0.0711495536 17.8571429 14.8577009 9.28571429 14.8577009 27.1428571"></polygon>
                                                            <polygon id="Path-5" points="0.0711495536 9.28571429 14.8577009 0.666199779 29.6358817 9.28571429 14.8577009 17.8571429"></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>

                                <span className="nav-text">
                                    <p className="p1">My 3D Learning</p>
                                </span>
                            </Link>

                        </li>}
                        <li className="has-subnav" onClick={() => this.moeHandler("test")}>
                            <Link to='/my-mock-test'>
                                <span className="nav-text">
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Test Series</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -176.000000)">
                                                <g id="Group-10" transform="translate(0.000000, 160.000000)">
                                                    <g id="ic-nav-mocktest" transform="translate(30.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="Group-14" transform="translate(12.000000, 10.000000)">
                                                            <path className="icon-bg" d="M18.3734483,4 L20,4 C22.209139,4 24,5.790861 24,8 L24,24 C24,26.209139 22.209139,28 20,28 L4,28 C1.790861,28 2.705415e-16,26.209139 0,24 L0,8 C-2.705415e-16,5.790861 1.790861,4 4,4 L5.71436912,4"
                                                                id="Path" stroke="#FFFFFF" strokeLinecap="round"
                                                                strokeLinejoin="round"></path>
                                                            <path className="icon-bg" d="M6,14.5 L19,14.5" id="Line-Copy-6" stroke="#FFFFFF"
                                                                strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path className="icon-bg" d="M6,19.5 L14,19.5" id="Line-Copy-13" stroke="#FFFFFF"
                                                                strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path className="icon-bg" d="M8.83680199,1.5 C9.40737869,0.296790287 10.6279233,-0.5 12,-0.5 C13.3720767,-0.5 14.5926213,0.296790287 15.163198,1.5 L16.7719882,1.5 C17.377135,1.5 17.917449,1.96632179 18.0006865,2.56432139 L18.490447,6.08288609 C18.597756,6.85382179 18.0356385,7.5 17.2535115,7.5 L6.74616424,7.5 C5.96736231,7.5 5.40168359,6.85551799 5.50922874,6.08288609 L5.99898923,2.56432139 C6.08215838,1.96681299 6.6233006,1.5 7.22768753,1.5 L8.83680199,1.5 Z"
                                                                id="Combined-Shape" stroke="#FFFFFF" strokeLinejoin="round"></path>
                                                            <circle id="Oval" className="icon-bg-fill" fill="#FFFFFF" cx="12.0612746" cy="3.1" r="1"></circle>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span className="nav-text" >
                                    <p className='p1'> My Test Series</p>

                                </span>
                            </Link>

                        </li>
                        <li className="has-subnav" onClick={() => this.moeHandler("video")}>
                            <Link to='/my-videos'>
                                <span className="nav-text" >
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >

                                        <title>Videocourses</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -336.000000)">
                                                <g id="My-Videos-Copy" transform="translate(4.000000, 320.000000)">
                                                    <g id="ic-nav-videocourse" transform="translate(26.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="Group-5" transform="translate(9.000000, 11.000000)" className="icon-bg" stroke="#FFFFFF"
                                                            strokeLinecap="round" strokeLinejoin="round">
                                                            <rect id="Rectangle-6" x="0" y="0" width="29.8666667" height="21"
                                                                rx="2"></rect>
                                                            <path d="M0,26.1 L3.77020361,26.1 M7.95800617,26.1 L29.8666667,26.1"
                                                                id="Line"></path>
                                                            <circle id="Oval" cx="6" cy="26" r="2"></circle>
                                                            <path d="M12.8126958,14.5 C12.3566011,14.5 12,14.1010593 12,13.5917373 L12,7.41165254 C12,6.90042373 12.356404,6.5 12.8126958,6.5 C12.9638079,6.5 13.1162992,6.54576271 13.2542112,6.63241525 L18.2310617,9.72097458 C18.5039305,9.89131356 18.6666667,10.1832627 18.6666667,10.5002119 C18.6666667,10.820339 18.5039325,11.1105932 18.2310637,11.2811441 L13.2542112,14.3709746 C13.1162992,14.4559322 12.9638079,14.5 12.8126958,14.5 L12.8126958,14.5 Z"
                                                                id="Path"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span className="nav-text">
                                    <p className="p1">My Video Courses</p>
                                </span>
                            </Link>

                        </li>
                        <li className="has-subnav" onClick={() => this.moeHandler("live-class")}>
                            {/* <a onClick={() => this.handleUserAccountLink('/my-live-class')}> */}
                            <Link to='/my-live-class'>
                                <span className="nav-text">
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >

                                        <title>Liveclasses</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -256.000000)">
                                                <g id="My-Videos" transform="translate(0.000000, 240.000000)">
                                                    <g id="ic-nav-liveclass" transform="translate(30.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="Group" transform="translate(5.000000, 15.000000)">
                                                            <rect id="Rectangle" stroke="#FFFFFF" strokeLinecap="round" className="icon-bg"
                                                                strokeLinejoin="round" x="0" y="0" width="38" height="18" rx="2"></rect>
                                                            <text id="LIVE" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                                                                fontWeight="normal" fill="#FFFFFF" className="icon-bg-fill">
                                                                <tspan x="6.91210938" y="13">LIVE</tspan>
                                                            </text>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>

                                <span className="nav-text">
                                    <p className="p1">My Live Classes</p>
                                </span>
                            </Link>

                        </li>
                        <li className="has-subnav" onClick={() => this.moeHandler("ebook")}>
                            <Link to={'/my-ebooks'}>
                                <span className="nav-text">
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >

                                        <title>Ebook</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -96.000000)">
                                                <g id="Item" transform="translate(4.000000, 80.000000)">
                                                    <g id="ic-nav-ebook" transform="translate(26.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g className="icon-bg" id="Group" transform="translate(13.750000, 11.500000)" stroke="#FFFFFF"
                                                            strokeLinecap="round" strokeLinejoin="round">
                                                            <path d="M20,12.5166601 L20,20.5 L0,20.5 L0,3.125 C-2.77555756e-16,1.39911016 1.39911016,5.94596577e-16 3.125,2.77555756e-16 L16.75,0"
                                                                id="Rectangle-6"></path>
                                                            <path d="M13.8,0 L18.8449371,0 C19.510475,0 20.05,0.539381504 20.05,1.20500475 L20.05,7.5 L16.9225878,5.49603175 L13.8,7.5 L13.8,0 Z"
                                                                id="Rectangle-7"></path>
                                                            <path d="M0,20.5 L20,20.5 L20,24.3023754 C20,24.9638052 19.4650483,25.5 18.8088036,25.5 L2.49257535,25.5 C1.115964,25.5 0,24.3903379 0,23 L0,20.5 Z"
                                                                id="Rectangle-5-Copy"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>

                                <span className="nav-text">
                                    <p className="p1">My E-Books</p>
                                </span>
                            </Link>

                        </li>
                        <li className="has-subnav">

                            <Link to='/my-orders'>
                                <span className="nav-text">
                                    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    >

                                        <title>Orders</title>
                                        <desc>Created with Sketch.</desc>
                                        <g id="Live-Classes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Side-Panel-Copy" transform="translate(-30.000000, -416.000000)">
                                                <g id="My-Orders" transform="translate(4.000000, 400.000000)">
                                                    <g id="ic-nav-orders" transform="translate(26.000000, 16.000000)">
                                                        <rect x="0" y="0" width="48" height="48"></rect>
                                                        <g id="Group-58" transform="translate(9.000000, 6.000000)" stroke="#FFFFFF" className="icon-bg"
                                                            strokeLinecap="round" strokeLinejoin="round">
                                                            <polygon id="Path-4" points="14.8536309 35.7142857 14.8536309 17.8571429 29.6358817 9.28571429 29.6374534 27.1428571"></polygon>
                                                            <polygon id="Path-4-Copy" transform="translate(7.464425, 22.500000) scale(-1, 1) translate(-7.464425, -22.500000) "
                                                                points="0.0738783943 35.7142857 0.0711495536 17.8571429 14.8577009 9.28571429 14.8577009 27.1428571"></polygon>
                                                            <polygon id="Path-5" points="0.0711495536 9.28571429 14.8577009 0.666199779 29.6358817 9.28571429 14.8577009 17.8571429"></polygon>
                                                            <polyline id="Path-5-Copy" points="7.85770089 4.66619978 22.6358817 13.2857143 22.6358817 21"></polyline>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>

                                <span className="nav-text">
                                    <p className="p1">My Orders</p>
                                </span>
                            </Link>

                        </li>
                    {isADDA && (
                        <li className="has-subnav">
                            <Link to="/wishlist" onClick={
                                () =>   fireWishListMoengageEvent('wishlist_viewed', {
                                    source_screen:'Wish listing',
                                    products_wishlisted_count: this.props?.wishlistPackageData?.likeCount || 0
                                  })
                            }>
                            <span className="nav-text">
                                <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    marginLeft:'9px',
                                    marginTop:'9px',
                                }}
                                >
                                <path
                                    d="M16.08 27.1599C16.08 27.1599 16.02 27.1599 16 27.1599C15.85 27.1899 15.68 27.1399 15.56 27.0199L3.33996 14.7899C-3.91006e-05 11.4499 -3.91006e-05 6.01992 3.33996 2.67992C6.67996 -0.660078 12.12 -0.660078 15.46 2.67992L16 3.21992L16.54 2.67992C18.16 1.05992 20.31 0.169922 22.6 0.169922C24.89 0.169922 27.04 1.05992 28.66 2.67992C30.28 4.29992 31.17 6.44992 31.17 8.73992C31.17 11.0299 30.28 13.1799 28.66 14.7999L16.44 27.0199C16.34 27.1199 16.21 27.1699 16.09 27.1699L16.08 27.1599ZM4.04996 3.37992C1.09996 6.32992 1.09996 11.1299 4.04996 14.0799L16 26.0299L27.95 14.0799C29.38 12.6499 30.17 10.7499 30.17 8.72992C30.17 6.70992 29.38 4.80992 27.95 3.37992C26.52 1.94992 24.62 1.15992 22.6 1.15992C20.58 1.15992 18.68 1.94992 17.25 3.37992L16.71 3.91992C16.33 4.29992 15.67 4.29992 15.3 3.91992L14.76 3.37992C11.81 0.429922 7.00996 0.429922 4.05996 3.37992H4.04996Z"
                                    fill="white"
                                />
                                </svg>
                            </span>

                            <span className="nav-text">
                                <p className="p1">My Wishlist</p>
                            </span>
                            </Link>
                        </li>
                    )}
                    </ul>
                </nav>
            </div>
        )
    }

}
const mapStateToProps = (state) => ({
    userDetails: state.header.userInfo || {},
    wishlistPackageData: state.wishlist?.wishlistPackageData,
});
export default connect(mapStateToProps, null)(MyaccountNavbar);
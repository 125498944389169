import React from 'react';
import FilterList from '../FilterList';
import FilterTopicHeading from '../FilterTopicHeading';

const ProductTypeListing = (props) => {
    const facetsList = (props.allCategoryData && props.allCategoryData.facetsList) || props.facetsList;
    const topicHeading = `${props.heading}` || '';
    return <>
        <FilterTopicHeading
            openDetailFilters={props.openDetailFilters}
            handleApply = {props.handleApply}
            tagArr={props.tagArr}
            showShortFiltersForDetail={props.showShortFiltersForDetail}
            topicHeading={topicHeading}
            sortBy={props.sortBy}
            count={(props.packageCount)}
            handleSortBy={props.handleSortBy}
            sortByValue={props.sortByValue}
            facetsList = {facetsList}
            getUtmFromStore={props.getUtmFromStore}
            title={props.title}
            useTitle={props.useTitle}
            hideSortByFilter={props?.hideSortByFilter}
        />
        <div className={'all-category-list'}>
            <FilterList
                filterList={props.allCategoryData && props.allCategoryData.packageEsEntity || props.allCategoryData}
                handleDetailCardClick={props.handleDetailCardClick}
                showLoader={props.showLoader}
            />
        </div>
    </>;
};
export default ProductTypeListing;



import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { getDeviceType } from '../../data/utils/helpers';
import { DEVICE_IDENTIFIERS } from '../../../constants/textConstants';
import { openTestPortal } from '../enrolledCourses/TestSeries/TakeTest/util';


const FixedMockCard = ({
    data,
    handlePopUp,
    formatMilliseconds,
    userRegistered,showNetworkPopup,
    currentTime,
    testState,
    userStartTime,
    handleAppRedirectPopup
}) => {

    const history = useHistory();
    const [quizState, setQuizState] = useState(-1);
    const [localRemainingTime, setLocalRemainingTime] = useState(null);

    const localRemainingTimeAtSomePointTime = localStorage.getItem(`${data.mappingId}RemainingTime`);
    const pointOfTime = localStorage.getItem(`${data.mappingId}whichPointOfTime`);
    const isTimeLimitSectionWise = data?.sections?.[0]?.secTime > 0;

    useEffect(() => {
        if(isTimeLimitSectionWise && pointOfTime){
            const timePassed = (currentTime - pointOfTime)/1000;
            const remainingTime = localRemainingTimeAtSomePointTime - timePassed;
            if(remainingTime < 0){
                setLocalRemainingTime(0);
            }else{
                setLocalRemainingTime(Math.ceil(remainingTime)*1000);
            }
        }
    },[currentTime])

    // quiz state map:
    // 1: register
    // 2: registered 
    // 3: attemptNow 
    // 4: resume  
    // 5: attempted
    // 6: resultAwaited 
    // 7: resultPublished

    useEffect(() => {
        if (currentTime < data.fixedMockTestStartTime && !userRegistered){
            setQuizState(1);
        }else if (currentTime < data.fixedMockTestStartTime && userRegistered){
            setQuizState(2);
        }else if (currentTime < data.fixedMockSubmitTime && getTestState() == "NOT_STARTED" && !localStorage.getItem(`${data.mappingId}Submitted`)){
            setQuizState(3);
        }else if (currentTime < data.fixedMockSubmitTime && testState == "INCOMPLETE" && getRemainingTime() != 0 && !localStorage.getItem(`${data.mappingId}Submitted`)) {
            setQuizState(4);
        }else if (currentTime < data.fixedMockSubmitTime && (testState == "COMPLETED" || getRemainingTime() == 0 || localStorage.getItem(`${data.mappingId}Submitted`))) {
            setQuizState(5);
        }else if (currentTime < data.fixedMockResultTime){
            setQuizState(6);
        }else if(currentTime >= data.fixedMockResultTime){
            setQuizState(7);
        }else{
            setQuizState(-1);
        }
    },[currentTime])

    const getFormatedDate = (date) => {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let time = d.toLocaleTimeString('en');
        let noSec;
        const amPm = time.split(' ')[1];
        const hour = time.split(':')[0];
        if (parseInt(hour) <= 9){
            noSec = time.substring(0,4);
        }else{
            noSec = time.substring(0,5);
        }
        let dateString = `${noSec} ${amPm}, ${da} ${mo}`
        return dateString;
    }

    const getRemainingTime = () => {
        if(isTimeLimitSectionWise && localRemainingTime !== null){
            return localRemainingTime;
        }
        const timeElapsed = currentTime - userStartTime;
        const remainingTime = Math.min(data.timeLimit*1000 - timeElapsed, data.fixedMockSubmitTime - currentTime);
        if (remainingTime < 0){
            return 0;
        }
        return remainingTime;
    }

    const getTestState = () => {
        if (!testState && currentTime > data.fixedMockTestStartTime){
            return "NOT_STARTED";
        }
        if (testState === "INCOMPLETE" && getRemainingTime() === 0){
            return "COMPLETED";
        }
        return testState;
    }

    let packageId = data.fixedMockPkg;




    const handleOpenQuizPortal = (status = "") => {
        if(navigator.onLine){
        openTestPortal(data?.mappingId,packageId,data?.title,status,null,data?.exl)
        }else{
            showNetworkPopup(true);
        }
    }

    const handleCardClick = () => {
        let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
        if (quizState == 1){
            history.push(`/fpm/${data.mappingId}`);
        }else if (quizState == 2){
            //do nothing
            history.push({
                pathname : `/fpm/${data.mappingId}`,
                state : { registrationStatus : "Registered" }
            });   
        }else if (quizState == 3){
            history.push(`/fpm/${data.mappingId}`);
        }else if (quizState == 4){
            if(getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER && !iOS) handleAppRedirectPopup(data.mappingId);
            else handleOpenQuizPortal('INCOMPLETE');
        }else if (quizState == 5 || quizState == 6){
            handlePopUp(false, true, {title: data.title, resultTime: data.fixedMockResultTime, testState: getTestState()});
        }else if(quizState == 7){
            if(getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER && !iOS) handleAppRedirectPopup(data.mappingId);
            else{
                let url = `/my-test-analysis/${data.title}/${packageId}/${data.mappingId}?&fixedMockResult=true`;
                localStorage.setItem("fixedMockResult", "yes");
                window.open(url);
            }
        }
    }


    

    let bottomRightBox =

        quizState == 1 ?
            <div className="mockbox-resigter-btn cursor-pointer" onClick = {handleCardClick}>
                <span className='fixedMockButton'>REGISTER</span>
            </div> :

        quizState == 2 ?
            <div className="mockbox-resigter-btn cursor-pointer" onClick = {handleCardClick}>
                <span className='fixedMockButton'>REGISTERED</span>
            </div> :

        quizState == 3 ?
            <div className="mock-attempt-now cursor-pointer" onClick = {handleCardClick}>
                <span className='fixedMockButton'>Attempt Now</span>
            </div> :

        quizState == 4 ?
            <div className="mock-attempt-now cursor-pointer" onClick = {handleCardClick}>
                <a className='fixedMockButton'>Resume</a>
            </div>
             :
        quizState == 5 || quizState == 6?

            <div className="mockbox-result-await">
                <div className="result-timer"><img src="../images/ic_timer.svg" alt='mock-timer'/> {formatMilliseconds(data.fixedMockResultTime - currentTime)}</div>
                <div className="mockbox-resigter-btn cursor-pointer" onClick = {handleCardClick}><span className='fixedMockButton'>RESULT AWAITED</span></div>
            </div> :

        quizState == 7?

            <div className="mock-attempt-now c-g cursor-pointer fixedMockButton" onClick = {handleCardClick}>View Result</div>:
        <></>

    let mockBoxTime = 
        quizState == 1 || quizState == 2?
        <ul>
            <li>STARTS AT: <span>{getFormatedDate(data.fixedMockTestStartTime)}</span></li>
        </ul> :

        quizState == 3 || quizState == 5 || quizState == 6 || quizState == 7?
        <ul><li>{data.noOfQuestions} Ques</li>
            <li>{data.timeLimit/60} Min</li>
        </ul> :

        quizState == 4 ?
        <ul >
            {(isTimeLimitSectionWise && !localRemainingTime)? "":<li className = "d-flex"><div className = "m-auto">Time Remaining</div> <div  className="result-timer ml-5"><img src="../images/ic_timer.svg" alt='result-timer'/> {isTimeLimitSectionWise ? formatMilliseconds(localRemainingTime): formatMilliseconds(getRemainingTime())}</div></li>}
        </ul>
            :
        // quizState == 5?
        // <ul>
        //     <li className = "d-flex"><div className = "m-auto">RESULT AWAITED</div> <div  className="result-timer ml-5"><img src="../images/ic_timer.svg" /> {formatMilliseconds(data.fixedMockResultTime - currentTime)}</div></li>
        // </ul>:
        <></>
  
  return ( data?
      <div className="mock-list-box">
          <div className="mock-box-img"><img src = {data.thumbnail} alt='mock-time-icon'/></div>
          <div className="mock-box-content">
              <div className="mock-box-title">{data.title}</div>
              <div className="mock-box-time">
                    {mockBoxTime}
              </div>
              <div className="mock-box-bottom">
                  <div className="mockbox-bottom-left">
                      <div className="mockbox-cash-prize"><div className="cash-icon"><img src="../images/trophyicon.png" alt='trophy-icon'/></div>{data.fixedMockPrizeType}</div>
                      <div className="mockbox-examname">{data.examid}</div>
                  </div>
                  <div className="mockbox-bottom-right">
                        {bottomRightBox}
                  </div>
              </div>

          </div>
      </div>:
      <></>
  )
}

export default FixedMockCard

import React from "react";
import PropTypes from "prop-types";
const QuestionDistribution = ({
  isSectional = false,
  correctCount = 0,
  wrongCount = 0,
  unattemptedCount = 0,
  totalCount = 0,
  unevaluatedCount = 0,
  reAttemptCb = () => null,
}) => {
  return (
    <section className="boxWhiteFull Qdis">
      <div className="qdisheading">
        <label>Question Distribution</label>
        {isSectional && <div className="qdistag">SECTIONAL</div>}
      </div>

      <div className="totalAnswerswrap">
        <div className="totalansbar">
          <div
            className="greenbar"
            style={{ width: `${(correctCount / totalCount) * 100}%` }}
          ></div>
          <div
            className="redbar"
            style={{ width: `${(wrongCount / totalCount) * 100}%` }}
          ></div>
          <div
            className="yellowbar"
            style={{ width: `${(unevaluatedCount / totalCount) * 100}%` }}
          ></div>
          <div
            className="graybar"
            style={{ width: `${(unattemptedCount / totalCount) * 100}%` }}
          ></div>
        </div>

        <div className="totalAnsProgress">
          <div className="totalcorrect">
            <div className="totalcorrectCirle">{correctCount}</div> Correct
          </div>

          <div className="totalWrong">
            <div className="totalWrongCirle">{wrongCount}</div> Wrong
          </div>

          {unevaluatedCount ? (
            <div className="totalUnevaluated">
              <div className="totalUnevaluatedCirle">{unevaluatedCount}</div>{" "}
              Not evaluated
            </div>
          ) : (
            <></>
          )}

          <div className="totalUnattemped">
            <div className="totalUnattempedCircle">{unattemptedCount}</div>{" "}
            Unattempted
          </div>
        </div>
        <div className="totalAnsbtns">
          <button onClick={reAttemptCb} className="reAttempedbtn">
            <span>Re-Attempt</span>
          </button>
        </div>
      </div>
    </section>
  );
};

QuestionDistribution.propTypes = {
  isSectional: PropTypes.bool,
  correctCount: PropTypes.number,
  wrongCount: PropTypes.number,
  unattemptedCount: PropTypes.number,
  totalCount: PropTypes.number,
  reAttemptCb: PropTypes.func.isRequired,
};
export default QuestionDistribution;

import React from "react";
import { FallBackImage } from "../../../constants/appConfig";

const ExamLabelCard = ({
  src = FallBackImage,
  examName = "Up Constable",
  active = false,
  onClick,
}) => {
  return (
    <li className={`${active ? "activeExam" : ""}`} onClick={onClick}>
      <div className="img-exam-option">
        <img
          src={src}
          onError={(event) => {
            event.target.src = FallBackImage;
            event.onerror = null;
          }}
          loading='lazy'
        />
      </div>
      <div className="exam-name-selection">{examName}</div>
    </li>
  );
};

export default ExamLabelCard;

import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pageName, pageSizeForDetailListing, searchUrlEndPoint } from '../../constants/appConfig';
import { withCookies } from 'react-cookie';
import SearchBlock from '../components/filter/searchfilters';
import BreadcrumbSchema from '../components/library/BreadcrumbSchema';
import CustomHelmet from '../components/library/CustomHelmet';
import CustomLoader from '../components/library/CustomLoader';
import BottomScrollListener from '../components/library/BottomScrollListener';
import ProductTypeListing from '../components/filter/listings/ProductTypeListing';
import FilterTags from '../components/filter/FilterTags';
import CustomSkeleton from '../components/library/CustomSkeleton';
import NewMobileFilter from '../components/filter/NewMobileFilter';

import NoResultsFound from '../components/library/NoResultsFound';
import { productKeys, productNames, radioProductFacetMap, viewAllKeys } from '../../constants/appConstants';
import '../data/ducks/searchDetailListing/reducers';
import { clearSearchDetailData, getPaginatedDataForSearchDetail } from '../data/ducks/searchDetailListing/actions';
import {
  capitalizedString,
  createStorefrontPdpUrl,
  getMoengageMetadata,
  isEmpty,
  isSet,
  removeHyphensAndInsertSpaces,
  validateProductName,
  makeUrl,
  getDeviceType,
  getPdpUrl,
  getUrlPathObject,
  removeElementsFromLastInArray,
  trackMoengageEvent,
  createOptimizedImgUrl,
  isADDA,
  getMoengagePayloadFromFacets,
  fireMoengageEvent,
  getTagIdParams
} from '../data/utils/helpers';
import Faq from './SEO/faq';
import DfpAdPdp from '../components/library/DfpAdPdp';
import NewSortByMobileFilter from '../components/filter/NewSortByMobileFilter';
// import { checkProperties } from '../components/utils';

let pageN = 0;

let filterObj = {};
let requiredProductName = '';
let count = 0;
const validTags = ['categoryFacets', 'searchTerm', 'examTypesFacets', 'coursesFacets', 'langFacets', 'subjectFacets', 'tagsFacets'];

class SearchDetailListing extends PureComponent {
  constructor(props) {
    super(props);
    const tags = this.createTagsFromUrl();
    const { cookies } = props;
    this.state = {
      loading: true,
      listingLoader: false,
      showFilterModal: false,
      queryParam: props.location.search.substring(1) || '',
      tagArr: tags.tagArr,
      sortBy: 'RELEVANCE',
      dataLoader: false,
      facetObj: tags.facetObj,
      userEnteredSearchTerm: props.location.search.substring(1).split('&').filter(e => e.indexOf('searchTerm') > -1).join().split('=')[1],
      showSortModal: false,
      selectedSortCategory: 'Relevance',
      selectedFilterCategory: 'No Filter',
      showFilterAppliedDot: false,
      showSortByAppliedDot: true,
      facetsList: this.props.searchDetailListing?.data?.primaryFacets
    };
  }

  static propTypes = {
    getPaginatedDataForSearchDetail: PropTypes.func,
    searchDetailListing: PropTypes.object,
    clearSearchDetailData: PropTypes.func
  };

  componentDidMount() {

    const { location } = this.props;

    const { search } = location;
    const examName = search.split('/')[1];

    let payload = { source: "web", brand: pageName[1] };
    const courseCategory = ((search || "").split('=')[1] || "").split('&')[0]
    this.getSecondaryFacets();

    if (this.props.searchDetailListing?.data?.packageEsEntity?.length > 0) {
      payload.action = 'results_found_hit_query';
      payload.term = this.state.userEnteredSearchTerm;
      payload.no_of_results_found = this.props.searchDetailListing.data.count || 0;
      payload.getUtmFromStore = this.props.utmParams || ""
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("search", getMoengageMetadata(payload));
    }
    else {
      payload.action = 'results_not_found_hit_query';
      payload.term = this.state.userEnteredSearchTerm;
      payload.getUtmFromStore = this.props.utmParams || ""
      if (typeof (Moengage) !== 'undefined') Moengage.track_event("search", getMoengageMetadata(payload));
    }

  }

  componentWillUnmount() {
    count = 0;
    const googletag = window.googletag;
    googletag.destroySlots();
  }
  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
        return true;
    }
    return false;
  }

  createTagsFromUrl = () => {
    const qParams = this.props.location.search.substring(1);
    let tempTagArr = [];
    let newFacetObj = {};
    if (qParams.length) {
      const splitArr = qParams.split('&');
      splitArr.map(item => {
        let newArr = item.split('=');
        if (validTags.includes(newArr[0])) {
          let finalArr = (newArr[1] || "").split(',');
          finalArr.map(it => {
            if (!tempTagArr.includes(decodeURI(it))) {
              tempTagArr.push(decodeURI(it));
            }
          });

          newFacetObj[newArr[0]] = finalArr;
        }

      });
    }
    return {
      tagArr: tempTagArr.filter((item) => item),
      facetObj: newFacetObj
    };
  };

  getSecondaryFacets = () => {
    const qParams = this.props.location.search.substring(1);
    const paramsObj = new URLSearchParams(qParams);
    const courses = paramsObj.get("coursesFacets");
    const categories = paramsObj.get("categoryFacets");
    const searchTerm = encodeURI(paramsObj.get("searchTerm"));
    let extraParams = getTagIdParams( courses, this.props.globalExamList );
    fetch(`${searchUrlEndPoint}/packages/secondaryFacets?${courses ? `coursesFacets=${courses}${extraParams}` : ""}${categories ? "&categoryFacets=" + categories : ""}${searchTerm ? "&searchTerm=" + searchTerm : ""}`).then(res => res.json()).then(res => {
      this.setState((prevState) => {
        const newFacetsList = { ...prevState.facetsList, ...res.data };
        const newState = { ...prevState, facetsList: newFacetsList };
        return newState;
      })
    })
  }

  handleFilterButton = () => {
    this.setState({
      showFilterModal: true,
      showSortModal: false
    });
    fireMoengageEvent("filter_button_clicked", {}, "filter");
  };

  handleSortButton = () => {
    this.setState({
      showSortModal: !this.state.showSortModal
    });
  };

  hideFilterOnMobile = () => {
    this.setState({
      showFilterModal: false
    });
  };

  handleFilterApply = (facetObj) => {
    this.setState({
      dataLoader: true,
      showFilterAppliedDot: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length),
      selectedFilterCategory: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length) ? 'Filter Applied' : 'No Filter',
    });
    let urlString = '&';
    const facetObjectKeys = Object.keys(facetObj);
    const facetKeysArrLen = facetObjectKeys.length;
    for (let i = 0; i < facetKeysArrLen; i++) {
      let facetValue = facetObj[facetObjectKeys[i]].join(',');
      urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
    }
    const encodedQueryString = encodeURI(urlString.slice(0, -1));
    fireMoengageEvent("filter_applied", getMoengagePayloadFromFacets(facetObj), "filter");
    this.props.history.push(getUrlPathObject('/search', `?${encodedQueryString.substring(1)}`));

    this.setState({
      queryParam: encodedQueryString
    }, () => this.getPaginatedDataForSearchDetail(0, true, this.state.sortBy));

  };

  resetFilter = () => {
    filterObj = {};
    this.clearAllFilters();
    this.setState({
      showFilterModal: false,
      currentActiveFilter: '',
      showFilterAppliedDot: false,
      selectedFilterCategory: 'No Filter',
    });
  };

  changeRoute = (route) => {
    this.setState({
      dataLoader: true,
      facetObj: {},
      tagArr: []
    });
    this.props.history.push(getUrlPathObject(`/${route}`));
    this.props.clearSearchDetailData();
    this.props.getPaginatedDataForSearchDetail(
      {
        pageNo: 0,
        type: 'init',
        categoryFacet: viewAllKeys[route.toLowerCase()],
        queryParam: '',
        sortBy: 'RELEVANCE'
      }
    ).then(() => {
      this.setState({
        dataLoader: false
      });
    });
  };

  getPaginatedDataForSearchDetail = (pageNo = 0, forFilters, sortBy) => {
    this.getSecondaryFacets();
    if (isEmpty(this.props.searchDetailListing) || forFilters) {
      const detailObj = {
        pageNo,
        type: 'init',
        // categoryFacet: viewAllKeys[requiredProductName],
        queryParam: `${this.state.queryParam}` || `${this.props.location.search.substring(1)}`,
        sortBy
      };
      // dispatch the action to get the detailed data for the listing with pagination.
      this.props.getPaginatedDataForSearchDetail(detailObj).then(() => {
        this.setState({
          loading: false,
          showFilterModal: false,
          showProductLoader: false,
          dataLoader: false,
          tagArr: this.createTagsFromUrl().tagArr
        });
        pageN = 0;
      });
    }
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  getNewParamsForAllCategory = (facetKey, value) => {
    typeof window !== 'undefined' && window.scrollTo(0, 0);

    const { facetObj, tagArr } = this.state;
    this.setState({
      dataLoader: true
    });
    if (facetKey === 'allExamtype') {
      const selection = value.toLowerCase();
      this.setState({
        tagArr: [],
        facetObj: {}
      });
      this.props.history.push(getUrlPathObject(`/${selection}-study-materials`));
      this.props.getPaginatedDataForSearchDetail(selection).then(() => {
        this.setState({
          dataLoader: false
        });
      });
    } else {
      let newFacetObj = Object.assign({}, facetObj);
      let newTagArr = tagArr;

      if (!isSet(newFacetObj[facetKey])) {
        newFacetObj[facetKey] = [];
      }
      if (newFacetObj[facetKey].includes(value)) {
        newFacetObj[facetKey] = newFacetObj[facetKey].filter(e => e.toLowerCase() !== value.toLowerCase());
        newTagArr = newTagArr.filter(e => e.toLowerCase() !== value.toLowerCase());
      } else {
        newFacetObj[facetKey].push(value);
        newTagArr.push(value);
      }
      let strNew = '';
      let p;
      for (p in newFacetObj) {
        if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
          strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
        }
      }
      strNew = strNew.substring(-1);
      this.setState({
        queryParam: strNew,
        tagArr: newTagArr,
        facetObj: newFacetObj
      }, () => {
        this.getPaginatedDataForSearchDetail(0, true, this.state.sortBy);
      });
      this.props.history.push(getUrlPathObject('/search', `?${strNew.slice(0, -1)}`));
    }
  };
  clearAllFilters = () => {
    const { history } = this.props;
    history.push(getUrlPathObject(
      `/search`
    ),);
    this.setState({
      dataLoader: true,
      queryParam: '',
      tagArr: [],
      facetObj: {},
      showFilterAppliedDot: false,
      selectedFilterCategory: 'No Filter',
    }, () => {
      this.getPaginatedDataForSearchDetail(0, true, this.state.sortBy);
    });
    fireMoengageEvent("filter_reset", {}, "filter");
  };

  handleSortBy = (sortBy) => {
    // sortBy = ASC or RELEVANCE
    this.setState({
      sortBy,
      loading: true,
      showProductLoader: true
    }, () => {
      this.getPaginatedDataForSearchDetail(0, true, sortBy);
    });
  };

  setSelectedSortCategoryChange = (category) => {
    this.setState({
      selectedSortCategory: category
    })
  }

  handleDetailCardClick = (obj) => {
    const category = productKeys[obj.suggestionCat] || '';
    const id = obj.id || '';
    const title = obj.title || '';
    const slugurl = obj.slugURL || '';
    const storeUrl = getPdpUrl(category, id, title, slugurl)
    const getUtmFromStore = this.props.utmParams || ""

    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'product_click',
      getMoengageMetadata({
        source: 'web',
        'id': id,
        'name': title,
        'category': category,
        'list': category,
        'brand': pageName[1],
        'variant': '-',
        'price': obj['price'],
        'quantity': '1',
        'packageUrl': storeUrl,
        'packageActualPrice': obj.maxPrice,
        'packageDiscountedPrice': obj['price'],
        getUtmFromStore: getUtmFromStore,
        source_screen: 'Search listing',
        productType: category
      })

    );
    // window.open(storeUrl);
    // this.props.history.push(getUrlPathObject(storeUrl))
  };

  getPaginatedDataOnScroll = () => {
    const totalCount = this.props.searchDetailListing.data.count || 0;
    ;
    if (totalCount <= (pageN + 1) * pageSizeForDetailListing) {
      return;
    }
    this.setState({
      listingLoader: true
    });
    pageN++;
    const detailObj = {
      pageNo: pageN,
      type: 'scroll',
      // categoryFacet: viewAllKeys[requiredProductName],
      queryParam: `&${this.state.queryParam}` || `&${this.props.location.search.substring(1)}`,
      sortBy: this.state.sortBy
    };
    this.props.getPaginatedDataForSearchDetail(detailObj).then(() => {
      this.setState({
        listingLoader: false
      });
    });
  };

  handleRadio = (product) => {
    requiredProductName = productKeys[product?.toLowerCase()];
    this.props.history.push(getUrlPathObject(`/${requiredProductName}`));

    this.setState({
      dataLoader: true,
      queryParam: ''
    }, () => this.getPaginatedDataForSearchDetail(0, true, this.state.sortBy));
    this.clearAllFilters();

  };

  removeTag = (item) => {
    const { tagArr, facetObj } = this.state;
    let newFacetObj = {};
    const { location } = this.props;
    const tempTagArr = [...tagArr];
    this.setState({
      dataLoader: true
    });

    if (tempTagArr.indexOf(item) > -1) {
      tempTagArr.splice(tempTagArr.indexOf(item), 1);
      const facetKeys = Object.keys(facetObj);
      facetKeys.map(e => {
        newFacetObj[e] = facetObj[e].filter(f => {
          return f.toLowerCase() !== item.toLowerCase();
        });
      });
    }
    let arr3 = [];
    let finalString = '';

    let splitArr = location.search.substring(1).split('&');
    splitArr.map(str => {
      let newAr = str.split('=');
      const cat = newAr.shift();
      let finalAr = (newAr[0] || "").split(',');

      finalAr = finalAr.filter(val => {
        let decodedUri = decodeURI(val).toLowerCase();
        return decodedUri !== item.toLowerCase();
      });

      const string2 = finalAr.join(',');

      if (string2.length) {
        arr3.push(cat + '=' + string2);
      }
    });
    if (arr3.length > 1) {
      finalString = arr3.join('&');
    } else {
      if (isSet(arr3[0])) {
        finalString = arr3[0];
      }
    }

    this.setState({
      queryParam: finalString,
      tagArr: tempTagArr,
      facetObj: newFacetObj,
      facetObj: newFacetObj,
      showFilterAppliedDot: tempTagArr.length && true,
      selectedFilterCategory: tempTagArr.length ? 'Filter Applied' : 'No Filter',

    }, () => {
      this.getPaginatedDataForSearchDetail(0, true, this.state.sortBy);
    });
    this.props.history.push(getUrlPathObject(`/search`, `?${finalString}`));
  };




  datalayerHanler = (searchValue) => {


    const { location } = this.props;

    const { search } = location;
    const examName = search.split('/')[1];

    let payload = { source: "web", brand: pageName[1] };
    const courseCategory = ((search || "").split('=')[1] || "").split('&')[0]
    let exammCategory = searchValue.data && searchValue.data.packageEsEntity && searchValue.data.packageEsEntity[0] && searchValue.data.packageEsEntity[0].courses;

    if (searchValue && typeof (window) != "undefined") {



      if (this.props.cookies.get('cp_token') && typeof (window) != "undefined") {
        window.dataLayer.push({
          name: this.props.cookies.get('cp_user_name') || "guest",
          email: this.props.cookies.get('cp_user_email') || "",
          isMobile: getDeviceType() == 'm' ? true : false,
          loginType: 'email',
          isLoggedIn: true,
          pageName: 'Listing',
          examCategoryName: "",
          category: this.state.tagArr || "",

        })
      }
      else if (!this.props.cookies.get('cp_token') && typeof (window) != "undefined") {
        window.dataLayer.push({
          name: "guest",
          email: "",
          isMobile: getDeviceType() == 'm' ? true : false,
          loginType: '',
          isLoggedIn: false,
          pageName: 'Listing',
          examCategoryName: "",
          category: this.state.tagArr || "",
        })
      }
      if (count == 1) {
        let body = {};
        body.user_email = this.props.cookies.get("cp_user_email") || "";
        body.user_name = this.props.cookies.get("cp_user_name") || "";
        body.source_screen = "";
        body.examCategory = exammCategory;
        body.getUtmFromStore = this.props.utmParams;
        trackMoengageEvent("list_page_view", body);

      }
    }
  }

  render() {
    const { tagArr, dataLoader, showFilterModal, listingLoader, queryParam, facetObj, userEnteredSearchTerm, showSortModal, selectedFilterCategory, selectedSortCategory, showFilterAppliedDot, showSortByAppliedDot, } = this.state;
    const { searchDetailListing } = this.props;

    if (searchDetailListing && searchDetailListing.data) {
      count++;
      this.datalayerHanler(searchDetailListing)

    }
    const { location } = this.props;
    const { pathname } = location;

    const courseCategory = pathname.substring(1).split('-');
    const examName = removeElementsFromLastInArray(courseCategory, 2).join('-');
    const pageHeading = removeHyphensAndInsertSpaces(examName.toUpperCase());


    return (
      <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
        {!this.props.isPaytmMini && <div className={"dfd-container-search"}>
          <DfpAdPdp />
        </div>}
        <div className={'listing-data-container search-detail-listing'} style={{ 'marginTop': '30px' }}>

          <CustomHelmet
            title={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].metaTitle ? this.props.seo_data[pathname].metaTitle : `${pageHeading} Books, Live Classes, E-Books, Mock Test Series Online - ${pageName[1]}`}
            description={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].metaDescription ? this.props.seo_data[pathname].description : `Looking for ${pageHeading} exam preparation online? Avail the best study material for ${pageHeading} exam online, Books, Live Classes, faculty interaction, E-Books, Mock Test Series.`}
            url={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].canonical || this.props.req_url}
            keywords={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].keywords}
            noFollow={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].noFollow}
            noIndex={this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].noIndex}
          />
          <div className={'filter-parent-container col m3 fcwidth'}>
            <SearchBlock
              facetsList={this?.state?.facetsList}
              showSearch={false}
              // filterHeading={capitalizedString(examName)}
              // currentItem={examName}
              type={1}
              getNewParamsforAllCategory={this.getNewParamsForAllCategory}
              selectedTags={tagArr}
              getUtmFromStore={this.props.utmParams}
            />
          </div>

          <div className={'col m9 fccontwidth'}>
            {
              dataLoader ? (
                <>
                  <CustomSkeleton />
                </>
              ) : (
                <>
                  {
                    tagArr.length > 0 &&
                    <div className={'tag-container'}>
                      <FilterTags tagArr={tagArr} removeTag={this.removeTag} />
                      <div className={'clear-all'}
                        onClick={this.clearAllFilters}>Clear All
                      </div>
                    </div>
                  }
                  {
                    !searchDetailListing?.data?.packageEsEntity?.length ? (
                      <NoResultsFound
                        message={`Sorry, No Matching Result Found ${decodeURIComponent(userEnteredSearchTerm) !== "undefined" ? "for " + decodeURIComponent(userEnteredSearchTerm) : ""}`}
                        handleOnClick={this.clearAllFilters} />) : (
                      <>
                        <BottomScrollListener
                          offset={600}
                          onBottom={() => this.getPaginatedDataOnScroll()}
                        >
                          <ProductTypeListing
                            allCategoryData={searchDetailListing.data}
                            packageCount={searchDetailListing.data.count}
                            handleDetailCardClick={this.handleDetailCardClick}
                            heading={'Search Results'}
                            sortBy={true}
                            handleSortBy={this.handleSortBy}
                            showLoader={this.state.showProductLoader}
                            sortByValue={this.state.sortBy}
                            getUtmFromStore={this.props.utmParams}
                            hideSortByFilter={true}
                          />
                        </BottomScrollListener>
                        {
                          listingLoader &&
                          <div style={{ 'margin': '20px' }}>
                            <CustomLoader buttonSpinner={true} />
                          </div>
                        }
                      </>
                    )
                  }
                  {searchDetailListing?.data?.packageEsEntity?.length ? <div className={'filter-sort-wrapper'}>
                    <div className={'only-mobile sort-btn'}
                      onClick={this.handleSortButton}>
                      <div className={'sort-btn-wrapper'}>
                        <span className='filter-sort-btn-icon'>
                          <img src={'/images/sort-bottom-icon.svg'} alt="sort" title='sort' />
                          <span className={'filter-name'}>Sort By</span>
                        </span>
                        <span className='filter-sort-btn-label'>
                          <span className='filter-selected-category-wrapper'>
                            {showSortByAppliedDot ? <span className={'filter-applied-dot'}></span> : ""}
                            <span className='selected-filter-category'>{selectedSortCategory}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className={'only-mobile filter-btn'}
                      onClick={this.handleFilterButton}>
                      <div className={'filter-btn-wrapper'}>
                        <span className='filter-sort-btn-icon'>
                          <img src={'/images/filter-bottom-icon-new.svg'} alt="filter" title='filter' />
                          <span className={'filter-name'}>Filters</span>
                        </span>
                        <span className='filter-sort-btn-label'>
                          <span className='filter-selected-category-wrapper'>
                            {showFilterAppliedDot ? <span className={'filter-applied-dot'}></span> : ""}
                            <span className='selected-filter-category'>{selectedFilterCategory}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div> : ""}
                  {showFilterModal && 
                    <NewMobileFilter
                      visible={showFilterModal}
                      onCancel={this.hideFilterOnMobile}
                      // onOk={this.onOk}
                      facetsList={this.state.facetsList}
                      type={1}
                      handleApply={this.handleFilterApply}
                      resetFilter={this.resetFilter}
                      selectedTags={tagArr}
                      facetObj={facetObj}
                      handleRadio={this.handleRadio}
                    // productCategory={radioProductFacetMap[requiredProductName]}
                    />
                  }
                  {showSortModal &&
                    <NewSortByMobileFilter
                      handleSortBy={this.handleSortBy}
                      handleSortButton={this.handleSortButton}
                      setSelectedSortCategory={this.setSelectedSortCategoryChange}
                      selectedSortCategory={this.state.selectedSortCategory}
                    />
                  }
                </>
              )}

          </div>




        </div>
        {
          this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].bottomContent &&
          <div className="bottomContentContainer">
            <div className="bottomContent"
              dangerouslySetInnerHTML={{
                __html: this.props.seoData[pathname].bottomContent
              }}>

            </div>
          </div>}

        {this.props.seo_data && this.props.seo_data[pathname] && this.props.seo_data[pathname].faqs && this.props.seo_data[pathname].faqs.length >= 1 && this.checkProperties(this.props.seo_data[pathname].faqs[0]) &&
          <div className="faqcontainersearch"> <Faq faqJson={this.props.seo_data[pathname].faqs} />

          </div>}
      </div>
    );
  }
}

SearchDetailListing.fetching = ({ dispatch, match, location }) => {
  return [
    dispatch(getPaginatedDataForSearchDetail(
      {
        pageNo: 0,
        type: 'init',
        queryParam: location.indexOf('?') > -1 ? `${location.substring(location.indexOf('?') + 1)}` : '',
        sortBy: 'RELEVANCE'
      }
    ))
  ];
};

const mapStateToProps = (state) => {
  const { searchDetailListing } = state;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  let req_url = state.req_url;
  let seo_data = state.seo_data;
  let isPaytmMini = state.isPaytmMini;
  const globalExamList = state?.header?.globalExamList;
  return {
    searchDetailListing,
    utmParams,
    isLoggedIn,
    userInfo,
    req_url, 
    seo_data, 
    isPaytmMini,
    globalExamList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaginatedDataForSearchDetail: (obj) => dispatch(getPaginatedDataForSearchDetail(obj)),
    clearSearchDetailData: () => dispatch(clearSearchDetailData())
  };
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(SearchDetailListing));
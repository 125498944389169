import React from "react";

const HelpCard = ({
  title = "",
  subTitle = "",
  btnName = "",
  CtaComp = null,
  btnInfo = "",
  onClick,
}) => {
  return (
    <div className="helpWrap">
      <div className="helpHeading">
        <span className="helpTitle">{title}</span>
        <span className="helpsubTitle">{subTitle}</span>
      </div>
      <div className="bottomBtnHelp">
        {CtaComp ? (
          CtaComp
        ) : (
          <button className="helpBtn" onClick={onClick}>
            {btnName}
          </button>
        )}
        <span className="helpBtnInfo">{btnInfo}</span>
      </div>
    </div>
  );
};

export default HelpCard;

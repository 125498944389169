import React from "react";
import { createOptimizedImgUrl } from "../data/utils/helpers";

const FreeContentCatalogCard = ({item , onClick, cardBtn}) => {
  let src= item.contentImgUrl;
  let bgImgStyle = {
    backgroundImage: `url(${createOptimizedImgUrl(item?.contentImgUrl, 110)})`,
    backgroundSize: "cover",
    height: "110px",
    width:"110px",
    backgroundPosition: "bottom"
  };
  return (
    <>
      <div class="catalog-card freeContent" >
        <div
          class="catalog-card-media"
          style={bgImgStyle}
        >
          <div class="catalog-card-overlay"></div>
          <div>
            <img
              alt="overlay"
              title="overlay"
              src={src}
              height="110px"
            />
          </div>
        </div>
        <div class="catalog-card-content ">
          <div
            class="catalog-card-header"
          >
            {item?.contentTitle}
          </div>
          <div class="catalog-card-subheader">
           <div className="subjectExamTagName">{item?.subjectTag} {item?.examTag && "| " + item.examTag} </div>  
          <div class="attemptFreeContent" onClick={() => onClick(item)}>{cardBtn}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FreeContentCatalogCard;

import React from "react";
import PropTypes from "prop-types";
import { isADDA } from "../../data/utils/helpers";
const OverLaySearchHOC = ({
  setSearchUiVisiblity,
  SearchComponent,
  className,
  children,
}) => {
  return (
    <div
      className={`full-search-bar ${isADDA ? "productListingWrap" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="search-header">
        <div className="container">
          <div
            className="round-arrow"
            onClick={() => setSearchUiVisiblity(false)}
          >
            <img src="/images/testi-pagi.svg" />
          </div>
          {SearchComponent}
        </div>
      </div>
      {children}
    </div>
  );
};
OverLaySearchHOC.propTypes = {
  setSearchUiVisiblity: PropTypes.func,
  SearchComponent: PropTypes.element,
  children: PropTypes.element,
};
export default OverLaySearchHOC;

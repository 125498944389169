import React, { useEffect } from 'react';

const CustomLoaderHeaderFooterHide = (props) => {

    return (
        <div>
            <div className={props.buttonSpinner ? 'button-spinner' : 'spinner-listingg'}>
                <div className={props.buttonSpinner ? 'button-spin' : 'spinner'}></div>
            </div>
        </div>
    );
};

export default CustomLoaderHeaderFooterHide;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { testTimer } from "../../../../utils";
const ScoreCard = ({
  ribonLabel = "",
  ribonLabelRight = "",
  title = "",
  iconSrc = "",
  consumedNumber = 0,
  totalNumber = 0,
  isRefresh = false,
  isTimeScoreCard = false,
}) => {
  const percentage = (consumedNumber / totalNumber) * 100;
  return (
    <div className="sectionalBox">
      <div className="sectionalTag">{ribonLabel}</div>
      {ribonLabelRight && (
        <div className="sectionalTagRight">{ribonLabelRight}</div>
      )}
      <div className="iconWithText">
        <div className="icon-small">
          <img src={iconSrc} alt={title} />
        </div>
        {title}
        {isRefresh && (
          <img
            src="../../../images/resultIcon-refresh.svg"
            className="refreshicon"
          />
        )}
      </div>

      <div className="spedoScoreResult">
        <SemiCircleProgressBar
          percentage={percentage}
          stroke={"#FF647C"}
          background={"#DCD3D3"}
          diameter={120}
          showPercentValue={false}
        />
        <div className="textProgressbar">
          <div className="toptextProgress">
            {isTimeScoreCard ? testTimer(consumedNumber) : consumedNumber}
          </div>
          <div className="bottontextProgress">
            {isTimeScoreCard ? testTimer(totalNumber) : totalNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreCard.prototype = {
  ribonLabel: PropTypes.string,
  ribonLabelRight: PropTypes.string,
  title: PropTypes.string,
  iconSrc: PropTypes.string,
  consumedNumber: PropTypes.string,
  totalNumber: PropTypes.string,
  isTimeScoreCard: PropTypes.bool,
};
export default ScoreCard;

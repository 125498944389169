import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { VIDEO_URI } from "../../constants/appUrls";
import { videoPackageUrl } from "../../constants/appConfig";
import fetch_url from "../data/utils/fetch";
import WatermarkElement from "./library/WatermarkElement";
// import CookieEnableModal from "./library/CookieEnableModal";

var vid = null;
const OldShakaPlayer = (props) => {
  const videoContainer = useRef(null);
  const videoComponent = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setPlayFlag] = useState(false);
  const [showPlayPause, setShowPlayPause] = useState(false);

  let [rateChange, setRateChange] = useState(1);
  var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const isRecordedUrlAvailaible = useMemo(() => {
    return props.getSelectedVideo ? props.getSelectedVideo() : false
  }, [props.getSelectedVideo])
  useEffect(() => {
    const attrLen = document.getElementsByClassName(
      "shaka-controls-container"
    )[0]?.attributes?.length;
    if (attrLen > 1) {
      setShowPlayPause(true);
    } else {
      setShowPlayPause(false);
    }
  });
  useEffect(() => {
    setPlayFlag(false);
    if (isRecordedUrlAvailaible) {
      props.setProgressApi(true);
    }
    var video = document.getElementById("shaka-video");
    var player = new shaka.Player(video);
    if (typeof video !== "undefined" && video !== null) {
      video.pause();
    }
    if (typeof player !== "undefined" && player !== null) {
      player.unload();
      player.destroy();
    }
    setTimeout(() => {
      fetchDRMToken(props.id);
    }, 2000);
  }, [props.id, props.url, props?.classId]);

  useEffect(() => {
    if (isRecordedUrlAvailaible && !props.progressFlag) {
      const timer = setInterval(() => {
        videoOnProgress();
      }, parseInt(10000 / rateChange)); // timer = 10 sec * playbackSpeed
      return () => clearInterval(timer);
    }
  }, [isRecordedUrlAvailaible, rateChange]);


  useEffect(() => {
    if (props?.getVideoRatingStatus) {
      props.getVideoRatingStatus();
      props?.setShowRatingModalAllowed(true)
    }
  }, [])

  const timeSet = (timeInSec) => {
    setCurrentTime(timeInSec);
  };
  const fetchDRMToken = async (video_id = null) => {
    if (isRecordedUrlAvailaible) {
      props.setProgressApi(true);
    }
    let obs = document.getElementsByClassName("shaka-controls-container");
    for (var i = obs.length - 1; i >= 0; --i) {
      obs[i].remove();
    }
    let obs_spinner = document.getElementsByClassName(
      "shaka-spinner-container"
    );
    for (var j = obs_spinner.length - 1; j >= 0; --j) {
      obs_spinner[j].remove();
    }
    let video = document.getElementById("shaka-video");
    fetch_url(
      `${videoPackageUrl.videoStatus}?packageId=${props.packageId}&videoId=${video_id || props.id
      }`
    ).then((res) => {
      let startTime = 0;
      if (res.data && res.data[0]) {
        startTime =
          res.data[0].wt > 1
            ? res.data[0].wt / 1000 + (res.data[0].wt % 1000)
            : 0;
      }
      if (startTime > 0) {
        video.currentTime = startTime;
        videoOnProgress(startTime);
        const video5 = document.getElementById("shaka-video");
        video5.addEventListener("timeupdate", (event) => {
          timeSet(startTime);
        });
      }
      if (isRecordedUrlAvailaible) {
        props.setProgressApi(false);
      }
    });

    if (typeof video !== "undefined" && video !== null) {
      video.pause();
    }
    if (isSafari || iOS) {
      async function getFpsCertificate() {
        let req = await fetch(
          "//fp-keyos.licensekeyserver.com/cert/2b8db8748b79535edd52bbc1495d6efe.der"
        );
        let cert = await req.arrayBuffer();
        return new Uint8Array(cert);
      }
      // async function initPlayer() {
      // Enable support for IE
      shaka.polyfill.installAll();
      // Check to see if the browser supports the basic APIs Shaka needs.
      if (shaka.Player.isBrowserSupported()) {
        // Create a Player instance.
        if (isPlaying) {
          setPlayFlag(false);
        }
        let video = document.getElementById("shaka-video");
        let player = new shaka.Player(video);
        const video_safari = videoComponent.current;
        const videoCnt_safari = videoContainer.current;
        const ui = new shaka.ui.Overlay(player, videoCnt_safari, video_safari);
        //Setting UI configuration JSON object
        const uiConfig = {};
        //Configuring elements to be displayed on video player control panel
        uiConfig["controlPanelElements"] = [
          "play_pause",
          "spacer",
          "mute",
          "time_and_duration",
          "fullscreen",
          "overflow_menu",
          "picture_in_picture",
        ];
        uiConfig["overflowMenuButtons"] = [
          "playback_rate",
          "picture_in_picture",
        ];
        uiConfig["playbackRates"] = [0.75, 1, 1.25, 1.5, 1.75, 2];
        ui.configure(uiConfig);
        ui.getControls();

        // Attach player to the window to make it easy to access in the JS console.
        window.player = player;
        // Get certificate
        let cert = await getFpsCertificate();
        // Global content Id to have access to it later.
        let contentId = "";
        player.configure(
          "drm.servers.com\\.apple\\.fps\\.1_0",
          `${VIDEO_URI}/v3/get/license?drm-type=fairplay&pkgId=${props.packageId
          }${props?.isOlc ? "&isOlc=true&id=" + props?.classId : "&isOlc=false&id=" + props?.id}&isPersistent=false`
        );
        player.configure(
          "drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate",
          cert
        );
        player.configure("drm.initDataTransform", (initData, initDataType) => {
          if (initDataType != "skd") return initData;
          // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
          const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
          contentId = skdUri.split("skd://")[1].substr(0, 32);
          const cert = player.drmInfo().serverCertificate;
          return shaka.util.FairPlayUtils.initDataTransform(
            initData,
            contentId,
            cert
          );
        });
        // Tell player that it needs to send custom data (authentication XML) during its license request.
        player.getNetworkingEngine().registerRequestFilter((type, request) => {
          if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
            let jwtToken = Cookies.get("cp_token");
            // request.headers["X-JWT-Token"] = jwtToken;
            // request.headers["X-Auth-Token"] = "fpoa43edty5";
            request.headers["Content-Type"] = "text/plain";
            const originalPayload = new Uint8Array(request.body);
            const base64Payload =
              shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
            const params = "spc=" + base64Payload + "&assetId=" + contentId;
            request.body = shaka.util.StringUtils.toUTF8(params);
          }
        });
        player
          .getNetworkingEngine()
          .registerResponseFilter((type, response) => {
            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
              let responseText = shaka.util.StringUtils.fromUTF8(response.data);
              // Trim whitespace.
              responseText = responseText.trim();
              // Decode the base64-encoded data into the format the browser expects.
              response.data =
                shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
            }
          });

        // Listen for error events.
        player.addEventListener("error", (event) => {
          console.error(
            "Error code",
            event.detail.code,
            "object",
            event.detail
          );
        });
        // Try to load a manifest.
        // This is an asynchronous process.
        console.log("PLAYER IS CALLING");

        video.play();
        player
          .load(props.hlsUrl)
          .then(function () {
            // This runs if the asynchronous load is successful.
            const videoDet = document.getElementById("shaka-video");
            if (props?.setTotalVideoDuration) {
              props.setTotalVideoDuration(videoDet?.duration)
            }
            console.log("The video has now been loaded!");
          })
          .catch((e) => {
            console.error(
              "Failed loading manifest. Code",
              e.code,
              "object",
              e.detail
            );
          });
      } else {
        // This browser does not have the minimum set of APIs we need.
        console.error("Browser not supported!");
      }
    } else {
      // Install built-in polyfills to patch browser incompatibilities.
      shaka.polyfill.installAll();

      // Check to see if the browser supports the basic APIs Shaka needs.
      if (shaka.Player.isBrowserSupported()) {
        // Everything looks good!

        console.log("Player is good=>>>>>>", props.url);
        const video = videoComponent.current;
        const videoCnt = videoContainer.current;
        let player = new shaka.Player(video);
        const ui = new shaka.ui.Overlay(player, videoCnt, video);
        //Setting UI configuration JSON object
        const uiConfig = {};
        //Configuring elements to be displayed on video player control panel
        uiConfig["controlPanelElements"] = [
          "play_pause",
          "spacer",
          "mute",
          "volume",
          "time_and_duration",
          "fullscreen",
          "overflow_menu",
          "picture_in_picture",
        ];
        uiConfig["overflowMenuButtons"] = [
          "playback_rate",
          "picture_in_picture",
        ];

        uiConfig["playbackRates"] = [0.75, 1, 1.25, 1.5, 1.75, 2];
        ui.configure(uiConfig);
        ui.getControls();

        // Tell player from where to get DRM licenses.
        player.configure({
          drm: {
            servers: {
              "com.widevine.alpha": `${VIDEO_URI}/v3/get/license?drm-type=widevine&pkgId=${props.packageId
                }${props?.isOlc ? "&isOlc=true&id=" + props?.classId : "&isOlc=false&id=" + props?.id
                }&isPersistent=false`,
              "com.microsoft.playready": `${VIDEO_URI}/v3/get/license?drm-type=playready&pkgId=${props.packageId
                }${props?.isOlc ? "&isOlc=true&id=" + props?.classId : "&isOlc=false&id=" + props?.id
                }&isPersistent=false`,
            },
          },
        });
        // Tell player that it needs to send custom data (authentication XML) during its license request.
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            // Only add headers to license requests:
            console.log(
              "shaka.net.NetworkingEngine.RequestType.LICENSE",
              shaka.net.NetworkingEngine.RequestType.LICENSE
            );

            if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
              // This is the specific header name and value the server wants.
              // You need to add actual authentication XML here in base64 encoded format.
              let jwtToken = Cookies.get("cp_token");
              // request.headers["X-JWT-Token"] = jwtToken;
              // request.headers["X-Auth-Token"] = "fpoa43edty5";
            }
          });

        player.addEventListener("error", (error) => {
          console.log("Error", error);
        });
        await player
          .load(props.url)
          .then(function () {
            console.log("The video has now been loaded!=>>>>>>>>>>>>");
            vid = document.getElementById("shaka-video");
            if (props?.setTotalVideoDuration) {
              props.setTotalVideoDuration(vid?.duration)
            }
            vid.addEventListener("ratechange", (event) => {
              playBackRateChange(event);
            });
          })
          .catch((error) => {
            console.log("Error in catch", error);
            window.setTimeout(() => {
              fetchDRMToken();
            }, 2000);
          });
      } else {
        // This browser does not have the minimum set of APIs we need.
        console.error("Browser not supported!");
      }
    }
  };

  const videoOnProgress = (timeee = null) => {
    if (isRecordedUrlAvailaible) {
      let playerTime = document.getElementById("shaka-video");
      setCurrentTime(parseInt(playerTime.currentTime));
      let playerCurrentTime =
        timeee && timeee > 0
          ? timeee * 1000
          : parseInt(playerTime.currentTime) * 1000;
      if (!props.progressFlag && playerCurrentTime > 0 && !playerTime?.paused) {
        let payload = [
          {
            pid: props.packageId,
            vid: props.getSelectedVideo(),
            wt: playerCurrentTime,
            ts: Date.now(),
            st: 10000
          },
        ];

        fetch_url(videoPackageUrl.videoStatus, "POST", payload);
      }
    }
  };
  const videoPlayPauseHandler = (flag) => {
    vid = document.getElementById("shaka-video");
    if (flag) {
      vid.pause();
      if (isPlaying) {
        setPlayFlag(false);
      }
    } else {
      vid.play();
      if (!isPlaying) {
        setPlayFlag(true);
      }
    }
  };
  const videoForwardHandler = () => {
    vid.currentTime = vid.currentTime + 15;
  };
  const videoBackwardHandler = () => {
    vid.currentTime = vid.currentTime - 15;
  };

  const playBackRateChange = (e) => {
    const rateSpeed = e.target?.ui?.player_?.playRateController_?.rate_ || 1
    setRateChange(rateSpeed);
  };
  const changePlayer = (status) => {
    if (status) {
      if (!isPlaying) {
        setPlayFlag(status);
      }
    } else {
      if (isPlaying) {
        setPlayFlag(status);
      }
    }


    // Rating section

    let VideoDetails = document.getElementById("shaka-video");
    // video pause status
    if (!status && props.isRatingEnable && props.canUserRate && props?.handleRatingAction) {

      props.handleRatingAction().then((res) => {
        if (res) {
          if ((res / 1000) > (VideoDetails?.duration / 2)) { // time in sec 
            props.setIsRatingVideosModalVisible(true);
            props.getCurrentVideoWt(VideoDetails?.currentTime)
          }
        }
      });


    }

    // video end status
    if (VideoDetails?.ended && props?.handleRatingAction && props.isRatingEnable && props?.canUserRate) {
      props.handleRatingAction().then((res) => {
        if (res) {
          if ((res / 1000) > (VideoDetails?.duration / 2)) {
            props.setIsRatingVideosModalVisible(true);
            props.getCurrentVideoWt(VideoDetails?.currentTime)
          }
        }
      });

    }

  };
  return (
    <>
      {/* <CookieEnableModal /> */}

      <div
        className={`video-container ${props?.isOlc ? "olc_there" : "olc_not"}`}
        ref={videoContainer}
        id="video-container"
      >
        <WatermarkElement containerId={"video-container"} />
        <p data-content="resolution"></p>
        <video
          ref={videoComponent}
          className="shaka-video"
          id="shaka-video"
          onTimeUpdate={(e) => timeSet(e.target.currentTime)}
          onPause={() => changePlayer(false)}
          onPlay={() => changePlayer(true)}
          controls={false}
        />
        {showPlayPause ? (
          <div className="pre-forword-wrap">
            <button
              className="pre-video-icon"
              onClick={() => videoBackwardHandler()}
            >
              <img src="/images/gold_assets/video-backword-icon.svg" />
            </button>
            <button
              className={`play-pause-btn ${!isPlaying ? "pause-btn" : ""} `}
              onClick={() => videoPlayPauseHandler(isPlaying)}
            >
              <img
                src={`${!isPlaying
                  ? "/images/gold_assets/ic_play.svg"
                  : "/images/gold_assets/video-pause-icon.svg"
                  }`}
              />
            </button>
            <button
              className="forword-video-icon"
              onClick={() => videoForwardHandler()}
            >
              <img src="/images/gold_assets/video-forword-icon.svg" />
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  header: state.header,
});
export default connect(mapStateToProps, null)(OldShakaPlayer);
import React from "react";
import { Link, useLocation } from "react-router-dom";

const BreadCrumItem = ({
  href = "#",
  breadCrumName = "Link1",
  active = true,
  devider = true,
}) => {
  return (
    <>
      <div className={`ppc-breadcrumbs-${active ? "current" : "prev"}`}>
        {active ? (
          <span>{breadCrumName}</span>
        ) : (
          <Link to={href}>{breadCrumName}</Link>
        )}
      </div>
      {devider && <div className="ppc-breadcrumbs-devider">/</div>}
    </>
  );
};
const PPCBreadCrumbs = ({ routes = [] }) => {
  let { pathname } = useLocation();
  return (
    <section className="ppc-breadcrumbs-wrap">
      <div className="ppc-breadcrumbs">
        {routes?.map((route, index) => (
          <BreadCrumItem
            key={route?.name}
            href={route?.route}
            breadCrumName={route?.name}
            active={pathname === route?.route}
            devider={routes?.length !== index + 1}
          />
        ))}
      </div>
    </section>
  );
};

export default PPCBreadCrumbs;

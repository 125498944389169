import React, { useState, useEffect, useRef, useCallback } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { childPackageDetailUrl } from "../../../../constants/appConfig";
import ErrorBoundary from "../../../components/ErrorBoundary";
import ContineInApp from "../../../components/library/ContinueInApp/ContinueInApp";
import NewCarousel from "../../../components/library/NewCarousel";
import PPCContinueLiveClassPopup from "../../../components/PPC/PPCContinueLiveClassPopup";
import ProductCard from "../../../components/PPC/ProductCard";
import {
  getTimeLeftStamp,
  openEbook,
} from "../../../components/utils";
import {
  getPPCAllContinueListings,
  getPPCContinueListing,
  setAllContinueListingData,
} from "../../../data/ducks/header/actions";
import fetch from "../../../data/utils/fetch";
import {
  getDeviceType,
  getMoengageMetadata,
} from "../../../data/utils/helpers";
import { DEVICE_IDENTIFIERS } from "../../../../constants/textConstants";
import { openTestPortal } from "../../../components/enrolledCourses/TestSeries/TakeTest/util";

const PPCContinueListing = ({
  data = [],
  category,
  isCategoryBased = true, // isCategoryBased is an boolean value,to handle which service (category based continue data or all continue data) to call.
}) => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [videoConsumptionData, setVideoConsumptionData] = useState({});
  const [clickedItemData, setClickedItemData] = useState();
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showVideoLoad, setShowVideoLoad] = useState(false);
  const { packageId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((enteries) => {
        if (enteries[0].isIntersecting && hasMore) {
          setPageNo((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getPPCContinueListingPaginated = async () => {
    if (pageNo !== 0) {
      // alert(2);
      setLoading(true);
      const res = await dispatch(
        getPPCContinueListing(packageId, pageNo, 20, category)
      );
      setLoading(false);
      const result = await res[0];
      setHasMore(result?.data?.length > 0);
    }
  };

  const getNextPagePPCAllContinueListing = async () => {
    if (pageNo !== 0) {
      setLoading(true);
      const res = await getPPCAllContinueListings(packageId, pageNo, 10);
      setHasMore(res.length > 0);
      dispatch(setAllContinueListingData(res));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isCategoryBased) {
      getPPCContinueListingPaginated();
    } else {
      getNextPagePPCAllContinueListing();
    }
  }, [pageNo]);
  let possibelCategories = {
    ONLINE_LIVE_CLASSES: "my-live-class",
    VIDEOS: "video-package",
    TEST_SERIES: "test-series",
    EBOOKS: "ebooks",
  };
  let btnLabel = {
    ONLINE_LIVE_CLASSES: "Start",
    VIDEOS: "Start",
    TEST_SERIES: "Start",
    EBOOKS: "Open",
  };
  const getActionLabel = (data, category) => {
    let label = "";
    label = `${data?.status === "NOT_STARTED" ? btnLabel[category] : "Resume"}`;
    return label;
  };
  const getOlcData = async (srcPackageId, classId) => {
    const response = await fetch(
      `${childPackageDetailUrl.olc}/${srcPackageId}`,
      "GET"
    );
    let olcData = response?.data?.onlineClasses || [];
    return olcData?.find((olc) => olc?.classId == classId) || {};
  };
  const resumeOlcVideoHandler = async (packageData) => {
    setShowVideoPopup(true);
    setShowVideoLoad(true);
    //first get all olc data.
    const olcConsumptionData = await getOlcData(
      packageData?.packageId,
      packageData?.id
    );
    setVideoConsumptionData(olcConsumptionData);
    setShowVideoLoad(false);
  };
  // /video-package/15322/WEEKLY%20MCQ%20CLASS%2002%207TH%20AUG%20TO%2013TH%20AUG#current-video/161791
  const openConsumptionScreen = (packageData) => {
    let checkCategoryFlag = isCategoryBased ? category : packageData?.category;

    /**
     * mWeb android -> restrict the user to open consumptionScreen for every category and show openInApp popup instead.
     * mWeb Ios -> restrict the user (excluding VIDEOS category) to open consumptionScreen for category and show openInApp popup instead.
     */
    if ( getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER) 
    {
      setClickedItemData({
        packageId: packageData?.id,
        type: checkCategoryFlag,
        parentPackageId: packageData?.packageId,
      });
      return false;
    }

    let moengagePayload = {};
    moengagePayload.id = packageData?.packageId;
    moengagePayload.category = packageData?.type;
    moengagePayload.package_status = "Paid";
    moengagePayload.exam_category = packageData?.category;
    if (packageData?.duration && packageData.seekTime) {
      moengagePayload.time_left =
        getTimeLeftStamp(packageData?.duration, packageData?.seekTime) || "";
    }
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "Resume_btn_clicked",
        getMoengageMetadata(moengagePayload)
      );

    switch (checkCategoryFlag) {
      case "ONLINE_LIVE_CLASSES":
        resumeOlcVideoHandler(packageData);
        break;
      case "EBOOKS":
        openEbook(packageData?.packageId, packageData?.id);
        break;
      case "TEST_SERIES":
        openTestPortal(
          packageData?.id,
          packageData?.packageId,
          packageData?.title,
          packageData?.status,
          null,
          packageData?.courseList,
        );
        break;
      case "VIDEOS":
        history.push(
          `/${possibelCategories[checkCategoryFlag]}/${packageData?.packageId}/${packageData?.title}#current-video/${packageData?.id}`
        );
      default:
        break;
    }
  };
  const getIconSrc = {
    ONLINE_LIVE_CLASSES: "login-icon5",
    VIDEOS: "login-icon4",
    TEST_SERIES: "login-icon2",
    EBOOKS: "login-icon3",
    MODELS_3D: "login-icon9",
  };
  if (data?.length)
    return (
      <ErrorBoundary>
        <>
          <PPCContinueLiveClassPopup
            visible={showVideoPopup}
            onCancel={() => {
              setShowVideoPopup(false);
              setVideoConsumptionData({});
            }}
            showVideoLoad={showVideoLoad}
            videoConsumptionData={videoConsumptionData}
          />
          <ContineInApp
            show={!!clickedItemData}
            packageId={clickedItemData && clickedItemData?.packageId}
            parentpackageId={clickedItemData && clickedItemData?.parentPackageId}
            type={clickedItemData && clickedItemData?.type}
            close={() => {
              setClickedItemData();
            }}
            isContinuesection={true}
          />
          <div className="ppc-product-crousel-wrap">
            <div className="ppc-product-crousel-heading">
              Continue from Where You Left
            </div>
            <NewCarousel id={`ppc-continue-listings`}>
              {data?.map((item, idx) => (
                <ProductCard
                  key={`${idx}_${new Date().getTime() * Math.random(-9)}`}
                  ref={
                    data?.length > 5 && data?.length - 2 === idx
                      ? lastElementRef
                      : null
                  }
                  title={item?.title}
                  src={item?.thumbnail}
                  btnActionLabel={getActionLabel(
                    item,
                    isCategoryBased ? category : item?.category
                  )}
                  onClick={() => openConsumptionScreen(item)}
                  seekTime={
                    getTimeLeftStamp(item?.duration, item?.seekTime) || ""
                  }
                  iconSrc={`/images/${
                    getIconSrc[category || item?.category]
                  }.svg`}
                />
              ))}
            </NewCarousel>
          </div>
        </>
      </ErrorBoundary>
    );
  return <></>;
};

export default memo(PPCContinueListing);

import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import {configureStore} from '../app/data/store';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import Routes from '../routes/routes';
import '../less/theme.less';
import {logPageView} from '../app/data/utils/helpers';
import {googleAnalyticsId} from '../constants/appConfig';

const history = createBrowserHistory();

if (typeof window !== 'undefined') {
    ReactGA.initialize(googleAnalyticsId, { debug: false });
    logPageView(window.location.pathname + window.location.search);

    history.listen(() => {
        logPageView(window.location.pathname + window.location.search);
    });
}

window.onload = () => {
    Loadable.preloadReady().then(() => {
        let store = configureStore(window.INITIAL_STATE);
        ReactDOM.hydrate(
            <Provider store={ store }>
                <CookiesProvider>
                    <BrowserRouter>
                        <div>{renderRoutes(Routes)}</div>
                    </BrowserRouter>
                </CookiesProvider>
            </Provider>
            , document.getElementById('app'));
    });
};
import React, { useMemo } from "react";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isStringValueExist,
} from "../../../data/utils/helpers";

const FacetsItemRenderer = ({
  facetItems = [],
  facetType = "",
  isSearchEnabled = true,
  selectedFiltersFacets = {},
  onChangeFacetHandlerCb = () => null,
}) => {
  const memoizeFacetItems = useMemo(() => {
    if (!facetItems?.length) return [];
    // exclude Magazines category before mapping
    return facetItems?.filter((facet) => !facet?.includes("Magazines"));
  }, [facetItems]);
  if (!memoizeFacetItems?.length)
    return (
      <div className="noresult-found">
        <div className="no-result-icon">
          <img src="/images/no-result-icon.svg" />
        </div>
        <div className="no-rusult-heading">No Results Found!</div>
        <div className="no-rusult-subheading">
          Please check if you have the right spelling, or try different
          keywords.
        </div>
      </div>
    );

  return memoizeFacetItems?.map((item, index) => {
    const facetValue = getSubstringAfterChar(item, "#");
    const facetLabel = getSubstringBeforeChar(item, "#");
    const isChecked = isStringValueExist(
      selectedFiltersFacets[facetType],
      facetValue
    );

    return (
      <li key={`facet_${index}`}>
        <input
          type="checkbox"
          id={`${facetValue}_${index}`}
          value={facetValue}
          checked={isChecked}
          onChange={(e) =>
            onChangeFacetHandlerCb({
              isSelected: e.target.checked,
              value: facetValue,
              facetType,
              isSearchEnabled, //pass false flag to restrict dispatch on input change
            })
          }
        />
        <label htmlFor={`${facetValue}_${index}`}>{facetLabel}</label>
      </li>
    );
  });
};

export default FacetsItemRenderer;

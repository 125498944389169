import React, { useState } from 'react';
import { productKeys } from '../../../constants/appConstants';
import { isEmpty, getPdpUrl } from '../../data/utils/helpers';
import CustomSkeleton from '../library/CustomSkeleton';
import { useHistory } from 'react-router';
import QuickViewProductCard from '../home/QuickViewProductCard';
import { useSelector } from 'react-redux';
import { FallBackImage } from '../../../constants/appConfig';
import { checkIsPackageWishList, useWishListedPackageIds } from '../utils';
const placeholder = FallBackImage;
const FilterList = (props) => {
    const courselistArr = props.filterList;
    const [quickViewPopup, setQuickViewPopup] = useState(false);
    const { hoveredPackageInfos={}  } = useSelector((state) => state.header);
  const [wishListPackageIds] = useWishListedPackageIds();
    return <>
        {
            !courselistArr || props.showLoader ?
                <CustomSkeleton /> : courselistArr && courselistArr.map((item, i) => {
                    let categories = props.categoryname || item && item['categories'] || "";
                    categories = categories.toLowerCase();

                    const category = productKeys[categories] || "";
                    const id = item?.id || "";
                    const title = item?.cardtitle || item?.title || "";
                    const slugurl = item?.slugURL || "";
                    const pdpurl = getPdpUrl(category, id, title, slugurl);
                    const isWishList = checkIsPackageWishList(
                        wishListPackageIds,
                        item?.id
                      );
                    return (
                        <div className="filterListCards">
                            <QuickViewProductCard
                                index={i}
                                key={item?.id}
                                id={item?.id}
                                onClick={() => props.handleDetailCardClick(item)}
                                cardtitle={item?.title}
                                slugURL={item?.slugURL}
                                src={item?.imgUrl}
                                datasrc={item?.imageUrl}
                                categoriesincluded={!isEmpty(item?.subCategories) ? item?.subCategories.map(e => e.category) : []}
                                sellingprice={item?.price}
                                maximumretailprice={item?.maxPrice}
                                categoryname={categories}
                                // coupons={{ "coupons": item?.coupons, defaultCoupons: item?.defaultCoupons }}
                                item={item}
                                setQuickViewPopup={(flag) => {
                                    setQuickViewPopup(flag)
                                }}
                                coupons={{
                                    coupons: item?.coupons,
                                    defaultCoupons: item?.defaultCoupons,
                                }}
                                listName="FilterList"
                                pdpUrl={pdpurl}
                                productDetailInfo={hoveredPackageInfos[item?.id] && hoveredPackageInfos[item?.id].packageEsEntity[0]
                                }
                                isProductInfoExist={hoveredPackageInfos.hasOwnProperty(item?.id)}
                                source_screen = "Product Listing Filtered"
                                isWishList={isWishList}
                            />
                        </div>
                    )
                })

        }

    </>;
};
export default FilterList;
// this expects that  VideoPlayer is already installed

import React from "react";
import fetch from "../../../data/utils/fetch";
import { videoPackageUrl } from "../../../../constants/appConfig";
import { getMoengageMetadata } from "../../../data/utils/helpers";
let videoPlayedTime = 0;
var startTime;
var action;
import { isIosSafari } from "../../../data/utils/helpers";
import CookieEnableModal from "../CookieEnableModal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import WatermarkElement from "../WatermarkElement";

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.updateVideoStatus = this.updateVideoStatus.bind(this);
    this.moengageEvetTriggred = this.moengageEvetTriggred.bind(this);
    this.onIllegalMediaSwitchHandler =
      this.onIllegalMediaSwitchHandler.bind(this);
    // this.openHandoutSection = this.openHandoutSection.bind(this)

    this.state = {
      id: "",
      currentTime: 0,
      videoPercentage: 10,
      showPlayer: true,
      isIlegalMediaSwitchHandled: false,
      showNextButton: false,
      percentage: 100,
    };
    this.iconRef = React.createRef();
    // this state is just to call the re-render .......could have used lifecycle
    this.startVideoFrom = 100;
    this.isSafari = !!window.navigator.userAgent.match(
      /Version\/[\d\.]+.*Safari/
    );
    this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  // drm configuration for videojs

  componentDidMount() {
    // instantiate Video.js
    if (
      typeof videojs != "undefined" &&
      videojs &&
      this.props.token &&
      !this.props.demoUrl
    ) {
      this.playVideo();
    } else {
      this.playDemoUrl();
    }

    if (this.props.getVideoRatingStatus) {
      this.props.getVideoRatingStatus();
      this.props?.setShowRatingModalAllowed(true);
    }
    // adding ILLEGAL_MEDIA_SWITCH_EVENT event listener
    window.addEventListener(
      "ILLEGAL_MEDIA_SWITCH_EVENT",
      this.onIllegalMediaSwitchHandler
    );

    setTimeout(() => {
      let videoContainer = document.querySelector('.video-js');
      videoContainer.setAttribute('id','vid-cont')
    }, 0); // because videojs takes time to initialize
  }

  onIllegalMediaSwitchHandler() {
    if (!this.state.isIlegalMediaSwitchHandled) {
      console.info("Handling illegal media switch");
      this.setState({ showPlayer: false, isIlegalMediaSwitchHandled: true });
      this.setState({ showPlayer: true }, () => {
        this.playVideo(this.startVideoFrom);
      });
    }
  }

  moengageEvetTriggred(eventName) {
    var value = this.props?.selectedVideoDetails;
    var payload = this.props?.moengageData;
    if (this.props?.moengageData) {
      payload.contentTitle = value?.name || "";
      payload.startTime = startTime;
      payload.endTime = videoPlayedTime;
      payload.action = "video_watched";
      payload.state = action;
      payload.contentID = this.props?.id;
      payload.videoPlayDuration = eventName
        ? Math.abs(videoPlayedTime - startTime)
        : "";
    }
    if (typeof Moengage !== "undefined")
      Moengage.track_event("video_engaged", getMoengageMetadata(payload));
  }

  setVideoForReplay = () => {
    let payload = [
      {
        pid: this.props?.packageId,
        vid: this.props?.id,
        wt: 0,
        ts: Date.now(),
      },
    ];

    if (!this.props?.isOlc) {
        fetch(videoPackageUrl.videoStatus, "POST", payload);
    }
  }

  updateVideoStatus = () => {
    this.player.on("ended", () => {
      let payload = [
        {
          pid: this.props.packageId,
          vid: this.props.id,
          wt: 0,
          ts: Date.now(),
        },
      ];

      if (!this.props?.isOlc) {
        fetch(videoPackageUrl.videoStatus, "POST", payload);
      }
    });
    this.player.on("timeupdate", () => {
      if (
        this.props.setTotalVideoDuration &&
        this.props.totalVideoDuration <= 0
      ) {
        this.props.setTotalVideoDuration(this.player.cache_.duration);
      }

      if (this.player.cache_.currentTime == 0) {
        action = "video_first_start";
        this.moengageEvetTriggred();
      }
      if (this.player.cache_.currentTime - this.state.currentTime >= 10) {
        // here st is screen time
        let payload = [
          {
            pid: this.props.packageId,
            vid: this.props.id,
            wt: parseInt(this.player.cache_.currentTime) * 1000,
            ts: Date.now(),
            st: 10000,
          },
        ];
        this.state.currentTime = this.player.cache_.currentTime;
        if (!this.props?.isOlc) {
          fetch(videoPackageUrl.videoStatus, "POST", payload);
        }
      }
      //Moengage event update
      var progress =
        (this.player.cache_.currentTime / this.player.cache_.duration) * 100;
      var progressTick = Math.floor(progress / 10);
      if (progressTick > 0 && this.props.isVideosection && progress >= 10) {
        if (progressTick * 10 == this.state.videoPercentage) {
          action = "Reached " + 10 * progressTick + "%";
          this.moengageEvetTriggred();
          let videoPercentage = this.state.videoPercentage;
          videoPercentage = videoPercentage + 10;
          this.setState({ videoPercentage: videoPercentage });
        }
      }
      videoPlayedTime = parseInt(this.player.cache_.currentTime, 10);
      const duration = parseInt(this.player.cache_.duration, 10);

      if (duration > 3 && duration - videoPlayedTime < 15) {
        this.setState({ showNextButton: true });
      } else {
        this.setState({ showNextButton: false });
      }

      if (duration - videoPlayedTime <= 3 && this.state.showNextButton) {
        this.setState({ showNextButton: false });
        this.setVideoForReplay()
        this.props.nextVideoPlayHandler();
      }
    });

    if (this.props.isVideosection) {
      this.player.on("play", () => {
        startTime = this.player.cache_.currentTime;
        startTime = startTime;
        action = "Start playing";
        this.moengageEvetTriggred();
      });
      this.player.on("seeking", () => {
        action = "seek";
        this.moengageEvetTriggred();
        startTime = this.player.cache_.currentTime;
        let progress1 =
          (this.player.cache_.currentTime / this.player.cache_.duration) * 100;
        let progressTick1 = Math.floor(progress1 / 10);
        if (progress1 < 10 || progress1 >= 100) {
          this.setState({ videoPercentage: 10 });
        } else if (progress1 < 100) {
          let videovalue = progressTick1 * 10 + 10;
          this.setState({ videoPercentage: videovalue });
        }
      });
      this.player.on("pause", () => {
        action = "Pause";
        this.moengageEvetTriggred("pause");
      });
      this.player.on("canplaythrough", () => {
        action = "buffering";
        this.moengageEvetTriggred();
      });
      this.player.on("ended", () => {
        action = "video_finished";
        this.moengageEvetTriggred();
      });
      this.player.on("error", () => {
        action = "video_played";
        this.moengageEvetTriggred();
      });
    }

    this.player.on("waiting", function () {
      this.addClass("vjs-custom-waiting");
    });
    this.player.on("playing", function () {
      this.removeClass("vjs-custom-waiting");
    });

    // Rating Section

    if (this.props.isRatingEnable && this.props.canUserRate) {
      this.player.on("pause", () => {
        if (this.props.handleRatingAction) {
          // here handleRatingAction will return totalWatchTime asynchronously and show popup after that.
          this.props.handleRatingAction().then((res) => {
            if (res) {
              if (res / 1000 > this.player.cache_.duration / 2) {
                // time in sec
                this.props.setIsRatingVideosModalVisible(true);
                this.props.getCurrentVideoWt(this.player.cache_.currentTime);
              }
            }
          });
        }
      });

      this.player.on("ended", () => {
        if (this.props.handleRatingAction) {
          // here handleRatingAction will return totalWatchTime asynchronously and show popup after that.
          this.props.handleRatingAction().then((res) => {
            if (res) {
              if (res / 1000 > this.player.cache_.duration / 2) {
                // time in sec
                this.props.setIsRatingVideosModalVisible(true);
                this.props.getCurrentVideoWt(this.player.cache_.currentTime);
              }
            }
          });
        }
      });
    }
  };

  playVideo = (startVideoFrom) => {
    const video_node = document.getElementById("demo");

    if (!this.props?.isOlc) {
      fetch(
        `${videoPackageUrl.videoStatus}?packageId=${this.props.packageId}&videoId=${this.props.id}`
      ).then((res) => {
        let startTime = 0;
        if (res.data && res.data[0]) {
          startTime =
            res.data[0].wt > 1 ? res.data[0].wt / 1000 : res.data[0].wt || 0;
        }
        this.state.currentTime = startTime;
        this.player = videojs(
          this.videoNode,
          this.props.videoOptions,
          function onPlayerReady() {
            this.player_.on("loadedmetadata", function () {
              if (startVideoFrom) {
                this.player_.currentTime(startVideoFrom);
              } else {
                this.player_.currentTime(startTime);
              }
            });
            this.player_.play();
            this.bigPlayButton.show();
          }
        );

        this.player.on("loadedmetadata", () => {
          // let qualities = this.player
          //   .tech({ IWillNotUseThisInPlugins: true })
          //   ?.hls.representations();
          let qualities = Array.from(this.player.qualityLevels()?.levels_)?.map(i => ({width: i.width, height: i.height, id: i.id, label: i.label, bitrate: i.bitrate, frameRate: i.frameRate, enabled: i.enabled_ }))
            

          this.topControlsContainer({
            class: "item-resolution",
            qualities: qualities,
            father: this.player.controlBar.el_,
            // this.playerQuality();
          });
          this.addChapterToPlayer();
        });
        this.player.on("loadstart", () => {
          this.forwardRewind();
        });
        this.player.on("leavepictureinpicture", async () => {
          // Picture-in-picture mode has been exited
          this.props?.closeViewModal(false);
          this.updateVideoStatus();
          console.log("Exited picture-in-picture mode");
        });

        this.updateVideoStatus();
      });
    } else {
      let startTime = 0;
      this.state.currentTime = startTime;
      this.player = videojs(
        this.videoNode,
        this.props.videoOptions,
        function onPlayerReady() {
          this.player_.currentTime(startTime);
          this.bigPlayButton.show();
          this.player_.play();
        }
      );
      this.updateVideoStatus();
    }
  };

  playDemoUrl = () => {
    this.player =
      videojs &&
      videojs(
        this.videoNode,
        this.props.videoOptions,
        function onPlayerReady() {
          // this.player_.currentTime(startTime)
          this.bigPlayButton.show();
          this.player_.play();
        }
      );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.player) {
        this.player.dispose();
      }
      // Create new player
      if (this.props.token && !this.props.demoUrl) this.playVideo();
      else this.playDemoUrl();
    }
    if (this.props.isViewedOnSingleScreen == false) {
      if (this.player) {
        this.player.pause();
        this.player.dispose();
      }
    }
    //open close PiP window
    if (prevProps?.pictureInPicture !== this.props?.pictureInPicture) {
      let mediaElement = this.player.el().firstChild;
      if (
        mediaElement !== document.pictureInPictureElement &&
        this.props.pictureInPicture
      ) {
        this.openPipVideoWindow(true);
      } else {
        this.closePipVideoWindow(false);
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    window.removeEventListener(
      "ILLEGAL_MEDIA_SWITCH_EVENT",
      this.onIllegalMediaSwitchHandler
    );
  }

  playerQuality() {
    this.player.one("loadedmetadata", () => {
      // this.createButtonsQualities({
      //   class: "item-resolution",
      //   qualities: qualities,
      //   father: this.player.controlBar.el_
      // });
      let qualities = Array.from(this.player.qualityLevels()?.levels_)?.map(i => ({width: i.width, height: i.height, id: i.id, label: i.label, bitrate: i.bitrate, frameRate: i.frameRate, enabled: i.enabled_ }));

      this.topControlsContainer({
        class: "item-resolution",
        qualities: qualities,
        father: this.player.controlBar.el_,
      });
    });
  }

  createButtonsQualities(params) {
    let { qualities } = params;
    let contentMenu = document.createElement("div");
    let menu = document.createElement("div");
    let icon = document.createElement("div");

    let fullscreen = params.father.querySelector(".vjs-fullscreen-control");
    contentMenu.appendChild(icon);
    contentMenu.appendChild(menu);
    fullscreen.before(contentMenu);

    menu.classList.add("menu");
    icon.classList.add("icon", "vjs-icon-cog");
    contentMenu.classList.add("contentMenu");

    let autoButton = this.createAutoQualityButton(params);

    menu.appendChild(autoButton);

    qualities.sort((a, b) => {
      return a.height > b.height ? 1 : 0;
    });

    qualities.map((quality) => {
      let button = document.createElement("div");

      if (params && params.class) button.classList.add(params.class);

      button.id = `${quality.height}`;
      button.innerText = quality.height + "p";

      if (quality.height === 360) {
        this.resetQuality(params);
        button.classList.add("selected");
        quality.enabled(true);
        this.concatToAutoLabel();
      }

      button.addEventListener("click", () => {
        this.resetQuality(params);
        button.classList.add("selected");
        quality.enabled(true);
        this.concatToAutoLabel();
      });

      menu.appendChild(button);
    });
  }

  concatToAutoLabel = () => {
    let auto = document.querySelector("#auto");
    
    // let current = this.player
    //   .tech({ IWillNotUseThisInPlugins: true })
    //   .hls.selectPlaylist().attributes.RESOLUTION.height;
    let current = 360;

    document.querySelector("#auto").innerHTML = auto.classList.contains(
      "selected"
    )
      ? `Auto <span class='current'>${current}p</span>`
      : `Auto `;
  };

  createAutoQualityButton(params) {
    let button = document.createElement("div");
    // button.id = "auto";
    // button.innerText = `Auto`;
    button.classList.add("selected");

    const radiobox = document.createElement("input");
    radiobox.type = "radio";
    radiobox.id = "auto";
    radiobox.value = "auto";
    radiobox.name = "quality";
    radiobox.className = "video-quality-radio";

    const label = document.createElement("label");
    label.htmlFor = "auto";
    label.innerText = "Auto";
    label.className = "video-quality-span";

    button.appendChild(radiobox);
    button.appendChild(label);

    if (params && params.class) button.classList.add(params.class);

    button.addEventListener("click", () => {
      this.removeSelected(params);
      button.classList.add("selected");
      params.qualities.map((quality) => quality.enabled(true));
    });

    return button;
  }

  resetQuality(params) {
    this.removeSelected(params);

    for (let quality of params.qualities) {
      quality.enabled(false);
    }
  }

  removeSelected(params) {
    document.querySelector("#auto").classList.remove("selected");
    [...document.querySelectorAll(`.${params.class}`)].map((quality) => {
      quality.classList.remove("selected");
    });
  }

  topControlsContainer(params) {
    const container = this.player.el();
    // Create a custom <div> element
    const customDiv = document.createElement("div");
    customDiv.className = "vjs-screen-top-control-container";
    // Insert the custom <div> into the middle of the player's container
    const middleIndex = Math.floor(container.childNodes.length / 2);

    // Disable the default options
    this.player.controlBar.removeChild("PlaybackRateMenuButton");
    this.player.controlBar.removeChild("FullscreenToggle");

    // DONOT REMOVE THESE COMMENTED CODE
    // this.player.controlBar.removeChild("VolumePanel")
    // this.player.controlBar.removeChild("TimeDivider")
    // this.player.controlBar.removeChild("SeekToLive")
    // this.player.controlBar.removeChild("RemainingTimeDisplay")
    // this.player.controlBar.removeChild("PictureInPictureToggle")

    // custom playback rate menu button
    const customPlaybackRateButton = document.createElement("button");
    customPlaybackRateButton.classList.add("vjs-play-back-rate-button");
    customPlaybackRateButton.innerHTML =
      '<i class="custom-playback-rate-button"></i>';

    // container for the custom menu items
    const menuContainer = document.createElement("div");
    menuContainer.className = "vjs-menu-content";

    // playback rate menu wrapper
    const menuContainerWrapper = document.createElement("div");
    menuContainerWrapper.className = "vjs-menu-content-wrapper";

    const playbackHeader = document.createElement("div");
    playbackHeader.className = "vjs-menu-header-container";
    playbackHeader.innerText = "Playback Speed";

    menuContainerWrapper.appendChild(playbackHeader);

    // Add custom playback rate menu items
    var customRates = [
      { rate: 0.5, label: "0.5 x" },
      { rate: 0.7, label: "0.7 x" },
      { rate: 1, label: "1.0 x" },
      { rate: 1.25, label: "1.25 x" },
      { rate: 1.5, label: "1.5 x" },
      { rate: 1.75, label: "1.75 x" },
      { rate: 2, label: "2 x" },
    ];

    customRates.forEach((item) => {
      const menuItem = document.createElement("div");
      menuItem.className = "item-resolution";

      // custom playback rate radio input
      const radioInput = document.createElement("input");
      radioInput.type = "radio";
      radioInput.className = "video-quality-radio";
      radioInput.name = "playbackRate";
      radioInput.value = item.rate;
      radioInput.id = item.rate;

      // custom playback rate radio label
      const label = document.createElement("label");
      label.className = "video-quality-span";
      label.textContent = item.label;
      label.htmlFor = item.rate;

      // custom playback NORMAL super script
      const superscript_HD = document.createElement("sup");
      superscript_HD.innerText = "(NORMAL)";
      superscript_HD.className = "video-quality-superscript";

      // condition for adding NORMAL superscript
      if (item?.rate === 1) {
        label.appendChild(superscript_HD);
      }

      menuItem.appendChild(radioInput);
      menuItem.appendChild(label);

      // Set the playback rate when a menu item is clicked
      menuItem.addEventListener("click", () => {
        this.player.playbackRate(item.rate);
      });

      menuContainerWrapper.appendChild(menuItem);
      const radio = menuContainerWrapper.querySelector(
        'input[type="radio"][id="1"]'
      );
      if (radio) {
        radio.checked = true; // Set the 'checked' property to true to select the radio button
      } else {
        console.log(`Radio button with ID ${radio} not found.`);
      }
    });

    menuContainer.appendChild(menuContainerWrapper);
    customPlaybackRateButton.appendChild(menuContainer);

    // const radio = document.querySelector(`input[type="radio"][id="${radioIdToSelect}"]`);

    const hadnoutBtn = document.createElement("button");
    hadnoutBtn.classList.add("vjs-handout-button");
    hadnoutBtn.innerHTML = '<i class="vjs-icon-handout"></i>';

    hadnoutBtn.onclick = () => {
      this.props.openHandoutSection();
    };

    let { qualities } = params;
    const contentMenu = document.createElement("div");
    const menu = document.createElement("div");
    const menu_wrapper = document.createElement("div");
    const icon = document.createElement("div");

    const customFullscreenButton = document.createElement("button");
    customFullscreenButton.classList.add("custom-fullscreen-button");
    customFullscreenButton.innerHTML = '<i class="custom-fullscreen-icon"></i>';

    // Handle click event for the custom fullscreen button
    customFullscreenButton.addEventListener("click", () => {
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      } else {
        this.player.requestFullscreen();
      }
    });

    contentMenu.appendChild(icon);
    contentMenu.appendChild(menu);
    menu.appendChild(menu_wrapper);

    // conditionally adding handout button to top container div
    if (this.props?.handoutsAvailable) {
      customDiv.style.width = "215px";
      customDiv.appendChild(hadnoutBtn);
    } else {
      customDiv.style.width = "154px";
    }
    // adding custome element to top container div
    customDiv.appendChild(contentMenu);
    customDiv.appendChild(customPlaybackRateButton);
    customDiv.appendChild(customFullscreenButton);

    // addtion of custom div to the top right corner of video js
    container.insertBefore(customDiv, container.childNodes[middleIndex]);

    menu.classList.add("menu");
    menu_wrapper.classList.add("menu-wrapper");
    icon.classList.add("icon", "vjs-icon-cog");
    contentMenu.classList.add("contentMenu");

    // video quality heading
    const videoQualityHeader = document.createElement("div");
    videoQualityHeader.className = "vjs-menu-header-container";
    videoQualityHeader.innerText = "Video Quality";

    menu_wrapper.appendChild(videoQualityHeader);

    // adding Auto quality button to the list
    const autoButton = this.createAutoQualityButton(params);
    menu_wrapper.appendChild(autoButton);
    // qualities sorting to decending order
    qualities.sort((a, b) => {
      return b.height - a.height;
    });

    qualities.map((quality, index) => {
      const button = document.createElement("div");

      if (params && params.class) button.classList.add(params.class);

      // radio button added for quality
      const radiobox = document.createElement("input");
      radiobox.type = "radio";
      radiobox.id = quality.height;
      radiobox.value = quality.height;
      radiobox.name = "quality";
      radiobox.className = "video-quality-radio";

      // label added for radio button
      const label = document.createElement("label");
      label.htmlFor = quality.height;
      label.innerText = quality.height + "p";
      label.className = "video-quality-span";

      // HD superscript element
      const superscript_HD = document.createElement("sup");
      superscript_HD.innerText = "HD";
      superscript_HD.className = "video-quality-superscript";

      // DATA SAVER element
      const datasaver_label = document.createElement("div");
      datasaver_label.innerText = "DATA SAVER";
      datasaver_label.className = "video-quality-datasaver";

      button.appendChild(radiobox);
      button.appendChild(label);

      // condition for HD superscript
      if (quality?.height > 700) {
        label.appendChild(superscript_HD);
      }

      // condition for DATA SAVER label
      if (index === qualities?.length - 1) {
        button.appendChild(datasaver_label);
      }

      // auto select radio button for 360p
      const radioIdToSelect = "360";
      this.selectQualityRadio({ quality, params, button, radioIdToSelect });

      button.addEventListener("click", () => {
        this.resetQuality(params);
        button.classList.add("selected");
        quality.enabled(true);
        this.concatToAutoLabel();
      });

      menu_wrapper.appendChild(button);
    });
  }

  selectQualityRadio({ quality, params, button, radioIdToSelect }) {
    if (quality?.height === 360) {
      this.resetQuality(params);
      button.classList.add("selected");
      quality.enabled(true);
      this.concatToAutoLabel();
    }

    const radio = document.querySelector(
      `input[type="radio"][id="${radioIdToSelect}"]`
    );
    if (radio) {
      radio.checked = true; // Set the 'checked' property to true to select the radio button
    } else {
      console.log(`Radio button with ID ${radioIdToSelect} not found.`);
    }
  }

  forwardRewind() {
    const container = this.player.el();

    // Insert the custom <div> into the middle of the player's container
    const customDiv = document.createElement("div");
    customDiv.className = "vjs-screen-center-btn-container";
    const middleIndex = Math.floor(container.childNodes.length / 2);
    container.insertBefore(customDiv, container.childNodes[middleIndex]);

    // Create the rewind button
    const rewindButton = document.createElement("button");
    rewindButton.classList.add("skip-back-button");
    rewindButton.innerHTML = '<i class="skip-back-button-icon"></i>';
    // Rewind logic here
    let rewindClickCount = 0;
    let rewindDoubleClickTimeout;
    rewindButton.addEventListener("click", () => {
      // this.player.currentTime(this.player.currentTime() - 10); // Rewind by 10 seconds
      clearTimeout(rewindDoubleClickTimeout);

      rewindClickCount++;

      rewindDoubleClickTimeout = setTimeout(() => {
        if (rewindClickCount === 1) {
          // Single click logic
          this.player.currentTime(
            this.player.currentTime() - rewindClickCount * 5
          ); // Rewind by 5 seconds
        } else {
          // Multiple clicks logic
          this.player.currentTime(
            this.player.currentTime() - clickMultiplier * rewindClickCount
          );
        }
        rewindClickCount = 0;
      }, clickDelay);
    });

    // Create the fast forward button
    const fastForwardButton = document.createElement("button");
    fastForwardButton.classList.add("skip-forward-button");
    fastForwardButton.innerHTML =
      '<i class="skip-forward-button-icon" aria-hidden="true"></i>';
    let forwardClickCount = 0;
    let doubleClickTimeout;
    let clickDelay = 500;
    const clickMultiplier = 10; // Adjust this multiplier as needed

    // Forward logic here
    fastForwardButton.addEventListener("click", () => {
      // this.player.currentTime(this.player.currentTime() + 10); // Forward by 10 seconds
      clearTimeout(doubleClickTimeout);

      forwardClickCount++;

      doubleClickTimeout = setTimeout(() => {
        if (forwardClickCount === 1) {
          // Single click logic
          this.player.currentTime(
            this.player.currentTime() + forwardClickCount * 5
          ); // Forward by 5 seconds
        } else {
          // Multiple clicks logic
          this.player.currentTime(
            this.player.currentTime() + clickMultiplier * forwardClickCount
          );
        }
        forwardClickCount = 0;
      }, clickDelay);
    });

    const bigPlayButton = this.player.controlBar.playToggle.el_;

    // Append the buttons to the custom div
    customDiv.appendChild(rewindButton);
    customDiv.appendChild(bigPlayButton);
    customDiv.appendChild(fastForwardButton);

    // Insert the custom div in the middle of the control bar
    container.insertBefore(customDiv, container.childNodes[middleIndex]);
  }

  // chapter marker
  addChapterToPlayer() {
    const arr = this.props?.bookmarkData || [];
    const total = this.player.duration();

    const markers = arr.map((ele, ind) => {
      return {
        name: ele.name,
        time: ele.startTime / 1000,
        width: ((ele.endTime - ele.startTime) / 1000 / total) * 100,
      };
    });

    var progressBar = this.player.controlBar.progressControl.children_[0].el_;

    for (var i = 0; i < markers.length; i++) {
      const time = markers[i]?.time;
      const width = markers[i]?.width;
      const name = markers[i]?.name;

      const left = (time / total) * 100 + "%";

      const markerDiv = document.createElement("div");
      markerDiv.classList.add("vjs-marker");
      markerDiv.setAttribute("data-time", time);
      markerDiv.style.left = left;
      markerDiv.style.width = `${width}%`;
      const markerSpan = document.createElement("span");
      markerSpan.innerHTML = name;
      markerDiv.appendChild(markerSpan);
      progressBar.append(markerDiv);
    }
  }
  async openPipVideoWindow(event) {
    let mediaElement = this.player.el().firstChild;
    try {
      await mediaElement.requestPictureInPicture();
    } catch (error) {
      // Notify listeners
      this.player.trigger("error open trigger", error);
    } finally {
      // Switch completed
    }
  }
  async closePipVideoWindow(event) {
    try {
      // Exit picture-in-picture
      await document.exitPictureInPicture();
    } catch (error) {
      // Notify listeners
      this.player.trigger("error close trigger", error);
    }
  }
  render() {
    const { showNextButton } = this.state;
    return (
      <div className="new-videojs-video-wrapper">
        <CookieEnableModal />
        {!isIosSafari() ? (
          <div key={this.props.id} style={{ width: "100%", height: "100%" }}>
            {this.state.showPlayer ? (
              <div
                className="normal_player"
                data-vjs-player
                style={{ width: "100%", height: "100%" }}
              >
                <WatermarkElement containerId={'vid-cont'} />
                <video
                  id="my-video"
                  ref={(node) => (this.videoNode = node)}
                  className="video-js vjs-current-time"
                  style={{ width: "100%", height: "100%", display: "hidden" }}
                  controls={false}
                  preload="auto"
                ></video>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div
            id='vid-cont'
            className="normal_player"
            key={this.props.id}
            style={{ width: "100%", height: "100%" }}
          >
            <WatermarkElement containerId={'vid-cont'} />
            <video
              width="100%"
              height="100%"
              controls
              autoPlay={true}
              style={{ width: "100%", height: "100%" }}
            >
              <sources
                src={this.props.videoOptions.sources[0].src}
                type="application/x-mpegURL"
                withCredentials={true}
              />
            </video>
          </div>
        )}
        {showNextButton &&
          (this.props?.nextVideoDetail?.tImg ||
            this.props?.nextThumbnailImage) && (
            <div
              id="class-next-icon-play"
              onClick={() => {
                this.setVideoForReplay()
                this.props.nextVideoPlayHandler();
                this.setState({ showNextButton: false });

                // this.props.setNextButtonClicked(true);
              }}
            >
              <img
                src={
                  this.props?.nextVideoDetail?.tImg ||
                  this.props.nextThumbnailImage ||
                  ""
                }
                id="class-next-icon"
                className="forword-video-icon"
              />
              <img
                src="/images/gold_assets/ic_play.svg"
                style={{
                  position: "absolute",
                  zIndex: "99",
                  transform: "translate(-130%,-5%)",
                }}
              />
            </div>
          )}
      </div>
    );
  }
}
import React, { useRef, useState } from 'react';
import { STOREFRONT_URI } from '../../../constants/appConfig';
import { wrapperWithoutExamList } from '../../../constants/appConstants';
import { getUrlPath, getUrlPathObject, removeSpacesAndInsertHyphens } from '../../data/utils/helpers';
import Chevron from './Chevron';


function CustomAccordionSideBar(props) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');
  const [activeMenu, setActiveMenu] = useState("");
  const content = useRef(null);
  function toggleAccordion(activeKey = null) {
    if (props.Sidebar) {
      let menuActiveItems = document.querySelectorAll(`.accordion.new_accordion.active.${activeKey}`);
      if (menuActiveItems && menuActiveItems.length > 0) {
        [].forEach.call(menuActiveItems, function (el) {
          el.classList.remove("active");
          el.classList.add("close");
        });
      } else {
        document.querySelector(`.accordion.new_accordion.${activeKey}`).classList.add("active");
        document.querySelector(`.accordion.new_accordion.${activeKey}`).classList.remove("close");
      }
      let menuSubItems = document.querySelectorAll(`.mbl-exam-list.active.${activeKey}`);
      if (menuSubItems && menuSubItems.length > 0) {
        [].forEach.call(menuSubItems, function (el) {
          el.classList.remove("active");
          el.classList.add("close");
        });
      } else {
        document.querySelector(`.mbl-exam-list.${activeKey}`).classList.add("active");
        document.querySelector(`.mbl-exam-list.${activeKey}`).classList.remove("close");
      }
      setActiveMenu(activeKey);
      setHeightState(
        activeMenu == activeKey ? '0px' : `${content.current.scrollHeight}px`
      );
      setRotateState(
        activeMenu == activeKey ? 'accordion__icon' : 'accordion__icon rotate'
      );
    } else {
      setActiveState(setActive === '' ? 'active' : '');
      setHeightState(
        setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
      );
      setRotateState(
        setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
      );
    }
  }
  const gotoLink = (url, itemTitle) => {
    if (props.footer) {
      props.trackEvent(itemTitle, props.title, url)
    }
    if (url === `${STOREFRONT_URI}/my-ebooks`) {
      props.history.push('/my-ebooks')
      !props.footer && props.close();
    }
    else if (url === `${STOREFRONT_URI}/my-videos`) {
      props.history.push('/my-videos')
      !props.footer && props.close();
    }
    else if (url == `${STOREFRONT_URI}/my-live-class`) {
      props.history.push('/my-live-class')
      !props.footer && props.close();
    }
    else if (url == `${STOREFRONT_URI}/my-mock-test`) {
      props.history.push('/my-mock-test')
      !props.footer && props.close();
    }
    else if (props.isLink) {
      props.history.push(getUrlPathObject(url));
    }
    else {
      props.isPaytmMini ? window.open(`${url}?utm_source=paytm`, "_self") : window.open(getUrlPath(url))
    }
    !props.footer && props.close();
    setActiveMenu("");
    props.setSideBarActive();
  }
  let activeItem = props?.activeItem;
  return (
    <>
      <div className="mb-exam-menu-btn">
        {
          props.icon && (<div className="mb-exam-menu-logo" style = {props.noChevron && {opacity:"1"}}><img  src={props.icon} alt="" /></div>)
        }
        {
          props.Sidebar ? (<button className={`accordion new_accordion ${(activeMenu === activeItem.id) ? "active" : "close"} ${activeItem.id}`} onClick={() => toggleAccordion(activeItem.id)} style={props.bodyStyle}>
            <p className="accordion__title">{props.title}</p>
            {!props.noChevron && <Chevron className={`${setRotate}`} width={10} fill={'#777'} />}
          </button>) : (<button className={`accordion new_accordion ${setActive}`} style={props.bodyStyle} onClick={toggleAccordion}>
            <p className="accordion__title">{props.title}</p>
            {!props.noChevron && <Chevron className={`${setRotate}`} width={10} fill={'#777'} />}
          </button>)
        }
      </div>
      <div
        ref={content}
        className={'accordion__content'}
      >
        {
          (props.activeItem.examList && props.activeItem.examList.length > 0) ? props.activeItem.examList.map((subExamItem) => {
            return (
              <>
                {
                  props.courseList[subExamItem.id] && props.courseList[subExamItem.id].length > 0 &&
                  <div className={`accordion__child ${props.customChildClass ? props.customChildClass : ''}`}
                    onClick={() => gotoLink(
                      subExamItem.id.toLowerCase() == "bankingandinsurance"
                        ? `/banking-study-materials`
                        : subExamItem.id.toLowerCase() == "engineering"
                          ? `/engineering-study-materials`
                          : `/${subExamItem.id
                            .toLowerCase()
                            ?.replaceAll("_", "-")}-study-materials`
                    )}>
                    {subExamItem.dpn.replace(" STATE EXAMS", "")}
                  </div>
                }

              </>
            );
          }): <>
          {props.activeItem.type === wrapperWithoutExamList && props.courseList[props.activeItem.id] && props.courseList[props.activeItem.id].length && props.courseList[props.activeItem.id].map((subExamItemWithoutWrapper, subExamItemIndex) => {
            return (
              <>
                {
                  <div 
                    key={subExamItemIndex}
                    className={`accordion__child ${props.customChildClass ? props.customChildClass : ''}`}
                    onClick={() => gotoLink(`${removeSpacesAndInsertHyphens(subExamItemWithoutWrapper?.name.toLowerCase())}-exam-kit`)}
                  >
                    {subExamItemWithoutWrapper?.name || ""}
                  </div>
                }

              </>
            );
          })}
          </>
        }
      </div>
    </>
  );
}

export default CustomAccordionSideBar;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';


import MainListing from '../components/my3dmodels/MainListing';
import ViewModelsListing from '../components/my3dmodels/ViewModelsListing';
import Myaccountnavbar from './Myaccountnavbar';
import { withCookies } from 'react-cookie';



import {
  fetchUserModels,
  fetchViewAllModels
} from "../data/ducks/my3dmodels/actions";

import "../data/ducks/my3dmodels/reducers";
import { getSubstringAfterChar, scrollToTop, getMoengageMetadata, getPdpUrl } from "../data/utils/helpers";
import CustomLoader from '../components/library/CustomLoader';

class My3DModels extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.isLoggedIn = cookies.get('cp_token');
    this.state = {
      isLoading: true,
      Loading: true,
      clicked: false,
      modelsList: "",
      searchTerm: "",
      searchTermInput: "",
      noSearchTerm: true,
    }
  }
  componentDidMount() {
    scrollToTop(0);
    this.props.fetchUserModels().then(() => {
      this.setState({
        Loading: false
      })
    }).catch(() => {
      this.setState({
        Loading: false
      })
    });

    if (this.props.match.params.id && this.state.clicked == false) {
      this.getModelsList(this.props.match.params.id);
    }
  }

  getModelsList = (modelId, data) => {

    let MoePayload = {};
    if (data) {
      MoePayload = {
        package_status: 'paid',
        url: location.href,
        name: data.title,
        id: data.id,
        action: 'my_3d_models_btnClicked',
        category: 'MODELS_3D',
        'packageUrl': getPdpUrl("MODELS_3D", data.id, data.title)
      }
      if (typeof (Moengage) !== 'undefined') Moengage.track_event(
        '3dmodels',
        getMoengageMetadata(
          MoePayload
        ),
      );
    }




    this.state.clicked = true;
    this.setState({ isLoading: true });
    if (!this.props.match.params.id) {
      this.props && this.props.history.push(`/my-3d-models/${modelId}`);
    }


    this.props.fetchViewAllModels(modelId, 0, 5000).then(() => {
      this.setState({ isLoading: false });
    });
  }

  handleSearchInput = (event) => {
    event.preventDefault();
    this.setState({ searchTermInput: event.target.value });
    if(!event.target.value.trim()) this.setState({ noSearchTerm: true});
    else this.setState({ noSearchTerm: false});
  }

  handleSearchAction = () => {
    const searchTerm = this.state.searchTermInput?.trim()?.toLowerCase();
    if(searchTerm.length <= 1){
      return;
    }
    this.setState({ searchTerm: searchTerm });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        this.handleSearchAction();
    }
}

  goToModels = () => {
    this.props && this.props.history.push(`/my-3d-models`);
  }

  render() {
    const { modelsList, viewModelsList, location } = this.props;


    const { pathname } = location;
    let id = getSubstringAfterChar(pathname, '/');
    if (!this.isLoggedIn) {
      return <Redirect to="/#show-login" />;
    }

    return (
      <div className="myprofile-container myebooks ebookslidepanel">
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .web-footer{
                            margin-top:0px !important;
                        }
                        `}}
        />
        <div className={'ebook-navbar'}>
          <Myaccountnavbar />
        </div>

        <div className="slide-left-ebook">
          <div style={{ zIndex: '-999' }} className="slide-left-ebook-inner">
            <div className="main-container-account" style={{ border: 'none', minHeight: 'calc(100vh - 200px)' }}>
              {id === 'my-3d-models' ?
                <div>
                  <header className='header-title custumSearchWrap'>
                    <div className='text-container'>
                      3D Learning
                                      </div>
                    <span className='custum-search-wrapper'>
                      <input className='ebooks-search' type={'text'} placeholder="Search" onChange={(e) => {this.handleSearchInput(e)}} onKeyDown = {this.handleKeyDown}></input>
                      <button className='ebooks-search-btn' onClick={() => {this.handleSearchAction()}}>Search</button>
                    </span>
                  </header>
                  {
                    this.state.Loading ?
                      <CustomLoader />

                      :
                      <MainListing modelsList={modelsList} getModelsList={this.getModelsList} searchTerm={this.state.searchTerm} noSearchTerm={this.state.noSearchTerm}/>
                  }

                </div> :
                <>
                  {
                    this.state.isLoading == false && viewModelsList?.models3DData ? <ViewModelsListing viewModelsList={viewModelsList} goToModels={this.goToModels} />
                      :
                      <CustomLoader />
                  }

                </>
              }

            </div>
          </div>
        </div>

      </div>

    )

  }
}

const mapDispatchToProps = {
  fetchUserModels,
  fetchViewAllModels
};

const mapStateToProps = state => ({
  modelsList: state.models.modelsList,
  viewModelsList: state.models.viewModelsList,
  isLoggedIn: state.header.jwtToken,
  userInfo: state.header.userInfo
});

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(My3DModels))

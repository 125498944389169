import React, { useEffect, useMemo, useState } from "react";
import { GOOGLE_CLIENT_ID, pageName } from "../../../../constants/appConfig";
import LeftPanelLoginModal from "../LeftPanelLoginModal";
import { getDeviceType, hitMoengageForOnboarding, initScriptChat, isADDA, saveCookies } from "../../../data/utils/helpers";
import RightPanelLoginModal from "./RightPanelLoginModal";
import { loginFromCookie } from "../../../data/ducks/header/actions";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { onBoardingStepStates } from "./onboardingConstants";


const LoginModalNew = (props) => {
  const [currentStep, setCurrentStep] = useState(onBoardingStepStates.signup);
  const [previousStep, setPreviousStep] = useState("");

  const [loginPanelErrors, setLoginPanelErrors] = useState({
    email : [],
    password : [],
    phone : [],
    otp : [],
    social: "",
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword : false,
    confirmPassword : false,
  });

  const setCookie = (name, value, options = {}) => {
    props.cookies.set(name, value, options);
  };

  const closeModal = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
    if (localStorage.getItem("urlAfterLogin")) {
      localStorage.removeItem("urlAfterLogin");
    }
    if (localStorage.getItem("urlForTestPortal")) {
      localStorage.removeItem("urlForTestPortal");
    }

    if (localStorage.getItem("urlForFreeQuizzes")) {
      localStorage.removeItem("urlForFreeQuizzes");
    }
  };

  const handleUserLogin = (userDetails) => {
    if (userDetails && userDetails?.jwtToken) {
          let cookiesData = {
            jwtToken : userDetails?.jwtToken,
            jwt256Token : userDetails?.jwt256Token,
            userId : userDetails?.id || userDetails?.userId,
            name : userDetails?.name,
            email : userDetails?.email,
            phoneNumber : userDetails?.phoneNumber,
            userCourse : props?.userCourse,
            refId : userDetails?.refId
          }
          saveCookies(cookiesData, setCookie);
          isADDA && initScriptChat();
          props.loginFromCookie(
            userDetails?.jwtToken,
            userDetails?.name,
            userDetails?.email,
            userDetails?.id || userDetails?.userId,
            userDetails?.phoneNumber,
            props?.userCourse?.selectedCourse,
            userDetails.jwt256Token
          );
          handleUpdateUserCookie( userDetails?.email, userDetails?.jwtToken, userDetails?.name);
          props?.fetchProfileData();
    }
  };

  const handleUpdateUserCookie = (email = "", jwtToken = "", name = "") => {

    let e = email || props.cookies.get("cp_user_email");
    let j = jwtToken || props.cookies.get("cp_token");
    let n = name || props.cookies.get("cp_user_name");
    if (typeof window !== "undefined") {
      updateUserDataInCookie(e,j,n);
    }
  }

  const handleBackBtnClick = () => {
    setLoginPanelErrors({
      email : [],
      password : [],
      phone : [],
      otp : [],
      social : "",
    });
    setShowPassword({
      currentPassword : false,
      confirmPassword : false,
    })
    setCurrentStep(previousStep);
    props.changeSocialError("");
  }

  useEffect(() => {
    async function gp() {
      const gapi = await import("gapi-script");
      gapi.load("client:auth2", () => {
        gapi.client.init({ clientId: GOOGLE_CLIENT_ID, plugin_name: "email" });
      });
    }
    if (!isADDA) {
      gp();
    }
  }, []);

  let targetEvent = null;
  useEffect(() => {
    try {
      targetEvent = window.addEventListener("keydown", (event) => {
        if (event.keyCode == 27) {
          closeModal();
        }
      });
      return () => {
        window.removeEventListener("keydown", targetEvent);
        document.body.classList.remove("noscroll");
      };
    } catch (ex) {}
  }, []);

  useEffect(() => {
    setLoginPanelErrors({...loginPanelErrors, social : props?.socialErrors});
  },[props.socialErrors]);

  useMemo(() => {
    const stepToPreviousStepMapping = {
      "signup": "",
      "email-signin": "signup",
      "mobile-otp-verify": "signup",
      "forgot-password-email-collect": "email-signin",
      "create-password": "email-signin",
      "user-details-collection": ""
    };
    
    setPreviousStep(stepToPreviousStepMapping[currentStep] || "");
  }, [currentStep]);

  return (
    <div
      data-testid="login-modal"
      footer={null}
      className={" main-login-wrap onboard-modal-wrap"}
    >
      <div className="main-login-content-wrap">
        <div className="onboard-modal-header">
        {previousStep && <div className="leftbackbtn" onClick={() => {handleBackBtnClick()}}><img src="/images/onboard-back-pop.svg" /></div>}
        {(currentStep === onBoardingStepStates.mobile_otp_verify) && <div className="help-btn" onClick={() => {
          hitMoengageForOnboarding("onboarding_screen", "Onboarding_Help", {platform:'web'})
          window.open("/onboarding-help", "_blank")
          }}>Help</div>}
        <div className="right-cross-icon" onClick={closeModal}>
          <img src="/images/onboard-close-pop.svg" />
        </div>

        </div>
       
        {(currentStep === onBoardingStepStates.signup) && <div className="central-heading">Welcome to <span className="header-brand-name">{pageName[1]}</span></div>}
        <div className="panel-wrapper">
          {/* Need to insert panels inside this */}
        </div>
        <div className="onboard-content-insider">
        {(getDeviceType() === "m" && currentStep === onBoardingStepStates.signup || getDeviceType() === "d") ? <LeftPanelLoginModal /> : ""}
        <RightPanelLoginModal 
          // States
          showSignupForm={props.showSignupForm}
          showPassError={props.showPassError}
          showPasswordError={props.showPasswordError}
          loginPanelErrors={loginPanelErrors}
          setLoginPanelErrors={setLoginPanelErrors}
          showPassword={showPassword} 
          setShowPassword={setShowPassword}

          // Functions
          closeModal={closeModal}
          handleUserLogin={handleUserLogin}
          handleEmailOnBlur={props.handleEmailOnBlur}
          handlePasswordOnBlur={props.handlePasswordOnBlur}
          handlePasswordOnFocus={props.handlePasswordOnFocus}
          responseGoogleSuccess={props.responseGoogleSuccess}
          responseGoogleFail={props.responseGoogleFail}
          responseFacebook={props.responseFacebook}
          handleUpdateUserCookie={handleUpdateUserCookie}
          changeSocialError={loginPanelErrors?.social}

          // Navigation States
          currentStep={currentStep} 
          setCurrentStep={setCurrentStep}
        />
        </div>
      </div>
    </div>
  );
};

LoginModalNew.fetching = ({ dispatch }) => {
  return [
    dispatch(loginFromCookie()),
  ];
};

const mapStateToProps = (state) => ({
  userCourse: state?.home?.userCourse,
})

const mapDispatchToProps = {
  loginFromCookie,
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(LoginModalNew));

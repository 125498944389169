import { isADDA } from "../../../data/utils/helpers";

const facetsLabelConstants = {
  faculties: "Faculty Name",
  categories: "Content Type",
  examTypes: "Exam Name",
  languages: "Language",
  packages: "Packages",
  subParent: "Subjects"
};

const facetsSearchKeyConstants = {
  faculties: "facultyFacets",
  categories: "categoryFacets",
  examTypes: "examTypesFacets",
  languages: "langFacets",
  packages: "packageFacets",
  subParent: "subParentFacets"
};
const initialFilterKeyConstants = {
  searchTerm: "",
  facultyFacets: "",
  categoryFacets: "",
  examTypesFacets: "",
  langFacets: "",
  packageFacets: "",
};

const disAllowedPPCFacets = isADDA ? [] :['packages','examTypes','languages', 'categories']

export {
  facetsLabelConstants,
  facetsSearchKeyConstants,
  initialFilterKeyConstants,
  disAllowedPPCFacets
};

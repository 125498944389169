import React, { useState, useEffect, useRef } from "react";
import {
  freshDeskTicketCreate,
  FreshDeskAPIKey,
  leadSquaredUrl,
  pageName,
} from "../../../constants/appConfig";
import fetch_url from "../../data/utils/fetch";
import { Cookies } from "react-cookie";
import Axios from "axios";
import { isADDA } from "../../data/utils/helpers";

const SupportFormService = (props) => {
  const [supportDataLength, setSupportDataLength] = useState(0);
  const [userName, setUserName] = useState(props.name ? props.name : "");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState(props.email ? props.email : "");
  const [textareaVal, setTextareaVal] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [userNameErr, setuserNameErr] = useState(false);
  const [mobileNumberErr, setMobileNumberErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [success, setSuccess] = useState(false);

  const [ExamCategoryList, setExamCategoryList] = useState([]);
  const [ExamList, setExamList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [issueList, setissueList] = useState([]);
  const [SelectedExamCategory, setSelectedExamCategory] = useState("");
  const [SelectedExam, setSelectedExam] = useState("");
  const [Selectedproduct, setSelectedPro] = useState("");
  const [SelectedIssue, setSelectedIssue] = useState("");
  let [DisabledExam, setDisabledExam] = useState(true);
  let [DisabledProduct, setDisabledProduct] = useState(true);
  let [DisabledIssue, setDisabledIssue] = useState(true);
  let disAllowUpload = ['Wants to purchase/course information','Request a Sales Call']

  useEffect(() => {
    if (props?.supportData) {
      setSelectedExamCategory(props.supportData.examCategory);
      setSelectedExam(props.supportData.exam);
      setproductList(governmentJobExams?.[0]?.product_category);
      setSelectedPro(props.supportData.product);
      setissueList(purchase_issues.issue_category)
      setSelectedIssue(props.supportData.issue);
      setSupportDataLength(Object.keys(props?.supportData)?.length);
    }
  }, [props?.supportData]);

  // Products

  const live_class_issues = {
    product: "Live Class",
    issue_category: isADDA ? [
      {
        issue: "Wants to purchase/course information",
      },
      {
        issue: "Unable to Find Live Class",
      },
      {
        issue: "Recorded Session not uploaded",
      },
      {
        issue: "Live class PDF missing",
      },
      {
        issue: "Other",
      },
    ]: 
    [
      {
        issue: "Wants to purchase product",
      },
      {
        issue: "Live class is not available",
      },
      {
        issue: "Recorded session not available",
      },
      {
        issue: "Other",
      }
    ]
  }

  const test_series_issues = {
    product: "Test Series",
    issue_category: isADDA ? [
      {
        issue: "Mock Missing / not uploaded",
      },
      {
        issue: "Video Solution",
      },
      {
        issue: "Error in Question / Answer",
      },
      {
        issue: "Request a Sales Call",
      },
      {
        issue: "Other",
      },
    ]:
    [
      {
        issue: "Mock is missing",
      },
      {
        issue: "Error in question/answer",
      },
      {
        issue: "Other",
      },
    ]
  }

  const ebooks_issues = {
    product: "E-books",
    issue_category: isADDA ? [
      {
        issue: "Wants to purchase/course information",
      },
      {
        issue: "Ebooks not uploaded",
      },
      {
        issue: "Upload plan missing/Not followed",
      },
      {
        issue: "Ebook not uploaded",
      },
      {
        issue: "Other",
      },
    ]:
    [
      {
        issue: "DPP is not available",
      },
      {
        issue: "File is not opening",
      },
      {
        issue: "Other",
      },
    ]
  }

  const video_course_issues = {
    product: "Video Course",
    issue_category: isADDA ? [
      {
        issue: "Wants to purchase/course information",
      },
      {
        issue: "Incomplete Video course",
      },
      {
        issue: "Uploading delay (as per uploading plan)",
      },
      {
        issue: "Other",
      },
    ]:
    [
      {
        issue: "Video is missing ",
      },
      {
        issue: "Issue in video",
      },
      {
        issue: "Other",
      },
    ]
  }

  const books_issues = {
    product: "Books",
    issue_category: [
      {
        issue: "Wants to purchase/course information",
      },
      {
        issue: "Need tracking details",
      },
      {
        issue: "Delay In delivery / Book Not Delivered",
      },
      {
        issue: "Defective / Missing Book",
      },
      {
        issue: "Other",
      },
    ],
  };

  const purchase_issues = {
    product: "Purchase Related",
    issue_category: [
      {
        issue: "Request a Sales Call",
      },
    ],
  };

  const general_issues = {
    product: "General Issues",
    issue_category: [
      {
        issue: "Password Change",
      },
      {
        issue: "Email Change",
      },
      {
        issue: "Subject Related Doubt",
      },
      {
        issue: "Other",
      },
    ],
  };

  // Exam Categories

  const skillDevelopmentExams = [
    {
      exam: "Skill Development",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,

      ],
    }
  ];

  const governmentJobExams = [
    {
      exam: "Banking",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "JAIIB & CAIIB",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "SSC",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Railways",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Teaching",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "UPSC",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Defence",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Agriculture",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "FCI",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "UGC",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Regulatory Bodies & SO",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ];

  const stateExams = [
    {
      exam: "Bihar",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Punjab",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "West Bengal",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Odisha",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Kerala",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Uttar Pradesh",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "AP & Telangana",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Tamil Nadu",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Maharashtra",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "North East States",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Rajasthan",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Gujrat",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Madhya Pradesh",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Haryana",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Chhattisgarh",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Uttarakhand",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Jharkhand",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ];

  const gateAndEnggExams = [
    {
      exam: "Civil Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Mechanical Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Electrical Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Electronics Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Computer Science Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Instrumentation Engineering",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ];

  const undergradEntranceExams = [
    {
      exam: "JEE",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "CUET",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Law Entrances",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "IPM Entrances Exam",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "NEET",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Defence",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ];

  const schoolK12Exams = [
    {
      exam: "Class 12",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Class 11",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Class 10",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Class 9",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ]

  const IITJEE = [
    {
      exam: "Class 11",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Class 12",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Dropper",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ]
  const NEET = [
    {
      exam: "Class 11",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Class 12",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
    {
      exam: "Dropper",
      product_category: [
        live_class_issues,
        test_series_issues,
        ebooks_issues,
        video_course_issues,
        books_issues,
        purchase_issues,
        general_issues,
      ],
    },
  ]

  const cookie = new Cookies();
  useEffect(() => {
    if(isADDA) {
      setExamCategoryList([
        {
          examCategoryName: 'Government Job Exams',
          examCategoryList: governmentJobExams,
        },
        {
          examCategoryName: 'State Exams',
          examCategoryList: stateExams,
        },
        {
          examCategoryName: 'Gate & Engg Exams',
          examCategoryList: gateAndEnggExams,
        },
        {
          examCategoryName: 'Undergrad Entrance Exams',
          examCategoryList: undergradEntranceExams,
        },
        {
          examCategoryName: 'School (K-12)',
          examCategoryList: schoolK12Exams,
        },
        {
          examCategoryName: 'Skill Development',
          examCategoryList: skillDevelopmentExams,

        },
      ])
    }
    else {
      setExamCategoryList([
        {
          examCategoryName: 'IIT JEE',
          examCategoryList: IITJEE,
        },
        {
          examCategoryName: 'NEET',
          examCategoryList: NEET,
        },
      ])
    }
  }, []);

  const fileRef = useRef();
  const txtRef = useRef();
  const isValid = () => {
    let returnVal = true;
    if (userName.split(" ").join("") == "") {
      setuserNameErr(true);
      returnVal = false;
    } else if (mobileNumber.split(" ").join("") == "" || isNaN(mobileNumber)) {
      setMobileNumberErr(true);
      returnVal = false;
    } else if (email.split(" ").join("") == "") {
      setEmailErr(true);
      returnVal = false;
    }
    return returnVal;
  };

  const submitServiceForm = (evt) => {
    evt.preventDefault();
    if (!isValid()) {
      return;
    }
    props.setLoading(true);
    let fileData = {};
    fileData.name = userName;
    fileData.email = email;
    let leadSquaredFlag = false;
    if (
      SelectedIssue === "Wants to purchase/course information" ||
      SelectedIssue === "Request a Sales Call"
    ) {
      leadSquaredFlag = true;
      fileData.phone = mobileNumber;
      fileData.mx_Priority = SelectedExamCategory;
      fileData.mx_Interested_Course = SelectedExam;
      fileData.mx_NewTitle = txtRef.current.value;
    } else {
      fileData.description = txtRef.current.value;
      fileData.subject = `Request-${SelectedIssue}-${Selectedproduct}-${SelectedExam}-${SelectedExamCategory}`;
    }
    if (selectedFile) {
      uploadAttachment(fileData, leadSquaredFlag);
    } else {
      postFormData(fileData, leadSquaredFlag);
    }
  };

  const postFormData = (formData2, leadSquaredFlag) => {
    setSuccess(false);
    if (!isADDA) {
      formData2.tags = ["Sankalp Bharat"];
      formData2.custom_fields = { cf_source: "Sankalp Bharat" };
    }
    fetch(leadSquaredFlag ? `${leadSquaredUrl}&action=CreateOrUpdateLead` : freshDeskTicketCreate, {
      method: "POST",
      body: JSON.stringify(formData2),
      headers: getHeaders(null, leadSquaredFlag),
    })
      .then((res, err) => {
        setInitialState();
      })
      .catch((ex) => {
        setSuccess(true);
        props.setLoading(false);
      });
  };

  const uploadAttachment = (postData, leadSquaredFlag) => {
    const formData = new FormData();
    for (const key in postData) {
      formData.append(key, postData[key]);
    }
    if (!isADDA) {
      formData.append('tags[]', "Sankalp Bharat");
      formData.append('custom_fields[cf_source]', "Sankalp Bharat");
    }
    formData.append(
      "attachments[]",
      fileRef.current.files[0]
    );

    setSuccess(false);
    fetch(leadSquaredFlag ? leadSquaredUrl : freshDeskTicketCreate, {
      method: "POST",
      body: formData,
      headers: getHeaders(true, leadSquaredFlag),
    })
      .then(res => res.json())
      .then((res) => {
        setInitialState()
      })
      .catch((ex) => {
        setSuccess(true);
        props.setLoading(false);
      });

  };

  const getHeaders = (attachment = null, leadSquaredFlag) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${leadSquaredFlag ? cookie.get("cp_token") : FreshDeskAPIKey}`);
    if (!attachment) {
      myHeaders.append("Content-Type", "application/json");
    }
    return myHeaders;
  };

  const setInitialState = () => {
    setSuccess(true);
        props.setLoading(false);
        txtRef.current.value = "";
        fileRef.current.value = "";
        setSelectedFile(null);
        setUserName(props.name ? props.name : "");
        setEmail(props.email ? props.email : "");
        setMobileNumber("");
        setSelectedExamCategory("");
        setSelectedExam("");
        setSelectedPro("");
        setSelectedIssue("");
        setSupportDataLength(0);
  }

  const ChangeExamCategory = (event) => {
    setSelectedExamCategory(event.target.value);
    setExamList(
      ExamCategoryList.find(
        (cat) => cat.examCategoryName === event.target.value
      ).examCategoryList
    );
    setSelectedExam("Select Exam");
    setSelectedPro("Select Product");
    setSelectedIssue("Select Issue");
    setissueList([]);
    setDisabledExam(false);
    setDisabledProduct(false);
    setDisabledIssue(false);
  };
  const ChangeExam = (event) => {
    setSelectedExam(event.target.value);
    setproductList(
      ExamList.find((exm) => exm.exam === event.target.value).product_category
    );
    setSelectedPro("Select Product");
    setSelectedIssue("Select Issue");
    setissueList([]);
    setDisabledExam(true);
    setDisabledProduct(false);
    setDisabledIssue(false);
  };
  const ChangeProduct = (event) => {
    setSelectedPro(event.target.value);
    const products = ExamList.find(
      (exms) => exms.exam === SelectedExam
    ).product_category;
    setissueList(
      products.find((pro) => pro.product === event.target.value).issue_category
    );
    setSelectedIssue("Select Issue");
    setDisabledProduct(true);
    setDisabledIssue(false);
  };
  const ChangeIssue = (event) => {
    setSelectedIssue(event.target.value);
    setDisabledIssue(true);
  };

  const handleChangeProduct = (event) => {
    setSelectedPro(event.target.value);
    setissueList(
      productList?.find((pro) => pro?.product === event.target.value)?.issue_category
    );
    setSelectedIssue("Select Issue");
    setDisabledProduct(true);
    setDisabledIssue(false);
  };

  return (
    <div className={"form-container"}>
      <form
        className="service-related-form"
        name="userDetail"
        onSubmit={submitServiceForm}
      >
        <div className="service-form-header">Service Related</div>
        <div className="form-main-container support-form-container">
          <span>
            <input
              type="text"
              placeholder="Your Name"
              className="input-support"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            {userNameErr && <div className="errormsg">Please enter name</div>}
          </span>
          <span>
            <input
              type="text"
              placeholder="Mobile"
              className="input-support"
              maxLength="10"
              minLength="10"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
            {mobileNumberErr && (
              <div className="errormsg">
                Please enter 10 digit mobile number
              </div>
            )}
          </span>
          <span style={{ paddingRight: "0px" }}>
            <input
              type="email"
              placeholder="Email"
              className="input-support"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {emailErr && (
              <div className="errormsg">Please enter valid email</div>
            )}
          </span>
          {supportDataLength > 0 ? (
            <div className="Dropdowns">
              <div className="select">
                <input
                  type="text"
                  placeholder="Exam Category"
                  value={SelectedExamCategory}
                  disabled={true}            
                  required
                />
              </div>
              <div className="select">
                <input
                  type="text"
                  placeholder="Exam"
                  value={SelectedExam}
                  disabled={true}     
                  required
                />
              </div>

              <div className="select">
                <select
                  placeholder="Product"
                  value={Selectedproduct}
                  onChange={handleChangeProduct}
                  required
                >
                  <option value="" disabled={DisabledProduct}>
                    Select Product
                  </option>
                  {productList.map((e, key) => {
                    return <option key={key}>{e.product}</option>;
                  })}
                </select>
              </div>
              <div className="select">
                <select
                  placeholder="Issue"
                  value={SelectedIssue}
                  onChange={ChangeIssue}
                  required
                >
                  <option value="" disabled={DisabledIssue}>
                    Select Issue
                  </option>
                  {issueList.map((e, key) => {
                    return <option key={key}>{e.issue}</option>;
                  })}
                </select>
              </div>
            </div>
          ) : (
            <div className="Dropdowns">
              <div className="select">
                <select
                  placeholder="Exam Category"
                  value={SelectedExamCategory}
                  onChange={ChangeExamCategory}
                  required
                >
                  <option value="" disabled={true}>
                    Select Exam Category
                  </option>
                  {ExamCategoryList.map((cat, ind) => {
                    return (
                      <option key={cat.examCategoryName}>
                        {cat.examCategoryName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="select">
                <select
                  placeholder="Exam"
                  value={SelectedExam}
                  onChange={ChangeExam}
                  required
                >
                  <option value="" disabled={DisabledExam ? true : null}>
                    Select Exam
                  </option>
                  {ExamList.map((e, key) => {
                    return <option key={key}>{e.exam}</option>;
                  })}
                </select>
              </div>

              <div className="select">
                <select
                  placeholder="Product"
                  value={Selectedproduct}
                  onChange={ChangeProduct}
                  required
                >
                  <option value="" disabled={DisabledProduct ? true : null}>
                    Select Product
                  </option>
                  {productList.map((e, key) => {
                    return <option key={key}>{e.product}</option>;
                  })}
                </select>
              </div>
              <div className="select">
                <select
                  placeholder="Issue"
                  value={SelectedIssue}
                  onChange={ChangeIssue}
                  required
                >
                  <option value="" disabled={DisabledIssue ? true : null}>
                    Select Issue
                  </option>
                  {issueList.map((e, key) => {
                    return <option key={key}>{e.issue}</option>;
                  })}
                </select>
              </div>
            </div>
          )}

          <textarea
            placeholder="Describe your concern"
            minLength="100"
            required
            className="input-textarea"
            onChange={(e) => e.target.value}
            ref={txtRef}
          ></textarea>
          {!disAllowUpload?.includes(SelectedIssue) && (<label
            for="myfile"
            className="fileuploadlabel"
            style={{
              display: "inline-block",
              float: "left",
              marginTop: "10px",
            }}
          >
            <img src={`https://storecdn.${pageName[0]}.com/upload.svg`} />
            Upload file
            <span className="filename"></span>
          </label>)}
          {selectedFile && selectedFile.name ? (
            <div className="filename">{selectedFile.name}</div>
          ) : null}
          <input
            type="file"
            style={{ visibility: "hidden", margin: 0 }}
            id="myfile"
            name="myfile"
            onChange={(e) => {
              setSelectedFile(e.target.files[0]);
            }}
            ref={fileRef}
          />

          <div className="clearfix"></div>

          <button
            type="submit"
            className="button form-submit-support"
            style={{ fontSize: "14px !important" }}
          >
            SUBMIT
          </button>

          {success && (
            <span className="successmsg">
              Request submitted successfully!!!
            </span>
          )}
        </div>
      </form>
    </div>
  );
};
export default SupportFormService;

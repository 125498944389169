const SET_PAGE_NO = 'SET_PAGE_NO';
const SET_RANK_TYPE = 'SET_RANK_TYPE';
const SET_RANK = 'SET_RANK';
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const GET_COLLEGE_LIST = 'GET_COLLEGE_LIST';

export default {
    SET_PAGE_NO,
    SET_RANK_TYPE,
    SET_RANK,
    UPDATE_FORM_DATA,
    GET_COLLEGE_LIST
}
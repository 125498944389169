import React, { useEffect, useState } from "react";

const FAQSection = ({ faqJson }) => {
  //  when data coming from API
  const [faqs, setFaqs] = useState([]);
  const handleToggle = (index) => {
    setFaqs(
      faqs?.map((faq, i) =>
        i === index ? { ...faq, expanded: !faq.expanded } : faq
      )
    );
  };
  useEffect(() => {
    if (faqJson?.length) {
      setFaqs(
        faqJson
          ?.filter((f) => f?.qN?.length && f?.aN?.length)
          ?.map((faq) => ({
            question: faq?.qN,
            answer: faq?.aN,
            expanded: false,
          }))
      );
    }
  }, [faqJson]);

  const handleExpandAll = (e) => {
    e.preventDefault();
    const allExpanded = faqs?.every((faq) => faq?.expanded);
    if (allExpanded) {
      setFaqs(faqs?.map((faq) => ({ ...faq, expanded: false })));
    } else {
      setFaqs(faqs?.map((faq) => ({ ...faq, expanded: true })));
    }
  };

  return (
    <section className="pdpFaqWrap" id="FAQs">
      <div className="pdp-container">
        <div className="pdpFaqHeading">
          <h3 className="pdpSimpleHeading">Frequently Asked Questions</h3>
          <div className="expendAllReview">
            <a href="#" onClick={(e) => handleExpandAll(e)}>
              {faqs?.every((faq) => faq?.expanded)
                ? "Collapse all questions"
                : "Expand all questions"}
            </a>
          </div>
          <div className="expendAllReview  expendmweb">
            <a href="#" onClick={(e) => handleExpandAll(e)}>
              {faqs?.every((faq) => faq?.expanded)
                ? "Collapse all"
                : "Expand all"}
            </a>
          </div>
        </div>

        <div className="pdpFaqList">
          <ul>
            {faqs?.map((faq, index) => (
              <li key={index} className={faq?.expanded ? "pdpFaqActive" : ""}>
                <div
                  className="pdpFaqHeader"
                  onClick={() => handleToggle(index)}
                >
                  {`${index + 1}. `}
                  {faq?.question}
                  <span
                    className={
                      faq?.expanded ? "faquparrow expanded" : "faquparrow"
                    }
                  ></span>
                </div>
                {faq?.expanded && (
                  <div className="pdpFaqContent">
                    <p>{faq?.answer}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;

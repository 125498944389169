import React from "react";
import PropTypes from "prop-types";
import TestAnalysisHeader from "./Components/TestAnalysisHeader";
const TestResultLayout = ({
  testTitle = "",
  level = "",
  dateAttempted = <></>,
  rightAction,
  selectedSideTab,
  onClickSideTab,
  sideBarTabs,
  children,
  isFixedMock = false,
  liveTestCategory = null,
}) => {
  return (
    <div className="resultAnalysisWrapper">
      <TestAnalysisHeader />
      <div className="fuild-container">
        <section className="sidebar-content-wrap">
          <section className="analysis-sidebar">
            <div className="sidebarMenuWrap">
              <ul>
                {sideBarTabs.map((tab) => (
                  <li
                    key={tab}
                    className={selectedSideTab === tab ? "activeMenu" : ""}
                    onClick={() => onClickSideTab(tab)}
                  >
                    <span>{isFixedMock ? "Overview" : tab}</span>
                    {/* show Overview for fixedMock as side tab */}
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <div className="contentWrap">
            <section className="content-heading-wrap">
              <div className="contentHeading">
                <div className="contentExamName">
                  {testTitle}
                  {level && <span className="mediamName">{level}</span>}
                  {liveTestCategory && <span className="mediamName">{liveTestCategory === 2 ? "Full Length Test" : "Sectional Test"}</span>}
                </div>
                <div className="atemptDateWrap">{dateAttempted}</div>
              </div>
              {rightAction}
            </section>

            {children}
          </div>
        </section>
      </div>
    </div>
  );
};

TestResultLayout.propTypes = {
  testTitle: PropTypes.string,
  level: PropTypes.string,
  dateAttempted: PropTypes.element,
  selectedSideTab: PropTypes.string,
  onClickSideTab: PropTypes.func,
  sideBarTabs: PropTypes.array,
  children: PropTypes.element.isRequired,
};
export default TestResultLayout;

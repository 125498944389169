import React from "react";
import { Link } from "react-router-dom";
import { productKeys } from "../../../../constants/appConstants";
import {
  isEmpty,
  removeHyphensAndInsertSpaces,
  trackMoengageEvent,
} from "../../../data/utils/helpers";

export const SeoSubCategory = (props) => {
  return (
    <div className="seo-block-con">
      {props.data &&
        props.data.length >= 1 &&
        props.data.map((value) => {
          let categoryName = value.suggestionCategory;
          return (
            <Link
              onClick={(e) => trackEvent(e)}
              className="list"
              to={
                !props.isProductCategory
                  ? `/${(props.currentItem || "").toLowerCase()}/${
                      productKeys[categoryName]
                    }-${props.courseDetail ? "study-kit" : "kit"}`
                  : `/${value.url}`
              }
            >
              <span>
                {" "}
                {!props.isProductCategory
                  ? `${
                      props.prefixValue
                        ? `${removeHyphensAndInsertSpaces(
                            props.prefixValue.toUpperCase()
                          )}  ${
                            value.suggestionCategory == "Publications"
                              ? "Books"
                              : value.suggestionCategory
                          }`
                        : value.suggestionCategory
                    } `
                  : `${
                      props.prefixValue
                        ? ` ${removeHyphensAndInsertSpaces(
                            props.prefixValue.toUpperCase()
                          )} ${value.name}`
                        : removeHyphensAndInsertSpaces(value.name)
                    }  `}
              </span>
            </Link>
          );
        })}
    </div>
  );
};

const trackEvent = (e) => {
  let key = e.target.innerHTML;
  let payload = {};
  payload.Link_text = key;
  payload.Link_URL = e.target.getAttribute("href");
  payload.Link_type = "SEO link - Subcategory";
  trackMoengageEvent("SEO_Link_Clicked", payload);
};

export const SeoSubCategoryWeb = (props) => {
  const course = typeof sessionStorage !== "undefined" ? sessionStorage.getItem("ExamCategoryMappingId")?.toUpperCase(): "";
  return (
    <>
      {props?.getFilterPagesData[0]?.suggestionCategory && (
        <div className="related-category-container">
          <div className="seo-exam-category ">
            {props.heading ||
              (props.isProductListing ? "Exam Category" : "Sub Category")}
          </div>

          {!isEmpty(props.getFilterPagesData) &&
            props.getFilterPagesData.map((value) => {
              let categoryName = value.suggestionCategory;
              return (
                <Link
                  onClick={(e) => trackEvent(e)}
                  className="related-link"
                  to={
                    !props.isProductCategory
                      ? `/${(props.currentItem || "").toLowerCase()}/${
                          productKeys[categoryName]
                        }-${props.courseDetail ? "study-kit" : "kit" + `${course ? "?coursesFacets=" + course: ""}`}`
                      : `/${value.url}`
                  }
                >
                  {!props.isProductCategory
                    ? `${
                        props.prefixValue
                          ? `${removeHyphensAndInsertSpaces(
                              props.prefixValue.toUpperCase()
                            )}  ${
                              value.suggestionCategory == "Publications"
                                ? "Books"
                                : value.suggestionCategory
                            }`
                          : value.suggestionCategory == "Publications"
                          ? "Books"
                          : value.suggestionCategory
                      } `
                    : `${
                        props.prefixValue
                          ? ` ${removeHyphensAndInsertSpaces(
                              props.prefixValue.toUpperCase()
                            )} ${value.name}`
                          : removeHyphensAndInsertSpaces(value.name)
                      }  `}
                </Link>
              );
            })}
        </div>
      )}
    </>
  );
};

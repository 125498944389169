import React from "react";
import { connect } from "react-redux";

const TrendingSuggestions = ({ trendingSearch, onClick }) => {
  return (
    <>
      <ul>
        {trendingSearch?.map((item) => (
          <li key={item?.key}  onClick={() => onClick(item?.key)}>
            <a style={{cursor: "pointer"}}>
              {item?.key}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

function mapStateToProps(state) {
  let { trendingSearch } = state?.header?.allTrendingData?.data;
  return {
    trendingSearch,
  };
}
export default connect(mapStateToProps, null)(TrendingSuggestions);

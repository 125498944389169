import React, { useState, useRef, useEffect } from "react";
import { VIDEO_INFO, pageName } from "../../../constants/appConfig";
import { useWindowDimensions } from "../utils";

const LearnWithAdda = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const windowSize = useWindowDimensions();
  const videoRef = useRef();

  //reset the video when the slide changes
  const videoSrcHandler = (currenSlide) => {
    if (windowSize.width >= 720 && videoRef.current) {
      // change Video current time to 0
      videoRef.current.currentTime = 0;
      // reload the video source
      videoRef.current.load();
    }
  };

  useEffect(() => {
    videoSrcHandler();
  }, [activeSlide]);
  const slideTabs = [
    {
      heading: "Choose your exam",
      description:
        "Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims",
    },
    {
      heading: "Select your desired course",
      description:
        "Find a course that contains your desired products and suits your needs",
    },
    {
      heading: "Make the purchase",
      description:
        "Buy the course in 3 quick steps and pay using any medium you prefer",
    },
    {
      heading: "Navigate to My Purchased Courses",
      description: "Access all your content from your purchased course here",
    },
    {
      heading: "Choose the course you want to study",
      description: `Download the ${pageName[1]} app for a 360˚ learning experience`,
    },
  ];

  // show the section when the window size is greater than 720px
  return windowSize.width >= 720 ? (
    <section className="why-adda">
      <div className="container">
        <div className="common-headding">
          How to learn with <span>{pageName[1]}?</span>
        </div>
        <div className="why-adda-wrapper">
          <div className="course-story-body tabs">
            {slideTabs.map((slide, index) => (
              <div
                key={`ind__${index}`}
                style={{ cursor: "pointer" }}
                className={`course-story-box tab-link ${activeSlide == index && "active"
                  }`}
                data-tab="0"
                id={`slide-${index}`}
                onClick={() => setActiveSlide(index)}
              >
                <div className="course-stody-heading">{slide.heading}</div>
                <div className="course-story-phera">{slide.description}</div>
              </div>
            ))}
          </div>
          {/* <!--Right side Video Visual--> */}
          <div className="video-visual">
            <div className="tab-content active">
              <video
                ref={videoRef}
                autoPlay={true}
                controls={false}
                muted
                style={{ width: "100%" }}
                onEnded={() => {
                  if (activeSlide == VIDEO_INFO.length - 1) {
                    //it means last video
                    setActiveSlide(0);
                  } else {
                    setActiveSlide((prev) => prev + 1);
                  }
                }}
              >
                <source
                  src={VIDEO_INFO[parseInt(activeSlide)].src}
                  type={VIDEO_INFO[parseInt(activeSlide)].type}
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};
export default LearnWithAdda;
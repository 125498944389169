import reducerRegistry from '../../reducerRegistry';

function ebooks(
    state = {},
    action
) {
    switch (action.type) {
    
    case 'FETCH_USER_EBOOK': {
        const newData = action.payload.data;
        return {
            ...state,
            ebooksList: newData,
        };
    }

    case 'FETCH_VIEW_EBOOK': {
        const ebooksData = action.payload.data;
        return {
            ...state,
            viewEbooksList: ebooksData,
        };
    }

    default:
        return state;
    }
}

reducerRegistry.register('ebooks', ebooks);
export default ebooks;
import React, { useState, useEffect } from "react";
import { moengageEvent } from "../utils";
import { getMoengageMetadata } from "../../data/utils/helpers";

const AExpansionPanel = ({ title, content, variant, vCount, cCount, open ,isSubject}) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  useEffect(() => {
    // bcoz redesign
    setShow(open || false);
  }, [open]);

  const moengageEvent=()=>{
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      'Subject_Clicked',
      getMoengageMetadata({
        product_type: "Videos",
        section_type: "All",
        subject:title
      }),
    );
  }

  return (
    <div className={`a-expansion-panel `}>
      <div className={`aep-title ${variant ? "light" : ""}`} onClick={()=>{ 
        toggle()
        isSubject ? moengageEvent() :null
      }
       }>
        <div className="aep-title-text">{`${title} (${
          vCount ? vCount : cCount
        })`}</div>
        <div className="aep-title-icon">
          <div className={show ? "chevronDown" : "chevronUp"}></div>
        </div>
      </div>
      {
        <div
          className={`aep-content ${variant ? "light" : ""}`}
          style={{ display: `${show ? "" : "none"}` }}
        >
          {content}
        </div>
      }
    </div>
  );
};

export default AExpansionPanel;

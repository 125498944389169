const courseIncludeSubcategories = [
    { category: 'ONLINE_LIVE_CLASSES', image: '/images/pdpincludeicon1.svg', id:'liveClasses' },
    { category: 'TEST_SERIES', image: '/images/pdpincludeicon2.svg', id:'testPkgs'},
    { category: 'VIDEOS', image: '/images/pdpincludeicon3.svg', id:'videoPkgs'},
    { category: 'EBOOKS', image: '/images/pdpincludeicon4.svg', id:'ebooksPkgs'},
    {category: 'BOOKS', image: '/images/pdpincludeicon5.svg', id:'bookPkgs'}
];

const packageContentSubcategories = [
    { category: 'ONLINE_LIVE_CLASSES', image: '/images/pdpLiveClas.svg', id:'liveClasses' },
    { category: 'TEST_SERIES', image: '/images/pdpTestSeries.svg', id:'testPkgs' },
    { category: 'EBOOKS', image: '/images/pdpEbooks.svg', id:'ebooksPkgs' },
    { category: 'VIDEOS', image: '/images/pdpVideos.svg', id:'videoPkgs' },
    { category: "BOOKS", image: "/images/pdpBooks.svg", id:'bookPkgs' },
];

const contacts = {
    "BANKING" : "080-35358375",
    "JAIIB_CAIIB" : "080-37834944",
    "SSC" : "080-49232873",
    "RAILWAYS" : "080-49232873",
    "CTET" : "080-49232871",
    "UPSC" : "080-35358472",
    "DEFENCE" : "080-49232874",
    "BIHAR" : "080-35358480",
    "PUNJAB_STATE_EXAMS" : "080-37834950",
    "WEST_BENGAL" : "080-35358477",
    "ODISHA_STATE_EXAMS" : "080-35358475",
    "KERALA" : "080-35358478",
    "UTTAR_PRADESH" : "080-35358481",
    "ANDHRA_PRADESH" : "080-35358479",
    "TAMIL_NADU" : "080-35358476",
    "MAHARASHTRA" : "080-37834945",
    "NORTH_EAST_STATE_EXAMS" : "080-37834953",
    "RAJASTHAN" : "080-37834951",
    "GUJARAT" : "080-37834952",
    "MADHYA_PRADESH" : "080-37834951",
    "HARYANA" : "080-37834950",
    "CHHATTISGARH" : "080-37834951",
    "UTTARAKHAND" : "080-35358481",
    "JHARKHAND" : "080-35358480",
    "ENGINEERING" : "080-35358376",
    "GATE_CIVIL_ENGINEERING" : "080-37834943",
    "IITNEET" : "079-68217025",
    "NEET-ENTRANCE-EXAM" : "079-68217025",
    "CLASS_12" : "080-35358482",
    "CLASS_11" : "080-35358482",
    "CLASS_10" : "080-35358482",
    "CLASS_9" : "080-35358482",
    "CLASS_8" : "080-35358482",
    "CLASS_7" : "080-35358482",
    "CLASS_6" : "080-35358482",
    "CLASS_5" : "080-35358482",
    "CLASS_4" : "080-35358482",
    "CLASS_3" : "080-35358482",
    "CLASS_2" : "080-35358482",
    "CLASS_1" : "080-35358482",
    "AGRICULTURE" : "079-71418076",
    "MECHANICAL_ENGINEERING" : "080-35358376",
    "ELECTRICAL_ENGINEERING" : "080-35358376",
    "ELECTRONICS_ENGINEERING" : "080-35358376",
    "COMPUTER_SCIENCE_ENGINEERING" : "080-35358376",
    "INSTRUMENTATION_ENGINEERING" : "080-35358376",
    "CHEMICAL_ENGINEERING" : "080-35358376",
    "CUET" : "080-35358482",
    "CUCET" : "080-35358482",
    "LAW" : "079-68217042",
    "IPM" : "080-35358482",
    "OTHERUGEXAMS" : "",
    "HOTELMANAGEMENT" : "",
    "DESIGNAPTITUTE" : "",
    "GUJRAT" : "080-37834952",
    "NEET" : "079-68217025",
    "NDA" : "080-49232874",
    "UG_DEFENCE" : "079-68217085",
    "FCI" : "079-71418076",
    "UGC_NET" : "080-49232871",
    "REGULATORY_BODIES" : "080-35358375",
    "GATE_MECHANICAL_ENGINEERING" : "080-35358376",
    "GATE_ELECTRICAL_ENGINEERING" : "080-35358376",
    "GATE_ELECTRONICS_ENGINEERING" : "080-35358376",
    "GATE_INSTRUMENTATION_ENGINEERING" : "080-35358376",
    "GATE_COMPUTER_SCIENCE_ENGINEERING" : "080-35358376",
}

const caste = [
   {id:'GN', label: 'General'},
   {id:'EWS', label: 'EWS'},
   {id:'SC', label: 'SC'},
   {id:'ST', label: 'ST'},
   {id:'OBC', label: 'OBC'},
]


export {
    courseIncludeSubcategories,
    packageContentSubcategories,
    contacts,
    caste
};
import React from 'react';
import { createOptimizedImgUrl } from '../../../data/utils/helpers';
//import { urlencoded } from 'body-parser';

const getIconClass = (categoryId) => {

    switch (categoryId) {
        case 1:
            return 'testIconM'
        case 4:
            return 'eBookIconM'
    }
    return 'eBookIconM'
}
const InfoButtonLarge = (props) => {
    const SubCategoryMapping = {
        MODELS_3D: {label:'3D Learning',iconuri:'/images/ic_models3d_new.svg',id:'models3DPkgs'},
        ONLINE_LIVE_CLASSES:{label:'Online Live Classes',iconuri:'/images/ic_onlineliveclasses_new.svg',id:'liveClasses'},
        TEST_SERIES: {label:'Test Series',iconuri:'/images/ic_test_series_new.svg',id:'testPkgs'},
        VIDEOS: {label:'Videos',iconuri:'/images/ic_videos_new.svg',id:'videoPkgs'} ,
        EBOOKS: {label:'E-books',iconuri:'/images/ic_ebooks_new.svg',id:'ebooksPkgs'},
        BOOKS: {label:'Books',iconuri:'/images/ic_books_new.svg',id:'ebooksPkgs'}
    }
    return (
        <>
         {
            props.id == "FREE_CONTENT" ? <div  className={`infoButtonLarge lastbtninfo`} onClick={()=>props.onClick(props.id)}>
                    <div class="free-content-wrap">
                        <div class="free-strip"><span>Free</span></div>
                        <div class="free-break">
                            <div class="free-semi-circle"></div>
                            <div class="free-rect"></div>
                            <div class="free-semi-circle-inverted"></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div class="innercontent-free">
                            <div class="try-content">Try for free now !</div>
                            <div class="explore-content">Explore course content here</div>

                        </div>
                    </div>

            </div> : 
        <div className={`infoButtonLarge ${(props.label || "").toLowerCase()=='books'?'books':''}`} onClick={()=>{if(props.label!='BOOKS'){props.onClick(SubCategoryMapping[props.label].id, props.label)}}}>
           
            <div className='icon'
            >
                  <img src={SubCategoryMapping[props.label].iconuri}/>
            </div>
            <div className='contentSection'>
                <span className='count'>{props.count} {props.label=='ONLINE_LIVE_CLASSES'?'Hours':''}</span>
                <span className="label">    {SubCategoryMapping[props.label].label}</span>
            
            
            </div>
        </div>
            }
        </>
    )
}
export default InfoButtonLarge;
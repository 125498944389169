import React, { memo, useEffect } from "react";

const ScoreAnalysisPieChart = ({ ribonLabel = "", dataConfig = [] }) => {
  useEffect(() => {
    const intervals = [];

    if (dataConfig?.length) {
      dataConfig?.forEach((config) => {
        let progressSelector = document.querySelector(
          `.${config?.progressSelector}`
        );
        let progressValue = config?.progressValue;
        let progressEndValue = config?.progressEndValue;
        let speed = config?.speed;
        let colorCode = config?.gradientsColorCode;
        // when progressEndValue is 0.
        if (!progressEndValue) {
          progressSelector.style.background = `conic-gradient(
            ${colorCode[0]} ${0 * 3.6}deg,
            ${colorCode[1]} ${0 * 3.6}deg
          )`;
          return;
        }
        // don't run below code when progressEndValue is 0
        let progressInterval = setInterval(() => {
          progressValue++;
          progressSelector.style.background = `conic-gradient(
            ${colorCode[0]} ${progressValue * 3.6}deg,
            ${colorCode[1]} ${progressValue * 3.6}deg
          )`;
          if (progressValue == Math.round(progressEndValue)) {
            clearInterval(progressInterval);
          }
        }, speed);

        intervals.push(progressInterval);
      });
    }

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [dataConfig]);
  return (
    <div className="sectionalBox">
      <div className="sectionalTag">{ribonLabel}</div>
      <div className="totalRange">
        {dataConfig?.map((analysis) => (
          <div
            key={analysis?.legendClassName}
            className={`totalragetext perceragetotal ${analysis?.legendClassName}`}
          >
            <div className="totaltext-heading">{analysis?.label}</div>
            <div className="totaltext-ratio">
              {analysis?.percVal % 1
                ? Number(analysis?.percVal).toFixed(2) // fixed to two decimal after decimal point,if number is float
                : analysis?.percVal}
              %
            </div>
          </div>
        ))}
      </div>
      <div className="totalragecircle">
        <div class="Circluerouter">
          {dataConfig?.map((config) => (
            <div
              className={config?.progressSelector}
              key={config?.progressSelector}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ScoreAnalysisPieChart);

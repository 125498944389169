import React from "react";
import PropTypes from "prop-types";
import { getQuestionStatus } from "../../../../../data/utils/helpers";

const ChooseQuestions = ({
  questionList,
  userResponseObj,
  onClick,
  prevSectionsLen,
}) => {
  /** possible qType is 1,2,3,4,5,6
   * questionType representation
   * 1: MCQ
   * 2: FITB
   * 3: MCMC
   * 4: DF
   * 5: DFR
   * 6: DTB
   */

  return (
    <div className="tcq-status-list">
      <span className="tcqs-list-header">Choose a Question</span>
      {questionList?.map((ques, index) => {
        const statusClassName = ["green", "red", "grey", "purple"];
        let status = getQuestionStatus(ques, userResponseObj);
        let classname = statusClassName[status];
        if(userResponseObj?.[ques.id]?.evaluated === false) {
          classname = 'yellow';
        }
        // let isDTB = ques?.questionType == 6;
        return (
          <div
            className={`circle ${classname}`}
            // style={{ flexDirection: isDTB ? "column" : "row" }}
            onClick={() => onClick(index)}
            key={index}
          >
            {prevSectionsLen + index + 1}
            {/* {isDTB && <span style={{ fontSize: "9px" }}>DTB</span>} */}
          </div>
        );
      })}
    </div>
  );
};
ChooseQuestions.defaultProps = {
  questionList: [],
  userResponseObj: {},
  prevSectionsLen: 0,
};

ChooseQuestions.propTypes = {
  questionList: PropTypes.array,
  userResponseObj: PropTypes.object,
  onClick: PropTypes.func,
  prevSectionsLen: PropTypes.number,
};
export default ChooseQuestions;

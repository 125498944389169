import React from "react";
import LazyIntersection from "../LazyIntersection";
import { FallBackImage } from "../../../constants/appConfig";
import LiveTestToolTipIcon from "../library/LiveTestToolTipIcon";
import { toolTipMessage } from "../enrolledCourses/TestSeries/TestResultAnalysis/Components/liveTestUtils";

const ChildPackageCard = React.forwardRef((props, ref = null) => {
  let {
    src = FallBackImage,
    title = "",
    subTitle = "",
    onClick,
    batchStartDate,
    showLiveTestData,
    setShowLiveTestModal,
    liveTestCount = 0,
  } = props;

  const getFormattedDate = (milliTime) => {
    let date = new Date(milliTime);
    // strDate.substring(0, 10);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    date.setHours(0, 0, 0, 0);
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();

    var currentDate = new Date(y, m, d);
    let formattedDate = date
      .toLocaleString()
      .substring(0, 10)
      .split("/")
      // .reverse()
      .join("-");
    if (currentDate >= date) {
      formattedDate = "Started on " + formattedDate;
    } else {
      formattedDate = "Starts on " + formattedDate;
    }

    return formattedDate;
  };

  const showHowItWorks = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowLiveTestModal(true);
  };

  return (
    <div onClick={onClick} className="ppc-single-card-box" ref={ref}>
      <div className="ppc-single-card-img">
        <LazyIntersection
          src={src || "/images/ppc-pack-img.png"}
          placeholder={FallBackImage}
        />
      </div>
      <div className="ppc-single-card-content p-rel">
        <div className="ppc-single-card-name">
          <span className="title">{title}</span>
          {showLiveTestData ? <LiveTestToolTipIcon liveTestText={"Live Test"} tooltipMessage={toolTipMessage.fullLengthTooltip}/> : ""}
        </div>
        {showLiveTestData ? <div className="live-test-count">
          <span className="test-count">{liveTestCount} Live Tests</span>
        </div> : ""}
        {subTitle && <div className="ppc-single-card-subname">{subTitle}</div>}
       <div className="ppcCardBottom">
        {batchStartDate && (
          <div className="ppc-batchstart-date p-abs-b0">
            {getFormattedDate(batchStartDate)} 
          </div>
        )}
        {showLiveTestData ? (
          <div className="how-it-works-wrapper" onClick={showHowItWorks}>
            How it works?
          </div>
        ) : ""}
        </div>

        <div className="pccCardBottomMobile">
        {showLiveTestData ? <LiveTestToolTipIcon liveTestText={"Live Test"} tooltipMessage={toolTipMessage.fullLengthTooltip}/> : ""}
        {showLiveTestData ? (
          <div className="how-it-works-wrapper" onClick={showHowItWorks}>
            How it works?
          </div>
        ) : ""}

        </div>
      </div>
    </div>
  );
});

export default ChildPackageCard;

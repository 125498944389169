import reducerRegistry from '../../reducerRegistry';

function models(
    state = {},
    action
) {
    switch (action.type) {
    
    case 'FETCH_USER_MODELS': {
        const newData = action.payload.data;
        return {
            ...state,
            modelsList: newData,
        };
    }

    case 'FETCH_VIEW_MODELS': {
        const modelsData = action.payload.data;
        return {
            ...state,
            viewModelsList: modelsData,
        };
    }

    default:
        return state;
    }
}

reducerRegistry.register('models', models);
export default models;
import { GLOBAL_EXAMS_API } from "../../../../constants/appUrls";
import fetch from "../../../data/utils/fetch";

const useOnboardingAPI = () => {

  const getCatchObj = (res) => {
    console.error(res);
    let response = {
      success : res?.success,
      message : res?.response || res?.message || res?.error,
      data : [],
    }
    return response;
  }

  const sendMobileOTP = async (userMobile) => {
    try {

        let payload = {phone : userMobile};

        const url = `${GLOBAL_EXAMS_API}user-ws/new-phone-verify`;
        const resData = await fetch( url, "POST", payload );
        let response = {
          success : resData?.success,
          message : resData?.response,
        }
        return response;
      } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const verifyMobileOTP = async (otpDetails) => {
    try {
      let payload = {
        phone : otpDetails?.phone || "",
        otp : otpDetails?.otp || "",
      };
      const url = `${GLOBAL_EXAMS_API}user-ws/new-otp-verify`;
      const resData = await fetch( url, "POST", payload );
      let response = {
        success : resData?.success,
        message : resData?.response,
        data : resData?.data,
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const sendEmailOTP = async (userEmail, userId, helpDeskFlag = null) => {
    try {

      let payload = {
        email : userEmail,
        id : userId,
      };

      if (helpDeskFlag !== null) payload.helpDesk = helpDeskFlag;

      const url = `${GLOBAL_EXAMS_API}user-ws/email-verify`;
      const resData = await fetch( url, "POST", payload );
      let response = {
        success : resData?.success,
        message : resData?.response,
        data : resData?.data,
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const verifyEmailOTP = async (otpDetails, helpDeskFlag = null) => {
    try {
      let payload = {
        email: otpDetails?.email || "",
        id: otpDetails?.userId || "",
        updateEmail: otpDetails?.updateEmail || false,
        otp: otpDetails?.otp || ""
      }

      if (helpDeskFlag !== null) payload.helpDesk = helpDeskFlag;
      
      const url = `${GLOBAL_EXAMS_API}user-ws/email-otp-verify`;
      const resData = await fetch( url, "POST", payload );
      let response = {
        success : resData?.success,
        message : resData?.response,
        data : resData?.data,
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };
  const emailUserLogin = async (userDetails) => {
    try {
        let payload = {
          email : userDetails?.email || "",
          providerName : "email",
          sec : userDetails?.password || "",
        }
        
        const url = `${GLOBAL_EXAMS_API}user-ws/login`;
        const resData = await fetch( url, "POST", payload );

        let filteredData = {
          jwtToken : resData?.jwtToken,
          name : resData?.userInfo?.name,
          email : resData?.userInfo?.email || resData?.userInfo?.userEmail,
          userId : resData?.userInfo?.id,
          selectedCourse : "",
          jwt256Token : resData?.jwtTokenNew,
          phoneNumber : resData?.userInfo?.phone,
          refId : resData?.userInfo?.refId,
        }

        let successFlag = resData?.jwtToken?.length ? true : false;

        let response = {
          success : successFlag,
          message : resData?.response || resData?.message || resData?.error,
          data : filteredData,
        }
        return response;
        } 
    catch (res) {
        let response = getCatchObj(res);
        return response;
    }
  };


  const registerNewUser = async (userDetails, passwordResetFlag = false) => {
    try {
      let payload = {
        name : userDetails?.name || "",
      }

      if (passwordResetFlag) {
        payload = {
          email : userDetails?.email || "",
          otp : userDetails?.otp || "",
          providerName : "email",
          sec : userDetails?.password || "",
        }
      }

      if(passwordResetFlag) {
        payload.email = userDetails?.forgotPasswordEmail;
        payload.sec = userDetails?.confirmNewPassword;
      }
      
      const url = `${GLOBAL_EXAMS_API}user-ws/register`;
      const resData = await fetch( url, "POST", payload );
      let successFlag = resData?.jwtToken ? true : false;
      let response = {
        success : successFlag,
        message : resData?.response,
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const updateUserProfile = async (userDetails) => {
    try {
        let payload = {
          name : userDetails?.name || "",
        }        
        const url = `${GLOBAL_EXAMS_API}user-ws/updateProfile`;
        const resData = await fetch( url, "POST", payload );
        let response = {
          success : resData?.success,
          message : resData?.response || resData?.error,
        }
        return response;
        } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };


  const updateMobileSendOTP = async (otpDetails, helpDeskFlag = false) => {
    try {

        let payload = {
          userId : otpDetails?.userId,
          oldPhoneNumber : otpDetails?.oldPhoneNumber,
          newPhoneNumber : otpDetails?.newPhoneNumber,
          helpDesk : helpDeskFlag,
        };
        const url = `${GLOBAL_EXAMS_API}user-ws/sendUpdatePhoneRequest`;
        const resData = await fetch( url, "POST", payload );
        let response = {
          success : resData?.success,
          message : resData?.message || resData?.response || resData?.error,
        }
        return response;
        } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const updateMobileVerifyOTP = async (otpDetails, helpDeskFlag = false) => {
    try {
      let payload = {
        userId : otpDetails?.userId,
        oldPhoneNumber : otpDetails?.oldPhoneNumber,
        newPhoneNumber : otpDetails?.newPhoneNumber,
        otp: otpDetails?.otp,
        helpDesk : helpDeskFlag,
        logOut : otpDetails?.logOut || false,
      };
      const url = `${GLOBAL_EXAMS_API}user-ws/verifyUpdatePhoneRequest`;
      const resData = await fetch( url, "POST", payload );
      let response = {
        success : resData?.success,
        message : resData?.message || resData?.response || resData?.error,
        data : resData?.data,
      }
      return response;
    } 
  catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const forgotPasswordEmailOTPSend = async (email) => {
    try {
      const url = `${GLOBAL_EXAMS_API}user-ws/forgotPassword?emailId=${email}`;

      // testUrl for testing purpose only. Need to be removed before moving to QA
      const resData = await fetch( url, "GET", null );
      let response = {
        success : resData?.success,
        message : resData?.message || resData?.response || resData?.error,
      }
      return response;
    } 
    catch (res) {
        let response = getCatchObj(res);
        return response;
    }
  };

  const forgotPasswordEmailOTPVerify = async (otpDetails) => {
    try {
      let payload = {
        email : otpDetails?.forgotPasswordEmail || "",
        otp : otpDetails?.otp || "",
      }
      const url = `${GLOBAL_EXAMS_API}user-ws/verifyOTP?`;
      const resData = await fetch( url, "POST", payload );
      let response = {
        success : true,
        message : resData?.message || resData?.response || resData?.error || "OTP Verified",
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const changeUserPassword = async (passwordDetails) => {
    try {
        const url = `${GLOBAL_EXAMS_API}user-ws/api/v2/changePassword?oldPassword=${passwordDetails?.currentPassword}&newPassword=${passwordDetails?.newPassword}`;
        const resData = await fetch( url, "POST", null );
        let filteredData = {
          jwtToken : resData?.data?.jwtToken,
          name : resData?.data?.userInfo?.name,
          email : resData?.data?.userInfo?.email,
          userId : resData?.data?.userInfo?.id,
          selectedCourse : "",
          jwt256Token : resData?.data?.jwtTokenNew,
          refId : resData?.data?.userInfo?.refId,
          phoneNumber : resData?.data?.userInfo?.phone,
        }

        let response = {
          success : resData?.success,
          message : resData?.response || resData?.message || resData?.error,
          data : filteredData,
        }
        return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  const trueCallerSignUp = async (requestId) => {
    try {
      const url = `${GLOBAL_EXAMS_API}user-ws/webTrueCallerLogin?requestId=${requestId}`;
      const resData = await fetch( url, "POST", {} );
      let response = {
        success : resData?.success,
        message : resData?.response,
        data : resData?.data,
      }
      return response;
    } 
    catch (res) {
      let response = getCatchObj(res);
      return response;
    }
  };

  return {
    sendMobileOTP,
    verifyMobileOTP,
    sendEmailOTP,
    verifyEmailOTP,
    registerNewUser,
    updateUserProfile,
    emailUserLogin,
    updateMobileSendOTP,
    updateMobileVerifyOTP,
    forgotPasswordEmailOTPSend,
    forgotPasswordEmailOTPVerify,
    changeUserPassword,
    trueCallerSignUp,
  }
}


export default useOnboardingAPI;
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import '../data/ducks/productDetails/reducers';
import CustomModal from "./library/CustomModal";

//import PaymentMethod from "./PaymentMethod";
//import UserDetails from "./UserDetails";
const UserDetails = lazy(() => import('./UserDetails'));
const PaymentMethod = lazy(() => import('./PaymentMethod'));
const PaymentFailure = lazy(() => import('./PaymentFailure'));
// import ApplyCoupon from "./ApplyCoupon";
// import PaymentFailure

const CheckoutModal = ({
  priceDetails,
  cartJson,
  isShippable,
  userEmail,
  setUserEmail,
  setCheckoutData,
  checkoutData,
  Getpayload,
  getUtmFromStore,
  payablePrice,
  productData,
  updatePriceDetails,
  loggedUserDetails
}) => {
  const [hashUrl, setHashUrl] = useState("");
  const doc = useRef(null);
  const [header, setHeader] = useState("What are you preparing for?");
  const [cartError, setCartError] = useState('')
  const [fromapp, setFromapp] = useState(false);
  useEffect(() => {
    if (location.hash) {
      setHashUrl(location.hash);
    }
    window.addEventListener("hashchange", () => {
      setHashUrl(location.hash);
    }, false);
    // window.onhashchange = () => {
    //   setHashUrl(location.hash);
    // };
    setCheckoutData({ couponCode: priceDetails.appliedCoupon })
  }, []);

  const close = () => {
    window.location.hash = "";
  };

  useEffect(() => {
    let fromapp = getParameterByName('from_app');
    if (fromapp) {
      setFromapp(true);
    }
    else {
      setFromapp(false);
    }
  }, [])

  function getParameterByName(name, url) {
    if (!url) url = typeof window != undefined && window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  /*
  checkoutData={
    email,
    packageId,
    phone,
    name,
    couponCode
  }
  */
  // const [checkoutData,setCheckoutData] = useState({couponCode:priceDetails.appliedCoupon})
  if (!hashUrl) return null;
  let ModalBodyComponent;
  let ModalHeader;
  switch (hashUrl) {
    case "#checkout/userDetails":
      ModalHeader = "Checkout";
      ModalBodyComponent = <Suspense fallback={<div></div>}><UserDetails
        productData={productData}
        priceDetails={priceDetails}
        updatePriceDetails={updatePriceDetails}


        checkoutData={checkoutData}
        payablePrice={payablePrice}
        cartJson={cartJson}
        loggedUserDetails={loggedUserDetails}
        isShippable={isShippable}
        updateCheckoutData={setCheckoutData}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        Getpayload={Getpayload}
      ></UserDetails></Suspense>;
      break;
    case "#checkout/payment":
      ModalHeader = "Payment";
      ModalBodyComponent = <Suspense fallback={<div></div>}><PaymentMethod
        priceDetails={priceDetails}
        checkoutData={checkoutData}
        setCartError={setCartError}
        Getpayload={Getpayload}
        getUtmFromStore={getUtmFromStore}
      >
      </PaymentMethod></Suspense>;
      break;
    case "#checkout/alreadyPurchased":
      ModalHeader = "Already Purchased";
      ModalBodyComponent = <Suspense fallback={<div></div>}>
        <PaymentFailure
          text={cartError}
          Getpayload={Getpayload}
          checkoutData={checkoutData}
          >
        </PaymentFailure> </Suspense>
      break;
    default:
      return null;
  }
  const onBackClick = () => {
    const backActionList = ['', "#checkout/userDetails", "#checkout/payment"]
    window.location.hash = backActionList[backActionList.indexOf(hashUrl) - 1].substring(1)
  }
  return (
    <CustomModal
      ref={doc}
      hideCross={fromapp ? true : false}
      modalClassName={`checkout-modal modalZindex ${hashUrl == '#checkout/userDetails' ? 'usermodal' : ''}`}
      footer={null}
      visible={true}
      title={<div className={"modal-header"}>{ModalHeader}</div>}
      onCancel={close}
      closeCallback={close}
      closable={true}
      maskClosable={false}
      keyboard={false}
      bodyStyle={{ overflowY: "auto" }}

    >
      <div>
        {ModalBodyComponent}

      </div>
      {/* <CustomButton buttontext="Continue"></CustomButton> */}
    </CustomModal>
  );
};
export default CheckoutModal;

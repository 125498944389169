import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";
import { GOOGLE_CLIENT_ID, FACEBOOK_ID } from "../../../constants/appConfig";
import LeftPanelLoginModal from "./LeftPanelLoginModal";
import { isADDA } from "../../data/utils/helpers";

const LoginModal = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const closeModal = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
    if (localStorage.getItem("urlAfterLogin")) {
      localStorage.removeItem("urlAfterLogin");
    }
    if (localStorage.getItem("urlForTestPortal")) {
      localStorage.removeItem("urlForTestPortal");
    }

    if (localStorage.getItem("urlForFreeQuizzes")) {
      localStorage.removeItem("urlForFreeQuizzes");
    }
  };
  const onCancel = () => {
    document.body.classList.remove("noscroll");
    if (props.onCancel) {
      props.onCancel();
    }
  };
  const onBack = () => {
    document.body.classList.remove("noscroll");
    if (props.onBack) {
      props.onBack();
    }
  };

  useEffect(() => {
    async function gp() {
      const gapi = await import("gapi-script");
      gapi.load("client:auth2", () => {
        gapi.client.init({ clientId: GOOGLE_CLIENT_ID, plugin_name: "email" });
      });
    }
    if (!isADDA) {
      gp();
    }
  }, []);

  let targetEvent = null;
  useEffect(() => {
    try {
      targetEvent = window.addEventListener("keydown", (event) => {
        if (event.keyCode == 27) {
          closeModal();
        }
      });
      return () => {
        window.removeEventListener("keydown", targetEvent);
        document.body.classList.remove("noscroll");
      };
    } catch (ex) {}
  }, []);

  return (
    <div
      data-testid="login-modal"
      footer={null}
      closeCallback={() => onCancel()}
      className={" main-login-wrap"}
    >
      <div class="main-login-content-wrap">
        <LeftPanelLoginModal />
        <div class="right-panel-login">
          <div class="rightpanel-header">
            {props.showSignupForm && (
              <div
                class="back-tn-panel"
                onClick={() => props.handleSignupForm("Login")}
              >
                <img src="/images/login-pop-back-btn.svg" />
              </div>
            )}
            <div class="right-cross-icon" onClick={closeModal}>
              <img src="/images/login-pop-cross-btn.svg" />
            </div>
          </div>
          <div className="login-and-form">
            <div className="login-heading">
              {props.showSignupForm ? "Register Now!" : "Login"}
            </div>
            <div className="online-login-btns">
              <GoogleLogin
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="login-with-google ripple"
                  >
                    Continue with Google
                  </div>
                )}
                clientId={GOOGLE_CLIENT_ID}
                onSuccess={props.responseGoogleSuccess}
                onFailure={props.responseGoogleFail}
              />
              <FacebookLogin
                appId={FACEBOOK_ID}
                autoLoad={false}
                disableMobileRedirect
                fields="name,email,picture"
                callback={props.responseFacebook}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="login-with-fb ripple"
                    style={{ boxShadow: "0px 4px 10px rgb(0 0 0 / 15%)" }}
                  >
                    Continue with Facebook
                  </div>
                )}
              />
            </div>
            <div className="dashed-stripe">or</div>
            <form
              onSubmit={
                !props.showSignupForm
                  ? props.handleUserLogin
                  : props.handleUserSignup
              }
            >
              <div className="right-panel-form-section">
                {props.showSignupForm && (
                  <>
                    <div class="text">
                      <input
                        type={"text"}
                        size="default"
                        onBlur={props.handleUserNameOnBlur}
                        onFocus={props.handleUserNameOnFocus}
                        placeholder=" "
                        onKeyDown={(e) => props.handleKeyPressLoginSignup(e)}
                        value={props.name || ""}
                        className={
                          props.showUserNameError || props.showNameError
                            ? "wrongpass a-input"
                            : "a-input"
                        }
                        onChange={(e) => props.handleusername(e)}
                      />
                      <label class="floating-label">Enter your Full Name</label>
                      {props.showUserNameError && (
                        <div className={"wrongpass-msg mb-2"}>
                          Please enter a valid name
                        </div>
                      )}
                      {props.showNameError && (
                        <div className={"wrongpass-msg mb-2"}>
                          Name should be atleast 2 characters and maximum 50
                          characters
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="email">
                  <input
                    data-testid="email-input-box"
                    type={"email"}
                    onChange={(e) => props.handleuseremail(e)}
                    onKeyDown={(e) => props.handleKeyPressLoginSignup(e)}
                    placeholder=" "
                    onBlur={props.handleEmailOnBlur}
                    onFocus={props.handleEmailOnFocus}
                    size="default"
                    className={
                      props.showEmailError ? "wrongpass a-input" : "a-input"
                    }
                    value={props.emailId}
                  />
                  <label class="floating-label">Enter your email</label>
                  {props.showEmailError && (
                    <div className={"wrongpass-msg mb-2"}>
                      Please enter a valid email id
                    </div>
                  )}
                </div>
                <div className="password-eye">
                  <input
                    data-testid="password-input-box"
                    type={showPassword ? "text" : "password"}
                    style={{ "margin-bottom": "unset" }}
                    onChange={(e) => props.handleuserpassword(e)}
                    onBlur={
                      props.showSignupForm
                        ? props.handlePasswordOnBlur
                        : () => {}
                    }
                    placeholder=" "
                    onFocus={props.handlePasswordOnFocus}
                    onKeyDown={(e) => props.handleKeyPressLoginSignup(e)}
                    size="default"
                    className={
                      props.showPasswordError || props.showPassError
                        ? "wrongpass a-input"
                        : "a-input"
                    }
                    value={props.password}
                  />
                  <label class="floating-label">Password</label>
                  <div
                    class="eye-pass"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src="/images/crossed_eye.svg" alt="" />
                    ) : (
                      <img src="/images/show-pass-eye-icon.svg" alt="" />
                    )}
                  </div>
                </div>
                <div className="forgot-pass">
                  {props.showPasswordError && props.showSignupForm && (
                    <div className={"wrongpass-msg"}>
                      Opps Looks like its a wrong password !
                    </div>
                  )}
                  {props.showPassError && props.showSignupForm && (
                    <div className={"wrongpass-msg"}>{props.showPassError}</div>
                  )}
                  {props.showPasswordError && !props.showSignupForm && (
                    <span className={"wrongpass-msg"}>
                      Please enter your password
                    </span>
                  )}
                  {!props.showPassError &&
                    !props.showPasswordError &&
                    props.apiError &&
                    props.apiError.length && (
                      <span className={"wrongpass-msg"}>{props.apiError}</span>
                    )}
                  {!props.showSignupForm && (
                    <a data-testid="forgot-password"
                      style={{ cursor: "pointer" }}
                      onClick={props.handleForgotPassword}
                    >
                      Forgot Password?
                    </a>
                  )}
                </div>
                {/* <div class="primary-red-btn ripple" onClick={!props.showSignupForm ? props.handleUserLogin : props.handleUserSignup}>{!props.showSignupForm ? 'LOGIN' : 'SIGNUP'}</div> */}
                <CustomButton
                  type="primary"
                  className={"primary-red-btn ripple"}
                  buttontext={!props.showSignupForm ? "LOGIN" : "SIGNUP"}
                  loading={props.showLoginLoader}
                  onClick={
                    !props.showSignupForm
                      ? props.handleUserLogin
                      : props.handleUserSignup
                  }
                />
                <div className="singup" data-testid="login-signup-button">
                  {!props.showSignupForm
                    ? "Don't have an Account?"
                    : "Already have an Account?"}
                  <a
                    data-testid="login-signup-button-link"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.handleSignupForm(
                        !props.showSignupForm ? "Signup" : "Login"
                      )
                    }
                  >
                    {!props.showSignupForm ? " Sign up here" : " Login"}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;

import React from "react";
import CustomButton from "./CustomButton";
import ProductTypeListing from "../../components/filter/listings/ProductTypeListing";

const NoResultsFound = (props) => {
  const message = props.message || "Sorry, No Matching Result Found";

  const handleDetailCardClick = () => {
    props.handleDetailCardClick();
  };

  return (
    
    <div className={"no-result-found"}>
       <div className={"no-result-found-body"}>
      <img
        src={props.image ? props.image : "/images/no-result-found.svg"}
        alt="no-result-found"
        className={props.width ? "static-size-not-found-image" : ""}
      />{" "}
      <div className="d-flex-c">
        <span>{message}</span>
        {props.message2 && (
          <span className={"no-result-text"}>{props.message2}</span>
        )}
      </div>
      </div>
      {props.handleOnClick && (
        <CustomButton
          onClick={props.handleOnClick}
          className={"no-results-button"}
        >
          Clear All filters
        </CustomButton>
      )}
      {
        props.allCategoryData?.length && <div className="all-recomend-course">Courses bought by most :</div>
      }
      {
       
          props.allCategoryData &&   <ProductTypeListing
          allCategoryData={props.allCategoryData}
          handleDetailCardClick={() => handleDetailCardClick()}
        />
      }

    </div>
  );
};

export default NoResultsFound;

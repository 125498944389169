import React, { useState } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import { isUserPasswordValid } from "../../data/utils/helpers";
import {
  startLoader,
  stopLoader,
  resetUserPassword,
} from "../../data/ducks/header/actions";
import CustomButton from "./CustomButton";
import { pageName } from "../../../constants/appConfig";

const cookie = new Cookies();
const ResetPasswordForm = (props) => {
  let email = cookie.get("cp_user_email");
  let name = cookie.get("cp_user_name");

  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [error, setError] = useState("");

  if (
    password &&
    password.length >= 6 &&
    password.length <= 20 &&
    password.localeCompare(confirmPassword) == 0 &&
    typeof document != "undefined" &&
    typeof window != "undefined"
  ) {
    let element = window.document.getElementById("custombutton");
    if (element.classList.contains("custom-button-disabled"))
      element.classList.remove("custom-button-disabled");
  } else if (
    password &&
    password.localeCompare(confirmPassword) != 0 &&
    typeof document != "undefined" &&
    typeof window != "undefined"
  ) {
    let element = window.document.getElementById("custombutton");
    if (!element.classList.contains("custom-button-disabled"))
      element.classList.add("custom-button-disabled");
  }

  const handleLogout = () => {
    var eventFired = new CustomEvent("userLogoutCE");
    document.dispatchEvent(eventFired);
  };

  const resetPassword = () => {
    setPasswordError(false);
    setConfirmPasswordError(false);
    if (password.match(/[^\x00-\x7F]/)) {
      setError("Password should not contain emojis");
      return;
    }
    if (
      isUserPasswordValid(password) &&
      password === confirmPassword &&
      password === password.trim()
    ) {
      const obj = {
        email: email,
        otp: props.otpVal,
        providerName: "email",
        sec: confirmPassword,
      };
      props.startLoader();
      props
        .resetUserPassword(obj)
        .then(
          () => {
            console.log("success");
            setShowModal(true);
            setTimeout(() => handleLogout(), 2000);
          }
          // this.handleCancelResetModal()
        )
        .finally(() => props.stopLoader());
    } else {
      if (!isUserPasswordValid(password)) {
        setPasswordError(true);
      }
      if (password !== confirmPassword) {
        setConfirmPasswordError(true);
      }
      if (password !== password.trim()) {
        setError("Your password can’t start or end with a blank space");
      }
    }
  };

  const togglePassType = (e) => {
    let elem = e.target.parentNode.getElementsByTagName("input")[0];
    let imgElm = e.target.parentNode.getElementsByTagName("img")[0];
    elem.focus();
    if (elem.type == "password") {
      elem.type = "text";
      imgElm.src = "/images/crossed_eye.svg";
    } else {
      elem.type = "password";
      imgElm.src = "/images/eyeicon.svg";
    }
  };

  return (
    <div>
      {showModal && (
        <div className="mock-comman-pop">
          <div className="mock-comman-pop-content">
            <div className="mock-header-popup">
              <div className="mockpopheader-title"></div>
              <div
                className="close-mock-pop"
                onClick={() => setShowModal(false)}
              >
                <a href="#">
                  <img src="../images/checkout-close.svg" />
                </a>
              </div>
            </div>
            <div className="comman-pop-content text-center">
              <img src="/images/success.svg" width="160" />
              <h3>
                <b>Congratulations.</b>
              </h3>
              <p>Your password is reset successfully.</p>
            </div>
          </div>
        </div>
      )}
      <div className="userconatiner">
        <div className="myaccountheader">Account Information</div>
        <div className="row">
          <div className="input-group">
            <label htmlFor="email">Name</label>
            <input
              type="text"
              size="default"
              value={name || "Vishal123 sharma"}
              disabled={true}
            />
          </div>
          <div className="input-group">
            <label htmlFor="psw">Email</label>
            <input
              type="email"
              size="default"
              value={email || `vishal123.sharma@${pageName[0]}.com`}
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-group">
            <label htmlFor="pwd">Password</label>
            <input
              type="password"
              size="default"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={passwordError ? "input-error" : "margin2"}
            />
            <img
              src="/images/eyeicon.svg"
              className="pass-hide-icon"
              onClick={togglePassType}
              width="22"
              alt="show password"
            />
            {!passwordError &&
              password &&
              (password.length < 6 || password.length > 20) && (
                <div className={"error-message"}>
                  Password should be between 6 and 20 characters
                </div>
              )}
            {passwordError && (
              <div className={"error-message"}>
                Password should be between 6 and 20 characters
              </div>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="cnfPwd">Confirm Password</label>
            <input
              type="password"
              size="default"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={confirmPasswordError ? "input-error" : "margin2"}
            />
            <img
              src="/images/eyeicon.svg"
              className="pass-hide-icon"
              onClick={togglePassType}
              width="22"
              alt="show password"
            />
            {!confirmPasswordError &&
              confirmPassword &&
              password.localeCompare(confirmPassword) != 0 && (
                <div className={"error-message"}>Passwords do not match</div>
              )}
            {confirmPasswordError && (
              <div className={"error-message"}>Passwords do not match</div>
            )}
          </div>
        </div>
        {error && <div className={"error-message"}>{error}</div>}
        <div className="buttondiv1">
          <CustomButton
            onClick={resetPassword}
            id="custombutton"
            type="primary"
            block
            loading={props.showLoader}
            buttontext={"RESET PASSWORD"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showLoader: state.header.showLoader,
});

const mapDispatchToProps = {
  startLoader,
  stopLoader,
  resetUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);

import { isADDA } from "../app/data/utils/helpers";

const browseByProductType = {
    'mock-tests': 'TEST_SERIES',
    'live-classes': 'ONLINE_LIVE_CLASSES',
    'video-courses': 'VIDEOS',
    'e-books': 'EBOOKS',
    'printed-books': 'BOOKS'
};

const storeProductsLinkObj = {
    'Online Live Classes': 'onlineliveclasses',
    'Test Series': 'testseries',
    'E-Books': 'ebooks',
    'Publications': 'books',
    'Video Courses': 'video',
};

const QUIZ_BUTTON_STATES_ENUM = {
    'NOT_STARTED': 'ATTEMPT',
    'INCOMPLETE': 'RESUME',
    'COMPLETED': 'SEE RESULT'
};

const productNames = {
    'mock-tests': 'Mock Tests',
    'books': 'Books',
    'ebooks': 'E-Books',
    'videos': 'Videos',
    'live-classes': 'Online Live Classes',
    '3d-models': '3D Learning'
};

const facetsMap = {
    'categories': 'categoryFacets',
    'courses': 'coursesFacets',
    'faculties': 'facultyFacets',
    'languages': 'langFacets',
    'subjects': 'subjectFacets',
    'tags': 'tagsFacets',
    'examtypes': 'examTypesFacets',
    'time': 'yearFacets'

};
// Hardcoded examPages

const examCategoryArray = isADDA ? [
    { name: "Banking" }, { name: "SSC" }, { name: 'Railways' },
    { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }
] : [{name: "Class12-JEE"}, {name: "Dropper-JEE"},{name: "Class12-NEET"}, {name: "Dropper-NEET"}];

//Product Category Array

const productCategoryArray = [
    { name: "Mock Tests", url: "mock-tests" }, { name: "Books", url: "books" }, { name: "ebooks", url: "videos" }, { name: "Online Live Classes", url: "live-classes" }
]

const productKeys = {
    'Mock Tests': 'mock-tests',
    'Printed Books': 'books',
    'E-Books': 'ebooks',
    'Video Courses': 'videos',
    'Online Live Classes': 'live-classes',
    'Live Classes': 'live-classes',
    'books': 'books',
    'test_series': 'mock-tests',
    'test series': 'mock-tests',
    'Test Series': 'mock-tests',
    'ebooks': 'ebooks',
    'magazines': 'magazines',
    'online_live_classes': 'live-classes',
    'online live classes': 'live-classes',
    'Publications': 'books',
    'videos': 'videos',
    '3D-Models':'3d-models',
    '3D Models':'3d-models',
    '3D Learning':'3d-models',
    'models 3d':'3d-models',
    'models_3d':'3d-models'
};


const viewAllKeys = {
    'mock-tests': 'TEST_SERIES',
    'books': 'BOOKS',
    'ebooks': 'EBOOKS',
    'videos': 'VIDEOS',
    'live-classes': 'ONLINE_LIVE_CLASSES',
    '3d-models': 'MODELS_3D'
};

const radioFilterKeyMap = {
    'mocktests': 'Test series',
    'books': 'Books',
    'ebooks': 'Ebooks',
    'videos': 'Videos',
    'liveclasses': 'Online live classes',
    'magazines': 'Magazines'
}

const quickLinkMapping = {
    "online live classes": "olc",
    "test series": "test-series",
    "ebooks": "ebooks",
    "books": "books",
    "videos": "videos",
    "3d learning": "models"
  };

const headerSearchOptions = [
    {
        key: 'all',
        text: 'All',
        value: 'ALL',
        placeholder: 'Search Video Courses, Test Series & more…',
    },
    {
        key: 'testSeries',
        text: 'Test Series',
        value: 'TEST_SERIES',
        placeholder: 'Search Test Series',
    },
    {
        key: 'books',
        text: 'Books',
        value: 'BOOKS',
        placeholder: 'Search Books',
    },
    {
        key: 'eBooks',
        text: 'E-Books',
        value: 'EBOOKS',
        placeholder: 'Search E-Books',
    },
    {
        key: 'videoCourses',
        text: 'Video Courses',
        value: 'VIDEOS',
        placeholder: 'Search Video Courses',
    },
    {
        key: 'liveClasses',
        text: 'Live Classes',
        value: 'ONLINE_LIVE_CLASSES',
        placeholder: 'Search Live Classes',
    },
];

const radioProductFacetMap = {
    'mock-tests': 'Test series',
    'books': 'Books',
    'ebooks': 'Ebooks',
    'magazines': 'Magazines',
    'live-classes': 'Online live classes',
    'videos': 'Videos',
    '3d-models': '3D Learning'
}

const categoryInclusionUrlMap = {
    'videos': 'icon-sm-video.svg',
    'books': 'icon-sm-book.svg',
    'TEST_SERIES': 'icon-sm-mock-test.svg',
    'ONLINE_LIVE_CLASSES': 'icon-sm-live-class.svg',
    'EBOOKS': 'icon-sm-book-pdf.svg',
    'BOOKS': 'icon-sm-book.svg',
    'VIDEOS': 'icon-sm-video.svg',
};

const timelineZeroStateMessage = 'No results found. Please try again.';

const mobileFilterMap = {
    courses: 'Exam Category',
    languages: 'Languages',
    subjects: 'Subjects',
    categories: 'Product Category',
    time: 'Time (Year)',
    examtypes: 'Exams',
    faculties: 'Faculty',
    tags: 'Tags',
};

const dfpSlotsForWeb = [
    {
        path: '/21778185301/Store_Craosual',
        size: [1300, 355],
        id: 'div-gpt-ad-1577441964124-0'
    }/*,
    {
        path:'/21778185301/StoreHomePage_Desktop1',
        size:[1300, 355],
        id:'div-gpt-ad-1577445004959-0'
    },
    {
        path:'/21778185301/StoreHomePage_Desktop2',
        size:[1300, 355],
        id:'div-gpt-ad-1577445274351-0'
    },
    {
        path:'/21778185301/StoreHomePage_Desktop3',
        size:[1300, 355],
        id:'div-gpt-ad-1577445546181-0'
    }*/
]

const dfpSlotsForWebPDP = [
    {
        path: '/21778185301/Store_PDP_Desktop',
        size: [1199, 120],
        id: 'div-gpt-ad-1600066942145-0'
    }

]
const dfpSlotsForMobilePDP = [

    {
        path: '/21778185301/Store_PDP_Mobile',
        size: [360, 80],
        id: 'div-gpt-ad-1600067067061-0'
    }
]

const dfpSlotsSubjectSeoPage = {
    "web": {
        path: '/22777125435/SankalpBharatBlog/SankalpWebFooter',
        size: [970, 90],
        id: 'div-gpt-ad-1698779080618-0'
    },
    "mweb" : {
        path: '/22777125435/SankalpBharatBlog/SankalpMobileFooter',
        size: [320, 100],
        id: 'div-gpt-ad-1698779819385-0'
    }
}

const dfpSlotsForMobile = [
    {
        path: '/21778185301/Store_Mobile_Craosual_360x150',
        size: [360, 150],
        id: 'div-gpt-ad-1577442106922-0'
    }/*,
    {
        path:'/21778185301/StoreHomePage_Mobile1',
        size:[360, 150],
        id:'div-gpt-ad-1577445902479-0'
    },
    {
        path:'/21778185301/StoreHomePage_Mobile2',
        size:[360, 150],
        id:'div-gpt-ad-1577445832828-0'
    },
    {
        path:'/21778185301/StoreHomePage_Mobile3',
        size:[360, 150],
        id:'div-gpt-ad-1577446037024-0'
    }*/
]

// d ->  Desktop
// m -> Mobile
const LOGGED_IN_CP_ORIGIN_MAP = {
    d: '11',
    m: '12'
}

const LOGGED_OUT_CP_ORIGIN_MAP = {
    d: '1',
    m: '4'
}

const ADDA_GOLD_DATA = {
    EXAM_LIST: [' Banking and Insurance', "SSC", "Railways", "Teaching", "Defence", "UPSC", "Engineering", "IIT/Medical", "CLAT", "GATE and IITJAM", "CA", "State Exams"],
    CONTENT_DATA: [
        {
            img: '/images/gold_assets/Illustration_1.svg',
            header: 'Structured Study Material',
            info: 'Prepare for multiple exams without repeating the concepts. Organized material ensures comprehensive preparation.'
        },
        {
            img: '/images/gold_assets/Illustration_2.svg',
            header: 'Instant Doubt Clearing With Experts',
            info: 'Get on a 1-to-1 video call with the expert to clear your doubts. Anytime help at your fingertips!'
        },
        {
            img: '/images/gold_assets/Illustration_3.svg',
            header: 'Unlimited Practice',
            info: 'Attempt as many tests as you want. Improve your speed, accuracy and score.'
        },
        {
            img: '/images/gold_assets/Illustration_4.svg',
            header: 'Personalised Recommendations',
            info: 'We help you understand your progress and performance and get a personalised study plan to crack your competitive exam.'
        },
    ],
    TESTIMONIALS: [
        {
            img: '/images/gold_assets/testimonial_1.svg',
            message: 'Performance analysis showed me my weaknesses, and told me how to improve them!',
            name: 'Reena Kesria',
            qualification: 'Selected in SBI PO, SBI Clerk, FCI Grade 3, CWC Superintendent, RRB PO, and IDBI PO'
        },
        {
            img: '/images/gold_assets/testimonial_2.png',
            message: 'Gautam sir helped me clear all doubts live in realtime, never got stuck on any concept.',
            name: 'Priya V',
            qualification: 'Selected as an Associate in State Bank of India'
        },
        {
            img: '/images/gold_assets/testimonial_4.svg',
            message: 'Practice test showed me my progress, and gave my rank compared to other people preparing for SBI PO Pre Exam',
            name: 'Anil Duragappa Talawar',
            qualification: 'IBPS Clerk in Corporation Bank'
        },
        {
            img: '/images/gold_assets/testimonial_3.svg',
            message: 'I could not attend the live classes because of internet issues. But, with Gold, I downloaded the whole study material and was able to study anytime I wanted.',
            name: 'Abha Jha',
            qualification: 'Selected as IBPS Clerk in Bank of India'
        },

        {
            img: '/images/gold_assets/testimonial_5.svg',
            message: 'With Gold, I learnt concept once, and practiced more for multiple exams. I dont have to waste my time learning same concept again and again for different exams like in Live Classes.',
            name: 'Vineet',
            qualification: 'Selected as IBPS PO in UCO Bank'
        }
    ],
    VIDEO_INFO: [{
        src: '/images/intro-video/Part_1.mp4',
        type: 'video/mp4'
    },
    {
        src: '/images/intro-video/Part_2.mp4',
        type: 'video/mp4'
    },
    {
        src: '/images/intro-video/Part_3.mp4',
        type: 'video/mp4'
    },
    {
        src: '/images/intro-video/Part_4.mp4',
        type: 'video/mp4'
    },
    {
        src: '/images/intro-video/Part_5.mp4',
        type: 'video/mp4'
    }]

}

const textConstants = {
    trackingId: "Tracking Id:"
}
const wrapperWithoutExamList = 2;

const overlayVariant = {
    overlayBg : "overlayBg"
}

const ExpiryImg = "https://s3.ap-south-1.amazonaws.com/storefront-assets/Expiry.png";

const categoryIconMappings = {
  olcCount: { icon: "/images/liveClassIcon.svg", label: "Live Classes" },
  testSeriesCount: {
    icon: "/images/testseriesIcon.svg",
    label: "Test Series",
  },
  videosCount: { icon: "/images/videosIcon.svg", label: "Videos" },
  ebooksCount: { icon: "/images/ebooksIcon.svg", label: "E-books" },
  booksCount: { icon: "/images/booksIcon.svg", label: "Books" },
};
const categoryAdditionalMap = {
  ONLINE_LIVE_CLASSES: "Live + Recorded",
  TEST_SERIES: "Mock and Part Tests",
  VIDEOS: "Top Faculties",
  EBOOKS: "Latest Pattern",
  BOOKS: "Latest Pattern",
};
// Define the priority order of categories
const categoryPriority = [
  "ONLINE_LIVE_CLASSES",
  "TEST_SERIES",
  "VIDEOS",
  "EBOOKS",
  "BOOKS",
];
const categoryCountPriority = [
  "olcCount",
  "testSeriesCount",
  "videosCount",
  "ebooksCount",
  "booksCount",
];

const subjectPagesAvailable = [
    { title: 'inorganic-chemistry', desc: 'Learn Inorganic Chemistry Questions extracted from to class 11th and 12th syllabus which will be helpful for IIT JEE and NEET examination.' },
  { title: 'organic-chemistry', desc: 'Learn Organic Chemistry Questions based on class 11th and 12th syllabus. It will be helpful to crack the IIT JEE and NEET entrance examination.' },
  { title: 'physical-chemistry', desc: 'Here, we have covered Physical Chemistry Questions related to class 11th and 12th which will be helpful to crack IIT JEE and NEET examination.' },
  { title: 'hindi', desc: 'Here, we have covered all Hindi Questions from class 9th, 10th, 11th and 12th syllabus. Learn these questions and increase your marks in board examinations.' },
  { title: 'history', desc: 'Here, we have covered all History Questions from class 9th, 10th, 11th and 12th syllabus. Learn these history questions and score good marks in board examinations.' },
  { title: 'geography', desc: 'Here, we have covered all Geography Questions from class 9th, 10th, 11th and 12th CBSE syllabus. Learn these geography questions and score good marks in board examinations.' },
  { title: 'civics', desc: 'In this article, we have covered all Civics Questions from class 11th and 12th syllabus. Learn these questions and increase your marks in board examinations and CUET Arts Exam.' },
  { title: 'economics', desc: 'In this article, we have covered all Economics Questions from class 11th and 12th syllabus. Learn these questions to score marks in board examinations.' },
  { title: 'biology', desc: 'Here, we have covered Bilogy Questions from class 9th, 10th, 11th and 12th which will be helpful to crack NEET and other medical and agriculture exams.' },
  { title: 'botany', desc: 'Learn Botany Questions from class 11th and 12th which will be helpful to crack NEET and other medical and agriculture exams.' },
  { title: 'chemistry', desc: 'Here, we have covered chemistry Questions related to class 10th, 11th and 12th which will be helpful for IIT JEE and NEET examination.' },
  { title: 'english', desc: 'In this article, we have covered all English Questions from class 9th, 10th, 11th and 12th. Learn these questions and increase your marks in board examinations.' },
  { title: 'mathematics', desc: 'Learn and attempts mathematics questions and quiz based on the syllabus of class 9th, 10th, 11th and 12th to crack IIT JEE, CUET and other engineering exams.' },
  { title: 'physics', desc: 'In this article, we have covered physics Questions related to class 11th and 12th which will be helpful for IIT JEE and NEET examination.' },
  { title: 'science', desc: 'Learn and attempts Science questions and quiz based on the syllabus of class 9th, 10th, 11th and 12th to score good marks in board exams and entrance examinations.' },
  { title: 'social-science', desc: 'Learn and attempts Social Science or SST questions and quiz based on the syllabus of class 9th and 10th to score good marks in board examinations.' },
  { title: 'zoology', desc: 'Here, we have covered Zoology Questions from class 11th and 12th which will be helpful to crack NEET and other medical examinations.' },
  { title: 'polity', desc: 'In this article, we have covered all Polity Questions from class 9th, 10th, 11th and 12th syllabus. Learn these political science questions and increase your marks in board examinations.' }
  ]
  const productCategoryMap = {
    ONLINE_LIVE_CLASSES: "Live Classes",
    VIDEOS: "Videos",
    TEST_SERIES: "Mock Tests",
    BOOKS:"Books",
    EBOOKS: "E-Books",
    MODELS_3D: "3D Learning",
  };
 
const indianTimezoneOffset = -330;  //in secs (+5:30 GMT). Negative sign is for correction.

const istUtcTimeDiff = 5.5*60*60*1000;

export {
    browseByProductType,
    storeProductsLinkObj,
    ADDA_GOLD_DATA,
    QUIZ_BUTTON_STATES_ENUM,
    productNames,
    productKeys,
    viewAllKeys,
    headerSearchOptions,
    facetsMap,
    radioProductFacetMap,
    timelineZeroStateMessage,
    categoryInclusionUrlMap,
    mobileFilterMap,
    radioFilterKeyMap,
    quickLinkMapping,
    dfpSlotsForWeb,
    dfpSlotsForMobile,
    LOGGED_IN_CP_ORIGIN_MAP,
    LOGGED_OUT_CP_ORIGIN_MAP,
    dfpSlotsForWebPDP,
    dfpSlotsForMobilePDP,
    dfpSlotsSubjectSeoPage,
    // productKeysArray,
    examCategoryArray,
    productCategoryArray,
    textConstants,
    wrapperWithoutExamList,
    overlayVariant,
    ExpiryImg,
    categoryIconMappings,
    categoryAdditionalMap,
    categoryPriority,
    categoryCountPriority,
    subjectPagesAvailable,
    indianTimezoneOffset,
    productCategoryMap,
    istUtcTimeDiff,
};

import React from "react";

const ProductCardHoc = React.forwardRef((props, ref = null) => {
  const { children, className } = props;
  return (
    <div className={className} {...props} ref={ref}>
      {children}
    </div>
  );
});
export default ProductCardHoc;

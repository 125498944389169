import types from './types';
import {mediaCoverage} from '../../../../server/models/home';
import {storeHostUrl, timeLineUrl,quizAttemptUrl,usersReviewUrl,stagingMainUrl} from '../../../../constants/appConfig';
import {isSet} from '../../utils/helpers';

const fetchMediaCoverage = () => {
    return {
        CALL_API: [
            {
                type: types.RECEIVE_MEDIA_COVERAGE,
                meta: {
                    response: mediaCoverage(),
                    method: 'LOCAL',
                }
            }
        ]
    };
};

const fetchHomeRecommendations = () => {
    return {
        CALL_API: [
            {
                type: types.FETCH_HOME_RECOMMENDATION,
                meta: {
                    path: `${stagingMainUrl}/recommendations?id=0`,
                    method: 'GET'
                }
            }
        ]
    };
};


const fetchUsersAppreciation = () => {
    return{
        CALL_API:[
            {
                type:types.FETCH_USER_APPRECIATION,
                meta:{
                    path:usersReviewUrl,
                    method:'GET'
                }
            }
        ]
    };
};

const getRequesQuizStates = (freeMockPackageId, freeMockMappingIdArr) => {
    return{
        CALL_API:[
            {
                type: types.FEED_QUIZ_STATUS_FETCH_SUCCESS,
                meta:{
                    path: `${quizAttemptUrl}/?packageId=${freeMockPackageId}&mappingIds=${freeMockMappingIdArr}`,
                    method: 'GET',
                }
            }
        ]
    };
};

const setUserCourse = userCourse => {
    return {
        type: types.SET_USER_COURSE,
        payload: {
            userCourse
        },
        method: 'LOCAL',
    };
};

const setPostDataWithStatus = newData => {
    return {
        type: types.FEED_QUIZ_STATUS_FETCH_SUCCESS,
        meta: {
            response: newData,
            method: 'LOCAL',
        }
    };
};

const setCurrentActiveQuizIdInStore = (pkdId, quizId) => {
    return {
        type: types.SET_CURRENT_ACTIVE_QUIZ_SUCCESS,
        meta: {
            response: {
                mappingId: quizId,
                packageId: pkdId,
            },
            method: 'LOCAL',
        }
    };
};

const clearFeed = () => {
    return {
        type: types.CLEAR_FEED,
        meta: {
            response: {},
            method: 'LOCAL',
        }
    };
};
const setReferrer = () => {
    return {
        type: 'SET_REFERRER',
        meta: {
            response: {},
            method: 'LOCAL',
        }
    };
};

export {
    fetchMediaCoverage,
    fetchHomeRecommendations,
    fetchUsersAppreciation,
    setUserCourse,
    getRequesQuizStates,
    setPostDataWithStatus,
    setCurrentActiveQuizIdInStore,
    clearFeed,
    setReferrer
};
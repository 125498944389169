import React, { useState, useEffect, useMemo } from "react";
import { formatTimestampToHoursMinutesSeconds } from "../data/utils/helpers";

function CountDownTimer({ targetTime = 0, onTimerEnd = () => null }) {
  const calculateRemainingTime = () => {
    const currentTime = new Date().getTime();
    const remainingTime = targetTime - currentTime;
    return remainingTime > 0 ? remainingTime : 0;
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const updatedRemainingTime = calculateRemainingTime();
      if (updatedRemainingTime === 0) {
        clearInterval(timerInterval);
        // Call the onTimerEnd callback when the timer ends
        onTimerEnd(false);
      }
      setRemainingTime(updatedRemainingTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [targetTime, onTimerEnd]); // Include onTimerEnd in the dependency array

  const countDownTimer = useMemo(
    () => formatTimestampToHoursMinutesSeconds(remainingTime),
    [remainingTime]
  );
  return (
    <div>
      <p>{countDownTimer}</p>
    </div>
  );
}

export default CountDownTimer;

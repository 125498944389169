import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getMoengageMetadata } from "../../data/utils/helpers";
import HelpCard from "./HelpCard";
const CallNowWidget = ({
  contact,
  deviceType,
  showDirectCall = false,
  Getpayload,
  Position,
}) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const copyContactToClipboard = (contact) => {
    navigator.clipboard
      .writeText(contact)
      .then(() => {
        setTooltipText("Copied!");
        const toolTipTimeout = setTimeout(() => {
          setTooltipText("Copy to clipboard");
          clearTimeout(toolTipTimeout);
        }, 5000);
        return;
      })
      .catch(() => console.error("Failed to copy phone number to clipboard."));
  };

  const callNowMoengage = () => {
    let payload = Getpayload || {};
    let moePayload = getMoengageMetadata(payload);
    moePayload.Position = Position;
    moePayload.package_url = location?.href;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event("call_now_button_clicked", moePayload);
    }
  };
  const handleCallNow = (e) => {
    e.preventDefault();
    callNowMoengage();
    if (deviceType === "d") {
      copyContactToClipboard(contact);
    } else {
      window.open(`tel:${contact}`, "_blank");
    }
  };

  if (showDirectCall) {
    return (
        <HelpCard
          title="Call us directly for purchase related queries"
          subTitle="Mon - Sat | 9:00 am - 7:00 pm"
          CtaComp={
            <div className="actioncallus">
              <Link
                to="#"
                onClick={(e) => handleCallNow(e)}
                className="calltolltip"
              >
                Call now<span className="calltolltipText">{tooltipText}</span>
              </Link>
            </div>
          }
          btnInfo={`(${contact})`}
        />
    );
  } else {
    return (
      <>
        {deviceType === "d" ? (
          <div className="groupmeetlist webshow">
            <div className="icongroupmeet">
              <div className="callIcon" />
            </div>
            <span className="nameigroup">For Admission Enquiry Call at </span>{" "}
            <Link
              to="#"
              className="calltolltip"
              onClick={(e) => handleCallNow(e)}
            >
              {contact}
              <span className="calltolltipText">{tooltipText}</span>
            </Link>
          </div>
        ) : (
          <div
            className="groupmeetlist mwebshow"
            onClick={(e) => handleCallNow(e)}
          >
            <div className="icongroupmeet">
              <div className="callIcon" />
            </div>
            <div className="callustextmwb">
              <span className="nameigroup">For Admission Enquiry </span>{" "}
              <span>({contact})</span>
            </div>
            <div className="btnmobilcallus">
              <span>Call Now</span>
            </div>
          </div>
        )}
      </>
    );
  }
};
export default CallNowWidget;

import React, { useState } from "react";
import { copyToClipBoard } from "../../data/utils/helpers";

const SocialShare = ({
  textToShare = "",
  clickToCopyNeed = true,
  onClickCb = () => null,
}) => {
  const [copiedText, setCopiedText] = useState("");
  const handleShare = (plt = "", url = "") => {
    if (onClickCb) onClickCb(plt);
    window.open(url, "_blank");
  };
  const socialPlatforms = [
    {
      name: "Telegram",
      redirectUrl: `https://t.me/share/url?url=${encodeURIComponent(
        textToShare
      )}`,
      icon: "/images/telegram.svg",
    },
    {
      name: "Whatsapp",
      redirectUrl: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        textToShare
      )}`,
      icon: "/images/whatsapp.svg",
    },
    {
      name: "Facebook",
      redirectUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        textToShare
      )}`,
      icon: "/images/facebook1.svg",
    },
    {
      name: "Twitter",
      redirectUrl: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        textToShare
      )}`,
      icon: "/images/twitter.svg",
    },
    /* Add more social sharing as needed */
  ];
  const copyUrlHandler = (urlToCopy = "") => {
    copyToClipBoard(urlToCopy)
      .then(() => {
        setCopiedText("Link copied");
        const toolTipTimeout = setTimeout(() => {
          setCopiedText("");
          clearTimeout(toolTipTimeout);
        }, 5000);
        return;
      })
      .catch(() => setCopiedText("Failed to copy link!."));
  };
  return (
    <div className="sc-wrap">
      <div className="sc-items">
        {clickToCopyNeed && (
          <button className="scBtn" onClick={() => copyUrlHandler(textToShare)}>
            <img src="/images/linkattach.svg" lat="copy-link" />
            Copy Link
          </button>
        )}
        {socialPlatforms?.map((plt) => (
          <button
            className="scBtn"
            onClick={() => handleShare(plt?.name, plt?.redirectUrl)}
            key={plt?.name}
          >
            <img src={plt?.icon} lat={plt?.name} />
            {plt?.name}
          </button>
        ))}
      </div>
      {copiedText && <span class="sc-linkcopied">{copiedText}</span>}
    </div>
  );
};

export default SocialShare;

import React, { useEffect, useState } from "react";
import { isADDA } from "../../data/utils/helpers"; 
const LeftPanelLoginModal = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    var interval = setInterval(() => {
      if (activeIndex >= data.length - 1) {
        setActiveIndex(0);
      } else setActiveIndex(activeIndex + 1);
    }, 1500);

    return () => clearInterval(interval);
  }, [activeIndex]);
  let data = [];
  if(isADDA) {
    data = [
      {
        id: 0,
        src: "/images/newloginpopimg1.svg",
        heading: "Largest Learning Destination",
        sub_head: "10+ crore students' learning and sharing community",
      },
      {
        id: 1,
        src: "/images/newloginpopimg2.svg",
        heading: "Highest Success Numbers",
        sub_head: "1 Lakh+ government job selections",
      },
      {
        id: 2,
        src: "/images/newloginpopimg3.svg",
        heading: "Unmatched Tests & Video Classes",
        sub_head: "10,000+ tests,5000+ hours of Recorded and Live sessions",
      },
      {
        id: 3,
        src: "/images/newloginpopimg4.svg",
        heading: "Stay ahead of the pack",
        sub_head: "Latest Exam notifications,Job alerts,Current affairs",
      },
    ];
  } else {
    data = [
      {
        id: 0,
        src: "/images/sankalp-images/onboard-slider-img-1.svg",
        preHighlightText: "",
        highlightText: "Sankalp ",
        postHighlightText: "Selection ka",
        footer: "India’s most loved & largest platform for IIT and NEET exam preparation",
      },
      {
        id: 1,
        src: "/images/sankalp-images/onboard-slider-img-2.svg",
        preHighlightText: "Best-in-Class ",
        highlightText: "Study ",
        postHighlightText: "Material",
        footer: "Test Series, Live Class, Sample papers, E-Books, Notes & PDFs",
      },
      {
        id: 2,
        src: "/images/sankalp-images/onboard-slider-img-3.svg",
        preHighlightText: "Ask your ",
        highlightText: "Doubts",
        postHighlightText: "",
        footer: "Superfast Doubt solution by experts",
      },
      {
        id: 3,
        src: "/images/sankalp-images/onboard-slider-img-4.svg",
        preHighlightText: "",
        highlightText: "Exam ",
        postHighlightText: "Oriented Tests and Video Classes",
        footer: "10k+ tests, 5k+ hours of Recorded Video Classes",
      },
    ];
  }
  return (
    <div class="left-panel-login" data-testid="left-panel-login-modal">
      <div class="left-panel-scroller">
        <div class="left-panel-box">
          {
            isADDA ? <div class="newl-login-punch">
            Join India's largest learning destination <br />{" "}
            <img src="/images/header-logo.svg" />
          </div> : "" 
          }
          {data
            .filter((item) => item.id == activeIndex)
            .map((item) => {
              return (
                <div key={item.id}>
                  <div class="left-panel-img">
                    <img src={item.src} />
                  </div>
                  {isADDA ? "" : <div className="sankalp-punchline">
                    <span>{item.preHighlightText}</span>
                    <span className="sankalp-punchline-highlight">{item.highlightText}</span>
                    <span>{item.postHighlightText}</span>
                  </div>}
                  {
                    isADDA ? <div class="heading-panel-left">{item.heading}</div> : <div class="heading-panel-left">{item.footer}</div>
                  }
                </div>
              );
            })}
        </div>
        <div className="left-panel-dots">
          {Array.from(Array(data.length).keys()).map((item) => (
            <div
              data-testid="panel-dot"
              key={item}
              className={
                activeIndex == item ? "panel-dots active" : "panel-dots"
              }
              onClick={() => setActiveIndex(item)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default LeftPanelLoginModal;

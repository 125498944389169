import React, { useEffect, useState } from "react";
import CustomModal from "./library/CustomModal";
import { updateHash } from "../data/utils/helpers";
import FreeContentTestModal from "./FreeContentTestModal";
import FreeContentEbooksModal from "./FreeContentEbooksModal";

const FreeContentModal = (props) => {
  let testContentLength = props.productData.freeContentData?.filter(item => item.contentType == 1)?.length;
  let ebookContentLength = props.productData.freeContentData?.filter(item => item.contentType == 4)?.length;
  const TabList = [
    { id: 0, label: `Test (${testContentLength})` ,tabName:"test", itemLength : testContentLength },
    { id: 1, label: `Ebooks (${ebookContentLength})` ,tabName:"ebooks", itemLength : ebookContentLength},
  ];
  let ModalBodyComponent = "";

  const activeTab=(id)=>{
    updateHash(`freeContent/3939/${id}`); 
  }
  const [hashUrl, setHashUrl] = useState("");
  useEffect(() => {
    if(location.hash){
      setHashUrl(location.hash);
    }
    window.addEventListener("hashchange", ()=>{
      setHashUrl(location.hash);
    }, false);
  }, []);

  const close = () => {
    props.setFreeContentModalVisible(false);
    updateHash(''); 
  };

  let hashArray = hashUrl.split("/");
  switch (hashArray[2]) {
    case "test":
      ModalBodyComponent = (
        // <Suspense fallback={<div></div>}>
          <FreeContentTestModal
            TabList={TabList}
            id={hashArray[2]}
            onSelect={activeTab}
            productData={props.productData}
            closeCallback={close} 
          ></FreeContentTestModal>
        // </Suspense>
      );
      break;
    case "ebooks":
      ModalBodyComponent = (
        // <Suspense fallback={<div></div>}>
          <FreeContentEbooksModal
            TabList={TabList}
            id={hashArray[2]}
            onSelect={activeTab}
            productData={props.productData}
            closeCallback={close}
          ></FreeContentEbooksModal>
        // </Suspense>
      );
      break;
  }

  return (
    <>
      <CustomModal
        footer={null}
        visible={props.freeContentModalVisible}
        title={
          <div key="packages-header" className={"modal-header"}>
            {" "}
            {props?.productData?.title}
          </div>
        }
        onBack={close}
        onCancel={close}
        closeCallback={close}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{
          height: "630px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        modalClassName={"largemodal"}
      >
        {ModalBodyComponent}
      </CustomModal>
    </>
  );
};

export default FreeContentModal;

import reducerRegistry from "../../reducerRegistry";
import types from "./types";

function continueReading(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_READING_LIST_DATA:
      return {
        ...state,
        data: state.data ? [...state.data, ...action.payload] : action.payload,
      };
    case types.UPDATE_READING_LIST_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case types.REPLACE_READING_LIST_DATA:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
}

reducerRegistry.register("continueReading", continueReading);
export default continueReading;

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showButtonToolTip } from "../../data/ducks/toast/actions";
import PropTypes from "prop-types";

/**
 * AlertToolTipButton component displays a button with a tooltip.
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.Element} props.iconElement - Icon or content to display inside the button.
 * @param {string} props.tooltipText - Text for the tooltip.
 * @param {string} props.wrapClassName - CSS class for the wrapper div.
 * @param {string} props.iconClassName - CSS class for the button icon.
 * @param {string} props.tooltipClassName - CSS class for the tooltip text.
 * @param {function} props.onClickCb - Callback function to execute on button click.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {boolean} props.isHoverToolTipEnable - Enable showing tooltip on hover.
 * @param {string} props.toolTipName - Name of the tooltip.
 *
 * @returns {React.Element} Rendered component
 */
const AlertToolTipButton = ({
  iconElement = "",
  tooltipText = "",
  wrapClassName = "custom-tooltipWrap",
  iconClassName = "custom-tooltipIcon",
  tooltipClassName = "custom-tooltipText",
  onClickCb = () => {},
  disabled = false,
  isHoverToolTipEnable = false,
  toolTipName = "",
}) => {
  const { toolTipButtonInfo } = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  /**
   * Dispatches an action to show/hide the button's tooltip.
   *
   * @param {boolean} flag - Whether to show the tooltip.
   */
  const tooltipDisPatchEventHandler = (flag = false) => {
    dispatch(
      showButtonToolTip({
        isToolTipVisible: flag,
        toolTipName,
        tooltipText,
      })
    );
  };

  /**
   * Handles the button click event.
   */
  const handleButtonClick = (e) => {
    if (disabled) return;
    onClickCb(e);
  };

  /**
   * Checks if the tooltip should be shown.
   */
  const isToolTipShow = useMemo(
    () =>
      toolTipName === toolTipButtonInfo?.toolTipName &&
      toolTipButtonInfo?.isToolTipVisible,
    [toolTipName, toolTipButtonInfo]
  );

  useEffect(() => {
    // if isHoverToolTipEnable flag is true then return timeout code..
    if (isHoverToolTipEnable) return;
    let toolTipTimeout;

    if (isToolTipShow) {
      toolTipTimeout = setTimeout(() => {
        tooltipDisPatchEventHandler(false);
      }, toolTipButtonInfo?.duration);
    } else {
      clearTimeout(toolTipTimeout);
    }

    return () => {
      clearTimeout(toolTipTimeout);
    };
  }, [toolTipButtonInfo]);

  /**
   * Handles mouse hover events to show/hide the tooltip.
   *
   * @param {boolean} isVisible - Whether the tooltip should be shown.
   */
  const showHoverToolTipHandler = (isVisible) => {
    if (isHoverToolTipEnable) tooltipDisPatchEventHandler(isVisible);
  };

  return (
    <div className={wrapClassName}>
      <button
        className={iconClassName}
        onClick={handleButtonClick}
        disabled={disabled}
        onMouseLeave={() => showHoverToolTipHandler(false)}
        onMouseOver={() => showHoverToolTipHandler(true)}
      >
        {iconElement}
      </button>
      {isToolTipShow && (
        <span className={tooltipClassName}>
          {toolTipButtonInfo?.tooltipText}
        </span>
      )}
    </div>
  );
};

AlertToolTipButton.propTypes = {
  iconElement: PropTypes.element,
  tooltipText: PropTypes.string,
  wrapClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  onClickCb: PropTypes.func,
  disabled: PropTypes.bool,
  isHoverToolTipEnable: PropTypes.bool,
  toolTipName: PropTypes.string,
};

export default React.memo(AlertToolTipButton);

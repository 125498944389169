import React, { useEffect, useState, useRef, useMemo } from "react";
import { smoothSlideScroll } from "../../data/utils/helpers";
import { throttle } from "../utils";
import MahaPackComparison from "./MahaPackComparison";

const ProductDetailDescription = ({
  description,
  overview,
  title,
  additionalDesc,
  mahapackJson,
  faqJson,
  testimonialsData,
  subCategoriesData,
  Getpayload,
  isPurchased,
  isMobile,
  showOverlay,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const isScrollRightHappened = useRef(false);
  const [wrapperStyles, setWrapperStyles] = useState({});
  const handleNavClick = (id) => {
    const element = document.getElementById(id);
    const offset = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      // top: offset - 100,
      top:
        offset - (isMobile ? 120 : Number(wrapperStyles?.top || 0) + (65 + 38)),
      behavior: "smooth",
    });
  };
  useEffect(() => {
    let desc = document.getElementsByClassName("pdp-desc");
    for (let i = 0; i < desc.length; i++) {
      let anchors = desc[i].getElementsByTagName("a");
      for (let i = 0; i < anchors.length; i++) {
        anchors[i].setAttribute("target", "_blank");
        anchors[i].setAttribute("class", "pdp-desc-link");
      }
    }
  }, [description, overview, additionalDesc]);



  const sliderRef = useRef(null);

  // this useEffect set wrapper sticky top value based on overlaycard height only for web
  useEffect(() => {
    if (!isMobile && showOverlay) {
      setWrapperStyles({
        top: document?.getElementById("PDPOverlayCard")?.offsetHeight,
      });
    }
  }, [isMobile, showOverlay]);

  const newDescShow = useMemo(() => overview?.length > 0, [overview]);
  const additional = useMemo(
    () => additionalDesc?.filter((item) => item?.desc),
    [additionalDesc]
  );
  const descriptionTabs = useMemo(() => {
    // default overview tab
    let tabs = ["Overview"];
    // make title as tab from additional
    additional?.forEach((desc) => tabs.push(desc?.title));
    // make tabs FAQs and Testimonials if data present
    if (faqJson?.length) {
      tabs.push("FAQs");
    }
    if (testimonialsData?.length) {
      tabs.push("Testimonials");
    }
    // set 0 index as deafult active tab
    setActiveLink(tabs[0]);
    return tabs;
  }, [additional]);

  /**
   * Handles the scroll event and updates the active link based on the scroll position.
   */
  useEffect(() => {
    if (!descriptionTabs?.length) return;
    // BY PASS BLEOW CODE if description List not available

    /**
     * Scrolls the description slider based on the scroll position.
     */
    const handleScroll = () => {
      const sections = [...descriptionTabs];
      const scrollPosition = window.scrollY;
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const element = document.getElementById(section);
        if (element) {
          const offsetTop =
            element.getBoundingClientRect().top +
            window.pageYOffset -
            (isMobile ? 121 : Number(wrapperStyles?.top || 0) + (65 + 39)); //extra offset position
          if (scrollPosition >= Number(offsetTop.toFixed(0))) {
            const activeDescIndex = descriptionTabs?.indexOf(section);
            // for web scroll
            // isScrollRightHappened flag is to identify scroll happened only for web view,for better optimization
            if (!isMobile) {
              if (!isScrollRightHappened.current && activeDescIndex >= 6) {
                isScrollRightHappened.current = true;
                smoothSlideScroll(sliderRef, true, 600);
              } else if (
                isScrollRightHappened.current &&
                activeDescIndex <= 3
              ) {
                smoothSlideScroll(sliderRef, false, 600);
                isScrollRightHappened.current = false;
              }
            } else if (isMobile) {
              // for mweb scroll
              // Scroll to the active element
              const activeElement = document.querySelector(".activeNav");
              if (activeElement) {
                const elementRect = element.getBoundingClientRect();
                const activeRect = activeElement.getBoundingClientRect();
                const elementLeft = elementRect.left;
                const activeLeft = activeRect.left;
                const scrollLeft = element.scrollLeft;
                const scrollValue = activeLeft - elementLeft + scrollLeft;
                smoothSlideScroll(sliderRef, true, scrollValue - scrollLeft);
              }
            }
            setActiveLink(section);
            break;
          }
        }
      }
    };
    // adding scroll listner on mounting
    window.addEventListener("scroll", handleScroll);

    // clean up scroll listener on unmounting
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [descriptionTabs, wrapperStyles]);

  return (
    <>
      {newDescShow ? (
        <>
          <div className="pdpOverviewNav" style={wrapperStyles}>
            <div className="pdp-container slider-wrapper">
              <ul className="slider" ref={sliderRef}>
                {descriptionTabs?.map((tab) => (
                  <li
                    key={tab}
                    onClick={() => handleNavClick(tab)}
                    className={activeLink === tab ? "activeNav" : ""}
                  >
                    <span className="navLink">{tab}</span>
                  </li>
                ))}
              </ul>
              {descriptionTabs?.length > 7 && (
                <>
                  <div
                    className="arrow left"
                    onClick={() => smoothSlideScroll(sliderRef, false, 100)}
                  >
                    <img
                      src={"/images/pdpslidebtnarrow.svg"}
                      alt="button-left"
                      title="prev"
                      style={{
                        transform: "rotate(180deg)",
                        outlineStyle: "none",
                      }}
                    />{" "}
                  </div>
                  <div
                    className="arrow right"
                    onClick={() => smoothSlideScroll(sliderRef, true, 100)}
                  >
                    <img
                      src={"/images/pdpslidebtnarrow.svg"}
                      alt="button-right"
                      title="next"
                      style={{ outlineStyle: "none" }}
                    />{" "}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="pdpOverviewDetail">
            <div className="pdp-container">
              <section id="Overview">
                <h3 className="pdpSimpleHeading">Overview</h3>
                {overview && (
                  <div className="overview-container">
                    <div
                      className={`pdp-overview productInfoBody pdp-desc ${
                        showMore ? "" : "overview-less"
                      }`}
                      dangerouslySetInnerHTML={{ __html: overview }}
                    />
                    {isMobile && (
                      <div
                        className={`tablebtnbottom ${
                          showMore ? "upshowbtn" : ""
                        }`}
                        onClick={() => setShowMore((old) => !old)}
                      >
                        Show {showMore ? "Less" : "More"}
                      </div>
                    )}
                  </div>
                )}
              </section>
              {additional?.map((item, idx) => (
                <section
                  className="m40"
                  id={item?.title}
                  key={item?.title + idx}
                >
                  <h3 className="pdpSimpleHeading">{item?.title}</h3>
                  <div
                    className="productInfoBody pdp-desc"
                    dangerouslySetInnerHTML={{
                      __html: item?.desc,
                    }}
                  />
                </section>
              ))}

              {/* Mahapack comparision */}
              {mahapackJson && mahapackJson?.length > 0 && (
                <div className="pdpComparison">
                  <div className="pdpCommanHeading">
                    Comparison with <span>MahaPack</span>
                  </div>
                  <MahaPackComparison
                    mahapackComparisonData={mahapackJson}
                    title={title}
                    GetPayload={Getpayload}
                    isPurchased={isPurchased}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="pdp-container">
          <div className="pdpCommanHeading">
            Product <span>Description</span>
          </div>
          <div
            className="productInfoBody pdp-desc"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      )}
    </>
  );
};

export default ProductDetailDescription;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import CustomLoader from "../../../components/library/CustomLoader";
import {
  getManageExamsByPackage,
  savePackageExam,
} from "../../../data/ducks/header/actions";
import {
  capitalizeWords,
  removeHyphensAndInsertSpaces,
} from "../../../data/utils/helpers";
import PPCWrapper from "../PPCWrapper";
import ExamList from "./ExamList";

const SelectExams = ({}) => {
  const [loading, setLoading] = useState(true);
  const [saveExamLoading, setSaveExamLoading] = useState(false);
  const [examList, setExamList] = useState([]);
  const [filteredExamList, setFilteredExamList] = useState([]);
  const [preSelectedExamsList, setPreSelectedExamsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { packageExamsData } = useSelector((state) => state.header);

  const dispatch = useDispatch();
  const { packageId, packageName } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  let { manageExams } = packageExamsData;
  /** (modifyExamListData) --
   * here we modify the examsList data based on the selectedExamsList coming from the API.
   * used for showing already selected exams.
   */

  const modifyExamListData = (manageExamData) => {
    let examList = manageExamData?.examList;
    let selectedExamListIds =
      manageExamData?.selectedExamList?.map((exam) => exam.id) || [];
    let newExamsList = examList?.map((exam) => {
      return {
        ...exam,
        active: selectedExamListIds?.includes(exam.id),
      };
    });
    setExamList(newExamsList);
    setPreSelectedExamsList(selectedExamListIds);
  };

  /**
   *
   * @param {selectExam} called on clicking on examCard label.
   * usage -- here handling the selection of the exam.
   */
  const selectExam = (newId) => {
    let updatedPreSelectedExams = [...preSelectedExamsList];
    // check is passed examId is already present in the array.
    if (!updatedPreSelectedExams.includes(newId)) {
      updatedPreSelectedExams.push(newId);
    }
    // if passed examId is already there than remove that id from the array.
    else {
      updatedPreSelectedExams = updatedPreSelectedExams.filter(
        (el) => el !== newId
      );
    }
    // after modification update the preSelectedExamsList state with the updated array.
    setPreSelectedExamsList(updatedPreSelectedExams);

    // now update the examList state with new selected exams,so that the active exams will reflect on the UI.
    let updatedExamList = [];
    updatedExamList = examList?.map((exam) => {
      return {
        ...exam,
        active: updatedPreSelectedExams?.includes(exam.id),
      };
    });
    /**
     * run this below code only when searchTerm is present.
     * usage:- this will update the DOM for the filtered state data (when user mark (check/uncheck) on clicking over showed result.).
     */
    if (searchTerm) {
      let selectedFilteredExamListData = filteredExamList?.map((exam) => {
        return {
          ...exam,
          active: updatedPreSelectedExams?.includes(exam.id),
        };
      });
      setFilteredExamList(selectedFilteredExamListData);
    }
    setExamList(updatedExamList);
  };
  /**
   *
   * @param {saveExam}
   * usage -- call the service and update the preSelectedExams data with the new passed ExamsId.
   */
  const saveExam = async () => {
    // at least you have to select 1 exam from the list.
    if (!preSelectedExamsList?.length) {
      alert("Please select any one exam to proceed! ");
      return false;
    }
    if (!saveExamLoading) {
      setSaveExamLoading(true);
      const res = await dispatch(
        savePackageExam(packageId, preSelectedExamsList)
      );
      let result = await res[0];
      let { childPackageCount } = result?.data || 0;
      /**
       * if number of selected exams is 1, then redirect user on the list-view screen.
       * if in response childPackageCount > 20 and number of selected exams >= 2, then redirect user on the your-exam screen.
       * if in response childPackageCount < 20 and number of selected exams >= 2, then redirect user on the list-view screen.
       */
      let redirectUrl;
      if (preSelectedExamsList?.length === 1) {
        redirectUrl = `/my-purchase/${packageId}/${packageName}`;
      } else if (preSelectedExamsList?.length >= 2 && childPackageCount > 20) {
        redirectUrl = `/my-purchase/${packageId}/${packageName}/exams`;
      } else if (preSelectedExamsList?.length >= 2 && childPackageCount < 20) {
        redirectUrl = `/my-purchase/${packageId}/${packageName}`;
      } else {
        //default case:
        redirectUrl = "/my-purchase";
      }
      history.replace(redirectUrl);
    }
  };

  /**
   *
   * @param {getManageExam}
   * usage -- call the service and provide list of manageExam Data.
   */
  const getManageExam = (packageId) => {
    dispatch(getManageExamsByPackage(packageId)).then((res) => {
      setLoading(false);
    });
  };

  /**
   *
   * @param {searchExam}
   * usage -- show the exam which matches the searchTerm enter in input.
   */

  const searchExam = (value) => {
    const filteredList = examList?.filter((exam) =>
      exam.name.toUpperCase().includes(value.toUpperCase())
    );
    setFilteredExamList(filteredList);
  };

  useEffect(() => {
    if (packageId) {
      getManageExam(packageId);
    }
  }, [packageId]);

  useEffect(() => {
    if (manageExams && Object.keys(manageExams).length) {
      modifyExamListData(manageExams);
    }
  }, [manageExams]);
  useEffect(() => {
    if (searchExam) {
      searchExam(searchTerm);
    }
  }, [searchTerm]);
  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
    {
      route: pathname,
      name: "SelectExam",
    },
  ];
  // redirect to productDetail view page,when only single examList is present.
  // this screen will only show for package with multiple examList.
  if (examList?.length === 1)
    return <Redirect to={`${pathname.replace("/selectExams", "")}`} />;
  return (
    <PPCWrapper
      breadCrumbRoutes={breadCrumbsList}
      pageTitle={capitalizeWords(removeHyphensAndInsertSpaces(packageName))}
      rightSection={<></>}
    >
      <section className="exam-selection-wrap">
        <div className="ppc-container">
          {/* this will available for WEB view. */}
          <div className="exam-selection-content exam-selection-web">
            <div className="exam-selection-left-img">
              <img
                src="/images/exam-select-left.svg"
                alt="Search-Your-Exam"
                fetchpriority="high"
              />
            </div>
            <div className="exam-options-wrap">
              <div className="exam-option-header">
                <div className="heading-exam-priority">
                  <div className="txt-select-priority">
                    Select Your Exam Priority
                  </div>
                  <div className="sub-txt-select-priority">
                    (You can always change them later)
                  </div>
                </div>
                <div className="exam-selection-search">
                  <input
                    type="search"
                    placeholder="Search your exam"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="searchicon">
                    <img src="/images/ppc-search-icon.svg" />
                  </div>
                </div>
              </div>
              {loading ? (
                <CustomLoader />
              ) : (
                <ExamList
                  examList={searchTerm ? filteredExamList : examList} // show filteredExamList when searchTerm is present.
                  saveExamCb={saveExam}
                  selectExamCb={selectExam}
                  saveExamLoading={saveExamLoading}
                />
              )}
            </div>
          </div>
          {/* this will available for Mweb view. */}

          <div className="exam-selection-mweb">
            <div className="exam-mweb-search-wrap">
              <div className="mweb-search-top-heading">
                Select your Exam Priority
              </div>
              <div className="exam-selection-search">
                <input
                  type="search"
                  placeholder="Search your exam"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="searchicon">
                  <img src="/images/ppc-search-icon.svg" />
                </div>
              </div>
              <div className="mweb-search-bottom-sub">
                (You can always change them later)
              </div>
              {loading ? (
                <CustomLoader />
              ) : (
                <ExamList
                  examList={searchTerm ? filteredExamList : examList} // show filteredExamList when searchTerm is present.
                  saveExamCb={saveExam}
                  selectExamCb={selectExam}
                  saveExamLoading={saveExamLoading}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </PPCWrapper>
  );
};

export default SelectExams;

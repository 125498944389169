import React, { useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { Redirect, useHistory } from 'react-router-dom';
import CustomLoader from "../../components/library/CustomLoader";
import { dateMonthFullTime, dateMonthTime, dateSuffix } from "../../components/utils";
import { getDeviceType, getMoengageMetadata, isADDA, queryStringToObject, secondsToHms } from "../../data/utils/helpers";
import { TEST_SERIES_URL, TIMELINE_URI } from "../../../constants/appUrls";
import fetch from "../../data/utils/fetch";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { loginFlag } from "../../data/ducks/header/actions";
import CustomLoaderHeaderFooterHide from "../../components/library/CustomLoaderHeaderFooterHide";
import ContineInApp from "../../components/library/ContinueInApp/ContinueInApp";
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS } from "../../../constants/textConstants";
import { openTestPortal } from "../../components/enrolledCourses/TestSeries/TakeTest/util";

const ScholarshipMain = (props) => {
  const [scholarshipData, SetScholarshipData] = useState(null);
  const [windowTimeFlag, setWindowTimeFlag] = useState(false);
  const historyy = useHistory()
  const [isMobile, setIsMobile] = useState(false);
  const cookie = new Cookies();
  const date = new Date();
  const [buttonState, setButtonState] = useState("");
  const [quizStatusObject, setQuizStatusObject] = useState({});
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [loadingQuizState, setLoadingQuizState] = useState(false);
  const [loadingRegisterStatus, setLoadingRegisterStatus] = useState(false);
  const [flagtoFetchQuizState, setFlagtoFetchQuizState] = useState(false);
  const [isPreRegistrationView, setIsPreRegistrationView] = useState(true);
  const [pendingButtonClick, setPendingButtonClick] = useState(false);
  const [redirectToMockListingFlag, setRedirectToMockListingFlag] = useState(false);
  const [localRemainingTime, setLocalRemainingTime] = useState(null);
  const [isTimeLimitSectionWise, setIsTimeLimitSectionWise] = useState(false);
  const [localRemainingTimeAtSomePointTime, setLocalRemainingTimeAtSomePointTime] = useState(null);
  const [pointOfTime, setPointOfTime] = useState(null);

  const mappingId = props.match.params.scholarshiptType;
  let currentPath = props?.history?.location?.pathname;
  currentPath = currentPath.replace(/\/+$/g, '');
  const [faqData, SetFaqData] = useState([
    {
      heading: `What is a Payment Gateway?`,
      discription: `A payment gateway as a merchant service that processes credit card payments for
          ecommerce sites and
          traditional brick and mortar stores. Popular payment gateways include
          PayPal/Braintree, Stripe, and
          Square.`,
    },
    {
      heading: `Do I need to pay to Instapay even when there is no transaction
          going on in my
          business?`,
      discription: `TEXT 2`,
    },
    {
      heading: `What platforms does ACME payment gateway support?`,
      discription: `TEXT 3`,
    },
    {
      heading: `Is there any setup fee or annual maintainance fee that I need
          to pay regularly?`,
      discription: `TEXT 4`,
    },
  ]);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [loading, setLoading] = useState(true);
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [clickedItemData, setClickedItemData] = useState();

  useEffect(() => {
    if(scholarshipData){
      setLocalRemainingTimeAtSomePointTime(localStorage.getItem(
        `${scholarshipData.mappingId}RemainingTime`
      ))
      setPointOfTime(localStorage.getItem(`${scholarshipData.mappingId}whichPointOfTime`))
      if(scholarshipData?.sections?.[0]?.secTime > 0){
        setIsTimeLimitSectionWise(true);
      }
    }
  },[scholarshipData])

  useEffect(() => {
    if (isTimeLimitSectionWise && pointOfTime) {
      const timePassed = (currentTime - pointOfTime) / 1000;
      const remainingTime = localRemainingTimeAtSomePointTime - timePassed;
      if (remainingTime < 0) {
        setLocalRemainingTime(0);
      } else {
        setLocalRemainingTime(Math.ceil(remainingTime) * 1000);
      }
    }
  }, [currentTime, isTimeLimitSectionWise, localRemainingTimeAtSomePointTime, pointOfTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, [1000])

    const fun = () => {
      if (localStorage.getItem("isTestPortalOpened") == "yes") {
        // test window opened for fixed mock
        localStorage.setItem("isTestPortalOpened", "no");
        setFlagtoFetchQuizState(old => !old);
      }
    }
    window.addEventListener("focus", fun);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", fun);
    }
  }, [])

  useEffect(() => {
    const tokenFlag = props?.state?.header?.jwtToken?.length > 0;
    if(pendingButtonClick && tokenFlag && !loading && !loadingQuizState && !loadingRegisterStatus && buttonState){
      setPendingButtonClick(false);
      handleButtonClick();
    }
  },[pendingButtonClick, props.loginFlagData, loading, loadingQuizState, loadingRegisterStatus, buttonState])

  useEffect(() => {
    const tokenFlag = props?.state?.header?.jwtToken?.length > 0;
    if(redirectToMockListingFlag && tokenFlag){
      setRedirectToMockListingFlag(false);
      redirectToMockListing();
    }
  },[redirectToMockListingFlag, props.loginFlagData])

  useEffect(() => {
    // removing pending actions if user closes the login modal
    const tokenFlag = props?.state?.header?.jwtToken?.length > 0;
    if(!props.loginFlagData && !tokenFlag){
      setPendingButtonClick(false);
      setRedirectToMockListingFlag(false);
    }
  },[props.loginFlagData])


  useEffect(() => {
    if (scholarshipData) {
      setIsPreRegistrationView(currentTime < scholarshipData.fixedMockTestStartTime && registrationStatus !== "Registered");
    }
  }, [registrationStatus, currentTime])
  useEffect(() => {
    if (props.state.header.jwtToken?.length > 0) {
      if (!scholarshipData) {
        getRegistrationStatus();
        getQuizState();
      }
      else {
        if (currentTime >= scholarshipData.fixedMockTestStartTime) {
          getQuizState();
        } else {
          getRegistrationStatus();
        }
      }
    }
  }, [props.state.header.jwtToken, flagtoFetchQuizState])

  useEffect(() => {
    const { history, match, location } = props;
    const { scholarshiptType } = match.params;
    let url = `${TIMELINE_URI}/v3/fixedMock?id=${scholarshiptType}`
    let urlArr = location.search.split("?");
    urlArr = urlArr[urlArr.length - 1].split("&");
    urlArr.map((item) => {
      let [key, value] = item.split("=");
      if (key === "platform" &&  (value === "and" || value === "ios")) {
        setIsAndroid(true);
        url = `${TIMELINE_URI}/v3/fixedMock?id=${scholarshiptType}`
      }
      if (key == "mode" && value == "night") {
        setIsDark(true);
      }
    });
    Axios.get(url)
      .then((response) => {
        response = response.data.data;
        setLoading(false);
        if (response.length > 0) {
          SetScholarshipData(response[0]);
          let faqJson = JSON.parse(response[0].fixedMockFaqJson || '{}');
          SetFaqData(faqJson);
          let time = date.getTime();
          let attemptWindowFlag = false;
          if (time > response[0].fixedMockTestStartTime && time < response[0].fixedMockSubmitTime) {
            attemptWindowFlag = true;
          } else {
            attemptWindowFlag = false;
          }
          setWindowTimeFlag(attemptWindowFlag);
          checkForDevice(attemptWindowFlag);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  useEffect(() => {
    if (!scholarshipData) {
      return;
    }
    if (props.state.header.jwtToken) {
      if (currentTime < scholarshipData.fixedMockTestStartTime && !registrationStatus) {
        setButtonState("Click to Register");
      } else if (currentTime < scholarshipData.fixedMockTestStartTime && registrationStatus) {
        setButtonState("Registered");
      } else if (currentTime < scholarshipData.fixedMockSubmitTime && quizStatusObject.testState == "NOT_STARTED" && !localStorage.getItem(`${scholarshipData.mappingId}Submitted`)) {
        setButtonState("Attempt");
      } else if (currentTime < scholarshipData.fixedMockSubmitTime && quizStatusObject.testState == "INCOMPLETE" && getRemainingTime() != 0 && !localStorage.getItem(`${scholarshipData.mappingId}Submitted`)) {
        setButtonState("Resume");
      } else if (currentTime < scholarshipData.fixedMockResultTime) {
        setButtonState("Result Awaited");
      } else if (currentTime >= scholarshipData.fixedMockResultTime) {
        setButtonState("View Result");
      } else {
        setButtonState("");
      }
    } else {
      if (currentTime < scholarshipData.fixedMockTestStartTime) {
        setButtonState("Click to Register");
      } else if (currentTime < scholarshipData.fixedMockSubmitTime) {
        setButtonState("Attempt");
      } else if (currentTime < scholarshipData.fixedMockResultTime) {
        setButtonState("Result Awaited");
      } else if (currentTime >= scholarshipData.fixedMockResultTime) {
        setButtonState("View Result");
      } else {
        setButtonState("");
      }
    }
  }, [currentTime, props.state.header.jwtToken, registrationStatus, quizStatusObject]);


  const checkForDevice = (attemptWindowFlag) => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      if (attemptWindowFlag) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    } else {
      setIsMobile(false);
    }
  };

  const redirectToMockListing = () => {
    if (props.state.header.jwtToken) {
      localStorage.setItem('freeQuizTabId', 2);
      props.history.push(
        {
          pathname: '/my-mock-test',
          state: { 'freeQuizTabId': 2 }
        }
      );
    } else {
      localStorage.setItem('freeQuizTabId', 2);
      localStorage.setItem('urlForFreeQuizzes', '/my-mock-test');
      props.loginFlag(true);
      setRedirectToMockListingFlag(true);
      if (currentPath) props.history.push(currentPath + "/#show-login");
    }
  }


  useEffect(() => {
    if (isAndroid) {
      {
        const scholorshipMain = document.getElementsByClassName("scholarship-main")[0]
        const headerComponent =
          document.getElementsByClassName("headerwrap")[0];
        const footerComponent =
          document.getElementsByClassName("new-footer")[0];
        const needHelpClass = document.getElementById("fc_widget");
        if (headerComponent) {
          headerComponent.style.display = "none";
        }
        if (footerComponent) {
          footerComponent.style.display = "none";
        }
        if (needHelpClass) {
          needHelpClass.className = "fc_widget-android";
        }
        if (scholorshipMain) {
          if (isDark) {
            scholorshipMain.className = "scholarship-main scholarship-main-android mblDark";
          } else {
            scholorshipMain.className = "scholarship-main scholarship-main-android";
          }
        }
      }
    }
  }, [isAndroid]);

  useEffect(() => {
    let accItem = document.getElementsByClassName("accordion-item-sch");
    for (let i = 0; i < accItem.length; i++) {
      accItem[i].className = "accordion-item-sch close";
    }
  }, []);
  const toggleItem = (index) => {
    let accItem = document.getElementsByClassName("accordion-item-sch");
    let itemClass = accItem[index].className;
    for (let i = 0; i < accItem.length; i++) {
      accItem[i].className = "accordion-item-sch close";
    }
    if (itemClass == "accordion-item-sch close") {
      accItem[index].className = "accordion-item-sch open";
    }
  };

  const getTimeObj = (time) => {
    let dateTime = dateMonthTime(time);
    let splittedArray = dateTime.dateMonthTime.split(" ");
    return splittedArray;
  };
  const getTimeObjTopCard = (time) => {
    let dateTime = dateMonthFullTime(time);
    let splittedArray = dateTime.dateMonthTime.split(" ");
    return splittedArray;
  };
  const renderTestStartTime = (time) => {
    let splittedArray = getTimeObjTopCard(time);
    return (
      <>
        <div class="banner-cal-header">Test Starts at </div>
        <div class="banner-cal-date-wrap">
          <div className="bannercal-date-day">
            <div class="banner-cal-date">
              {" " + splittedArray[0]}<sup>{dateSuffix(splittedArray[0])}</sup>
            </div>
            <div class="banner-cal-day">{splittedArray[1]}</div>
          </div>
          <div class="banner-cal-tile">
            {splittedArray[3]} {splittedArray[4]}
          </div>
        </div>
      </>
    );
  };

  const getRegistrationStatus = () => {
    setLoadingRegisterStatus(true);
    const url = `${TIMELINE_URI}/fixedMock/register/status?mappingIds=${mappingId}`;
    fetch(url, "GET", {}, "application/json", true, { "X-JWT-Token": props.state.header.jwtToken })
      .then((result) => {
        if (result.success && result.data) {
          setRegistrationStatus(result.data[0].isRegistered ? "Registered" : null);
        }
        setLoadingRegisterStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingRegisterStatus(false);
      });
  };

  const getQuizState = () => {
    setLoadingQuizState(true);
    let url = `${TIMELINE_URI}/fixedMock/status?mappingIds=${mappingId}`
    fetch(url, "GET", {}, "application/json", true, { "X-JWT-Token": props.state.header.jwtToken }).then(res => {
      if (res.success) {
        setQuizStatusObject(res.data[0]);
      }
      setLoadingQuizState(false);
    }).catch(err => { console.log("err: ", err); setLoadingQuizState(false); });
  }

  const getRemainingTime = () => {
    if(isTimeLimitSectionWise && localRemainingTime !== null){
      return localRemainingTime;
    }
    const timeElapsed = currentTime - quizStatusObject.userStartTime;
    const remainingTime = Math.min(scholarshipData.timeLimit * 1000 - timeElapsed, scholarshipData.fixedMockSubmitTime - currentTime);
    if (remainingTime < 0) {
      return 0;
    }
    return remainingTime;
  }

  const renderResultTime = (time) => {
    let splittedArray = getTimeObj(time);
    return (
      <div class="right-side-time-content">
        {splittedArray[3] + " " + splittedArray[4]},
        {" " + splittedArray[0]}<sup>{dateSuffix(splittedArray[0]) + " "}</sup>
        {splittedArray[1]}
      </div>
    );
  };
  const renderAttemptWindow = (startTime, submitTime) => {
    let splittedStartArray = getTimeObj(startTime);
    let splittedSubmitArray = getTimeObj(submitTime);

    return (
      <div class="right-side-time-content">
        {splittedStartArray[3] + " " + splittedStartArray[4]},
        {" " + splittedStartArray[0]}<sup>{dateSuffix(splittedStartArray[0]) + " "}</sup>
        {splittedStartArray[1]} to
        <br /> {splittedSubmitArray[3] + " " + splittedSubmitArray[4]},
        {" " + splittedSubmitArray[0]}
        <sup>{dateSuffix(splittedSubmitArray[0]) + " "}</sup>
        {splittedSubmitArray[1]}
      </div>
    );
  };
  const callMoengage = () => {
    let payload = {};
    payload.title = `${scholarshipData.title}`;
    payload.mapping_id = mappingId;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event("registration", getMoengageMetadata(payload));
    }
  }
  const handleRegisterClick = () => {
    let tokenFlag = false;
    if (props.state.header && props.state.header.jwtToken && props.state.header.jwtToken.length > 0) {
      tokenFlag = true;
    }
    if (tokenFlag) {
      setLoading(true);
      fetch(
        `${TEST_SERIES_URL}/testseries/fixedMockPushNotification?mappingId=${scholarshipData.mappingId}&packageId=${scholarshipData.fixedMockPkg}`,
        "POST"
      ).then(
        (res) => {
          if (!res.data.isAlreadyRegistered) {
            // alert("You are registered for this test series");

          } else {
            alert("You are already registered for this test series");
          }
          setLoading(false);
          callMoengage();

          const src = historyy?.location?.state?.src;
          if (src) {
            historyy.push({
              pathname: src,
              state: {
                isRegistered: true
              }
            });
          }
          else {
            //by default it will go to mock listing page after registration
            localStorage.setItem("freeQuizTabId", isADDA ? 2 : 1);
            historyy.push({
              pathname: "/my-mock-test",
              state: {
                isRegistered: true
              }
            });
          }
        },
        (res) => {
          setLoading(false);
        }
      );
    } else {
      props.loginFlag(true);
      setPendingButtonClick(true);
      if (currentPath) props.history.push(currentPath + "/#show-login");
    }
  };

  const openTestAttempWindow = (status = "") => {
    let tokenFlag = false;
    if (props.state.header && props.state.header.jwtToken && props.state.header.jwtToken.length > 0) {
      tokenFlag = true;
    }
    if (tokenFlag) {
      let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
      if(getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER && !iOS){
        setClickedItemData({
          fpmId: scholarshipData?.mappingId,
          type: COURSE_CATEGORY_IDENTIFIERS.FPM_IDENTIFIER
        });
        return;
      }
      openTestPortal(scholarshipData?.mappingId,scholarshipData?.fixedMockPkg,scholarshipData?.title,status,null,scholarshipData?.exl)
    } else {
      props.loginFlag(true);
      setPendingButtonClick(true);
      if (currentPath) props.history.push(currentPath + "/#show-login");
    }
  }

  const openTestAnalysis = () => {
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    if(getDeviceType() !== DEVICE_IDENTIFIERS.DESKTOP_IDENTIFIER && !iOS){
      setClickedItemData({
        fpmId: scholarshipData?.mappingId,
        type: COURSE_CATEGORY_IDENTIFIERS.FPM_IDENTIFIER
      });
      return;
    }
    let url = `/my-test-analysis/${scholarshipData.title}/${scholarshipData.fixedMockPkg}/${scholarshipData.mappingId}?&fixedMockResult=true`;
    localStorage.setItem("fixedMockResult", "yes");
    window.open(url);
  }
  const handleButtonClick = () => {
    switch (buttonState) {
      case "Click to Register":
        topFunction();
        handleRegisterClick();
        break;
      case "Attempt":
        openTestAttempWindow('new');
        break;
      case "Resume":
        openTestAttempWindow('INCOMPLETE');
        break;
      case "View Result":
        openTestAnalysis();
        break;
      default:
        break;
    }
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const getFormatedText = (text) => {
    let arr = [];
    let formatedText = "";
    let cnt = 0;
    for (let i = 0; i < text.length; i++) {
      if (cnt < 50) {
        formatedText += text.charAt(i);
        cnt++;
      } else {
        arr.push(<div>{formatedText}</div>)
        formatedText = "";
        formatedText += text.charAt(i);
        cnt = 1;
      }
    }
    if (formatedText.length > 0) {
      arr.push(<div>{formatedText}</div>)
    }
    return arr;
  }

  const renderPrizeDescription = (description) => {

    //  description = "Bigprize-update/nBigprize-update2222/nBigprize-update222"
    const splittedArray = description ? description.split("/n") : [];

    const list = splittedArray.map((description) => {
      if (description !== "") {
        return (<li>
          {getFormatedText(description)}
        </li>)
      }
    })
    return list;

  }

  const button = <a className={buttonState === "Registered" ? "disable-fixed-btn" : "cursor-pointer"} onClick={handleButtonClick}>{buttonState}</a>

  const TimeCard = () => {
    return (
      <>
        <div class="sch-reward-card isOnlyMweb">
          <div class="sch-reward-card-img">
            <img src="/images/sch-reward-icon.svg" />
          </div>
          <div class="sch-reward-contnt">
            <div class="sch-reward-heading">
              Get <span> {scholarshipData.fixedMockPrizeType} </span>{" "}
              !!
            </div>
            <ul>
              {renderPrizeDescription(scholarshipData.fixedMockPrizeDescription)}

            </ul>
          </div>
        </div>


        <div class="sch-time-card">
          <div class="time-watch-img">
            <img src="/images/timewatchboy.svg" className="isOnlyWeb" />
            <img src="/images/sch-time-watch-icon.svg" className="isOnlyMweb" />
          </div>
          <div class="sch-time-contnt">
            <div class="row-time-table">
              <div class="left-side-time-content">Test Duration </div>
              <div class="right-side-time-content">
                {secondsToHms(scholarshipData.timeLimit)}
              </div>
            </div>

            <div class="row-time-table">
              <div class="left-side-time-content">
                Attempt Window{" "}
                <span class="hoveroverme"><img
                  src="/images/sch-time-info-icon.svg" style={{ "cursor": "pointer" }} /><i>Test can be attempted during this period only</i></span>{" "}
              </div>
              {renderAttemptWindow(
                scholarshipData.fixedMockTestStartTime,
                scholarshipData.fixedMockSubmitTime
              )}
            </div>
            <div class="row-time-table">
              <div class="left-side-time-content">
                Result declaration{" "}
              </div>
              {renderResultTime(scholarshipData.fixedMockResultTime)}
            </div>
          </div>
        </div>
      </>
    )
  }
  const TestGuideLine = () => {
    return (
      <>
        <div class="test-guidelines-wrap">
          <div class="test-guide-content">
            <div class="test-guide-heading">Test Guidelines</div>
            <ul class="progress">
              <li class="progress__item">
                <p class="progress__title">Only one attempt allowed:</p>
                <p class="progress__info">
                  Test once started can’t be stopped/restarted.
                </p>
              </li>
              <li class="progress__item">
                <p class="progress__title">
                  Complete the test in one go:
                </p>
                <p class="progress__info">
                  The timer will still be running if you close the test
                  window or pause the test.
                </p>
              </li>
              <li class="progress__item">
                <p class="progress__title">
                  Complete before the deadline:
                </p>
                <p class="progress__info">
                  Test must be submitted on or before the submission
                  deadline.
                </p>
              </li>
              <li class="progress__item">
                <p class="progress__title">Steady Internet required:</p>
                <p class="progress__info">Please keep the internet
                  connection on for smooth functioning of the test.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
  const LoaderCustom = () => {
    let loaderType = "WEB";
    let urlArr = props.location.search.split("?");
    urlArr = urlArr[urlArr.length - 1].split("&");
    urlArr.map((item) => {
      let [key, value] = item.split("=");
      if (key == "platform" && value == "and") {
        loaderType = "AND"
      }
    });
    return (
      <>
        {loaderType == "AND" && <div style={{ margin: "auto" }} className="loader-containerr scholorship-loader">
          <CustomLoaderHeaderFooterHide />
        </div>}
        {loaderType == "WEB" && <div style={{ margin: "auto" }} className="loader-container scholorship-loader">
          <CustomLoader />
        </div>}
      </>
    )
  }

  let exams = scholarshipData?.exl?.length == 1 ? scholarshipData.examid : scholarshipData?.exl?.join(", ");
  return (
    <div className={isDark ? "scholarship-main mblDark" : "scholarship-main"}>
      {loading || loadingQuizState || loadingRegisterStatus || !buttonState ? (
        <LoaderCustom />
      ) : scholarshipData ? (
        <>
          <div class="sch-banner-wrapper">
            <div class="container">
              <div class="sch-banner-content isOnlyMweb">
                <h1>{scholarshipData.title}</h1>
                <div
                  class="banner-phera"
                  dangerouslySetInnerHTML={{
                    __html: `<p>${scholarshipData.fixedMockEventDescription}</p>`,
                  }}
                />
              </div>

              <div class="sch-banner-content isOnlyWeb">
                <div className="sch-baner-left">
                  <h1>{scholarshipData.title}</h1>

                  <div
                    class="banner-phera"
                    dangerouslySetInnerHTML={{
                      __html: `<p>${scholarshipData.fixedMockEventDescription}</p>`,
                    }}
                  />
                  {(!isPreRegistrationView) &&
                    <div className="pheraDateBox">
                      <div class="banner-cal-inner">
                        {renderTestStartTime(scholarshipData.fixedMockTestStartTime)}
                      </div>
                    </div>
                  }
                  <div
                    class="banner-btn">
                    {button}
                  </div>
                  <div className="seen-all-test-btn" ><a onClick={redirectToMockListing} style={{ cursor: "pointer" }}>See All Tests</a></div>
                </div>
                <div className="sch-banner-right">


                  <div className="sch-right-leftBox">
                    {/* canlder html */}
                    <div class="banner-cal-wrap"  >
                      {
                        isPreRegistrationView &&
                        <div class="banner-cal-inner">
                          {renderTestStartTime(scholarshipData.fixedMockTestStartTime)}
                        </div>
                      }

                      {/* <!--get Reward--> */}
                      <div class="sch-reward-card">

                        <div class="sch-reward-contnt">
                          <div class="sch-reward-heading">
                            Get <span> {scholarshipData.fixedMockPrizeType} </span>{" "}
                            !!
                          </div>
                          <ul>
                            {renderPrizeDescription(scholarshipData.fixedMockPrizeDescription)}

                          </ul>
                        </div>
                      </div>

                      {!isPreRegistrationView && <div className="leftTestDuration"><TimeCard /></div>}


                    </div>



                  </div>
                  <div className="sch-right-rightBox">
                    {isMobile && <div className="rightTestDuration" ><TestGuideLine /></div>}
                    {!isMobile && isPreRegistrationView && <div className="rightTestDuration" ><TimeCard /></div>}
                    {!isPreRegistrationView && <div className="rightTestGuideLine"><TestGuideLine /></div>}
                  </div>
                </div>


              </div>
              {/* <!-- banner calender insection--> */}
              <div class="banner-cal-wrap isOnlyMweb">
                <div class="banner-cal-inner">
                  {renderTestStartTime(scholarshipData.fixedMockTestStartTime)}
                </div>
              </div>

            </div>
          </div>

          <div class="sch-main-body-wrap">
            <div class="container">
              {/* <!--top section in body--> */}
              <div class="sch-content-top-wrap">
                {/* <!-- two section reward and time section--> */}
                <div class="reward-and-time-wrap isOnlyMweb">
                  {/* <!--REWARD CARD--> */}

                  {/* <!--TIME CARD--> */}
                  {isMobile ? <TestGuideLine /> : <TimeCard />}

                </div>


                <div className="isOnlyMweb">
                  {/* <!--test guidelines--> */}
                  {isMobile ? <TimeCard /> : <TestGuideLine />}
                </div>
                <div className="isOnlyWeb" style={{ marginBottom: "50px" }}>
                  {/* <!--test guidelines--> */}
                  {isMobile ? <TimeCard /> : isPreRegistrationView && <TestGuideLine />}
                </div>

                {/* <!--Faq--> */}
                {faqData && faqData.length > 0 && <div class="faq-wrap">
                  <div class="faq-heading">Frequently Asked Questions</div>
                  <div class="accordion-sch w-100" id="basicAccordion">
                    {/* <!-- faq -1--> */}
                    {faqData &&
                      faqData.map((faq, index) => {
                        return (
                          <div class={`${(index == 0) ? "accordion-item-sch open" : "accordion-item-sch close"}`}>
                            <h3
                              class="accordion-header-sch"
                              onClick={() => toggleItem(index)}
                            >
                              {faq.question}
                            </h3>
                            <div class="accordion-body-sch">
                              <p>{faq.answer}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>}

                {/* <!--Register Wins--> */}
                <div class="sch-register-wrap">
                  <div class="sch-register-img">
                    <img src="/images/sch-register-img.png" />
                  </div>
                  <div class="sch-register-content">
                    <div class="sch-register-heading">
                      Register to win exciting prizes !!!
                    </div>
                    <div class="sch-register-phera">
                      All india Scholarship exam for {exams}
                    </div>
                    <div class="sch-register-btn">
                      {button}
                    </div>
                    <div className="seen-all-test-btn"><a onClick={redirectToMockListing} style={{ cursor: "pointer" }}>See All Tests</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isAndroid && <div class="fixed-register-btn">{button}</div>}
        </>
      ) : (
        <div class="sch-notfound-wrap">

          <div class="container">
            <div class="sch-notfound-img">
              <img src="/images/sch-notfound-icn.svg" />
            </div>
            <div class="notfound-topheading">Ooops...</div>
            <div class="notfound-error">Page Not Found</div>
            <div class="notfound-msg">
              Sorry, the content you’re looking for doesn’t exist. Either it was
              removed, or you mistyped the link.{" "}
            </div>
            {!isAndroid && <div class="notfount-btn" onClick={() => { historyy.push("/") }}>
              <a>Back to Homepage</a>
            </div>}
          </div>
        </div>
      )}
      <ContineInApp
        show={!!clickedItemData}
        fpmId={clickedItemData && clickedItemData?.fpmId}
        type={clickedItemData && clickedItemData?.type}
        close={() => {
            setClickedItemData();
        }}
      />
    </div>
  );
};
const mapStateToProps = state => ({
  loginFlagData: state.header.loginFlag,
  state: state
});
const mapDispatchToProps = (dispatch) => {
  return {
    loginFlag: (data) => dispatch(loginFlag(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipMain);
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/library/CustomButton";
import fetch_url from "../../../data/utils/fetch";
import {
  GLOBAL_EXAMS_API,
  MEDIA_UPLOAD_URL,
} from "../../../../constants/appUrls";
import { Cookies } from "react-cookie";
import { fileUploader } from "../../../data/utils/uploadFile";
import { BackButton } from "../../../../constants/icons";
const cookie = new Cookies();

const AdmitCardCampaigns = ({
  campaigns,
  isCampaignSubmitted,
  setIsCampaignSubmitted,
  onClose,
  setSubmittedCampaignIds,
}) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [fields, setFields] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isToast, setIsToast] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // default campaign id
  useEffect(() => {
    if (campaigns?.length) {
      const campaignId = campaigns?.[0]?.id;
      setSelectedCampaignId(campaignId);
    }
  }, [campaigns]);

  useEffect(() => {
    let timer;
    if (isToast) {
      timer = setTimeout(() => {
        setIsToast(false);
      }, 1000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [isToast]);

  useEffect(() => {
    if (selectedCampaignId && campaigns) {
      const fields =
        campaigns?.find((campaign) => campaign?.id === selectedCampaignId)
          ?.fields || [];
      const textFields = fields?.map((field) => {
        field?.type === `file` ? setIsFileUpload(true) : setIsFileUpload(false);
        return {
          ...field,
          name: field?.name,
          value: "",
        };
      });
      setFields(textFields);
    }
  }, [selectedCampaignId, campaigns]);

  const onCampaignTabChange = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setIsBtnDisabled(true);
    setUploadedFile("");
    setIsToast(selectedCampaignId !== campaignId);
  };

  const onInputChange = (e, fieldIndex, format) => {
    const allFields = [...fields];
    const selectedField = allFields[fieldIndex];
    const alphabetsRegex = /^[a-zA-Z ]*$/;
    const numberRegex = /^[0-9\b]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
    if (format === `alphabets`) {
      if (alphabetsRegex.test(e.target.value) || e.target.value === "") {
        selectedField.value = e.target.value;
        setFields(allFields);
      }
    }
    if (format === `number`) {
      if (numberRegex.test(e.target.value) || e.target.value === "") {
        selectedField.value = e.target.value;
        if (e.target.value?.trim()?.length === selectedField?.length) {
          delete selectedField.error;
        }
        setFields(allFields);
      }
    }
    if (format === `alphanumeric`) {
      if (alphanumericRegex.test(e.target.value) || e.target.value === "") {
        selectedField.value = e.target.value;
        setFields(allFields);
      }
    }
    if (format === `text`) {
      selectedField.value = e.target.value;
      setFields(allFields);
    }
    if (format === `dob`) {
      selectedField.value = e.target.value;
      setFields(allFields);
    }

    const inValidFields = fields?.filter((field) => {
      if (field?.type !== `file` && field?.value?.trim()?.length === 0) {
        return field;
      }
      if (field?.type === `number`) {
        if (field?.value?.trim()?.length !== field?.length) {
          return field;
        }
      }
    });

    if (inValidFields?.length === 0 || uploadedFile) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    const campaign = campaigns?.find(
      (campaign) => campaign?.id === selectedCampaignId
    );
    if (!isBtnDisabled) {
      const data = {
        examId: campaign?.examId,
        campaignId: selectedCampaignId,
        productId: campaign?.rewardProductId,
        fields: fields?.map((field) => {
          return {
            name: field?.name,
            value: field?.value,
          };
        }),
      };
      fetch_url(
        `${GLOBAL_EXAMS_API}certification-ws/api/v1/admitCardCampaign`,
        "POST",
        data
      )
        .then((res) => {
          if (res?.success) {
            setIsLoading(false);
            setIsCampaignSubmitted(true);
            setResponse(res?.data);
            setUploadedFile("");
            setSubmittedCampaignIds((ids) => [...ids, selectedCampaignId]);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsCampaignSubmitted(false);
          setResponse("");
          setUploadedFile("");
          throw err;
        });
    }
  };

  const onBack = () => {
    setIsCampaignSubmitted(false);
    setIsBtnDisabled(true);
    setResponse("");
    campaigns?.length === 0 && onClose();
  };

  const onFileUpload = async (e) => {
    const allowedFileTypes = [`application/pdf`, `image/jpeg`, `image/png`];
    const fileType = e.target.files[0]?.type;
    if (allowedFileTypes?.includes(fileType)) {
      setIsUploading(true);
      setUploadedFile(e.target.files[0]?.name);
      const formData = new FormData();
      formData?.append(`file`, e.target?.files[0]);
      e.target.value = "";
      const headers = {
        "jwt-token": cookie.get("cp_new_token"),
        "service-name": "Sankalp-AdmitcardUploadService",
      };
      const { response } = await fileUploader(
        `post`,
        `${MEDIA_UPLOAD_URL}/v1/media/upload`,
        headers,
        formData
      );
      if (response?.message === `success`) {
        setIsBtnDisabled(false);
        setIsUploading(false);
        fields?.push({
          name: `admit-card`,
          value: response?.originalMediaUrl,
        });
      } else {
        setIsUploading(false);
      }
    } else {
      setUploadedFile("");
    }
  };

  const onRemoveFile = () => {
    setUploadedFile("");
    const hasEmptyFields = fields?.some(
      (field) => field?.type !== `file` && !field?.value
    );
    setIsBtnDisabled(hasEmptyFields);
  };

  const onValidateInput = (index) => {
    const allFields = [...fields];
    const selectedField = allFields[index];
    if (selectedField?.length !== selectedField?.value?.trim()?.length) {
      selectedField.error = `${selectedField?.name}`;
      setFields(allFields);
      setIsBtnDisabled(true);
    }
  };

  return (
    <div className="admit-card-campaigns-wrapper">
      {isCampaignSubmitted ? (
        <div className="adminThanksBox">
          <div className="adminThanksHeader">
            <span onClick={onBack}>
              <BackButton />
            </span>
          </div>

          <div className="adminThanksMsg">
            Thank you for providing the details. You have been rewarded with the
            below package.
          </div>

          <div className="adminThanksCard">
            <div className="adminThanksCardImg">
              <img src={response?.imgUrl} />
            </div>
            <div className="adminThanksTitle">{response?.title}</div>
          </div>

          <div className="admitThanksInfo">
            <div className="admitInfoIcon">
              <img src="/images/AdmintCardInfo.svg" />
            </div>
            <div className="textInfo">
              This reward package will be added in your “My Content” within 48
              hours.
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="left-section">
            <div className="image-wrapper">
              <img src="/images/giftPackage-img.svg" />
            </div>
            <div className="tagline">
              Gift await :Provide your info, enjoy a free package!
            </div>
          </div>
          <div className="right-section">
            <div className="admitCardHeader">
              <img src="/images/close-icon.svg" onClick={onClose} />
            </div>

            {campaigns?.length > 1 && (
              <div className="campaign-tabs-wrapper">
                {campaigns?.map((campaign) => {
                  return (
                    <div
                      className={`campaign-tab ${
                        selectedCampaignId === campaign?.id ? `active` : null
                      }`}
                      key={campaign?.id}
                      onClick={() => onCampaignTabChange(campaign?.id)}
                    >
                      {campaign?.examName}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="form-wrapper">
              <div className="campaign-fields-wrapper">
                {campaigns?.length === 1 && (
                  <div className="field-label-wrapper">
                    <label>Exam Name:</label>
                    <input readOnly value={campaigns?.[0]?.examName} />
                  </div>
                )}
                {fields?.map((field, index) => {
                  return field?.name === `admit-card` ? null : (
                    <>
                      <div className="field-label-wrapper">
                        <label>{field?.name}:</label>
                        <input
                          type={
                            field?.type === `dob`
                              ? `date`
                              : field?.type === `number`
                              ? `tel`
                              : field?.type
                          }
                          placeholder={`Enter your ${field?.name}`}
                          value={field?.value}
                          onChange={(e) => onInputChange(e, index, field?.type)}
                          maxlength={field?.length}
                          onBlur={
                            field?.type === `number`
                              ? () => onValidateInput(index)
                              : () => {}
                          }
                        />
                      </div>
                      <div className="errorwrapper">
                        {field?.type === `number` && field?.error && (
                          <>
                            <span className="errorlabel">{field?.error}</span>
                            <span> must have {field?.length} digits.</span>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}

                {isFileUpload && (
                  <div className="upload-card-wrapper">
                    <div className="or">
                      <span>Or</span>
                    </div>
                    <div className="field-label-wrapper">
                      <label>Upload Admit Card</label>
                      <div className="uploafile">
                        <label className="uploadiput">
                          <div for="upload">
                            <img src="/images/wlUpload.svg" />
                            Upload
                          </div>
                          <input
                            type="file"
                            name="photo"
                            id="upload"
                            accept=".jpg,.jpeg,.pdf,.png"
                            onChange={onFileUpload}
                            multiple
                          />
                        </label>
                        <div className="fileAlowedAdmitCard">
                          (Pdf, jpg, jpeg)
                        </div>
                        {uploadedFile && (
                          <>
                            <div className="fileNameAdmitCard">
                              <span className="filenameuploaded">
                                {uploadedFile}
                              </span>
                              {!isUploading && (
                                <span
                                  className="crossiconuploaded"
                                  onClick={onRemoveFile}
                                ></span>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="adminCardBtn">
                {isToast && (
                  <div className="admitThanksInfo">
                    <div className="admitInfoIcon">
                      <img src="/images/AdmintCardInfoRed.svg" />
                    </div>
                    <div className="textInfo">
                      Your details won't be saved until you click on submit
                      button.
                    </div>
                  </div>
                )}

                <CustomButton
                  type="primary"
                  className={`adminCardBtn ripple ${
                    isBtnDisabled ? `diableBtn` : null
                  }`}
                  buttontext={`Submit`}
                  loading={false}
                  onClick={onSubmit}
                />
                {isLoading && (
                  <div className="loaderCircle">
                    <div class="load"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdmitCardCampaigns;

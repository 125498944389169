import React , {useState, useEffect} from 'react';
import {withCookies} from 'react-cookie'

import SupportFormService from '../components/support/SupportFormService';
import SupportFormPurchase from '../components/support/SupportFormPurchase';
import CustomLoader from '../components/library/CustomLoader';
import {createOptimizedImgUrl, scrollToTop, diff_minutes} from '../data/utils/helpers';


const Support  = (props) => {

    const [loading, setLoading] = useState(false);
    const [supportData, setSupportData] = useState(undefined)
    const name = props.cookies.get('cp_user_name');
    const email = props.cookies.get('cp_user_email');
    
    useEffect(()=>{
        scrollToTop();
    },[]);

    const supData = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('supportData'));

    useEffect(() => {
        if(supData?.date) {
            var now = new Date();
            if(diff_minutes(now, new Date(supData.date)) > 0.5) { 
                setSupportData(undefined);
                typeof window !== 'undefined' && localStorage.removeItem('supportData');
            } else {
                setSupportData(supData);
            }
        }
    }, []);
    

    const getFileData = (file, callback) => {
    }

    return(
        <>
            {
                loading && <div className="supportloader"><CustomLoader /></div>
            }
            <div className={'support-container'}>
                <div className="suppot-heading-wrap" style={{position:'relative'}}>
                    <div className="support-header-container">
                        <div>
                            <div>Don’t Worry</div>
                            <div>We are here for you!</div>
                        </div>
                    </div>
                
                </div>
                
                <SupportFormService
                    name={name}
                    email={email}
                    getFileData={getFileData}
                    setLoading={setLoading}
                    supportData = {supportData}
                />

            </div>
        </>
    )
}


export default withCookies (Support) 

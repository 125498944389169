import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { threeDModeliFrameUrl } from "../../constants/appConfig";
import CustomLoaderHeaderFooterHide from "../components/library/CustomLoaderHeaderFooterHide";
import { getMoengageMetadata } from "../data/utils/helpers";

const VeekshaViewer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const timer = useRef(0);

  const iframeRef = useRef(null);
  useEffect(() => {
    // Call the function initially and whenever the window is resized
    window.addEventListener("resize", setIframeHeight);
    setLoading(false);
    let intervalId;

    if (loading === false) {
      setIframeHeight();
      intervalId = setInterval(() => {
        timer.current += 1;
      }, 1000);
      callExitLaunchMoengage("Launch")
    }
    return () => {
      window.removeEventListener("resize", setIframeHeight);
      window.removeEventListener("load", setIframeHeight);
      // Cleanup the interval on component unmount
      clearInterval(intervalId);
    };
  }, [loading]);

  useEffect(() => {
    const headerComponent = document.getElementsByClassName("headerwrap")[0];
    const footerComponent = document.getElementsByClassName("new-footer")[0];
    if (headerComponent) {
      headerComponent.style.display = "none";
    }
    if (footerComponent) {
      footerComponent.style.display = "none";
    }

    return () => {
      if (headerComponent) {
        headerComponent.style.display = "block";
      }
      if (footerComponent) {
        footerComponent.style.display = "block";
      }
    };
  }, []);

  useEffect(() => {
    const handleUnload = (event) => {
      try {
        callExitLaunchMoengage("Exit")
        event.preventDefault();
        return (event.returnValue = "");
      } catch (error) {
        console.log("error", error);
      }
    };

    window.addEventListener("beforeunload", (e) => handleUnload(e));

    return () => {
      window.removeEventListener("beforeunload", handleUnload);

      callExitLaunchMoengage("Exit")
    };
  }, []);

  const callExitLaunchMoengage = (action) => {
    let payload = location?.state?.payload || {};
    if(action === "Exit")
      payload.secondConsumed = String(timer.current);
    payload.action = action;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event("MODELS_3D", getMoengageMetadata(payload));
    }
  };

  const opt = {
    pkgId: location?.state?.pkgId,
    topicName: location?.state?.topic,
    contentUrl: location?.state?.file,
  };

  function setIframeHeight() {
    let iframe = iframeRef.current;
    if (iframe) {
      let iframeWidth = iframe.offsetWidth - 100;
      let desiredHeight = (iframeWidth * 9) / 16;
      iframe.style.height = desiredHeight + "px";
    }
  }

  function handleFullScreen() {
    const iframe = iframeRef.current;

    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  }

  return (
    <div className="veeksha-container">
      {loading ? (
        <div style={{ margin: "auto" }} className="loader-container">
          <CustomLoaderHeaderFooterHide />
        </div>
      ) : (
        <div className="veeksha-wrapper">
          <div className="iframe-wrapper">
            <iframe
              ref={iframeRef}
              id="veeksha-iframe"
              src={`${threeDModeliFrameUrl}?pkgId=${opt.pkgId}&topic=${opt?.topicName}&file=${opt.contentUrl}`}
              width="100%"
            />
            <button
              className="veeksha-fullscreen-btn"
              onClick={handleFullScreen}
            ></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VeekshaViewer;

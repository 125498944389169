import React from 'react'
import { pageName } from '../../../constants/appConfig';

const NoDataFound=({errorMessage,isExpired,parentId,selectedOption,parentInfo,recentParentInfo,published,showExpiredModal,subsectionsId,displayMessage})=>{
   

    return(
      
         <div className=" " >   
                <div className="  ">
                    <div className="">
                    
                        { !isExpired && <div className="live-class-zero-state-mob">
                            <img className="zero-state zero-img live-class-zero-state-mobile-image " src={`https://storecdn.${pageName[0]}.com/ic_empty_liveclass.svg`} />
                            <div className="live-class-zero-state-text-mobile ">
                           
                                {!errorMessage &&<span  >You don't have any classes scheduled today</span>}
                                
                                <span  >{errorMessage}</span>
                            </div>
                            
                            {
                              !errorMessage && <div  className="padding-top-20 "  >
                                <button className="sf-button-secondary " 
                                onClick={()=>selectedOption(subsectionsId)}>{displayMessage}</button>
                               
                            </div> 
                            }
                           
                        </div>}
                     
                       { isExpired &&  <div className="live-class-zero-state-mob "  >
                        
                            <img   style={{height:'132px'}}  src={`https://storecdn.${pageName[0]}.com/Expiry.png`} />
                            <div className="live-class-zero-state-text-mobile ">
                                <span>Interested in buying more such products?</span>
                               
                            </div>
                           
                        {<div  className="padding-top-20 ">
                        
                            {/* <button className="sf-button-secondary "  
                            onClick={()=>showExpiredPopup(recentParentInfo,parentInfo,published,recentParentInfo && recentParentInfo.published)}>BUY LIVE CLASSES</button>   */}
                            <button className="sf-button-secondary "
                             onClick={()=>showExpiredModal(parentInfo,published,parentInfo && parentInfo.published)}  >VIEW DETAILS</button>
                        </div>}
                        </div> }
                    </div>
                </div>
            </div>
    )

}
export default NoDataFound;
import types from './types';
import {STOREFRONT_URI} from '../../../../constants/appConfig';
import { THREED_MODEL_URL } from '../../../../constants/appUrls';

const fetchUserModels = () => {
    return {
        CALL_API: [
            {
                type: types.FETCH_USER_MODELS,
                meta: {
                    path: `${STOREFRONT_URI}/api/v1/models`,
                    method: 'GET'
                }
            }
        ]
    };
};
const fetchViewAllModels = (modelId,pageNumber,pageSize) => {
    return {
        CALL_API: [
            {
                type: types.FETCH_VIEW_MODELS,
                meta: {
                    path: `${THREED_MODEL_URL}/api/v1/models/${modelId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
                    method: 'GET'
                }
            }
        ]
    };
};


export {
    fetchUserModels,
    fetchViewAllModels
};
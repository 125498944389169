import PropTypes from "prop-types";
import React, { Component } from "react";
//import BottomScrollListener from 'react-bottom-scroll-listener';
import BottomScrollListener from "../components/library/BottomScrollListener";
import { connect } from "react-redux";
import { pageSizeForDetailListing, pageName, searchUrlEndPoint } from "../../constants/appConfig";
import {
  productKeys,
  productNames,
  viewAllKeys,
} from "../../constants/appConstants";
import FilterTags from "../components/filter/FilterTags";
import ProductTypeListing from "../components/filter/listings/ProductTypeListing";
import NewMobileFilter from "../components/filter/NewMobileFilter";
import SearchBlock from "../components/filter/searchfilters";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import NoResultsFound from "../components/library/NoResultsFound";
import NotFoundStatic from "../components/NotFoundStatic";
import { Cookies } from "react-cookie";
import {
  clearExamDetailData,
  getPaginatedDataForExamDetail,
} from "../data/ducks/examDetailListing/actions";
import "../data/ducks/examDetailListing/reducers";
import {
  capitalizedString,
  createStorefrontPdpUrl,
  getMoengageMetadata,
  isEmpty,
  isSet,
  removeHyphensAndInsertSpaces,
  validateProductName,
  makeUrl,
  getDeviceType,
  getPdpUrl,
  getUrlPathObject,
  parseUrlForSeo,
  removeSpacesAndInsertHyphens,
  getExamCategoryId,
  trackMoengageEvent,
  createOptimizedImgUrl,
  replaceHyphenWithUnderscore,
  isADDA,
  handleShowFilter,
  getMoengagePayloadFromFacets,
  fireMoengageEvent,
  getTagIdParams,
} from "../data/utils/helpers";
import CustomSkeleton from "../components/library/CustomSkeleton";
import BreadCrumb from "../components/library/BreadCrumb";
import { pageMeta } from "../../constants/meta";
import Faq from "./SEO/faq";
import { checkProperties } from "../components/utils";
import DfpAdPdp from "../components/library/DfpAdPdp";
import { SeoSectionMobile } from "../components/library/SeoBlock/SeoBlock";
import NewSortByMobileFilter from "../components/filter/NewSortByMobileFilter";
import fetch from "../data/utils/fetch";
import CarouselSchema from "../components/library/CarouselSchema";

let pageN = 0;
let filterObj = {};
const antIcon = <div className={"spinner"} style={{ fontSize: 24 }} />;
let requiredProductName = "";
let filterList;
const sponsoredCategory = 3
const cookie = new Cookies();
let count = 0;
class ExamDetailListing extends Component {
  constructor(props) {
    super(props);
    const tags = this.createTagsFromUrl();
    this.state = {
      loading: true,
      listingLoader: false,
      showFilterModal: false,
      queryParam: props.location.search.substring(1) || "",
      tagArr: tags.tagArr,
      sortBy: "RELEVANCE",
      dataLoader: false,
      facetObj: tags.facetObj,
      similarProduct: "",
      showSortModal: false,
      selectedSortCategory: 'Relevance',
      selectedFilterCategory: 'No Filter',
      showFilterAppliedDot: false,
      showSortByAppliedDot: true,
      secondaryFacetsList: [],
    };
    this.currentUrl = parseUrlForSeo(props.location.pathname);
  }

  static propTypes = {
    getPaginatedDataForExamDetail: PropTypes.func,
    examDetailListing: PropTypes.object,
    clearExamDetailData: PropTypes.func,
    utmParams: PropTypes.object,
  };

  componentWillUnmount() {
    if (
      this.props.examDetailListing &&
      !isEmpty(this.props.examDetailListing)
    ) {
      this.props.clearExamDetailData();
      this.clearBeforeUnload();
    }
    count = 0;
    const googletag = window.googletag;
    if (googletag) googletag.destroySlots();
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.search.length) {
      const requiredString = location.search.substring(1);
      const paramArr = requiredString.split("&");
      for (let i = 0; i < paramArr.length; i++) {
        const newArr = paramArr[i].split("=");
        if (decodeURI(newArr[1]).length) {
          filterObj[decodeURI(newArr[0])] = [decodeURI(newArr[1])];
        }
      }
    }
    const { pathname } = location;
    const examName = pathname.split("/")[1];
    const productName = pathname.split("/")[2];
    let category = productName.split("-").slice(0, -1).join("-");

    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get("cp_user_name");
    let email = cookie.get("cp_user_email");
    const token = cookie.get("cp_token");

    if (this.props.examList) {
      let examDetailListing =
        JSON.parse(localStorage.getItem("examMenuData")) || this.props.examList;
      let selectedExamCategory = getExamCategoryId(examDetailListing, examName);
        let similarProduct = examDetailListing[selectedExamCategory];
        similarProduct &&
          similarProduct?.map((elem, index) => {
            if (
              removeSpacesAndInsertHyphens(elem?.name?.toLowerCase()) ==
              examName?.toLowerCase()
            ) {
              similarProduct.splice(index, 1);
            }
          });
        this.setState({ similarProduct: similarProduct });
      
    }

    if (!JSON.parse(localStorage.getItem("examMenuData"))) {
      localStorage.setItem("examMenuData", JSON.stringify(this.props.examList));
    }
    if (this.validateProduct(requiredProductName)) {
      this.getPaginatedDataForDetailedPage(
        0,
        examName,
        false,
        this.state.sortBy
      );
    }
    typeof window !== "undefined" && window.scrollTo(0, 0);
    //event list page view
    if(requiredProductName) this.getSecondaryFacetsList('');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.productType !==
      this.props.match.params.productType ||
      prevProps.match.params.examName !== this.props.match.params.examName
    ) {
      const { location, match } = this.props;
      const { pathname } = location;
      const examName = pathname.split("/")[1];
      const productName = pathname.split("/")[2];
      this.getPaginatedDataForDetailedPage(
        0,
        examName,
        false,
        this.state.sortBy,
        true
      );
      typeof window !== "undefined" && window.scrollTo(0, 0);
    }
  }

  productCategoryArray = isADDA ? [
    { name: "Mock Tests", url: "mock-tests" },
    { name: "Books", url: "books" },
    { name: "E-books", url: "ebooks" },
    { name: "Online Live Classes", url: "live-classes" },
    { name: "Videos", url: "videos" },
  ] : [
    { name: "Mock Tests", url: "mock-tests" },
    { name: "Books", url: "books" },
    { name: "E-books", url: "ebooks" },
    { name: "Online Live Classes", url: "live-classes" },
    { name: "Videos", url: "videos" },
    { name: "3D Learning", url: "3d-models" },
  ];

  clearBeforeUnload() {
    this.setState({
      tagArr: [],
      facetObj: {},
    });
  }

  createTagsFromUrl = () => {
    const qParams = this.props.location.search.substring(1);
    let tempTagArr = [];
    let newFacetObj = {};
    if (qParams.length) {
      let splitArr = qParams.split("&");
      splitArr = splitArr.filter(
        (e) => !(e.indexOf("utm") > -1 || e.indexOf("gclid") > -1)
      );
      splitArr.map((item) => {
        let newArr = item.split("=");
        let finalArr = (newArr[1] || "").split(",");
        finalArr.map((it) => {
          if (!tempTagArr.includes(decodeURI(it))) {
            tempTagArr.push(decodeURI(it));
          }
        });
        newFacetObj[newArr[0]] = finalArr;
      });
    }
    return {
      tagArr: tempTagArr.filter((item) => item),
      facetObj: newFacetObj,
    };
  };
  getPaginatedDataForDetailedPage = (
    pageNo = 0,
    examName,
    forFilters,
    sortBy,
    stateUpdate
  ) => {
    typeof window !== "undefined" && window.scrollTo(0, 0);
    this.createTagsFromUrl();
    if (isEmpty(this.props.examDetailListing) || forFilters || stateUpdate) {
      let queryParam = this.state.queryParam || `&${this.props.location.search.substring(1)}`;
      let courseCategoryFacets = queryParam?.split("=")?.[1];
      let tagParams = getTagIdParams( courseCategoryFacets, this.props.globalExamList );
      const examDetailObj = {
        pageNo,
        examName,
        type: "init",
        categoryFacet: replaceHyphenWithUnderscore(
          viewAllKeys[requiredProductName]
        ),
        queryParam: queryParam,
        sortBy,
        sponsoredCategory,
        tagParams,
      };
      // dispatch the action to get the detailed data for the listing with pagination.
      this.props.getPaginatedDataForExamDetail(examDetailObj).then(() => {
        this.setState({
          loading: false,
          showFilterModal: false,
          showProductLoader: false,
          dataLoader: false,
          tagArr: this.createTagsFromUrl().tagArr,
        });
      });
      pageN = 0;
    }
  };

  getPaginatedDataOnScroll = (examName) => {
    const totalCount = this.props.examDetailListing.data.count || 0;
    if (totalCount <= (pageN+1) * pageSizeForDetailListing) {
      return;
    }
    this.setState({
      listingLoader: true,
    });
    pageN++;
    let tagParams = getTagIdParams( examName, this.props.globalExamList );
    const examDetailObj = {
      pageNo: pageN,
      examName,
      type: "scroll",
      categoryFacet: replaceHyphenWithUnderscore(
        viewAllKeys[requiredProductName]
      ),
      queryParam:
        this.state.queryParam || `&${this.props.location.search.substring(1)}`,
      sortBy: this.state.sortBy,
      sponsoredCategory,
      tagParams,
    };
    this.props.getPaginatedDataForExamDetail(examDetailObj).then(() => {
      this.setState({
        listingLoader: false,
        dataLoader: false,
      });
    });
  };

  handleDetailCardClick = (obj) => {
    const category = productKeys[obj.suggestionCat] || "";
    const id = obj.id || "";
    const title = obj.title || "";
    const slugurl = obj.slugURL || "";
    const getUtmFromStore = this.props.utmParams;

    const storeUrl = getPdpUrl(category, id, title, slugurl);

    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "product_click",
        getMoengageMetadata({
          source: "web",
          id: id,
          name: title,
          category: category,
          list: category,
          brand: pageName[1],
          variant: "-",
          price: obj["price"],
          quantity: "1",
          packageUrl: storeUrl,
          packageActualPrice: obj.maxPrice,
          packageDiscountedPrice: obj["price"],
          getUtmFromStore: getUtmFromStore,
          source_screen: " Exam Detail listing",
        })
      );
    //createStoreFrontPdpUrl
    // window.open(storeUrl);
    // this.props.history.push(getUrlPathObject(storeUrl));
  };

  handleFilterButton = () => {
    this.setState({
      showFilterModal: true,
            showSortModal: false
        });
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    fireMoengageEvent("filter_button_clicked", moePayload, "filter");
    };

    handleSortButton = () => {
        this.setState({
            showSortModal: !this.state.showSortModal
    });
  };

  hideFilterOnMobile = () => {
    this.setState({
      showFilterModal: false,
    });
  };

  handleFilterApply = (facetObj) => {
    this.setState({
      dataLoader: true,
      showFilterModal: false,
    });
    let urlString = "&";
    const facetObjectKeys = Object.keys(facetObj);
    const exam = this.props.location.pathname.split("/")[1];
    const facetKeysArrLen = facetObjectKeys.length;
    for (let i = 0; i < facetKeysArrLen; i++) {
      let facetValue = facetObj[facetObjectKeys[i]];
      urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
    }
    const encodedQueryString = encodeURI(urlString.slice(0, -1));
    this.setState(
      {
        queryParam: encodedQueryString,
        showFilterModal: false,
        showFilterAppliedDot: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ),
        selectedFilterCategory: (Object.keys(facetObj)?.length > 1 || facetObj[Object.keys(facetObj)?.[0]]?.[0].length ) ? 'Filter Applied' : 'No Filter',
      },
      () =>
        this.getPaginatedDataForDetailedPage(0, exam, true, this.state.sortBy)
    );
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    moePayload = {...moePayload, ...getMoengagePayloadFromFacets(facetObj)}
    fireMoengageEvent("filter_applied", moePayload, "filter");
    this.props.history.push(
      getUrlPathObject(
        `${this.props.location.pathname}`,
        `?${encodedQueryString.substring(1)}`
      )
    );
  };

  resetFilter = () => {
    filterObj = {};
    this.clearAllFilters();
    this.setState({
      showFilterModal: false,
      currentActiveFilter: '',
      showFilterAppliedDot: false,
      selectedFilterCategory: 'No Filter',
    });
  };

  getNewParamsForAllCategory = (facetKey, value) => {
    this.setState({
      dataLoader: true,
    });
    const { facetObj, tagArr } = this.state;
    this.setState({
      dataLoader: true,
    });
    const { location } = this.props;
    const { pathname } = location;
    const examName = pathname.split("/")[1];

    if (facetKey === "allExamtype") {
      const selection = value.toLowerCase();
      this.setState({
        tagArr: [],
        facetObj: {},
      });
      this.props.history.push(getUrlPathObject(`/${selection}`));
      this.props.getSearchData(selection).then(() => {
        this.setState({
          dataLoader: false,
        });
      });
    } else {
      let newFacetObj = Object.assign({}, facetObj);
      let newTagArr = tagArr;
      const courseName = this.props.match.params.courseType;
      const productName = this.props.match.params.productType;

      if (!isSet(newFacetObj[facetKey])) {
        newFacetObj[facetKey] = [];
      }
      if (newFacetObj[facetKey].includes(value)) {
        newFacetObj[facetKey] = newFacetObj[facetKey].filter(
          (e) => e.toLowerCase() !== value.toLowerCase()
        );
        newTagArr = newTagArr.filter(
          (e) => e.toLowerCase() !== value.toLowerCase()
        );
      } else {
        newFacetObj[facetKey].push(value);
        newTagArr.push(value);
      }
      let strNew = "";
      let p;
      for (p in newFacetObj) {
        if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
          strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
        }
      }
      strNew = strNew.substring(-1);
      this.setState(
        {
          queryParam: strNew,
          tagArr: newTagArr,
          facetObj: newFacetObj,
        },
        () => {
          this.getPaginatedDataForDetailedPage(
            0,
            examName,
            true,
            this.state.sortBy
          );
        }
      );
      this.props.history.push(
        getUrlPathObject(
          `/${examName}/${productName}-kit`,
          `?${strNew.slice(0, -1)}`
        )
      );
    }
  };

  datalayerHanler = (searchValue) => {
    const { location } = this.props;
    const { pathname } = location;
    const examName = pathname.split("/")[1];
    const productName = pathname.split("/")[2];
    let category = productName.split("-").slice(0, -1).join("-");

    let productCategory = productName.split("-").slice(0, -1).join("-");
    let ProCategory = productNames[productCategory];

    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get("cp_user_name");
    let email = cookie.get("cp_user_email");
    const token = cookie.get("cp_token");

    if (searchValue && typeof window != "undefined") {
      let excategory = searchValue.courses;
      let exammCategory =
        searchValue.data &&
        searchValue.data.packageEsEntity &&
        searchValue.data.packageEsEntity[0] &&
        searchValue.data.packageEsEntity[0].courses;
      if (token) {
        window.dataLayer.push({
          name: name || "guest",
          email: email || "",
          isMobile: getDeviceType() == "m" ? true : false,
          loginType: "email",
          isLoggedIn: true,
          examName: examName,
          pageName: "Listing",
          examCategoryName: exammCategory,
          category: ProCategory,
        });
      } else if (!token && !user_id_cookie) {
        window.dataLayer.push({
          name: "guest",
          email: "",
          isMobile: getDeviceType() == "m" ? true : false,
          loginType: "",
          isLoggedIn: false,
          examName: examName,
          pageName: "Listing",
          examCategoryName: exammCategory,
          category: ProCategory,
        });
      }
      if (count == 1) {
        let payload = {};
        payload.user_email = cookie.get("cp_user_email") || "";
        payload.user_name = cookie.get("cp_user_name") || "";
        payload.source_screen = "";
        payload.examCategory = exammCategory;
        payload.getUtmFromStore = this.props.utmParams;
        trackMoengageEvent("list_page_view", payload);
      }
    }
  };

  handleSortBy = (sortBy) => {
    const { location } = this.props;
    const { pathname } = location;
    const examName = pathname.split("/")[1];
    // sortBy = ASC or RELEVANCE
    this.setState(
      {
        sortBy,
        showProductLoader: true,
      },
      () => {
        this.getPaginatedDataForDetailedPage(0, examName, true, sortBy);
      }
    );
  };

  setSelectedSortCategoryChange = (category) => {
    this.setState({
        selectedSortCategory: category
    })
  }

  removeTag = (item) => {
    this.setState({
      dataLoader: true,
    });
    const { location } = this.props;
    const { pathname } = location;
    const examName = pathname.split("/")[1];
    const productName = pathname.split("/")[2];

    const { tagArr, facetObj } = this.state;
    // const courseName = pathname.split('/')[1];
    const courseCategory = pathname.substring(1).split("-")[0];
    let newFacetObj = {};
    const tempTagArr = [...tagArr];
    this.setState({
      dataLoader: true,
    });

    if (tempTagArr.indexOf(item) > -1) {
      tempTagArr.splice(tempTagArr.indexOf(item), 1);
      const facetKeys = Object.keys(facetObj);
      facetKeys.map((e) => {
        newFacetObj[e] = facetObj[e].filter((f) => {
          return f.toLowerCase() !== item.toLowerCase();
        });
      });
    }

    let arr3 = [];
    let finalString = "";

    let splitArr = location.search.substring(1).split("&");
    splitArr.map((str) => {
      let newAr = str.split("=");
      const cat = newAr.shift();
      let finalAr = (newAr[0] || "").split(",");

      finalAr = finalAr.filter((val) => {
        let decodedUri = decodeURI(val).toLowerCase();
        return decodedUri !== item.toLowerCase();
      });

      const string2 = finalAr.join(",");

      if (string2.length) {
        arr3.push(cat + "=" + string2);
      }
    });
    if (arr3.length > 1) {
      finalString = arr3.join("&");
    } else {
      if (isSet(arr3[0])) {
        finalString = arr3[0];
      }
    }

    this.setState(
      {
        queryParam: finalString,
        tagArr: tempTagArr,
        facetObj: newFacetObj,
        showFilterAppliedDot: tempTagArr.length && true,
        selectedFilterCategory: tempTagArr.length ? 'Filter Applied' : 'No Filter',
      },
      () => {
        this.getPaginatedDataForDetailedPage(
          0,
          examName,
          true,
          this.state.sortBy
        );
      }
    );
    this.props.history.push(
      getUrlPathObject(`/${examName}/${productName}`, `?${finalString}`)
    );
  };

  clearAllFilters = () => {
    const { history, location } = this.props;
    const { pathname } = location;
    const examName = pathname.split("/")[1];
    const productName = pathname.split("/")[2];

    history.push(getUrlPathObject(`/${examName}/${productName}`));
    this.setState(
      {
        dataLoader: true,
        queryParam: "",
        tagArr: [],
        facetObj: {},
        showFilterAppliedDot: false,
        selectedFilterCategory: 'No Filter',
      },
      () => {
        this.getPaginatedDataForDetailedPage(
          0,
          examName,
          true,
          this.state.sortBy
        );
      }
    );
    let moePayload = {};
    moePayload.Exam_Category = sessionStorage.getItem("ExamCategoryMappingId");
    fireMoengageEvent("filter_reset", moePayload, "filter");
  };

  validateProduct(requiredProductName) {
    return validateProductName(requiredProductName);
  }

  getSecondaryFacetsList = (course) => {
    const { location } = this.props;
    const { pathname } = location;
    const examName = pathname?.split("/")[1];
    try{
      fetch(`${searchUrlEndPoint}/packages/secondaryFacets?${requiredProductName? "&categoryFacets=" + viewAllKeys[requiredProductName]: ""}${examName? "&examTypesFacets=" + examName : ""}`).then(res => {
        this.setState({secondaryFacetsList: res.data})
      }).catch((error) => {
        console.error(error)
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  render() {
    const validFilters = ['languages', 'subjects', 'tags'];
    const { location, examDetailListing } = this.props;
    if (
      examDetailListing &&
      examDetailListing.data &&
      examDetailListing.data.facetsList
    ) {
      count++;
      this.datalayerHanler(examDetailListing, count);
    }
    const {
      listingLoader,
      showFilterModal,
      queryParam,
      facetObj,
      dataLoader,
      tagArr,
      showSortModal,
      selectedFilterCategory,
      selectedSortCategory, 
      showFilterAppliedDot, 
      showSortByAppliedDot,
    } = this.state;

    const { pathname } = location;
    const examName = pathname.split("/")[1];
    const productName = pathname.split("/")[2];
    requiredProductName = productName.split("-").slice(0, -1).join("-");

    let title = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} ${productNames[requiredProductName]
      } ${new Date().getFullYear()}.Get ${removeHyphensAndInsertSpaces(examName.toUpperCase())
      } Test Series Online Free`;
    if (pageMeta[this.currentUrl]) {
      title = pageMeta[this.currentUrl].title;
    }
    let ExamCat = productNames[requiredProductName].toLowerCase();
    let defaultHeadingDescription = ``;
    let defaultHeading = ``;
    let defaultMetaTitle = ``;
    let defaultMetaDesc = ``;
    if(ExamCat == "e-books"){
      defaultHeadingDescription = `Buy Latest Online ${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()}.Download pdf of ${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()} for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}.Read ${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()} subject-wise.`;
    } else if(ExamCat=="mock tests") {
      defaultHeadingDescription = `Prepare and Practice ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock test ${new Date().getFullYear()} based on the latest pattern. ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Test Series online free for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}.`;
    } else if(ExamCat=="books") {
      defaultHeadingDescription = `Purchase ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Books ${new Date().getFullYear()} from our latest ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Booklist ${new Date().getFullYear()} to upscale your ${removeHyphensAndInsertSpaces(examName.toUpperCase())} exam preparation to a next level.`;
    } else if(ExamCat=="videos") {
      defaultHeadingDescription = `Take our ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Course to increase your score. ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Pre Recorded video lectures and video courses for you all.`;
    } else if(ExamCat=="online live classes"){
      defaultHeadingDescription = `Join ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online Coaching ${new Date().getFullYear()} and ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Live Classes ${new Date().getFullYear()} at an affordable price.Best online coaching for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}.`;
    }

    if(ExamCat == "e-books"){
      defaultHeading = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()}`;
    } else if(ExamCat=="mock tests") {
      defaultHeading = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test ${new Date().getFullYear()}`;
    } else if(ExamCat=="books") {
      defaultHeading = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Books ${new Date().getFullYear()}`;
    } else if(ExamCat=="videos") {
      defaultHeading = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Courses ${new Date().getFullYear()}`;
    } else if(ExamCat=="online live classes"){
      defaultHeading = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} ONLINE COACHING ${new Date().getFullYear()}, LIVE CLASSES`;
    }

    if(ExamCat == "e-books"){
      defaultMetaTitle = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()}.Get Latest ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online eBooks for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}`;
    } else if(ExamCat=="mock tests") {
      defaultMetaTitle = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test ${new Date().getFullYear()}.Get ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Test Series Online Free.`;
    } else if(ExamCat=="books") {
      defaultMetaTitle = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Books ${new Date().getFullYear()}.Check Best ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Booklist ${new Date().getFullYear()}.`;
    } else if(ExamCat=="videos") {
      defaultMetaTitle = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Courses ${new Date().getFullYear()}, ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Lectures, video classes for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam.`;
    } else if(ExamCat=="online live classes"){
      defaultMetaTitle = `Enroll for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online Coaching ${new Date().getFullYear()}.${removeHyphensAndInsertSpaces(examName.toUpperCase())} Live Classes ${new Date().getFullYear()}.`;
    }

    if(ExamCat == "e-books"){
      defaultMetaDesc = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks ${new Date().getFullYear()}.Try out Latest ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online eBooks ${new Date().getFullYear()}.Prepare from the ${removeHyphensAndInsertSpaces(examName.toUpperCase())} eBooks for the upcoming ${removeHyphensAndInsertSpaces(examName.toUpperCase())} exam ${new Date().getFullYear()}.`;
    } else if(ExamCat=="mock tests") {
      defaultMetaDesc = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test ${new Date().getFullYear()}.Practice ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test Online.Free ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test Series are also available.${removeHyphensAndInsertSpaces(examName.toUpperCase())} Mock Test ${new Date().getFullYear()} are the best mock tests for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}.`;
    } else if(ExamCat=="books") {
      defaultMetaDesc = `Buy Best ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Books ${new Date().getFullYear()}. Get ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Booklist ${new Date().getFullYear()} for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam. ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Subject wise books in Hindi & English.`;
    } else if(ExamCat=="videos") {
      defaultMetaDesc = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Courses ${new Date().getFullYear()},Watch a free Online ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video Course Class & Take ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Video course for to Boost your Preparation`;
    } else if(ExamCat=="online live classes"){
      defaultMetaDesc = `${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online Coaching ${new Date().getFullYear()}.Attend a free ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online Live Classes.${removeHyphensAndInsertSpaces(examName.toUpperCase())} Online Coaching ${new Date().getFullYear()} & Online Live Classes for ${removeHyphensAndInsertSpaces(examName.toUpperCase())} Exam ${new Date().getFullYear()}.`;
    }

    return (
      <>
        {validateProductName(requiredProductName) ? (
          <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
            {!this.props.isPaytmMini && (
              <div className={"dfd-container"}>
                <DfpAdPdp />
              </div>
            )}
            <CustomHelmet
              title={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaTitle
                  ? this.props.seoData[pathname].metaTitle
                  : defaultMetaTitle}
              description={this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].metaDescription
                  ? this.props.seoData[pathname].metaDescription
                  : defaultMetaDesc}
              url={
                (this.props.seoData &&
                  this.props.seoData[pathname] &&
                  this.props.seoData[pathname].canonical) ||
                this.props.req_url
              }
              keywords={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].keywords
              }
              noFollow={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noFollow
              }
              noIndex={
                this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].noIndex
              }
            />
            <BreadcrumbSchema
              items={[
                {
                  title: "HOME",
                  url: "/",
                },
                {
                  title: removeHyphensAndInsertSpaces(examName),
                  url: `/${examName}-exam-kit`,
                },
                {
                  title: productNames[requiredProductName],
                },
              ]}
            />
            {(requiredProductName === "live-classes" || requiredProductName === "videos") ? 
              <CarouselSchema 
                data = {examDetailListing?.data?.packageEsEntity} 
                origin = {this?.props?.origin} 
                isOnlyLiveClasses={true}
                schemaType = "Carousel"
              /> : ""
            }
            <div className={"breadcrumb-container"}>
              <BreadCrumb
                routeData={[
                  { name: "Home", target: "/" },
                  {
                    name: `${removeHyphensAndInsertSpaces(
                      examName.toUpperCase()
                    )} Exam Kit`,
                    target: `/${examName}-exam-kit`,
                  },
                  {
                    name:
                      this.props.seoData[pathname]?.extra1 ||
                      productNames[requiredProductName],
                    target: "",
                  },
                ]}
              />
            </div>
            <div className={"header-container"}>
              <h1 className={"listing-header"} style={{ "text-transform": "capitalize" }}>
                {(this.props.seoData &&
                  this.props.seoData[pathname] &&
                  this.props.seoData[pathname].pageHeading
                    ?.split(":")
                    .join(" : ")
                    ?.split(",")
                    .join(", ")) ||
                  defaultHeading}
              </h1>
              {this.props.seoData &&
                this.props.seoData[pathname] &&
                this.props.seoData[pathname].pageHeadingDescription ? (
                  <div className="headingdescription seoHeadingDescription">
                    {this.props.seoData[pathname].pageHeadingDescription}
                  </div>
                ) :  <div className="headingdescription seoHeadingDescription">
                {defaultHeadingDescription}
              </div>}
            </div>

            {examDetailListing &&
              examDetailListing?.data &&
              examDetailListing?.data?.packageEsEntity ? (
              <div className={"listing-data-container"}>
                <div className={"filter-parent-container col m3 fcwidth"}>
                  <SearchBlock
                    similarProduct={this?.state?.similarProduct}
                    examCategoryArray={this?.productCategoryArray}
                    facetsList={examDetailListing?.data?.facetsList}
                    showSearch={false}
                    filterHeading={capitalizedString(examName)}
                    currentItem={examName}
                    currentexamName={examName}
                    SuffixValue={productNames[requiredProductName]}
                    courseType={examName}
                    productName={productName}
                    examCat={productNames[requiredProductName]}
                    type={"examDetail"}
                    heading="Related Products"
                    getNewParamsforAllCategory={this.getNewParamsForAllCategory}
                    selectedTags={tagArr}
                    getUtmFromStore={this.props.utmParams}
                  />
                </div>
                <div className={"col m9 fccontwidth"}>
                  {dataLoader ? (
                    <>
                      <CustomSkeleton />
                    </>
                  ) : (
                    <>
                      {tagArr.length > 0 && (
                        <div className={"tag-container"}>
                          <FilterTags
                            tagArr={tagArr}
                            removeTag={this.removeTag}
                          />
                          <div
                            className={"clear-all"}
                            onClick={this.clearAllFilters}
                          >
                            Clear All
                          </div>
                        </div>
                      )}
                      {!examDetailListing.data.packageEsEntity.length ? (
                        <NoResultsFound
                          handleOnClick={this.clearAllFilters}
                          allCategoryData={
                            this.props.allTrendingData?.data?.hotSellingPackages
                          }
                          handleDetailCardClick={this.handleDetailCardClick}
                        />
                      ) : (
                        <>
                          <BottomScrollListener
                            offset={600}
                            onBottom={() =>
                              this.getPaginatedDataOnScroll(examName)
                            }
                          >
                            <ProductTypeListing
                              allCategoryData={examDetailListing.data}
                              packageCount={examDetailListing.data.count}
                              handleDetailCardClick={this.handleDetailCardClick}
                              heading={productNames[requiredProductName]}
                              sortBy={true}
                              handleSortBy={this.handleSortBy}
                              sortByValue={this.state.sortBy}
                              showLoader={this.state.showProductLoader}
                              getUtmFromStore={this.props.utmParams}
                              useTitle={`${examDetailListing.data.count} ${productNames[requiredProductName]
                                } for ${removeHyphensAndInsertSpaces(
                                  examName.toUpperCase()
                                )}`}
                              hideSortByFilter = {true}
                            />
                          </BottomScrollListener>
                          {listingLoader && (
                            <div style={{ margin: "20px" }}>
                              <CustomLoader buttonSpinner={true} />
                            </div>
                          )}
                        </>
                      )}
                      {examDetailListing?.data?.packageEsEntity?.length ? <div className={'filter-sort-wrapper'}>
                      <div className={'only-mobile sort-btn'}
                              onClick={this.handleSortButton}>
                              <div className={'sort-btn-wrapper'}>
                                  <span className='filter-sort-btn-icon'>
                                      <img src={'/images/sort-bottom-icon.svg'} alt="sort" title='sort' />                                                                    {
                                          // tagArr.length ? (
                                          //     <span className={'dot-filter'}></span>) : (
                                          //     <span></span>)

                                      }
                                      <span className={'filter-name'}>Sort By</span>
                                  </span>
                                  <span className='filter-sort-btn-label'>
                                      <span className='filter-selected-category-wrapper'>
                                          {showSortByAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                          <span className='selected-filter-category'>{selectedSortCategory}</span>
                                      </span>
                                  </span>
                              </div>
                          </div>
                          {handleShowFilter(this.props.examDetailListing?.data?.facetsList || this.state?.secondaryFacetsList, validFilters) && <div className={'only-mobile filter-btn'}
                              onClick={this.handleFilterButton}>
                              <div className={'filter-btn-wrapper'}>
                              <span className='filter-sort-btn-icon'>
                                      <img src={'/images/filter-bottom-icon-new.svg'} alt="filter" title='filter' />                                                                    
                                      <span className={'filter-name'}>Filters</span>
                                  </span>
                                  <span className='filter-sort-btn-label'>
                                      <span className='filter-selected-category-wrapper'>
                                          {showFilterAppliedDot ? <span className={'filter-applied-dot'}></span> :"" }
                                          <span className='selected-filter-category'>{selectedFilterCategory}</span>
                                      </span>
                                  </span>
                              </div>
                          </div>}
                      </div> : ""}
                      {showFilterModal && (
                        <NewMobileFilter
                          visible={showFilterModal}
                          onCancel={this.hideFilterOnMobile}
                          onOk={this.onOk}
                          facetsList={examDetailListing?.data?.facetsList || this.state?.secondaryFacetsList}
                          handleApply={this.handleFilterApply}
                          facetsObject={filterObj}
                          resetFilter={this.resetFilter}
                          type={1}
                          selectedTags={tagArr}
                          facetObj={facetObj}
                          variant={"examDetail"}
                        />
                      )}
                      {showSortModal &&
                        <NewSortByMobileFilter
                            handleSortBy = {this.handleSortBy}
                            handleSortButton = {this.handleSortButton}
                            setSelectedSortCategory = {this.setSelectedSortCategoryChange}
                            selectedSortCategory = {this.state.selectedSortCategory}
                        />
                    }
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="listing-spinner-wrapper">
                <CustomLoader />
              </div>
            )}
          </div>
        ) : (
          <div style={{ marginTop: "80px", height: "9vh" }}>
            <NotFoundStatic />
          </div>
        )}
        {this.props?.seoData[pathname]?.bottomContent && (
          <div className="bottomContentContainer">
            <div
              className="bottomContent"
              dangerouslySetInnerHTML={{
                __html: this.props.seoData[pathname].bottomContent,
              }}
            ></div>
          </div>
        )}

        {this.props.seoData &&
          this.props.seoData[pathname] &&
          this.props.seoData[pathname].faqs &&
          this.props.seoData[pathname].faqs.length >= 1 &&
          checkProperties(this.props.seoData[pathname].faqs[0]) && (
            <div className="faqcontainersearch">
              {" "}
              <Faq faqJson={this.props.seoData[pathname].faqs} />
            </div>
          )}
        <div className="related-category-mobile">
          <div className="title-seo">Related Products</div>

          <SeoSectionMobile
            data={this.productCategoryArray}
            currentexamName={examName}
            examCat={productNames[requiredProductName]}
          />
        </div>
        {this.state.similarProduct && this.state.similarProduct.length >= 1 && (
          <div className="related-category-mobile">
            <div className="title-seo">Important Exams Study Kits</div>

            <SeoSectionMobile
              data={this.state.similarProduct.slice(0, 5)}
              examCat={""}
              courseType={examName}
              productName={productName}
              isRelatedCategory={true}
              SuffixValue={productNames[requiredProductName]}
            />
          </div>
        )}
      </>
    );
  }
}

ExamDetailListing.fetching = ({ dispatch, match, location }) => {
  return [
      dispatch(getPaginatedDataForExamDetail({
          pageNo: 0,
          examName: match?.params?.examName,
          type: 'init',
          categoryFacet: replaceHyphenWithUnderscore(viewAllKeys[match?.params?.productType]),
          queryParam: location.indexOf('?') !== -1 ? `&${location.substring(location.indexOf('?') + 1)}`: "",
          sortBy: 'RELEVANCE'
      }
      ))
  ];
};
const mapStateToProps = (state) => {
  const { examDetailListing } = state;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  let req_url = state.req_url;
  let seoData = state.seoData;
  let isPaytmMini = state.isPaytmMini;
  const examList = state.header.examList;
  const allTrendingData = state.header.allTrendingData;
  const origin = state?.header?.origin || "";
  const globalExamList = state?.header?.globalExamList;
  return {
    examDetailListing,
    utmParams,
    userInfo,
    isLoggedIn,
    req_url,
    seoData,
    isPaytmMini,
    examList,
    allTrendingData,
    origin,
    globalExamList,
  };
};

// server side API fetching for SSR
ExamDetailListing.fetching = ({ dispatch, match, location }) => {
  const productName = location?.split("/")[2];
  const initialProductName = productName?.split("-")?.slice(0, -1)?.join("-");
  if (location) {
    const examDetailObj = {
      pageNo:0,
      examName:location?.split("/")[1] || "",
      type: "init",
      categoryFacet: replaceHyphenWithUnderscore(
        viewAllKeys[initialProductName]
      ),
      queryParam: location?.split("?")[1] ? `&${location?.split("?")[1]}`: "",
      sortBy: "RELEVANCE",
      sponsoredCategory
    };
    return [
      dispatch(getPaginatedDataForExamDetail(examDetailObj))
    ];
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaginatedDataForExamDetail: (obj) =>
      dispatch(getPaginatedDataForExamDetail(obj)),
    clearExamDetailData: () => dispatch(clearExamDetailData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetailListing);

import React, { useEffect, useRef, useState } from "react";
import Loadable from 'react-loadable';
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import {
  PaytmMerchant_Id,
  paytmMiniUrls,
  STOREFRONT_URI,
  pageName,
  pdpUrls,
  prodUrls,
} from "../../constants/appConfig";

import {
  fetchExamList,
  fetchGlobalExamList,
} from "../data/ducks/header/actions";

import {
  clearFeed,
  getRequesQuizStates,
  setPostDataWithStatus,
  setUserCourse,
} from "../data/ducks/home/actions";
import "../data/ducks/home/reducers";
import fetch from "../data/utils/fetch";
import {
  getDeviceType,
  getMoengageMetadata,
  getParameterByName,
  isEmpty,
  scrollToTop,
  optimizedImgUrl,
  sendDataToCretio,
  trackMoengageEvent,
  isADDA,
} from "../data/utils/helpers";
import { COURSE_CATEGORY_IDENTIFIERS, DEVICE_IDENTIFIERS } from "../../constants/textConstants";
import CustomLoader from "../components/library/CustomLoader";
import { useLocation } from "react-router";

const GetLink = Loadable({
  loader: () => import("../components/GetLink"),
  modules: ["../components/GetLink"],
  webpack: [require.resolve("../components/GetLink")],
  loading: () => <div></div>,
});
const ContineInApp = Loadable({
  loader: () => import("../components/library/ContinueInApp/ContinueInApp"),
  modules: ["../components/library/ContinueInApp/ContinueInApp"],
  webpack: [require.resolve("../components/library/ContinueInApp/ContinueInApp")],
  loading: () => <div>....</div>,
});
const BuyNowCheckoutModalCRM = Loadable({
  loader: () => import("../components/BuyNowCheckoutModalCRM"),
  modules: ["../components/BuyNowCheckoutModalCRM"],
  webpack: [require.resolve("../components/BuyNowCheckoutModalCRM")],
  loading: () => <div>....</div>,
});
const FooterText = Loadable({
  loader: () => import("../components/FooterText"),
  modules: ["../components/FooterText"],
  webpack: [require.resolve("../components/FooterText")],
  loading: () => <div>....</div>,
});


const ExamMenu = Loadable({
  loader: () => import("../components/home/ExamMenu/ExamMenu"),
  modules: ["../components/home/ExamMenu/ExamMenu"],
  webpack: [require.resolve("../components/home/ExamMenu/ExamMenu")],
  loading: () => <div>....</div>,
});
const BrowseByProductType = Loadable({
  loader: () => import("../components/home/BrowseByProductType"),
  modules: ["../components/home/BrowseByProductType"],
  webpack: [require.resolve("../components/home/BrowseByProductType")],
  loading: () => <div>....</div>,
});
const ConitnueReadingLatest = Loadable({
  loader: () => import("../components/home/ContinueReadingLatest"),
  modules: ["../components/home/ContinueReadingLatest"],
  webpack: [require.resolve("../components/home/ContinueReadingLatest")],
  loading: () => <div>....</div>,
});
const RecentlyAdded = Loadable({
  loader: () => import("../components/home/RecentlyAdded"),
  modules: ["../components/home/RecentlyAdded"],
  webpack: [require.resolve("../components/home/RecentlyAdded")],
  loading: () => <div>....</div>,
});
const MyPurchasedCoursesLatest = Loadable({
  loader: () => import("../components/home/MyPurchasedCoursesLatest"),
  modules: ["../components/home/MyPurchasedCoursesLatest"],
  webpack: [require.resolve("../components/home/MyPurchasedCoursesLatest")],
  loading: () => <div>....</div>,
});


const LearnWithAdda = Loadable({
  loader: () => import("../components/home/LearnWithAdda"),
  modules: ["../components/home/LearnWithAdda"],
  webpack: [require.resolve("../components/home/LearnWithAdda")],
  loading: () => <div>....</div>,

});

const FixedMockBanner = Loadable({
  loader: () => import("../components/home/FixedMockBanner"),
  modules: ["../components/home/FixedMockBanner"],
  webpack: [require.resolve("../components/home/FixedMockBanner")],
  loading: () => <div>....</div>,

});
const PlatformBanner = Loadable({
  loader: () => import("../components/home/PlatformBanner"),
  modules: ["../components/home/PlatformBanner"],
  webpack: [require.resolve("../components/home/PlatformBanner")],
  loading: () => <div>....</div>,

});
const Testimonials = Loadable({
  loader: () => import("../components/home/Testimonials"),
  modules: ["../components/home/Testimonials"],
  webpack: [require.resolve("../components/home/Testimonials")],
  loading: () => <div>....</div>,

});
const CustomHelmet = Loadable({
  loader: () => import("../components/library/CustomHelmet"),
  modules: ["../components/library/CustomHelmet"],
  webpack: [require.resolve("../components/library/CustomHelmet")],
  loading: () => <div>....</div>,

});
const CustomModal = Loadable({
  loader: () => import("../components/library/CustomModal"),
  modules: ["../components/library/CustomModal"],
  webpack: [require.resolve("../components/library/CustomModal")],
  loading: () => <div>....</div>,

});



const cookie = new Cookies();
// const carouselFeedbackContainer = React.createRef();
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
var count = 0;
const Home = (props) => {
  //for checking whether tab is hidden or not
  let hidden = null;
  let visibilityChange = null;
  if (
    typeof document !== "undefined" &&
    typeof document.hidden !== "undefined"
  ) {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (
    typeof document !== "undefined" &&
    typeof document.msHidden !== "undefined"
  ) {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (
    typeof document !== "undefined" &&
    typeof document.webkitHidden !== "undefined"
  ) {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
  // const history = useHistory();
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const [showCloseIcon, setShowCloseIconModal] = useState(true);
  const [showUserConsent, setshowUserConsent] = useState(false);
  const [innerWidth, setInnerWidth] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isOrderEnable, setIsOrderEnable] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [viewOfferModalOnly, setViewOfferModalOnly] = useState(null);
  const [applyOffers, setApplyOffers] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});
  const [userEmail, setUserEmail] = useState();
  const [fromBuyNowBtn, setFromBuyNowBtn] = useState(false);
  const [showCongratePopUp, setShowCongratePopUp] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const orderId = query.get("orderId");
  const guestUserToken = query.get("token");
  const [loadingOrderDetails, setLoadingOrderDetails] = useState((orderId && orderId !== "-") ? true: false);

  const [clickedItemData, setClickedItemData] = useState();
  useEffect(() => {
    if (innerWidth === null) {
      setInnerWidth(window.innerWidth);
    }
  }, []);

  var userId = cookie.get("cp_user_id");

  const redirectAfterLogin = () => {
    if (localStorage.getItem("GoToTest")) {
      if (localStorage.getItem("urlForTestPortal")) {
        const testPortalUrl = localStorage.getItem("urlForTestPortal");
        localStorage.removeItem("urlForTestPortal");
        localStorage.removeItem("GoToTest");
        window.open(
          testPortalUrl,
          "_blank",
          "height=" +
          screen.height +
          ",width=" +
          screen.width +
          ",resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes"
        );
      }
    } else if (localStorage.getItem("urlAfterLogin")) {
      if (localStorage.getItem("urlAfterLogin")) {
        const quizUrl = localStorage.getItem("urlAfterLogin");
        localStorage.removeItem("urlAfterLogin");
        localStorage.removeItem("GoToQuizRegister");
        props.history.push({ pathname: quizUrl, state: { src: "/" } });
      }
    } else if (localStorage.getItem("urlForFreeQuizzes")) {
      const freeQuizUrl = localStorage.getItem("urlForFreeQuizzes");
      localStorage.setItem("freeQuizTabId", 2);
      localStorage.removeItem("urlForFreeQuizzes");
      props.history.push(freeQuizUrl);
    } else if (localStorage.getItem("GoToQuizResult")) {
      const resultUrl = localStorage.getItem("resultUrl");
      localStorage.removeItem("GoToQuizResult");
      localStorage.removeItem("resultUrl");
      window.open(resultUrl);
    }
  };

  useEffect(() => {
    redirectAfterLogin();
    if (props.location.state && props.location.state.isRegistered) {
      setShowCongratePopUp(true);
    }
  }, [userId]);

  const selectCourse = (selectedCourse) => {
    try {
      document.body.classList.remove("noscroll");
    } catch (ex) { }
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    props.clearFeed();
    cookie.set("cp_user_" + user_id, selectedCourse, { path: "/", expires: d });
    // dispatch an action to set the selected course in the reducer and then save the same in cookie
    props.setUserCourse(selectedCourse);
    setTimeout(() => {
      let d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      var user_id = cookie.get("cp_user_id");
      if (user_id) {
        cookie.set("cp_user_" + user_id, selectedCourse, {
          path: "/",
          expires: d,
        });
      }
    }, 0);
    setShowCourseModal(false);
    setShowCloseIconModal(true);
  };

  let [pageNumber, setPageNumber] = useState(0);
  function ready(callback) {
    // call if jsbridge is injected
    if (window.JSBridge) {
      callback && callback();
    } else {
      // listen to jsbridge ready event
      document.addEventListener("JSBridgeReady", callback, false);
    }
  }

  const [showCourseModal, setShowCourseModal] = useState(false);
  const { examList, globalExamList, userInfo, isLoggedIn, utmParams } = props;
  const consentCancel = () => {
    JSBridge && JSBridge.call("popWindow");
    setshowUserConsent(false);
  };
  const updatedUserInfoFromPaytm = (authId, openId) => {
    fetch(paytmMiniUrls.userInfo, "GET", undefined, undefined, undefined, {
      authId: authId,
      openId: openId,
    })
      .then(
        (res) => {
          if (res.data) {
            // save info in local storage
            let userData = JSON.parse(res.data);
            var name = localStorage.getItem("name");
            var email = localStorage.getItem("email");
            var phone = localStorage.getItem("phone");

            let paytmUserDetails = {
              name: userData.profileInfo.displayName || name,
              phone: userData.phoneInfo.phoneNumber || phone,
              email: userData.email || email,
            };

            var moepayload = {};
            moepayload.user_email =
              (paytmUserDetails && paytmUserDetails.email) || "";
            moepayload.user_name =
              (paytmUserDetails && paytmUserDetails.name) || "";
            moepayload.user_mobile_no =
              (paytmUserDetails && paytmUserDetails.phone) || "";
            trackMoengageEvent("is_paytm_home_page_view", moepayload);
            localStorage.setItem(
              "paytm-user-info",
              JSON.stringify(paytmUserDetails)
            );
          }
        },
        (err) => {
          console.log("paytm-mini-userinfo-err", err);
        }
      )
      .catch((err) => {
        console.log("paytm-mini-userinfo-catch", err);
      });
  };

  const proceedConsent = () => {
    setshowUserConsent(false);
    paytmFetchAuthCode();
  };

  const paytmFetchAuthCode = () => {
    if (props.isPaytmMini) {
      localStorage.setItem("cp-origin", 23);
      ready(function () {
        JSBridge.call(
          "paytmFetchAuthCode",
          {
            clientId: PaytmMerchant_Id,
          },
          function (result) {
            if (result.error == -1) {
              count++;
              setshowUserConsent(true);
            } else if (result.data) {
              updatedUserInfoFromPaytm(
                result.data.authId,
                result.data.authId,
                result.data.openId
              );
            } else {
              var userDetails = localStorage.getItem("paytm-user-info");
              var moepayload = {};
              moepayload.user_email = (userDetails && userDetails.email) || "";
              moepayload.user_name = (userDetails && userDetails.name) || "";
              moepayload.user_mobile_no =
                (userDetails && userDetails.phone) || "";
              trackMoengageEvent("is_paytm_home_page_view", moepayload);
            }
          }
        );
      });
    }
  };

  useEffect(() => {
    paytmFetchAuthCode();
    let user_id_cookie = cookie.get("cp_user_id");

    if (userInfo && isLoggedIn && typeof window != "undefined") {
      window.dataLayer.push({
        name: (userInfo && userInfo.name) || "guest",
        email: (userInfo && userInfo.email) || "",
        isMobile: getDeviceType() == DEVICE_IDENTIFIERS.MOBILE_IDENTIFIER ? true : false,
        loginType: "email",
        isLoggedIn: true,
      });
    } else if (!isLoggedIn && !user_id_cookie && typeof window != "undefined") {
      window.dataLayer.push({
        name: "guest",
        email: "",
        isMobile: getDeviceType() == DEVICE_IDENTIFIERS.MOBILE_IDENTIFIER ? true : false,
        loginType: "",
        isLoggedIn: false,
      });
    }

    var user_id = cookie.get("cp_user_id") || (userInfo && userInfo.id);
    if (user_id && !cookie.get("cp_user_" + user_id)) {
      setShowCourseModal(true);
      setShowCloseIconModal(false);
    } else if (cookie.get("cp_token") && !cookie.get("cp_user_" + user_id)) {
      setShowCourseModal(true);
    }

    if (props.isLoggedIn) {
      if (props.location.search == "?feed") {
        executeScroll();
      }
      props.setUserCourse(props.userCourse);
      setTimeout(() => {
        if (props.userCourse)
          localStorage.removeItem("categorySelectedForFeed");
      }, 1000);
    }
  }, [props.isLoggedIn]);

  useEffect(() => {
    let payload = {};
    payload.user_email = cookie.get("cp_user_email") || "";
    payload.user_name = cookie.get("cp_user_name") || "";
    payload.source_screen = "";
    payload.examCategory = props.userCourse;
    payload.getUtmFromStore = utmParams;
    trackMoengageEvent("home_page_view", payload);
    if (!isEmpty(examList)) {
      localStorage.setItem("examMenuData", JSON.stringify(props.examList));
    }
    checkForDevice();
    if (orderId && orderId !== "-") {
      try{
        fetch(`${STOREFRONT_URI}/api/v1/order/validate/${orderId}`, "POST", {
          redirectUrl: "",
        }, "", "", {token: guestUserToken}).then(
          (res) => {
            setOrderDetails(res?.data);
            if (res?.data?.couponDiscount) {
              setApplyOffers(true);
            }
            setIsOrderEnable(true);
          }).catch((err) => {
            console.log("err: ", err);
            setIsOrderEnable(false);
          }).finally(() => {
            setLoadingOrderDetails(false);
          })
      }catch(err){
        console.log("err: ", err);
      }
    }
  }, []);

  var user_id = cookie.get("cp_user_id");
  const fireCretio = () => {
    sendDataToCretio({ event: "viewHome" });
  };

  const goTo = (url) => {
    props.history.push(url);
  };
  const checkForDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  let pathname = props.location.pathname;
  // moEngage fire on Explore quiz clicked
  const moengageEventTracker = (e) => {
    let payload = { source: "web", brand: pageName[1] };
    payload.Button_URL = e.url;
    payload.Type = "Explore quiz, articles and videos";

    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "home page Partner Site button clicked  ",
        getMoengageMetadata(payload)
      );
  };

  const handleAppRedirectPopup = (fpmId) => {
    setClickedItemData({
      fpmId: fpmId,
      type: COURSE_CATEGORY_IDENTIFIERS.FPM_IDENTIFIER
    });
    return;
  }

  if(loadingOrderDetails){
    return (
      <div className = "mt-120">
        <CustomLoader/>
      </div>
    )
  }
  const onClose = () => {
    const hostname = window.location.hostname;
    if(hostname === prodUrls.paymentLinkHostname){
      window.location.href = prodUrls.baseUrl
    }else{
      setIsOrderEnable(false)
    }
  }

  return (
    <>
      <div className="home-new adda-home">
        <div className={"consentWindowModal"}>
          <CustomModal
            hideHeader={true}
            modalClassName={"modalZindex"}
            footer={null}
            visible={showUserConsent}
            closable={true}
            maskClosable={true}
            keyboard={false}
            bodyStyle={{ overflowY: "auto" }}
          >
            <div className="consentContainer">
              <h3 className="ConsentHeading">Oops!</h3>
              <p className="consenttext">
                We cannot proceed further without this inforrmation Please try
                again..
              </p>
              <div className="consentactioncontainer">
                <span className="consentCancel" onClick={consentCancel}>
                  Cancel
                </span>
                {count <= 3 && (
                  <span className="consentProceed" onClick={proceedConsent}>
                    Proceed
                  </span>
                )}
              </div>
            </div>
          </CustomModal>
        </div>
        <CustomHelmet
          title={
            props.seoData &&
              props.seoData[pathname || "/"] &&
              props.seoData[pathname || "/"].metaTitle
              ? props.seoData[pathname || "/"].metaTitle
              : `${pageName[1]} - ${isADDA ? 'India\'s Largest Vernacular Learning Platform' : 'Live Classes and Doubt Sessions for JEE, NEET'}`
          }
          description={
            props.seoData &&
              props.seoData[pathname || "/"] &&
              props.seoData[pathname || "/"].metaDescription
              ? props.seoData[pathname || "/"].metaDescription
              : `${pageName[1]} is ${isADDA ? 'India\'s largest vernacular learning platform with courses in more than 12 Indian languages. Adda247 is backed by marquee investors like Google, WestBridge Capital, Infoedge etc. Adda247 is on a mission to take quality education to the masses by providing online courses in local languages at a very affordable price.' : 'India’s Best Preparation platform for Live Classes and Doubt Sessions of JEE, NEET'}`
          }
          url={
            (props.seoData &&
              props.seoData[pathname || "/"] &&
              props.seoData[pathname || "/"].canonical) ||
            props.req_url
          }
          keywords={
            props.seoData &&
            props.seoData[pathname || "/"] &&
            props.seoData[pathname || "/"].keywords
          }
          noFollow={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noFollow
          }
          noIndex={
            props.seoData &&
            props.seoData[pathname] &&
            props.seoData[pathname].noIndex
          }
        />
        {/* <!--baner section--> */}
        <GetLink pos="header" isHome />

        {/* New Fixed Mock Banner Section Start */}
        {showCongratePopUp && (
          <div className="mock-comman-pop">
            <div className="mock-comman-pop-content">
              <div className="short-heading-popup">Congratulations!</div>
              <div className="comman-pop-content">
                You have been successfully registered.
              </div>
              <div className="mock-pop-btn">
                <a
                  href="#"
                  onClick={() => {
                    setShowCongratePopUp(false);
                  }}
                  className="redcolorbtn"
                >
                  CONTINUE
                </a>
              </div>
            </div>
          </div>
        )}
        <FixedMockBanner handleAppRedirectPopup={handleAppRedirectPopup} />
        {/* New Fixed Mock Banner Section End */}

        <div className="container">
          <div>
            {cookie.get("cp_token") && <ConitnueReadingLatest goTo={goTo} />}
            {cookie.get("cp_token") && <MyPurchasedCoursesLatest goTo={goTo} />}
            {cookie.get("cp_token") && <RecentlyAdded goTo={goTo} />}

            <ExamMenu
              title={"Explore by"}
              subtitle={"Exams"}
              examData={examList}
              globalExamList={globalExamList}
              history={props.history}
              isPaytmMini={props.isPaytmMini}
              getUtmFromStore={utmParams}
            />

            <BrowseByProductType getUtmFromStore={utmParams} />

            {isADDA ? <PlatformBanner /> : <></>}
            {innerWidth && innerWidth >= 720 && isADDA ? (
              <LearnWithAdda />
            ) : (
              <></>
            )}
            {isADDA ? <Testimonials /> : <></>}
            {/* <!--Download App--> */}
            <GetLink />
            {fireCretio()}
          </div>
        </div>
      </div>

      {isOrderEnable && (
        <BuyNowCheckoutModalCRM
          maxPrice={orderDetails?.totalSellingPrice}
          price={orderDetails?.payablePrice}
          priceDetails={orderDetails}
          viewOfferModalOnly={viewOfferModalOnly}
          userInfo={props.userInfo}
          isLoggedIn={props.isLoggedIn}
          setViewOfferModalOnly={setViewOfferModalOnly}
          viewOffers={setApplyOffers}
          updatePriceDetails={setOrderDetails}
          loggedUserDetails={props.loggedUserDetails}
          productData={orderDetails?.cart[0]}
          cartJson={`{"${orderDetails?.cart[0].id}":1}`}
          isShippable={orderDetails?.cart[0].shippable}
          userEmail={orderDetails?.email}
          setUserEmail={setUserEmail}
          Getpayload={{}}
          orderId={getParameterByName("orderId")}
          setCheckoutData={setCheckoutData}
          checkoutData={checkoutData}
          getUtmFromStore={props.utmParams}
          setFromBuyNowBtn={(flag) => setFromBuyNowBtn(flag)}
          razorpayPayload={orderDetails?.razorPayCreateOrderResponse}
          payablePrice={orderDetails && orderDetails?.payablePrice}
          onClose={onClose}
        ></BuyNowCheckoutModalCRM>
      )}
      <FooterText />
      <ContineInApp
        show={!!clickedItemData}
        fpmId={clickedItemData && clickedItemData?.fpmId}
        type={clickedItemData && clickedItemData?.type}
        close={() => {
          setClickedItemData();
        }}
      />
    </>
  );
};

const mapDispatchToProps = {
  fetchGlobalExamList,
  fetchExamList,
  setUserCourse,
  getRequesQuizStates,
  setPostDataWithStatus,
  clearFeed,
};

const mapStateToProps = (state) => ({
  examList: state.header.examList,
  globalExamList: state.header.globalExamList,
  userDetails: state.header.userInfo || {},
  coursePosts: state.home.coursePosts,
  isLoggedIn: state.header.jwtToken,
  userCourse:
    state.home.userCourse || cookie.get("cp_user_" + cookie.get("cp_user_id")),
  userInfo: state.header.userInfo,
  utmParams: state.referrer || {},
  req_url: state.req_url,
  isPaytmMini: state.isPaytmMini,
  seoData: state.seoData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
import React, { useState, useRef } from "react";
import { useDrag } from "../../../utils";
import External from "./External";
const SciCalci = (props) => {
  const [showCalculator, setShowCalculator] = useState("cal");
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const divRef = useRef();

  const handleDrag = (e) => {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY,
    });
  };

  const drag = useDrag(divRef, [translate], {
    onDrag: handleDrag,
  });
  return (
    <div className="PfixedCalc"
      ref={divRef}
      style={{
        transform: `translateX(${translate.x}px) translateY(${translate.y}px)`,
        cursor: "all-scroll",
        position: "absolute",
        bottom: "0px",
        right:'10px'
      }}
    >
            {showCalculator == "cal" && <div >
                <External />
                <div id="keyPad" className="ui-widget-content calc_container">
                    {/* new Help changes */}
                    <div id="helptopDiv">
                        <span>Scientific Calculator</span>
                        <span><img className="calculator-close-icon" src="/images/close-icon.svg" onClick={() => props.showHide(false)} /></span>
                        <button className="calculator-help-btn" onClick={() => setShowCalculator("help")}>Help</button>
                    </div>
                    {/* main content start here*/}
                    <div id="mainContentArea">
                        <input type="text" id="keyPad_UserInput1" className="keyPad_TextBox1" readOnly />
                        <div className="text_container">
                            <input type="text" id="keyPad_UserInput" className="keyPad_TextBox" maxLength={30} readOnly />
                            <span id="memory" className="memoryhide">
                                <font size={2}>M
                                </font></span><font size={2}>
                            </font></div><font size={2}>
                            <div className="clear" />
                            <div className="left_sec">
                                <div className="calc_row clear">
                                    <a href="#nogo" id="keyPad_btnMod" className="keyPad_btnBinaryOp">mod</a>
                                    <div className="degree_radian">
                                        <input type="radio" name="degree_or_radian" defaultValue="deg" defaultChecked="checked" />Deg
                                        <input type="radio" name="degree_or_radian" defaultValue="rad" />Rad
                                    </div>
                                    <a href="#nogo" id="keyPad_btnPi" className="keyPad_btnConst" style={{ visibility: 'hidden' }}>π</a>
                                    <a href="#nogo" id="keyPad_btnE" className="keyPad_btnConst" style={{ visibility: 'hidden' }}>e</a>
                                    <a href="#nogo" id="keyPad_btnE" className="keyPad_btnConst" style={{ visibility: 'hidden' }}>e</a>
                                </div>
                                <div className="calc_row clear">
                                    <a href="#nogo" id="keyPad_MC" className="keyPad_btnMemoryOp">MC</a>
                                    <a href="#nogo" id="keyPad_MR" className="keyPad_btnMemoryOp">MR</a>
                                    <a href="#nogo" id="keyPad_MS" className="keyPad_btnMemoryOp">MS</a>
                                    <a href="#nogo" id="keyPad_M+" className="keyPad_btnMemoryOp">M+</a>
                                    <a href="#nogo" id="keyPad_M-" className="keyPad_btnMemoryOp">M-</a>
                                </div>
                                <div className="calc_row clear">
                                    <a href="#nogo" id="keyPad_btnBack" className="keyPad_btnCommand calc_arrows">
                                        <div style={{ position: 'relative', top: '-3px' }}>←</div>
                                    </a>
                                    <a href="#nogo" id="keyPad_btnAllClr" className="keyPad_btnCommand">C</a>
                                    <a href="#nogo" id="keyPad_btnInverseSign" className="keyPad_btnUnaryOp">+/-</a>
                                    <a href="#nogo" id="keyPad_btnOpen" className="keyPad_btnBinaryOp ">(</a>
                                    <a href="#nogo" id="keyPad_btnClose" className="keyPad_btnBinaryOp ">)</a>
                                    <a href="#nogo" id="keyPad_btnSquareRoot" className="keyPad_btnUnaryOp">
                                        <div style={{ position: 'relative', top: '1px' }}>√</div>
                                    </a>
                                </div>
                                <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                    <a href="#nogo" id="keyPad_btn7" className="keyPad_btnNumeric">7</a>
                                    <a href="#nogo" id="keyPad_btn8" className="keyPad_btnNumeric">8</a>
                                    <a href="#nogo" id="keyPad_btn9" className="keyPad_btnNumeric ">9</a>
                                    <a href="#nogo" id="keyPad_btnDiv" className="keyPad_btnBinaryOp">/</a>
                                    <a href="#nogo" id="keyPad_%" className="keyPad_btnBinaryOp">%</a>
                                    <a href="#nogo" id="keyPad_btn10X" className="keyPad_btnUnaryOp"><span className="baseele">10</span><span className="superscript">x</span></a>
                                    <a href="#nogo" id="keyPad_btnInverse" className="keyPad_btnUnaryOp"><span className="baseele">1/x</span></a>
                                </div>
                                <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                    <a href="#nogo" id="keyPad_btn4" className="keyPad_btnNumeric">4</a>
                                    <a href="#nogo" id="keyPad_btn5" className="keyPad_btnNumeric">5</a>
                                    <a href="#nogo" id="keyPad_btn6" className="keyPad_btnNumeric ">6</a>
                                    <a href="#nogo" id="keyPad_btnMult" className="keyPad_btnBinaryOp"><span style={{ position: 'relative', top: '3px', fontSize: '20px' }}>*</span></a>
                                    <a href="#nogo" id="keyPad_btnCube" className="keyPad_btnUnaryOp"><span className="baseele">x</span><span className="superscript">3</span></a>
                                    <a href="#nogo" id="keyPad_btnSquare" className="keyPad_btnUnaryOp"><span className="baseele">x</span><span className="superscript">2</span></a>
                                    <a href="#nogo" id="keyPad_btnE" className="keyPad_btnConst">e</a>
                                </div>
                                <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                    <a href="#nogo" id="keyPad_btn1" className="keyPad_btnNumeric">1</a>
                                    <a href="#nogo" id="keyPad_btn2" className="keyPad_btnNumeric">2</a>
                                    <a href="#nogo" id="keyPad_btn3" className="keyPad_btnNumeric">3</a>
                                    <a href="#nogo" id="keyPad_btnMinus" className="keyPad_btnBinaryOp"><span style={{ position: 'relative', top: '-1px', fontSize: '20px' }}>-</span></a>
                                    <a href="#nogo" id="keyPad_btnCubeRoot" className="keyPad_btnUnaryOp">
                                        <font size={3}>∛ </font>
                                    </a>
                                    <a href="#nogo" id="keyPad_btnAbs" className="keyPad_btnUnaryOp"><span className="baseele">|x|</span></a>
                                    <a href="#nogo" id="keyPad_btnExp" className="keyPad_btnUnaryOp"><span className="baseele">e</span><span className="superscript">x</span></a>
                                </div>
                                <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                    <a href="#nogo" id="keyPad_btn0" className="keyPad_btnNumeric">0</a>
                                    <a href="#nogo" id="keyPad_btnDot" className="keyPad_btnNumeric ">.</a>
                                    <a href="#nogo" id="keyPad_btnPlus" className="keyPad_btnBinaryOp">+</a>
                                    <a href="#nogo" id="keyPad_btnPi" className="keyPad_btnConst">π</a>
                                    <a href="#nogo" id="keyPad_btnYpowX" className="keyPad_btnBinaryOp"><span className="baseele">x</span><span className="superscript">y</span></a>
                                    <a href="#nogo" id="keyPad_btnYrootX" className="keyPad_btnBinaryOp"><span className="superscript" style={{ top: '-8px' }}>y</span><span className="baseele" style={{ fontSize: '1.2em', margin: '-6px 0 0 -9px' }}>√x</span></a>
                                </div>
                                <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                    <a href="#nogo" id="keyPad_btnSin" className="keyPad_btnUnaryOp min ">sin</a>
                                    <a href="#nogo" id="keyPad_btnCosin" className="keyPad_btnUnaryOp min">cos</a>
                                    <a href="#nogo" id="keyPad_btnTg" className="keyPad_btnUnaryOp min">tan</a>
                                    <a href="#nogo" id="keyPad_btnFact" className="keyPad_btnUnaryOp">n!</a>
                                    <a href="#nogo" id="keyPad_btnLogBase2" className="keyPad_btnUnaryOp"><span className="baseele">log</span><span className="subscript">2</span><span className="baseele">x</span></a>
                                    <a href="#nogo" id="keyPad_btnLn" className="keyPad_btnUnaryOp">ln</a>
                                    <a href="#nogo" id="keyPad_btnLg" className="keyPad_btnUnaryOp">log</a>
                                </div>
                                <div className="equa-row-left">
                                    <div className="calc_row clear" style={{ marginTop: '5px' }}>
                                        <a href="#nogo" id="keyPad_btnAsinH" className="keyPad_btnUnaryOp min "><span className="baseele">sinh</span><span className="superscript">-1</span></a>
                                        <a href="#nogo" id="keyPad_btnAcosH" className="keyPad_btnUnaryOp min "><span className="baseele">cosh</span><span className="superscript">-1</span></a>
                                        <a href="#nogo" id="keyPad_btnAtanH" className="keyPad_btnUnaryOp min "><span className="baseele">tanh</span><span className="superscript">-1</span></a>
                                        <a href="#nogo" id="keyPad_btnAsin" className="keyPad_btnUnaryOp min"><span className="baseele">sin</span><span className="superscript">-1</span></a>
                                        <a href="#nogo" id="keyPad_btnAcos" className="keyPad_btnUnaryOp min"><span className="baseele">cos</span><span className="superscript">-1</span></a>
                                        <a href="#nogo" id="keyPad_btnAtan" className="keyPad_btnUnaryOp min"><span className="baseele">tan</span><span className="superscript">-1</span></a>
                                    </div>
                                    <div className="calc_row clear">
                                        <a href="#nogo" id="keyPad_btnYlogX" className="keyPad_btnBinaryOp "><span className="baseele">log</span><span className="subscript">y</span><span className="baseele">x</span></a>
                                    </div>
                                    <a href="#nogo" id="keyPad_btnEnter" className="keyPad_btnCommand ">
                                        <div style={{ marginBottom: '2px' }}>=</div>
                                    </a>
                                </div>
                            </div>
                            <div className="clear" />
                            {/* new Help changes */}

                            {/* new Help changes */}
                            {/* main content end here*/}
                        </font></div><font size={2}>
                    </font></div><font size={2}>
                </font></div>}
            {showCalculator == "help" &&
                <div id="keyPad" className="ui-widget-content calc_container">
                    <div id="helptopDiv">
                        <span>Scientific Calculator</span>
                        <span><img className="calculator-close-icon" src="/images/close-icon.svg" onClick={() => props.showHide(false)} /></span>
                        <button className="calculator-help-btn" onClick={() => setShowCalculator("cal")}>Back</button>
                    </div>
                    <div id="helpContent" onmousedown="return false" >
                        <h3 style={{ textAlign: 'center' }}><strong>Calculator Instructions</strong></h3>
                        Allows you to perform basic and complex mathematical operations such as modulus, square root, cube root,
                        trigonometric, exponential, logarithmic, hyperbolic functions, etc.
                        <br /> You can operate the calculator using the buttons provided on screen with your mouse. <br />
                        <br />
                        <h3 style={{ textDecoration: 'underline', color: 'green' }}>Do's:</h3>
                        <ul>
                            <li> Be sure to press [C] when beginning a new calculation.</li>
                            <li> Simply an equation using parenthesis and other mathematical operators.</li>
                            <li> Use the predefined operations such as p (Pi), log, Exp to save time during calculation.</li>
                            <li> Use memory function for calculating cumulative totals.</li>
                            <strong>
                                [M+]: Will add displayed value to memory.
                                <br />
                                [MR]: Will recall the value stored in memory.
                                <br />
                                [M-]: Subtracts the displayed value from memory.
                            </strong>
                            <li> Be sure select the angle unit (Deg or Rad) before beginning any calculation.</li>
                            <strong>Note: By default angle unit is set as Degree</strong>
                        </ul>
                        <h3><span style={{ textDecoration: 'underline', color: 'red' }}>Dont's:</span></h3>
                        <ul>
                            <li> Perform multiple operations together.</li>
                            <li> Leave parenthesis unbalanced.</li>
                            <li> Change the angle unit (Deg or Rad) while performing a calculation..</li>
                        </ul>
                        <h3><span style={{ textDecoration: 'underline' }}>Limitations:</span></h3>
                        <ul>
                            <li> Keyboard operation is disabled.</li>
                            <li> The output for a Factorial calculation is precise up to 14 digits.</li>
                            <li> The output for Logarithmic and Hyperbolic calculations is precise up to 5 digits.</li>
                            <li> Modulus (mod) operation performed on decimal numbers with 15 digits would not be precise.</li>
                            <br />
                            <strong> Use mod operation only if the number comprises of less than 15 digits i.e. mod operation provides
                                best results for smaller numbers.</strong>
                            <br />
                            <li>The range of value supported by the calculator is 10(-323) to 10(308).</li>
                        </ul>
                        <br />
                        <br />
                    </div></div>}
    </div>
    )
}
export default SciCalci;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Cookies } from "react-cookie";
import { getMyPurchaseListings } from "../../data/ducks/header/actions";
import MyPurchaseList from "./MyPurchaseList";
import PPCBreadCrumbs from "../../components/PPC/PPCBreadCrumbs";
import { scrollToTop } from "../../data/utils/helpers";
import { pageName } from "../../../constants/appConfig";
import CustomHelmet from "../../components/library/CustomHelmet";
const cookie = new Cookies();

const MyPurchase = (props) => {
  const [loading, setLoading] = useState(true);
  const [showGuidanceToast, setShowGuidanceToast] = useState(false);
  const [PPCGuidanceMsg] = useState([
    {
      icon: "/images/HowAcessIcon-1.svg",
      guideMsg: "Select the package you have purchased.",
    },
    {
      icon: "/images/HowAcessIcon-2.svg",
      guideMsg:
        "Select the exam or batch you want to study.",
    },
    {
      icon: "/images/HowAcessIcon-3.svg",
      guideMsg:
        "You can also search your favourite batch from the search bar.",
    },
  ]);
  const { myPurchaseList } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  let { pathname, state } = useLocation();
  let defaultMetaTitle = `My Purchased`;
  let defaultMetaDesc = `List of all ${pageName[0]} products purchased by me.`;
  const getListings = () => {
    dispatch(getMyPurchaseListings()).then((res) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    scrollToTop(0);
    getListings();
  }, []);
  useEffect(() => {
    if (state) {
      setShowGuidanceToast(true);
    }
  }, [state]);
  let breadCrumbsList = [
    {
      route: "/",
      name: "Home",
    },
    {
      route: pathname,
      name: "My Purchases",
    },
  ];
  const token = cookie.get("cp_token");
  if (token) {
    return (
      <div className="ppc-wrap">
        {/* <!--PPC breadcrumbs--> */}
        <section className="ppc-mypack-wrap">
          <div className="ppc-container">
            <PPCBreadCrumbs routes={breadCrumbsList} />
            <CustomHelmet
              title={
                props.seoData &&
                props.seoData[pathname] &&
                props.seoData[pathname].metaTitle
                  ? props.seoData[pathname].metaTitle
                  : defaultMetaTitle
              }
              description={
                props.seoData &&
                props.seoData[pathname] &&
                props.seoData[pathname].metaDescription
                  ? props.seoData[pathname].metaDescription
                  : defaultMetaDesc
              }
              url={
                (props.seoData &&
                  props.seoData[pathname] &&
                  props.seoData[pathname].canonical) ||
                props.req_url
              }
              keywords={
                props.seoData &&
                props.seoData[pathname] &&
                props.seoData[pathname].keywords
              }
              noFollow={
                props.seoData &&
                props.seoData[pathname] &&
                props.seoData[pathname].noFollow
              }
              noIndex={
                props.seoData &&
                props.seoData[pathname] &&
                props.seoData[pathname].noIndex
              }
            />

            {showGuidanceToast && (
              <div className="ppc-guidance-banner">
                <div className="guide-img">
                  <img src={"/images/HowAcessImg.svg"} alt="guide-img" />
                </div>
                <div className="ppc-guide">
                  <label>How to access the course:</label>
                  <div className="ppc-guide-list">
                    {PPCGuidanceMsg?.map((guide, idx) => (
                      <div className="ppc-guide-box" key={`guide_${idx}`}>
                        <img src={guide?.icon} alt="icon" />
                        <span>{guide?.guideMsg}</span>
                      </div>
                    ))}
                  </div>
              
                </div>
                <div className="ppc-guide-closeIcon"> <img
                    src="/images/Cross-icon-upload.svg"
                    alt="close"
                    className="closeIcon"
                    onClick={() => setShowGuidanceToast(false)}
                  /></div>
              </div>
            )}
            <div className="heading-mypack">My packages</div>
            <MyPurchaseList
              list={myPurchaseList}
              loading={loading}
              activeCategoryParam={state ? `?category=${state}` : ""}
            />
          </div>
        </section>
      </div>
    );
  }
  return <Redirect to="/#show-login" />;
};
export default MyPurchase;

import React, { useState, useEffect, useRef } from "react";
import NewCarousel from "../components/library/NewCarousel";
import CustomLoader from '../components/library/CustomLoader';
import { addaGoldExcelUrl, pageName } from '../../constants/appConfig';
import { ADDA_GOLD_DATA } from '../../constants/appConstants';
import { isEmailValid } from '../data/utils/helpers';
import { createOptimizedImgUrl } from "../data/utils/helpers"
let carouselContainer = React.createRef();
const AddaGoldLanding = () => {
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const [playVideo, setPlayVideo] = useState(false);
    const [testimonialPage, setTestimonialPage] = useState(0);
    const [error, setError] = useState(false);
    const playDemoVideo = () => {
        setPlayVideo(true);
    }
    const navigate = (direction) => {
        if (direction == 'left') {
            if (testimonialPage == 0) return
            else {
                setTestimonialPage(testimonialPage - 1);
            }
        }
        else {
            if ((testimonialPage + 1) * 3 >= TESTIMONIALS.length) return
            else {
                setTestimonialPage(testimonialPage + 1);
            }

        }
    }
    const emailChange = (e) => {
        setEmail(e.target.value);

    }

    const onEmailBlur = (e) => {
        let value = e.target.value
        if (!value) setError(false)
        else {
            setError(!isEmailValid(value));
        }

    }

    const saveEmail = () => {

        if (!isEmailValid(email)) {
            setError(true);
            return
        }
        setError(false)
        setLoading(true);
        fetch(`${addaGoldExcelUrl}?Email=${email}&callback=googleDocCallback`, {
            method: 'GET'
        }).then((res) => {
            setSaved(true);

        }).catch((err) => {
            console.log('err', err)

        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        window.googleDocCallback = function () { return true; };
    }, [])


    const EXAM_LIST = ADDA_GOLD_DATA.EXAM_LIST || []
    const CONTENT_DATA = ADDA_GOLD_DATA.CONTENT_DATA || []
    const TESTIMONIALS = ADDA_GOLD_DATA.TESTIMONIALS || []

    const VIDEO_INFO = ADDA_GOLD_DATA.VIDEO_INFO || {}

    let testimonialItem = []
    return (
        <div className='gold-section'>
            <div className='gold-container'>

                <div className='info'>

                    <div className='info-headnav'>
                        <img src='/images/gold_assets/Logo.png'></img>
                    </div>
                    <div className='info-content'>
                        <div className='info-data'>
                            <div className='info-crown'><img src='/images/gold_assets/ic_gold_crown.svg'></img></div>
                            <div className='info-intro'>Introducing</div>
                            <div className='info-data-image'><img src='/images/gold_assets/ic_Adda Gold_typoLogo.png'></img></div>

                            <div className='info-block'>
                                <div className='red-block'></div>
                            </div>
                            <div className='info-header'>All you need to succeed in your exams!</div>

                            {saved ?
                                <div className='info-action-success'>
                                    <div className='info-action-success-icon'>
                                        <img src='/images/gold_assets/ic_tick.svg'></img>
                                    </div>
                                    <div className='info-action-success-text'>
                                        Thank You for your interest. We will let you know when your Gold access is enabled.
                       </div>

                                </div> : <>
                                    <div className='info-action-text'>Share your email to get early access to Gold!</div>
                                    <div className='info-actions'>
                                        <div className={`icon-input-container black ${error ? 'error' : ''}`}>
                                            <div className='icon'>
                                                <img src='/images/gold_assets/ic_email.svg'></img>
                                            </div>
                                            <div className='icon-input black'>
                                                <input placeholder='Enter your email Id' value={email} onBlur={onEmailBlur} onChange={emailChange}></input>
                                            </div>
                                        </div>
                                        <div >
                                            {/* {loading ? <CustomLoader /> : <button className='gold-button red' disabled={loading} onClick={saveEmail}></button>} */}
                                            <button className='gold-button red' disabled={loading} onClick={saveEmail}>{loading ? <div className='loader-container'><div className='loader'></div></div> : 'I AM INTERESTED'}</button>
                                        </div>

                                    </div></>}
                            <div className='error-msg'>  {error ? 'Please enter a valid email Id' : ''}</div>
                            <div className='info-playstore-icon'>
                                <img src='/images/gold_assets/ic_gplay.svg'></img>
                            </div>

                        </div>
                        <div className='info-video'>
                            {/* <div style={{ height: '400px', width: '540px' }}> */}
                            {/* <VideoPlayer
                            videoOptions={{
                                controls: true,
                                autoplay: false,
                                preload: "auto",
                                loop: false,
                                manualCleanup: true,
                                techOrder: ["html5"],
                                html5: {
                                    nativeAudioTracks: false,
                                    nativeVideoTracks: false,
                                    hls: {
                                        overrideNative: true,
                                        withCredentials: true,
                                    },
                                },
                                sources: [
                                    {
                                        src: `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`,
                                        type: "video/mp4",
                                    },
                                ]
                            }}

                        />
                    </div> */}
                            {playVideo ? <video autoplay="autoplay" controls="controls" id="vid" muted

                                // poster="/images/gold_assets/thumbnail.jpg"
                            >
                                <source src={VIDEO_INFO.src} type={VIDEO_INFO.type} />
                            </video> : <div className='video-thumbnail'>

                                    <div className='video-content-wrapper'><img src='/images/gold_assets/ic_play.svg' onClick={playDemoVideo}></img></div>
                                    <div className='video-thumbnail-text'>Our CEO, Mr. Anil Nagar explains how {pageName[1]} Gold can raise the bar for your learning.</div>
                                </div>}
                        </div>
                    </div>
                </div>


                <div className='exam-info'>
                    <div className='exam-info-text'>
                        Every Concept, Every Topic, Every Exam
                </div>
                    <div className='exam-info-itemlist'>
                        {EXAM_LIST.map((exam) => {
                            return <div className='exam-info-item'>
                                {exam}
                            </div>
                        })}



                    </div>
                </div>
                <div className='content-info'>
                    <div className='content-info-text'>
                        What do you get with Gold?
            </div>
                    <div className='content-info-items'>

                        {CONTENT_DATA.map((content) => {
                            return (
                                // <div className='content-card-container'>
                                <div className='content-card'>

                                    <div className='content-card-image'>
                                        <img src={createOptimizedImgUrl(content.img, 197)}></img>
                                    </div>
                                    <div className='content-card-data'>
                                        <div className='content-card-header'>
                                            {content.header}
                                        </div>
                                        <div className='content-card-body'>
                                            {content.info}
                                        </div>

                                    </div>

                                </div>
                                // </div>
                            )
                        })}
                    </div>
                </div>

                <div className='get-gold'>
                    <div className='get-gold-info'>
                        <div className='get-gold-header'>Learn anywhere and anytime!</div>
                        <div className='get-gold-text'>Share your email to get an early-access to the Gold feature!</div>
                        {saved ? <div className='get-gold-success'>
                            <div className='get-gold-success-icon'>
                                <img src='/images/gold_assets/ic_tick_white.svg'></img>
                            </div>
                            <div className='get-gold-success-text'>
                                Thank You for your interest. We will let you know when your Gold access is enabled.
                            </div>

                        </div> :
                            <div className={`get-gold-actions`}>
                                <div className={`icon-input-container  ${error ? 'error' : ''}`}>
                                    <div className='icon'>
                                        <img src='/images/gold_assets/ic_email.svg'></img>
                                    </div>
                                    <div className='icon-input'>
                                        <input placeholder='Enter your email Id' value={email} onBlur={onEmailBlur} onChange={emailChange}></input>
                                    </div>
                                </div>
                                <div className='gold-button-container'>
                                    <button className='gold-button white' disabled={loading} onClick={saveEmail}>{loading ? <div className='loader-container'><div className='loader'></div></div> : 'I AM INTERESTED'}</button>
                                </div>

                            </div>
                        }
                        {<div className='get-gold-err'> {error ? 'Please Enter a valid Email Id' : ''}</div>}


                    </div>

                    <div className='get-gold-image'>
                        <img src='/images/gold_assets/phone.png'></img>
                    </div>




                </div>
                <div className='testimonials' >
                    <div className='testimonials-header'>Our Students Love Us!</div>
                    <div className='testimonials-list'
                        style={{
                            maxWidth: '1325px',
                            alignSelf: 'center'
                        }}>
                        <NewCarousel ref={carouselContainer} id={'adda-gold-carousel'} margin={10}>
                            {TESTIMONIALS.map((testimonial, index) => {
                                return (
                                    <div className='testimonial' key={index}>
                                        <div className='testimonial-image'>
                                            <img src={createOptimizedImgUrl(testimonial.img, 104)}></img>
                                        </div>
                                        <div className='testimonial-message'>
                                            <div>
                                                {testimonial.message}
                                            </div>
                                        </div>
                                        <div className='testimonial-info-container'>
                                        <div className='testimonial-name'>
                                            {testimonial.name}
                                        </div>
                                        <div className='testimonial-qualification'>
                                            {testimonial.qualification}
                                        </div>
                                        </div>

                                    </div>

                                )
                            })}

                        </NewCarousel>

                    </div>

                </div>
                {/* <div className='testimonials' style={{ display: "none" }} >
                    <div className='testimonials-header'>Our Students Love Us!</div>
                    <div className='testimonial-carousel'>
                        <div className='left'>
                            <img src='/images/gold_assets/left.png' onClick={() => { navigate('left') }}></img>
                        </div>

                        <TransitionGroup className="testimonials-list">
                            <CSSTransition
                                key={testimonialPage}
                                timeout={100}
                                classNames="t-item"
                            >
                                <div className='testimonials-list-i'

                                >
                            {TESTIMONIALS.slice(testimonialPage * 3, (testimonialPage + 1) * 3).map((testimonial, index) => {

                                return (

                                    <div className={`testimonial`} >
                                        <div className='testimonial-image'>
                                            <img src={testimonial.img}></img>
                                        </div>

                                        <div className='testimonial-message'>
                                            <div>
                                                {testimonial.message}
                                            </div>
                                        </div>
                                        <div className='testimonial-info-container'>
                                        <div className='testimonial-name'>
                                            {testimonial.name}
                                        </div>
                                        <div className='testimonial-qualification'>
                                            {testimonial.qualification}
                                        </div>
                                        </div>

                                    </div>
                                  

                                )
                            })}
                                </div>
                            </CSSTransition>

                        </TransitionGroup>
                        <div className='right'>
                            <img src='/images/gold_assets/right.png' onClick={() => { navigate('right') }}></img>

                        </div>
                    </div>
                    <div className='testimonial-index'>
                        {Array(Math.ceil(TESTIMONIALS.length / 3)).fill(0).map((item, index) => {
                            return <div className={index == testimonialPage ? 'index-item-active' : 'index-item'}>
                                <div></div>
                            </div>

                        })

                        }

                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default AddaGoldLanding;
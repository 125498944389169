import React, { useEffect } from "react";
import { useState } from "react";

const FreeContentTab = ({ tabItemList, onSelect, activeId, id }) => {

  return (
    <div className="packageBody">
    <div className="tab" id={`tabListcont-${id}`}>
       

      {tabItemList.map((item) => (
        <button
          className={activeId === item.tabName ? "active" : ""}
          onClick={() => onSelect(item.tabName)}
          style={{ display: item?.itemLength ? "visible" : "none" }}
        >
          {" "}
          {item.label} 
        </button>
      ))}
    </div>

    </div>
  );
};
export default FreeContentTab;

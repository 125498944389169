import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { productKeys } from "../../../constants/appConstants";
import { getProductDetailHoverInfo } from "../../data/ducks/header/actions";
import {
  createOptimizedImgUrl,
  getPdpUrl,
  isADDA,
  isEmpty,
  isExamDetailListingPage,
  msToTimeHMS,
  saveUserSearchAnalyticsData,
} from "../../data/utils/helpers";
import CustomLoader from "../library/CustomLoader";
import { pageName, searchUrlEndPoint } from "../../../constants/appConfig";
import { TIMELINE_URI } from "../../../constants/appUrls";
import AddaProductDetailCard from "./AddaProductDetailCard";

const QuickViewProductCard = (props) => {
  let couponDetails = null;
  let timeCUrrent = null;
  let timeExpiry = null;
  let timeRemaning = null;
  let finalTimeStr = null;
  let timeArray = null;
  let expiredCouponFlag = true;
  let highlights = null;
  let subCategories = null;
  let tag = null;
  let couponsOffers = false;
  let defaultCouponAbsoluteDiscount = null;
  let defaultCoupon = props?.coupons?.defaultCoupons;
  let price = props?.sellingprice;
  let discount = defaultCoupon?.discountValue;
  let notExpiredDefaultCouponAvailable = false;
  if (defaultCoupon?.discountType == "percentOff") {
    defaultCouponAbsoluteDiscount = (price * discount) / 100;
  } else {
    defaultCouponAbsoluteDiscount = discount;
  }
  let quickViewPopupSide = "left-side";
  if (props && props.item && props.item.tags) {
    if (props.item.tags.length > 0) {
      tag = props.item.tags[props.item.tags.length - 1].name;
    }
  }
  if (props && props.item && props.item.hlsJson) {
    highlights = props.item.hlsJson;
  }

  if (props?.productDetailInfo?.subCategories) {
    subCategories = props.productDetailInfo?.subCategories;
  }
  if (
    props?.coupons?.defaultCoupons &&
    Date.now() <= defaultCoupon.validityExpire - 5.5 * 60 * 60 * 1000
  ) {
    couponDetails = props?.coupons?.defaultCoupons;
    notExpiredDefaultCouponAvailable = true;
  } else if (
    props &&
    props.coupons &&
    props.coupons.coupons &&
    props.coupons.coupons.length > 0
  ) {
    couponDetails = props?.coupons?.coupons[0];
    couponsOffers = true;
  }
  if (couponDetails) {
    timeCUrrent = Math.round(new Date().getTime() / 1000);
    timeExpiry = Math.round(
      new Date(
        couponDetails?.expiry || couponDetails?.validityExpire
      ).getTime() / 1000
    );
    timeRemaning = timeExpiry - timeCUrrent;
    if (timeRemaning < 0) {
      expiredCouponFlag = false;
    }
    finalTimeStr = msToTimeHMS(timeRemaning);
    timeArray = finalTimeStr.split(":");
    for (let k = 0; k < timeArray.length; k++) {
      timeArray[k] = parseInt(timeArray[k].slice(0, -1));
      if (timeArray[k] < 0) {
        timeArray[k] = 2;
      }
    }
    if (timeArray[2] == 0) {
      timeArray[2] = 2;
    }
  }
  const [quickViewFlag, setQuickViewFlag] = useState("card");
  const [quickViewPopupPosition, setQuickViewPopupPosition] =
    useState("left-side");
  const [indexHovered, setIndexHovered] = useState(props.index);
  const [dateTooltip, setDateTooltip] = useState(false);
  const [quickViewHover, setQuickViewHover] = useState(false);
  const [couponCodeHover, setCouponCodeHover] = useState(false);
  const [productInfoLoader, setProductInfoLoader] = useState(false);
  const category = productKeys[props.categoryname] || "";
  const id = props.id || "";
  const title = props.cardtitle || props.title || "";
  const slugurl = props.slugURL || "";
  let pdpurl = getPdpUrl(category, id, title, slugurl);
  const isProductInfoExist = props?.isProductInfoExist;
  const history = useHistory();
  const dispatch = useDispatch();
  const cardOnClick = () => {
    sessionStorage.removeItem("QVPCscrollVal");
    sessionStorage.setItem(
      "QVPCscrollVal",
      JSON.stringify({ X: window.scrollX, Y: window.scrollY })
    );
    const searchValue = history.location.search
      .substring(1)
      .split("&")
      .filter((e) => e.indexOf("searchTerm") > -1)
      .join()
      .split("=")[1];
    // save user analytics call
    saveUserSearchAnalyticsData({
      url: searchValue
        ? `${searchUrlEndPoint}/v2/trending/searches`
        : `${TIMELINE_URI}/analytics/data`,
      data: props.item,
      searchTerm: searchValue ? searchValue : "",
    });

    // props.linkTo(pdpurl);
  };
  const pathname = history?.location?.pathname;
  if (pathname?.includes("exam-kit")) {
    const path = pathname?.split("/")[1];
    const examName = path.substring(0, path.length - 9);
    pdpurl = pdpurl + `?examCategory=${examName}`;
  } else if (isExamDetailListingPage(pathname)) {
    const examName = pathname?.split("/")[1];
    pdpurl = pdpurl + `?examCategory=${examName}`;
  }

  const getProductInfo = (packageId = 0) => {
    setProductInfoLoader(true);
    dispatch(getProductDetailHoverInfo(packageId)).then((res) =>
      setProductInfoLoader(false)
    );
  };

  const quickViewClick = (flag, index, id) => {
    let div = document.getElementById(`peoductCardId_${props.id}`);
    let screenWidth = screen.width;
    let rightOffset =
      screenWidth -
      div.getBoundingClientRect().width -
      div.getBoundingClientRect().left;
    if (rightOffset < screenWidth / 2.8) {
      quickViewPopupSide = "left-side";
      setQuickViewPopupPosition("rightsideview");
    } else {
      quickViewPopupSide = "right-side";
      setQuickViewPopupPosition("right-side");
    }
    if (flag) {
      if (!!id && !isProductInfoExist && quickViewFlag !== "pop") {
        getProductInfo(id);
      }
      setQuickViewFlag("pop");
    } else {
      setQuickViewFlag("card");
    }
    setIndexHovered(index);
  };
  const SubCategoryMapping = {
    ONLINE_LIVE_CLASSES: {
      label: "Live Classes",
      iconuri: "/images/ic_onlineliveclasses_new.svg",
      id: "liveClasses",
    },
    TEST_SERIES: {
      label: "Mock Tests",
      iconuri: "/images/ic_test_series_new.svg",
      id: "testPkgs",
    },
    BOOKS: {
      label: "Books",
      iconuri: "/images/ic_books_new.svg",
      id: "ebooksPkgs",
    },
    VIDEOS: {
      label: "Video Lectures",
      iconuri: "/images/ic_videos_new.svg",
      id: "videoPkgs",
    },
    EBOOKS: {
      label: "EBooks",
      iconuri: "/images/ic_ebooks_new.svg",
      id: "ebooksPkgs",
    },
    MODELS_3D: {
      label: "3D Learning ",
      iconuri: "/images/ic_models3d_new.svg",
      id: "models3DPkgs",
    },
  };
  const quickViewHoverFun = (flag) => {
    setQuickViewHover(flag);
  };
  const CouponsDetailsCode = (props) => {
    const { couponDetails } = props;
    let couponCode = `${couponDetails.code}`;
    let couponCodeFinal, couponCodeSliced;
    let couponCodeFinal7, couponCodeSliced7;
    let charLimit = 5;
    let charLimit7 = 7;
    if (couponCode.length > 6) {
      couponCodeSliced = couponDetails.code.slice(0, charLimit) + "...";
    } else {
      couponCodeSliced = couponDetails.code;
    }
    couponCodeFinal = couponCodeSliced;
    if (couponCode.length > charLimit7) {
      couponCodeSliced7 = couponDetails.code.slice(0, charLimit7) + "...";
    } else {
      couponCodeSliced7 = couponDetails.code;
    }
    couponCodeFinal7 = couponCodeSliced7;
    const dataOriginal = `<p>${couponDetails.discountValue}${
      couponDetails.discountType == "percentOff" ? "%" : "Rs"
    } off | Use <span className="couponAtEnd">${couponCode}</span></p>`;
    const data = `<p>${couponDetails.discountValue}${
      couponDetails.discountType == "percentOff" ? "%" : "Rs"
    } off | Use <span className="couponAtEnd">${couponCodeFinal}</span></p>`;
    const data7 = `<p style = "color: #2CAB49">"${couponCodeFinal}" coupon applied</p>`;
    if (!couponsOffers) {
      return (
        <>
          <div
            className="productOffCode quickview "
            onMouseLeave={() => setCouponCodeHover(false)}
            onMouseOver={() => setCouponCodeHover(true)}
          >
            <p style={{ color: "#2CAB49" }}>
              "{couponCodeFinal}" coupon applied
            </p>
          </div>
          <div className="productOffCode productOffCodeMobile">
            <div dangerouslySetInnerHTML={{ __html: data7 }} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="productOffCode" data-testid="offers-available">
            <img src="/images/Vector_1.png" /> {Offers_available}
          </div>
        </>
      );
    }
  };
  const Offers_available = `Offers available`;
  if (!props.cardtitle) return null;
  if (isADDA) {
    return (
      <AddaProductDetailCard
        packageInfoData={props?.item}
        packageId={props?.id}
        cardTitle={props?.cardtitle}
        src={props?.src}
        contentCount={props?.item?.contentCount}
        mahaPack={props?.item?.mahaPack}
        categories={props?.item?.categories || ""}
        languages={props?.item?.languages}
        newTags={props?.item?.newTags}
        freeContentAvailable={props?.item?.freeContentAvailable}
        couponDetails={couponDetails}
        timeArray={timeArray}
        expiredCouponFlag={expiredCouponFlag}
        defaultCouponAbsoluteDiscount={defaultCouponAbsoluteDiscount}
        notExpiredDefaultCouponAvailable={notExpiredDefaultCouponAvailable}
        sellingPrice={props?.sellingprice}
        tag={tag}
        dualValidity={props?.item?.dualValidity}
        pathName={pdpurl}
        source_screen={props?.source_screen || "Product Listing Card"}
        published={props?.published}
        isWishList={props?.isWishList}
        confirmRemoveWishList={props?.confirmRemoveWishList}
        isChecked={props?.isChecked}
        isBuyNowShow={props?.isBuyNowShow}
        wishlistOperationAllowed={props?.wishlistOperationAllowed}
        isSelectableShow={props?.isSelectableShow}
        onClickCb={() => cardOnClick()}
        onCheckedCb={props.onCheckedCb}
      />
    );
  }
  return (
    <>
      <div
        data-testid="quick-view-product-card"
        className={`productList`}
        onMouseLeave={() => {
          quickViewClick(false, "out", 0);
          quickViewHoverFun(false);
        }}
      >
        <Link
          onMouseOver={() => quickViewHoverFun(true)}
          id={`peoductCardId_${props.id}`}
          className={`${
            indexHovered == props.index + id
              ? "productListHover"
              : "productCardMain"
          }`}
          to={{
            pathname: pdpurl,
            state: {
              source_screen: props?.source_screen || "Product Listing Card",
            },
          }}
          onClick={() => cardOnClick()}
        >
          <div className="productImage">
            {props?.item?.tags?.length > 0 ? (
              <div className="productBestValue">{tag}</div>
            ) : (
              props?.item?.dualValidity && (
                <div className="productBestValue">Double Validity</div>
              )
            )}
            <img
              src={createOptimizedImgUrl(
                props.src || "/images/produtImg.jpg",
                193,
                193
              )}
              height="173px"
              width="173px"
              alt={props.cardtitle || pageName[0]}
            />
          </div>
          <div className="product-content">
            <div className="freeProdList">
              {props &&
                props.item &&
                props.item.freeContentAvailable &&
                "Free demo available"}
            </div>

            <div className="cursor-pointer">
              <div className="productTitle" data-testid="card-title">
                {props.cardtitle.length < 60
                  ? props.cardtitle
                  : `${props.cardtitle.slice(0, 60)}...`}
              </div>
              {couponDetails &&
              timeArray &&
              expiredCouponFlag &&
              defaultCouponAbsoluteDiscount &&
              notExpiredDefaultCouponAvailable ? (
                <div className="productPrice" data-testid="selling-price">
                  ₹{" "}
                  {Math.round(
                    (props.sellingprice - defaultCouponAbsoluteDiscount) * 100
                  ) / 100}{" "}
                  <span style={{ textDecoration: "line-through" }}>
                    ₹{props.sellingprice}
                  </span>
                </div>
              ) : (
                <div data-testid="selling-price" className="productPrice">
                  ₹ {props.sellingprice}
                </div>
              )}

              {couponDetails && timeArray && expiredCouponFlag ? (
                <CouponsDetailsCode couponDetails={couponDetails} />
              ) : (
                ""
              )}
            </div>
            {highlights && quickViewHover && highlights.length > 0 && (
              <div className="quickView">
                <button
                  className="newQuickView"
                  onMouseOver={() => quickViewClick(true, props.index + id, id)}
                >
                  Quick View
                </button>
              </div>
            )}
          </div>
        </Link>

        {(quickViewFlag == "pop" || dateTooltip) && (
          <div>
            {quickViewFlag == "pop" && (
              <div
                className={`quickPopwrapper ${quickViewPopupPosition}`}
                onMouseOver={() => quickViewClick(true, props.index + id, 0)}
              >
                <div className="quickViewouter">
                  {productInfoLoader ? (
                    <CustomLoader />
                  ) : (
                    <>
                      {subCategories?.length ? (
                        <div className="content-included-wrapper">
                          <div className="quick-view-heading">
                            Content Included:
                          </div>

                          <div className="quickfeature-small">
                            {subCategories &&
                              subCategories.map((item, index) => {
                                return (
                                  <div
                                    className="small-feature-list"
                                    key={index}
                                  >
                                    <div className="small-feature-icon">
                                      <img
                                        src={
                                          SubCategoryMapping[item.category]
                                            .iconuri
                                        }
                                      />
                                    </div>
                                    <div className="small-feature-contentSection">
                                      {item.category ==
                                      "ONLINE_LIVE_CLASSES" ? (
                                        <div className="small-feature-count">
                                          {item.vduration
                                            ? parseInt(item.vduration)
                                            : 0}{" "}
                                          Hours
                                        </div>
                                      ) : (
                                        <div className="small-feature-count">
                                          {item.count}
                                        </div>
                                      )}
                                      <div className="small-feature-label">
                                        {item?.count > 1
                                          ? SubCategoryMapping[item.category]
                                              .label
                                          : SubCategoryMapping[
                                              item.category
                                            ].label.slice(0, -1)}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="course-highlight-wrapper">
                        <div className="quick-view-heading">
                          Course Highlights:
                        </div>
                        <div className="qucikDiscriptionlist">
                          <ul>
                            {highlights &&
                              highlights?.filter(item => item.hlsJson !== "")?.map((item, index) => {
                                return (
                                      <li key={index}>{item.hlsJson}</li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="QuickListbutton">
                    <Link onClick={() => cardOnClick()} to={pdpurl}>
                      KNOW MORE
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default QuickViewProductCard;

import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import '../data/ducks/productDetails/reducers';
const UserDetails = lazy(() => import('./UserDetails'));
const PaymentMethod = lazy(() => import('./PaymentMethod'));
const PaymentFailure = lazy(() => import('./PaymentFailure'));
import PaymentFailures from './PaymentFailure';
import PaymentSuccess from './PaymentSuccess';

// import ApplyCoupon from "./ApplyCoupon";
import {
  isEmailValid,
  isValidCharCheck,
  isPhone,
  isPinCode,
  isEmptyField
} from "../data/utils/helpers";
import { lockBodyScroll } from "./utils";
import { useHistory } from "react-router";
import UserDetailsCRM from "./UserDetailsCRM";
import ApplyCouponCRM from "./ApplyCouponCRM";
import PaymentSuccessCRM from "./PaymentSuccessCRM";

const BuyNowCheckoutModalCRM = ({
  maxPrice,
  price,
  priceDetails,
  cartJson,
  isShippable,
  userEmail,
  setUserEmail,
  setCheckoutData,
  checkoutData,
  Getpayload,
  getUtmFromStore,
  payablePrice,
  productData,
  updatePriceDetails,
  loggedUserDetails,
  setViewOfferModalOnly,
  viewOffers,
  viewOfferModalOnly,
  userInfo,
  cartData,
  isLoggedIn,
  setFromBuyNowBtn,
  razorpayPayload,
  onClose,
  orderId
}) => {
  var stepArray = [{ stepNo: 1, stepName: 'Apply Offer' }, { stepNo: 2, stepName: 'Fill details' }, { stepNo: 3, stepName: 'Payment' }]
  const [hashUrl, setHashUrl] = useState("");
  const doc = useRef(null);
  const [header, setHeader] = useState("What are you preparing for?");
  const [continueButtonClicked, setContinueButtonCLick] = useState(false);
  const [cartError, setCartError] = useState('')
  const [fromapp, setFromapp] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [userInformation, setUserInformation] = useState({});
  const [addressModalShow, setAddressModalShow] = useState(false);
  var buyNowModalUrl = ['#checkout/viewOffers', '#checkout/userDetails', '#checkout/payment']
  const history = useHistory();
  useEffect(() => {
    if (location.hash) {
      setHashUrl(location.hash);
    }
    window.addEventListener("hashchange", () => {
      setHashUrl(location.hash);
    }, false);
    setCheckoutData({ couponCode: priceDetails?.appliedCoupon });
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    head.appendChild(script);
  }, []);

  useEffect(() => {
    let hashUrl = location.hash;
    setActiveStep(2);
    if (hashUrl.indexOf('#cart/success') > -1) setActiveStep(3);
    if (hashUrl.indexOf('#cart/failure') > -1) setActiveStep(4)
  }, [hashUrl])

  useEffect(() => {
    setFromBuyNowBtn(true);
    lockBodyScroll(true)
    let fromapp = getParameterByName('from_app');
    if (fromapp) {
      setFromapp(true);
    }
    else {
      setFromapp(false);
    }
    return () => { lockBodyScroll(false) }
  }, [])
  const handleBackFromBrowserTab = () => {
    lockBodyScroll(false)
    viewOffers(false)
    setViewOfferModalOnly(false)
  }
  function getParameterByName(name, url) {
    if (!url) url = typeof window != undefined && window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  let cartActionClass;
  let modalHeight = '630px';
  let ModalBodyComponent;
  let ModalHeader;

  if ((hashUrl.indexOf('#cart/success') > -1) || (priceDetails?.orderStatus === "PAID") || (priceDetails?.payablePrice === 0)) {
    ModalHeader = "Order Successful";
    ModalBodyComponent = <PaymentSuccessCRM
      orderId={orderId}
      email={priceDetails.email}
      Getpayload={Getpayload}
      fromapp={fromapp}
      userInfo={priceDetails.address}
      isLoggedIn={isLoggedIn}
      viewOffers={viewOffers}
      setViewOfferModalOnly={setViewOfferModalOnly}
    />;
    cartActionClass = 'cartaction-success'
  } else if ((hashUrl.indexOf('#cart/failure') > -1) || (!priceDetails) || (priceDetails?.orderStatus == "REFUNDED") || (priceDetails?.orderStatus == "ABANDONED") ) {
    ModalHeader = <span className='error'>Payment Failed</span>;
    ModalBodyComponent = <Suspense fallback={<div></div>}>  <PaymentFailures
      fromapp={fromapp}
      Getpayload={Getpayload}
      cartData={cartData}
      checkoutData={checkoutData}
    > </PaymentFailures></Suspense>;
    cartActionClass = 'cartaction-failure'
    modalHeight = 'auto';
  } else {
    ModalHeader = "Checkout";
    ModalBodyComponent = <Suspense fallback={<div></div>}><UserDetailsCRM
      productData={productData}
      priceDetails={priceDetails}
      updatePriceDetails={updatePriceDetails}
      setUserInformation={setUserInformation}
      maxPrice={maxPrice}
      price={price}
      checkoutData={checkoutData}
      payablePrice={payablePrice}
      cartJson={cartJson}
      loggedUserDetails={loggedUserDetails}
      isShippable={isShippable}
      updateCheckoutData={setCheckoutData}
      userEmail={userEmail}
      setUserEmail={setUserEmail}
      Getpayload={Getpayload}
      continueButtonClicked={continueButtonClicked}
      setContinueButtonCLick={setContinueButtonCLick}
      addressModalShow={addressModalShow}
      setAddressModalShow={setAddressModalShow}
    ></UserDetailsCRM></Suspense>;
  }
  

  const handleClickStep = (stepNo) => {
    if (hashUrl.indexOf('#cart/success') > -1) return
    if (stepNo == 1) {
      window.location.hash = "#checkout/viewOffers";
    }
    else if (stepNo == 2) {
      window.location.hash = "#checkout/userDetails";
    }
    else {
      setContinueButtonCLick(true)
      let invalidState = {};
      if (isShippable) {
        invalidState = {
          phone: !isPhone(userInformation.phone),
          name: isEmptyField(userInformation.name) || isValidCharCheck(userInformation.name),
          email: !isEmailValid(userInformation.email),
          pincode: !isPinCode(userInformation.pincode),
          firstLine:
            isEmptyField(userInformation.firstLine) ||
            isValidCharCheck(userInformation.firstLine),
          secondLine: isValidCharCheck(userInformation.secondLine),
          landmark: isValidCharCheck(userInformation.landmark),
          city: isValidCharCheck(userInformation.city),
          state: isValidCharCheck(userInformation.state),
        };
      } else {
        invalidState = {
          phone: !isPhone(userInformation.phone),
          name: isEmptyField(userInformation.name) || isValidCharCheck(userInformation.name),
          email: !isEmailValid(userInformation.email),
        };
      }
      if (Object.values(invalidState).find((item) => item)) {
        window.location.hash = "#checkout/userDetails";
        return;
      }

      window.location.hash = "#checkout/payment";
    }
  }

  const backBtnClick = () => {
    if (hashUrl == '#checkout/viewOffers') {
      viewOffers(false);
      setViewOfferModalOnly(false)
      lockBodyScroll(false)
      window.location.hash = "";
    }
    else if (hashUrl == '#checkout/userDetails') {
      window.location.hash = "#checkout/viewOffers";
    }
    else if (hashUrl == '#checkout/payment') {
      window.location.hash = "#checkout/userDetails";
    }
  }
  /** function that will called when hash url is changes.
   *  called only when hash url is empty.
   *  handling case when user press back button from browser tab.
   */

  const handleClose = () => {
    onClose();
  }

  return (
    <div className={`buynow-container ${addressModalShow ? 'buynow-container-address' : ''}`}>
      <div className="buynow-wrapper">
        <div className="buynow-header">
          <div className="buynow-header-heading" id={"available-offer-checkoutId"}>{viewOfferModalOnly ? 'Available Offers' : (hashUrl.indexOf('#cart/success') > -1) ? 'Confirmation' : 'Checkout'}</div>
          <div className="buynow-close-icon" onClick={handleClose}><a ><img src="../../images/checkout-close.svg" alt="" /></a></div>
        </div>
        {ModalBodyComponent}
      </div>
    </div>
  );
};
export default BuyNowCheckoutModalCRM;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myPurchase } from "../../../../constants/appConfig";
import { usePaidSearchConsumptionEvent } from "../../../components/utils";
import { fetchPurChasedPopularCourses } from "../../../data/ducks/header/actions";
import { saveUserSearchAnalyticsData } from "../../../data/utils/helpers";
import ProductCardHoc from "./ProductCardHoc";
import ProductInfoCard from "./ProductInfoCard";
import SearchResultsContainer from "./SearchResultsContainer";

const PPCPopularCourse = () => {
  const [isContentLoading, setIsContentLoading] = useState(false);
  const { purchasedPopularCourses } = useSelector((state) => state.header);
  const dispatch = useDispatch();

  const [redirectEvent] = usePaidSearchConsumptionEvent();

  useEffect(() => {
    if (!purchasedPopularCourses?.packages?.length) {
      setIsContentLoading(true);
      dispatch(fetchPurChasedPopularCourses())
        .then((res) => setIsContentLoading(false))
        .catch((error) => {
          setIsContentLoading(false);
        });
    }
  }, []);

  return (
    <SearchResultsContainer
      loading={isContentLoading}
      heading="POPULAR COURSES"
    >
      {purchasedPopularCourses?.packages?.length ? (
        purchasedPopularCourses?.packages?.map((item, idx) => {
          return (
            <ProductCardHoc
              key={`package_${item?.id}`}
              className="paid_product_wrapper"
            >
              <ProductInfoCard
                packageInfo={item}
                onClick={() => {
                  saveUserSearchAnalyticsData({
                    url: myPurchase.userAnalyticsPaidContent,
                    data: item,
                  });
                  redirectEvent(item);
                }}
              />
            </ProductCardHoc>
          );
        })
      ) : (
        <div className="noresult-found">
          <div className="no-result-icon">
            <img src="/images/no-result-icon.svg" />
          </div>
          <div className="no-rusult-heading">No data Found!</div>
          <div className="no-rusult-subheading">
            Please start searching,they will show here!.
          </div>
        </div>
      )}
    </SearchResultsContainer>
  );
};
export default PPCPopularCourse;

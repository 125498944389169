import React, { useEffect, useState } from "react";
import CustomModal from "../../../../library/CustomModal";
import PropTypes from "prop-types";
import { STOREFRONT_URI, STORE_CDN } from "../../../../../../constants/appUrls";
import { useParams } from "react-router";
import fetch from "../../../../../data/utils/fetch";
import { pageName } from "../../../../../../constants/appConfig";
import { hasValue } from "../../../../../data/utils/helpers";
const AddRatingPopup = ({
  isVisible = false,
  openFeedback,
  closeCallback,
  feedbackUpdated,
  setFeedbackUpdated,
  rating,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [responseText, setResponseText] = useState();
  const { mappingId, packageId, title } = useParams();
  const [feedBacksConstants, setFeedBacksConstants] = useState([]);
  const [feedBacksConstantsError, setFeedBacksConstantsError] = useState(false);

  const selectedResponse = (value) => {
    let updatedResponse = [...selectedIds];
    let indexOfValue = updatedResponse.indexOf(value);
    if (indexOfValue == -1) {
      updatedResponse.push(value);
    } else {
      updatedResponse.splice(indexOfValue, 1);
    }
    setSelectedIds(updatedResponse);
  };
  const updateFeedback = () => {
    let payload = {
      packageId: packageId,
      mappingId: mappingId,
      responseText: responseText,
      starRating: rating + 1,
      selectedStrings: selectedIds,
    };
    fetch(`${STOREFRONT_URI}/api/v2/feedback`, "POST", payload).then(
      (res) => {
        if (res.success) {
          setFeedbackUpdated(true);
        }
      },
      (err) => {
        setFeedbackUpdated(false);
      }
    );
  };
  const imagesForRating = {
    1: "one",
    2: "one",
    3: "three",
    4: "four",
    5: "five",
  };
  const ratingLabels = {
    1: "Very Bad",
    2: "Bad",
    3: "Average",
    4: "Great",
    5: "Excellent",
  };
  const ratingSubtexts = {
    1: "What did you not like?",
    3: "How can it be improved?",
    4: "Good to know that you liked it.",
    5: "Thanks for loving it!",
  };

  const handleCloseCallback = () => {
    setResponseText("");
    closeCallback();
  }

  useEffect(() => {
    setSelectedIds([]);
  },[rating]);

  useEffect(() => {
    window.fetch(`${STORE_CDN}/feedback_data.json`)
    .then(res => res.json())
    .then((res) => {
      setFeedBacksConstants(res);
    })
    .catch((err) => {
      setFeedBacksConstants([]);
      setFeedBacksConstantsError(true);
    })
  },[]);
  
  return (
    <CustomModal
      visible={isVisible}
      onCancel={handleCloseCallback}
      modalClassName={"feedback-modal"}
      closeCallback={handleCloseCallback}
    >
      {feedbackUpdated ? (
        <div className="feedback-content">
          <div className="feedback-image">
            <img src="../../../images/rate-this.svg" height="120px"/>
          </div>
          <div className="feedback-updated-header">Thank You!</div>
          <div className="feedback-updated-text">
            We have received your feedback. It will help us improve your
            experience.
          </div>
        </div>
      ) : (
        feedBacksConstantsError ? 
        <div className="feedback-content">
          <div className="feedback-image">
            <img src="../../../images/rate-this.svg" height="120px"/>
          </div>
          <div className="bottomStarsContent">
              <div className="resultRateThisSubHeading">Something went wrong! Please check your internet connection.</div>
          </div>
        </div>
         : 
        <div className="feedback-content">
          <div className="feedback-image">
            <img src="../../../images/rate-this.svg" height="120px"/>
          </div>
          <div className="feedback-star-rating">
            {Array(5)
              .fill(1)
              .map((val, index) => {
                return (
                  <span
                    onClick={() => {
                      openFeedback(index);
                    }}
                  >
                    {
                      !(hasValue(rating) && rating >= 0) || index > rating
                          ? <div className="emptyStar ht2 wd2" />
                          : <div className="filledStar ht2 wd2" />
                    }
                  </span>
                );
              })}
          </div>
          {!(hasValue(rating) && rating >= 0) && <>
            <div className= "bad-excellent-feedback">
              <div className="bad-text-rat">Very Bad</div>
              <div className="excellent-text">Excellent</div>
            </div>
            <div className="bottomStarsContent">
              <div className="resultRateThisHeading">Rate this test</div>
              <div className="resultRateThisSubHeading">We would love to know how was your experience with this test?</div>
            <div  className="resultPopCheckBtns"></div>
            </div>
          </>}
          {(hasValue(rating) && rating >= 0) ? (
            <div className="feedback-category">
              <div>{ratingLabels[rating+1]}</div>
              <div>{ rating <= 1 ? ratingSubtexts[1] : ratingSubtexts[rating+1]}</div>
              {
              <div className="feedback-category-list">
                {feedBacksConstants[rating+1]?.map((val, index) => {
                  return (
                    <button
                      className={
                        selectedIds?.includes(val)
                          ? "feeback-category-active"
                          : ""
                      }
                      onClick={() => {
                        selectedResponse(val);
                      }}
                    >
                      {val}
                    </button>
                  );
                })}
              </div>
            }
            </div>
          ) : ""}

          {rating == undefined ? (
            <div className="feedback-action rate-later">
              <button onClick={handleCloseCallback}>Rate Later</button>
            </div>
          ) : (
            <>
              <div className="resultPopInput">
                <input 
                type="text" 
                placeholder="How can we improve ?" 
                value={responseText}
                onChange={(e) => {
                  setResponseText(e.target.value);
                }}
                />
                </div>
              <div className="resultPopSumit">
                <button onClick={updateFeedback}>SUBMIT</button>
              </div>
              <span className="resultPopDoLater" onClick={handleCloseCallback}>I’ll do it later</span>
            </>
          )}
        </div>
      )}
    </CustomModal>
  );
};
AddRatingPopup.propTypes = {
  isVisible: PropTypes.bool,
};
export default AddRatingPopup;

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { courseItems, courseTagUrl, pageName } from "../../constants/appConfig";
import { productKeys, quickLinkMapping } from "../../constants/appConstants";
import FilterTags from "../components/filter/FilterTags";
import MainListing from "../components/filter/listings/MainListing";
import NewMobileFilter from "../components/filter/NewMobileFilter";
import SearchBlock from "../components/filter/searchfilters";
import BreadcrumbSchema from "../components/library/BreadcrumbSchema";
import CustomHelmet from "../components/library/CustomHelmet";
import CustomLoader from "../components/library/CustomLoader";
import NoResultsFound from "../components/library/NoResultsFound";
import NotFoundStatic from "../components/NotFoundStatic";
import MobileSeosection, { SeoSectionMobile } from "../components/library/SeoBlock/SeoBlock"
import { Cookies } from "react-cookie";
import {
  clearCourseCategoryData,
  getDataForCourseCategoryListing,
  getPaginatedDataForCourseDetail
} from "../data/ducks/courseCategoryListing/actions";
import "../data/ducks/courseCategoryListing/reducers";
import {
  capitalizedString,
  removeHyphensAndInsertSpaces,
  capitalizeWords,
  createStorefrontPdpUrl,
  getMoengageMetadata,
  isEmpty,
  isSet,
  sendDataToCretio,
  makeUrl,
  getDeviceType,
  removeElementsFromLastInArray,
  getPdpUrl,
  getUrlPathObject,
  parseCourseName,
  removeSpacesAndInsertHyphens,
  getSubstringBeforeChar, scrollToTop,
  trackMoengageEvent,
  isADDA,
  replaceHyphenWithUnderscore,
  getMoengagePayloadFromFacets,
  fireMoengageEvent,
  getTagIdParams,

} from "../data/utils/helpers";
import CustomSkeleton from "../components/library/CustomSkeleton";
import BreadCrumb from "../components/library/BreadCrumb";
import CustomModal from "../components/library/CustomModal";
import { default as Link } from '../containers/ConnectedLink';
import Faq from "./SEO/faq";
import { checkProperties } from "../components/utils";
import fetch from "../data/utils/fetch";
import DfpAdPdp from "../components/library/DfpAdPdp";
import CarouselSchema from "../components/library/CarouselSchema";

let filterObj = {};
const cookie = new Cookies();
let count = 0;
const sponsoredCategory = 1
class CourseCategoryListing extends PureComponent {
  constructor(props) {
    super(props);
    const tags = this.createTagsFromUrl();
    this.state = {
      loader: true,
      showFilterModal: false,
      filterParamsObj: {},
      dataLoader: false,
      tagArr: tags.tagArr,
      facetObj: tags.facetObj,
      queryParam: props.location.search.substring(1) || "",
      courseTag: ""
      // storeUrl:''
    };
    this.alreadyCall = false;
  }
  examCategoryArray = isADDA ? [
    { name: "Banking" }, { name: "SSC" }, { name: 'Railways' },
    { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }
  ] : [{name: "Class12-JEE"}, {name: "Dropper-JEE"},{name: "Class12-NEET"}, {name: "Dropper-NEET"}];

  // relatedCategory = [{ name: "Banking" }, { name: "SSC" }, { name: 'Railways' }, { name: "Teaching" }, { name: "Defence" }, { name: "Engineering" }]

  static propTypes = {
    getDataForCourseCategoryListing: PropTypes.func,
    clearCourseCategoryData: PropTypes.func,
    courseCategoryListingData: PropTypes.object,
    facets: PropTypes.object,
    utmParams: PropTypes.object
  };

  componentDidMount() {

    const { location } = this.props;
    const { tagArr } = this.state;
    const { pathname } = location;
    const courseCat = pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-')
    const examName = courseCat.join('-')
    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get('cp_user_name');
    let email = cookie.get('cp_user_email');
    const token = cookie.get('cp_token');



    typeof window !== "undefined" && window.scrollTo(0, 0);
    if (!tagArr.length) {
      this.setState({
        loader: true
      });
    }
    if (
      this.verifyCourse(courseCategory) &&
      isEmpty(this.props.courseCategoryListingData)
    ) {
      this.getAllData(courseCategory);
    }
    typeof window !== "undefined" && window.scrollTo(0, 0);

    //EVENT LISTING PAGE VIEW

    this.getCourseTag(examName)

  }


  getCourseTag = (examName) => {

    fetch(`${courseTagUrl}/${examName}?examType=1`, 'GET').then(res => {
      let tagValue = res.data;
      this.setState({ courseTag: tagValue })

    })
  }


  componentWillUnmount() {
    if (
      this.props.courseCategoryListingData &&
      !isEmpty(this.props.courseCategoryListingData.data)
    ) {
      this.props.clearCourseCategoryData();
      this.clearBeforeUnload();
    }
    count = 0;
    const googletag = window.googletag;
    if (googletag) googletag.destroySlots();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.courseType != this.props.match.params.courseType && this.alreadyCall == false) {
      this.getNewParamsForAllCategory('allExamtype', nextProps.match.params.courseType);
      this.getCourseTag(nextProps.match.params.courseType)
    } else {
      this.alreadyCall = false;
    }
  }

  createTagsFromUrl = () => {
    const qParams = this.props.location.search.substring(1);
    let tempTagArr = [];
    let newFacetObj = {};
    if (qParams !== undefined && qParams.length) {
      let splitArr = qParams.split("&");
      splitArr = splitArr.filter(e => !(e.indexOf("utm") > -1 || e.indexOf("gclid") > -1));

      splitArr.map(item => {
        let newArr = item.split("=");
        let finalArr = (newArr[1] || "").split(",");
        finalArr.map(it => {
          if (!tempTagArr.includes(decodeURI(it))) {
            tempTagArr.push(decodeURI(it));
          }
        });
        newFacetObj[newArr[0]] = finalArr;
      });
    }
    return {
      tagArr: tempTagArr.filter(item => item),
      facetObj: newFacetObj
    };
  };

  getAllData = courseCategory => {
    const { location } = this.props;
    this.createTagsFromUrl();

    if (
      location.search &&
      location.search.length > 1 &&
      isEmpty(this.props.courseCategoryListingData)
    ) {
      // const courseCategory = location.pathname.substring(1).split("-")[0];
      const courseCat = location.pathname.substring(1).split('-');
      courseCat.splice(-2);
      const courseCategory = courseCat.join('-')
      let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
      this.props.getDataForCourseCategoryListing(
        `${courseCategory}${tagParams}&${this.state.queryParam}`,sponsoredCategory
      );
      return;
    }
    if (!this?.props?.courseCategoryListingData?.data) {
      let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
      this.props.getDataForCourseCategoryListing(`${replaceHyphenWithUnderscore(courseCategory).toUpperCase()}${tagParams}`,sponsoredCategory).then((response) => {
        this.setState({
          loader: false,
          dataLoader: false
        });
      });
    }
    else {
      this.setState({
        loader: false,
        dataLoader: false
      });
    }
  };

  handleFilterButton = () => {
    this.setState({
      showFilterModal: true
    });
    fireMoengageEvent("filter_button_clicked", {}, "filter");
  };

  setExamAsCurrentCategoryAndOpenFilter = () => {
    this.setState({
      showFilterModal: true,
      currentActiveFilter: "examTypes"
    });
  };

  hideFilterOnMobile = () => {
    this.setState({
      showFilterModal: false,
      currentActiveFilter: ""
    });
  };

  fireCretio = id => {
    sendDataToCretio({ event: "viewList", item: [id] });
  };

  handleDetailCardClick = obj => {
    const category = productKeys[obj.categoryname] || "";
    const id = obj.id || "";
    const title = obj.cardtitle || obj.title || "";
    const slugurl = obj.slugURL || "";
    const getUtmFromStore = this.props.utmParams;
    this.fireCretio(id);
    // let categoryName = obj.categoriesincluded?obj.categoriesincluded[0]:''
    const storeUrl = getPdpUrl(category, id, title, slugurl);
    // this.setState({storeUrl:storeUrl});
    if (typeof (Moengage) !== 'undefined') Moengage.track_event(
      "product_click",
      getMoengageMetadata({
        source: "web",
        id: id,
        name: title,
        category: category,
        productType: category,
        list: category,
        brand: pageName[1],
        variant: "-",
        sellingPrice: obj.sellingprice,
        quantity: "1",
        packageUrl: storeUrl,
        packageActualPrice: obj.maximumretailprice,
        packageDiscountedPrice: obj.sellingprice,
        getUtmFromStore: getUtmFromStore,
        source_screen: ' CourseCategory listing',
      })
    );
    //createStoreFrontPdpUrl
    // window.open(storeUrl);
    // this.props.history.push(getUrlPathObject(storeUrl));
  };

  getNewParamsForAllCategory = (facetKey, value) => {

    this.alreadyCall = true;
    const { facetObj, tagArr } = this.state;
    this.setState({
      dataLoader: true
    });
    if (facetKey === "allExamtype") {
      const selection = value.toLowerCase();
      this.setState({
        tagArr: [],
        facetObj: {}
      });
      this.props.history.push(getUrlPathObject(`/${selection}-study-materials`));
      let tagParams = getTagIdParams( selection, this.props.globalExamList );
      this.props.getDataForCourseCategoryListing(`${replaceHyphenWithUnderscore(selection).toUpperCase()}${tagParams}`,sponsoredCategory).then(() => {
        this.setState({
          dataLoader: false
        });
      });
    } else {
      let newFacetObj = Object.assign({}, facetObj);
      let newTagArr = tagArr;
      // const courseName = this.props.match.params.courseType;
      // const productName = this.props.match.params.productType;
      const { location } = this.props;
      const { pathname } = location;

      const courseCat = pathname.substring(1).split('-');
      courseCat.splice(-2);
      const courseCategory = courseCat.join('-')

      if (!isSet(newFacetObj[facetKey])) {
        newFacetObj[facetKey] = [];
      }
      if (newFacetObj[facetKey].includes(value)) {
        newFacetObj[facetKey] = newFacetObj[facetKey].filter(
          e => e.toLowerCase() !== value.toLowerCase()
        );
        newTagArr = newTagArr.filter(
          e => e.toLowerCase() !== value.toLowerCase()
        );
      } else {
        newFacetObj[facetKey].push(value);
        newTagArr.push(value);
      }
      let strNew = "";
      let p;
      for (p in newFacetObj) {
        if (newFacetObj[p].length && Array.isArray(newFacetObj[p])) {
          strNew = strNew + `${p}=${newFacetObj[p].join()}&`;
        }
      }
      strNew = strNew.slice(0, -1);
      this.setState(
        {
          queryParam: strNew,
          tagArr: newTagArr,
          facetObj: newFacetObj
        },
        () => {
          let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
          this.props
            .getDataForCourseCategoryListing(`${replaceHyphenWithUnderscore(courseCategory).toUpperCase()}${tagParams}&${strNew}`,sponsoredCategory)
            .then(() => {
              this.setState({
                dataLoader: false
              });
            });
        }
      );
      this.props.history.push(getUrlPathObject(`/${courseCategory}-study-materials`, `?${strNew}`));
    }
    typeof window !== "undefined" && window.scrollTo(0, 0);
  };

  removeTag = item => {
    const { tagArr, facetObj } = this.state;
    const { location } = this.props;
    const { pathname } = location;
    const courseCat = pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-')
    const examName = courseCat.join('-')
    let newFacetObj = {};
    const tempTagArr = [...tagArr];

    this.setState({
      dataLoader: true
    });

    if (tempTagArr.indexOf(item) > -1) {
      tempTagArr.splice(tempTagArr.indexOf(item), 1);
      const facetKeys = Object.keys(facetObj);
      facetKeys.map(e => {
        newFacetObj[e] = facetObj[e].filter(f => {
          return f.toLowerCase() !== item.toLowerCase();
        });
      });
    }
    let arr3 = [];
    let finalString = "";

    let splitArr = location.search.substring(1).split("&");
    splitArr.map(str => {
      let newAr = str.split("=");
      const cat = newAr.shift();
      let finalAr = (newAr[0] || "").split(",");

      finalAr = finalAr.filter(val => {
        let decodedUri = decodeURI(val).toLowerCase();
        return decodedUri !== item.toLowerCase();
      });

      const string2 = finalAr.join(",");

      if (string2.length) {
        arr3.push(cat + "=" + string2);
      }
    });
    if (arr3.length > 1) {
      finalString = arr3.join("&");
    } else {
      if (isSet(arr3[0])) {
        finalString = arr3[0];
      }
    }
    this.setState(
      {
        queryParam: finalString,
        tagArr: tempTagArr,
        facetObj: newFacetObj
      },
      () => {
        let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
        this.props
          .getDataForCourseCategoryListing(`${courseCategory}${tagParams}&${finalString}`,sponsoredCategory)
          .then(() => {
            this.setState({
              dataLoader: false
            });
          });
      }
    );
    this.props.history.push(

      getUrlPathObject(`/${courseCategory}-study-materials`, `?${finalString}`)
    );
  };


  clearAllFilters = () => {
    const { history, location } = this.props;
    const { pathname } = location;
    const courseCat = pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-')
    history.push(getUrlPathObject(`/${courseCategory}-study-materials`));
    this.setState(
      {
        dataLoader: true,
        queryParam: "",
        tagArr: [],
        facetObj: {}
      },
      () => {
        let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
        this.props.getDataForCourseCategoryListing(`${courseCategory}${tagParams}`,sponsoredCategory).then(() => {
          this.setState({
            dataLoader: false
          });
        });
      }
    );
    fireMoengageEvent("filter_reset", {}, "filter");
  };

  clearBeforeUnload() {
    this.setState({
      tagArr: [],
      facetObj: {}
    });
  }

  verifyCourse = courseCategory => {
    return !!courseItems.includes(courseCategory);
  };

  handleRadio = course => {
    const reqCourse = course.toLowerCase();
    this.setState({
      dataLoader: true
    });
    this.props.history.push(getUrlPathObject(`/${reqCourse}-study-materials`));
    let tagParams = getTagIdParams( reqCourse, this.props.globalExamList );
    this.props.getDataForCourseCategoryListing(`${reqCourse}${tagParams}`,sponsoredCategory).then(() => {
      this.setState({
        dataLoader: false,
        showFilterModal: false,
        tagArr: []
      });
    });
    // this.clearAllFilters();
  };


  quickLinks = (category) => {
    let hashvalue = quickLinkMapping[category.toLowerCase()];
    // window.location.hash = hashvalue;
    let element = typeof window != "undefined" && window.document.getElementById(hashvalue);
    window.scrollTo({
      'behavior': 'smooth',
      'left': 0,
      'top': element.offsetTop - 80
    });
  }

  handleFilterApply = facetObj => {

    const { location } = this.props;
    const { pathname } = location;
    const courseCat = pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-')
    const examName = courseCat.join('-')
    this.setState({
      dataLoader: true
    });
    let urlString = "&";
    const facetObjectKeys = Object.keys(facetObj);
    const facetKeysArrLen = facetObjectKeys.length;
    for (let i = 0; i < facetKeysArrLen; i++) {
      let facetValue = facetObj[facetObjectKeys[i]].join(",");
      urlString = urlString + `${facetObjectKeys[i]}=${facetValue}&`;
    }
    const encodedQueryString = encodeURI(urlString.slice(0, -1));
    this.props.history.push(
      getUrlPathObject(`/${courseCategory}-study-materials`, `?${encodedQueryString.substring(1)}`)

    );
    fireMoengageEvent("filter_applied", getMoengagePayloadFromFacets(facetObj), "filter");
    let tagParams = getTagIdParams( courseCategory, this.props.globalExamList );
    this.props
      .getDataForCourseCategoryListing(`${courseCategory}${tagParams}${encodedQueryString}`,sponsoredCategory)
      .then(() => {
        this.setState({
          dataLoader: false,
          showFilterModal: false,
          tagArr: this.createTagsFromUrl().tagArr,
          currentActiveFilter: ""
        });
      });
    typeof window !== "undefined" && window.scrollTo(0, 0);
  };


  datalayerHanler = (searchValue, count) => {

    const { location } = this.props;
    const { tagArr } = this.state;
    const { pathname } = location;
    const courseCat = location.pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-')
    const examName = courseCat.join('-')

    let user_id_cookie = cookie.get("cp_user_id");
    let name = cookie.get('cp_user_name');
    let email = cookie.get('cp_user_email');
    const token = cookie.get('cp_token');

    const CategoryValue = searchValue.data && searchValue.data[0] && searchValue.data[0].suggestionCategory
    if (searchValue && typeof (window) != "undefined") {

      let excategory = searchValue.facets.courses;
      let exammCategory = searchValue.data && searchValue.data[0] && searchValue.data[0].packageEsEntity && searchValue.data[0].packageEsEntity[0] && searchValue.data[0].packageEsEntity[0].courses;
      if (token) {
        window.dataLayer.push({
          name: name || "guest",
          email: email || "",
          isMobile: getDeviceType() == 'm' ? true : false,
          loginType: 'email',
          isLoggedIn: true,
          examName: examName,
          pageName: 'Category',
          examCategoryName: exammCategory || "",
          category: CategoryValue || ""


        })
      }
      else if (!token && !user_id_cookie) {
        window.dataLayer.push({
          name: "guest",
          email: "",
          isMobile: getDeviceType() == 'm' ? true : false,
          loginType: '',
          isLoggedIn: false,
          examName: examName,
          pageName: 'Category',
          examCategoryName: exammCategory || "",
          category: CategoryValue || ""
        })
      }


      //event 

      if (count == 1) {
        let payload = {};
        payload.user_email = cookie.get("cp_user_email") || "";
        payload.user_name = cookie.get("cp_user_name") || "";
        payload.source_screen = "";
        payload.examCategory = exammCategory;
        payload.getUtmFromStore = this.props.utmParams;
        trackMoengageEvent("list_page_view", payload);
      }


    }


  }


  resetFilter = () => {
    filterObj = {};
    this.clearAllFilters();
    this.setState({
      showFilterModal: false,
      currentActiveFilter: ""
    });
  };

  render() {
    const { location, courseCategoryListingData, facets } = this.props;
    const { facetsList, showFilterModal, tagArr, facetObj } = this.state;
    const { pathname, search } = location;
    const categories = facets ? facets.categories : facetsList && facetsList.categories;
    let categoriesArrOld = categories && Object.keys(categories);
    let quickLink = {};
    for (var key in categories) {
      if (parseInt(categories[key]) > 0) {
        quickLink[key] = categories[key]
      }
    }

    let quickLinkKey = quickLink && Object.keys(quickLink);
    if(quickLinkKey[2] !== undefined && quickLinkKey[4] !== undefined){
      const temp = quickLinkKey[2];
      quickLinkKey[2] = quickLinkKey[4]
      quickLinkKey[4] = temp;

  }
    let quickLinkArr = !isEmpty(quickLinkKey) && quickLinkKey?.filter(e => e !== 'Magazines#MAGAZINES')?.map(e => e === "Models 3d#MODELS_3D" ? "3D Learning#MODELS_3D" : e);

    if(courseCategoryListingData?.data?.length){
      let result = quickLinkArr?.filter(o1 => courseCategoryListingData?.data?.some(o2 => o1.includes(o2?.category)));
      quickLinkArr = result
  }

    let categoriesArr = !isEmpty(categoriesArrOld) && categoriesArrOld.filter(e => e !== 'Magazines#MAGAZINES');

    if (courseCategoryListingData && courseCategoryListingData.facets) {
      count++;
      this.datalayerHanler(courseCategoryListingData, count)

    }
    const queryParams =
      search.indexOf("?") > -1 ? location.search.split("?")[1] : "";
    const courseCat = pathname.substring(1).split('-');
    courseCat.splice(-2);
    const courseCategory = courseCat.join('-');

    if (this.verifyCourse(courseCategory)) {
      // return the relevant component/page
      return (
        <div className={`inline-block ${isADDA ? 'productListingWrap':''}`}>
          {!this.props.isPaytmMini && <div className={"dfd-container"}>
            <DfpAdPdp category={courseCategory || ""} />
          </div>}



          <CustomHelmet
            title={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaTitle ? this.props.seoData[pathname].metaTitle :
              `${capitalizeWords(removeHyphensAndInsertSpaces(courseCategory))} Study material | ${capitalizeWords(removeHyphensAndInsertSpaces(courseCategory))} Online Coaching at ${pageName[1]}`}
            description={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].metaDescription ? this.props.seoData[pathname].metaDescription : `${pageName[1]} is the best place to get all resources related to ${capitalizedString(
              courseCategory
            )}. Best  Study material for ${new Date().getFullYear()} Exam is a sure shot way to succeed in ${capitalizedString(
              courseCategory
            )}. Get a best study material for ${capitalizedString(
              courseCategory
            )} to analyse performance improve weak areas.`}

            url={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].canonical || this.props.req_url}
            keywords={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].keywords}
            noFollow={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noFollow}
            noIndex={this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].noIndex}
          />
          <BreadcrumbSchema
            items={[
              {
                title: "Home",
                url: "/"
              },
              {
                title: capitalizedString(courseCategoryListingData.title || (courseCategory || "").replace("_", " "))
              }
            ]}
          />
          <CarouselSchema
            data = {this?.props?.courseCategoryListingData?.data}
            origin = {this?.props?.origin}
            schemaType = "Carousel"
          />
          <div className={"breadcrumb-container"}>
            <BreadCrumb
              routeData={[
                { name: "Home", target: "/" },
                {
                  name: `${capitalizedString(courseCategoryListingData.title || parseCourseName(courseCategory || ""))?.replace(/_/g, '-')} study material`,
                  target: ""
                }
              ]}
            />
          </div>
          <div className={"header-container"}>
            <h1 className={"listing-header"}>
              {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeading?.split(":").join(" : ")?.split(",").join(", ")  || (`${capitalizedString(courseCategoryListingData.title || (parseCourseName(courseCategory || "")).replace("_", " "))} Study Material`)}
            </h1>
            {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].pageHeadingDescription && <div className="headingdescription seoHeadingDescription">{this.props.seoData[pathname].pageHeadingDescription}</div>}
          </div>

          <div className={"listing-data-container"}>
            {isEmpty(facets) && false ? (
              <CustomLoader />
            ) : (
              <>
                <div className={"filter-parent-container col m3 fcwidth"}>
                    <SearchBlock
                      SuffixValue={"Online Coaching"}
                      facetsList={facetsList || facets}
                      showSearch={false}
                      courseTag={this.state.courseTag && this.state.courseTag.slice(0, 7)}
                      filterHeading={capitalizedString(courseCategory)}
                      // relatedCategory={examCategoryArray}
                      currentItem={courseCategory}
                      changeRoute={this.changeRoute}
                      getNewParamsforAllCategory={
                        this.getNewParamsForAllCategory
                      }
                      type={"courseCategory"}
                      selectedTags={tagArr}
                      examCat={courseCategory}
                      getUtmFromStore={this.props.utmParams}
                    />

                </div>
                <div className={"col m9 fccontwidth"}>
                  {isEmpty(courseCategoryListingData) ||
                    this.state.dataLoader ? (
                    [...Array(5)].map((e, i) => <CustomSkeleton />)
                  ) : (
                    <>
                      {!isADDA && quickLinkArr?.length >= 1 ? (
                        <div className="seo-tags-container">
                          <div className="seo-quick-link">Quick Links: </div>
                          {quickLinkArr.map((value, index) => {
                            return (
                              <div
                                className="seo-tags-value"
                                onClick={() =>
                                  this.quickLinks(
                                    getSubstringBeforeChar(value, "#")
                                  )
                                }
                              >
                                {" "}
                                {getSubstringBeforeChar(value, "#")}
                                {index != quickLinkArr.length - 1 ? (
                                  <span className="ma-le-20">|</span>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {(tagArr.length > 0) ? (
                        <div className={"tag-container"}>
                          <FilterTags
                            tagArr={tagArr}
                            removeTag={this.removeTag}
                          />
                          <div
                            className={"clear-all"}
                            onClick={this.clearAllFilters}
                          >
                            Clear All
                          </div>
                        </div>
                      ) : <></>}
                      {(!courseCategoryListingData.data.length ||
                        !isSet(
                          courseCategoryListingData.data[0].packageEsEntity
                        )) ? (
                        <NoResultsFound handleOnClick={this.clearAllFilters} 
                         allCategoryData={this.props.allTrendingData?.data?.hotSellingPackages}
                         handleDetailCardClick={this.handleDetailCardClick}
                        />
                      ) : (
                        <MainListing
                          params={this.props.match.params}
                          getFilterPagesData={courseCategoryListingData.data}
                          course={courseCategory}
                          handleDetailCardClick={this.handleDetailCardClick}
                          facetsList={facetsList || facets}
                          courseDetail={true}
                          title={courseCategoryListingData.title}
                        // storeUrl={this.state.storeUrl}
                        />
                      )}
                      {courseCategoryListingData?.data?.length ? <div
                        className={"only-mobile"}
                        onClick={this.handleFilterButton}
                      >
                        <div className={"filter-button"}>
                          <span>
                            <img src={"/images/filter-bottom-icon-new.svg"} alt={pageName[0]} title={pageName[0]} />
                            <span className={"filter-bottom-text"}>Filters</span>
                          </span>
                          <span className="filter-sort-btn-label">
                            <span className="filter-selected-category-wrapper">
                            {queryParams ? (
                              <>
                                <span className="filter-applied-dot"></span>
                                <span className="selected-filter-category">Filter Applied</span>
                              </>
                            ) : (
                              <span className="selected-filter-category">No Filter</span>
                            )}
                            </span>
                          </span>
                        </div>
                      </div> : ""}
                      {showFilterModal && (
                        <NewMobileFilter
                          visible={showFilterModal}
                          onCancel={this.hideFilterOnMobile}
                          onOk={this.onOk}
                          facetsList={facetsList || facets}
                          handleApply={this.handleFilterApply}
                          resetFilter={this.resetFilter}
                          type={1}
                          handleRadio={this.handleRadio}
                          selectedTags={tagArr}
                          facetObj={facetObj}
                          courseCategory={courseCategory}
                          currentActiveFilter={this.state.currentActiveFilter}
                        />
                      )}
                    </>
                  )}

                </div>
              </>
            )}
          </div>
          {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].bottomContent &&
                 <div className="bottomContentContainer">
                 <div className="bottomContent"
                   dangerouslySetInnerHTML={{
                     __html: this.props.seoData[pathname].bottomContent
                   }}>
   
                 </div>
               </div>}

          {this.props.seoData && this.props.seoData[pathname] && this.props.seoData[pathname].faqs && this.props.seoData[pathname].faqs.length >= 1 && checkProperties(this.props.seoData[pathname].faqs[0]) &&
            <div className="faqcontainersearch"> <Faq faqJson={this.props.seoData[pathname].faqs} />
            </div>}

          <div className="related-category-mobile">
            <div className="title-seo">Related Category</div>

            <SeoSectionMobile
              data={this.examCategoryArray}
              examCat={courseCategory}
              isRelatedCategory={true}
              isExamtypes={false}
              SuffixValue={"Online Coaching"}
            />
          </div>
          <div className="related-category-mobile">
            <div className="title-seo">Exam Types</div>

            <SeoSectionMobile data={this.state.courseTag.slice(0, 7)}
              examCat={courseCategory}
              isExamtypes={true}
              isRelatedCategory={false}
              SuffixValue={"Online Coaching"}
            />
          </div>

        </div>
      );
    } else {
      // return some error page, for the time being I'll show a message
      return (
        <div style={{ marginTop: "100px" }}>
          <NotFoundStatic />
        </div>
      );
    }
  }
}

CourseCategoryListing.fetching = ({ dispatch, match, location }) => {
  let queryParams = "";
  if (location) {
    const courseCat = location.substring(1).split('-');
    courseCat.splice(-2);
    queryParams = location.indexOf("?") > -1 ? location.split("?")[1] : "";
    let newQueryArray = queryParams.split('utm_source');
    if (newQueryArray[0]) {
      return [
        dispatch(
          getDataForCourseCategoryListing(`${match.params.courseType}&${newQueryArray[0]}`,sponsoredCategory)
        )
      ];
    }
    else {
      return [
        dispatch(
          getDataForCourseCategoryListing(`${match.params.courseType}`,sponsoredCategory)
        )
      ];
    }
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getDataForCourseCategoryListing:( data,sponsoredCategory) =>
      dispatch(getDataForCourseCategoryListing(data,sponsoredCategory)),
    clearCourseCategoryData: () => dispatch(clearCourseCategoryData()),
    getPaginatedDataForCourseDetail: obj =>
      dispatch(getPaginatedDataForCourseDetail(obj))
  };
}

function mapStateToProps(state, ownProps) {
  let { courseType } = ownProps.match.params;
  const courseCat = ownProps.location.pathname.substring(1).split('-');
  courseCat.splice(-2);
  let courseCategory = courseCat.join('-');
  let keys = Object.keys(state.courseCategoryListing);
  const courseCategoryListing = state.courseCategoryListing[courseCategory] || state.courseCategoryListing[keys[0]] || {};
  const origin = state?.header?.origin || "";
  let facets = courseCategoryListing.facets;
  let utmParams = state.referrer || {};
  let isLoggedIn = state.header.jwtToken;
  let userInfo = state.header.userInfo;
  let req_url = state.req_url;
  let seoData = state.seoData;
  let isPaytmMini = state.isPaytmMini;
  let  allTrendingData = state.header.allTrendingData;
  const globalExamList = state.header.globalExamList
  return {
    courseCategoryListingData: courseCategoryListing,
    facets,
    utmParams,
    userInfo,
    isLoggedIn,
    req_url, seoData,
    isPaytmMini,
    allTrendingData,
    origin,
    globalExamList
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCategoryListing);
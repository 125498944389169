import React from "react";
import { useWindowDimensions } from "../../../components/utils";

const RecentSearches = ({ recentSearches = [], onClickCb = () => null }) => {
  const windowSize = useWindowDimensions();
  const isWebView = windowSize?.width >= 768;

  if (!recentSearches?.length) {
    return <></>;
  }
  return (
    <div className="left-side-trending">
      <div className="full-width-title">Recent searches</div>
      {isWebView ? (
        <ul>
          {recentSearches?.map((item) => (
            <li key={item} onClick={() => onClickCb(item)}>
              <a style={{ cursor: "pointer" }}>{item}</a>
            </li>
          ))}
        </ul>
      ) : (
        <div className="mwebsearchbtns">
          {recentSearches?.map((item) => (
            <button key={item} onClick={() => onClickCb(item)}>
              {item}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecentSearches;

import { isADDA } from "../../../data/utils/helpers"

const onBoardingSupportEmail = isADDA ? "support@adda247.com" : "support@sankalpbharat.com";

const onBoardingStepStates = {
    signup : "signup",
    email_signin : "email-signin",
    mobile_otp_verify : "mobile-otp-verify",
    forgot_password_email_collect : "forgot-password-email-collect",
    create_password : "create-password",
    user_details_collection : "user-details-collection",
    mobile_otp_send : "mobile-otp-send",
    account_recovery_details_collect : "account-recovery-details-collect",
    email_otp_verify : "email-otp-verify",
    password_change : "password-change",
    no_details_found : "no-details-found",
    account_already_linked_error : "account-already-linked-error",
    change_success : "change-success",
    account_recovery_success : "account-recovery-success",
    modal_error : "modal-error",
    email_otp_send : "email-otp-send",
    mobile_number_change_input : "mobile-number-change-input",
}


export {
    onBoardingSupportEmail,
    onBoardingStepStates,
}
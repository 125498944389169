import React from "react";
import BrowseByProductType from "../../components/home/BrowseByProductType";
import { Link } from "react-router-dom";
import { moengageEvent } from "../utils";
const EmptyCart = ({title,header,subHeader,backGroundImageSrc,ProductTypeVisible,minHeight,top,exploreVisible}) => {
  return (
    <div>
      <div
        style={{
          position: "relative",
          top: top ? top: "90px",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          width: "100%",
          minHeight: minHeight?minHeight: "100vh",
          backgroundColor: "#ffffff",
          marginBottom:'40px',
          marginTop:'80px'
        }}
      >
        <div
          data-testid="empty-cart"
          className="empty-cart"
        >
            <div className="ec-title">  {title} </div>

            <div className="ec-image"><img src='/images/empty_cart.svg' alt='empty' title='empty' ></img> </div>
            <div className="ec-header">{header} </div>
      <div className="ec-sub-header">  {subHeader}</div>
           {  ProductTypeVisible && <div className="ec-slider"> <BrowseByProductType /></div>}
         { exploreVisible &&  <Link to ='/' className="explore-now-button"> 
           <span className="buy-now" onClick={moengageEvent}>EXPLORE NOW</span></Link>}
        </div>
      </div>
    </div>
  );
};

export default EmptyCart;

import { checkIsTestServer } from "../app/data/utils/helpers";
import {
  SEARCH_URL,
  STOREFRONT_URI,
  TIMELINE_URI,
  USER_AUTH_URI,
  IFRAME_URI,
  QUIZ_ATTEMPT_URL,
  STORE_URI,
  COUPON_URI,
  ORDER_API,
  STORE_CDN,
  CDN_PATH,
  VIDEO_URI,
  STORE_FRONT_API_URI,
  SPECIAL_TEST_PATH,
  PaytmMerchant_Id,
  TEST_SERIES_URL,
  PROD_STORE_CDN,
  LEADSQUARED_HOST_URL,
  LIVE_CLASS_URL,
  NEW_ADMIN_UI,
  PAGENAME,
  EBOOKS_URL,
  MISC_URL,
  THREED_MODEL_URL,
  THREED_MODEL_IFRAME_URL,
  LCS_SERVICE_URL,
} from "./appUrls";

const pageName = PAGENAME;
const isADDA = pageName[0] === "adda247";
const userPassword = {
  minLength: 5,
  maxLength: 20,
};
const leadSquared_Name = "da_63985_b860a0b0";
const leadSquared_Stage = "Test";
const leadSquared_xapikey = "4c1b089d9019492cb574531d583a5b18";
const shoogloTrackier_key = "650982d384d3b730aa63430e";

const testResultOrigin = {
  staging: `https://stagingapi.${pageName[0]}.com`,
  prod: `https://api.${pageName[0]}.com`
}
const testSeriesURLProd = isADDA ? "https://testseriesapi.adda247.com" : "https://api.sankalpbharat.com/testseries-ws";
const newsletterUrl = `${EBOOKS_URL}/api/v1`;
const addaGoldExcelUrl =
  "https://script.google.com/macros/s/AKfycbxXIsrWKKv32Har60JJ45MT_oQ9OwMoUhArlojrLIvDZSKuYLw/exec";
const otpResendLimit = 3;
const dtdcApiUrl = `${STOREFRONT_URI}//api/v1/dtdc/verify/pincode`;
const prodUrl = `https://www.${pageName[0]}.com`;
const courseTagUrl = `${STORE_URI}/api/v3/tabs/course`;
const tendingData = `${SEARCH_URL}/trending/data`;
const iFrameUrl = IFRAME_URI;
const threeDModeliFrameUrl = THREED_MODEL_IFRAME_URL;
const quizAttemptUrl = QUIZ_ATTEMPT_URL;
const imageOptimiserBaseUrl = `https://st.${pageName[0]}.com`;
const googleAnalyticsId = "UA-75257726-1";
const pageSizeForDetailListing = 20;
const pageSizeForPackageViewListing = 10;
const searchUrlEndPoint = `${SEARCH_URL}`;
const allExamListingUrl = `${SEARCH_URL}/packages/suggestion`;
const searchSuggestionUrl = `${SEARCH_URL}/packages/v8/suggestion`;
const productListingUrl = `${SEARCH_URL}/packages/v8/listing`;
const subscriptionUrl = `https://sales.${pageName[0]}.com/`;
const shareIconUrl = `https://static.${pageName[0]}.com/images/ic_share.svg`;
//const timeLineUrl = `${TIMELINE_URI}/list?language=ENGLISH&size=10`;
const timeLineUrl2 = `${TIMELINE_URI}/`;
const timeLineUrl_alt = isADDA
  ? "https://timeline.adda247.com"
  : "https://api.sankalpbharat.com/timeline-ws";
const userApi = USER_AUTH_URI;
const stagingSearchUrl = `${SEARCH_URL}/packages/search`;
const storeHostUrl = `${STOREFRONT_URI}`;
const stagingSearchForSuggestionUrl = `${SEARCH_URL}/packages/suggestion`;
const searchSuggestionUrlV6 = `${SEARCH_URL}/packages/v8/search`;
const allCousrsesUrl = `https://www.${pageName[0]}.com/jsonData/allExamMenuData.json`;
const usersReviewUrl = `https://storecdn.${pageName[0]}.com/dynamic/reviewSlider.json`;
const stagingMainUrl = `https://staging.${pageName[0]}.com`;
const drmUrl = `https://staginggold.adda247.com/app-content-ws/v1/drm/authxml?ispresistent=false`;
const freshDeskTicketCreate = `https://newaccount1640841829797.freshdesk.com/api/v2/tickets`;
const FreshDeskAPIKey = 'MUdncDZzSTRmQnc3UDJSMENSNlI6';
const ServiceSupportUrl =
  "https://adda247.kapturecrm.com/add-ticket-from-other-source.html/v.2.0";
const purchaseSupportUrl = "https://adda247.kapturecrm.com/add-update-enquiry";
const leadSquaredUrl = `${LEADSQUARED_HOST_URL}/executebylapptoken?name=${leadSquared_Name}&stage=${leadSquared_Stage}&xapikey=${leadSquared_xapikey}`;
const shooglooTrackierUrl = "tsm.s3g6.com";
const seoDataUrl = `${STOREFRONT_URI}/v1/cms`;
const pdpUrls = {
  productDetails: `${SEARCH_URL}/v10/packages/info`,
  packageOffering: `${SEARCH_URL}/api/v4/packages`,
  videoListing: `${STORE_FRONT_API_URI}/api/v1/streaming-video`,
  storeurl: { STORE_URI },
  // testSeries: `${STORE_FRONT_API_URI}/api/v1/test-series`,
  testSeries: `${TEST_SERIES_URL}/api/v5/test-series`,
  eBooks: `${STORE_FRONT_API_URI}/api/v1/ebooks`,
  models3d: `${THREED_MODEL_URL}/api/v1/models`,
  liveClasses: `${STORE_FRONT_API_URI}/api/v2/live-classes`,
  couponList: `${COUPON_URI}/coupons/android`,
  storeFrontURI: `${STORE_FRONT_API_URI}`,
  updateAddress: `${STOREFRONT_URI}/api/v1/addresses`,
  updateAddressGuest: `${STOREFRONT_URI}/api/v1/guest/addresses`,
  applyCart: `${STOREFRONT_URI}/api/v1/orders/cartCalc`,
  applyCartGuest: `${STOREFRONT_URI}/api/v1/guest/orders/cartCalc`,
  createOrder: `${STOREFRONT_URI}/api/v1/orders`,
  createOrderGuest: `${STOREFRONT_URI}/api/v1/guest/orders`,
  purchasedPackage: `${STOREFRONT_URI}/isPkg/purchased`,
  purchasedPackageV2: `${STOREFRONT_URI}/v2/isPkg/purchased`,
  paymentCallbackUrl: `https://staging.${pageName[0]}.com`,
  pincodeListing: `${STORE_CDN}/pincodes`,
  cart: `${STORE_URI}/cart`,
  demoVideoUrl: `${VIDEO_URI}/demo/file?`,
  orderDetails: `${STOREFRONT_URI}/api/v3/orders`,
  orderDetailsGuest: `${STOREFRONT_URI}/api/v1/guest/orders`,
  store: STORE_URI,
  userApi: userApi,
  trackerAPi: `${STORE_URI}/api/v1/orders/dtdctracking`,
  orderApi: `${STORE_URI}/api/v3/orders`,
  csrfToken: `${STORE_URI}/api/token`,
  mahapack: `${STOREFRONT_URI}/api/v3/packages/mahapack/`,
};

const enrolledCoursesUrl = {
  myVideos: `${STOREFRONT_URI}/api/v1/videos/streaming-video`,
  myMockTest: `${STOREFRONT_URI}/api/v1/test-series`,
  myLiveTest: `${STOREFRONT_URI}/api/v1/test-series?liveTest=true`,
  liveclassesV1: `${STOREFRONT_URI}/api/v1/live-classes`,
  liveclasses: `${STOREFRONT_URI}/api/v3/live-classes`,
  myFreeMockTest: `${STOREFRONT_URI}/api/v1/test-series?price=0`,
  videoClasses: `${STOREFRONT_URI}/api/v2/live-classes`,
  // videoClasses: `${STOREFRONT_URI}/api/v3/live-classes`,

};

const testEventsUrl = {
  liveAndUpcoming: `${TIMELINE_URI}/v3/fixedMock?status=1&language=ENGLISH`,
  pastEvents: `${TIMELINE_URI}/v3/fixedMock?status=2&language=ENGLISH`,
  activeEvents: `${TIMELINE_URI}/v3/fixedMock?isActive=1&language=ENGLISH`,
};

const paytmMiniUrls = {
  userInfo: `${USER_AUTH_URI}/v1/paytmMini/getUserInfo`,
};

const videoPackageUrl = {
  singleView: `${VIDEO_URI}/video-streaming/singleView`,
  packageDetails: `${isADDA ? PROD_STORE_CDN : STORE_CDN}/${isADDA ? 'digital-course' : `sankalp-${CDN_PATH}-digital-course`}/${CDN_PATH}`,
  videoDetails: `${STOREFRONT_URI}/api/v1/streaming-video`,
  videoStatus: `${USER_AUTH_URI}/api/v1/video/status`,
  videoSource: `${VIDEO_URI}/demo/file`,
  paidVideoSource: `${VIDEO_URI}/file`,
  liveclassVideoSource: `${VIDEO_URI}/file`,
  videoRating: `${LIVE_CLASS_URL}/api/v1/live-classes/get-videoRating`,
  videoRatingStatus: `${USER_AUTH_URI}/api/v1/video/status`,
  videoSaveFeedback: `${LIVE_CLASS_URL}/api/v1/live-classes/save-userFeedback`,
  videoSaveUserCommnets: `${LIVE_CLASS_URL}/api/v1/live-classes/save-userComments`,
  videoRatingFeedbackOptions: `${LIVE_CLASS_URL}/api/v1/live-classes/get-FeedbackOptions`

};
const liveClassHandout = {
  handout: `${NEW_ADMIN_UI}/api/v1/app/handout/scheduled/`

}
const myPurchase = {
  boughtpackages: `${STOREFRONT_URI}/api/v1/my/boughtpackages`,
  purchasedpackages: `${STOREFRONT_URI}/api/v2/ppc/package/purchased`,
  selectedPackageExams: `${STOREFRONT_URI}/api/v1/ppc/package/exams`,
  manageExams: `${STOREFRONT_URI}/api/v1/ppc/package/exams/manage`,
  saveExams: `${STOREFRONT_URI}/api/v1/ppc/package/exams/save`,
  packageData: `${STOREFRONT_URI}/api/v2/ppc/package/child`,
  // paid web search URL's
  packageSearch: `${STOREFRONT_URI}/packages/paid/v3/search`,
  facets: `${STOREFRONT_URI}/packages/paid/v3/fetch/facets`,
  popularCourses: `${STOREFRONT_URI}/packages/paid/v2/popularCourses`,
  allPackageCount: `${STOREFRONT_URI}/api/v1/ppc/package/getPackageFacetsCount`,
  subParents: `${STOREFRONT_URI}/api/v1/ppc/package/subParent`,
  userAnalyticsPaidContent: `${TIMELINE_URI}/user-analytics/paid-content`,
};
const packageView = {
  packageView: `${STOREFRONT_URI}/api/v3/my/purchase`,
};
const childPackageDetailUrl = {
  ebooks: `${STOREFRONT_URI}/api/v1/my/purchase/ebooks`,
  testseries: `${TEST_SERIES_URL}/api/v5/my/purchase/test-series`,
  videos: `${STOREFRONT_URI}/api/v1/my/purchase/videos`,
  olc: `${STOREFRONT_URI}/api/v1/my/purchase/OLC`,
  models3d: `${THREED_MODEL_URL}/api/v1/models`,
  packageInfo: `${SEARCH_URL}/packages/v2/info`,
};

const cartCheckoutUrls = {
  packageById: `${STOREFRONT_URI}/api/v1/packages/packagesbyIds`,
  userCart: `${STOREFRONT_URI}/user/cart`,
  cart: `${STOREFRONT_URI}/cart`,
};
const specialTestUrl = `${PROD_STORE_CDN}/spltest/${SPECIAL_TEST_PATH}.json`;
const signedurl = "/generate/signed/url";
const prodUrls = {
  paymentLinkHostname: "payment.adda247.in",
  baseUrl: "https://www.adda247.com"
}

const wishListUrl = {
  wishlistIds: `${LCS_SERVICE_URL}/v1/post/userId`,
  wishListAddRemove: `${LCS_SERVICE_URL}/v1/discussion/like`,
  wishListPackages: `${SEARCH_URL}/v2/wishlist`,
  deletBulkWishlist:`${LCS_SERVICE_URL}/v1/batchDiscussion/like`
}

const loggedInUserMenu = isADDA ? [
  {
    name: "Test Series",
    url: `${storeHostUrl}/my-mock-test`,
  },
  {
    name: "E-books",
    url: `${storeHostUrl}/my-ebooks`,
  },
  {
    name: "Videos",
    url: `${storeHostUrl}/my-videos`,
  },
  {
    name: "Live Class",
    url: `${storeHostUrl}/my-live-class`,
  },
] : [
  {
    name: "3D Learning",
    url: `${storeHostUrl}/my-3d-models`,
  },
  {
    name: "Test Series",
    url: `${storeHostUrl}/my-mock-test`,
  },
  {
    name: "E-books",
    url: `${storeHostUrl}/my-ebooks`,
  },
  {
    name: "Videos",
    url: `${storeHostUrl}/my-videos`,
  },
  {
    name: "Live Class",
    url: `${storeHostUrl}/my-live-class`,
  },
];
const socialUrls = isADDA
  ? {
    twitter: "https://twitter.com/adda247live",
    fb: "https://www.facebook.com/adda247live",
    yt: "https://www.youtube.com/channel/UC1L2JoMpcY6MRLhFd3gg5Xg",
    linkedIn: "https://www.linkedin.com/company/adda247",
    telegram: "https://t.me/cuetAdda247",
  }
  : {
    play_store: "https://bit.ly/3A6YzHL",
    yt: "https://www.youtube.com/@sankalp-bharat",
    ig: "https://www.instagram.com/sankalp_bharat1",
    telegram: "https://telegram.me/sankalp_bharat1",
  };

const routeToAppUrl = isADDA ?
  "https://adda247.page.link/?&apn=com.adda247.app&ibi=com.metisEduventures.adda247" : "https://bit.ly/3A6YzHL";
const routeToAppLinkUrl = `https://www.${pageName[0]}.com`;

const dfpNativeArray = ["0", "div-gpt-ad-1556282028698-0"];
const courseItems = [
  "banking",
  "ssc",
  "defence",
  "insurance",
  "railways",
  "ctet",
  "teaching",
  "engineeringexams",
  "engineering",
  "rajasthan",
  "andhra-pradesh",
  "andhra_pradesh",
  "west-bengal",
  "west_bengal",
  "tamil-nadu",
  "tamil_nadu",
  "maharashtra",
  "haryana",
  "chhattisgarh",
  "uttar-pradesh",
  "bihar",
  "madhya-pradesh",
  "madhya_pradesh",
  "kerala",
  "punjab-state-exams",
  "punjab_state_exams",
  "odisha-state-exams",
  "odisha_state_exams",
  "upsc",
  "clat",
  "gate_iitjam",
  "gate-iitjam",
  "ca",
  "iitneet",
  "jaiib_caiib",
  "jaiib-caiib",
  "uttarakhand",
  "gujrat",
  "cuet",
  "cuet-hindi",
  "cuet_hindi",
  "cuet-pg",
  "cuet_pg",
  "law",
  "otherugexams",
  "hotelmanagement",
  "designaptitude",
  "ipm",
  "neet",
  "civil",
  "electrical-engineering",
  "electronics-engineering",
  "computer-science-engineering",
  "instrumentation-engineering",
  "chemical-engineering",
  "mechanical-engineering",
  "mechanical_engineering",
  "instrumentation_engineering",
  "civil-engineering",
  "engineeringcat",
  "agriculture",
  "gujarat",
  "jharkhand",
  "civil_engineering",
  "electrical_engineering",
  "electronics_engineering",
  "computer_science_engineering",
  "chemical_engineering",
  "class-11",
  "north-east-state-exams",
  "fci",
  "regulatory-bodies",
  "ugc-net",
  "class-9",
  "class-10",
  "class-12",
  "ug-defence",
  "class_10",
  "class_9",
  "ug_defence",
  "class_12",
  "class11_jee",
  "class11-jee",
  "class12_jee",
  "class12-jee",
  "dropper_jee",
  "dropper-jee",
  "class11_neet",
  "class11-neet",
  "class12_neet",
  "class12-neet",
  "dropper_neet",
  "dropper-neet",
  "skill-development",
  "skill_development",
  "gate-civil-engineering",
  "gate-electronics-engineering",
  "gate-instrumentation-engineering",
  "gate-computer-science-engineering",
  "uttar_pradesh",
  "regulatory_bodies",
  "ugc_net",
  "north_east_state_exams",
  "class_11",
  "cucet",
  "gate_civil_engineering",
  "gate_electrical_engineering",
  "nda",
  "gate_mechanical_engineering",
  "gate_computer_science_engineering",
  "gate_electronics_engineering",
  "class_6",
  "class_7",
  "class_8",
  "designaptitute",
  "gate_instrumentation_engineering",
  "class_1",
  "gate-mechanical-engineering",
  "gate-electrical-engineering",
  "class-8",
  "class-6",
  "class-7",
  "class-1",
  "state-pcs-judiciary",
  "state_pcs_judiciary",
];

const productItems = isADDA ? [
  "onlineliveclasses",
  "mock-tests",
  "videos",
  "books",
  "ebooks",
  "live-classes"
] : [
  "onlineliveclasses",
  "mock-tests",
  "videos",
  "books",
  "ebooks",
  "live-classes",
  "3d-models"
];
const storeItemsUrlMapping = {
  onlineliveclasses: "",
  "mock-tests": "testseries",
  videos: "video",
  books: "books",
  ebooks: "ebooks",
  "live-classes": "onlineliveclasses",
};

const FallBackImage = isADDA ? "/images/img-placeholder.svg" : "/images/img-placeholder-sankalp.png";

const radioButtonOptions = [
  "Banking",
  "SSC",
  "Teaching",
  "Railway",
  "Insurance",
  "Defence",
];

const allCoursesArr = [
  "BANKING",
  "SSC",
  "RAILWAYS",
  "CTET",
  "DEFENCE",
  "ENGINEERING",
  "ENGINEERINGCAT",
  "UPSC",
  "GATE_IITJAM",
  "CLAT",
  "CA",
  "IITNEET",
  "OTHER",
  "UG",
];
const allCoursesArrLabel = {
  BANKING: "BANKING & INSURANCE",
  SSC: "SSC",
  RAILWAYS: "RAILWAYS",
  CTET: "TEACHING",
  DEFENCE: "DEFENCE",
  ENGINEERING: "ENGINEERING",
  OTHER: "STATE EXAMS",
  UPSC: "UPSC",
  CLAT: "CLAT",
  GATE_IITJAM: "GATE & IITJAM",
  CA: "CA",
  IITNEET: "IIT/MEDICAL",
  UG: "UNDERGRAD",
  ENGINEERINGCAT: "ENGINEERINGCAT",
};

const GOOGLE_CLIENT_ID = isADDA
  ? "117575776360-se3ubakrmij2q5mouk2rk46740ipurca.apps.googleusercontent.com"
  : "435369356648-t119aetjl0fge61vj6rqjj76819gmi6v.apps.googleusercontent.com";
const FACEBOOK_ID = isADDA ? "627005884507677" : "1623702784763474";
const VIDEO_INFO = [
  {
    src: "/images/intro-video/Part_1.mp4",
    type: "video/mp4",
  },
  {
    src: "/images/intro-video/Part_2.mp4",
    type: "video/mp4",
  },
  {
    src: "/images/intro-video/Part_3.mp4",
    type: "video/mp4",
  },
  {
    src: "/images/intro-video/Part_4.mp4",
    type: "video/mp4",
  },
  {
    src: "/images/intro-video/Part_5.mp4",
    type: "video/mp4",
  },
];

const isTestServer = checkIsTestServer();

const true_caller_app_data = {
  adda : isTestServer ? 
  {
    app_key : "wIlDd1e57e9ebc2ed4efb9b3f9991aa2a0952",
    app_name : "ADDA247 Staging"
  } : 
  {
    app_key : "6Y6aNe5319f0d7821411db71fb5876fb140b2",
    app_name : "Adda247"
  },
  sankalp : isTestServer ? 
  {
    app_key : "pdb0v084b7b27706e4d4ea167988dbf331c78",
    app_name : "Sankalp Bharat Staging"
  } : 
  {
    app_key : "f64NYd29fc67ead4d490d86a36ba3f547c697",
    app_name : "Sankalp Bharat"
  },
}

const true_caller_config = {
  APP_KEY : isADDA ? true_caller_app_data?.adda?.app_key : true_caller_app_data?.sankalp?.app_key,
  APP_NAME : isADDA ? true_caller_app_data?.adda?.app_name : true_caller_app_data?.sankalp?.app_name,
  LANGUAGE : "en",
  LINK_TO_YOUR_PRIVACY_PAGE : isADDA ? "https://www.adda247.com/privacy-policy.html" : "https://www.sankalpbharat.com/privacy-policy.html",
  LINK_TO_YOUR_TERMS_PAGE : isADDA ? "https://www.adda247.com/term-conditions.html" : "https://www.sankalpbharat.com/terms-and-conditions.html",
  TITLE_STRING_PREFIX : isADDA ? "ADDA247" : "Sankalp Bharat",
  TITLE_STRING_SUFFIX : isADDA ? "ADDA247" : "Sankalp Bharat",
  BUTTON_TEXT_PREFIX : "continuewith",
  BUTTON_FILL_COLOR : isADDA ? "#FC3A52" : "#5B0EDB",
  BUTTON_TEXT_COLOR : "#ffffff",
  BUTTON_SHAPE : "Rounded",
  FOOTER_CTA_STRING : "useanothernum",
  TIME_IN_MILLISECONDS : "15000",
}

export {
  pageName,
  testResultOrigin,
  testSeriesURLProd,
  newsletterUrl,
  addaGoldExcelUrl,
  userPassword,
  loggedInUserMenu,
  allExamListingUrl,
  socialUrls,
  subscriptionUrl,
  shareIconUrl,
  // timeLineUrl,
  stagingSearchUrl,
  childPackageDetailUrl,
  storeHostUrl,
  dfpNativeArray,
  courseItems,
  productItems,
  storeItemsUrlMapping,
  stagingSearchForSuggestionUrl,
  allCousrsesUrl,
  searchSuggestionUrl,
  productListingUrl,
  searchSuggestionUrlV6,
  STOREFRONT_URI,
  pageSizeForDetailListing,
  pageSizeForPackageViewListing,
  googleAnalyticsId,
  searchUrlEndPoint,
  prodUrl,
  timeLineUrl_alt,
  quizAttemptUrl,
  imageOptimiserBaseUrl,
  userApi,
  iFrameUrl,
  threeDModeliFrameUrl,
  usersReviewUrl,
  stagingMainUrl,
  pdpUrls,
  FallBackImage,
  timeLineUrl2,
  cartCheckoutUrls,
  STORE_FRONT_API_URI,
  enrolledCoursesUrl,
  videoPackageUrl,
  liveClassHandout,
  radioButtonOptions,
  ServiceSupportUrl,
  purchaseSupportUrl,
  allCoursesArrLabel,
  routeToAppUrl,
  routeToAppLinkUrl,
  paytmMiniUrls,
  myPurchase,
  packageView,
  courseTagUrl,
  tendingData,
  GOOGLE_CLIENT_ID,
  FACEBOOK_ID,
  PaytmMerchant_Id,
  dtdcApiUrl,
  specialTestUrl,
  signedurl,
  seoDataUrl,
  drmUrl,
  freshDeskTicketCreate,
  FreshDeskAPIKey,
  VIDEO_INFO,
  testEventsUrl,
  leadSquaredUrl,
  MISC_URL,
  prodUrls,
  wishListUrl,
  shoogloTrackier_key,
  shooglooTrackierUrl,
  true_caller_config,
};

import React, { useState, useEffect, useRef } from "react";
import CouponComponent from "./CouponComponent";
import fetch from "../data/utils/fetch";
import { pdpUrls } from "../../constants/appConfig";
import CustomLoader from "./library/CustomLoader";
import AInput from "./library/AInput";
import { checkLoginStatus, getDiscount, getMoengageMetadata, trackMoengageEvent } from "../data/utils/helpers";
import CustomButton from "./library/CustomButton";
import { BLANK_IDENTIFIER, ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "./MoengageConstants";


function numberWithCommas(x) {
  x = x || "0";
  if (x - parseInt(x) > 0) {
    x = x.toFixed(2);
  }
  x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return x;
}
let remove_count = 0;

export const AppliedCouponDetails = ({ priceDetails, productData }) => {
  return (
    <>
      <div className="buynow-price-detail-box">
        <div className="buynow-heading-wrap">
          <div className="buynow-price-box-heding">Price Details</div>
        </div>
        <div className="buynow-right-amount-wrap">
          <div className="buynow-amount-payable">
            <div className="buynow-amount-payable-left">Subtotal</div>
            <div className="buynow-amount-payable-right">
              ₹ {numberWithCommas(priceDetails.totalSellingPrice)}
            </div>
          </div>

          {priceDetails?.couponDiscount ? (
            <div className="buynow-amount-payable">
              <div className="buynow-amount-payable-left">Discount {
                priceDetails?.couponCode && (<span className="couponApplied">{`(${priceDetails?.couponCode} coupon applied)`}</span>)
              } 
              </div>
              <div className="buynow-amount-payable-right">
                {` - ₹ ${numberWithCommas(priceDetails?.couponDiscount)}`}
              </div>
            </div>
          ): ""}
          {
            priceDetails?.oldOrderAmount ? (
              <div className="buynow-amount-payable">
                <div className="buynow-amount-payable-left">Old Order Amount</div>
                <div className="buynow-amount-payable-right">
                  {` - ₹ ${numberWithCommas(priceDetails?.oldOrderAmount)}`}
                </div>
              </div>
            ): ""
          }
          {priceDetails?.discount && (
            <div className="buynow-total-discount">
              <div className="buynow-total-discount-left">
                Discount (Coupon {priceDetails?.appliedCoupon})
              </div>
              <div className="buynow-total-discount-right">
                {" "}
                <span>- </span>{`₹ ${numberWithCommas(priceDetails?.discount)}`}
              </div>
            </div>
          )}
          <div className="buynow-total-payable">
            <div className="buynow-total-payable-left">To Pay</div>
            <div className="buynow-total-payable-right">
              ₹{" "}
              {priceDetails?.payablePrice === undefined
                ? numberWithCommas(productData?.price)
                : numberWithCommas(priceDetails?.payablePrice)
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const YourOrdersDetailsMobileView = ({ maxPrice, price }) => {
  return (
    <>
      <div className="footer-payment-detail">
        <div className="price">
          <div className="price-tag">
            <span className="selling-price">
              &#8377;{numberWithCommas(price)}<i>Only</i>
            </span>{" "}
            {
              maxPrice !== price ? <> {getDiscount(maxPrice, price) >= 0 ? (
                <>
                  {" "}
                  <div className="offprice">
                    <span className="maximum-retail-price">
                      &#8377; <strike>{numberWithCommas(maxPrice)}</strike>
                    </span>
                    <span className="off">{getDiscount(maxPrice, price)}% off</span>
                  </div>
                </>
              ) : null}</> : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export const YourOrdersDetails = ({ maxPrice, price, productData, priceDetails, bookFlag }) => {
  return (
    <>
      <section className="buynow-order-box">
        <div className="buynow-heading-wrap">
          <div className="buynow-inner-heading">Your Order</div>
        </div>
        <div className="buynow-product-detail">
          <div className="buynow-product-image">
            <img src={productData?.imgUrl} />
          </div>
          <div className="buynow-pdp-detail">
            <div className="username-validity">
              <div className="buynow-pdp-name">
                {productData?.title}
              </div>
              {(productData?.subCategories?.length === 1 && productData.subCategories[0].category === "BOOKS") ? "" : (((productData.dualValidity) || (productData?.vpData && productData?.vpData[0])) ? <div className="buynow-pdp-validity">
                ({`Validity ${productData.dualValidity ? `${productData.vpData[0].mO / 2}+${productData?.vpData[0].mO / 2}` : productData?.vpData[0]?.mO} Months`})
              </div> : "")}
            </div>
            <div className="price">
              <div className={"price-tag"}>
                {
                  maxPrice !== price && priceDetails?.discountType !== 'flat' ? <>       {getDiscount(maxPrice, price) >= 0 ? (
                    <>
                      {" "}
                      <div className="offprice">
                        <span className="maximum-retail-price">
                          &#8377; <strike>{numberWithCommas(maxPrice)}</strike>
                        </span>
                        <span className="off">{getDiscount(maxPrice, price)}% off</span>
                      </div>
                    </>
                  ) : null}</> : null
                }
                {
                  priceDetails?.discountType == 'flat' ? <div className="buynow-total-discount buynow-total-discount-left fixed-price-coupon">Fixed Price </div> : ''
                }

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ApplyCouponCRM = ({
  packageId,
  isPaytmMini,
  packageType,
  defaultPriceInfo,
  priceDetails,
  updatePriceDetails,
  productData,
  maxPrice,
  price,
  availableCouponList,
  cartPayload,
  loggedUserDetails,
  userEmail,
  setUserEmail,
  couponListData,
  close,
  Getpayload,
  getUtmFromStore,
  fromUserDetailsSection, // flag that identifies if the  section is opened from userDetails popup,
  setViewOfferModalOnly,
  viewOfferModalOnly,
}) => {
  const intialCounter = useRef();
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState({
    couponStatus: false, couponErrorMessage: ""
  });
  const [isError, setError] = useState("");
  const [applyingWithoutEmail, setApplyingWithoutEmail] = useState(false);
  const [applying, setApplying] = useState(false);
  const [couponList, setCouponList] = useState(availableCouponList);
  const [isRemovebuttoncliecked, setCouponcodeRemove] = useState(false);
  const [iseapplybuttoneclicked, setApplyButtonClicked] = useState(false);
  const [enteredCouponCode, setEnteredCouponCode] = useState(
    priceDetails.enteredCouponFlag ? priceDetails.appliedCoupon : ''
  );
  useEffect(() => {
    // fetch( url, method, body )
    remove_count = 0;
    if (!availableCouponList || availableCouponList.length == 0) {
      if (packageId) {
        getCouponsForUser();
      }
    }

    return () => {
      try {
        document.body.classList.remove("noscroll");
      } catch (ex) { }
    };
  }, []);

  const addToCartInLocalStorage = (productDetails) => {
    let cartItems =
      (localStorage.getItem("CART_ITEMS") &&
        JSON.parse(localStorage.getItem("CART_ITEMS"))) ||
      [];
    if (!cartItems.find((cartItem) => cartItem.id === productDetails.id)) {
      // product is not added already.
      cartItems.push({
        id: productDetails.id,
      });
    }
    localStorage.setItem("CART_ITEMS", JSON.stringify(cartItems));
  };

  const submit = () => {
    setViewOfferModalOnly(false);
    addToCartInLocalStorage(productData);
    window.location.replace("#checkout/userDetails");
  };
  useEffect(() => {
    // remove Coupon if email is changed
    if (priceDetails.appliedCoupon && intialCounter.current) {
      applyCoupon("");
    }
    intialCounter.current = userEmail;
  }, [userEmail]);
  const getCouponsForUser = () => {
    setIsCouponLoading(true);
    fetch(
      `${pdpUrls.couponList}?packageIds=${packageId}&pkdCategory=${packageType}`,
      "GET"
 
    ).then(async (res) => {
      setIsCouponLoading(false);
      if (res.data) {
        let couponData = [];
        let couponDataSanitized = [];
        const sanitizeWithDynamicDiscount = res?.data?.map(async (coupon) => {
          /** when coupon is dynamic static parameter is false.
           *  Dynamic coupon value to be shown and applied through dynamicDiscount object based on platform on web and android
           */
          if (!coupon?.static) {
            if (coupon?.dynamicDiscount) {
              let filterData = coupon?.dynamicDiscount.filter(v => ((v.type == "web") || (v.type == "ONLINE_LIVE_CLASSES") || (v.type == "TEST_SERIES") || (v.type == "VIDEOS") || (v.type == "EBOOKS") || (v.type == "BOOKS")));
              if (filterData && filterData.length) {
                couponData.push(coupon);
              }
            }
          } else {
            couponData.push(coupon)
          }
        });
        if (couponData && couponData.length) {
          couponData.filter((x) => {
            if (!x?.static) {
              if (x.dynamicDiscountCategory == "platform") {
                let selectCoupon2 = x?.dynamicDiscount?.find((v) => v.type == "web");
                if (selectCoupon2) {
                  couponDataSanitized.push({...x, dynamicDiscount:[selectCoupon2], discountValue:selectCoupon2.value});
                }
              } else {
                let selectCoupon = x?.dynamicDiscount?.find((v) => v.type == packageType);
                if (selectCoupon) {
                  couponDataSanitized.push({...x, dynamicDiscount:[selectCoupon], discountValue:selectCoupon.value});
                }
              }
            } else {
              couponDataSanitized.push(x);
            }
          })
        }
        setCouponList(couponDataSanitized);
      } else {
        setCouponList(null);
      }
    },
      (err) => {
        setIsCouponLoading(false);
        setCouponList(null);
      }
    );
  };

  let userInfo =
    localStorage.getItem("paytm-user-info") &&
    JSON.parse(localStorage.getItem("paytm-user-info"));

  //   return true;
  // };

  const applyCoupon = (couponCode, flag, applyBtn, coupon) => {
    // if(coupon?.discountType == 'flat')
    // {
    //  updatePriceDetails({...priceDetails, discountType : 'flat'})
    // }
    // else if(couponCode == ''){
    //  updatePriceDetails({...priceDetails, discountType : ''})

    // }
    const isLogined = checkLoginStatus();
    let source_screen = `${viewOfferModalOnly ? "View_offers" : "Buy_now"}`;
    if (couponCode && Getpayload) {
      setCouponcodeRemove(false);
      setApplyButtonClicked(true);
      var body = Getpayload;
      body.couponCode = couponCode || BLANK_IDENTIFIER;
      body.user_email = userEmail || "";
      body.getUtmFromStore = getUtmFromStore || "";
      body.source_screen = source_screen;
      body.exam_category = body?.courses || BLANK_IDENTIFIER;
      body.user_exam_category_selected = WEB_IDENTIFIER;
      body.package_subject = BLANK_IDENTIFIER;
      body.language = ENGLISH_IDENTIFIER;
      body.package_final_price = BLANK_IDENTIFIER;
      body.package_url = window?.location?.href || BLANK_IDENTIFIER;
      body.modified_date = BLANK_IDENTIFIER;
      body.published_date = BLANK_IDENTIFIER;
      body.exam = BLANK_IDENTIFIER;
      remove_count = 0;
      if (typeof Moengage !== "undefined")
        trackMoengageEvent(
          "coupon_code_applyButton_clicked",
          getMoengageMetadata(body)
        );
    }
    if (couponCode != "") {
      remove_count = 0;
      var body = Getpayload || {};
      body.source_screen = source_screen;
      body.exam_category = body?.courses || BLANK_IDENTIFIER;
      body.user_exam_category_selected = WEB_IDENTIFIER;
      body.package_subject = BLANK_IDENTIFIER;
      body.language = ENGLISH_IDENTIFIER;
      body.package_final_price = BLANK_IDENTIFIER;
      body.package_url = window?.location?.href || BLANK_IDENTIFIER;
      body.modified_date = BLANK_IDENTIFIER;
      body.published_date = BLANK_IDENTIFIER;
      body.exam = BLANK_IDENTIFIER;
      if (typeof Moengage !== "undefined")
        trackMoengageEvent(`${applyBtn != "text" ? "coupon_code_applyButton_clicked" : "coupon_code_btn_clicked"}`, getMoengageMetadata(body));
    }
    if (couponCode == "") {
      setInvalidCoupon(oldState => ({ ...oldState, couponStatus: false }));
      // setInvalidCoupon(false);
      remove_count = 0;
      setEnteredCouponCode("");
      setApplyButtonClicked(false);
      setCouponcodeRemove(true);
      var body = Getpayload;
      if (typeof Moengage !== "undefined")
        trackMoengageEvent("coupon_code_removed", getMoengageMetadata(body));
    }


    if (applying) return;
    if (couponCode == "") {
      setApplying("Removing Coupon");
    } else {
      setApplying("Applying Coupon");
    }

    let payload = {
      cartJson: cartPayload,
      couponCode: couponCode,
    };
    const  url = isLogined ? pdpUrls.applyCart : pdpUrls.applyCartGuest
    fetch(`${url}?user-email=${userEmail}`, "POST", payload).then(
      (res) => {
        let defaultValue = {
          basePrice: undefined,
          discount: undefined,
          payablePrice: undefined,
          discountType: undefined
        };
        setApplying(false);
        if (res.data) {
          let data = res.data;
          if (flag) {
            setEnteredCouponCode(couponCode);
          }
          if (res.data.discount !== 0) {
            setInvalidCoupon(oldState => ({ ...oldState, couponStatus: false, couponErrorMessage: data.error }));
            updatePriceDetails({
              discount: data.discount,
              payablePrice: data.payablePrice,
              appliedCoupon: couponCode,
              enteredCouponFlag: flag ? true : false,
              discountType: coupon?.discountType == 'flat' && couponCode !== '' ? 'flat' : ''
            });
          } else {
            if (couponCode) {
              updatePriceDetails(priceDetails);
              setInvalidCoupon(oldState => ({ ...oldState, couponStatus: true, couponErrorMessage: data.error }));
            } else {
              updatePriceDetails({ appliedCoupon: couponCode, ...defaultValue });
            }
          }
        } else {
          updatePriceDetails(priceDetails);
          setInvalidCoupon(oldState => ({ ...oldState, couponStatus: true, couponErrorMessage: data.error }));
        }
      },
      (err) => {
        setApplying(false);
      }
    );
  };
  if (isCouponLoading)
    return (
      <div className="couponLoader">
        {" "}
        <CustomLoader />
      </div>
    );

  if (
    enteredCouponCode &&
    enteredCouponCode === priceDetails.appliedCoupon &&
    !priceDetails.discount &&
    !applying &&
    Getpayload &&
    remove_count == 0
  ) {
    var payload = Getpayload;
    payload.couponCode = enteredCouponCode || "";
    payload.user_email = userEmail || "";

    payload.error = "Invalid Coupon";
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "coupon_code_incorrect",
        getMoengageMetadata(payload)
      );
    remove_count = 1;
  }
  return (
    <>
      <div className={`buynow-body-content ${viewOfferModalOnly ? "viewOfferModalAlignCent" : ""}`}>
        <div className="buynow-left-side">
          <div className="coupon" style={!fromUserDetailsSection ? {} : {}}>


            <div className={`coupon-list`}>
              <section className="buynow-coupon-box">
                {couponList?.length ? (
                  <>
                    {" "}
                    <div className="buynow-heading-wrap">
                      <div className="buynow-inner-heading">Available Offers</div>
                      {priceDetails.appliedCoupon && priceDetails?.discount ? (
                        <div className="buynow-offer-applied">
                          (Coupon Applied {priceDetails.appliedCoupon})
                        </div>
                      ) : null}
                    </div>
                    <div className="wrap-coupon-list">
                      {couponList &&
                        couponList.map((coupon) => {
                          return (
                            <div className="coupon-list-item">
                              <CouponComponent
                                coupon={coupon}
                                priceDetails={priceDetails}
                              />
                              <span
                                className="coupon-list-apply"
                                onClick={() => {
                                  applyCoupon(
                                    priceDetails.appliedCoupon == coupon.code
                                      ? ""
                                      : coupon.code, false, null, coupon
                                  );
                                }}
                              >
                                {priceDetails.appliedCoupon == coupon.code
                                  ? "REMOVE"
                                  : "APPLY"}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="nocopoun"></div>
                  </>
                )}

                <div className={`buynow-coupon-enter-box ${!couponList ? 'buynow-coupon-enter-box-borderTop' : ''}`}>
                  <div
                    className="coupon-list-header"
                    style={{ borderTop: "0px" }}
                  >
                    Got a Coupon code ? Enter it here
                  </div>
                  <div className="coupon-input">
                    <AInput
                      className="coupon-input-box"
                      placeholder="Enter Coupon Code"
                      onChange={(e) => {
                        setEnteredCouponCode(e.target.value)
                        if (!e.target.value) setInvalidCoupon(false)
                      }}
                      value={enteredCouponCode}
                      onKeyUp={(e) => {
                        if (e.keyCode == 13) {
                          applyCoupon(enteredCouponCode, true)
                        }
                      }}
                    ></AInput>
                    {enteredCouponCode &&
                      enteredCouponCode === priceDetails.appliedCoupon ? (
                      <button
                        onClick={() => {
                          applyCoupon("");
                        }}
                        className="coupon-list-apply coupon-input-submit"
                      >
                        REMOVE
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          if (enteredCouponCode != "") {
                            applyCoupon(enteredCouponCode, true, "text");
                          }
                        }}
                        className={`coupon-list-apply coupon-input-submit ${!enteredCouponCode ? 'disabled' : ''}`}
                      >
                        APPLY
                      </div>
                    )}
                  </div>
                  {applying ? (
                    <div className="coupon-info success">{applying}...</div>
                  ) : (
                    invalidCoupon?.couponStatus && (
                      <div className="coupon-info error">{invalidCoupon.couponErrorMessage || "Invalid Coupon"}</div>
                    )
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="buynow-right-side">
          <YourOrdersDetails bookFlag={productData?.subCategories?.some(el => el?.category === "BOOKS") && productData?.subCategories?.length == 1 ? true : false} productData={productData} maxPrice={maxPrice} price={price} priceDetails={priceDetails} />
          <AppliedCouponDetails
            priceDetails={priceDetails}
            productData={productData}
          />
          <div className="footer-paymentbtn-fix">
            <YourOrdersDetailsMobileView maxPrice={maxPrice} price={price} priceDetails={priceDetails} />
            <CustomButton
              buttontext="Continue"
              onClick={submit}
            ></CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.header.userInfo,
    isPaytmMini: state.isPaytmMini,
  };
};
export default ApplyCouponCRM;

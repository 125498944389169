import React from "react";
import LazyIntersection from "../LazyIntersection";
import { FallBackImage } from "../../../constants/appConfig";

const ComingSoonPrdCard = ({
  src = FallBackImage,
  title = "Number Analogy and Odd One Out in the resonse of recor.....",
  onClick,
  batchStartDate,
}) => {
  const getFormattedDate = (milliTime) => {
    let date = new Date(milliTime);
    // strDate.substring(0, 10);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    date.setHours(0, 0, 0, 0);
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();

    var currentDate = new Date(y, m, d);

    let formattedDate = date
      .toLocaleString()
      .substring(0, 10)
      .split("/")
      .reverse()
      .join("-");
    if (currentDate >= date) {
      formattedDate = "Started on " + formattedDate;
    } else {
      formattedDate = "Starts on " + formattedDate;
    }

    return formattedDate;
    // + " " + strTime;
  };
  return (
    <div className="cmg-product-card" onClick={onClick}>
      <div className="cmg-product-card-img-wrap">
        <div
          className="cmg-product-card-img-bg"
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></div>

        <div className="cmg-product-card-img-overlay"></div>
        {/* <img
          src={src}
          onError={(event) => {
            event.target.src = "/images/img-placeholder.svg";
            event.onerror = null;
          }}
          loading='lazy'
        /> */}
        <LazyIntersection
          src={src || "/images/ppc-pack-img.png"}
          placeholder={FallBackImage}
        />
      </div>
      <div className="cmg-product-name">
        {title}
        {batchStartDate && (
          <p className="ppc-batchstart-date">
            {getFormattedDate(batchStartDate)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ComingSoonPrdCard;

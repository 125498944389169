import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  STOREFRONT_URI,
  TEST_SERIES_URL,
} from "../../../../../constants/appUrls";
import fetch from "../../../../data/utils/fetch";
import { getMoengageMetadata, isADDA } from "../../../../data/utils/helpers";
import { getParameterByName, overAlldate } from "../../../utils";
import DTBResullInfo from "../TestResults/DTBResullInfo";

import TestResultLayout from "./TestResultLayout";
import OverAllResultAnalysis from "./OverAllResultAnalysis";
import SectionalAnalysis from "./SectionalAnalysis";
import ComparisonAnalysis from "./ComparisonAnalysis";
import CustomLoader from "../../../../components/library/CustomLoader";
import CustomHelmet from "../../../library/CustomHelmet";
import { pageName, stagingMainUrl, testResultOrigin, testSeriesURLProd } from "../../../../../constants/appConfig";

const TestResultAnalysis = () => {
  const [selectedSideTab, setSelectedSideTab] = useState("Result Analysis");
  const [sectionTabs, setSectionTabs] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const dtbContentFlag = queryParams.get("dtb");
  const sfJson = queryParams.get("sfJsonPreview");
  const fixedMockFlag =
    queryParams.get("fixedMockResult") && !queryParams.get("freeContent");
  const freeContentFlag = queryParams.get("freeContent");
  const exam = queryParams.get("exam"); // taged exam name with test.

  const { mappingId, packageId, title } = useParams();

  const [moengageData, setMoengageData] = useState();
  const [loadingResult, setLoadingResult] = useState(true);
  const [resultError, setResultError] = useState("");
  const [testResult, setTestResult] = useState({});
  const [feedbackData, setFeedbackData] = useState();
  const [isPPC, setIsppc] = useState();
  const [loadingFeedback, setLoadingFeedBack] = useState(true);
  const [rating, setRating] = useState(undefined); // not in a mood to change intial value now;
  const [mode, setMode] = useState("");
  const [liveTestCategory, setLiveTestCategory] = useState(null);

  const userDetails = useSelector((store) =>
    store.header.userInfo ? store.header.userInfo : {}
  );
  const userId = userDetails ? userDetails.id : "";
  const [topperList, setTopperList] = useState(null);
  const [userData, setUserData] = useState({});
  const [userRank, setUserRank] = useState({});
  const [ratingAvailable, setRatingAvailable] = useState(true);
  let testurlQueryParams = search;
  // freeContentFlag is true then remove freeContent from query.
  if (freeContentFlag) {
    testurlQueryParams = search
      .split("&")
      ?.filter((param) => !param.includes("fixedMockResult"))
      ?.join("&");
  }
  const jwtTokenNew = useSelector((state) => state.header.jwtTokenNew);
  const isTestAttempted = testResult?.overall;
  const moengageViewResult = (res) => {
    var data = {};
    var sectionalData = [];
    data.attemptedDate = overAlldate(res?.data?.overall?.dateAttempted);
    data.package_status = "Paid/Free";
    data.title = title;
    data.testId = mappingId;
    data.id = packageId;
    data.action = "analysis viewed";
    data.rating = rating;
    data.markScored = res?.data?.overall?.marks;
    data.timeSpent = res?.data?.overall?.timeSpent;
    data.totalTime = res?.data?.overall?.totalTime;
    data.attemptedPercentage = res?.data?.overall?.attempted;
    data.correct_qnCount = res?.data?.sections?.reduce((total, value) => {
      return total + value?.correctAnswerCount;
    }, 0);
    data.incorrect_qnCount = res?.data?.sections?.reduce((total, value) => {
      return total + value?.incorrectAnswerCount;
    }, 0);
    data.totalQuestions = res?.data?.sections?.reduce((total, value) => {
      return total + value?.totalQuestions;
    }, 0);
    data.unattempted_qnCount =
      data?.totalQuestions - (data?.incorrect_qnCount + data?.correct_qnCount);
    data.oa_rank = res?.data?.overall?.overallRank;
    data.oa_average_score = res?.data?.testInfo?.averageScore;
    data.oa_highest_score =
      res?.data?.toppersList && res?.data?.toppersList[0]?.totalMarksObtained;

    res?.data?.sections?.forEach(function (elem) {
      sectionalData.push({
        sec_name: elem?.sectionName,
        sec_rank: elem?.rank,
        sec_accuracy: elem?.accuracy,
        sec_score: elem?.marks,
        sec_timeTaken: elem?.timeSpent,
      });
    });
    data.sectionalData = sectionalData;
    let ppcstatus = getParameterByName("ppc");
    setMoengageData(data);

    setIsppc(ppcstatus);
    data.isPPc = ppcstatus;
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        '"test_submitted_scoreData',
        getMoengageMetadata(data)
      );
    setTestResult(res.data);
    if(res.data?.overall?.liveTest) {
      setMode(res.data?.overall?.nonLiveUser ? "NLT" : "LT");
      setLiveTestCategory(res.data?.overall?.testCategory || null);
    }
  };

  const getTopperData = () => {
    let baseUrl;
    if (TEST_SERIES_URL === testSeriesURLProd){
      baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
    }else{
      baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
    }
    try{
      fetch(`${baseUrl}/${packageId}/${mappingId}/results/topper.json`, "GET")
      .then((res) => {
        if (res) {
          setTopperList(res);
          getUserData();
        }
      })
      .catch((err) => {console.error(err)})
      .finally(() => {setLoadingResult(false);})
    }
    catch (err) {
      console.error(err);
    }
  };

  const getUserData = () => {
    let baseUrl;
    if (TEST_SERIES_URL === testSeriesURLProd){
      baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
    }else{
      baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
    }
    fetch(
      `${baseUrl}/${packageId}/${mappingId}/results/${userId}.json`,
      "GET",
      undefined,
      undefined,
      undefined,
      {
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
        Origin: stagingMainUrl?.slice(8),
        "x-jwt-token-new": jwtTokenNew,
      }
    )
      .then((res) => {
        if (res.data) {
          setUserData(res.data);
          getUserRank();
          getFeedbackInfo();
          setLoadingResult(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUserRank = () => {
    let baseUrl;
    if (TEST_SERIES_URL === testSeriesURLProd){
      baseUrl = isADDA ? TEST_SERIES_URL : testResultOrigin.prod;
    }else{
      baseUrl = isADDA ? stagingMainUrl : testResultOrigin.staging;
    }
    fetch(
      `${baseUrl}/${packageId}/${mappingId}/results/${userId}_rank.json`,
      "GET",
      undefined,
      undefined,
      undefined,
      {
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
        Origin: stagingMainUrl?.slice(8),
        "x-jwt-token-new": jwtTokenNew,
      }
    )
      .then((res) => {
        if (res) {
          setUserRank(res);
        }
      })
      .catch((err) => console.log(err));
  };
  const defineFixedMockedData = () => {
    let fixedMockData = {};
    // define overall
    if (userData?.result) {
      fixedMockData["overall"] = {
        ...userData.result,
        ...userRank,
      };
    }
    // define topperInfo & toppersList
    if (topperList?.length) {
      fixedMockData["topperInfo"] = {
        overall: {
          ...topperList[0],
        },
      };
      fixedMockData["toppersList"] = [...topperList];
    }
    setTestResult(fixedMockData);
  };
  const testAttempDate = useMemo(() => {
    if (!Object.keys(testResult).length) return;
    const d = new Date(testResult?.overall?.dateAttempted);

    function isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }
    let date;
    if (isValidDate(d)) {
      const hoursMin = d.toLocaleTimeString("en", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      const hour = new Intl.DateTimeFormat("en", {
        calendar: "numeric",
      }).format(d);
      date = `${da} ${mo}, ${ye} | ${hoursMin}`;
    }
    return date;
  }, [testResult]);

  useEffect(() => {
    if (fixedMockFlag && userId) {
      getTopperData();
    }
  }, [userId]);

  useEffect(() => {
    if (fixedMockFlag && !freeContentFlag) defineFixedMockedData();
  }, [topperList, userData, userRank]);

  useEffect(() => {
    // dont run below code for DTB result analysis.
    if (dtbContentFlag) {
      setLoadingResult(false);
      return;
    }
    // -------------
    let testData = localStorage.getItem("testCalcData");
    let testDataParsed = JSON.parse(testData);
    if (!fixedMockFlag) getFeedbackInfo();

    if (freeContentFlag) {
      if (testDataParsed?.resultData) {
        moengageViewResult(testDataParsed?.resultData);
        setLoadingResult(false);
      }
      return;
    }
    if (fixedMockFlag) {
      return;
    }
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/view-result?packageId=${packageId}&mappingId=${mappingId}`,
      "GET"
    ).then(
      (res) => {
        if (res.data) {
          moengageViewResult(res);
          setLoadingResult(false);
        } else {
          setResultError("Something Went Wrong,Please try again later!");
          setTestResult({});
        }
      },
      (err) => {
        setResultError("Something Went Wrong,Please try again later!");
        setTestResult({});
      }
    );
  }, []);

  // make tabs for sections.
  useEffect(() => {
    if (testResult) {
      let tabs = testResult?.sections?.map((section) => {
        if (section?.dtbQuesSecCount !== section?.totalQuestions) {
          return section?.secDN;
        }
      });
      setSectionTabs(tabs);
    }
  }, [testResult]);
  const getSideTabs = () => {
    let sideTabs = ["Result Analysis", "Sections Report", "Compare Yourself"];
    if (freeContentFlag && !fixedMockFlag) {
      sideTabs = ["Result Analysis", "Sections Report"];
    } else if (fixedMockFlag) {
      sideTabs = ["Result Analysis"];
    }
    return sideTabs;
  };
  const getFeedbackInfo = () => {
    try {
      fetch(
        `${STOREFRONT_URI}/api/v1/feedback?mappingId=${mappingId}&packageId=${packageId}`,
        "GET"
      ).then(
        (res) => {
          setRating(res?.data ? +res.data.starRating - 1 : undefined);
          setFeedbackData(res.data);
          setRatingAvailable(res?.data ? true : false);
        },
        (err) => {
          setRating(undefined);
          setFeedbackData(null);
          setRatingAvailable(false);
        }
      ).finally(() => {setLoadingFeedBack(false);})
    } catch (err){
      console.error(err);
    }
  };
  const getAnalysisComponent = {
    "Result Analysis": (
      <OverAllResultAnalysis
        testResult={testResult}
        mappingId={mappingId}
        packageId={packageId}
        title={title}
        rating={rating}
        setRating={setRating}
        isPPC={isPPC}
        moengageData={moengageData}
        freeContentFlag={freeContentFlag}
        fixedMockFlag={fixedMockFlag}
        ratingAvailable={ratingAvailable}
        exam={exam}
        mode={mode}
      />
    ),
    "Sections Report": (
      <SectionalAnalysis
        sectionTabs={sectionTabs}
        testResult={testResult}
        mappingId={mappingId}
        packageId={packageId}
        title={title}
        rating={rating}
        setRating={setRating}
        isPPC={isPPC}
        moengageData={moengageData}
        freeContentFlag={freeContentFlag}
        ratingAvailable={ratingAvailable}
        exam={exam}
        mode={mode}
      />
    ),
    "Compare Yourself": (
      <ComparisonAnalysis
        sectionTabs={sectionTabs}
        testResult={testResult}
        mappingId={mappingId}
        packageId={packageId}
        title={title}
        rating={rating}
        setRating={setRating}
        isPPC={isPPC}
        moengageData={moengageData}
        freeContentFlag={freeContentFlag}
        ratingAvailable={ratingAvailable}
      />
    ),
  };
  if (resultError) {
    return <h1 style={{ textAlign: "center" }}>{resultError}</h1>;
  }
  if (loadingResult) {
    return <CustomLoader />;
  }
  if (dtbContentFlag) {
    return (
      <DTBResullInfo
        mappingId={mappingId}
        packageId={packageId}
        title={title}
        freeContentFlag={freeContentFlag}
        sfJson={sfJson}
        exam={exam}
      />
    );
  }
  return (
    <>
    <CustomHelmet title={`${pageName[1]}`} />
    <TestResultLayout
      testTitle={decodeURIComponent(title)}
      dateAttempted={
        testAttempDate && (
          <>
            {fixedMockFlag
              ? `${isTestAttempted ? "Attempted" : "Conducted"}`
              : "Attempted"}{" "}
            on
            <span> {testAttempDate}</span>
          </>
        )
      }
      rightAction={
        <Link
          className="viewSolbtn"
          to={`/test-series-portal/${mappingId}/${packageId}/COMPLETED/${encodeURIComponent(
            title
          )}${testurlQueryParams}`}
          target="_blank"
        >
          <span>View Solutions</span>
        </Link>
      }
      selectedSideTab={selectedSideTab}
      onClickSideTab={setSelectedSideTab}
      sideBarTabs={getSideTabs()}
      level=""
      isFixedMock={fixedMockFlag}
      liveTestCategory={liveTestCategory}
    >
      <>
        {testResult?.overall?.dtbQuestotalCount > 0 && (
          <p className="tr-sub-info">
            <img
              src={"/images/test-solution/infoiconrould.svg"}
              alt="tick-blue.svg"
            />
            Subjective questions({testResult?.overall?.dtbQuestotalCount}) are
            not included for analysis
          </p>
        )}
        {getAnalysisComponent[selectedSideTab]}
      </>
    </TestResultLayout>
    </>
  );
};

export default TestResultAnalysis;

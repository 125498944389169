import { MISC_URL } from "../../../../constants/appConfig";
import types from "./types";

const setPageNo = (pageNo) => {
  return {
    type: types.SET_PAGE_NO,
    payload: {
      data: pageNo,
    },
  };
};
const setRankType = (rankType) => {
  return {
    type: types.SET_RANK_TYPE,
    payload: {
      data: rankType,
    },
  };
};
const setRank = (rank) => {
  return {
    type: types.SET_RANK,
    payload: {
      data: rank,
    },
  };
};
const updateFormData = (formData) => {
  return {
    type: types.UPDATE_FORM_DATA,
    payload: {
      data: formData,
    },
  };
};

const getCollegeList = (
  rank,
  rankType,
  category,
  state = "",
  page,
  pageSize
) => {
  let url = `${MISC_URL}/api/v1/predict-college?rank=${rank}&category=${category}&predictionLevel=${
    rankType == 0 ? "india" : "state"
  }`;
  if (rankType == 1 && state !== "") url = url + `&stateName=${state}`;
  url = url + `&pageNumber=${page - 1}&pageSize=${pageSize}`;
  return {
    CALL_API: [
      {
        type: types.GET_COLLEGE_LIST,
        meta: {
          path: url,
          method: "GET",
        },
      },
    ],
  };
};

export { setPageNo, setRankType, setRank, updateFormData, getCollegeList };

import React from "react";
import LazyIntersection from "../LazyIntersection";
import { FallBackImage } from "../../../constants/appConfig";

const PackageDetailCard = React.forwardRef((props, ref = null) => {
  let {
    src = FallBackImage,
    title = "Package title come here",
    validityDate = "12 Apr 2022",
    isExpired = false,
    onClick,
  } = props;
  return (
    <div
      ref={ref}
      onClick={onClick}
      className={`ppc-mypackbox ${isExpired ? "expire-pack" : "active-pack"}`}
    >
      <div className="ppc-mypack-img">
        {/* <img
          src={src || "/images/ppc-pack-img.png"}
          onError={(event) => {
            event.target.src = "/images/img-placeholder.svg";
            event.onerror = null;
          }}
          loading="lazy"
        /> */}
        <LazyIntersection
          src={src || "/images/ppc-pack-img.png"}
          placeholder={FallBackImage}
        />
      </div>
      <div className="ppc-mypackbox-content">
        <div className="ppc-mypack-title">{title}</div>
        <div className="ppc-mypack-valdity">
          Validity - <span> {validityDate}</span>
        </div>
        <div className="ppc-mypack-btns">
          <div className={`ppc-mypack-btn ${isExpired ? "expire" : "active"}`}>
            {isExpired ? "Expired" : "Active"}
          </div>
          {isExpired && (
            <div className="ppc-mypack-renew">
              <span href="#" onClick={onClick}>
                Renew Now
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default PackageDetailCard;

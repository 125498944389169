import React, { useEffect, useState } from "react";
import { getMoengageMetadata } from "../../../../../data/utils/helpers";
import AddRatingPopup from "./AddRatingPopup";

const TestRating = ({
  rating,
  setRating,
  isPPC,
  moengageData,
  isThumbnail = true,
  flexBy,
  ratingAvailable
}) => {
  const [isFeedBackVisible, setIsFeedBackVisible] = useState(false);
  const [feedbackUpdated, setFeedbackUpdated] = useState(false);

  const openFeedback = (value) => {
    setRating(value);
    setIsFeedBackVisible(true);
    var payloadvalue = moengageData;
    var payload = {};
    payload.package_status = "Paid/Free";
    payload.contentTitle = payloadvalue.title;
    payload.contentID = payloadvalue.testId;
    payload.id = payloadvalue.id;
    payload.action = "rate_submitted";
    payload.isPPc = isPPC;
    if (typeof Moengage !== "undefined")
      Moengage.track_event("test", getMoengageMetadata(payload));
  };
  const closeFeedBack = () => {
    if (!feedbackUpdated) {
      setRating(-1);
    }
    setIsFeedBackVisible(false);
  };
  useEffect(() => {
    if (rating === undefined && !ratingAvailable) {
      setIsFeedBackVisible(true);
    }
  }, [rating, ratingAvailable]);
  return (
    <>
      <AddRatingPopup
        rating={rating}
        isVisible={isFeedBackVisible}
        closeCallback={closeFeedBack}
        openFeedback={openFeedback}
        feedbackUpdated={feedbackUpdated}
        setFeedbackUpdated={setFeedbackUpdated}
      />
      <section className="boxWhiteFull ratethis">
        <div className={`ratethisBox ${flexBy}`}>
          <div className="ratethisText">
            <div className="rateThisheading">
              {rating != -1 ? "You rated this test" : "Rate this test"}
            </div>
            <div className="rateThistext">
              {rating != -1
                ? "We have received your feedback. It will help us improve your experience."
                : "We would love to know how was your experience with this test?"}
            </div>
            <div className="rateThisStarts">
              <div className="rating">
                {Array(5)
                  .fill(1)
                  .map((val, index) => {
                    return (
                      <span
                        onClick={() => {
                          if (rating == -1) {
                            setIsFeedBackVisible(true);
                            setRating(index);
                          }
                        }}
                      >
                        <img
                          height="28px"
                          src={
                            rating == undefined || index > rating
                              ? "/images/fadeStar.svg"
                              : "/images/fillStar.svg"
                          }
                        ></img>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          {isThumbnail && (
            <div className="ratethisimg">
              <img src="../../../images/rate-this.svg" />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default TestRating;

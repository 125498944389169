import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';


const ConnectedLink  = (props)=>{
    let {referrer,children,...otherProps}=props
    if(Array.isArray(referrer)){
        let queryParams = referrer.map((value)=>`${Object.keys(value)[0]}=${Object.values(value)[0]}`).join('&')
        otherProps.to = `${otherProps.to}?${queryParams}`
    }
    return (
        <Link {...otherProps}>{children}</Link>
    )

}

const mapStateToProps = (state) => ({
    referrer:state.referrer
});



export default connect(mapStateToProps, null)(ConnectedLink);

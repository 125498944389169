import React from 'react';
import BrowseByProductType from './home/BrowseByProductType';

const NotFoundStatic = (props) => {
  return (
    <>
      <div className={'page-not-found'}>
        <img style={{ 'width': '465px', 'height': '100%' }} src={'/images/404.png'} alt={'404'} title='404' />
      </div>
      <div className="home-new">
        <BrowseByProductType />
      </div>
    </>
  );
};

export default NotFoundStatic;

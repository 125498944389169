import React, {useState, useEffect} from "react";
import { capitalizeWords, getDeviceType } from "../../data/utils/helpers";
import { FallBackImage } from "../../../constants/appConfig";

export const SalientFeature = ({ salientFeatures }) => {
  const [isMobile, setIsMobile] = useState(false)

  const toggleTooltip = (e, index) => {
    e.persist();
    let tooltipParent = document.getElementById('salientFeatureList');
    let tootltip = document.getElementById(`salient-${index}`).childNodes[0];
    let toottipWrapper = document.getElementById(`salient-${index}`);

    if (toottipWrapper) {
      var rect = toottipWrapper.getBoundingClientRect();
    
      var x = rect.left  // X coordinate relative to the viewport
      var y = rect.top   // Y coordinate relative to the viewport

      if (tootltip.style.visibility === "visible") {
        tootltip.style.visibility = "hidden";
      } else {
        tootltip.style.cssText = `visibility: visible; position: fixed; left: ${x.toString()}px; top: ${(y+50).toString()}px; min-height: max-content; bottom: inherit; height: auto; text-align: left;`;
      }
    }

    // Attach click event listener to document to close the tootltip when clicked outside
    const closeTootltip = (event) => {
      if (event.target !== e.target) {
        tootltip.style.visibility = 'hidden';
        document.removeEventListener('click', closeTootltip);
      }
    };

    const hideTooltipOnScroll = () => {
      tootltip.style.visibility = 'hidden';
      window.removeEventListener('scroll', hideTooltipOnScroll);
    };

    tooltipParent.addEventListener('scroll', hideTooltipOnScroll); // Added a scroll event listener to hide the tooltip on horizontal scroll
    document.addEventListener('click', closeTootltip); // Added click event listener to document to track clicks outside the menu
    document.addEventListener('scroll', hideTooltipOnScroll); // Added a scroll event listener to hide the tooltip on vertical scroll
  };

  

  useEffect(() => {
    var isMobile = ['t','m']?.includes(getDeviceType())
    setIsMobile(isMobile)
  }, []);
  

    return (
        <div className="alientFeatures">
        <div className="slelectValheading" data-testid="salientFeatureHeading">
          Salient <span>Features</span>
        </div>
      <ul data-testid="salientFeatureList" id="salientFeatureList">
        {salientFeatures && salientFeatures?.map((feature, index) => (
          <li className="sFtooltip" key={feature?.id} id={`salient-${index}`}>
            <span className="sFtooltiptext">{capitalizeWords(feature?.description)}</span>
            <div className="outerSalientFeatures" onClick={(e) => isMobile ? toggleTooltip(e,index) : null}>
              <div className="imgSalientFeatures" data-testid="outerSalientFeatureHeading">
                {/* createOptimizedImgUrl */}
                <img src={feature?.thumbnail ? feature?.thumbnail: FallBackImage} alt={feature?.name} />
              </div>
              <div className="textSalientFeatures">{capitalizeWords(feature?.name)}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    );
  };

export default SalientFeature;
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import CustomLoader from "../../../components/library/CustomLoader";
import CategoriesTabs from "../../../components/PPC/CategoriesTabs";
import {
  getExamSubPackage,
  getPackageCategories,
} from "../../../data/ducks/header/actions";
import {
  capitalizeWords,
  isADDA,
  removeHyphensAndInsertSpaces,
} from "../../../data/utils/helpers";
import PPCWrapper from "../PPCWrapper";
import ChildPackageListings from "./ChildPackageListings";

const ExamSubPackageView = () => {
  const [showContentLoading, setShowContentLoading] = useState(true);
  let {
    packageId = "",
    examId = "",
    packageName = "",
    examName = "",
    subParentId = "",
    subParentTitle = "",
  } = useParams();
  const location = useLocation();
  const [fromOptionsPage, setFromOptionsPage] = useState(
    location?.state?.fromOptionsPage
  );
  const { pathname, search } = location;
  const { packageData } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState();
  const queryParmas = new URLSearchParams(search);
  const activeCategory = queryParmas.get("category");
  const isPaidSearchRedirect = queryParmas.get("redirectFrom") === "paidSearch";
  let { examSubPackages, categoryTabs } = packageData;
  const [isUniqueExam, setIsUniqueExam] = useState(false)

  const pkgIdAndExamId = useMemo(() => {
    let pkgId = packageId;
    let exmId = examId;
    if (subParentId) {
      // if subParentId exist than replace packageId with subParentId and examId as 0.
      pkgId = subParentId;
      exmId = 0;
    }
    return {
      pkgId,
      exmId,
    };
  }, [packageId, examId, subParentId]);

  const getPackageCategoriesData = (packageId, examId) => {
    dispatch(getPackageCategories(packageId, examId));
  };

  const getExamSubPackageData = async (packageId, examId) => {
    setShowContentLoading(true);
    await dispatch(getExamSubPackage(packageId, examId, activeTab));
    setShowContentLoading(false);
  };

  useEffect(() => {
    if (pkgIdAndExamId?.pkgId) {
      getPackageCategoriesData(pkgIdAndExamId?.pkgId, pkgIdAndExamId?.exmId);
    }
  }, [pkgIdAndExamId]);

  //call the services onChanging any of the flags data.
  useEffect(() => {
    if (activeTab && pkgIdAndExamId?.pkgId) {
      getExamSubPackageData(pkgIdAndExamId?.pkgId, pkgIdAndExamId?.exmId);
    }
  }, [activeTab, pkgIdAndExamId]);

  //sync tab state
  useEffect(() => {
    if (activeCategory || categoryTabs?.length) {
      setActiveTab(activeCategory || categoryTabs[0]?.category);
    }
  }, [activeCategory, categoryTabs]);

  useEffect(() => {
    if(isPaidSearchRedirect){
      sessionStorage?.setItem("showPaidContentSearch", "true");
    }
  },[])

  useEffect(() => {
    setIsUniqueExam(localStorage.getItem(`isUniqueExamIn${packageId}`) || false)
  },[])

  const optionsObj = {
    route: `/my-purchase/${packageId}/${packageName}/exams/${examId}/${examName}/options`,
    name: capitalizeWords(removeHyphensAndInsertSpaces(examName)),
  };
  const allBatches = {
    route: `/my-purchase/${packageId}/${packageName}/exams/${examId}/${examName}/allBatches`,
    name: capitalizeWords(removeHyphensAndInsertSpaces(examName)) + " Batches",
  };
  let breadCrumbsList = [
    {
      route: "/my-purchase",
      name: "My Purchase",
    },
    {
      route: `/my-purchase/${packageId}/${packageName}/exams`,
      name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
    },
    {
      route: pathname,
      name: capitalizeWords(
        removeHyphensAndInsertSpaces(
          subParentTitle ? subParentTitle : examName + " Courses"
        )
      ),
    },
  ];
  if (subParentId || fromOptionsPage) {
    // subParent exists
    breadCrumbsList.splice(2, 0, optionsObj);
    if (subParentId) {
      breadCrumbsList.splice(3, 0, allBatches);
    }
  }


  if(!isADDA && isUniqueExam){
    const arr = pathname?.split("/");
    arr?.splice(arr?.length-3, 3);
    const allBatchesPathname = arr?.join("/") + `/allBatches`;
    breadCrumbsList = [
      {
        route: "/my-purchase",
        name: "My Purchase",
      },
      {
        route: allBatchesPathname,
        name: capitalizeWords(removeHyphensAndInsertSpaces(packageName)),
      },
      {
        route: pathname,
        name: capitalizeWords(
          removeHyphensAndInsertSpaces(
            subParentTitle ? subParentTitle : examName + " Courses"
          )
        ),
      },
    ]
  }

  return (
    <PPCWrapper
      breadCrumbRoutes={!isPaidSearchRedirect ? breadCrumbsList : []} // hide breadCrumbs when user come from paidSearch
      pageTitle={removeHyphensAndInsertSpaces(
        subParentTitle ? subParentTitle : examName + " Courses"
      )}
      rightSection={<></>}
    >
      <CategoriesTabs
        tabs={categoryTabs}
        activeTab={activeTab}
        disableEvent={showContentLoading}
        isPaidSearchRedirect={isPaidSearchRedirect}
      />
      {showContentLoading ? (
        <CustomLoader />
      ) : (
        <>
          <ChildPackageListings
            data={examSubPackages}
            category={activeTab}
            examId={pkgIdAndExamId?.exmId}
            stateKeyName="examSubPackages" // where you store data.
            subParentTitle={subParentTitle}
            subParentId={subParentId}
            isPaidSearchRedirect={isPaidSearchRedirect}
            isUniqueExam = {isUniqueExam}
          />
        </>
      )}
    </PPCWrapper>
  );
};

export default ExamSubPackageView;
import React, { useMemo, useState } from 'react';
import CustomButton from '../CustomButton';
import OnboardingModal from './OnboardingModal';
import LeftPanelLoginModal from '../LeftPanelLoginModal';
import OnboardingOtpInput from './OnboardingOtpInput';
import useOnboardingAPI from './useOnboardingAPI';
import { copyToClipBoard, hitMoengageForOnboarding, validateInput, openEmailClient } from '../../../data/utils/helpers';
import { onBoardingStepStates, onBoardingSupportEmail } from './onboardingConstants';

const getHelpSectionScreen = ({...args}) => {

    const {
        currentStep, 
        showEmailOtp,
        otpSentFlag, 
        setOtpSentFlag,
        helpSectionErrors, 
        loading, 

        // API States
        emailVerifyDetails, 
        setEmailVerifyDetails,
        mobileUpdationDetails, 
        setMobileUpdationDetails,

        // API Functions
        handleSendEmailOTP,
        handleVerifyEmailOTP,
        handleMobileUpdateOTPSend,

    } = {...args};


    switch (currentStep) {
        case onBoardingStepStates.email_otp_send :
            return (
                <div className="updateotpouter">
                    <div className='signup-subheading'>Provide your recovery
                        <span className="otp-subheader-contact-wrapper"><span className="otp-subheader-contact"> Email ID</span></span>
                    </div>

                    <div className='updateOtpWrap'>

                        <div className='onbaorPIBox oBoption'>
                            <label className='otp-email-input-label'>Email ID</label>
                            <div className='onbaordPInputWrap'>
                                <input 
                                    className='otp-email-input-box' 
                                    placeholder='Enter email ID' 
                                    value={emailVerifyDetails?.email}
                                    onChange={(e) => {
                                    setEmailVerifyDetails({...emailVerifyDetails, email : e.target.value?.trim()})
                                    }} 
                                />
                                <span 
                                    className={`otp-email-send-btn ${(emailVerifyDetails?.email?.length < 2) ? "onboardBtnDisable" : ""}`} 
                                    onClick={() => {handleSendEmailOTP()}}>
                                        Send OTP
                                </span>
                            </div>
                            <div className="otpErrorWrap">{helpSectionErrors?.email}</div>
                        </div>
                       

                        {showEmailOtp && <OnboardingOtpInput 
                            otpDetails={emailVerifyDetails}
                            setOtpDetails={setEmailVerifyDetails}
                            resendOTP={() => {handleSendEmailOTP()}}
                            otpSentFlag={otpSentFlag}
                            setOtpSentFlag={setOtpSentFlag}
                            receiverType={"email"}
                            showOtpDurationMsg={false}
                        />}
                        <div className="otpErrorWrap">{helpSectionErrors?.otp}</div>
                        <div className="nextbtncont">
                        <CustomButton
                            type="primary"
                            className={`bluebtn ripple ${(emailVerifyDetails?.otp?.length < 6) ? "onboardBtnDisable" : ""}`}
                            buttontext={"Submit"}
                            loading={loading}
                            onClick={() => {handleVerifyEmailOTP()}}
                        />
                        </div>
                    </div>
                </div>
            );
        case onBoardingStepStates.mobile_number_change_input :
            return (
                <div className="updateotpouter">
                
                    <div className='signup-subheading'>Enter your current mobile number and your  
                        <span className="otp-subheader-contact-wrapper"><br/><span className="otp-subheader-contact"> New Mobile Number</span></span>
                    </div>


                    <div className='update-mobile-input-wrapper'>
                        <div className='update-mobile-input-box-wrap'>
                            <div className='onbaorPIBox'>
                                <label className='update-mobile-input-label'>Current Mobile Number</label>
                                <input 
                                    className='update-mobile-input-box' 
                                    placeholder='Enter Mobile Number' 
                                    onChange={(e) => 
                                        setMobileUpdationDetails({...mobileUpdationDetails, oldPhoneNumber : e.target.value?.trim()?.substring(0, 10)})
                                    } 
                                    value={mobileUpdationDetails?.oldPhoneNumber}
                                />
                                <div className="otpErrorWrap">{helpSectionErrors?.phone?.[0]}</div>
                            </div>
                            <div className='onbaorPIBox'>
                                <label className='update-mobile-input-label'>New Mobile Number</label>
                                <input 
                                    className='update-mobile-input-box' 
                                    placeholder='Enter Mobile Number' 
                                    onChange={(e) => 
                                        setMobileUpdationDetails({...mobileUpdationDetails, newPhoneNumber : e.target.value?.trim()?.substring(0, 10)})
                                    } 
                                    value={mobileUpdationDetails?.newPhoneNumber}
                                />
                                <div className="otpErrorWrap">{helpSectionErrors?.phone?.[1]}</div>
                            </div>
                        </div>
                        <div className="nextbtncont">
                        <CustomButton
                            type="primary"
                            className={`bluebtn ripple ${(mobileUpdationDetails?.oldPhoneNumber?.length < 10 || mobileUpdationDetails?.newPhoneNumber?.length < 10) ? "onboardBtnDisable" : ""}`}
                            buttontext={"Submit"}
                            loading={loading}
                            onClick={() => {handleMobileUpdateOTPSend(mobileUpdationDetails, true)}}
                        />
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

const OnboardingHelpSection = (props) => {

    const {updateMobileSendOTP, updateMobileVerifyOTP, sendEmailOTP, verifyEmailOTP, verifyMobileOTP} = useOnboardingAPI();

    const [hideForm, setHideForm] = useState(true);
    
    const [currentStep, setCurrentStep] = useState(onBoardingStepStates.email_otp_send);
    const [showEmailOtp, setShowEmailOtp] = useState(false);

    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [currentModalState, setCurrentModalState] = useState(onBoardingStepStates.mobile_otp_send);

    const [helpSectionErrors, setHelpSectionErrors] = useState({
        email : [],
        password : [],
        phone : [],
        otp : [],
    });

    const [loading, setLoading] = useState(false);

    // API States
    const [emailVerifyDetails, setEmailVerifyDetails] = useState({
        email : "",
        otp : "",
        userId : "",
    });

    const [mobileUpdationDetails, setMobileUpdationDetails] = useState({
        userId : "",
        oldPhoneNumber : "",
        newPhoneNumber : "",
        otp : "",
      });

    const [modalButtonAction, setModalButtonAction] = useState(() => {});
    const [otpSentFlag, setOtpSentFlag] = useState(false);

    const [selectedChange] = useState("Phone Number");

    const handlePasswordChangeModal = (nextStep, hideForm) => {
        setCurrentStep(nextStep);
        setHideForm(hideForm);
        closeOnboardingModal();
    }

    const handleSendEmailOTP = async () => {
        setLoading(true);
        let inputFields = {
            email : [emailVerifyDetails?.email],
        }
        const errors = validateInput(inputFields);
        if (errors?.email?.some(error => error)) { 
            setHelpSectionErrors({...helpSectionErrors, email : errors?.email});
            setLoading(false);
            return;
        }
        else {
            setHelpSectionErrors({...helpSectionErrors, email : []});
            const response = await sendEmailOTP(emailVerifyDetails?.email, "", true);
            if (response?.success) {
                setOtpSentFlag(true);
                setEmailVerifyDetails({...emailVerifyDetails, userId : response?.data?.id});
                setMobileUpdationDetails({...mobileUpdationDetails, userId : response?.data?.id});
                setShowEmailOtp(true);
            }
            else {
                setHelpSectionErrors({...helpSectionErrors, email : [response?.message]});
            }
            setLoading(false);
        }
    }

    const handleVerifyEmailOTP = async () => {
        setLoading(true);
        let inputFields = {
            otp : [emailVerifyDetails?.otp],
        }
        const errors = validateInput(inputFields);
        if (errors?.otp?.some(error => error)) { 
            setHelpSectionErrors({...helpSectionErrors, otp : errors?.otp});
            setLoading(false);
            return;
        }
        else {
            setHelpSectionErrors({...helpSectionErrors, otp : []});
            const response = await verifyEmailOTP(emailVerifyDetails, true);
            if (response?.success) {
                setCurrentStep(onBoardingStepStates.mobile_number_change_input);
            }
            else {
                setHelpSectionErrors({...helpSectionErrors, otp : [response?.message]});
            }
            setLoading(false);
        }
    }

    const handleMobileUpdateOTPSend = async (payload, helpDeskFlag) => {
        setLoading(true);
        let inputFields = {
            phone : [payload?.oldPhoneNumber, payload?.newPhoneNumber],
        }
        const errors = validateInput(inputFields);
        if (errors?.phone?.some(error => error)) { 
            setHelpSectionErrors({...helpSectionErrors, phone : errors?.phone});
            setLoading(false);
            return;
        }
        else {
            setHelpSectionErrors({...helpSectionErrors, phone : []});
            const response = await updateMobileSendOTP(payload, helpDeskFlag);
            if (response?.success) {
                setOtpSentFlag(true);
                setModalButtonAction(getModalAction(onBoardingStepStates.mobile_otp_send));
                setShowOnboardingModal(true);
            }
            else {
                setHelpSectionErrors({...helpSectionErrors, phone : ["", response?.message]});
            }
            setLoading(false);
        }
    }

    const getModalAction = (modalCurrentState) => {
        if(modalCurrentState === onBoardingStepStates.mobile_otp_send) {
            setCurrentModalState(onBoardingStepStates.mobile_otp_send);
            return () => async (payload) => {
                const response = await updateMobileVerifyOTP(payload, true);
                if (response?.success) {
                    setCurrentModalState(onBoardingStepStates.change_success);
                    setShowOnboardingModal(true);
                    setModalButtonAction(getModalAction(onBoardingStepStates.change_success));
                    hitMoengageForOnboarding("Help Screen", "Mobile_Updated", {platform : "web"});
                    closeOnboardingModal();
                }
                else {
                    console.error("email otp --> ", response?.message);
                    setShowOnboardingModal(true);
                    setCurrentModalState(onBoardingStepStates.mobile_otp_send);
                }
            }
        }
        else if(modalCurrentState === onBoardingStepStates.change_success) {
            return () => handlePasswordChangeModal(onBoardingStepStates.email_otp_send, true);
        }
        else return closeOnboardingModal
    }

    const handleSupportClick = () => {
        copyToClipBoard(onBoardingSupportEmail);
        openEmailClient(onBoardingSupportEmail);
    }

    const helpSectionScreen = useMemo(
        () => getHelpSectionScreen({
            currentStep, 
            showEmailOtp,
            otpSentFlag, 
            setOtpSentFlag,
            helpSectionErrors, 
            loading, 

            // API States
            emailVerifyDetails, 
            setEmailVerifyDetails,
            mobileUpdationDetails, 
            setMobileUpdationDetails,

            // API Functions
            handleSendEmailOTP,
            handleVerifyEmailOTP,
            handleMobileUpdateOTPSend,
        }), 
    [
        currentStep, 
        showEmailOtp,
        emailVerifyDetails, 
        mobileUpdationDetails, 
        modalButtonAction,
        otpSentFlag, 
        selectedChange,
        helpSectionErrors, 
        loading, 
    ]);

    const closeOnboardingModal = () => {
        setShowOnboardingModal(false);
    };

    return (
        <div className="pd100 onBaord-help-wrap">
            {hideForm ? <div className='help-section wrapper'>
                <div className='help-section-header'>Help Section</div>
                <div className='help-section-btn-wrapper'>
                    <CustomButton
                        type="primary"
                        className={"bluebtn ripple"}
                        buttontext={
                        <span className="help-section primary-btn-wrap">
                            <span className="help-section-primary-btn-label">Update Phone Number</span>
                        </span>
                        }
                        loading={false}
                        onClick={() => {setHideForm(false)}}
                    />
                    <CustomButton
                        type="secondary"
                        className={"whiteBgbtn ripple"}
                        buttontext={
                        <span className="help-section-second-btn-wrap">
                            <span className="help-section-second-btn-label" onClick={() => {handleSupportClick()}}>
                                Contact Support
                            </span>
                        </span>
                        }
                        loading={false}
                        onClick={() => {}}
                    />
                </div>
            </div> : ( 
            <div className='onBoardInnerUpdation'>
                <LeftPanelLoginModal />
               
               <div className="updation-cotent-wrap">
                            <div className="signup-heading">Update your phone number</div>
                           {currentStep && helpSectionScreen}
                        </div>
            </div>
        )}
        <OnboardingModal 
            showModal={showOnboardingModal} 
            closeModal={closeOnboardingModal}
            currentStep={currentModalState}
            setCurrentStep={setCurrentModalState}
            otpDetails={mobileUpdationDetails}
            setOtpDetails={setMobileUpdationDetails}
            modalButtonAction={modalButtonAction}
            otpSentFlag={otpSentFlag} 
            setOtpSentFlag={setOtpSentFlag}
            selectedChange={selectedChange}
            showEditIcon={false}
            resendOTP={() => {handleMobileUpdateOTPSend(mobileUpdationDetails, true)}}
        />
    </div>
    );
};

export default OnboardingHelpSection;
import React, { useState } from "react";
import CustomModal from "./CustomModal";

const CookieEnableModal = () => {
  const [show] = useState(
    typeof window !== undefined && window.navigator.cookieEnabled
  );
  return (
    <CustomModal
      footer={null}
      visible={!show}
      hideHeader={true}
      closable={true}
      maskClosable={false}
      keyboard={false}
      modalClassName="error401"
    >
      <div>
        <h3 style={{color:"#000"}}>Enable Cookies</h3>
        <p>
          Please allow cookies for our site to play the video / recorded
          session.
        </p>
      </div>
    </CustomModal>
  );
};

export default CookieEnableModal;

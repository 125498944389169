import React, { useMemo } from "react";
import CustomModal from "../../../components/library/CustomModal";
import { isEmpty } from "../../../data/utils/helpers";
import { facetTypeKeyName } from "../constants";
import FacetsItemRenderer from "./FacetsItemRenderer";

const MwebFilter = ({
  visible = false,
  activeTab,
  setActiveTab,
  loading = false,
  tabFacetsMap = {},
  facetItems = [],
  selectedFiltersFacets = {},
  onChangeFacetHandlerCb = () => null,
  onCancel = () => null,
  applyFilterCb = () => null,
  clearFiltersCb = () => null,
}) => {
  const isTabChangeDisable = useMemo(
    () => !!Object.values(selectedFiltersFacets)?.filter((v) => v)?.length,
    [selectedFiltersFacets]
  );
  return (
    <CustomModal
      visible={visible}
      footer={null}
      centered
      mask={false}
      wrapClassName={"width100vw"}
      title={
        <div className={"modal-header-filter"}>
          <div className={"modal-filtermobile"}>Filters</div>
        </div>
      }
      closeCallback={onCancel}
      bodyStyle={{ padding: "0", margin: "0" }}
      closeIcon={
        <img
          onClick={onCancel}
          alt={"adda247"}
          src={"/images/close-icon.svg"}
          title="adda247"
          loading="lazy"
          height={14}
          width={14}
        />
      }
    >
      <div className={"mobile-filter-main-block"}>
        <div className={"mobile-exam-category"}>
          {tabFacetsMap && !isEmpty(tabFacetsMap) && (
            <div>
              {Object.keys(tabFacetsMap)?.map((item, i) => {
                return (
                  <div
                    key={`facet_tab_${i}`}
                    onClick={() => {
                      if (!isTabChangeDisable) setActiveTab(item);
                    }}
                    className={
                      activeTab == item
                        ? "active-category-listing"
                        : "category-listing"
                    }
                  >
                    <div className="facet_label">{item}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={"checkbox-container-mobile"}>
          <FacetsItemRenderer
            facetItems={facetItems}
            facetType={facetTypeKeyName[tabFacetsMap[activeTab]]}
            isSearchEnabled={false}
            selectedFiltersFacets={selectedFiltersFacets}
            onChangeFacetHandlerCb={onChangeFacetHandlerCb}
          />
        </div>
        <div className={"filter-footer"}>
          <div className={`reset `} onClick={clearFiltersCb}>
            RESET
          </div>
          <div
            className={`apply`}
            onClick={() => {
              applyFilterCb(selectedFiltersFacets);
            }}
          >
            {loading ? "APPLYING..." : "APPLY"}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default MwebFilter;

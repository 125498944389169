import React, { useState, useEffect } from "react";
import CustomModal from "./library/CustomModal";
import ApplyCoupon, { AppliedCouponDetails } from "./ApplyCoupon";

import fetch from "../data/utils/fetch";
import { pdpUrls } from "../../constants/appConfig";
import CustomButton from "./library/CustomButton";

const ApplyCouponModal = ({
  visible,
  onCancel,
  packageId,
  packageType,
  defaultPriceInfo,
  fromapp,
  priceDetails,
  updatePriceDetails,
  productData,
  availableCouponList,
  cartPayload,
  loggedUserDetails,
  userEmail,
  setUserEmail,
  getUtmFromStore,
  Getpayload,
  setViewOffer,
}) => {
  useEffect(() => {
    setViewOffer(false);
  });

  return (
    <>
      <CustomModal
        fromapp={fromapp}
        modalClassName={"modalZindex"}
        footer={null}
        visible={visible}
        title={<div className={"modal-header"}>Available Offers</div>}
        onCancel={onCancel}
        closeCallback={onCancel}
        closable={true}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ overflowY: "auto" }}
        dataTestid={"apply-coupon-modal"}
      >
        <ApplyCoupon
          packageId={packageId}
          packageType={packageType}
          priceDetails={priceDetails}
          updatePriceDetails={updatePriceDetails}
          productData={productData}
          availableCouponList={availableCouponList}
          cartPayload={cartPayload}
          loggedUserDetails={loggedUserDetails}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
          close={onCancel}
          Getpayload={Getpayload}
          getUtmFromStore={getUtmFromStore}
        ></ApplyCoupon>
      </CustomModal>
    </>
  );
};
export default ApplyCouponModal;

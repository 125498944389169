import React from 'react';
import { prodUrl } from "../../../constants/appConfig";
const BreadcrumbSchema = ({ items }) => {
  let schemaString = [];
  if (items && items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      schemaString.push(`{"@type": "ListItem", "position": ${i + 1}, "name": "${items[i].title?.split("\"")?.join("")}", "item": "${items[i].url ? prodUrl + items[i].url : prodUrl}"}`);
    }
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
                {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                    ${schemaString.join(",")}
                ]
              }
            ` }}
    />
  );
};

export default BreadcrumbSchema;
import React, { useState, useEffect ,useRef} from "react";
import CourseCard from "./CourseCard";
import AButton from "../library/AButton";
import CustomLoader from "../library/CustomLoader";
import NoResultsFound from "../library/NoResultsFound";
import { timestampToDateTimedaysecond } from "../utils";
import { openTestPortal } from "../enrolledCourses/TestSeries/TakeTest/util";
import { getLiveTestDateTimeObj, getLiveTestRemainingTime, getLiveTestState, useLiveTestAction } from "../enrolledCourses/TestSeries/TestResultAnalysis/Components/liveTestUtils";
import { BLANK_IDENTIFIER, ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "../MoengageConstants";
import { getPdpUrl } from "../../data/utils/helpers";

const TestSeriesSection = ({ data, getData, searchText,packageId ,setsuggestionData,setinternalTab,setSearchText,setInputText,loading,setGroupFilter,moengageEventSectionTabChange,moengageEventforstatus,liveTest=false}) => {
  const focus = useRef();
  const testPortalOpened= useRef();
  const [displayData, setDisplayData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [ddFilter, setDdFilter] = useState("");
  const [ddOptions, setDdOptions] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [liveTestFlag, setLiveTestFlag] = useState(liveTest || false);
  const [liveTestStates, setLiveTestStates] = useState([]);
  const [liveTestRemainingTime, setLiveTestRemainingTime] = useState([]);
  const [filters, setFilters] = useState([
    { id: "", label: "All Tests" },
    { id: "Incomplete", label: "Incomplete" },
    { id: "Not Attempted", label: "Not Attempted" },
    { id: "Attempted", label: "Attempted" },
  ]);
  const [showLoader, setShowLoader] = useState(false);

  const [handleLiveTestAction] = useLiveTestAction(testPortalOpened);

  useEffect(() => {
    if (!data) {
      getData();
      testPortalOpened.current=false
    }
    window.addEventListener("focus", ()=>{
      focus.current="focus";
      if(testPortalOpened.current)
      {
        getData();
      }
         
    });
  }, []);
  
  useEffect(() => {
    if (data && data.length) {
      let dataObj = {};
      data.forEach((item) => {
        if (!dataObj[item.groupName]) {
          dataObj[item.groupName] = {
            id: item.groupName,
            label: item.groupName,
          };
        }
      });
      setDdOptions([{ id: "", label: "All" }, ...Object.values(dataObj)]);
      if(!liveTestFlag && liveTest) {
        setLiveTestFlag(liveTest);
        if(!filters.includes(liveTestFilters[0])) setFilters([...filters, ...liveTestFilters])
      }
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime(Date.now());
    }, [1000]);

    return () => { clearInterval(interval) }
  },[]); 

  useEffect(() => {
    if(liveTestFlag) {
      let statesArr = [];
      let remaingTimeArr = [];
      displayData?.forEach((testData) => {
        let liveTestStateObj = getLiveTestState(testData)
        statesArr.push(liveTestStateObj);
        remaingTimeArr.push(getLiveTestRemainingTime(Math.min(Date.now(), testData?.testData?.[0]?.fixedMockSubmitTime), testData?.testData?.[0]?.fixedMockResultTime));
      });
      setLiveTestStates(statesArr);
      setLiveTestRemainingTime(remaingTimeArr);
    }
  },[liveTestFlag, currentTime]); 
  

  const liveTestFilters = [
    { id: 2, label: "Full Length Tests" },
    { id: 1, label: "Sectional Tests" },
  ];

  useEffect(() => {
    if (!searchText) {
      if(typeof selectedFilter === 'string') getDisplayData(data);
      else getliveTestFilteredData(data, selectedFilter);
    }
  }, [data, searchText, selectedFilter]);

  useEffect(() => {
    setShowLoader(true);
    if (ddFilter) {
      let filteredDisplayData = data?.filter((item) => {
        return ddFilter == item.groupName;
      });
      setDisplayData(filteredDisplayData);
    }
    else {
      getDisplayData(data);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, [800]);
  }, [ddFilter]);

  useEffect(() => {

    if (searchText) {
      let filteredDisplayData = displayData?.filter((item) => {
        let title = (item.testData && item.testData[0]) ? item.testData[0].title.toLowerCase() : '';
        return title.indexOf(searchText.toLowerCase()) > -1;
      });
      setDisplayData(filteredDisplayData);
    }
  }, [searchText]);

  const getDisplayData = (data) => {
    setShowLoader(true);
    let filteredDisplayData = data?.filter((item) => {
      return (
          !selectedFilter ||
          item.status == selectedFilter
        );
      }); 

    setDisplayData(filteredDisplayData);
    setTimeout(() => {
      setShowLoader(false);
    }, [800]);
  } 

  const getliveTestFilteredData = (data, selectedFilter) => {
    
    setShowLoader(true);
    let filteredDisplayData = data?.filter((item) => {
      return (
          !selectedFilter ||
          item?.testData?.[0]?.testCategory == selectedFilter
        );
      }); 

    setDisplayData(filteredDisplayData);
    setTimeout(() => {
      setShowLoader(false);
    }, [800]);
  } 


  const getTestInfo = (details, hideDifficulty=false) => {
    if (details.status == "Coming Soon") {
      if (!details.testData[0].commingSoonDate) return '';
      let timeArray = timestampToDateTimedaysecond(details.testData[0].commingSoonDate).split(' ');
      return `Mock Live On ${timeArray[2]} ${timeArray[1]} ${timeArray[3]}`
    }
    let difficulty = details.level == "UNRATED" ? "MODERATE" : details.level;
    let ques = details?.testData  && details?.testData?.[0]?.noOfQuestions;
    let time = details?.testData && details?.testData?.[0]?.timeLimit / 60;

    if(hideDifficulty) return `${ques} Questions | ${time} min`;

    return `${ques} Questions | ${time} min | ${difficulty} `;
  };

  const handleFilterApply = (filterId) => {
    moengageEventSectionTabChange(filterId);
    setSelectedFilter(filterId);
    setinternalTab(filterId);
    setSearchText("");
    setInputText("");
    setsuggestionData("");
  }

  return (
    <>
    {!data ? <CustomLoader /> : data?.length === 0 ? 
      <div className="course-content">
        <NoResultsFound image={"/images/coming_soon.svg"}
              message={'Sorry to keep you waiting...'}
              message2={"We are in the process of setting up the content for you. Please check again after some time"}/>
      </div> : ""}
    {data && data?.length ? <div className="course-content">
      <div className="course-content-filters">
        <div>
          {filters?.map((filter) => {
            return (
              <AButton
              
                text={filter.label}
                onClick={() => {
                  handleFilterApply(filter?.id);
                }}
                contained
                color={filter.id === selectedFilter ? undefined : "grey"}
              />
            );
          })}
        </div>

        <div className="cc-filtet-select">
          <div>
            {ddOptions && (
              <select
                // id="test-status"
                className="test-filter-select"
                value={ddFilter}
                onChange={(e) => {
                  setDdFilter(e.target.value);
                  setGroupFilter(e.target.value)
                }}
              >
                {ddOptions.map((values) => {
                  return <option value={values.id}>{values.label}</option>;
                })}
              </select>
            )}
          </div>
        </div>
      </div>
      {(displayData?.length && !showLoader) ? (
        <div className="course-items">
          {displayData?.map((testSeriesData, index) => {
            let testData = testSeriesData?.testData
              ? testSeriesData?.testData?.[0]
              : {};

            let liveTest = testData?.liveTest || false;

            let actionText = "RESUME";
            let cardClick;

            let liveTestStatusObj = {buttonText : "Coming Soon"};
            let liveTestRemTime = liveTestRemainingTime[index] || "";
            let liveTestSchedule = getLiveTestDateTimeObj(testData?.fixedMockTestStartTime, testData?.fixedMockSubmitTime, testData?.attemptBeforeTime) || "";
            let liveTestResultTime = testData?.fixedMockResultTime;
            let source_screen = "ppc_test_listing";
            let event_name = "";
            let moepayload = {};

            if (liveTest) {
              liveTestStatusObj = liveTestStates[index];

              if(liveTestStatusObj?.status) {
                switch (liveTestStatusObj?.status) {
                  case "Start":
                    event_name = "starttest_listing_clicked";
                    break;
                  case "Resume":
                    event_name = "resumetest_listing_clicked";
                    break;
                  case "Result Published":
                    event_name = "showresult_listing_clicked";
                    break;
                  default:
                    event_name = "";
                    break;
                }
              }

              //Moengage event
              var Parenttitle = testData?.title;

              moepayload = {
                package_status: packageId == 557 ? "free" : "paid",
                name: Parenttitle,
                TestId: testData?.mappingId,
                test_id: testData?.mappingId,
                content_title: testData?.title,
                contentID: testData?.mappingId,
                id: testData?.mappingId,
                subPackageType: "TEST_SERIES",
                category: "TEST_SERIES",
                action:
                  testData?.status == "INCOMPLETE"
                    ? "resume_test"
                    : testData?.status == "COMPLETED"
                    ? "view_results"
                    : "start_test",
                content_url: getPdpUrl(
                  "TEST_SERIES",
                  testData?.mappingId,
                  Parenttitle
                ),
                content_subject: BLANK_IDENTIFIER,
                exam_category: WEB_IDENTIFIER,
                user_exam_category_selected: WEB_IDENTIFIER,
                deep_link: BLANK_IDENTIFIER,
                rating: BLANK_IDENTIFIER,
                index: BLANK_IDENTIFIER,
                package_purchased: BLANK_IDENTIFIER,
                exam: BLANK_IDENTIFIER,
                language: ENGLISH_IDENTIFIER,
                content_language: ENGLISH_IDENTIFIER,
                test_type: "live",
              };

              actionText = liveTestStatusObj?.buttonText?.toUpperCase();

              cardClick = (reattemptFlag = null) => {
                handleLiveTestAction({testData : testSeriesData, quizStatus : liveTestStatusObj?.buttonText || "Coming Soon", reattempt : reattemptFlag, event_name : reattemptFlag ? "reattempttest_listing_clicked" : event_name, source_screen : source_screen, moepayload : moepayload})
              }
            } else {

              if (testSeriesData.status === "Not Attempted") {
                actionText = "START TEST";
              } else if (testSeriesData.status === "Coming Soon") {
                actionText = "COMING SOON";
              } else if (testSeriesData.status === "Attempted") {
                actionText = "RESULT";
              }

              if(testSeriesData.status !== "Coming Soon"){
                let status = (testSeriesData.status === 'Incomplete') ? 'INCOMPLETE':(testSeriesData.status === "Attempted" ? "COMPLETED" : "")
                cardClick = ()=>{
                  testPortalOpened.current=true
                  openTestPortal(testData.mappingId,packageId,testData?.title,status,null,testSeriesData?.testData?.[0]?.examList, "", false, true);
                }
              }

            }

            return (
              <CourseCard
                title={testData.title}
                actionButton={<AButton text={actionText} onClick={()=>{
                  moengageEventforstatus(actionText)
                  cardClick()
                } } loading={loading}></AButton>}
                info={getTestInfo(testSeriesData, true)}
                liveTest={testData?.liveTest}
                liveTestStatusObj={liveTestStatusObj}
                liveTestSchedule={liveTestSchedule}
                liveTestRemainingTime={liveTestRemTime}
                liveTestResultTime={liveTestResultTime}
                testCategory={testData?.testCategory || 2}
                liveTestCutoff={testData?.cutOffMarks || 0}
                liveTestPackage={liveTestFlag || false}
                reattemptBtn={<AButton text={"REATTEMPT"} onClick={()=>{
                  moengageEventforstatus(actionText)
                  cardClick("REATTEMPT")
                } } loading={loading}></AButton>}
              />
            );
          })}
        </div>
      ) : (showLoader ? <CustomLoader />
        : data && !displayData?.length ? (
            <div className="course-items">
              <NoResultsFound   />
            </div>
          ) : ""
        )}
    </div> : ""}
  </>
  );
};

export default TestSeriesSection;

import React from "react";
import TrendingSuggestions from "./TrendingSuggestions";

const TrendingSearch = ({ onClick }) => {
  return (
    <div className="left-side-trending">
      <div className="full-width-title">trending searches</div>
      <TrendingSuggestions onClick={onClick} />
    </div>
  );
};

export default TrendingSearch;

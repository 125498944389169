import React from 'react';
import { Link } from 'react-router-dom';
import { removeHyphensAndInsertSpaces, removeSpacesAndInsertHyphens,trackMoengageEvent } from '../../../data/utils/helpers';

export const SeoSectionMobile = (props) => {
    
    return (
        <div className="seo-block-con">

            { props.data && props.data.length >= 1 && !props.courseType ? props.data.map(value => {
                if (props.examCat && (value.name.toLowerCase() != props.examCat.toLowerCase()) || props.isExamtypes) {
                    
                    return (
                        <Link onClick={(e) => trackEvent(e)} className="list"
                            to={props.isRelatedCategory ? `/${value.name && value.name.toLowerCase() == "teaching" ? "ctet" : value.name && value.name.toLowerCase()}-study-materials` : props.currentexamName ? `/${props.currentexamName}/${value.url && removeSpacesAndInsertHyphens( value.url.toLowerCase())}-kit`:`/${removeSpacesAndInsertHyphens(value.name && value.name.toLowerCase())}-exam-kit`}>
                          {  props.currentexamName ? <span >{`${removeHyphensAndInsertSpaces(props.currentexamName).toUpperCase()} ${value.name}`}</span>: <span >{`${value.name} ${ props.SuffixValue && removeHyphensAndInsertSpaces(props.SuffixValue)}`}</span>}

                        </Link>
                    )
                }
            })
            :
              props.data && props.data.map(value=>{
                if (  value.name.toLowerCase() != props.courseType || props.isExamtypes) {
                return(
                    <Link onClick={(e) => trackEvent(e)} className="list"
                            to={props.productName ? `/${removeSpacesAndInsertHyphens(value.name.toLowerCase())}/${props.productName}`:`/${value.name.toLowerCase()=="teaching" ?"ctet" :value.name.toLowerCase()}/${props.productType}-study-kit`}>
                            <span >{`${value.name} ${ props.SuffixValue && removeHyphensAndInsertSpaces( props.SuffixValue )}`}</span>

                        </Link>

                )
                }
            })
            
            }
        </div>
    )

}

const trackEvent = (e) => {
    let key = e.target.innerHTML;
    let payload = {};
    payload.Link_text = key;
    payload.Link_URL = e.target.getAttribute('href');
    payload.Link_type = "SEO link - Related products";
    trackMoengageEvent("SEO_Link_Clicked",payload)
}
export const SeoSectionWeb = ({courseType, data,productName, examCat, isProductListing, SuffixValue, isRelatedCategory, heading,isExamtypes,productType,currentexamName }) => {
    return (
        <div className="related-category-container">
             {
              data?.length && (examCat || isExamtypes || courseType) && <div className="seo-exam-category test">{heading || (isProductListing ? " Exam Category" : "Related Category")}</div>
            }
            { !courseType ? data.map(value => {
                 if (examCat && (value.name.toLowerCase() !=examCat.toLowerCase()) || isExamtypes) {
                    return (
                        <Link onClick={(e) => trackEvent(e)} className="related-link"
                        to={isRelatedCategory ? `/${value.name.toLowerCase() == "teaching" ? "ctet" : value.name.toLowerCase()}-study-materials` : currentexamName ? `/${currentexamName}/${removeSpacesAndInsertHyphens(value.url.toLowerCase())}-kit`:`/${removeSpacesAndInsertHyphens(value.name.toLowerCase())}-exam-kit`}>
                        { currentexamName ?  `${removeHyphensAndInsertSpaces(currentexamName).toUpperCase()} ${value.name}`:  `${value.name} ${SuffixValue}`}
                        </Link>
                    )
                }

            })
        :
        data && data.map(value=>{
            if (courseType && value.name.toLowerCase() != courseType || isExamtypes) {
            return(
                <Link onClick={(e) => trackEvent(e)} className="related-link"
                to={productName ? `/${removeSpacesAndInsertHyphens(value.name.toLowerCase())}/${productName}`:`/${value.name.toLowerCase()=="teaching" ?"ctet" :value.name.toLowerCase()}/${productType}-study-kit`}>
                    {`${value.name} ${removeHyphensAndInsertSpaces( SuffixValue)}`} 

                    </Link>

            )
            }
        })  }
            
        </div>
    )
}




import reducerRegistry from "../../reducerRegistry";
import types from "./types";

const initialState = {
  toasts: [],
  toolTipButtonInfo: {
    isToolTipVisible: false,
    toolTipName: "TOOLTIP",
    tooltipText: "success",
    duration: 1500,
  },
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case types.REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload),
      };
    case types.SHOW_BUTTON_TOOLTIP:
      return {
        ...state,
        toolTipButtonInfo: { ...state.toolTipButtonInfo, ...action.payload },
      };
    default:
      return state;
  }
};

reducerRegistry.register("toast", toastReducer);
export default toastReducer;
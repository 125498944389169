import types from "./types";
import {
  stagingSearchForSuggestionUrl,
  storeHostUrl,
  userApi,
  STORE_FRONT_API_URI,
  searchUrlEndPoint,
  searchSuggestionUrlV6,
  myPurchase,
  pdpUrls,
} from "../../../../constants/appConfig";
import {
  GLOBAL_EXAMS_API,
  STOREFRONT_URI,
  TEST_SERIES_URL
} from "../../../../constants/appUrls";
import fetch, { requestHeaders } from "../../utils/fetch";
import { isExactStringExistInArray } from "../../utils/helpers";
import { isADDA } from "../../utils/helpers";

const userLoginAndRegister = (userDetailObj, extraParams) => {
  return {
    CALL_API: [
      {
        type: extraParams.isLogin
          ? types.INITIATE_LOGIN
          : types.INITIATE_SIGNUP,
        meta: {
          path: extraParams.path,
          method: "POST",
          body: userDetailObj,
        },
      },
    ],
  };
};

const loginFromCookie = (
  token,
  name,
  email,
  id,
  phone,
  selectedCourse,
  jwtTokenNew,
  cartCount
) => {
  const jwtToken = token;
  let userInfo = {
    email,
    id,
    name,
    selectedCourse,
    phone,
  };
  return {
    type: types.INITIATE_LOGIN,
    payload: {
      jwtToken,
      userInfo,
      cartCount,
      jwtTokenNew,
    },
    method: "LOCAL",
  };
};

const updateUserDetails = (userInfo = {}) => {
  return {
    type: types.UPDATE_USER_DETAILS,
    payload: {...userInfo},
    method: "LOCAL",
  };
}

const checkIfEmailRegistered = (email) => {
  return {
    CALL_API: [
      {
        type: types.VERIFY_EMAIL_REGISTERED,
        meta: {
          path: `${userApi}/forgotPassword?emailId=${email}`,
          method: "GET",
        },
      },
    ],
  };
};

const verifyOTP = (obj) => {
  return {
    CALL_API: [
      {
        type: types.VERIFY_OTP,
        meta: {
          path: `${userApi}/verifyOTP`,
          method: "POST",
          body: obj,
        },
      },
    ],
  };
};

const logoutUser = () => {
  return {
    type: types.LOGOUT_USER,
    meta: {
      response: true,
      method: "LOCAL",
    },
  };
};

const startLoader = () => {
  return {
    type: types.SHOW_LOADER,
    meta: {
      response: true,
      method: "LOCAL",
    },
  };
};
const loginFlag = (flag) => {
  return {
    type: types.LOGIN_FLAG,
    payload: flag,
  };
};

const stopLoader = () => {
  return {
    type: types.HIDE_LOADER,
    meta: {
      response: false,
      method: "LOCAL",
    },
  };
};

const getAutoSuggestion = (searchTerm, dropDownValue = "ALL") => {
  let path = `${stagingSearchForSuggestionUrl}?searchTerm=${searchTerm}&pageNo=1&pageSize=20&primaryFilter=true`;
  if (dropDownValue !== "ALL") {
    path = `${stagingSearchForSuggestionUrl}?searchTerm=${searchTerm}&pageNo=1&pageSize=20&categoryFacets=${dropDownValue}&primaryFilter=true`;
  }
  return {
    CALL_API: [
      {
        type: types.FETCH_AUTO_SUGGESTION,
        meta: {
          path,
          method: "GET",
          searchKey: searchTerm,
          dropDownValue,
        },
      },
    ],
  };
};
const getSearchSuggestions = (
  keyWord,
  activeTab = "",
  isKeywordChanged = false
) => {
  let path = "";
  path =
    activeTab === "ALL"
      ? `${searchSuggestionUrlV6}?searchTerm=${keyWord}&primaryFacetsRequired=${isKeywordChanged}&pageSize=12${isADDA ? "&block3DModels=true" : ""}`
      : `${searchSuggestionUrlV6}?searchTerm=${keyWord}&categoryFacets=${activeTab}&primaryFacetsRequired=${isKeywordChanged}&pageSize=12${isADDA ? "&block3DModels=true" : ""}`;
  return {
    CALL_API: [
      {
        type: types.FETCH_SEARCH_SUGGESTIONS,
        meta: {
          path,
          method: "GET",
          searchKey: keyWord,
          activeTab,
          isKeywordChanged: isKeywordChanged,
        },
      },
    ],
  };
};
const clearSearchSuggestions = () => {
  return {
    type: types.CLEAR_SEARCH_SUGGESTIONS,
    meta: {
      response: true,
      method: "LOCAL",
    },
  };
};

// PPC SERVICES
const getMyPurchaseListings = () => {
  return {
    type: types.GET_MY_PURCHASE_LISTINGS,
    CALL_API: [
      {
        type: types.GET_MY_PURCHASE_LISTINGS,
        meta: {
          path: `${myPurchase.purchasedpackages}?pageNumber=0&pageSize=10`,
          method: "GET",
        },
      },
    ],
  };
};
const getNextMyPurchaseListings = (pageNo, pageSize = 10) => {
  return {
    type: types.GET_NEXT_MY_PURCHASE_LISTINGS,
    CALL_API: [
      {
        type: types.GET_NEXT_MY_PURCHASE_LISTINGS,
        meta: {
          path: `${myPurchase.purchasedpackages}?pageNumber=${pageNo}&pageSize=${pageSize}`,
          method: "GET",
        },
      },
    ],
  };
};
const getPackageCategories = (packageId, examId) => {
  return {
    type: types.GET_PACKAGE_CATEGORIES,
    CALL_API: [
      {
        type: types.GET_PACKAGE_CATEGORIES,
        meta: {
          path: `${myPurchase.packageData}?packageId=${packageId}${examId ? `&exams=${examId}` : ""
            }&pageNumber=0&isComingSoon=false&pageSize=0`,
          method: "GET",
        },
      },
    ],
  };
};
const getPackageCategoriesAndExams = async (packageId, examId) => {
  let options = {
    method: "GET",
    headers: requestHeaders(),
  };
  let categoryUrl = `${myPurchase.packageData}?packageId=${packageId}${examId ? `&exams=${examId}` : ""
    }&pageNumber=0&isComingSoon=false&pageSize=0`;
  let selectedExamsUrl = `${myPurchase.selectedPackageExams}?packageId=${packageId}`;

  const categories = fetch(categoryUrl);
  const selectedExams = fetch(selectedExamsUrl);
  const TYPE_ARRAY = {
    0: "categories",
    1: "yourSelectedExams",
  };
  const servisesToCall = [categories, selectedExams];
  try {
    const responses = await Promise.allSettled(servisesToCall);
    let sanitizeData = {};
    responses?.forEach((data, idx) => {
      let key = TYPE_ARRAY[idx];
      if (data?.value?.data) {
        sanitizeData[key] = data?.value?.data || [];
      } else if (data?.status === "rejected") {
        sanitizeData[key] = [];
        sanitizeData["error"] =
          data?.reason?.message || "Oops Something Went Wrong!";
      }
    });
    return Promise.resolve(sanitizeData);
  } catch (error) {
    return Promise.reject(error);
  }
};
const getParentPackageExams = (packageId) => {
  return {
    type: types.GET_PARENT_PACKAGE_EXAMS,
    CALL_API: [
      {
        type: types.GET_PARENT_PACKAGE_EXAMS,
        meta: {
          path: `${myPurchase.selectedPackageExams}?packageId=${packageId}`,
          method: "GET",
        },
      },
    ],
  };
};
const getNextPagePackageChild = (
  packageId,
  category,
  examId,
  pageNo,
  pageSize = 10,
  stateKeyName = "childData"
) => {
  let childPkgUrl = `${myPurchase.packageData}?packageId=${packageId}${examId ? `&exams=${examId}` : ""
    }&category=${category}&isComingSoon=false&pageNumber=${pageNo}&pageSize=${pageSize}`;
  return {
    type: types.GET_NEXT_CHILD_PACKAGE_DATA,
    CALL_API: [
      {
        type: types.GET_NEXT_CHILD_PACKAGE_DATA,
        meta: {
          path: childPkgUrl,
          method: "GET",
          state: stateKeyName,
          selectedCategory: category,
        },
      },
    ],
  };
};
const getPPCContinueListing = (packageId, pageNo, pageSize, category) => {
  let possibelUrlsForTheContinue = {
    ONLINE_LIVE_CLASSES: "live-classes",
    VIDEOS: "videos",
    TEST_SERIES: "test-series",
    EBOOKS: "ebooks",
  };
  let continueStudyingUrl = `${STOREFRONT_URI}/api/v3/${possibelUrlsForTheContinue[category]}/ppc/package/continue-studying?packageId=${packageId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  return {
    type: types.GET_PPC_CONTINUE_LISTING,
    CALL_API: [
      {
        type: types.GET_PPC_CONTINUE_LISTING,
        meta: {
          path: continueStudyingUrl,
          method: "GET",
        },
      },
    ],
  };
};
const getProductDetailHoverInfo = (packageId) => {
  return {
    CALL_API: [
      {
        type: types.GET_PACKAGE_HOVER_DETAILS,
        meta: {
          path: `${pdpUrls.productDetails}?packageId=${packageId}&sc=1${isADDA ? "&block3DModels=true" : ""}`,
          method: "GET",
          packageId: packageId,
        },
      },
    ],
  };
};
const getAllAvailableDataOfthePackage = async (packageId, category, examId) => {
  let possibelUrlsForTheContinue = {
    ONLINE_LIVE_CLASSES: "live-classes",
    VIDEOS: "videos",
    TEST_SERIES: "test-series",
    EBOOKS: "ebooks",
  };
  let options = {
    method: "GET",
    headers: requestHeaders(),
  };

  let continueStudyingUrl = `${STOREFRONT_URI}/api/v1/${possibelUrlsForTheContinue[category]
    }/ppc/package/continue-studying?packageId=${packageId}&pageNo=${0}&pageSize=${20}`;

  if (isADDA && category === 'TEST_SERIES') {
    continueStudyingUrl = `${TEST_SERIES_URL}/api/v3/${possibelUrlsForTheContinue[category]
    }/ppc/package/continue-studying?packageId=${packageId}&pageNo=${0}&pageSize=${20}`;
  }

  let comingSoonUrl = `${STOREFRONT_URI}/api/v2/ppc/package/child?packageId=${packageId}${examId ? `&exams=${examId}` : ""
    }&category=${category}&isComingSoon=true`;
  let childDataUrl = `${myPurchase.packageData}?packageId=${packageId}${examId ? `&exams=${examId}` : ""
    }&category=${category}&isComingSoon=false&pageNumber=${0}&pageSize=${10}`;
  const continueStudying = fetch(continueStudyingUrl, "GET");
  const childData = fetch(childDataUrl, "GET");

  const comingSoon = fetch(comingSoonUrl, "GET");

  const TYPE_ARRAY = {
    0: "continueStudyingData",
    1: "childData",
  };
  const servisesToCall = [continueStudying, childData];
  if (category === "ONLINE_LIVE_CLASSES") {
    // call comming soon service only for live classes.
    servisesToCall.push(comingSoon);
    TYPE_ARRAY[2] = "comingSoonData";
  }
  try {
    const packageData = await Promise.allSettled(servisesToCall);
    let sanitizeData = {};
    packageData?.forEach((data, idx) => {
      let key = TYPE_ARRAY[idx];
      // sort the continueStudying data
      if (key === "continueStudyingData") {
        sanitizeData[key] =
          data?.value?.data?.sort((a, b) => b.updatedAt - a.updatedAt) || [];
      } else if (key === "childData") {
        data.value.data.selectedCategory = category; // which tab is selected.
        sanitizeData[key] = data?.value?.data || {};
      } else {
        sanitizeData[key] = data?.value?.data || {};
      }
    });

    return Promise.resolve(sanitizeData);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getPPCAllContinueListings = async (packageId, pageNo, pageSize) => {
  let liveClassStudyingUrl = `${STOREFRONT_URI}/api/v1/live-classes/ppc/package/continue-studying?packageId=${packageId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  let videosStudyingUrl = `${STOREFRONT_URI}/api/v1/videos/ppc/package/continue-studying?packageId=${packageId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  let testSeriesStudyingUrl = `${TEST_SERIES_URL}/api/v3/test-series/ppc/package/continue-studying?packageId=${packageId}&pageNo=${pageNo}&pageSize=${pageSize}`;
  let ebooksStudyingUrl = `${STOREFRONT_URI}/api/v1/ebooks/ppc/package/continue-studying?packageId=${packageId}&pageNo=${pageNo}&pageSize=${pageSize}`;

  const liveClassStudying = fetch(liveClassStudyingUrl);
  const videosStudying = fetch(videosStudyingUrl);
  const testSeriesStudying = fetch(testSeriesStudyingUrl);
  const ebooksStudying = fetch(ebooksStudyingUrl);

  const TYPE_ARRAY = {
    0: "ONLINE_LIVE_CLASSES",
    1: "VIDEOS",
    2: "TEST_SERIES",
    3: "EBOOKS",
  };
  const servisesToCall = [
    liveClassStudying,
    videosStudying,
    testSeriesStudying,
    ebooksStudying,
  ];

  try {
    const allContinueListing = await Promise.allSettled(servisesToCall);
    let mergedData = [];
    allContinueListing?.forEach((response, idx) => {
      if (response?.value?.data) {
        let fetchedData = response.value.data.map((item) => {
          item.category = TYPE_ARRAY[idx];
          return item;
        });
        fetchedData.sort((a, b) => b.updatedAt - a.updatedAt);
        mergedData = mergedData.concat(fetchedData);
      }
    });
    return Promise.resolve(mergedData);
  } catch (error) {
    return Promise.reject(error);
  }
};
const setInitialAllContinueListingData = (data = []) => {
  return {
    type: types.SET_INIT_PPC_ALL_CONTINUELISTINGS,
    meta: {
      response: true,
      method: "LOCAL",
      allContinueListing: data,
    },
  };
};
const setAllContinueListingData = (data) => {
  return {
    type: types.SET_PPC_ALL_CONTINUELISTINGS,
    meta: {
      response: true,
      method: "LOCAL",
      allContinueListing: data,
    },
  };
};
const getExamSubPackage = (packageId, examId, category) => {
  return {
    type: types.GET_EXAM_SUBPACKAGE,
    CALL_API: [
      {
        type: types.GET_EXAM_SUBPACKAGE,
        meta: {
          path: `${myPurchase.packageData
            }?packageId=${packageId}&category=${category}${examId ? `&exams=${examId}` : ""
            }&isComingSoon=false&pageNumber=${0}&pageSize=${10}`,
          method: "GET",
          selectedCategory: category, // which tab is selected.
        },
      },
    ],
  };
};
const setFullPackageData = (data = {}, category) => {
  return {
    type: types.SET_FULL_PACKAGE_DATA,
    meta: {
      response: true,
      method: "LOCAL",
      packageData: data,
      category,
    },
  };
};
const toggleExamModeKey = (key, isEnabled) => {
  let availableExamModes = {};
  try {
    //examModes key should always be object.
    availableExamModes = JSON.parse(localStorage.getItem("examModes")) || {};
    availableExamModes[key] = isEnabled;
    localStorage.setItem("examModes", JSON.stringify(availableExamModes));
  } catch (error) {
    console.log("Error while Setting exam Modes", error);
  }
  return {
    type: types.SET_EXAM_MODE,
    meta: {
      response: true,
      method: "LOCAL",
      availableExamModes,
    },
  };
};
const syncStateWithLocalStorage = (examModes = {}) => {
  return {
    type: "SYNC_EXAM_MODES",
    meta: {
      response: true,
      method: "LOCAL",
      examModes,
    },
  };
};

const getManageExamsByPackage = (packageId) => {
  return {
    type: types.GET_PARENT_PACKAGE_MANAGE_EXAMS,
    CALL_API: [
      {
        type: types.GET_PARENT_PACKAGE_MANAGE_EXAMS,
        meta: {
          path: `${myPurchase.manageExams}?packageId=${packageId}`,
          method: "GET",
        },
      },
    ],
  };
};
const savePackageExam = (packageId, newExamIds) => {
  return {
    type: types.SAVE_PARENT_PACKAGE_EXAMS,
    CALL_API: [
      {
        type: types.SAVE_PARENT_PACKAGE_EXAMS,
        meta: {
          path: myPurchase.saveExams,
          method: "POST",
          body: {
            packageId,
            examList: newExamIds,
          },
        },
      },
    ],
  };
};

const cleanUpPPCPackageState = (data) => {
  return {
    type: types.CLEAN_PPC_PACKAGE_STATE,
    meta: {
      response: true,
      method: "LOCAL",
      initialPPCState: data,
    },
  };
};

// ******* Paid content web search SERVICES **********
const searchPurchasedPackages = (queryParams = {}, pageNo = 0) => {
  return {
    CALL_API: [
      {
        type: types.PURCHASED_PACKAGE_SEARCH,
        meta: {
          path: `${myPurchase.packageSearch}?${queryParams}&pageNo=${pageNo}&pageSize=20${isADDA ? "&block3DModels=true" : ""}`,
          method: "GET",
          isPaginated: pageNo > 0 ? true : false
        },
      },
    ],
  };
};
const saveRecentSearches = (searchTerm = "") => {
  let recentSearches = JSON.parse(localStorage.getItem("recentSearches")) || [];
  const searchStringExist = isExactStringExistInArray(
    recentSearches,
    searchTerm
  );
  try {
    if (searchTerm && !searchStringExist && recentSearches?.length < 5) {
      recentSearches.unshift(searchTerm);
    } else if (
      searchTerm &&
      !searchStringExist &&
      recentSearches?.length == 5
    ) {
      // replace
      recentSearches.pop();
      recentSearches.unshift(searchTerm);
    }
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  } catch (error) {
    console.log("Error while saving recent searches", error);
  }
  return {
    type: types.SAVE_RECENT_SEARCH,
    meta: {
      response: true,
      method: "LOCAL",
      recentSearches,
    },
  };
};
const searchPackageFacets = (searchTerm) => {
  return {
    CALL_API: [
      {
        type: types.PURCHASED_PACKAGE_FACETS,
        meta: {
          path: `${myPurchase.facets}${searchTerm ? `?searchTerm=${searchTerm}` : ""
            }${isADDA ? "&block3DModels=true" : ""}`,
          method: "GET",
        },
      },
    ],
  };
};

const fetchPurChasedPopularCourses = () => {
  return {
    CALL_API: [
      {
        type: types.PURCHASED_POPULAR_COURSES,
        meta: {
          path: `${myPurchase.popularCourses}${isADDA ? "?block3DModels=true" : ""}`,
          method: "GET",
        },
      },
    ],
  };
};
// END OF PPC SERVICES

const resetUserPassword = (obj) => {
  return {
    CALL_API: [
      {
        type: types.RESET_PASSWORD,
        meta: {
          path: `${userApi}/register`,
          method: "POST",
          body: obj,
        },
      },
    ],
  };
};

const loadUserCart = () => {
  return {
    CALL_API: [
      {
        type: types.GET_USER_CART,
        meta: {
          path: `${storeHostUrl}/cart`,
          method: "GET",
        },
      },
    ],
  };
};

const setCartCountFromCookie = (cartCount) => {
  return {
    type: types.GET_CART_COUNT_FROM_COOKIE,

    payload: {
      cartCount,
    },
    method: "LOCAL",
  };
};

const fetchExamList = () => {
  return {
    CALL_API: [
      {
        type: types.FETCH_EXAM_LIST,
        meta: {
          path: `${STORE_FRONT_API_URI}/api/v3/tabs/course?examType=1`,
          method: "GET",
        },
      },
    ],
  };
};

const fetchGlobalExamList = (origin) => {
  return {
    CALL_API: [
      {
        type: types.FETCH_GLOBAL_EXAM_LIST,
        meta: {
          path: `${GLOBAL_EXAMS_API}GlobalConfig/v2/exam/list?src=and`,
          method: "GET",
          origin: origin
        },
      },
    ],
  };
};

const fetchTendingData = () => {
  return {
    CALL_API: [
      {
        type: types.FETCH_TRENDING_DATA,
        meta: {
          path: `${searchUrlEndPoint}/trending/data`,
          method: "GET",
        },
      },
    ],
  };
};

const savePurchasedCoursesData = (coursesData) => {
  return {
    type: types.SAVE_PURCHASED_COURSES,
    payload: {
      coursesData,
    },
  };
};

const fetchedPurchasedCoursesData = (newPurchaseCourseDataPageNo) => {
  return {
    type: types.FETCHED_PURCHASED_COURSES_DATA,
    payload: {
      newPurchaseCourseDataPageNo,
    },
  };
};

const saveRecentlyAddedCoursesData = (recentlyAddedCoursesData) => {
  return {
    type: types.SAVE_RECENTLY_ADDED_COURSES,
    payload: {
      recentlyAddedCoursesData,
    },
  };
};

export {
  userLoginAndRegister,
  startLoader,
  stopLoader,
  getAutoSuggestion,
  getSearchSuggestions,
  clearSearchSuggestions,
  loginFromCookie,
  logoutUser,
  checkIfEmailRegistered,
  verifyOTP,
  resetUserPassword,
  loadUserCart,
  setCartCountFromCookie,
  fetchExamList,
  fetchTendingData,
  fetchGlobalExamList,
  loginFlag,
  getMyPurchaseListings,
  getNextMyPurchaseListings,
  getManageExamsByPackage,
  toggleExamModeKey,
  syncStateWithLocalStorage,
  savePackageExam,
  getPackageCategories,
  getPackageCategoriesAndExams,
  getNextPagePackageChild,
  getAllAvailableDataOfthePackage,
  getParentPackageExams,
  setFullPackageData,
  getExamSubPackage,
  getPPCContinueListing,
  getPPCAllContinueListings,
  setInitialAllContinueListingData,
  setAllContinueListingData,
  cleanUpPPCPackageState,
  savePurchasedCoursesData,
  saveRecentlyAddedCoursesData,
  getProductDetailHoverInfo,
  fetchedPurchasedCoursesData,
  searchPurchasedPackages,
  saveRecentSearches,
  searchPackageFacets,
  fetchPurChasedPopularCourses,
  updateUserDetails,
};

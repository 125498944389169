import React, { memo, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ContineInApp from "../../../components/library/ContinueInApp/ContinueInApp";
import ComingSoonPrdCard from "../../../components/PPC/ComingSoonPrdCard";
import { useWindowDimensions } from "../../../components/utils";
import { getDeviceType, makeUrl } from "../../../data/utils/helpers";

const PPCComingSoonListings = ({ data = {}, category }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [slicedData, setSlicedData] = useState([]);
  const [clickedItemData, setClickedItemData] = useState();
  const windowDimension = useWindowDimensions();
  const history = useHistory();
  const { pathname } = useLocation();

  let { packages = [] } = data;

  const makeRedirectUrl = (packageId, title) => {
    return `${pathname}/${packageId}/${makeUrl(title)}`;
  };
  const openConsumptionScreen = (packageId, title) => {
    /**
     * mWeb android & Ios-> restrict the user to open consumptionScreen for every category and show openInApp popup instead.
     */
    if (getDeviceType() == "m" || getDeviceType() == "t") {
      setClickedItemData({
        packageId: packageId,
        type: category,
        parentPackageId: packageId,
      });
      return false;
    }
    let redirectUrl = makeRedirectUrl(packageId, title);
    history.push(redirectUrl);
  };

  const handleShowMore = () => {
    setIsShowMore(!isShowMore);
  };
  /**
   * makeSlicedData
   * usage -> show default data upto 5,on clicking showMore show data upto array length.
   */
  const makeSlicedData = () => {
    let slicedData = [...packages];
    let noItems = 5;
    if (isShowMore) {
      noItems = packages.length;
    }
    slicedData = slicedData.slice(0, noItems);
    setSlicedData(slicedData);
  };

  /**
   * handleDataVisiblity sets the state data based on viewPort.
   * Usage -> WEB - Show Sliced data with 5
   *       -> MWEB - Show full data without slicing.
   */
  const handleDataVisiblity = () => {
    if (packages.length && windowDimension?.width > 768) {
      makeSlicedData();
    } else if (packages.length && windowDimension?.width < 768) {
      setSlicedData([...packages]);
    }
  };
  useEffect(() => {
    handleDataVisiblity();
  }, [packages, isShowMore, windowDimension.width]);
  if (slicedData?.length)
    return (
      <>
        <ContineInApp
          show={!!clickedItemData}
          packageId={clickedItemData && clickedItemData.packageId}
          parentpackageId={clickedItemData && clickedItemData.parentPackageId}
          type={clickedItemData && clickedItemData.type}
          close={() => {
            setClickedItemData();
          }}
          isContinuesection={false}
        />
        <div className="cmg-soon-cards">
          <div className="cmg-soon-heading">Coming Soon </div>
          <div className="cmg-soon-card-list">
            {slicedData?.map((data, i) => (
              <ComingSoonPrdCard
                key={i}
                src={data?.thumbnail || data?.src}
                title={data.title}
                onClick={() =>
                  openConsumptionScreen(data?.packageId, data?.title)
                }
                batchStartDate={data?.batchStartDate}
              />
            ))}
          </div>

          {packages?.length > 5 && (
            <div
              className={`cmg-viewmore ${isShowMore ? "viewless" : ""}`}
              onClick={handleShowMore}
            >
              <span>
                {isShowMore ? "Show Less" : "Show More"}
                <span>
                  <img src="/images/viewmore-ppc.svg" />
                </span>
              </span>
            </div>
          )}
        </div>
      </>
    );
  return <></>;
};

export default memo(PPCComingSoonListings);

export const SEARCH_URL = "https://search.sankalpbharat.com/package-search-ws";
export const STOREFRONT_URI = "https://store.sankalpbharat.com";
export const TIMELINE_URI = "https://ytsearch.sankalpbharat.com";
export const USER_AUTH_URI = "https://api.sankalpbharat.com/user-ws";
export const IFRAME_URI = "https://www.sankalpbharat.com/iFrame-prod.js";
export const QUIZ_ATTEMPT_URL =
  "https://store.sankalpbharat.com/api/v1/test-series/request-states";
export const STORE_URI = "https://store.sankalpbharat.com";
export const COUPON_URI = "https://api.sankalpbharat.com/coupon-ws";
export const ORDER_API = "https://store.sankalpbharat.com/api/v2/orders";
export const STORE_CDN = "https://storecdn.sankalpbharat.com";
export const CDN_PATH = "prod";
export const VIDEO_URI = "https://api.sankalpbharat.com/video-ws";
export const STORE_FRONT_API_URI = "https://store.sankalpbharat.com";
export const SPECIAL_TEST_PATH = "specialTests";
export const PaytmMerchant_Id = "merchant-adda247-prod";
export const TEST_SERIES_URL = "https://api.sankalpbharat.com/testseries-ws";
export const imageOptimiserBaseUrl = "https://st.sankalpbharat.com/";
export const GLOBAL_EXAMS_API = "https://api.sankalpbharat.com/";
export const PROD_STORE_CDN = "https://storecdn.sankalpbharat.com";
export const LEADSQUARED_HOST_URL = "https://lapps-in21.leadsquared.com";
export const EBOOKS_URL = "https://api.sankalpbharat.com/ebooks-ws";
export const LIVE_CLASS_URL = 'https://api.sankalpbharat.com/liveclass-ws';
export const NEW_ADMIN_UI = "https://livecl.sankalpbharat.com"
export const MISC_URL = 'https://api.sankalpbharat.com/miscellaneous';
export const THREED_MODEL_URL =  "https://api.sankalpbharat.com/3dmodels-ws";
export const THREED_MODEL_IFRAME_URL = "https://www.sankalpbharat.com/veeksha/index.html";
export const PAGENAME = ['sankalpbharat', 'Sankalp Bharat', 'Sankalp Bharat'];
export const FPM_PkgId = 330;
export const Live_PkgId = 2915;
export const hiddenFpmIds = [374290];
export const LCS_SERVICE_URL = "";
export const WEB_HOST_URL = "https://www.sankalpbharat.com";
export const MEDIA_UPLOAD_URL = "https://media-uploader-prod.adda247.com"
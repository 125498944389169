import React, { useEffect, useRef, useState } from "react";
import CustomModal from "./library/CustomModal";
import PaymentFailure from './PaymentFailure';
import PaymentSuccess from './PaymentSuccess';


// import ApplyCoupon from "./ApplyCoupon";
const  CartActionModal = ({ priceDetails,Getpayload,isLoggedIn,userInfo,goTo,cartData,cartDataInfo
}) => {
  const [hashUrl, setHashUrl] = useState("");
  const doc = useRef(null);
  const [header, setHeader] = useState("What are you preparing for?");
const[fromapp,setFromapp]=useState(false);

  function getParameterByName(name, url) {
    if (!url) url=typeof window !=undefined && window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


useEffect(()=>{
  let fromapp=getParameterByName("from_app");
  if(fromapp)
  {
    setFromapp(true);
  }
  else{
    setFromapp(false);
  }
},[])
  useEffect(() => {
    if(location.hash){
      setHashUrl(location.hash);
    }
    window.addEventListener("hashchange", ()=>{
      setHashUrl(location.hash);
    }, false);

  }, []);

  const close = () => {
    if(window.location.hash && window.location.hash.indexOf('#cart/success')!==-1){
      if(window.location.pathname==='/my-cart/checkout'){
        goTo('/')
      }
    }
    window.location.hash = "";
  };
  if (!hashUrl) return null;
  let ModalBodyComponent;
  let ModalHeader;
  let cartActionClass;
  let modalHeight = '630px';
  if(hashUrl.indexOf('#cart/success')>-1){
    ModalHeader = "Order Successful";
    ModalBodyComponent = <PaymentSuccess 
    orderId={hashUrl.split('/')[2]} email={hashUrl.split('/')[3]}
    Getpayload={Getpayload}
    fromapp={fromapp}
    userInfo={userInfo }
    isLoggedIn={isLoggedIn}
    cartData={cartData}
    cartDataInfo={cartDataInfo}

    />;
    cartActionClass='cartaction-success'
  }
  else if(hashUrl ==='#cart/failure'){
    ModalHeader = <span className='error'>Payment Failed</span>;
    ModalBodyComponent =  <PaymentFailure
    fromapp={fromapp}
     Getpayload={Getpayload}
     cartData={cartData}
     cartDataInfo={cartDataInfo}
      > </PaymentFailure>;
    cartActionClass='cartaction-failure'
    modalHeight = 'auto';
  }
  else{
    return null;
  }
  // switch (hashUrl) {
  //   case "#cart/success":
  //     ModalHeader = "Success";
  //     ModalBodyComponent = <PaymentSuccess/>;
  //     cartActionClass='cartaction-success'
  //     break;
  //   case "#cart/failure":
  //     ModalHeader = <span className='error'>Payment Failed</span>;
  //     ModalBodyComponent =  <PaymentFailure  ></PaymentFailure>;
  //     cartActionClass='cartaction-failure'
  //     break;
  //   default:
  //     return null;
  // }
//   const onBackClick = ()=>{
//     const backActionList=['',"#checkout/userDetails","#checkout/payment"]
//     window.location.hash=backActionList[backActionList.indexOf(hashUrl)-1].substring(1)
//   }

// ModalHeader = <span className='error'>Payment Failed</span>;;
// ModalBodyComponent = <PaymentFailure/>;
  return (
    <div className={cartActionClass}>
    <CustomModal
    ref={doc}
      modalClassName={"checkout-modal modalZindex"} 
      footer={null}
      hideHeader={hashUrl==="#cart/success"}
      visible={true}
      title={<div className={"modal-header"}>{ModalHeader}</div>}
      onCancel={close}
      closeCallback={close}
      closable={true}
      maskClosable={false}
      keyboard={false}
      hideCross={ fromapp  ? true :false}
      bodyStyle={{  overflowY: "auto", height: modalHeight }}
    //   onBack={onBackClick}
    >
     
        {/* <UserDetails></UserDetails> */}

        {/* <PaymentMethod></PaymentMethod>
         */}
         {ModalBodyComponent}
   
      {/* <CustomButton buttontext="Continue"></CustomButton> */}
    </CustomModal>
    </div>
  );
};
export default  CartActionModal;

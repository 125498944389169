import React from "react";
import TrendingSuggestions from "./TrendingSuggestions";

const SearchNotFound = ({ onClick,searchKeywordText }) => {
  return (
    <div className="searchbox-not-found-screen">
      <div className="web-searchbox-no-found">
        <div className="web-searchbox-icon">
          <img src="/images/no-result-found.svg" />
        </div>
        <div className="title-web-searchbox">
          No results found for {searchKeywordText}
        </div>
        <div className="sub-title-web-sarchbox">
          Please check if you have the right spelling, or try different
          keywords.
        </div>
      </div>
      <div className="not-found-treding-search">
        <div className="not-found-title">Try searching these instead</div>
        <TrendingSuggestions onClick={onClick} />
      </div>
    </div>
  );
};

export default SearchNotFound;

import React from 'react'
import { useLocation, useHistory } from "react-router-dom";
import NoResultsFound from '../../../components/library/NoResultsFound';
import { isADDA, makeUrl } from '../../../data/utils/helpers';

const ExamOptions = ({
    examName, 
    allPackageCountData, 
    handleAllBatchesClick, 
    handleAllCoursesClick
}) => {

    const { pathname } = useLocation();
    const history = useHistory();
    let titleTextForSubParents = "";
    if (allPackageCountData) {
        titleTextForSubParents = allPackageCountData?.packages?.reduce((result, current) => {
            if (result) {
                return result + ", " + current?.title;
            } else {
                return current?.title;
            }
        }, "")
    }

    const goToChildPackageListing = (id, title) => {
      const url = pathname.substring(0, pathname.length - 8);
      history.push(`${url}/${id}/${makeUrl(title)}/batch`)
    }

  return (
    <div className="viewBatchWrap"> 
                  <div className="viewBatchBox LeftBoxView">
                      <div className="viewBatchHeadingWrap">
                        <div className="viewBatchHeading">All Batches for {examName}</div>
                        <div className={"viewBatchBtnTop" + (allPackageCountData?.packages?.length == 0 ? " disabled": "")}><span onClick = {handleAllBatchesClick}>View All Batches</span></div>
                      </div>
                      
                    {(allPackageCountData?.packages?.length > 0) ?
                      <>
                        <div className="viewBatchPhera">
                          {titleTextForSubParents} {(allPackageCountData?.subParentCount > 2) && <>and <span>{allPackageCountData?.subParentCount - 2} others</span></>}
                        </div>

                        <div className="viewBatchList">
                          {
                            allPackageCountData?.packages?.map(pkg => { return <div className="viewBatchThumb cp" onClick = {() => goToChildPackageListing(pkg.packageId, pkg.title)}><span ><img src={pkg.thumbnail} /></span></div> })
                          }
                          {(allPackageCountData?.subParentCount > 2) && <div className="viewBatchThumb cp" onClick = {handleAllBatchesClick}><img src={allPackageCountData?.packages?.[1].thumbnail} /><span className="viewBatchMore">+{allPackageCountData?.subParentCount - 2} more<br />courses</span></div>}
                        </div>
                      </>
                      :
                      <div className="viewBatchList">
                        <NoResultsFound message = {"No Batches Found"}/>
                      </div>
                    }

                  </div>
                  <div className="viewBatchBox RightBoxView">
                  <div className="viewBatchHeadingWrap">
                        <div className="viewBatchHeading">All Courses for {examName}</div>
                        <div className="viewBatchBtnTop"><span onClick = {handleAllCoursesClick}>View All Courses</span></div>
                      </div>

                    <div className="viewAllCourseList">
                        { (allPackageCountData?.childPackageMap?.ONLINE_LIVE_CLASSES > 0) &&
                              <div className="viewAllCourseListBox">
                                  <div className="viewAllCourseName">Live Class</div>
                                  <div className="viewAllCourseValue">{allPackageCountData?.childPackageMap?.ONLINE_LIVE_CLASSES} Courses</div>
                              </div>
                        }

                        { (allPackageCountData?.childPackageMap?.TEST_SERIES > 0) &&
                              <div className="viewAllCourseListBox">
                                  <div className="viewAllCourseName">Test Series</div>
                                  <div className="viewAllCourseValue">{allPackageCountData?.childPackageMap?.TEST_SERIES} Courses</div>
                              </div>
                        }


                        { (allPackageCountData?.childPackageMap?.VIDEOS > 0) &&
                              <div className="viewAllCourseListBox">
                                  <div className="viewAllCourseName">Video Class</div>
                                  <div className="viewAllCourseValue">{allPackageCountData?.childPackageMap?.VIDEOS} Courses</div>
                              </div>
                        }

                        { (allPackageCountData?.childPackageMap?.EBOOKS > 0) &&
                              <div className="viewAllCourseListBox">
                                  <div className="viewAllCourseName">E-books</div>
                                  <div className="viewAllCourseValue">{allPackageCountData?.childPackageMap?.EBOOKS} Courses</div>
                              </div>
                        }
                        {!isADDA && (allPackageCountData?.childPackageMap?.MODELS_3D > 0) &&
                              <div className="viewAllCourseListBox">
                                  <div className="viewAllCourseName">3D Learning</div>
                                  <div className="viewAllCourseValue">{allPackageCountData?.childPackageMap?.MODELS_3D} Courses</div>
                              </div>
                        }
                    </div>


                    <div className="viewAllCourseBoxImg">
                      <img src={`/images/${isADDA ? 'viewallcourseBigImg':'viewallcourseBigImgSB'}.svg`} />
                    </div>

                  </div>

              </div>
  )
}

export default ExamOptions

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {facetsMap, mobileFilterMap} from '../../../constants/appConstants';
import {cleanObj, fireMoengageEvent, getSubstringAfterChar, getSubstringBeforeChar, isEmpty, isSet} from '../../data/utils/helpers';
import CustomModal from '../library/CustomModal';
import { pageName } from '../../../constants/appConfig';

let obj = {};
let tempOb = {};

class NewMobileFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentSelectedFilter: props.currentActiveFilter ? props.currentActiveFilter : props.facetsList ? this.getRequiredFacets(props.facetsList)[0] : '',
            facetsObject: props.facetObj || {},
        };
        obj = props.facetObj;
    }

    componentDidMount() {
        const {facetObj, selectedTags, productCategory} = this.props;
        this.getFilterData(this.state.currentSelectedFilter);
        this.setState({
            tagArr: selectedTags,
            productCategory
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentSelectedFilter !== prevState.currentSelectedFilter) {
            fireMoengageEvent(`${this.state.currentSelectedFilter}_tab_clicked`, {}, "Filter_Internal_Tab_Selected");
        }
    }

    getRequiredFacets(facets) {
        if (!isSet(facets.tags)) {
            delete facets.tags;
        }
        if (isSet(facets.time)) {
            delete facets.time;
        }
        if (this.props.variant === 'courseDetail') {
            delete facets.courses;
            delete facets.categories;
            // return Object.keys(facets).filter(e);
        }
        if (this.props.variant === 'examDetail') {
            delete facets.courses;
            delete facets.categories;
            delete facets.examTypes;
        }
        delete facets.faculties;
        const facetArr = Object.keys(facets);
        return facetArr?.filter(e => !isEmpty(facets[e]));
    }

    getFilterData = (currentFilter) => {
        this.setState({
            currentSelectedFilter: currentFilter
        });
        const filterData = this.props.facetsList ? this.props.facetsList[currentFilter] : {};
        if(Object.keys(filterData)?.length && currentFilter == "categories") {
            filterData["3D Learning#MODELS_3D"] = filterData["Models 3d#MODELS_3D"]
            delete filterData["Models 3d#MODELS_3D"];
        }
        this.setState({
            filterData
        });
    };

    handleOnChange = (e) => {
        this.createFacetsObject(e);
    };

    dummyMethod = (e) => {
        const val = e.target.value;
        let tempArr = this.state.tagArr;
        if (tempArr.includes(val)) {
            const index = tempArr.indexOf(val);
            tempArr.splice(index, 1);
        } else {
            tempArr.unshift(val);
        }
        this.setState({
            tagArr: tempArr
        });
        this.createFacetsObject(e);
        this.forceUpdate();
    };

    handleRadioOnChange = (e) => {
        this.props.handleRadio(e.target.value === '3D Learning' ? 'models 3d' : e.target.value );
    };

    createFacetsObject = (e) => {
        let tempA = [];
        obj = this.state.facetsObject;
        if (isEmpty(obj)) {
            tempA = [];
            tempOb = {};
        }
        const val = e.target.value;
        if (!isSet(tempOb[this.state.currentSelectedFilter.toLowerCase()])) {
            if(this.state.currentSelectedFilter === "languages") tempOb['languages'] = [];
            else tempOb[this.state.currentSelectedFilter.toLowerCase()] = [];
            tempA = tempOb[this.state.currentSelectedFilter.toLowerCase()];
            if (tempA.includes(val)) {
                tempA.splice(tempA.indexOf(val), 1)
            } else {
                tempA.push(val);
            }
            obj[facetsMap[this.state.currentSelectedFilter.toLowerCase()]] = tempA;
        } else {
            if(this.state.currentSelectedFilter === "langFacets") tempA = tempOb['languages'] = tempA
            else tempA = tempOb[this.state.currentSelectedFilter.toLowerCase()];
            if (tempA.includes(val)) { 
                tempA.splice(tempA.indexOf(val), 1);
            } else {
                tempOb[this.state.currentSelectedFilter.toLowerCase()].push(val);
            }
            obj[facetsMap[this.state.currentSelectedFilter.toLowerCase()]] = tempOb[this.state.currentSelectedFilter.toLowerCase()];
        }

    };

    handleClickOnApply = () => {
        const facets = cleanObj(obj);
        this.props.handleApply(facets);
    };

    handleResetClick = () => {
        obj = {};
        this.setState({
            facetsObject: {}
        });
        this.props.resetFilter();
    };

    getItem = (arrayItem, getItem) => {
        return arrayItem?.filter(element => element.toLowerCase().indexOf(getItem.toLowerCase()) > -1);
    };

    handleFilterSearch = e => {
        const searchText = e.target.value.trim();
        // filterData -> object
        const searchObj = Object.assign({}, this.props.facetsList['examTypes']);
        const obj = this.getItem(Object.keys(searchObj), searchText).reduce((o, key) => ({
            ...o,
            [key]: Math.random()
        }), {});
        this.setState({
            filterData: obj
        });
    };

    getFilters = () => {
        const {type} = this.props;
        let {filterData} = this.state;
        if (isSet(filterData) && isSet(filterData['Magazines#MAGAZINES'])) {
            delete filterData['Magazines#MAGAZINES'];
        }
        if (isSet(filterData) && isSet(filterData['Insurance#INSURANCE'])) {
            delete filterData['Insurance#INSURANCE'];
        }
        let val = this.state.facetsObject[facetsMap[(this.state.currentSelectedFilter || "").toLowerCase()]];
        switch (type) {
        case 1: {
            

            return (
                <>
                    {
                        this.state.currentSelectedFilter === 'examTypes' &&
                        <input type="text"
                               placeholder="Search"
                               className={'filter-input'}
                               onChange={this.handleFilterSearch}
                               onFocus={() => fireMoengageEvent("Filter_Search_Clicked", {}, "Filter_Search")}
                        />
                    }
                    {
                        filterData && !isEmpty(filterData) ?
                        Object.keys(filterData)?.map(item => {
                            let radioValue = item?.substring(item.indexOf('#') + 1);
                                //radioValue = radioValue.split(" ").join("_");
        
                            let labelValue = getSubstringBeforeChar(item, '#');
                                
                            return(
                            <div className={'check-container'}>
                                <label>{labelValue} 
                                <input type={'checkbox'}
                                       className={'radio-button-mobile test1'}
                                       value={getSubstringAfterChar(item, '#')}
                                       key={Math.random()}
                                        // onChange={(e) => this.handleOnChange(e)}
                                    onClick={(ev) => this.dummyMethod(ev)}
                                    checked={this.props?.selectedTags?.includes(radioValue)}
                                      // checked={this.props.selectedTags.length && this.props.selectedTags.includes(getSubstringAfterChar(item, '#'))}
                                />
                                </label>
                            </div>
                        )}) : <div className='no-result-found-margin'>No Filters Applicable!</div>
                    }
                </>
            );
        }
        case 2: {
            if (this.state.currentSelectedFilter === 'courses') {
                return (
                    !isEmpty(filterData) &&
                    Object.keys(filterData)?.map(item => {
                        if((item || "").toLowerCase()=="engineering#engineering"){
                            item = "Engineering Exams#ENGINEERING EXAMS";
                        }

                        let radioValue = item?.substring(item.indexOf('#') + 1);
                        //radioValue = radioValue.split(" ").join("_");

                        let labelValue = getSubstringBeforeChar(item, '#');
                        if((labelValue || "").toLowerCase()=='gate iitjam'){
                            labelValue = "GATE & IITJAM";
                        }
                        if((labelValue || "").toLowerCase()=='iitneet'){
                            labelValue = "IIT/Medical";
                        }


                        return(
                        <div className={'check-container'}>
                            <label for={item}>{labelValue}
                            <input type={'radio'}
                                   className={'radio-button-mobile'}
                                    onClick={this.handleRadioOnChange}
                                // defaultValue={this.prop.courseCategory}
                                   value={radioValue}
                                   key={Math.random()}
                                   id={item}
                                   checked = {this.props.courseCategory === getSubstringAfterChar(item, '#').toLowerCase()}
                            />
                            </label>
                        </div>
                    )})
                );
            }
            return (
                <>
                    {
                        this.state.currentSelectedFilter === 'examTypes' &&
                        <input type="text"
                               placeholder="Search"
                               className={'filter-input'}
                               onChange={this.handleFilterSearch}
                        />
                    }
                    {!isEmpty(filterData) ? (
                        Object.keys(filterData)?.map(item => {                            
                                if (this.props.selectedTags.includes(getSubstringAfterChar(item, '#'))) {
                                   // this.createFacetsObject([getSubstringAfterChar(item, '#')]);
                                }
                                return (
                                    <div className={`check-container ${this.state.currentSelectedFilter}`}>
                                        <label>{getSubstringBeforeChar(item, '#')}
                                        <input className={'radio-button-mobile  test2'}
                                               type={'checkbox'}
                                               onChange={(e)=>this.props?.handleFilterChange(e)}
                                                onClick={(ev) => this.dummyMethod(ev)}
                                               name={getSubstringAfterChar(item, '#')}
                                               value={getSubstringAfterChar(item, '#')}
                                               key={Math.random()}
                                               id={item}
                                                checked={this.props.selectedTags.includes(getSubstringAfterChar(item, '#'))}
                                        />
                                        </label>
                                    </div>
                                );
                            }
                        )
                    ) : (<div className='no-result-found-margin'>No results found!</div>)
                    }
                </>
            );

        }
        case 3: {
            if (this.state.currentSelectedFilter === 'categories') {
                return (
                    !isEmpty(filterData) &&
                    Object.keys(this.state.filterData)?.map(item => (
                        <div className={'check-container'}>
                            <label for={item}>{getSubstringBeforeChar(item, '#')}
                            <input className={'radio-button-mobile'}
                                   type={'radio'}
                                   value={getSubstringBeforeChar(item, '#')}
                                   key={Math.random()}
                                   checked={this.state.productCategory.toLowerCase() === getSubstringBeforeChar(item, '#').toLowerCase()}
                                   onChange={this.handleRadioOnChange}
                            />
                            </label>
                        </div>
                    ))
                );
            }

            
            return (
                <>
                    {
                        this.state.currentSelectedFilter === 'examTypes' &&
                        <input type="text"
                               placeholder="Search"
                               className={'filter-input'}
                               onChange={this.handleFilterSearch}
                        />
                    }
                    {!isEmpty(filterData) &&
                    Object.keys(this.state.filterData)?.map(item => {
                        if((item || "").toLowerCase()=="engineering#engineering"){
                            item = "Engineering Exams#ENGINEERING EXAMS";
                        }

                        let radioValue = item.substring(item.indexOf('#') + 1);
                        //radioValue = radioValue.split(" ").join("_");

                        let labelValue = getSubstringBeforeChar(item, '#');
                        if((labelValue || "").toLowerCase()=='gate iitjam'){
                            labelValue = "GATE & IITJAM";
                        }
                        if((labelValue || "").toLowerCase()=='iitneet'){
                            labelValue = "IIT/Medical";
                        }
                        return (
                                <div className={'check-container'}>
                                    <label>{labelValue}
                                    <input type={'checkbox'}
                                           className={'radio-button-mobile test3'}
                                        // onChange={this.handleOnChange}
                                            onClick={(ev) => this.dummyMethod(ev)}
                                           name={radioValue}
                                           value={radioValue}
                                           checked={this.state.tagArr.includes(radioValue)}
                                           key={item}
                                           id={item}
                                   />
                                   
                                    </label>                                   
                                </div>
                        )
                        
                    })
                    }
                </>
            );
        }
        default: {
            return (
                !isEmpty(filterData) &&
                Object.keys(this.state.filterData)?.map(item => (
                    <div className={'check-container'}>
                        <label for={item}>{getSubstringBeforeChar(item, '#')}
                        <input type={'checkbox'}
                               className={'radio-button-mobile test4'}
                               onChange={this.handleOnChange}
                               name={getSubstringAfterChar(item, '#')}
                               value={getSubstringAfterChar(item, '#')}
                               key={item}
                               checked={val && val.includes(getSubstringBeforeChar(item, '#'))}
                               id={item}
                        />
                        </label>
                    </div>
                ))
            );
        }
        }
    };

    render() {
        const {facetsList} = this.props;
        const {currentSelectedFilter} = this.state;
        const filterList = facetsList ? this.getRequiredFacets(facetsList) : [];
        return (
            <CustomModal
                visible={this.props.visible}
                footer={null}
                centered
                mask={false}
                wrapClassName={'width100vw'}
                // destroyOnClose
                title={
                    <div className={'modal-header-filter'}>
                        <div className={'modal-filtermobile'}>All Filters</div>
                    </div>
                }
                // style={this.props.style}
                onOk={this.props.handleOk}
                closeCallback={this.props.onCancel}
                bodyStyle={{'padding': '0', 'margin': '0'}}
                closeIcon={<img onClick={this.props.onCancel} alt={pageName[0]} src={'/images/close-icon.svg'}
                title={pageName[0]}  />}
            >
                <div className={'mobile-filter-main-block'}>
                    <div className={'mobile-exam-category'}>
                        {filterList && !isEmpty(filterList) &&
                        <div>
                            {
                                filterList?.map((item, i) => {
                                    if (!isEmpty(facetsList[item])) {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => this.getFilterData(item)}
                                                className={currentSelectedFilter == item ? 'active-category-listing' : 'category-listing'}
                                            >
                                                {mobileFilterMap[item.toLowerCase()]}
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        }
                    </div>
                    <div className={'checkbox-container-mobile'}>
                        {
                            this.getFilters()
                        }
                    </div>
                    <div className={'filter-footer'}>
                        <div className={'reset'} onClick={this.handleResetClick}>RESET</div>
                        <div className={'apply'} onClick={this.handleClickOnApply}>APPLY</div>
                    </div>
                </div>
            </CustomModal>
        );
    }
}

export default connect(null, null)(NewMobileFilter);
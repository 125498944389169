import React, { useEffect, useState } from "react";
import { openTestPortal } from "../../TakeTest/util";
import { Live_PkgId } from "../../../../../../constants/appUrls";
import { ENGLISH_IDENTIFIER, WEB_IDENTIFIER } from "../../../../MoengageConstants";
import { getMoengageMetadata, isADDA } from "../../../../../data/utils/helpers";
import Cookies from "js-cookie";
export const getLiveTestState = (testInfo) => {

    let currentTime = Date.now();
    let currentStateObj = {
        status : "Coming Soon",
        buttonText : "Coming Soon",
        buttonClass : "cmgSoon",
    };

    let startTime = testInfo?.testData?.[0]?.fixedMockTestStartTime;
    let endTime = testInfo?.testData?.[0]?.fixedMockSubmitTime;
    let attemptBeforeTime = testInfo?.testData?.[0]?.attemptBeforeTime;
    let resultTime = testInfo?.testData?.[0]?.fixedMockResultTime;
    let resultPublished = testInfo?.testData?.[0]?.resultPublished;
    let bufferTime = attemptBeforeTime + 0    //Taking 5 mins as buffer (In ms); Taking Zero as buffer time as of now. But can be used in future if required
    let status = getQuizStatus(testInfo?.status);
    let mappingId = testInfo?.testData?.[0]?.mappingId;
    let submitStatus = localStorage.getItem(`${mappingId}Submitted`) || false;
    let testPublished = testInfo?.testData?.[0]?.published || false;

    if(!startTime || !endTime || !testPublished) return currentStateObj; 

    let liveStatus = true;

    if(currentTime >= resultTime) liveStatus = false;

    if (liveStatus) {

        // Live Test Conditions:

        if (currentTime < startTime) {
            currentStateObj = {
                status : "Scheduled On",
                buttonText : "Scheduled On",
                buttonClass : "liveClassDisabled schdldisable",
            }
        }
        else if (currentTime >= startTime && currentTime < attemptBeforeTime) {
            // Instructions Window
            currentStateObj = {
                status : "Start",
                buttonText : "Start Test",
                buttonClass : "liveClassActive",
            }
        }
        else if (currentTime >= attemptBeforeTime && currentTime < bufferTime && status === "NOT_STARTED") {
            currentStateObj = {
                status : "Start",
                buttonText : "Start Test",
                buttonClass : "liveClassActive",
            }
        }
        else if (currentTime >= attemptBeforeTime && currentTime < endTime && status === "INCOMPLETE" && !submitStatus) {
            currentStateObj = {
                status : "Resume",
                buttonText : "Resume",
                buttonClass : "liveClassActive",
            }
        }
        else if (currentTime >= attemptBeforeTime && currentTime < endTime && status === "INCOMPLETE" && submitStatus) {
            currentStateObj = {
                status : "Result Awaited",
                buttonText : "Result Awaited",
                buttonClass : "liveClassInactive",
            }
        }
        else if (currentTime >= attemptBeforeTime && currentTime < resultTime && status === "NOT_STARTED") {
            // Start after 'x' hours
            currentStateObj = {
                status : "Start Awaited",
                buttonText : "Start Test",
                buttonClass : "liveClassDisabled",
            }
        }
        else if (
            (status === "COMPLETED" && !resultPublished) || 
            (status === "NOT_STARTED" && currentTime > bufferTime) || 
            (currentTime >= endTime && currentTime < resultTime) || 
            (!resultPublished && status !== "COMING_SOON")
        ) {
            currentStateObj = {
                status : "Result Awaited",
                buttonText : "Result Awaited",
                buttonClass : "liveClassInactive",
            }
        }
        else {
            currentStateObj = {
                status : "Coming Soon",
                buttonText : "Coming Soon",
                buttonClass : "liveClassDisabled schdldisable",
            }
        }

    }

    else {

        // Non-Live Test Conditions:

        if (status === "COMPLETED" && !resultPublished) {
            currentStateObj = {
                status : "Result Awaited",
                buttonText : "Result Awaited",
                buttonClass : "liveClassDisabled",
            }
        }
        else if (status === "COMPLETED" && resultPublished) {
            currentStateObj = {
                status : "Result Published",
                buttonText : "View Result",
                buttonClass : "liveClassActive",
            }
            
        }
        else if (status === "INCOMPLETE") {
            currentStateObj = {
                status : "Resume",
                buttonText : "Resume",
                buttonClass : "liveClassActive",
            }
        }
        else if (status === "NOT_STARTED" && !resultPublished) {
            currentStateObj = {
                status : "Start",
                buttonText : "Start Test",
                buttonClass : "liveClassDisabled",
            }
        }
        else if (status === "NOT_STARTED" && resultPublished) {
            currentStateObj = {
                status : "Start",
                buttonText : "Start Test",
                buttonClass : "liveClassActive",
            }
        }
        else {
            currentStateObj = {
                status : "Coming Soon",
                buttonText : "Coming Soon",
                buttonClass : "cmgSoon",
            }
        }

    }

    return currentStateObj;

}

export const getQuizStatus = (status) => {
    if(status === "Not Attempted" || status === "NOT_STARTED") return "NOT_STARTED";
    else if(status === "Attempted" || status === "COMPLETED") return "COMPLETED";
    else if(status === "Incomplete" || status === "INCOMPLETE") return "INCOMPLETE";
    else return "COMING_SOON";
}

export const getActionButtonActiveStatus = (status) => {

    let hiddenStatus = ["Coming Soon"];
    let activeStatus = ["Start", "Start Test", "Resume", "Result Published", "View Result", "Instruction"];
    let inactiveStatus = ["Scheduled", "Result Awaited", "Start Awaited"];

    if (hiddenStatus.includes(status)) return "live-btn-hide"
    else if(activeStatus.includes(status)) return "live-btn-active"
    else if(inactiveStatus.includes(status)) return "live-btn-disabled"
    else return "live-btn-disabled"
}

export const getLiveTestDateTimeObj = (startDate, endTime, attemptBefore) => {

    if (startDate === undefined || endTime === undefined || attemptBefore === undefined ||
        startDate === null || endTime === null || attemptBefore === null ||
        isNaN(startDate) || isNaN(endTime) || isNaN(attemptBefore)) {
        return {
            error: 'Invalid input. Please provide valid timestamps.'
        };
    }

    const formatDate = (timestamp, includeYear = true) => {
        const date = new Date(timestamp);
        const day = date.getDate();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = includeYear ? `, ${date.getFullYear()}` : '';

        const suffix = (day >= 11 && day <= 13) ? 'th' : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][day % 10];

        return `${day}${suffix} ${month}${year}`;
    };

    const formatAmPmTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';

        const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
        return formattedTime;
    };

    const durationMilliseconds = endTime - attemptBefore;
    if (durationMilliseconds < 0) {
        return {
            error: 'End time should be greater than or equal to start date.'
        };
    }

    const durationSeconds = Math.floor(durationMilliseconds / 1000);
    const durationHours = Math.floor(durationSeconds / 3600);
    const durationMinutes = Math.floor((durationSeconds % 3600) / 60);
    const durationFormatted = `${durationHours}:${durationMinutes < 10 ? '0' : ''}${durationMinutes}:${durationSeconds % 60 < 10 ? '0' : ''}${durationSeconds % 60}`;

    const attemptBeforeFormatted = formatAmPmTime(attemptBefore);
    const startsAtFormatted = formatAmPmTime(startDate);
    const startDateFormattedWithYear = formatDate(startDate);
    const startDateFormattedWithoutYear = formatDate(startDate, false);

    return {
        duration: durationFormatted,
        attemptBefore: attemptBeforeFormatted,
        startsAt: startsAtFormatted,
        startDate: startDateFormattedWithYear,
        startDateWithoutYear: startDateFormattedWithoutYear
    };
}

export const getLiveTestActionText = (test) => {

    let liveTestObj = getLiveTestState(test);
    let text = liveTestObj.buttonText;
    return {
      actionItem:
        (<span
        className={`${test.status.toLowerCase()} ${
            text ? "test-disabled" : ""
        } test-btn`}
        >
        {text}
        </span>),
    };
  };

export const getLiveTestRemainingTime = (startTime, endTime) => {
    let remainingTime = ""

    if (startTime && endTime) {

        const currentTime = Date.now();

        if (currentTime >= startTime && currentTime < endTime) {
            const remainingMilliseconds = endTime - currentTime;
            const remainingSeconds = Math.floor(remainingMilliseconds / 1000);
            const hours = Math.floor(remainingSeconds / 3600);
            const minutes = Math.floor((remainingSeconds % 3600) / 60);
            const seconds = remainingSeconds % 60;

            remainingTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
    }
    return remainingTime;
}

export const useLiveTestAction = (testPortalOpened) => {

    const handleLiveTestAction = ({testData = {}, quizStatus = "", reattempt = null, source_screen = "", event_name = "", moepayload = {}}) => {

        let mappingId = testData?.testData[0]?.mappingId;
        let title = encodeURIComponent(testData?.testData[0]?.title);

        let currentTime = Date.now();
        let resultTime = testData?.testData[0]?.fixedMockResultTime;
        let liveStatus = currentTime >= resultTime ? false : true;
        let examList = [];

        if (isADDA) examList = testData?.testData[0]?.examList;

        //  ["Coming Soon", "Scheduled", "Result Awaited"]  --> Non Actionable states
        let openTestStatus = ["Start Test", "Resume", "Instruction"];
        let openResultsStatus = ["Result Published", "View Result"];

        if(source_screen && event_name) hitMoengageForLiveTest(source_screen, event_name, moepayload);

        if (openTestStatus.includes(quizStatus)) {
            openTest(mappingId, title, getQuizStatus(testData?.status), liveStatus, examList);
        }
        else if (openResultsStatus.includes(quizStatus)) {
            redirectToResults(mappingId, title, getQuizStatus(testData?.status), reattempt, liveStatus);
        }
        else {
            console.log("Non Actionable!");
        }
    }

    const openTest = (mappingId, title, completionStatus, liveStatus = false, examList) => {

        testPortalOpened.current = true;

        let packageId = Live_PkgId;
        
        openTestPortal(mappingId,packageId,title,completionStatus,null,examList || [], "", false, true, liveStatus ? "LT" : "NLT");
    }

    const redirectToResults = (mappingId, title, completionStatus, reattempt) => {
        let packageId = Live_PkgId;
        openTestPortal(mappingId,packageId,title,completionStatus,reattempt,[], "", false, true);
    }

    return [handleLiveTestAction];

}

export const hitMoengageForLiveTest = (source_screen = "", event_name = "", moepayload = {}) => {

    let moengageData = {};

    let commonAttributes = {

        // Event Attributes
        exam_category : WEB_IDENTIFIER,
        source_screen : source_screen,

        // User Attributes
        user_exam_category_selected : WEB_IDENTIFIER,
        language_selected : ENGLISH_IDENTIFIER,
        user_name : Cookies.get("cp_user_name") || "",
        user_email : Cookies.get("cp_user_email") || "",
        category : "TEST_SERIES",
        user_id : Cookies.get("cp_user_id"),
        paid_status : Cookies.get("cp_paid_user") ? 1 : 0,
    }

    moengageData = {...moengageData, ...commonAttributes};

    if (moepayload && Object.keys(moepayload)?.length) moengageData = {...moengageData, ...moepayload};

    if (typeof Moengage !== "undefined") Moengage.track_event(event_name, getMoengageMetadata(moengageData));
}

export const indicatorMessages = {
    instructions_online : "Your internet connection is restored. You can start the test now!",
    instructions_offline : "You are offline. Please do not press back! This test will be accessible only for 5 more minutes.",
    test_window_online : "You are online",
    test_window_offline : "You are offline. Continue attempting your test and do not leave the screen. Make sure you are connected to internet while submitting the test.",
}

export const toolTipMessage = {
    fullLengthTooltip : "To be taken on scheduled date and time to access overall performance analysis.",
    sectionalTooltip : "To be taken on scheduled date and time."
}

export const liveTestModalData = {
    howItWorks : {
        type: "howItWorks",
        title : "How it Works?",
        description : `
        <ul>
            <label class="lt-modal-heading">About Live Test:</label> 
            <li>
                <label>What:</label> 
                "Engage in Live Tests for an authentic exam experience on scheduled dates."
            </li>

            <li>
                <label>How to Know About It:</label>
                <ol>
                    <li>"Stay informed through the announcement section."</li>
                    <li>"Check live test status in the live tests batch."</li>
                    <li>"Receive default in-app notifications."</li>
                </ol>
            </li>

            <li>
                <label>How It Helps You:</label> 
                "Evaluate your All India Ranking and gain detailed, real-time insights into your performance."
            </li>
        </ul>

        <ul>
            <label class="lt-modal-heading">Important Points to Remember:</label> 
            <li>
                <label>Start time:</label> 
                <ol>
                    <li>Please join the test on time. You will see the instructions screen first. </li>
                    <li>Do NOT LEAVE THE INSTRUCTIONS SCREEN and the test will start automatically once instructions time is over.</li>
                    <li>No one will be allowed to start the test once instruction time is over. (Instructions time is mainly included for you to check your belongings and internet connection.)</li>
                </ol>
            </li>

            <li>
                <label>Resume test:</label>
                <ol>
                    <li>You can resume test if left in between without submitting</li>
                </ol>
            </li>

            <li>
                <label>Pause test:</label> 
                <ol>
                    <li>Test time will not be paused during the test.</li>
                    <li>If your internet connection is lost during the test (you will be notified), keep attempting the test and check your connection while submitting the test to prevent any data loss</li>
                </ol>
            </li>

            <li>
                <label>Submission Buffer time:</label> 
                <ol>
                    <li>15 mins buffer time after test end time (no attempts allowed during this time) to check your internet connection and successfully submit the test in case of no internet connection during test submission</li>
                </ol>
            </li>

            <li>
                <label>Reattempt test:</label> 
                <ol>
                    <li>If you miss your live test, you can attempt it only after the result has been published for live takers.</li>
                    <li>You will not get your actual rank and other analysis for non live hour attempts.</li>
                </ol>
            </li>

        </ul>
        `,
    },
    submitTest : {
        type: "submitTest",
        title : "Submit Test",
        description : "You need to be connected to internet to submit the test.",
        primaryButtonText : "Ok",
        secondaryButtonText : "Cancel",
    },
    noInternetConnection : {
        type: "noInternetConnection",
        title : "No Internet Connection",
        description : "Please check your internet connection and retry to submit the test.",
        secondaryDescription : "Do not leave the screen or press back, otherwise the progress will be lost.",
        primaryButtonText : "Retry",
    },
    testSubmitted : {
        type: "testSubmitted",
        title : "Test Submitted",
        description : "Hi",
        secondaryDescription : "Your test is submitted successfully. You can check your result in ",
        primaryButtonText : "Ok",
    },
}


export const useLiveTestModal = (onBeforeReloadOrCloseTab) => {
    const [liveTestModalBody, setLiveTestModalBody] = useState();
    const [onlineFlag, setOnlineFlag] = useState();
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        setOnlineFlag(navigator.onLine)
    }, [navigator.onLine]);

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeReloadOrCloseTab);
        return () => {
          window.removeEventListener('beforeunload', onBeforeReloadOrCloseTab);
        };
    }, []);

    const handleOpenModal = (modalType, submitTest, onlineFlag) => {
      if (modalType === "submitTest") {
        openSubmitTestPopup(modalType, submitTest);
      } else if (!onlineFlag && modalType === "noInternetConnection") {
        openNoConnectionPopup(modalType, submitTest, onlineFlag);
      } else if (onlineFlag && modalType === "testSubmitted") {
        openTestSubmittedPopup(modalType);
      } else {
        openSubmitTestPopup(modalType, submitTest);
      }
    };
  
    const openSubmitTestPopup = (modalType, submitTest) => {
      let modalBody = { ...liveTestModalData?.[modalType] };
      modalBody.primaryAction = onlineFlag ? submitTest : () => openNoConnectionPopup("noInternetConnection", submitTest);
      setLiveTestModalBody(modalBody);
    };
  
    const openNoConnectionPopup = (modalType, submitTest, onlineFlag) => {
      let modalBody = { ...liveTestModalData?.[modalType] };
      modalBody.primaryAction = onlineFlag ? submitTest : () => console.log("No Internet connection");
      setLiveTestModalBody(modalBody);
    };
  
    const openTestSubmittedPopup = (modalType) => {
      let modalBody = { ...liveTestModalData?.[modalType] };
      modalBody.description = `${modalBody.description} ${userDetails?.name || ""}!`
      modalBody.primaryAction = () => {
        window.close();
      };
      setLiveTestModalBody(modalBody);
    };
  
    return [handleOpenModal, liveTestModalBody, setUserDetails];
  };

  export const getStateUpdateTime = (stateUpdateTime) => {
    if(stateUpdateTime) {
        const currentTime = Date.now();
        const timeDifference = stateUpdateTime - currentTime;
    
        // Convert milliseconds to days, hours, and minutes
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    
        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (hours > 0) {
            // Output in "hh:mm" format
            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            return `${formattedHours}:${formattedMinutes} hour${formattedHours > 1 ? "s" : ""}`;
        } else {
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }
    }
    return "";
  };


  export const LIVE_TEST_CONSTANTS = {
    MODE : {
        LIVE_TEST : "LT",
        NON_LIVE_TEST : "NLT",
    }
  }
import types from './types';

const updateReadingListData = (data)=>{
    return {
        type: types.UPDATE_READING_LIST_DATA,
        payload: data,
    };
}
const replaceReadingListData = (data)=>{
    return {
        type: types.REPLACE_READING_LIST_DATA,
        payload: data,
    };
}
const updateReadingListPage = (pageNo)=>{
    return {
        type: types.UPDATE_READING_LIST_PAGE,
        payload:pageNo,
        
    };
}

export {
    updateReadingListData,
    replaceReadingListData,
    updateReadingListPage

}
const SET_LIVE_CLASS_DATA_NEW = 'SET_LIVE_CLASS_DATA_NEW';
const SET_MY_VIDEOS_DATA = 'SET_MY_VIDEOS_DATA';
const SET_MY_MOCK_TEST_DATA = 'SET_MY_MOCK_TEST_DATA';
const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
const RESET_CACHE_ENROLLED_COURSES = 'RESET_CACHE_ENROLLED_COURSES';
export default {
    SET_LIVE_CLASS_DATA_NEW,
    SET_MY_VIDEOS_DATA,
    SET_MY_MOCK_TEST_DATA,
    SET_SELECTED_TAB,
    RESET_CACHE_ENROLLED_COURSES
}

import React from "react";

const CouponComponent = ({coupon, priceDetails})=>{
    if(!coupon)
    return null;
    let rupee = "&#8377;"
    let discountValue = coupon.discountType=='percentOff'?`${coupon.discountValue}%`:`${coupon.discountValue}`
    return (
        <div className={` ${priceDetails.appliedCoupon == coupon.code ? 'fade-coupon' : ''}`}>
            <div className={`coupon-component-wrapper`}>
                <div className='coupon-component-value'>
                {coupon.discountType!=='percentOff' && <span>&#8377;</span>}{discountValue}
                </div>
                <div className='break'>
                    <div className='semi-circle'></div>
                    <div className='rect'></div>
                    <div className='semi-circle-inverted'></div>
                    <div></div>
                    <div></div>
                </div>
        <div className='coupon-component-name'>{coupon.code}</div>
                  </div>
                  {
                      coupon.discountType == 'flat' ? <div className="buynow-coupon-discribe">Get this course for ₹{coupon.discountValue} flat</div> :   <div className="buynow-coupon-discribe">Get extra {coupon.discountType=='amountOff' && '₹'}{coupon.discountValue}{coupon.discountType == 'percentOff' && '%'} Off on all Products</div>
                  }
                
        </div>

    )
}

export default CouponComponent;
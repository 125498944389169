import React, { useState, useEffect} from "react";
import CustomLoader from "../library/CustomLoader";
import { getMoengageMetadata } from "../../data/utils/helpers";

const AppliedCouponInfo = ({
    appliedCoupon,
    couponDiscount,
    Getpayload,
    setRemoveCouponFlag,
    isDefaultCouponAvailable,
    applyDefaultCouponFlag,
    setApplyDefaultCouponFlag,
    defaultCouponCode,
    isPurchased,
    published,
    modified_date,
    published_date,
    price
  }) => {
    const [prevCoupon, setprevCoupon] = useState("");
    const [removeFlag, setRemoveFlag] = useState(false);
  
    useEffect(() => {
      if (appliedCoupon && couponDiscount) {
        setRemoveFlag(false);
      }
    }, [appliedCoupon && couponDiscount]);
  
    useEffect(() => {
      if (couponDiscount && appliedCoupon !== prevCoupon && Getpayload) {
        let payload = Getpayload || {};
        payload.couponCode = appliedCoupon;
        payload.couponDiscount = couponDiscount;
        payload.package_url = window?.location?.href || "";
        payload.source_screen = "PDP";
        payload.modified_date = modified_date || '';
        payload.published_date = published_date || '';
        payload.final_package_price = price - couponDiscount;
  
        if (typeof Moengage !== "undefined")
          Moengage.track_event(
            "coupon_code_successfully_applied",
            getMoengageMetadata(payload)
          );
      }
    }, [couponDiscount]);
    useEffect(() => {
      setTimeout(() => {
        setprevCoupon(appliedCoupon);
      }, 5000);
    }, [appliedCoupon]);
  
    if (isPurchased || !published) {
      return <></>;
    }
  
    return (
      <>
        {appliedCoupon && couponDiscount ? (
          <div className="pdpCouponAplied">
            <img src="/images/pdpdiscountNew.svg" alt="discountIcon"/>"{appliedCoupon || "SUB20"}"
            coupon applied
            <span
              className="closeCoupon"
              onClick={() => {
                setRemoveCouponFlag(true);
                setRemoveFlag(true);
              }}
            >
              <img src="/images/pdpclosecoupon.svg" alt="closeIcon"/>
            </span>
          </div>
        ) : isDefaultCouponAvailable ? (
          <div className="pdpCouponAplied">
            <img src="/images/pdpdiscountNew.svg" alt="discountIcon"/>"{defaultCouponCode}" coupon
            available
            {!applyDefaultCouponFlag ? (
              <span
                onClick={() => {
                  setApplyDefaultCouponFlag(true);
                }}
                className="closeCoupon"
              >
                APPLY
              </span>
            ) : (
              <div>
                <CustomLoader buttonSpinner={true} />
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  };

export default AppliedCouponInfo;
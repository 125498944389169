import React, { useState } from 'react'
import { getMoengageMetadata } from '../../data/utils/helpers';
import { pageName } from '../../../constants/appConfig';

export default function NewSortByMobileFilter(props) {

    const [sortCategory, setSortCategory] = useState(props?.selectedSortCategory && props.selectedSortCategory ===   "Price : Low To High" ? "ASC" : props?.selectedSortCategory && props.selectedSortCategory ===  "Price : High To Low" ? "DESC" : "Relevance");

    const handleRadioClick = (e) => {
        setSortCategory(e.target.value);
        props.handleSortBy(e.target.value);
        const category = e.target.value === "ASC" ? "Price : Low To High" : e.target.value === "DESC" ? "Price : High To Low" : "Relevance";
        props.setSelectedSortCategory(category);
        let payload = { source: "MWEB", brand: pageName[1] };
        payload.action = 'Sort_Option_Clicked',
        payload.getUtmFromStore = props.getUtmFromStore;

        payload.Sort_option_choosed = (e.target.value === "ASC") ? 'ASC applied' : e.target.value + " applied";
        if (typeof (Moengage) !== 'undefined') Moengage.track_event('sort', getMoengageMetadata(payload));
    }

  return (
    <div className='new-sort-modal-wrapper'>
        <div className='new-sort-modal-content'>
        <div className='new-sort-modal-header'>
            <span className='sort-modal-heading '>Sort By</span>
            <span><img onClick={() => {props.handleSortButton()}} alt={pageName[0]} src={'/images/close-icon.svg'} title={pageName[0]}  /></span>
        </div>
            <div className={'new-sort-btn-wrapper'} style={{ display: "flex", flexDirection: "column"}}  >
                <div className='sort-category'>
                    <input type="radio" id="Relevance" name="sort-by" value="Relevance" checked={sortCategory === 'Relevance'} onChange = {(e) => {handleRadioClick(e)}}/>
                    <label htmlFor="Relevance">Relevance</label>
                </div>
                <div className='sort-category'>
                    <input type="radio" id="DESC" name="sort-by" value="DESC" checked={sortCategory === 'DESC'} onChange = {(e) => {handleRadioClick(e)}} />
                    <label htmlFor="DESC" >Price : High To Low</label>
                </div>
                <div className='sort-category'>
                    <input type="radio" id="ASC" name="sort-by" value="ASC" checked={sortCategory === 'ASC'} onChange = {(e) => {handleRadioClick(e)}} />
                    <label htmlFor="ASC">Price : Low To High</label>
                </div>
            </div>
        </div>
    </div>
  )
}
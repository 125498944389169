import { Cookies } from "react-cookie";
import { getMoengageMetadata } from "../data/utils/helpers";
import { ENGLISH_IDENTIFIER } from "./MoengageConstants";

const cookie = new Cookies();

// Moengage event for JOIN LIVE
const moengageEventForLiveClass = (classData,title,sourceScreen) => {
    const olcType = classData?.olcType ? classData?.olcType.toLowerCase() : "impartus";
    let eventName = "";
    if (olcType === "addalive") {
      eventName = "start_session_adda";
    } else {
      eventName = "liveclass_start_session";
    }
    const body = {};
    body.schedule_id = classData?.classId;
    body.package_id = classData?.packageId;
    body.teacher_name = classData?.faculty;
    body.userId = cookie.get("cp_user_id");
    body.source_screen = sourceScreen;
    body.content_id = classData?.externalScheduleId;
    body.topic = classData?.name || classData?.topicName;
    body.package_name = title;
    body.package_url = window?.location?.href || '';
    body.package_status = "Paid";
    body.start_schedule_time = classData?.startTime || classData?.startTimeInMilli;
    body.language_selected = ENGLISH_IDENTIFIER;
    if (typeof Moengage !== "undefined") {
      Moengage.track_event(eventName, getMoengageMetadata(body));
    }
  };

export {moengageEventForLiveClass}
import React from 'react'

export const Indicator = (props) => {
  return (
    <>
    {props.showIndicator ? <div className={`indicator-wrapper ${props.additionIndicatorWrapper}`}>
        <div className={`indicator-icon ${props?.indicatorIconClass || ""}`} />
        <div className={`indicator-body ${props?.indicatorBodyClass || ""}`} >
            {props.indicatorBody}
        </div>
    </div> : ""}
    </>
  )
}

import React from "react";
import { memo } from "react";

const ExamModeToggler = ({
  enabled = true,
  onChange = () => {},
  disabled = false,
  isVisible = true,
}) => {
  return (
    <div
      className="old-exam-view-wrap"
      style={{ visibility: isVisible ? "visible" : "hidden" }}
    >
      {isVisible && (
        <>
          <div className="old-exam-icon">
            <img src="/images/examModeIcon.svg" />
          </div>
          <div className="exammode">
            <div className="exam-mode-title">Exam Mode</div>
            <div className="exam-mode-status">
              Turn{" "}
              {`${
                enabled ? "Off for Old View" : "On for Exam Focused Learning"
              }`}
            </div>
          </div>
          <div className="exam-mode-switch-btn">
            <input
              className="switch-btn"
              type="checkbox"
              checked={enabled}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(ExamModeToggler);

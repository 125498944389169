import Axios from "axios";

export const fileUploader = async (method, url, headers, data) => {
  const config = {
    method: method,
    url: url,
    headers: {
      ...headers,
      "content-type": "multipart/form-data",
    },
    data: data,
  };
  try {
    const response = await Axios(config);
    if (response?.status === 200) {
      const { data } = response;
      return data;
    }
  } catch (error) {}
};

import React from 'react';

const CustomLoader = (props) => {
    return (
        <div className={props.buttonSpinner ? 'button-spinner' : 'spinner-listing'}>
            <div className={props.buttonSpinner ? 'button-spin' : 'spinner'}></div>
        </div>
    );
};

export default CustomLoader;

import types from './types';
import { pageSizeForDetailListing, productListingUrl } from '../../../../constants/appConfig';
import { isADDA } from '../../utils/helpers';

const getPaginatedDataForCourseDetail = ({ pageNo, courseName, type, categoryFacet, queryParam, sortBy,sponsoredCategory = 1, tagParams = "" }) => {
    let updatedCourseName = ""
    if (courseName == "civil-engineering") {
        updatedCourseName = "engineering";
    } else {
        updatedCourseName = courseName;
    }
    return {
        CALL_API: [
            {
                type: type === 'init' ? types.GET_COURSE_DETAIL_LISTING : types.GET_COURSE_DETAIL_LISTING_ON_SCROLL,
                meta: {
                    path: `${productListingUrl}?coursesFacets=${updatedCourseName.toUpperCase()}&categoryFacets=${categoryFacet}${tagParams}&pageNo=${pageNo}&pageSize=${pageSizeForDetailListing}&${queryParam.length ? `${queryParam}` : ''}${sortBy.toUpperCase() != "RELEVANCE"? "&sortOrder=" + sortBy: ""}&primaryFilter=true&sponsoredCategory=${sponsoredCategory}${isADDA ? "&block3DModels=true" : ""}`,
                    method: 'GET',
                }
            }
        ]
    };
};

const clearCourseDetailData = () => {
    return {
        type: types.CLEAR_LISTING_DATA,
        meta: {
            response: true,
            method: 'LOCAL',
        }
    };
};

export {
    getPaginatedDataForCourseDetail,
    clearCourseDetailData
};


import React, { useMemo } from "react";
import {
  getSubstringAfterChar,
  getSubstringBeforeChar,
  isStringValueExist,
} from "../../../../data/utils/helpers";
import Accordion from "../Accordion";
import { facetsLabelConstants, facetsSearchKeyConstants, disAllowedPPCFacets } from "../constants";

const WebFacetsFilter = ({
  facetsList = {},
  selectedFiltersFacets = {},
  onChangeFacetHandlerCb = () => null,
  clearFiltersCb = () => null,
  isFilterApplied,
}) => {


  const facetsListKeys = useMemo(
    () =>
      // make factesListKeys which has value
      Object.keys(facetsList).filter(
        (key) => Object.keys(facetsList[key]).length > 0
      ).filter(key => !disAllowedPPCFacets.includes(key)),
    [facetsList]
  );

  const facetsItemRendering = (facetItems = [], facetType = "") => {
    return facetItems?.map((item, index) => {
      const facetValue = getSubstringAfterChar(item, "#");
      const facetLabel = getSubstringBeforeChar(item, "#");
      const isChecked = isStringValueExist(
        selectedFiltersFacets[facetType],
        facetValue
      );
      return (
        <div key={item + index} className="facets_checkbox_container">
          <input
            type="checkbox"
            id={`${facetValue}_${index}`}
            value={facetValue}
            checked={isChecked}
            onChange={(e) =>
              onChangeFacetHandlerCb({
                isSelected: e.target.checked,
                value: facetValue,
                facetType,
                isSearchEnabled: true, //pass true flag to enable dispatch on input change
              })
            }
          />
          <label htmlFor={`${facetValue}_${index}`}>{facetLabel}</label>
        </div>
      );
    });
  };
  if (!facetsListKeys?.length) {
    return "";
  }
  return (
    <div className="left-side-trending">
      <div className="full-width-title">
        Filters
        <button
          onClick={() => {
            isFilterApplied ? clearFiltersCb() : null;
          }}
          disabled={!isFilterApplied}
        >
          Clear All
        </button>
      </div>
      {facetsListKeys?.map((facet, index) => (
        <Accordion
          label={facetsLabelConstants[facet]}
          key={`${facet}_${index}`}
        >
          {facetsItemRendering(
            Object.keys(facetsList[facet]),
            facetsSearchKeyConstants[facet]
          )}
        </Accordion>
      ))}
    </div>
  );
};

export default WebFacetsFilter;

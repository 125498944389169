import React, { useEffect, useMemo, useState } from "react";
import { createResponseObjectFromArray } from "../../../../data/utils/helpers";
import ComparisonScoreCard from "./Components/ComparisonScoreCard";
import ComparisonTimeSpent from "./Components/ComparisonTimeSpent";
import LeaderBoard from "./Components/LeaderBoard";
import SectionTabs from "./Components/SectionTabs";
import TestRating from "./Components/TestRating";

const ComparisonAnalysis = ({
  sectionTabs = [],
  testResult = {},
  rating,
  setRating = () => {},
  isPPC,
  moengageData,
  freeContentFlag = false,
  ratingAvailable,
}) => {
  const [selectedSection, setSelectedSection] = useState(sectionTabs[0]);
  const {
    overall,
    sections = [],
    testInfo,
    topperInfo,
    toppersList,
  } = testResult;
  const [sectionalAnalysisList, setSectionalAnalysisList] = useState({});

  useEffect(() => {
    if (testResult) {
      const sectionsByKeys = createResponseObjectFromArray(sections, "secDN");
      // here we store overAll list of section analysis in state..
      setSectionalAnalysisList(sectionsByKeys);
    }
  }, [testResult]);

  // here we return respective sectional data.
  const selectedSectionData = useMemo(() => {
    if (!selectedSection || !Object.keys(sectionalAnalysisList).length)
      return {};
    // map topper info of respective selectedSection
    const topperInfoData = createResponseObjectFromArray(
      topperInfo?.sections || [],
      "secDN"
    )[selectedSection];
    let currentSectionData = { ...sectionalAnalysisList[selectedSection] };
    // define topperInfo key
    if (topperInfoData) {
      currentSectionData["topperInfo"] = topperInfoData;
    }
    return currentSectionData;
  }, [selectedSection, sectionalAnalysisList]);

  return (
    <>
      <SectionTabs
        tabs={sectionTabs}
        activeTab={selectedSection}
        onClick={setSelectedSection}
      />
      <section className="rowWrap">
        <div className="rowWidth8">
          <section className="ability-screen">
            <ComparisonScoreCard
              title="Mark Scored"
              currentUserScore={selectedSectionData?.marks?.toFixed()}
              topperScore={selectedSectionData?.topperInfo?.marks || 0}
              avarageScore={selectedSectionData?.info?.averageScore}
              totalValue={selectedSectionData?.totalMarks}
            />
            <ComparisonScoreCard
              title="Accuracy"
              currentUserScore={selectedSectionData?.accuracy}
              topperScore={selectedSectionData?.topperInfo?.accuracy}
              avarageScore={selectedSectionData?.info?.averageAccuracy}
              totalValue={100}
              hideTotalValue={true}
              showPercentage={true}
            />
            <ComparisonScoreCard
              title="Incorrect Responses"
              currentUserScore={selectedSectionData?.incorrectAnswerCount}
              topperScore={
                selectedSectionData?.topperInfo?.incorrectAnswerCount
              }
              avarageScore={selectedSectionData?.info?.averageIncorrect}
              totalValue={selectedSectionData?.secOpt ? selectedSectionData?.attemptAllowed : selectedSectionData?.totalQuestions}
            />
            <ComparisonScoreCard
              title="Correct Responses"
              currentUserScore={selectedSectionData?.correctAnswerCount}
              topperScore={selectedSectionData?.topperInfo?.correctAnswerCount}
              avarageScore={selectedSectionData?.info?.averageCorrect}
              totalValue={selectedSectionData?.secOpt ? selectedSectionData?.attemptAllowed : selectedSectionData?.totalQuestions}
            />

            <ComparisonTimeSpent
              title="Time Spent"
              currentUserScore={selectedSectionData?.timeSpent}
              topperScore={selectedSectionData?.topperInfo?.timeSpent}
              avarageScore={selectedSectionData?.info?.averageTime}
              totalValue={
                (selectedSectionData?.totalTime || overall?.totalTime || 0) *
                1000
              } //seconds to milliseconds
            />
          </section>
        </div>
        <div className="rowWidth4">
          <TestRating
            rating={rating}
            setRating={setRating}
            isPPC={isPPC}
            moengageData={moengageData}
            isThumbnail={false}
            ratingAvailable={ratingAvailable}
          />
          {/* exlude leaderBoard in reattempt.. */}
          {toppersList?.length && (
            <LeaderBoard
              toppersList={toppersList}
              totalMarks={overall?.totalMarks}
              dtbQuestotalMarks={overall?.dtbQuestotalMarks || 0}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ComparisonAnalysis;

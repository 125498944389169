import React, { useEffect, useState } from "react";
import Instruction from "./TakeTest/Instructions";
import Test from "./TakeTest/Test";
import TestSolution from "./TakeTest/TestSolution";
import fetch from "../../../data/utils/fetch";
import CustomLoader from "./../../library/CustomLoader";
import CustomHelmet from "./../../library/CustomHelmet";
import { STOREFRONT_URI } from "../../../../constants/appConfig";
import { getParameterByName, isADDA } from "../../../data/utils/helpers";
import { useScript } from "../../utils";
import { TEST_SERIES_URL, TIMELINE_URI, PAGENAME, FPM_PkgId, Live_PkgId } from "../../../../constants/appUrls";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import ToastContainer from "../../Toast/ToastContainer";
import Cookies from "js-cookie";
import { LIVE_TEST_CONSTANTS } from "./TestResultAnalysis/Components/liveTestUtils";
const TakeTest = (props) => {
  const [startTest, setStartTest] = useState(props.match.params && props.match.params.status === "COMPLETED" ? true : false);
  const [language, setLanguage] = useState("ENGLISH");
  const [testData, setTestData] = useState();
  const [loading, setLoading] = useState(true);
  const [errorMsg,setErrorMsg] = useState('')
  const [loadingPreviousState, setLoadingPreviousState] = useState(false);
  const [previousState, setPreviousState] = useState();
  const [languageList, setLanguageList] = useState([]);
  const [isPPc, setIsppc] = useState(false);
  useScript("/images/drm/nocopy-content.js");
  const [isFixedMock, setIsFixedMock] = useState(false);
  const [fixedMockData, setFixedMockData] = useState({});
  const [totalTestTime, setTotalTestTime] = useState(0);
  const [liveTestAdditionalData, setLiveTestAdditionalData] = useState({
    startTime: "",
    attemptBeforeTime : "",
    submitTime : "",
    startBufferTime: "",
    endBufferTime: "",
  });  
  const [nLTAdditionalData, setNLTAdditionalData] = useState({
    userStartTime : Date.now(),
    userSubmitTime : Date.now(),
    userAlreadyStarted : false,
  });
  const {search} = useLocation()
  const jwtTokenNew = Cookies.get("cp_new_token") || useSelector(state => state.header.jwtTokenNew);
  const queryParams = new URLSearchParams(search);
  const sfJson = queryParams.get("sfJsonPreview") // sfJsonPreview flag you get when redirect from CMS preview test.
  const exam = queryParams.get("exam") // taged exam name with test.
  const mode = queryParams.get("mode") || "" // mode in case of Live Test.
  const freeContentFlag = queryParams.get("freeContent");

  const changeLanguage = (e) => {setLanguage(e.target.value);};

  let mappingId = props.match.params.mappingId;
  let packageId = props.match.params.packageId;
  const startTestFlag = props.match.params?.status && props.match.params?.status === "new";
  const fpmData = {};
  

  useEffect(() => {
    let status=getParameterByName("ppc")
    setIsppc(status)
    setLoading(true);
    if (parseInt(packageId) !== FPM_PkgId && mode !== LIVE_TEST_CONSTANTS.MODE.LIVE_TEST && parseInt(packageId) !== 0){
      //normal test
      fetchNormalTestData();
      
    }
    if(sfJson){
      fetchSfJsonTest(sfJson)
    }
  }, []);

  useEffect(() => {
    if (jwtTokenNew && parseInt(packageId) === FPM_PkgId){
      setIsFixedMock(true);
      fetchFixedMockData();
    }
    else if(jwtTokenNew && parseInt(packageId) === Live_PkgId && mode === LIVE_TEST_CONSTANTS.MODE.LIVE_TEST){
      fetchLiveTestData();
    }
  },[jwtTokenNew])


  const initialiseTestData = data => {
    if (data) {
      let LanguageList = Object.keys(data).filter((lang) => {
        if (["meta", "orgName"].includes(lang) || !data[lang]) {  /// bcoz stupid API :(
          return false;
        }
        return lang;
      })
      setLanguageList(LanguageList);
      // let selectedLanguage = LANGUAGES.find((language)=>res.data[language]);
      setLanguage(LanguageList[0]);
      setTestData(data);
    }
    else {
      setTestData(null);
    }
  }
  const goTo = (url) => {
    props.history.push(url);
  };
  const resumeTestData = () => {
    if(freeContentFlag) return
    setLoadingPreviousState(true);
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/request-state?packageId=${props.match.params.packageId}&mappingId=${props.match.params.mappingId}`,
      "GET"
    ).then(
      (res) => {
        setLoadingPreviousState(false);
        if (res.data && res.data.testState){
          setPreviousState(JSON.parse(res.data.testState));
          if(mode === LIVE_TEST_CONSTANTS.MODE.NON_LIVE_TEST && res?.data?.userStartTime) {
            let nLTAdditionalDataCopy = {...nLTAdditionalData};
            nLTAdditionalDataCopy.userAlreadyStarted = true;
            nLTAdditionalDataCopy.userStartTime = res?.data?.userStartTime;
            setNLTAdditionalData(nLTAdditionalDataCopy);
          }
        }
        else {
          setPreviousState(null);
        }
      },
      (err) => {
        setLoadingPreviousState(false);
        setPreviousState(null);
      }
    );
  };

  const resumeFixedMockData = () => {
    if (freeContentFlag) return;
    setLoadingPreviousState(true);
    let headers = {};
    headers["x-jwt-token-new"] = jwtTokenNew;
    fetch(`${TEST_SERIES_URL}/testseries/getFixedMockData?mappingId=${mappingId}${mode ? `&packageId=${Live_PkgId}` : ""}`, "GET", {}, "application/json", false, headers).then(res => {
      if (res.success && res.data){
        let data = res.data;
        if (data.testState === "INCOMPLETE" || data.testState === "COMPLETED"){
          setStartTest(true);
        }
        
        let fpmRemainingTime = data?.userRemainingTime/1000;
        
        fpmData.fpmUserRemainingTime = fpmRemainingTime;
        fpmData.testState = data.testState;
        if (data?.testState === "COMPLETED" || data?.userRemainingTime <= 0 || localStorage.getItem(`${data.mappingId}Submitted`) === "true"){
          setLoadingPreviousState(false);
          return;
        }
        fpmData.postSignedUrl = data.postSignedUrl; //url for save state
        
        setFixedMockData(fpmData);
        
        if(data.getSignedUrl){  // getSignedUrl => api for request state(answers data) in case of resume
           window.fetch(data.getSignedUrl,{method: "GET"}).then(res => res.json()).then(res => {
              getFixedMockAdditionalData(res, fpmRemainingTime);
              setPreviousState(res);

              setLoadingPreviousState(false);
           }).catch(err => {
              setPreviousState(null);
              setLoadingPreviousState(false);
           })
        }else{
          setLoadingPreviousState(false);
        }
      }else{
        // setLoadingPreviousState(false);
      }
    }).catch(err => {
      console.log("not getting test state so can not start, getting error: ", err);
      // setLoadingPreviousState(false);
    })
  }

  const getFixedMockAdditionalData = (data, fpmRemainingTime = null) => {
    let remainingTime;
    let currentTime;
    for(let i = 0; i < data?.length; i++){
      const answer = data[i];
      if(answer !== null){
        remainingTime = answer.userRemainingTime; // answer.userRemainingTime is the remaining time when user exit the test
        currentTime = answer.currentTime;  // answer.current time is the time when user exit the test
        break;
      }
    }
    if(remainingTime !== undefined){
      const userAwayTime = ((Date.now() - currentTime)/1000);
      const userRemainingTime = Math.ceil(remainingTime - userAwayTime);

      if (isFixedMock && fpmRemainingTime) userRemainingTime = Math.min(fpmRemainingTime, userRemainingTime);

      setFixedMockData(prev => {
        const newData = {...prev};
        newData.fpmUserRemainingTime = userRemainingTime;
        return newData;
      })
    }
  }

  const fetchFixedMockData = () => {
    if(!window?.location?.pathname?.includes("COMPLETED")){
      resumeFixedMockData();
    }

    fetch(`${TIMELINE_URI}/v3/fixedMock?id=${mappingId}`).then(res => {
      const data = res.data? res.data[0]: null;
      
      if (data){
        const sfJson = data.sfJson;
        fpmData.submitTime= data.fixedMockSubmitTime;
        fpmData.fixedMockResultTime= data.fixedMockResultTime;
        setFixedMockData(fpmData);
        fetch(sfJson, "GET").then(res => {
          initialiseTestData(res);
          setTotalTestTime(res?.meta?.time);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setTestData(null);
        })
      }
    }).catch(err => {
      setLoading(false);
      setTestData(null);
    })
  }

  const fetchLiveTestData = () => {
    if(!window?.location?.pathname?.includes("COMPLETED")){
      resumeFixedMockData();
    }

    fetch(`${TEST_SERIES_URL}/testseries/live-test/${mappingId}`).then(res => {
      const data = res?.data || null;
      
      if (data){
        const sfJson = data?.sfJson || "";
        fpmData.submitTime= data?.fixedMockSubmitTime;
        fpmData.fixedMockResultTime= data?.fixedMockResultTime;
        setLiveTestAdditionalData(
          {
            startTime : data?.fixedMockTestStartTime,
            attemptBeforeTime : data?.attemptBeforeTime,
            submitTime : data?.fixedMockSubmitTime,
            startBufferTime: 5*60*1000,   //5 min start buffer
            endBufferTime: 15*60*1000,    //15 min end buffer
          }
        );
        setFixedMockData(fpmData);
        if(sfJson) {
          fetch(sfJson, "GET").then(res => {
            if(res) {
              initialiseTestData(res);
              setTotalTestTime(res?.meta?.time);
              setLoading(false);
            }
          })
          .catch(err => {
            setLoading(false);
            setTestData(null);
          })
        }
        else {
          setLoading(false);
          setTestData(null);
        }
      }
    }).catch(err => {
      setLoading(false);
      setTestData(null);
    })
  }

  const fetchNormalTestData = () => {
    resumeTestData();
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/start-test?packageId=${
        props.match.params.packageId
      }&mappingId=${props.match.params.mappingId}${freeContentFlag ? "&freeContent=true" : ""}${
        props.match.params.status === "COMPLETED" ||  props.match.params.status === "REATTEMPT" ? "&testStatus=COMPLETED" : ""
      }`,
      "GET"
    ).then(
      (res) => {
        setLoading(false);
        initialiseTestData(res.data);  
        setTotalTestTime(res?.data?.meta?.time);
      },
      (err) => {
        setErrorMsg(err?.message)
        setLoading(false);
        setLoadingPreviousState(false);
        setTestData(null);
      }
    );
  }

  const fetchSfJsonTest = (sfJson) => {
      fetch(sfJson, "GET").then(res => {
        initialiseTestData(res);
        setTotalTestTime(res?.meta?.time);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setTestData(null);
      })
  }
  let testPackageDetails = {
    title: props.match.params.title,
    packageId: props.match.params.packageId,
    mappingId: props.match.params.mappingId,
  };
  let body = <div>{errorMsg}</div>;
  if (loading || loadingPreviousState) {
    body = <CustomLoader></CustomLoader>;
  } else if (testData) {
    if (startTest) {
      body =
        props.match.params && props.match.params.status === "COMPLETED" ? (
          <TestSolution
            isPPc={isPPc}
            data={testData}
            language={language}
            changeLanguage={changeLanguage}
            sfJson={sfJson}
            {...testPackageDetails}
          />
        ) : (
          <>
            <Test
              isPPc={isPPc}
              data={testData}
              prevData={previousState}
              language={language}
              goTo={goTo}
              changeLanguage={changeLanguage}
              isFixedMock = {isFixedMock}
              fixedMockData = {fixedMockData}
              isReattempt = {props.match.params.status === "REATTEMPT" ? true : false}
              {...testPackageDetails}
              totalTestTime={totalTestTime}
              nLTAdditionalData={nLTAdditionalData}
              sfJson={sfJson}
              mode={mode}
            />
            <ToastContainer/>
          </>
        );
    } else {
      body = (
        <Instruction
          data={testData}
          language={language}
          changeLanguage={changeLanguage}
          setStartTest={setStartTest}
          isFixedMock = {isFixedMock}
          exam={exam}
          mode={mode}
          setFixedMockData={setFixedMockData}
          startTestFlag={startTestFlag}
          liveTestAdditionalData={liveTestAdditionalData}
          nLTAdditionalData={nLTAdditionalData}
          setNLTAdditionalData={setNLTAdditionalData}
        ></Instruction>
      );
    }
  }
  return (
    <div
      id="test-portal"
      style={{
        position: "relative",
        top: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <CustomHelmet title={`${PAGENAME[1]} Store | ${PAGENAME[1]}`} />
      {/* {testData && <Test data={testData} language={language} changeLanguage={changeLanguage}/> */}
      {body}
    </div>
  );
};

export default TakeTest;
import React from 'react'

export default function LiveTestToolTipIcon(props) {
  return (
    <div className={`liveTest-icon-wrapper ${props.additionalClass || ""}`} onClick={(e) => {e.stopPropagation()}}>
      <div className="liveTestTollTipText">{props?.tooltipMessage || ""}</div>
        <div className='livetest-tooltip-icon'>icon</div>
        {
            props.liveTestText && 
            <div className='livetest-toottip-text'>
                {props.liveTestText}
            </div>
        }
    </div>
  )
}

import React from "react";
import ViewInstructionHeader from "./ViewInstructionHeader";

const ExamTestHeader = ({
  title = "",
  examName = "",
  data = {},
  language = "",
  changeLanguage = () => null,
  hideViewInstruction = false,
}) => {
  return (
    <div>
      <div className="ins-header">
        <img
          src="/images/header-logo.svg"
          className="logo-img"
          alt={`logo`}
          title={"adda247"}
          width="172px"
          height="40px"
        />
        <span>{title}</span>
      </div>
      {!hideViewInstruction && (
        <ViewInstructionHeader
          examName={examName}
          data={data}
          language={language}
          changeLanguage={changeLanguage}
        />
      )}
    </div>
  );
};

export default ExamTestHeader;

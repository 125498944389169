import React, { useEffect, useState,useRef } from "react";
import NewCarousel from "../library/NewCarousel";
import { Link } from "react-router-dom";
import fetch from "../../data/utils/fetch";
import { getDeviceType, getMoengageMetadata, optimizedImgUrl } from "../../data/utils/helpers";
import { connect } from "react-redux";
import {
  updateReadingListData,
  updateReadingListPage,
  replaceReadingListData,
} from "../../data/ducks/continueReading/actions";
import "../../data/ducks/continueReading/reducers";
import AProgressBar from "../library/AProgressBar";
import AButton from "../library/AButton";
import { FallBackImage, STOREFRONT_URI, pageName } from "../../../constants/appConfig";
// import ContinueInApp from '../../components/library/ContinueInApp/ContinueInApp';
import ContineInApp from "../../components/library/ContinueInApp/ContinueInApp";
import { EBOOKS_COPYRIGHT_MSG } from "../../../constants/textConstants";
import { openTestPortal } from "../enrolledCourses/TestSeries/TakeTest/util";
// import { data } from "autoprefixer";
// import { createOptimizedImgUrl } from "../../data/utils/helpers";

let carouselContainer = React.createRef();
const ContinueReading = (props) => {
  const focus = useRef();
  const testPortalOpened= useRef();
  const propData= useRef();
  const [modalData, setModalData] = useState();
  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  // const [displayData,setDisplayData] = useState([]);
  useEffect(()=>{
    propData.current=props.continueReading

  },[props.continueReading.data && props.continueReading.data.length])
 
  useEffect(() => {

    if(!props.continueReading.data)
     getNextPageData(0);
    let previousData = props.continueReading.data
    window.addEventListener("focus", ()=>{
      focus.current="focus";
     
      if(testPortalOpened.current){
        updateTestData();
        // testPortalOpened.current=false;
      }
     
    });
  }, []);

  const updateTestData = ()=>{
    // its bad design  but we need to do it

    // getting pageSize to update all the videos
    let propsData = propData.current
    if(!propsData) return;
    let pageSize = ((propsData.page|| 0)+1)*10;
    let previousData =propsData.data || []
    fetch(
      ` ${STOREFRONT_URI}/api/v1/my/purchased/test-series/continue-studing?pageNo=0&pageSize=${pageSize}`,
      "GET"
    ).then((res)=>{
      if(res && res.data){
        let index= 0;
        let updatedData = previousData.map((item)=>{
            if(item.type == "TEST-SERIES" ){
              if(res.data[index]){
                res.data[index].type='TEST-SERIES';
                let updatedData = res.data[index]
                index+=1;
                return updatedData
              }
              return item;
            }
            else{
              return item;
            }
        })
        props.replaceReadingListData(updatedData);

      }
      else{
        let updateValue = previousData.filter((item=>{
          
            return item && item.type!="TEST-SERIES";
                    
      }))
      
      props.replaceReadingListData(updateValue);
      }
      
    
    },()=>{})

  }
  const getNextPageData = (page) => {
    // let nextPageNo = props.continueReading.page
    //   ? props.continueReading.page + 1
    //   : 0;
    let ebooksData = fetch(
      `${STOREFRONT_URI}/api/v1/my/purchased/ebooks/continue-studing?pageNo=${page}&pageSize=10`,
      "GET"
    );
    let testData = fetch(
      ` ${STOREFRONT_URI}/api/v1/my/purchased/test-series/continue-studing?pageNo=${page}&pageSize=10`,
      "GET"
    );
    let videoData = fetch(
      ` ${STOREFRONT_URI}/api/v1/my/purchased/videos/continue-studing?pageNo=${page}&pageSize=10`,
      "GET"
    );
    Promise.all([videoData, testData, ebooksData])
      .then((responses) => {
        let mergedData = [];
        const TYPE_ARRAY = ["VIDEOS", "TEST-SERIES", "EBOOKS"];
        responses.forEach((response, index) => {
          if (response && response.data) {
            let fetchedData = response.data.map((item) => {
              item.type = TYPE_ARRAY[index];
              return item;
            });
            fetchedData.sort((a, b) => b.updatedAt - a.updatedAt);
            mergedData = mergedData.concat(fetchedData);
          }
        });
        props.updateReadingListData(mergedData)
        
       
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    let minItemsForCarousel = getDeviceType() == "m"?2:6;
    if (props.continueReading.data && (props.continueReading.data.length>minItemsForCarousel)) {
      let options = {
        root: document.getElementById("continue-studying"),
        rootMargin: "0px",
        threshold: 0.5,
      };
      let callback = (items) => {
        items.forEach((item) => {
          if (item.isIntersecting) {
            getNextPageData(!props.continueReading.page ? 1:props.continueReading.page+1 );
            props.updateReadingListPage(
              !props.continueReading.page ? 1:props.continueReading.page+1
            );
          }
        });
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.getElementById("continue-studying-last-item");
      if (target) observer.observe(target);
    }
  }, [props.continueReading.data && props.continueReading.data.length]);
  if (!props.continueReading.data || props.continueReading.data.length == 0)
    return null;

  return (
    <div className="container-padding">
    <ContineInApp show={!!modalData}
    packageId={modalData && modalData.packageId}
    parentpackageId={modalData && modalData.parentPackageId}
    type={modalData && modalData.type}
    close={() => {
      setModalData();
    }}
    isContinuesection={true}
  />
      <h2 className={"heading-title-home "} > Continue Studying</h2>
      <NewCarousel ref={carouselContainer} id={"continue-studying"}>
        {props.continueReading.data.map((courseInfo, index) => {
          let timeLeft;
          let payload={};
          payload.id=courseInfo.packageId;
          payload.category=courseInfo.type;
          payload.package_status="Paid";
          payload.exam_category=courseInfo.category && courseInfo.category;
          if (
            courseInfo.duration != undefined &&
            courseInfo.seekTime != undefined
          ) {
            timeLeft = courseInfo.duration - courseInfo.seekTime; // in ms
            timeLeft = timeLeft / 1000; // in s
            let hrs = Math.floor(timeLeft / 3600)
              ? `${Math.floor(timeLeft / 3600)} hrs `
              : "";
            let min = Math.ceil((timeLeft % 3600) / 60)
              ? Math.ceil((timeLeft % 3600) / 60)
              : "";
            min=   min>1 ?`${min} mins `:`${min} min `
           payload.time_left=timeLeft? `${hrs ? hrs : ""}${min ? min : ""}`:"";
            timeLeft =timeLeft? `${hrs ? hrs : ""}${min ? min : ""}left `:'';
            
          }
          let onClickFunction = () => {
          };
          if (courseInfo.type == "EBOOKS") {
         
            if (getDeviceType() == "m") {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              ); 
                setModalData({
                  packageId: courseInfo.id,
                  type: 'EBOOKS',
                  parentPackageId: courseInfo.packageId
                })
              }
            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              );
                if (
                  confirm(
                    EBOOKS_COPYRIGHT_MSG
                  )
                ) {
                  typeof window !== "undefined" &&
                    window.open(
                      `/e-books?file=${courseInfo.packageId}_${courseInfo.id}.doc`
                    );
                }
              };
            }
          }
          else if (courseInfo.type == "TEST-SERIES") {
            if (getDeviceType() == 'm') {
              onClickFunction = () => {

                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              );
                setModalData({
                  packageId: courseInfo.id,
                  type: 'TEST_SERIES',
                  parentPackageId: courseInfo.packageId
                })
              }
            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              );
                testPortalOpened.current=true;
                openTestPortal(courseInfo.id,courseInfo.packageId,courseInfo.title,"INCOMPLETE", "", [], "", false, true)
              };
            }
          }
          else if (courseInfo.type == 'VIDEOS') {
            
            if (getDeviceType() == 'm' && !iOS) {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              );
                setModalData({
                  packageId: courseInfo.id,
                  parentPackageId: courseInfo.packageId,
                  type: 'VIDEOS'
                })
              }

            }
            else {
              onClickFunction = () => {
                if (typeof (Moengage) !== 'undefined') Moengage.track_event(
                  'Resume_btn_clicked',
                  getMoengageMetadata(
                      payload
                  ),
              );
                let url = `/video-package/${courseInfo.packageId}/${courseInfo.title}#current-video/${courseInfo.id}`
                props.goTo(url);

              }
            }
          }
          return (
            <div className="continue-reading-container"
              id={
                index == props.continueReading.data.length - 3
                  ? "continue-studying-last-item"
                  : ""
              }
              onClick={onClickFunction}
            >
           
              <div className="continue-reading">
                <div
                  title={courseInfo.title}
                  className="continue-reading-image"
                  // style={{ backgroundImage: `url(${courseInfo.thumbnail})` }}
                  style={{ backgroundImage: `url(${optimizedImgUrl(courseInfo.thumbnail)})` }}

                >
                  <div className="overlay-bg"></div>
                  <img src={optimizedImgUrl(courseInfo.thumbnail )|| FallBackImage } onError={(e)=>{e.target.onerror = null; e.target.src=`${FallBackImage}`}} alt={courseInfo.title|| 'course'}/>
                </div>
                <div className="continue-reading-progress">
                  {courseInfo.seekTime ? (
                    <AProgressBar
                      progress={Math.ceil(
                        (courseInfo.seekTime / courseInfo.duration) * 100
                      )}
                    // progress={2}
                    />
                  ) : ''}
                </div>
                <div className="continue-reading-details">
                  <div className="cr-details-title" title={courseInfo.title}>{courseInfo.title}</div>
                  <div className="cr-details-info">
                    <span>
                      <AButton
                        
                        text={"RESUME"}
                      ></AButton>
                    </span>
                    <span>
                      {" "}
                      {timeLeft && (
                        <span className="time-left"> {timeLeft}</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </NewCarousel>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { continueReading } = state;
  return {
    continueReading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateReadingListData: (data) => dispatch(updateReadingListData(data)),
    updateReadingListPage: (pageNo) => dispatch(updateReadingListPage(pageNo)),
    replaceReadingListData:(data)=>dispatch(replaceReadingListData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContinueReading);
import React, { useEffect, useState } from "react";
import OTPInput from "./AOtp";
import { connect } from "react-redux";
import { Cookies } from "react-cookie";
import {
  checkIfEmailRegistered,
  startLoader,
  stopLoader,
  verifyOTP,
} from "../../data/ducks/header/actions";
import { isEmailValid, maskString } from "../../data/utils/helpers";
import CustomButton from "./CustomButton";

const ForgotPasswordForm = (props) => {
  const cookie = new Cookies();
  const [username, setUsername] = useState(cookie.get("cp_user_email"));
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailNotFoundForOTP, setEmailNotFoundForOTP] = useState(false);
  const [emailCheckErrorMsg, setEmailCheckErrorMsg] = useState(undefined);
  const [OTP, setOTP] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpValue, setOtpValue] = useState(undefined);
  const [emptyOTP, setEmptyOTP] = useState(false);
  const [showOTPInputSection, setShowOTPInputSection] = useState(false);
  const [timer, setTimer] = useState(30);

  const handleCancel = (e) => {
    e.preventDefault();
    setOtpValue("");
    setOtpInvalid(false);
    setShowOTPInputSection(false);
    props.setForgetPassShow(false);
  };

  const handleKeyPressForForgotPassword = (e) => {
    if (e.keyCode === 13) {
      if (showOTPInputSection) {
        // do nothing
      } else {
        handleEmailIdForOTP();
      }
    }
  };

  const handleEmailIdForOTP = () => {
    setShowEmailError(false);
    setEmailNotFoundForOTP(false);
    if (isEmailValid(username)) {
      props.startLoader();
      // dispatch an action to check if the email id is registered or not
      props
        .checkIfEmailRegistered(username)
        .then(
          () => {
            setEmailCheckErrorMsg({});
            setEmailNotFoundForOTP(false);
            setOtpInvalid(false);
            setOtpValue("");
            setShowOTPInputSection(true);
            setTimer(30);
          },
          (err) => {
            if (err.message) {
              // then it means too many attempts
              // allOTPAttemptsDone:err.message,
              setEmailCheckErrorMsg({ [username]: err.message });
            }
          }
        )
        .catch((err) => console.log(11, err))
        .finally(() => props.stopLoader());
    } else {
      setShowEmailError(true);
    }
  };

  const validateOTP = (otpVal) => {
    setOtpInvalid(false);
    setEmptyOTP(false);
    if (otpVal.length < 6) {
      setEmptyOTP(true);
      return;
    }
    props.startLoader();
    setOtpValue(otpVal);
    const obj = {
      email: username,
      otp: otpVal,
    };
    // dipatch and action which verifies the otp entered.
    props
      .verifyOTP(obj)
      .then(
        () => {
          console.log("success");
          console.log(otpVal);
          props.setOTPValue(OTP);
        },
        () => {
          setOtpInvalid(true);
          setEmailNotFoundForOTP(false);
        }
      )
      .finally(() => props.stopLoader());
  };

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <div className="userconatiner">
        <div className="myaccountheader">Forgot Password?</div>
        {showOTPInputSection ? (
          <>
            <div className="row sub-text">
              Please enter the OTP sent to <br />
              {maskString(username)}
            </div>
            <div>Enter 6 Digit OTP Below</div>
            <OTPInput
              length={6}
              className="otpContainer"
              inputClassName={`otpInput ${otpInvalid ? "input-error" : ""}`}
              isNumberInput
              autoFocus
              onChangeOTP={(otp) => setOTP(otp)}
            />
            <div className="row sub-text font-12">
              Didn't receive the OTP?
              <span className="devider">
                &nbsp;
                {timer > 0 ? (
                  <span className="sec"> Resend in {timer}s</span>
                ) : (
                  <span className="sec" onClick={handleEmailIdForOTP}>
                    <a style={{ cursor: "pointer" }}> Resend OTP</a>
                  </span>
                )}
              </span>
            </div>

            {otpInvalid && (
              <div style={{ display: "block" }} className={"error-message"}>
                Invalid OTP
              </div>
            )}

            {emptyOTP && (
              <div style={{ display: "block" }} className={"error-message"}>
                Please enter the OTP
              </div>
            )}
          </>
        ) : (
          <>
            <div className="row sub-text">
              Enter the email address associated with your account and we'll
              email you an OTP to verify it's you.
            </div>
            <div className="row">
              <div className="input-group">
                <label htmlFor="username">Email</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Enter email address"
                  className={
                    showEmailError || emailNotFoundForOTP
                      ? "input-error"
                      : "margin2"
                  }
                  onChange={(e) => setUsername(e.target.value)}
                  size="default"
                  onKeyDown={(e) => handleKeyPressForForgotPassword(e)}
                  value={username}
                  disabled={true}
                />
              </div>
            </div>
          </>
        )}
        {showEmailError && (
          <div className={"error-message"}>Please enter a valid email id</div>
        )}
        {emailCheckErrorMsg && emailCheckErrorMsg[username] && (
          <div className={"error-message"}>{emailCheckErrorMsg[username]}</div>
        )}
        <div className="buttondiv1">
          {!showOTPInputSection && (
            <CustomButton
              type="secondary"
              classes="custom-button cancel-btn"
              buttontext="CANCEL"
              onClick={handleCancel}
            />
          )}
          <CustomButton
            type="primary"
            buttontext={showOTPInputSection ? "VALIDATE OTP" : "SEND OTP"}
            loading={props.showLoader}
            onClick={
              showOTPInputSection
                ? () => validateOTP(OTP)
                : () => handleEmailIdForOTP()
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  showLoader: state.header.showLoader,
});

const mapDispatchToProps = {
  startLoader,
  stopLoader,
  checkIfEmailRegistered,
  verifyOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

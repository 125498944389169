import { isADDA } from '../../app/data/utils/helpers';
import { storeHostUrl } from '../../constants/appConfig';

const exploreAddaStats = () => [
    {
        imageUri: 'https://www.adda247.com/static/images/ic-learning-app.svg',
        title: 'Ranked No.1',
        subTitle: 'Learning App in Test prep'
    },
    {
        imageUri: 'https://www.adda247.com/static/images/ic-selection.svg',
        title: '40 million+',
        subTitle: 'Online Students'
    },
    {
        imageUri: 'https://www.adda247.com/static/images/ic-coaching-centre.svg',
        title: '450+',
        subTitle: 'Coaching Centres across India'
    }
];

const exploreAddaBlogSites = () => [
    {
        imageUri: 'https://www.adda247.com/images/ba-logo.svg',
        title: '',
        subTitle: '',
        url: 'https://www.bankersadda.com'
    },
    {
        imageUri: 'https://www.adda247.com/images/sa-logo.svg',
        title: '',
        subTitle: '',
        url: 'https://sscadda.com'
    },
    {
        imageUri: 'https://www.adda247.com/images/ta-logo.svg',
        title: '',
        subTitle: '',
        url: 'https://teachersadda.co.in'
    },
    {
        imageUri: 'https://www.adda247.com/images/ea-logo.svg',
        title: '',
        subTitle: '',
        url: 'https://www.engineersadda.co'
    },
    {
        imageUri: 'https://www.adda247.com/images/da-logo.svg',
        title: '',
        subTitle: '',
        url: 'https://defence.adda247.com/'
    }
];

const mediaCoverage = () => [
    {
        title: 'Times of India',
        imageUri: '/images/toi-logo.png',
        link:
      'https://timesofindia.indiatimes.com/business/india-business/adda247-raises-usd-6-mn-in-series-b-funding-led-by-info-edge/articleshow/71952331.cms'
    },
    {
        title: 'The Statesman',
        imageUri: '/images/the-statesman.png',
        link:
      'https://www.thestatesman.com/education/5-apps-to-help-you-in-preparing-for-competitive-examinations-1502735553.html'
    },
    {
        title: 'The Hindu',
        imageUri: '/images/the-hindu.png',
        link:
      'https://www.thehindu.com/education/fostering-digitisation/article26035226.ece'
    },
    {
        title: 'Yourstory',
        imageUri: '/images/your-story.png',
        link:
      'https://yourstory.com/2019/11/funding-edtech-adda247-series-b-infoedge'
    },
    {
        title: 'The Indian Express',
        imageUri: '/images/Indian-Express-logo.png',
        link:
      'https://indianexpress.com/article/education/teachers-day-these-people-left-their-lucrative-jobs-to-tutor-students-online/'
    },
    {
        title: 'Business World',
        imageUri: '/images/business-world.png',
        link:
      'http://www.businessworld.in/article/Trends-In-The-Ed-tech-Space/08-12-2018-164926'
    }
];

const browseByProductType = () => isADDA ? [
    {
        iconUri: '/images/product-type-img2.svg',
        title: 'Online Live Classes',
        url: `${storeHostUrl}/online-coaching`,
        desc: `Live classes & doubt clearing sessions by Top Faculty`,
        explore_text : `Explore Courses`
    },
    {
        iconUri: '/images/product-type-img1.svg',
        title: 'Mock Tests',
        url: `${storeHostUrl}/mock-tests`,
        desc: `Practice unlimited mock tests that get you prepared to face exams like a pro`,
        explore_text : `Explore Tests`
    },
    {
        iconUri: '/images/product-type-img3.svg',
        title: 'Video Courses',
        url: `${storeHostUrl}/video-classes`,
        desc: `High Quality Content for Complete Conceptual Clarity`,
        explore_text : `Explore Videos`
    },
    {
        iconUri: '/images/product-type-img4.svg',
        title: 'E-Books',
        url: `${storeHostUrl}/ebooks`,
        desc: `Study at your convenience with our vast library of e-books`,
        explore_text : `Explore E-Books`
    },
    {
        iconUri: '/images/product-type-img5.svg',
        title: 'Printed Books',
        url: `${storeHostUrl}/books`,
        desc: `Still want books made of paper? Don’t worry, we’ve got you covered`,
        explore_text : `Explore Books`
    },
    {
        iconUri: '/images/product-type-img6.svg',
        title: 'Free Study Material',
        url: `/mock-tests`,
        desc: `Get free All India Level Tests and Much More`,
        explore_text : `Explore Free Tests`
    }
] : [
    {
        iconUri: '/images/product-type-img7.svg',
        title: '3D Learning',
        url: `${storeHostUrl}/3d-models`,
        desc: `Get 3D-Models for experiential learning`,
        explore_text : `Explore 3D Learning`
    },
    {
        iconUri: '/images/product-type-img2-sank.svg',
        title: 'Online Live Classes',
        url: `${storeHostUrl}/online-coaching`,
        desc: `Live classes & doubt clearing sessions by Top Faculty`,
        explore_text : `Explore Courses`
    },
    {
        iconUri: '/images/product-type-img1.svg',
        title: 'Mock Tests',
        url: `${storeHostUrl}/mock-tests`,
        desc: `Practice unlimited mock tests that get you prepared to face exams like a pro`,
        explore_text : `Explore Tests`
    },
    {
        iconUri: '/images/product-type-img5.svg',
        title: 'Printed Books',
        url: `${storeHostUrl}/books`,
        desc: `Still want books made of paper? Don’t worry, we’ve got you covered`,
        explore_text : `Explore Books`
    },
    {
        iconUri: '/images/product-type-img6.svg',
        title: 'Free Study Material',
        url: `/mock-tests`,
        desc: `Get free All India Level Tests and Much More`,
        explore_text : `Explore Free Tests`
    }  
];

export {
    browseByProductType,
    mediaCoverage,
    exploreAddaBlogSites,
    exploreAddaStats
};
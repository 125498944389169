import React, { useEffect, useState } from "react";
import fetch from "../../../../data/utils/fetch";
import { STOREFRONT_URI } from "../../../../../constants/appConfig";
import CustomModal from "../../../library/CustomModal";
const VideoSolution = ({ packageId, mappingId, questionId }) => {
  const [baseUrl, setBaseUrl] = useState();

  const [videoUrl, setVideoUrl] = useState({}); // {questionId:'url' | -1}
  const [loading, setLoading] = useState(true);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    fetch(
      `${STOREFRONT_URI}/api/v1/test-series/${packageId}/${mappingId}/video-solutions`,
      "GET"
    ).then(
      (res) => {
        if (res.success && res.data) {
          setBaseUrl(res.data.url);
        }
      },
      (err) => {}
    );
  }, []);
  useEffect(() => {
    if (questionId !=undefined && baseUrl && videoUrl[questionId+1]==undefined ) {
      let lang = "ENGLISH";
      var path1 = mappingId + "/" + lang + "/" + (questionId + 1) + ".mp4";
      var path2 = mappingId + "/" + "HINDI" + "/" + (questionId + 1) + ".mp4";
      let videoBaseUrl = baseUrl;
      let videoUrl1 = videoBaseUrl.replace(/\*/g, path1);
      let videoUrl2 = videoBaseUrl.replace(/\*/g, path2);
      doesVideoSolutionExist(videoUrl1, videoUrl2);
    }
  }, [questionId, baseUrl]);

  const doesVideoSolutionExist = (url1, url2) => {
    setLoading(true);
    fetch(url1, "HEAD").then(
      (res) => {
        setLoading(false);
        updateVideoUrlData(url1);
      },
      (err) => {
        fetch(url2, "HEAD").then(
          ((res) => {
            setLoading(false);
            updateVideoUrlData(url2);
          },
          (err) => {
            setLoading(false);
          })
        );
      }
    );
  };

  const closeSolution = () => {
    setPlay(false);
  };
  const updateVideoUrlData = (url) => {
    let updatedVideoUrl = { ...videoUrl };
    updatedVideoUrl[questionId + 1] = url;
    setVideoUrl(updatedVideoUrl);
  };

  const playSolutionVideo = () => {
    if (videoUrl[questionId + 1]) {
      setPlay(true);
    }
  };
  return (
    <>
      {play && (
        <CustomModal
            modalClassName={"video-solution"}
          footer={null}
          visible={play}
          title={"Video Solution"}
          onCancel={closeSolution}
          closeCallback={closeSolution}
          closable={true}
          maskClosable={false}
          keyboard={false}
         
        >
          <div style={{
            padding:'10px'
          }}>
          <video
            controls
            autoPlay
            // muted
            width="100%"
            height="100%"
            oncontextmenu="return false;"
          >
            <source src={videoUrl[questionId + 1]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
        </CustomModal>
      )}
      {baseUrl && (videoUrl[questionId + 1])?
        <div className={`test-action-item ${loading?'disabled':''}`} >
          <button className="red" disabled={loading} onClick={playSolutionVideo}>
            Video Solution
          </button>{" "}
        </div>:''
      }
    </>
  );
};

export default VideoSolution;

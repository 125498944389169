import React from "react";
import { FallBackImage } from "../../../constants/appConfig";

const ProductCard = React.forwardRef((props, ref = null) => {
  let {
    src = FallBackImage,
    title = "Number Analogy and Odd One Out in the resonse of recor.....",
    btnActionLabel,
    seekTime = "25",
    onClick = { onClick },
    iconSrc = "/images/login-icon2.svg",
  } = props;
  return (
    <div className="ppc-product-card" ref={ref}>
      <div className="cmg-product-card-img-wrap">
        <div
          className="cmg-product-card-img-bg"
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></div>
        <div className="cmg-product-card-img-overlay"></div>
        <img
          src={src || FallBackImage}
          onError={(event) => {
            event.target.src = FallBackImage;
            event.onerror = null;
          }}
        />

        <div className="ppc-absloute-card-icon">
          <img src={iconSrc} />
        </div>
      </div>

      <div className="ppc-product-detail">
        <div className="ppc-product-card-name">{title}</div>
        {btnActionLabel && (
          <div className="ppc-product-card-bottom" onClick={onClick}>
            <div className="ppc-product-card-btn">
              <span>{btnActionLabel}</span>
            </div>
            {seekTime && (
              <div className="ppc-product-card-time">{`${seekTime}`}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default ProductCard;

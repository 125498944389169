import React from "react";
import PropTypes from "prop-types";
import { getQuestionStatus } from "../../../../../data/utils/helpers";
const QuestionsAnalysis = ({
  dtbInfo,
  questionList,
  userResponseObj,
  optionalSectionFlag,
}) => {
  /**
   * 0 -> Correct,1 -> Wrong,2 -> Not Attempt
   *
   */

  const countQuesByStatus = (status) => {
    return questionList?.filter(
      (ques) => getQuestionStatus(ques, userResponseObj) == status && userResponseObj?.[ques.id]?.evaluated !== false
    ).length;
  };
  const getUnevaluatedCount = () => {
    let total = 0;
    for (const key in userResponseObj) {
      if(userResponseObj[key]?.evaluated === false && questionList.find((ques) => parseInt(ques?.id) === parseInt(userResponseObj[key]?.questionMapping)))
        total++;
    }
    return total;
  };
  return (
    <div className="tcq-status-index">
      {dtbInfo?.dtbCount > 0 && (
        <p className="tr-sub-info">
          <img
            src={"/images/test-solution/info-icon.svg"}
            alt="tick-blue.svg"
          />
           Subjective questions({dtbInfo?.dtbCount}) are not included for analysis
        </p>
      )}
      {!dtbInfo?.isOnlyDtb && (
        <>
          <div className="tcq-answered">
            <div className="circle green">{countQuesByStatus(0)}</div>
            Correct
          </div>
          <div className="tcq-answered">
            <div className="circle red">{countQuesByStatus(1)}</div>
            Wrong
          </div>
        </>
      )}
      <div className="tcq-answered">
        <div className="circle " style={{ backgroundColor: "purple" }}>
          {dtbInfo?.dtbCount}
        </div>
        Subjective Question
      </div>
      <div className="tcq-answered">
        <div className="circle grey">{countQuesByStatus(2)}</div>
        Not Answered
      </div>
      {optionalSectionFlag && <div className="tcq-answered">
        <div className="circle yellow">{getUnevaluatedCount()}</div>
        Not Evaulated
      </div>}
    </div>
  );
};
QuestionsAnalysis.defaultProps = {
  dtbInfo: {},
  questionList: [],
  userResponseObj: {},
};

QuestionsAnalysis.propTypes = {
  dtbInfo: PropTypes.object,
  questionList: PropTypes.array,
  userResponseObj: PropTypes.object,
};
export default QuestionsAnalysis;

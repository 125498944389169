import React, { useState, useEffect, useRef } from "react";
import { isPhone, scrollToTop } from "../../data/utils/helpers";
import { caste } from "../../../constants/consts";
import { MISC_URL } from "../../../constants/appConfig";
import fetch from "../../data/utils/fetch";

const UserDetailsForm = ({
  rankType,
  setRankType,
  setPageNo,
  updateFormData,
  addToast,
}) => {
  const [formData, setFormData] = useState({
    neet_score: "",
    app_no: "",
    caste: "",
    email: "",
    mob_no: "",
    rankType: rankType,
    state_name: "",
  });
  const [stateNameData, setStateNameData] = useState([]);
  const [otp, setOTP] = useState("");
  const [otpText, setOtpText] = useState("Send OTP");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [phoneEdit, setPhoneEdit] = useState(false);
  const [timer, setTimer] = useState(0);
  const count = useRef(0);

  const invalidPhoneText = "Please input a valid 10-digit mobile number.";
  const invalidAppNoText = "Please input a valid application number.";
  const invalidOTPText = "Invalid OTP. Please try again.";

  useEffect(() => {
    let intervalId = setInterval(() => {
      setTimer((timer) => (timer > 0 ? timer - 1 : 0));
    }, 1000);

    fetch(`${MISC_URL}/api/v1/get-state-list`, "GET")
      .then((res) => {
        if (res.success && res.data) {
          setStateNameData(res.data);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if(count.current === 5) {
      addToast({message: 'Please try resending the OTP!', timeout: 5000});
      setOtpSent(false);
      count.current = 0;
    }
  },[count.current])

  const handleChange = (label, value) => {
    setFormData({ ...formData, [label]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.app_no && formData.app_no.length < 12) {
      addToast({
        message: invalidAppNoText,
      });
      return;
    }
    updateFormData({ ...formData, otp });
    scrollToTop()
    setPageNo(2);
  };

  const handleSendOtp = () => {
    if (!formData.mob_no || !isPhone(formData.mob_no)) {
      addToast({ message: invalidPhoneText });
      return;
    }

    // setting Initial state for OTP Validation
    setOTP("");
    setOtpVerified(false);

    const body = { phone: formData.mob_no };
    fetch(`${MISC_URL}/api/v1/generate-otp`, "POST", body)
      .then((res) => {
        if (res.success) {
          addToast({
            message: res?.response || "OTP Sent",
            variant: "success",
          });
          setPhoneEdit(true);
          setOtpText("Resend OTP");
          setOtpSent(true);
          setTimer(30);
        } else {
          addToast({
            message: res?.response || "Some Error Occurred",
          });
        }
      })
      .catch((err) => {
        addToast({ message: "Some Error Occurred" });
        console.log(err);
      });
  };

  const handleVerifyOtp = () => {
    if (!formData.mob_no || !isPhone(formData.mob_no)) {
      addToast({ message: invalidPhoneText });
      return;
    }

    if (!otp || otp?.length < 6) {
      addToast({ message: invalidOTPText });
      count.current += 1;
      return;
    }

    setVerifying(true);

    const body = { phone: formData.mob_no, otp: otp };

    fetch(`${MISC_URL}/api/v1/verify-otp`, "POST", body)
      .then((res) => {
        if (res.success) {
          setOtpVerified(true);
        } else {
          addToast({
            message: res?.response || invalidOTPText,
            timeout: 5000,
          });
          count.current += 1;
        }
        setVerifying(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRankTypeChange = (typ) => {
    setFormData({ ...formData, rankType: typ });
    setRankType(typ);
  };

  const isDisabled = () => {
    return (
      !formData.neet_score ||
      !formData.caste ||
      !formData.mob_no ||
      (rankType === 1 && !formData.state_name) ||
      !otpVerified
    );
  };

  return (
    <section className="userDetail">
      <form className="sankUserDetailForm" onSubmit={handleSubmit}>
        <div className="formTwoInRow">
          <div className="SankInputBox">
            <label>
              NEET Score<span>*</span>
            </label>
            <div className="sankInputField">
              <input
                type="number"
                placeholder="Enter your score out of 720"
                required
                max={720}
                min={0}
                name="neet_score"
                value={formData.neet_score}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="SankInputBox">
            <label>Application number</label>
            <input
              type="number"
              placeholder="Enter your NEET application number"
              title="Enter a valid application number."
              pattern="\d{15}"
              name="app_no"
              min={0}
              value={formData.app_no}
              onChange={(e) =>
                handleChange(e.target.name, e.target.value.slice(0, 15))
              }
            />
          </div>
        </div>

        <div className="formTwoInRow">
          <div className="SankInputBox">
            <label>
              Category/caste<span>*</span>
            </label>
            <div className="sankInputField">
              <select
                required
                name="caste"
                placeholder="Select Category/caste"
                value={formData.caste}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                <option value="" disabled="" selected="">
                  Select Category/caste
                </option>
                {caste?.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="SankInputBox">
            <label>Email</label>
            <div className="sankInputField">
              <input
                type="email"
                name="email"
                placeholder="Enter your email id"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="formTwoInRow">
          <div className="SankInputBox">
            <label>
              Mobile Number<span>*</span>
              {phoneEdit && (
                <span
                  data-testid="editText"
                  className="edit"
                  onClick={() => setPhoneEdit(false)}
                >
                  Edit
                </span>
              )}
            </label>
            <div className="sankInputField">
              <input
                type="number"
                placeholder="Enter your 10 digit mobile number"
                title="Enter a valid 10 digit mobile number."
                pattern="[6-9][0-9]{9}"
                required
                min={0}
                name="mob_no"
                value={formData.mob_no}
                onChange={(e) => {
                  handleChange(e.target.name, e.target.value.slice(0, 10));
                  setOtpVerified(false);
                  setOtpText('Send OTP');
                  setTimer(0);
                }}
                readOnly={phoneEdit}
              />

              {timer <= 0 && formData.mob_no.length === 10 && (
                <div
                  className="SankFormAbsText linkText"
                  onClick={handleSendOtp}
                >
                  {otpText}
                </div>
              )}
              {timer > 0 && (
                <div className="SankFormAbsText">
                  <span className="sec">Resend in {timer}s</span>
                </div>
              )}
            </div>
            <div className="sankOtpInfo">
              Only 10 OTP can be generated in 24 hours for per mobile number
            </div>
          </div>
          {otpSent ? (
            <div className="SankInputBox">
              <label>
                Enter OTP<span>*</span>
              </label>
              <div className="sankInputField">
                <input
                  type="number"
                  name="otp"
                  min={0}
                  value={otp}
                  placeholder="Enter OTP"
                  onChange={(e) => {
                    setOTP(e.target.value.slice(0, 6));
                    setOtpVerified(false);
                  }}
                />
                {verifying ? (
                  <div className="SankFormAbsText">
                    <span className="spinner" />
                  </div>
                ) : (
                  <div className="SankFormAbsText linkText">
                    {otpVerified ? (
                      <img
                        data-testid="verified-icon"
                        src="images/sankOptConfirmed.svg"
                      />
                    ) : (
                      <span onClick={handleVerifyOtp}>Verify</span>
                    )}
                  </div>
                )}
              </div>
              <div className="sankOtpInfo">
                To predict the rank, verifying the OTP is must
              </div>
            </div>
          ) : (
            <div className="SankInputBox"></div>
          )}
        </div>

        <div className="formSingleInRow">
          <div className="sankPredictRankHeading">Predict my rank</div>
          <div className="radiobtns">
            <div className="SankInputBox">
              <input
                data-testid="radio-india"
                id="toggle-on"
                name=""
                type="radio"
                onChange={() => handleRankTypeChange(0)}
                checked={rankType === 0}
                value={formData.rankType}
              />
              <label htmlFor="toggle-on">All India Level Rank</label>
            </div>
            <div className="SankInputBox">
              <input
                data-testid="radio-state"
                id="toggle-off"
                name=""
                onChange={() => handleRankTypeChange(1)}
                checked={rankType === 1}
                value={formData.rankType}
                type="radio"
              />
              <label htmlFor="toggle-off">State Level Rank</label>
            </div>
          </div>

          {rankType === 1 && (
            <div className="SankInputBox StateSection">
              <label>
                State<span>*</span>
              </label>
              <div className="sankInputField">
                <select
                  required={rankType === 1}
                  placeholder="Select your state"
                  name="state_name"
                  value={formData.state_name}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                  <option value="" disabled="" selected="">
                    Select your state{" "}
                  </option>
                  {stateNameData?.map((item, idx) => (
                    <option key={idx} value={item.stateName}>
                      {item.stateName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="sankUserDetailSubmit">
            <button disabled={isDisabled()} type="submit">
              Predict My Rank
            </button>
            {/* <input type="submit" value="Predict My Rank" /> */}
          </div>
        </div>
      </form>
    </section>
  );
};

export default UserDetailsForm;

import types from "./types";

const addToast = (obj) => ({
  type: types.ADD_TOAST,
  payload: {
    id: Date.now(),
    ...obj,
  },
});

const removeToast = (id) => ({
  type: types.REMOVE_TOAST,
  payload: id,
});
const showButtonToolTip = (payload = {}) => ({
  type: types.SHOW_BUTTON_TOOLTIP,
  payload,
});

export { addToast, removeToast,showButtonToolTip };

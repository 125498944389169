import reducerRegistry from "../../reducerRegistry";
import types from "./types";

function productDetailListing(state = {}, action) {
  switch (action.type) {
    case types.GET_PRODUCT_DETAIL_LISTING:
      let packageDetail = getPackageData(action.payload.data);
      return {
        ...state,
        data: packageDetail,
      };

    case types.GET_PRODUCT_DETAIL_LISTING_ON_SCROLL: {
      const newList = state.data.packageEsEntity.concat(
        action.payload.data.packages
      );
      state.data.packageEsEntity = newList;
      return {
        ...state,
      };
    }
    case types.GET_SECONDARY_FACETS:
      const secondaryFacetState = Object.assign({}, state);
      secondaryFacetState.data.facetsList.examTypes =
        action.payload.data.examTypes;
      return {
        ...secondaryFacetState,
      };

    case types.CLEAR_PRODUCT_LISTING_DATA: {
      state = {};
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

const getPackageData = (pdata) => {
  let defaultData = {
    category: null,
    count: 0,
    facetsList: {},
    packageEsEntity: [],
    suggestionCategory: null,
  };
  defaultData.packageEsEntity = pdata.packages;
  defaultData.count = pdata.count;
  defaultData.facetsList = pdata.primaryFacets;

  return defaultData;
};
reducerRegistry.register("productDetailListing", productDetailListing);
export default productDetailListing;

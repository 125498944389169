import Cookies from "js-cookie";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import Loadable from 'react-loadable';
import { BLANK_IDENTIFIER } from "./MoengageConstants";
import CustomLoader from "../components/library/CustomLoader";
import fetch from "../data/utils/fetch";

import {
  GOOGLE_CLIENT_ID,
  STOREFRONT_URI,
  storeHostUrl,
  userApi,
  pageName,
  pdpUrls,
} from "../../constants/appConfig";
import { default as Link } from "../containers/ConnectedLink";
import { replaceReadingListData } from "../data/ducks/continueReading/actions";
import {
  checkIfEmailRegistered,
  getAutoSuggestion,
  loadUserCart,
  loginFlag,
  loginFromCookie,
  logoutUser,
  resetUserPassword,
  setCartCountFromCookie,
  startLoader,
  stopLoader,
  userLoginAndRegister,
  verifyOTP,
} from "../data/ducks/header/actions";
import "../data/ducks/header/reducers";
import {
  deleteAllCookies,
  fireMetaPixelEvent,
  fireWishListMoengageEvent,
  getCpOriginValue,
  getMoengageMetadata,
  getPdpUrl,
  getUpdatedNewWishListStore,
  getUrlPath,
  getUrlPathObject,
  initScriptChat,
  isADDA,
  isEmailValid,
  isEmpty,
  isExamDetailListingPage,
  isSet,
  isUserNameValid,
  isUserPasswordValid,
  openWidget,
  parseJwt,
  replaceHyphenWithSpace,
  resetMobileMenu,
} from "../data/utils/helpers";


const CustomDropdown = Loadable({
  loader: () => import("./library/CustomDropdown"),
  modules: ["./library/CustomDropdown"],
  webpack: [require.resolve("./library/CustomDropdown")],
  loading: () => <div></div>,
});


const CustomModal = Loadable({
  loader: () => import("./library/CustomModal"),
  modules: ["./library/CustomModal"],
  webpack: [require.resolve("./library/CustomModal")],
  loading: () => <div></div>,
});
const Sidebar = Loadable({
  loader: () => import("./library/Sidebar"),
  modules: ["./library/Sidebar"],
  webpack: [require.resolve("./library/Sidebar")],
  loading: () => <div></div>,
});

const MainMenu = Loadable({
  loader: () => import("./MainMenu"),
  modules: ["./MainMenu"],
  webpack: [require.resolve("./MainMenu")],
  loading: () => <div></div>,
});
const FocusInput = Loadable({
  loader: () => import("./searchOverlay/FocusInput"),
  modules: ["./searchOverlay/FocusInput"],
  webpack: [require.resolve("./searchOverlay/FocusInput")],
  loading: () => <div></div>,
  timeout: 20000
});
const SearchUIOverLay = Loadable({
  loader: () => import("./searchOverlay/index"),
  modules: ["./searchOverlay/index"],
  webpack: [require.resolve("./searchOverlay/index")],
  loading: () => <div></div>,
});
const LoginModal = Loadable({
  loader: () => import("./library/LoginModal"),
  modules: ["./library/LoginModal"],
  webpack: [require.resolve("./library/LoginModal")],
  loading: () => <div></div>,
});
const ForgotPasswordNew = Loadable({
  loader: () => import("./library/ForgotPassswordNew"),
  modules: ["./library/ForgotPassswordNew"],
  webpack: [require.resolve("./library/ForgotPassswordNew")],
  loading: () => <div></div>,
});
const ResetPasswordModalNew = Loadable({
  loader: () => import("./library/ResetPasswordModalNew"),
  modules: ["./library/ResetPasswordModalNew"],
  webpack: [require.resolve("./library/ResetPasswordModalNew")],
  loading: () => <div></div>,
});

const PPCSearch = Loadable({
  loader: () => import("../containers/MyPurchases/PPCSearch/PPCSearch"),
  modules: ["../containers/MyPurchases/PPCSearch/PPCSearch"],
  webpack: [require.resolve("../containers/MyPurchases/PPCSearch/PPCSearch")],
  loading: () => <div></div>,
});

const LoginModalNew = Loadable({
  loader: () => import("./library/LoginModal/LoginModalNew"),
  modules: ["./library/LoginModal/LoginModalNew"],
  webpack: [require.resolve("./library/LoginModal/LoginModalNew")],
  loading: () => <div></div>,
});

import FirstWishListGuideToolTip from "../containers/WishList/FirstWishListGuideToolTip";
import {
  setWishListedPackage,
  setConfirmDeletWishList,
  addRemoveWishList,
  setWishListPackageDetails,
  setSelectedWishListPackageIds,
} from "../data/ducks/wishlist/actions";
import { showButtonToolTip } from "../data/ducks/toast/actions";
class NavBar extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      showLoginModal: false,
      isLoginDisabled: true,
      isEmailValid: false,
      isPasswordValid: false,
      showSignupForm: false,
      isMenuToggled: false,
      visible: false,
      showForgotPasswordModal: false,
      emailNotFoundForOTP: false,
      emailCheckErrorMsg: {},
      showOTPInputSection: false,
      showResetPasswdModal: false,
      isResetDisabled: true,
      showMobileSearch: false,
      searchUiVisibility: false,
      showLoginComponent: false,
      searchTerm: "",
      selectedPlaceHolder: "",
      dropDownSelectedValue: "",
      isSearchDropDownOpen: false,
      sideBarVisibility: false,
      showPurchasedItems: true,
      showErrorModal: false,
      isFacebookPhoneLogin: false,
      verifierToken: "",
      resetPasswordSuccess: false,
      selectedExam: "",
      selectedExam_menu: "",
      socialErrors: "",
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleExamChange = this.handleExamChange.bind(this);
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      // checkCpTokenInParent();
    }
  }

  handleExamChange(exam) {
    this.setState({ selectedExam_menu: exam });
  }

  updateCartState() {
    let token = this.props.cookies.get("cp_token");
  }

  fetchProfileData = () => {
    const customHeaders = {
      "Content-Type": "application/json",
      "X-Auth-Token": "fpoa43edty5",
      "X-JWT-Token": this.props.cookies.get("cp_token"),       // isLoggedIn is jwttoken
    };
    
    fetch(
      `${pdpUrls.userApi}/api/v2/profile`,
      "GET",
      null,
      null,
      null,
      customHeaders
    )
      .then((data) => {
        this.props.cookies.set("cp_paid_user", data?.data?.paidUser)
      })
      .catch((err) =>
      console.log(err)
      );
  };
  componentDidMount() {
    if(typeof this.props.cookies.get("cp_token") !== "undefined"){
      if(typeof this.props.cookies.get("cp_paid_user") === "undefined") {
        this.fetchProfileData()
      }
    }
    // saving query params in session storage
    const queryParams = new URLSearchParams(
      this.props.history?.location?.search
    );
    for (let key of queryParams.keys()) {
      if (key.includes("utm") || key.includes("lid")) {
        sessionStorage.setItem(key, queryParams.get(key));
      }
    }
    document.addEventListener('LoginModalOpen', this.handleLoginModal);
    // saving query params in session storage
    this.handleSelectedExamChange();
    this.unlisten = this.props.history.listen((location, action) => {
      this.handleSelectedExamChange();
      this.handlePaidSearchRetain();
    });

    setTimeout(
      function () {
        this.setState({ showLoginComponent: true });
      }.bind(this),
      2000
    );

    if (this.props.cookies.get("cp_token")) {
      const token = this.props.cookies.get("cp_token");
      const name = this.props.cookies.get("cp_user_name");
      const email = this.props.cookies.get("cp_user_email");
      const id = this.props.cookies.get("cp_user_id");
      const phone = this.props.cookies.get("cp_user_phone");
      const newToken = this.props.cookies.get("cp_new_token");
      const selectedCourse =
        this.props.cookies.get("cp_user" + this.props.userDetails.id) ||
        "BANKING";
      this.props.loginFromCookie(
        token,
        name,
        email,
        id,
        phone,
        selectedCourse,
        newToken
      );

      let d = new Date();
      d.setTime(d.getTime() + 525600 * 60 * 1000);
      this.props.cookies.set("cp_token", token, { path: "/", expires: d });
    }

    this.updateCartState();
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("error401", this.handle401Error.bind(this));

    document.addEventListener("userLogoutCE", this.handleLogOut.bind(this));

    let token = this.props.cookies.get("cp_token");
    if (!this.props.isPaytmMini) {
      if (!token && !this.props.from_app && !this.props.goldapp) {
        google &&
          google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: this.handleOneTapSuccess,
            select_by: "user",
          });
        google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          }
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.unlisten();
    localStorage.removeItem("selectedExam");
    document.removeEventListener('LoginModalOpen', this.handleLoginModal);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userDetails.email !== prevProps.userDetails.email) {
      this.updateCartState();
    }
    if (this.props.loginFlagData) {
      document.body.classList
        ? document.body.classList.add("login-active")
        : (document.body.className += " login-active");

      // resetting the value of loginFlag redux state
      this.props.loginFlag(false)
      this.setState({
        showLoginModal: true,
      });
    }
    if (location.hash == "#show-login") {
      document.body.classList
        ? document.body.classList.add("login-active")
        : (document.body.className += " login-active");
      location.hash = "";
      this.setState({
        showLoginModal: true,
      });
    }
    if (location.hash == "#about-us-register") {
      location.hash = "";
      if (
        !(
          this.props.userDetails.email &&
          this.props.userDetails.email.length > 0
        )
      ) {
        this.setState({
          showLoginModal: true,
        });
      }
    }
    if (location.hash == "#need-help") {
      location.hash = "";
      openWidget();
    }
    if (prevState.showLoginModal !== this.state.showLoginModal) {
      this.setState({
        isFacebookPhoneLogin: false,
      });
    }
  }

  handlePaidSearchRetain = () => {
    const pathname = this.props.history?.location?.pathname;
    if (sessionStorage?.getItem("showPaidContentSearch") === "true" && pathname?.includes("my-purchase")) {
      const timeoutId = setTimeout(() => {
        this.showMobileSearch(true);
        clearTimeout(timeoutId);
      }, [200])
      sessionStorage?.setItem("showPaidContentSearch", "false");
    }
  }

  handleSelectedExamChange = () => {
    const pathname = this.props?.history?.location?.pathname;
    if (pathname?.includes("exam-kit")) {
      const path = pathname?.split("/")[1];
      let examName = path.substring(0, path.length - 9);
      examName = replaceHyphenWithSpace(examName)?.toUpperCase();
      this.setState({ selectedExam: examName });
    } else if (pathname?.includes("product")) {
      const searchParams = new URLSearchParams(
        this.props?.history?.location?.search
      );
      let examName = searchParams.get("examCategory");
      if (examName?.includes("?")) examName = examName?.split("?")[0] || "";
      if (examName) {
        examName = replaceHyphenWithSpace(examName)?.toUpperCase();
        this.setState({ selectedExam: examName });
      } else {
        this.setState({ selectedExam: "" });
      }
    } else if (isExamDetailListingPage(pathname)) {
      let examName = pathname?.split("/")[1];
      examName = replaceHyphenWithSpace(examName)?.toUpperCase();
      this.setState({ selectedExam: examName });
    } else {
      this.setState({ selectedExam: "" });
      localStorage.removeItem("selectedExam");
    }
  };

  handle401Error() {
    if (!window.location.pathname.includes("my-profile"))
      this.setState({ showErrorModal: true });
  }

  moeHandler = (value) => {
    document.querySelector(
      ".signin-up-wrap .dropdown .dropdown-content"
    ).style.display = "none";
    document.body.classList
      ? document.body.classList.remove("coursemenu-active")
      : null;

    if (typeof Moengage !== "undefined" && value !== "") {
      let payload = { action: `my_${value}btnClicked` };
      if (value === "test") {
        payload.index = BLANK_IDENTIFIER;
        payload.content_subject = BLANK_IDENTIFIER;
        payload.rating = BLANK_IDENTIFIER;
        payload.content_url = BLANK_IDENTIFIER;
        payload.exam_category = BLANK_IDENTIFIER;
        payload.user_exam_category_selected = BLANK_IDENTIFIER;
        payload.exam = BLANK_IDENTIFIER;
        payload.language = "English";
        payload.content_language = BLANK_IDENTIFIER;
        payload.content_id = BLANK_IDENTIFIER;
        payload.content_title = BLANK_IDENTIFIER;
        payload.package_status = BLANK_IDENTIFIER;
        payload.package_id = BLANK_IDENTIFIER;
        payload.package_title = BLANK_IDENTIFIER;
        payload.package_type = BLANK_IDENTIFIER;
        payload.package_purchased = BLANK_IDENTIFIER;
        payload.deep_link = BLANK_IDENTIFIER;
      }
      Moengage.track_event(
        value,
        getMoengageMetadata(payload)
      );
    }
  };

  handleSearchSubmit = (searchCat, searchTerm) => {
    if (searchTerm.length > 1) {
      window.open(
        getUrlPath(
          `/search`,
          `categoryFacets=${searchCat || "TEST_SERIES,BOOKS,EBOOKS,VIDEOS,ONLINE_LIVE_CLASSES"
          }&searchTerm=${searchTerm}&primaryFilter=true`
        ),
        "_self"
      );
    }
  };

  //for view all  click
  goToStorePage = (param) => {
    const { dropDownSelectedValue, searchTerm } = this.state;
    debugger;

    window.open(
      getUrlPath(
        `/search`,
        `categoryFacets=${!dropDownSelectedValue ? param : dropDownSelectedValue
        }&searchTerm=${searchTerm}&primaryFilter=true`
      ),
      "_self"
    );
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        areResultsHidden: true,
      });
    }
  }

  handleLoginModal = () => {
    document.body.classList
      ? document.body.classList.add("login-active")
      : (document.body.className += " login-active");
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "login_btn_clicked",
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
        })
      );
    this.setState({
      showLoginModal: !this.state.showLoginModal,
    });
    localStorage.removeItem("GoToTest");
    localStorage.removeItem("urlForTestPortal");
    localStorage.removeItem("urlAfterLogin");
    localStorage.removeItem("GoToQuizRegister");
    localStorage.removeItem("urlForFreeQuizzes");
    localStorage.removeItem("freeQuizTabId");
    localStorage.removeItem("GoToQuizResult");
    localStorage.removeItem("resultUrl");
  };

  toggleMyPurchasedCourses = () => {
    this.setState({
      showPurchasedItems: !this.state.showPurchasedItems,
    });
  };

  handleForgotPasswdBack = () => {
    this.setState({
      showLoginModal: !this.state.showLoginModal,
      showOTPInputSection: false,
      showForgotPasswordModal: false,
      emailNotFoundForOTP: false,
    });
  };

  handleOTPBack = () => {
    this.setState({
      showOTPInputSection: false,
      showForgotPasswordModal: true,
      emptyOTP: false,
      otpInvalid: false,
    });
  };

  handleLogOut = () => {
    if (typeof Moengage !== "undefined") {
      Moengage.track_event(
        "logout",
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
        })
      );
      Moengage.destroy_session();
    }
    deleteAllCookies();
    this.props.clearCacheData();
    if (typeof window !== "undefined") {
      removeUserDataInCookie();
    }
    this.props.logoutUser();
    this.props.setWishListedPackage({});
    this.props.replaceReadingListData();
    this.hideSidebar();
    this.props.history.push(getUrlPathObject("/"));
  };

  handleUserAccountLink = (linkParam) => {
    if (linkParam === "/my-ebooks") this.props.history.push("/my-ebooks");
    else if (linkParam == "/my-live-class")
      this.props.history.push("/my-live-class");
    else
      typeof window !== "undefined" &&
        window.open(`${STOREFRONT_URI}${linkParam}`);
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancelLoginModal = () => {
    document.body.classList
      ? document.body.classList.remove("login-active")
      : null;
    this.resetComponentState();
    this.setState({
      showLoginModal: false,
    });
    this.props.loginFlag(false);
  };

  handleNewPassword = (e) => {
    this.setState({
      newPassword: e.target.value,
    });
    if (!isUserPasswordValid(e.target.value)) {
      this.setState({
        showNewPasswordError: true,
      });
    } else {
      this.setState({
        showNewPasswordError: false,
      });
    }
    if (
      isUserPasswordValid(e.target.value) &&
      e.target.value === this.state.confirmPassword
    ) {
      this.setState({
        showConfirmPasswordError: false,
      });
    }
    if (
      e.target.value === e.target.value.trim() &&
      e.target.value === this.state.confirmPassword
    ) {
      this.setState({
        showSpacePasswordError: false,
      });
    }
  };

  handleCancelResetModal = () => {
    this.resetComponentState();
    this.setState({
      showResetPasswdModal: false,
      resetPasswordSuccess: false,
    });
  };

  showMobileSearch = (val) => {
    this.setState({
      searchUiVisibility: val,
    });
    if (val) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
      document
        .getElementsByClassName("main-header-wrap")[0]
        .classList.add("active-header-search");
    } else {
      document.body.style.overflow = "inherit";
      document.body.style.height = "inherit";
      document
        .getElementsByClassName("main-header-wrap")[0]
        .classList.remove("active-header-search");
    }
  };

  resetPasswordMethod = () => {
    this.setState({
      showNewPasswordError: false,
      showConfirmPasswordError: false,
      showSpacePasswordError: false,
    });
    const { userEmail, confirmPassword, otpValue, newPassword } = this.state;
    if (
      isUserPasswordValid(newPassword) &&
      newPassword === confirmPassword &&
      newPassword == newPassword.trim()
    ) {
      const obj = {
        email: userEmail,
        otp: otpValue,
        providerName: "email",
        sec: confirmPassword,
      };
      this.props.startLoader();
      this.props
        .resetUserPassword(obj)
        .then(() => {
          this.setState({ resetPasswordSuccess: true });
        })
        .finally(() => this.props.stopLoader());
    } else {
      if (!isUserPasswordValid(newPassword)) {
        this.setState({
          showNewPasswordError: true,
        });
      }
      if (newPassword !== confirmPassword) {
        this.setState({
          showConfirmPasswordError: true,
        });
      }
      if (newPassword !== newPassword.trim()) {
        this.setState({
          showSpacePasswordError: true,
        });
      }
    }
  };

  handleConfirmPassword = (e) => {
    this.setState({
      confirmPassword: e.target.value,
    });
    if (e.target.value !== this.state.newPassword) {
      this.setState({
        showConfirmPasswordError: true,
      });
    } else {
      this.setState({
        showConfirmPasswordError: false,
      });
    }
    if (
      e.target.value === e.target.value.trim() &&
      e.target.value === this.state.newPassword
    ) {
      this.setState({
        showSpacePasswordError: false,
      });
    }
  };

  validateOTP = (otpVal) => {
    this.setState({
      otpInvalid: false,
      emptyOTP: false,
    });
    if (otpVal.length < 6) {
      this.setState({
        emptyOTP: true,
      });
      return;
    }
    this.props.startLoader();
    this.setState({
      otpValue: otpVal,
    });
    const obj = {
      email: this.state.userEmail,
      otp: otpVal,
    };
    // dipatch and action which verifies the otp entered.
    this.props
      .verifyOTP(obj)
      .then(
        () =>
          this.setState({
            showResetPasswdModal: true,
            showForgotPasswordModal: false,
            newPassword: "",
            userPassword: "",
          }),
        () =>
          this.setState({
            showResetPasswdModal: false,
            emailNotFoundForOTP: false,
            otpInvalid: true,
          })
      )
      .finally(() => this.props.stopLoader());
  };

  handleCancelForgotModal = () => {
    this.setState({
      showForgotPasswordModal: false,
    });
    this.resetComponentState();
  };

  handleUserEmail = (e) => {
    let updateItems = { userEmail: e.target.value };

    this.setState({
      userEmail: e.target.value,
    });
    if (e.target.value) {
      this.setState({});
    }
  };

  handleKeyPressLoginSignup = (e) => {
    if (e.keyCode === 13) {
      if (this.state.showSignupForm) {
        this.handleUserSignup();
      } else {
        this.handleUserLogin();
      }
    }
  };

  handleEnterInResetPassword = (e) => {
    if (e.keyCode === 13) {
      this.resetPasswordMethod();
    }
  };

  removeBodyUnscroll = () => {
    try {
      document.body.classList.remove("noscroll");
    } catch (ex) { }
  };

  updateDataByAdda = (email, jwtToken, name) => {
    let d = new Date();
    d.setTime(d.getTime() + 525600 * 60 * 1000);
    this.props.cookies.set("cp_token", jwtToken, {
      path: "/",
      expires: d,
    });
  };

  responseFacebook = (response) => {
    this.removeBodyUnscroll();
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "login",
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
          channel: "fb",
          login_status: "success",
          signup_source: pageName[0],
        })
      );
    if (response.accessToken && response.email) {
      const facebookLoginUserDetailObject = {
        name: response.name,
        email: response.email,
        providerUserId: response.userID,
        providerToken: response.accessToken, // id_token
        providerName: "f",
      };
      this.props.startLoader();
      const extraParams = {
        isLogin: false,
        path: `${userApi}/register`,
      };
      this.props
        .userLogin(facebookLoginUserDetailObject, extraParams)
        .then((resp) => {
          // fire meta pixel CompleteRegistration event
          fireMetaPixelEvent("CompleteRegistration");
          setTimeout(() => this.updateCartState(), 0);

          // close login modal, render logged in state
          this.resetComponentState();
          if (typeof window !== "undefined") {
            updateUserDataInCookie(
              this.props.userDetails.email,
              this.props.jwtToken,
              this.props.userDetails.name
            );
          }
        })
        .then(() => {
          if (typeof Moengage !== "undefined")
            Moengage.track_event(
              "login",
              getMoengageMetadata({
                source: "web",
                brand: pageName[1],
                channel: "fb",
                login_status: "success",
                signup_source: pageName[0],
              })
            );
          this.setState({
            socialErrors : "",
          });
          let d = new Date();
          d.setTime(d.getTime() + 525600 * 60 * 1000);
          this.props.cookies.set("cp_token", this.props.jwtToken, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_name", this.props.userDetails.name, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_phone", this.props.userDetails.phone, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_id", this.props.userDetails.id, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set(
            "cp_user_email",
            this.props.userDetails.email,
            { path: "/", expires: d }
          );
          this.props.cookies.set(
            "cp_user_" + this.props.userDetails.id,
            !isSet(this.props.userCourse) ? "BANKING" : this.props.userCourse,
            {
              path: "/",
              expires: d,
            }
          );
          this.props.cookies.set(
            "provider_name",
            facebookLoginUserDetailObject.providerName,
            { path: "/", expires: d }
          );
          this.props.cookies.set("cp_new_token", this.props.jwtTokenNew, {
            path: "/",
            expires: d,
          });
          this.hideSidebar();
          isADDA && initScriptChat();
        })
        .catch((err) => {
          if (typeof Moengage !== "undefined")
            Moengage.track_event(
              "login",
              getMoengageMetadata({
                source: "web",
                brand: pageName[1],
                channel: "fb",
                login_status: "fail",
                signup_source: pageName[0],
              })
            );
          this.setState({
            // apiError: err.message,
            apiError: "Some error occurred, please try again.",
            socialErrors: err?.message || "Some error occured, please try agian",
          });
        })
        .finally(() => this.props.stopLoader());
    } else if (response.accessToken) {
      const facebookLoginUserDetailObject = {
        name: response.name,
        providerUserId: response.userID,
        providerToken: response.accessToken, // id_token
        providerName: "f",
      };
      fetch(`${userApi}/facebook/signin`, "POST", facebookLoginUserDetailObject)
        .then((res) => {
          if (res.isUserDetailsRequired === true) {
            this.setState({
              isFacebookPhoneLogin: true,
              showSignupForm: true,
              verifierToken: res.verifierToken,
            });
          } else {
            this.setState({
              isFacebookPhoneLogin: true,
            });
            const extraParams = {
              isLogin: false,
              path: `${userApi}/facebook/signin`,
            };
            this.props
              .userLogin(facebookLoginUserDetailObject, extraParams)
              .then((resp) => {
                setTimeout(() => this.updateCartState(), 0);
                // close login modal, render logged in state
                this.resetComponentState();
                if (typeof window !== "undefined") {
                  updateUserDataInCookie(
                    this.props.userDetails.email,
                    this.props.jwtToken,
                    this.props.userDetails.name
                  );
                }
              })
              .then(() => {
                if (typeof Moengage !== "undefined")
                  Moengage.track_event(
                    "login",
                    getMoengageMetadata({
                      source: "web",
                      brand: pageName[1],
                      channel: "fb",
                      login_status: "success",
                      signup_source: pageName[0],
                    })
                  );
                let d = new Date();
                d.setTime(d.getTime() + 525600 * 60 * 1000);
                this.props.cookies.set("cp_token", this.props.jwtToken, {
                  path: "/",
                  expires: d,
                });
                this.props.cookies.set(
                  "cp_user_name",
                  this.props.userDetails.name,
                  {
                    path: "/",
                    expires: d,
                  }
                );
                this.props.cookies.set(
                  "cp_user_id",
                  this.props.userDetails.id,
                  {
                    path: "/",
                    expires: d,
                  }
                );
                this.props.cookies.set(
                  "provider_name",
                  facebookLoginUserDetailObject.providerName,
                  { path: "/", expires: d }
                );
                this.props.cookies.set(
                  "fb_user_id",
                  facebookLoginUserDetailObject.providerUserId,
                  { path: "/", expires: d }
                );

                this.props.cookies.set(
                  "cp_user_email",
                  this.props.userDetails.email,
                  { path: "/", expires: d }
                );
                this.props.cookies.set(
                  "cp_user_" + this.props.userDetails.id,
                  !isSet(this.props.userCourse)
                    ? "BANKING"
                    : this.props.userCourse,
                  {
                    path: "/",
                    expires: d,
                  }
                );
                this.hideSidebar();
                isADDA && initScriptChat();
              })
              .catch((err) => {
                if (typeof Moengage !== "undefined")
                  Moengage.track_event(
                    "login",
                    getMoengageMetadata({
                      source: "web",
                      brand: pageName[1],
                      channel: "fb",
                      login_status: "fail",
                      signup_source: pageName[0],
                    })
                  );
                this.setState({
                  // apiError: err.message,
                  apiError: "Some error occurred, please try again.",
                });
              })
              .finally(() => this.props.stopLoader());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  changeSocialError = (err) => {
    this.setState({
      socialErrors: err
    });
  }

  handleOneTapSuccess = (response) => {
    let mainToken = response.credential;
    response = parseJwt(response.credential);
    let profileObj = {
      name: response.name,
      email: response.email,
      googleId: response.sub,
    };
    response = {
      tokenId: mainToken,
      type: "onetap",
      oneTap: 1,
      profileObj,
    };
    this.responseGoogleSuccess(response);
  };

  responseGoogleSuccess = (response) => {
    this.removeBodyUnscroll();
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "login",
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
          type: response?.type || "google",
          login_status: "success",
          channel: "google",
        })
      );

    const googleLoginUserDetailObject = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      providerUserId: response.profileObj.googleId,
      providerToken: response.tokenId, // id_token
      providerName: "g",
    };

    if (response.oneTap) {
      googleLoginUserDetailObject["oneTap"] = response.oneTap;
    }

    this.props.startLoader();

    const extraParams = {
      isLogin: false,
      path: `${userApi}/register`,
    };
    this.props
      .userLogin(googleLoginUserDetailObject, extraParams)
      .then((resp) => {
        // fire meta pixel CompleteRegistration event
        fireMetaPixelEvent("CompleteRegistration");
        setTimeout(() => this.updateCartState(), 0);
        // close login modal, render logged in state
        this.resetComponentState();
        if (typeof window !== "undefined") {
          updateUserDataInCookie(
            this.props.userDetails.email,
            this.props.jwtToken,
            this.props.userDetails.name
          );
        }
      })
      .then(() => {

        this.setState({
          socialErrors : "",
        });

        let d = new Date();
        d.setTime(d.getTime() + 525600 * 60 * 1000);
        this.props.cookies.set("cp_token", this.props.jwtToken, {
          path: "/",
          expires: d,
        });
        this.props.cookies.set("cp_user_name", this.props.userDetails.name, {
          path: "/",
          expires: d,
        });
        this.props.cookies.set("cp_user_phone", this.props.userDetails.phone, {
          path: "/",
          expires: d,
        });
        this.props.cookies.set("cp_user_id", this.props.userDetails.id, {
          path: "/",
          expires: d,
        });
        this.props.cookies.set("cp_user_email", this.props.userDetails.email, {
          path: "/",
          expires: d,
        });
        this.props.cookies.set(
          "provider_name",
          googleLoginUserDetailObject.providerName,
          { path: "/", expires: d }
        );
        this.props.cookies.set(
          "cp_user_" + this.props.userDetails.id,
          !isSet(this.props.userCourse) ? "BANKING" : this.props.userCourse,
          {
            path: "/",
            expires: d,
          }
        );
        this.props.cookies.set("cp_new_token", this.props.jwtTokenNew, {
          path: "/",
          expires: d,
        });
        this.hideSidebar();
        isADDA && initScriptChat();
        this.fetchProfileData()
      })
      .catch((err) => {
        this.setState({
          // apiError: err.message,
          apiError: "Some error occurred, please try again.",
          socialErrors: err?.message || "Some error occured, please try again",
        });
      })
      .finally(() => this.props.stopLoader());
  };

  responseGoogleFail = (data) => {
    Moengage.track_event(
      "login",
      getMoengageMetadata({
        source: "web",
        brand: pageName[1],
        type: data?.type || "google",
        login_status: "fail",
        channel: "google",
      })
    );
    this.removeBodyUnscroll();
  };

  handleKeyPressForForgotPassword = (e) => {
    this.removeBodyUnscroll();
    if (e.keyCode === 13) {
      if (this.state.showOTPInputSection) {
        // do nothing
      } else {
        this.handleEmailIdForOTP();
      }
    }
  };

  handleEmailIdForOTP = () => {
    this.removeBodyUnscroll();
    this.setState({
      showEmailError: false,
      emailNotFoundForOTP: false,
    });
    if (isEmailValid(this.state.userEmail)) {
      this.props.startLoader();
      // dispatch an action to check if the email id is registered or not
      this.props
        .checkIfEmailRegistered(this.state.userEmail)
        .then(
          () => {
            this.setState({
              emailCheckErrorMsg: {},
              emailNotFoundForOTP: false,
              showOTPInputSection: true,
              otpInvalid: false,
              otpValue: "",
            });
          },
          (err) => {
            if (err.message) {
              // then it means too many attempts
              this.setState({
                // allOTPAttemptsDone:err.message,
                emailCheckErrorMsg: { [this.state.userEmail]: err.message },
              });
            }
          }
        )
        .catch((err) => console.log(11, err))
        .finally(() => this.props.stopLoader());
    } else {
      this.setState({
        showEmailError: true,
      });
    }
  };

  handleUserPassword = (e) => {
    this.setState({
      userPassword: e.target.value,
    });
  };

  handleUserName = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };

  handleUserLogin = () => {
    this.removeBodyUnscroll();
    this.setState({
      showPasswordError: false,
      showEmailError: false,
      apiError: "",
    });

    const { userEmail, userPassword } = this.state;

    if (isEmailValid(userEmail) && userPassword.length) {
      this.props.startLoader();
      const userLoginObj = {
        email: userEmail.trim(),
        providerName: "email",
        sec: userPassword,
      };
      const extraParams = {
        isLogin: true,
        path: `${userApi}/login`,
      };
      this.props
        .userLogin(userLoginObj, extraParams)
        .then((resp) => {
          document.body.classList
            ? document.body.classList.remove("login-active")
            : null;
          document.body.classList
            ? document.body.classList.remove("sidebar-active")
            : null;
          resetMobileMenu();
          setTimeout(() => this.updateCartState(), 0);
          // close login modal, render logged in state
          this.resetComponentState();
          if (typeof window !== "undefined") {
            updateUserDataInCookie(
              this.props.userDetails.email,
              this.props.jwtToken,
              this.props.userDetails.name
            );
          }
          this.updateDataByAdda(
            this.props.userDetails.email,
            this.props.jwtToken,
            this.props.userDetails.name
          );
        })
        .then(() => {
          if (typeof Moengage !== "undefined")
            Moengage.track_event(
              "login",
              getMoengageMetadata({
                source: "web",
                brand: pageName[1],
                channel: "email",
                login_status: "success",
              })
            );
          let d = new Date();
          d.setTime(d.getTime() + 525600 * 60 * 1000);
          this.props.cookies.set("cp_token", this.props.jwtToken, {
            path: "/",
            expires: d,
          });
          //this.props.cookies.set('cp_token', this.props.jwtToken, {path: '/', expires: d});
          this.props.cookies.set("cp_user_name", this.props.userDetails.name, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_phone", this.props.userDetails.phone, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_id", this.props.userDetails.id, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set(
            "cp_user_email",
            this.props.userDetails.email,
            { path: "/", expires: d }
          );
          this.props.cookies.set(
            "cp_user_" + this.props.userDetails.id,
            !isSet(this.props.userCourse) ? "BANKING" : this.props.userCourse,
            {
              path: "/",
              expires: d,
            }
          );
          this.props.cookies.set("cp_new_token", this.props.jwtTokenNew, {
            path: "/",
            expires: d,
          });
          setTimeout(() => this.updateCartState(), 0);
          this.hideSidebar();
          isADDA && initScriptChat();
          this.fetchProfileData()
        })
        .catch((err) => {
          if (typeof Moengage !== "undefined")
            Moengage.track_event(
              "login",
              getMoengageMetadata({
                source: "web",
                brand: pageName[1],
                channel: "email",
                login_status: "fail",
              })
            );
          this.setState({
            apiError: err.message,
          });
        })
        .finally(() => this.props.stopLoader());
    } else {
      if (!isEmailValid(userEmail)) {
        this.setState({
          showEmailError: true,
        });
      }
      if (!userPassword.length) {
        this.setState({
          showPasswordError: true,
        });
      }
    }
  };

  // 3 onBlur handlers for email, password, and username
  handleEmailOnBlur = () => {
    const { userEmail } = this.state;
    if (!isEmailValid(userEmail)) {
      this.setState({
        showEmailError: true,
      });
    }
  };

  handlePasswordOnBlur = () => {
    const { userPassword } = this.state;
    if (
      !isUserPasswordValid(userPassword) &&
      userPassword == userPassword.trim()
    ) {
      this.setState({
        showPasswordError: true,
        showPassError: "",
      });
    }
    if (userPassword !== userPassword.trim()) {
      this.setState({
        showPassError: "Your password can’t start or end with a blank space",
        showPasswordError: false,
      });
    }
  };

  handleUserNameOnBlur = () => {
    const { userName } = this.state;
    if (!isUserNameValid(userName)) {
      this.setState({
        showUserNameError: true,
      });
    }
    if ((userName.length > 0 && userName.length < 2) || userName.length > 50) {
      this.setState({
        showNameError: true,
      });
    }
  };

  // 3 onFocus handlers for email, password, and username
  handleEmailOnFocus = () => {
    this.setState({
      showEmailError: false,
    });
  };

  handlePasswordOnFocus = () => {
    this.setState({
      showPasswordError: false,
      showPassError: "",
    });
  };

  handleUserNameOnFocus = () => {
    this.setState({
      showUserNameError: false,
      showNameError: false,
    });
  };

  handleForgotPassword = () => {
    this.removeBodyUnscroll();
    this.setState({
      showForgotPasswordModal: true,
      showLoginModal: false,
    });
    this.props.loginFlag(false);
  };

  handleUserSignup = () => {
    this.removeBodyUnscroll();
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        "signup",
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
          channel: "email",
          signup_source: pageName[0],
          "cp-origin": getCpOriginValue(!!Cookies.get("cp_user_email")),
        })
      );
    this.setState({
      showUserNameError: false,
      showEmailError: false,
      showPasswordError: false,
      showPassError: "",
      showNameError: false,
      apiError: "",
    });
    const { userEmail, userPassword, userName } = this.state;

    if (
      isUserNameValid(userName) &&
      isUserPasswordValid(userPassword) &&
      isEmailValid(userEmail) &&
      userName.length > 0 &&
      userName.length >= 2 &&
      userName.length <= 50 &&
      userPassword === userPassword.trim()
    ) {
      this.props.startLoader();

      var userRegisterObj = {};

      if (this.state.isFacebookPhoneLogin) {
        userRegisterObj = {
          email: userEmail.trim(),
          name: userName.trim(),
          providerName: "email",
          password: userPassword,
          verifierToken: this.state.verifierToken,
        };
      } else {
        userRegisterObj = {
          email: userEmail.trim(),
          name: userName.trim(),
          providerName: "email",
          sec: userPassword,
        };
      }
      const extraParams = {
        isLogin: false,
        path: `${userApi}${this.state.isFacebookPhoneLogin ? "/facebook/new-user" : "/register"
          }`,
      };
      this.props
        .userLogin(userRegisterObj, extraParams)
        .then((resp) => {
          // fire meta pixel CompleteRegistration event
          fireMetaPixelEvent("CompleteRegistration");
          setTimeout(() => this.updateCartState(), 0);

          // close login modal, render logged in state
          this.setState({
            isFacebookPhoneLogin: false,
          });
          this.resetComponentState();
          if (typeof window !== "undefined") {
            updateUserDataInCookie(
              this.props.userDetails.email,
              this.props.jwtToken,
              this.props.userDetails.name
            );
          }
        })
        .then(() => {
          let d = new Date();
          d.setTime(d.getTime() + 10080 * 60 * 1000);
          this.props.cookies.set("cp_token", this.props.jwtToken, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_name", this.props.userDetails.name, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_phone", this.props.userDetails.phone, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set("cp_user_id", this.props.userDetails.id, {
            path: "/",
            expires: d,
          });
          this.props.cookies.set(
            "cp_user_email",
            this.props.userDetails.email,
            { path: "/", expires: d }
          );
          this.props.cookies.set(
            "cp_user_" + this.props.userDetails.id,
            !isSet(this.props.userCourse) ? "BANKING" : this.props.userCourse,
            {
              path: "/",
              expires: d,
            }
          );
          this.props.cookies.set("cp_new_token", this.props.jwtTokenNew, {
            path: "/",
            expires: d,
          });

          this.hideSidebar();
          isADDA && initScriptChat();
        })
        .catch((err) => {
          this.setState({
            apiError: err.message,
          });
        })
        .finally(() => this.props.stopLoader());
    } else {
      if (!isUserNameValid(userName)) {
        this.setState({
          showUserNameError: true,
        });
      }
      if (
        (userName.length > 0 && userName.length < 2) ||
        userName.length > 50
      ) {
        this.setState({
          showNameError: true,
        });
      }
      if (
        !isUserPasswordValid(userPassword) &&
        userPassword == userPassword.trim()
      ) {
        this.setState({
          showPasswordError: true,
          showPassError: "",
        });
      }
      if (userPassword !== userPassword.trim()) {
        this.setState({
          showPassError: "Your password can’t start or end with a blank space!",
          showPasswordError: false,
        });
      }
      if (!isEmailValid(userEmail)) {
        this.setState({
          showEmailError: true,
        });
      }
    }
  };

  handleSignupForm = (type) => {
    let value;
    if (typeof Moengage !== "undefined")
      if (type == "Signup") value = "signup_btn_clicked";
    if (typeof Moengage !== "undefined")
      Moengage.track_event(
        value,
        getMoengageMetadata({
          source: "web",
          brand: pageName[1],
          channel: "email",
        })
      );
    this.removeBodyUnscroll();
    this.setState({
      showSignupForm: !this.state.showSignupForm,
      apiError: "",
    });
  };

  toggleMenu = () => {
    this.setState({ isMenuToggled: !this.state.isMenuToggled });
    if (!this.state.isMenuToggled) {
      document.body.classList
        ? document.body.classList.add("coursemenu-active")
        : (document.body.className += " coursemenu-active");
    }
  };
  menuClick = () => {
    if (this.state.isMenuToggled) {
      this.setState({ isMenuToggled: false });
      document.body.classList
        ? document.body.classList.remove("coursemenu-active")
        : null;
    } else {
      this.setState({ isMenuToggled: true });
      document.body.classList
        ? document.body.classList.add("coursemenu-active")
        : (document.body.className += " coursemenu-active");
    }
  };
  resetComponentState = () => {
    this.setState({
      showLoginModal: false,
      isLoginDisabled: true,
      isEmailValid: false,
      isPasswordValid: false,
      showSignupForm: false,
      showEmailError: false,
      showNameError: false,
      showPasswordError: false,
      showPassError: "",
      userEmail: "",
      userPassword: "",
      userName: "",
      newPassword: "",
      confirmPassword: "",
      showConfirmPasswordError: false,
      showNewPasswordError: false,
      showSpacePasswordError: false,
      showUserNameError: false,
      isResetDisabled: true,
      otpValue: "",
      otpInvalid: false,
      showOTPInputSection: false,
      apiError: "",
      emailNotFoundForOTP: false,
      emptyOTP: false,
      emailCheckErrorMsg: {},
    });
    this.props.loginFlag(false);
  };

  handleOnKeyPressSearch = (e) => {
    if (e) {
      window.open(
        getUrlPath(
          `${storeHostUrl}/search`,
          `category=${this.state.dropDownSelectedValue}&searchTerm=${e}`
        ),
        "_self"
      );
      this.setState({
        areResultsHidden: true,
      });
    }
  };

  getCurrentSearchKey = () => {
    return (
      document.querySelector("#searchBar.search-input-field").value ||
      this.state.searchTerm
    );
  };

  handleOnChangeSearch = (searchCat, searchTerm) => {
    let searchStr = searchTerm;
    const that = this;

    this.setState(
      {
        areResultsHidden: false,
        searchTerm,
        dropDownSelectedValue: searchCat,
      },
      () => {
        let dropDownVal = searchCat || "ALL";
        if (searchStr.length > 2) {
          let searchKey = `${that.getCurrentSearchKey()}-${dropDownVal}`;
          //check if search term has any value in reducer already
          if (
            this.props.searchResults &&
            !isEmpty(this.props.searchResults[searchKey])
          ) {
            this.setState({
              searchDropDownData: this.props.searchResults[searchKey],
              areResultsHidden: false,
            });
          } else if (this.state.dropDownSelectedValue) {
            //check if drop down selected before search
            this.props
              .getAutoSuggestion(searchStr, this.state.dropDownSelectedValue)
              .then(() => {
                searchKey = `${that.getCurrentSearchKey()}-${dropDownVal}`;
                this.setState({
                  searchDropDownData: this.props.searchResults[searchKey],
                  areResultsHidden: false,
                });
              });
          } else {
            // dispatch an action
            this.props.getAutoSuggestion(searchStr).then(() => {
              searchKey = `${that.getCurrentSearchKey()}-${dropDownVal}`;
              this.setState({
                searchDropDownData: this.props.searchResults[searchKey],
                areResultsHidden: false,
              });
            });
          }
        } else {
          this.setState({
            searchDropDownData: {},
          });
        }
      }
    );
  };

  openSearchResult = (item) => {
    const payload = {
      searchterm: this.state.searchTerm,
      courses: item.courses,
      packageId: item.id,
      categories: item.categories,
      keyFor: 1,
      contentType: "PACKAGE",
    };
    this.props.history.push(
      getUrlPathObject(
        getPdpUrl(item.categories, item.id, item.title, item.slugURL)
      )
    );
    // this.state.areResultsHidden=false;
    this.setState({
      areResultsHidden: true,
    });
  };
  showSidebar = () => {
    document.body.classList
      ? document.body.classList.add("sidebar-active")
      : (document.body.className += " sidebar-active");
    this.setState({
      sideBarVisibility: true,
    });
  };
  hideSidebar = () => {
    document.body.classList
      ? document.body.classList.remove("sidebar-active")
      : null;
    resetMobileMenu();
    this.setState({
      sideBarVisibility: false,
    });
  };

  handleErrorLogin() {
    this.handleLogOut();
    this.setState({ showErrorModal: false });
    this.handleLoginModal();
  }
  handleErrorCancel() {
    this.setState({ showErrorModal: false });
    this.handleLogOut();
  }


  enableLoginModal = () => {
    this.resetComponentState();
    this.setState({ showForgotPasswordModal: false, showLoginModal: true });
  };
  resetWishListStoreState = () => {
    this.props.setWishListPackageDetails([]);
    this.props.setSelectedWishListPackageIds([]);
  };
  closeDeleteWishConfirm = () => {
    this.props.setConfirmDeletWishList({
      visible: false,
      source_screen: ''
    });
  };
  deleteWishlistHandler = async () => {
    try {
      const { likeType, packageId } = this.props?.lastClickedWishItemInfo;
      await addRemoveWishList(likeType, packageId);
      this.props.showButtonToolTip({
        isToolTipVisible: true,
        toolTipName: "DELETE_WL",
        tooltipText: `Item removed from Wishlist`,
      });
      // update new wishlist data
      const newWishListState = getUpdatedNewWishListStore(
        true,
        packageId,
        this.props.wishlistPackageData
      );
      // fire moengage
      fireWishListMoengageEvent("wishlist_item_removed", {
        source_screen:this.props?.confirmWishListDelete?.source_screen,
        products_wishlisted_count: newWishListState?.likeCount,
      });
      // close confirm popup
      this.closeDeleteWishConfirm();
      // Dispatch the updated wishlist state to the store
      this.props.setWishListedPackage(newWishListState);
      // reset wishlist state when likeCount is 0
      if (!newWishListState?.likeCount) {
        this.resetWishListStoreState();
      }
    
    } catch (error) {
      this.props.showButtonToolTip({
        isToolTipVisible: true,
        toolTipName: "DELETE_WL",
        tooltipText: error,
      });
    }
  };
  render() {
    const {
      showLoginModal,
      showSignupForm,
      userEmail,
      userPassword,
      userName,
      isMenuToggled,
      showForgotPasswordModal,
      emailNotFoundForOTP,
      showOTPInputSection,
      showResetPasswdModal,
      showMobileSearch,
      newPassword,
      confirmPassword,
      showConfirmPasswordError,
      showNewPasswordError,
      showSpacePasswordError,
      isResetDisabled,
      otpInvalid,
      areResultsHidden,
      sideBarVisibility,
      emailCheckErrorMsg,
    } = this.state;

    const {
      userDetails,
      examMenuData,
      allCoursesList,
      userCourse,
      globalExamList,
      loginFlagData,
    } = this.props;
    if (this.props.from_app) return null;
    if (this.props.goldapp) return null;
    // if(this.props.test_portal) return null;

    let hideHeaderNavigation = false;
    let isTestPortal = false;
    if (
      this.props.test_portal ||
      (typeof window !== "undefined" &&
        (window.location.pathname.includes("my-test-analysis") ||
          window.location.pathname.includes("test-series-portal")))
    )
      hideHeaderNavigation = true;
    if (hideHeaderNavigation) return null;
    if (
      userDetails["email"] &&
      (!userDetails["name"] || userDetails["name"] == "undefined")
    ) {
      userDetails["name"] = userDetails["email"];
    }
    let user_pic = this.props.cookies.get("user_profile_pic");
    return (
      <>
        <div
          className="main-header-wrap"
          style={{
            width: "100%",
            top: "0",
            zIndex: "5",
            visibility: hideHeaderNavigation ? "hidden" : "unset",
            boxShadow: hideHeaderNavigation
              ? "none"
              : "0 0 4px 0 rgba(169, 169, 169, 0.5)",
            background: "#fff",
            position: hideHeaderNavigation ? "unset" : "fixed",
          }}
        >
          <div className="headerwrap">
            <div className="container">
              <div className="hemburger-mobile" onClick={this.showSidebar}>
                <div className="icons-hemb">
                  <img src="/images/menudraw.svg" alt="hamburger menu"/>
                </div>
                {isADDA && <FirstWishListGuideToolTip />}
              </div>
              <div className="adda-logo">
                <Link to={"/"}>
                  {isADDA ? (
                    <img
                      src="/images/header-logo.svg"
                      className="logo-img"
                      alt={`${pageName[0]}-logo`}
                      title={pageName[0]}
                      width="150px"
                      height="40px"
                    />
                  ) : (
                    <img
                      src={"/images/sankalpB-logo.svg"}
                      className="logo-img"
                      alt={`${pageName[0]}-logo`}
                      title={pageName[0]}
                    />
                  )}
                </Link>
              </div>
              {isMenuToggled ? (
                <div className="all-course-wrap" onClick={() => this.menuClick()}>
                  <div
                    className={`all-course-content coursebtn-active"
                    }`}
                    style={{ width: "auto", paddingRight: "10px" }}
                  >
                    {this.state.selectedExam_menu
                      ? this.state.selectedExam_menu
                      : "All Courses"}{" "}
                    <img
                      src={"/images/uprow-menu.svg"}
                      style={{ marginLeft: "10px" }}
                      alt="all course arrow"
                    />
                  </div>{" "}
                </div>
              ) : (
                <div className="all-course-wrap" onClick={() => this.menuClick()}>
                  {" "}
                  <div
                    className={`all-course-content`}
                    style={{ width: "auto", paddingRight: "10px" }}
                  >
                    {this.state.selectedExam || "All Courses"}{" "}
                    <img
                      src={"/images/sankalp-images/alcourse-arrow.svg"}
                      style={{ marginLeft: "10px" }}
                      alt="all course arrow"
                    />
                  </div>
                </div>
              )}
              <div className="sarch-course-wrap">
                <FocusInput />
              </div>
              <div className={"only-desktop signin-up-wrap"}>
                <div className="container">
                  {this.state.showLoginComponent && (
                    <div>
                      {isEmpty(userDetails) ? (
                        <div
                          className={"login-btn ripple"}
                          onClick={this.handleLoginModal}
                        >
                          Signup/Login
                        </div>
                      ) : (
                        <CustomDropdown
                          text={userDetails.name || userDetails.email || this.props.cookies.get("cp_user_name") || this.props.cookies.get("cp_user_phone") }
                          className={"header-dropdown-loggedIn"}
                          userPic={user_pic || ""}
                        >
                          <div className="dropdown-wrap menu-fixed">
                            <div className="container">
                              <div
                                className="login-dropdown-wrap"
                                onMouseEnter={() => {
                                  document.querySelector(
                                    ".signin-up-wrap .dropdown .dropdown-content"
                                  ).style.display = "block";
                                }}
                                onMouseLeave={() => {
                                  document.querySelector(
                                    ".signin-up-wrap .dropdown .dropdown-content"
                                  ).style.display = "none";
                                }}
                              >
                                <div className="login-dropdown-content">
                                  <div className="login-leftside-list">
                                    <ul>
                                      <li>
                                        <Link
                                          to={"/my-purchase"}
                                          onClick={() => { this.moeHandler(""); sessionStorage?.setItem("showPaidContentSearch", "false") }}
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon0.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My Purchased Courses
                                        </Link>
                                      </li>
                                      {!isADDA && <li>
                                        <Link
                                          to={{ pathname: '/my-purchase', state: 'MODELS_3D' }}
                                          onClick={() =>
                                            this.moeHandler("3dmodels")
                                          }
                                        >
                                          <div class="icon-login">
                                            <img src="/images/login-icon9.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My 3D Learning
                                        </Link>
                                      </li>}
                                      <li>
                                        <Link
                                          to={"/my-mock-test"}
                                          onClick={() =>
                                            this.moeHandler("test")
                                          }
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon2.svg" alt="login-icon" />
                                          </div>{" "}
                                          My Test Series
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to={{ pathname: '/my-purchase', state: 'EBOOKS' }}
                                          onClick={() =>
                                            this.moeHandler("ebook")
                                          }
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon3.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My E-Books
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to={{ pathname: '/my-purchase', state: 'VIDEOS' }}
                                          onClick={() =>
                                            this.moeHandler("video")
                                          }
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon4.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My Videos
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to={{ pathname: '/my-purchase', state: 'ONLINE_LIVE_CLASSES' }}
                                          onClick={() =>
                                            this.moeHandler("live-class")
                                          }
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon5.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My Live Classes
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to={"/my-orders"}
                                          onClick={() =>
                                            this.moeHandler("ebook")
                                          }
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon1.svg" alt="login-icon"/>
                                          </div>{" "}
                                          My Orders
                                        </Link>
                                      </li>
                                      {isADDA && (
                                        <li>
                                          <Link
                                            to={"/wishlist"}
                                            onClick={() => {
                                            this.moeHandler("")
                                            fireWishListMoengageEvent('wishlist_viewed', {
                                              source_screen:'Wish listing',
                                              products_wishlisted_count:this.props?.wishlistPackageData?.likeCount || 0
                                            });
                                            }}
                                          >
                                            <div class="icon-login">
                                              <img
                                                src="/images/HeartBlank.svg"
                                                alt="wish"
                                              />
                                            </div>{" "}
                                            My Wishlist
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                  <div className="login-rightside-list">
                                    <ul>
                                      <li>
                                        <Link
                                          to="/my-profile"
                                          key={
                                            userDetails.email + userDetails.id
                                          }
                                          onClick={() => this.moeHandler("")}
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon6.svg" alt="login-icon"/>
                                          </div>{" "}
                                          Account Information
                                        </Link>
                                      </li>
                                      <li>
                                        {isADDA ? (
                                          <span
                                            onClick={() => openWidget()}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="icon-login">
                                              <img src="/images/login-icon7.svg" alt="login-icon"/>
                                            </div>{" "}
                                            Help & Support
                                          </span>
                                        ) : (
                                          <Link to="/support">
                                            <div class="icon-login">
                                              <img src="/images/login-icon7.svg" alt="login-icon"/>
                                            </div>{" "}
                                            Help & Support
                                          </Link>
                                        )}
                                      </li>
                                      <li>
                                        <span
                                          onClick={this.handleLogOut}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="icon-login">
                                            <img src="/images/login-icon8.svg" alt="login-icon"/>
                                          </div>{" "}
                                          Logout
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CustomDropdown>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {
                <>
                  <div
                    onClick={() => this.showMobileSearch(true)}
                    className={"only-mobile"}
                    style={{
                      padding: "0",
                      top: "12px",
                      right: "56px",
                      position: "absolute",
                    }}
                  >
                    <img
                      src="/images/mobile-search-icon.svg"
                      className={"mobile-right-icon"}
                      alt={pageName[0]}
                      title={pageName[0]}
                    />
                  </div>
                  {this.state.searchUiVisibility &&
                    (this.props?.history?.location?.pathname.includes(
                      "/my-purchase"
                    ) ? (
                      <PPCSearch
                        setSearchUiVisiblity={(val) =>
                          this.showMobileSearch(val)
                        }
                      />
                    ) : (
                      <SearchUIOverLay
                        setSearchUiVisiblity={(val) =>
                          this.showMobileSearch(val)
                        }
                      />
                    ))}

                  {showForgotPasswordModal && (
                    <ForgotPasswordNew
                      emailId={userEmail}
                      title={
                        <div className={"modal-header"}>
                          <img
                            style={{ marginRight: "16px" }}
                            src={"/images/back-arrow.svg"}
                            onClick={
                              !showOTPInputSection
                                ? this.handleForgotPasswdBack
                                : this.handleOTPBack
                            }
                            alt={pageName[0]}
                            title={pageName[0]}
                          />
                          {!showOTPInputSection
                            ? "Forgot Password"
                            : "Enter OTP"}
                        </div>
                      }
                      centered
                      emptyOTP={this.state.emptyOTP}
                      handleKeyPressForForgotPassword={
                        this.handleKeyPressForForgotPassword
                      }
                      handleKeyPressLoginSignup={this.handleKeyPressLoginSignup}
                      visible={showForgotPasswordModal}
                      handleuseremail={this.handleUserEmail}
                      method={this.handleEmailIdForOTP}
                      showEmailError={this.state.showEmailError}
                      emailNotFoundForOTP={emailNotFoundForOTP}
                      showLoginLoader={this.props.showLoader}
                      showOTPInputSection={showOTPInputSection}
                      validateOTP={this.validateOTP}
                      otpInvalid={otpInvalid}
                      onCancel={this.handleCancelForgotModal}
                      openLoginModalFromForgotScreen={
                        this.handleForgotPasswdBack
                      }
                      handleEmailOnBlur={this.handleEmailOnBlur}
                      handleEmailOnFocus={this.handleEmailOnFocus}
                      emailCheckErrorMsg={emailCheckErrorMsg}
                      handleSignupForm={this.handleSignupForm}
                      enableLoginModal={this.enableLoginModal}
                    />
                  )}
                </>
              }
            </div>
          </div>

          <Sidebar
            isPaytmMini={this.props.isPaytmMini}
            open={sideBarVisibility}
            close={this.hideSidebar}
            initiateLogin={this.handleLoginModal}
            userName={isADDA ? (userDetails.name) : (userDetails?.name || userDetails?.email || userDetails?.phone)}
            userEmail={userDetails.email}
            handleLogOut={this.handleLogOut}
            allCoursesList={examMenuData}
            history={this.props.history}
            globalExamList={globalExamList}
            wishlistPackageData={this.props?.wishlistPackageData || {}}
            userPhone={userDetails?.phone}
          />
          {isMenuToggled && (
            <MainMenu
              allCoursesList={examMenuData}
              examMenuData={examMenuData}
              globalExamList={globalExamList}
              resetMenu={this.menuClick}
              handleExamChange={this.handleExamChange}
              selectedExam={this.state.selectedExam}
            />
          )}
        </div>
        {this.state.showErrorModal && (
          <CustomModal
            footer={null}
            visible={true}
            closable={true}
            maskClosable={false}
            keyboard={false}
            modalClassName="error401"
            hideHeader={true}
          >
            <div className="error-401-popup">
              <h3>Logged Out</h3>
              <p>
                You are logged out because password for your {pageName[1]}{" "}
                account was recently changed.
                <br />
                <br />
                If you have not made this change, please write to us at{" "}
                <a href={`mailto:support@${pageName[0]}.com`}>
                  support@{pageName[0]}.com
                </a>
              </p>
              <div className="act-btns">
                <div onClick={this.handleErrorCancel.bind(this)}>CANCEL</div>
                <div onClick={this.handleErrorLogin.bind(this)}>LOGIN</div>
              </div>
            </div>
          </CustomModal>
        )}
        {this.state.showLoginModal && (
          isADDA ? 
          <LoginModal
            style={{ padding: "0", margin: "0" }}
            centered
            title={
              <div className={"modal-header"}>
                {showSignupForm ? "Sign up" : "Login"}
              </div>
            }
            responseFacebook={this.responseFacebook}
            responseGoogleSuccess={this.responseGoogleSuccess}
            responseGoogleFail={this.responseGoogleFail}
            visible={showLoginModal || loginFlagData}
            handleKeyPressLoginSignup={this.handleKeyPressLoginSignup}
            onCancel={this.handleCancelLoginModal}
            handleuseremail={this.handleUserEmail}
            handleuserpassword={this.handleUserPassword}
            handleusername={this.handleUserName}
            isLoginDisabled={this.state.isLoginDisabled}
            showEmailError={this.state.showEmailError}
            showNameError={this.state.showNameError}
            showUserNameError={this.state.showUserNameError}
            showPasswordError={this.state.showPasswordError}
            showPassError={this.state.showPassError}
            handleEmailOnBlur={this.handleEmailOnBlur}
            handleEmailOnFocus={this.handleEmailOnFocus}
            handleUserNameOnBlur={this.handleUserNameOnBlur}
            handleUserNameOnFocus={this.handleUserNameOnFocus}
            handlePasswordOnBlur={this.handlePasswordOnBlur}
            handlePasswordOnFocus={this.handlePasswordOnFocus}
            handleUserLogin={this.handleUserLogin}
            apiError={this.state.apiError}
            handleUserSignup={this.handleUserSignup}
            showLoginLoader={this.props.showLoader}
            handleSignupForm={this.handleSignupForm}
            showSignupForm={showSignupForm}
            emailId={userEmail}
            password={userPassword}
            name={userName}
            handleForgotPassword={this.handleForgotPassword}
          /> :
          <LoginModalNew
            style={{ padding: "0", margin: "0" }}
            centered
            title={
              <div className={"modal-header"}>
                {showSignupForm ? "Sign up" : "Login"}
              </div>
            }
            responseFacebook={this.responseFacebook}
            responseGoogleSuccess={this.responseGoogleSuccess}
            responseGoogleFail={this.responseGoogleFail}
            visible={showLoginModal || loginFlagData}
            handleKeyPressLoginSignup={this.handleKeyPressLoginSignup}
            onCancel={this.handleCancelLoginModal}
            handleuseremail={this.handleUserEmail}
            handleuserpassword={this.handleUserPassword}
            handleusername={this.handleUserName}
            isLoginDisabled={this.state.isLoginDisabled}
            showEmailError={this.state.showEmailError}
            showNameError={this.state.showNameError}
            showUserNameError={this.state.showUserNameError}
            showPasswordError={this.state.showPasswordError}
            showPassError={this.state.showPassError}
            handleEmailOnBlur={this.handleEmailOnBlur}
            handleUserNameOnBlur={this.handleUserNameOnBlur}
            handleUserNameOnFocus={this.handleUserNameOnFocus}
            handlePasswordOnBlur={this.handlePasswordOnBlur}
            handlePasswordOnFocus={this.handlePasswordOnFocus}
            handleUserLogin={this.handleUserLogin}
            apiError={this.state.apiError}
            handleUserSignup={this.handleUserSignup}
            showLoginLoader={this.props.showLoader}
            handleSignupForm={this.handleSignupForm}
            showSignupForm={showSignupForm}
            emailId={userEmail}
            password={userPassword}
            name={userName}
            handleForgotPassword={this.handleForgotPassword}
            socialErrors={this.state.socialErrors}
            fetchProfileData={this.fetchProfileData}
            changeSocialError={this.changeSocialError}
          />
        )}
        {showResetPasswdModal && (
          <ResetPasswordModalNew
            title={<div className={"modal-header"}>Reset Password</div>}
            centered
            visible={showResetPasswdModal}
            handleNewPassword={this.handleNewPassword}
            handleConfirmPassword={this.handleConfirmPassword}
            confirmPassword={confirmPassword}
            newPassword={newPassword}
            showNewPasswordError={showNewPasswordError}
            showSpacePasswordError={showSpacePasswordError}
            showConfirmPasswordError={showConfirmPasswordError}
            showLoader={this.props.showLoader}
            resetPasswordMethod={this.resetPasswordMethod}
            isResetDisabled={isResetDisabled}
            onCancel={this.handleCancelResetModal}
            keyboard={false}
            handleEnterInResetPassword={this.handleEnterInResetPassword}
            resetPasswordSuccess={this.state.resetPasswordSuccess}
          />
        )}
        {this.props?.confirmWishListDelete?.visible && (
          <CustomModal
            visible={this.props.confirmWishListDelete?.visible}
            title={
              <div key="packages-header" className={"modal-header"}>
                Confirm remove
              </div>
            }
            onCancel={this.closeDeleteWishConfirm}
            closeCallback={this.closeDeleteWishConfirm}
            closable={true}
            modalClassName={"wishlist-popup"}
          >
            <div>
              <p>Are you sure you want to remove this wishlist item?</p>
              <div className="wishdltbottom">
                <button onClick={this.closeDeleteWishConfirm}>Cancel</button>
                <button
                  onClick={this.deleteWishlistHandler}
                  // disabled={isDeleteLoading}
                >
                  Remove
                </button>
              </div>
            </div>
          </CustomModal>
        )}
      </>
    );
  }
}

NavBar.fetching = ({ dispatch }) => {
  return [
    dispatch(userLoginAndRegister()),
    dispatch(stopLoader()),
    dispatch(startLoader()),
    dispatch(getAutoSuggestion()),
    dispatch(loginFromCookie()),
    dispatch(setCartCountFromCookie()),
    dispatch(logoutUser()),
    dispatch(checkIfEmailRegistered()),
    dispatch(verifyOTP()),
    dispatch(resetUserPassword()),
    dispatch(replaceReadingListData()),
    //dispatch(fetchAllCoursesList()),
    dispatch(loginFlag()),
    dispatch(setWishListedPackage()),
  ];
};
const mapStateToProps = (state) => ({
  showLoader: state.header.showLoader,
  userDetails: state.header.userInfo || {},
  jwtToken: state.header.jwtToken,
  searchResults: state.header.search,
  cartCount: state.header.cartCount,
  loginFlagData: state.header.loginFlag,
  allCoursesList: state.header.allCoursesList,
  userCourse: state?.home?.userCourse,
  from_app: state.from_app,
  goldapp: state.goldapp,
  test_portal: state.test_portal,
  isPaytmMini: state.isPaytmMini,
  continueReading: state.continueReading,
  jwtTokenNew: state.header.jwtTokenNew,
  wishlistPackageData: state.wishlist?.wishlistPackageData,
  confirmWishListDelete: state.wishlist?.confirmWishListDelete,
  lastClickedWishItemInfo: state.wishlist?.lastClickedWishItemInfo,
});
const mapDispatchToProps = {
  userLogin: userLoginAndRegister,
  startLoader,
  loginFromCookie,
  stopLoader,
  getAutoSuggestion,
  logoutUser,
  checkIfEmailRegistered,
  verifyOTP,
  resetUserPassword,
  loadUserCart,
  setCartCountFromCookie,
  //fetchAllCoursesList,
  replaceReadingListData,
  loginFlag,
  setWishListedPackage,
  setConfirmDeletWishList,
  showButtonToolTip,
  setWishListPackageDetails,
  setSelectedWishListPackageIds,
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(NavBar)
);

import React from "react";
import { convertHtmlTagsToEntities } from "../../data/utils/helpers";

const QuestionPageSchema = ({ data }) => {
  const {subject, questionType, options, correct} = data
  let schemaObj;
  if (![1,3]?.includes(questionType)) {
    //DTB
    schemaObj = {
      "@context": "https://schema.org",
      "@type": "QAPage",
      mainEntity: {
        "@type": "Question",
        "encodingFormat": "text/html",
        name: data?.questionTitle, // question summary
        text: convertHtmlTagsToEntities(data?.question), //question
        answerCount:1,
        acceptedAnswer: {
          "@type": "Answer",
          encodingFormat: "text/html",
          text: convertHtmlTagsToEntities(data?.solution), // answer
          url: data?.questionURL,
        }, 
      },
    };
  } else {
    if(questionType == 1 || questionType == 3) {
    schemaObj = {
      "@context": "https://schema.org/",
      "@type": "Quiz",
      name: `Quiz about ${subject}`,
      about: subject,
      url: data?.questionURL,
      educationalAlignment: [
        {
          "@type": "AlignmentObject",
          alignmentType: "educationalSubject",
          targetName: subject,
        },
      ],
      hasPart: {
        "@type": "Question",
        typicalAgeRange: "10-18",
        educationalLevel: "intermediate",
        eduQuestionType:
          data.questionType === 3 ? "Checkbox" : "Multiple choice",
        learningResourceType: "Practice problem",
        assesses: subject,
        encodingFormat: "text/html",
        text: convertHtmlTagsToEntities(data?.question), // questionText
        suggestedAnswer: [],
        acceptedAnswer: []
      },
    };
    options?.filter((item) => !correct?.includes(item?.id))?.forEach((item) => {
      schemaObj.hasPart.suggestedAnswer.push({
        "@type": "Answer",
        encodingFormat: "text/html",
        text: convertHtmlTagsToEntities(item?.val),
        position: item.id,
      })
    })
    if(questionType == 1) {
      schemaObj.hasPart.acceptedAnswer = {
        "@type": "Answer",
        encodingFormat: "text/html",
        text: convertHtmlTagsToEntities(options.find(item => item.id == correct[0])?.val),
        position: correct[0],
      }
      schemaObj.hasPart.answerExplanation = {
        "@type": "Comment",
        encodingFormat: "text/html",
        text: convertHtmlTagsToEntities(data?.solution), // solution
      }
    } else {
      options?.filter(item => correct?.includes(item?.id))?.forEach(item => {
        schemaObj.hasPart.acceptedAnswer.push({
          "@type": "Answer",
          encodingFormat: "text/html",
          text: convertHtmlTagsToEntities(item?.val),
          position: item.id,
          answerExplanation: {
            "@type": "Comment",
            encodingFormat: "text/html",
            text: convertHtmlTagsToEntities(data?.solution), // solution
          },
        })
      })
    }
  }
  }
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schemaObj),
      }}
    />
  );
};

export default QuestionPageSchema;

import React from "react";
import { Suspense } from "react";
import CustomLoader from "../../../../library/CustomLoader";
import { getStrippedHTML, getWordCounts } from "../../../../utils";
const RichTextEditor = React.lazy(() =>
  import("../../../../library/RichTextEditor/RichTextEditor")
);
const DTB = ({
  wordLimit = "",
  editorData = "",
  onChangeCb,
  dtbFontStyles = [],
  warningmessage = '',
  quesIndex,
}) => {
  return (
    <div>
      <Suspense fallback={<CustomLoader />}>
        <div>
          <RichTextEditor
            editorData={editorData}
            onChangeCb={onChangeCb}
            availableFonts={dtbFontStyles}
            wordLimit={wordLimit}
            quesIndex={quesIndex}
          />

          {warningmessage && (
            <p
              className="fill-in-blanks-input-warning"
              style={{ color: "red" }}
            >
              {warningmessage}
            </p>
          )}
        </div>
      </Suspense>
      {/* <textarea placeholder="type your answer here..." /> */}
    </div>
  );
};

export default DTB;
